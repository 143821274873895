import React from "react";
import { useTranslation } from "react-i18next";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();

function ExpItem(props) {
  const { langMode, item, openExpModal, copyInfo } = props;
  const { t } = useTranslation();

  const isWide = langMode === "wide";
  const titleClass =
    langMode === "wide"
      ? "col-4 m-0 p-0 pr-2 font-weight-bold"
      : "col-2 m-0 p-0 pr-2 font-weight-bold";

  return (
    <div className="mb-2 bg-white rounded">
      <div className="p-2 border-bottom border-width-1">
        <p>
          <span className="font-weight-bold">No.</span> {item.ROWINDEX}
        </p>
      </div>
      <div className="p-2">
        <div className="row m-0 p-1">
          <p className={titleClass}>INV No.</p>
          <p className="col m-0 p-0 pl-2">{item.MG_CODE}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exporter")}</p>
          <p className="col m-0 p-0 pl-2">{item.EXP_FIRM}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("importer")}</p>
          <p className="col m-0 p-0 pl-2">{item.BUY_FIRM}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("reportNo")}</p>
          <p className="col m-0 p-0 pl-2">{item.RPT_NO}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exp_modalAmount")}</p>
          <p className="col m-0 p-0 pl-2">
            {item.CUR} {item.CON_AMT ? util.addCommas(item.AMT) : "-"}
          </p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("exp_modalProductNm")}</p>
          <p className="col m-0 p-0 pl-2">{item.EXC_GNM}</p>
        </div>
        <div className="row m-0 p-0">
          <div className={isWide ? "col-12 m-0 p-0" : "col-6 m-0 p-0"}>
            <div className="row m-0 p-1">
              <p className="col-4 m-0 p-0 pr-2 font-weight-bold">
                {t("numOfPackage")}
              </p>
              <p className="m-0 py-0 px-2">
                {item.TOT_PACK_CNT ? util.addCommas(item.TOT_PACK_CNT) : "-"}
              </p>
            </div>
            <div className="row m-0 p-1">
              <p className="col-4 m-0 p-0 pr-2 font-weight-bold">
                {t("weight")}(KG)
              </p>
              <p className="m-0 p-0 px-2">
                {item.TOT_WT ? util.addCommas(item.TOT_WT) : "-"}
              </p>
            </div>
          </div>
          <div className={isWide ? "col-12 m-0 p-0" : "col-6 m-0 p-0"}>
            <div className="row m-0 p-1">
              <p className="col-4 m-0 p-0 pr-2 font-weight-bold">
                {t("customsClearanceStatus")}
              </p>
              <p className="col m-0 p-0 px-2">{item.RECE || "-"}</p>
            </div>
            <div className="row m-0 p-1">
              <p className="col-4 m-0 p-0 pr-2 font-weight-bold">
                {t("carryingPeriod")}
              </p>
              <p className="col m-0 p-0 px-2">{item.PLAN_SHIP_DAY || "-"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-1">
        <div className="col-6 m-0 p-1">
          <button
            className="border-0 w-100 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            onClick={(e) => openExpModal(e, "file", item)}
            data-toggle="modal"
            data-target="#ExpModal"
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-paperclip" />
            </span>
            <span>파일</span>
          </button>
        </div>
        <div className="col-6 m-0 p-1">
          <button
            className="border-0 w-100 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            onClick={() => copyInfo("exp", item)}
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-share-nodes" />
            </span>
            <span>공유</span>
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(ExpItem);
