import React, { useEffect, useRef, useState } from "react";

import TalkNewsManageTable from "./TalkNewsManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import TalkNewsRoomPreviewModal from "./TalkNewsRoomPreviewModal";
import CommonLoader from "../CommonLoader";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "TalkNewsManage";

export default function TalkNewsManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [newsMain, setNewsMain] = useState(undefined);
  const [filters, setFilters] = useState({});
  const controller = {
    cnmPicMain: useRef(),
    cnmName: useRef(),
    cnmDesc: useRef(),
  };
  const searchController = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      requestChatNewsMain(loginInfo.comp_cd);
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  // 알림톡 메인 정보 요청
  const requestChatNewsMain = (comp_cd) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsMainSelect.php",
      msgID: msgID,
      data: [],
      cnmSite: comp_cd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length > 0) {
          console.log(newData["returnData"][0]);

          if (!newData["returnData"][0].cnmPicMain) {
            newData["returnData"][0].cnmPicMain =
              newData["returnData"][0].COMP_LOGO;
          }
          setNewsMain(newData["returnData"][0]);
        }
      }
    });
  };

  const changedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
        cnmMainKey: news.cnmMainKey,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  const handleUploadImage = (data) => {
    if (!data.ret) {
      codeList.Modal.current.alert("오류가 발생했습니다. 다시 시도해주세요");
      return;
    }
    controller.cnmPicMain.current.value = `${data.fileName}?${data.realFileName}`;
    setNewsMain((prevState) => {
      return {
        ...prevState,
        cnmPicMain: `${data.fileName}?${data.realFileName}`,
      };
    });
  };

  const requestChatNewMainUpdate = () => {
    if (newsMain === undefined) {
      return;
    }

    if (controller.cnmName.current.value.trim() === "") {
      codeList.Modal.current.alert("알림톡 이름을 입력해주세요", () => {
        controller.cnmName.current.focus();
      });
      return;
    }
    if (controller.cnmDesc.current.value.trim() === "") {
      codeList.Modal.current.alert("알림톡 설명을 입력해주세요", () => {
        controller.cnmDesc.current.focus();
      });
      return;
    }

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsMainUpdate.php",
      msgID: msgID,
      data: [],
      ...newsMain,
      cnmPicMain: controller.cnmPicMain.current.value,
      cnmName: controller.cnmName.current.value,
      cnmDesc: controller.cnmDesc.current.value,
      isUser: true,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상 처리되었습니다.", () => {
            setNewsMain(socketMsg);
            changedNews(socketMsg);
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  const actionSearch = () => {
    setFilters({
      SEARCH_LEVEL: searchController.SEARCH_LEVEL.current.value,
      SEARCH_WORD: searchController.SEARCH_WORD.current.value,
    });
  };

  const addNewData = () => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_TALK_NEWS_MANAGE_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  if (newsMain === undefined) {
    return (
      <div className="container-fluid">
        <div className="p-5 bg-white rounded">
          <p className="h6 font-weight-bold mb-0 text-secondary text-center">
            알림톡 서비스가 등록되어있지 않습니다.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {newsMain && <TalkNewsRoomPreviewModal newsMain={newsMain} />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">
            <div className="card border-0">
              <div className="p-3 d-flex flex-column align-items-center text-dark">
                <div
                  className="rounded-circle border position-relative mb-3"
                  style={{
                    width: 200,
                    height: 200,
                    background: `url(${newsMain.cnmPicMain}) 50% 50% /cover no-repeat`,
                  }}
                >
                  <UploadImageToS3WithPhp
                    region="newsTalk/news"
                    callback={handleUploadImage}
                  >
                    <span
                      className="position-absolute btn btn-secondary"
                      style={{ bottom: 10, right: 10 }}
                    >
                      <i className="fa-regular fa-image" />
                    </span>
                  </UploadImageToS3WithPhp>

                  <input
                    className="d-none"
                    ref={controller.cnmPicMain}
                    defaultValue={newsMain ? newsMain.cnmPicMain : ""}
                  />
                </div>
                <span className="rounded-pill bg-light border py-1 px-2 font-weight-bold text-secondary font-size-08 mb-3">
                  {util.addCommas(newsMain.cnmSubscribe)}명 구독
                </span>
                <input
                  className="form-control border-0 bg-light text-center h6 font-weight-bold mb-2"
                  placeholder="알림톡 이름을 작성해주세요"
                  defaultValue={newsMain.cnmName}
                  maxLength={300}
                  spellCheck={false}
                  ref={controller.cnmName}
                />
                <textarea
                  className="form-control bg-light border-0 mb-2"
                  placeholder="알림톡 설명을 작성해주세요"
                  rows={5}
                  maxLength={300}
                  spellCheck={false}
                  defaultValue={newsMain.cnmDesc}
                  ref={controller.cnmDesc}
                />
                <button
                  className="btn btn-primary rounded-pill"
                  onClick={() => requestChatNewMainUpdate()}
                >
                  저장하기
                </button>
              </div>
            </div>
            <p className="font-size-08 mt-2 text-secondary">
              최종수정일 :{" "}
              {util.getDateToFormat(newsMain.cnmUpdateDate, "YYYY-MM-DD")}
            </p>
          </div>
          <div className="col-8 m-0 p-0">
            <div className="card border-0">
              <div className="h-100 p-3 d-flex flex-column">
                <div className="pb-3">
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      <button
                        className="btn btn-sm btn-primary mr-2"
                        data-toggle="modal"
                        data-target="#TalkNewsManageEdit"
                        onClick={addNewData}
                      >
                        <i className="fa-solid fa-plus mr-2" />
                        신규 작성
                      </button>
                      <button
                        className="btn btn-sm btn-outline-primary"
                        data-toggle="modal"
                        data-target="#TalkNewsRoomPreviewModal"
                      >
                        미리보기
                      </button>
                    </div>
                    <div>
                      <div className="input-group">
                        <select
                          className="form-control"
                          ref={searchController.SEARCH_LEVEL}
                        >
                          <option value="">검색 기준</option>
                          <option value="cntTitle">제목</option>
                          <option value="cntMSG">내용</option>
                        </select>
                        <input
                          className="form-control"
                          placeholder="검색어를 입력해주세요"
                          ref={searchController.SEARCH_WORD}
                          onKeyPress={(e) => {
                            if (e.charCode === 13) {
                              actionSearch();
                            }
                          }}
                        />
                        <button
                          className="input-group-text border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                          onClick={actionSearch}
                        >
                          검색
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <TalkNewsManageTable
                  newsMain={newsMain}
                  filters={filters}
                  loginInfo={loginInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
