import React, { useEffect, useRef, useState } from "react";
import SchedulePromotionManageTable from "./component/SchedulePromotionManageTable";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CommonLoader from "../CommonLoader";

const _mClassName = "SchedulePromotionManage";
const socket = new BuddibleSocket();
const util = new Utilities();

let ship_comp = [];
export default function SchedulePromotionManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const controller = {
    sp_USE_YN: useRef(),
    sp_LCL_FCL: useRef(),
    SEARCH_DATE_TYPE: useRef(),
    SEARCH_DATE_START: useRef(),
    SEARCH_DATE_END: useRef(),
    SEARCH_TYPE: useRef(),
    SEARCH_VALUE: useRef(),
  };
  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      requestShipCompData();
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const requestShipCompData = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/b_logo/JS_b_logo_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          ship_comp = newData["returnData"];
          setIsLoading(false);
        }
      }
    });
  };

  const addNewData = () => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const handleChangedFilter = () => {
    const filters = {
      sp_USE_YN: controller.sp_USE_YN.current.value,
      sp_LCL_FCL: controller.sp_LCL_FCL.current.value,
      SEARCH_DATE_TYPE: controller.SEARCH_DATE_TYPE.current.value,
      SEARCH_DATE_START: controller.SEARCH_DATE_START.current.value,
      SEARCH_DATE_END: controller.SEARCH_DATE_END.current.value,
      SEARCH_TYPE: controller.SEARCH_TYPE.current.value,
      SEARCH_VALUE: controller.SEARCH_VALUE.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <button
            data-toggle="modal"
            data-target="#SchedulePromotionManageEdit"
            className="btn btn-outline-primary ml-2"
            onClick={addNewData}
          >
            신규등록
          </button>
          <div className="btn-toolbar mb-3 justify-content-end" role="toolbar">
            <select
              className="custom-select form-control w-auto"
              ref={controller.sp_USE_YN}
              onChange={handleChangedFilter}
            >
              <option value="">-사용여부-</option>
              <option value="Y">사용</option>
              <option value="N">중지</option>
            </select>
            <select
              className="custom-select form-control w-auto ml-2"
              ref={controller.sp_LCL_FCL}
              onChange={handleChangedFilter}
            >
              <option value="">-운송분류-</option>
              <option value="FCL">FCL</option>
              <option value="LCL">LCL</option>
              <option value="AIR">AIR</option>
            </select>

            <div className="input-group ml-2">
              <select
                className="custom-select form-control w-auto"
                ref={controller.SEARCH_DATE_TYPE}
                onChange={handleChangedFilter}
              >
                <option value="">-검색일자-</option>
                <option value="sp_CREATE_DATE">생성일</option>
                <option value="sp_DATE_START">시작일</option>
                <option value="sp_DATE_END">종료일</option>
              </select>
              <input
                type="date"
                className="form-control"
                style={{ minWidth: 150 }}
                ref={controller.SEARCH_DATE_START}
                onChange={handleChangedFilter}
              />
              <span className="input-group-append border-right-0">
                <div className="input-group-text border-right-0">-</div>
              </span>
              <input
                type="date"
                className="form-control min-200"
                style={{ minWidth: 150 }}
                ref={controller.SEARCH_DATE_END}
                onChange={handleChangedFilter}
              />
            </div>

            <div className="input-group ml-2">
              <select
                className="custom-select form-control w-auto"
                ref={controller.SEARCH_TYPE}
                onChange={handleChangedFilter}
              >
                <option value="">-검색조건-</option>
                <option value="sp_COMP_CD">선사코드</option>
                <option value="sp_POL_CODE">출발항 코드</option>
                <option value="sp_POD_CODE">도착항 코드</option>
                <option value="sp_MEMO">메모</option>
              </select>
              <input
                type="text"
                className="form-control"
                placeholder="검색어를 입력해주세요"
                ref={controller.SEARCH_VALUE}
                style={{ minWidth: 200 }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    handleChangedFilter();
                  }
                }}
              />
              <button
                className="input-group-text btn btn-primary rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                onClick={handleChangedFilter}
              >
                검색
              </button>
            </div>
          </div>
        </div>
        {!isLoading && (
          <SchedulePromotionManageTable
            loginInfo={loginInfo}
            ship_comp={ship_comp}
          />
        )}
      </div>
    </div>
  );
}
