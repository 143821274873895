/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import $ from "jquery";

export default function AboutGBTS() {
  useEffect(() => {}, []);

  const openGBTSPDF = () => {
    window.open(
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/images/GBTS%EC%86%8C%EA%B0%9C%EC%84%9C+(VER+2021.12.29).pdf",
      "gbts_pdf"
    );
  };

  const openGBTSJoin = () => {
    window.open("http://home.gbts.co.kr/joinus/", "gbts_join");
  };

  return (
    <>
      <main>
        <dsiv className="white_bg py-5">
          <div className="container margin_60">
            <div className="main_title">
              <h2>
                <span>GBTS</span> 소개
              </h2>

              <p>화주 중심 수출입통관, 물류정보 통합 디지털 협업 ERP시스템</p>
            </div>
            <div className="row mt-3">
              <div className="col-4 d-flex align-items-center">
                <div>
                  <h3 className="font-weight-bold">
                    <span>수출업무화면</span>
                  </h3>
                  <ul>
                    <li>- 인보이스,팩킹리스트 작성 및 대량생성 툴 제공</li>
                    <li>- 선적, 통관, 배차, 보험등 간편한 업무의뢰</li>
                    <li>- 업무진행 상황의 실시간 모니터링</li>
                    <li>- 클라우드 기반의 편리하고 안전한 문서관리</li>
                  </ul>
                </div>
              </div>
              <div className="col-8 text-right">
                <a href="/images/export.png" target="_blank">
                  <img
                    className="w-100"
                    src="/images/export.png"
                    alt="export"
                  />
                  <span>
                    ※ 이미지 클릭시 큰 사이즈의 이미지를 볼 수 있습니다.
                  </span>
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-8">
                <a href="/images/import.png" target="_blank">
                  <img
                    className="w-100"
                    src="/images/import.png"
                    alt="import"
                  />
                  <span>
                    ※ 이미지 클릭시 큰 사이즈의 이미지를 볼 수 있습니다.
                  </span>
                </a>
              </div>
              <div className="col-4 d-flex align-items-center">
                <div>
                  <h3 className="font-weight-bold">
                    <span>수입업무화면</span>
                  </h3>
                  <ul>
                    <li>- 선박, 항공기의 도착예정일 및 실도착일시 확인</li>
                    <li>- 수입통관 절차 실시간 모니터링</li>
                    <li>- 배차정보와 화물이동 상태 확인</li>
                    <li>- 클라우드 기반의 편리하고 안전한 문서관리</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col py-5">
                <h4 className="text-center">
                  보다 상세한 내용을 원하신다면 PDF 소개자료를 참고하세요.
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <button className="btn_1 mr-2" onClick={openGBTSPDF}>
                  브로셔 다운받기
                </button>
                <button className="btn_1" onClick={openGBTSJoin}>
                  가입 및 이용안내
                </button>
              </div>
            </div>
          </div>
        </dsiv>
      </main>
    </>
  );
}
