import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import LogiStoryManageTable from "./LogiStoryManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "LogiStoryManage";
let typeList = [];
let typeObjList = {};
export default function LogiStoryManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const $searchText = useRef();
  const $searchType = useRef();
  const $comp_type = useRef();
  const $use_yn = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      selectTypeList();
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const selectTypeList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_notice_type_full_name_code_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          typeList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGISTORY_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_TYPE: $searchType.current.value,
      SEARCH_WORD: $searchText.current.value,
      COMP_TYPE: $comp_type.current.value,
      USE_YN: $use_yn.current.value,
    };
    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGISTORY_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      data-target="#LogistoryEditModal"
                      onClick={addNewData}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="flex-fill d-flex flex-row justify-content-end align-items-center mb-2 bm-sm-0 order-1 order-md-1 order-lg-1">
                    <select
                      className="custom-select form-control mr-2  mb-2"
                      ref={$use_yn}
                      onChange={() => {
                        searchFilterChanged();
                      }}
                      style={{ width: 150 }}
                    >
                      <option value="">-노출여부-</option>
                      <option value="Y">노출</option>
                      <option value="N">중지</option>
                    </select>
                    <select
                      className="custom-select form-control mr-2  mb-2"
                      ref={$comp_type}
                      onChange={() => {
                        searchFilterChanged();
                      }}
                      style={{ width: 150 }}
                    >
                      <option value="">-업체구분-</option>
                      <option value="A">항공사</option>
                      <option value="B">콘솔사</option>
                      <option value="F">선사</option>
                      <option value="O">기타</option>
                    </select>
                    <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                      <select
                        className="custom-select form-control"
                        ref={$searchType}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-분류-</option>
                        {typeList.length > 0 &&
                          typeList.map((type) => {
                            return (
                              <option
                                key={type.SmallType}
                                value={type.SmallType}
                              >
                                {type.FullName}
                              </option>
                            );
                          })}
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={(ref) => ($searchText.current = ref)}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <LogiStoryManageTable
              loginInfo={loginInfo}
              typeList={typeList}
              typeObjList={typeObjList}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
