import React from "react";

export default function MallOperationPolicy() {
  return (
    <>
      <h2>
        <strong>프레이스콥(Freiscop) 운영정책</strong>
      </h2>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h3>
        <strong>1. 사용 전 꼭 숙지하여 주세요.</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        본 운영정책은 주식회사 지비티에스(이하 &lsquo;회사&rsquo;)가 제공하는
        프레이스콥(Freiscop) 서비스를 운영함에 있어, 서비스 내에 발생할 수 있는
        문제 상황에 대하여 일관성 있게 대처하기 위하여 서비스 운영의 기준과
        여러분이 지켜주셔야 할 세부적인 사항이 규정되어 있습니다. 본 운영정책을
        지키지 않을 경우 불이익을 당할 수 있으니 주의 깊게 읽어 주시기 바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        본 운영정책에서 별도로 정의하지 않은 용어는 GBTS 통합서비스약관에서 정한
        용어의 정의에 따릅니다. 다만 본 운영정책에서 &ldquo;개별 서비스&rdquo;라
        함은 프레이스콥, 로지톡 등 브랜드 단위의 개별 서비스 내의 세부 하위
        서비스까지 포괄하여 지칭함을 유의하여 주시기 바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        회사는 서비스와 관련된 정책을 개선하고자 여러분과의 지속적인 의견 교환을
        통해 합리적인 운영정책을 세울 수 있도록 노력하고 있으며, 본 운영정책이
        변경되는 경우 최소 7일 전에 공지사항을 통해 공지하도록 하겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>
          2. 여러분의 쾌적한 서비스 이용을 위해 운영정책을 적용하고 있습니다.
        </strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        회사는 여러분의 쾌적한 서비스 이용을 위해 운영정책에 근거하여 서비스를
        운영하고 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        서비스 이용 중 다른 이용자의 운영정책 위반 행위로 인해 피해를 입으신
        경우, 여러분은 이를 회사에 제보하여 운영정책의 적용을 요청할 수 있으며,
        회사는 신고 내용을 확인하여 운영정책에 따른 제재 조치를 취할 수
        있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        또한, 신고가 없더라도, 회사는 관련 법령 또는 약관에 위배되거나 본
        운영정책에서 금지하는 활동이 발견된 경우, 운영정책에 따른 제재 조치를
        취할 수 있습니다. 그러나, 서비스 내 이용자들 간에 발생하는 분쟁 및
        이용자 스스로의 과실로 일어난 피해에 대해서는 개입하거나 조치하지
        않습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        신고 또는 기타 방법으로 운영정책에 위반 된 내용이 발견되어 제재가
        진행되는 경우, 회사는 게시자에게 서비스 내 알림, 메일 등의 방법으로 제재
        내용 등을 최대한 신속히 알리도록 하겠습니다. 다만, 다른 이용자의 긴급한
        보호가 필요하거나, 제재 내용 전달이 불가능한 등의 경우에는 그러하지 않을
        수 있으며, 검토 결과를 신고자에게 통지할 의무가 있는 것은 아니라는 점을
        참고해 주시기 바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        여러분이 운영정책의 적용 결과가 불만족스러울 경우 고객센터를 통해 이의를
        신청할 수 있으며, 회사는 접수된 이의제기를 검토하여 수용 여부를 회신
        드리겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>3. 다음과 같은 활동은 금지하고 있습니다.</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        ① 프레이스콥 상품등록 및 관련서비스 이용시 금지하는 활동
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (1) 타인 및 타사가 등록한 상품의 상품금액과 제공하는 서비스 내용을
        완전히 동일하게 지속적으로 복사하여 게시하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (2) 다른 상품으로 보이기 위하여 동일한 운송방법, 동일한 구간에 대하여
        서비스금액을 조금씩 차등을 두어 복수로 등록하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (3) 실제로 판매가 불가능할 정도의 낮은 운임을 지속적으로 등록하여 화주를
        현혹하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (4) 계정을 타인에게 판매,양도,대여하거나 타인어게 이용을 허락 또는 이를
        시도하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (5) 타인의 계정을 취득하기 위해 구매&middot;양수&middot;교환을
        시도하거나 이를 타인에게 알선하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (6) 타인을 기망하여 타인의 계정을 탈취하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (7) 기타 정상적인 서비스 이용으로 볼 수 없는 비정상적인 상품등록 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (8) 타인 및 타사가 등록한 상품의 평가를 훼손하기 위하여 평점 및 후기
        작성란에 서비스 수요자를 가장하여 낮은별점 또는 악의적인 후기를 작성하는
        행위
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <p style={{ paddingLeft: "30px" }}>
        ② 게시판 서비스 이용 시 금지하는 활동
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (1)불법적인 사행성&middot;도박 사이트를 홍보하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (2)게임 아이템, 점수, 계정 등 유무형의 결과물을 판매, 구매, 환전하거나
        요청하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (3)불법 제품 또는 인터넷에서 판매 금지된 물품을 판매하거나 홍보하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (4)범법 행위에 대한 동기 부여 및 실행에 도움이 되는 정보를 제공하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (5)악성코드, 바이러스 등의 프로그램을 설치&middot;유포하여 서비스 이용을
        저해하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (6)컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을
        방해&middot;파괴하거나, 할 수 있는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (7)타인의 개인정보를 탈취&middot;유포&middot;거래하려는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (8)방송&middot;음원&middot;영화&middot;만화&middot;사진&middot;영상&middot;게시물
        등 타인의 저작물을 당사자의 동의 없이 공유하거나, 불법적인 경로로 획득할
        수 있는 정보나 방법을 제공하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (9)타인의 권리에 속하는 상표권, 의장권 등을 무단으로 침해하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (10) 타인에게 성적 수치심이나 불쾌감&middot;혐오감을 유발할 수 있는
        내용을 게시하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (11) 동일한 내용을 동일 개별 서비스 또는 여러 개별 서비스에 반복적으로
        등록하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (12) 서비스의 명칭 또는 회사의 임직원이나 운영진을 사칭하여 다른
        이용자를 속이거나 이득을 취하는 등 피해와 혼란을 주는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (13) 욕설&middot;비속어&middot;은어 등의 사용 및 그 외 상식과 사회
        통념에 반하는 비정상적인 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (14) 언론사의 명의나 언론사의 직책 등을 사칭 또는 도용하여 기사 형태를
        갖춘 게시물 중 그 내용이 허위로 판단되는 게시물을 게시하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (15) 회사가 허용하지 않은 악의적인 방법으로 서비스를 이용하는 등
        서비스의 정상적인 운영을 방해하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>&nbsp;</p>
      <h3>
        <strong>4. 계정과 서비스 이용이 제한될 수 있습니다.</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        여러분의 활동이 관련 법령에 위배되거나, 관련 약관 또는 운영정책에 위반될
        경우 회사는 다른 이용자를 피해로부터 보호하기 위해 여러분의 계정과
        서비스 이용을 제한할 수 있습니다. 또한 운영정책에 구체적으로 해당하지
        않는 사항이라고 하더라도 건전한 서비스 환경 제공에 악영향을 끼치거나
        다른 이용자에게 불편을 끼치는 행위도 이용 제한될 수 있다는 점을 참고해
        주시기 바랍니다. 특히 비정상적인 로그인이라고 판단되거나, 약관이나
        운영정책을 위반할 가능성이 있는 특별한 이용환경 및 이용패턴이 감지되는
        경우 이용자들의 안전한 활동을 보호하기 위하여 이와 동일하거나 유사한
        행위를 제한하고 금지하는 이용자 보호조치가 취하여 질 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        다만, 여러분은 이용제한과 관련하여 조치 결과가 불만족스러울 경우
        고객센터를 통해 이의를 제기할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        계정과 서비스 이용 제한에 대한 구체적인 내용은 다음 내용을 참고해 주시기
        바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>①계정 이용 제한</p>
      <p style={{ paddingLeft: "60px" }}>
        (1)계정의 생성이나 이용 과정에서 관련 법령, 관련 약관 또는 운영정책 위반
        사항이 발견된 경우, 계정의 생성&middot;이용&middot;탈퇴가 제한될 수
        있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (2)계정을 비정상적으로 생성&middot;이용&middot;탈퇴하려는 시도가 발견된
        경우, 해당 계정의 이용 및 탈퇴를 제한하거나, 해당 계정의 비정상적 생성이
        계속 될 수 없도록 계정 생성이 제한 될 수도 있으며, 필요할 경우 해당
        계정을 삭제하고 재가입을 제한하는 등의 적절한 제한을 할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (3)이용 제한은 한시적 제한에서 영구적 제한으로 단계적으로 제한되지만,
        즉시 영구적으로 제한되는 경우도 있다는 점을 꼭 잊지 말아 주시기
        바랍니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (4)여러분의 계정을 타인이 비정상적으로 이용하려는 시도가 발견될 경우,
        타인이 여러분의 계정을 무단으로 사용하는 것을 막기 위하여 비밀번호 입력
        및 추가적인 본인 확인 절차를 거치도록 할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (5)여러분의 계정을 타인이 무단으로 사용하는 것을 예방하기 위해서는,
        타인이 여러분 계정의 비밀번호를 쉽게 알 수 없도록 여러분의 주기적인
        관리가 중요합니다. 비밀번호 관리를 소홀히 하셔서 발생하는 불이익은
        안타깝지만 회사가 책임질 수 없고 여러분께서 부담하실 수밖에 없습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>②서비스 이용 제한</p>
      <p style={{ paddingLeft: "60px" }}>
        (1)개별 서비스 이용 과정에서 관련 법령, 관련 약관 또는 운영정책 위반
        사항이 발견된 경우, 해당 개별 서비스의 이용이 제한될 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        (2)위반 사항이 발견된 경우 다른 이용자가 문제 게시물을 볼 수 없도록
        게시물의 노출이 제한되거나, 삭제될 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
    </>
  );
}
