import React, { useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";
import axios from "axios";

import BasaHistoryManageTable from "./component/BasaHistoryManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "BasaHistoryManage";

export default function BasaHistoryManage(props) {
  const { pagesOpen, loginInfo, compList } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [selectedComp, setSelectedComp] = useState([]);
  const SEARCH_LEVEL = useRef();
  const SEARCH_WORD = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);
  const exportExcel = () => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//www.gbts.co.kr/backside/basa/JS_basaResultHistoryExportExcel.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              SEARCH_LEVEL: SEARCH_LEVEL.current.value,
              SEARCH_WORD: SEARCH_WORD.current.value,
              brhCOMP_CD:
                selectedComp.length > 0 ? selectedComp[0]?.COMP_CD : "",
            },
          })
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([response.data], {
                  type: response.headers["content-type"],
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `BASA기업조회이력_${util.todayDate("YYYYMMDD")}.xls`
              );
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              codeList.Modal.current.alert(
                "오류가 발생하였습니다. 다시 시도해주세요"
              );
            });
        }
      }
    );
  };

  const actionSearch = () => {
    const filters = {
      SEARCH_LEVEL: SEARCH_LEVEL.current.value,
      SEARCH_WORD: SEARCH_WORD.current.value,
      brhCOMP_CD: selectedComp.length > 0 ? selectedComp[0]?.COMP_CD : "",
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ERP_BASA_HISTORY_CHANGED_FILTER,
      _mClassName,
      filters,
      () => {}
    );
  };
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid h-100">
      <div className="card">
        <div className="card-header">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => exportExcel()}
              >
                엑셀 다운로드
              </button>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div className="mr-2">
                <Select
                  placeholder="업체를 선택해주세요"
                  className="bg-white border rounded"
                  style={{ minWidth: 200, height: 35 }}
                  multi={false}
                  clearable={true}
                  values={selectedComp}
                  options={compList}
                  sortBy={"COMP_CD"}
                  valueField={"COMP_NM"}
                  labelField={"COMP_NM"}
                  onChange={(value) => {
                    setSelectedComp(value);
                  }}
                  itemRenderer={({ item, methods }) => {
                    const isSelected =
                      selectedComp.findIndex(
                        (data) => data.COMP_CD === item.COMP_CD
                      ) > -1;
                    return (
                      <div key={item.COMP_CD}>
                        <div
                          className={`p-2 border-bottom cursor_pointer_bg_light ${
                            isSelected ? "bg-light" : ""
                          }`}
                          onClick={() => {
                            methods.addItem(item);
                          }}
                        >
                          <span className="font-weight-bold text-secondary">
                            [{item.COMP_CD}]
                          </span>{" "}
                          {item.COMP_NM}
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <div className="input-group">
                  <select className="form-control" ref={SEARCH_LEVEL}>
                    <option value="brhUSER_ID">사용자 아이디</option>
                    <option value="brhBizno">사업자등록번호</option>
                  </select>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    ref={SEARCH_WORD}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) actionSearch();
                    }}
                  />
                  <button
                    className="btn btn-primary input-group-text border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                    onClick={() => actionSearch()}
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BasaHistoryManageTable />
      </div>
    </div>
  );
}
