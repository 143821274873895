/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import ScheduleManageEdit from "./ScheduleManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
    "& button": {
      borderRadius: "100%",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const _mClassName = "ScheduleManageTable";

const headCells = [
  {
    field: "sm_UID",
    headerName: "관리번호",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sm_EXP_IMP",
    headerName: "구분",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let expImp = params.data === "EXP" ? "수출" : "수입";
      return (
        <span className="text-truncate text-wrap text-break">{expImp}</span>
      );
    },
  },
  {
    field: "sm_FCL_LCL",
    headerName: "운송방식",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sm_FWD_NM",
    headerName: "포워더명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },

  {
    field: "sm_VESSEL",
    headerName: "선박 및 항차",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sm_VOY}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_DOC_CLOSE",
    headerName: "서류마감",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "sm_CARGO_CLOSE",
    headerName: "화물마감",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "sm_ORIGIN",
    headerName: "출발지 및 일자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sm_DEPARTURE_DATE}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_DEST",
    headerName: "도착지 및 일자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sm_ARRIVE_DATE}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_USE_YN",
    headerName: "노출여부",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">
            {params.data === "Y" ? "사용" : "중지"}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_Function",
    headerName: "기능",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#ScheduleManageEdit"
            onClick={(e) => params.openEdit(e, params)}
            title={"수정"}
            aria-label={"수정"}
          >
            <i className="fa-solid fa-pen" />
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-toggle="modal"
            onClick={(e) => params.removeData(e, params)}
            title={"삭제"}
            aria-label={"삭제"}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        </span>
      );
    },
  },
];

let filters = {};

export default function ScheduleManageTable({
  isServerPaging = true,
  isServerSort = true,
  searchCodeList,
  handleDeleteCount,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sm_IDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "sm_IDX",
      dir: "desc",
    },
  ]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = { ...n };
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    const msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          handleDeleteCount(parseInt(newData.pageSize, 10));
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeData(e, params) {
    editModal.current.removeData(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEdit={openEdit}
                removeData={removeData}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <ScheduleManageEdit
        ref={editModal}
        requestUserSelect={requestUserSelect}
        searchCodeList={searchCodeList}
      />
    </TableContainer>
  );
}

ScheduleManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const {
    row,
    isMultiSelect = false,
    requestUserSelect,
    requestUserUpdate,
    openEdit,
    removeData,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <ScheduleDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                    requestUserUpdate={requestUserUpdate}
                    openEdit={openEdit}
                    removeData={removeData}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

const ScheduleDetail = (props) => {
  let { rowData, requestUserSelect, setOpen } = props;
  return (
    <tr className="container" id={rowData.sr_IDX}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">상세정보</div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">수출입 구분</h6>{" "}
                <p>
                  {rowData.sm_EXP_IMP === "EXP"
                    ? "수출"
                    : rowData.sm_EXP_IMP === "IMP"
                    ? "수입"
                    : ""}
                </p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">운송방식</h6>{" "}
                <p>{rowData.sm_FCL_LCL}</p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">포워더</h6>{" "}
                <p>{rowData.sm_FWD_NO}</p>
                <p>{rowData.sm_FWD_NM}</p>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">선박 및 항차</h6>{" "}
                <p>{rowData.sm_VESSEL}</p>
                <p>{rowData.sm_VOY}</p>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">서류마감</h6>{" "}
                <p>{rowData.sm_DOC_CLOSE}</p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">화물마감</h6>{" "}
                <p>{rowData.sm_CARGO_CLOSE}</p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">출발지 및 일자</h6>{" "}
                <p>
                  {rowData.sm_ORIGIN} - {rowData.sm_POL_CODE}
                </p>
                <p>{rowData.sm_DEPARTURE_DATE}</p>
              </div>

              <div className="col-6">
                <h6 className="font-weight-bold">도착지 및 일자</h6>{" "}
                <p>
                  {rowData.sm_DEST} - {rowData.sm_POD_CODE}
                </p>
                <p>{rowData.sm_ARRIVE_DATE}</p>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">라인</h6>{" "}
                <p>{rowData.sm_LINE}</p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <h6 className="font-weight-bold">지역</h6>{" "}
                <p>{rowData.sm_REGION}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold">주요 안내사항</h6>{" "}
                {rowData.sm_REMARK ? (
                  <p
                    className="border rounded p-2"
                    dangerouslySetInnerHTML={{ __html: rowData.sm_REMARK }}
                  />
                ) : (
                  <p className="border rounded p-2">-</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
