import React from "react";
export default function TermsOfUse() {
  return (
    <>
      <h2>
        <strong>&ldquo;GBTS&rdquo; </strong>
        <strong>이용약관</strong>
      </h2>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h3>
        <strong>제 1 장 환영합니다!</strong>
      </h3>
      <h3>
        <strong>제 1 조 (목적)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        주식회사 지비티에스(이하 &lsquo;회사&rsquo;)가 제공하는 서비스를 이용해
        주셔서 감사합니다. 회사는 여러분이 다양한 인터넷과 모바일 서비스를 좀 더
        편리하게 이용할 수 있도록 회사 또는 관계사의 개별 서비스에 모두 접속
        가능한 통합로그인계정 체계를 만들고 그에 적용되는 'GBTS계정 약관(이하
        '본 약관')을 마련하였습니다. 본 약관은 여러분이 GBTS계정 서비스를
        이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인
        사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기
        바랍니다.
      </p>

      <h3>
        <strong>제 2 조 (약관의 효력 및 변경)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 본 약관의 내용은&nbsp;GBTS 웹사이트&nbsp;또는 개별 서비스의 화면에
        게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 여러분 모두에게 그
        효력이 발생합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 회사는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을
        변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자
        15일 전부터 여러분에게 서비스 공지사항에서 공지 또는 통지하는 것을
        원칙으로 하며, 피치 못하게 여러분에게 불리한 내용으로 변경할 경우에는 그
        시행일자 30일 전부터 GBTS계정에 등록된 이메일 주소로 이메일(이메일주소가
        없는 경우 서비스 내 전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 등의
        별도의 전자적 수단) 발송 또는 여러분이 등록한 휴대폰번호로 문자메시지
        발송하는 방법 등으로 개별적으로 알려 드리겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        3. 회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터
        개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로
        본다는 뜻을 명확하게 고지하였음에도 여러분의 의사표시가 없는 경우에는
        변경된 약관을 승인한 것으로 봅니다. 여러분이 개정약관에 동의하지 않을
        경우 여러분은 이용계약을 해지할 수 있습니다.
      </p>
      <h3 style={{ paddingLeft: "30px" }}>&nbsp;</h3>
      <h3>
        <strong>제 3 조 (약관 외 준칙)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 회사가 정한 개별
        서비스의 이용약관, 운영정책 및 규칙 등(이하 &lsquo;세부지침&rsquo;)의
        규정에 따릅니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 4 조 (용어의 정의)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        1. GBTS계정: 회사 또는 관계사가 제공하는 개별 서비스를 하나의
        로그인계정과 비밀번호로 회원 인증, 회원정보 변경, 회원 가입 및 탈퇴 등을
        관리할 수 있도록 회사가 정한 로그인계정 정책을 말합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 회원: GBTS계정이 적용된 개별 서비스 또는 GBTS계정 웹사이트에서 본
        약관에 동의하고, GBTS계정을 이용하는 회사 및 회사로부터 사용자 아이디를
        부여받은자를 말합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        3. 관계사: 회사와 제휴 관계를 맺고 GBTS계정을 공동 제공하기로 합의한
        법인을 말합니다. 개별 관계사는&nbsp;
        <a href="https://www.kakaocorp.com/kakao/subsidiaryCompany">
          GBTS 웹사이트
        </a>
        에서 확인할 수 있고 추후 추가/변동될 수 있으며 관계사가 추가/변동될
        때에는 GBTS 웹사이트에 변경 사항을 게시합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        4. 개별 서비스: GBTS계정을 이용하여 접속 가능한 회사 또는 관계사가
        제공하는 서비스를 말합니다. 개별 서비스는 추후 추가/변동될 수 있으며
        서비스가 추가/변동될 때에는&nbsp;GBTS웹사이트에 변경 사항을 게시합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        5. GBTS계정 웹사이트: 회원이 온라인을 통해 GBTS계정 정보를 조회 및
        수정할 수 있는 인터넷 사이트를 말합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        6. GBTS계정 정보 : GBTS계정을 이용하기 위해 회사가 정한 필수 내지 선택
        입력 정보로서 GBTS 웹사이트 또는 개별 서비스 내 GBTS계정 설정 화면을
        통해 정보 확인, 변경 처리 등을 관리할 수 있는 회원정보 항목을 말합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 2 장 GBTS계정 이용계약</strong>
      </h3>
      <h3>
        <strong>제 5 조 (계약의 성립)</strong>
      </h3>
      <ol>
        <li>
          GBTS계정 이용 신청은 개별 서비스 또는 GBTS계정 웹사이트 회원가입
          화면에서 여러분이 GBTS계정 정보에 일정 정보를 입력하는 방식으로
          이루어집니다.
        </li>
        <li>
          GBTS계정 이용계약은 여러분이 본 약관의 내용에 동의한 후 본 조
          제1항에서 정한 이용신청을 하면 회사가 입력된 일정 정보를 인증한 후
          가입을 승낙함으로써 체결됩니다.
        </li>
      </ol>

      <h3>
        <strong>제 6 조 (GBTS계정 이용의 제한)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 제5조에 따른 가입 신청자에게 회사는 원칙적으로 GBTS계정의 이용을
        승낙합니다. 다만, 회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지
        승낙을 유보하거나 승낙하지 않을 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-1.회사가 본 약관 또는 세부지침에 의해 귀 사의 GBTS계정을 삭제하였던
        경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-2.여러분이 다른 회사나 개인의 상호나 이름, 이메일 주소 등 정보를
        이용하여 GBTS계정을 생성하려 한 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-3.GBTS계정 생성 시 필요한 정보를 입력하지 않거나 허위의 정보를 입력한
        경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-4.제공 서비스 설비 용량에 현실적인 여유가 없는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-5.서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-6.기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-7.회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간에
        이용계약을 임의로 해지하고 재이용을 신청하는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-8.기타 관련법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는
        경우
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 만약, 여러분이 위 조건에 위반하여 GBTS계정을 생성한 것으로 판명된
        때에는 회사는 즉시 여러분의 GBTS계정 이용을 정지시키거나 GBTS계정을
        삭제하는 등 적절한 제한을 할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 3 장 GBTS계정 이용</strong>
      </h3>
      <h3>
        <strong>제 7 조 (GBTS계정 제공)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 회사가 개별 서비스와 연동하여 GBTS계정에서 제공하는 서비스(이하
        &ldquo;GBTS계정 서비스&rdquo; 또는 &ldquo;서비스&rdquo;) 내용은 아래와
        같습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-1.통합로그인 : GBTS계정이 적용된 개별 서비스에서 하나의 GBTS계정과
        비밀번호로 로그인할 수 있는 통합 회원 인증 서비스를 이용할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-2.SSO(Single Sign On): 웹브라우저나 특정 모바일 기기에서 GBTS계정 1회
        로그인으로 여러분이 이용 중인 개별 서비스간 추가 로그인 없이 자동 접속
        서비스를 이용할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-3.GBTS계정 정보 통합 관리 : 개별 서비스 이용을 위해 GBTS계정 정보를
        통합 관리합니다. 또한, 여러분이 이용하고자 하는 개별 서비스의 유형에
        따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있고, 이를
        GBTS계정 정보로 저장합니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>1-4.기타 회사가 제공하는 서비스</p>
      <p style={{ paddingLeft: "30px" }}>
        2. 회사는 더 나은 GBTS계정 서비스의 제공을 위하여 여러분에게 서비스의
        이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를
        서비스화면 내에 표시하거나 여러분의 이메일로 전송할 수 있습니다. 광고성
        정보 전송의 경우에는 사전에 수신에 동의한 경우에만 전송합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 8 조 (GBTS계정 서비스의 변경 및 종료)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 회사는 GBTS계정 서비스를 365일, 24시간 쉬지 않고 제공하기 위하여
        최선의 노력을 다합니다. 다만, 아래 각 호의 경우 GBTS계정 서비스의 전부
        또는 일부를 제한하거나 중지할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-1.GBTS계정 서비스용 설비의 유지&middot;보수 등을 위한 정기 또는 임시
        점검의 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-2.정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 GBTS계정
        이용에 지장이 있는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-3.관계사와의 계약 종료, 정부의 명령/규제, 서비스/회원 정책 변경 등
        회사의 제반 사정으로 GBTS계정 서비스를 유지할 수 없는 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-4.기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 전항에 의한 GBTS계정 서비스 중단의 경우에는 미리 제14조에서 정한
        방법으로 여러분에게 통지 내지 공지하겠습니다. 이 때 원만한 서비스 및
        정책 변경 등을 위하여 서비스 이용 시 재로그인 또는 추가적인 동의 절차
        등이 필요할 수 있습니다. 다만, 회사로서도 예측할 수 없거나 통제할 수
        없는 사유(회사의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로
        서비스가 중단된 경우에는 사전 통지 내지 공지를 할 수 없습니다. 이러한
        경우에도 회사가 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를
        복구하도록 노력하되, 2시간 이상 복구가 지연될 시 GBTS&nbsp;
        <a href="https://www.kakao.com/notices?lang=ko">서비스 공지사항</a>에
        게시하여 알려 드리겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 9 조 (GBTS계정 관리)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. GBTS계정은 본인만 이용할 수 있으며, 다른 사람이 여러분의 GBTS계정을
        이용하도록 허락할 수 없습니다. 그리고 여러분은 다른 사람이 여러분의
        GBTS계정을 무단으로 사용할 수 없도록 직접 비밀번호를 관리하여야 합니다.
        회사는 다른 사람이 여러분의 GBTS계정을 무단으로 사용하는 것을 막기
        위하여 비밀번호 입력 및 추가적인 본인 확인 절차를 거치도록 할 수
        있습니다. 만약 무단 사용이 발견된다면, 고객센터를 통하여 회사에게
        알려주시기 바라며, 회사는 무단 사용을 막기 위한 방법을 여러분에게
        안내하도록 하겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 여러분은 GBTS계정 SYSTEM.GBTS.CO.KR 서비스 내 기초설정 화면을 통하여
        여러분의 GBTS계정 정보를 열람하고 수정할 수 있습니다. 다만, GBTS계정
        서비스의 제공 및 관리를 위해 필요한 GBTS계정 아이디 등 일부 정보는
        수정이 불가능할 수 있으며, 수정하는 경우에는 추가적인 본인 확인 절차가
        필요할 수 있습니다. 여러분이 이용 신청 시 알려주신 내용에 변동이 있을
        때, 직접 수정하시거나 이메일, 고객센터를 통하여 회사에 알려 주시기
        바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        3. GBTS 계정이 생성되어 제공된 경우, 회원사 사용자 계정을 신규로
        생성하거나 수정, 삭제등 관리의무는 회원사 본인에게 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        4. 여러분이 GBTS계정 정보를 적시에 수정하지 않아 발생하는 문제에 대하여
        회사는 책임을 부담하지 아니합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 4 장 계약당사자의 의무</strong>
      </h3>
      <h3>
        <strong>제 10 조 (회원의 의무)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 여러분이 GBTS계정 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안
        됩니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-1.이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 GBTS계정
        및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나
        명의자의 허락 없이 문자메시지(SMS) 인증 등을 수행하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-2.타사 또는 타인의 명예를 손상시키거나 불이익을 주는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-3.게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-4.회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-5.공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을
        타인에게 유포하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-6.GBTS계정 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을
        유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-7.GBTS계정 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해할 수
        있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는
        스팸메일(Spam Mail)을 전송하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-8.회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사,
        수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는
        행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를
        복제, 분해 또는 모방하거나 기타 변형하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-9.타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-10.다른 회원의 개인정보를 수집, 저장, 공개하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-11.자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
        목적으로 허위의 정보를 유통시키는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-12.윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-13.수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상
        또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을
        방해하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-14.관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
        프로그램 포함)의 전송 또는 게시 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-15.회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는
        타인의 명의를 도용하여 글을 게시하거나 E-mail, 메시지 등을 발송하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>
        1-16.컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
        파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일,
        프로그램을 포함하고 있는 자료를 게시하거나 E-mail, 메시지 등으로
        발송하는 행위
      </p>
      <p style={{ paddingLeft: "60px" }}>1-17.기타 불법한 행위</p>
      <p style={{ paddingLeft: "30px" }}>
        2. 여러분은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게
        양도&middot;증여할 수 없으며, 담보로 제공할 수 없습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        3. 혹시라도 여러분이 관련 법령, 회사의 모든 약관 또는 정책을 준수하지
        않는다면, 회사는 여러분의 위반행위 등을 조사할 수 있고, 여러분의
        계정・서비스 이용을 잠시 또는 계속하여 중단하거나, 재가입에 제한을 둘
        수도 있습니다. 또한 여러분이 서비스와 관련된 설비의 오작동이나 시스템의
        파괴 및 혼란을 유발하는 등 서비스 제공에 악영향을 미치거나 안정적 운영을
        심각하게 방해한 경우, 회사는 이러한 위험 활동이 확인된 여러분의 계정들에
        대하여 이용제한을 할 수 있습니다. 다만, 여러분은 이용제한과 관련하여
        조치 결과가 불만족스러울 경우 고객센터를 통해 이의를 제기할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        4. 본 조에서 정한 사항 및 그 밖에 GBTS계정 서비스의 이용에 관한 자세한
        사항은&nbsp;
        <a href="https://www.kakao.com/policy/oppolicy?lang=ko">
          GBTS 운영정책
        </a>
        &nbsp;등을 참고해 주시기 바랍니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 13 조 (개인정보의 보호)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        여러분의 개인정보의 안전한 처리는 회사에게 있어 가장 중요한 일 중
        하나입니다. 여러분의 개인정보는 서비스의 원활한 제공을 위하여 여러분이
        동의한 목적과 범위 내에서만 이용됩니다. 법령에 의하거나 여러분이 별도로
        동의하지 아니하는 한 회사가 여러분의 개인정보를 제3자에게 제공하는 일은
        결코 없으므로, 안심하셔도 좋습니다. 회사가 여러분의 개인정보를 안전하게
        처리하기 위하여 기울이는 노력이나 기타 자세한 사항은&nbsp;
        <a href="https://www.kakao.com/policy/privacy">GBTS 개인정보처리방침</a>
        을 참고하여 주십시오.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 14 조 (회원에 대한 통지 및 공지)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        회사는 여러분과의 의견 교환을 소중하게 생각합니다. 여러분은
        언제든지&nbsp;<a href="https://cs.kakao.com/">고객센터</a>에 이메일 또는
        전화를 통하여 의견을 개진할 수 있습니다. 서비스 이용자 전체에 대한
        공지는 칠(7)일 이상&nbsp;
        <a href="https://www.kakao.com/notices">서비스 공지사항</a>란에
        게시함으로써 효력이 발생합니다. 여러분에게 중대한 영향을 미치는 사항의
        경우에는 GBTS계정에 등록된 이메일 주소로 이메일(이메일주소가 없는 경우
        로지톡 서비스 내 메시지 발송, 서비스 내 알림을 띄우는 등의 별도의 전자적
        수단) 발송 또는 여러분이 등록한 휴대폰번호로 문자메시지 발송하는 방법
        등으로 개별적으로 알려 드리겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <h3>
        <strong>제 5 장 이용계약 해지 등</strong>
      </h3>

      <h3>
        <strong>제 15 조 (이용계약 해지)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 여러분이 GBTS계정 이용을 더 이상 원치 않는 때에는 언제든지 서비스 내
        제공되는 메뉴를 이용하여 이용계약의 해지 신청을 할 수 있으며, 회사는
        법령이 정하는 바에 따라 신속히 처리하겠습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 이용계약이 해지되면 법령 및 개인정보 처리방침에 따라 여러분의 정보를
        보유하는 경우를 제외하고는 여러분의 GBTS계정 정보 및 GBTS계정으로
        이용하였던 개별 서비스 데이터는 삭제됩니다. 다만, 여러분이 개별 서비스
        내에서 작성한 게시물 등 모든 데이터의 삭제와 관련한 사항은 개별 서비스의
        약관에 따릅니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        3. 이용계약이 해지된 경우라도 여러분은 다시 회사에 대하여 이용계약의
        체결을 신청할 수 있습니다.
      </p>
      <h3>
        <strong>제 16 조 (손해배상)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
        명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다.
        또한, 회사는 CP(Contents Provider)가 제공하거나 회원이 작성하는 등의
        방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는
        보증을 하지 않으며, 회사의 과실 없이 발생된 여러분의 손해에 대하여는
        책임을 부담하지 아니합니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>
        2. 회사는 회사의 과실로 인하여 여러분이 손해를 입게 될 경우 본 약관 및
        관련 법령에 따라 여러분의 손해를 배상하겠습니다. 다만 회사는 회사의 과실
        없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한
        회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해,
        징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-1.천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-2.여러분의 귀책사유로 서비스 이용에 장애가 발생한 경우
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-3.서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-4.제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써
        발생하는 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-5.제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
        방해함으로써 발생하는 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-6.제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-7.전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등
        제3자가 서비스를 이용하는 과정에서 발생된 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>
        2-8.기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
      </p>
      <p style={{ paddingLeft: "60px" }}>&nbsp;</p>
      <h3>
        <strong>제 17 조 (분쟁의 해결)</strong>
      </h3>
      <p style={{ paddingLeft: "30px" }}>
        본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스
        이용과 관련하여 회사와 여러분 간에 분쟁이 발생하면 이의 해결을 위해
        성실히 협의할 것입니다. 그럼에도 불구하고 해결되지 않으면 민사소송법상의
        관할법원에 소를 제기할 수 있습니다.
      </p>
      <p style={{ paddingLeft: "30px" }}>공고일자 : 2022년 1월 24일</p>
      <p style={{ paddingLeft: "30px" }}>시행일자 : 2022년 1월 24일</p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
    </>
  );
}
