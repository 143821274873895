import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import PickPlaceManageTable from "./PickPlaceManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const _mClassName = "PickPlaceManage";
export default function PickPlaceManage(props) {
  const { pagesOpen } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $searchText = useRef();
  const $searchLevel = useRef();
  const $useYN = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);
  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_PICK_PLACE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_PICK_PLACE_MANAGE_FILTER_CHANGED,
      _mClassName,
      {
        SEARCH_WORD: $searchText.current.value,
        SEARCH_LEVEL: $searchLevel.current.value,
        ppUseYN: $useYN.current.value,
      },
      () => {}
    );
  };
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn  btn-outline-primary "
                      data-toggle="modal"
                      data-target="#editModal"
                      onClick={addNewData}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="flex-fill d-flex flex-row justify-content-end align-items-center mb-2 bm-sm-0 order-1 order-md-1 order-lg-1">
                    <select
                      className="custom-select form-control ml-2"
                      ref={$useYN}
                      onChange={() => {
                        searchFilterChanged();
                      }}
                      style={{ width: 150 }}
                    >
                      <option value="">-사용여부-</option>
                      <option value="Y">사용</option>
                      <option value="N">미사용</option>
                    </select>

                    <div className="input-group ml-2">
                      <select
                        className="custom-select form-control"
                        ref={$searchLevel}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="ppIDX">관리번호</option>
                        <option value="ppCode">코드</option>
                        <option value="ppDescription">상세</option>
                        <option value="ppRemark">비고</option>
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={(ref) => ($searchText.current = ref)}
                      />

                      <button
                        className="input-group-text btn btn-primary"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <PickPlaceManageTable isServerPaging={true} isServerSort={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
