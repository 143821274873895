/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { code } from "../../lib/aes/Base64";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "SetUserProfile";

export default function SetUserProfile(props) {
  let { changeProfile } = props.params;
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(
    "https://www.gbts.co.kr/images/user_pic-50x50.png"
  );
  const [nameCard, setNameCard] = useState("");
  const [userData, setUserData] = useState({});

  const $controllers = {
    USER_PIC: useRef(),
    USER_NICK: useRef(),
    USER_TEAM_NM: useRef(),
    USER_PART_NM: useRef(),
    USER_PW: useRef(),
    USER_NAME_CARD: useRef(),
    USER_TALK_USE_YN: useRef(),
  };

  useEffect(() => {
    let loginInfo = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginInfo) {
      if (loginInfo["returnData"].length) {
        setUserData({ ...loginInfo["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            setUserData({ ...n["returnData"][0] });
          }
        }
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [userData]);

  const requestUserSelect = () => {
    setLoading(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_info.php",
      msgID: msgID,
      data: {},
      COMP_CD: userData.comp_cd,
      USER_ID: userData.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          let data = newData["returnData"][0];
          for (let key in $controllers) {
            if (data.hasOwnProperty(key)) {
              $controllers[key].current.value = data[key];
            }
          }
          setNameCard(data.USER_NAME_CARD);
          if (data.USER_PIC) {
            setUploadFile(data.USER_PIC);
          } else {
            $controllers.USER_PIC.current.value =
              "https://www.gbts.co.kr/images/user_pic-50x50.png";
            setUploadFile("https://www.gbts.co.kr/images/user_pic-50x50.png");
          }

          setLoading(false);
        }
      }
    });
  };

  const uploadImage = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      $controllers.USER_PIC.current.value = fileLink;
      setUploadFile(fileLink);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다."
      );
    }
  };

  const uploadNameCard = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let file = `${fileName}?${fileLink}`;
      $controllers.USER_NAME_CARD.current.value = file;
      setNameCard(file);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다."
      );
    }
  };

  const profileUpdate = () => {
    let profile = {
      USER_PIC: $controllers.USER_PIC.current.value,
      USER_NICK: $controllers.USER_NICK.current.value,
      USER_TEAM_NM: $controllers.USER_TEAM_NM.current.value,
      USER_PART_NM: $controllers.USER_PART_NM.current.value,
      USER_TALK_USE_YN: $controllers.USER_TALK_USE_YN.current.value,
      USER_PW: $controllers.USER_PW.current.value,
      USER_NAME_CARD: $controllers.USER_NAME_CARD.current.value,
    };
    requestUserUpdate(profile);
  };

  const requestUserUpdate = (updateData) => {
    setLoading(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_update.php",
      msgID: msgID,
      data: {},
      COMP_CD: userData.comp_cd, //업체코드
      USER_ID: userData.user_id, //아이디
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          if (newData["effect"]) {
            EVENT_SOCKET_USER_PROFILE_CHANGED(updateData);
          } else {
            codeList.Modal.current.alert("비밀번호를 다시 확인해 주세요");
            setLoading(false);
          }
        }
      }
    });
  };

  const EVENT_SOCKET_USER_PROFILE_CHANGED = (updateData) => {
    let msgID = util.makeUUIDv4();

    let data = {
      method: MsgIDList.EVENT_SOCKET_USER_PROFILE_CHANGED,
      msgID: msgID,
      data: {
        COMP_CD: userData.comp_cd, //업체코드
        USER_ID: userData.user_id, //아이디
        USER_PIC: updateData.USER_PIC,
        USER_NICK: updateData.USER_NICK,
        USER_PART_NM: updateData.USER_PART_NM,
        USER_TEAM_NM: updateData.USER_TEAM_NM,
        USER_NAME_CARD: updateData.USER_NAME_CARD,
        USER_TALK_USE_YN: updateData.USER_TALK_USE_YN,
      },
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          changeProfile(updateData);
          codeList.Modal.current.alert("프로필변경이 완료되었습니다.");
        }
      }
    });

    // $controllers.USER_PW.current.value = "";
    setLoading(false);
  };

  const createdNameCard = (nameCard) => {
    if (!nameCard) return;
    let data = nameCard.split("?");
    let fileName = data[0];
    let fileLink = data[1];
    return (
      <>
        <button
          className="btn btn-sm btn-danger mr-2"
          onClick={() => {
            $controllers.USER_NAME_CARD.current.value = "";
            setNameCard("");
          }}
        >
          삭제
        </button>
        <a
          className="font-weight-bold text-decoration-underline"
          href={fileLink}
          target="_blank"
        >
          {fileName}
        </a>
      </>
    );
  };

  return (
    <div className="p-3 w-100 h-100 overflow_scroll">
      <h4 className="text-info py-2">프로필 수정</h4>

      <div className="row">
        <div className="col-lg-5">
          <div className="card rounded-lg border-0 shadow-sm">
            <div className="card-body">
              <div className="mb-3 row d-flex flex-row justify-content-center">
                <div
                  className="border rounded-circle"
                  style={{
                    minWidth: 200,
                    minHeight: 200,
                    background: `url(${uploadFile}) 50% 50%/cover no-repeat`,
                  }}
                />
              </div>
              <UploadImageToS3WithPhp
                region={"talk/profile"}
                callback={uploadImage}
              />
              <input
                className="form-control d-none" // d-none
                disabled={true}
                type="text"
                ref={$controllers.USER_PIC}
              />
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  닉네임
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="닉네임을 입력해주세요"
                    ref={$controllers.USER_NICK}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  부서명
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="부서명을 입력해주세요"
                    ref={$controllers.USER_TEAM_NM}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  담당업무
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="담당업무를 입력해주세요"
                    autoComplete="new-password"
                    ref={$controllers.USER_PART_NM}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  명함사진
                </label>
                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"talk/namecard"}
                    callback={uploadNameCard}
                  />
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={$controllers.USER_NAME_CARD}
                  />
                  {nameCard !== "" && createdNameCard(nameCard)}
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  검색허용 여부
                </label>
                <div className="col-sm-9">
                  <select
                    ref={$controllers.USER_TALK_USE_YN}
                    className="form-control"
                  >
                    <option value="Y">공개</option>
                    <option value="N">비공개</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="비밀번호를 입력해주세요"
                    autoComplete="new-password"
                    required
                    ref={$controllers.USER_PW}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        if (loading) return;
                        profileUpdate();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex flex-row justify-content-end">
                  <div
                    className="btn btn-primary ml-auto"
                    onClick={() => {
                      if (loading) return;
                      profileUpdate();
                    }}
                  >
                    프로필 변경{" "}
                    {loading && <i className="icon-spin3 animate-spin" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
