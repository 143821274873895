import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import BLogoManageTable from "./BLogoManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const _mClassName = "BLogoManage";
export default function BLogoManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $SEARCH_WORD = useRef();
  const $SEARCH_LEVEL = useRef();
  const $USE_YN = useRef();
  const $comp_type = useRef();
  const $blSchDisplay = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_BLOGO_MANAGE_FILTER_CHANGED,
      _mClassName,
      {
        SEARCH_WORD: $SEARCH_WORD.current.value,
        SEARCH_LEVEL: $SEARCH_LEVEL.current.value,
        USE_YN: $USE_YN.current.value,
        blCompType: $comp_type.current.value,
        blSchDisplay: $blSchDisplay.current.value,
      },
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
            <div
              className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
              role="toolbar"
            >
              <div
                className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                role="group"
              >
                <button
                  className="btn  btn-outline-primary "
                  data-toggle="modal"
                  data-target="#bLogoManageEdit"
                  onClick={addNewData}
                  aria-label={"신규등록"}
                  type="button"
                >
                  <i className="fas fa-plus" /> 신규등록
                </button>
              </div>

              <div className="flex-fill d-flex flex-row justify-content-end align-items-center mb-2 bm-sm-0 order-1 order-md-1 order-lg-1">
                <select
                  className="custom-select form-control ml-2"
                  ref={$comp_type}
                  onChange={() => {
                    searchFilterChanged();
                  }}
                  style={{ width: 150 }}
                >
                  <option value="">-업체구분-</option>
                  <option value="A">항공사</option>
                  <option value="B">콘솔사</option>
                  <option value="F">선사</option>
                </select>
                <select
                  className="custom-select form-control ml-2"
                  ref={$USE_YN}
                  onChange={() => {
                    searchFilterChanged();
                  }}
                  style={{ width: 150 }}
                >
                  <option value="">-사용여부-</option>
                  <option value="Y">사용</option>
                  <option value="N">미사용</option>
                </select>

                <select
                  className="custom-select form-control ml-2"
                  ref={$blSchDisplay}
                  onChange={() => {
                    searchFilterChanged();
                  }}
                  style={{ width: 150 }}
                >
                  <option value="">-표기여부-</option>
                  <option value="Y">표기</option>
                  <option value="N">미표기</option>
                </select>
                <div className="input-group ml-2">
                  <select
                    className="custom-select form-control"
                    ref={$SEARCH_LEVEL}
                    onChange={searchFilterChanged}
                    style={{ minWidth: 150 }}
                  >
                    <option value="blCompNM_KO">상호명(국문)</option>
                    <option value="blCompNM">상호명(영문)</option>
                    <option value="blFWDNO">포워더 No</option>
                  </select>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="검색어"
                    aria-label="검색어"
                    aria-describedby="btnGroupAddon2"
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        searchFilterChanged(e);
                      }
                    }}
                    ref={$SEARCH_WORD}
                  />

                  <button
                    className="input-group-text btn btn-primary"
                    onClick={searchFilterChanged}
                  >
                    <i className="fas fa-search mr-2" />
                    검색
                  </button>
                </div>
              </div>
            </div>
          </h6>
        </div>
        <BLogoManageTable isServerPaging={true} isServerSort={true} />
      </div>
    </div>
  );
}
