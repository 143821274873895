import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AutoLogin from "../lib/AutoLogin";
import CommonHeader from "./CommonHeader";
import CommonSlideNavbar from "./CommonSlideNavbar";
// 대시보드
import DashboardMain from "./dashboard/DashboardMain";
import DashboardFCLReserve from "./dashboard/DashboardFCLReserve";
// 예약관리
import ScheduleBookingManage from "./scheduleBookingManage/ScheduleBookingManage";
import ScheduleFCLBookingManage from "./scheduleFCLBookingManage/ScheduleFCLBookingManage";
import ScheduleAirBookingManage from "./scheduleAirBookingManage/ScheduleAirBookingManage";
// 스케줄관리
import ScheduleManage from "./scheduleManage/ScheduleManage";
import ScheduleAIRManage from "./scheduleAirManage/ScheduleAIRManage";
import SchedulePromotionManage from "./shcedulePromotionManage/SchedulePromotionManage";
import ScheduleRequestManage from "./scheduleRequestManage/ScheduleRequestManage";
import PickPlaceManage from "./pickPlaceManage/PickPlaceManage";
import AirlineManage from "./airlineManage/AirlineManage";
import PriceAirManage from "./priceAirManage/PriceAirManage";
import PriceLCLFixedManage from "./priceLCLFixedManage/PriceLCLFixedManage";
import PriceLCLAdditionalManage from "./priceLCLAddtinalManage/PriceLCLAdditionalManage";
import PriceFclCustomGradeManage from "./priceFclCustomGradeManage/PriceFclCustomGradeManage";
import PriceFclDefaultManage from "./priceFclDefaultManage/PriceFclDefaultManage";
import PriceFclForeignCurrencyManage from "./priceFclForeignCurrencyManage/PriceFclForeignCurrencyManage";
// 로지킵관리
import StoreManage from "./storeManage/StoreManage";
import StoreRequestManage from "./storeRequestManage/StoreRequestManage";
import StoreReviewManage from "./storeReviewManage/StoreReviewManage";
// 견적요청 게시판 관리
import ProductRequestManage from "./productRequestManage/ProductRequestManage";
import ProductRequestReplyManage from "./productRequestManage/ProductRequestReplyManage";
// 기업 & 사용자 관리
import AdminCompanyInfoManage from "./adminCompanyInfoManage/AdminCompanyInfoManage";
import ShipperInfoManage from "./shipperInfoManage/ShipperInfoManage";
import ExportCompManage from "./exportCompManage/ExportCompManage";
import UserManage from "./userManagement/UserManage";
// 기업조회 관리
import BasaCoPointManage from "./basaCoPointManage/BasaCoPointManage";
import BasaHistoryManage from "./basaHistoryManage/BasaHistoryManage";
// 뉴스톡 관리(지비티에스 관리자)
import TalkNewsCompManage from "./talkNewsCompManage/TalkNewsCompManage";
import EntireTalkManage from "./entireTalkManage/EntireTalkManage";
// 게시글 관리
import LogiStoryManage from "./logiStoryManage/LogiStoryManage";
import BLogoManage from "./bLogoManage/BLogoManage";
import BBSManage from "./bbsManage/BBSManage";
import NoticeManage from "./noticeManage/NoticeManage";
import QnAManage from "./qnaManage/QnAManage";
import PromotionManage from "./promotionManage/PromotionManage";
// 지역 관리
import CityManage from "./cityManage/CityManage";
// 배너광고 관리
import ADManage from "./adManage/ADManage";
import MallBannerManage from "./mallBannerManage/MallBannerManage";
import AdvertiseVideoManage from "./advertiseVideoManage/AdvertiseVideoManage";
// 회사정보 관리
import CompInfoManage from "./compInfoManage/CompInfoManage";
import TalkNewsManage from "./talkNewsManage/TalkNewsManage";
// 프레이스콥 플러스
import RequestTransitMemoManage from "./logiWork/requestTransitMemoManage/RequestTransitMemoManage";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import "./css/ruang-admin.css";
// import AddProduct from "./addProduct/AddProduct";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "ERP";
let activeSubMenu = {
  dashboard: true,
  reserveManage: false,
  mallSchedule: false,
  storeMall: false,
  compInfo: false,
  productRequest: false,
  // --------
  accountManage: false,
  referenceCorpManage: false,
  newsTalkManage: false,
  postManage: false,
  countryManage: false,
  // --------
  advertisement: false,
  freiscopPlus: false,
};

const page_list = [
  { name: "dashboard", title: "대시보드", uLevel: 90, key: "dashboard" },
  {
    name: "DashboardMain",
    title: "대시보드 메인",
    page: (params) => <DashboardMain {...params} />,
    uLevel: 90,
    key: "dashboard",
  },
  {
    name: "DashboardFCLReserve",
    title: "FCL 예약현황",
    page: (params) => <DashboardFCLReserve {...params} />,
    uLevel: 90,
    key: "dashboard",
  },
  {
    key: "reserveManage",
    name: "type",
    title: "예약관리",
    uLevel: 9,
  },
  {
    name: "ScheduleBookingManage",
    title: "LCL 예약관리",
    page: (params) => <ScheduleBookingManage {...params} />,
    uLevel: 9,
    key: "reserveManage",
  },
  {
    name: "ScheduleFCLBookingManage",
    title: "FCL 예약관리",
    page: (params) => <ScheduleFCLBookingManage {...params} />,
    uLevel: 9,
    key: "reserveManage",
  },
  {
    name: "ScheduleAirBookingManage",
    title: "AIR 예약관리",
    page: (params) => <ScheduleAirBookingManage {...params} />,
    uLevel: 9,
    key: "reserveManage",
  },

  {
    name: "type",
    title: "스케쥴 관리",
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "ScheduleManage",
    title: "스케쥴 등록관리(해상)",
    page: (params) => <ScheduleManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "ScheduleAIRManage",
    title: "스케쥴 등록관리(항공)",
    page: (params) => <ScheduleAIRManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "SchedulePromotionManage",
    title: "스케쥴 프로모션 관리",
    page: (params) => <SchedulePromotionManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "ScheduleRequestManage",
    title: "견적요청 관리",
    page: (params) => <ScheduleRequestManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },

  {
    name: "PickPlaceManage",
    title: "픽업장소 관리",
    page: (params) => <PickPlaceManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "AirlineManage",
    title: "스케쥴(항공) 공급업체 관리",
    page: (params) => <AirlineManage {...params} />,
    uLevel: 90,
    key: "mallSchedule",
  },
  {
    name: "type",
    title: "스케쥴 운임 관리",
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceAirManage",
    title: "스케줄(항공) 운임 관리",
    page: (params) => <PriceAirManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceLCLFixedManage",
    title: "LCL 고정운임 관리",
    page: (params) => <PriceLCLFixedManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceLCLAdditionalManage",
    title: "LCL 가산운임 관리",
    page: (params) => <PriceLCLAdditionalManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceFclCustomGradeManage",
    title: "업체 등급별 FCL 운임관리",
    page: (params) => <PriceFclCustomGradeManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceFclDefaultManage",
    title: "스케줄(FCL) 기본운임 관리",
    page: (params) => <PriceFclDefaultManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },
  {
    name: "PriceFclForeignCurrencyManage",
    title: "스케줄(FCL) 선사별 운임 관리",
    page: (params) => <PriceFclForeignCurrencyManage {...params} />,
    uLevel: 90,
    key: "mallScheduleCost",
  },

  { name: "type", title: "로지킵관리", uLevel: 90, key: "storeMall" },
  {
    name: "StoreManage",
    title: "등록창고관리",
    page: (params) => <StoreManage {...params} />,
    uLevel: 90,
    key: "storeMall",
  },
  {
    name: "StoreRequestManage",
    title: "로지킵 요청관리",
    page: (params) => <StoreRequestManage {...params} />,
    uLevel: 90,
    key: "storeMall",
  },
  {
    name: "StoreReviewManage",
    title: "로지킵 리뷰관리",
    page: (params) => <StoreReviewManage {...params} />,
    uLevel: 90,
    key: "storeMall",
  },

  {
    name: "type",
    title: "견적요청 게시판 관리",
    uLevel: 90,
    key: "productRequest",
  },
  {
    name: "ProductRequestManage",
    title: "견적요청 관리",
    page: (params) => <ProductRequestManage {...params} />,
    uLevel: 90,
    key: "productRequest",
  },
  {
    name: "ProductRequestReplyManage",
    title: "견적요청 댓글관리",
    page: (params) => <ProductRequestReplyManage {...params} />,
    uLevel: 90,
    key: "productRequest",
  },

  {
    name: "type",
    title: "기업 & 사용자 관리",
    uLevel: 90,
    key: "accountManage",
  },
  {
    name: "AdminCompanyInfoManage",
    title: "등록회사 정보관리",
    page: (params) => <AdminCompanyInfoManage {...params} />,
    uLevel: 90,
    key: "accountManage",
  },

  {
    name: "ShipperInfoManage",
    title: "화주기업 정보관리",
    page: (params) => <ShipperInfoManage {...params} />,
    uLevel: 90,
    key: "accountManage",
  },
  {
    name: "ExportCompManage",
    title: "수출 중소기업 관리",
    page: (params) => <ExportCompManage {...params} />,
    uLevel: 90,
    key: "accountManage",
  },
  /*  {
    name: "UserManage",
    title: "사용자 계정관리",
    page: (params) => <UserManage {...params} />,
    uLevel: 8,
    key: "accountManage",
  },*/

  {
    name: "type",
    title: "기업조회 관리",
    uLevel: 90,
    key: "referenceCorpManage",
  },
  {
    name: "BasaCoPointManage",
    title: "업체별 BASA 포인트 관리",
    page: (params) => <BasaCoPointManage {...params} />,
    uLevel: 90,
    key: "referenceCorpManage",
  },
  {
    name: "BasaHistoryManage",
    title: "BASA 조회이력 관리",
    page: (params) => <BasaHistoryManage {...params} />,
    uLevel: 90,
    key: "referenceCorpManage",
  },

  //   todo E0001 업체 권한 부여 대기(240124)
  {
    name: "type",
    title: "뉴스톡 관리",
    uLevel: 90,
    key: "newsTalkManage",
  },
  {
    name: "TalkNewsCompManage",
    title: "뉴스톡 업체관리",
    page: (params) => <TalkNewsCompManage {...params} />,
    uLevel: 90,
    key: "newsTalkManage",
  },

  {
    name: "EntireTalkManage",
    title: "알림톡 관리",
    page: (params) => <EntireTalkManage {...params} />,
    uLevel: 90,
    key: "newsTalkManage",
  },

  { name: "type", title: "게시글 관리", uLevel: 90, key: "postManage" },
  {
    name: "LogiStoryManage",
    title: "선사 · 항공사 공지사항 관리",
    page: (params) => <LogiStoryManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },
  {
    name: "BLogoManage",
    title: "선사 · 항공사 로고 관리",
    page: (params) => <BLogoManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },
  {
    name: "BBSManage",
    title: "서식자료실 관리",
    page: (params) => <BBSManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },

  {
    name: "NoticeManage",
    title: "공지사항 관리",
    page: (params) => <NoticeManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },
  {
    name: "QnAManage",
    title: "Q&A 관리",
    page: (params) => <QnAManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },
  {
    name: "PromotionManage",
    title: "추천선사 및 프로모션 관리",
    page: (params) => <PromotionManage {...params} />,
    uLevel: 90,
    key: "postManage",
  },

  { name: "type", title: "지역 관리", uLevel: 90, key: "countryManage" },
  {
    name: "CityManage",
    title: "대륙/국가/도시 관리",
    page: (params) => <CityManage {...params} />,
    uLevel: 90,
    key: "countryManage",
  },

  {
    name: "type",
    title: "배너광고 관리",
    uLevel: 90,
    key: "advertisement",
  },

  {
    name: "ADManage",
    title: "파트너사 배너광고",
    page: (params) => <ADManage {...params} />,
    uLevel: 90,
    key: "advertisement",
  },
  {
    name: "MallBannerManage",
    title: "스케쥴 배너광고",
    page: (params) => <MallBannerManage {...params} />,
    uLevel: 90,
    key: "advertisement",
  },

  {
    name: "AdvertiseVideoManage",
    title: "홍보영상 관리",
    page: (params) => <AdvertiseVideoManage {...params} />,
    uLevel: 90,
    key: "advertisement",
  },

  { name: "type", title: "회사정보 관리", uLevel: 1, key: "compInfo" },
  {
    name: "CompInfoManage",
    title: "회사정보 관리",
    page: (params) => <CompInfoManage {...params} />,
    uLevel: 1,
    key: "compInfo",
  },
  {
    name: "TalkNewsManage",
    title: "뉴스톡 관리",
    page: (params) => <TalkNewsManage {...params} />,
    uLevel: 1,
    key: "compInfo",
  },
  // todo 데이터 요청 시점 변경
  { name: "type", title: "프레이스콥 플러스", uLevel: 90, key: "freiscopPlus" },
  {
    name: "RequestTransitMemoManage",
    title: "운송요청 메모관리",
    page: (params) => <RequestTransitMemoManage {...params} />,
    uLevel: 90,
    key: "freiscopPlus",
  },
  {
    name: "UserManage",
    title: "사용자 계정관리",
    page: (params) => <UserManage {...params} />,
    uLevel: 9,
    key: "compInfo",
  },
];

let isLoading = true;
let loginInfo = null;
let compList = [];
let compListObj = {};

export default function ERP() {
  const params = useParams();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [searchCodeList, setSearchCodeList] = useState([]);
  const [pagesOpen, setPagesOpen] = useState({
    DashboardMain: true, //true
    DashboardFCLReserve: false,
    ScheduleBookingManage: false,
    ScheduleFCLBookingManage: false,
    ScheduleAirBookingManage: false,
    PickPlaceManage: false,
    AirlineManage: false,
    ScheduleRequestManage: false,
    ScheduleManage: false,
    ScheduleAIRManage: false,
    SchedulePromotionManage: false,
    PriceAirManage: false,
    PriceLCLFixedManage: false,
    PriceLCLAdditionalManage: false,
    PriceFclCustomGradeManage: false,
    PriceFclDefaultManage: false,
    PriceFclForeignCurrencyManage: false,
    StoreManage: false,
    CompInfoManage: false,
    StoreRequestManage: false,
    StoreReviewManage: false,
    ProductRequestManage: false,
    ProductRequestReplyManage: false,
    LogiStoryManage: false,
    BLogoManage: false,
    BBSManage: false,
    AdminCompanyInfoManage: false,
    NoticeManage: false,
    QnAManage: false,
    PromotionManage: false,
    CityManage: false,
    AdvertiseVideoManage: false,
    MallBannerManage: false,
    ADManage: false,
    UserManage: false,
    BasaCoPointManage: false,
    BasaHistoryManage: false,
    TalkNewsCompManage: false,
    EntireTalkManage: false,
    TalkNewsManage: false,
    ShipperInfoManage: false,
    ExportCompManage: false,
    RequestTransitMemoManage: false,
  });

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (beforeData, newData) => {
        codeList.Modal.current.alert("로그인 후 이용해주세요", () => {});
        window.location.replace("/");
      }
    );
  })();

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData["returnData"].length > 0) {
        setSearchCodeList(codeList.codeCountryFullName);
        handleLoginData(userData["returnData"][0], () => {
          requestCompList(() => {
            isLoading = false;
            setIsNeedUpdate(!isNeedUpdate);
          });
        });
      }
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (beforeData, newData) => {
        if (newData.ret) {
          setSearchCodeList(codeList.codeCountryFullName);

          handleLoginData(newData["returnData"][0], () => {
            requestCompList(() => {
              isLoading = false;
              setIsNeedUpdate(!isNeedUpdate);
            });
          });
        }
      }
    );

    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setSearchCodeList(newV);
        }
      );
    } else {
      setSearchCodeList(codeList.codeCountryFullName);
    }

    if (params && params.hasOwnProperty("page")) {
      let setItem = {};
      for (let key in pagesOpen) {
        setItem[key] = key.toUpperCase() === params.page.toUpperCase();
      }
      setPagesOpen(setItem);
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName
      );
    };
  }, []);

  const requestCompList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_comp_list_all.php",
      msgID: msgID,
      data: [],
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          compList = newData["returnData"];
          for (let i = 0; i < newData["returnData"].length; i++) {
            let data = newData["returnData"][i];
            compListObj[data["COMP_CD"]] = data;
          }
          callback && callback();
        }
      }
    });
  };
  //로그인 여부 및 권한 확인
  const handleLoginData = (data, callback) => {
    let userData = { ...data };

    userData.erp_auth = -1;
    userData.isForwarder = false;
    userData.isAdmin = false;
    userData.isCorpAdmin = false;
    userData.isConsole = false;
    //   todo E0001 업체 권한 부여 대기(240124)
    // userData.comp_cd = "E0001";

    if (
      userData.comp_gb === "E" &&
      (userData.user_id === "sckcus@bstc.kr" ||
        userData.user_id === "hskim@bstc.kr" ||
        userData.user_id === "joonox@bstc.kr" ||
        userData.user_id === "admin")
    ) {
      userData.erp_auth = 100;
      userData.isForwarder = false;
      userData.isAdmin = true;
    }

    if (userData.comp_gb === "Z") {
      userData.erp_auth = 100;
      userData.isForwarder = false;
      userData.isAdmin = true;
    }

    if (userData.comp_gb === "Z" || userData.user_id === "admin") {
      userData.isCorpAdmin = true;
    }

    if (userData.comp_gb === "B" && userData.user_id === "admin") {
      userData.erp_auth = 10;
    }

    if (userData.comp_gb === "B" && userData.user_id !== "admin") {
      userData.erp_auth = 9;
    }

    if (
      userData.comp_gb === "A" ||
      userData.comp_gb === "C" ||
      userData.comp_gb === "D" ||
      userData.comp_gb === "F"
    ) {
      userData.erp_auth = 8;
    }

    if (userData.comp_gb === "B") {
      userData.isForwarder = userData.COMP_GB_SUB2 === "B01";
      userData.isConsole =
        userData.COMP_GB_SUB2 === "B02" || userData.COMP_GB_SUB2 === "B03";
    }

    // if (userData.comp_cd === "E0001") {
    //   userData.erp_auth = 1;
    // }

    if (userData.comp_cd === "A9100") {
      userData.erp_auth = -1;
    }

    loginInfo = userData;
    callback && callback();
  };

  //메뉴 열기 닫기
  const handleOpenSubMenu = (key) => {
    activeSubMenu = {
      ...activeSubMenu,
      [key]: !activeSubMenu[key],
    };
    setIsNeedUpdate(!isNeedUpdate);
  };

  //페이지 이동
  const handlePageOpen = (name, callback) => {
    let temp_pagesOpen = { ...pagesOpen };
    for (let key in temp_pagesOpen) {
      temp_pagesOpen[key] = key === name;
    }
    if (callback) {
      callback();
    }
    setPagesOpen(temp_pagesOpen);
  };

  const MappedComponent = useCallback(
    ({
      p_name,
      index,
      pagesOpen,
      loginInfo,
      compList,
      compListObj,
      searchCodeList,
    }) => {
      if (!page_list[index].page) return <></>;
      return (
        <MappedComponentMemo
          p_name={p_name}
          index={index}
          pagesOpen={pagesOpen}
          loginInfo={loginInfo}
          compList={compList}
          compListObj={compListObj}
          searchCodeList={searchCodeList}
        />
      );
    },
    []
  );

  const MappedComponentMemo = React.memo(
    ({
      p_name,
      index,
      pagesOpen,
      loginInfo,
      compList,
      compListObj,
      searchCodeList,
    }) => {
      return (
        <div
          key={`erp_${index}`}
          style={!pagesOpen[p_name] ? { display: "none" } : { display: "" }}
          className="main h-100"
        >
          {page_list[index].page({
            pagesOpen: pagesOpen,
            loginInfo: loginInfo,
            compList: compList,
            compListObj: compListObj,
            searchCodeList: searchCodeList,
          })}
        </div>
      );
    }
  );

  return (
    <>
      <AutoLogin />
      {!isLoading && (
        <div id="wrapper" className="wrapper">
          <CommonSlideNavbar
            loginInfo={loginInfo}
            pages={page_list}
            activeSubMenu={activeSubMenu}
            pagesOpen={pagesOpen}
            handleOpenSubMenu={handleOpenSubMenu}
            handlePageOpen={handlePageOpen}
          />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <CommonHeader />
              <div
                className="overflow_scroll-Y py-4"
                style={{ height: "calc(100vh - 70px)" }}
              >
                {searchCodeList.length > 0 &&
                  page_list.map((p, index) => {
                    if (loginInfo && loginInfo.erp_auth > p.uLevel) {
                      return (
                        <MappedComponent
                          key={index}
                          p_name={p.name}
                          index={index}
                          pagesOpen={pagesOpen}
                          loginInfo={loginInfo}
                          compList={compList}
                          compListObj={compListObj}
                          searchCodeList={searchCodeList}
                        />
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
