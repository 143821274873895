import React, { useEffect, useState } from "react";
import AirlineManageHeader from "./AirlineManageHeader";
import AirlineManageTable from "./component/AirlineManageTable";
import CommonLoader from "../CommonLoader";

const _mClassName = "AirlineManage";

export default function AirlineManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="card">
        <AirlineManageHeader />
        <AirlineManageTable />
      </div>
    </div>
  );
}
