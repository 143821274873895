import React, { useCallback, useState } from "react";

const dotStyle = {
  width: 10,
  height: 10,
  top: -18,
  left: "50%",
  transform: "translateX(-50%)",
};

const logi_list = [
  {
    key: "logiMall",
    title: "프레이스콥(freiscop)",
    desc: "로지톡은 프레이스콥(freiscop)에서 화물 수요자(포워더)와 공급자(선사, 콘솔사) 간 실시간 업무소통을 지원합니다.",
    img: "/img/logiMallMain.png",
    img_alt: "프레이스콥 메인화면",
    url: "https://www.gbts.co.kr",
  },
  {
    key: "logiWork",
    title: "로지웍(LogiWork)",
    desc: "로지톡으로 로지웍이 제공하는 실시간 화물(통관)정보 제공 서비스를 PC와 스마트폰에서 확인할 수 있습니다.",
    img: "/img/logiWork.png",
    img_alt: "로지웍 수입화물 실제 진행화면",
    url: "https://system.gbts.co.kr/",
  },
  {
    key: "logiKeep",
    title: "로지킵(logiKeep)",
    desc: "로지톡으로 지도에서 보세창고를 쉽게 검색할 수 있고, 고객과 업무 담당자간 대화를 직접 연결할 수 있습니다.",
    img: "/img/logiKeepMain.png",
    img_alt: "로지킵 메인화면",
    url: "https://www.gbts.co.kr/storeMall",
  },
];

export default function LogiTalkLandingProductInfo() {
  const [currentHash, setCurrentHash] = useState("logiMall");

  const slidePrev = () => {
    let current = logi_list.findIndex((item) => item.key === currentHash);
    let prev = current - 1 > -1 ? current - 1 : logi_list.length - 1;
    let changedHash = logi_list[prev].key;
    setCurrentHash(changedHash);
  };
  const slideNext = () => {
    let current = logi_list.findIndex((item) => item.key === currentHash);
    let next = current + 1 < logi_list.length ? current + 1 : 0;
    let changedHash = logi_list[next].key;
    setCurrentHash(changedHash);
  };

  const CarouselItem = useCallback((props) => {
    return <CarouselItemRender {...props} />;
  }, []);

  const CarouselItemRender = React.memo((props) => {
    const { item } = props;
    return (
      <div
        className={`carousel-item ${currentHash === item.key ? "active" : ""}`}
        key={item.key}
      >
        <div className="h4 font-weight-bold mb-3">
          <span className="text-warning">{item.title}</span>
          <button
            className="btn btn-sm btn-outline-light py-0 font-weight-500 ml-2"
            style={{ fontSize: "0.4em" }}
            onClick={() => {
              window.open(item.url, "_blank", "width=1200, height=800");
            }}
          >
            바로가기
            <i className="fa-solid fa-arrow-right-long ml-2" />
          </button>
        </div>
        <p className="h5 mb-4" style={{ lineHeight: "1.5em" }}>
          <span
            className="mr-2"
            dangerouslySetInnerHTML={{ __html: item.desc }}
          />
        </p>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            className="shadow"
            src={item.img}
            style={{
              height: "auto",
              width: "100%",
              maxWidth: 400,
            }}
          />
          <p className="text-light mt-2">▲ {item.img_alt}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="bg-white w-100 position-relative d-flex flex-row justify-content-center">
      <div className="row m-0 p-0" style={{ maxWidth: 1600 }}>
        <div className="col-12 col-xl-7 p-5 bg-white d-flex flex-column align-items-center">
          <div className="S-CORE mb-3">
            <p className="h2 m-0 mb-1 font-weight-bold">지금이</p>
            <p
              className="h2 m-0 font-weight-bold"
              style={{ lineHeight: "1.5em", wordSpacing: -7 }}
            >
              <span className="bg-gbts text-white px-1">로지톡</span> 차별화된
              서비스를 시작할
              <span
                className="text-dark px-1 ml-2"
                style={{ background: "#bb86fc" }}
              >
                '
                <span className="d-inline-block position-relative text-dark">
                  <span
                    className="position-absolute bg-primary rounded-circle d-none d-md-block"
                    style={dotStyle}
                  />
                  바
                </span>
                <span className="d-inline-block position-relative text-dark">
                  <span
                    className="position-absolute bg-info rounded-circle d-none d-md-block"
                    style={dotStyle}
                  />
                  로
                </span>{" "}
                <span className="d-inline-block position-relative text-dark">
                  <span
                    className="position-absolute rounded-circle  d-none d-md-block"
                    style={{ ...dotStyle, background: "#bb86fc" }}
                  />
                  그
                </span>{" "}
                <span className="d-inline-block position-relative text-dark">
                  <span
                    className="position-absolute bg-secondary rounded-circle d-none d-md-block"
                    style={dotStyle}
                  />
                  때
                </span>
                '
              </span>{" "}
              입니다.
            </p>
          </div>
          <div className="row m-0 p-0 w-100">
            <div className="col-md-6 m-0 p-2">
              <div
                className="shadow w-100 p-4 S-CORE h-100"
                style={{ borderRadius: 20, border: "2px solid #444444" }}
              >
                <div className="h1 mb-2">
                  <i className="fa-solid fa-rotate" />
                </div>
                <h3 className="m-0 font-weight-400">디바이스 자동 동기화</h3>
                <div
                  className="w-50 m-3 ml-0"
                  style={{ borderBottom: "1px solid #444444" }}
                />
                <div className="px-4">
                  <ul className="list-disc">
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        PC, 태블릿, 스마트폰 화면에 최적화된 UI
                      </p>
                    </li>
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        로지톡 대화내용 다른 디바이스와 실시간 동기화
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-0 p-2">
              <div
                className="shadow w-100 p-4 S-CORE h-100"
                style={{ borderRadius: 20, border: "2px solid #444444" }}
              >
                <div className="h1 mb-2">
                  <i className="fa-solid fa-cloud-arrow-down" />
                </div>
                <h3 className="m-0 font-weight-400">대화, 파일 영구보관</h3>
                <div
                  className="w-50 m-3 ml-0"
                  style={{ borderBottom: "1px solid #444444" }}
                />
                <div className="px-4">
                  <ul className="list-disc">
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        클라우드 서버 별도 백업없이 자동저장
                      </p>
                    </li>
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        대화창에서 지난 대화내용, 파일 상시 다운로드 가능
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-0 p-2">
              <div
                className="shadow w-100 p-4 S-CORE h-100"
                style={{ borderRadius: 20, border: "2px solid #444444" }}
              >
                <div className="h1 mb-2">
                  <i className="fa-brands fa-searchengin" />
                </div>
                <h3 className="m-0 font-weight-400">물류 플레이어 검색</h3>
                <div
                  className="w-50 m-3 ml-0"
                  style={{ borderBottom: "1px solid #444444" }}
                />
                <div className="px-4">
                  <ul className="list-disc">
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        로지톡 회원사(360여개) 검색 후 담당자 톡대화
                      </p>
                    </li>
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        회원사 기업신용정보 검색 바로가기 기능
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-0 p-2">
              <div
                className="shadow w-100 p-4 S-CORE h-100"
                style={{ borderRadius: 20, border: "2px solid #444444" }}
              >
                <div className="h1 mb-2">
                  <i className="fa-solid fa-user-plus" />
                </div>
                <h3 className="m-0 font-weight-400">업무톡 맞춤형 관리</h3>
                <div
                  className="w-50 m-3 ml-0"
                  style={{ borderBottom: "1px solid #444444" }}
                />
                <div className="px-4">
                  <ul className="list-disc">
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        회원수 제한없이 관리자가 사용자 추가/삭제
                      </p>
                    </li>
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        소속 및 직급별 구분
                      </p>
                    </li>
                    <li className="mb-1">
                      <p className="h6 mb-0 font-weight-400">
                        명함 프로필 설정
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5 p-5 bg-gbts d-flex flex-column align-items-center">
          <div className="BM-DH d-flex flex-column align-items-center">
            <p
              className="h2 text-white mb-3"
              style={{ textShadow: "0px 5px 0px rgba(0,0,0,1)" }}
            >
              로지톡 <span className="text-warning">활용법</span>
            </p>
            <p
              className="h6 text-white pb-2 mb-4"
              style={{ borderBottom: "1px solid #fff" }}
            >
              디지털 물류 플랫폼 GBTS
            </p>
          </div>
          <div className="NNS text-white w-100 d-flex flex-column flex-fill">
            <div className="carousel-indicators p-0 m-0 position-static justify-content-start h5 mb-4 font-weight-600">
              <span
                data-target="#productInfoCarousel"
                data-slide-to="0"
                className={`transition-2 ${
                  currentHash === "logiMall"
                    ? "text-warning border-bottom border-warning"
                    : ""
                }  cursor_pointer`}
                onClick={() => setCurrentHash("logiMall")}
              >
                프레이스콥
              </span>
              <span
                className={`transition-2 ${
                  currentHash === "logiWork"
                    ? "text-warning border-bottom border-warning"
                    : ""
                } mx-4  cursor_pointer`}
                data-target="#productInfoCarousel"
                data-slide-to="1"
                onClick={() => setCurrentHash("logiWork")}
              >
                로지웍
              </span>
              <span
                className={`transition-2 ${
                  currentHash === "logiKeep"
                    ? "text-warning border-bottom border-warning"
                    : ""
                }   cursor_pointer`}
                data-target="#productInfoCarousel"
                data-slide-to="2"
                onClick={() => setCurrentHash("logiKeep")}
              >
                로지킵
              </span>
            </div>
            <div
              id="productInfoCarousel"
              className="carousel slide flex-fill"
              data-touch="false"
              data-interval="false"
            >
              <div className="carousel-inner">
                {logi_list.map((item, index) => {
                  return <CarouselItem key={item.key} item={item} />;
                })}
              </div>

              <button
                className="carousel-control-prev bg-transparent border-0 mt-5"
                type="button"
                data-target="#productInfoCarousel"
                data-slide="prev"
                onClick={() => slidePrev()}
              >
                <span className="h1 mb-0">
                  <i className="fa-solid fa-chevron-left" />
                </span>
              </button>
              <button
                className="carousel-control-next bg-transparent border-0 mt-5"
                type="button"
                data-target="#productInfoCarousel"
                data-slide="next"
                onClick={() => slideNext()}
              >
                <span className="h1 mb-0">
                  <i className="fa-solid fa-chevron-right" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
