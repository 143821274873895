/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
const socket = new BuddibleSocket();
const util = new Utilities();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const headCells = [
  {
    field: "srKey",
    headerName: "관리번호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "srType",
    headerName: "문의종류",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "srGoods",
    headerName: "화물종류",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "srCustomsYN",
    headerName: "보세여부",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => {
      return (
        <span>
          {params.data === "Y" ? "보세" : params.data === "N" ? "일반" : " "}
        </span>
      );
    },
  },
  {
    field: "srUserName",
    headerName: "회신 담당자명",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "srCreateDate",
    headerName: "제출일자",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
];
let sendFileData = [];

const StoreDetail = (props) => {
  const { rowData } = props;
  const parentData = { ...rowData };

  const $srController = {
    srKey: useRef(),
    srType: useRef(),
    srGoods: useRef(),
    srPacking: useRef(),
    srInOutType: useRef(),
    srCustomsYN: useRef(),
    srStartDate: useRef(),
    srEndDate: useRef(),
    srUserName: useRef(),
    srEmail: useRef(),
    srMobile: useRef(),
    srDesc: useRef(),
    srCreateDate: useRef(),
  };

  useEffect(() => {
    if (parentData.srKey !== "") {
      for (let key in parentData) {
        if ($srController.hasOwnProperty(key)) {
          if ($srController[key].current) {
            $srController[key].current.value = parentData[key];
          }
        }
      }
    }

    return () => {
      sendFileData = [];
    };
  }, []);

  return (
    <tr className="container" id={parentData.pmKeyMain}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">요청 상세정보</div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">창고관리번호</h6>{" "}
                <p>{rowData.srKey}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">문의종류</h6>{" "}
                <p>{rowData.srType}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">화물종류</h6>{" "}
                <p>{rowData.srGoods}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">포장상태</h6>{" "}
                <p>{rowData.srPacking}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">보관장소</h6>{" "}
                <p>{rowData.srInOutType}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">보관 예정 시작일</h6>{" "}
                <p>{rowData.srStartDate}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">보관 예정 종료일</h6>{" "}
                <p>{rowData.srEndDate}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">보세여부</h6>{" "}
                <p>
                  {rowData.srCustomsYN === "Y"
                    ? "보세"
                    : rowData.srCustomsYN === "N"
                    ? "일반"
                    : ""}
                </p>
              </div>
              <div className="col-12 col-xxl-3 mb-4"></div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">회신 담당자명</h6>{" "}
                <p>{rowData.srUserName}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">회신 이메일</h6>{" "}
                <p>{rowData.srEmail}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">회신 전화번호</h6>{" "}
                <p>{rowData.srMobile}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">문의내용</h6>{" "}
                {/*<textarea>{rowData.srDesc}</textarea>*/}
                <p>{rowData.srDesc}</p>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

function Row(props) {
  const {
    row,
    openEdit,
    openCopy,
    requestUserSelect,
    isMultiSelect = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();

  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        size={"small"}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <StoreDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const _mClassName = "StoreRequestManageTable";

export default function StoreRequestManageTable({
  isServerPaging = false,
  isServerSort = false,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("srCreateDate");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "srCreateDate",
      sort: "desc",
    },
  ]);

  const [filters, setFilters] = useState({});

  (() => {
    sendFileData = [];
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_REQUEST_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
  })();

  useEffect(() => {
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_CUSTOMS_STORE_REQUEST_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/customsStore/JS_store_request_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" id="productManageList">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  requestUserSelect={requestUserSelect}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                requestUserSelect={requestUserSelect}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}
