import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Checkbox from "@material-ui/core/Checkbox";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "IMPAlarmTalkCompTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const headCells = [
  {
    field: "COMP_C_NUM",
    headerName: "업체부호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p className="font-weight-bold">{params.data}</p>;
    },
  },
  {
    field: "COMP_NM",
    headerName: "업체명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p className="font-weight-bold">{params.data}</p>;
    },
  },
];
let filters = {};

export default function IMPAlarmTalkCompTable(props) {
  const { _selectCompList, selectComp, addComp } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("COMP_CD");
  const [sortModel, setSortModel] = useState([
    {
      field: "COMP_CD",
      dir: "asc",
    },
  ]);
  const [dense, setDense] = useState(false);
  const [serverData, setServerData] = useState([]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      () => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_search_shipper/JS_gbts_comp_shipper_list.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = { _selectCompList, selectComp };
  return (
    <>
      <div className="card">
        <div className="card-header bg-light border-bottom">
          <div className="d-flex flex-row justify-content-between">
            <p className="h6 font-weight-bold mb-0">화주목록</p>
            <div>
              <div className="input-group">
                <select
                  className="form-control"
                  onChange={(e) => {
                    filters["SEARCH_LEVEL"] = e.target.value;
                  }}
                >
                  <option value="">검색조건</option>
                  <option value="COMP_C_NUM">업체부호</option>
                  <option value="COMP_NM">업체명</option>
                </select>
                <input
                  className="form-control border min-width-200"
                  style={{ height: 40 }}
                  type="text"
                  placeholder="검색어를 입력해주세요."
                  onChange={(e) => {
                    filters["SEARCH_WORD"] = e.target.value;
                  }}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      setPage(0);
                      requestUserSelect();
                    }
                  }}
                />
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setPage(0);
                    requestUserSelect();
                  }}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />

            <TableBody>
              {data.map((row, index) => {
                return (
                  <Row
                    key={row.COMP_CD}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                );
              })}

              {loading && (
                <TableRow style={{ height: (dense ? 33 : 65) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length + 1}>
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length === 0 && !loading && (
                <TableRow style={{ height: (dense ? 33 : 65) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length + 1}>
                    <div className="d-flex justify-content-center">
                      <div>해당 데이터가 존재하지 않습니다.</div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length > 0 && emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 65) * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div className="mb-3 mt-3 px-3">
            <div className="d-flex flex-row justify-content-between">
              <button className="btn btn-sm btn-primary" onClick={addComp}>
                {_selectCompList.length}개 업체 등록
              </button>
              <Pagination
                style={{ justifyContent: "flex-end" }}
                className={classes.pagination}
                color="primary"
                count={totalPageCount}
                page={page + 1}
                allin={"right"}
                onChange={(event, value) => {
                  handleChangePage(event, value - 1);
                }}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </TableContainer>
      </div>
    </>
  );
}

function Row(props) {
  const { row, _selectCompList, selectComp } = props;
  const classes = useRowStyles();

  const isChecked = _selectCompList.findIndex(
    (data) => data.COMP_CD === row.COMP_CD
  );

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      <TableCell>
        <Checkbox
          color="primary"
          defaultChecked={isChecked > -1}
          onChange={(e) => {
            selectComp(row);
          }}
        />
      </TableCell>
      {headCells.map((headCell) => {
        const rowProp = {
          data: row[headCell.field],
          currentData: row,
          currentHeader: headCell.field,
          headCells: headCells,
        };
        return (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell(rowProp)
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter(rowProp)
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell, index) => (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
