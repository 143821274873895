/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "UserManageEdit";

const forms = [
  {
    filed: "EXC_IDX",
    title: "관리번호",
    inputType: "text",
    placeholder: "ID",
    disabled: true,
  },
  {
    filed: "EXC_YEAR",
    title: "인증연도",
    inputType: "text",
    placeholder: "인증연도를 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_ROUND",
    title: "차수",
    inputType: "text",
    placeholder: "차수를 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_COMP_NM",
    title: "상호명",
    inputType: "text",
    placeholder: "상호명을 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_COMP_NUM",
    title: "사업자등록번호",
    inputType: "text",
    placeholder: "사업자등록번호를 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_COMP_CEO_NM",
    title: "대표자명",
    inputType: "text",
    placeholder: "대표자명을 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_CERTIFICATE_NUM",
    title: "인증서번호",
    inputType: "text",
    placeholder: "인증서번호를 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_CERTIFICATE_STARTDATE",
    title: "인증시작",
    inputType: "date",
    placeholder: "인증시작일을 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_CERTIFICATE_ENDDATE",
    title: "인증종료",
    inputType: "date",
    placeholder: "인증종료일을 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_JURISDICTION",
    title: "관할청",
    inputType: "text",
    placeholder: "관할청을 입력해주세요",
    disabled: false,
  },
  {
    filed: "EXC_LOCATION",
    title: "지역",
    inputType: "text",
    placeholder: "지역을 입력해주세요",
    disabled: false,
  },
];

function ExportCompManageEdit(props, ref) {
  const { requestUserSelect } = props;
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const controller = {
    EXC_IDX: useRef(),
    EXC_YEAR: useRef(),
    EXC_ROUND: useRef(),
    EXC_COMP_NM: useRef(),
    EXC_COMP_NUM: useRef(),
    EXC_COMP_CEO_NM: useRef(),
    EXC_CERTIFICATE_NUM: useRef(),
    EXC_CERTIFICATE_STARTDATE: useRef(),
    EXC_CERTIFICATE_ENDDATE: useRef(),
    EXC_JURISDICTION: useRef(),
    EXC_LOCATION: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SHIPPER_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));
  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      for (let key in controller) {
        if (controller[key].current) {
          if (key.endsWith("DATE")) {
            controller[key].current.value = util.getDateToFormat(
              userData.currentData[key],
              "YYYY-MM-DD"
            );
            continue;
          }
          controller[key].current.value = userData.currentData[key];
        }
      }
    } else {
      for (let key in controller) {
        if (controller[key].current) {
          controller[key].current.value = "";
        }
      }
    }
  }, [userData]);

  const checkValidation = () => {
    for (let i = 0; i < forms.length; i++) {
      const data = forms[i];
      if (
        !data.disabled &&
        controller.hasOwnProperty(data.filed) &&
        controller[data.filed].current.value.trim() === ""
      ) {
        codeList.Modal.current.alert(data.placeholder, () => {});
        return false;
      }
    }
    return true;
  };
  const actionSave = () => {
    if (!checkValidation()) return;
    let updateData = {};
    for (let i = 0; i < forms.length; i++) {
      const data = forms[i];
      if (
        data.filed === "EXC_COMP_NUM" &&
        controller[data.filed].current.value.indexOf("-") === -1
      ) {
        updateData[data.filed] = util.getAutoHyphenBizID(
          controller[data.filed].current.value
        );
        continue;
      }
      updateData[data.filed] = controller[data.filed].current.value;
    }
    requestUserUpdate(updateData);
  };
  const requestUserUpdate = (updateData) => {
    let file = updateData.EXC_IDX
      ? "/talk_search_shipper/JS_export_company_update.php"
      : "/talk_search_shipper/JS_export_company_insert.php";
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: file,
      msgID: msgID,
      data: {},
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("requestUserUpdate", newData);
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상적으로 등록이 완료되었습니다.");
          requestUserSelect();
          cancel();
        } else {
          codeList.Modal.current.alert(
            "등록중 오류가 발생하였습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };
  const cancel = () => {
    for (let key in controller) {
      if (controller[key].current) {
        controller[key].current.value = "";
      }
    }
    setUserData({});
    $("#ExportCompManageEditClose").click();
  };

  const FormRow = (props) => {
    const { filed, title, inputType, placeholder, disabled } = props;
    return (
      <div className="mb-3 mx-1 row">
        <label className="col-form-label col-sm-3 text-end ">{title}</label>
        <div className="col-sm-9">
          <input
            className="form-control"
            type={inputType}
            ref={controller[filed]}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="modal fade"
      id="ExportCompManageEdit"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: 700 }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="staticBackdropLabel">
              <strong>수출유망 중소기업 등록/수정</strong>
            </h3>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="ExportCompManageEditClose"
              onClick={() => {
                cancel();
              }}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>
          <div className="modal-body">
            {forms.map((item) => {
              return <FormRow key={item.filed} {...item} />;
            })}
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={cancel}>
              취소
            </button>
            <button className="btn btn-primary" onClick={actionSave}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ExportCompManageEdit);
