/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import CryptoJS from "crypto-js";
import { useParams, useLocation } from "react-router-dom";
import QueryString from "qs";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
const util = new Utilities();
const socket = new BuddibleSocket();

/*window.jQuery = $;
window.$ = $;
global.jQuery = $;*/
//U2FsdGVkX18U/58TA/vXgflCHtSytucJ08aMu0i90Rc=@U2FsdGVkX1+YlDkfmjKqAn28SZnKXMcFHMOFA2hsQZ4=

export default function Logi_talktalk() {
  const open_logi_talktalk = () => {
    const windowFeatures = "left=100,top=100,width=648,height=900";
    window.open(
      "https://talktalk.gbts.co.kr/?site=GBTS",
      "logi_talktalk",
      windowFeatures
    );
  };

  const closeTooltip = () => {
    document.getElementById("open_logi_talktalk_tooltip").style.display =
      "none";
  };

  const openTooltip = () => {
    document.getElementById("open_logi_talktalk_tooltip").style.display =
      "block";
  };

  return (
    <div
      style={{
        zIndex: 9999,
        position: "fixed",
        right: "0px",
        bottom: "90px",
      }}
    >
      {" "}
      <div
        id="open_logi_talktalk_tooltip"
        className="position-absolute bg-blue-light p-2 shadow-sm text-left rounded"
        style={{
          bottom: 70,
          right: 20,
          width: 180,
          borderBottomRightRadius: 0,
        }}
      >
        <div className=" d-flex flex-row justify-content-between">
          <span
            className="font-weight-bold mr-1"
            style={{ whiteSpace: "break-spaces", wordBreak: "keep-all" }}
          >
            <span onClick={open_logi_talktalk} style={{ color: "#3B44AC" }}>
              로지톡톡
            </span>
            으로 문의하세요
          </span>{" "}
        </div>
        <span
          className="d-flex flex-row justify-content-start align-items-center mt-1"
          style={{ fontSize: "0.8em" }}
        >
          <span
            className="d-inline-block bg-info rounded-circle mr-1"
            style={{ width: 5, height: 5 }}
          />
          <span>담당자 실시간 대기중</span>
        </span>
        <span
          className="bg-blue-light d-inline-block position-absolute"
          style={{
            width: 15,
            height: 15,
            bottom: -8,
            right: 25,
            transform: "rotate(50deg)",
          }}
        />
      </div>
      <button
        onClick={open_logi_talktalk}
        onMouseOver={openTooltip}
        style={{
          zIndex: 9999,
          position: "fixed",
          right: "0px",
          bottom: "90px",
          width: 56,
          height: 56,
          background:
            "url('/img/use_img/play_store_512.png') 50% 50%/110% no-repeat",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          border: "none",
          backgroundColor: "transparent",
          margin: "0 22px 0 0",
          boxShadow: "1px 1px 8px rgba(0,0,0,0.2)",
          borderRadius: 13,
        }}
      />
    </div>
  );
}
