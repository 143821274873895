import React, { useEffect, useRef, useState } from "react";

import PriceLCLFixedManageTable from "./component/PriceLCLFixedManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import axios from "axios";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "PriceLCLFixedManage";
let port_list = [];

export default function PriceLCLFixedManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const controller = {
    SPD_KND: useRef(),
    SPD_DIV: useRef(),
  };
  const fileRef = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      if (port_list.length === 0) {
        if (codeList.codeCountryFullName.length === 0) {
          socket.addLocalEventListener(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
            _mClassName,
            (oldV, newV) => {
              port_list = newV;
              setIsNeedUpdate(!isNeedUpdate);
            }
          );
        } else {
          port_list = codeList.codeCountryFullName;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  // 엑셀파일 다운로드
  const downloadExcel = () => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//www.gbts.co.kr/backside/priceLCLFixed/JS_price_lcl_fixed_export_excel.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              SPD_KND: controller.SPD_KND.current.value,
              SPD_DIV: controller.SPD_DIV.current.value,
            },
          })
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([response.data], {
                  type: response.headers["content-type"],
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `LCL 고정운임_${util.todayDate("YYYYMMDD")}.xls`
              );
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              codeList.Modal.current.alert(
                "오류가 발생하였습니다. 다시 시도해주세요"
              );
            });
        }
      }
    );
  };

  // 엑세파일 업로드 통신
  const requestExcelUpload = (fileData, callback) => {
    const formData = new FormData();
    formData.append("files", fileData[0]);
    axios
      .post(
        "//www.gbts.co.kr/backside/priceLCLFixed/JS_price_lcl_fixed_import_excel.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.RESULT === "Y") {
          callback(response.data.RESULT, response.data.MSG);
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };

  // 엑셀파일 유효성검사
  const checkValidation = (name, size, type) => {
    let reg = /\.(xls|xlsx)$/i;

    if (!reg.test(name)) {
      codeList.Modal.current.alert(
        "지원하지 않는 형식 입니다.\nExcel 파일만 가능합니다.",
        () => {}
      );
      return false;
    }
    if (size > 1000000 * 10) {
      codeList.Modal.current.alert("용량은 10MB가 이하로 해주세요.", () => {});
      return false;
    }
    return true;
  };

  //엑셀파일업로드
  const uploadExcel = (e) => {
    let file = e.target.files[0],
      name = file.name,
      size = file.size,
      type = file.type;

    if (checkValidation(name, size, type)) {
      requestExcelUpload(e.target.files, function (ret, msg) {
        let type = "error";
        if (ret === "Y") {
          codeList.Modal.current.alert(msg, () => {
            fileRef.current.value = "";
            actionSearch();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요.",
            () => {
              console.log(msg);
            }
          );
        }
      });
    }
  };

  // 검색
  const actionSearch = () => {
    const filters = {
      SPD_KND: controller.SPD_KND.current.value,
      SPD_DIV: controller.SPD_DIV.current.value,
    };

    socket.fireLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_LCL_FIXED_PRICE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const htmlID = `customFile_${util.makeUUIDv4Short()}`;

  if (isLoad) return <CommonLoader />;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                <button
                  className="ml-2 btn btn-sm btn-secondary"
                  onClick={downloadExcel}
                >
                  엑셀 다운로드
                </button>

                <input
                  type="file"
                  className="d-none"
                  id={htmlID}
                  ref={fileRef}
                  onChange={uploadExcel}
                />
                <label
                  className="m-0 ml-2 btn btn-sm btn-primary"
                  htmlFor={htmlID}
                >
                  엑셀 업로드
                </label>
              </div>
              <div className="d-flex flex-row justify-content-end align-items-center">
                <select
                  className="form-control w-auto mr-2"
                  ref={controller.SPD_DIV}
                  onChange={actionSearch}
                >
                  <option value="">-구분-</option>
                  <option value="R.TON">R.TON</option>
                  <option value="B/L">B/L</option>
                </select>
                <div>
                  <div className="input-group">
                    <input
                      className="form-control w-auto"
                      placeholder="항목 검색"
                      ref={controller.SPD_KND}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          actionSearch();
                        }
                      }}
                    />
                    <button
                      className="btn btn-secondary rounded-0 rounded-top-right rounded-bottom-right"
                      onClick={actionSearch}
                    >
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <PriceLCLFixedManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
