import React, { useEffect, useState } from "react";
import AlarmTalkManageToolbar from "./components/AlarmTalkManageToolbar";
import EXPAlarmTalkManage from "./components/EXPAlarmTalkManage";
import IMPAlarmTalkManage from "./components/IMPAlarmTalkManage";

import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const pages = [
  {
    key: "exp",
    name: "수출알림",
    component: (props) => <EXPAlarmTalkManage {...props} />,
  },
  {
    key: "imp",
    name: "수입알림",
    component: (props) => <IMPAlarmTalkManage {...props} />,
  },
];

let noti_set = {
  EXP: [],
  IMP: [],
};

export default function AlarmTalkManage() {
  const [userInfo, setUserInfo] = useState(null);
  const [activePage, setActivePage] = useState("exp");
  const [notiSet, setNotiSet] = useState({
    EXP: [],
    IMP: [],
  });

  useEffect(() => {
    window.addEventListener("message", receiveUserInfo);
    receiveUserInfo();
    return () => {
      window.removeEventListener("message", receiveUserInfo);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      getUserNotiSet();
    }
  }, [userInfo]);

  const receiveUserInfo = (event) => {
    if (!event || event.origin !== "https://system.gbts.co.kr") return;
    setUserInfo(event.data);
  };

  const getUserNotiSet = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/logiwork/noti_set/JS_select_user_noti_set.php",
      msgID: msgID,
      data: [],
      cunsCOMPCD: userInfo.comp_cd,
      cunsUSERID: userInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"]) {
          noti_set = newData["returnData"];
          setNotiSet(noti_set);
        }
      }
    });
  };

  const saveUserNotiSet = (key, data) => {
    noti_set[key] = data;
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/logiwork/noti_set/JS_insert_user_noti_set.php",
      msgID: msgID,
      data: [],
      cunsCOMPCD: userInfo.comp_cd,
      cunsUSERID: userInfo.user_id,
      cunsSET: JSON.stringify(noti_set),
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        codeList.Modal.current.alert(newData["MSG"]);
      }
    });
  };

  const handleChangePage = (page) => {
    setActivePage(page.key);
  };

  const MappedPage = React.memo((props) => {
    const { activePage, page } = props;
    return (
      <div
        className={`flex-grow-1 pt-1 ${
          activePage !== page.key ? "d-none" : ""
        }`}
      >
        {page.component(props)}
      </div>
    );
  }, []);

  if (!userInfo) return <></>;

  return (
    <div
      className="vh-100 d-flex flex-column"
      style={{ background: "#CCCCCC", maxHeight: "100vh" }}
    >
      <AlarmTalkManageToolbar
        pages={pages}
        activePage={activePage}
        handleChangePage={handleChangePage}
      />
      {pages.map((page, index) => {
        return (
          <MappedPage
            key={page.key}
            page={page}
            activePage={activePage}
            userInfo={userInfo}
            notiSet={notiSet}
            saveUserNotiSet={saveUserNotiSet}
          />
        );
      })}
    </div>
  );
}
