/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import StoreReviewManageTable from "./StoreReviewManageTable";
import Utilities from "../../lib/Utilities";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};
const dateBtnList = [
  { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
  { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
  { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
  { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
  { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
];

const _mClassName = "StoreReviewManage";

let tempDate = new Date();
tempDate.setDate(new Date().getDate() - new Date().getDay());
export default function StoreReviewManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [searchStartDate, setSearchStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );

  const [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  const $controller = {
    searchText: useRef(),
    searchState: useRef(),
    searchStartDate: useRef(),
    searchEndDate: useRef(),
    searchHistoryStartDate: useRef(),
    searchHistoryEndDate: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
    $controller.searchStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    $controller.searchEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );
    searchFilterChanged();
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $controller.searchText.current.value,
      srShowYN: $controller.searchState.current.value,
      searchStartDate: $controller.searchStartDate.current.value,
      searchEndDate: $controller.searchEndDate.current.value,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_REVIEW_MANAGE_FILTER_CHANGED_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const searchWordClear = () => {
    $controller.searchText.current.value = "";
  };

  if (isLoad) return <CommonLoader />;
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="card-subtitle pt-3 text-muted font-weight-bold">
                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div className="btn-group mt-2" role="group">
                    {dateBtnList.map((btn, index) => {
                      return (
                        <button
                          key={index}
                          className="btn  btn-outline-info"
                          data-range={btn.value}
                          onClick={changeDatePicker}
                        >
                          {btn.desc}
                        </button>
                      );
                    })}
                  </div>
                  <div className="d-flex flex-row justify-content-end flex-wrap">
                    <div className="input-group mt-2 mr-0 mr-md-2">
                      <input
                        className="form-control"
                        type="date"
                        value={searchStartDate}
                        ref={$controller.searchStartDate}
                        onChange={(e) => {
                          setSearchStartDate(e.target.value);
                          if (e.target.value > searchEndDate) {
                            setSearchEndDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                      <span className="input-group-append">
                        <div className="input-group-text">-</div>
                      </span>
                      <input
                        className="form-control border-left-0"
                        type="date"
                        value={searchEndDate}
                        ref={$controller.searchEndDate}
                        onChange={(e) => {
                          setSearchEndDate(e.target.value);
                          if (e.target.value < searchStartDate) {
                            setSearchStartDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                    </div>
                    <div className="input-group order-1 order-md-1 order-lg-1  mt-2">
                      <select
                        className="custom-select form-control"
                        style={{ minWidth: 150 }}
                        ref={$controller.searchState}
                        onChange={searchFilterChanged}
                      >
                        <option value="">노출여부선택</option>
                        <option value="Y">공개</option>
                        <option value="N">비공개</option>
                      </select>

                      <input
                        ref={$controller.searchText}
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged();
                          }
                        }}
                        aria-describedby="btnGroupAddon2"
                        onClick={() => {
                          searchWordClear();
                        }}
                      />
                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                        onClick={() => {
                          searchFilterChanged();
                        }}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StoreReviewManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
