/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";
import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import { Link, useParams } from "react-router-dom";
const socket = new BuddibleSocket();
const util = new Utilities();

const add_file = {
  nt: "/notice/JS_notice_select.php",
  qa: "/qa/JS_qa_select.php",
  bb: "/bbs/JS_bbs_select.php",
  pr: "/promotion/JS_promotion_select.php",
  prq: "/product_request/JS_pr_select.php",
  ls: "/logistoryBoard/JS_postLogistorySelect.php",
};
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const _mClassName = "PostTable";

function Row(props) {
  const { row, headCells, index } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root].join(" ")}
      hover
      tabIndex={-1}
      // key={row.id}
      key={`row_${index}`}
    >
      {headCells.map((headCell, index) => (
        <TableCell
          // key={headCell.field}
          key={`TableCell_${index}`}
          align={headCell.numeric ? "right" : "left"} //text 정렬
          padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
        >
          {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}

Row.propTypes = {
  // row: PropTypes.shape({
  //   id: PropTypes.string.isRequired,
  // }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            // key={headCell.field}
            key={`TableCell_${index}`}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
let filters = {};

function PostTable(
  { isServerPaging = true, isServerSort = true, setActivePostData },
  ref
) {
  const classes = useStyles();
  const params = useParams();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("uID");

  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([]);

  const [tableCells, setTableCells] = useState(null);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_POST_TYPE_CHANGE,
      _mClassName,
      (b, n) => {
        if (n && n.hasOwnProperty("filters")) {
          filters = { ...n.filters };
          requestUserSelect();
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_POST_TYPE_CHANGE,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page]);

  const requestUserSelect = () => {
    setLoading(true);
    let msgID = util.makeUUIDv4();
    let file = add_file[params.post_type];

    let socketMsg = {
      file: file,
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useImperativeHandle(ref, () => ({
    changeTableData(e, params) {
      tableDataHandler(e, params);
    },
  }));

  const tableDataHandler = (e, tableData) => {
    let postType = tableData.id;
    if (postType === "prq" || postType === "ls") {
      postType = "pr";
    }
    let setData = [
      {
        renderCell: (params) => {
          let currentData = { ...params.currentData };
          let hash = "";
          for (let key in currentData) {
            if (key.indexOf("IDX") > -1) {
              hash = currentData[key];
            }
          }

          let createDate = currentData[`${postType}CreateDate`];
          if (createDate) {
            if (util.getBrowserType() === "Safari") {
              createDate = util.getDateToFormat(
                new Date(createDate.replace(/-/g, "/")),
                "YYYY-MM-DD"
              );
            } else {
              createDate = util.getDateToFormat(
                new Date(createDate),
                "YYYY-MM-DD"
              );
            }
          }

          let updateDate = currentData[`${postType}UpdateDate`];
          if (updateDate) {
            if (util.getBrowserType() === "Safari") {
              updateDate = util.getDateToFormat(
                new Date(updateDate.replace(/-/g, "/")),
                "YYYY-MM-DD"
              );
            } else {
              updateDate = util.getDateToFormat(
                new Date(updateDate),
                "YYYY-MM-DD"
              );
            }
          }

          let postImg = null;
          if (tableData.id === "ls") {
            if (currentData.prFiles) {
              postImg = currentData.prFiles.split("|");
            }
          }

          let prLOGO = "";
          if (currentData.prLOGO) {
            prLOGO = currentData.prLOGO.split("?")[0];
          }

          let postTitle = currentData[`${postType}TITLE`];
          if (currentData.prBCOMPNM) {
            postTitle = `[${currentData.prBCOMPNM}] ` + postTitle;
          }

          return (
            <>
              <Link
                className="text-dark"
                to={{
                  pathname: `/PostDetail/${tableData.id}/${hash}`,
                }}
              >
                <div className="post">
                  <div className="d-flex flex-row justify-content-start align-items-center mb-2">
                    {tableData.id === "ls" && (
                      <div
                        className="d-flex flex-row justify-content-center align-items-center rounded position-relative mr-3"
                        style={{
                          width: 50,
                          height: 50,
                          background: `url(${prLOGO}) 50% 50%/contain no-repeat`,
                        }}
                      >
                        {!prLOGO && (
                          <div className="h-100 w-100 rounded d-flex flex-row justify-content-center align-items-center font-weight-bold text-secondary bg-light text-center font-size-08">
                            <span>LOGO</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex-grow-1">
                      <h2 className="m-0 flex-fill ellipsis_2">{postTitle}</h2>
                    </div>
                  </div>
                  <div className="post_info clearfix d-flex flew-row justify-content-between">
                    <div className="post-left">
                      <ul>
                        <li>
                          <i className="icon-calendar-empty" /> 등록일자 :
                          <span>{createDate}</span>
                        </li>
                        <li>
                          <i className="icon-inbox-alt" />
                          {tableData.id === "prq"
                            ? "상품요청"
                            : currentData.sName || "-"}
                        </li>
                        {tableData.id === "prq" && (
                          <li>
                            <i className="icon-users" />
                            {currentData["prReadCnt"]}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>{" "}
                  <div
                    style={{
                      whiteSpace: "break-spaces",
                      wordBreak: "keep-all",
                      maxHeight: 300,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="post_content"
                      dangerouslySetInnerHTML={{
                        __html: currentData[`${postType}CONTENTS`],
                      }}
                    />
                  </div>
                  <div className="btn_1 mt-3">해당 게시글 보기</div>
                </div>
              </Link>
            </>
          );
        },
      },
    ];
    setTableCells(setData);
    requestUserSelect(tableData.id);
  };

  return (
    <TableContainer className="shadow-none border-0" component={Paper}>
      <Table aria-label="table">
        {tableCells !== null && (
          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    // key={row.id}
                    key={`row_1_${index}`}
                    row={row}
                    index={index}
                    headCells={tableCells}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    // key={row.id}
                    key={`row_2_${index}`}
                    row={row}
                    index={index}
                    headCells={tableCells}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    // key={row.id}
                    key={`row_3_${index}`}
                    row={row}
                    index={index}
                    headCells={tableCells}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  // key={row.id}
                  key={`row_4_${index}`}
                  row={row}
                  index={index}
                  headCells={tableCells}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={tableCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={tableCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

export default forwardRef(PostTable);

PostTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
