import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CommonNav from "../../../CommonNav";
import Loading from "../../../../lib/Loading";
import Footer from "../../../Footer";

import UploadImageToS3WithPhp from "../../../../lib/UploadImageToS3WithPhp";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ReserveAIR";

const times = [
  "00:00",
  "00:10",
  "00:20",
  "00:30",
  "00:40",
  "00:50",
  "01:00",
  "01:10",
  "01:20",
  "01:30",
  "01:40",
  "01:50",
  "02:00",
  "02:10",
  "02:20",
  "02:30",
  "02:40",
  "02:50",
  "03:00",
  "03:10",
  "03:20",
  "03:30",
  "03:40",
  "03:50",
  "04:00",
  "04:10",
  "04:20",
  "04:30",
  "04:40",
  "04:50",
  "05:00",
  "05:10",
  "05:20",
  "05:30",
  "05:40",
  "05:50",
  "06:00",
  "06:10",
  "06:20",
  "06:30",
  "06:40",
  "06:50",
  "07:00",
  "07:10",
  "07:20",
  "07:30",
  "07:40",
  "07:50",
  "08:00",
  "08:10",
  "08:20",
  "08:30",
  "08:40",
  "08:50",
  "09:00",
  "09:10",
  "09:20",
  "09:30",
  "09:40",
  "09:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
  "22:00",
  "22:10",
  "22:20",
  "22:30",
  "22:40",
  "22:50",
  "23:00",
  "23:10",
  "23:20",
  "23:30",
  "23:40",
  "23:50",
];

let memo_files = [];
let dgNoteActive = 0;
let flexiBagActive = 0;
let emailCnt = 0;
let emailList = [];
let airline_logo = "";
let supplier_logo = "";

export default function ReserveAIR(props) {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [loginInfo, setLoginInfo] = useState(null);
  const [isSuccessGetData, setIsSuccessGetData] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const navigate = useNavigate();

  const $controller = {
    sba_SHIPPER_NM: useRef(),
    sba_BLNO: useRef(),
    sba_PRODUCT_NM: useRef(),
    sba_CNT: useRef(),
    sba_QUANTITIES: useRef(),
    sba_BRING_TIME: useRef(),
    sba_FILES: useRef(),
    sba_MEMO: useRef(),
    sba_CFS_CODE: useRef(),
  };

  useEffect(() => {
    // SELECT_CFS_DATA();
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData && userData["returnData"].length) {
      emailList[0] = userData["returnData"][0].user_id;
      setLoginInfo(userData["returnData"][0]);
      SELECT_SCHEDULE_DATA();
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) {
          codeList.Modal.current.alert(
            "로그인 후 이용 가능한 서비스 입니다.",
            () => {
              window.location.replace();
            }
          );
        }
        emailList[0] = userData["returnData"][0].user_id;
        setLoginInfo(n["returnData"][0]);
        SELECT_SCHEDULE_DATA();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        console.log("데이터 통신에러", n);
      }
    );

    return () => {
      memo_files = [];
      dgNoteActive = 0;
      flexiBagActive = 0;

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  // CFS 정보 가져오기
  const SELECT_CFS_DATA = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_booking_cfs_select.php",
      msgID: msgID,
      data: {},
      smKey: "040077140",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("SELECT_CFS_DATA", newData);
    });
  };

  //스케쥴 정보 가져오기
  const SELECT_SCHEDULE_DATA = () => {
    const sm_IDX = params.hasOwnProperty("id") ? params.id : "";
    if (sm_IDX === "") {
      codeList.Modal.current.alert("잘못된 접근입니다.");
      window.history.back();
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_sub_select.php",
      msgID: msgID,
      data: {},
      sm_IDX: sm_IDX,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            airline_logo = newData["returnData"][0].sm_FWD_LOGO.split("?")[0];
            supplier_logo =
              newData["returnData"][0].sm_PROVIDER_LOGO.split("?")[0];
            let tempData = {
              ...newData["returnData"][0],
            };
            let detailData = socket.getLocalDataSet(
              MsgIDList.EVENT_MOVE_RESERVE_AIR
            );
            setScheduleData({ ...tempData, ...detailData });
            setIsSuccessGetData(true);
          } else {
            codeList.Modal.current.alert("잘못된 접근입니다.", () => {
              window.history.back();
            });
          }
        } else {
          codeList.Modal.current.alert(
            "데이터를 받아오는 중 오류가 발생하였습니다. 관리자에게 문의해주세요"
          );
        }
      }
    });
  };

  const handleUploadFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...memo_files];
    //파일명?파일주소
    dataList.push(`${val.realFileName}?${val.fileName}`);
    memo_files = dataList;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const deleteUploadFile = (index) => {
    memo_files.splice(index, 1);
    setIsNeedUpdate(!isNeedUpdate);
  };

  const addEmailCnt = () => {
    emailCnt += 1;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const removeEmailInput = (index) => {
    emailCnt -= 1;
    emailList.splice(index, 1);
    setIsNeedUpdate(!isNeedUpdate);
  };

  const createdEmailInput = () => {
    let temp = [];
    for (let i = 1; i <= emailCnt; i++) {
      temp.push(
        <div className="mb-1 d-flex flex-row justify-content-start align-items-center">
          <input
            className="form-control mr-2"
            placeholder="이메일을 입력해주세요"
            value={emailList[i]}
            onChange={(e) => {
              emailList[i] = e.target.value;
            }}
          />
          <button
            className="btn btn-danger m-0"
            onClick={() => {
              removeEmailInput(i);
            }}
          >
            -
          </button>
        </div>
      );
    }
    return temp;
  };

  //입력값 검사
  const checkValidation = () => {
    if ($controller.sba_SHIPPER_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("실화주 정보를 입력해주세요.");
      return false;
    }
    if ($controller.sba_BLNO.current.value.trim() === "") {
      codeList.Modal.current.alert("H B/L NO를 입력해주세요.");
      return false;
    }
    if ($controller.sba_PRODUCT_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("선적예정품목명을 입력해주세요.");
      return false;
    }
    if ($controller.sba_CNT.current.value.trim() === "") {
      codeList.Modal.current.alert("부킹물량을 입력해주세요.");
      return false;
    }
    if ($controller.sba_QUANTITIES.current.value.trim() === "") {
      codeList.Modal.current.alert("단위를 입력해주세요.");
      return false;
    }
    if ($controller.sba_BRING_TIME.current.value.trim() === "") {
      codeList.Modal.current.alert("반입(예정)시간을 선택해주세요.");
      return false;
    }

    return true;
  };

  //임시저장
  const actionSave = () => {
    codeList.Modal.current.confirm(
      "해당 스케줄에 대해서 예약요청 정보를 제출하시겠습니까?",
      (ret) => {
        if (ret) {
          if (checkValidation()) {
            let data = {
              sba_IDX_MAIN: scheduleData.sm_IDX,
              sba_COMP_CD: loginInfo.comp_cd,
              sba_COMP_NM: loginInfo.comp_nm,
              sba_USER_ID: loginInfo.user_id,
              sba_USER_NM: loginInfo.user_nm,
              sba_SHIPPER_NM: null,
              sba_BLNO: null,
              sba_PRODUCT_NM: null,
              sba_CNT: null,
              sba_QUANTITIES: null,
              sba_BRING_TIME: null,
              sba_FILES: memo_files.length > 0 ? memo_files.join("|") : "",
              sba_MEMO: null,
              sba_EMAIL: emailList.join("|"),
              sba_CFS_CODE: null,
              sba_STATUS: "N",
              sba_STATUS2: "A",
              sba_FLIGHT_DATE: util.getDateToFormat(
                scheduleData.realDate,
                "YYYY-MM-DD"
              ),
              sba_CREATE_USERID: loginInfo.user_id,
              sba_CREATE_COMPCD: loginInfo.comp_cd,
              sba_UPDATE_USERID: loginInfo.user_id,
              sba_UPDATE_COMPCD: loginInfo.comp_cd,
              sba_FWD_NO: scheduleData.sm_FWD_NO,
              sba_FWD_NM: scheduleData.sm_FWD_NM,
              sba_PROVIDER_CODE: scheduleData.sm_PROVIDER_CODE,
              sba_PROVIDER_ID: scheduleData.sm_PROVIDER_ID,
              sba_PROVIDER_SALES_ID: scheduleData.sm_PROVIDER_SALES_ID,
              sba_DEPARTURE_DATE: scheduleData.sm_DEPARTURE_DATE,
              sba_VESSEL: scheduleData.sm_VESSEL,
              sba_CARGO_YN: scheduleData.sm_CARGO_YN,
              sba_POL_CODE: scheduleData.sm_POL_CODE,
              sba_POD_CODE: scheduleData.sm_POD_CODE,
              sba_EXP_IMP: scheduleData.sm_EXP_IMP,
              sba_INFO_MON: scheduleData.sm_INFO_MON,
              sba_INFO_TUE: scheduleData.sm_INFO_TUE,
              sba_INFO_WED: scheduleData.sm_INFO_WED,
              sba_INFO_THU: scheduleData.sm_INFO_THU,
              sba_INFO_FRI: scheduleData.sm_INFO_FRI,
              sba_INFO_SAT: scheduleData.sm_INFO_SAT,
              sba_INFO_SUN: scheduleData.sm_INFO_SUN,
              sba_REMARK: scheduleData.sm_REMARK,
              //   --------------이메일에 사용되는 데이터
              sm_PROVIDER_NM: scheduleData.sm_PROVIDER_NM,
              FLIGHT_NAME: scheduleData.FLIGHT_NAME,
              AIR_FREIGHT_NO: scheduleData.AIR_FREIGHT_NO,
              sm_ORIGIN: scheduleData.sm_ORIGIN,
              sm_POL_CODE: scheduleData.sm_POL_CODE,
              DEPARTURE_TIME: scheduleData.DEPARTURE_TIME,
              sm_DEST: scheduleData.sm_DEST,
              sm_POD_CODE: scheduleData.sm_POD_CODE,
              AIRRIVE_TIME: scheduleData.AIRRIVE_TIME,
              AIR_FREIGHT_MEMO: scheduleData.AIR_FREIGHT_MEMO,
              sm_REMARK: scheduleData.sm_REMARK,
            };
            for (let key in $controller) {
              if ($controller[key].current)
                data[key] = $controller[key].current.value || "";
            }
            INSERT_RESERVE_SCHEDULE_AIR(data);
          }
        }
      }
    );
  };

  //FCL 예약정보 서버통신
  const INSERT_RESERVE_SCHEDULE_AIR = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_booking_air/JS_schedule_booking_air_insert.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    setIsLoading(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("예약요청이 정상적으로 처리되었습니다.");
          navigate(-1);
        } else {
          codeList.Modal.current.alert(
            "예약 요청 처리중 오류가 발생하였씁니다."
          );
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {isLoading && <Loading />} <CommonNav />
      <main style={{ marginTop: 60 }}>
        {isSuccessGetData && loginInfo && scheduleData ? (
          <div className="container">
            <div className="row py-3 m-0 border-bottom">
              <div className="col-xl-12 m-0 p-0">
                <span className="h5 font-weight-bold mb-0">예약하기</span>
              </div>
            </div>
            <div className="py-3 border-bottom">
              {/*요청자*/}
              <div className="row m-0 mb-3 p-0">
                <div className="col-12 m-0 p-0">
                  <span className="h6 mb-0">
                    ■ 요청자 :{" "}
                    <span className="font-weight-bold">
                      [{loginInfo.comp_nm}]{" "}
                      {loginInfo.USER_NICK || loginInfo.user_nm}
                    </span>
                    님
                  </span>
                </div>
              </div>

              {/*요청 스케쥴정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <p className="h6 mb-1 font-weight-bold">요청스케쥴 정보</p>{" "}
                  <p>- 요청 일자 : {scheduleData.dateTxt}</p>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <div className="row m-0 p-0 border border-right-0">
                    <div className="col-12 col-lg-4 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        항공사
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            background: `url(${airline_logo}) 50% 50% / contain no-repeat`,
                          }}
                        />
                        <p>{scheduleData.sm_FWD_NM}</p>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        공급사
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            background: `url(${supplier_logo}) 50% 50% / contain no-repeat`,
                          }}
                        />
                        <p>{scheduleData.sm_PROVIDER_NM}</p>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        편명
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p>{scheduleData.FLIGHT_NAME}</p>
                        <p>{scheduleData.AIR_FREIGHT_NO}</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        출발시간
                      </div>

                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p className="font-weight-bold">
                          {scheduleData.sm_ORIGIN}
                        </p>
                        <p className="font-weight-bold text-danger">
                          {scheduleData.sm_POL_CODE}{" "}
                          {scheduleData.DEPARTURE_TIME}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        도착시간
                      </div>

                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p className="font-weight-bold">
                          {scheduleData.sm_DEST}
                        </p>
                        <p className="font-weight-bold text-danger">
                          {scheduleData.sm_POD_CODE} {scheduleData.AIRRIVE_TIME}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 p-0 ">
                    <div className="col-12 m-0 p-0">
                      ※ 안내사항 : {scheduleData.sm_REMARK || "-"}
                    </div>
                  </div>
                </div>
              </div>

              {/*품목 정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <p className="h6 mb-0 font-weight-bold">품목 정보</p>{" "}
                  <p className="d-flex flex-row justify-content-start align-items-center">
                    - 아래의 표에 품목정보를 입력해주세요{" "}
                    <span
                      className="d-inline-block bg-info-light"
                      style={{ height: 20, width: 50 }}
                    />{" "}
                    입력항목
                  </p>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <div className="row m-0 mb-2 p-0 border border-right-0">
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        화주 상호
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="w-100 border-0 bg-info-light"
                          ref={$controller.sba_SHIPPER_NM}
                          placeholder="실화주 상호를 입력하세요"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        H B/L NO
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="w-100 border-0 bg-info-light"
                          ref={$controller.sba_BLNO}
                          placeholder="귀사의 B/L 번호를 입력하세요"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        선적예정 품목명
                      </div>
                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="w-100 border-0 bg-info-light"
                          ref={$controller.sba_PRODUCT_NM}
                          placeholder="대표 품목을 입력하세요"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        부킹물량
                      </div>

                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="w-100 border-0 bg-info-light"
                          ref={$controller.sba_CNT}
                          placeholder="물량을 입력하세요"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        단위
                      </div>

                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="w-100 border-0 bg-info-light"
                          ref={$controller.sba_QUANTITIES}
                          placeholder="KG"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        반입(예정)시간
                      </div>

                      <div className="p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <div className="bg-info-light w-100">
                          <select
                            className="border-0 bg-transparent"
                            ref={$controller.sba_BRING_TIME}
                          >
                            {times.map((time) => {
                              return (
                                <option key={time} value={time}>
                                  {time}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0 ">
                      <div style={{ width: 200 }}>
                        <UploadImageToS3WithPhp
                          region={`mall/reserveAIR/${scheduleData.sm_IDX}`}
                          callback={(val) => {
                            handleUploadFile(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 m-0 p-0">
                      {memo_files.length > 0 &&
                        memo_files.map((file, index) => {
                          let splitData = file.split("?");
                          let fileLink = splitData[1];
                          let fileName = splitData[0];
                          return (
                            <div
                              key={fileLink + "_" + index}
                              className="d-flex flex-row align-items-center mb-1"
                            >
                              <span
                                className="cursor_pointer text-underline text-primary mr-2"
                                onClick={() => {
                                  window.open(
                                    fileLink,
                                    "_blank",
                                    "width=1200, height=800"
                                  );
                                }}
                              >
                                {fileName}
                              </span>
                              <button
                                className="btn btn-sm btn-danger px-2 py-1  font-size-06"
                                onClick={() => deleteUploadFile(index)}
                              >
                                삭제
                              </button>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/*인천공항 반입지 정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-12 m-0 mb-2 p-0">
                  <p className="h6 mb-0 font-weight-bold">반입지 정보</p>{" "}
                </div>
                <div className="col-12 m-0 mb-2 p-0">
                  <div className="row m-0 mb-2 p-0 border border-right-0">
                    <div className="col-4 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        CFS CODE
                      </div>
                      <div className="bg-light p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <input
                          className="border-0 bg-light text-center"
                          ref={$controller.sba_CFS_CODE}
                          value={"040077140"}
                        />
                      </div>
                    </div>
                    <div className="col-8 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        주소/전화번호
                      </div>
                      <div className="bg-light p-1 flex-fill d-flex flex-column align-items-start justify-content-cener">
                        [(주)더블유코리아] 주소 : 인천시 중구 공항동로 296번길
                        98-30 범한 판토스 내 27-28 GATE(TEL : 032-744-7404)
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Booking 요청 메모*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <p className="h6 mb-0 font-weight-bold">Booking 요청 메모</p>{" "}
                </div>
                <div className="col-12 m-0 p-0">
                  <textarea
                    className="form-control"
                    rows={5}
                    placeholder="예약요청시 특이사항을 작성해주세요"
                    ref={$controller.sba_MEMO}
                  />
                </div>
              </div>

              {/*Booking 정보 회신처*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <p className="h6 mb-0 font-weight-bold">
                    Booking 정보 회신처
                  </p>{" "}
                </div>
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 p-0 ">
                    <div className="col-4 m-0 p-0">이메일</div>
                    <div className="col-8 m-0 p-0">
                      <div className="mb-1 d-flex flex-row justify-content-start align-items-center">
                        <input
                          className="form-control mr-2"
                          readOnly={true}
                          value={loginInfo.user_id}
                        />
                        <button
                          className="btn btn-primary m-0"
                          onClick={() => {
                            addEmailCnt();
                          }}
                        >
                          +
                        </button>
                      </div>
                      {createdEmailInput()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right my-3">
              <button
                className="btn btn-sm btn-outline-info text-freiscop border-freiscop mr-2"
                onClick={() => {
                  actionSave();
                }}
              >
                제출하기
                <i className="fa-solid fa-arrow-up-from-bracket ml-1" />
              </button>
            </div>
          </div>
        ) : (
          <div className="container text-center">
            <div className="margin_60">
              <p className="h6 font-weight-bold">
                데이터를 받아오는데 실패하였습니다. 다시 시도해주세요
              </p>
              <button
                className="btn btn-primary border-freiscop bg-freiscop"
                onClick={() => {
                  navigate(-1);
                }}
              >
                뒤로가기
              </button>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}
