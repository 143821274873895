/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CityManageTable from "./CityManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();

const _mClassName = "CityManage";

function CityManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_CITY_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                  <div
                    className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                    role="toolbar"
                  >
                    <div
                      className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                      role="group"
                    >
                      <button
                        className="btn  btn-outline-primary "
                        data-toggle="modal"
                        data-target="#staticCityEditModal"
                        onClick={addNewData}
                        aria-label={"신규등록"}
                        type="button"
                      >
                        <i className="fas fa-plus" /> 신규등록
                      </button>
                    </div>
                  </div>
                </h6>
              </div>
              <CityManageTable isServerPaging={true} isServerSort={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CityManage;
