/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import FCLShippingDetailData from "./FCLShippingDetailData";

export default function FCLShippingDetail() {
  return (
    <div className="container">
      <FCLShippingDetailData />
    </div>
  );
}
