/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";

import AdminCompanyInfoManageTable from "./AdminCompanyInfoManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "AdminCompanyInfoManageTableWrap";
const BUSINESS_TYPE_B = [
  { key: "B03", name: "선사" },
  { key: "B01", name: "포워더" },
  // { key: "B02", name: "콘솔사" },
];
const BUSINESS_TYPE = [
  { key: "C", name: "운송사" },
  { key: "D", name: "보험사" },
  { key: "E", name: "관세사" },
  { key: "F", name: "보세창고" },
  { key: "Z", name: "GBTS 관리자" },
];

let filters = { COMP_GB: "B" };
let bigCd = "";
let comp_custom_info_list = {};
let isLoading = true;
function AdminCompanyInfoManageTableWrap(props) {
  const [selectBusiness, setSelectBusiness] = useState("B");
  const [serverSubData, setServerSubData] = useState([]);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const $searchText = useRef();
  const $searchLevel = useRef();

  useEffect(() => {
    SELECT_CUSTOM_COMP_INFO();
  }, []);

  const SELECT_CUSTOM_COMP_INFO = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_list.php",
      msgID: msgID,
      data: [],
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            let data = newData["returnData"][i];
            comp_custom_info_list[data.COMP_CD] = { ...data };
          }
          isLoading = false;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const changeSelectedBusiness = (key) => {
    if (key.length < 3) {
      filters = {
        COMP_GB: key,
      };
    } else {
      filters = {
        COMP_GB_SUB2: key,
      };
    }
    if (key === "B") {
      bigCd = "CTB";
    } else if (key === "F") {
      bigCd = "CTF";
    } else {
      bigCd = "";
    }

    if (bigCd !== "") {
      requestCompCdSub();
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_ADMIN_COMPANY_INFO_MANAGE_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
    setSelectBusiness(key);
  };

  const requestCompCdSub = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/app/JS_comp_sub_list.php",
      msgID: msgID,
      data: [],
      bigCd: bigCd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerSubData(newData["returnData"]);
        }
      }
    });
  };

  const selectCompSubType = (e) => {
    let val = e.target.value;
    filters = {
      ...filters,
      COMP_GB_SUB2: val,
    };

    if (selectBusiness !== "") {
      filters.COMP_GB = selectBusiness;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_ADMIN_COMPANY_INFO_MANAGE_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    filters = {
      ...filters,
      [$searchLevel.current.value]: $searchText.current.value,
    };

    if (selectBusiness !== "") {
      filters.COMP_GB = selectBusiness;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_ADMIN_COMPANY_INFO_MANAGE_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
    $searchLevel.current.value = "";
    $searchText.current.value = "";
  };

  return (
    <>
      {!isLoading && (
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <div className="card w-100">
                <div style={{ padding: ".75rem 1.25rem" }}>
                  <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                    <div
                      className="btn-toolbar mb-3 justify-content-end"
                      role="toolbar"
                    >
                      <div>
                        <div className="d-flex flex-wrap flex-row flex-wrap align-items-center mb-2">
                          {BUSINESS_TYPE_B.map((bType, index) => {
                            return (
                              <a
                                key={bType.key}
                                className={`btn font-weight-bold ${
                                  BUSINESS_TYPE.length - 1 !== index
                                    ? "mr-2 "
                                    : ""
                                }${
                                  filters.COMP_GB === bType.key
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                                }`}
                                onClick={() => {
                                  changeSelectedBusiness(bType.key);
                                }}
                              >
                                {bType.name}
                              </a>
                            );
                          })}{" "}
                          {BUSINESS_TYPE.map((bType, index) => {
                            return (
                              <a
                                key={bType.key}
                                className={`btn  font-weight-bold ${
                                  BUSINESS_TYPE.length - 1 !== index
                                    ? "mr-2 "
                                    : ""
                                }${
                                  filters.COMP_GB === bType.key
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                                }`}
                                onClick={() => {
                                  changeSelectedBusiness(bType.key);
                                }}
                              >
                                {bType.name}
                              </a>
                            );
                          })}
                        </div>

                        <div className=" input-group order-1 order-md-1 order-lg-1">
                          {bigCd !== "" ? (
                            <select
                              className="input-group-append form-control border"
                              style={{ minWidth: 150 }}
                              onChange={(e) => selectCompSubType(e)}
                            >
                              <option value="">업종상세</option>
                              {serverSubData &&
                                serverSubData.map((item, index) => {
                                  return (
                                    <option
                                      key={item.cIDX}
                                      label={item.cNAME}
                                      value={item.cSMALLCD}
                                    >
                                      {item.cNAME}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            ""
                          )}
                          <select
                            className="input-group-append form-control border"
                            ref={$searchLevel}
                            style={{ minWidth: 150 }}
                          >
                            <option value="">검색조건</option>
                            <option value="COMP_CD">업체코드</option>
                            <option value="COMP_NUM">사업자등록번호</option>
                            {/*<option value="COMP_GB">업체구분</option>*/}
                            <option value="COMP_NM">업체명</option>
                          </select>
                          <input
                            type="text"
                            className="form-control border"
                            style={{ minWidth: 200 }}
                            placeholder="검색어를 입력해 주세요"
                            aria-label="검색어"
                            aria-describedby="btnGroupAddon2"
                            onKeyPress={(e) => {
                              if (e.charCode === 13) {
                                searchFilterChanged(e);
                              }
                            }}
                            ref={$searchText}
                          />

                          <div
                            className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                            style={{
                              borderRadius: "0px 3px 3px 0",
                            }}
                            onClick={searchFilterChanged}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </div>
                        </div>
                      </div>
                    </div>
                  </h6>
                </div>
                <div className="card-body p-0">
                  <AdminCompanyInfoManageTable
                    comp_custom_info_list={comp_custom_info_list}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminCompanyInfoManageTableWrap;
