import React, { useCallback, useEffect, useRef, useState } from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import MMSI from "../../main/mall/schedule/mmsiList";
import modal from "../../lib/Modal";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

let bookingEmail = [];

let oceanlookWebToken =
  "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";
export default function ReserveLinkModal({
  loginInfo,
  setIsReserveLinkOpen,
  modal_data,
}) {
  const [uploadFile, setUploadFile] = useState([]);
  const [emailCount, setEmailCount] = useState(1);
  let payment = "A";
  let files = [];

  const $controller = {
    p_name: useRef(),
    p_comp_nm: useRef(),
    p_user: useRef(),
    p_cnt: useRef(),
    p_quantities: useRef(),
    p_memo: useRef(),
    linkUrl: useRef(),
  };

  // console.log(modal_data);

  let mmsiNum = mmsi.getMMSI(modal_data.sm_VESSEL);

  let randLink = util.buddibleEncrypt(
    `${modal_data.sb_IDX}|${modal_data.sb_IDX_MAIN}`
  );

  if (modal_data.sb_FILE) {
    files = modal_data.sb_FILE.split("|");
  }

  //첨부파일 다운로드
  const fileDownload = (url, name) => {
    codeList.Modal.current.confirm(
      "첨부된 파일을 다운로드하시겠습니까?",
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  // 링크 복사
  const copyLink = () => {
    const el = $controller.linkUrl.current;
    el.select();
    document.execCommand("copy");
    if (document.execCommand("copy")) {
      codeList.Modal.current.alert("복사되었습니다.", () => {});
    }
  };
  return (
    <>
      <div className="modal_background">
        <div className="modal_body bg-white rounded w-50">
          <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
            {/*<p className="h5 mr-3 p-0 m-0">예약하기</p>*/}
            <div className="modal-title">BOOK INFORMATION</div>
            <div
              className="btn btn-sm btn-danger"
              onClick={() => setIsReserveLinkOpen(false)}
            >
              <i className="fa-solid fa-xmark" />
            </div>
          </div>

          <div
            className="overflow_scroll-Y p-3 px-4"
            style={{ maxHeight: "calc(100vh - 200px)", minWidth: 320 }}
          >
            <div className="row border rounded m-2">
              <div className="col-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-left border-bottom"
                  style={{ minWidth: 100 }}
                >
                  FORWARDER NAME
                </div>
                <div className="p-2 text-left">{modal_data.sm_FWD_NM}</div>
              </div>
              <div className="col-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-left border-bottom"
                  style={{ minWidth: 100 }}
                >
                  BOOKING NO
                </div>
                <div className="p-2 text-center">{modal_data.sb_IDX}</div>
              </div>
            </div>

            <div className="row border rounded m-2">
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  SCHEDULE NO
                </div>
                <div className="p-2 text-center">{modal_data.sb_IDX_MAIN}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  FCL/LCL
                </div>
                <div className="p-2 text-center">{modal_data.sm_FCL_LCL}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  VESSEL
                </div>
                <div className="p-2 text-center">{modal_data.sm_VESSEL}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  VOY
                </div>
                <div className="p-2 text-center">{modal_data.sm_VOY}</div>
              </div>
            </div>

            <div className="row border rounded m-2">
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  DOC CLOSE
                </div>
                <div className="p-2 text-center">{modal_data.sm_DOC_CLOSE}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  CARGO CLOSE
                </div>
                <div className="p-2 text-center">
                  {modal_data.sm_CARGO_CLOSE}
                </div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  POL
                </div>
                <div className="p-2 text-center">
                  {modal_data.sm_DEPARTURE_DATE}
                </div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  POD
                </div>
                <div className="p-2 text-center">
                  {modal_data.sm_ARRIVE_DATE}
                </div>
              </div>
            </div>

            <div className="row border rounded m-2">
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  SHIPPER
                </div>
                <div className="p-2 text-center">{modal_data.sb_COMP_NM}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  H/BL No
                </div>
                <div className="p-2 text-center">{modal_data.sb_USER_NM}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  QTY
                </div>
                <div className="p-2 text-center">{modal_data.sb_CNT}</div>
              </div>
              <div className="col-md-3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  UNIT
                </div>
                <div className="p-2 text-center">
                  {modal_data.sb_QUANTITIES}
                </div>
              </div>
            </div>

            <div className="row rounded m-2">
              <div className="col-md-3 border rounded-left p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center"
                  style={{ minWidth: 100 }}
                >
                  CFS
                </div>
              </div>
              <div className="col-md-5 border rounded-right p-0">
                <div
                  className="p-2 font-weight-bold text-center"
                  style={{ minWidth: 100 }}
                >
                  {modal_data.sb_CFS}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3 border rounded p-0">
                <div
                  className="p-2 bg-success text-white font-weight-bold text-center cursor_pointer"
                  style={{ minWidth: 100 }}
                >
                  CFS 상세정보
                </div>
              </div>
            </div>

            <div className="p-2 py-3">
              <p className="h6 font-weight-bold mb-2">■ 처리상태</p>
              <p>
                {
                  {
                    Y: "예약확정",
                    N: "예약요청",
                    B: "B/L 발행",
                  }[modal_data.sb_STATUS]
                }
              </p>
            </div>

            {files && files.length > 0 && (
              <div className="p-2 py-3">
                <p className="h6 font-weight-bold mb-2">■ 첨부파일</p>
                <p className="mb-2">
                  - 파일명을 클릭시 브라우저를 통해 미리 확인이 가능합니다.
                </p>
                <div className="mb-2">
                  {files.map((item, index) => {
                    let splitFile = item.split("?");
                    let fileName = splitFile[0];
                    let fileLink = splitFile[1];
                    return (
                      <p key={item + "_" + index}>
                        <span
                          className="text-primary font-weight-bold cursor_pointer mr-2"
                          onClick={() => {
                            window.open(fileLink, "_blank");
                          }}
                        >
                          {fileName}
                        </span>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            fileDownload(fileLink, fileName);
                          }}
                        >
                          다운로드
                        </button>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            {mmsiNum && (
              <div className="p-2 py-3">
                <p className="h6 font-weight-bold mb-2">■ 선박위치정보</p>
                <iframe
                  className="w-100"
                  height="400"
                  src={`https://www.oceanlook.net/v3/popup?token=${oceanlookWebToken}&mmsi=${mmsiNum}`}
                ></iframe>
              </div>
            )}

            <div className="p-2 py-3">
              <p className="h6 font-weight-bold mb-2">■ 링크주소</p>
              <div className="input-group">
                <input
                  className="form-control"
                  readOnly={true}
                  value={`https://gbts.co.kr/reserveLink/${randLink}`}
                  ref={$controller.linkUrl}
                />
                <button
                  className="btn btn-outline-primary ml-2"
                  onClick={() => {
                    copyLink();
                  }}
                >
                  복사
                </button>
              </div>
            </div>
            <div className="p-3 d-flex flex-row justify-content-end align-items-center border-top">
              <div
                className="btn btn-primary"
                className="btn btn-danger mr-1"
                onClick={() => setIsReserveLinkOpen(false)}
              >
                취소
              </div>
              <div className="btn btn-primary ml-1">제출</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
