/* eslint-disable no-unused-vars */
/*global kakao*/
import React, { useEffect, useRef, useState } from "react";
import CommonNav from "../main/CommonNav";
import LoadingUI from "../lib/LoadingUI";
import StoreList from "./components/StoreList";
import StoreSummaryData from "./components/StoreSummaryData";
import StoreImageSlide from "./components/StoreImageSlide";
import StoreDetailContact from "./components/StoreDetailContact";
import StoreReview from "./components/StoreReview";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import StoreMallMap from "./components/StoreMallMap";
import { useParams } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let storeData = [];

export default function StoreMallMain() {
  const param = useParams();
  const [loginInfo, setLoginInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [isStoreListView, setIsStoreListView] = useState(true);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isSearchWord, setIsSearchWord] = useState("");
  const [wordFilter, setWordFilter] = useState("");
  const [isImageSlideView, setIsImageSlideView] = useState(false);
  const [imageSlideIndex, setImageSlideIndex] = useState(0);
  const [isContactView, setIsContactView] = useState(false);
  const [currentView, setCurrentView] = useState(0); //0:데스크탑, 1: 테블릿, 2:모바일
  const [isMobileListView, setIsMobileListView] = useState(false);
  const isLogiWork = window.location.pathname.startsWith("/freiscop");

  //-------review-----------
  const [storeReviewList, setStoreReviewList] = useState([
    {
      srIDX: "1", // 리뷰관리번호
      srKey: "", // 스토어 키
      srUserName: "테스트", // 리뷰자 성명
      srUserCompCD: "Z0001", // 리뷰자 업체코드
      srUserID: "buddible@buddible.com", // 리뷰자 아이디
      srRating: "4", // 리뷰점수
      srReviewText:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore doloremque eius labore officiis optio qui quia sunt suscipit? Consequatur eveniet hic libero rerum vel veritatis? Animi corporis culpa delectus dolore dolorem ea earum error, illo inventore laboriosam, maxime natus odio pariatur placeat quibusdam soluta unde! Assumenda dolorum incidunt repellendus sapiente!", // 리뷰내용
      srReviewPWD: "", // 리뷰 비밀번호
      srCreateDate: new Date().toLocaleDateString(), // 작성일
      srEditDate: new Date().toLocaleDateString(), // 수정일
      srShowYN: "N", // 노출여부
    },
  ]);

  const $search_word = useRef();
  const $store_url = useRef();

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "StoreMallMain",
      (b, n) => {
        if (!n) return;
        if (!n["returnData"].length) return;
        setLoginInfo(n["returnData"][0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      "StoreMallMain",
      (b, n) => {
        setLoading(false);
      }
    );
  })();

  useEffect(() => {
    mobileHandler();
    window.addEventListener("resize", mobileHandler);

    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData["returnData"].length) {
        setLoginInfo(userData["returnData"][0]);
      }
    }
    if (param.store_key) {
      $search_word.current.value = param.store_key;
      SEARCH_STORE();
    }

    return () => {
      window.removeEventListener("resize", mobileHandler);

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "StoreMallMain"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        "StoreMallMain"
      );
    };
  }, []);

  const mobileHandler = () => {
    let innerWindow = window.innerWidth;
    if (innerWindow > 1600) {
      setCurrentView(0);
    } else if (innerWindow <= 1600 && innerWindow > 900) {
      setCurrentView(1);
    } else {
      setCurrentView(2);
    }
  };

  const SEARCH_STORE = () => {
    let value = $search_word.current.value;

    setLoading(true);
    setWordFilter(value);
    setIsSearchWord(value);
    getStoreListByKeyword(value);

    /*if (value !== "") {
      setLoading(true);
      setWordFilter(value);
      setIsSearchWord(value);
      getStoreListByKeyword(value);
    } else {
      setWordFilter("");
      setIsSearchWord("");
      getStoreListByKeyword("");
    }*/
  };

  const getStoreListByKeyword = (keyword) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_CUSTOMS_STORE_KEYWORD_CHANGED,
      "StoreMallMain",
      { keyword: keyword },
      () => {}
    );
  };

  const SELECTED_STORE = (value) => {
    let storeData = {};
    for (let key in value) {
      if (
        key !== "sdTypeKind" &&
        key !== "sdTypeGoods" &&
        key !== "sdPhoto" &&
        key.indexOf("Contact") === -1
      ) {
        storeData[key] = value[key] ? value[key] : "등록된 정보가 없습니다.";
      } else {
        storeData[key] = value[key] ? value[key] : "";
      }
    }
    setIsStoreListView(false);
    setSelectedStore(storeData);
  };

  const SELECTED_STORE_CLEAR = () => {
    window.location.hash = "";
    setIsStoreListView(true);
    setSelectedStore(null);
  };

  const STORE_LIST_VIEW = () => {
    window.location.hash = "";
    setIsStoreListView(true);
  };

  const SET_STORE_LIST = (value) => {
    setLoading(false);
    setWordFilter("");
    if (Array.isArray(value)) {
      storeData = [...value];
      setStoreList(value);
    }
  };

  useEffect(() => {
    if (selectedStore !== null) {
      getStoreReview(selectedStore);
    }
  }, [selectedStore]);

  const getStoreReview = (store) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/review/JS_store_review_select.php",
      msgID: msgID,
      data: {},
      srKey: store.smKey, //store.smKey,
      srShowYN: "Y",
    };
    setLoading(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      //console.log(newData);
      if (newData) {
        if (newData.ret) {
          storeListDataHandler(newData["returnData"]);
          setLoading(false);
        }
      }
    });
  };

  const storeListDataHandler = (reviewList) => {
    let dataList = [];
    for (let i = 0; i < reviewList.length; i++) {
      let review = reviewList[i];
      if (
        review.srKey === loginInfo["COMP_C_NUM"] ||
        review.srUserID === loginInfo["user_id"] ||
        review.srPublicYN === "Y"
      ) {
        review.isShow = true;
      } else {
        review.isShow = false;
      }
      dataList.push(review);
    }
    setStoreReviewList(dataList);
  };

  useEffect(() => {
    if (storeList.length > 0) {
      if (window.location.hash) {
        let hashDataKey = window.location.hash.split("?")[0].split("#");
        hashDataKey = hashDataKey[1];
        let dataList = [...storeList];
        let selectedStore = dataList.find((data) => data.smKey === hashDataKey);
        SELECTED_STORE(selectedStore);
      }
    }
  }, [storeList, isStoreListView, wordFilter]);

  const slideImagChange = (index) => {
    setImageSlideIndex(index);
  };

  const slideImagOpen = (index) => {
    slideImagChange(index);
    setIsImageSlideView(true);
  };
  const slideImagClose = () => {
    setImageSlideIndex(0);
    setIsImageSlideView(false);
  };

  const selectMapMarker = (storeKey) => {
    let storeListData = [...storeData];
    let getStore = storeListData.find((data) => data.smKey === storeKey);
    if (getStore) {
      SELECTED_STORE(getStore);
    } else {
      console.log("selectMapMarker 창고정보 못찾음", getStore);
    }
  };

  const storeRequestInsert = (uploadData) => {
    if (checkValidation(uploadData)) {
      let msgID = util.makeUUIDv4();
      let socketMsg = {
        file: "/customsStore/JS_store_request_insert.php",
        msgID: msgID,
        data: {},
        ...uploadData,
      };
      setLoading(true);

      socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
        if (newData) {
          let msg = newData.ret
            ? "문의요청이 정상처리되었습니다."
            : "요청에 실패하였습니다. 관리자에게 문의 바랍니다.";
          codeList.Modal.current.alert(msg);
          setLoading(false);
        }
      });
    }
  };

  const checkValidation = (uploadData) => {
    if (uploadData.srType.trim() === "") {
      codeList.Modal.current.alert("문의종류는 필수 작성입니다.");
      return false;
    }
    if (uploadData.srUserName.trim() === "") {
      codeList.Modal.current.alert("회신 담당자명은 필수 작성입니다.");
      return false;
    }
    if (uploadData.srEmail.trim() === "") {
      codeList.Modal.current.alert("회신 이메일은 필수 작성입니다.");
      return false;
    }
    if (uploadData.srMobile.trim() === "") {
      codeList.Modal.current.alert("회신 연락처는 필수 작성입니다.");
      return false;
    }

    return true;
  };

  const storeURLCopy = () => {
    let dummy = document.createElement("input");
    let text = `https://www.gbts.co.kr/storeMall/${selectedStore.smKey}`;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    codeList.Modal.current.alert("창고 정보를 담은 URL이 복사되었습니다.");
  };

  const moveStoreDetailScroll = () => {
    let scrollHeight =
      document.getElementById("storeDetail").scrollHeight -
      document.getElementById("storeDetail").offsetHeight -
      100;
    document.getElementById("storeDetail").scrollTo(0, scrollHeight);
  };

  const createLeftDetailImage = (photo) => {
    if (!photo) return;
    let splitData = photo.split("|")[0].split("?");

    return (
      <img
        className="cursor_pointer"
        src={splitData[0]}
        alt={splitData[0]}
        style={{ height: 200 }}
        onClick={() => {
          slideImagOpen(0);
        }}
      />
    );
  };

  const addReviewListHandler = () => {
    if (selectedStore !== null) {
      getStoreReview(selectedStore);
    }
  };

  return (
    <>
      {!isLogiWork && <CommonNav />}
      <StoreReview
        loginInfo={loginInfo}
        storeReviewList={storeReviewList}
        selectedStore={selectedStore}
        addReviewListHandler={addReviewListHandler}
      />
      <main
        className={`${
          currentView === 0 || currentView === 1
            ? "d-flex flex-row justify-content-start"
            : ""
        }`}
        style={{ marginTop: isLogiWork ? 0 : 60 }}
      >
        {loading && <LoadingUI />}
        {/*창고 리스트 및 요약*/}
        {(isMobileListView || currentView < 2) && (
          <div
            className={`bg-white border shadow py-1 d-flex flex-column justify-content-start overflow_scroll ${
              currentView === 2 ? "position-fixed" : ""
            }`}
            style={{
              height: isLogiWork
                ? "100vh"
                : currentView !== 2
                ? "calc(100vh - 98px)"
                : "calc(100vh - 58px)",
              minWidth: currentView !== 2 ? 350 : "100vw",
              maxWidth: currentView !== 2 ? 400 : "100vw",
              zIndex: 999,
            }}
          >
            <input
              id="store_url"
              className="form-control d-none"
              ref={$store_url}
              disabled
            />
            {!isStoreListView && selectedStore !== null && currentView !== 2 ? (
              <StoreSummaryData
                selectedStore={selectedStore}
                createLeftDetailImage={createLeftDetailImage}
                storeURLCopy={storeURLCopy}
                STORE_LIST_VIEW={STORE_LIST_VIEW}
              />
            ) : (
              <>
                {/*  창고 검색*/}
                <div className="pb-3 shadow-sm">
                  <div className="p-2 px-3 d-flex flex-row justify-content-between align-items-center">
                    <h4
                      className={`p-0 mb-0 font-weight-bold`}
                      style={
                        currentView === 2 && selectedStore === null
                          ? { marginLeft: 65 }
                          : {}
                      }
                    >
                      로지킵
                    </h4>
                    <a
                      href="https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/GBTS-%EB%B3%B4%EC%84%B8%EC%B0%BD%EA%B3%A0%EB%AA%B0+%EC%86%8C%EA%B0%9C%EC%84%9C(2022.03.16).pdf"
                      target="_blank"
                      className="btn_1"
                    >
                      가입 및 이용안내
                    </a>
                  </div>

                  <div className="bg-light rounded p-2 px-3 mx-3 d-flex flex-row align-items-center">
                    <input
                      className="flex-fill bg-transparent border-0"
                      placeholder="업체명 또는 창고부호 및 지역명으로 검색하세요"
                      ref={$search_word}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          SEARCH_STORE();
                        } else {
                          window.searchKeyword = {
                            keyword: $search_word.current.value.trim(),
                          };
                        }
                      }}
                    />
                    <h6
                      className="p-0 pl-3 m-0 cursor_pointer"
                      onClick={() => {
                        SEARCH_STORE();
                      }}
                    >
                      <i className="fa-solid fa-magnifying-glass" />
                    </h6>
                  </div>
                </div>
                {/*  창고리스트*/}
                <StoreList
                  wordFilter={wordFilter}
                  loading={loading}
                  storeList={storeList}
                  isStoreListView={isStoreListView}
                  SELECTED_STORE={SELECTED_STORE}
                />
              </>
            )}
          </div>
        )}

        {/*지도*/}
        <div className="flex-fill position-relative">
          <StoreMallMap
            selectedStore={selectedStore}
            isSearchWord={isSearchWord}
            setWordFilter={setWordFilter}
            setParentStoreList={SET_STORE_LIST}
            selectMapMarker={selectMapMarker}
            setLoading={setLoading}
            currentView={currentView}
            isLogiWork={isLogiWork}
          />

          {currentView === 2 && selectedStore === null && (
            <div
              className="position-absolute btn_1"
              style={{
                zIndex: 9999999,
                top: 14,
                left: 17,
              }}
              onClick={() => {
                setSelectedStore(null);
                setIsMobileListView(!isMobileListView);
              }}
            >
              {!isMobileListView ? (
                <i className="fa-solid fa-bars" />
              ) : (
                <i className="fa-solid fa-map" />
              )}
            </div>
          )}
          {isImageSlideView && selectedStore !== null && (
            <StoreImageSlide
              slideImagClose={slideImagClose}
              imageSlideIndex={imageSlideIndex}
              selectedStore={selectedStore}
              slideImagChange={slideImagChange}
            />
          )}
        </div>
        {/*창고 상세 정보 및 문의하기*/}
        {selectedStore !== null && (
          <StoreDetailContact
            loginInfo={loginInfo}
            currentView={currentView}
            selectedStore={selectedStore}
            isLogiWork={isLogiWork}
            SELECTED_STORE_CLEAR={SELECTED_STORE_CLEAR}
            storeURLCopy={storeURLCopy}
            moveStoreDetailScroll={moveStoreDetailScroll}
            setIsContactView={setIsContactView}
            isContactView={isContactView}
            slideImagOpen={slideImagOpen}
            storeRequestInsert={storeRequestInsert}
            storeReviewList={storeReviewList}
          />
        )}
      </main>
    </>
  );
}
