import React, { useRef, useState, useEffect } from "react";
import Select from "react-dropdown-select";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import ScheduleAIRManageTable from "./ScheduleAIRManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleAIRManage";

let today = new Date();
let currentYear = today.getFullYear();
let currentMonth = today.getMonth() + 1;
let minYear = currentYear - 10;
let port_list = [];

let yearList = [];
for (let i = 0; i <= 20; i++) {
  yearList.push(minYear + i);
}

let monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
let selectYear = currentYear;
let selectMonth = currentMonth;

export default function ScheduleAIRManage(props) {
  const { pagesOpen } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    sm_POL_CODE: useRef(),
    sm_POD_CODE: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      if (port_list.length === 0) {
        if (codeList.codeAirCountryFullName.length === 0) {
          socket.addLocalEventListener(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
            _mClassName,
            (oldV, newV) => {
              port_list = newV;
              setIsNeedUpdate(!isNeedUpdate);
            }
          );
        } else {
          port_list = codeList.codeAirCountryFullName;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  //신규등록
  const handleNewData = () => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  //검색필터 데이터 변경
  const searchFilterChanged = (data) => {
    const filters = {
      ...data,
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
      sm_POL_CODE: controller.sm_POL_CODE.current.value,
      sm_POD_CODE: controller.sm_POD_CODE.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  // 월 선택 핸들러
  const handleSearchDate = (value) => {
    let monthSet = "";
    if (value > 12) {
      selectYear = value;
    } else {
      selectMonth = value;
      monthSet = value;
      if (value < 10) {
        monthSet = `0${value}`;
      }
    }

    let searchStartDate = `${selectYear}-${monthSet}-01`;
    let searchEneDate = util.getDateToFormat(
      new Date(selectYear, monthSet, -1),
      "YYYY-MM-DD"
    );
    searchFilterChanged({
      SEARCH_DATE_START: searchStartDate,
      SEARCH_DATE_END: searchEneDate,
    });
    setIsNeedUpdate(!isNeedUpdate);
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-end"
                  role="toolbar"
                >
                  <div>
                    <button
                      className="btn btn-outline-primary outline mr-0 mr-md-2 mb-2 mb-md-0"
                      onClick={() => handleNewData()}
                      data-toggle="modal"
                      data-target="#ScheduleAIRManageEdit"
                      aria-label={"신규등록"}
                    >
                      신규등록
                    </button>
                  </div>
                </div>

                <div
                  className="btn-toolbar mb-3 justify-content-end"
                  role="toolbar"
                >
                  <div className="input-group mr-2">
                    <div className="input-group mb-2 mr-2">
                      <select
                        style={{ minWidth: 120 }}
                        className="custom-select form-control"
                        value={selectYear}
                        onChange={(e) => {
                          handleSearchDate(e.target.value);
                        }}
                      >
                        {yearList.map((y) => {
                          return (
                            <option key={y} value={y}>
                              {y}년
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className="custom-select form-control"
                        value={selectMonth}
                        onChange={(e) => {
                          handleSearchDate(e.target.value);
                        }}
                      >
                        {monthList.map((m) => {
                          return (
                            <option key={m} value={m}>
                              {m}월
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="mr-2 d-flex" style={{ height: 36 }}>
                      <input
                        type="text"
                        className="form-control rounded d-none"
                        disabled={true}
                        ref={controller.sm_POL_CODE}
                      />
                      <Select
                        required
                        className="form-control text-dark"
                        clearable={true}
                        multi={false}
                        options={port_list}
                        name={"SearchName"}
                        sortBy={"SearchName"}
                        searchBy={"SearchName"}
                        valueField={"City"}
                        placeholder={"출발지"}
                        style={{
                          flexGrow: 1,
                          minWidth: 180,
                        }}
                        labelField={"NewFullName"}
                        onChange={(data) => {
                          let value =
                            data.length === 0 ? "" : data[0].CustomCode;
                          controller.sm_POL_CODE.current.value = value;
                          searchFilterChanged();
                        }}
                        itemRenderer={({ item, methods }) => {
                          return (
                            <p
                              role="option"
                              aria-selected="false"
                              tabIndex="-1"
                              className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                              onClick={() => methods.addItem(item)}
                              color="#0074D9"
                              style={{
                                fontSize: "11px",
                                padding: "2px 5px",
                                cursor: "pointer",
                                borderBottom: "1px solid #fff",
                                color: "gray",
                                fontStyle: "italic",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b
                                style={{
                                  fontWeight: 600,
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                {`${item.CityName} (${item.CityName_EN}) `}
                              </b>

                              {item.CountryName + " " + item.ContinentName}
                            </p>
                          );
                        }}
                      />
                    </div>

                    <div className="mr-2 d-flex" style={{ height: 36 }}>
                      <input
                        type="text"
                        className="form-control rounded d-none"
                        disabled={true}
                        ref={controller.sm_POD_CODE}
                      />
                      <Select
                        required
                        className="form-control text-dark"
                        clearable={true}
                        multi={false}
                        options={port_list}
                        name={"SearchName"}
                        sortBy={"SearchName"}
                        searchBy={"SearchName"}
                        valueField={"City"}
                        placeholder={"도착지"}
                        style={{
                          flexGrow: 1,
                          minWidth: 180,
                        }}
                        labelField={"NewFullName"}
                        onChange={(data) => {
                          let value =
                            data.length === 0 ? "" : data[0].CustomCode;
                          controller.sm_POD_CODE.current.value = value;
                          searchFilterChanged();
                        }}
                        itemRenderer={({ item, methods }) => {
                          return (
                            <p
                              role="option"
                              aria-selected="false"
                              tabIndex="-1"
                              className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                              onClick={() => methods.addItem(item)}
                              color="#0074D9"
                              style={{
                                fontSize: "11px",
                                padding: "2px 5px",
                                cursor: "pointer",
                                borderBottom: "1px solid #fff",
                                color: "gray",
                                fontStyle: "italic",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b
                                style={{
                                  fontWeight: 600,
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                {`${item.CityName} (${item.CityName_EN}) `}
                              </b>

                              {item.CountryName + " " + item.ContinentName}
                            </p>
                          );
                        }}
                      />
                    </div>

                    <div className="input-group mb-2">
                      <select
                        className="custom-select form-control"
                        ref={controller.SEARCH_LEVEL}
                        onChange={() => searchFilterChanged()}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-미선택-</option>
                        <option value="sm_IDX">관리번호</option>
                        <option value="sm_FWD_NM">항공사명</option>
                        <option value="sm_VESSEL">항공기명</option>
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged();
                          }
                        }}
                        ref={controller.SEARCH_WORD}
                      />

                      <button
                        className="input-group-text btn btn-primary rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                        onClick={() => searchFilterChanged()}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <ScheduleAIRManageTable {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
