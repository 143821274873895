import React, { useState } from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const util = new Utilities();
const codeList = new CodeList();

export default function LogiTalkChatRoomNotice(props) {
  const { current_notice, setIsNoticeListShow } = props;
  const [isCollapse, setIsCollapse] = useState(true);

  const openFileWindow = (fileLink) => {
    window.open(fileLink, "_blank", "width=1000,height=1000");
  };

  return (
    <div
      className="position-fixed bg-white shadow-sm rounded p-0"
      style={{
        zIndex: 999,
        width:
          document.getElementById("chat") &&
          document.getElementById("chat").offsetWidth - 36,
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-fill d-flex flex-row justify-content-start">
          <div className="text-gbts p-2">
            <i className="fa-solid fa-tag" />
          </div>

          <div className="flex-fill m-2 ">
            <p className={`font-weight-bold ${isCollapse ? "ellipsis_1" : ""}`}>
              {current_notice.crnFiles.length > 0 && (
                <i className="fa-solid fa-box-archive text-secondary mr-2" />
              )}
              {current_notice.crnCONTENTS}
            </p>
          </div>
          <span
            className="cursor_pointer p-2"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <i
              className={`fa-solid fa-chevron-${isCollapse ? "down" : "up"}`}
            />
          </span>
        </div>
      </div>
      <div className="px-2">
        {current_notice && current_notice.crnFiles.length > 0 && !isCollapse && (
          <div className="bg-light mt-2 p-2 rounded">
            <p className="font-weight-bold">
              ※첨부파일 ( <i className="fa-solid fa-file-arrow-down" /> 클릭시
              파일 다운로드 )
            </p>
            {current_notice.crnFiles.map((item, index) => {
              return (
                <div key={item.fileLink + "-" + index}>
                  <p className="cursor_pointer">
                    <span onClick={() => openFileWindow(item.fileLink)}>
                      {item.fileName}
                    </span>
                    <i
                      className="fa-solid fa-file-arrow-down ml-2 cursor_pointer text-gbts"
                      onClick={() => {
                        codeList.Modal.current.confirm(
                          `${item.fileName}을(를) 다운로드 하시겠습니까?`,
                          (ret) => {
                            if (ret) {
                              util.downloadAs(
                                item.fileLink,
                                item.fileName,
                                () => {
                                  codeList.Modal.current.alert(
                                    "파일이 정상적으로 다운로드 되었습니다."
                                  );
                                }
                              );
                            }
                          }
                        );
                      }}
                    />
                  </p>
                </div>
              );
            })}{" "}
          </div>
        )}
      </div>
      <div
        className={`${isCollapse ? "d-none" : ""} row m-0 mt-2 p-0  border-top`}
      >
        <div
          className="col p-2 m-0 text-center cursor_pointer border-right"
          onClick={() => {
            setIsNoticeListShow(true);
          }}
        >
          전체보기
        </div>
        <div
          className="col p-2 m-0 text-center cursor_pointer"
          onClick={() => {
            setIsCollapse(true);
          }}
        >
          접어두기
        </div>
      </div>
    </div>
  );
}
