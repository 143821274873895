/* eslint-disable no-unused-vars */
/*global kakao*/
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState, useCallback } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const headCells = [
  {
    field: "smKey",
    headerName: "관리번호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "smName",
    headerName: "창고명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "smTel",
    headerName: "전화번호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.telFormatConvert(params.data)}</span>;
    },
  },
  {
    field: "smAddrFull",
    headerName: "주소",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "smCustoms",
    headerName: "세관명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "smUseYN",
    headerName: "사용여부",
    type: "string",
    minWidth: 110,
    numeric: false,
    disablePadding: false,

    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "smStoreExpireEnd",
    headerName: "기능",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <button
          type="button"
          key={"btn-" + params.currentData.smKey}
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#branchEditModal"
          onClick={(e) => params.setOpen(!params.open)}
          title={"수정"}
          aria-label={"수정"}
        >
          <i className="fas fa-pencil-alt" />
        </button>
      </span>
    ),
  },
];

let geocoder = null;

let sendFileData = [];
let addrDetail = {
  smAddrFull: "",
  smFormattedAddr: "",
  smLocationType: "",
  smPlaceID: "",
  lat: "",
  lng: "",
};

const StoreDetail = (props) => {
  const [sendFileList, setSendFileList] = useState([]);
  const [storeAddr, setStoreAddr] = useState({});

  const { rowData, requestUserSelect, setOpen } = props;
  const parentData = { ...rowData };

  let $smController = {
    smKey: useRef(),
    smName: useRef(),
    smCustoms: useRef(),
    smTel: useRef(),
    smAddrFull: useRef(),
    smFine: useRef(),
    smPenalty: useRef(),
    smBonded: useRef(),
    smUseYN: useRef(),
  };
  const $sdController = {
    sdKey: useRef(), //키값
    sdTypeKind: useRef(), //보세구분
    sdTypeGoods: useRef(), //취급화물
    sdExtentOutside: useRef(), //야적면적
    sdExtentInside: useRef(), //옥내면적
    sdGoodsOutside: useRef(), //옥외화물종류
    sdGoodsInside: useRef(), //옥내화물종류
    sdEquipment: useRef(), //보유장비
    sdWorkingTime: useRef(), //영업시간
    sdSecurity: useRef(), //보안장비
    sdInsure: useRef(), //보험가입현황
    sdEtcMemo: useRef(), //기타사항
    sdContact1: useRef(),
    sdContact1Email: useRef(),
    sdContact1Tel: useRef(),
    sdContact2: useRef(),
    sdContact2Email: useRef(),
    sdContact2Tel: useRef(),
    sdContact3: useRef(),
    sdContact3Email: useRef(),
    sdContact3Tel: useRef(),
    sdContact4: useRef(),
    sdContact4Email: useRef(),
    sdContact4Tel: useRef(),
    sdPhoto: useRef(), //사진
    sdContact1CompCD: useRef(),
    sdContact1UserID: useRef(),
    sdContact2CompCD: useRef(),
    sdContact2UserID: useRef(),
    sdContact3CompCD: useRef(),
    sdContact3UserID: useRef(),
    sdContact4CompCD: useRef(),
    sdContact4UserID: useRef(),
  };

  useEffect(() => {
    if (parentData.smKey !== "") {
      for (let key in parentData) {
        if ($smController.hasOwnProperty(key)) {
          if ($smController[key].current) {
            $smController[key].current.value = parentData[key];
          }
        }
      }
      SdStoreDataSet();
    }
    window.kakao.maps.load(() => {
      geocoder = new kakao.maps.services.Geocoder();
    });

    return () => {
      sendFileData = [];
    };
  }, []);

  useEffect(() => {
    if (storeAddr.hasOwnProperty("address_name")) {
      addrDetail = {
        smAddrFull: storeAddr.address_name,
        smFormattedAddr: storeAddr.address_name,
        smLocationType: storeAddr.address_type,
        smPlaceID: "",
        lat: storeAddr.address.y,
        lng: storeAddr.address.x,
      };
    }
  }, [storeAddr]);

  const SdStoreDataSet = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/JS_store_detail_select.php",
      msgID: msgID,
      data: {},
      sdKey: parentData.smKey,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          let dataList = { ...newData["returnData"][0] };
          for (let key in dataList) {
            if ($sdController[key].current) {
              $sdController[key].current.value = dataList[key]
                ? dataList[key]
                : "";
            }

            if (key === "sdPhoto") {
              let sdPhotoList = dataList[key] ? dataList[key].split("|") : [];
              sendFileData = [...sdPhotoList];
              setSendFileList(sdPhotoList);
            }
          }
        }
      }
    });
  };

  const handleUpload = async (file) => {
    const region = `mall/cutomsStore`;
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let dataList = [...sendFileData, `${response.data["url"]}?${name}`];
      sendFileData = dataList;
      setSendFileList(dataList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (sendFileList.length) {
      $sdController.sdPhoto.current.value = sendFileList.join("|");
    } else {
      $sdController.sdPhoto.current.value = "";
    }
  }, [sendFileList]);

  const onChangeFiles = useCallback(
    (e) => {
      let selectFiles = [];
      if (e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }
      if (selectFiles.length > 5) {
        alert("전경사진은 최대 5개까지 등록가능합니다.");
        return;
      }

      for (const file of selectFiles) {
        handleUpload(file);
      }
    },

    [sendFileList]
  );

  const sdTypeGoodsSelect = (e) => {
    let target = e.target;
    let currentValue = $sdController.sdTypeGoods.current.value;
    currentValue = currentValue !== "" ? currentValue.split("|") : [];

    if (target.checked) {
      currentValue.push(target.value);
    } else {
      let del = currentValue.findIndex((value) => value === target.value);
      currentValue.splice(del, 1);
    }
    currentValue = currentValue.join("|");
    $sdController.sdTypeGoods.current.value = currentValue;
  };

  const storeMainDataUpdate = () => {
    let updateData = {};
    for (let key in $smController) {
      if ($smController[key].current) {
        updateData[key] = $smController[key].current.value;
      }
    }

    if (addrDetail.smAddrFull === "") {
      for (let key in parentData) {
        if (addrDetail.hasOwnProperty(key)) {
          addrDetail[key] = parentData[key];
        }
      }
    }

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/customsStore/JS_customs_store_main_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
      ...addrDetail,
    };

    if (checkValidation()) {
      codeList.Modal.current.confirm(
        "창고정보를 업데이트하시겠습니까?",
        (ret) => {
          if (ret) {
            socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
              if (newData) {
                if (newData["ret"]) {
                  storeDetailDataUpdate();
                } else {
                  codeList.Modal.current.alert(
                    "사용여부 업로드중 문제가 발생하였습니다. 관리자에게 문의 주세요."
                  );
                }
              }
            });
          } else {
            codeList.Modal.current.alert("취소하였습니다.");
          }
        }
      );
    }
  };
  const storeDetailDataUpdate = () => {
    let updateData = {};
    for (let key in $sdController) {
      if ($sdController[key].current) {
        updateData[key] = $sdController[key].current.value;
      }
    }

    updateData.sdKey = parentData.smKey;

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/JS_store_detail_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("창고정보가 업데이트 되었습니다.");
          setOpen(false);
          requestUserSelect();
        } else {
          codeList.Modal.current.alert(
            "사용여부 업로드중 문제가 발생하였습니다. 관리자에게 문의 주세요."
          );
        }
      }
    });
  };

  const checkValidation = () => {
    if ($smController.smKey.current.value.trim() === "") {
      codeList.Modal.current.alert("관리번호는 필수입력 사항입니다.", () => {});
      return false;
    }
    if ($smController.smName.current.value.trim() === "") {
      codeList.Modal.current.alert("창고명은 필수입력 사항입니다.", () => {});
      return false;
    }
    if ($smController.smUseYN.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "사용여부는 필수 선택 사항입니다.",
        () => {}
      );
      return false;
    }
    for (let key in addrDetail) {
      if (key !== "smPlaceID") {
        if (addrDetail[key].trim() === "") {
          codeList.Modal.current.alert(
            "주소등록은 필수입력 사항입니다.",
            () => {}
          );
          return false;
        }
      }
    }

    return true;
  };

  const deleteSdPhoto = (index) => {
    let dataList = [...sendFileData];
    dataList.splice(index, 1);
    sendFileData = dataList;
    setSendFileList(dataList);
  };

  const StoreAddrHandler = () => {
    if (geocoder === null) return;
    let addr = $smController.smAddrFull.current.value;
    geocoder.addressSearch(addr, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === kakao.maps.services.Status.OK) {
        console.log("StoreAddrHandler", result);
        let addrData = result[0];
        codeList.Modal.current.confirm(
          "등록주소가 [" + addrData.address_name + "] 이 맞습니까?",
          (ret) => {
            if (ret) {
              $smController.smAddrFull.current.value = addrData.address_name;
              setStoreAddr({ ...addrData });
            } else {
              $smController.smAddrFull.current.value = "";
            }
          }
        );
      }
    });
  };

  return (
    <tr className="container" id={parentData.pmKeyMain}>
      <td colSpan="12" className="bg-light">
        {/*결제정보*/}
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">창고 상세정보</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">관리번호</h6>{" "}
                <input className="form-control" ref={$smController.smKey} />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">창고명</h6>{" "}
                <input className="form-control" ref={$smController.smName} />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">전화번호</h6>{" "}
                <input className="form-control" ref={$smController.smTel} />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">주소</h6>{" "}
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    ref={$smController.smAddrFull}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        StoreAddrHandler();
                      }
                    }}
                    aria-label="customsStoreAddr"
                    aria-describedby="customsStoreAddr"
                  />

                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="customsStoreAddr"
                      onClick={() => {
                        StoreAddrHandler();
                      }}
                    >
                      주소등록
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">세관명</h6>{" "}
                <input
                  className="form-control"
                  ref={$smController.smCustoms}
                  placeholder="예) 파주세관"
                />
              </div>

              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">과태료부과여부</h6>{" "}
                <select className="form-control" ref={$smController.smFine}>
                  <option value="">선택</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">가산세징수여부</h6>{" "}
                <select className="form-control" ref={$smController.smPenalty}>
                  <option value="">선택</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">하역장소보세구역여부</h6>{" "}
                <select className="form-control" ref={$smController.smBonded}>
                  <option value="">선택</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>

              {/*-----------------------------------------------------------*/}

              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">보세구분</h6>
                <select className="form-control" ref={$sdController.sdTypeKind}>
                  <option value="">보세구분</option>{" "}
                  <option value="A">보세창고</option>{" "}
                  <option value="B">일반창고</option>{" "}
                </select>
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">취급화물</h6>{" "}
                <div className="form-control border-0 d-flex flex-row justify-content-start align-items-center">
                  <label htmlFor="sdTypeKind_A" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_A"
                      value="A"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    드라이{" "}
                  </label>
                  <label htmlFor="sdTypeKind_B" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_B"
                      value="B"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    냉장{" "}
                  </label>
                  <label htmlFor="sdTypeKind_C" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_C"
                      value="C"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    냉동{" "}
                  </label>
                  <label htmlFor="sdTypeKind_D" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_D"
                      value="D"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    보온{" "}
                  </label>
                  <label htmlFor="sdTypeKind_E" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_E"
                      value="E"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    검역물{" "}
                  </label>
                  <label htmlFor="sdTypeKind_F" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_F"
                      value="F"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    수산물{" "}
                  </label>
                  <label htmlFor="sdTypeKind_G" className="m-0 mr-3">
                    <input
                      type="checkbox"
                      id="sdTypeKind_G"
                      value="G"
                      onChange={(e) => {
                        sdTypeGoodsSelect(e);
                      }}
                    />{" "}
                    위험물{" "}
                  </label>
                  <input
                    className="form-control d-none"
                    ref={$sdController.sdTypeGoods}
                    disabled
                  />
                </div>
              </div>

              <div className="col-12 mb-2">
                <h6 className="font-weight-bold">창고전경사진</h6>{" "}
                <div className="d-flex flex-row flex-wrap justify-content-start">
                  {sendFileList.length > 0 &&
                    sendFileList.map((img, index) => {
                      let splitData = img.split("?");
                      let fileHr = splitData[0];
                      let fileName = splitData[1];
                      return (
                        <div
                          key={fileHr}
                          className="border rounded mr-2 mb-2  bg-light d-flex flex-row justify-content-center align-items-center position-relative"
                          style={{ width: 260, height: 130 }}
                        >
                          <span
                            className="rounded-circle bg-danger text-white position-absolute text-center cursor_pointer"
                            style={{
                              top: -10,
                              right: -5,
                              width: 20,
                            }}
                            onClick={() => {
                              deleteSdPhoto(index);
                            }}
                          >
                            &times;
                          </span>
                          <img
                            className="w-100 h-100"
                            src={fileHr}
                            alt={fileName}
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="form-group">
                  <div className="custom-file">
                    <input
                      className="custom-file-input cursor_pointer"
                      type="file"
                      id="customsStoreFileUpload"
                      multiple={true}
                      max={5}
                      onChange={onChangeFiles.bind(this)}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customsStoreFileUpload"
                    >
                      파일첨부
                    </label>
                  </div>
                </div>
                <input
                  className="form-control d-none"
                  type="text"
                  disabled
                  ref={$sdController.sdPhoto}
                />
              </div>

              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">옥외면적</h6>{" "}
                <input
                  placeholder="예) 8,400M2(2,540평)"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdExtentOutside}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">옥외화물종류</h6>{" "}
                <input
                  placeholder="예) 잉곳, 석제등"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdGoodsOutside}
                />
              </div>

              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">옥내면적</h6>{" "}
                <input
                  placeholder="예) 3,520M2(1,060평)"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdExtentInside}
                />
              </div>

              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">옥내화물종류</h6>{" "}
                <input
                  placeholder="예) 위험물제외 모든 박스화물"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdGoodsInside}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">보유장비</h6>{" "}
                <input
                  placeholder="예) 1톤 지게차 3대, 5톤 지게차 1대, 리치스태커 1대(필요시 즉시임대)"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdEquipment}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">영업시간</h6>{" "}
                <input
                  placeholder="예) 08시00분~18시:00분 (요청시 연장가능)"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdWorkingTime}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">보안장비</h6>{" "}
                <input
                  placeholder="예) ADT CAPS 옥외 카메라 10대, 옥내 카메라 5대 사각지대 없음"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdSecurity}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">보험가입현황</h6>{" "}
                <input
                  placeholder="예) 화재보험 10억원,  장치화물보상책임보험 10억원"
                  className="form-control"
                  type="text"
                  ref={$sdController.sdInsure}
                />
              </div>
              <div className="col-12 mb-2">
                <h6 className="font-weight-bold p-0 mb-0">담당자 및 연락처</h6>{" "}
                <p className="text-danger pb-2">
                  로지톡 연동을 위해 회사코드 및 아이디를 대소문자 구분하여
                  정확한 입력 바랍니다.
                </p>
                <div className="row p-0 mx-1">
                  <div className="col-6 col-md-3 m-0 p-0 mb-2 pr-2 ">
                    <div className="p-3 border rounded">
                      <p className="font-weight-bold">담당자(1)</p>{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact1}
                        placeholder="담당자명을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact1Email}
                        placeholder="담당자 이메일을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact1Tel}
                        placeholder="담당자 연락처를 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact1CompCD}
                        placeholder="담당자 회사코드를 입력해주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact1UserID}
                        placeholder="담당자 아이디를 입력해주세요."
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 m-0 p-0 mb-2 pr-2 ">
                    <div className="p-3 border rounded">
                      <p className="font-weight-bold">담당자(2)</p>{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact2}
                        placeholder="담당자명을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact2Email}
                        placeholder="담당자 이메일을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact2Tel}
                        placeholder="담당자 연락처를 입력해 주세요."
                      />{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact2CompCD}
                        placeholder="담당자 회사코드를 입력해주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact2UserID}
                        placeholder="담당자 아이디를 입력해주세요."
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 m-0 p-0 mb-2 pr-2 ">
                    <div className="p-3 border rounded">
                      <p className="font-weight-bold">담당자(3)</p>{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact3}
                        placeholder="담당자명을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact3Email}
                        placeholder="담당자 이메일을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact3Tel}
                        placeholder="담당자 연락처를 입력해 주세요."
                      />{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact3CompCD}
                        placeholder="담당자 회사코드를 입력해주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact3UserID}
                        placeholder="담당자 아이디를 입력해주세요."
                      />
                    </div>
                  </div>
                  <div className="col-6 col-md-3 m-0 p-0 mb-2">
                    <div className="p-3 border rounded">
                      <p className="font-weight-bold">담당자(4)</p>{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact4}
                        placeholder="담당자명을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact4Email}
                        placeholder="담당자 이메일을 입력해 주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact4Tel}
                        placeholder="담당자 연락처를 입력해 주세요."
                      />{" "}
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact4CompCD}
                        placeholder="담당자 회사코드를 입력해주세요."
                      />
                      <input
                        className="form-control mb-2"
                        type="text"
                        ref={$sdController.sdContact4UserID}
                        placeholder="담당자 아이디를 입력해주세요."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-2">
                <h6 className="font-weight-bold">기타사항</h6>{" "}
                <input
                  placeholder="기타 안내사항을 작성해 주세요."
                  className="form-control"
                  type="text"
                  ref={$sdController.sdEtcMemo}
                />
              </div>
              <div className="col-6 col-xxl-3 mb-2">
                <h6 className="font-weight-bold">사용여부</h6>{" "}
                <select className="form-control" ref={$smController.smUseYN}>
                  <option value="">선택</option>
                  <option value="Y">사용</option>
                  <option value="N">중지</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-end">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    storeMainDataUpdate();
                  }}
                >
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

function Row(props) {
  const {
    row,
    openEdit,
    openCopy,
    requestUserSelect,
    isMultiSelect = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();

  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        size={"small"}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  {open && (
                    <StoreDetail
                      rowData={row}
                      setOpen={setOpen}
                      requestUserSelect={requestUserSelect}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

let filters = {};
const _mClassName = "StoreManageTable";
export default function StoreManageTable({
  isServerPaging = false,
  isServerSort = false,
  rowData,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("smUpdateDate");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "smUpdateDate",
      dir: "desc",
    },
  ]);

  (() => {
    sendFileData = [];
    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = n;
        requestUserSelect();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_CREATED,
      _mClassName,
      (b, n) => {
        if (!n) return;
        let dataList = [n, ...serverData];
        setServerData(dataList);
      }
    );
  })();

  useEffect(() => {
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_CUSTOMS_STORE_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_CUSTOMS_STORE_CREATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/JS_customs_store_main_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" id="productManageList">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  parentData={rowData}
                  requestUserSelect={requestUserSelect}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  parentData={rowData}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  parentData={rowData}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                index={index}
                parentData={rowData}
                requestUserSelect={requestUserSelect}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length + 1}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

StoreManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
