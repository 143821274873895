import React, { useEffect, useState } from "react";
import CommonNav from "../CommonNav";
import ImgView from "./component/ImgView";
import AboutGbts from "./component/AboutGbts";
import TabSection from "./component/TabSection";
import OtherServiece from "./OtherServiece";
import Footer from "../Footer";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "Info";

let isMobileBack = false;
export default function Info() {
  const [isMobile, setIsMobile] = useState(false);
  const [loginInfo, setLoginInfo] = useState(null);

  useEffect(() => {
    checkViewportWidth();

    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData && userData["returnData"].length) {
      setLoginInfo(userData["returnData"][0]);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n && n["returnData"].length) {
          setLoginInfo(n["returnData"][0]);
        }
      }
    );

    window.addEventListener("resize", checkViewportWidth);
    return () => {
      window.removeEventListener("resize", checkViewportWidth);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  const checkViewportWidth = () => {
    let value;
    if (window.innerWidth >= 1200) {
      value = false;
    } else {
      value = true;
    }
    if (value !== isMobileBack) {
      isMobileBack = value;
      setIsMobile(value);
    }
  };

  const commonProps = {
    isMobile: isMobile,
    loginInfo: loginInfo,
  };

  return (
    <>
      <CommonNav />
      <main id="info" style={{ marginTop: 55 }}>
        <ImgView {...commonProps} />
        <AboutGbts {...commonProps} />
        <TabSection {...commonProps} />
        {/*<LogiTalkTalk {...commonProps} />*/}
        <OtherServiece {...commonProps} />
      </main>
      <Footer />
    </>
  );
}
