import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import BuddibleSocket from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function LogiWorkInquiryModal(props) {
  const { setIsInquiryModalOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const $controller = {
    comp_name: useRef(),
    user_name: useRef(),
    e_mail: useRef(),
    phone_num: useRef(),
    memo: useRef(),
    privacyPolicy: useRef(),
  };

  const checkValidate = () => {
    if (
      $controller.comp_name.current &&
      $controller.comp_name.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("회사명은 필수 입력입니다.");
      return true;
    }
    if (
      $controller.user_name.current &&
      $controller.user_name.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이름을 입력해주세요");
      return true;
    }
    if (
      $controller.e_mail.current &&
      $controller.e_mail.current.value.trim() === "" &&
      $controller.phone_num.current &&
      $controller.phone_num.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이메일 또는 휴대폰 번호를 입력해주세요");
      return true;
    }
    if (
      $controller.e_mail.current.value.trim() === "" &&
      $controller.phone_num.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이메일 또는 휴대폰 번호를 입력해주세요");
      return true;
    }

    if (!util.validationEmail($controller.e_mail.current.value)) {
      codeList.Modal.current.alert("유효하지 않은 이메일 주소입니다.");
      return true;
    }
    if (!util.validationMobileNumber($controller.phone_num.current.value)) {
      codeList.Modal.current.alert("유효하지 않은 휴대폰 번호입니다.");
      return true;
    }
    if (!$controller.privacyPolicy.current.checked) {
      codeList.Modal.current.alert("개인정보 처리방침 동의를 체크해주세요");
      return true;
    }

    return false;
  };

  const requestInquiry = () => {
    if (checkValidate()) return;
    setIsLoading(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logiwork_inquiry/JS_logiwork_inquiry_mail_send.php",
      msgID: msgID,
      data: {},
      comp_name: $controller.comp_name.current.value,
      user_name: $controller.user_name.current.value,
      e_mail: $controller.e_mail.current.value,
      phone_num: $controller.phone_num.current.value,
      memo: $controller.memo.current.value,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "로지웍 도입문의 요청 메일이 정상적으로 발송되었습니다."
          );
        } else {
          codeList.Modal.current.alert("문의 요청 중 오류가 발생하였습니다.");
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="modal_background">
        {isLoading && (
          <div
            className="position-fixed w-100 h-100 d-flex flex-column justify-content-center align-items-center"
            style={{ background: "rgba(0,0,0,0.6)", zIndex: 999 }}
          >
            <span className="h3 text-white">...Loading</span>
          </div>
        )}
        <div
          className="modal_body rounded bg-white overflow-hidden"
          style={{ maxWidth: "90vw" }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center p-3">
            <p className="h4 mb-0 font-weight-bold">
              <span className="tebg-freiscop">로지웍</span> 도입문의
            </p>
            <p
              className="h3 mb-0 text-danger cursor_pointer"
              onClick={() => setIsInquiryModalOpen(false)}
            >
              &times;
            </p>
          </div>
          <div
            className="border-top border-bottom p-3"
            style={{ width: 600, maxWidth: "100%" }}
          >
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">회사명</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.comp_name}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">이름</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.user_name}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">이메일</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.e_mail}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">
                휴대폰번호
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.phone_num}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">
                요청사항
              </label>
              <div className="col-sm-9">
                <textarea
                  rows={5}
                  className="form-control"
                  ref={$controller.memo}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between p-3">
            <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
              <label className="container_check m-0 p-0 mr-3">
                <input
                  type="checkbox"
                  className="position-static"
                  ref={$controller.privacyPolicy}
                />
                <span className="checkmark" />
              </label>
              <Link
                to="/Terms/privacyPolicy"
                target="_blank"
                className="align-middle"
              >
                개인정보 처리방침 동의
              </Link>
            </div>

            <div>
              <button
                className="btn btn-secondary mr-2"
                onClick={() => setIsInquiryModalOpen(false)}
              >
                취소
              </button>
              <button
                className="btn btn-dark bg-freiscop"
                onClick={() => requestInquiry()}
              >
                문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
