import React, { useEffect, useState } from "react";
import CommonNav from "../main/CommonNav";
import LogiWorkLandingCarousel from "./component/LogiWorkLandingCarousel";
import LogiWorkLandingSolution from "./component/LogiWorkLandingSolution";
import LogiWorkLandingConnect from "./component/LogiWorkLandingConnect";
import LogiWorkInquiryModal from "./component/LogiWorkInquiryModal";
import Footer from "../main/Footer";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import CodeList from "../lib/CodeList";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "LogiWorkLanding";

export default function LogiWorkLanding() {
  const [loginInfo, setLoginInfo] = useState(null);
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n["returnData"].length) return;
        setLoginInfo(n["returnData"][0]);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  return (
    <>
      <CommonNav />
      <main id="logitalk_landing" style={{ marginTop: 60 }}>
        <LogiWorkLandingCarousel
          loginInfo={loginInfo}
          setIsInquiryModalOpen={setIsInquiryModalOpen}
        />
        <LogiWorkLandingSolution />
        <LogiWorkLandingConnect setIsInquiryModalOpen={setIsInquiryModalOpen} />
      </main>
      <Footer />
      {isInquiryModalOpen && (
        <LogiWorkInquiryModal setIsInquiryModalOpen={setIsInquiryModalOpen} />
      )}
    </>
  );
}
