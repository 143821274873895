import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AutoLogin from "../lib/AutoLogin";
import GBTSLogoSvg from "../assets/GBTSLogoSvg";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import Loading from "../lib/Loading";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "Test";

let scrollTop = 0;
let loginInfo = null;
let subscribeList = {};

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

const ttt = {
  csclPrgsStts: "수입(사용소비) 결재통보",
  vydf: "2402N",
  rlseDtyPridPassTpcd: "N",
  prnm: "WOMENS CLOTHES",
  ldprCd: "IDJKT",
  shipNat: "PA",
  blPt: "C",
  dsprNm: "인천항",
  etprDt: "20240410",
  prgsStCd: "CAGE01",
  msrm: "63.54",
  wghtUt: "KG",
  dsprCd: "KRINC",
  cntrGcnt: "2",
  cargTp: "수입 일반화물",
  shcoFlcoSgn: "SNKO",
  pckGcnt: "778",
  etprCstm: "인천세관",
  shipNm: "INCHEON VOYAGER",
  hblNo: "DTLS24030029",
  prcsDttm: "20240411020149",
  frwrSgn: "DSLG",
  spcnCargCd: "",
  ttwg: "12570.42",
  ldprNm: "Jakarta, Java",
  frwrEntsConm: "(주)동서로지스틱스",
  dclrDelyAdtxYn: "N",
  mtTrgtCargYnNm: "N",
  cargMtNo: "24SNKO1517I30840001",
  cntrNo: "HLHU8303490",
  mblNo: "SNKO073240300429",
  blPtNm: "Consol",
  lodCntyCd: "ID",
  prgsStts: "반입완료",
  shcoFlco: "장금상선(주)",
  pckUt: "GT",
  shipNatNm: "파나마",
  agnc: "장금상선(주)",
  SN: 925218,
  CI_CNTR_LIST: [
    {
      cntrNo: "HLHU8303490",
      cntrSelgNo1: 115576,
      cntrSelgNo2: "",
      cntrSelgNo3: "",
      cntrStszCd: "40HQ",
    },
    {
      cntrNo: "SKLU1439875",
      cntrSelgNo1: 115515,
      cntrSelgNo2: "",
      cntrSelgNo3: "",
      cntrStszCd: "20GP",
    },
  ],
  BL_ST2_DT: "2024-04-11 02:01:49",
  BL_ST: "CAGE01",
  BL_ST2: "",
  Sub2List: [],
  Sub1List: [
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240411020149",
      dclrNo: "02012016240113351A",
      rlbrDttm: "2024-04-11 01:50:32",
      wght: "12570.42",
      rlbrBssNo: {},
      bfhnGdncCn:
        "[부가사항] 한진인천컨테이너터미널(주)의 장치기간은 최대 2 개월 입니다.",
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "반입신고",
      pckUt: "GT",
      rlbrCn: "입항 반입",
      shedSgn: "02012016",
    },
    {
      shedNm: {},
      prcsDttm: "20240410110902",
      dclrNo: "24SNKO1517I00000001",
      rlbrDttm: {},
      wght: {},
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "0",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 운항정보 정정",
      pckUt: {},
      rlbrCn: {},
      shedSgn: {},
    },
    {
      shedNm: {},
      prcsDttm: "20240410110902",
      dclrNo: "24SNKO1517I",
      rlbrDttm: {},
      wght: {},
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "0",
      cargTrcnRelaBsopTpcd: "입항보고 수리",
      pckUt: {},
      rlbrCn: {},
      shedSgn: {},
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240409170139",
      dclrNo: "24020104271",
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "하선신고 수리",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016",
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240409123000",
      dclrNo: "4277624040105M",
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "수입(사용소비) 결재통보",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016/입항전수입",
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240409112957",
      dclrNo: "4277624040105M",
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "수입(사용소비) 심사진행",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016",
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240409112941",
      dclrNo: "4277624040105M",
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: "베스트관세법인",
      bfhnGdncCn:
        "[안내사항] 한진인천컨테이너터미널(주) 은 수입신고 수리일로부터 15일이내에 물품을 반출하여야하며, 미반출시 과태료가 부과됩니다.",
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "수입신고",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016/입항전수입",
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240408182034",
      dclrNo: {},
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: "(주)동서로지스틱스",
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 심사완료",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016",
    },
    {
      shedNm: "한진인천컨테이너터미널(주)",
      prcsDttm: "20240408170512",
      dclrNo: {},
      rlbrDttm: {},
      wght: "12570.42",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "778",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 제출",
      pckUt: "GT",
      rlbrCn: {},
      shedSgn: "02012016",
    },
  ],
};

export default function Test() {
  // -----user data
  const [loading, setLoading] = useState(false); //로딩중 여부
  const [isLoginSuccess, setIsLoginSuccess] = useState(true); //로그인 완료여부
  const [isNeedUpdate, setIsNeedUpdate] = useState(true); //로그인 완료여부

  // ----- only UI
  const [isShowSearch, setIsShowSearch] = useState(false);

  // -----news state data
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortModel, setSortModel] = useState([
    {
      field: "cntCreatDate",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const SEARCH_WORD = useRef();
  const { t, i18n } = useTranslation();

  (() => {
    if (window.ReactNativeWebView === undefined) {
      alert("잘못된 접근입니다.");
      window.location.replace("/");
    }
  })();

  useEffect(() => {
    document.addEventListener("message", receiveMessage);
    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n) return;
      requestSubscribeList();
    });
    socket.addLocalEventListener("SendNews", _mClassName, (b, n) => {
      if (!n) return;
      requestUserSelect();
    });

    if (loginInfo) {
      requestSubscribeList();
    }
    return () => {
      document.removeEventListener("message", receiveMessage);
      socket.removeLocalEventListener("ChangedNews", _mClassName);
      socket.removeLocalEventListener("SendNews", _mClassName);
    };
  }, []);

  const receiveMessage = (event) => {
    let data = util.buddibleDecrypt(event.data);
    data = JSON.parse(data);

    if (data.key === "init") {
      i18n.changeLanguage(data.lang, (err) => console.log(err));
      const loginData = {
        comp_cd: data.comp_cd,
        user_id: data.user_id,
        user_pw: data.user_pw,
      };
      requestLogin(loginData);
    }

    if (data.key === "ChangedSubscribe") {
      requestSubscribeList(() => {
        const msgID = util.makeUUIDv4();
        const socketData = {
          method: "ChangedNews",
          msgID: msgID,
          data: {
            ...data,
          },
        };
        socket.sendSocketMessage(
          socketData,
          msgID,
          (beforeData, newData) => {}
        );
      });
    }
  };
  const requestLogin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          loginInfo = newData["returnData"][0];
          requestSubscribeList(() => {
            setIsLoginSuccess(true);
          });
        }
        setLoading(false);
      }
    });
  };

  const requestSubscribeList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let subscribeTemp = {};
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            subscribeTemp[newData["returnData"][i].cnmMainKey] = "";
            newData["returnData"][i].isEntire = false;
          }
          subscribeList = subscribeTemp;
          setIsNeedUpdate((prevState) => !prevState);
          callback && callback(newData);
        }
      }
    });
  };

  const clickSubscribeBtn = (news) => {
    if (!loginInfo) {
      codeList.Modal.current.alert(t("requestLoginMSG"));
      return;
    }

    const isSubscribe = subscribeList.hasOwnProperty(news.cnmMainKey);
    const confirmMSG = isSubscribe
      ? t("requestSubscribeCancelMSG")
      : t("requestSubscribeMSG");
    codeList.Modal.current.confirm(
      ` [${news.cnmCompNM}-${news.cnmName}] ${confirmMSG}`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/talk_news/JS_chatNewsChannelSubscribe.php",
            msgID: msgID,
            data: {},
            cnuMainKey: news.cnmMainKey,
            cnuUserSite: loginInfo.comp_cd,
            cnuUserID: loginInfo.user_id,
            cnuRoomAuth: "",
            cnuRoomName: news.cnmName,
            cnuAgreeYN: isSubscribe ? "N" : "Y",
            cnuJoinDate: new Date(),
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  socketMsg.cnuAgreeYN === "Y"
                    ? `[${news.cnmName}]${t("successSubscribeMSG")}`
                    : `[${news.cnmName}]${t("successSubscribeCancelMSG")}`,
                  () => {
                    requestSubscribeList();
                    const data = JSON.stringify({
                      ...news,
                      cnuAgreeYN: socketMsg.cnuAgreeYN,
                      isSubscribe: isSubscribe ? "N" : "Y",
                    });
                    window.ReactNativeWebView?.postMessage(data);
                  }
                );
              } else {
                codeList.Modal.current.alert(t("errorMSG"));
              }
            }
          });
        }
      }
    );
  };

  useEffect(() => {
    requestUserSelect();
  }, [filters]);

  const actionSearch = () => {
    setFilters({ SEARCH_WORD: SEARCH_WORD.current.value });
  };

  const requestUserSelect = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cntDelYN: "N",
      isLogiTalk: "Y",
      isApp: "Y",
      ...filters,
    };

    setIsDataLoad(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          const beforeFirstIndex =
            serverData.length > 0 ? serverData[0].cntIDX : 0;
          const newFirstIndex = newData["returnData"][0]?.cntIDX;

          if (newData["returnData"][0]) {
            if (Number(newFirstIndex) > Number(beforeFirstIndex)) {
              setServerData((prevState) => [
                ...newData["returnData"],
                ...prevState,
              ]);
            } else {
              setServerData((prevState) => [
                ...prevState,
                ...newData["returnData"],
              ]);
            }
            setPage((prevState) => prevState + 1);
          } else {
            setPage(0);
            setServerData([]);
          }
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
          if (callback) {
            callback();
          } else {
            setIsDataLoad(false);
          }
        }
      }
    });
  };

  const clickShowMore = () => {
    scrollTop = document.getElementById("scrollView").scrollTop;
    requestUserSelect(() => {
      setTimeout(() => {
        setIsDataLoad(false);
        document
          .getElementById("scrollView")
          .scrollTo({ left: 0, top: scrollTop, behavior: "instant" });
      }, 1000);
    });
  };

  const ItemRender = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  if (loading) {
    return <Loading />;
  }

  if (!loading && !isLoginSuccess) {
    return (
      <div className="vh-100 d-flex flex-row justify-content-center align-items-center font-weight-bold">
        <p>{t("errorMSG")}</p>
      </div>
    );
  }

  return (
    <>
      {isDataLoad && <Loading />}
      <AutoLogin />
      <div className="vh-100 d-flex flex-column">
        <div className="bg-white shadow-sm p-2 mb-2">
          {isShowSearch ? (
            <div className="d-flex flex-row justify-content-between align-items-center bg-light rounded h5 px-3 py-2 mb-0 bg-info">
              <button
                className="bg-transparent border-0 p-0 m-0 text-secondary mt-1"
                onClick={() => {
                  codeList.Modal.current.confirm(t("closeSearchMSG"), (ret) => {
                    if (ret) {
                      setIsShowSearch(false);
                      setServerData([]);
                      setPage(0);
                      setFilters({ SEARCH_WORD: "" });
                    }
                  });
                }}
              >
                <i className="fa-solid fa-arrow-left" />
              </button>
              <div className="flex-grow-1 px-3 h6 mb-0 h-100">
                <input
                  className="d-inline-block border-0 w-100 bg-transparent p-2 font-weight-bold"
                  placeholder={t("enterSearchTerm")}
                  ref={SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionSearch();
                    }
                  }}
                />
              </div>
              <button
                className="bg-transparent border-0 p-0 text-secondary mt-1"
                onClick={() => {
                  actionSearch();
                }}
              >
                <i className="fa-solid fa-magnifying-glass" />
              </button>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-between align-items-center p-1">
              <p className="h5 mb-0 font-weight-bold bg-white text-gbts">
                NEWS
              </p>
              <button
                className="bg-transparent border-0 h5 mb-0 text-secondary mt-1"
                onClick={() => setIsShowSearch(true)}
              >
                <i className="fa-solid fa-magnifying-glass" />
              </button>
            </div>
          )}
        </div>
        <div id="scrollView" className="flex-grow-1 overflow_scroll-Y">
          {data.length === 0 && (
            <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-center">
              <p className="font-weight-bold text-secondary">
                {t("emptyNewsMSG")}
              </p>
            </div>
          )}
          {data.length > 0
            ? data.map((item, index) => {
                return (
                  <ItemRender
                    key={item.id}
                    item={item}
                    index={index}
                    subscribeList={subscribeList}
                    clickSubscribeBtn={clickSubscribeBtn}
                  />
                );
              })
            : ""}
        </div>
        {data.length < totalCount && (
          <p
            className="font-weight-bold text-center py-2 pb-3 cursor_pointer cursor_pointer_bg_light"
            onClick={() => clickShowMore()}
          >
            {t("showMore")}
          </p>
        )}
      </div>
    </>
  );
}

const Item = React.memo((props) => {
  const { item, subscribeList, clickSubscribeBtn } = props;
  const { t, i18n } = useTranslation();

  const isSubscribe = subscribeList.hasOwnProperty(item.cntMainKey);
  const [year, month, date] = util
    .getDateToFormat(item.cntCreatDate, "YYYY-MM-DD")
    .split("-");

  return (
    <>
      <div className="col-12 col-md-6 col-lg-3 m-0 p-0 mb-2">
        <div className={`bg-white cursor_pointer p-3 shadow-sm`}>
          <Link className="mb-2" to={`/NewsDetail/${item.cntIDX}`}>
            {item.cntPic && (
              <div
                className="rounded"
                style={{
                  height: 240,
                  background: `url(${item.cntPic}) 50% 50% / cover no-repeat`,
                }}
              />
            )}
            {!item.cntPic && (
              <div className="child-center min-height-200">
                <GBTSLogoSvg />
              </div>
            )}
          </Link>
          <div className="p-2 d-flex flex-row justify-content-between">
            <Link
              className="d-inline-block flex-fill text-dark"
              to={`/NewsDetail/${item.cntIDX}`}
            >
              <p className="h5 text-secondary font-weight-bold mb-1 ellipsis_2">
                {item.cntTitle}
              </p>
              <p className="h6 ellipsis_1 mb-3">{item.cntMSG}</p>
              <p className="font-size-09 font-weight-bold">
                {year}
                {t("year")} {month}
                {t("month")} {date}
                {t("date")}
              </p>
            </Link>
            <div className="pl-3 text-right">
              <button
                className="bg-transparent border-0 p-0 font-weight-bold text-freiscop"
                style={{ minWidth: 50 }}
                onClick={() => clickSubscribeBtn(item)}
              >
                <span className="mr-2">{t("subscribe")}</span>
                <i
                  className={
                    isSubscribe
                      ? "fa-solid fa-bookmark"
                      : "fa-regular fa-bookmark"
                  }
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
