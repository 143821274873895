import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CommonNav from "../../../CommonNav";
import Loading from "../../../../lib/Loading";
import Footer from "../../../Footer";

import UploadImageToS3WithPhp from "../../../../lib/UploadImageToS3WithPhp";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ReserveFCL";

const times = [
  "00:00",
  "00:10",
  "00:20",
  "00:30",
  "00:40",
  "00:50",
  "01:00",
  "01:10",
  "01:20",
  "01:30",
  "01:40",
  "01:50",
  "02:00",
  "02:10",
  "02:20",
  "02:30",
  "02:40",
  "02:50",
  "03:00",
  "03:10",
  "03:20",
  "03:30",
  "03:40",
  "03:50",
  "04:00",
  "04:10",
  "04:20",
  "04:30",
  "04:40",
  "04:50",
  "05:00",
  "05:10",
  "05:20",
  "05:30",
  "05:40",
  "05:50",
  "06:00",
  "06:10",
  "06:20",
  "06:30",
  "06:40",
  "06:50",
  "07:00",
  "07:10",
  "07:20",
  "07:30",
  "07:40",
  "07:50",
  "08:00",
  "08:10",
  "08:20",
  "08:30",
  "08:40",
  "08:50",
  "09:00",
  "09:10",
  "09:20",
  "09:30",
  "09:40",
  "09:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
  "22:00",
  "22:10",
  "22:20",
  "22:30",
  "22:40",
  "22:50",
  "23:00",
  "23:10",
  "23:20",
  "23:30",
  "23:40",
  "23:50",
];
const sfc_TYPE = {
  "": "",
  DC: "Dry",
  RF: "Reefer",
  TC: "Tank",
  OT: "Open Top",
  FR: "Flat Rack",
};
const sfc_SIZE = {
  "": "",
  20: "20`",
  40: "40`",
  "4H": "40`HQ",
  45: "45`",
};

let loginInfo = {
  comp_cd: "Z0001",
  comp_num: "1234567890",
  comp_nm: "(주)지비티에스",
  comp_gb: "Z",
  user_id: "amber.jung@buddible.com",
  user_nm: "정다영",
  user_tel: "",
  use_gb: "A",
  activation_gb: "B",
  comp_mail: "",
  auth_imp: "Y",
  auth_exp: "Y",
  USER_PIC: "https://www.gbts.co.kr/images/user_pic-50x50.png",
  USER_NICK: "정다영",
  USER_PART_NM: "개발 및 유지보수",
  USER_TEAM_NM: "개발팀",
  FWD_NO: "",
  COMP_C_NUM: "00000001",
  USER_TALK_USE_YN: "Y",
  USER_NAME_CARD: "",
  SHIP_IMG_URL10:
    "https://s3.ap-northeast-2.amazonaws.com/gbts-invoice/talk/logo/Z0001_ship_1670395561713.png",
  COMP_NM_ENG: "GBTS",
  COMP_ADDR_ENG:
    "87, Gonghangdong-ro 296beon-gil, Jung-gu, Incheon, Republic of Korea",
  COMP_TEL: "032-751-9880",
  COMP_GB_SUB2: null,
};
let schedule_data = {
  sm_IDX: "7756",
  sm_UID: "22L-F1939",
  sm_FWD_NO: "2028117182",
  sm_FWD_NM: "NAMSUNG",
  sm_FCL_LCL: "FCL",
  sm_VESSEL: "PEGASUS PROTO",
  sm_VOY: "2201S",
  sm_DOC_CLOSE: "2022-12-28",
  sm_CARGO_CLOSE: "2022-12-29",
  sm_ORIGIN: "부산 (PUSAN)",
  sm_DEST: "자카르타 (JAKARTA)",
  sm_DEPARTURE_DATE: "2022-12-30",
  sm_ARRIVE_DATE: "2023-01-04",
  sm_POL_CODE: "KRPUS",
  sm_POD_CODE: "IDJKT",
  sm_POL_NAME: "부산 (PUSAN)",
  sm_POD_NAME: "자카르타 (JAKARTA)",
  sm_EXP_IMP: "EXP",
  sm_LINE: "NSSL",
  sm_REGION: "",
  sm_REMARK:
    '<p><strong>테스트데이터 입니다. 예약불가능</strong></p><p><strong><u>항로명:&nbsp;ANX (Asia New Express)</u></strong></p><p><strong>개시일:</strong>&nbsp;인천발&nbsp;2022년&nbsp;12월&nbsp;10일 토요일&nbsp;(M/V PEGASUS PROTO, DPRT 2201S)</p><p><strong style="color: rgb(230, 0, 0);">서비스&nbsp;ROUTE:</strong><span style="color: rgb(230, 0, 0);">&nbsp;인천 부산 울산 상해 호치민 람차방 자카르타 호치민 홍콩 셔코우</span></p><p><span style="color: rgb(102, 185, 102);">ABCDEFG</span></p>',
  sm_USE_YN: "Y",
  sm_CFS: "03012240",
  sm_DOC_CLOSE_TIME: "",
  sm_CARGO_CLOSE_TIME: "",
  sm_PROVIDER_CODE: "B001F",
  sm_PROVIDER_ID: "admin",
  sm_PROVIDER_SALES_ID: "admin",
  sm_PROMO_YN: "Y",
  sm_PROMO_MEMO: "신규항로 ANX 개시 랜덤박스 이벤트",
  sm_TS_PORT: null,
  sm_2ND_VESSEL: null,
  sm_EDI_DATE: null,
  sm_CFS_DATE: null,
  sm_AFR_DATE: null,
  sm_ARRIVE_TERMINAL: null,
  sm_DEPARTURE_TERMINAL: null,
  COMP_LOGO:
    "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/gbts/companyInfo/20221125164818_남성해운CI_1.png?남성해운CI_1.png",
  COMP_CD: "B001F",
};
let container_files = [];
let memo_files = [];
let reefer_count = 0;
let oug_count = 0;
let dgNoteActive = 0;
let flexiBagActive = 0;
let note_modal_data = {
  title: "테스트 타이틀",
  desc: "테스트 내용",
};

let sbf_BOOK_UID = "";

export default function ReserveFCL(props) {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isSuccessGetData, setIsSuccessGetData] = useState(false);
  const [sbf_TRAN_TYPE, setTransitType] = useState("LINE");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [container_detail_list, setContainer_detail_list] = useState([]);
  const [container_detail_list_OG, setContainer_detail_list_OG] = useState([]);
  const [pickPlace, setPickPlace] = useState([]);
  const cfs_data = { ...location.state };

  const $controller = {
    sbf_SHIPPER: useRef(), //SHIPPER
    sbf_COMP_NM: useRef(), //실화주

    sbf_USER_NAME: useRef(), //부킹담당자 이름
    sbf_BOOK_TEL: useRef(), //부킹담당자 전화번호
    sbf_BOOK_MOBILE: useRef(), //부킹담당자 휴대전화
    sbf_USER_ID: useRef(), //부킹담당자 이메일
    sbf_BOOK_FAX: useRef(), //부킹담당자 팩스

    sbf_DOC_USER_NAME: useRef(), //서류담당자 이름
    sbf_DOC_USER_TEL: useRef(), //서류담당자 전화번호
    sbf_DOC_USER_MOBILE: useRef(), //서류담당자 휴대전화
    sbf_DOC_USER_ID: useRef(), //서류담당자 이메일
    sbf_DOC_USER_FAX: useRef(), //서류담당자 팩스

    sbf_WEIGHT_CNT: useRef(), //화물 무게
    sbf_WEIGHT_TYPE: useRef(), //화물 무게 구분

    sbf_TRAN_TYPE: useRef(), // 운송조건 구분
    sbf_TRAN_FREIGHT: useRef(), //운송조건 Freight
    sbf_TRAN_FREIGHT_SCNO: useRef(), //운송조건 SC No.
    sbf_TRAN_TERM: useRef(), //운송조건 SC No.

    sbf_PICK_DATE: useRef(), //픽업날자
    sbf_PICK_TIME: useRef(), //픽업 시간
    sbf_PICK_PLACE: useRef(), //픽업위치
    sbf_PICK_USER_NM: useRef(), //픽업 담당자 이름
    sbf_PICK_USER_TEL: useRef(), //픽업 담당자 연락처
    sbf_PICK_MEMO: useRef(), // 픽업 요청 메모

    sbf_FACT_DATE: useRef(), //공장 작업일자
    sbf_FACT_TIME: useRef(), //공장 작업 시간
    sbf_FACT_PLACE: useRef(), //공장 작업장소
    sbf_FACT_USER_NM: useRef(), //공장 담당자 이름
    sbf_FACT_USER_TEL: useRef(), //공장 담당자 연락처
    sbf_FACT_MEMO: useRef(), //공장 요청 메모

    sbf_CFS_DATE: useRef(), //입고 날짜
    sbf_CFS_TIME: useRef(), //입고 시간
    sbf_CFS_PLACE: useRef(), // 입고 CFS
    sbf_CFS_MEMO: useRef(), //입고 요청사항

    sbf_ETC_PRODUCT: useRef(), //영문명
    sbf_ETC_RTN_YN: useRef(), //반송화물 보세운송조건
    sbf_ETC_MEMO: useRef(), //요청사항
  };

  useEffect(() => {
    SELECT_PICK_PLACE();
    sbf_BOOK_UID = util.makeUUIDv4();
    if (sbf_BOOK_UID !== "") {
      setContainer_detail_list([
        {
          id: util.makeUUIDv4(),
          sfc_BOOK_UID: sbf_BOOK_UID,
          sfc_MAIN_TYPE: "M", //컨테이너 타입
          sfc_SIZE: "", //컨테이너 사이즈
          sfc_TYPE: "", //컨테이너 추가정보 타입
          sfc_CNT: "", // 수량
          sfc_MAKER_YN: "", // 화주컨테이너 YN
          sfc_DANGER_YN: "", //위험화물 YN
          sfc_EMPTY_YN: "", //empty YN
          sfc_FLEXI_YN: "", // Flexi-Bag YN
          sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
          sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
          sfc_LENGTH: "", //OUG 사이즈
          sfc_WIDTH: "", //OUG 사이즈
          sfc_HEIGHT: "", //OUG 사이즈
          sfc_VOID: "", //OUG 사이즈
          sfc_WEIGHT: "", //OUG 사이즈
          sfc_MEMO: "", // 컨테이너 메모
        },
      ]);
    }

    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData && userData["returnData"].length) {
      loginInfo = userData["returnData"][0];
      SELECT_SCHEDULE_DATA();
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) {
          codeList.Modal.current.alert(
            "로그인 후 이용 가능한 서비스 입니다.",
            () => {
              window.location.replace();
            }
          );
        }
        loginInfo = n["returnData"][0];
        SELECT_SCHEDULE_DATA();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        console.log("데이터 통신에러", n);
      }
    );

    return () => {
      loginInfo = null;
      schedule_data = null;
      container_files = [];
      memo_files = [];
      reefer_count = 0;
      oug_count = 0;
      dgNoteActive = 0;
      flexiBagActive = 0;

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  const SELECT_PICK_PLACE = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/pick_place/JS_pick_place_select.php",
      msgID: msgID,
      data: {},
      ppUseYN: "Y",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = [...newData["returnData"]];
          setPickPlace(data);
        }
      }
    });
  };

  //스케쥴 정보 가져오기
  const SELECT_SCHEDULE_DATA = () => {
    const sm_IDX = params.hasOwnProperty("id") ? params.id : "";

    if (sm_IDX === "") {
      codeList.Modal.current.alert("잘못된 접근입니다.");
      window.history.back();
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select.php",
      msgID: msgID,
      data: {},
      sm_IDX: sm_IDX,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            schedule_data = newData["returnData"][0];
            setIsSuccessGetData(true);
          } else {
            codeList.Modal.current.alert("잘못된 접근입니다.", () => {
              window.history.back();
            });
          }
        } else {
          codeList.Modal.current.alert(
            "데이터를 받아오는 중 오류가 발생하였습니다. 관리자에게 문의해주세요"
          );
        }
      }
    });
  };

  //SHIPPER와 실화주 동일
  const copyShipperComp = () => {
    $controller.sbf_COMP_NM.current.value =
      $controller.sbf_SHIPPER.current.value;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //부킹 담당자 동일
  const copyEmployeeInfo = () => {
    $controller.sbf_DOC_USER_NAME.current.value =
      $controller.sbf_USER_NAME.current.value;
    $controller.sbf_DOC_USER_TEL.current.value =
      $controller.sbf_BOOK_TEL.current.value;

    $controller.sbf_DOC_USER_MOBILE.current.value =
      $controller.sbf_BOOK_MOBILE.current.value;
    $controller.sbf_DOC_USER_ID.current.value =
      $controller.sbf_USER_ID.current.value;
    $controller.sbf_DOC_USER_FAX.current.value =
      $controller.sbf_BOOK_FAX.current.value;

    setIsNeedUpdate(!isNeedUpdate);
  };

  //담당자 연결
  const requestEnquiry = (enquiryType) => {
    let typeText = "업무";
    let empCompCD = schedule_data.sm_PROVIDER_CODE;
    let empID = schedule_data.sm_PROVIDER_ID;
    if (enquiryType === 1) {
      typeText = "영업";
      empID = schedule_data.sm_PROVIDER_SALES_ID;
    }
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
      return;
    }
    codeList.Modal.current.confirm(
      `해당 스케쥴에 대해 ${typeText}담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "LogiTalk", "_blank");
        }
      }
    );
  };

  //컨테이너 정보 첨부파일 업로드
  const uploadContainerFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...container_files];
    //파일명?파일주소
    dataList.push(`${val.realFileName}?${val.fileName}`);
    container_files = dataList;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 정보 첨부파일 삭제
  const deleteContainerFile = (index) => {
    container_files.splice(index, 1);
    setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 정보 첨부파일 업로드
  const uploadAddMemoFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...memo_files];
    //파일명?파일주소
    dataList.push(`${val.realFileName}?${val.fileName}`);
    memo_files = dataList;
    setIsNeedUpdate(!isNeedUpdate);
  };
  /*

    //컨테이너 추가
  const AddContainerInfo = () => {
    let data = [
      ...container_detail_list,
      {
        id: util.makeUUIDv4(),
        sfc_BOOK_UID: sbf_BOOK_UID,
        sfc_MAIN_TYPE: "", //컨테이너 타입
        sfc_SIZE: "", //컨테이너 사이즈
        sfc_TYPE: "", //컨테이너 추가정보 타입
        sfc_CNT: "", // 수량
        sfc_MAKER_YN: "", // 화주컨테이너 YN
        sfc_DANGER_YN: "", //위험화물 YN
        sfc_EMPTY_YN: "", //empty YN
        sfc_FLEXI_YN: "", // Flexi-Bag YN
        sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
        sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
        sfc_LENGTH: "", //OUG 사이즈
        sfc_WIDTH: "", //OUG 사이즈
        sfc_HEIGHT: "", //OUG 사이즈
        sfc_VOID: "", //OUG 사이즈
        sfc_WEIGHT: "", //OUG 사이즈
        sfc_MEMO: "", // 컨테이너 메모
      },
    ];
    container_detail_list = data;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 삭제
  const DeleteContainerInfo = (index) => {
    if (container_detail_list.length === 1) {
      codeList.Modal.current.alert(
        "최소 한개의 컨테이너 정보 등록이 필요합니다."
      );
      return;
    }
    container_detail_list.splice(index, 1);
    reefer_count = container_detail_list.filter(
      (item) => item.sfc_MAIN_TYPE === "Reefer"
    ).length;

    oug_count = container_detail_list.filter(
      (item) =>
        item.sfc_MAIN_TYPE === "Open Top" || item.sfc_MAIN_TYPE === "Flat Rack"
    ).length;

    setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 데이터 변경
  const ContainerDetailDataChange = (index, key, value) => {
    container_detail_list[index][key] = value;
    reefer_count = container_detail_list.filter(
      (item) => item.sfc_MAIN_TYPE === "Reefer"
    ).length;

    oug_count = container_detail_list.filter(
      (item) =>
        item.sfc_MAIN_TYPE === "Open Top" || item.sfc_MAIN_TYPE === "Flat Rack"
    ).length;

    setIsNeedUpdate(!isNeedUpdate);
  };
*/

  //비매치
  //컨테이너 추가
  const AddContainerInfo = (type = "M") => {
    let data = [
      ...container_detail_list,
      {
        id: util.makeUUIDv4(),
        sfc_BOOK_UID: sbf_BOOK_UID,
        sfc_MAIN_TYPE: type, //컨테이너 타입
        sfc_SIZE: "", //컨테이너 사이즈
        sfc_TYPE: "", //컨테이너 추가정보 타입
        sfc_CNT: "", // 수량
        sfc_MAKER_YN: "", // 화주컨테이너 YN
        sfc_DANGER_YN: "", //위험화물 YN
        sfc_EMPTY_YN: "", //empty YN
        sfc_FLEXI_YN: "", // Flexi-Bag YN
        sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
        sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
        sfc_LENGTH: "", //OUG 사이즈
        sfc_WIDTH: "", //OUG 사이즈
        sfc_HEIGHT: "", //OUG 사이즈
        sfc_VOID: "", //OUG 사이즈
        sfc_WEIGHT: "", //OUG 사이즈
        sfc_MEMO: "", // 컨테이너 메모
      },
    ];
    //mContainer_detail_list = data;
    setContainer_detail_list(data);
    //setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 삭제
  const DeleteContainerInfo = (index) => {
    console.log("DeleteContainerInfo", index);

    if (container_detail_list.length === 1) {
      codeList.Modal.current.alert(
        "최소 한개의 컨테이너 정보 등록이 필요합니다."
      );
      return;
    }
    let _container_detail_list = [...container_detail_list];
    _container_detail_list.splice(index, 1);

    if (reefer_count) {
      reefer_count =
        _container_detail_list.filter((item) => item.sfc_MAIN_TYPE === "R")
          .length > 0;
    }
    if (oug_count) {
      oug_count =
        _container_detail_list.filter((item) => item.sfc_MAIN_TYPE === "O")
          .length > 0;
    }
    setContainer_detail_list(_container_detail_list);

    //setIsNeedUpdate(!isNeedUpdate);
  };

  //컨테이너 데이터 변경
  const ContainerDetailDataChange = (index, key, value) => {
    // ※DRY/ REEFER/ TANK/ OPT, FRK 동시에 선택 불가

    // DRY, REEFER, TANK, OPT, FRK 는 동일 사이즈 추가 불가 QTY로만 조정
    // REEFER는 20, 40HQ 사이즈만 존재함

    // OPT, FRK는 컨테이너 수량별로 detail정보가 필요
    // OPT, FRK는 size 변경시 상세 정보 자동변경 필요

    // Dry, Reefer, Tank, OpenTop, FlatRack

    //container_detail_list[index][key] = value;
    /*
    DC - Dry
    RF - Reefer
    TC - Tank
    OT - Open Top
    FR - Flat Rack
    */

    let _container_detail_list = [...container_detail_list];

    _container_detail_list[index][key] = value;
    if (_container_detail_list.length > 1) {
      // 행이 1개이상일 경우 비교
      // ※DRY/ REEFER/ TANK/ OPT, FRK 동시에 선택 불가 검사
      if (key === "sfc_TYPE") {
        //타입 변경시
        let beforeType = "";

        for (let i = 0; i < _container_detail_list.length; i++) {
          if (i !== index) {
            //이전 선택 컨테이너타입 변수 저장
            if (beforeType === "") {
              beforeType = _container_detail_list[i]["sfc_TYPE"];
              break;
            }
          }
        }

        if (beforeType !== "") {
          //이전에 선택된 컨테이너 타입이 있을경우
          let currentType = _container_detail_list[index]["sfc_TYPE"];
          if (beforeType !== currentType) {
            //이전 컨테이너와 타입이 다른 경우
            if (
              !(
                (beforeType === "OT" || beforeType === "FR") &&
                (currentType === "OT" || currentType === "FR")
              )
            ) {
              // 이전 컨테이너와 타입이 다르면서 OT와 FR혼용이 아닌경우 선택 취소
              codeList.Modal.current.alert(
                `${sfc_TYPE[currentType]} 컨테이너와 다른 컨테이너는 동시 선택이 불가능합니다.`
              );
              _container_detail_list[index][key] = "";
              setContainer_detail_list([..._container_detail_list]);
              return;
            }
          }
        }

        //컨테이너 타입 유효성 모두 검수 끝난경우
        //_container_detail_list[index][key] = value;
      }
    }

    //사이즈 유효성 검사
    // REEFER는 20, 40HQ 사이즈만 존재함
    // FR, OP는 20, 40 사이즈만 존재함
    let currentType = _container_detail_list[index]["sfc_TYPE"];
    let currentSize = _container_detail_list[index]["sfc_SIZE"];
    if (currentSize !== "") {
      if (currentType === "RF" && currentSize === "40") {
        codeList.Modal.current.alert(
          `${sfc_TYPE[currentType]} 컨테이너는 40사이즈가 없습니다. 40HQ로 자동 변경합니다.`
        );
        _container_detail_list[index]["sfc_SIZE"] = "4H";
        //setContainer_detail_list([..._container_detail_list]);
      }
      if (
        (currentType === "OT" || currentType === "FR") &&
        currentSize === "4H"
      ) {
        codeList.Modal.current.alert(
          `${sfc_TYPE[currentType]} 컨테이너는 40HQ사이즈가 없습니다. 40으로 자동 변경합니다.`
        );
        _container_detail_list[index]["sfc_SIZE"] = "40";
        //setContainer_detail_list([..._container_detail_list]);
      }
    }

    //사이즈 변경시
    // DRY, REEFER, TANK, OPT, FRK 는 동일 사이즈 추가 불가 QTY로만 조정
    if (_container_detail_list.length > 1) {
      let sizeList = {};
      for (let i = 0; i < _container_detail_list.length; i++) {
        if (i !== index) {
          //이전 선택 컨테이너사이즈 변수 저장
          // OT와 FR은 중복 사이즈가 가능하기때문에 TYPE도 키값에 추가
          sizeList[
            _container_detail_list[i]["sfc_TYPE"] +
              _container_detail_list[i]["sfc_SIZE"]
          ] = true;
        }
      }
      if (
        sizeList.hasOwnProperty(
          _container_detail_list[index]["sfc_TYPE"] +
            _container_detail_list[index]["sfc_SIZE"]
        )
      ) {
        //중복 타입의 중복 사이즈가 있는경우 선택 취소
        codeList.Modal.current.alert(
          `컨테이너 사이즈가 중복 되었습니다. 컨테이너 정보를 확인 바랍니다.`
        );
        _container_detail_list[index]["sfc_SIZE"] = "";
        setContainer_detail_list([..._container_detail_list]);
        return;
      }
    }
    remakeOGData();
    setContainer_detail_list(_container_detail_list);

    /*
    for (let i = 0; i < _container_detail_list.length; i++) {
      if (i !== index) {
        //이전 선택 컨테이너사이즈 변수 저장
        sizeList[_container_detail_list[i]["sfc_SIZE"]] = true;
      }
    }*/

    reefer_count =
      _container_detail_list.filter((item) => item.sfc_TYPE === "RF").length >
      0;
    // if (reefer_count) {
    //   AddContainerInfo("R");
    // }
    oug_count =
      _container_detail_list.filter(
        (item) => item.sfc_TYPE === "OT" || item.sfc_TYPE === "FR"
      ).length > 0;
    // if (oug_count) {
    //   AddContainerInfo("O");
    // }
    //setIsNeedUpdate(!isNeedUpdate);
  };

  //OG 컨테이너 행 개수 컨트롤
  const remakeOGData = () => {
    //container_detail_list_OG
    let _container_detail_list = [...container_detail_list];
    let _container_detail_list_OG = [...container_detail_list_OG];
    let totalOG = 0;
    for (let i = 0; i < _container_detail_list.length; i++) {
      if (
        _container_detail_list[i]["sfc_TYPE"] === "OT" ||
        _container_detail_list[i]["sfc_TYPE"] === "FR"
      ) {
        totalOG += parseInt(_container_detail_list[i]["sfc_CNT"], 0);
      }
    }

    if (_container_detail_list_OG.length > totalOG) {
      _container_detail_list_OG.splice(totalOG - 1);
    }
    if (_container_detail_list_OG.length < totalOG) {
      let addCount = totalOG - _container_detail_list_OG.length;
      for (let i = 0; i < addCount; i++) {
        _container_detail_list_OG.push({
          id: util.makeUUIDv4(),
          sfc_BOOK_UID: sbf_BOOK_UID,
          sfc_MAIN_TYPE: "O", //컨테이너 타입
          sfc_SIZE: "", //컨테이너 사이즈
          sfc_TYPE: "", //컨테이너 추가정보 타입
          sfc_CNT: "", // 수량
          sfc_MAKER_YN: "", // 화주컨테이너 YN
          sfc_DANGER_YN: "", //위험화물 YN
          sfc_EMPTY_YN: "", //empty YN
          sfc_FLEXI_YN: "", // Flexi-Bag YN
          sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
          sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
          sfc_LENGTH: "", //OUG 사이즈
          sfc_WIDTH: "", //OUG 사이즈
          sfc_HEIGHT: "", //OUG 사이즈
          sfc_VOID: "", //OUG 사이즈
          sfc_WEIGHT: "", //OUG 사이즈
          sfc_MEMO: "", // 컨테이너 메모
        });
      }
    }

    let increaseCnt = 0;
    for (let i = 0; i < _container_detail_list.length; i++) {
      if (
        _container_detail_list[i]["sfc_TYPE"] === "OT" ||
        _container_detail_list[i]["sfc_TYPE"] === "FR"
      ) {
        let addCount = 0;

        addCount = parseInt(_container_detail_list[i]["sfc_CNT"], 0);

        if (addCount > 0) {
          for (let j = increaseCnt; j < increaseCnt + addCount; j++) {
            console.log(
              j,
              i,
              _container_detail_list[i]["sfc_TYPE"],

              _container_detail_list[i]["sfc_SIZE"]
            );
            _container_detail_list_OG[j]["sfc_TYPE"] =
              _container_detail_list[i]["sfc_TYPE"];
            _container_detail_list_OG[j]["sfc_SIZE"] =
              _container_detail_list[i]["sfc_SIZE"];
          }
          increaseCnt += addCount;
        }
      }
    }

    setContainer_detail_list_OG(_container_detail_list_OG);
  };

  //컨테이너 데이터 변경
  const ContainerOGDetailDataChange = (index, key, value) => {
    let _container_detail_list_OG = [...container_detail_list_OG];
    _container_detail_list_OG[index][key] = value;
    setContainer_detail_list_OG(_container_detail_list_OG);
  };

  //컨테이너 정보 첨부파일 삭제
  const deleteAddMemoFile = (index) => {
    memo_files.splice(index, 1);
    setIsNeedUpdate(!isNeedUpdate);
  };

  //입력값 검사
  const checkValidation = () => {
    //회사정보
    if ($controller.sbf_COMP_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("회사정보의 실화주 정보를 입력해주세요.");
      return false;
    }

    //부킹담당자
    if ($controller.sbf_USER_NAME.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 이름을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_BOOK_TEL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 전화번호을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_BOOK_MOBILE.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 휴대전화 번호를 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 이메일을 입력해주세요."
      );
      return false;
    }
    //서류담당자
    if ($controller.sbf_DOC_USER_NAME.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 이름을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_TEL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 전화번호을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_MOBILE.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 휴대전화 번호를 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 이메일을 입력해주세요."
      );
      return false;
    }
    //화물상세정보
    if ($controller.sbf_WEIGHT_CNT.current.value.trim() === "") {
      codeList.Modal.current.alert("화물의 무게를 입력해주세요.");
      return false;
    }

    //운송조건
    if (
      $controller.sbf_TRAN_FREIGHT.current.value === "Collect" &&
      $controller.sbf_TRAN_FREIGHT_SCNO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송조건의 SC No.를 입력해주세요.");
      return false;
    }
    if ($controller.sbf_TRAN_TERM.current.value.trim() === "") {
      codeList.Modal.current.alert("운송조건 Term를 선택해주세요");
      return false;
    }
    //운송요청 - 픽업정보
    if (
      $controller.sbf_PICK_DATE.current &&
      $controller.sbf_PICK_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 픽업일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_PICK_PLACE.current &&
      $controller.sbf_PICK_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 픽업장소를 선택해주세요"
      );
      return false;
    }

    if (
      $controller.sbf_PICK_USER_NM.current &&
      $controller.sbf_PICK_USER_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 픽업정보 담당자를 입력해주세요");
      return false;
    }

    if (
      $controller.sbf_PICK_USER_TEL.current &&
      $controller.sbf_PICK_USER_TEL.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 담당자 연락처를 입력해주세요"
      );
      return false;
    }

    //운송요청 - 도어정보
    if (
      $controller.sbf_FACT_DATE.current &&
      $controller.sbf_FACT_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 작업일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_FACT_PLACE.current &&
      $controller.sbf_FACT_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 작업장소를 입력해주세요"
      );
      return false;
    }

    if (
      $controller.sbf_FACT_USER_NM.current &&
      $controller.sbf_FACT_USER_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 도어정보 담당자를 입력해주세요");
      return false;
    }

    if (
      $controller.sbf_FACT_USER_TEL.current &&
      $controller.sbf_FACT_USER_TEL.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 담당자 연락처를 입력해주세요"
      );
      return false;
    }

    //운송요청 - 입고정보
    if (
      $controller.sbf_CFS_DATE.current &&
      $controller.sbf_CFS_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 입고정보 반입일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_CFS_PLACE.current &&
      $controller.sbf_CFS_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 입고정보 CFS를 선택해주세요");
      return false;
    }

    return true;
  };

  //컨테이너 데이터 입력 검사
  const checkValidationForContainer = () => {
    //기본컨테이너 및 Reefer컨테이너 리스트 검사
    let _container_detail_list = [...container_detail_list];
    for (let i = 0; i < _container_detail_list.length; i++) {
      let main_container = _container_detail_list[i];
      //  일반 컨테이너 입력검사
      if (main_container["sfc_TYPE"].trim() === "") {
        codeList.Modal.current.alert("컨테이너 정보의 타입을 입력해주세요.");
        return false;
      }
      if (main_container["sfc_SIZE"].trim() === "") {
        codeList.Modal.current.alert("컨테이너 정보의 사이즈를 입력해주세요.");
        return false;
      }
      if (
        main_container["sfc_CNT"].trim() === "" ||
        main_container["sfc_CNT"] === 0
      ) {
        codeList.Modal.current.alert("컨테이너 정보의 수량을 입력해주세요.");
        return false;
      }

      if (main_container["sfc_MAKER_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 화주 컨테이너 여부를 선택해주세요."
        );
        return false;
      }
      if (main_container["sfc_DANGER_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 위험화물 여부를 선택해주세요."
        );
        return false;
      }
      if (main_container["sfc_EMPTY_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 Empyt 여부를 선택해주세요."
        );
        return false;
      }

      if (main_container["sfc_FLEXI_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 Flexi-Bag 여부를 선택해주세요."
        );
        return false;
      }

      if (main_container["sfc_TYPE"] === "RF") {
        //  냉동컨테이너 입력 검사
        if (main_container["sfc_TEMPERATURE"].trim() === "") {
          codeList.Modal.current.alert(
            "냉동 컨테이너 추가정보의 온도를 입력해주세요."
          );
          return false;
        }
        if (main_container["sfc_FAN_OPEN_PER"].trim() === "") {
          codeList.Modal.current.alert(
            "냉동 컨테이너 추가정보의 환풍기 오픈정도를 선택해주세요."
          );
          return false;
        }
      }
    }

    //OG 컨테이너 리스트 검사
    let _container_detail_list_OG = [...container_detail_list_OG];
    for (let i = 0; i < _container_detail_list_OG.length; i++) {
      let oog_container = _container_detail_list_OG[i];
      if (oog_container["sfc_LENGTH"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Length를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_WIDTH"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Width를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_HEIGHT"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Height를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_VOID"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Void를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_WEIGHT"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Weight를 입력해주세요."
        );
        return false;
      }
    }

    return true;
  };

  //임시저장
  const actionSave = () => {
    codeList.Modal.current.confirm(
      "해당 스케줄에 대해서 예약요청 정보를 제출하시겠습니까?",
      (ret) => {
        if (ret) {
          if (checkValidation() && checkValidationForContainer()) {
            let data = {
              sbf_IDX_MAIN: schedule_data.sm_IDX,
              sbf_UID_MAIN: schedule_data.sm_UID,

              sbf_BOOK_UID: sbf_BOOK_UID, //예약 UID
              sbf_COMP_CD: loginInfo.comp_cd, //업체코드
              sbf_COMP_NAME: loginInfo.comp_nm, //업체명

              sbf_SHIPPER: null, //SHIPPER
              sbf_COMP_NM: null, //실화주

              sbf_USER_NAME: null, //부킹담당자 이름
              sbf_BOOK_TEL: null, //부킹담당자 전화번호
              sbf_BOOK_MOBILE: null, //부킹담당자 휴대전화
              sbf_USER_ID: null, //부킹담당자 이메일
              sbf_BOOK_FAX: null, //부킹담당자 팩스

              sbf_DOC_USER_NAME: null, //서류담당자 이름
              sbf_DOC_USER_TEL: null, //서류담당자 전화번호
              sbf_DOC_USER_MOBILE: null, //서류담당자 휴대전화
              sbf_DOC_USER_ID: null, //서류담당자 이메일
              sbf_DOC_USER_FAX: null, //서류담당자 팩스

              sbf_WEIGHT_CNT: null, //화물 무게
              sbf_WEIGHT_TYPE: null, //화물 무게 구분

              sbf_TRAN_TYPE: null, // 운송조건 구분
              sbf_TRAN_FREIGHT: null, //운송조건 Freight
              sbf_TRAN_FREIGHT_SCNO: null, //운송조건 SC No.
              sbf_TRAN_TERM: null, //운송조건 SC No.

              sbf_PICK_DATE: null, //픽업날자
              sbf_PICK_TIME: null, //픽업 시간
              sbf_PICK_PLACE: null, //픽업위치
              sbf_PICK_USER_NM: null, //픽업 담당자 이름
              sbf_PICK_USER_TEL: null, //픽업 담당자 연락처
              sbf_PICK_MEMO: null, // 픽업 요청 메모

              sbf_FACT_DATE: null, //공장 작업일자
              sbf_FACT_TIME: null, //공장 작업 시간
              sbf_FACT_PLACE: null, //공장 작업장소
              sbf_FACT_USER_NM: null, //공장 담당자 이름
              sbf_FACT_USER_TEL: null, //공장 담당자 연락처
              sbf_FACT_MEMO: null, //공장 요청 메모

              sbf_CFS_DATE: null, //입고 날짜
              sbf_CFS_TIME: null, //입고 시간
              sbf_CFS_PLACE: null, // 입고 CFS
              sbf_CFS_MEMO: null, //입고 요청사항

              sbf_ETC_PRODUCT: null, //영문명
              sbf_ETC_RTN_YN: null, //반송화물 보세운송조건
              sbf_ETC_MEMO: null, //요청사항

              sbf_FILES: container_files.join("|"),
              sbf_ETC_FILES: memo_files.join("|"),

              sbf_CREATE_COMPCD: loginInfo.comp_cd,
              sbf_CREATE_USERID: loginInfo.user_id,
              sbf_UPDATE_COMPCD: loginInfo.comp_cd,
              sbf_UPDATE_USERID: loginInfo.user_id,
              containers: [
                ...container_detail_list,
                ...container_detail_list_OG,
              ],
              ...schedule_data,
            };
            for (let key in $controller) {
              if ($controller[key].current)
                data[key] = $controller[key].current.value || "";
            }
            INSERT_RESERVE_SCHEDULE_FCL(data);
          }
        }
      }
    );
  };

  //FCL 예약정보 서버통신
  const INSERT_RESERVE_SCHEDULE_FCL = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_reserve_insert.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    setIsLoading(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("INSERT_RESERVE_SCHEDULE_FCL", newData);
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("예약요청이 정상적으로 처리되었습니다.");
          //초기화 및 이전 페이지로 넘기기 필요
          setContainer_detail_list_OG([]);
          setContainer_detail_list([]);
          navigate(-1);
        } else {
          codeList.Modal.current.alert(
            "예약 요청 처리중 오류가 발생하였씁니다."
          );
        }
        setIsLoading(false);
      }
    });
  };

  (() => {
    reefer_count = 0;
    oug_count = 0;
    for (let i = 0; i < container_detail_list.length; i++) {
      if (container_detail_list[i]["sfc_TYPE"] === "RF") {
        reefer_count++;
      }
      if (container_detail_list[i]["sfc_MAIN_TYPE"] === "O") {
        oug_count++;
      }
    }
  })();

  return (
    <>
      {isLoading && <Loading />}{" "}
      {isModalOpen && <NoteModal setIsModalOpen={setIsModalOpen} />}
      <CommonNav />
      <main style={{ marginTop: 60 }}>
        {isSuccessGetData && loginInfo && schedule_data && (
          <div className="container">
            <div className="row py-3 m-0 border-bottom">
              <div className="col-xl-12 m-0 p-0">
                <span className="h5 font-weight-bold mb-0">예약하기</span>
              </div>
            </div>
            <div className="py-3 border-bottom">
              {/*요청자*/}
              <div className="row m-0 mb-3 p-0">
                <div className="col-12 m-0 p-0">
                  <span className="h6 mb-0">
                    ■ 요청자 :{" "}
                    <span className="font-weight-bold">
                      [{loginInfo.comp_nm}]{" "}
                      {loginInfo.USER_NICK || loginInfo.user_nm}
                    </span>
                    님
                  </span>
                </div>
              </div>

              {/*회사정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-lg-6 m-0 mb-2 p-0">
                  <div className="row m-0 mb-2 p-0">
                    <div className="col-lg-6 m-0 p-0">
                      <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                        회사정보
                      </span>
                    </div>
                    <div className="col-lg-6 m-0 p-0 text-right">
                      <button
                        className="btn btn-sm btn-outline-secondary py-0 px-2 mr-2 font-size-06"
                        onClick={() => copyShipperComp()}
                      >
                        SHIPPER와 실화주 동일
                      </button>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-lg-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          SHIPPER
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          readOnly={true}
                          ref={$controller.sbf_SHIPPER}
                          defaultValue={loginInfo.comp_nm}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          실화주
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_COMP_NM}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*담당자 정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-12 m-0 mb-2 p-0">
                  <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                    담당자정보
                  </span>
                </div>
                <div className="col-lg-6 m-0 p-0">
                  <div className="row m-0 p-0">
                    <div className="col-xl-12 m-0 mb-2 p-0">
                      <span className="h6 mb-0 font-weight-bold">
                        부킹담당자
                      </span>
                    </div>
                    <div className="col-xl-6 m-0 mb-xl-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          이름
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_USER_NAME}
                          defaultValue={loginInfo.user_nm}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0" />
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          전화번호
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_BOOK_TEL}
                          defaultValue={loginInfo.COMP_TEL}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          휴대전화
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_BOOK_MOBILE}
                          defaultValue={loginInfo.user_tel}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          이메일
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_USER_ID}
                          defaultValue={loginInfo.user_id}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          FAX
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_BOOK_FAX}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 m-0 p-0">
                  <div className="row m-0 p-0">
                    <div className="col-xl-6 m-0 mb-xl-2 p-0 d-flex flex-row justify-content-between align-items-center">
                      <span className="h6 mb-0 font-weight-bold">
                        서류담당자
                      </span>
                      <button
                        className="btn btn-sm px-2 py-0 btn-outline-secondary font-size-06 mr-2"
                        onClick={() => copyEmployeeInfo()}
                      >
                        부킹 담당자 동일
                      </button>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0" />
                    <div className="col-xl-6 m-0 mb-xl-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          이름
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_DOC_USER_NAME}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0" />
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          전화번호
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_DOC_USER_TEL}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          휴대전화
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_DOC_USER_MOBILE}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          이메일
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_DOC_USER_ID}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          FAX
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_DOC_USER_FAX}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*요청 스케쥴정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                    요청 스케쥴 정보
                  </span>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <div className="row m-0 p-0">
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        스케쥴 No
                      </div>
                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_UID}
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        선사
                      </div>
                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {!schedule_data.COMP_LOGO && schedule_data.sm_FWD_NM}
                        {schedule_data.COMP_LOGO && (
                          <div
                            className="w-100 h-100"
                            style={{
                              minWidth: 80,
                              background: `url(${
                                schedule_data.COMP_LOGO.split("?")[0]
                              }) 50% 50% / contain no-repeat`,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        FCL/LCL
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_FCL_LCL}
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        VESSEL/VOY
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p>{schedule_data.sm_VESSEL}</p>
                        <p>{schedule_data.sm_VOY}</p>
                      </div>
                    </div>
                    <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        POL
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p className="font-weight-bold">
                          {schedule_data.sm_ORIGIN}
                        </p>
                        <p className="font-weight-bold text-danger">
                          {schedule_data.sm_DEPARTURE_DATE} (
                          {util.handleGetDate(schedule_data.sm_DEPARTURE_DATE)})
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        POD
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p className="font-weight-bold">
                          {schedule_data.sm_DEST}
                        </p>
                        <p className="font-weight-bold text-danger">
                          {schedule_data.sm_ARRIVE_DATE} (
                          {util.handleGetDate(schedule_data.sm_ARRIVE_DATE)})
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-secondary text-white font-weight-bold p-2">
                        T/S Port
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_TS_PORT || "-"}
                      </div>
                    </div>
                    <div className="col-6 col-xl-auto flex-grow-1 text-center m-0 p-0 d-flex flex-column">
                      <div className="bg-secondary text-white font-weight-bold p-2">
                        2<sup>nd</sup> Vessel
                      </div>
                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_2ND_VESSEL || "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <p className="text-secondary">
                    ※ 운항선사의 사정상 출항/도착시간은 예고없이 변동될 수
                    있습니다.
                  </p>
                </div>
              </div>

              {/*업무 스케쥴 정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                    업무 스케쥴 정보
                  </span>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <div className="row m-0 p-0">
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        서류마감
                      </div>
                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_DOC_CLOSE}{" "}
                        {schedule_data.sm_DOC_CLOSE_TIME}
                      </div>
                    </div>
                    <div className="col-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        EDI 신고마감
                      </div>
                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_EDI_DATE || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        CFS 반입마감/AFR마감
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_CFS_DATE || "-"} {" / "}
                        {schedule_data.sm_AFR_DATE || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        컨테이너 반입마감
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        {schedule_data.sm_CARGO_CLOSE}{" "}
                        {schedule_data.sm_CARGO_CLOSE_TIME}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column flex-xl-row flex-wrap ">
                    <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        출발터미널
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p>{schedule_data.sm_ARRIVE_TERMINAL || "-"}</p>
                      </div>
                    </div>
                    <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        도착 터미널
                      </div>

                      <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                        <p>{schedule_data.sm_DEPARTURE_TERMINAL || "-"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <span className="text-secondary mr-2">
                    ※ 서류/컨테이너 반입마감 시간은 변동될 수 있으므로,
                    업무담당자 톡연결로 확인해 주시기 바랍니다.{" "}
                  </span>
                  <button
                    className="btn btn-sm btn-info py-1 px-2 mr-2 font-size-04"
                    onClick={() => requestEnquiry(0)}
                  >
                    영업 담당자 연결
                    <i className="fa-solid fa-headset ml-2" />
                  </button>
                  <button
                    className="btn btn-sm btn-secondary py-1 px-2 mr-2 font-size-04"
                    onClick={() => requestEnquiry(1)}
                  >
                    업무 담당자 연결
                    <i className="fa-solid fa-file-lines ml-2" />
                  </button>
                </div>
              </div>

              {/*화물 상세정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-6 m-0 mb-2 p-0">
                  <div className="row m-0 mb-2 p-0">
                    <div className="col-xl-12 m-0 p-0">
                      <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                        화물 상세정보
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 150 }}
                        >
                          Gross Weight
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="number"
                          ref={$controller.sbf_WEIGHT_CNT}
                          style={{ width: 150 }}
                        />
                      </div>
                    </div>
                    <div className="col-6 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start align-items-center overflow-hidden h-100 mr-2">
                        <input
                          className={"d-none"}
                          ref={$controller.sbf_WEIGHT_TYPE}
                          defaultValue="KG"
                        />
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            defaultChecked={true}
                            name="sbf_WEIGHT_CNT"
                            onClick={(e) => {
                              $controller.sbf_WEIGHT_TYPE.current.value = "KG";
                            }}
                          />{" "}
                          KG
                        </label>
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            name="sbf_WEIGHT_CNT"
                            onClick={(e) => {
                              $controller.sbf_WEIGHT_TYPE.current.value = "TON";
                            }}
                          />{" "}
                          TON
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*컨테이너 정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-12 col-md-6 m-0 mb-2 p-0">
                  <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                    컨테이너 정보{" "}
                  </span>
                  <span className="d-block d-lg-inline-block font-weight-bold text-danger ml-lg-2">
                    ※ DRY / REEFER / TANK / OPT, FRK 동시에 선택 불가
                  </span>
                </div>
                <div className="col-12 col-md-6 m-0 mb-2 p-0 text-right">
                  <button
                    className="btn btn-sm btn-light py-1 px-2 font-size-09"
                    onClick={() => AddContainerInfo("M")}
                  >
                    컨테이너 추가 +
                  </button>
                </div>
                <div className="col-12 m-0 p-0 mb-2 overflow_scroll">
                  <div
                    className="row flex-nowrap m-0 p-0"
                    style={{ width: 1110 }}
                  >
                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        Type
                      </div>
                    </div>
                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        Size
                      </div>
                    </div>

                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        Q'ty
                      </div>
                    </div>
                    <div className="col-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        화주 컨테이너
                      </div>
                    </div>
                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        위험화물
                      </div>
                    </div>
                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        Empyt
                      </div>
                    </div>
                    <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        Flexi-Bag
                      </div>
                    </div>
                    <div className="col-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                      <div className="bg-info text-white font-weight-bold p-2">
                        추가 요청사항
                      </div>
                    </div>
                    <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
                      <div className="bg-secondary text-white font-weight-bold p-2">
                        삭제
                      </div>
                    </div>
                  </div>

                  {container_detail_list.map((item, index) => {
                    return (
                      <ContainerInfoRow
                        item={item}
                        index={index}
                        setIsModalOpen={setIsModalOpen}
                        DeleteContainerInfo={DeleteContainerInfo}
                        ContainerDetailDataChange={ContainerDetailDataChange}
                        key={item.id}
                      />
                    );
                  })}
                </div>
                <div className="col-xl-12 m-0 p-0 mb-2">
                  <div className="row m-0 p-0">
                    <span className="d-flex flex-row justify-content-center align-items-center bg-info text-white rounded px-5 mr-2">
                      파일첨부
                    </span>
                    <UploadImageToS3WithPhp
                      region={`mall/reserveFcl/${schedule_data.sm_UID}`}
                      callback={(val) => {
                        uploadContainerFile(val);
                      }}
                    >
                      <span className="d-flex flex-row align-items-center btn btn-sm btn-secondary h-100 px-3">
                        +
                      </span>
                    </UploadImageToS3WithPhp>
                  </div>
                </div>
                <div>
                  {container_files.length > 0 &&
                    container_files.map((item, index) => {
                      let split_data = item.split("?");
                      let file_name = split_data[0];
                      let file_link = split_data[1];
                      return (
                        <div
                          key={item + "_" + index}
                          className="d-flex flex-row align-items-center mb-1"
                        >
                          <span
                            className="cursor_pointer text-underline text-primary mr-2"
                            onClick={() => {
                              window.open(
                                file_link,
                                "_blank",
                                "width=1200, height=800"
                              );
                            }}
                          >
                            {file_name}
                          </span>
                          <button
                            className="btn btn-sm btn-danger px-2 py-1  font-size-06"
                            onClick={() => deleteContainerFile(index)}
                          >
                            삭제
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/*컨테이너 추가정보*/}
              {(reefer_count > 0 || container_detail_list_OG.length > 0) && (
                <div className="row m-0 mb-4 p-0">
                  <div className="col-xl-12 m-0 mb-2 p-0">
                    <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                      컨테이너 추가정보
                    </span>
                  </div>
                  {reefer_count > 0 && (
                    <div className="col-xl-12 m-0 p-0 mb-3">
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <span className="h6 mb-0 font-weight-bold">
                          냉동 컨테이너(Reefer) 추가정보
                        </span>
                      </div>
                      <div className="col-12 m-0 p-0 mb-2 overflow_scroll">
                        <div
                          className="row flex-nowrap m-0 p-0"
                          style={{ width: 1110 }}
                        >
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Type
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Size
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              온도(℃)
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              환풍기 OPEN
                            </div>
                          </div>
                          {/*      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-secondary text-white font-weight-bold p-2">
                              삭제
                            </div>
                          </div>*/}
                        </div>
                        {container_detail_list.map((item, index) => {
                          if (item.sfc_TYPE === "RF") {
                            return (
                              <ReeferContainerInfoRow
                                key={item.id}
                                item={item}
                                index={index}
                                DeleteContainerInfo={DeleteContainerInfo}
                                ContainerDetailDataChange={
                                  ContainerDetailDataChange
                                }
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                  {container_detail_list_OG.length > 0 && (
                    <div className="col-xl-12 m-0 p-0 mb-3">
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <span className="h6 mb-0 font-weight-bold mr-2">
                          OUG(Out of Gauge) 추가정보
                        </span>
                        <button className="btn btn-sm btn-outline-secondary px-2 py-1 font-size-08 mt-1 mt-lg-0">
                          컨테이너 사이즈정보
                        </button>
                      </div>

                      <div className="col-12 m-0 p-0 mb-2 overflow_scroll">
                        <div
                          className="row flex-nowrap m-0 p-0 font-size-09"
                          style={{ width: 1110 }}
                        >
                          <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Type
                            </div>
                          </div>
                          <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Size
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Length(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Width(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Height(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Void(teu)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Weight(kg)
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              요청사항
                            </div>
                          </div>
                        </div>

                        {container_detail_list_OG.map((item, index) => {
                          return (
                            <OUGContainerInfoRow
                              key={item.id}
                              item={item}
                              index={index}
                              DeleteContainerInfo={DeleteContainerInfo}
                              ContainerDetailDataChange={
                                ContainerOGDetailDataChange
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/*운송조건*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <div className="row m-0 mb-2 p-0">
                    <div className="col-xl-12 m-0 p-0">
                      <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                        운송조건
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-xl-12 m-0 p-0 d-flex flex-row flex-wrap justify-content-start align-items-center">
                      <div className="mb-2 d-flex flex-row justify-content-start align-items-center">
                        <div className="bg-info text-white py-1 px-2 font-weight-bold rounded px-3">
                          구분
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-center overflow-hidden h-100 mx-2">
                          <input
                            type="text"
                            className="d-none"
                            ref={$controller.sbf_TRAN_TYPE}
                            defaultValue="LINE"
                          />
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              defaultChecked={true}
                              name="sbf_TRAN_TYPE"
                              onClick={() => {
                                $controller.sbf_TRAN_TYPE.current.value =
                                  "LINE";
                                setTransitType("LINE");
                              }}
                            />{" "}
                            라인운송
                          </label>
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              name="sbf_TRAN_TYPE"
                              onClick={() => {
                                $controller.sbf_TRAN_TYPE.current.value =
                                  "SELF";
                                setTransitType("SELF");
                              }}
                            />{" "}
                            자가운송
                          </label>
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              name="sbf_TRAN_TYPE"
                              onClick={() => {
                                $controller.sbf_TRAN_TYPE.current.value = "CFS";
                                setTransitType("CFS");
                              }}
                            />{" "}
                            CFS
                          </label>
                        </div>
                      </div>
                      <div className="mb-2 d-flex flex-row align-items-start align-items-sm-center">
                        <div className="bg-info text-white py-1 px-2 font-weight-bold rounded px-3">
                          Freight
                        </div>
                        <div className="d-flex flex-row flex-wrap justify-content-start align-items-center overflow-hidden h-100 mx-2">
                          <input
                            type="text"
                            className="d-none"
                            ref={$controller.sbf_TRAN_FREIGHT}
                            defaultValue="Prepaid"
                          />
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center mb-1 mb-sm-0">
                            <input
                              type="radio"
                              className="mr-1"
                              defaultChecked={true}
                              name="sbf_TRAN_FREIGHT"
                              onClick={() => {
                                $controller.sbf_TRAN_FREIGHT.current.value =
                                  "Prepaid";
                                $controller.sbf_TRAN_FREIGHT_SCNO.current.value =
                                  "";
                                $controller.sbf_TRAN_FREIGHT_SCNO.current.disabled = true;
                              }}
                            />{" "}
                            Prepaid
                          </label>
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center mb-1 mb-sm-0">
                            <input
                              type="radio"
                              className="mr-1"
                              name="sbf_TRAN_FREIGHT"
                              onClick={() => {
                                $controller.sbf_TRAN_FREIGHT.current.value =
                                  "Collect";
                                $controller.sbf_TRAN_FREIGHT_SCNO.current.disabled = false;
                              }}
                            />{" "}
                            Collect
                          </label>
                          <input
                            type="text"
                            className="border rounded"
                            disabled={true}
                            ref={$controller.sbf_TRAN_FREIGHT_SCNO}
                            placeholder="SC No. 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="mb-2 d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 100 }}
                        >
                          Term
                        </div>

                        <select
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          style={{ width: 200 }}
                          disabled={sbf_TRAN_TYPE !== "SELF"}
                          ref={$controller.sbf_TRAN_TERM}
                        >
                          {sbf_TRAN_TYPE === "LINE" && (
                            <option value="CY-CY">CY-CY</option>
                          )}
                          {sbf_TRAN_TYPE === "SELF" && (
                            <>
                              <option value="">미선택</option>
                              <option value="CY-CY">CY-CY</option>
                              <option value="CFS-CY">CFS-CY</option>
                            </>
                          )}
                          {sbf_TRAN_TYPE === "CFS" && (
                            <option value="CFS-CY">CFS-CY</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*운송 요청*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-12 m-0 mb-2 p-0">
                  <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                    운송요청
                  </span>
                </div>

                <div className="col-xl-12 m-0 p-0">
                  {sbf_TRAN_TYPE === "LINE" && (
                    <div className="row m-0 p-0">
                      <div className="col-xl-6 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <span className="h6 mb-0 font-weight-bold">
                              픽업정보
                            </span>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 rounded overflow-hidden mr-2">
                              <div className="col-4 bg-info text-white py-1 px-2 text-center font-weight-bold rounded-top-left rounded-bottom-left">
                                픽업일자
                              </div>
                              <input
                                className="col-5 form-control p-0 px-2 rounded-0 m-0 h-unset font-size-08"
                                type="date"
                                ref={$controller.sbf_PICK_DATE}
                              />
                              <select
                                className="col-3 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08 border-left-0"
                                ref={$controller.sbf_PICK_TIME}
                              >
                                {times.map((time) => {
                                  return (
                                    <option key={time} value={time}>
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 rounded overflow-hidden mr-2">
                              <div className="col-4 bg-info rounded-top-left rounded-bottom-left text-white py-1 px-2 text-center font-weight-bold">
                                픽업장소
                              </div>
                              <select
                                className="col-8 form-control rounded-0  rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset font-size-08"
                                ref={$controller.sbf_PICK_PLACE}
                              >
                                <option value="">미선택</option>
                                {pickPlace.map((item) => {
                                  return (
                                    <option key={item.ppCode}>
                                      {item.ppRemark}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 bg-info rounded-top-left rounded-bottom-left text-white py-1 px-2 text-center font-weight-bold">
                                담당자
                              </div>
                              <input
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_USER_NM}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                연락처
                              </div>
                              <input
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_USER_TEL}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 col-xl-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                요청사항
                              </div>
                              <input
                                className="col-8 col-xl-10 form-control rounded-0 rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_MEMO}
                                placeholder="픽업 요청사항을 입력해주세요"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <span className="h6 mb-0 font-weight-bold">
                              도어정보
                            </span>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                작업일자
                              </div>
                              <input
                                className="col-5 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                type="date"
                                ref={$controller.sbf_FACT_DATE}
                              />
                              <select
                                className="col-3 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                ref={$controller.sbf_FACT_TIME}
                              >
                                {times.map((time) => {
                                  return (
                                    <option key={time} value={time}>
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                작업장소
                              </div>
                              <input
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_FACT_PLACE}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info  text-white py-1 px-2 text-center font-weight-bold">
                                담당자
                              </div>
                              <input
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_FACT_USER_NM}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info bg-info text-white py-1 px-2 text-center font-weight-bold">
                                연락처
                              </div>
                              <input
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                style={{ width: 200 }}
                                ref={$controller.sbf_FACT_USER_TEL}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div
                                className="col-4 col-xl-2  rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold"
                                style={{ width: 100 }}
                              >
                                요청사항
                              </div>
                              <input
                                className="col-8 col-xl-10 form-control rounded-0 rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_FACT_MEMO}
                                placeholder="작업 요청사항을 입력해주세요"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {sbf_TRAN_TYPE === "SELF" && (
                    <div className="row m-0 p-0">
                      <div className="col-xl-6 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <span className="h6 mb-0 font-weight-bold">
                              픽업정보
                            </span>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                픽업일자
                              </div>
                              <input
                                className="col-5 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                type="date"
                                ref={$controller.sbf_PICK_DATE}
                              />
                              <select
                                className="col-3 form-control rounded-0 border-left-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                ref={$controller.sbf_PICK_TIME}
                              >
                                {times.map((time) => {
                                  return (
                                    <option key={time} value={time}>
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                픽업장소
                              </div>
                              <select
                                className="col-8 form-control rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                style={{ width: 200 }}
                                ref={$controller.sbf_PICK_PLACE}
                              >
                                <option value="">미선택</option>
                                {pickPlace.map((item) => {
                                  return (
                                    <option key={item.ppCode}>
                                      {item.ppRemark}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                담당자
                              </div>
                              <input
                                className="col-8 form-control rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_USER_NM}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                연락처
                              </div>
                              <input
                                className="col-8 form-control rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_USER_TEL}
                              />
                            </div>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 col-xl-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                요청사항
                              </div>
                              <input
                                className="col-8 col-xl-10 form-control rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_PICK_MEMO}
                                placeholder="픽업 요청사항을 입력해주세요"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {sbf_TRAN_TYPE === "CFS" && (
                    <div className="row m-0 p-0">
                      <div className="col-xl-6 m-0 p-0">
                        <div className="row m-0 p-0">
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <span className="h6 mb-0 font-weight-bold">
                              입고정보
                            </span>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                반입일자
                              </div>
                              <input
                                className="col-5 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                type="date"
                                ref={$controller.sbf_CFS_DATE}
                              />
                              <select
                                className="col-3 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                ref={$controller.sbf_CFS_TIME}
                              >
                                {times.map((time) => {
                                  return (
                                    <option key={time} value={time}>
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                CFS
                              </div>

                              <input
                                className="col-8 form-control rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_CFS_PLACE}
                                placeholder={"CFS정보를 입력해주세요"}
                                defaultValue={cfs_data.CFS_TEL_L || ""}
                                readOnly={!!cfs_data.CFS_TEL_L}
                              />
                              {/*              <select
                                className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                ref={$controller.sbf_CFS_PLACE}
                              >
                                <option value="">미선택</option>
                                <option value="test">test</option>
                              </select>*/}
                            </div>
                          </div>
                          <div className="col-xl-12 m-0 mb-2 p-0">
                            <div className="row m-0 p-0 mr-2">
                              <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                요청사항
                              </div>
                              <input
                                className="col-8 form-control rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset flex-fill"
                                type="text"
                                ref={$controller.sbf_CFS_MEMO}
                                placeholder="픽업 요청사항을 입력해주세요"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/*추가정보*/}
              <div className="row m-0 mb-4 p-0">
                <div className="col-xl-6 m-0 mb-2 p-0">
                  <div className="row m-0 mb-2 p-0">
                    <div className="col-xl-12 m-0 p-0">
                      <span className="h6 mb-0 d-inline-block py-1 px-2 font-weight-bold bg-secondary text-white">
                        추가정보(선택)
                      </span>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-xl-12 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 200 }}
                        >
                          품명(영문)
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          ref={$controller.sbf_ETC_PRODUCT}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start  overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold rounded"
                          style={{ width: 200 }}
                        >
                          반송화물(보세운송조건)
                        </div>
                        <div className="d-flex flex-row justify-content-start px-2">
                          <input
                            type="text"
                            ref={$controller.sbf_ETC_RTN_YN}
                            defaultValue="N"
                            className="d-none"
                          />
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              defaultChecked={true}
                              name="sbf_ETC_RTN_YN"
                              onClick={() => {
                                $controller.sbf_ETC_RTN_YN.current.value = "N";
                              }}
                            />{" "}
                            No
                          </label>
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              name="sbf_ETC_RTN_YN"
                              onClick={() => {
                                $controller.sbf_ETC_RTN_YN.current.value = "Y";
                              }}
                            />{" "}
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 m-0 mb-2 p-0">
                      <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                        <div
                          className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                          style={{ width: 200 }}
                        >
                          요청사항
                        </div>
                        <input
                          className="form-control p-0 px-2 m-0 h-unset flex-fill"
                          type="text"
                          style={{ width: 200 }}
                          ref={$controller.sbf_ETC_MEMO}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12 m-0 p-0 mb-3">
                      <div className=" d-flex flex-row align-items-center">
                        <span
                          className="bg-info text-white text-center rounded px-5 py-1 mr-2"
                          style={{ width: 200 }}
                        >
                          파일첨부
                        </span>
                        <UploadImageToS3WithPhp
                          region={`mall/reserveFcl/${schedule_data.sm_UID}`}
                          callback={(val) => {
                            uploadAddMemoFile(val);
                          }}
                        >
                          <span className="btn btn-sm btn-secondary py-0 mt-1">
                            +
                          </span>
                        </UploadImageToS3WithPhp>
                      </div>
                      <div>
                        {memo_files.length > 0 &&
                          memo_files.map((item, index) => {
                            let split_data = item.split("?");
                            let file_name = split_data[0];
                            let file_link = split_data[1];
                            return (
                              <div
                                key={item + "_" + index}
                                className="d-flex flex-row align-items-center mb-1"
                              >
                                <span
                                  className="cursor_pointer text-underline text-primary mr-2"
                                  onClick={() => {
                                    window.open(
                                      file_link,
                                      "_blank",
                                      "width=1200, height=800"
                                    );
                                  }}
                                >
                                  {file_name}
                                </span>
                                <button
                                  className="btn btn-sm btn-danger px-2 py-1  font-size-06"
                                  onClick={() => deleteAddMemoFile(index)}
                                >
                                  삭제
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right">
                <button
                  className="btn btn-sm btn-outline-info text-freiscop border-freiscop mr-2"
                  onClick={() => {
                    actionSave();
                  }}
                >
                  제출하기
                  <i className="fa-solid fa-arrow-up-from-bracket ml-1" />
                </button>
                {/*<button className="btn btn-sm btn-info bg-freiscop border-freiscop">*/}
                {/*  미리보기*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}

//컨테이너 기본 정보
const ContainerInfoRow = React.memo((props) => {
  const {
    item,
    index,
    setIsModalOpen,
    DeleteContainerInfo,
    ContainerDetailDataChange,
  } = props;

  return (
    <div className="row flex-nowrap m-0 p-0" style={{ width: 1110 }}>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_TYPE}
            onChange={(e) => {
              ContainerDetailDataChange(index, "sfc_TYPE", e.target.value);
            }}
          >
            <option value="">미선택</option>
            <option value="DC">Dry</option>
            <option value="RF">Reefer</option>
            <option value="TC">Tank</option>
            <option value="OT">Open Top</option>
            <option value="FR">Flat Rack</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_SIZE}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_SIZE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="20">20`</option>
            <option value="40">40`</option>
            <option value="4H">40`HQ</option>
            <option value="45">45`</option>
          </select>
        </div>
      </div>

      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="수량 입력"
            min={1}
            value={item.sfc_CNT}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_CNT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_MAKER_YN}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_MAKER_YN", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_DANGER_YN}
            onChange={(e) => {
              if (e.target.value === "Y" && dgNoteActive === 0) {
                note_modal_data = {
                  title: "DG 관련서류는 아래 첨부파일에 등록해주세요",
                  desc: "※ 관련서류: 위험물컨테이너(자동차) 수납검사증(한국해사위험 물검사원장 발행)",
                };
                dgNoteActive = 1;
                setIsModalOpen(true);
              }
              ContainerDetailDataChange(index, "sfc_DANGER_YN", e.target.value);
            }}
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_EMPTY_YN}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_EMPTY_YN", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_FLEXI_YN}
            onChange={(e) => {
              if (e.target.value === "Y" && flexiBagActive === 0) {
                note_modal_data = {
                  title: "Flexi-Bag 관련서류는 아래 첨부파일에 등록해 주세요.",
                  desc: "※ 관련서류: MSDS, LOI, COA Membership, COA Rail Impact TestReport, Insurance Policy Certificate\n",
                };
                flexiBagActive = 1;
                setIsModalOpen(true);
              }
              ContainerDetailDataChange(index, "sfc_FLEXI_YN", e.target.value);
            }}
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="컨테이너 요청사항을 입력해주세요"
            value={item.sfc_MEMO}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_MEMO", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            onClick={() => DeleteContainerInfo(index)}
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>
    </div>
  );
});

//냉동 컨테이너 정보
const ReeferContainerInfoRow = React.memo((props) => {
  const { item, index, ContainerDetailDataChange, DeleteContainerInfo } = props;
  return (
    <div className="row flex-nowrap m-0 p-0" style={{ width: 1110 }}>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <span className="text-center border-0 ">Reefer</span>

          {/*<select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_TYPE}
            disabled={true}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_TYPE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="RF">Reefer</option>
          </select>*/}
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <span className="text-center border-0 ">
            {sfc_SIZE[item.sfc_SIZE]}
          </span>
          {/*<select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_SIZE}
            contentEditable={false}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_SIZE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="20">20`</option>
            <option value="40">40`</option>
            <option value="4H">40`HQ</option>
            <option value="45">45`</option>
          </select>*/}
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="온도입력"
            onChange={(e) =>
              ContainerDetailDataChange(
                index,
                "sfc_TEMPERATURE",
                e.target.value
              )
            }
          />
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            onChange={(e) =>
              ContainerDetailDataChange(
                index,
                "sfc_FAN_OPEN_PER",
                e.target.value
              )
            }
          >
            <option value="">미선택</option>
            <option value="0">CLOSE</option>
            <option value="100">FULL OPEN</option>
            <option value="10">10% OPEN</option>
            <option value="20">20% OPEN</option>
            <option value="30">30% OPEN</option>
            <option value="40">40% OPEN</option>
            <option value="50">50% OPEN</option>
            <option value="60">60% OPEN</option>
            <option value="70">70% OPEN</option>
            <option value="80">80% OPEN</option>
            <option value="90">90% OPEN</option>
          </select>
        </div>
      </div>
      {/*      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            onClick={() => DeleteContainerInfo(index)}
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>*/}
    </div>
  );
});

//OUG 컨테이너 정보
const OUGContainerInfoRow = React.memo((props) => {
  const { item, index, ContainerDetailDataChange, DeleteContainerInfo } = props;
  return (
    <div
      className="row flex-nowrap m-0 p-0 border-bottom"
      style={{ width: 1110 }}
    >
      <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_TYPE}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_TYPE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="OT">Open Top</option>
            <option value="FR">Flat Rack</option>
          </select>
        </div>
      </div>
      <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            value={item.sfc_SIZE}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_SIZE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="20">20`</option>
            <option value="40">40`</option>
            <option value="4H">40`HQ</option>
            <option value="45">45`</option>
          </select>
        </div>
      </div>

      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_LENGTH", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_WIDTH", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_HEIGHT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_VOID", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_WEIGHT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="요청사항을 입력해주세요"
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_MEMO", e.target.value)
            }
          />
        </div>
      </div>
      {/*     <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            onClick={() => DeleteContainerInfo(index)}
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>*/}
    </div>
  );
});

//안내 문구 모달
const NoteModal = (props) => {
  const { setIsModalOpen } = props;
  return (
    <div className="modal_background">
      <div
        className="modal_body bg-white p-3 rounded-lg"
        style={{ maxWidth: 400 }}
      >
        <p className="h6 mb-0 font-weight-bold">{note_modal_data.title}</p>
        <p className="mb-2 text-secondary">{note_modal_data.desc}</p>
        <div className="text-right">
          <button
            className="btn btn-sm btn-primary bg-freiscop border-freiscop"
            onClick={() => setIsModalOpen(false)}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
