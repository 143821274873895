import React, { useEffect, useState } from "react";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "ChatNewsCompModal";
let channelData = {};

export default function ChatNewsCompModal(props) {
  const { newsChatList, actionSubscribe } = props;

  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", autoCloseModal);
    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n) return;
      channelData = {
        ...channelData,
        cnmSubscribe:
          Number(channelData.cnmSubscribe) +
          (n.data.cnuAgreeYN === "Y" ? 1 : -1),
      };
      setIsNeedUpdate((prevState) => !prevState);
    });
    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_INFO_MODAL_CALL,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n.cnmPicMain) {
          n.cnmPicMain = n.COMP_LOGO;
        }
        channelData = { ...n };
        setIsNeedUpdate((prevState) => !prevState);
      }
    );

    return () => {
      channelData = {};
      window.removeEventListener("resize", autoCloseModal);
      socket.removeLocalEventListener("ChangedNews", _mClassName);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_NEWS_INFO_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  const autoCloseModal = () => {
    document.querySelector("#ChatNewsCompModalClose").click();
  };

  const isSubscribe =
    newsChatList.findIndex(
      (item) => item.cnmMainKey === channelData.cnmMainKey
    ) > -1;

  return (
    <div
      className="modal fade"
      id="ChatNewsCompModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="ChatNewsCompModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0">
          <div className="modal-body">
            <div className="p-2 text-center">
              <div className="rounded-lg overflow-hidden mb-2">
                <div
                  className="min-width-300 min-height-300"
                  style={{
                    background: `url(${channelData.cnmPicMain}) #efefef 50% 50% / contain no-repeat`,
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-center mb-2 font-size-08">
                <span className="px-2 py-1 rounded-pill border bg-light mr-1">
                  {channelData.cnmCompNM}
                </span>
                {!channelData.isEntire && (
                  <>
                    <span
                      className={`${
                        isSubscribe
                          ? "bg-light"
                          : "bg-freiscop text-white border-freiscop"
                      } px-2 py-1 rounded-pill border ml-1 cursor_pointer`}
                      onClick={() => actionSubscribe()}
                    >
                      <span className="font-weight-bold">
                        {channelData.cnmSubscribe}명
                      </span>{" "}
                      구독{isSubscribe ? "취소" : ""}
                    </span>
                  </>
                )}
              </div>
              <p className="h5 font-weight-bold text-center">
                {channelData.cnmName}
              </p>
              <p className="h6 font-weight-bold">{channelData.cnmDesc}</p>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
              id="ChatNewsCompModalClose"
            >
              <i className="fa fa-times mr-2" />
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
