/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import $ from "jquery";

import BuddibleSocket from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "TalkNewsRoomPreviewModal";

const now = new Date(); // 현재 날짜 및 시간
let standardDate = new Date(now.setDate(now.getDate() + 1)); // 내일

function TalkNewsRoomPreviewModal(props, ref) {
  const { newsMain } = props;
  const [serverData, setServerData] = useState([]);

  useEffect(() => {
    if (typeof newsMain === "object" && newsMain.hasOwnProperty("cnmMainKey")) {
      requestUserSelect({}, (newData) => {
        setServerData(newData["returnData"]);
        moveToBottom();
      });
    }
  }, [newsMain]);

  const requestUserSelect = (filters, callback) => {
    const msgID = util.makeUUIDv4();
    const data = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      sort: [{ field: "cntCreatDate", dir: "asc" }],
      cntMainKey: newsMain.cnmMainKey,
      cntDelYN: "N",
      ...filters,
    };

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const moveToBottom = () => {
    const _target = document.querySelector("#msgList");
    setTimeout(() => {
      _target.scrollTop = _target.scrollHeight;
    }, 100);
  };

  const closeModal = () => {
    $("#TalkNewsRoomPreviewModalClose").click();
  };

  //creative time separator
  const dateSeparator = (data) => {
    let date = new Date(data);
    let separator = "";

    let standardDateYear = standardDate.getFullYear();
    let standardDateMonth = standardDate.getMonth();
    let standardDateDay = standardDate.getDate();

    let crtYear = date.getFullYear();
    let crtMonth = date.getMonth();
    let crtDate = date.getDate();

    if (
      standardDateYear !== crtYear ||
      standardDateMonth !== crtMonth ||
      standardDateDay !== crtDate
    ) {
      standardDate = date;
      separator = (
        <div className="d-flex flex-row align-items-center py-2">
          <div className="border border-bottom-0 flex-grow-1" />
          <div className="text-secondary  px-2">
            {date.toLocaleDateString()}
          </div>
          <div className="border border-bottom-0 flex-grow-1" />
        </div>
      );
    }

    return separator;
  };

  const NewsItemRender = useCallback((props) => {
    return (
      <>
        {dateSeparator(props.item.cntCreatDate)}
        <NewsItem {...props} />
      </>
    );
  }, []);

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  return (
    <div
      className="modal fade"
      id="TalkNewsRoomPreviewModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="TalkNewsRoomPreviewModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <div className="text-secondary">
              <h5 className="modal-title" id="staticBackdropLabel">
                <strong>{newsMain.cnmName}</strong>
              </h5>
              <p>{newsMain.cnmDesc}</p>
            </div>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="TalkNewsRoomPreviewModalClose"
              onClick={closeModal}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>

          <div className="modal-body">
            <div
              className="flex-fill position-relative"
              style={{ height: 600 }}
            >
              <div
                id="msgList"
                className="h-100 bg-blue-light overflow_scroll-Y scroll_hidden rounded-xl p-2"
              >
                {data.length === 0 && (
                  <div className="w-100 h-100 child-center">
                    <p className="font-weight-bold text-secondary">
                      등록된 알림톡이 없습니다.
                    </p>
                  </div>
                )}

                {data.map((item, index) => {
                  return (
                    <NewsItemRender
                      key={item.cntMSGKey}
                      index={index}
                      item={item}
                      newsMain={newsMain}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              <i className="fa fa-times mr-2" />
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(TalkNewsRoomPreviewModal);

const NewsItem = React.memo((props) => {
  const { index, item, newsMain } = props;
  const { cntPic, cntMSG, cntTitle, cntFile, cntLink, cntCreatDate } = item;
  const profile =
    newsMain.COMP_LOGO !== "" ? newsMain.COMP_LOGO.split("?")[0] : "";

  const [picLink, picName] = cntPic.split("?");
  const files = cntFile !== "" ? cntFile.split("|") : [];
  const date = new Date(cntCreatDate);
  const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  const min =
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;

  return (
    <div className="d-flex flex-row justify-content-start align-items-start py-2">
      <div className="d-flex flex-row justify-content-start">
        <div
          className="rounded-circle mr-2"
          style={{
            minWidth: 45,
            minHeight: 45,
            width: 45,
            height: 45,
            background: `url(${profile}) #fff 50% 50% /contain no-repeat`,
          }}
        />
        <div className="pt-2">
          <p className="h6 font-weight-bold mb-2">{newsMain.cnmCompNM}</p>
          <div className="d-flex flex-row align-items-end">
            <div
              className="bg-white overflow-hidden shadow-sm"
              style={{ borderRadius: "0 10px 10px 10px", maxWidth: 320 }}
            >
              <div>
                <div className="px-3 py-2 bg-freiscop-50 text-white">
                  <p className="mb-0 font-weight-bolder">{cntTitle}</p>
                </div>
                <div className="px-3">
                  {picLink !== "" && (
                    <img
                      src={picLink}
                      alt={picName}
                      width="100%"
                      className="rounded mt-3"
                    />
                  )}
                  <p className="my-2">{cntMSG}</p>
                </div>
              </div>

              {cntLink !== "" && (
                <div className="px-3 pb-2">
                  <p className="font-weight-bold">※ 참조링크</p>
                  <p>
                    <a href={cntLink} target="_blank" className="underline">
                      {cntLink}
                    </a>{" "}
                  </p>
                </div>
              )}

              {files.length > 0 && (
                <div className="px-3 pb-2">
                  <p className="mb-2 font-weight-bold">※ 첨부파일</p>
                  {files.map((file, index) => {
                    const [fileLink, fileName] = file.split("?");
                    return (
                      <p key={index} className="mb-1">
                        <span
                          className="cursor_pointer mr-2"
                          onClick={() => {
                            codeList.Modal.current.confirm(
                              `[${fileName}]을 다운로드하시겠습니까?`,
                              (ret) => {
                                if (ret) {
                                  util.downloadAs(fileLink, fileName);
                                }
                              }
                            );
                          }}
                        >
                          💾
                        </span>
                        <a
                          href={fileLink}
                          target="_blank"
                          className="underline"
                        >
                          {fileName}{" "}
                        </a>
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <span className="pl-2">
              {hour}:{min}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
