import React, { Component } from "react";
import "./stylesheet/Loading.css";

class Loading extends Component {
  componentDidMount() {
    document.querySelector("body").style.height = "100vh";
    document.querySelector("body").style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.querySelector("body").style.height = "unset";
    document.querySelector("body").style.overflow = "unset";
  }

  render() {
    return (
      <div className="loadingWrap">
        <div className="loading">
          <i className="fas fa-spinner" />
          <p>loading</p>
        </div>
      </div>
    );
  }
}

export default Loading;
