import React, { useState, useReducer, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import SearchLogisticsUserTable from "./SearchLogisticsUserTable";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import "../../StyleSheet.css";

const socket = new BuddibleSocket();
const util = new Utilities();

const controller = {
  searchType: "searchType",
  searchWord: "searchWord",
  COMP_GB: "COMP_GB",
  COMP_GB_SUB2: "COMP_GB_SUB2",
};

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}
function reducerFilters(state, action) {
  let newState = { ...state };
  if (controller.hasOwnProperty(action.type)) {
    if (typeof action.value === "string") {
      newState[action.type] = action.value.trim();
    } else {
      newState[action.type] = action.value;
    }
  }

  switch (action.type) {
    case "selectCompSubType":
      newState["searchType"] = action.value.searchType;
      newState["searchWord"] = action.value.searchWord;
      newState["COMP_GB"] = action.value.COMP_GB;
      newState["COMP_GB_SUB2"] = action.value.COMP_GB_SUB2;
      break;
    case "clearSearchValue":
      newState["searchType"] = "";
      newState["searchWord"] = "";
      newState["COMP_GB"] = "";
      newState["COMP_GB_SUB2"] = "";
      break;
    case "COMP_GB":
      newState["COMP_GB_SUB2"] = "";
      if (action.value.length > 1) {
        newState["COMP_GB_SUB2"] = action.value;
        newState["COMP_GB"] = "";
      }
      break;

    case "searchWord":
      newState["searchWord"] = action.value;
      break;

    default:
      break;
  }

  return newState;
}
const _mClassName = "SearchLogistics";

let storeType = [];

let totalCount = 0;
let totalPageCount = 0;
let page = 0;
let rowsPerPage = 30;
let loginInfo = null;

export default function SearchLogistics(props) {
  const { loginInfo, langMode, addFavUser, openUserInfoModal } = props;
  const [serverData, setServerData] = useState([]);
  const [filters, dispatchFilters] = useReducer(reducerFilters, {
    searchType: "",
    searchWord: "",
    COMP_GB: "",
    COMP_GB_SUB2: "",
  });

  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  const { t } = useTranslation();

  const BUSINESS_TYPE_B = [
    { key: "B03", name: t("forwarder3") },
    { key: "B02", name: t("forwarder2") },
    { key: "B01", name: t("forwarder") },
  ];
  const BUSINESS_TYPE = [
    { key: "E", name: t("customsOfficer") },
    { key: "F", name: t("bondedStore") },
    { key: "C", name: t("carrier") },
    { key: "D", name: t("insuranceCompany") },
    { key: "Z", name: t("manager") },
  ];
  useEffect(() => {
    requestLogisticsList();

    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        console.log("EVENT_LOGIN_AUTO_SUCCESS", n);
      }
    );

    return () => {
      storeType = [];
      totalCount = 0;
      totalPageCount = 0;
      page = 0;
      rowsPerPage = 30;

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [filters]);

  // 창고 목록 요청
  const requestStoreType = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/app/JS_comp_sub_list.php",
      msgID: msgID,
      data: [],
      bigCd: "CTF",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          storeType = newData["returnData"];
          callback && callback();
        }
      }
    });
  };

  // 물류업체 목록 조회
  const requestLogisticsList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk/JS_gbts_comp_list.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page,
      pageSize: 5,
      isDev: 1,
      sort: [
        {
          field: "COMP_NM",
          sort: "asc",
        },
      ],
      ...filters,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          if (newData.page == 0) {
            setServerData(newData["returnData"]);
          } else {
            setServerData(serverData.concat(newData["returnData"]));
          }
        }
      }
    });
  };

  //업체 사용자 목록 불러오기
  const requestLogisticsUserList = (data, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
      sort: [
        {
          field: "USER_ID",
          sort: "asc",
        },
      ],
      COMP_CD: data.COMP_CD,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = [];
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].USE_GB !== "D") {
              if (newData["returnData"][i].USER_PIC) {
                newData["returnData"][i].USER_PIC = util.changeURIForThumbnail(
                  newData["returnData"][i].USER_PIC
                );
              }
              dataList.push(newData["returnData"][i]);
            }
          }
          callback && callback(dataList);
        }
      }
    });
  };

  const handleChangeFindComp = (key, text) => {
    page = 0;
    dispatchFilters({ type: key, value: text });
  };

  const handleActiveCompType = (value) => {
    if (value === "F" && storeType.length === 0) {
      // 보세창고
      requestStoreType(() => {
        handleChangeFindComp("COMP_GB", value);
      });
      return;
    }

    handleChangeFindComp("COMP_GB", value);
  };

  const clearSearchFilters = () => {
    controller.SEARCH_LEVEL.current.value = "";
    controller.SEARCH_WORD.current.value = "";
    handleChangeFindComp("clearSearchValue");
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="border-bottom">
        <div className="overflow_scroll">
          <div
            className="d-flex flex-row flex-wrap py-2 px-1"
            style={{ minWidth: langMode === "wide" ? 1200 : 800 }}
          >
            <div className="p-1">
              <button
                className={`btn btn-sm border-gbts text-gbts rounded-pill font-weight-bold px-3 ${
                  filters.COMP_GB === "" && filters.COMP_GB_SUB2 === ""
                    ? "text-white bg-gbts"
                    : ""
                }`}
                onClick={() => {
                  clearSearchFilters();
                }}
              >
                {t("showAll")}
              </button>
            </div>
            {BUSINESS_TYPE_B.map((item) => {
              return (
                <div className="p-1" key={item.key}>
                  <button
                    className={`btn btn-sm border-gbts text-gbts rounded-pill font-weight-bold px-3 ${
                      filters.COMP_GB_SUB2 === item.key
                        ? "text-white bg-gbts"
                        : ""
                    }`}
                    onClick={() => handleActiveCompType(item.key)}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
            {BUSINESS_TYPE.map((item) => {
              return (
                <div className="p-1" key={item.key}>
                  <button
                    className={`btn btn-sm border-gbts text-gbts rounded-pill font-weight-bold px-3 ${
                      filters.COMP_GB === item.key ? "text-white bg-gbts" : ""
                    }`}
                    onClick={() => handleActiveCompType(item.key)}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {filters.COMP_GB === "F" && storeType.length > 0 && (
          <div className="px-2 pb-2">
            <select className="bg-light border-light rounded p-2 w-100">
              {storeType.map((type) => {
                return (
                  <option value={type.cSMALLCD} key={type.cSMALLCD}>
                    {type.cNAME}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="px-2 pb-2">
          <div className="d-flex flex-row rounded bg-light overflow-hidden text-secondary">
            <select
              className="p-2 border-right border-white border-width-2 border-top-0 border-bottom-0 border-left-0 bg-light max-width-100"
              ref={controller.SEARCH_LEVEL}
              onChange={(e) => {
                const value = e.target.value;
                handleChangeFindComp("searchType", value);
              }}
            >
              <option value="">-{t("condition")}-</option>
              <option value="COMP_CD">{t("compCode")}</option>
              <option value="COMP_NUM">{t("compBizNum")}</option>
              <option value="COMP_NM">{t("compName")}</option>
            </select>
            <div className="flex-grow-1 p-2 border-left border-width-2 border-white">
              <div className="d-flex flex-row align-items-center">
                <input
                  type="text"
                  placeholder={t("enterSearchTerm")}
                  className="border-0 w-100 bg-light"
                  ref={controller.SEARCH_WORD}
                  onKeyPress={(e) => {
                    const value = e.target.value;
                    if (e.charCode === 13) {
                      handleChangeFindComp("searchWord", value);
                    }
                  }}
                />
                <button className="bg-transparent border-0">
                  <i className="fa-solid fa-magnifying-glass" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 pb-2">
          <p className="h6 mb-0 font-weight-bold">
            {t("total")}{" "}
            <span className="font-weight-bold text-gbts">{totalCount}</span>
            {t("searchResult")}
          </p>
        </div>
      </div>
      <div className="h-100 w-100 overflow_scroll-Y">
        <SearchLogisticsUserTable
          loginInfo={loginInfo}
          serverData={serverData}
          addFavUser={addFavUser}
          openUserInfoModal={openUserInfoModal}
          requestLogisticsUserList={requestLogisticsUserList}
        />
        {serverData.length === totalCount && (
          <div className="p-2">
            <p className="text-center bg-light rounded p-2">
              {t("successSearchMSG")}
            </p>
          </div>
        )}
        {serverData.length < totalCount && (
          <div className="p-2">
            <button
              className="w-100 rounded font-weight-bold border-0 text-center bg-gbts text-white rounded p-2"
              onClick={() => {
                if (page < totalPageCount) {
                  page++;
                  requestLogisticsList();
                }
              }}
            >
              {t("showMore")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
