import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();

function ExpModal(props, ref) {
  const { downloadFiles, shareFiles } = props;
  const [subject, setSubject] = useState("");
  const [data, setData] = useState(undefined);
  const [selectFile, setSelectFile] = useState([]);
  const { t } = useTranslation();

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openModal(e, key, params) {
      if (key === "file") {
        SELECT_EXP_SUB_FILE_LIST(params.RPT_NO, (newData) => {
          setSubject(key);
          setData({ ...params, files: newData });
        });
        return;
      }
      setSubject(key);
      setData(params);
    },
  }));

  //수입정보 첨부파일 받아오기
  const SELECT_EXP_SUB_FILE_LIST = (RPT_NO, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/app/JS_exp_sub_file_list.php",
      msgID: msgID,
      data: {},
      RPT_NO: RPT_NO,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (callback) callback(newData["returnData"]);
        }
      }
    });
  };

  const handleSelectFiles = (e, item) => {
    setSelectFile((prevState) => {
      if (e.target.checked) {
        return [...prevState, item];
      }
      return prevState.filter((data) => data.SN !== item.SN);
    });
  };

  const closeModal = () => {
    setSubject("");
    setData(undefined);
    setSelectFile([]);
  };

  return (
    <div
      className="modal fade"
      id="ExpModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="ExpModal"
    >
      <div className="modal-dialog modal-dialog-centered mt-0 d-flex flex-row align-items-center">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              <strong>{t("file")}</strong>
            </h5>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="ExpModalClose"
              onClick={() => closeModal()}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>
          <div className="modal-body p-1">
            {data !== undefined && (
              <>
                {subject === "file" && (
                  <div>
                    <div className="row m-0 p-0 font-weight-bold border-bottom">
                      <div className="col-2 m-0 p-1 text-center">
                        {t("select")}
                      </div>
                      <div className="col-3 m-0 p-1">{t("fileType")}</div>
                      <div className="col-7 m-0 p-1">{t("fileName")}</div>
                    </div>
                    {data.files?.length > 0 &&
                      data.files.map((item, index) => {
                        const isLast = index === data.files.length - 1;
                        return (
                          <div
                            key={index}
                            className={`row m-0 p-0 ${
                              isLast ? "" : "border-bottom"
                            }`}
                          >
                            <div className="col-2 m-0 py-2 px-1 d-flex flex-row align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  handleSelectFiles(e, item);
                                }}
                              />
                            </div>
                            <div className="col-3 m-0 py-2 px-1">
                              <p>{item.FILE_GB_NM}</p>
                            </div>
                            <div className="col-7 m-0 py-2 px-1">
                              <p>{item.FILE_NM}</p>
                            </div>
                          </div>
                        );
                      })}
                    {data.files?.length === 0 && (
                      <p className="p-2">{t("notFileMSG")}</p>
                    )}
                    {data.files?.length > 0 && (
                      <div className="row m-0 mt-2 p-0">
                        <div className="col-6 m-0 p-1">
                          <button
                            className="btn btn-sm btn-secondary w-100"
                            onClick={() => downloadFiles(selectFile)}
                          >
                            {t("download")}
                          </button>
                        </div>
                        <div className="col-6 m-0 p-1">
                          <button
                            className="btn btn-sm btn-secondary w-100"
                            onClick={() => shareFiles(selectFile)}
                          >
                            {t("share")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {subject === "" && (
              <div className="col-12 text-center py-2">
                <div className="h4 mb-0">
                  <i className="fas fa-spinner spin-infinite" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ExpModal);
