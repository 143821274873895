import React from "react";
import SectionTitle from "../../SectionTitle";
import Utilities from "../../../../lib/Utilities";

const util = new Utilities();

export default React.memo(CorpCurrentStatus);
function CorpCurrentStatus({ compInfo, isExistData, isPrint, DOC_DATE }) {
  let data = [];
  let mappedData = {
    COMP_BIZ_NUM: "-",
    COMP_NM: "-",
    COMP_BOSS_NM: "-",
    COMP_INCORP_DATE: "-",
    COMP_CORP_NUM: "-",
    COMP_KIND_CD: "-",
    COMP_COWORKER_CNT: "-",
    COMP_ADDR: "-",
    COMP_ADDR_NUM: "-",
    COMP_KIND: "-",
    COMP_PRODUCT: "-",
  };

  if (isExistData && compInfo !== null) {
    data = [
      ...compInfo["1000001"],
      ...compInfo["1000002"],
      ...compInfo["1000003"],
    ];
  }

  for (let item of data) {
    switch (item.item_nm) {
      case "사업자등록번호":
        mappedData["COMP_BIZ_NUM"] = util.getAutoHyphenBizID(item.item_val);
        break;
      case "고객명":
        mappedData["COMP_NM"] = item.item_val || "(null)";
        break;
      case "대표자명":
        mappedData["COMP_BOSS_NM"] = item.item_val || "(null)";
        break;
      case "설립일자":
        mappedData["COMP_INCORP_DATE"] = item.item_val || "(null)";
        break;
      case "법인등록번호(마스킹 처리)":
        mappedData["COMP_CORP_NUM"] =
          item.item_val.indexOf("-") > -1
            ? item.item_val.split("-")[0] + "*******"
            : "";
        break;
      case "업종코드":
        mappedData["COMP_KIND_CD"] = item.item_val || "(null)";
        break;
      case "상시종업원수":
        mappedData["COMP_COWORKER_CNT"] = item.item_val || "(null)";
        break;
      case "사업장주소":
        mappedData["COMP_ADDR"] = item.item_val || "(null)";
        break;
      case "업종":
        mappedData["COMP_KIND"] = item.item_val || "(null)";
        break;
      case "주요품목":
        mappedData["COMP_PRODUCT"] = item.item_val || "(null)";
        break;
    }
  }
  const titleClass =
    " m-0 p-2 bg-freiscop-10 text-center border-bottom border-freiscop font-weight-bold";
  const descClass = "m-0 p-2 border-bottom border-freiscop";

  return (
    <div className="mb-5">
      <div className="d-flex flex-row justify-content-between">
        <SectionTitle title={"기업현황"} />
        {DOC_DATE && <p>보고서 기준일자 : {DOC_DATE}</p>}
      </div>
      <div className="container-fluid border-top border-bottom border-freiscop m-0 p-0">
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>기업명(한글)</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["COMP_NM"]}
          </div>
          <div className={`col-4 col-md-2 ${titleClass}`}>설립일자</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["COMP_INCORP_DATE"]}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>사업자등록번호</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["COMP_BIZ_NUM"]}
          </div>
          <div className={`col-4 col-md-2 ${titleClass}`}>법인등록번호</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["COMP_CORP_NUM"]}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>대표자명</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["COMP_BOSS_NM"]}
          </div>
          <div className={`col-4 col-md-2 ${titleClass}`}>상시직원수</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {util.addCommas(mappedData["COMP_COWORKER_CNT"])}명
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>주소</div>
          <div className={`col-8 col-md-10  ${descClass}`}>
            {mappedData["COMP_ADDR"]}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass} border-bottom-0`}>
            업종
          </div>
          <div className={`col-8 col-md-4  ${descClass} border-bottom-0`}>
            ({mappedData["COMP_KIND_CD"]}) {mappedData["COMP_KIND"]}
          </div>
          <div className="col-12 d-md-none border-bottom  border-freiscop" />
          <div className={`col-4 col-md-2 ${titleClass} border-bottom-0`}>
            주요제품
          </div>
          <div className={`col-8 col-md-4  ${descClass} border-bottom-0`}>
            {mappedData["COMP_PRODUCT"]}
          </div>
        </div>
      </div>
    </div>
  );
}
