/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import $ from "jquery";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import PropTypes from "prop-types";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const USER_TYPE = [
  { key: "A", desc: "활성" },
  { key: "D", desc: "비활성" },
];

const _mClassName = "UserManageEdit";
let isCheckID = false;

function UserManageEdit(props, ref) {
  const { data = {}, requestUserSelect, loginInfo } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSamePWD, setIsSamePWD] = useState(true);

  const $editRef = {
    user_id: useRef(),
    user_nm: useRef(),
    // use_gb: useRef(),
    USER_PW: useRef(),
    USER_PW_RE: useRef(),
    USER_TEL: useRef(),
    // USER_NICK: useRef(),
    USER_PART_NM: useRef(),
    USER_TEAM_NM: useRef(),
    // USER_TALK_USE_YN: useRef(),
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  //사용자 입력 폼 기본값 세팅(신규등록, 수정)
  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $editRef.user_id.current.value = userData.currentData.user_id;
      $editRef.user_id.current.disabled = true;
      $editRef.user_nm.current.value = userData.currentData.user_nm;
      $editRef.USER_PW.current.value = "";
      $editRef.USER_PW_RE.current.value = "";
      // $editRef.use_gb.current.value = userData.currentData.use_gb;
      $editRef.USER_TEL.current.value = userData.currentData.USER_TEL;
      // $editRef.USER_NICK.current.value = userData.currentData.USER_NICK;
      $editRef.USER_PART_NM.current.value = userData.currentData.USER_PART_NM;
      $editRef.USER_TEAM_NM.current.value = userData.currentData.USER_TEAM_NM;
      // $editRef.USER_TALK_USE_YN.current.value =
      //   userData.currentData.USER_TALK_USE_YN;
    } else {
      $editRef.user_id.current.value = "";
      $editRef.user_id.current.disabled = false;
      $editRef.user_nm.current.value = "";
      $editRef.USER_PW.current.value = "";
      $editRef.USER_PW_RE.current.value = "";
      // $editRef.use_gb.current.value = "";
      $editRef.USER_TEL.current.value = "";
      // $editRef.USER_NICK.current.value = "";
      $editRef.USER_PART_NM.current.value = "";
      $editRef.USER_TEAM_NM.current.value = "";
      // $editRef.USER_TALK_USE_YN.current.value = "Y";
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
    removeUser(e, params) {
      DeleteUser(e, params);
    },
  }));

  const DeleteUser = (e, params) => {
    let updateData = {
      user_id: params.currentData.user_id,
      ntDelYN: "Y",
    };
    codeList.Modal.current.confirm("해당 사용자을 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.UPDATE);
      }
    });
  };

  //사용자 저장
  const actionSave = (e) => {
    console.log(
      "$editRef.USER_PW.current.value",
      $editRef.USER_PW.current.value
    );

    if (checkValidation()) {
      let updateData = {
        comp_cd: loginInfo.comp_cd,
        user_id: $editRef.user_id.current.value.trim(),
        user_nm: $editRef.user_nm.current.value.trim(),
        use_gb: "A", //$editRef.use_gb.current.value,
        USER_PW: $editRef.USER_PW.current.value.trim(),
        USER_TEL: $editRef.USER_TEL.current.value.trim(),
        USER_NICK: $editRef.user_nm.current.value.trim(), //$editRef.USER_NICK.current.value.trim(),
        USER_PART_NM: $editRef.USER_PART_NM.current.value.trim(),
        USER_TEAM_NM: $editRef.USER_TEAM_NM.current.value.trim(),
        USER_TALK_USE_YN: "Y", // $editRef.USER_TALK_USE_YN.current.value,
      };

      let isUpdate = userData.hasOwnProperty("currentData");
      codeList.Modal.current.confirm(
        isUpdate
          ? "해당 사용자 정보를 수정하시겠습니까?"
          : "해당 사용자를 추가하시겠습니까?",
        (ret) => {
          if (ret) {
            requestUserUpdate(
              updateData,
              isUpdate ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
            );
          }
        }
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($editRef.user_id.current.value.trim() === "") {
      codeList.Modal.current.alert("계정을 입력해주세요", () => {});
      return false;
    }

    if (
      $editRef.USER_PW.current.value.trim() !==
      $editRef.USER_PW_RE.current.value.trim()
    ) {
      codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {});
      return false;
    }
    if (!userData.hasOwnProperty("currentData")) {
      if ($editRef.USER_PW.current.value.trim() === "") {
        codeList.Modal.current.alert("비밀번호를 입력해 주세요.", () => {});
        return false;
      }
      if (!isCheckID) {
        codeList.Modal.current.alert("아이디 중복확인이 필요합니다.");
        return;
      }
    }

    if ($editRef.user_nm.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요", () => {});
      return false;
    }

    // if ($editRef.use_gb.current.value.trim() === "") {
    //   codeList.Modal.current.alert("계정 사용여부를 선택해주세요", () => {});
    //   return false;
    // }

    return true;
  };

  const checkPassword = () => {
    setIsSamePWD(
      $editRef.USER_PW.current.value.trim() ===
        $editRef.USER_PW_RE.current.value.trim()
    );
  };

  // 사용자 아이디 중복체크
  const checkUserID = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/user/JS_user_id_check.php",
      msgID: msgID,
      data: {},
      comp_cd: loginInfo.comp_cd,
      user_id: $editRef.user_id.current.value.trim(),
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        let alertMSG = "사용가능한 아이디입니다.";
        if (newData.hasOwnProperty("msg")) alertMSG = newData["msg"];
        isCheckID = newData["ret"];
        codeList.Modal.current.alert(alertMSG);
      }
    });
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/user/JS_user_update_gbts.php"
          : "/user/JS_user_insert_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        isCheckID = false;
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_USER_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_USER_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "사용자 정보를 수정 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "사용자를 추가 하였습니다.",
                () => {}
              );

          $("#UserEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "사용자 정보 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "사용자 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };
  const Cancel = () => {
    isCheckID = false;
    $editRef.user_id.current.value = "";
    $editRef.user_id.current.disabled = true;
    $editRef.user_nm.current.value = "";
    // $editRef.use_gb.current.value = "";
    $editRef.USER_TEL.current.value = "";
    // $editRef.USER_NICK.current.value = "";
    $editRef.USER_PART_NM.current.value = "";
    $editRef.USER_TEAM_NM.current.value = "";
    // $editRef.USER_TALK_USE_YN.current.value = "Y";
    $("#UserEditModal").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="UserEditModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>사용자 등록/수정</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="UserEditModal"
                onClick={() => {
                  Cancel();
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <h6 className="font-weight-bold">· 로그인 정보</h6>
              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  회사코드 <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    value={loginInfo.comp_cd}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  아이디(이메일){" "}
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </label>
                <div className="col-sm-9">
                  <div className="row m-0 p-0">
                    <div className="col-9 m-0 p-0 pr-2">
                      <input
                        className="form-control d-inline-block mr-2"
                        ref={$editRef.user_id}
                      />
                    </div>
                    <div className="col-3 m-0 p-0">
                      <button
                        className="btn btn-primary w-100 bg-freiscop border-freiscop"
                        onClick={() => checkUserID()}
                      >
                        중복확인
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </label>

                <div className="col-sm-9">
                  <input
                    className="form-control"
                    ref={$editRef.USER_PW}
                    onKeyUp={checkPassword}
                    type="password"
                    placeholder={
                      userData.hasOwnProperty("currentData")
                        ? "※ 비밀번호 변경이 필요한 경우에만 입력해 주세요."
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호 확인
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    ref={$editRef.USER_PW_RE}
                    onKeyUp={checkPassword}
                    type="password"
                    placeholder="상단과 동일한 비밀번호를 입력해주세요"
                  />
                  {!isSamePWD && (
                    <div className="col-form-label text-end text-danger p-0">
                      ※ 비밀번호가 상이합니다.
                    </div>
                  )}
                </div>
              </div>

              <h6 className="font-weight-bold mt-2">· 사용자 정보</h6>

              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">이름</label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.user_nm} />
                </div>
              </div>
              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  연락처
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_TEL} />
                </div>
              </div>

              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  업무부서
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_TEAM_NM} />
                </div>
              </div>
              <div className="mb-3 mx-1 row">
                <label className="col-form-label col-sm-3 text-end">
                  담당업무
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_PART_NM} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `사용자 등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*<div
        className="modal fade"
        id="UserEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="UserEditModal"
      >
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 700 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>사용자 등록/수정</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="UserEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  계정(이메일)
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.user_id} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호
                </label>

                <div className="col-sm-9">
                  <input
                    className="form-control"
                    ref={$editRef.USER_PW}
                    onKeyUp={checkPassword}
                    type="password"
                    placeholder={
                      userData.hasOwnProperty("currentData")
                        ? "※ 비밀번호 변경이 필요한 경우에만 입력해 주세요."
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호 확인
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    ref={$editRef.USER_PW_RE}
                    onKeyUp={checkPassword}
                    type="password"
                    placeholder="상단과 동일한 비밀번호를 입력해주세요"
                  />
                  {!isSamePWD && (
                    <div className="col-form-label text-end text-danger p-0">
                      ※ 비밀번호가 상이합니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">성명</label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.user_nm} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  전화번호
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_TEL} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  톡닉네임
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_NICK} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  부서명
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_TEAM_NM} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  담당업무
                </label>
                <div className="col-sm-9">
                  <input className="form-control" ref={$editRef.USER_PART_NM} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  톡노출여부
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$editRef.USER_TALK_USE_YN}
                  >
                    <option value="Y">사용</option>
                    <option value="Y">미사용</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  계정사용
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$editRef.use_gb}
                  >
                    <option value="">미선택</option>
                    {USER_TYPE.map((menu, index) => {
                      return (
                        <option key={index} value={menu.key}>
                          {menu.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `사용자 등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  );
}

export default forwardRef(UserManageEdit);
