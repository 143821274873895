/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";

import ScheduleLogiWorkBookListTable from "./ScheduleLogiWorkBookListTable";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleLogiWorkBookList";
export default function ScheduleLogiWorkBookList({
  expImp,
  login_info,
  portList,
  airportList,
}) {
  const [filter, setFilter] = useState({});
  const controller = {
    rt_EXP_IMP: useRef(),
    rt_TYPE: useRef(),
    rt_STATUS: useRef(),
    rt_POL_CODE: useRef(),
    rt_POD_CODE: useRef(),
    SEARCH_DATE_FILED: useRef(),
    SEARCH_DATE_START: useRef(),
    SEARCH_DATE_END: useRef(),
    SEARCH_FILED: useRef(),
    SEARCH_WORD: useRef(),
  };

  useEffect(() => {
    if (expImp) {
      changedFilter();
    }
  }, []);

  const changedFilter = () => {
    const filters = {
      rt_EXP_IMP: controller.rt_EXP_IMP.current.value,
      rt_TYPE: controller.rt_TYPE.current.value,
      rt_STATUS: controller.rt_STATUS.current.value,
      rt_POL_CODE: controller.rt_POL_CODE.current.value,
      rt_POD_CODE: controller.rt_POD_CODE.current.value,
      SEARCH_DATE_FILED: controller.SEARCH_DATE_FILED.current.value,
      SEARCH_DATE_START: controller.SEARCH_DATE_START.current.value,
      SEARCH_DATE_END: controller.SEARCH_DATE_END.current.value,
      SEARCH_FILED: controller.SEARCH_FILED.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };
    setFilter(filters);
  };

  const selectStyle = {
    height: 40,
  };

  return (
    <div className="card mt-3">
      <div className="card-header bg-light border-bottom">
        <div className="card-subtitle pt-3 text-muted font-weight-bold">
          <div className="btn-toolbar mb-3 justify-content-end" role="toolbar">
            <div className="ml-2 mb-2">
              <select
                className="form-control"
                ref={controller.rt_EXP_IMP}
                onChange={changedFilter}
                style={selectStyle}
                defaultValue={expImp}
              >
                <option value="">-수출입구분-</option>
                <option value="EXP">수출</option>
                <option value="IMP">수입</option>
              </select>
            </div>
            <div className="ml-2 mb-2">
              <select
                className="form-control"
                ref={controller.rt_STATUS}
                onChange={changedFilter}
                style={selectStyle}
              >
                <option value="">-처리상태-</option>
                <option value="N">예약요청</option>
                <option value="B">부킹완료</option>
                <option value="I">인보이스발행</option>
                <option value="Y">운송완료</option>
                <option value="C">예약취소</option>
              </select>
            </div>
            <div className="ml-2 mb-2">
              <select
                className="form-control"
                ref={controller.rt_TYPE}
                onChange={changedFilter}
                style={selectStyle}
              >
                <option value="">-운송분류-</option>
                <option value="LCL">LCL</option>
                <option value="FCL">FCL</option>
                <option value="AIR">항공</option>
              </select>
            </div>
            <div className="ml-2">
              <div
                style={{ minWidth: 200, ...selectStyle }}
                className="form-control m-0 p-0"
              >
                <input
                  className="d-none"
                  type="text"
                  readOnly={true}
                  ref={controller.rt_POL_CODE}
                />
                <Select
                  required
                  multi={false}
                  clearable={true}
                  options={[...portList, ...airportList]}
                  className="border-0"
                  name={"SearchName"}
                  sortBy={"NewFullName"}
                  searchBy={"SearchName"}
                  valueField={"CustomCode"}
                  placeholder="출발지"
                  labelField={"NewFullName"}
                  autoFocus={false}
                  onChange={(val) => {
                    console.log(val);
                    let value = val.length > 0 ? val[0].CustomCode : "";
                    controller.rt_POL_CODE.current.value = value;

                    changedFilter();
                  }}
                  itemRenderer={({
                    item,
                    itemIndex,
                    props,
                    state,
                    methods,
                  }) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            state.cursor === itemIndex
                              ? "#edf0f9"
                              : "transparent",
                        }}
                      >
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0 "
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: "normal",
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontFamily: "Consolas",
                            }}
                          >
                            {item.CustomCode
                              ? "[" + item.CustomCode + "] "
                              : ""}
                          </b>

                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="ml-2 mb-2">
              <div
                style={{ minWidth: 200, ...selectStyle }}
                className="form-control m-0 p-0"
              >
                <input
                  className="d-none"
                  type="text"
                  readOnly={true}
                  ref={controller.rt_POD_CODE}
                />
                <Select
                  required
                  multi={false}
                  clearable={true}
                  options={[...portList, ...airportList]}
                  className="border-0"
                  name={"SearchName"}
                  sortBy={"NewFullName"}
                  searchBy={"SearchName"}
                  valueField={"CustomCode"}
                  placeholder="도착지"
                  labelField={"NewFullName"}
                  autoFocus={false}
                  onChange={(val) => {
                    let value = val.length > 0 ? val[0].CustomCode : "";
                    controller.rt_POD_CODE.current.value = value;

                    changedFilter();
                  }}
                  itemRenderer={({
                    item,
                    itemIndex,
                    props,
                    state,
                    methods,
                  }) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            state.cursor === itemIndex
                              ? "#edf0f9"
                              : "transparent",
                        }}
                      >
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: "normal",
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontFamily: "Consolas",
                            }}
                          >
                            {item.CustomCode
                              ? "[" + item.CustomCode + "] "
                              : ""}
                          </b>

                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            </div>

            <div className="input-group ml-2 mb-2">
              <select
                className="input-group-append form-control"
                ref={controller.SEARCH_DATE_FILED}
                onChange={changedFilter}
                style={selectStyle}
              >
                <option value="rt_REQUEST_DATE">출발희망일자</option>
                <option value="rt_CREATE_DATE">요청일</option>
                <option value="rt_UPDATE_DATE">최종수정일</option>
              </select>
              <input
                className="form-control border"
                type="date"
                ref={controller.SEARCH_DATE_START}
                style={{ height: 40 }}
                onChange={changedFilter}
              />
              <span className="input-group-append">
                <div className="input-group-text">-</div>
              </span>
              <input
                className="form-control border border-left-0"
                type="date"
                onChange={changedFilter}
                ref={controller.SEARCH_DATE_END}
                style={{ height: 40 }}
              />
            </div>
            <div className="input-group ml-2 mb-2">
              <select
                className="input-group-append form-control"
                ref={controller.SEARCH_FILED}
                onChange={changedFilter}
                style={selectStyle}
              >
                <option value="rt_DESC">요청내용</option>
                <option value="rt_ANSWER">답변</option>
              </select>
              <input
                className="form-control border"
                type="text"
                placeholder="검색어를 입력해주세요"
                ref={controller.SEARCH_WORD}
                style={{ height: 40 }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) changedFilter();
                }}
              />
              <button
                className="btn_1 rounded-0 rounded-top-right rounded-bottom-right"
                onClick={changedFilter}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ScheduleLogiWorkBookListTable
        login_info={login_info}
        filter={filter}
        expImp={expImp}
        portList={portList}
        airportList={airportList}
      />
    </div>
  );
}
