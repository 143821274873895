/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "PostSideNav";
let typeList = [];
let logoList = [];
let bcomp_list = [];
export default function PostSideNav(props) {
  let { postMenuList } = props;
  let param = useParams();
  let $searchText = useRef();
  let $searchType = useRef();
  let $searchComp = useRef();
  const [loginInfo, setLoginInfo] = useState(null);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            setLoginInfo(n["returnData"][0]);
          }
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        setLoginInfo(null);
      }
    );
  })();

  useEffect(() => {
    selectTypeList();
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData["returnData"].length) {
        setLoginInfo(userData["returnData"][0]);
      }
    }
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  const selectTypeList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_notice_type_full_name_code_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          typeList = newData["returnData"];
          selectBCompList();
        }
      }
    });
  };

  const selectBCompList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_bcomp_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          bcomp_list = [...newData["returnData"]];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const searchFilterChanged = (e, SEARCH_LEVEL) => {
    e.preventDefault();

    const params = {
      filters: {
        SEARCH_WORD: $searchText.current.value,
        SEARCH_LEVEL: SEARCH_LEVEL,
      },
      postKey: param.post_type,
    };

    if ($searchType.current) {
      params.filters.SEARCH_TYPE = $searchType.current.value;
    }
    if ($searchComp.current) {
      params.filters.SEARCH_COMP = $searchComp.current.value;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_POST_TYPE_CHANGE,
      _mClassName,
      params,
      () => {}
    );
  };

  const categorizeCreated = () => {
    let type = param.post_type;
    let list = [];
    postMenuList.forEach((menu) => {
      if (
        menu.id === type &&
        menu.id !== "pr" &&
        menu.id !== "prq" &&
        menu.id !== "ls"
      ) {
        menu.categorize.forEach((categori, index) => {
          list.push(
            <li key={`sidemenu_${index}`}>
              <a
                href="#"
                onClick={(e) => {
                  searchFilterChanged(e, categori.key);
                }}
              >
                {categori.desc}
              </a>
            </li>
          );
        });
      }
    });
    return list;
  };

  return (
    <aside className="col-lg-3">
      <div className="widget">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="검색어를 입력해 주세요"
            ref={$searchText}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                searchFilterChanged(e);
              }
            }}
          />
          <span className="input-group-btn">
            <button
              className="btn btn-default"
              type="button"
              onClick={searchFilterChanged}
            >
              <i className="icon-search" />
            </button>
          </span>
        </div>
      </div>
      <hr />
      {postMenuList.map((menu, index) => {
        return (
          <Link
            key={`sideNav_${index}`}
            to={`/Post/${menu.id}`}
            className="btn_full"
            onClick={() => {
              searchFilterChanged();
            }}
          >
            {menu.title}
          </Link>
        );
      })}

      {param.post_type !== "pr" &&
        param.post_type !== "prq" &&
        param.post_type !== "ls" && (
          <>
            <hr />
            <div className="widget" id="cat_blog">
              <h4>카테고리</h4>
              <ul>
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      searchFilterChanged(e, "");
                    }}
                  >
                    ALL
                  </a>
                </li>
                {categorizeCreated()}
              </ul>
            </div>
          </>
        )}

      {param.post_type === "ls" && (
        <>
          <div className="mb-2">
            {" "}
            <select
              className="custom-select form-control"
              ref={$searchType}
              onChange={searchFilterChanged}
              style={{ minWidth: 150 }}
            >
              <option value="">-분류-</option>
              {typeList.length > 0 &&
                typeList.map((type) => {
                  return (
                    <option key={type.SmallType} value={type.SmallType}>
                      {type.FullName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              className="custom-select form-control"
              ref={$searchComp}
              onChange={searchFilterChanged}
              style={{ minWidth: 150 }}
            >
              <option value="">-업체선택-</option>
              {bcomp_list.length > 0 &&
                bcomp_list.map((item, index) => {
                  return (
                    <option key={item.prBCOMPNM} value={item.prBCOMPNM}>
                      {item.prBCOMPNM}
                    </option>
                  );
                })}
            </select>
          </div>
        </>
      )}

      {param.post_type === "qa" && (
        <>
          <hr />
          <Link to="/WriteQnA" className="btn_1">
            문의글 작성하기
          </Link>
        </>
      )}
      {param.post_type === "prq" && (
        <>
          <hr />
          <Link to="/WriteRequestProduct" className="btn_1">
            상품(견적) 요청 게시글 작성하기
          </Link>
        </>
      )}
      {param.post_type === "ls" && loginInfo && loginInfo.comp_cd === "Z0001" && (
        <>
          <hr />
          <Link to="/WriteLogiStory" className="btn_1">
            선사•항공사 공지사항 작성하기
          </Link>
        </>
      )}
    </aside>
  );
}
