import React, { useCallback, useRef } from "react";
import EntireTalkRoomEditModal from "./EntireTalkRoomEditModal";

const user_default_image = "https://www.gbts.co.kr/images/user_pic-50x50.png";
export default function EntireTalkRoomList(props) {
  const { loginInfo, talkRoomList, selectTalkRoom, selectedTalkRoom } = props;
  const editModal = useRef();

  const openModal = (e, params) => {
    editModal.current.openModal(e, params);
  };
  const removeData = (e, params) => {
    editModal.current.removeData(e, params);
  };

  const ItemRender = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  return (
    <>
      <EntireTalkRoomEditModal ref={editModal} loginInfo={loginInfo} />
      {talkRoomList.length === 0 && (
        <p className="p-5 text-center font-weight-bold text-secondary">
          등록된 전체메세지 채널이 존재하지 않습니다.
        </p>
      )}
      {talkRoomList.map((item, index) => (
        <ItemRender
          key={item.cnmMainKey}
          item={item}
          selectedTalkRoom={selectedTalkRoom}
          selectTalkRoom={selectTalkRoom}
          openModal={openModal}
          removeData={removeData}
        />
      ))}
    </>
  );
}

const Item = (props) => {
  const { item, selectedTalkRoom, selectTalkRoom, openModal, removeData } =
    props;

  const pic =
    item.cnmPicMain !== "" ? item.cnmPicMain.split("?")[0] : user_default_image;

  const isSelected = selectedTalkRoom.cnmMainKey === item.cnmMainKey;

  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center position-relative p-2 mb-2 rounded ${
        isSelected ? "bg-freiscop-10" : ""
      } `}
    >
      <div
        className="d-flex flex-row align-items-center flex-grow-1 cursor_pointer"
        onClick={() => selectTalkRoom(item)}
      >
        <div
          className="rounded-circle overflow-hidden position-relative d-flex flex-row align-items-center justify-content-center flex-wrap border"
          style={{
            background: `url(${pic}) #fff 50% 50% / contain no-repeat`,
            width: 50,
            minWidth: 50,
            height: 50,
            minHeight: 50,
          }}
        />
        <div className="flex-grow-1 px-2">
          <p className="font-weight-bold ellipsis_1" style={{ maxWidth: 150 }}>
            {item.cnmName}
          </p>
          <p className="ellipsis_1" style={{ maxWidth: 150 }}>
            {item.cnmDesc}
          </p>
        </div>
      </div>
      <div className="min-width-100">
        <button
          className="btn btn-sm btn-secondary mr-2"
          data-toggle="modal"
          data-target="#EntireTalkRoomEditModal"
          onClick={(e) => openModal(e, item)}
        >
          수정
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => removeData(e, item)}
        >
          삭제
        </button>
      </div>
    </div>
  );
};
