import React from "react";

export default function LoadingUI() {
  return (
    <div
      className="w-100 h-100 position-fixed d-flex flex-column justify-content-center align-items-center text-white text-center"
      style={{ background: "rgba(0,0,0,0.2)", zIndex: 99999 }}
    >
      <i className="icon-spin5 animate-spin h4 text-white" />
      <p>...Loading</p>
    </div>
  );
}
