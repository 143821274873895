/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const _mClassName = "PriceAirManageTable";

const headCells = [
  {
    field: "func",
    headerName: "삭제",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            params.deleteCostData(params.currentData.SPA_IDX);
          }}
        >
          <i className="fa-regular fa-trash-can" />
        </button>
      );
    },
  },
  {
    field: "SPA_IDX",
    headerName: "No.",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "SPA_POL_NAME",
    headerName: "출발지",
    numeric: false,
    disablePadding: false,
    minWidth: 300,
  },
  {
    field: "SPA_POD_NAME",
    headerName: "도착지",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "SPA_COUNTRY",
    headerName: "국가",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "SPA_DISTANCE",
    headerName: "거리",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "SPA_TYPE",
    headerName: "적용타입",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "SPA_MONEY_KIND",
    headerName: "통화",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data || "-"}</span>;
    },
  },
  {
    field: "SPA_COST_MIN",
    headerName: "최소금액",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_MINUS_45K",
    headerName: "-45K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_PLUS_45K",
    headerName: "+45K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_100K",
    headerName: "+100K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_300K",
    headerName: "+300K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_500K",
    headerName: "+500K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_COST_1000K",
    headerName: "+1000K",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let amount = params.data === null ? "-" : util.addCommas(params.data);
      return <span>{amount}</span>;
    },
  },
  {
    field: "SPA_UPDATE_DATE",
    headerName: "최종수정일",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let date = util.getDateToFormat(params.data, "YYYY-MM-DD");
      return <span>{date}</span>;
    },
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
    "& button": {
      borderRadius: "100%",
    },
  },
}));
let loginInfo = null;
let filters = {};

export default function PriceAirManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("SPA_IDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "SPA_IDX",
      dir: "desc",
    },
  ]);

  useEffect(() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    loginInfo = loginData.returnData[0];

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_PRICE_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = { ...n };
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_PRICE_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_PRICE_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_PRICE_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_price_air_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const deleteCostData = (SPA_IDX) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_price_delete.php",
      msgID: msgID,
      data: {},
      SPA_IDX: SPA_IDX,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        codeList.Modal.current.alert(newData["MSG"]);
        if (newData["ret"]) {
          requestUserSelect();
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeData(e, params) {
    editModal.current.removeData(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                  deleteCostData={deleteCostData}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                  deleteCostData={deleteCostData}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                  deleteCostData={deleteCostData}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEdit={openEdit}
                removeData={removeData}
                deleteCostData={deleteCostData}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}
function Row(props) {
  const {
    row,
    isMultiSelect = false,
    requestUserSelect,
    requestUserUpdate,
    openEdit,
    removeData,
    deleteCostData,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                  deleteCostData: deleteCostData,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                  deleteCostData: deleteCostData,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};
