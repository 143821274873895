import React, { useCallback, useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithPhp from "../../../lib/UploadImageToS3WithPhp";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "LogiTalkChatRoomNoticeList";

let detail_data = null;
let isUpdate = false;

export default function LogiTalkChatRoomNoticeList(props) {
  const {
    notice_list,
    setIsNoticeListShow,
    activeChRoom,
    loginInfo,
    user_list,
  } = props;
  const [isDetailShow, setIsDetailShow] = useState(false);
  const [isEditShow, setIsEditShow] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);

  useEffect(() => {
    return () => {
      detail_data = null;
      isUpdate = false;
    };
  }, []);

  const $editController = {
    crnCONTENTS: useRef(),
    crnIsTop: useRef(),
  };

  //상세보기 열기
  const handleDetailShow = (item) => {
    detail_data = item;
    setIsDetailShow(true);
  };
  //상세정보 닫기
  const handleDetailClose = () => {
    detail_data = null;
    setIsDetailShow(false);
  };

  //첨부파일 업로드
  const UploadFileCallback = (value) => {
    if (value.ret) {
      let dataList = [...uploadFiles];
      let file = `${value.fileName}?${value.realFileName}`;
      dataList.push(file);
      setUploadFiles(dataList);
    }
  };

  //첨부파일 삭제
  const deleteUploadFile = (index) => {
    let dataList = [...uploadFiles];
    dataList.splice(index, 1);
    setUploadFiles(dataList);
  };

  //유효성 검사
  const checkValidate = () => {
    if ($editController.crnCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("공지 내용을 입력해주세요");
      return false;
    }
    if ($editController.crnIsTop.current.value.trim() === "") {
      codeList.Modal.current.alert("채팅방 상단 노출여부를 선택해주세요");
      return false;
    }
    return true;
  };

  //공지사항 저장
  const INSERT_TALK_NOTICE = () => {
    if (checkValidate()) {
      let updateData = {
        crnRoomKey: activeChRoom.crmMainKey,
        crnCONTENTS: $editController.crnCONTENTS.current.value,
        crnFiles: uploadFiles.join("|"),
        crnIsTop: $editController.crnIsTop.current.value,
        crnCreator: `${loginInfo.comp_cd}_${loginInfo.user_id}`,
      };

      if (isUpdate) {
        updateData.crnIDX = detail_data.crnIDX;
      }

      let msgID = util.makeUUIDv4();
      let data = {
        file: isUpdate
          ? "/talk_notice/JS_talk_notice_update.php"
          : "/talk_notice/JS_talk_notice_insert.php",
        msgID: msgID,
        ...updateData,
      };
      socket.sendMessage(data, msgID, (beforeData, newData) => {
        if (newData) {
          if (newData["ret"]) {
            if (isUpdate && detail_data) {
              for (let key in detail_data) {
                detail_data[key] = updateData[key];
              }
              if (updateData.crnFiles !== "") {
                let split_data = updateData.crnFiles.split("|");
                let fileList = [];
                for (let j = 0; j < split_data.length; j++) {
                  let file_split = split_data[j].split("?");
                  let file = {
                    fileLink: file_split[0],
                    fileName: file_split[1],
                  };
                  fileList.push(file);
                }
                detail_data.crnFiles = fileList;
              }
            }
            codeList.Modal.current.alert(newData["MSG"]);
            $editController.crnCONTENTS.current.value = "";
            $editController.crnIsTop.current.value = "";
            setUploadFiles([]);
            setIsEditShow(false);
            socket.fireLocalEventListener(
              MsgIDList.EVENT_TALK_NOTICE_DATA_UPDATE,
              _mClassName,
              newData,
              () => {}
            );
            EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE();
            codeList.Modal.current.alert(newData["MSG"]);
          } else {
            codeList.Modal.current.alert(newData["MSG"]);
          }
        }
      });
    }
  };

  //공지 삭제
  const DELETE_TALK_NOTICE = () => {
    codeList.Modal.current.confirm(
      "해당 공지사항을 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let data = {
            file: "/talk_notice/JS_talk_notice_delete.php",
            msgID: msgID,
            crnRoomKey: detail_data.crnRoomKey,
            crnIDX: detail_data.crnIDX,
          };
          socket.sendMessage(data, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  "공지사항이 정삭적으로 삭제되었습니다."
                );
                detail_data = null;
                setIsDetailShow(false);
                socket.fireLocalEventListener(
                  MsgIDList.EVENT_TALK_NOTICE_DATA_UPDATE,
                  _mClassName,
                  newData,
                  () => {}
                );
                EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE();
              } else {
                codeList.Modal.current.alert(
                  "공지사항 삭제에 실패하였습니다. 다시 시도해주세요"
                );
              }
            }
          });
        }
      }
    );
  };

  //공지 업데이트 서버
  const EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE = () => {
    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE,
      msgID: msgID,
      data: {
        crtMainKey: activeChRoom.crmMainKey,
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserCOMPNM: loginInfo.comp_nm,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserPIC: loginInfo.USER_PIC,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserName: loginInfo.user_nm,
      },
    };
    socket.sendSocketMessage(data, msgID, (b, n) => {});
  };

  //공지 수정
  const UPDATE_TALK_NOTICE = () => {
    isUpdate = true;
    setIsEditShow(true);
  };

  useEffect(() => {
    if (isEditShow && isUpdate) {
      for (let key in $editController) {
        if ($editController[key].current) {
          $editController[key].current.value = detail_data[key] || "";
        }
      }
      let files = [];
      if (detail_data.crnFiles.length > 0) {
        for (let i = 0; i < detail_data.crnFiles.length; i++) {
          let file = detail_data.crnFiles[i];
          files.push(`${file.fileLink}?${file.fileName}`);
        }
      }
      setUploadFiles(files);
    }
  }, [isEditShow]);

  return (
    <div
      className="h-100 w-100 position-absolute d-flex flex-row justify-content-between overflow-hidden"
      style={{
        background: "rgba(0,0,0,0.1)",
        backdropFilter: "blur(2px)",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <div className="p-3">
        <h6 className="font-weight-bold">
          <i
            className="fa-solid fa-arrow-left-long cursor_pointer"
            onClick={() => {
              setIsNoticeListShow(false);
            }}
          />
        </h6>
      </div>
      <div
        className="bg-white shadow-lg d-flex flex-column  animation-move-left animation-duration-1 active "
        style={{ width: 320 }}
      >
        <div className="p-2 d-flex flex-row justify-content-between align-items-center">
          <h6 className="text-info mb-0">
            <i
              className="fa-solid fa-xmark cursor_pointer text-dark mx-2"
              onClick={() => {
                setIsNoticeListShow(false);
              }}
            />
            {isEditShow ? "공지작성" : "공지"}
          </h6>
          <button
            className="btn_1"
            onClick={() => {
              if ($editController.crnCONTENTS.current) {
                $editController.crnCONTENTS.current.value = "";
              }
              if ($editController.crnIsTop.current) {
                $editController.crnIsTop.current.value = "";
              }
              setUploadFiles([]);
              setIsEditShow(!isEditShow);
            }}
          >
            {isEditShow ? "취소" : "공지작성"}
          </button>
        </div>
        {!isEditShow ? (
          <>
            {!isDetailShow && detail_data === null ? (
              <div className="flex-fill overflow_scroll p-3">
                {notice_list.length > 0 ? (
                  notice_list.map((item) => {
                    return (
                      <div
                        className="p-3 border-bottom mb-2 cursor_pointer"
                        key={item.crnIDX}
                        onClick={() => handleDetailShow(item)}
                      >
                        <p className="h6 m-0 ellipsis_1">{item.crnCONTENTS}</p>
                        <p className="m-0 mt-2 text-secondary">
                          <span className="font-weight-bold">
                            {user_list[item.crnCreator].USER_NICK ||
                              user_list[item.crnCreator].USER_NM}
                          </span>{" "}
                          · {item.crnCreateDate}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div className="font-weight-bold text-secondary">
                    등록된 공지사항이 없습니다.
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-fill bg-light">
                {detail_data !== null ? (
                  <div className="border-top border-bottom bg-white">
                    <div className="d-flex flex-row justify-content-start align-items-center pt-3 px-3">
                      <div
                        className="mr-2 border"
                        style={{
                          background: `url(${
                            user_list[detail_data.crnCreator].USER_PIC
                          })50% 50%/cover no-repeat`,
                          width: 50,
                          height: 50,
                          borderRadius: 15,
                        }}
                      />
                      <div className="flex-fill text-secondary">
                        <div className="font-weight-bold d-flex flex-row justify-content-between">
                          <span>
                            {user_list[detail_data.crnCreator].USER_NICK ||
                              user_list[detail_data.crnCreator].USER_NM}
                          </span>
                          <div>
                            <span
                              className="mr-2 cursor_pointer"
                              onClick={() => {
                                UPDATE_TALK_NOTICE();
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square" />
                            </span>{" "}
                            <span
                              className="text-danger cursor_pointer"
                              onClick={() => {
                                DELETE_TALK_NOTICE();
                              }}
                            >
                              <i className="fa-solid fa-trash" />
                            </span>
                          </div>
                        </div>
                        <div>{detail_data.crnUpdateDate} · 공지</div>
                      </div>
                    </div>
                    <p className="p-3 h6 mb-0">{detail_data.crnCONTENTS}</p>

                    {detail_data.crnFiles.length > 0 && (
                      <div className="bg-light mb-2 mx-2 p-2 rounded">
                        <p className="font-weight-bold">
                          ※첨부파일 ({" "}
                          <i className="fa-solid fa-file-arrow-down" /> 클릭시
                          파일 다운로드 )
                        </p>
                        {detail_data.crnFiles.map((item, index) => {
                          return (
                            <div
                              key={item.fileLink + "-" + index}
                              className="mb-1"
                            >
                              <p>
                                <span
                                  className="cursor_pointer"
                                  onClick={() => {
                                    window.open(
                                      item.fileLink,
                                      "_blank",
                                      "width=1000,height=1000"
                                    );
                                  }}
                                >
                                  {item.fileName}
                                </span>
                                <i
                                  className="fa-solid fa-file-arrow-down ml-2 cursor_pointer text-gbts"
                                  onClick={() => {
                                    codeList.Modal.current.confirm(
                                      `${item.fileName}을(를) 다운로드 하시겠습니까?`,
                                      (ret) => {
                                        if (ret) {
                                          util.downloadAs(
                                            item.fileLink,
                                            item.fileName,
                                            () => {
                                              codeList.Modal.current.alert(
                                                "파일이 정상적으로 다운로드 되었습니다."
                                              );
                                            }
                                          );
                                        }
                                      }
                                    );
                                  }}
                                />
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div
                      className="p-3 cursor_pointer text-center mb-0 border-top bg-gbts text-white font-weight-bold"
                      onClick={() => {
                        handleDetailClose();
                      }}
                    >
                      닫기
                    </div>
                  </div>
                ) : (
                  "공지 상세데이터를 받아오는데 실패하였습니다. 관리자에게 문의해주세요"
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex-fill bg-light">
              <div className="border-top border-bottom p-2 bg-white">
                <textarea
                  rows={5}
                  className="form-control mb-2"
                  placeholder="공지 내용을 입력해주세요"
                  ref={$editController.crnCONTENTS}
                />
                {uploadFiles.length > 0 &&
                  uploadFiles.map((item, index) => {
                    let split_data = item.split("?");
                    let fileName = split_data[1];
                    let isLast = uploadFiles.length - 1 === index;
                    return (
                      <div
                        key={`${fileName}_${index}`}
                        className={`p-2 mb-1 ${isLast ? "" : "border-bottom"}`}
                      >
                        <p className="flex-fill font-weight-bold">
                          <span className="mr-2">{fileName}</span>
                          <span
                            className="text-danger font-weight-bold cursor_pointer"
                            onClick={() => deleteUploadFile(index)}
                          >
                            삭제
                          </span>
                        </p>
                      </div>
                    );
                  })}

                <UploadImageToS3WithPhp
                  region={`talk/notice/${loginInfo.comp_cd}_${loginInfo.user_id}`}
                  callback={UploadFileCallback}
                />
                <select
                  className="form-control mb-2"
                  ref={$editController.crnIsTop}
                >
                  <option value="">-채팅방 노출여부 선택-</option>
                  <option value={0}>채팅상단 노출</option>
                  <option value={1}>비노출</option>
                </select>
                <button
                  className="btn_1 w-100"
                  onClick={() => INSERT_TALK_NOTICE()}
                >
                  공지 저장
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
