import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import { makeStyles } from "@material-ui/core/styles";

const socket = new BuddibleSocket();
const util = new Utilities();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
let rowsPerPage = 10;
let page = 0;
let totalCount = 100;
let totalPageCount = 1;

export default function LogiStory() {
  const [serverData, setServerData] = useState([]);
  const [transType, setTransType] = useState("");
  const classes = useStyles();
  const $SEARCH_WORD = useRef();

  useEffect(() => {
    requestUserSelect();
  }, [transType]);

  const requestUserSelect = (SEARCH_WORD) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_mainLogistorySelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      SEARCH_WORD: SEARCH_WORD,
      TRANS_TYPE: transType,
      sort: [
        {
          field: "prCreateDate",
          dir: "desc",
        },
      ],
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          setServerData(newData["returnData"]);
        }
      }
    });
  };

  const handleSearch = () => {
    let value = $SEARCH_WORD.current.value;
    requestUserSelect(value);
  };

  const handleCompType = (type) => {
    setTransType(type);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    requestUserSelect();
  };

  const PostItemRender = useCallback((params) => {
    return <PostItem {...params} />;
  }, []);

  const PostItem = useCallback(
    React.memo((params) => {
      const item = { ...params };

      let hash = "";
      for (let key in item) {
        if (key.indexOf("IDX") > -1) {
          hash = item[key];
        }
      }

      let prLOGO;
      if (item.prLOGO) {
        prLOGO = item.prLOGO.split("?")[0];
      }
      return (
        <>
          <div className="col-12 m-0 px-0 py-2 text-dark border-bottom">
            <div className="row m-0 p-0">
              <div className="col-12 col-lg-8 m-0 p-0">
                <div className="w-100 d-flex flex-column flex-lg-row align-items-lg-center">
                  <div className="d-flex flex-row align-items-center mb-1 mb-lg-0">
                    <div
                      className="mr-2 rounded"
                      style={{
                        minWidth: 30,
                        width: 30,
                        height: 30,
                        background: `url(${prLOGO})50% 50%/contain rgb(250,250,250) no-repeat`,
                      }}
                    >
                      {!prLOGO && (
                        <div className="w-100 h-100 rounded bg-light d-flex flex-row justify-content-center align-items-center">
                          <span>LOGO</span>
                        </div>
                      )}
                    </div>
                    <p
                      className="h6 m-0 mr-2 text-center"
                      style={{ minWidth: 100 }}
                    >
                      {item.prBCOMPNM || "업체명"}
                    </p>
                  </div>
                  <h6 className="m-0 mr-3 text-secondary d-none d-xl-block">
                    <i className="fa-solid fa-chevron-right" />
                  </h6>{" "}
                  <Link
                    to={`/PostDetail/ls/${hash}`}
                    className="text-dark flex-grow-1"
                  >
                    <p
                      className="h6 w-100 text-truncate m-0"
                      style={{ maxWidth: 600 }}
                    >
                      {item.prTITLE}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-4 m-0 mt-2 mt-lg-0 p-0 d-flex flex-row align-items-center justify-content-end">
                <p className="m-0 font-weight-bold text-secondary">
                  {item.prCreateDate.split(" ")[0]}
                </p>
                <Link
                  className="btn btn-sm btn-outline-light border-freiscop text-freiscop ml-3"
                  to={`/PostDetail/ls/${hash}`}
                >
                  <i className="fa-solid fa-arrow-right-long" />
                  <span> VIEW</span>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    })
  );

  return (
    <div className="p-3" id="services">
      <div className="container  margin_30">
        <div className="row m-0">
          <div className="col-12 m-0 p-0">
            <div className="row m-0 p-0">
              <div className="col-12 col-lg-8 m-0 p-0">
                <h3 className="m-0 mb-1 p-0 font-weight-bold">
                  선사 • 항공사 공지사항
                </h3>
                <div className="row m-0 p-0">
                  <div className="col-12 col-sm-auto m-0 p-0 mt-1">
                    <button
                      className={`btn btn-sm btn-outline-secondary border-freiscop text-freiscop px-3 mr-2 ${
                        transType === "" ? "bg-freiscop text-white" : ""
                      }`}
                      style={{ height: 34 }}
                      onClick={() => {
                        handleCompType("");
                      }}
                    >
                      ALL
                    </button>
                    <button
                      className={`btn btn-sm btn-outline-secondary border-freiscop text-freiscop px-3 mr-2 ${
                        transType === "S" ? "text-white bg-freiscop" : ""
                      }`}
                      style={{ height: 34 }}
                      onClick={() => {
                        handleCompType("S");
                      }}
                    >
                      해상
                    </button>
                    <button
                      className={`btn btn-sm btn-outline-secondary border-freiscop text-freiscop px-3 mr-2 ${
                        transType === "A" ? "text-white bg-freiscop" : ""
                      }`}
                      style={{ height: 34 }}
                      onClick={() => {
                        handleCompType("A");
                      }}
                    >
                      항공
                    </button>
                  </div>
                  <div className="col-12 col-sm-auto m-0 p-0 mt-1">
                    <div className="input-group mb-1">
                      <input
                        className="form-control m-0 py-1 border border-dark"
                        placeholder="검색어"
                        ref={$SEARCH_WORD}
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            handleSearch();
                          }
                        }}
                      />
                      <button
                        className="m-0 btn btn-sm btn-primary"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <i className="fa-solid fa-magnifying-glass" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 m-0 p-0 d-flex flex-row justify-content-end align-items-end">
                <p className="font-weight-bold px-1">공지게시일</p>
                <div
                  className="btn btn-sm btn-outline-light border-freiscop text-freiscop ml-3"
                  style={{ opacity: 0, cursor: "default" }}
                >
                  <i className="fa-solid fa-arrow-right-long" />
                  <span> VIEW</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0 my-2 border-top border-bottom border-dark">
          {serverData.length > 0
            ? serverData.map((item) => {
                return <PostItemRender key={item.prIDX} {...item} />;
              })
            : "조건에 맞는 데이터가 존재하지 않습니다."}
        </div>
        <div className="d-flex flex-row justify-content-center">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            color="primary"
            shape="rounded"
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}
