import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NewsCompModal from "./NewsCompModal";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import Loading from "../../../../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "AppWebViewNewsDetail";

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

let isLoad = true;
let loginInfo = null;
let likePostList = {};
export default function AppWebViewNewsDetail(props) {
  const [newsData, setNewsData] = useState({});
  const [subscribeList, setSubscribeList] = useState({});
  const [isIOS, setIsIOS] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const param = useParams();
  const { t, i18n } = useTranslation();

  // (() => {
  //   if (!param || !param.hasOwnProperty("idx")) {
  //     alert(t("wrongRequestMSG"));
  //     window.location.replace("/AppWebViewNews");
  //   }
  //   if (window.ReactNativeWebView === undefined) {
  //     alert(t("wrongRequestMSG"));
  //     window.location.replace("/");
  //   }
  // })();

  useEffect(() => {
    window.addEventListener("message", receiveMessage);
    document.addEventListener("message", receiveMessage);
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData["returnData"][0];
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
      }
    );
    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n) return;

      requestSubscribeList(() => {
        requestNewsDetailDataSelect();
      });
    });

    return () => {
      window.removeEventListener("message", receiveMessage);
      document.removeEventListener("message", receiveMessage);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener("ChangedNews", _mClassName);
    };
  }, []);

  useEffect(() => {
    if (loginInfo) {
      setIsIOS(localStorage.os === "ios");
      requestUserLikeList((newData) => {
        for (let i = 0; i < newData["returnData"].length; i++) {
          likePostList[newData["returnData"][i].cntlMSGKey] = "";
        }
        requestSubscribeList(() => {
          requestNewsDetailDataSelect();
        });
      });

      setTimeout(() => {
        if (isLoad) {
          setIsError(true);
        }
      }, 3000);
    }
  }, [loginInfo]);

  const requestUserLikeList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_getLikeListWithUser.php",
      msgID: msgID,
      data: {},
      cntlCreator: loginInfo.user_id,
      cntlCreatorSite: loginInfo.comp_cd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const requestLogin = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          loginInfo = newData["returnData"][0];
          requestSubscribeList(() => {
            requestNewsDetailDataSelect();
          });
        }
      }
    });
  };

  // 구독 뉴스채널 데이터 요청
  const requestSubscribeList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let subscribeTemp = {};
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = t("deleteMSGNote");
            }
            subscribeTemp[newData["returnData"][i].cnmMainKey] = "";
            newData["returnData"][i].isEntire = false;
          }
          setSubscribeList(subscribeTemp);
          callback && callback(newData);
        }
      }
    });
  };

  // 채널 정보 요청
  const requestNewsCompDataSelect = (cntMainKey, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsMainSelect.php",
      msgID: msgID,
      data: {},
      cnmMainKey: cntMainKey,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 뉴스톡 디테일 데이터 요청
  const requestNewsDetailDataSelect = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      cntIDX: param.idx,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = { ...newData["returnData"][0] };
          requestNewsCompDataSelect(newsData.cntMainKey, (newData) => {
            data = {
              ...newData["returnData"][0],
              ...data,
            };

            if (!data.cnmPicMain) {
              data.cnmPicMain = data.COMP_LOGO;
            }
            isLoad = false;
            setIsLiked(likePostList.hasOwnProperty(data.cntMSGKey));
            setNewsData(data);
          });
        }
      }
    });
  };

  const updateLikeStatusNewsWithUser = (type, updateData) => {
    const filePath = {
      INSERT: "/talk_news/JS_insertLikeChatNews.php",
      DELETE: "/talk_news/JS_deleteLikeChatNews.php",
    };
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: filePath[type],
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (type === "INSERT") {
            likePostList[updateData.cntlMSGKey] = "";
            setIsLiked(true);
          }
          if (type === "DELETE") {
            delete likePostList[updateData.cntlMSGKey];
            setIsLiked(false);
          }

          setNewsData((prevState) => {
            let nextState = { ...prevState };
            let cntLikeTotal = Number(nextState.cntLikeTotal);
            if (type === "INSERT") {
              ++cntLikeTotal;
            } else {
              --cntLikeTotal;
            }

            nextState.cntLikeTotal = cntLikeTotal;
            return nextState;
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  const receiveMessage = (event) => {
    let data = {};
    if (typeof event.data === "string") {
      data = util.buddibleDecrypt(event.data);
      data = JSON.parse(data);
    } else {
      return;
    }

    if (data.key === "init") {
      try {
        i18n.changeLanguage(data.lang, (err) => console.log(err));
        const loginData = {
          comp_cd: data.comp_cd,
          user_id: data.user_id,
          user_pw: data.user_pw,
        };
        localStorage.setItem("os", data.os);
        requestLogin(loginData);
      } catch (e) {
        setIsError(true);
      }
    }

    if (data.key === "ChangedSubscribe") {
      requestSubscribeList(() => {
        requestNewsDetailDataSelect();
      });
    }
  };
  // ws 구독 데이터 전송
  const ChangedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  // 구독정보 앱 전달
  const sendSubscribeDataForApp = (news) => {
    const data = JSON.stringify(news);
    window.ReactNativeWebView?.postMessage(data);
  };

  const clickSubscribeBtn = (news) => {
    if (!loginInfo) {
      codeList.Modal.current.alert(t("requestLoginMSG"));
      return;
    }

    const isSubscribe = subscribeList.hasOwnProperty(news.cnmMainKey);
    const confirmMSG = isSubscribe
      ? t("requestSubscribeMSG")
      : t("requestSubscribeCancelMSG");
    codeList.Modal.current.confirm(
      `[${news.cnmCompNM}-${news.cnmName}] ${confirmMSG}`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/talk_news/JS_chatNewsChannelSubscribe.php",
            msgID: msgID,
            data: {},
            cnuMainKey: news.cnmMainKey,
            cnuUserSite: loginInfo.comp_cd,
            cnuUserID: loginInfo.user_id,
            cnuRoomAuth: "",
            cnuRoomName: news.cnmName,
            cnuAgreeYN: isSubscribe ? "N" : "Y",
            cnuJoinDate: new Date(),
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  socketMsg.cnuAgreeYN === "Y"
                    ? `[${news.cnmName}]${t("successSubscribeMSG")}`
                    : `[${news.cnmName}]${t("successSubscribeCancelMSG")}`,
                  () => {
                    requestSubscribeList(() => {
                      setNewsData((prevState) => {
                        return {
                          ...prevState,
                          cnmSubscribe:
                            Number(prevState.cnmSubscribe) +
                            (socketMsg.cnuAgreeYN === "Y" ? 1 : -1),
                        };
                      });
                      const temp = {
                        ...news,
                        cnuAgreeYN: socketMsg.cnuAgreeYN,
                        isSubscribe: isSubscribe ? "N" : "Y",
                      };
                      sendSubscribeDataForApp(temp);
                      ChangedNews(temp);
                    });
                  }
                );
              } else {
                codeList.Modal.current.alert(t("errorMSG"));
              }
            }
          });
        }
      }
    );
  };

  const downloadFile = (url, name) => {
    codeList.Modal.current.confirm(
      `[${name}]${t("confirmDownloadMSG")}`,
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  if (isError) {
    return (
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <p>{t("errorMSG")}</p>
        <button className="btn_1" onClick={() => window.history.back()}>
          {t("reload")}
        </button>
      </div>
    );
  }

  if (!isError && !newsData.hasOwnProperty("cntIDX")) {
    return <Loading />;
  }

  const [year, month, date] = util
    .getDateToFormat(newsData.cntCreatDate, "YYYY-MM-DD")
    .split("-");
  const [imgLink, imgName] = newsData.cntPic.split("?");
  const files = newsData.cntFile !== "" ? newsData.cntFile.split("|") : "";

  const isSubscribe = subscribeList.hasOwnProperty(newsData.cnmMainKey);

  return (
    <>
      <NewsCompModal
        data={newsData}
        subscribeList={subscribeList}
        clickSubscribeBtn={clickSubscribeBtn}
      />
      <div className="vh-100 overflow_scroll-Y p-4 bg-white">
        {isIOS && (
          <div className="mb-4">
            <span
              className="h5 mb-0 d-inline-block cursor_pointer d-flex flex-row align-items-center text-primary"
              onClick={() => {
                window.history.back();
              }}
            >
              <i className="fa-solid fa-chevron-left mr-2" /> {t("back")}
            </span>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between mb-5">
          <div>
            <p className="h4 font-weight-bold">{newsData.cntTitle}</p>
            <p>
              {t("writer")} :{" "}
              <span
                className="underline cursor_pointer font-weight-bold"
                data-toggle="modal"
                data-target="#NewsCompModal"
              >
                {newsData.cnmCompNM}
              </span>{" "}
              · {year}
              {t("year")} {month}
              {t("month")} {date}
              {t("date")}
            </p>
          </div>
        </div>
        <div>
          {imgLink && (
            <img
              src={imgLink}
              alt={imgName}
              className="w-100 mb-3 rounded-lg"
            />
          )}
          <p className="h6 pb-3 mb-3">{newsData.cntMSG}</p>

          {newsData.cntLink !== "" && (
            <div className="h6 border-top py-3">
              <p className="font-weight-bold mb-2">※ {t("referenceLink")}</p>
              <p>
                <a
                  href={newsData.cntLink}
                  target="_blank"
                  className="underline"
                >
                  {newsData.cntLink}
                </a>
              </p>
            </div>
          )}

          {files !== "" && (
            <div className="pt-3">
              <p className="font-weight-bold mb-1">※ {t("attachments")}</p>
              {files.map((file, index) => {
                const splitData = file.split("?");
                const fileLink = splitData[0];
                const fileName = splitData[1];

                return (
                  <p key={`${file}_${index}`} className="mb-2 d-flex flex-row">
                    <span
                      className="d-inline-block mr-2 font-weight-bold cursor_pointer font-size-08"
                      style={{ minWidth: 60 }}
                      onClick={() => {
                        downloadFile(fileLink, fileName);
                      }}
                    >
                      {t("save")} 💾
                    </span>
                    <a href={fileLink}>{fileName}</a>
                  </p>
                );
              })}
            </div>
          )}
        </div>

        <div className="mt-2 pt-2 border-top">
          <span className="font-size-09 font-weight-bolder text-secondary">
            {Number(newsData.cntLikeTotal) > 0 ? (
              <>
                {t("like")} {newsData.cntLikeTotal}
              </>
            ) : (
              <p style={{ height: "1rem" }} />
            )}
          </span>
        </div>

        <div className="d-flex flex-row justify-content-between font-weight-bold mb-0 mt-2">
          <div>
            <button
              className={`btn btn-white pl-0 font-weight-bold text-${
                isLiked ? "freiscop" : "secondary"
              }`}
              onClick={() => {
                updateLikeStatusNewsWithUser(isLiked ? "DELETE" : "INSERT", {
                  cntlMSGKey: newsData.cntMSGKey,
                  cntlCreator: loginInfo.user_id,
                  cntlCreatorSite: loginInfo.comp_cd,
                });
              }}
            >
              <i
                className={
                  isLiked ? "fa-solid fa-thumbs-up" : "fa-regular fa-thumbs-up"
                }
              />
              <span className="d-inline-block ml-2 font-weight-bold">
                {t("like")}
              </span>
            </button>
          </div>
          <div className="d-flex flex-ro justify-content-end">
            <div className="mr-1 bg-light border p-2 rounded">
              {newsData.cnmSubscribe}
              {t("person")} {t("subscribe")}
            </div>
            <button
              className={`ml-1 ${
                isSubscribe ? "bg-light border p-2 rounded" : "p-2 btn_1"
              }`}
              onClick={() => clickSubscribeBtn(newsData)}
            >
              {isSubscribe
                ? `${t("subscribe")} ${t("cancel")}`
                : t("subscribe")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
