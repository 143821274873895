/* eslint-disable no-unused-vars */
/*global kakao*/
import React, { useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import StoreMallMain from "../StoreMallMain";

import "../../CustomStyle.css";
import { useParams } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

window.mapApi = {
  map: null,
  infoWindow: null,
  infoWindows: [],
  detailInfoWindow: null,
  userLocation: { lat: 37.663381958, lng: 126.799115781 },
  directionsService: null,
  directionsRenderer: null,
  timer: null,
  mapLocation: { lat: 37.663381958, lng: 126.799115781 },
  popup: null,
  lastCenter: { lat: 0, lng: 0 },
  lastZoom: 8,
  defaultZoom: 8,
  markerCluster: null,
  CustomOverlay: null,
  keyword: {},
};

//{La: 126.80205581028743, Ma: 37.55699251291402}
/*
mapLocation: { lat: 37.663381958, lng: 126.799115781 },
userLocation: { lat: 37.663381958, lng: 126.799115781 },

 */
/*
  lastZoom: 8,
  defaultZoom: 8,
 */

let geocoder = null;
let mapLoad = 0;
let dbBound = {
  centerLat: 0,
  centerLng: 0,
  maxLat: 0,
  maxLng: 0,
  minLat: 0,
  minLng: 0,
  zoomLevel: 0,
};
window.searchKeyword = { keyword: "" };

export default function StoreMallMap(props) {
  const param = useParams();

  let {
    setParentStoreList,
    selectedStore,
    selectMapMarker,
    isSearchWord,
    setWordFilter,
    setLoading,
    currentView,
    isLogiWork,
  } = props;

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_CUSTOMS_STORE_KEYWORD_CHANGED,
      "StoreMallMap",
      (b, n) => {
        window.searchKeyword = n;
        if (n.keyword === "") {
          GetStoreDB(n);
          return;
        }
        if (geocoder === null) return;
        setLoading(false);
        geocoder.addressSearch(n.keyword, function (result, status) {
          // 정상적으로 검색이 완료됐으면
          if (status === kakao.maps.services.Status.OK) {
            let addrData = result[0];
            /*let mapContainer = document.getElementById("map"), // 지도를 표시할 div
              mapOption = {
                center: new kakao.maps.LatLng(addrData.y, addrData.x), // 지도의 중심좌표
                level: 6, // 지도의 확대 레벨
              };
            window.mapApi.map = new kakao.maps.Map(mapContainer, mapOption);*/
            let location = new window.kakao.maps.LatLng(addrData.y, addrData.x);

            window.mapApi.map.setLevel(window.mapApi.defaultZoom);
            window.mapApi.map.panTo(location);
            //임시주석
            window.searchKeyword = { keyword: "" };
            GetStoreDB();
          } else if (status === "ZERO_RESULT") {
            GetStoreDB(n);
            /*setLoading(false);
            setWordFilter(isSearchWord);*/
          }
        });
      }
    );
  })();

  useEffect(() => {
    if (selectedStore) {
      selectedStoreLocation(selectedStore);
    }
  }, [selectedStore]);

  useEffect(() => {
    if (isSearchWord) {
      keywordBound(isSearchWord);
    } else {
      setLoading(false);
      setWordFilter(isSearchWord);
    }
  }, [isSearchWord]);

  useEffect(() => {
    //setLoading(true);
    if (param.hasOwnProperty("store_key")) {
      getShareURLStoreData();
    } else {
      initMap();
    }
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_CUSTOMS_STORE_KEYWORD_CHANGED,
        "StoreMallMap"
      );
      if (window.mapApi.map !== null) {
        /*window.mapApi.map.addListener.removeListener("zoom_changed");
        window.mapApi.map.addListener.removeListener("bounds_changed");*/
      }
    };
  }, []);

  const selectedStoreLocation = () => {
    //load가 안통함 부르는 위치 확인 필요
    try {
      let location = new window.kakao.maps.LatLng(
        parseFloat(selectedStore.lat),
        parseFloat(selectedStore.lng)
      );
      window.mapApi.map.setLevel(5);
      window.mapApi.map.panTo(location);
    } catch (e) {}
  };

  const loadKakao = () => {
    if (window.kakao === null || window.kakao === undefined) {
      setTimeout(loadKakao, 200);
      return;
    }
    try {
      window.kakao.maps.load(() => {
        mapLoad++;

        if (geocoder === null) {
          // console.log("loadKakao", mapLoad);
          geocoder = new window.kakao.maps.services.Geocoder();
          selectedStoreLocation();
        }

        //initMap();
      });
    } catch (e) {
      setTimeout(loadKakao, 200);
    }
  };
  loadKakao();

  const keywordBound = (isSearchWord) => {
    return;
    if (geocoder === null) return;
    geocoder.addressSearch(isSearchWord, function (result, status) {
      // 정상적으로 검색이 완료됐으면
      if (status === kakao.maps.services.Status.OK) {
        let addrData = result[0];
        let mapContainer = document.getElementById("map"), // 지도를 표시할 div
          mapOption = {
            center: new kakao.maps.LatLng(addrData.y, addrData.x), // 지도의 중심좌표
            level: 6, // 지도의 확대 레벨
          };
        window.mapApi.map = new kakao.maps.Map(mapContainer, mapOption);
        //임시주석
        //GetStoreDB();
      } else if (status === "ZERO_RESULT") {
        setLoading(false);
        setWordFilter(isSearchWord);
      }
    });
  };

  const GetStoreDB = (SEARCH_WORD = {}) => {
    let msgID = util.makeUUIDv4();

    // 지도영역을 동서남북으로 변경
    let bound = window.mapApi.map.getBounds();
    if (bound.hasOwnProperty("ha", "oa", "pa", "qa")) {
      bound.west = bound.ha;
      delete bound.ha;

      bound.east = bound.oa;
      delete bound.oa;

      bound.north = bound.pa;
      delete bound.pa;

      bound.south = bound.qa;
      delete bound.qa;
    }

    let socketMsg = {
      file: "/customsStore/JS_customs_store_main_select.php",
      msgID: msgID,
      data: {
        bound: bound,
      },
      ...window.searchKeyword,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setLoading(false);
          let returnData = newData["returnData"];

          if (returnData.length > 1) {
            for (let i = 0; i < returnData.length - 1; i++) {
              if (typeof returnData[i].lat === "string") {
                returnData[i].lat = parseFloat(returnData[i].lat);
                returnData[i].lng = parseFloat(returnData[i].lng);
              }

              for (let j = i + 1; j < returnData.length; j++) {
                if (typeof returnData[j].lat === "string") {
                  returnData[j].lat = parseFloat(returnData[j].lat);
                  returnData[j].lng = parseFloat(returnData[j].lng);
                }

                if (
                  Math.round(returnData[i].lat * 1000) ===
                    Math.round(returnData[j].lat * 1000) &&
                  Math.round(returnData[j].lng * 1000) ===
                    Math.round(returnData[j].lng * 1000)
                ) {
                  returnData[j].lat += 0.0003;
                  returnData[j].isSame = true;
                }
              }
            }
          }
          if (window.searchKeyword.keyword !== "") {
            for (let i = 0; i < returnData.length; i++) {
              /*returnData[i].lat =
                parseFloat(returnData[i].lat) +
                Math.random() * (0.007 - 0.001) -
                Math.random() * (0.007 - 0.001);
              returnData[i].lng =
                parseFloat(returnData[i].lng) +
                Math.random() * (0.007 - 0.001) -
                Math.random() * (0.007 - 0.001);*/

              if (i === 0) {
                dbBound.maxLat = returnData[i].lat;
                dbBound.maxLng = returnData[i].lng;
                dbBound.minLat = returnData[i].lat;
                dbBound.minLng = returnData[i].lng;
              } else {
                if (dbBound.maxLat < returnData[i].lat)
                  dbBound.maxLat = returnData[i].lat;

                if (dbBound.maxLng < returnData[i].lng)
                  dbBound.maxLng = returnData[i].lng;

                if (dbBound.minLat > returnData[i].lat)
                  dbBound.minLat = returnData[i].lat;

                if (dbBound.minLng > returnData[i].lng)
                  dbBound.minLng = returnData[i].lng;
              }
            }

            dbBound.centerLat =
              dbBound.minLat + (dbBound.maxLat - dbBound.minLat) / 2;
            dbBound.centerLng =
              dbBound.minLng + (dbBound.maxLng - dbBound.minLng) / 2;

            dbBound.zoomLevel = Math.ceil(
              Math.max(
                Math.max(
                  (dbBound.maxLat - dbBound.minLat) / 2,
                  (dbBound.maxLng - dbBound.minLng) / 2
                ) * 10,
                window.mapApi.lastZoom
              )
            );
          }

          if (returnData.length === 0) {
            setParentStoreList([]);
            return;
          }

          createdMarkers(returnData);
          if (window.searchKeyword.keyword !== "") {
            let location = new window.kakao.maps.LatLng(
              parseFloat(dbBound.centerLat),
              parseFloat(dbBound.centerLng)
            );

            window.mapApi.map.setLevel(dbBound.zoomLevel);
            window.mapApi.map.panTo(location);
          }
        }
      }
    });
  };

  const getShareURLStoreData = () => {
    let msgID = util.makeUUIDv4();
    let smKey = param.store_key;
    let socketMsg = {
      file: "/customsStore/JS_customs_store_main_select.php",
      msgID: msgID,
      data: {},
      smKey: smKey,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setLoading(false);
          let returnData = newData["returnData"];
          /*for (let i = 0; i < returnData.length; i++) {
            returnData[i].lat +=
              Math.random() * (0.006 - 0.001) - Math.random() * (0.006 - 0.001);
            returnData[i].lng +=
              Math.random() * (0.006 - 0.001) - Math.random() * (0.006 - 0.001);
          }*/
          if (returnData.length > 1) {
            for (let i = 0; i < returnData.length - 1; i++) {
              if (typeof returnData[i].lat === "string") {
                returnData[i].lat = parseFloat(returnData[i].lat);
                returnData[i].lng = parseFloat(returnData[i].lng);
              }

              for (let j = i + 1; j < returnData.length; j++) {
                if (typeof returnData[j].lat === "string") {
                  returnData[j].lat = parseFloat(returnData[j].lat);
                  returnData[j].lng = parseFloat(returnData[j].lng);
                }

                if (
                  Math.round(returnData[i].lat * 1000) ===
                    Math.round(returnData[j].lat * 1000) &&
                  Math.round(returnData[j].lng * 1000) ===
                    Math.round(returnData[j].lng * 1000)
                ) {
                  returnData[j].lat += 0.0003;
                  returnData[j].isSame = true;
                }
              }
            }
          }

          if (returnData.length === 0) {
            setParentStoreList([]);
            return;
          }

          let location = {
            lat: parseFloat(returnData[0].lat),
            lng: parseFloat(returnData[0].lng),
          };
          initMap(location, createdMarkers, returnData);
        }
      }
    });
  };

  const createdMarkers = (dataList) => {
    // 마커 클릭시
    window.clickedMaker = (smKey) => {
      selectMapMarker(smKey);
    };

    // 마커
    const makeMarkerContent = (data) => {
      //returnData[j].isSame
      if (data.sdKey !== null) {
        return `
            <div class="markerCustomLabels" onclick="clickedMaker('${data.smKey}')">
                <span class="badge badge-danger mb-1"
                style="position: absolute;left: -8px;top: -8px;"
                >입점 </span>
                <span class="left" />
                <span class="center">${data.smName}</span>
                <span class="right" />
              </div>
            `;
      }
      return `
            <div class="markerCustomLabels" onclick="clickedMaker('${data.smKey}')">
                <span class="left" />
                <span class="center">${data.smName}</span>
                <span class="right" />
              </div>
            `;
    };

    // 마커 데이터
    let mapData = [];

    for (let i = 0; i < dataList.length; i++) {
      let coord = new kakao.maps.LatLng(
        parseFloat(dataList[i].lat),
        parseFloat(dataList[i].lng)
      );

      let marker = new kakao.maps.CustomOverlay({
        clickable: true,
        position: coord,
        content: makeMarkerContent(dataList[i]),
        map: window.mapApi.map,
      });

      mapData.push(marker);
      if (window.mapApi.timer) clearTimeout(window.mapApi.timer);
    }

    // 마커클러스터
    if (window.mapApi.markerCluster !== null) {
      window.mapApi.markerCluster.clear();
      window.mapApi.markerCluster.addMarkers(mapData);
    } else {
      window.mapApi.markerCluster = new kakao.maps.MarkerClusterer({
        map: window.mapApi.map,
        markers: mapData,
        averageCenter: true,
        minLevel: 6,
      });
    }
    setParentStoreList(dataList);
  };

  const initMap = (location, callback, returnData) => {
    if (!param.hasOwnProperty("store_key")) {
      try {
        getUserLocation();
      } catch (e) {
        getUserLocation();
      }
    } else {
      window.mapApi.userLocation.lat = parseFloat(location.lat);
      window.mapApi.userLocation.lng = parseFloat(location.lng);
      window.mapApi.defaultZoom = 3;
    }

    // 지도 생성
    try {
      if (window.kakao === null || window.kakao === undefined) {
        setTimeout(initMap(location, callback, returnData), 200);
        return;
      }
    } catch (e) {
      setTimeout(initMap(location, callback, returnData), 200);
      return;
    }

    window.kakao.maps.load(() => {
      let container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
      // console.log(location, callback, returnData);
      let options = {
        center: new window.kakao.maps.LatLng(
          window.mapApi.userLocation.lat,
          window.mapApi.userLocation.lng
        ),
        level: window.mapApi.defaultZoom,
        draggable: true,
        scrollwheel: true,
        disableDoubleClick: true,
        disableDoubleClickZoom: true,
      };

      window.mapApi.map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

      let zoomControl = new window.kakao.maps.ZoomControl();
      window.mapApi.map.addControl(
        zoomControl,
        window.kakao.maps.ControlPosition.RIGHT
      );

      if (!param.hasOwnProperty("store_key")) {
        GetStoreDB();
      }

      // 지도 이동시
      window.kakao.maps.event.addListener(
        window.mapApi.map,
        "bounds_changed",
        () => {
          // 3 seconds after the center of the map has changed, pan back to the
          // marker.

          if (window.mapApi.timer) clearTimeout(window.mapApi.timer);
          window.mapApi.timer = setTimeout(() => {
            let mapCenter = window.mapApi.map.getCenter();
            // console.log("bounds_changed");
            if (
              Math.abs(window.mapApi.mapLocation.lat - mapCenter.La) +
                Math.abs(window.mapApi.mapLocation.lng - mapCenter.Ma) >
              0.02
            ) {
              //setLoading(true);
              window.mapApi.mapLocation.lat = mapCenter.La;
              window.mapApi.mapLocation.lng = mapCenter.Ma;

              if (window.searchKeyword.keyword === "") {
                GetStoreDB();
              }
            }

            let zoom = window.mapApi.map.getLevel();

            if (window.mapApi.lastZoom !== zoom) {
              window.mapApi.lastZoom = zoom;

              //setLoading(true);
              //this.GetGuideByLocation();

              if (window.searchKeyword.keyword === "") {
                GetStoreDB();
              }
            }
          }, 1500);
        }
      );

      if (callback) {
        callback(returnData);
      }
    });
  };

  // 현재 위치 가져오기
  const getUserLocation = () => {
    //if (window.mapApi.map === null) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          window.mapApi.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          window.mapApi.mapLocation.lat = position.coords.latitude;
          window.mapApi.mapLocation.lng = position.coords.longitude;
        },
        () => {}
      );
    } else {
      // Browser doesn't support Geolocation
      /*handleLocationError(
        false,
        window.mapApi.infoWindow,
        window.mapApi.map.getCenter()
      );*/
    }
  };

  return (
    <>
      <div className="sbMapbox">
        <div className="googleMap">
          <div
            id={"map"}
            className="googleMap_map"
            style={{
              height: isLogiWork
                ? "100vh"
                : currentView !== 2
                ? "calc(100vh - 98px)"
                : "calc(100vh - 58px)",
            }}
          />
        </div>
      </div>
    </>
  );
}
