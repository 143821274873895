import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AutoLogin from "../../../lib/AutoLogin";
import GBTSLogoSvg from "../../../assets/GBTSLogoSvg";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Loading from "../../../lib/Loading";
// import AppWebViewNewsDetail from "./components/AppWebViewNewsDetail";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "AppWebViewNews";

let scrollTop = 0;
let loginInfo = null;
let subscribeList = {};

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

export default function AppWebViewNews() {
  // -----user data
  const [loading, setLoading] = useState(false); //로딩중 여부
  const [isLoginSuccess, setIsLoginSuccess] = useState(false); //로그인 완료여부
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [isError, setIsError] = useState(false);

  // ----- only UI
  const [isShowSearch, setIsShowSearch] = useState(false);

  // -----news state data
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortModel, setSortModel] = useState([
    {
      field: "cntCreatDate",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const SEARCH_WORD = useRef();
  const { t, i18n } = useTranslation();

  // (() => {
  //   if (window.ReactNativeWebView === undefined) {
  //     window.location.replace("/");
  //     alert("잘못된 접근입니다.");
  //   }
  // })();

  useEffect(() => {
    window.addEventListener("message", receiveMessage);
    document.addEventListener("message", receiveMessage);

    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n) return;
      requestSubscribeList();
    });
    socket.addLocalEventListener("SendNews", _mClassName, (b, n) => {
      if (!n) return;
      requestUserSelect();
    });

    if (loginInfo) {
      requestSubscribeList();
    }
    return () => {
      window.removeEventListener("message", receiveMessage);
      document.removeEventListener("message", receiveMessage);
      socket.removeLocalEventListener("ChangedNews", _mClassName);
      socket.removeLocalEventListener("SendNews", _mClassName);
    };
  }, []);

  const receiveMessage = (event) => {
    let data = {};
    if (typeof event.data === "string") {
      data = util.buddibleDecrypt(event.data);
      data = JSON.parse(data);
    } else {
      return;
    }

    if (data.key === "init") {
      try {
        i18n.changeLanguage(data.lang, (err) => console.log(err));
        const loginData = {
          comp_cd: data.comp_cd,
          user_id: data.user_id,
          user_pw: data.user_pw,
        };
        localStorage.setItem("os", data.os);
        requestLogin(loginData);
      } catch (e) {
        setIsError(true);
      }
    }

    if (data.key === "ChangedSubscribe") {
      requestSubscribeList(() => {
        const msgID = util.makeUUIDv4();
        const socketData = {
          method: "ChangedNews",
          msgID: msgID,
          data: {
            ...data,
          },
        };
        socket.sendSocketMessage(
          socketData,
          msgID,
          (beforeData, newData) => {}
        );
      });
    }
  };

  const requestLogin = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          loginInfo = newData["returnData"][0];
          requestSubscribeList(() => {
            setIsLoginSuccess(true);
            setLoading(false);
          });
        }
      }
    });
  };

  const requestSubscribeList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let subscribeTemp = {};
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (!newData["returnData"][i].cnmPicMain) {
              newData["returnData"][i].cnmPicMain =
                newData["returnData"][i].COMP_LOGO;
            }

            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            subscribeTemp[newData["returnData"][i].cnmMainKey] = "";
            newData["returnData"][i].isEntire = false;
          }
          subscribeList = subscribeTemp;
          setIsNeedUpdate((prevState) => !prevState);
          callback && callback(newData);
        }
      }
    });
  };

  useEffect(() => {
    requestUserSelect();
  }, [filters]);

  const actionSearch = () => {
    setServerData([]);
    setPage(0);
    setFilters({ SEARCH_WORD: SEARCH_WORD.current.value });
  };

  const requestUserSelect = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cntDelYN: "N",
      isLogiTalk: "Y",
      isApp: "Y",
      ...filters,
    };

    setIsDataLoad(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      try {
        if (newData) {
          if (newData["ret"]) {
            const beforeFirstIndex =
              serverData.length > 0 ? serverData[0].cntIDX : 0;
            const newFirstIndex = newData["returnData"][0]?.cntIDX;

            if (newData["returnData"][0]) {
              if (Number(newFirstIndex) > Number(beforeFirstIndex)) {
                setServerData((prevState) => [
                  ...newData["returnData"],
                  ...prevState,
                ]);
              } else {
                setServerData((prevState) => [
                  ...prevState,
                  ...newData["returnData"],
                ]);
              }
              setPage((prevState) => prevState + 1);
            } else {
              setPage(0);
              setServerData([]);
            }
            setTotalCount(parseInt(newData.pageSize, 10));
            setTotalPageCount(
              parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
                (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
            );
            setIsDataLoad(false);
            if (callback) {
              callback();
            } else {
              setIsDataLoad(false);
            }
          }
        }
      } catch (e) {
        setIsError(true);
      }
    });
  };

  const clickShowMore = () => {
    scrollTop = document.getElementById("scrollView").scrollTop;
    requestUserSelect(() => {
      setTimeout(() => {
        setIsDataLoad(false);
        document
          .getElementById("scrollView")
          .scrollTo({ left: 0, top: scrollTop, behavior: "instant" });
      }, 1000);
    });
  };

  const ItemRender = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  if (isError) {
    return (
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center font-weight-bold">
        <p className="mb-1">{t("errorMSG")}</p>
        <button
          className="btn_1"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("reload")}
        </button>
      </div>
    );
  }

  return (
    <>
      {isDataLoad && <Loading />}
      <AutoLogin />
      <div className="vh-100 d-flex flex-column">
        <div className="bg-white shadow-sm p-2 mb-2">
          <div className="d-flex flex-row justify-content-between align-items-center bg-light rounded h5 px-3 py-0 mb-0 bg-info">
            <button
              className="bg-transparent border-0 p-0 m-0 text-secondary mt-1"
              onClick={() => {
                codeList.Modal.current.confirm(t("closeSearchMSG"), (ret) => {
                  if (ret) {
                    SEARCH_WORD.current.value = "";
                    setIsShowSearch(false);
                    setServerData([]);
                    setPage(0);
                    setFilters({ SEARCH_WORD: "" });
                  }
                });
              }}
            >
              <i className="fa-solid fa-rotate" />
            </button>
            <div className="flex-grow-1 px-3 h6 mb-0 h-100">
              <input
                className="d-inline-block border-0 w-100 bg-transparent p-2 font-weight-bold"
                placeholder={t("enterSearchTerm")}
                ref={SEARCH_WORD}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    actionSearch();
                  }
                }}
              />
            </div>
            <button
              className="bg-transparent border-0 p-0 text-secondary mt-1"
              onClick={() => {
                actionSearch();
              }}
            >
              <i className="fa-solid fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div id="scrollView" className="flex-grow-1 overflow_scroll-Y">
          {!isDataLoad && data.length === 0 && (
            <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-center">
              <p className="font-weight-bold text-secondary">
                {t("emptyNewsMSG")}
              </p>
            </div>
          )}
          {data.length > 0
            ? data.map((item, index) => {
                return <ItemRender key={item.id} item={item} index={index} />;
              })
            : ""}
        </div>
        {data.length < totalCount && (
          <p
            className="font-weight-bold text-center py-2 pb-3 cursor_pointer cursor_pointer_bg_light"
            onClick={() => clickShowMore()}
          >
            {t("showMore")}
          </p>
        )}
      </div>
    </>
  );
}

const Item = React.memo((props) => {
  const { item } = props;
  const { t, i18n } = useTranslation();

  const picMain = item.cntPic ? item.cntPic.split("?")[0] : false;
  const [year, month, date] = util
    .getDateToFormat(item.cntCreatDate, "YYYY-MM-DD")
    .split("-");

  return (
    <div className="col-12 m-0 mb-2 p-0">
      <div className="row m-0 p-1 bg-white cursor_pointer shadow-sm">
        <div className="col-4 m-0 p-2">
          <Link className="mb-2" to={`/AppWebViewNewsDetail/${item.cntIDX}`}>
            {picMain && (
              <div
                className="rounded-sm"
                style={{
                  height: 80,
                  background: `url(${picMain}) 50% 50% / cover no-repeat`,
                }}
              />
            )}
            {!picMain && (
              <div className="child-center" style={{ height: 80 }}>
                <GBTSLogoSvg />
              </div>
            )}
          </Link>
        </div>
        <div className="col-8 m-0 p-2 pl-0">
          <Link
            className="d-inline-block text-dark h-100 d-flex flex-column justify-content-between"
            to={`/AppWebViewNewsDetail/${item.cntIDX}`}
          >
            <div className="mb-2">
              <p className="mb-0 font-weight-bold ellipsis_1">
                {item.cntTitle}
              </p>
              <p className="ellipsis_2">{item.cntMSG}</p>
            </div>
            <div className="d-flex flex-row justify-content-between">
              {Number(item.cntLikeTotal) > 0 ? (
                <p className="font-size-09 font-weight-bold">
                  {t("like")} {item.cntLikeTotal}
                </p>
              ) : (
                <p />
              )}
              <p className="font-size-09">
                {year}
                {t("year")} {month}
                {t("month")} {date}
                {t("date")}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
});
