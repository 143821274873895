/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function ReviewEdit(props, ref) {
  let { requestReviews } = props;
  const [productData, setProductData] = useState({});
  const [loginInfo, setLoginInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reviewRating, setReviewRating] = useState("");

  const $reviewRef = {
    rvCONTENTS: useRef(),
    rvUSERName: useRef(),
    rvPWD: useRef(),
    rvIDX: useRef(),
  };

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData["returnData"][0]);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      "ReviewEdit",
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "ReviewEdit",
      (beforeData, newData) => {
        if (newData) {
          setProductData({ ...newData });
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_REVIEW_MODAL_CALL,
      "ReviewEdit",
      (b, n) => {
        setLoginInfo({});
        setTimeout(() => {
          $("body").css("padding-right", "0px");
        }, 50);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        "ReviewEdit"
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
        "ReviewEdit"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PRODUCT_REVIEW_MODAL_CALL,
        "ReviewEdit"
      );
    };
  }, []);

  //공지사항 입력 폼 기본값 세팅(신규등록, 수정)
  useEffect(() => {
    if (loginInfo !== null) {
      if (loginInfo.hasOwnProperty("rvIDX")) {
        $reviewRef.rvIDX.current.value = loginInfo.rvIDX;
        $reviewRef.rvIDX.current.disabled = true;
        $reviewRef.rvUSERName.current.value = loginInfo.rvUSERName;
        $reviewRef.rvCONTENTS.current.value = loginInfo.rvCONTENTS;
      } else {
        $reviewRef.rvIDX.current.value = "";
        $reviewRef.rvIDX.current.disabled = true;
        $reviewRef.rvUSERName.current.value = "";
        $reviewRef.rvCONTENTS.current.value = "";
      }
    }
  }, [loginInfo]);

  const dataChange = (e, params) => {
    setLoginInfo(params);
    setReviewRating(params.rvRATING / 2);
  };

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));

  //리뷰 삭제
  const delReview = (e, params) => {
    let updateData = {
      ntIDX: $reviewRef.ntIDX.current.value,
      rvUSEYN: "N",
    };
    codeList.Modal.current.confirm("해당 리뷰를 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.UPDATE);
      }
    });
  };

  // 리뷰 저장
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        rvIDX: $reviewRef.rvIDX.current.value,
        rvCONTENTS: $reviewRef.rvCONTENTS.current.value, //내용
        rvUSERName: $reviewRef.rvUSERName.current.value, //작성자이름
        rvPWD: $reviewRef.rvPWD.current.value, //비밀번호
        rvCreateDate: loginInfo.rvCreateDate
          ? loginInfo.rvCreateDate
          : new Date(),
        rvUpdateDate: new Date(),
        rvUSEYN: "Y", //표시여부
        rvRATING: reviewRating * 2,
        rvCompCD: productData.pmCreatorSite, //상품등록자 업체코드
        rvUSERCompCD: "", //작성자  업체코드
        rvUSERID: "", //작성자
      };
      if (loginInfo !== null) {
        updateData.rvUSERCompCD = loginInfo.comp_cd;
        updateData.rvUSERID = loginInfo.user_id;
      }
      requestUserUpdate(
        updateData,
        $reviewRef.rvIDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  //입력 값 검사
  const checkValidation = () => {
    if ($reviewRef.rvUSERName.current.value.trim() === "") {
      codeList.Modal.current.alert("작성자 명을 입력해주세요", () => {});
      return false;
    }
    if ($reviewRef.rvCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if (reviewRating === "") {
      codeList.Modal.current.alert("별점을 선택해 주세요", () => {});
      return false;
    }
    if (loginInfo.hasOwnProperty("rvPWD") && loginInfo.rvPWD !== "") {
      if ($reviewRef.rvPWD.current.value !== loginInfo.rvPWD) {
        codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {});
        return false;
      }
    }

    return true;
  };

  //리뷰 등록 및 업데이트
  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/review/JS_review_update.php"
          : "/review/JS_review_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_NOTICE_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_NOTICE_MANAGE_DATA_UPDATED,
            "ReviewEdit",
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert("리뷰를 수정 하였습니다.", () => {
                requestReviews();
              })
            : codeList.Modal.current.alert("리뷰를 등록 하였습니다.", () => {
                requestReviews();
              });

          $("#ReviewEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "리뷰 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "리뷰 등록 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  //리뷰 등록 취소
  const Cancel = () => {
    setReviewRating(0);
    for (let key in $reviewRef) {
      $reviewRef[key].current.value = "";
    }
  };

  //리뷰 별 생성
  const CreatedRating = () => {
    let rating = [];
    for (let i = 1; i < 6; i++) {
      if (reviewRating >= i) {
        rating.push(
          <i
            key={`rating_${i}`}
            className="fas fa-star cursor_pointer"
            data-rating={i}
            onClick={(e) => setReviewRating(i)}
          />
        );
      } else {
        rating.push(
          <i
            key={`rating_empty_${i}`}
            className="far fa-star cursor_pointer"
            data-rating={i}
            onClick={(e) => setReviewRating(i)}
          />
        );
      }
    }
    return rating;
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticReviewModal"
        aria-labelledby="#staticReviewModalLabel"
        data-backdrop="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-backdrop fade show" />
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700, zIndex: 1050 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticReviewModalLabel">
                <strong>리뷰 작성하기</strong>
              </h3>
              <button
                type="button"
                className="close btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="reviewModalLabelClose"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$reviewRef.rvIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  작성자명
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    ref={$reviewRef.rvUSERName}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="15"
                    ref={$reviewRef.rvCONTENTS}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">별점</label>
                <div className="col-sm-9 text-warning">{CreatedRating()}</div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  비밀번호
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    ref={$reviewRef.rvPWD}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `리뷰 작성을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(ReviewEdit);
