import React, { useEffect, useRef, useState } from "react";
import SearchLogiTalkShipperTable from "./SearchLogiTalkShipperTable";
import SearchExportCompanyTable from "./SearchExportCompanyTable";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const searchFields = [
  { field: "COMP_NM", desc: "상호명", type: "A" },
  { field: "COMP_NUM", desc: "사업자등록번호", type: "A" },
  { field: "COMP_CEO_NM", desc: "대표자명", type: "A" },
  { field: "EXC_COMP_NM", desc: "상호명", type: "B" },
  { field: "EXC_COMP_CEO_NM", desc: "대표자명", type: "B" },
  { field: "EXC_YEAR", desc: "인증연도", type: "B" },
  { field: "EXC_LOCATION", desc: "지역", type: "B" },
];

const _mClassName = "SearchShipperTableWrap";

export default function SearchShipperTableWrap(props) {
  const [activeCompType, setActiveCompType] = useState("A");
  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    SEARCH_START_DATE: useRef(),
    SEARCH_END_DATE: useRef(),
  };

  const handleSelectComp = (data) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_COMP_SELECT,
      _mClassName,
      null,
      () => {}
    );
  };

  const CompTypeBtn = ({ title, type }) => {
    const className =
      type === activeCompType ? "btn-primary" : "btn-outline-secondary";
    return (
      <button
        className={`ml-2 btn btn-sm ${className}`}
        onClick={() => {
          handleSelectComp();
          setActiveCompType(type);
        }}
      >
        {title}
      </button>
    );
  };

  const changedFilter = () => {
    let filters = {
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };
    if (activeCompType === "B") {
      filters = {
        ...filters,
        SEARCH_START_DATE: controller.SEARCH_START_DATE.current.value,
        SEARCH_END_DATE: controller.SEARCH_END_DATE.current.value,
      };
    }
    socket.fireLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <div className="content">
      <div className="card w-100">
        <div className="card-header border-0">
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-end align-items-lg-start">
            <div className="mb-2 mb-lg-0">
              <CompTypeBtn title="로지톡 회원사" type="A" />
              <CompTypeBtn title="수출유망 중소기업" type="B" />
            </div>
            <div className="mb-2 mb-lg-0 d-flex flex-column flex-lg-row align-items-end align-items-lg-start">
              {activeCompType === "B" && (
                <div className="input-group mb-2">
                  <input
                    type="date"
                    className="form-control border"
                    ref={controller.SEARCH_START_DATE}
                    onChange={changedFilter}
                    style={{ height: 40 }}
                  />
                  <span className="input-group-text rounded-0 border-left-0 border-right-0">
                    -
                  </span>
                  <input
                    type="date"
                    className="form-control border"
                    ref={controller.SEARCH_END_DATE}
                    onChange={changedFilter}
                    style={{ height: 40 }}
                  />
                </div>
              )}

              <div className="input-group mb-2 ml-2">
                <select
                  className="input-group-append form-control border"
                  ref={controller.SEARCH_LEVEL}
                  onChange={changedFilter}
                >
                  <option value="">-검색조건-</option>
                  {searchFields
                    .filter((item) => item.type === activeCompType)
                    .map((item) => {
                      return (
                        <option key={item.field} value={item.field}>
                          {item.desc}
                        </option>
                      );
                    })}
                </select>
                <input
                  className="form-control border"
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  style={{ height: 40 }}
                  ref={controller.SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      changedFilter();
                    }
                  }}
                />
                <button
                  className="input-group-prepend btn bg-primary border-primary text-white d-flex align-items-center"
                  style={{ height: 40 }}
                  onClick={changedFilter}
                >
                  <i className="fas fa-search mr-2" />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          {activeCompType === "A" && <SearchLogiTalkShipperTable {...props} />}
          {activeCompType === "B" && <SearchExportCompanyTable {...props} />}
        </div>
      </div>
    </div>
  );
}
