import React from "react";

export default function LogiWorkLandingConnect(props) {
  const { setIsInquiryModalOpen } = props;
  return (
    <>
      <div className="bg-light">
        <div className="container-xl p-5">
          <p className="BM-DH h2 text-center">로지웍 고객사례</p>
          <div className="row">
            <div className="col-md-6 p-2">
              <div className="rounded-lg bg-white p-5 shadow-sm d-flex flex-column align-items-center h-100">
                <img
                  className="mb-2"
                  src="/img/use_img/partron_logo.png"
                  alt="logo"
                />
                <p className="h6 font-weight-bold border-bottom border-dark">
                  구매팀
                </p>
                <p className="h6 text-center">
                  “로지웍이 제공하는 클라우드 자동 문서관리가 정말 편리합니다.
                  수출입 문서가 많은 임가공 전자부품 제조기업에 강력
                  추천합니다.”
                </p>
              </div>
            </div>
            <div className="col-md-6 p-2">
              <div className="rounded-lg bg-white p-5 shadow-sm d-flex flex-column align-items-center h-100">
                <img src="/img/use_img/junglim_logo.png" alt="logo" />
                <p className="h6 font-weight-bold border-bottom border-dark">
                  총무팀
                </p>
                <p className="h6  text-center">
                  “물류, 화물 진행상황을 스마트폰으로도 확인할 수 있고 수출입
                  화물의 선박 위치정보까지 제공받을 수 있어서 재고현황을
                  정확하게 파악할 수 있어요.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#7fb2c2" }}>
        <div className="container-xl p-5">
          <div className="py-5">
            <p className="BM-DH h2 text-center text-white">
              로지웍을 직접 체험하세요 .
            </p>
            <p className="h4 font-weight-bold text-center text-dark">
              회사 이메일과 연락처를 남겨주시면, 로지웍 담당자가 신속히 연락
              드립니다.
            </p>
            <div className="text-center">
              <button
                className="btn btn-info bg-freiscop border-freiscop"
                onClick={() => setIsInquiryModalOpen(true)}
              >
                문의하기 <i className="fa-solid fa-arrow-right ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
