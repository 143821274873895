import React, { useState, useEffect, useMemo } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { ResponsiveBar } from "@nivo/bar";
import SectionTitle from "../../SectionTitle";
import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { ChartDefaultOption } from "../../../../lib/CommonUI";
import TableBody from "@material-ui/core/TableBody";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function IncomeStatement(props) {
  const { compInfo, isExistData, isPrint } = props;
  let sales = null;
  let profit = null;
  let netProfit = null;
  let tableData = null;

  let data = [];
  let mappedData = {
    DOC_DATE: "",
    DOC_DATE_LAST: "",
    DOC_DATE_BEFORE: "",
    SALES: 0,
    SALES_LAST: 0,
    SALES_BEFORE: 0,
    PROFIT: 0,
    PROFIT_LAST: 0,
    PROFIT_BEFORE: 0,
    NET_PROFIT: 0,
    NET_PROFIT_LAST: 0,
    NET_PROFIT_BEFORE: 0,
  };
  if (isExistData && compInfo !== null) {
    data = [
      ...compInfo["2000019"],
      ...compInfo["2000021"],
      ...compInfo["2000023"],
      ...compInfo["2000025"],
      ...compInfo["2000027"],
      ...compInfo["2000029"],
      ...compInfo["2000031"],
      ...compInfo["2000033"],
      ...compInfo["2000035"],
    ];

    // 당년, 전전, 전전년 계산로직
    if (compInfo["1000003"].length > 0) {
      for (let item of compInfo["1000003"]) {
        switch (item.item_nm) {
          case "당기결산종료일자":
            mappedData["DOC_DATE"] = util.getDateToFormat(
              item.item_val,
              "YYYY-MM-DD"
            );

            break;
        }
      }

      if (mappedData["DOC_DATE"] !== "-") {
        let date = new Date(mappedData["DOC_DATE"]);
        mappedData["DOC_DATE"] = util.getDateToFormat(date, "YY");
        mappedData["DOC_DATE_LAST"] = mappedData["DOC_DATE"] - 1;
        mappedData["DOC_DATE_BEFORE"] = mappedData["DOC_DATE"] - 2;
      }
    }

    for (let item of data) {
      switch (item.item_nm) {
        case "매출액(당기)":
          mappedData["SALES"] = Number(item.item_val);
          break;
        case "매출액(전기)":
          mappedData["SALES_LAST"] = Number(item.item_val);
          break;
        case "매출액(전전기)":
          mappedData["SALES_BEFORE"] = Number(item.item_val);
          break;
        case "영업이익(당기)":
          mappedData["PROFIT"] = Number(item.item_val);
          break;
        case "영업이익(전기)":
          mappedData["PROFIT_LAST"] = Number(item.item_val);
          break;
        case "영업이익(전전기)":
          mappedData["PROFIT_BEFORE"] = Number(item.item_val);
          break;
        case "당기순이익(당기)":
          mappedData["NET_PROFIT"] = Number(item.item_val);
          break;
        case "당기순이익(전기)":
          mappedData["NET_PROFIT_LAST"] = Number(item.item_val);
          break;
        case "당기순이익(전전기)":
          mappedData["NET_PROFIT_BEFORE"] = Number(item.item_val);
          break;
      }
    }
    sales = [
      {
        name: `${mappedData["DOC_DATE_BEFORE"]}년`,
        CNT: mappedData["SALES_BEFORE"],
        key: `year${mappedData["DOC_DATE_BEFORE"]}`,
      },
      {
        name: `${mappedData["DOC_DATE_LAST"]}년`,
        CNT: mappedData["SALES_LAST"],
        key: `year${mappedData["DOC_DATE_LAST"]}`,
      },
      {
        name: `${mappedData["DOC_DATE"]}년`,
        CNT: mappedData["SALES"],
        key: `year${mappedData["DOC_DATE"]}`,
      },
    ];
    profit = [
      {
        name: `${mappedData["DOC_DATE_BEFORE"]}년`,
        CNT: mappedData["PROFIT_BEFORE"],
        key: `year${mappedData["DOC_DATE_BEFORE"]}`,
      },
      {
        name: `${mappedData["DOC_DATE_LAST"]}년`,
        CNT: mappedData["PROFIT_LAST"],
        key: `year${mappedData["DOC_DATE_LAST"]}`,
      },
      {
        name: `${mappedData["DOC_DATE"]}년`,
        CNT: mappedData["PROFIT"],
        key: `year${mappedData["DOC_DATE"]}`,
      },
    ];
    netProfit = [
      {
        name: `${mappedData["DOC_DATE_BEFORE"]}년`,
        CNT: mappedData["NET_PROFIT_BEFORE"],
        key: `year${mappedData["DOC_DATE_BEFORE"]}`,
      },
      {
        name: `${mappedData["DOC_DATE_LAST"]}년`,
        CNT: mappedData["NET_PROFIT_LAST"],
        key: `year${mappedData["DOC_DATE_LAST"]}`,
      },
      {
        name: `${mappedData["DOC_DATE"]}년`,
        CNT: mappedData["NET_PROFIT"],
        key: `year${mappedData["DOC_DATE"]}`,
      },
    ];
    tableData = [
      {
        key: "sales",
        headName: "매출액",
        current: mappedData["SALES"],
        currentPer: isFinite(mappedData["SALES"] / mappedData["SALES_LAST"])
          ? util.addCommas(
              util.getPercent(mappedData["SALES"], mappedData["SALES_LAST"])
            )
          : 100,
        last: mappedData["SALES_LAST"],
        lastPer: isFinite(mappedData["SALES_LAST"] / mappedData["SALES_BEFORE"])
          ? util.addCommas(
              util.getPercent(
                mappedData["SALES_LAST"],
                mappedData["SALES_BEFORE"]
              )
            )
          : 100,
        before: mappedData["SALES_BEFORE"],
        beforePer: 0,
      },
      {
        key: "profit",
        headName: "영역이익",
        current: mappedData["PROFIT"],
        currentPer: isFinite(mappedData["PROFIT"] / mappedData["PROFIT_LAST"])
          ? util.addCommas(
              util.getPercent(mappedData["PROFIT"], mappedData["PROFIT_LAST"])
            )
          : 100,
        last: mappedData["PROFIT_LAST"],
        lastPer: isFinite(
          mappedData["PROFIT_LAST"] / mappedData["PROFIT_BEFORE"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["PROFIT_LAST"],
                mappedData["PROFIT_BEFORE"]
              )
            )
          : 100,
        before: mappedData["PROFIT_BEFORE"],
        beforePer: 0,
      },
      {
        key: "netProfit",
        headName: "당기순이익",
        current: mappedData["NET_PROFIT"],
        currentPer: isFinite(
          mappedData["NET_PROFIT"] / mappedData["NET_PROFIT_LAST"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["NET_PROFIT"],
                mappedData["NET_PROFIT_LAST"]
              )
            )
          : 100,
        last: mappedData["NET_PROFIT_LAST"],
        lastPer: isFinite(
          mappedData["NET_PROFIT_LAST"] / mappedData["NET_PROFIT_BEFORE"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["NET_PROFIT_LAST"],
                mappedData["NET_PROFIT_BEFORE"]
              )
            )
          : 100,
        before: mappedData["NET_PROFIT_BEFORE"],
        beforePer: 0,
      },
    ];
  }
  const headCells = [
    {
      field: "headName",
      headerName: "항목",
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {params.data}
          </span>
        );
      },
    },
    {
      field: "before",
      headerName: `${mappedData["DOC_DATE_BEFORE"]}년(YoY)`,
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}({params.currentData.beforePer}%)
          </span>
        );
      },
    },
    {
      field: "last",
      headerName: `${mappedData["DOC_DATE_LAST"]}년(YoY)`,
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}({params.currentData.lastPer}%)
          </span>
        );
      },
    },
    {
      field: "current",
      headerName: `${mappedData["DOC_DATE"]}년(YoY)`,
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}({params.currentData.currentPer}%)
          </span>
        );
      },
    },
  ];

  return (
    <div className="mb-5 ">
      <SectionTitle title="재무현황(손익계산서)" />{" "}
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flexd-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar
              isPrint={isPrint}
              data={sales}
              title={"매출액"}
              colors={["#7f7f7f", "#7f7f7f", "#125186"]}
            />
          </div>
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flexd-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar
              isPrint={isPrint}
              data={profit}
              title={"영업이익"}
              colors={["#7f7f7f", "#7f7f7f", "#125186"]}
            />
          </div>
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flexd-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar
              isPrint={isPrint}
              data={netProfit}
              title={"당기순이익"}
              colors={["#7f7f7f", "#7f7f7f", "#125186"]}
            />
          </div>
        </div>
        <p className="text-right mb-1">단위 : 백만원</p>
        <div className="border-top border-bottom border-freiscop">
          <TableContainer>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.field}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      style={{
                        background: "rgba(0,32,96,0.1)",
                        textAlign: "center",
                      }}
                    >
                      {headCell.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.map((row, index) => {
                    return (
                      <TableRow role="checkbox" key={row.key}>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.field}
                            padding={
                              headCell.disablePadding ? "none" : "normal"
                            } //패딩 값 부여 여부
                            style={{ textAlign: "center" }}
                          >
                            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
                              ? headCell.renderCell({
                                  data: row[headCell.field],
                                  currentData: row,
                                  currentHeader: headCell.field,
                                  headCells: headCells,
                                })
                              : headCell.hasOwnProperty("valueGetter")
                              ? headCell.valueGetter({
                                  data: row[headCell.field],
                                  currentData: row,
                                  currentHeader: headCell.field,
                                  headCells: headCells,
                                })
                              : row[headCell.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

const MyResponsiveBar = ({ data, title, colors, isPrint }) => {
  if (data === null) {
    return <p>{title}데이터가 존재하지 않습니다.</p>;
  }

  const widthProps = isPrint ? { width: 300 } : {};
  return (
    <ResponsiveBar
      {...ChartDefaultOption.ResponsiveBar}
      margin={{ top: 30, right: 40, bottom: 50, left: 40 }}
      data={data}
      keys={["CNT"]}
      indexBy="name"
      colorBy={"indexValue"}
      legends={[]}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: title,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      valueFormat={(v) => {
        return util.addCommas(v);
      }}
      colors={[...colors]}
      labelTextColor={"#ffffff"}
      {...widthProps}
      tooltip={({
        id,
        value,
        formattedValue,
        index,
        indexValue,
        data,
        color,
      }) => (
        <div
          style={{
            padding: 12,
            color: "white",
            background: "#222222",
          }}
        >
          <strong>
            {indexValue} : {util.addCommas(formattedValue)}
          </strong>
        </div>
      )}
    />
  );
};
