import React, { useRef } from "react";
import CorpInfoReferenceHistoryTable from "./CorpInfoReferenceHistoryTable";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "CorpInfoReferenceHistory";

export default function CorpInfoReferenceHistory({
  loginInfo,
  selectBASADataHistory,
}) {
  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  const actionSearch = () => {
    const filters = {
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value
        .trim()
        .split("-")
        .join(""),
    };

    socket.fireLocalEventListener(
      MsgIDList.EVENT_TALK_CORP_INFO_HISTORY_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <>
      <div className="content">
        <div className="card w-100">
          <div className="card-header border-0">
            <div className="d-flex flex-row justify-content-end">
              <div>
                <div className="input-group">
                  <select
                    className="input-group-append form-control"
                    ref={controller.SEARCH_LEVEL}
                    style={{ height: 38 }}
                  >
                    <option value="">-검색조건-</option>
                    {/*<option value="brhResult">업체명</option>*/}
                    <option value="brhBizno">사업자등록번호</option>
                  </select>
                  <input
                    className="form-control border"
                    placeholder="검색어를 입력해주세요"
                    type="text"
                    ref={controller.SEARCH_WORD}
                    style={{ height: 38, minWidth: 180 }}
                    onKeyPress={(e) => e.charCode === 13 && actionSearch()}
                  />
                  <button
                    className="btn btn-primary input-group-btn"
                    onClick={actionSearch}
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <CorpInfoReferenceHistoryTable
              loginInfo={loginInfo}
              selectBASADataHistory={selectBASADataHistory}
            />
          </div>
        </div>
      </div>
    </>
  );
}
