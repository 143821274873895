import React from "react";

export default function ImgView() {
  return (
    <span className="d-inline-block content-text">
      {/*<h2>GBTS</h2>*/}
      {/*<h2>GBTS</h2>*/}
      <img
        className="d-block d-xl-none" // opacity-80
        width="100%"
        src="/img/info/info_view.jpg"
      />
      <span
        className="vw-100 d-none d-xl-block" // opacity-80
        style={{
          background: "url(/img/info/info_view.jpg) 50% 50% / cover no-repeat",
          height: "40vh",
          maxHeight: "860px",
        }}
      />

      {/*<div className="w-100 h-100 opacity-mask" />*/}
    </span>
  );
}
