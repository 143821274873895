import React, { useEffect } from "react";

export default function OpenLogiWork() {
  return (
    <div className="container mt-3">
      <div
        className="row m-0 p-3 border rounded"
        style={{
          background: `url(/img/main/main_banner_background.jpg) 0% 0%/cover no-repeat`,
        }}
      >
        <div className="col-12 col-sm-9 m-0 p-0 ">
          <p className="S-CORE h4 mb-2 text-white">
            디지털 수출입 물류 ALL IN ONE 서비스{" "}
            <span className="font-weight-bold m-0 text-yellow text">
              로지웍(LogiWork)
            </span>
          </p>
          <p className="text-white">
            스케쥴 & 운임 조회 │ 온라인 물류부킹 │ 수출입 문서 생성 │ 클라우드
            문서관리 │ 화물위치 확인 │ 물류 AI 챗봇 │ 기업신용 정보조회
          </p>
        </div>
        <div className="col-12 col-sm-3 m-0 mt-2 mt-md-0 p-0 d-flex flex-row justify-content-end align-items-center">
          <div>
            <button
              className="S-CORE btn btn-sm btn-light border-0 font-weight-bold text-info"
              onClick={() => window.open("https://system.gbts.co.kr/")}
            >
              로지웍 바로가기 <i className="fa-solid fa-right-to-bracket" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
