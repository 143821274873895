import React, { useState, useRef, useEffect, useCallback } from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import LogoSvg from "../assets/LogoSvg";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function PopupRegistModal() {
  const handleButtonClick = () => {
    $("#popupRegistModalClose").click();

    setTimeout(function () {
      // needs to be in a timeout because we wait for BG to leave
      // keep class modal-open to body so users can scroll
      $("body").addClass("modal-open");
    }, 400);
  };

  return (
    <div
      className="modal fade"
      id="popupRegistModal"
      tabIndex="-1"
      aria-labelledby="popupRegistModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header" style={{ borderBottom: "none" }}>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="popupRegistModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="text-lg-center">
                <div id="logo" className="m-0">
                  <LogoSvg height="60px" />
                </div>
                <div className="logo  mt-2">
                  <h5 className="m-0 mb-1 text-secondary font-weight-bold">
                    회원 유형을 선택하세요.
                  </h5>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="m-3">
                  <a
                    className="btn_1"
                    data-toggle="modal"
                    data-target="#personalRegistModal"
                    href="#"
                    onClick={() => {
                      handleButtonClick();
                    }}
                    // data-dismiss="modal"
                    style={{
                      width: 120,
                      height: 90,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <i
                        className="fas fa-user"
                        style={{ fontSize: "x-large" }}
                      />
                      <p style={{ fontSize: "medium" }}>개인회원</p>
                    </div>
                  </a>
                </div>
                <div className="m-3">
                  <a
                    className="btn_2"
                    data-toggle="modal"
                    data-target="#registModal"
                    href="#"
                    onClick={() => {
                      handleButtonClick();
                    }}
                    // data-dismiss="modal"
                    style={{
                      width: 120,
                      height: 90,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <i
                        className="fas fa-building"
                        style={{ fontSize: "x-large" }}
                      />
                      <p style={{ fontSize: "medium" }}>기업회원</p>
                    </div>
                  </a>
                </div>
                <h6 className="m-0 mb-1 text-secondary font-weight-bold">
                  ※ 개인회원은 일부 사용이 제한될 수 있습니다.
                </h6>
              </div>
              {/*<div*/}
              {/*  className="row"*/}
              {/*  style={{ display: "flex", justifyContent: "center" }}*/}
              {/*>*/}
              {/*  */}
              {/*  <div className="m-3">*/}
              {/*    <a*/}
              {/*      className="btn_2"*/}
              {/*      data-toggle="modal"*/}
              {/*      data-target="#changePWModal"*/}
              {/*      href="#"*/}
              {/*      onClick={() => {*/}
              {/*        handleButtonClick();*/}
              {/*      }}*/}
              {/*      // data-dismiss="modal"*/}
              {/*      style={{*/}
              {/*        width: 120,*/}
              {/*        height: 90,*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "center",*/}
              {/*        alignItems: "center",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <div style={{ textAlign: "center" }}>*/}
              {/*        <p style={{ fontSize: "medium" }}>비번변경test</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*    */}
              {/*  */}
              {/*  <div className="m-3">*/}
              {/*    <a*/}
              {/*      className="btn_2"*/}
              {/*      data-toggle="modal"*/}
              {/*      data-target="#findPWModal"*/}
              {/*      href="#"*/}
              {/*      onClick={() => {*/}
              {/*        handleButtonClick();*/}
              {/*      }}*/}
              {/*      // data-dismiss="modal"*/}
              {/*      style={{*/}
              {/*        width: 120,*/}
              {/*        height: 90,*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "center",*/}
              {/*        alignItems: "center",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <div style={{ textAlign: "center" }}>*/}
              {/*        <p style={{ fontSize: "medium" }}>비번찾기test</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*    */}
              {/*  <div className="m-3">*/}
              {/*    <a*/}
              {/*      className="btn_2"*/}
              {/*      data-toggle="modal"*/}
              {/*      data-target="#findIDModal"*/}
              {/*      href="#"*/}
              {/*      onClick={() => {*/}
              {/*        handleButtonClick();*/}
              {/*      }}*/}
              {/*      // data-dismiss="modal"*/}
              {/*      style={{*/}
              {/*        width: 120,*/}
              {/*        height: 90,*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "center",*/}
              {/*        alignItems: "center",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <div style={{ textAlign: "center" }}>*/}
              {/*        <p style={{ fontSize: "medium" }}>아이디찾기test</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*    */}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
