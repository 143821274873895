/* eslint-disable no-unused-vars */
import React from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
let store_default_image =
  "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/cutomsStore/20220314164100_premiumiconwarehouse5537005.png";

export default function StoreSummaryData(props) {
  let { selectedStore, STORE_LIST_VIEW, createLeftDetailImage, storeURLCopy } =
    props;

  let rating = Math.floor(selectedStore.srRating);

  return (
    <>
      {" "}
      <div className="px-3 text-secondary d-flex flex-column justify-content-between h-100">
        <div>
          <h6
            className="cursor_pointer font-weight-bold"
            onClick={() => {
              STORE_LIST_VIEW();
            }}
          >
            <i className="fa-solid fa-arrow-left-long" /> 뒤로가기
          </h6>

          <div className="rounded border mb-2 d-flex flex-row justify-content-center bg-light">
            {selectedStore.sdPhoto ? (
              createLeftDetailImage(selectedStore.sdPhoto)
            ) : (
              <div
                className="w-100"
                style={{
                  height: 200,
                  background: `url(${store_default_image}) 50% 50%/cover no-repeat`,
                }}
              />
            )}
          </div>
          <h5 className="font-weight-bold text-dark d-flex flex-row justify-content-between m-0 p-0">
            <span className="flex-fill overflow-hidden text-truncate text-dark">
              {selectedStore.smName}
            </span>
            <i
              className="fa-solid fa-share-from-square cursor_pointer text-secondary ml-3"
              onClick={() => {
                storeURLCopy();
              }}
            />
          </h5>
          {!rating ? (
            <p className="font-weight-bold mb-2">평가정보 없음</p>
          ) : (
            <div className="font-weight-bold mb-2">
              {rating >= 1 ? (
                <i className="icon-star text-warning" />
              ) : (
                <i className="icon-star-empty text-warning" />
              )}
              {rating >= 2 ? (
                <i className="icon-star text-warning" />
              ) : (
                <i className="icon-star-empty text-warning" />
              )}
              {rating >= 3 ? (
                <i className="icon-star text-warning" />
              ) : (
                <i className="icon-star-empty text-warning" />
              )}
              {rating >= 4 ? (
                <i className="icon-star text-warning" />
              ) : (
                <i className="icon-star-empty text-warning" />
              )}
              {rating >= 5 ? (
                <i className="icon-star text-warning" />
              ) : (
                <i className="icon-star-empty text-warning" />
              )}{" "}
              | {rating * 2}점 /({selectedStore.srCount} 건)
            </div>
          )}
          <p className="p-0 m-0">{selectedStore.smCustoms}</p>
          <p className="p-0 m-0">
            {util.telFormatConvert(selectedStore.smTel)}
          </p>
          <p className="p-0 m-0">{selectedStore.smAddrFull}</p>
          <div className="border border-bottom-0 my-3" />
        </div>
      </div>
    </>
  );
}
