import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import CorpInfoReferenceDetail from "./CorpInfoReferenceDetail";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";
import Loading from "../../lib/Loading";
import CorpInfoReferenceHistory from "./corpInfoReferenceHistory/CorpInfoReferenceHistory";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CorpInfoReference";

let isDevSer = window.origin === "https://www.gbts.co.kr:4100";

let testComp = [
  "1048656606",
  "1058144068",
  "1058166266",
  "1058166266",
  "1058166266",
  "1058173054",
  "1058175130",
  "1058194928",
  "1058194928",
  "1058197148",
  "1058616174",
  "1058618231",
  "1058618231",
  "1058621551",
  "1058621551",
  "1058638431",
  "1058640558",
  "1058665883",
  "1058687460",
  "1058702551",
  "1058715186",
  "1058723084",
  "1058744250",
  "1058750131",
  "1058754103",
  "1058760319",
  "1058763413",
  "1058770670",
  "1058776413",
  "1058776413",
  "1058777955",
  "1058781968",
  "1058781968",
  "1058782609",
  "1058784683",
  "1058796419",
  "1058796419",
  "1058807572",
  "1058815091",
  "1060635018",
  "1068190565",
  "1068190565",
  "1068190833",
  "1068195027",
  "1068195027",
  "1068199566",
  "1068199964",
  "1068658600",
  "1068666677",
  "1068672164",
  "1068683905",
  "1068684199",
  "1068684199",
  "1068706579",
  "1068707511",
  "1068707511",
  "1068710729",
  "1068711145",
  "1068801251",
  "1068801251",
  "1078131588",
  "1078139936",
  "1078142450",
  "1078146795",
  "1078150625",
  "1078159849",
  "1078159849",
  "1078159849",
  "1078166637",
  "1078166898",
  "1078170392",
  "1078170392",
  "1078171936",
  "1078172629",
  "1078172629",
  "1078176528",
  "1078176528",
  "1078177395",
  "1078601376",
  "1078601376",
  "1078604086",
  "1078604086",
  "1078613663",
  "1078613663",
  "1078640966",
  "1078640966",
  "1078643925",
  "1078643925",
  "1078654266",
  "1078665496",
  "1078674016",
  "1078674151",
  "1078677876",
  "1078681841",
  "1078681841",
  "1078703032",
  "1078738278",
  "1078766487",
  "1078808882",
  "1078810578",
  "1078828461",
  "1081998760",
  "1088116993",
  "1088120594",
  "1088121967",
  "1088163195",
  "1088173150",
  "1088179950",
  "1088600948",
  "1088600948",
  "1088603907",
  "1088701018",
  "1090182913",
  "1092663753",
  "1094462248",
  "1098128100",
  "1098154200",
  "1098161583",
  "1098174467",
  "1098180936",
  "1098193988",
  "1098604160",
  "1098604160",
  "1098605999",
  "1098655301",
  "1098655301",
  "1098800120",
  "1100236599",
  "1100426566",
  "1101832065",
  "1108144662",
  "1108146170",
  "1108146170",
  "1108176150",
  "1108176150",
  "1108189543",
  "1108189543",
  "1108189543",
  "1108196272",
  "1108604705",
  "1108700157",
  "1108800402",
  "1108800402",
  "1118121018",
  "1118121018",
  "1118126181",
  "1122896555",
  "1128133308",
  "1130121157",
  "1131839922",
  "1132378045",
  "1138120048",
  "1138128720",
  "1138128720",
  "1138140063",
  "1138146183",
  "1138146183",
  "1138146183",
  "1138146257",
  "1138146602",
  "1138149332",
  "1138149332",
  "1138152744",
  "1138152744",
  "1138153006",
  "1138154927",
  "1138158151",
  "1138159316",
  "1138159427",
  "1138162790",
  "1138166326",
  "1138166928",
  "1138170835",
  "1138176334",
  "1138179855",
  "1138187184",
  "1138187184",
  "1138190525",
  "1138190525",
  "1138190957",
  "1138190957",
  "1138194900",
  "1138195078",
  "1138195078",
  "1138604999",
  "1138608016",
  "1138608016",
  "1138608530",
  "1138617077",
  "1138617077",
  "1138618829",
  "1138618829",
  "1138622435",
  "1138622435",
  "1138625640",
  "1138636870",
  "1138636870",
  "1138638015",
  "1138642862",
  "1138642862",
  "1138653980",
  "1138654373",
  "1138654373",
  "1138656444",
  "1138656444",
  "1138656444",
  "1138658817",
  "1138658817",
  "1138664298",
  "1138665604",
  "1138679049",
  "1138683878",
  "1138689402",
  "1138689402",
  "1138690888",
  "1138700933",
  "1138700933",
  "1138700933",
  "1142365072",
  "1148155540",
  "1148177791",
  "1148606419",
  "1148608950",
  "1148615164",
  "1148615164",
  "1148620008",
  "1148624834",
  "1148624834",
  "1148637585",
  "1148647012",
  "1148669108",
  "1148674765",
  "1148717437",
  "1148719610",
  "1148719610",
  "1148801816",
  "1158600077",
  "1158600077",
  "1158800687",
  "1168104862",
  "1168133616",
  "1168137418",
  "1168175974",
  "1168801563",
  "1178105428",
  "1178115867",
  "1178115867",
  "1178130460",
  "1178138797",
  "1178138797",
  "1178155476",
  "1178157552",
  "1178163845",
  "1178173678",
  "1180591447",
  "1188109577",
  "1188112596",
  "1188118265",
  "1188122304",
  "1188122304",
  "1188800385",
  "1190880852",
  "1191124433",
  "1191333052",
  "1191528456",
  "1191528456",
  "1192142629",
  "1192142629",
  "1198104411",
  "1198105477",
  "1198108358",
  "1198125774",
  "1198131655",
  "1198132106",
  "1198132746",
  "1198132824",
  "1198140227",
  "1198140227",
  "1198143317",
  "1198169530",
  "1198171036",
  "1198180817",
  "1198180817",
  "1198182998",
  "1198182998",
  "1198190557",
  "1198190799",
  "1198194456",
  "1198197865",
  "1198600477",
  "1198610029",
  "1198610029",
  "1198615363",
  "1198622388",
  "1198624275",
  "1198627011",
  "1198636989",
  "1198638328",
  "1198641482",
  "1198641482",
  "1198643312",
  "1198646546",
  "1198646546",
  "1198658173",
  "1198658659",
  "1198658659",
  "1198664898",
  "1198667987",
  "1198671477",
  "1198671477",
  "1198681617",
  "1198708651",
  "1200389130",
  "1200389130",
  "1208155486",
  "1208167380",
  "1208174071",
  "1208192856",
  "1208602209",
  "1208603324",
  "1208605507",
  "1208605507",
  "1208614855",
  "1208623354",
  "1208633357",
  "1208650680",
  "1208650680",
  "1208678640",
  "1208678640",
  "1208686698",
  "1208686698",
  "1208692448",
  "1208714959",
  "1208714959",
  "1208719916",
  "1208727788",
  "1208727788",
  "1208727788",
  "1208750654",
  "1208759505",
  "1208759505",
  "1208776576",
  "1208776576",
  "1208783039",
  "1208783039",
  "1208793560",
  "1208804273",
  "1208804273",
  "1210530198",
  "1218126494",
  "1218130760",
  "1218130760",
  "1218130760",
  "1218133132",
  "1218141044",
  "1218141253",
  "1218141253",
  "1218141253",
  "1218156663",
  "1218171252",
  "1218184612",
  "1218184612",
  "1218187939",
  "1218190716",
  "1218190716",
  "1218192728",
  "1218601275",
  "1218613562",
  "1218617326",
  "1218617326",
  "1218619722",
  "1218630498",
  "1218630498",
  "1218639276",
  "1218800440",
  "1228119787",
  "1228122619",
  "1228122619",
  "1228127915",
  "1228151905",
  "1228176212",
  "1228182903",
  "1228182903",
  "1228187742",
  "1228187742",
  "1228194127",
  "1228195112",
  "1228195112",
  "1228196538",
  "1228196668",
  "1228196668",
  "1228196668",
  "1228199253",
  "1228199253",
  "1228199253",
  "1228605459",
  "1228605459",
  "1228609891",
  "1228619031",
  "1228619031",
  "1228619123",
  "1228623554",
  "1228626702",
  "1228629427",
  "1228629427",
  "1228629427",
  "1228633713",
  "1228633843",
  "1228801044",
  "1231175697",
  "1231253045",
  "1231460948",
  "1231476945",
  "1231476945",
  "1233065171",
  "1233065171",
  "1233065171",
  "1233188512",
  "1233593217",
  "1233962255",
  "1238131052",
  "1238131052",
  "1238137207",
  "1238137885",
  "1238143107",
  "1238143107",
  "1238144595",
  "1238148591",
  "1238148591",
  "1238151359",
  "1238151359",
  "1238155563",
  "1238161916",
  "1238165440",
  "1238165440",
  "1238169107",
  "1238169179",
  "1238170002",
  "1238171694",
  "1238175690",
  "1238176762",
  "1238176762",
  "1238179809",
  "1238180650",
  "1238180650",
  "1238189189",
  "1238195717",
  "1238195717",
  "1238199936",
  "1238601378",
  "1238601835",
  "1238613535",
  "1238614780",
  "1238623673",
  "1238623692",
  "1238624124",
  "1238624560",
  "1238628265",
  "1238629018",
  "1238644469",
  "1238648465",
  "1240545064",
  "1240545064",
  "1241047654",
  "1242799320",
  "1244433306",
  "1248105294",
  "1248128884",
  "1248158831",
  "1248162747",
  "1248162747",
  "1248166364",
  "1248166398",
  "1248166398",
  "1248166398",
  "1248168038",
  "1248168571",
  "1248171436",
  "1248171436",
  "1248173512",
  "1248175413",
  "1248177065",
  "1248178481",
  "1248179097",
  "1248180600",
  "1248188728",
  "1248192047",
  "1248192047",
  "1248194536",
  "1248194894",
  "1248197625",
  "1248602929",
  "1248603175",
  "1248603175",
  "1248605246",
  "1248605246",
  "1248607448",
  "1248607448",
  "1248625048",
  "1248625048",
  "1248627987",
  "1248628031",
  "1248628031",
  "1248634488",
  "1248637020",
  "1248639445",
  "1248640490",
  "1248640490",
  "1248650396",
  "1248663061",
  "1248663606",
  "1248663606",
  "1248668106",
  "1248668106",
  "1248670150",
  "1248670150",
  "1248670733",
  "1248670733",
  "1248673540",
  "1248675683",
  "1248675932",
  "1248675999",
  "1248675999",
  "1248676213",
  "1248679243",
  "1248681147",
  "1248692246",
  "1248694297",
  "1248694429",
  "1248705281",
  "1248707412",
  "1248707412",
  "1248707881",
  "1248708177",
  "1248708406",
  "1248711901",
  "1248715076",
  "1248715076",
  "1248715625",
  "1248715625",
  "1248720754",
  "1248723751",
  "1248724430",
  "1248726587",
  "1248728076",
  "1248728469",
  "1248730873",
  "1248744019",
  "1248745319",
  "1248751655",
  "1248753051",
  "1248753051",
  "1248757979",
  "1251435111",
  "1258150278",
  "1258157795",
  "1258157795",
  "1258162003",
  "1258168007",
  "1258175702",
  "1258190116",
  "1258190116",
  "1258196503",
  "1258607738",
  "1258613720",
  "1258620070",
  "1258623873",
  "1258624417",
  "1258625186",
  "1258626259",
  "1258627262",
  "1258627262",
  "1258801437",
  "1261566235",
  "1268103332",
  "1268103332",
  "1268108962",
  "1268108962",
  "1268124171",
  "1268134155",
  "1268134155",
  "1268135848",
  "1268139621",
  "1268143090",
  "1268147612",
  "1268150325",
  "1268150325",
  "1268164343",
  "1268171878",
  "1268172805",
  "1268174083",
  "1268175893",
  "1268179147",
  "1268181342",
  "1268182642",
  "1268183938",
  "1268191869",
  "1268191869",
  "1268193979",
  "1268194866",
  "1268608633",
  "1268609888",
  "1268609888",
  "1268622504",
  "1268626761",
  "1268626999",
  "1268628361",
  "1268628361",
  "1268629870",
  "1268629870",
  "1268636347",
  "1268641568",
  "1268646108",
  "1268646447",
  "1268650354",
  "1268650543",
  "1268654253",
  "1268654253",
  "1268659369",
  "1268659369",
  "1268662108",
  "1268670592",
  "1268673769",
  "1268673978",
  "1268681051",
  "1268800595",
  "1270478679",
  "1272118120",
  "1272118120",
  "1272495583",
  "1272495583",
  "1278111437",
  "1278112076",
  "1278112076",
  "1278113814",
  "1278113814",
  "1278116183",
  "1278117936",
  "1278117936",
  "1278122308",
  "1278128957",
  "1278132301",
  "1278132301",
  "1278135614",
  "1278147712",
  "1278147712",
  "1278167469",
  "1278169337",
  "1278170834",
  "1278171283",
  "1278173087",
  "1278178549",
  "1278197075",
  "1278601399",
  "1278605148",
  "1278612746",
  "1278616233",
  "1278618740",
  "1278618740",
  "1278633417",
  "1278633417",
  "1278636826",
  "1278636826",
  "1278638955",
  "1278642240",
  "1278654824",
  "1278654824",
  "1278655712",
  "1278656287",
  "1278659358",
  "1278800389",
  "1278800389",
  "1281285877",
  "1281778823",
  "1288110616",
  "1288123334",
  "1288123334",
  "1288134113",
  "1288137008",
  "1288137008",
  "1288139044",
  "1288139495",
  "1288140333",
  "1288141384",
  "1288141410",
  "1288145186",
  "1288145186",
  "1288147409",
  "1288147409",
  "1288150210",
  "1288158172",
  "1288158172",
  "1288158342",
  "1288161041",
  "1288177295",
  "1288177766",
  "1288177811",
  "1288178858",
  "1288190245",
  "1288602858",
  "1288605796",
  "1288608129",
  "1288608129",
  "1288608508",
  "1288617609",
  "1288617609",
  "1288617609",
  "1288625642",
  "1288628751",
  "1288628751",
  "1288642281",
  "1288642281",
  "1288650253",
  "1288660479",
  "1288661600",
  "1288671791",
  "1288672811",
  "1288688045",
  "1288694867",
  "1288701934",
  "1288707791",
  "1288715331",
  "1288715331",
  "1288715331",
  "1293804727",
  "1298104780",
  "1298112048",
  "1298114838",
  "1298114838",
  "1298116141",
  "1298130309",
  "1298139561",
  "1298145078",
  "1298154669",
  "1298154669",
  "1298159529",
  "1298162209",
  "1298162209",
  "1298164930",
  "1298177677",
  "1298180997",
  "1298180997",
  "1298181851",
  "1298193847",
  "1298193847",
  "1298601740",
  "1298605182",
  "1298605256",
  "1298607948",
  "1298608739",
  "1298611006",
  "1298611006",
  "1298611006",
  "1298617617",
  "1298619895",
  "1298622352",
  "1298622352",
  "1298625039",
  "1298633989",
  "1298636481",
  "1298636481",
  "1298636481",
  "1298637836",
  "1298641820",
  "1298641820",
  "1298641820",
  "1298656978",
  "1298657884",
  "1298664046",
  "1298664920",
  "1298665593",
  "1298667266",
  "1298670645",
  "1298671964",
  "1298671964",
  "1298671964",
  "1298673865",
  "1298676052",
  "1298676052",
  "1298676052",
  "1298676576",
  "1298676708",
  "1298681104",
  "1298681104",
  "1298682710",
  "1298682710",
  "1298683650",
  "1298684358",
  "1298689197",
  "1298689329",
  "1298691728",
  "1298692028",
  "1298801410",
  "1298801410",
  "1300147341",
  "1300156985",
  "1300156985",
  "1300453086",
  "1300453086",
  "1303479292",
  "1303479292",
  "1303965376",
  "1303965376",
  "1308104927",
  "1308133932",
  "1308133932",
  "1308139300",
  "1308139300",
  "1308142751",
  "1308142921",
  "1308144822",
  "1308145383",
  "1308145457",
  "1308147402",
  "1308147402",
  "1308147402",
  "1308151226",
  "1308152337",
];
let historyKey = "";

let isRequest = false;
let isCheckCoPoint = false;
let coPoint = 0;

export default function CorpInfoReference(props) {
  const { loginInfo } = props;
  const [compInfo, setCompInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExistData, setIsExistData] = useState(false);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const $inputRef = useRef();

  useEffect(() => {
    requestBasaCoPointSelect((newData) => {
      let data = newData["returnData"][0];
      if (data) {
        isCheckCoPoint = true;
        coPoint = Number(data["bcpPOINT"]);

        let search_word = socket.getLocalDataSet(
          MsgIDList.EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT
        );
        if (search_word) {
          handleSearchValue(search_word);
        }

        socket.addLocalEventListener(
          MsgIDList.EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT,
          _mClassName,
          (b, n) => {
            if (!n) return;
            handleSearchValue(n);
          }
        );
      }
    });

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT,
        _mClassName
      );
    };
  }, []);

  const requestBasaCoPointSelect = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/basa/JS_basaCoPointSelect.php",
      msgID: msgID,
      data: [],
      bcpCOMP_CD: loginInfo.comp_cd,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 기업조회 데이터 가공
  const handleCompData = (dataList, callback) => {
    if (isDevSer) {
      let testIndex = dataList.findIndex((item) => item.req_cd === "3000407");
      if (testIndex > -1 && dataList[testIndex].Lst.length === 0) {
        //개발서버이고 수신데이터가 없는 경우에만 데이터 임의등록
        dataList[testIndex].Lst = [
          { item_val: "45.00", item_nm: "결제기간 일수" },
        ];
      }
    }

    let _compInfo = {};
    for (let item of dataList) {
      _compInfo[item.req_cd] = item["Lst"] ? item["Lst"] : [];
    }

    setIsExistData(true);
    setCompInfo(_compInfo);
    callback && callback();
  };
  const requestBasaHistory = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/basa/JS_basa_result_history_select.php",
      msgID: msgID,
      data: [],
      take: 1,
      skip: 0,
      brhCOMP_CD: loginInfo.comp_cd,
      brhUSER_ID: loginInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // BASA 기업정보 조회 데이터 요청
  const requestBASAData = (callback) => {
    if (isRequest) {
      alert("처리중입니다.");
      return;
    }

    isRequest = true;

    if (!$inputRef.current) {
      codeList.Modal.current.alert("오류가 발생하였습니다. 다시 시도해주세요.");
      return;
    }

    if (coPoint === 0) {
      codeList.Modal.current.alert("조회포인트를 모두 소진하였습니다.");

      return;
    }

    let comp_num = $inputRef.current.value.replaceAll("-", "");
    comp_num = comp_num.trim();

    if (comp_num === "") {
      codeList.Modal.current.alert("사업자등록번호를 입력해주세요.");
      return;
    }

    if (!util.validationBizID(comp_num)) {
      codeList.Modal.current.alert("유효하지 않은 사업자등록번호입니다.");
      return;
    }

    let data = {
      bzno: comp_num,
      COMP_CD: loginInfo.comp_cd,
      USER_ID: loginInfo.user_id,
    };

    historyKey = "";
    setIsExistData(false);
    setIsLoading(true);
    //axios.defaults.withCredentials = false;

    axios
      .post("https://talktalk.gbts.co.kr:6300/getBASAData", data)
      .then((response) => {
        if (Number(response.data.no1_ccnt) <= 1) {
          setCompInfo(null);
          codeList.Modal.current.alert(
            "해당 정보는 신용보증기금에서 제공하지 않는 정보입니다" + ""
          );
          if (callback) callback();
          return;
        }

        requestBasaHistory((newData) => {
          historyKey = newData["returnData"][0].brhIDX;
          // handleCompData(response.data.rpnsRsltLst);
          requestBasaCoPointSelect((newData) => {
            let data = newData["returnData"][0];
            if (data) {
              coPoint = Number(data["bcpPOINT"]);
            }
            handleCompData(response.data.rpnsRsltLst);
          });
        });
        if (callback) callback();
      })
      .catch((error) => {
        setCompInfo(null);
        setIsExistData(false);
        setIsLoading(false);
        if (callback) callback();
        codeList.Modal.current.alert(
          "통신장애로 인해 오류가 발생했습니다. 다시 시도해주세요."
        );
      })
      .finally((data) => {
        isRequest = false;
        setIsLoading(false);
      });
  };

  // 조회
  const handleSearchValue = (text) => {
    if ($inputRef.current) {
      $inputRef.current.value = text;
    }
    setIsExistData(false);
    setIsLoading(true);
    requestBASAData();
  };

  const selectBASADataHistory = (data) => {
    setIsExistData(false);
    setIsLoading(true);

    if (Number(data.no1_ccnt) <= 1) {
      setCompInfo(null);
      setIsLoading(false);
      alert("해당 기업의 데이터가 존재하지 않습니다.");
      return;
    }

    historyKey = data.brhIDX.replaceAll("/", "¿");
    handleCompData(data.rpnsRsltLst, () => {
      setIsLoading(false);
      setIsShowHistory(false);
    });
  };

  const testBasa = (idx) => {
    console.log(idx, $inputRef);
    $inputRef.current.value = testComp[idx];
    requestBASAData(() => {
      if (testComp.length > idx + 2) {
        testBasa(++idx);
      }
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <main
        className="p-3 w-100 h-100 bg-white overflow-auto"
        style={{
          maxHeight: "100vh",
        }}
      >
        <div className="h-100 d-flex flex-column">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div>
              <h4 className="text-info mb-1 pt-2 font-weight-bold">
                기업 신용정보 조회{isShowHistory && "이력"}
              </h4>
              {/*<button
                onClick={() => {
                  testBasa(0);
                }}
              >
                테스트
              </button>*/}
            </div>
            <div>
              <div className="d-flex flex-row mb-2">
                <div
                  className="px-2 py-1 rounded-pill border mr-2 bg-light font-weight-bold text-center"
                  style={{ width: 180 }}
                >
                  <i className="fa-solid fa-coins text-warning" /> 포인트 :{" "}
                  {util.addCommas(coPoint)}
                </div>
                <button
                  className="btn_1 px-2 py-1 rounded-pill"
                  style={{ width: 120 }}
                  onClick={() => setIsShowHistory((prevState) => !prevState)}
                >
                  {isShowHistory ? "기업정보 조회" : "MY 조회이력"}
                </button>
              </div>
            </div>
          </div>
          <p className="h6 mb-0">
            {isShowHistory
              ? "이전에 조회한 기업정보를 재열람합니다."
              : "신용보증기금이 보유한 국내최대, 고품질 기업 데이터를 조회할 수 있습니다."}
          </p>
          <p className="text-danger mb-3">
            (본 서비스는 파트너사의 경우 소정의 횟수 무료이용이 가능하며,
            일반회원의 경우 이용권을 구매하여 이용 가능합니다.)
          </p>
          <div className={isShowHistory ? "d-none" : ""}>
            <div className="row m-0 p-0">
              <div className="col-12 col-md-6 m-0 mb-3 p-0">
                <div className="input-group">
                  <input
                    className="form-control border border-freiscop rounded-0"
                    style={{ borderWidth: "3px !important" }}
                    placeholder="조회하고자하는 기업의 사업자등록번호를 입력해주세요"
                    ref={$inputRef}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        requestBASAData();
                      }
                    }}
                  />
                  <button
                    className="btn btn-sm rounded-0 text-white bg-freiscop border-freiscop"
                    onClick={() => {
                      requestBASAData();
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 pb-3">
              <CorpInfoReferenceDetail
                historyKey={historyKey}
                compInfo={compInfo}
                isExistData={isExistData}
              />
            </div>
          </div>
          <div className={isShowHistory ? "" : "d-none"}>
            <CorpInfoReferenceHistory
              loginInfo={loginInfo}
              selectBASADataHistory={selectBASADataHistory}
            />
          </div>
        </div>
      </main>
    </>
  );
}
