import React, { useEffect, useState } from "react";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
const socket = new BuddibleSocket();
export default function FCLImages() {
  const [productData, setProductData] = useState({
    pmIDX: 123,
    pmKeyMain: "해상(수출)",
    pmProductName: "인천-하이퐁 SM상선 FCL 직항",
    pmProductExpireStart: "2021-11-01",
    pmProductExpireEnd: "2021-11-12",
    pmProductExpireType: "출항일기준",
    pmDepartureType: "직항",
    pmDepartureDate: "5",
    pmDepartureDayWeek: "화|목",
    pmTransferDirectYN: "Y",
    pmTransferDay: "",
    pmShipCompanyImg: "ship-gfa701dc0d_640.jpg",
    pmShipCompanyName: "SM상선",
    pmShipCompanyDesc: "SM상선입니다.",
    pmShipCompanyHome: "https://www.smlines.com/kr/",
    pmShipInfo: "",
    pmForwarderImg: "ds.png",
    pmForwarderName: "동서로지스틱스",
    pmForwarderDesc: "동서로지스틱스입니다.",
    pmForwarderHome: "http://www.dongsuero.com/",
    pmTransferType: "",
    pmOptionInfo:
      "- 수출통관수수료\n" +
      "- 국내컨테이너운송료\n" +
      "\n" +
      "등 비용은 업무 진행시 제휴사(관세사)청구금액 및 운송구간에 따른 운임에 따릅니다. 해당 비용에 대하여 궁금하시면 문의 남겨 주세요.",
    pmOptionInclude: "- 운임, 부대비용을 포함한 상기 고지된 물류비용",
    pmOptionNotInclude:
      "- 통관수수료, 적하보험료, 내륙운송료, DOOR작업건의 경우 상차비용 등은 불포함 되어 있습니다",
    pmWarningInfo: "1. GBTS로지스몰에서 예약을 하시면 담당…..",
    pmCreator: "2021-11-01",
    pmUSEYN: "Y",
    subData: [],
    pmDepartureCity: "인천",
    pmArrivalCity: "하이퐁",
    pmProductType: "FCL",
  });

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "FCLImages",
      (beforeData, newData) => {
        if (newData) {
          setProductData({ ...newData });
        }
      }
    );
  }, []);
  return (
    <div className="container pb-3 pt-3" id="fclImages">
      <div className="row">
        {productData.pmShipCompanyImg !== "" && (
          <div
            className={`col-12 col-md-${
              productData.pmForwarderImg !== "" ? "6" : "12"
            } p-sm-0`}
          >
            <div className="card" style={{ height: "300px" }}>
              <div
                className="card-img bg-white h-100"
                style={{
                  background: `url(${productData.pmShipCompanyImg})50% 50% / cover no-repeat`,
                }}
              />
              <div className="card-img-overlay">
                <div className="parallax-content-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 text-right">
                        <span>{productData.pmShipCompanyName} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {productData.pmForwarderImg !== "" && (
          <div
            className={`col-12 col-md-${
              productData.pmShipCompanyImg !== "" ? "6" : "12"
            } p-sm-0`}
          >
            <div className="card" style={{ height: "300px" }}>
              <div
                className="card-img bg-white h-100"
                style={{
                  background: `url(${productData.pmForwarderImg})50% 50% / cover no-repeat`,
                }}
              />
              <div className="card-img-overlay card-footer d-flex flex-column justify-content-end">
                <div className="parallax-content-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 text-right">
                        <span>{productData.pmForwarderName} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
