/* eslint-disable no-unused-vars */
// IE9의 경우
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// IE11의 경우
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import "./config/i18n";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import BuddibleSocket, { MsgIDList } from "./lib/BuddibleSocket";
import Websocket from "react-websocket";

import AutoLogin from "./lib/AutoLogin";
import CodeList from "./lib/CodeList";
import CommonUI from "./lib/CommonUI";
import App from "./App";

let socket = new BuddibleSocket();
let codeList = new CodeList();
// console.log("index.js");
const href = window.location.href;

/*
href.startsWith("https://www.gbts.co.kr:4100")
          ? "wss://talk.gbts.co.kr:3600/"
          : "wss://talk.gbts.co.kr:3100/"
 */
/*
url={
        href.startsWith("https://www.gbts.co.kr:4100") ||
        href.startsWith("https://gbts.co.kr:4100")
          ? "wss://talk.gbts.co.kr:3100/"
          : "wss://talk.gbts.co.kr:3100/"
      }
 */
ReactDOM.render(
  <>
    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
    {
      /*        <iframe
            width="0"
            height="0"
            src="https://msgserver.buddible.com:3100"
            style={{ display: "none" }}
        />
        <Websocket
            url="wss://msgserver.buddible.com:3100/"
            onMessage={socket.handleData}
            reconnect={true}
            onOpen={socket.handleOpen}
            onClose={socket.handleClose}
            ref={(Websocket) => {
                socket.changeServer(Websocket);
            }}
        />

        <AutoLogin />*/
      //? "wss://talk.gbts.co.kr:3600/"
    }

    <Websocket
      url={
        href.startsWith("https://www.gbts.co.kr:4100") ||
        href.startsWith("https://gbts.co.kr:4100")
          ? "wss://talk.gbts.co.kr:3600/"
          : "wss://talk.gbts.co.kr:3100/"
      }
      onMessage={socket.handleData}
      reconnect={true}
      onOpen={socket.handleOpen}
      onClose={socket.handleClose}
      ref={(Websocket) => {
        // console.log("Websocket ref", Websocket);
        socket.changeServer(Websocket);
      }}
    />
    <CommonUI
      ref={(e) => {
        codeList.setModal(e);
      }}
    />
    <App />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
