/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef, useRef } from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const uiData = {
  mainTitle: "지역/국가/도시 관리",
  type: "지역/국가/도시",
};

const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const FILE_TYPE = {
  CONTINENT: "/code/JS_code_mng_continent_select.php",
  COUNTRY: "/code/JS_code_mng_country_select.php",
  CITY: "/code/JS_code_mng_city_select.php",
};

const _mClassName = "CityManageEdit";
function CityManageEdit(props, ref) {
  const [editType, setEditType] = useState("insert");
  const [userData, setUserData] = useState({});
  const [selectType, setSelectType] = useState("");
  const [continentList, setContinentList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortModel, setSortModel] = useState([
    {
      field: "cIDX",
      sort: "asc",
    },
  ]);

  const $controllers = {
    continentSelect: useRef(),
    countrySelect: useRef(),
    parentCBIGCD: useRef(),
    cBIGCD: useRef(),
    cSMALLCD: useRef(),
    cTYPE: useRef(),
    cNAME: useRef(),
    cUSEYN: useRef(),
    cCustomCode: useRef(),
  };

  const serverFile = {
    update: "/code/JS_code_mng_update.php",
    insert: "/code/JS_code_mng_insert.php",
  };

  useEffect(() => {
    if (userData.hasOwnProperty("cBIGCD")) {
      $controllers.cBIGCD.current.value = userData.cBIGCD;
      $controllers.cBIGCD.current.disabled = true;
      $controllers.cSMALLCD.current.value = userData.cSMALLCD;
      $controllers.cSMALLCD.current.disabled = true;
      $controllers.cNAME.current.value = userData.cNAME;
      if ($controllers.cCustomCode.current)
        $controllers.cCustomCode.current.value = userData.cCustomCode;
      $controllers.cUSEYN.current.value = userData.cUSEYN;
      $controllers.cTYPE.current.disabled = true;
      if (userData.cSMALLCD === "*") {
        if (userData.cBIGCD.length === 3) {
          $controllers.cTYPE.current.value = "CONTINENT";
          setSelectType("CONTINENT");
        } else if (userData.cBIGCD.length === 4) {
          $controllers.cTYPE.current.value = "COUNTRY";
          setSelectType("COUNTRY");
        }
      } else {
        $controllers.cTYPE.current.value = "CITY";
        setSelectType("CITY");
      }
      setEditType("update");
    } else {
      $controllers.cBIGCD.current.value = "";
      $controllers.cBIGCD.current.disabled = true;
      $controllers.cSMALLCD.current.value = "";
      $controllers.cSMALLCD.current.disabled = true;
      $controllers.cTYPE.current.value = "";
      $controllers.cTYPE.current.disabled = false;
      $controllers.cNAME.current.value = "";
      if ($controllers.cCustomCode.current)
        $controllers.cCustomCode.current.value = "";
      $controllers.cUSEYN.current.value = "";

      setEditType("insert");
    }
  }, [userData]);

  useEffect(() => {
    let cityData = socket.getLocalDataSet(
      MsgIDList.EVENT_CITY_MANAGE_EDIT_MODAL_CALL
    );
    if (cityData) {
      setUserData({ ...cityData });
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_CITY_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_CITY_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  const selectedType = (e) => {
    let type = $controllers.cTYPE.current.value;
    setSelectType(type);
    if (type === "CONTINENT") {
      requestUserSelect1(FILE_TYPE[type], type);
    }
    if (type === "COUNTRY") {
      requestUserSelect1(FILE_TYPE["CONTINENT"], type);
    }
    if (type === "CITY") {
      requestUserSelect1(FILE_TYPE["CONTINENT"], type);
    }
  };

  const selectedContinent = () => {
    let selectContinent = $controllers.continentSelect.current.value;
    if (selectType === "COUNTRY") {
      requestUserSelect2(FILE_TYPE[selectType], selectContinent, selectType);
    }
    if (selectType === "CITY") {
      requestUserSelect2(FILE_TYPE["COUNTRY"], selectContinent, selectType);
    }
  };

  const changeSelectCity = (cBIGCD) => {
    let parentCd = $controllers.countrySelect.current.value;
    requestUserSelect3(FILE_TYPE[selectType], parentCd, selectType);
  };

  const requestUserSelect1 = (fileAddr, type) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: fileAddr,
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = newData["returnData"];
          if (type === "CONTINENT") {
            let endCD = dataList[dataList.length - 1].cBIGCD;
            $controllers.cBIGCD.current.value = createdBIGCD(endCD);
            $controllers.cSMALLCD.current.value = "*";
            setContinentList([...newData["returnData"]]);
          }
          if (type === "COUNTRY") {
            setContinentList([...newData["returnData"]]);
          }
          if (type === "CITY") {
            setContinentList([...newData["returnData"]]);
          }
          setLoading(false);
        }
      }
    });
  };

  const requestUserSelect2 = (fileAddr, parentCD, type) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: fileAddr,
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cBIGCD: parentCD,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            let dataList = newData["returnData"];
            if (type === "COUNTRY") {
              let endCD = dataList[dataList.length - 1].cBIGCD;
              $controllers.cBIGCD.current.value = createdBIGCD(endCD);
              $controllers.cSMALLCD.current.value = "*";
              setCountryList([...newData["returnData"]]);
            }
            if (type === "CITY") {
              $controllers.cBIGCD.current.value = parentCD;
              setCountryList([...newData["returnData"]]);
            }
          } else {
            if (type === "COUNTRY") {
              $controllers.cBIGCD.current.value = `${parentCD}A`;
              $controllers.cSMALLCD.current.value = "*";
              setCountryList([...newData["returnData"]]);
            }
            if (type === "CITY") {
              $controllers.cBIGCD.current.value = parentCD;
              setCountryList([...newData["returnData"]]);
            }
          }
          setLoading(false);
        }
      }
    });
  };

  const requestUserSelect3 = (fileAddr, parentCD, type) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: fileAddr,
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cBIGCD: parentCD,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            let dataList = newData["returnData"];
            if (type === "CITY") {
              setCityList([...newData["returnData"]]);
              let endCD = dataList[dataList.length - 1].cSMALLCD;
              $controllers.cBIGCD.current.value = parentCD;
              $controllers.cSMALLCD.current.value = createdSMALLCD(endCD);
            }
          } else {
            $controllers.cBIGCD.current.value = parentCD;
            $controllers.cSMALLCD.current.value = `${parentCD}01`;
          }

          setLoading(false);
        }
      }
    });
  };

  const createdBIGCD = (str) => {
    let endIndex = str.length - 1;
    let newCD = str.charCodeAt(endIndex);
    newCD = newCD + 1;
    newCD = str.slice(0, endIndex) + String.fromCharCode(newCD);
    return newCD;
  };

  const createdSMALLCD = (str) => {
    let cd = str.slice(0, 4);
    let num = str.slice(4, 6);
    num = num.split("");
    let newNum = [];
    num.forEach((n, index) => {
      if (index === num.length - 1) {
        n = Number(n);
        n = n + 1;
        if (n < 10) {
          let pn = Number(num[0]);
          newNum.push(pn, n);
        } else {
          let pn = Number(num[0]) + 1;
          newNum.push(pn, 0);
        }
      }
    });
    newNum = newNum.join("");
    let newCD = cd + newNum;
    return newCD;
  };

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        cBIGCD: $controllers.cBIGCD.current.value,
        cSMALLCD: $controllers.cSMALLCD.current.value,
        cNAME: $controllers.cNAME.current.value,
        cUSEYN: $controllers.cUSEYN.current.value,
        cCustomCode: $controllers.cCustomCode.current.value,
      };
      requestUserUpdate(
        updateData,
        editType !== "insert" ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controllers.cBIGCD.current.value.trim() === "") {
      codeList.Modal.current.alert("대륙 또는 국가를 선택해 주세요", () => {});
      return false;
    }
    if ($controllers.cSMALLCD.current.value.trim() === "") {
      codeList.Modal.current.alert("대륙 또는 국가를 선택해 주세요", () => {});
      return false;
    }
    if ($controllers.cTYPE.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해 주세요", () => {});
      return false;
    }
    if ($controllers.cNAME.current.value.trim() === "") {
      codeList.Modal.current.alert("명칭을 입력해주세요", () => {});
      return false;
    }

    if ($controllers.cUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    let dataList = [];
    if (selectType === "CONTINENT") {
      dataList = [...continentList];
    }
    if (selectType === "COUNTRY") {
      dataList = [...countryList];
    }
    if (selectType === "CITY") {
      dataList = [...cityList];
    }

    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].cNAME === $controllers.cNAME.current.value) {
        codeList.Modal.current.alert("이미 사용된 명칭입니다.", () => {});
        return false;
      }
    }
    return true;
  };

  const requestUserUpdate = (updateData, TYPE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: TYPE === EDIT_TYPE.UPDATE ? serverFile.update : serverFile.insert,
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_CITY_MANAGE_DATA_UPDATE
              : MsgIDList.EVENT_CITY_MANAGE_DATA_UPDATE,
            _mClassName,
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `${uiData.type}를 업데이트 하였습니다.`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `${uiData.type}를 추가 하였습니다.`,
                () => {}
              );

          requestCountryFullName();
          Cancel();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `${uiData.type} 업데이트 중 오류가 발생하였습니다.`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `${uiData.type} 추가 중 오류가 발생하였습니다.`,
                () => {}
              );
        }
      }
    });
  };

  const requestCountryFullName = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/code/JS_country_full_name_code_select.php",
      msgID: msgID,
      data: {},
      cBIGCD: "CD",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.codeCountryFullName = newData["returnData"];
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
            _mClassName,
            codeList.codeCountryFullName,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  const Cancel = () => {
    $controllers.cBIGCD.current.value = "";
    $controllers.cBIGCD.current.disabled = true;
    $controllers.cSMALLCD.current.value = "";
    $controllers.cSMALLCD.current.disabled = true;
    $controllers.cNAME.current.value = "";
    $controllers.cUSEYN.current.value = "";
    $("#closeCityModalEdit").click();
    setSelectType("");
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticCityEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticCityEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>{uiData.mainTitle}</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeCityModalEdit"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$controllers.cBIGCD}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  고유 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="고유번호"
                    ref={$controllers.cSMALLCD}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controllers.cTYPE}
                    onChange={(e) => {
                      selectedType(e);
                    }}
                  >
                    <option value="">미선택</option>
                    <option value="CONTINENT">대륙</option>
                    <option value="COUNTRY">국가</option>
                    <option value="CITY">도시</option>
                  </select>
                </div>
              </div>

              {(selectType === "COUNTRY" || selectType === "CITY") && (
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    대륙
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controllers.continentSelect}
                      onChange={() => selectedContinent()}
                    >
                      <option value="">미선택</option>
                      {continentList.length > 0
                        ? continentList.map((continent) => {
                            return (
                              <option value={continent.cBIGCD}>
                                {continent.cNAME}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                </div>
              )}

              {selectType === "CITY" && (
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    국가
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controllers.countrySelect}
                      onChange={() => {
                        changeSelectCity();
                      }}
                    >
                      <option value="">
                        {countryList.length > 0
                          ? "미선택"
                          : "대륙을 선택해 주세요"}
                      </option>
                      {countryList.length > 0
                        ? countryList.map((country) => {
                            return (
                              <option value={country.cBIGCD}>
                                {country.cNAME}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                </div>
              )}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">명칭</label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="대륙/국가/도시 명칭을 입력해 주세요"
                    ref={$controllers.cNAME}
                  />
                </div>
              </div>
              <div
                className={`mb-3 row ${selectType === "CITY" ? "" : "d-none"}`}
              >
                <label className="col-form-label col-sm-3 text-end">
                  항구코드
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="항구코드를 입력해주세요"
                    ref={$controllers.cCustomCode}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controllers.cUSEYN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `${uiData.type}등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(CityManageEdit);
