import React, { Component } from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Loading from "./lib/Loading";
import ScrollToTop from "./lib/ScrollToTop";
import InitCodeList from "./lib/InitCodeList";
import BuddibleSocket, { MsgIDList } from "./lib/BuddibleSocket";
import Main from "./main/Main";
import FCLMain from "./oceanFreightFCL/FCLMain";
import Reserve from "./reserve/Reserve";
import PostMain from "./post/PostMain";
import PostDetail from "./postDetail/PostDetail";
import WriteQnA from "./writeQnA/WriteQnA";
import WriteRequestProduct from "./main/requestProduct/WriteRequestProduct";
import LogiTalk from "./logiTalk/LogiTalk";
import ChatNewsDetail from "./logiTalk/chatNews/ChatNewsDetail";
import WriteLogiStory from "./main/logiStory/WriteLogiStory";

import ERP from "./erp/ERP";
import AutoLogin from "./lib/AutoLogin";
import AboutGBTS from "./main/aboutGBTS/AboutGBTS";
import Terms from "./main/terms/Terms";
import StoreMallMain from "./storeMall/StoreMallMain";
import ReactGA from "react-ga";
import RouteChangeTracker from "./lib/RouteChangeTracker";

import ScheduleReserveLink from "./main/mall/scheduleReserveLink/ScheduleReserveLink";
import ScheduleReserveLinkHotDeal from "./main/mall/scheduleReserveLink/ScheduleReserveLinkHotDeal";
import LogiTalkChatRoomPop from "./logiTalk/chat/LogiTalkChatRoomPop";

import ReserveFCL from "./main/mall/schedule/FCL/ReserveFCL";

// ------------스케줄 페이지 분리-----------
import ScheduleLCL from "./main/mall/schedule/LCL/ScheduleLCL";
import ScheduleFCL from "./main/mall/schedule/FCL/ScheduleFCL";
import ScheduleAIR from "./main/mall/schedule/AIR/ScheduleAIR";

//로지톡 랜딩페이지
import LogiTalkLanding from "./logiTalkLanding/LogiTalkLanding";
//로지웍 랜딩페이지
import LogiWorkLanding from "./logiWorkLanding/LogiWorkLanding";

import AOS from "aos";
import "aos/dist/aos.css";

//FCL 예약정보링크 미리보기
import ScheduleFCLBookingInfoLink from "./erp/scheduleFCLBookingManage/ScheduleFCLBookingInfoLink";

// 홍보영상센터 페이지
import AdvertiseVideoCenter from "./advertiseVideoCenter/AdvertiseVideoCenter";

// 항공스케쥴 예약
import ReserveAIR from "./main/mall/schedule/AIR/ReserveAIR";

// 로지웍 스케쥴
import ScheduleLogiWork from "./main/mall/schedule_logiwork/ScheduleLogiWork";
import ScheduleLogiWorkCost from "./main/mall/schedule_logiwork/ScheduleLogiWorkCost";
import ScheduleLogiWorkReserveMemo from "./main/mall/schedule_logiwork/ScheduleLogiWorkReserveMemo";

import Info from "./main/info/Info";
import CorpInfoReferencePrint from "./logiTalk/corpInfoReference/CorpInfoReferencePrint";

import DeleteAccount from "./account/DeleteAccount";

// LogiTalk App WebView
// todo 테스트파일 삭제
import Test from "./test/Test"; //news screen
import LogiWorkTest from "./test/appLogiWork/LogiWorkTest";
import NewsDetail from "./test/NewsDetail";
import MainTest from "./test/main/Main";

import AppWebViewLogiWork from "./logiTalk/webView/logiWork/AppWebViewLogiWork";
import AppWebViewNews from "./logiTalk/webView/news/AppWebViewNews";
import AppWebViewNewsDetail from "./logiTalk/webView/news/components/AppWebViewNewsDetail";
import AppWebViewSearchUser from "./logiTalk/webView/searchUser/AppWebViewSearchUser";
// 로지웍 iframe 연동페이지
import AlarmTalkManage from "./logiWork/alarmTalkManage/AlarmTalkManage";

ReactGA.initialize("UA-221040546-1");
// const codeList = new CodeList();

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = new BuddibleSocket();
    this.state = {
      isLoadingFinished: true,
      loadingOpen: false,
    };
  }
  componentDidMount() {
    AOS.init();
    AOS.refresh();
    this.socket.fireLocalEventListener(
      MsgIDList.EVENT_LOADING_INDICATOR,
      "App",
      this.loadingOpenHandler
    );

    //this.initFinished();
  }

  componentWillUnmount() {
    localStorage.removeItem("mallFilter");
  }

  initFinished = () => {
    this.setState({ isLoadingFinished: true });
  };

  loadingOpenHandler = (v) => {
    this.setState({
      loadingOpen: v,
    });
  };

  render() {
    if (!this.state.isLoadingFinished) {
      return (
        <div id="wrap">
          <InitCodeList initFinished={this.initFinished} />
        </div>
      );
    }

    if (document.getElementById("LoadingModalPage"))
      document.getElementById("LoadingModalPage").remove();

    return (
      <div id="wrap">
        <AppMain />
      </div>
    );
  }
}

function AppMain(props) {
  // console.log("AppMain");
  return (
    <>
      <AutoLogin />
      <InitCodeList />
      <BrowserRouter>
        <ScrollToTop />
        <RouteChangeTracker />
        <Routes>
          <Route path="/MainTest" element={<MainTest />} />
          <Route path="/freiscop" element={<Main />} />
          <Route
            path="/freiscop/CorpInfoReferencePrint/:key"
            element={<CorpInfoReferencePrint />}
          />
          <Route path="/freiscop" element={<Main />} />
          <Route
            path="/freiscop/ScheduleLogiWorkCost/:type/:idx/:realTime"
            element={<ScheduleLogiWorkCost />}
          />
          <Route
            path="/freiscop/ScheduleLogiWorkReserveMemo/:exp_imp/:type"
            element={<ScheduleLogiWorkReserveMemo />}
          />
          <Route
            path="/freiscop/ScheduleLogiWorkReserveMemo/:type/:idx"
            element={<ScheduleLogiWorkReserveMemo />}
          />
          <Route path="/" element={<Main />} />
          <Route path="/gbts" element={<Main />} />
          <Route path="/ReserveFCL/:id" element={<ReserveFCL />} />
          <Route path="/Terms/:terms_type" element={<Terms />} />
          <Route path="/FCLMain/:pm_type/:pm_idx" element={<FCLMain />} />
          <Route path="/Reserve/:reserve_type/:pr_idx" element={<Reserve />} />
          <Route path="/Post/:post_type" element={<PostMain />} />
          <Route path="/ScheduleLCL" element={<ScheduleLCL />} />
          <Route path="/ScheduleLCL/:id" element={<ScheduleLCL />} />
          <Route path="/ScheduleFCL" element={<ScheduleFCL />} />
          <Route path="/ScheduleFCL/:id" element={<ScheduleFCL />} />
          <Route path="/ScheduleAIR" element={<ScheduleAIR />} />
          <Route path="/ScheduleAIR/:id" element={<ScheduleAIR />} />
          <Route path="/ReserveAIR/:id" element={<ReserveAIR />} />
          <Route
            path="/PostDetail/:post_type/:post_idx"
            element={<PostDetail />}
          />
          <Route path="/WriteQnA" element={<WriteQnA />} />
          <Route
            path="/WriteRequestProduct"
            element={<WriteRequestProduct />}
          />{" "}
          <Route path="/WriteLogiStory" element={<WriteLogiStory />} />
          <Route path="/WriteLogiStory/:idx" element={<WriteLogiStory />} />
          <Route path="/ERP" element={<ERP />} />
          <Route path="/ERP/:page" element={<ERP />} />
          <Route path="/LogiTalk" element={<LogiTalk />} />
          <Route path="/LogiTalk/sno/:bizNum" element={<LogiTalk />} />
          <Route path="/ChatNewsDetail/:id" element={<ChatNewsDetail />} />
          <Route
            path="/LogiTalk/ChatNewsDetail/:id"
            element={<ChatNewsDetail />}
          />
          <Route
            path="/CorpInfoReferencePrint/:key"
            element={<CorpInfoReferencePrint />}
          />
          <Route
            path="/CorpInfoReferencePrint"
            element={<CorpInfoReferencePrint />}
          />
          <Route
            path="/freiscop/CorpInfoReferencePrint/:key"
            element={<CorpInfoReferencePrint />}
          />
          <Route
            path="/freiscop/CorpInfoReferencePrint"
            element={<CorpInfoReferencePrint />}
          />
          <Route path="/aboutGBTS" element={<AboutGBTS />} />
          <Route path="/storeMall" element={<StoreMallMain />} />
          <Route path="/storeMall/:store_key" element={<StoreMallMain />} />
          <Route path="/freiscop/storeMall" element={<StoreMallMain />} />
          <Route
            path="/freiscop/storeMall/:store_key"
            element={<StoreMallMain />}
          />
          <Route
            path="/reserveLink/:randLink"
            element={<ScheduleReserveLink />}
          />
          <Route
            path="/reserveLinkHotdeal/:randLink"
            element={<ScheduleReserveLinkHotDeal />}
          />
          <Route
            path="/LogiTalkChatRoomPop/:crmMainKey"
            element={<LogiTalkChatRoomPop />}
          />
          <Route path="/LogiTalkLanding" element={<LogiTalkLanding />} />
          <Route path="/LogiWorkLanding" element={<LogiWorkLanding />} />
          <Route
            path="/ScheduleFCLBookingInfoLink"
            element={<ScheduleFCLBookingInfoLink />}
          />
          <Route
            path="/AdvertiseVideoCenter"
            element={<AdvertiseVideoCenter />}
          />
          <Route path="/INFO" element={<Info />} />
          <Route path="/DeleteAccount" element={<DeleteAccount />} />
          {/*------------------------todo 아래 경로 정리 필요(240125.Amber)*/}
          <Route path="/Test" element={<Test />} />
          <Route path="/NewsDetail" element={<NewsDetail />} />
          <Route path="/NewsDetail/:idx" element={<NewsDetail />} />
          <Route path="/LogiWorkTest" element={<LogiWorkTest />} />
          {/*---------------------*/}
          <Route path="/AppWebViewLogiWork" element={<AppWebViewLogiWork />} />
          <Route path="/AppWebViewNews" element={<AppWebViewNews />} />
          <Route
            path="/AppWebViewNewsDetail"
            element={<AppWebViewNewsDetail />}
          />
          <Route
            path="/AppWebViewNewsDetail/:idx"
            element={<AppWebViewNewsDetail />}
          />
          <Route
            path="/AppWebViewSearchUser"
            element={<AppWebViewSearchUser />}
          />
          {/*로지웍 iframe*/}
          <Route path="/AlarmTalkManage" element={<AlarmTalkManage />} />
          <Route path="/freiscop/schedule" element={<ScheduleLogiWork />} />
          <Route
            path="/freiscop/schedule/:type"
            element={<ScheduleLogiWork />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
