/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo,
} from "react";
import Select from "react-dropdown-select";
import $ from "jquery";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "";
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};
Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

let sm_ORIGIN = "";
let sm_DEST = "";
let sm_IDX = "";
const currentDate = util.todayDate("YYYY-MM-DD");
let isLoading = true;
let port_list = [];

const today = new Date();
const currentYear = today.getFullYear();

function ScheduleAIRManageEdit(props, ref) {
  const { data = {}, requestUserSelect, airline, supplier } = props;
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [desc, setDesc] = useState("");

  const controller = {
    sm_IDX: useRef(),
    sm_FWD_NO: useRef(),
    sm_FWD_NM: useRef(),
    sm_PROVIDER_CODE: useRef(),
    sm_PROVIDER_ID: useRef(),
    sm_PROVIDER_SALES_ID: useRef(),
    sm_DEPARTURE_DATE: useRef(),
    sm_VESSEL: useRef(),
    sm_CARGO_YN: useRef(),
    sm_POL_CODE: useRef(),
    sm_POD_CODE: useRef(),
    sm_ORIGIN: useRef(),
    sm_DEST: useRef(),
    sm_EXP_IMP: useRef(),
    sm_REMARK: useRef(),
    sm_USE_YN: useRef(),
    sm_DEL_YN: useRef(),
  };

  useEffect(() => {
    if (port_list.length === 0) {
      if (codeList.codeAirCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
          _mClassName,
          (oldV, newV) => {
            port_list = newV;
            isLoading = false;
            setIsNeedUpdate(!isNeedUpdate);
          }
        );
      } else {
        port_list = codeList.codeAirCountryFullName;
        isLoading = false;
        setIsNeedUpdate(!isNeedUpdate);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
    return () => {
      sm_ORIGIN = "";
      sm_DEST = "";
      sm_IDX = "";
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
    removeData(e, params) {
      handleDeleteData(e, params);
    },
  }));

  //parameter 데이터 확인 및 매핑
  const dataChange = (e, data) => {
    if (data.hasOwnProperty("currentData")) {
      sm_ORIGIN = data.currentData.sm_ORIGIN;
      sm_DEST = data.currentData.sm_DEST;
      sm_IDX = data.currentData.sm_IDX;

      for (let key in controller) {
        if (controller[key].current) {
          controller[key].current.value = data.currentData[key];
        }
      }

      setDesc(data.currentData.sm_REMARK);
    } else {
      sm_ORIGIN = "";
      sm_DEST = "";
      sm_IDX = "";
      for (let key in controller) {
        if (controller[key].current) {
          controller[key].current.value = "";
        }
      }

      setDesc("");
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  //스케줄 데이터 삭제
  const handleDeleteData = (e, params) => {
    let updateData = {
      sm_IDX: sm_IDX,
      sm_UID: params.currentData.sm_UID,
      sm_DEL_YN: "Y",
    };
    codeList.Modal.current.confirm("해당 스케쥴을 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.DELETE);
      }
    });
  };

  //스케줄 저장
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        sm_IDX: controller.sm_IDX.current.value,
        sm_FWD_NO: controller.sm_FWD_NO.current.value,
        sm_FWD_NM: controller.sm_FWD_NM.current.value,
        sm_PROVIDER_CODE: controller.sm_PROVIDER_CODE.current.value,
        sm_PROVIDER_ID: controller.sm_PROVIDER_ID.current.value,
        sm_PROVIDER_SALES_ID: controller.sm_PROVIDER_SALES_ID.current.value,
        sm_DEPARTURE_DATE: controller.sm_DEPARTURE_DATE.current.value,
        sm_VESSEL: controller.sm_VESSEL.current.value,
        sm_CARGO_YN: controller.sm_CARGO_YN.current.value,
        sm_POL_CODE: controller.sm_POL_CODE.current.value,
        sm_POD_CODE: controller.sm_POD_CODE.current.value,
        sm_EXP_IMP: controller.sm_EXP_IMP.current.value,
        sm_REMARK: controller.sm_REMARK.current.value,
        sm_USE_YN: controller.sm_USE_YN.current.value,
        sm_DEL_YN: "N",
      };

      requestUserUpdate(
        updateData,
        controller.sm_IDX.current.value !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  //입력값 유효성검사
  const checkValidation = () => {
    if (controller.sm_EXP_IMP.current.value.trim() === "") {
      codeList.Modal.current.alert("수출입구분을 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  //데이터통신
  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      file: "",
      data: {},
      ...updateData,
    };
    let alertSuccessMSG = "";
    let alertErrorMSG = "";
    if (TYPE === EDIT_TYPE.INSERT) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_insert.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 등록되었습니다.";
      alertErrorMSG =
        "스케쥴 등록중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }

    if (TYPE === EDIT_TYPE.UPDATE) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_update.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 수정되었습니다.";
      alertErrorMSG =
        "스케쥴 수정중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }
    if (TYPE === EDIT_TYPE.DELETE) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_update.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 삭제되었습니다.";
      alertErrorMSG =
        "스케쥴 삭제중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();
          codeList.Modal.current.alert(alertSuccessMSG, () => {});
          setLoading(false);
          Cancel();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(alertErrorMSG, () => {});
        }
      }
    });
  };

  // 취소
  const Cancel = () => {
    for (let key in controller) {
      if (controller[key].current) {
        controller[key].current.value = "";
      }
    }
    setDesc("");
    $("#ScheduleAIRManageEditClose").click();
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      uploadCallback(file[0]);
    };
  };
  //파일첨부 업데이트
  const uploadCallback = async (file) => {
    const region = "gbts/schdule_remark";
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const range = controller.sm_REMARK.current
        ?.getEditor()
        .getSelection()?.index;
      if (range !== null && range !== undefined) {
        let quill = controller.sm_REMARK.current?.getEditor();

        quill?.setSelection(range, 1);
        quill?.clipboard.dangerouslyPasteHTML(
          range,
          `<img style="width:100%;" width='100%'  src=${response.data["url"]} alt="이미지 태그가 삽입됩니다." />`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
  ];

  const createFullMonth = () => {
    let dateList = [];
    for (let i = 1; i <= 12; i++) {
      let month = i >= 10 ? i : `0${i}`;
      let value = `${currentYear}-${month}-01`;
      let title = `${month}월`;
      dateList.push(
        <option key={i} value={value}>
          {title} ({value})
        </option>
      );
    }
    return dateList;
  };

  return (
    <>
      {!isLoading && (
        <div
          className="modal fade"
          id="ScheduleAIRManageEdit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="ScheduleAIRManageEdit"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ minWidth: 700 }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel">
                  <strong>항공 스케쥴 등록</strong>
                </h3>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="ScheduleAIRManageEditClose"
                  onClick={() => Cancel()}
                >
                  <i className="fas fa-plus fa-times" />
                </button>
              </div>
              <div className="modal-body">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    관리 번호
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="account"
                      disabled={true}
                      className="form-control"
                      placeholder="ID"
                      ref={controller.sm_IDX}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    항공사
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={controller.sm_FWD_NO}
                    >
                      <option value="">-항공사를 선택해주세요-</option>
                      {airline.length > 0 &&
                        airline.map((item, index) => {
                          let key = "airline_" + item.AL_COMP_CD + "_" + index;
                          return (
                            <option key={key} value={item.AL_COMP_CD}>
                              {item.AL_COMP_NM}({item.AL_COMP_NM_EN})
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    공급사
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={controller.sm_FWD_NO}
                    >
                      <option value="">-공급사를 선택해주세요-</option>
                      {supplier.length > 0 &&
                        supplier.map((item, index) => {
                          let key = "supplier_" + item.AL_COMP_CD + "_" + index;
                          return (
                            <option key={key} value={item.AL_COMP_CD}>
                              {item.AL_COMP_NM}({item.AL_COMP_NM_EN})
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    운항 월
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={controller.sm_DEPARTURE_DATE}
                    >
                      <option value="">-운항월을 선택해주세요-</option>
                      {createFullMonth()}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    항공기 No.
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      placeholder="항공기 No.를 입력해주세요"
                      ref={controller.sm_VESSEL}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    화물전용 여부
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={controller.sm_CARGO_YN}
                    >
                      <option value="">-화물전용 여부를 선택해주세요-</option>
                      <option value="Y">화물전용</option>
                      <option value="N">화물/승객</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    출발항(공항)
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control d-none"
                      disabled={true}
                      ref={controller.sm_POL_CODE}
                    />{" "}
                    <input
                      type="text"
                      className="form-control d-none"
                      disabled={true}
                      ref={controller.sm_ORIGIN}
                    />
                    <Select
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={port_list}
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sm_ORIGIN ? sm_ORIGIN : "출발항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          //출발지명
                          controller.sm_ORIGIN.current.value = "";
                          //출발지 항구코드
                          controller.sm_POL_CODE.current.value = "";
                          return;
                        }
                        //출발지명
                        controller.sm_ORIGIN.current.value = data[0].CustomCode;
                        //출발지 항구코드
                        controller.sm_POL_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={({ item, methods }) => {
                        return (
                          <p
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                            onClick={() => methods.addItem(item)}
                            color="#0074D9"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #fff",
                              color: "gray",
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b
                              style={{
                                fontWeight: "normal",
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {item.CustomCode
                                ? "[" + item.CustomCode + "] "
                                : ""}
                            </b>

                            <b
                              style={{
                                fontWeight: 600,
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {`${item.CityName} (${item.CityName_EN}) `}
                            </b>

                            {item.CountryName + " " + item.ContinentName}
                          </p>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    도착항(공항)
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      disabled={true}
                      className="form-control d-none"
                      ref={controller.sm_POD_CODE}
                    />
                    <input
                      type="text"
                      disabled={true}
                      className="form-control d-none"
                      ref={controller.sm_DEST}
                    />
                    <Select
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={port_list}
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sm_DEST ? sm_DEST : "출발항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          controller.sm_DEST.current.value = "";
                          controller.sm_POD_CODE.current.value = "";
                          return;
                        }
                        controller.sm_DEST.current.value = data[0].CustomCode;
                        controller.sm_POD_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={({ item, methods }) => {
                        return (
                          <p
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                            onClick={() => methods.addItem(item)}
                            color="#0074D9"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #fff",
                              color: "gray",
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b
                              style={{
                                fontWeight: "normal",
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {item.CustomCode
                                ? "[" + item.CustomCode + "] "
                                : ""}
                            </b>

                            <b
                              style={{
                                fontWeight: 600,
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {`${item.CityName} (${item.CityName_EN}) `}
                            </b>

                            {item.CountryName + " " + item.ContinentName}
                          </p>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    주요 안내사항
                  </label>
                  <div className="col-sm-9">
                    <ReactQuill
                      className="bg-white w-100"
                      modules={modules}
                      formats={formats}
                      ref={controller.sm_REMARK}
                      value={desc}
                      onChange={setDesc}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    노출 여부
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={controller.sm_CARGO_YN}
                    >
                      <option value="">-노출 여부를 선택해주세요-</option>
                      <option value="Y">노출</option>
                      <option value="N">비노출</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      Cancel();
                    }}
                  >
                    <i className="fa fa-times mr-2" />
                    취소
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => actionSave(e)}
                    disabled={loading}
                  >
                    <i className="fa fa-check mr-2" />
                    저장
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default forwardRef(ScheduleAIRManageEdit);
