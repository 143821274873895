/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CommonNav from "../main/CommonNav";
import FCLTitle from "./FCLTitle";
import FCLImages from "./FCLImages";
import FCLShippingDetail from "./fclShippingDetail/FCLShippingDetail";
import Review from "./review/Review";
import Footer from "../main/Footer";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function FCLMain() {
  const param = useParams();

  const [pmProductData, setPmProductData] = useState({
    pmContactName: "",
    pmContactTel: "",
    pmContactEmail: "",
    pmContactCompCD: "",
    pmContactUserID: "",
    pmArrivalCity: "CDBD01",
    pmCreator: "",
    pmCreatorSite: "",
    pmDepartureCity: "CDAA01",
    pmDepartureDate: "2021-12-12|2021-12-15|2021-12-21|2021-12-30|2022-01-04",
    pmDepartureDayWeek: "",
    pmDepartureType: "D",
    pmForwarderDesc: "정직한 동서로지스틱스!",
    pmForwarderHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmForwarderImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/forwarderImage/20211212172437_ds.png",
    pmForwarderName: "동서로지스틱스",
    pmIDX: "",
    pmKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
    pmOptionInclude: "- 운임, 부대비용을 포함한 상기 고지된 물류비용",
    pmOptionInfo:
      "- 수출통관수수료\n- 국내컨테이너운송료\n\n등 비용은 업무 진행시 제휴사(관세사)청구금액 및 운송구간에 따른 운임에 따릅니다. 해당 비용에 대하여 궁금하시면 문의 남겨 주세요.",
    pmOptionNotInclude:
      "- 통관수수료, 적하보험료, 내륙운송료, DOOR작업건의 경우 상차비용 등은 불포함 되어 있습니다",
    pmProductExpireEnd: "2022-01-08",
    pmProductExpireStart: "2021-12-12",
    pmProductExpireType: "S",
    pmProductName: "인천-하이퐁 SM상선 FCL 직항",
    pmProductType: "A",
    pmShipCompanyDesc: "빠른 SM상선!",
    pmShipCompanyHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmShipCompanyImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/carrierImage/20211212172323_ship-gfa701dc0d_640.jpg",
    pmShipCompanyName: "SM상선",
    pmShipInfo: "PANCON SUCCESS",
    pmTransferDay: "30",
    pmTransferDirectYN: "Y",
    pmTransferType: "LCL",
    pmUSEYN: "Y",
    pmWarningInfo: "1. GBTS로지스몰에서 예약을 하시면 담당…..",
    subData: [
      {
        psIDX: 0,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "OCEAN FREIGHT",
        psServiceType: "A",
      },
      {
        psIDX: 1,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "EBS",
        psServiceType: "A",
      },
      {
        psIDX: 2,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "CIS",
        psServiceType: "A",
      },
      {
        psIDX: 3,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 40,
        psServiceCol2: 80,
        psServiceName: "CRS",
        psServiceType: "A",
      },
      {
        psIDX: 4,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 130000,
        psServiceCol2: 180000,
        psServiceName: "THC",
        psServiceType: "E",
      },
      {
        psIDX: 4,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 4200,
        psServiceCol2: 8400,
        psServiceName: "WFG",
        psServiceType: "E",
      },
      {
        psIDX: 5,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 86,
        psServiceCol2: 86,
        psServiceName: "SECURITY CHARGE",
        psServiceType: "E",
      },
      {
        psIDX: 6,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 50000,
        psServiceCol2: 50000,
        psServiceName: "DOCUMENT FEE",
        psServiceType: "E",
      },
      {
        psIDX: 7,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 80,
        psServiceCol2: 80,
        psServiceName: "D/O FEE",
        psServiceType: "E",
      },
    ],
  });

  useEffect(() => {
    ChangeProductType();
  }, [pmProductData]);

  useEffect(() => {
    getData();
  }, []);

  const ChangeProductType = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "FCLMain",
      pmProductData,
      () => {}
    );
  };

  const getData = () => {
    const pm_idx = param.pm_idx;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/product/JS_product_select_detail.php",
      msgID: msgID,
      data: {},
      pmIDX: pm_idx,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length === 0) return;
          let data = newData["returnData"];
          let temp = { ...pmProductData };

          for (let key in temp) {
            if (data[0].hasOwnProperty(key)) temp[key] = data[0][key];
          }
          // console.log(temp);
          //setProductData

          temp["subData"] = [];
          for (let i = 0; i < data.length; i++) {
            const tempSub = {
              psIDX: data[i]["psIDX"],
              psKeyMain: data[i]["psKeyMain"],
              psKeySub: data[i]["psKeySub"],
              psMoneyCD: data[i]["psMoneyCD"],
              psServiceCol1: data[i]["psServiceCol1"],
              psServiceCol2: data[i]["psServiceCol2"],
              psServiceName: data[i]["psServiceName"],
              psServiceType: data[i]["psServiceType"],
              psCostType: data[i]["psCostType"],
            };
            // console.log(tempSub);
            temp["subData"].push(tempSub);
          }
          // console.log(temp);
          setPmProductData({ ...temp });
        }
      }
    });
  };

  return (
    <>
      <CommonNav />
      <main className="white_bg py-4">
        <div id="FCLMain">
          <FCLTitle />
          <FCLImages />
          <FCLShippingDetail />
          <Review />
        </div>
      </main>
      <Footer />
    </>
  );
}
