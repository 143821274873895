/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import CommonNav from "../main/CommonNav";
import Footer from "../main/Footer";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import axios from "axios";
import { findAllByRole } from "@testing-library/react";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const serverFile = {
  update: "/qa/JS_qa_update.php",
  insert: "/qa/JS_qa_insert.php",
};

export default function WriteQnA() {
  const navigate = useNavigate();
  const [pwDisabled, setPwDisabled] = useState(true);
  const [qaData, setQaData] = useState({
    qaUSERName: "", //질문자 명
    qaTITLE: "", //질문 제목
    qaCONTENTS: "", //질문 내용
    qaTYPE: "", //질문 분류
    qaPublicYN: "Y", //공개여부
    qaPWD: "", //비밀번호 회사코드가있을경우 회사코드로 대체
    qaIDX: "", //index
    qaINSERTDATE: new Date(), //질문 업로드일
    qaUSEYN: "Y", //사용여부
    qaUSERID: "", //로그인 경우 유저 아이디
    qaCompCD: "", //회사 코드 z로 시작시 관리자
  });

  const controller = {
    qaTITLE: useRef(),
    qaCONTENTS: useRef(),
    qaUSERName: useRef(),
    qaPWD: useRef(),
    qaTYPE: useRef(),
    qaPublicYN: useRef(),
  };
  let loginInfo = null;
  if (sessionStorage.getItem("loginInfo")) {
    loginInfo = JSON.parse(
      util.buddibleDecrypt(sessionStorage.getItem("loginInfo"))
    );
  }

  useEffect(() => {
    if (loginInfo !== null) {
      controller.qaUSERName.current.value = loginInfo["returnData"][0].user_nm;
    }
  }, []);

  const ChangePublicYN = (e) => {
    let val = e.target.value;
    let setData = {};
    if (val === "N") {
      if (loginInfo !== null) {
        controller.qaPWD.current.value = loginInfo["returnData"][0].comp_cd;
        setData.qaPWD = loginInfo["returnData"][0].comp_cd;
        setPwDisabled(true);
      } else {
        setPwDisabled(false);
      }
    } else {
      controller.qaPWD.current.value = "";
    }
    setQaData({ ...qaData, ...setData, qaPublicYN: val });
  };

  const addQuestion = () => {
    if (checkValidation()) {
      codeList.Modal.current.confirm(
        "문의사항 등록을 진행하시겠습니까?",
        (ret) => {
          if (ret) {
            let updateData = {
              qaUSERName: controller.qaUSERName.current.value, //질문자 명
              qaTITLE: controller.qaTITLE.current.value, //질문 제목
              qaCONTENTS: controller.qaCONTENTS.current.value, //질문 내용
              qaTYPE: controller.qaTYPE.current.value, //질문 분류
              qaPublicYN: qaData.qaPublicYN, //공개여부
              qaPWD: controller.qaPWD.current.value, //비밀번호 회사코드가있을경우 회사코드로 대체
              qaIDX: "", //index
              qaINSERTDATE: new Date(), //질문 업로드일
              qaUSEYN: "Y", //사용여부
              qaUSERID: loginInfo ? loginInfo["returnData"][0].user_id : "", //로그인 경우 유저 아이디
              qaCompCD: loginInfo ? loginInfo["returnData"][0].comp_cd : "", //회사 코드 z로 시작시 관리자
            };
            requestUserUpdate(updateData);
          } else {
            codeList.Modal.current.alert("문의사항 등록이 취소되었습니다.");
          }
        }
      );
    }
  };

  const checkValidation = () => {
    if (controller.qaTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if (controller.qaTYPE.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if (controller.qaUSERName.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요", () => {});
      return false;
    }
    if (controller.qaCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if (qaData.qaPublicYN === "N") {
      if (controller.qaPWD.current.value.trim() === "") {
        codeList.Modal.current.alert("비밀번호를 입력해 주세요", () => {});
        return false;
      }
    }

    return true;
  };

  const requestUserUpdate = (updateData) => {
    // console.log("updateData", updateData);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/qa/JS_qa_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert(
            "문의사항 등록이 완료되었습니다.",
            () => {
              navigate("/Post/qa");
            }
          );
        }
      }
    });
  };

  return (
    <>
      {" "}
      <div className="white_bg" id="write_QnA" style={{ minHeight: "100vh" }}>
        <CommonNav />
        <main className="white_bg">
          <div className="py-5">
            <div className="my-5">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {/*필터 영역*/}
                    <div className="pt-3 text-muted font-weight-bold">
                      {/*간편기간 영역*/}
                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <h3 className="mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0">
                          <strong>문의글 작성하기</strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-row mb-3">
                    <input
                      className="form-control mr-3"
                      type="text"
                      placeholder="제목을 입력해 주세요"
                      ref={controller.qaTITLE}
                    />
                    <select
                      className="form-control w-25"
                      ref={controller.qaTYPE}
                    >
                      <option value="">분류</option>
                      <option value="FJA0">
                        해상(항공) 스케쥴 및 견적 문의
                      </option>
                      <option value="FJA1">내륙운송 문의</option>
                      <option value="FJA2">보관 및 하역관련 문의</option>
                      <option value="FJA3">통관 및 FTA 문의</option>
                      <option value="FJA4">적하보험 및 기타 문의</option>
                    </select>
                  </div>
                  <div className="col-12 d-flex flex-row mb-3">
                    <input
                      className="form-control mr-3"
                      type="text"
                      placeholder="이름"
                      ref={controller.qaUSERName}
                    />
                    <input
                      className="form-control mr-3"
                      type="password"
                      placeholder="비밀번호"
                      ref={controller.qaPWD}
                      disabled={pwDisabled}
                    />
                    <div className="btn-group btn-group-toggle">
                      <label
                        className={`btn btn-light ${
                          qaData.qaPublicYN === "N" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="public"
                          value="N"
                          onClick={(e) => {
                            ChangePublicYN(e);
                          }}
                        />
                        <i className="icon-lock" />
                      </label>{" "}
                      <label
                        className={`btn btn-light ${
                          qaData.qaPublicYN === "Y" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="public"
                          value="Y"
                          onClick={(e) => {
                            ChangePublicYN(e);
                          }}
                        />
                        <i className="icon-lock-open-alt" />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      rows={20}
                      placeholder="문의사항을 입력해주세요"
                      ref={controller.qaCONTENTS}
                    />
                  </div>
                  <div className="col-12 py-3 d-flex flex-row justify-content-center">
                    <div
                      className="btn_1 px-5"
                      onClick={() => {
                        addQuestion();
                      }}
                    >
                      등록하기
                    </div>

                    <Link to="/Post/qa" className="btn_1 outline ml-2 px-5">
                      목록으로
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
