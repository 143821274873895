import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "axios";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import remarkRehype from "remark-rehype";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import Utilities from "../../../lib/Utilities";
import LogoSvg from "../../../assets/LogoSvg";
import Loading from "../../../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleLogiWorkCost";

const FSC_COST = {
  S: { type: "C/WT", minAmount: 0, cost: 570 },
  M: { type: "C/WT", minAmount: 0, cost: 600 },
  L: { type: "C/WT", minAmount: 0, cost: 640 },
};

const SCC_COST = {
  ID: { type: "C/WT", minAmount: 6500, cost: 130 },
  DE: { type: "C/WT", minAmount: 6500, cost: 130 },
  US: { type: "C/WT", minAmount: 6500, cost: 130 },
};

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

let currency_rate = null;
let comp_list_obj = {};
let login_info = null;
let modal_data = null;
let today = new Date();
function isNumber(s) {
  s += ""; // 문자열로 변환
  s = s.replace(/^\s*|\s*$/g, ""); // 좌우 공백 제거
  if (s == "" || isNaN(s)) return false;
  return true;
}

export default function ScheduleLogiWorkCost() {
  const params = useParams();
  const { idx, type, realTime } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [isShowCompare, setIsShowCompare] = useState(false);
  const [compareData, setCompareData] = useState(null);
  const [loadText, setLoadText] = useState(
    "동일구간 스케줄 정보를 조회합니다."
  );
  const isFrei = isNumber(idx);

  useEffect(() => {
    requestCurrencyRate();
    requestShipCompList();
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      login_info = userData["returnData"][0];
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        login_info = n["returnData"][0];
      }
    );

    if (type === "air") {
      requestScheduleAirData((data) => {
        modal_data = data;

        if (modal_data) {
          requestScheduleAirDetailData((data) => {
            let current_day = new Date(+realTime).getDay();
            let select_day_key = `sm_INFO_${days[current_day]}`;
            modal_data = {
              ...modal_data,
              ...data,
              ...data[select_day_key],
            };
            requestScheduleAirPrice((data) => {
              modal_data = {
                ...modal_data,
                ...data,
              };

              setIsLoading(false);
            });
          });
        }
      });
    } else {
      requestScheduleData((data) => {
        modal_data = {
          COMP_LOGO: data.COMP_LOGO,
          COMP_CD: data.COMP_CD,
          sm_IDX: data.sm_IDX,
          sm_FWD_NO: data.sm_FWD_NO,
          sm_FWD_NM: data.sm_FWD_NM,
          sm_EXP_IMP: data.sm_EXP_IMP,
          sm_FCL_LCL: data.sm_FCL_LCL,
          sm_VESSEL: data.sm_VESSEL,
          sm_VOY: data.sm_VOY,
          sm_DOC_CLOSE: data.sm_DOC_CLOSE,
          sm_ORIGIN: data.sm_ORIGIN,
          sm_DEST: data.sm_DEST,
          sm_POL_CODE: data.sm_POL_CODE,
          sm_POD_CODE: data.sm_POD_CODE,
          sm_POL_NAME: data.sm_POL_NAME,
          sm_POD_NAME: data.sm_POD_NAME,
          sm_PROMO_YN: data.sm_PROMO_YN,
          sm_PROMO_MEMO: data.sm_PROMO_MEMO,
          sm_DEPARTURE_DATE: data.sm_DEPARTURE_DATE,
          sm_ARRIVE_DATE: data.sm_ARRIVE_DATE,
        };

        if (modal_data) {
          if (type === "lcl") {
            requestLCLPriceGradeSelect((newData) => {
              let price_list =
                newData["price_list"].length > 0
                  ? newData["price_list"]
                  : [
                      {
                        SP_KND: "OCEAN FREIGHT",
                        SP_DIV: "R.TON",
                        SP_CUR: "",
                        SP_PRICE: "-",
                      },
                      {
                        SP_KND: "SURCHARGE",
                        SP_DIV: "R.TON",
                        SP_CUR: "",
                        SP_PRICE: "-",
                      },
                    ];

              modal_data = {
                ...data,
                price_list: [...price_list, ...newData["price_default_list"]],
              };
              setIsLoading(false);
            });
          } else if (type === "fcl") {
            handleCompareCostData((data) => {
              let dataList = [...data];
              dataList.sort((a, b) => {
                return a["allTotal20"] - b["allTotal20"];
              });

              setCompareData(dataList);
            });

            switch (modal_data.sm_FWD_NO) {
              case "NSS":
                getCostNSS((data) => {
                  modal_data["SurchargeInfo"] = data;
                  getInfoNSS((data) => {
                    modal_data["bookInfo"] = data;
                    requestCostOCF();
                  });
                });
                break;
              case "NSSL":
                getCostNSS((data) => {
                  modal_data["SurchargeInfo"] = data;
                  getInfoNSS((data) => {
                    modal_data["bookInfo"] = data;
                    requestCostOCF();
                  });
                });
                break;
              case "PCS":
                getCostPCS((data) => {
                  modal_data["SurchargeInfo"] = data;
                  requestCostOCF();
                });
                break;
              case "SKR":
                getCostSKR((data) => {
                  modal_data.snkCost = data;
                  requestCostOCF();
                });
                break;
              case "HAS":
                getCostHAS((data) => {
                  modal_data.snkCost = data;
                  requestCostOCF();
                });
                break;
              case "DJS":
                getPriceDJShip((data) => {
                  modal_data.CoastList = data.CoastList;
                  requestCostOCF();
                });
                break;
              default:
                requestCostOCF();
                break;
            }
          }
        }
      });
    }

    return () => {
      login_info = null;
      modal_data = null;
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  // LCL운임정보 요청
  const requestLCLPriceGradeSelect = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_price_lcl_grade_select.php",
      msgID: msgID,
      data: {},
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
      COMP_LVL: login_info.COMP_LVL,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 환율정보 요청
  const requestCurrencyRate = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/currency_rate/JS_currency_rate_select.php",
      msgID: msgID,
      data: [],
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          currency_rate = JSON.parse(newData["returnData"][0].cr_price_data);
        }
      }
    });
  };

  // 선사 로고이미지 요청
  const requestShipCompList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/b_logo/JS_b_logo_select.php",
      msgID: msgID,
      data: [],
      USE_YN: "Y",
      blSchDisplay: "Y",
      blCompType: "F",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          newData["returnData"].forEach((item) => {
            comp_list_obj[item.blFWDNO] = item;
          });
        }
      }
    });
  };

  //남성해운 FCL 운임 추가정보
  const getCostNSS = (callback) => {
    let data = {
      bukrs: "1000",
      inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
      inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inptype: "D",
    };
    axios.defaults.withCredentials = false;
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceNSS", data)
      .then((response) => {
        let data = null;
        if (
          response.data.hasOwnProperty("dlt_preCol1") &&
          response.data["dlt_preCol1"].length > 0
        ) {
          data = [
            ...response.data["dlt_preCol1"],
            ...response.data["dlt_preCol2"],
          ];
        }

        callback(data);
      })
      .catch((error) => {
        console.log("error", error);
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );

        let replaceUrl = `${window.location.origin}/ScheduleLogiWorkCost/fcl/${modal_data.sm_IDX}`;
        window.location.replace(replaceUrl);

        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };

  // 남성해운 스케줄 디테일
  const getInfoNSS = (callback) => {
    let selectScheDetailData = {
      inpdiv: "F",
      inpdiv2: "S",
      inplpt: switchPortsCode(modal_data.sm_POL_CODE), //출발

      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inpdpt: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inpmon: modal_data.sm_DEPARTURE_DATE
        .replace(/[^0-9]/gi, "")
        .substring(0, 6), //해당항차 출발 년월

      inptsd: "D",
      inpvvd: modal_data.sm_VOY, //항차
    };

    axios.defaults.withCredentials = false;
    axios
      .post(
        "https://talktalk.gbts.co.kr:6100/selectScheDetail",
        selectScheDetailData
      )
      .then((response) => {
        let data = null;

        if (
          response.data.hasOwnProperty("dlt_scheduleDetailList") &&
          response.data["dlt_scheduleDetailList"].length > 0
        ) {
          data = { ...response.data["dlt_scheduleDetailList"][0] };
          data.VSL_CLOSE_F_desc = data.VSL_CLOSE_F === "F" ? "종료" : "가능";
        }
        callback && callback();
      })
      .catch((error) => {
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );
        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };

  // 장금상선 운임 get
  const getCostSKR = (cb) => {
    //장금상선
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceSinokor", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 흥아라인 운임 get
  const getCostHAS = (cb) => {
    //장금상선
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceHeungaline", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 동영해운 운임 GET
  const getCostPCS = (cb) => {
    let data = {
      bukrs: "1000",
      inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
      inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inptype: "D",
    };

    axios
      .post("https://talktalk.gbts.co.kr:6500/getPricePCS", data)
      .then((response) => {
        let data = null;
        if (
          response.data.hasOwnProperty("dlt_preCol1") &&
          response.data["dlt_preCol1"].length > 0
        ) {
          data = [
            ...response.data["dlt_preCol1"],
            ...response.data["dlt_preCol2"],
          ];
        }

        if (cb) cb(data);
      })
      .catch((error) => {
        console.log("error", error);
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );
        let replaceUrl = `${window.location.origin}/ScheduleFCL`;

        window.location.replace(replaceUrl);

        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };

  // 동진상선 운임 요청
  const getPriceDJShip = (cb) => {
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceDJShip", {
        inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const switchPortsCode = (val) => {
    const codes = {
      CNDAL: "CNDLC",
      CNNBO: "CNNGB",
      CNNJI: "CNNKG",
      CNSGH: "CNSHA",
      CNQIN: "CNTAO",
      CNXGA: "CNTXG",

      CNSTG: "CNSWA",
      CNYAT: "CNYNT",
      CNZJI: "CNZJG",
      JPAKI: "JPAXT",
      JPNGY: "JPNGO",
      JPOIP: "JPOIT",
      JPSDJ: "JPSGM",
      VNHAP: "VNHPH",
    };
    if (codes.hasOwnProperty(val)) {
      val = codes[val];
    }
    return val;
  };

  // get 스케줄 정보
  const requestScheduleData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select.php",
      msgID: msgID,
      data: {},
      sm_IDX: idx,
      sm_FCL_LCL: type.toUpperCase(),
    };

    if (type.toUpperCase() === "FCL") {
      socketMsg.file = "/schedule/JS_schedule_tradlinx_single_select.php";
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length > 0) {
          callback(newData["returnData"][0]);
        }
      }
    });
  };

  // OCF 운임정보 get
  const requestCostOCF = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_fcl_ocf_select.php",
      msgID: msgID,
      COMP_LVL: login_info.COMP_LVL,
      SP_POL: modal_data.sm_POL_CODE,
      SP_POD: modal_data.sm_POD_CODE,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = {};
          newData["comp_cost"].forEach((item) => {
            data[item.SP_SHIP_CODE] = {
              SP_20_INCH: Number(item.SP_20_INCH),
              SP_40_INCH: Number(item.SP_40_INCH),
            };
          });

          modal_data["comp_cost"] = data;
          modal_data["default_cost"] = newData["default_cost"][0] || {
            SP_20_INCH: 0,
            SP_40_INCH: 0,
          };

          let ocf = data[modal_data.sm_FWD_NO] || modal_data["default_cost"];

          modal_data["SP_20_INCH"] = ocf["SP_20_INCH"];
          modal_data["SP_40_INCH"] = ocf["SP_40_INCH"];

          setIsLoading(false);
        }
      }
    });
  };

  //동일구간 스케줄 요청
  const requestCompareSchedule = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_fcl_compare_info.php",
      msgID: msgID,
      sm_DOC_CLOSE: modal_data.sm_DOC_CLOSE,
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData["returnData"]);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생하였습니다. 다시 시도해주세요."
          );
          setIsLoading(false);
        }
      }
    });
  };

  // Compare Prepaid, Collect Cost (NSS , HAS ,SKR, PCS)
  const requestCompareScheduleCost = (callback) => {
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceForAll", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
        //--------------------------------
        bukrs: "1000",
        inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
        inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
        inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inptype: "D",
      })
      .then((response) => {
        callback && callback(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  // KRW 합계 및 최소값 계산
  const calcCost = (compareList, price) => {
    let minCost = {};

    for (let i = 0; i < price.length; i++) {
      let item = price[i];
      let key = item.Company;

      const _prepaid = item.aggregate.Prepaid;
      const _collect = item.aggregate.Collect;
      let prepaid = [];
      let collect = [];

      let pre20Total = 0;
      let pre40Total = 0;
      let col20Total = 0;
      let col40Total = 0;

      for (let key in _prepaid) {
        let con20 = _prepaid[key]["Standard Container 20GP"];
        //+ _prepaid[key]["wfglc"];
        let con40 = _prepaid[key]["Standard Container 40GP"];
        //+_prepaid[key]["wfglc"];

        prepaid.push({
          key: key,
          container20: con20,
          container40: con40,
        });

        if (con20 > 0) {
          if (!minCost.hasOwnProperty([`prepaid_20_${key}`])) {
            minCost[`prepaid_20_${key}`] = con20;
          } else {
            if (minCost[`prepaid_20_${key}`] > con20) {
              minCost[`prepaid_20_${key}`] = con20;
            }
          }
        }
        if (con40 > 0) {
          if (!minCost.hasOwnProperty([`prepaid_40_${key}`])) {
            minCost[`prepaid_40_${key}`] = con40;
          } else {
            if (minCost[`prepaid_40_${key}`] > con40) {
              minCost[`prepaid_40_${key}`] = con40;
            }
          }
        }

        if (currency_rate.hasOwnProperty(key) && currency_rate[key] !== null) {
          pre20Total += con20 * currency_rate[key];
          pre40Total += con40 * currency_rate[key];
        }

        if (key === "KRW") {
          pre20Total += con20;
          pre40Total += con40;
        }
      }
      for (let key in _collect) {
        let con20 = _collect[key]["Standard Container 20GP"];
        //+_collect[key]["wfglc"];
        let con40 = _collect[key]["Standard Container 40GP"];
        //+_collect[key]["wfglc"];

        collect.push({
          key: key,
          container20: con20,
          container40: con40,
        });

        if (con20 > 0) {
          if (!minCost.hasOwnProperty([`collect_20_${key}`])) {
            minCost[`collect_20_${key}`] = con20;
          } else {
            if (minCost[`collect_20_${key}`] > con20) {
              minCost[`collect_20_${key}`] = con20;
            }
          }
        }
        if (con40 > 0) {
          if (!minCost.hasOwnProperty([`collect_40_${key}`])) {
            minCost[`collect_40_${key}`] = con40;
          } else {
            if (minCost[`collect_40_${key}`] > con40) {
              minCost[`collect_40_${key}`] = con40;
            }
          }
        }

        if (currency_rate.hasOwnProperty(key) && currency_rate[key] !== null) {
          col20Total += con20 * currency_rate[key];
          col40Total += con40 * currency_rate[key];
        }

        if (key === "KRW") {
          col20Total += con20;
          col40Total += con40;
        }
      }

      let ocf20 = compareList[key]["SP_20_INCH"];
      let ocf40 = compareList[key]["SP_40_INCH"];

      pre20Total += ocf20 * currency_rate["USD"];
      pre40Total += ocf40 * currency_rate["USD"];

      if (!minCost.hasOwnProperty("SP_20_INCH")) {
        minCost.SP_20_INCH = ocf20;
      } else {
        if (minCost.SP_20_INCH > ocf20) {
          minCost.SP_20_INCH = ocf20;
        }
      }
      if (!minCost.hasOwnProperty("SP_40_INCH")) {
        minCost.SP_40_INCH = ocf40;
      } else {
        if (minCost.SP_40_INCH > ocf40) {
          minCost.SP_40_INCH = ocf40;
        }
      }

      pre20Total = Math.round(pre20Total);
      pre40Total = Math.round(pre40Total);

      col20Total = Math.round(col20Total);
      col40Total = Math.round(col40Total);

      let allTotal20 = pre20Total + col20Total;
      let allTotal40 = pre40Total + col40Total;

      if (pre20Total > 0) {
        if (!minCost.hasOwnProperty("pre20Total")) {
          minCost.pre20Total = pre20Total;
        } else {
          if (minCost.pre20Total > pre20Total) {
            minCost.pre20Total = pre20Total;
          }
        }
      }

      if (pre40Total > 0) {
        if (!minCost.hasOwnProperty("pre40Total")) {
          minCost.pre40Total = pre40Total;
        } else {
          if (minCost.pre40Total > pre40Total) {
            minCost.pre40Total = pre40Total;
          }
        }
      }

      if (col20Total > 0) {
        if (!minCost.hasOwnProperty("col20Total")) {
          minCost.col20Total = col20Total;
        } else {
          if (minCost.col20Total > col20Total) {
            minCost.col20Total = col20Total;
          }
        }
      }

      if (col40Total > 0) {
        if (!minCost.hasOwnProperty("col40Total")) {
          minCost.col40Total = col40Total;
        } else {
          if (minCost.col40Total > col40Total) {
            minCost.col40Total = col40Total;
          }
        }
      }
      if (allTotal20 > 0) {
        if (!minCost.hasOwnProperty("allTotal20")) {
          minCost.allTotal20 = allTotal20;
        } else {
          if (minCost.allTotal20 > allTotal20) {
            minCost.allTotal20 = allTotal20;
          }
        }
      }

      if (allTotal40 > 0) {
        if (!minCost.hasOwnProperty("allTotal40")) {
          minCost.allTotal40 = allTotal40;
        } else {
          if (minCost.allTotal40 > allTotal40) {
            minCost.allTotal40 = allTotal40;
          }
        }
      }

      compareList[key].pre20Total = pre20Total || "-";
      compareList[key].pre40Total = pre40Total || "-";

      compareList[key].col20Total = col20Total || "-";
      compareList[key].col40Total = col40Total || "-";

      compareList[key].allTotal20 = allTotal20 || "-";
      compareList[key].allTotal40 = allTotal40 || "-";

      compareList[key].prepaid = prepaid;
      compareList[key].collect = collect;
    }
    return { compareList, minCost };
  };

  // 동일구간 운임비교 요청
  const handleCompareCostData = (callback) => {
    let dataList = {};
    let price = {};
    requestCompareScheduleCost((data) => {
      modal_data.uuid = data.uuid;
      price = data.PriceData;

      for (let i = 0; i < price.length; i++) {
        let item = price[i];
        let key = item.Company;
        dataList[key] = {
          isExistSche: false,
          sm_FWD_NO: key,
          blLOGO: comp_list_obj[key].blLOGO,
          blCompNM: comp_list_obj[key].blCompNM,
          blCompNM_KO: comp_list_obj[key].blCompNM_KO,
        };

        if (modal_data.comp_cost.hasOwnProperty(key)) {
          dataList[key] = {
            ...dataList[key],
            ...modal_data.comp_cost[key],
          };
        } else {
          dataList[key] = {
            ...dataList[key],
            ...modal_data.default_cost,
          };
        }
      }

      requestCompareSchedule((data) => {
        if (Array.isArray(data) && data.length > 0) {
          data.forEach((item) => {
            let key = item.sm_FWD_NO;
            dataList[key] = {
              ...dataList[key],
              isExistSche: true,
              sm_FWD_NO: item.sm_FWD_NO,
              sm_FCL_LCL: item.sm_FCL_LCL,
              sm_VESSEL: item.sm_VESSEL,
              sm_VOY: item.sm_VOY,
              sm_DOC_CLOSE: item.sm_DOC_CLOSE,
              sm_CARGO_CLOSE: item.sm_CARGO_CLOSE,
              sm_DEPARTURE_DATE: item.sm_DEPARTURE_DATE,
              sm_ARRIVE_DATE: item.sm_ARRIVE_DATE,
              blLOGO: comp_list_obj[key].blLOGO,
              blCompNM: comp_list_obj[key].blCompNM,
              blCompNM_KO: comp_list_obj[key].blCompNM_KO,
            };
          });
        }

        let { compareList, minCost } = calcCost(dataList, price);
        compareList = Object.values(compareList);
        modal_data["compareList"] = compareList;
        modal_data["minCost"] = minCost;
        callback && callback(compareList);
      });
    });
  };

  // 스케줄 항공 정보
  const requestScheduleAirData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_select.php",
      msgID: msgID,
      data: {},
      sm_IDX: idx,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length > 0) {
          callback(newData["returnData"][0]);
        }
      }
    });
  };

  // 항공스케줄 데이터 정리
  const handleScheduleData = (dataList) => {
    let tempList = [];

    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i];
      for (let key in data) {
        if (key.startsWith("sm_INFO")) {
          if (data[key]) {
            let splitData = data[key].split("|");
            let sm_INFO = {
              FLIGHT_NAME: "",
              AIR_FREIGHT_NO: "",
              AIR_FREIGHT_MEMO: "",
              DEPARTURE_TIME: "",
              AIRRIVE_TIME: "",
            };

            sm_INFO["FLIGHT_NAME"] = splitData[0];

            if (data.sm_VESSEL) {
              sm_INFO["AIR_FREIGHT_NO"] = data.sm_VESSEL;
            }

            for (let i = 0; i < splitData.length; i++) {
              let data = splitData[i];
              if (i === 0) {
                sm_INFO["FLIGHT_NAME"] = splitData[0];
                continue;
              }

              if (data.length === 4) {
                sm_INFO["AIR_FREIGHT_NO"] = data;
                continue;
              }
              if (data.indexOf("/") > -1) {
                let flightTime = data;
                let flightTimeSplit = undefined;
                if (flightTime.indexOf("+")) {
                  flightTime = flightTime.split("+")[0];
                }
                flightTimeSplit = flightTime.split("/");
                let depTime = flightTimeSplit[0].split("");
                let airTime = flightTimeSplit[1].split("");
                sm_INFO[
                  "DEPARTURE_TIME"
                ] = `${depTime[0]}${depTime[1]}:${depTime[2]}${depTime[3]}`;
                sm_INFO[
                  "AIRRIVE_TIME"
                ] = `${airTime[0]}${airTime[1]}:${airTime[2]}${airTime[3]}`;
              } else {
                if (sm_INFO["AIR_FREIGHT_MEMO"] === "") {
                  sm_INFO["AIR_FREIGHT_MEMO"] = data;
                } else {
                  sm_INFO[
                    "AIR_FREIGHT_MEMO"
                  ] = `${sm_INFO["AIR_FREIGHT_MEMO"]} \n* ${data}`;
                }
              }
            }

            data[key] = sm_INFO;
          } else {
            data[key] = null;
          }
        }
      }

      let temp = { ...data };
      tempList.push(temp);
    }

    return tempList;
  };

  // 항공 주간데이터 get
  const requestScheduleAirDetailData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_sub_select.php",
      msgID: msgID,
      data: {},
      sm_DEPARTURE_DATE: modal_data.sm_DEPARTURE_DATE,
      sm_FWD_NO: modal_data.sm_FWD_NO,
      sm_PROVIDER_CODE: modal_data.sm_PROVIDER_CODE,
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
      sm_CARGO_YN: modal_data.sm_CARGO_YN,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length > 0) {
          let data_list = handleScheduleData(newData["returnData"]);
          callback(data_list[0]);
        }
      }
    });
  };

  //항공운임 get
  const requestScheduleAirPrice = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_price_air_select.php",
      msgID: msgID,
      data: {},
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        let data = {};
        if (newData["ret"] && newData["returnData"].length > 0) {
          data = newData["returnData"][0];
        }
        callback(data);
      }
    });
  };

  const requestUserReserve = (data) => {
    let exp_imp = modal_data.sm_EXP_IMP;
    let type = modal_data.sm_FCL_LCL;

    let customData = {
      ...data,
      COMP_NM: comp_list_obj.hasOwnProperty(data.sm_FWD_NO)
        ? comp_list_obj[data.sm_FWD_NO].blCompNM_KO
        : data.sm_FWD_NM || data.sm_FWD_NO,
      sm_POL_NAME: modal_data.sm_POL_NAME,
      sm_POD_NAME: modal_data.sm_POD_NAME,
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
    };

    let costData = compareData.find(
      (item) => item.sm_FWD_NO === modal_data.sm_FWD_NO
    );
    if (costData && !customData.hasOwnProperty("prepaid")) {
      customData = {
        ...customData,
        ...costData,
      };
    }

    customData = util.buddibleEncrypt(JSON.stringify(customData));
    localStorage.setItem("logiwork_booking_info", customData);

    window.open(
      `/freiscop/ScheduleLogiWorkReserveMemo/${exp_imp}/${type}`,
      "schedule_reserve",
      "width=600, height=800"
    );
  };

  // 담당자 연결
  const requestEnquiry = (date) => {
    let msg = `해당 스케줄에 대해 담당자에게 문의하시겠습니까?`;
    if (type === "air") {
      const selected_date = util.getDateToFormat(+date, "YYYY-MM-DD");
      msg = `[${selected_date} ${modal_data.sm_ORIGIN}->${modal_data.sm_DEST}] 항공스케줄에 대해 영업담당자에게 문의하시겠습니까?`;
    }
    codeList.Modal.current.confirm(msg, (ret) => {
      if (ret) {
        if (!login_info) {
          codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
          return;
        }
        // todo 담당자 연결 일시정지 추후 재확인 필요
        localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
        //`${managerCompCD}|${managerID}`
        window.open("/LogiTalk", "LogiTalk", "_blank");
      }
    });
  };

  const openCFSPopup = (sm_CFS) => {
    window.open(
      `/freiscop/storeMall/${sm_CFS}`,
      "logiwork_CFS",
      "width=1200, height=800"
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && modal_data !== null && (
        <div className="p-3 bg-white">
          <div className="mb-2 pb-2 border-bottom d-flex flex-row justify-content-between">
            <p className="h5 font-weight-bold mr-3 p-0 m-0 flex-fill">
              {modal_data.sm_FCL_LCL} 운임 안내
            </p>

            {modal_data.sm_FCL_LCL === "FCL" && (
              <div>
                <div className="btn-group">
                  <button
                    className={`btn btn-sm ${
                      !isShowCompare ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setIsShowCompare(false)}
                  >
                    운임 상세내역
                  </button>
                  <button
                    className={`btn btn-sm ${
                      isShowCompare ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setIsShowCompare(true)}
                  >
                    동일구간 운임비교
                  </button>
                </div>
              </div>
            )}
          </div>
          {type !== "air" && !isShowCompare && (
            <ScheduleInfo
              requestEnquiry={requestEnquiry}
              requestUserReserve={requestUserReserve}
            />
          )}
          {type === "air" && <ScheduleAirInfo />}
          {modal_data.sm_FCL_LCL === "LCL" && <CostLcl />}
          {modal_data.sm_FCL_LCL === "FCL" && (
            <CostFcl
              isFrei={isFrei}
              loginInfo={login_info}
              isShowCompare={isShowCompare}
              compareData={compareData}
              setCompareData={setCompareData}
              loadText={loadText}
              requestUserReserve={requestUserReserve}
            />
          )}
          {type === "air" && <CostAir />}
          {type !== "air" && !isShowCompare && (
            <EtcInfo
              requestUserReserve={requestUserReserve}
              requestEnquiry={requestEnquiry}
              openCFSPopup={openCFSPopup}
              isFrei={isFrei}
            />
          )}
          {type === "air" && (
            <EtcInfoAir requestEnquiry={requestEnquiry} realTime={realTime} />
          )}
        </div>
      )}
    </>
  );
}

const ScheduleInfo = ({ requestEnquiry, requestUserReserve }) => {
  let comp_type = "콘솔사";
  if (modal_data.sm_FCL_LCL === "FCL") {
    comp_type = "선사";
  }

  return (
    <>
      {modal_data.sm_FCL_LCL === "FCL" && (
        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
          <p className="font-weight-bold">※ 스케줄 정보</p>
          <div className="text-right">
            <button
              className="btn_1 py-1 px-2 font-size-08"
              onClick={() => requestEnquiry(modal_data.sm_UID)}
            >
              문의하기
            </button>

            <button
              className="btn_1 py-1 px-2 font-size-08 ml-2"
              onClick={() => requestUserReserve(modal_data)}
            >
              BOOKING 하기
            </button>
          </div>
        </div>
      )}

      <div className="mb-3">
        <div className="d-flex flex-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden">
          <div className="border-top border-right flex-grow-1">
            <div
              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
              style={{ minWidth: 100 }}
            >
              {comp_type}
            </div>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ height: 55 }}
            >
              {modal_data.COMP_LOGO ? (
                <div
                  style={{
                    backgroundImage: `url('${
                      modal_data.COMP_LOGO.split("?")[0]
                    }')`,
                    backgroundPosition: "50% 50%",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",

                    width: "50px",
                    height: "50px",
                  }}
                />
              ) : (
                <LogoSvg width={"60px"} />
              )}
            </div>
          </div>
          {/*
          <div className="border-top border-right flex-grow-1">
            <div
              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
              style={{ minWidth: 100 }}
            >
              스케쥴 No
            </div>
            <div className="p-2 text-center">{modal_data.sm_UID}</div>
          </div>
        */}
          <div className="border-top border-right flex-grow-1">
            <div
              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
              style={{ minWidth: 100 }}
            >
              FCL/LCL
            </div>
            <div className="p-2 text-center">{modal_data.sm_FCL_LCL}</div>
          </div>
          <div className="border-top border-right flex-grow-1">
            <div
              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
              style={{ minWidth: 100 }}
            >
              VESSEL/VOY
            </div>

            <div className="p-2 text-center">
              <p>{modal_data.sm_VESSEL}</p>
              <p>{modal_data.sm_VOY}</p>
            </div>
          </div>
          <div className="flex-grow-1 d-flex flex-row flex-wrap">
            <div className="border-top border-right flex-grow-1">
              <div
                className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                style={{ minWidth: 100 }}
              >
                POL
              </div>
              <div className="p-2 text-center">
                <p className="font-weight-bold">{modal_data.sm_POL_NAME}</p>
                <p className="text-danger">{modal_data.sm_DEPARTURE_DATE}</p>
              </div>
            </div>
            <div className="flex-grow-1">
              <div
                className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                style={{ minWidth: 100 }}
              >
                POD
              </div>
              <div className="p-2 text-center">
                <p className="font-weight-bold">{modal_data.sm_POD_NAME}</p>
                <p className="text-danger">{modal_data.sm_ARRIVE_DATE}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ScheduleAirInfo = () => {
  return (
    <div className="d-flex flex-column flex-xl-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden mb-3">
      <div className="flex-grow-1 d-flex flex-column flex-md-row">
        <div className="border-top border-right flex-grow-1">
          <div
            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
            style={{ minWidth: 100 }}
          >
            항공사
          </div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{ height: 55 }}
          >
            <div
              style={{
                minHeight: 30,
                width: 80,
                background: `url(https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/chatRoom/Z0001/buddible@buddible.com/20230420111829_IMG_1129.jpg) 50% 50% / contain no-repeat`,
              }}
            />
          </div>
        </div>
        <div className="border-top border-right flex-grow-1">
          <div
            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
            style={{ minWidth: 100 }}
          >
            공급사
          </div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{ height: 55 }}
          >
            <div
              style={{
                minHeight: 30,
                width: 80,
                background: `url(https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/chatRoom/Z0001/buddible@buddible.com/20230420111829_IMG_1128.jpg) 50% 50% / contain no-repeat`,
              }}
            />
          </div>
        </div>
        <div className="border-top border-right flex-grow-1">
          <div
            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
            style={{ minWidth: 100 }}
          >
            항명
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: 55 }}
          >
            <p>{modal_data.FLIGHT_NAME}</p>
            <p>{modal_data.AIR_FREIGHT_NO}</p>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 d-flex flex-column flex-md-row">
        <div className="border-top border-right flex-grow-1">
          <div
            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
            style={{ minWidth: 100 }}
          >
            출발시간
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: 55 }}
          >
            <p className="font-weight-bold">{modal_data.sm_ORIGIN}</p>
            <p className="font-weight-bold text-danger">
              {modal_data.sm_POL_CODE} {modal_data.DEPARTURE_TIME}
            </p>
          </div>
        </div>
        {/*
                        <div className="border-top border-right flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            소요시간
                          </div>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <p className="text-center mt-1">14h 00m</p>
                          </div>
                        </div>
                        */}
        <div className="flex-grow-1">
          <div
            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
            style={{ minWidth: 100 }}
          >
            도착시간
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: 55 }}
          >
            <p className="font-weight-bold">{modal_data.sm_DEST}</p>
            <p className="font-weight-bold text-danger">
              {modal_data.sm_POD_CODE} {modal_data.AIRRIVE_TIME}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const CostLcl = () => (
  <div className="mb-3">
    <p className="font-weight-bold mb-1">※ GENERAL RATE FOR FOWARDER</p>

    <div className="container-fluid m-0 p-0 text-center">
      <div className="row m-0 p-0 border bg-light font-weight-bold">
        <div className="col-5 m-0 p-0 border-right">
          <p className="p-2">CATEGORY</p>
        </div>
        <div className="col-2 m-0 p-0 border-right">
          <p className="p-2">PER</p>
        </div>
        {/*
                  <div className="col-3 m-0 p-0 border-right">
                    <p className="p-2">CURRENCY</p>
                  </div>*/}
        <div className="col-5 m-0 p-0">
          <p className="p-2">AMOUNT</p>
        </div>
      </div>
      {modal_data.price_list.map((price, index) => {
        const key_start = price.SP_KND ? "SP_" : "SPD_";

        if (
          modal_data.sm_FWD_NO === "GLSN" &&
          price.SP_KND !== "OCEAN FREIGHT" &&
          price.SP_KND !== "SURCHARGE"
        ) {
          return null;
        }

        return (
          <div
            key={index}
            className={`row m-0 p-0 border-left border-right border-bottom ${
              key_start === "SP_" ? "font-weight-bold" : ""
            }`}
          >
            <div className="col-5 m-0 p-0 border-right bg-light">
              <p className="p-2">{price[key_start + "KND"]}</p>
            </div>
            <div className="col-2 m-0 p-0 border-right">
              <p className="p-2">{price[key_start + "DIV"]}</p>
            </div>
            {/*<div className="col-3 m-0 p-0 border-right">
                        <p className="p-2">{price["SPD_CUR"]}</p>
                      </div>*/}
            <div className="col-5 m-0 p-0">
              <p className="p-2 text-right">
                {price[key_start + "CUR"]}{" "}
                {util.addCommas(price[key_start + "PRICE"])}
              </p>
            </div>
          </div>
        );
      })}
    </div>
    <p className="mt-1 font-weight-bold">
      *공지시점과 견적시점 간 차이로 인해 일부 차이가 발생할 수 있습니다
    </p>
    <p className="text-danger mt-1">
      ※운임이 공란인 경우 문의주시면 즉시 안내 드리겠습니다.
    </p>
  </div>
);

const CostAir = () => {
  return (
    <>
      <div className="row m-0">
        <div className="col-12 m-0 mb-1 p-0">
          <p className="font-weight-bold">※ 기본운임정보</p>
        </div>
        <div className="col-12 m-0 mb-2 p-0">
          <div className="d-flex flex-column flex-sm-row flex-wrap border-bottom border-left rounded overflow-hidden">
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                적용타입
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_TYPE || "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                통화
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_MONEY_KIND || "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                최소금액
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_MIN
                  ? util.addCommas(modal_data.SPA_COST_MIN)
                  : "-"}{" "}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                - 45K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_MINUS_45K
                  ? util.addCommas(modal_data.SPA_COST_MINUS_45K)
                  : "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                + 45K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_PLUS_45K
                  ? util.addCommas(modal_data.SPA_COST_PLUS_45K)
                  : "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                + 100K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_100K
                  ? util.addCommas(modal_data.SPA_COST_100K)
                  : "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                + 300K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_300K
                  ? util.addCommas(modal_data.SPA_COST_300K)
                  : "-"}{" "}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                + 500K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_500K
                  ? util.addCommas(modal_data.SPA_COST_500K)
                  : "-"}
              </div>
            </div>
            <div className="border-top border-right flex-grow-1">
              <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                + 1000K
              </div>
              <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                {modal_data.SPA_COST_1000K
                  ? util.addCommas(modal_data.SPA_COST_1000K)
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 ">
        <div className="col-12 m-0 p-0">
          <p className="font-weight-bold">※ 기타운임정보</p>
        </div>
        <div className="col-12 m-0 mb-2 p-0 border">
          <div className="row m-0 p-0 text-center bg-light font-weight-bold">
            <div className="col-2 m-0 p-2 border-bottom border-right">운임</div>
            <div className="col-4 m-0 p-2 border-bottom border-right">
              운임명
            </div>
            <div className="col-2 m-0 p-2 border-bottom border-right">
              적용타입
            </div>
            <div className="col-2 m-0 p-2 border-bottom border-right">
              Min Amount
            </div>
            <div className="col-2 m-0 p-2 border-bottom">금액</div>
          </div>
          <div className="row m-0 p-0 text-center">
            <div className="col-2 m-0 p-2 bg-light border-bottom border-right">
              CGC
            </div>
            <div className="col-4 m-0 p-2 bg-light border-bottom border-right">
              CGC CHARGE
            </div>
            <div className="col-2 m-0 p-2 border-bottom text-right border-right">
              B/L
            </div>
            <div className="col-2 m-0 p-2 border-bottom text-right border-right">
              0
            </div>
            <div className="col-2 m-0 p-2 border-bottom text-right">3,000</div>
          </div>
          <div className="row m-0 p-0 text-center">
            <div className="col-2 m-0 p-2 bg-light border-right">FSC</div>
            <div className="col-4 m-0 p-2 bg-light border-right">
              FUEL SURCHARGE
            </div>

            <div className="col-2 m-0 p-2 text-right border-right">
              {modal_data.SPA_DISTANCE
                ? FSC_COST[modal_data.SPA_DISTANCE].type
                : "-"}
            </div>
            <div className="col-2 m-0 p-2 text-right border-right">
              {modal_data.SPA_DISTANCE
                ? util.addCommas(FSC_COST[modal_data.SPA_DISTANCE].minAmount)
                : "-"}
            </div>
            <div className="col-2 m-0 p-2 text-right">
              {modal_data.SPA_DISTANCE
                ? util.addCommas(FSC_COST[modal_data.SPA_DISTANCE].cost)
                : "-"}
            </div>
          </div>
          {SCC_COST.hasOwnProperty(modal_data.SPA_COUNTRY) && (
            <div className="row m-0 p-0 border-top text-center">
              <div className="col-2 m-0 p-2 bg-light border-right">SCC</div>
              <div className="col-4 m-0 p-2 bg-light border-right">
                SECURITY CHARGE
              </div>
              <div className="col-2 m-0 p-2 text-right border-right">
                {SCC_COST[modal_data.SPA_COUNTRY].type}
              </div>
              <div className="col-2 m-0 p-2 text-right border-right">
                {util.addCommas(SCC_COST[modal_data.SPA_COUNTRY].minAmount)}
              </div>
              <div className="col-2 m-0 p-2 text-right">
                {util.addCommas(SCC_COST[modal_data.SPA_COUNTRY].cost)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const EtcInfo = ({
  requestEnquiry,
  openCFSPopup,
  isFrei,
  requestUserReserve,
}) => (
  <div className="row m-0 mb-3 p-0">
    <>
      <div className="col-8 m-0 p-0">
        <div>
          <p className="font-weight-bold">· Booking 및 마감시간 문의</p>
          <p className="ml-2">
            김호승 본부장 (032-751-9870, hskim@bstc.kr)
            {/*{modal_data.bookInfo.OUTPBU} ({modal_data.bookInfo.OUTPBT}
                      ,{modal_data.bookInfo.OUTPBE})*/}
          </p>
        </div>
        <div className="mt-2">
          <p className="font-weight-bold">· B/L 및 EDI신고 문의</p>
          <p className="ml-2">
            {modal_data.sm_FWD_NO === "NSSL" || modal_data.sm_FWD_NO === "NSS"
              ? "이혜인 사원 (02-772-8897, elielee@namsung.co.kr)"
              : "-등록된 담당자정보가 존재하지 않습니다."}
            {/*{modal_data.bookInfo.OUTPDU} ({modal_data.bookInfo.OUTPDT}
                      ,{modal_data.bookInfo.OUTPDE})*/}
          </p>
        </div>

        <div className="mt-2">
          <p className="font-weight-bold">· CFS 정보</p>
          {modal_data.smName ? (
            <>
              <p className="ml-2">{modal_data.smName}</p>
              <p className="ml-2">{modal_data.smAddrFull}</p>
              <p className="ml-2">{modal_data.smTel}</p>
              <button
                onClick={() => openCFSPopup(modal_data.sm_CFS)}
                className="ml-2 btn btn-sm btn-secondary font-size-06"
              >
                상세보기
              </button>
            </>
          ) : (
            <p>- 등록된 CFS 정보가 존재하지 않습니다.</p>
          )}
        </div>
      </div>
      <div className="col-4 m-0 p-0">
        {isFrei && (
          <p className="font-weight-bold text-right">
            Booking 가능 여부 : <span className={"text-primary"}>가능</span>
          </p>
        )}
      </div>
    </>
    <div className="col-12 m-0 p-0 text-right">
      <button
        className="btn_1"
        onClick={() => requestEnquiry(modal_data.sm_UID)}
      >
        문의하기
      </button>

      {modal_data.sm_FCL_LCL === "FCL" && (
        <button
          className="btn_1 ml-2"
          onClick={() => requestUserReserve(modal_data)}
        >
          BOOKING 하기
        </button>
      )}
    </div>
  </div>
);

const EtcInfoAir = ({ requestEnquiry, realTime }) => {
  const isBooking = +today < +realTime;
  return (
    <>
      <div className="row m-0 p-0 mb-2">
        <div className="col-auto flex-fill m-0 p-0">
          <p className="font-weight-bold">· 예약 담당자 정보</p>
          <p className="text-secondary ml-2 mb-2">
            김호승 본부장(<a href="tel:032-751-9870">032-751-9870</a>,{" "}
            <a href="mailto:hskim@bstc.kr">hskim@bstc.kr</a>)
          </p>
          <p className="font-weight-bold">· 공급사 담당자 정보</p>
          <p className="text-secondary ml-2">
            김미경 과장(
            <a href="mailto:scl@sclogistics.co.kr">scl@sclogistics.co.kr</a>)
          </p>
        </div>
        <div className="col-auto m-0 p-0">
          <p className="font-weight-bold">
            · Booking 가능여부 :{" "}
            <span className={isBooking ? "text-primary" : "text-danger"}>
              {isBooking ? "가능" : "불가"}
            </span>
          </p>
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-auto flex-fill m-0 p-0">
          <p className="font-weight-bold">· 반입지 정보</p>
          <p className="text-secondary ml-2">(주)더블유코리아</p>
          <p className="text-secondary ml-2">
            인천시 중구 공항동로 296번길 98-30 범한판토스 내 27-28 GATE
          </p>
          <p className="text-secondary ml-2">032-744-7404</p>
        </div>
      </div>

      <div className="col-12 m-0 p-0 text-right">
        <button
          className="btn_1"
          onClick={() => {
            requestEnquiry(realTime);
          }}
        >
          영업담당자와 대화하기
        </button>
      </div>
    </>
  );
};

const CostFcl = ({
  loadText,
  loginInfo,
  isShowCompare,
  compareData,
  setCompareData,
  requestUserReserve,
}) => {
  const [botTalk, setBotTalk] = useState("");
  const [isTalking, setIsTalking] = useState(false);
  const [sortModel, setSortModel] = useState({
    field: "allTotal20",
    sort: "asc",
  });
  const [isLoading, setIsLoading] = useState(true);

  const isNSS =
    modal_data.COMP_CD === "NSSL" ||
    modal_data.COMP_CD === "NSS" ||
    modal_data.sm_FWD_NO === "NSSL" ||
    modal_data.sm_FWD_NO === "NSS";
  const isSNK = modal_data.COMP_CD === "SKR" || modal_data.sm_FWD_NO === "SKR";
  const isHAS = modal_data.COMP_CD === "HAS" || modal_data.sm_FWD_NO === "HAS";
  const isPCS = modal_data.COMP_CD === "PCS" || modal_data.sm_FWD_NO === "PCS";
  const isDJS = modal_data.COMP_CD === "DJS" || modal_data.sm_FWD_NO === "DJS";
  const isCostRowDisplay = !isNSS && !isSNK && !isHAS && !isPCS && !isDJS;

  useEffect(() => {
    if (botTalk === "" && isShowCompare && compareData) {
      setIsLoading(false);
      getAIAnalysis();
    }
  }, [isShowCompare, compareData]);

  useEffect(() => {
    if (Array.isArray(compareData)) {
      sortCompareData(compareData);
    }
  }, [sortModel]);

  const sortCompareData = (data) => {
    let dataList = [...data];
    dataList.sort((a, b) => {
      return a[sortModel.field] - b[sortModel.field];
    });

    if (sortModel.sort === "desc") {
      dataList.reverse();
    }

    setCompareData(dataList);
  };

  async function getAIAnalysis() {
    if (isTalking) return;
    setIsTalking(true);
    try {
      let url = "/ai_service/api/ai_price";
      //let url = "/ai_service/api/schedule";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmd: "get",
          input: "운임비교",
          MSG_UID: util.makeUUIDv4(),
          SESSION: util.makeUUIDv4(),
          COMP_CD: loginInfo ? loginInfo.comp_cd : "",
          USER_ID: loginInfo ? loginInfo.user_id : "",
          PRICE_UID: modal_data.uuid,
          COMP_LVL: loginInfo.COMP_LVL,
          DOC_CLOSE: modal_data.sm_DOC_CLOSE,
          POL_CODE: modal_data.sm_POL_CODE,
          POD_CODE: modal_data.sm_POD_CODE,
        }),
      });

      const reader = response.body.getReader();

      let result = "";

      const decodeStream = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setBotTalk(result);
          setIsTalking(false);
          break;
        }
        result += decodeStream.decode(value);
        setBotTalk(result);
      }

      //const data = await response.json();
      if (response.status !== 200) {
        console.log(`Request failed with status ${response.status}`);
        setBotTalk("");
      }
      //setTextInput("");
    } catch (error) {
      console.log("error", error);
      setIsTalking(false);
      // alert(error.message);
    }
  }

  const handleSort = (key) => {
    let sortData = {
      field: key,
      sort: sortModel.sort === "asc" ? "desc" : "asc",
    };
    setSortModel(sortData);
  };

  let prepaid = [];
  let collect = [];

  if (isNSS || isPCS) {
    modal_data.SurchargeInfo.forEach((item) => {
      let data = {
        kind: item.surknd,
        base: "",
        amount20: "",
        amount40: "",
      };

      if (item.dc20) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.dc20)}`;
      } else if (item.wfg20) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.wfg20)}`;
      } else if (item.wfglc) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.wfglc)}`;
      }

      if (item.dc40) {
        data.amount40 = `${item.wfgcur} ${util.addCommas(item.dc40)}`;
      } else if (item.wfg40) {
        data.amount40 = `${item.wfgcur} ${util.addCommas(item.wfg40)}`;
      }

      if (item.dc20 || item.wfg20 || item.wfglc) {
        if (item.surpcg === "C") {
          collect.push(data);
        } else if (item.surpcg === "P") {
          prepaid.push(data);
        }
      }
    });
  } else if (isSNK || isHAS) {
    //장금상선, 흥아라인 운임 분류 로직
    const snkCost = modal_data.snkCost;
    snkCost.forEach((item) => {
      const data = {
        kind: item.col0,
        base: item.col2,
        amount20: item.col4,
        amount40: item.col5,
      };

      if (item.col1 === "Prepaid") {
        prepaid.push(data);
      } else if (item.col1 === "Collect") {
        collect.push(data);
      }
    });
  } else if (isDJS) {
    for (let currency in modal_data.CoastList.Prepaid) {
      for (let kind in modal_data.CoastList.Prepaid[currency]) {
        prepaid.push({
          kind: kind,
          base: "",
          amount20:
            currency +
            " " +
            util.addCommas(modal_data.CoastList.Prepaid[currency][kind]),
          amount40: false,
        });
      }
    }
    for (let currency in modal_data.CoastList.Collect) {
      for (let kind in modal_data.CoastList.Collect[currency]) {
        collect.push({
          kind: kind,
          base: "",
          amount20:
            currency +
            " " +
            util.addCommas(modal_data.CoastList.Collect[currency][kind]),
          amount40: false,
        });
      }
    }
  }

  return (
    <>
      <div className="mb-3">
        {!isShowCompare ? (
          <>
            <div>
              <p className="mt-3 font-weight-bold">※ Dry Container 기준</p>
              <div className="container-fluid m-0 p-0 text-center border">
                <div className="row m-0 p-0 border-bottom bg-light">
                  <div className="col-4 m-0 p-0 py-3 border-right">Kind</div>
                  <div className="col-8 m-0 p-0">
                    <div className="row m-0 p-0 py-1 border-bottom">
                      <div className="col-12">Amount</div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 py-1 border-right">20</div>
                      <div className="col-6 m-0 py-1">40</div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-4 m-0 p-0 py-1  bg-light border-right">
                    OCF
                  </div>
                  <div className="col-8 m-0 p-0 text-right">
                    <div className="row m-0 p-0 h-100">
                      <div className="col m-0 py-1 border-right">
                        USD{" "}
                        {modal_data["SP_20_INCH"] &&
                          util.addCommas(modal_data["SP_20_INCH"])}
                      </div>
                      <div className="col m-0 py-1">
                        USD{" "}
                        {modal_data["SP_20_INCH"] &&
                          util.addCommas(modal_data["SP_40_INCH"])}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="mt-1 mt-3">Prepaid : {prepaid.length}</p>

                <div className="container-fluid m-0 p-0 text-center">
                  <div className="row m-0 p-0 border bg-light">
                    <div
                      className={`${
                        isSNK || isHAS ? "col-5" : " col-6"
                      } col-auto m-0 flex-grow-1 py-3 bg-light border-right`}
                    >
                      Kind
                    </div>
                    {(isSNK || isHAS) && (
                      <div className="col-2 m-0 p-0 py-3 border-right">
                        Base
                      </div>
                    )}
                    <div
                      className={`${
                        isSNK || isHAS ? "col-5" : " col-6"
                      } m-0 p-0`}
                    >
                      <div className="row m-0 p-0 py-1 border-bottom">
                        <div className="col-12">Amount</div>
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col m-0 py-1 border-right">20</div>
                        <div className="col m-0 py-1">40</div>
                      </div>
                    </div>
                  </div>
                  {prepaid &&
                    prepaid.length > 0 &&
                    prepaid.map((item, index) => {
                      if (!item.amount20 || !item.amount40) return null;
                      return (
                        <div
                          className="row border-left border-right border-bottom m-0 p-0"
                          key={index}
                        >
                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 flex-grow-1 py-1 bg-light border-right`}
                          >
                            {item.kind}
                          </div>
                          {(isSNK || isHAS) && (
                            <div className="col-2 m-0 py-1 border-right">
                              {item.base}
                            </div>
                          )}

                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 p-0`}
                          >
                            <div className="row h-100 m-0 p-0">
                              <div className="col-6 flex-grow-1 m-0 py-1 border-right text-right">
                                {item.amount20}
                              </div>
                              <div className="col-6 m-0 py-1 text-right">
                                {item.amount40}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {prepaid.length > 0 &&
                    prepaid.map((item, index) => {
                      if (!(item.amount20 && !item.amount40)) return null;
                      return (
                        <div
                          className={`row border m-0 ${
                            isDJS ? "border-top-0" : "mt-1"
                          } p-0`}
                          key={index}
                        >
                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 flex-grow-1 py-1 bg-light border-right`}
                          >
                            {item.kind}
                          </div>
                          {(isSNK || isHAS) && (
                            <div className="col-2 m-0 py-1 border-right">
                              {item.base}
                            </div>
                          )}

                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 p-0`}
                          >
                            <div className="row h-100 m-0 p-0">
                              <div className="col-12 flex-grow-1 m-0 py-1 text-right">
                                {item.amount20}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div>
                <p className="mt-1 mt-3">
                  Collect : {collect && collect.length}
                </p>

                <div className="container-fluid m-0 p-0 text-center">
                  <div className="row m-0 p-0 border bg-light">
                    <div
                      className={`${
                        isSNK || isHAS ? "col-5" : " col-6"
                      } col-auto m-0 flex-grow-1 py-3 bg-light border-right`}
                    >
                      Kind
                    </div>
                    {(isSNK || isHAS) && (
                      <div className="col-2 m-0 p-0 py-3 border-right">
                        Base
                      </div>
                    )}
                    <div
                      className={`${
                        isSNK || isHAS ? "col-5" : " col-6"
                      } m-0 p-0`}
                    >
                      <div className="row m-0 p-0 py-1 border-bottom">
                        <div className="col-12">Amount</div>
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col m-0 py-1 border-right">20</div>
                        <div className="col m-0 py-1">40</div>
                      </div>
                    </div>
                  </div>
                  {collect &&
                    collect.length > 0 &&
                    collect.map((item, index) => {
                      if (!item.amount20 || !item.amount40) return null;
                      return (
                        <div
                          className="row border-left border-right border-bottom m-0 p-0"
                          key={index}
                        >
                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 flex-grow-1 py-1 bg-light border-right`}
                          >
                            {item.kind}
                          </div>
                          {(isSNK || isHAS) && (
                            <div className="col-2 m-0 py-1 border-right">
                              {item.base}
                            </div>
                          )}

                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 p-0`}
                          >
                            <div className="row h-100 m-0 p-0">
                              <div className="col-6 flex-grow-1 m-0 py-1 border-right text-right">
                                {item.amount20}
                              </div>
                              <div className="col-6 m-0 py-1 text-right">
                                {item.amount40}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {collect &&
                    collect.length > 0 &&
                    collect.map((item, index) => {
                      if (!(item.amount20 && !item.amount40)) return null;
                      return (
                        <div
                          className={`row border m-0 ${
                            isDJS ? "border-top-0" : "mt-1"
                          } p-0`}
                          key={index}
                        >
                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 flex-grow-1 py-1 bg-light border-right`}
                          >
                            {item.kind}
                          </div>
                          {(isSNK || isHAS) && (
                            <div className="col-2 m-0 py-1 border-right">
                              {item.base}
                            </div>
                          )}

                          <div
                            className={`${
                              isSNK || isHAS ? "col-5" : " col-6"
                            } m-0 p-0`}
                          >
                            <div className="row h-100 m-0 p-0">
                              <div className="col-12 flex-grow-1 m-0 py-1 text-right">
                                {item.amount20}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <p className="mt-2 text-danger">
              ※ 제시 운임은 우대운송계약(SC)을 적용하지 않은 General Rate입니다.
            </p>
          </>
        ) : isLoading ? (
          <div className="p-5 bg-light rounded-lg">
            <p className="mb-0 text-center text-secondary font-weight-bold">
              {loadText}...
              <i className="fa-solid fa-gear rotate" />
            </p>
          </div>
        ) : (
          <>
            <div className="mb-3">
              <div className="mb-3">
                <p className="h6 font-weight-bold pl-2">
                  <span className="text-info font-weight-900">
                    <i className="fa-solid fa-robot" /> AI
                  </span>
                  가 분석한 운임비교 안내
                </p>
                <div className="bg-light p-3 rounded-lg text-dark mb-0 markup_div">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm, rehypeHighlight, remarkRehype]}
                  >
                    {botTalk ? botTalk : ""}
                  </ReactMarkdown>

                  {isTalking && (
                    <span className="text-secondary">
                      ...
                      <i className="fa-solid fa-gear rotate" />
                    </span>
                  )}
                </div>
              </div>
              <p className="mt-3 h6 font-weight-bold">· 20FT Container</p>
              <div className="container-fluid m-0 p-0 text-center border">
                <CompareCostHeader
                  sortModel={sortModel}
                  handleSort={handleSort}
                />
                {isCostRowDisplay && (
                  <CompareCostRow
                    COMP_LOGO={modal_data.COMP_LOGO}
                    OCF={modal_data["SP_20_INCH"]}
                    minCost={modal_data.minCost}
                    isLarge={false}
                    isLast={false}
                  />
                )}
                {compareData &&
                  compareData.map((item, index) => {
                    const isLast = compareData.length - 1 === index;

                    return (
                      <CompareCostRow
                        key={`${item.sm_FWD_NO}_20`}
                        COMP_LOGO={item.blLOGO}
                        OCF={item["SP_20_INCH"]}
                        minCost={modal_data.minCost}
                        item={item}
                        index={index}
                        isLarge={false}
                        isLast={isLast}
                      />
                    );
                  })}
              </div>
              <p className="mt-3 h6 font-weight-bold">· 40FT Container</p>
              <div className="container-fluid m-0 p-0 text-center border mb-3">
                <CompareCostHeader
                  sortModel={sortModel}
                  handleSort={handleSort}
                />
                {isCostRowDisplay && (
                  <CompareCostRow
                    COMP_LOGO={modal_data.COMP_LOGO}
                    OCF={modal_data["SP_40_INCH"]}
                    minCost={modal_data.minCost}
                    isLarge={false}
                    isLast={false}
                  />
                )}
                {compareData &&
                  compareData.map((item, index) => {
                    const isLast = compareData.length - 1 === index;
                    return (
                      <CompareCostRow
                        key={`${item.sm_FWD_NO}_40`}
                        COMP_LOGO={item.blLOGO}
                        OCF={item["SP_40_INCH"]}
                        minCost={modal_data.minCost}
                        item={item}
                        index={index}
                        isLarge={true}
                        isLast={isLast}
                      />
                    );
                  })}
              </div>

              <div className="mb-3">
                <p className="mt-3 h6 font-weight-bold">
                  · 예약가능 스케줄 정보
                </p>
                <div className="row m-0 p-0 border bg-info text-white font-weight-bold text-center">
                  <div className="col-2 m-0 p-0 py-2 border-right">선사</div>
                  <div className="col-2 m-0 p-0 py-2 border-right">
                    DOC CLOSE
                    {/*DOC/CARGO CLOSE*/}
                  </div>
                  <div className="col-2 m-0 p-0 py-2 border-right">
                    VESSEL/VOY
                  </div>
                  <div className="col-2 m-0 p-0 py-2 border-right">POL</div>
                  <div className="col-2 m-0 p-0 py-2 border-right">POD</div>
                  <div className="col-2 m-0 p-0 py-2">POD</div>
                </div>
                {isCostRowDisplay && (
                  <CompareCompRow
                    isRepeat={false}
                    item={modal_data}
                    requestUserReserve={requestUserReserve}
                  />
                )}{" "}
                {compareData &&
                  compareData.map((item) => {
                    if (!item.isExistSche) return null;
                    return (
                      <CompareCompRow
                        key={item.sm_FWD_NO}
                        item={{
                          ...item,
                          COMP_LOGO: item.blLOGO,
                          sm_POL_NAME: modal_data.sm_POL_NAME,
                          sm_POD_NAME: modal_data.sm_POD_NAME,
                        }}
                        requestUserReserve={requestUserReserve}
                      />
                    );
                  })}
              </div>
            </div>

            <p className={`h6 text-danger`}>
              ※ 적용환율은 조회 당시 한국은행 매매 기준율을 적용한 값으로 추후
              변경될 수 있습니다.
            </p>
          </>
        )}
      </div>
    </>
  );
};
const CompareCostHeader = ({ sortModel, handleSort }) => (
  <div className="row m-0 p-0 border-bottom bg-light font-weight-bold">
    <div className="col-2 m-0 p-0 py-3 border-right">선사</div>
    <div className="col-10 m-0 p-0">
      <div className="row m-0 p-0">
        <div className="col-6 m-0 p-0 border-right">
          <div className="row m-0 p-0">
            <div className="col-12 m-0 p-0 py-1 border-bottom">항목별 합계</div>
            <div className="col-4 m-0 p-0 py-1 border-right">
              <p>OCF</p>
              <p> </p>
            </div>
            <div className="col-4 m-0 p-0 py-1 border-right">
              <p>Prepaid</p>
              <p> </p>
            </div>
            <div className="col-4 m-0 p-0 py-1">
              <p>Collect</p>
              <p> </p>
            </div>
          </div>
        </div>
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0 bg-secondary text-white">
            <div className="col-12 m-0 p-0 py-1 border-bottom">
              KRW 합산비교
            </div>
            <div
              className="col-4 m-0 p-0 py-1  border-right cursor_pointer"
              onClick={() => handleSort("allTotal20")}
            >
              TOTAL{" "}
              {sortModel.field === "allTotal20" && (
                <i
                  className={` ${
                    sortModel.sort === "asc"
                      ? "fa-solid fa-caret-up"
                      : "fa-solid fa-caret-down"
                  }`}
                />
              )}
            </div>
            <div
              className="col-4 m-0 p-0 py-1  border-right cursor_pointer"
              onClick={() => handleSort("pre20Total")}
            >
              <p>
                Prepaid{" "}
                {sortModel.field === "pre20Total" && (
                  <i
                    className={` ${
                      sortModel.sort === "asc"
                        ? "fa-solid fa-caret-up"
                        : "fa-solid fa-caret-down"
                    }`}
                  />
                )}
              </p>
              <p>+OCF</p>
            </div>
            <div
              className="col-4 m-0 p-0 py-1 cursor_pointer"
              onClick={() => handleSort("col20Total")}
            >
              <p>
                Collect{" "}
                {sortModel.field === "col20Total" && (
                  <i
                    className={` ${
                      sortModel.sort === "asc"
                        ? "fa-solid fa-caret-up"
                        : "fa-solid fa-caret-down"
                    }`}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const CompareCostRow = ({
  COMP_LOGO,
  OCF,
  minCost,
  item = null,
  isLarge,
  isLast = true,
}) => {
  let ocfClass = "";
  let totalClass = "";
  let totalPreClass = "";
  let totalColClass = "";

  if (item) {
    if (isLarge) {
      ocfClass =
        Number(OCF) === Number(minCost.SP_40_INCH)
          ? "text-primary font-weight-900"
          : "";
      totalClass =
        item.allTotal40 === minCost.allTotal40
          ? "text-primary font-weight-900"
          : "";
      totalPreClass =
        item.pre40Total === minCost.pre40Total
          ? "text-primary font-weight-900"
          : "";
      totalColClass =
        item.col40Total === minCost.col40Total
          ? "text-primary font-weight-900"
          : "";
    } else {
      ocfClass =
        Number(OCF) === Number(minCost.SP_20_INCH)
          ? "text-primary font-weight-900"
          : "";
      totalClass =
        item.allTotal20 === minCost.allTotal20
          ? "text-primary font-weight-900"
          : "";
      totalPreClass =
        item.pre20Total === minCost.pre20Total
          ? "text-primary font-weight-900"
          : "";
      totalColClass =
        item.col20Total === minCost.col20Total
          ? "text-primary font-weight-900"
          : "";
    }
  }

  return (
    <div
      className={`row m-0 p-0  ${isLast ? "" : "border-bottom"} ${
        item ? "" : "bg-freiscop-05"
      }`}
    >
      <div className="col-2 m-0 py-2 d-flex flex-column justify-content-center border-right">
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: 55 }}
        >
          {COMP_LOGO ? (
            <div
              style={{
                backgroundImage: `url('${COMP_LOGO.split("?")[0]}')`,
                backgroundPosition: "50% 50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",

                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <LogoSvg width={"60px"} />
          )}
        </div>
      </div>
      <div className="col-10 m-0 p-0 text-right">
        <div className="row m-0 p-0 h-100">
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            <p className={ocfClass}>USD {OCF ? util.addCommas(OCF) : "-"}</p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            {item.prepaid && item.prepaid.length > 0 ? (
              item.prepaid.map((data, index) => {
                let minKey = `prepaid_${isLarge ? "40" : "20"}_${data.key}`;
                let cost = data[isLarge ? "container40" : "container20"];
                let costClass = "";
                if (minCost[minKey] && minCost[minKey] === cost) {
                  costClass = "text-primary font-weight-900 ";
                }

                if (cost && cost > 0) {
                  cost = " " + util.addCommas(cost);
                } else {
                  cost = " -";
                }

                return (
                  <p key={index} className={costClass}>
                    {data.key}
                    {data.key !== "waive" ? " " + cost || " -" : ""}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            {item.collect && item.collect.length > 0 ? (
              item.collect.map((data, index) => {
                let minKey = `collect_${isLarge ? "40" : "20"}_${data.key}`;
                let cost = data[isLarge ? "container40" : "container20"];
                let costClass = "";
                if (minCost[minKey] && minCost[minKey] === cost) {
                  costClass = "text-primary font-weight-900 ";
                }

                if (cost && cost > 0) {
                  cost = " " + util.addCommas(cost);
                } else {
                  cost = " -";
                }

                return (
                  <p key={index} className={costClass}>
                    {data.key}
                    {data.key !== "waive" ? cost : ""}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right bg-light">
            <p className={totalClass}>
              &#8361;{" "}
              {item
                ? isLarge
                  ? util.addCommas(item.allTotal40)
                  : util.addCommas(item.allTotal20)
                : "-"}
            </p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right bg-light">
            <p className={totalPreClass}>
              &#8361;{" "}
              {item
                ? isLarge
                  ? util.addCommas(item.pre40Total)
                  : util.addCommas(item.pre20Total)
                : "-"}
            </p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center bg-light">
            <p className={totalColClass}>
              &#8361;
              {item
                ? isLarge
                  ? util.addCommas(item.col40Total)
                  : util.addCommas(item.col20Total)
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompareCompRow = ({ isRepeat = true, item, requestUserReserve }) => {
  return (
    <div
      className={`row m-0 p-0 border-bottom border-right border-left text-center ${
        isRepeat ? "" : "bg-freiscop-05"
      }`}
    >
      <div className="col-2 m-0 p-0 border-right">
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: 55 }}
        >
          {item.COMP_LOGO ? (
            <div
              style={{
                backgroundImage: `url('${item.COMP_LOGO.split("?")[0]}')`,
                backgroundPosition: "50% 50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",

                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <LogoSvg width={"60px"} />
          )}
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        {" "}
        <div className="p-2 text-center">
          {/*{sm_FCL_LCL}*/}
          <p>
            {item.sm_DOC_CLOSE} ({util.handleGetDate(item.sm_DOC_CLOSE)})
          </p>
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        {" "}
        <div className="p-2 text-center">
          <p className="font-weight-bold">{item.sm_VESSEL}</p>
          <p>{item.sm_VOY}</p>
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        <div className="p-2 text-center">
          <p className="font-weight-bold">{item.sm_POL_NAME}</p>
          <p className="text-danger font-weight-bold">
            {item.sm_DEPARTURE_DATE} (
            {util.handleGetDate(item.sm_DEPARTURE_DATE)})
          </p>
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        <div className="p-2 text-center">
          <p className="font-weight-bold">{item.sm_POD_NAME}</p>
          <p className="text-danger font-weight-bold">
            {item.sm_ARRIVE_DATE}({util.handleGetDate(item.sm_ARRIVE_DATE)})
          </p>
        </div>
      </div>
      <div className="col-2 m-0 p-0">
        <div className="p-2 text-center">
          <button
            className="btn_1 font-size-08 py-1"
            onClick={() => requestUserReserve(item)}
          >
            BOOKING 하기
          </button>
        </div>
      </div>
    </div>
  );
};
