import React from "react";

export default function ScheduleLogiWorkAIRWeekTable({
  item,
  selectWeekDateList,
  openCostPopup,
  login_info,
  sm_DEPARTURE_DATE,
}) {
  const PlaneIcon = ({ data, date }) => {
    return (
      <button
        className={`border-0 bg-freiscop text-white rounded-sm d-flex flex-row align-items-center justify-content-center`}
        style={{ width: 30, height: 30 }}
        onClick={() => {
          if (!login_info) {
            alert("로그인이 필요한 서비스입니다.");
            return;
          }
          openCostPopup("air", item.sm_IDX, +date.realDate);
        }}
      >
        <i className="fa-solid fa-plane-departure" />
      </button>
    );
  };

  const AirFreightItem = ({ data, date }) => {
    return (
      <>
        <PlaneIcon data={data} date={date} />
        <div className="mt-1 font-size-08">
          <p>{data.FLIGHT_NAME}</p>
          <p>{data.AIR_FREIGHT_NO}</p>
          <p className="font-weight-bold">
            {data.DEPARTURE_TIME} - {data.AIRRIVE_TIME}
          </p>
          <p className="font-weight-bold text-danger">
            {data.AIR_FREIGHT_MEMO ? "*" + data.AIR_FREIGHT_MEMO : " "}
          </p>
        </div>
      </>
    );
  };

  const dateFromString = new Date(sm_DEPARTURE_DATE);

  const yearFromString = dateFromString.getFullYear();
  const monthFromString = dateFromString.getMonth();

  for (let key in selectWeekDateList) {
    const currentDate = selectWeekDateList[key].realDate;
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    if (
      yearFromString === currentYear &&
      monthFromString === currentMonth &&
      item[`sm_INFO_${key}`]
    ) {
      selectWeekDateList[key].display = true;
    } else {
      selectWeekDateList[key].display = false;
    }
  }

  let isAllFalse = true;
  for (let key in selectWeekDateList) {
    if (selectWeekDateList[key].display) {
      isAllFalse = false;
      break;
    }
  }

  if (isAllFalse) return null;

  return (
    <div className="mb-2 bg-light rounded">
      <div className="row m-0 p-0">
        <div className="col-12 m-0 p-0">
          <div className="row h-100 m-0 p-0 text-center">
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold text-danger">
                일{selectWeekDateList.SUN.dateTxt}
              </span>
              {selectWeekDateList.SUN.display && item.sm_INFO_SUN && (
                <AirFreightItem
                  data={item.sm_INFO_SUN}
                  date={selectWeekDateList.SUN}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold">
                월{selectWeekDateList.MON.dateTxt}
              </span>
              {selectWeekDateList.MON.display && item.sm_INFO_MON && (
                <AirFreightItem
                  data={item.sm_INFO_MON}
                  date={selectWeekDateList.MON}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold">
                화{selectWeekDateList.TUE.dateTxt}
              </span>
              {selectWeekDateList.TUE.display && item.sm_INFO_TUE && (
                <AirFreightItem
                  data={item.sm_INFO_TUE}
                  date={selectWeekDateList.TUE}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold">
                수{selectWeekDateList.WED.dateTxt}
              </span>
              {selectWeekDateList.WED.display && item.sm_INFO_WED && (
                <AirFreightItem
                  data={item.sm_INFO_WED}
                  date={selectWeekDateList.WED}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold">
                목{selectWeekDateList.THU.dateTxt}
              </span>
              {selectWeekDateList.THU.display && item.sm_INFO_THU && (
                <AirFreightItem
                  data={item.sm_INFO_THU}
                  date={selectWeekDateList.THU}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold">
                금{selectWeekDateList.FRI.dateTxt}
              </span>
              {selectWeekDateList.FRI.display && item.sm_INFO_FRI && (
                <AirFreightItem
                  data={item.sm_INFO_FRI}
                  date={selectWeekDateList.FRI}
                />
              )}
            </div>
            <div
              className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-start align-items-center"
              style={{ width: 100 }}
            >
              <span className="mb-2 font-weight-bold text-primary">
                토{selectWeekDateList.SAT.dateTxt}
              </span>
              {selectWeekDateList.SAT.display && item.sm_INFO_SAT && (
                <AirFreightItem
                  data={item.sm_INFO_SAT}
                  date={selectWeekDateList.SAT}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
