/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import { Link } from "react-router-dom";
import { displayName } from "react-quill";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "LoginModal";

export default function LoginModal({ setIsLogin, toggleMobileNavOpen }) {
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const $company = useRef();
  const $userSite = useRef();
  const $userID = useRef();
  const $userPW = useRef();
  const $saveID = useRef();

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    $company.current.checked = true;
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      window.removeEventListener("resize", resizeWindow);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    const obj = $("#iframe_gbts")[0];

    let loginInfo = sessionStorage.getItem("loginInfo");
    if (loginInfo === null) loginInfo = localStorage.getItem("loginInfo");

    try {
      loginInfo = util.buddibleDecrypt(loginInfo);
      loginInfo = JSON.parse(loginInfo);
      //console.log(loginInfo);
    } catch (e) {
      loginInfo = null;
      sessionStorage.removeItem("loginInfo");
    }
    if (loginInfo !== null) {
      if (
        !loginInfo.hasOwnProperty("user_id") ||
        !loginInfo.hasOwnProperty("user_pw") ||
        !loginInfo.hasOwnProperty("comp_cd")
      ) {
        sessionStorage.removeItem("loginInfo");
        localStorage.removeItem("loginInfo");
        return;
      }
      $userID.current.value = util.buddibleDecrypt(loginInfo.user_id);
      $userPW.current.value = util.buddibleDecrypt(loginInfo.user_pw);
      $userSite.current.value = util.buddibleDecrypt(loginInfo.comp_cd);
    }
  });

  const resizeWindow = () => {
    if (window.innerWidth >= 768) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const handlePersonal = (e) => {
    // console.log(e.target.value);
    if (e.target.value === "personal") {
      $userSite.current.value = "Y0001";
      $userSite.current.disabled = true;
      $userSite.current.hidden = true;
    } else {
      $userSite.current.value = "";
      $userSite.current.disabled = false;
      $userSite.current.hidden = false;
    }
  };

  const handleModalBackground = () => {
    $("#loginModalClose").click();
    setTimeout(function () {
      // needs to be in a timeout because we wait for BG to leave
      // keep class modal-open to body so users can scroll
      $("body").addClass("modal-open");
    }, 400);
  };

  //login 이벤트 실행
  const actionLogin = (e) => {
    setLoading(true);
    if (checkLoginValidation()) {
      requestLogin({
        user_id: util.buddibleEncrypt($userID.current.value),
        user_pw: util.buddibleEncrypt($userPW.current.value),
        comp_cd: util.buddibleEncrypt($userSite.current.value),
      });
    } else {
      setLoading(false);
    }
  };

  //아이디 패스워드 입력 검사
  const checkLoginValidation = () => {
    if ($userSite.current.value.trim() === "") {
      codeList.Modal.current.alert("업체코드를 입력해주세요", () => {});
      return false;
    }
    if ($userID.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요", () => {});
      return false;
    }
    if ($userPW.current.value.trim() === "") {
      codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  //로그인정보 전송
  const requestLogin = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          if ($saveID.current.value) {
            localStorage.setItem(
              "loginInfo",
              util.buddibleEncrypt(JSON.stringify(newData))
            );
          }

          codeList.Modal.current.alert("로그인 되었습니다.", () => {
            $userID.current.value = "";
            $userPW.current.value = "";
            setIsLogin(true);
            window.location.replace("/");
          });
          $("#loginModalClose").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            newData["msg"]
              ? newData["msg"]
              : "로그인실패, 아이디 또는 비밀번호를 다시 입력해주세요.",
            () => {}
          );
        }
      }
    });
  };

  if (isMobile) {
    return (
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog p-0 m-0 bg-transparent" />
        <div className="vw-100 vh-100 bg-white d-flex flex-column d-md-none">
          <div className="modal-header">
            <h3 className="modal-title font-weight-bold m-0 p-0">로그인</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="loginModalClose"
              onClick={() => {
                $("#loginModalClose").click();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body flex-fill">
            <div className="h-100 d-flex flex-column justify-content-between">
              <div>
                <div className="clearfix">
                  <div className="checkboxes float-left">
                    <label
                      className="container_radio mr-3"
                      style={{ lineHeight: "unset", paddingLeft: 25 }}
                    >
                      기업회원
                      <input
                        type="radio"
                        name="compType"
                        value="company"
                        defaultChecked
                        ref={$company}
                        onClick={(e) => {
                          handlePersonal(e);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="checkboxes float-left">
                    <label
                      className="container_radio"
                      style={{ lineHeight: "unset", paddingLeft: 25 }}
                    >
                      개인회원
                      <input
                        type="radio"
                        name="compType"
                        value="personal"
                        onClick={(e) => {
                          handlePersonal(e);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder="업체코드"
                    ref={$userSite}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder="아이디"
                    ref={$userID}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="password"
                    placeholder="비밀번호"
                    ref={$userPW}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-left">
                    <label className="container_check" htmlFor="saveID">
                      아이디 저장 및 자동 로그인
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="saveID"
                        ref={$saveID}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-row justify-content-center mb-3">
                  <a
                    className="text-secondary cursor_pointer_fw_bold"
                    data-toggle="modal"
                    data-target="#findIDModal"
                    href="#"
                    onClick={() => {
                      handleModalBackground();
                    }}
                  >
                    <p>아이디찾기</p>
                  </a>
                  <p> | </p>
                  <a
                    className="text-secondary cursor_pointer_fw_bold"
                    data-toggle="modal"
                    data-target="#findPWModal"
                    href="#"
                    onClick={() => {
                      handleModalBackground();
                    }}
                  >
                    <p>비밀번호찾기</p>
                  </a>
                  <p> | </p>
                  <Link
                    className="text-secondary cursor_pointer_fw_bold"
                    to="/DeleteAccount"
                    onClick={() => {
                      toggleMobileNavOpen();
                      $("#loginModalClose").click();
                    }}
                  >
                    <p>계정삭제요청</p>
                  </Link>
                </div>
                <div className="text-center">
                  <button
                    value="Log In"
                    className="w-100 btn_1"
                    onClick={() => actionLogin()}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        Loading ...
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    ) : (
                      "Log in"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered d-none d-md-flex">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title font-weight-bold m-0 p-0">로그인</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="loginModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="sign-in-wrapper">
                <div className="clearfix">
                  <div className="checkboxes float-left">
                    <label
                      className="container_radio mr-3"
                      style={{ lineHeight: "unset", paddingLeft: 25 }}
                    >
                      기업회원
                      <input
                        type="radio"
                        name="compType"
                        value="company"
                        defaultChecked
                        ref={$company}
                        onClick={(e) => {
                          handlePersonal(e);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="checkboxes float-left">
                    <label
                      className="container_radio"
                      style={{ lineHeight: "unset", paddingLeft: 25 }}
                    >
                      개인회원
                      <input
                        type="radio"
                        name="compType"
                        value="personal"
                        onClick={(e) => {
                          handlePersonal(e);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder="업체코드"
                    ref={$userSite}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder="아이디"
                    ref={$userID}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="password"
                    placeholder="비밀번호"
                    ref={$userPW}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>

                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-left">
                    <label className="container_check" htmlFor="saveID">
                      아이디 저장 및 자동 로그인
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="saveID"
                        ref={$saveID}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-center mb-3">
                  <a
                    className="text-secondary cursor_pointer_fw_bold"
                    data-toggle="modal"
                    data-target="#findIDModal"
                    href="#"
                    onClick={() => {
                      handleModalBackground();
                    }}
                  >
                    <p>아이디찾기</p>
                  </a>
                  <p> | </p>
                  <a
                    className="text-secondary cursor_pointer_fw_bold"
                    data-toggle="modal"
                    data-target="#findPWModal"
                    href="#"
                    onClick={() => {
                      handleModalBackground();
                    }}
                  >
                    <p>비밀번호찾기</p>
                  </a>
                  <p> | </p>
                  <Link
                    className="text-secondary cursor_pointer_fw_bold"
                    to="/DeleteAccount"
                    onClick={() => {
                      $("#loginModalClose").click();
                    }}
                  >
                    <p>계정삭제요청</p>
                  </Link>
                </div>

                <div className="text-center">
                  <button
                    value="Log In"
                    className="w-100 btn_1"
                    onClick={() => actionLogin()}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        Loading ...
                        <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    ) : (
                      "Log in"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
