/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Utilities from "./Utilities";
import axios from "axios";

const util = new Utilities();
const UploadImageToS3WithPhp = ({
  region,
  callback,
  children,
  multi = false,
}) => {
  const htmlID = `customFile_${util.makeUUIDv4Short()}`;

  const onUpload = async (file) => {
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      callback &&
        callback({
          ret: true,
          fileName: response.data["url"],
          realFileName: file.name,
        });
    } catch (error) {
      callback &&
        callback({
          ret: false,
          fileName: "",
          realFileName: "",
        });
    }
  };

  const onFileChange = async (event) => {
    const files = Object.values(event.target.files);

    if (files.length === 0) return;
    if (multi) {
      for (const file of files) {
        await onUpload(file);
      }
    } else {
      onUpload(files[0]);
    }
  };

  return (
    <div className={children ? "" : "form-group mb-0"}>
      <div className={children ? "" : "custom-file"}>
        <input
          type="file"
          className={`custom-file-input ${children ? "d-none" : "d-block"}`}
          id={htmlID}
          multiple={multi}
          onChange={onFileChange}
        />
        {!children ? (
          <label className={`custom-file-label `} htmlFor={htmlID}>
            파일첨부
          </label>
        ) : (
          <label className="m-0" htmlFor={htmlID}>
            {children}
          </label>
        )}
      </div>
    </div>
  );
};

export default UploadImageToS3WithPhp;
