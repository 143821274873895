import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import ScheduleFCLBookingManageTable from "./ScheduleFCLBookingManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const _mClassName = "ScheduleFCLBookingManage";

let filters = {
  sbf_STATUS: "",
  SEARCH_LEVEL_DATE: "",
  SEARCH_START_DATE: "",
  SEARCH_END_DATE: "",
  SEARCH_LEVEL: "",
  SEARCH_WORD: "",
  EMPLOYEE_TYPE: "",
};

let pickPlace = [];

export default function ScheduleFCLBookingManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      SELECT_PICK_PLACE();
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const $controller = {
    sbf_STATUS: useRef(),
    SEARCH_LEVEL_DATE: useRef(),
    SEARCH_START_DATE: useRef(),
    SEARCH_END_DATE: useRef(),
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    EMPLOYEE_TYPE: useRef(),
  };

  const SELECT_PICK_PLACE = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/pick_place/JS_pick_place_select.php",
      msgID: msgID,
      data: {},
      ppUseYN: "Y",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          pickPlace = [...newData["returnData"]];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //검색필더 값 변경
  const handleFilterChange = (key, value) => {
    filters = {
      ...filters,
      [key]: value,
    };
  };

  //검색필터 적용
  const searchFilterChanged = (key, value) => {
    if (key) {
      filters = {
        ...filters,
        [key]: value,
      };
    }
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const searchEmployee = (value) => {
    filters = {
      ...filters,
      EMPLOYEE_TYPE: value,
      USER_ID: loginInfo.user_id,
    };
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  if (isLoad) {
    return <CommonLoader />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                      <div
                        className="btn-toolbar mb-3 justify-content-md-end"
                        role="toolbar"
                      >
                        {(loginInfo.isConsole || loginInfo.isAdmin) && (
                          <div className=" mb-2 mb-md-0 mx-0 ml-md-2">
                            <select
                              className="custom-select form-control"
                              ref={$controller.EMPLOYEE_TYPE}
                              onChange={(e) => searchEmployee(e.target.value)}
                              style={{ minWidth: 150 }}
                            >
                              <option value="">-담당 정보-</option>
                              <option value="sm_PROVIDER_ID">
                                내 업무 담당 스케쥴
                              </option>
                              <option value="sm_PROVIDER_SALES_ID">
                                내 영업 담당 스케쥴
                              </option>
                            </select>
                          </div>
                        )}

                        <div className=" mb-2 mb-md-0 mx-0 ml-md-2">
                          <select
                            className="custom-select form-control"
                            ref={$controller.sbf_STATUS}
                            onChange={(e) =>
                              searchFilterChanged("sbf_STATUS", e.target.value)
                            }
                            style={{ minWidth: 150 }}
                          >
                            <option value="">-요청상태-</option>
                            <option value="N">예약요청</option>
                            <option value="Y">예약확정</option>
                            <option value="B">B/L발행</option>
                            <option value="C">예약취소</option>
                          </select>
                        </div>
                        <div className="input-group mb-2 mb-md-0 mx-0 mx-md-2">
                          <select
                            className="custom-select form-control"
                            ref={$controller.SEARCH_LEVEL_DATE}
                            onChange={(e) =>
                              handleFilterChange(
                                "SEARCH_LEVEL_DATE",
                                e.target.value
                              )
                            }
                            style={{ minWidth: 150 }}
                          >
                            <option value="">- 검색일자 -</option>
                            <option value="sm_DOC_CLOSE">서류마감</option>
                            <option value="sm_CARGO_CLOSE">화물마감</option>
                            <option value="sm_DEPARTURE_DATE">출발일자</option>
                            <option value="sm_ARRIVE_DATE">도착일자</option>
                            <option value="sm_EDI_DATE">EDI 신고마감</option>
                            <option value="sm_CFS_DATE">CFS 반입마감</option>
                            <option value="sm_AFR_DATE">AFR마감</option>
                            <option value="sb_CREATEDATE">요청일자</option>
                          </select>
                          <input
                            className="form-control"
                            type="date"
                            ref={$controller.SEARCH_START_DATE}
                            onChange={(e) => {
                              handleFilterChange(
                                "SEARCH_START_DATE",
                                e.target.value
                              );
                            }}
                          />
                          <span className="input-group-append mb-2">
                            <div className="input-group-text">-</div>
                          </span>
                          <input
                            className="form-control border-left-0"
                            type="date"
                            ref={$controller.SEARCH_END_DATE}
                            onChange={(e) => {
                              handleFilterChange(
                                "SEARCH_END_DATE",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                          <select
                            className="custom-select form-control"
                            ref={$controller.SEARCH_LEVEL}
                            onChange={(e) =>
                              handleFilterChange("SEARCH_LEVEL", e.target.value)
                            }
                            style={{ minWidth: 150 }}
                          >
                            <option value="">-검색어기준-</option>
                            <option value="sm_UID">스케줄 관리번호</option>
                            <option value="sbf_IDX">예약 No.</option>
                            <option value="sbf_COMP_CD">회사코드</option>
                            <option value="sbf_SHIPPER">상호명</option>
                            <option value="sbf_USER_NAME">요청자명</option>
                          </select>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어"
                            ref={$controller.SEARCH_WORD}
                            onChange={(e) => {
                              filters = {
                                ...filters,
                                SEARCH_WORD: e.target.value,
                              };
                            }}
                            onKeyPress={(e) => {
                              if (e.charCode === 13) {
                                searchFilterChanged();
                              }
                            }}
                          />

                          <button
                            className="input-group-text btn btn-primary border-left-0"
                            onClick={() => searchFilterChanged()}
                            style={{
                              borderBottomLeftRadius: 0,
                              borderTopLeftRadius: 0,
                            }}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </button>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <ScheduleFCLBookingManageTable
                    pickPlace={pickPlace}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
