/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo,
} from "react";
import Select from "react-dropdown-select";
import $ from "jquery";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "";
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

let sm_ORIGIN = "";
let sm_DEST = "";
let sm_IDX = "";
const currentDate = util.todayDate("YYYY-MM-DD");
let isLoading = true;
let port_list = [];

function ScheduleManageEdit(props, ref) {
  const { data = {}, requestUserSelect } = props;
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [FCL_LCL_TYPE, setFCL_LCL_TYPE] = useState("");

  const [desc, setDesc] = useState();

  const $controller = {
    sm_UID: useRef(),
    sm_EXP_IMP: useRef(), //수입 수출
    sm_FCL_LCL: useRef(), //운송방식
    sm_FWD_NO: useRef(), //프로바이더 고유번호
    sm_PROVIDER_CODE: useRef(), //프로바이더 고유번호
    sm_PROVIDER_ID: useRef(), //업무담당자 아이디
    sm_PROVIDER_SALES_ID: useRef(), //영업담당자 아이디
    sm_FWD_NM: useRef(), //포워더명
    sm_VESSEL: useRef(), //선박명
    sm_VOY: useRef(), //항차
    sm_DOC_CLOSE: useRef(), //서류마감
    sm_CARGO_CLOSE: useRef(), //화물마감
    sm_ORIGIN: useRef(), //출발지
    sm_DEST: useRef(), //도착지
    sm_POL_CODE: useRef(), //출발항 코드
    sm_POD_CODE: useRef(), //도착항 코드
    sm_DEPARTURE_DATE: useRef(), //출발일자
    sm_ARRIVE_DATE: useRef(), //도착일자
    sm_LINE: useRef(),
    sm_REGION: useRef(),
    sm_REMARK: useRef(),
    sm_CFS: useRef(),
    sm_PROMO_YN: useRef(), //프로모션 여부
    sm_PROMO_MEMO: useRef(), //프로모션 여부
    sm_USE_YN: useRef(), //노출여부

    sm_TS_PORT: useRef(),
    sm_2ND_VESSEL: useRef(),
    sm_EDI_DATE: useRef(),
    sm_CFS_DATE: useRef(),
    sm_AFR_DATE: useRef(),
    sm_ARRIVE_TERMINAL: useRef(),
    sm_DEPARTURE_TERMINAL: useRef(),
  };

  useEffect(() => {
    if (port_list.length === 0) {
      if (codeList.codeCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
          _mClassName,
          (oldV, newV) => {
            port_list = newV;
            isLoading = false;
            setIsNeedUpdate(!isNeedUpdate);
          }
        );
      } else {
        port_list = codeList.codeCountryFullName;
        isLoading = false;
        setIsNeedUpdate(!isNeedUpdate);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
    return () => {
      sm_ORIGIN = "";
      sm_DEST = "";
      sm_IDX = "";
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
    removeData(e, params) {
      handleDeleteData(e, params);
    },
  }));

  //parameter 데이터 확인 및 매핑
  const dataChange = (e, data) => {
    if (data.hasOwnProperty("currentData")) {
      sm_ORIGIN = data.currentData.sm_ORIGIN;
      sm_DEST = data.currentData.sm_DEST;
      sm_IDX = data.currentData.sm_IDX;

      $controller.sm_UID.current.disabled = true;
      $controller.sm_UID.current.value = data.currentData.sm_UID;
      $controller.sm_FWD_NO.current.value = data.currentData.sm_FWD_NO;
      $controller.sm_FWD_NM.current.value = data.currentData.sm_FWD_NM;
      $controller.sm_FCL_LCL.current.value = data.currentData.sm_FCL_LCL;
      $controller.sm_VESSEL.current.value = data.currentData.sm_VESSEL;
      $controller.sm_VOY.current.value = data.currentData.sm_VOY;
      $controller.sm_DOC_CLOSE.current.value = data.currentData.sm_DOC_CLOSE;
      $controller.sm_CARGO_CLOSE.current.value =
        data.currentData.sm_CARGO_CLOSE;
      $controller.sm_ORIGIN.current.value = data.currentData.sm_POL_CODE;
      $controller.sm_DEST.current.value = data.currentData.sm_POD_CODE;
      $controller.sm_DEPARTURE_DATE.current.value =
        data.currentData.sm_DEPARTURE_DATE;
      $controller.sm_ARRIVE_DATE.current.value =
        data.currentData.sm_ARRIVE_DATE;
      $controller.sm_POL_CODE.current.value = data.currentData.sm_POL_CODE;
      $controller.sm_POD_CODE.current.value = data.currentData.sm_POD_CODE;
      $controller.sm_EXP_IMP.current.value = data.currentData.sm_EXP_IMP;
      $controller.sm_LINE.current.value = data.currentData.sm_LINE;
      $controller.sm_REGION.current.value = data.currentData.sm_REGION;
      $controller.sm_PROVIDER_CODE.current.value =
        data.currentData.sm_PROVIDER_CODE;
      $controller.sm_PROVIDER_ID.current.value =
        data.currentData.sm_PROVIDER_ID;
      $controller.sm_CFS.current.value = data.currentData.sm_CFS;
      $controller.sm_USE_YN.current.value = data.currentData.sm_USE_YN;
      $controller.sm_PROVIDER_SALES_ID.current.value =
        data.currentData.sm_PROVIDER_SALES_ID;
      $controller.sm_PROMO_YN.current.value = data.currentData.sm_PROMO_YN;
      $controller.sm_PROMO_MEMO.current.value = data.currentData.sm_PROMO_MEMO;
      $controller.sm_TS_PORT.current.value = data.currentData.sm_TS_PORT;
      $controller.sm_2ND_VESSEL.current.value = data.currentData.sm_2ND_VESSEL;
      $controller.sm_EDI_DATE.current.value = data.currentData.sm_EDI_DATE;
      $controller.sm_CFS_DATE.current.value = data.currentData.sm_CFS_DATE;
      $controller.sm_AFR_DATE.current.value = data.currentData.sm_AFR_DATE;
      $controller.sm_ARRIVE_TERMINAL.current.value =
        data.currentData.sm_ARRIVE_TERMINAL;
      $controller.sm_DEPARTURE_TERMINAL.current.value =
        data.currentData.sm_DEPARTURE_TERMINAL;
      setDesc(data.currentData.sm_REMARK);
      setFCL_LCL_TYPE(data.currentData.sm_FCL_LCL);
    } else {
      sm_ORIGIN = "";
      sm_DEST = "";
      sm_IDX = "";
      $controller.sm_UID.current.disabled = true;
      $controller.sm_UID.current.value = "";
      $controller.sm_FWD_NO.current.value = "";
      $controller.sm_FWD_NM.current.value = "";
      $controller.sm_FCL_LCL.current.value = "";
      $controller.sm_VESSEL.current.value = "";
      $controller.sm_VOY.current.value = "";
      $controller.sm_DOC_CLOSE.current.value = "";
      $controller.sm_CARGO_CLOSE.current.value = "";
      $controller.sm_ORIGIN.current.value = "";
      $controller.sm_DEST.current.value = "";
      $controller.sm_DEPARTURE_DATE.current.value = "";
      $controller.sm_ARRIVE_DATE.current.value = "";
      $controller.sm_POL_CODE.current.value = "";
      $controller.sm_POD_CODE.current.value = "";
      $controller.sm_EXP_IMP.current.value = "";
      $controller.sm_LINE.current.value = "";
      $controller.sm_REGION.current.value = "";
      $controller.sm_PROVIDER_CODE.current.value = "";
      $controller.sm_PROVIDER_ID.current.value = "";
      $controller.sm_CFS.current.value = "";
      $controller.sm_USE_YN.current.value = "";
      $controller.sm_PROVIDER_SALES_ID.current.value = "";
      $controller.sm_PROMO_YN.current.value = "";
      $controller.sm_PROMO_MEMO.current.value = "";
      $controller.sm_TS_PORT.current.value = "";
      $controller.sm_2ND_VESSEL.current.value = "";
      $controller.sm_EDI_DATE.current.value = "";
      $controller.sm_CFS_DATE.current.value = "";
      $controller.sm_AFR_DATE.current.value = "";
      $controller.sm_ARRIVE_TERMINAL.current.value = "";
      $controller.sm_DEPARTURE_TERMINAL.current.value = "";
      setDesc("");
      setFCL_LCL_TYPE("");
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  //스케줄 데이터 삭제
  const handleDeleteData = (e, params) => {
    let updateData = {
      sm_IDX: sm_IDX,
      sm_UID: params.currentData.sm_UID,
      sm_DEL_YN: "Y",
    };
    codeList.Modal.current.confirm("해당 스케쥴을 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.DELETE);
      }
    });
  };

  //스케줄 저장
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        sm_IDX: sm_IDX,
        sm_UID: $controller.sm_UID.current.value,
        sm_FWD_NO: $controller.sm_FWD_NO.current.value,
        sm_FWD_NM: $controller.sm_FWD_NM.current.value,
        sm_FCL_LCL: $controller.sm_FCL_LCL.current.value,
        sm_VESSEL: $controller.sm_VESSEL.current.value,
        sm_VOY: $controller.sm_VOY.current.value,
        sm_DOC_CLOSE: $controller.sm_DOC_CLOSE.current.value,
        sm_CARGO_CLOSE: $controller.sm_CARGO_CLOSE.current.value,
        sm_ORIGIN: $controller.sm_ORIGIN.current.value,
        sm_DEST: $controller.sm_DEST.current.value,
        sm_DEPARTURE_DATE: $controller.sm_DEPARTURE_DATE.current.value,
        sm_ARRIVE_DATE: $controller.sm_ARRIVE_DATE.current.value,
        sm_POL_CODE: $controller.sm_POL_CODE.current.value,
        sm_POD_CODE: $controller.sm_POD_CODE.current.value,
        sm_EXP_IMP: $controller.sm_EXP_IMP.current.value,
        sm_LINE: $controller.sm_LINE.current.value,
        sm_REGION: $controller.sm_REGION.current.value,
        sm_REMARK: desc,
        sm_PROVIDER_CODE: $controller.sm_PROVIDER_CODE.current.value,
        sm_PROVIDER_ID: $controller.sm_PROVIDER_ID.current.value,
        sm_PROVIDER_SALES_ID: $controller.sm_PROVIDER_SALES_ID.current.value,
        sm_PROMO_YN: $controller.sm_PROMO_YN.current.value,
        sm_PROMO_MEMO: $controller.sm_PROMO_MEMO.current.value,
        sm_CFS: $controller.sm_CFS.current.value,

        sm_TS_PORT: $controller.sm_TS_PORT.current.value,
        sm_2ND_VESSEL: $controller.sm_2ND_VESSEL.current.value,
        sm_EDI_DATE: $controller.sm_EDI_DATE.current.value,
        sm_CFS_DATE: $controller.sm_CFS_DATE.current.value,
        sm_AFR_DATE: $controller.sm_AFR_DATE.current.value,
        sm_ARRIVE_TERMINAL: $controller.sm_ARRIVE_TERMINAL.current.value,
        sm_DEPARTURE_TERMINAL: $controller.sm_DEPARTURE_TERMINAL.current.value,
        sm_USE_YN: $controller.sm_USE_YN.current.value,
        sm_DEL_YN: "N",
      };

      requestUserUpdate(
        updateData,
        $controller.sm_UID.current.value !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  //입력값 유효성검사
  const checkValidation = () => {
    if ($controller.sm_EXP_IMP.current.value.trim() === "") {
      codeList.Modal.current.alert("수출입구분을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_FCL_LCL.current.value.trim() === "") {
      codeList.Modal.current.alert("운송방식을 입력해주세요", () => {});
      return false;
    }
    // if ($controller.sm_FWD_NO.current.value.trim() === "") {
    //   codeList.Modal.current.alert(
    //     "프로바이더 고유번호를 입력해주세요",
    //     () => {}
    //   );
    //   return false;
    // }
    if ($controller.sm_FWD_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("포워더명을 입력해주세요", () => {});
      return false;
    }
    // if ($controller.sm_PROVIDER_CODE.current.value.trim() === "") {
    //   codeList.Modal.current.alert(
    //     "프로바이더 회사코드를 입력해주세요",
    //     () => {}
    //   );
    //   return false;
    // }
    // if ($controller.sm_PROVIDER_ID.current.value.trim() === "") {
    //   codeList.Modal.current.alert(
    //     "프로바이더 담당자 ID를 입력해주세요",
    //     () => {}
    //   );
    //   return false;
    // }

    if ($controller.sm_VESSEL.current.value.trim() === "") {
      codeList.Modal.current.alert("선박명을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_VOY.current.value.trim() === "") {
      codeList.Modal.current.alert("항차를 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_DOC_CLOSE.current.value.trim() === "") {
      codeList.Modal.current.alert("서류마감일을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_CARGO_CLOSE.current.value.trim() === "") {
      codeList.Modal.current.alert("화물마감일을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_ORIGIN.current.value.trim() === "") {
      codeList.Modal.current.alert("출발항(공항)을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_DEST.current.value.trim() === "") {
      codeList.Modal.current.alert("도착항(공항)을 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_DEPARTURE_DATE.current.value.trim() === "") {
      codeList.Modal.current.alert("출발일자를 입력해주세요", () => {});
      return false;
    }
    if ($controller.sm_ARRIVE_DATE.current.value.trim() === "") {
      codeList.Modal.current.alert("도착일자를 입력해주세요", () => {});
      return false;
    }

    if ($controller.sm_PROMO_YN.current.value.trim() === "") {
      codeList.Modal.current.alert("프로모션 여부를 선택해주세요", () => {});
      return false;
    }
    if (
      $controller.sm_PROMO_YN.current.value === "Y" &&
      $controller.sm_PROMO_MEMO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "프로모션 한줄 안내를 입력해주세요.",
        () => {}
      );
      return false;
    }

    if ($controller.sm_USE_YN.current.value.trim() === "") {
      codeList.Modal.current.alert("노출여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  //데이터통신
  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      file: "",
      data: {},
      ...updateData,
    };
    let alertSuccessMSG = "";
    let alertErrorMSG = "";
    if (TYPE === EDIT_TYPE.INSERT) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_insert.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 등록되었습니다.";
      alertErrorMSG =
        "스케쥴 등록중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }

    if (TYPE === EDIT_TYPE.UPDATE) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_update.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 수정되었습니다.";
      alertErrorMSG =
        "스케쥴 수정중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }
    if (TYPE === EDIT_TYPE.DELETE) {
      socketMsg = {
        ...socketMsg,
        file: "/schedule/JS_schedule_update.php",
      };
      alertSuccessMSG = "스케쥴이 정상적으로 삭제되었습니다.";
      alertErrorMSG =
        "스케쥴 삭제중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.";
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();
          codeList.Modal.current.alert(alertSuccessMSG, () => {});
          setLoading(false);
          Cancel();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(alertErrorMSG, () => {});
        }
      }
    });
  };

  // 취소
  const Cancel = () => {
    $controller.sm_UID.current.disabled = true;
    $controller.sm_UID.current.value = "";
    $controller.sm_FWD_NO.current.value = "";
    $controller.sm_FWD_NM.current.value = "";
    $controller.sm_FCL_LCL.current.value = "";
    $controller.sm_VESSEL.current.value = "";
    $controller.sm_VOY.current.value = "";
    $controller.sm_DOC_CLOSE.current.value = "";
    $controller.sm_CARGO_CLOSE.current.value = "";
    $controller.sm_ORIGIN.current.value = "";
    $controller.sm_DEST.current.value = "";
    $controller.sm_DEPARTURE_DATE.current.value = "";
    $controller.sm_ARRIVE_DATE.current.value = "";
    $controller.sm_POL_CODE.current.value = "";
    $controller.sm_POD_CODE.current.value = "";
    $controller.sm_EXP_IMP.current.value = "";
    $controller.sm_LINE.current.value = "";
    $controller.sm_REGION.current.value = "";
    $controller.sm_REMARK.current.value = "";
    $controller.sm_PROVIDER_CODE.current.value = "";
    $controller.sm_PROVIDER_ID.current.value = "";
    $controller.sm_CFS.current.value = "";
    $controller.sm_USE_YN.current.value = "";
    $controller.sm_PROVIDER_SALES_ID.current.value = "";
    $controller.sm_PROMO_YN.current.value = "";
    $controller.sm_PROMO_MEMO.current.value = "";
    $controller.sm_TS_PORT.current.value = "";
    $controller.sm_2ND_VESSEL.current.value = "";
    $controller.sm_EDI_DATE.current.value = "";
    $controller.sm_CFS_DATE.current.value = "";
    $controller.sm_AFR_DATE.current.value = "";
    $controller.sm_ARRIVE_TERMINAL.current.value = "";
    $controller.sm_DEPARTURE_TERMINAL.current.value = "";
    setDesc("");
    setFCL_LCL_TYPE("");
    $("#MallScheduleManageEditClose").click();
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      uploadCallback(file[0]);
    };
  };
  //파일첨부 업데이트
  const uploadCallback = async (file) => {
    const region = "gbts/schdule_remark";
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const range = $controller.sm_REMARK.current
        ?.getEditor()
        .getSelection()?.index;
      if (range !== null && range !== undefined) {
        let quill = $controller.sm_REMARK.current?.getEditor();

        quill?.setSelection(range, 1);
        quill?.clipboard.dangerouslyPasteHTML(
          range,
          `<img style="width:100%;" width='100%'  src=${response.data["url"]} alt="이미지 태그가 삽입됩니다." />`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
  ];

  return (
    <>
      {!isLoading && (
        <div
          className="modal fade"
          id="ScheduleManageEdit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="ScheduleManageEdit"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: 700 }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel">
                  <strong>스케쥴 단건 등록</strong>
                </h3>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="MallScheduleManageEditClose"
                  onClick={() => Cancel()}
                >
                  <i className="fas fa-plus fa-times" />
                </button>
              </div>
              <div className="modal-body">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    관리 번호
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="account"
                      disabled={true}
                      className="form-control"
                      placeholder="ID"
                      ref={$controller.sm_UID}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    프로바이더 고유번호
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="프로바이더 고유번호를 입력해주세요"
                      ref={$controller.sm_FWD_NO}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    담당자 회사코드
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="프로바이더 회사코드를 입력해주세요"
                      ref={$controller.sm_PROVIDER_CODE}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    업무 담당자 ID
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="프로바이더 업무 담당자 ID를 입력해주세요"
                      ref={$controller.sm_PROVIDER_ID}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    영업 담당자 ID
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="프로바이더 영업 담당자 ID를 입력해주세요"
                      ref={$controller.sm_PROVIDER_SALES_ID}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    수출입구분
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controller.sm_EXP_IMP}
                    >
                      <option value="">미선택</option>
                      <option value="EXP">수출</option>
                      <option value="IMP">수입</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    운송방식
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controller.sm_FCL_LCL}
                      onChange={() => {
                        if ($controller.sm_FCL_LCL.current) {
                          setFCL_LCL_TYPE($controller.sm_FCL_LCL.current.value);
                        }
                      }}
                    >
                      <option value="">미선택</option>
                      <option value="LCL">LCL</option>
                      <option value="FCL">FCL</option>
                      <option value="AIR">항공</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    포워더명
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="포워더 상호명을 입력해주세요"
                      ref={$controller.sm_FWD_NM}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    선박명
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="선박명을 입력해주세요"
                      ref={$controller.sm_VESSEL}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    항차
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="항차를 입력해주세요"
                      ref={$controller.sm_VOY}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    서류마감
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_DOC_CLOSE}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    EDI 신고마감
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_EDI_DATE}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    CFS 반입마감
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_CFS_DATE}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    AFR 마감
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_AFR_DATE}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    화물마감
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_CARGO_CLOSE}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    출발터미널
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="출발터미널정보를 입력해주세요"
                      ref={$controller.sm_ARRIVE_TERMINAL}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    도착터미널
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="도착터미널정보를 입력해주세요"
                      ref={$controller.sm_DEPARTURE_TERMINAL}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    출발항(공항)
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control d-none"
                      disabled={true}
                      ref={$controller.sm_POL_CODE}
                    />{" "}
                    <input
                      type="text"
                      className="form-control d-none"
                      disabled={true}
                      ref={$controller.sm_ORIGIN}
                    />
                    <Select
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={port_list}
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sm_ORIGIN ? sm_ORIGIN : "출발항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          //출발지명
                          $controller.sm_ORIGIN.current.value = "";
                          //출발지 항구코드
                          $controller.sm_POL_CODE.current.value = "";
                          return;
                        }
                        //출발지명
                        $controller.sm_ORIGIN.current.value =
                          data[0].CustomCode;
                        //출발지 항구코드
                        $controller.sm_POL_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={({ item, methods }) => {
                        return (
                          <p
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                            onClick={() => methods.addItem(item)}
                            color="#0074D9"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #fff",
                              color: "gray",
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b
                              style={{
                                fontWeight: "normal",
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {item.CustomCode
                                ? "[" + item.CustomCode + "] "
                                : ""}
                            </b>

                            <b
                              style={{
                                fontWeight: 600,
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {`${item.CityName} (${item.CityName_EN}) `}
                            </b>

                            {item.CountryName + " " + item.ContinentName}
                          </p>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    도착항(공항)
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      disabled={true}
                      className="form-control d-none"
                      ref={$controller.sm_POD_CODE}
                    />
                    <input
                      type="text"
                      disabled={true}
                      className="form-control d-none"
                      ref={$controller.sm_DEST}
                    />
                    <Select
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={port_list}
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sm_DEST ? sm_DEST : "출발항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          //출발지명
                          $controller.sm_DEST.current.value = "";
                          //출발지 항구코드
                          $controller.sm_POD_CODE.current.value = "";
                          return;
                        }
                        //출발지명
                        $controller.sm_DEST.current.value = data[0].CustomCode;
                        //출발지 항구코드
                        $controller.sm_POD_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={({ item, methods }) => {
                        return (
                          <p
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                            onClick={() => methods.addItem(item)}
                            color="#0074D9"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #fff",
                              color: "gray",
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b
                              style={{
                                fontWeight: "normal",
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {item.CustomCode
                                ? "[" + item.CustomCode + "] "
                                : ""}
                            </b>

                            <b
                              style={{
                                fontWeight: 600,
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {`${item.CityName} (${item.CityName_EN}) `}
                            </b>

                            {item.CountryName + " " + item.ContinentName}
                          </p>
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    T/S Port
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="T/S Port 정보를 입력해주세요"
                      ref={$controller.sm_TS_PORT}
                    />
                  </div>
                </div>
                <div
                  className="mb-3 row"
                  style={FCL_LCL_TYPE === "FCL" ? {} : { display: "none" }}
                >
                  <label className="col-form-label col-sm-3 text-end">
                    2 <sup>nd</sup> VESSEL
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="2nd Veseel 정보를 입력해주세요"
                      ref={$controller.sm_2ND_VESSEL}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    출발일자
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_DEPARTURE_DATE}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    도착일자
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      defaultValue={currentDate}
                      min={currentDate}
                      className="form-control"
                      ref={$controller.sm_ARRIVE_DATE}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    CFS CODE
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="CFS CODE를 입력해주세요"
                      ref={$controller.sm_CFS}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    라인
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="라인 정보를 입력해주세요"
                      ref={$controller.sm_LINE}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    지역
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="라인 정보를 입력해주세요"
                      ref={$controller.sm_REGION}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    주요 안내사항
                  </label>
                  <div className="col-sm-9">
                    <ReactQuill
                      className="bg-white w-100"
                      modules={modules}
                      formats={formats}
                      ref={$controller.sm_REMARK}
                      value={desc}
                      onChange={setDesc}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    프로모션 여부
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controller.sm_PROMO_YN}
                    >
                      <option value="">미선택</option>
                      <option value="Y">프로모션 상품</option>
                      <option value="N">일반 상품</option>
                    </select>
                  </div>
                </div>{" "}
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    프로모션 한줄소개
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      ref={$controller.sm_PROMO_MEMO}
                      maxLength={20}
                      placeholder="프로모션에 대한 한줄 소개를 작성해주세요"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    노출여부
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="custom-select form-control"
                      ref={$controller.sm_USE_YN}
                    >
                      <option value="">미선택</option>
                      <option value="Y">사용</option>
                      <option value="N">중지</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      Cancel();
                    }}
                  >
                    <i className="fa fa-times mr-2" />
                    취소
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => actionSave(e)}
                    disabled={loading}
                  >
                    <i className="fa fa-check mr-2" />
                    저장
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default forwardRef(ScheduleManageEdit);
