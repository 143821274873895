import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NewsCompModal from "./NewsCompModal";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import Loading from "../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "NewsDetail";

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

let loginInfo = null;
export default function NewsDetail(props) {
  const [data, setData] = useState(undefined);
  const [subscribeList, setSubscribeList] = useState({});
  const param = useParams();
  const { t } = useTranslation();

  (() => {
    if (!param || !param.hasOwnProperty("idx")) {
      alert(t("wrongRequestMSG"));
      window.location.replace("/Test");
    }
  })();

  useEffect(() => {
    document.addEventListener("message", receiveMessage);
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData["returnData"][0];
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
      }
    );
    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n) return;

      requestSubscribeList(() => {
        requestNewsDetailDataSelect();
      });
    });

    return () => {
      // document.removeEventListener("message", receiveMessage);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener("ChangedNews", _mClassName);
    };
  }, []);

  useEffect(() => {
    if (loginInfo) {
      requestSubscribeList(() => {
        requestNewsDetailDataSelect();
      });
    }
  }, [loginInfo]);

  const receiveMessage = (event) => {
    let data = util.buddibleDecrypt(event.data);
    data = JSON.parse(data);

    if (data.key === "ChangedSubscribe") {
      requestSubscribeList(() => {
        requestNewsDetailDataSelect();
      });
      return;
    }
  };

  // 구독 뉴스채널 데이터 요청
  const requestSubscribeList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let subscribeTemp = {};
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            subscribeTemp[newData["returnData"][i].cnmMainKey] = "";
            newData["returnData"][i].isEntire = false;
          }
          setSubscribeList(subscribeTemp);
          callback && callback(newData);
        }
      }
    });
  };

  // 채널 정보 요청
  const requestNewsCompDataSelect = (cntMainKey, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsMainSelect.php",
      msgID: msgID,
      data: {},
      cnmMainKey: cntMainKey,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 뉴스톡 디테일 데이터 요청
  const requestNewsDetailDataSelect = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      cntIDX: param.idx,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = { ...newData["returnData"][0] };
          requestNewsCompDataSelect(data.cntMainKey, (newData) => {
            data = {
              ...data,
              ...newData["returnData"][0],
            };
            setData(data);
          });
        }
      }
    });
  };

  // ws 구독 데이터 전송
  const ChangedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  // 구독정보 앱 전달
  const sendSubscribeDataForApp = (news) => {
    const data = JSON.stringify(news);
    window.ReactNativeWebView?.postMessage(data);
  };

  const clickSubscribeBtn = (news) => {
    if (!loginInfo) {
      codeList.Modal.current.alert(t("requestLoginMSG"));
      return;
    }

    const isSubscribe = subscribeList.hasOwnProperty(news.cnmMainKey);
    const confirmMSG = isSubscribe
      ? t("requestSubscribeMSG")
      : t("requestSubscribeCancelMSG");
    codeList.Modal.current.confirm(
      `[${news.cnmCompNM}-${news.cnmName}] ${confirmMSG}`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/talk_news/JS_chatNewsChannelSubscribe.php",
            msgID: msgID,
            data: {},
            cnuMainKey: news.cnmMainKey,
            cnuUserSite: loginInfo.comp_cd,
            cnuUserID: loginInfo.user_id,
            cnuRoomAuth: "",
            cnuRoomName: news.cnmName,
            cnuAgreeYN: isSubscribe ? "N" : "Y",
            cnuJoinDate: new Date(),
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  socketMsg.cnuAgreeYN === "Y"
                    ? `[${news.cnmName}]${t("successSubscribeMSG")}`
                    : `[${news.cnmName}]${t("successSubscribeCancelMSG")}`,
                  () => {
                    requestSubscribeList(() => {
                      setData((prevState) => {
                        return {
                          ...prevState,
                          cnmSubscribe:
                            Number(data.cnmSubscribe) +
                            (socketMsg.cnuAgreeYN === "Y" ? 1 : -1),
                        };
                      });
                      const temp = {
                        ...news,
                        cnuAgreeYN: socketMsg.cnuAgreeYN,
                        isSubscribe: isSubscribe ? "N" : "Y",
                      };
                      sendSubscribeDataForApp(temp);
                      ChangedNews(temp);
                    });
                  }
                );
              } else {
                codeList.Modal.current.alert(t("errorMSG"));
              }
            }
          });
        }
      }
    );
  };

  const downloadFile = (url, name) => {
    codeList.Modal.current.confirm(
      `[${name}]${t("confirmDownloadMSG")}`,
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  if (data === undefined) {
    return <Loading />;
  }

  const [year, month, date] = util
    .getDateToFormat(data.cntCreatDate, "YYYY-MM-DD")
    .split("-");
  const [imgLink, imgName] = data.cntPic.split("?");
  const files = data.cntFile !== "" ? data.cntFile.split("|") : "";

  const isSubscribe = subscribeList.hasOwnProperty(data.cnmMainKey);

  return (
    <>
      <NewsCompModal
        data={data}
        subscribeList={subscribeList}
        clickSubscribeBtn={clickSubscribeBtn}
      />
      <div className="vh-100 overflow_scroll-Y p-4 bg-white">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-6">
            <div className="d-flex flex-row justify-content-between mb-5">
              <div>
                <p className="h4 font-weight-bold">{data.cntTitle}</p>
                <p>
                  작성자 :{" "}
                  <span
                    className="underline cursor_pointer font-weight-bold"
                    data-toggle="modal"
                    data-target="#NewsCompModal"
                  >
                    {data.cnmCompNM}
                  </span>{" "}
                  · {year}
                  {t("year")} {month}
                  {t("month")} {date}
                  {t("date")}
                </p>
              </div>
              <div className="pl-2">
                <span
                  className="h4 mb-0 d-inline-block cursor_pointer"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <i className="fa-solid fa-right-from-bracket" />
                </span>
              </div>
            </div>

            <div>
              {imgLink && (
                <img
                  src={imgLink}
                  alt={imgName}
                  className="w-100 mb-3 rounded-lg"
                />
              )}
              <p className="h6 pb-3 mb-3">{data.cntMSG}</p>

              {data.cntLink !== "" && (
                <div className="h6 border-top py-3">
                  <p className="font-weight-bold mb-2">
                    ※ {t("referenceLink")}
                  </p>
                  <p>
                    <a
                      href={data.cntLink}
                      target="_blank"
                      className="underline"
                    >
                      {data.cntLink}
                    </a>
                  </p>
                </div>
              )}

              {files !== "" && (
                <div className="h6 border-top pt-3">
                  <p className="font-weight-bold mb-3">※ {t("attachments")}</p>
                  {files.map((file, index) => {
                    const splitData = file.split("?");
                    const fileLink = splitData[0];
                    const fileName = splitData[1];

                    return (
                      <p key={`${file}_${index}`} className="mb-2">
                        <span
                          className="d-inline-block mr-2 font-weight-bold cursor_pointer font-size-08"
                          onClick={() => {
                            downloadFile(fileLink, fileName);
                          }}
                        >
                          💾 {t("save")}
                        </span>
                        <a href={fileLink}>{fileName}</a>
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-center h6 font-weight-bold mb-0 mt-5">
              <div className="mr-1 bg-light border p-2 rounded">
                {data.cnmSubscribe}
                {t("person")} {t("subscribe")}
              </div>
              <button
                className={`ml-1 ${
                  isSubscribe ? "bg-light border p-2 rounded" : "p-2 btn_1"
                }`}
                onClick={() => clickSubscribeBtn(data)}
              >
                {isSubscribe
                  ? `${t("subscribe")} ${t("cancel")}`
                  : t("subscribe")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
