import React from "react";
import GBTSLogoSvg from "../../../assets/GBTSLogoSvg";

export default function AlarmTalkManageToolbar(props) {
  const { pages, activePage, handleChangePage } = props;

  return (
    <div
      className="p-2 d-flex flex-row justify-content-between align-items-center"
      style={{ background: "#F0F0F0" }}
    >
      <div>
        <span className="font-weight-bold mr-3">구분</span>
        {pages.map((page) => {
          return (
            <button
              key={page.key}
              className={`mr-2 logiwork-btn-color ${
                page.key === activePage ? "active" : ""
              }`}
              onClick={() => {
                if (page.key !== activePage) {
                  handleChangePage(page);
                }
              }}
            >
              {page.name}
            </button>
          );
        })}
      </div>
      <GBTSLogoSvg height="1rem" />
    </div>
  );
}
