import React, { useCallback, useEffect } from "react";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";

import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

import MMSI from "../mmsiList";
import LogoSvg from "../../../../assets/LogoSvg";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const util = new Utilities();
const codeList = new CodeList();

const mmsi = new MMSI();

const useRowStyles = makeStyles({
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
});

export default function ScheduleLCLTable(props) {
  const {
    page,
    totalPageCount,
    comp_list,
    schedule_list,
    loginInfo,
    openCostModal,
    handleChangePage,
    handleOpenOceanLookV3,
    sendUserUsedData,
  } = props;
  const classes = useRowStyles();

  const requestEnquiry = (item, enquiryType) => {
    let typeText = "업무";
    if (enquiryType === 1) {
      typeText = "영업";
    }
    codeList.Modal.current.confirm(
      `[${item.sm_ORIGIN}-${item.sm_DEST}] 스케줄에 대해 ${typeText}담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          if (!loginInfo) {
            codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
            return;
          }
          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "LogiTalk", "_blank");
        }
      }
    );
  };

  const copyScheduleURL = async (item) => {
    try {
      const schedule_url = `${window.location.origin}/ScheduleLCL/${item.sm_UID}`;
      await navigator.clipboard.writeText(schedule_url);
      codeList.Modal.current.alert("공유 링크가 정상적으로 복사되었습니다.");
    } catch (e) {
      codeList.Modal.current.alert("오류가 발생했습니다. 다시 시도해주세요");
    }
  };

  const insertUserLog = (item, type, isClick) => {
    sendUserUsedData({
      shIDX: item.sm_IDX,
      shType: item.sm_FCL_LCL,
      shDate: "",
      shORIGIN: item.sm_POL_CODE,
      shDEST: item.sm_POD_CODE,
      shClick: isClick,
      shClickFor: type,
    });
  };

  //스케줄 아이템 랜더
  const ScheduleItemRender = useCallback((props) => {
    return <ScheduleItem {...props} />;
  }, []);

  return (
    <>
      <div className="rounded shadow-sm">
        <div
          className={`col-12 m-0 p-0 d-none d-xl-block rounded-top-left rounded-top-right overflow-hidden`}
        >
          <div className={`row m-0 p-0 bg-secondary text-white `}>
            <div className="col-1 px-1 py-1 m-0 align-items-end">
              &nbsp;스케줄 No
            </div>
            <div className="col-2 px-1 py-1 m-0">콘솔사</div>
            <div className="col-2 px-1 py-1 m-0">서류마감/화물마감</div>
            <div className="col-4 px-1 py-1 m-0">출발/도착</div>
            <div className="col-1 px-1 py-1 m-0">선박</div>
            <div className="col-2 px-0 m-0 bg-dark text-center d-flex flex-row justify-content-around">
              <div className="px-2 py-1">안내</div>
              <div className="px-2 py-1">운임</div>
              <div className="px-2 py-1">문의</div>
              <div className="px-2 py-1">위치</div>
            </div>
          </div>
        </div>

        <div className="row m-0 p-0">
          {schedule_list.length === 0 && (
            <div className="col-12 m-0 p-5 bg-white text-center rounded-bottom">
              <p className="h6 mb-0 font-weight-bold text-secondary">
                <i className="fa-regular fa-calendar-days mr-2" />
                스케줄이 존재하지 않습니다.
              </p>
            </div>
          )}

          {schedule_list.length > 0 &&
            schedule_list.map((item, index) => {
              return (
                <ScheduleItemRender
                  key={item.sm_IDX}
                  index={index}
                  item={item}
                  comp_list={comp_list}
                  insertUserLog={insertUserLog}
                  requestEnquiry={requestEnquiry}
                  copyScheduleURL={copyScheduleURL}
                  openCostModal={openCostModal}
                  handleOpenOceanLookV3={handleOpenOceanLookV3}
                />
              );
            })}
        </div>
      </div>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          className={classes.pagination}
          color="info"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </>
  );
}

const ScheduleItem = React.memo((props) => {
  const {
    item,
    index,
    comp_list,
    openCostModal,
    insertUserLog,
    requestEnquiry,
    copyScheduleURL,
    handleOpenOceanLookV3,
  } = props;

  const isRadius = index === 9;

  const sm_DOC_CLOSE_day = util.handleGetDate(item.sm_DOC_CLOSE);
  const sm_CARGO_CLOSE_day = util.handleGetDate(item.sm_CARGO_CLOSE);
  const sm_DEPARTURE_DATE_day = util.handleGetDate(item.sm_DEPARTURE_DATE);
  const sm_ARRIVE_DATE_day = util.handleGetDate(item.sm_ARRIVE_DATE);

  useEffect(() => {
    $(".open_sch_info_" + item.sm_IDX).click((e) => {
      $("#collapse_btn_" + item.sm_IDX).click();
    });
  }, []);

  const mmsiNum = mmsi.getMMSI(item.sm_VESSEL);
  let comp_logo = "";
  if (item.COMP_LOGO) {
    comp_logo = item.COMP_LOGO.split("?")[0];
  } else {
    comp_logo = comp_list
      .find((data) => data.blFWDNO === item.sm_FWD_NO)
      ?.blLOGO?.split("?")[0];
  }

  return (
    <div className={`col-12 m-0  p-0 text-secondary`}>
      <div
        className={`row m-0 p-0 p-xl-1 align-items-center font-size-09 bg-white cursor_pointer cursor_pointer_bg_light ${
          isRadius
            ? "rounded-bottom-left rounded-bottom-right"
            : "border-bottom"
        }`}
      >
        <div className="col-12 col-xl-1 order-0 order-xl-0 m-0 p-0 px-2">
          <div
            className="d-flex flex-row align-items-center py-2 py-xl-0"
            onClick={(e) => {
              copyScheduleURL(item);
              insertUserLog(item, "share", 1);
            }}
          >
            <span className="h6 mr-1 mb-0">
              <i className="fa-solid fa-share-nodes mr-1 text-primary" />
            </span>
            <span className="font-size-09">{item.sm_UID}</span>
          </div>
        </div>
        <div className="col-12 d-xl-none border-bottom" />
        <div
          className={`col-6 col-xl-2 m-0 p-2 p-xl-0 order-1 order-xl-1 open_sch_info_${item.sm_IDX}`}
        >
          <div className="d-flex flex-row align-items-center">
            {comp_logo ? (
              <div
                style={{
                  backgroundImage: `url('${comp_logo}')`,
                  backgroundPosition: "50% 50%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",

                  width: "40px",
                  height: "40px",
                }}
              />
            ) : (
              <LogoSvg width={"20px"} />
            )}

            <div className="ml-2">
              {item.sm_PROMO_YN === "Y" && (
                <span className="badge badge-danger text-white mr-1 hover_point">
                  PROMO
                  <div
                    className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                    style={{
                      minWidth: 180,
                      bottom: "calc(100% + 20px)",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 9,
                    }}
                  >
                    <p className="text-danger" style={{ fontSize: "1em" }}>
                      *{item.sm_PROMO_MEMO}
                    </p>
                    <div
                      className="bg-white border-right border-bottom"
                      style={{
                        transform: "rotate(45deg)",
                        marginLeft: -10,
                        width: 20,
                        height: 20,
                        position: "absolute",
                        left: "50%",
                      }}
                    />
                  </div>
                </span>
              )}
              {util.isNumber(item.sm_IDX) && (
                <span className="badge badge-info">추천</span>
              )}
              <p className="font-weight-bold mt-1">
                {item.COMP_NM || item.sm_FWD_NM}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-xl-2 m-0 px-1 p-xl-0 order-3 order-xl-2 open_sch_info_${item.sm_IDX}`}
        >
          <div className="row m-0 p-2 p-xl-0">
            <div className="col-6 m-0 p-0 d-flex flex-row">
              <span className="mr-1 mb-0">
                <i className="fa-regular fa-file-lines" />
              </span>
              <div>
                <p>{item.sm_DOC_CLOSE}</p>
                <p>
                  ({sm_DOC_CLOSE_day}) {item.sm_DOC_CLOSE_TIME}
                </p>
              </div>
            </div>
            <div className="col-6 m-0 p-0 d-flex flex-row">
              <span className="font-weight-bold mr-1">
                <i className="fa-solid fa-dolly" />
              </span>
              <div>
                <p>{item.sm_CARGO_CLOSE}</p>
                <p>
                  ({sm_CARGO_CLOSE_day}) {item.sm_CARGO_CLOSE_TIME}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-xl-4 m-0 p-1 p-xl-0 order-4 order-xl-3 open_sch_info_${item.sm_IDX}`}
        >
          <div className="row m-0 p-2 font-weight-bold">
            <div className="col-6 m-0 p-0">
              <p className="text-dark">{item.sm_ORIGIN}</p>
              <p className="text-danger">
                {item.sm_DEPARTURE_DATE} ({sm_DEPARTURE_DATE_day})
              </p>
            </div>
            <div className="col-6 m-0 p-0">
              <p className="text-dark">{item.sm_DEST}</p>
              <p className="text-danger">
                {item.sm_ARRIVE_DATE} ({sm_ARRIVE_DATE_day}){" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`col-6 col-xl-1 m-0 px-1 py-2 p-xl-0 order-2 order-xl-4 open_sch_info_${item.sm_IDX}`}
        >
          <div className="h-100 d-flex flex-column justify-content-center">
            <p className="font-weight-bold">{item.sm_VESSEL}</p>
            {item.sm_VOY && <p>{item.sm_VOY}</p>}
          </div>
        </div>
        <div className="col-12 m-0 p-0 order-5 order-xl-5 d-xl-none border-top" />
        <div className="col-12 col-xl-2 order-7 order-xl-6 m-0 p-0">
          <div className="py-1 d-flex flex-row  justify-content-around align-items-center position-relative flex-grow-1">
            <div className="position-relative mr-1 hover_point text-center">
              <span className="text-center p-0 m-0">
                <a
                  className="h5 m-0 p-0 text-decoration-none text-secondary border-0 bg-transparent"
                  data-toggle="collapse"
                  href={`#collapse_${item.sm_IDX}`}
                  role="button"
                  aria-expanded="false"
                  aria-controls={`#collapse_${item.sm_IDX}`}
                  id={`collapse_btn_${item.sm_IDX}`}
                >
                  <i className="fa-regular fa-circle-question" />
                </a>
              </span>

              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 9,
                }}
              >
                {item.sm_PROMO_YN === "Y" && (
                  <p className="text-danger">*{item.sm_PROMO_MEMO}</p>
                )}
                클릭시 안내사항을 확인 할 수 있습니다.
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div
              className="position-relative mr-1 hover_point text-center cursor-pointer"
              onClick={() => {
                insertUserLog(item, "book", 1);
                openCostModal(item);
              }}
            >
              <span className="h5">
                <i
                  className={`fa-solid fa-magnifying-glass mx-1 cursor_pointer `}
                />
              </span>

              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 9,
                  wordBreak: "keep-all",
                }}
              >
                이 스케줄에 예약 요청 하시겠어요?
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div className="position-relative mr-1 hover_point text-center cursor-pointer">
              <span className="text-center p-0 m-0">
                {item.sm_PROVIDER_ID && (
                  <button
                    className="btn btn-custom-secondary btn-sm  btn-block"
                    style={{ fontSize: "10px", padding: "0.1rem" }}
                    onClick={() => {
                      requestEnquiry(item, 0);
                    }}
                  >
                    업무
                  </button>
                )}
                {item.sm_PROVIDER_SALES_ID && (
                  <button
                    className="btn btn-custom-primary btn-sm  btn-block"
                    style={{
                      fontSize: "10px",
                      padding: "0.1rem",
                      marginTop: "0.1rem",
                    }}
                    onClick={() => {
                      requestEnquiry(item, 1);
                    }}
                  >
                    영업
                  </button>
                )}
              </span>
              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                로지톡으로 담당자와 대화해보세요
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div
              className="position-relative mr-1 hover_point text-center cursor-pointer"
              onClick={() => {
                insertUserLog(item, "ship", 1);
                if (!mmsiNum) return;
                handleOpenOceanLookV3(item.sm_UID, item.sm_VESSEL);
              }}
              style={mmsiNum ? { cursor: "pointer" } : {}}
            >
              <span className="text-center p-0 m-0">
                <span className="h5">
                  {mmsiNum ? (
                    <i className="fa-solid fa-location-dot" />
                  ) : (
                    <i
                      className="fa-solid fa-location-dot"
                      style={{ color: "transparent" }}
                    />
                  )}
                </span>
              </span>

              {mmsiNum && (
                <div
                  className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                  style={{
                    minWidth: 180,
                    bottom: "calc(100% + 20px)",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 9,
                  }}
                >
                  선박 위치를 <br />
                  확인해보세요.
                  <div
                    className="bg-white border-right border-bottom"
                    style={{
                      transform: "rotate(45deg)",
                      marginLeft: -10,
                      width: 20,
                      height: 20,
                      position: "absolute",
                      left: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="collapse col-12 m-0 p-0 order-6 order-xl-7"
          id={`collapse_${item.sm_IDX}`}
        >
          <div className="py-2 px-3" style={{ fontSize: "1em" }}>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                선명 · 항차
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_VESSEL} / {item.sm_VOY}
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                소요일
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {(new Date(item.sm_ARRIVE_DATE).getTime() -
                  new Date(item.sm_DEPARTURE_DATE).getTime()) /
                  (1000 * 3600 * 24)}
                일
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                서류 마감
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                <span>
                  {item.sm_DOC_CLOSE} ({sm_DOC_CLOSE_day})
                </span>
                <span>{" " + item.sm_DOC_CLOSE_TIME}</span>
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                화물 마감
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                <span>
                  {item.sm_CARGO_CLOSE} ({sm_CARGO_CLOSE_day})
                </span>
                <span>{" " + item.sm_CARGO_CLOSE_TIME}</span>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                예상 출항
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_DEPARTURE_DATE} ({sm_DEPARTURE_DATE_day})
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                예상 도착
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_ARRIVE_DATE} ({sm_ARRIVE_DATE_day})
              </div>
            </div>
          </div>
          <div
            className="rounded col-12  py-2 px-3 flex-column flex-xl-row align-items-xl-center bg-white"
            style={{ color: "#3B44AC" }}
          >
            <div className="font-weight-bolder">주요 안내사항</div>
            <p dangerouslySetInnerHTML={{ __html: item.sm_REMARK }} />
          </div>
        </div>
      </div>
    </div>
  );
});

const test = {
  csclPrgsStts: "수입신고수리",
  vydf: "",
  rlseDtyPridPassTpcd: "N",
  prnm: "RUBBER SILICONE BLADE SWIVEL RUBBER SILICONE BLADE SWIVEL",
  ldprCd: "LAX",
  shipNat: "KR",
  blPt: "C",
  dsprNm: "서울/인천",
  etprDt: "20240510",
  prgsStCd: "CAGE12",
  msrm: "",
  wghtUt: "KG",
  dsprCd: "ICN",
  cntrGcnt: "0",
  cargTp: "수입 일반화물",
  shcoFlcoSgn: "KE",
  pckGcnt: "1",
  etprCstm: "인천공항세관",
  shipNm: "KE214",
  hblNo: "MAXAH064335",
  prcsDttm: "20240510150805",
  frwrSgn: "JTSL",
  spcnCargCd: "SPX",
  ttwg: "91",
  ldprNm: "로스앤젤레스",
  frwrEntsConm: "주식회사 제이티에스쉬핑",
  dclrDelyAdtxYn: "N",
  mtTrgtCargYnNm: "검사완료",
  cargMtNo: "24KE05Y59II00010005",
  cntrNo: "",
  mblNo: "18025593584",
  blPtNm: "Consol",
  lodCntyCd: "US",
  prgsStts: "반출완료",
  shcoFlco: "(주)대한항공",
  pckUt: "CT",
  shipNatNm: "한국",
  agnc: "",
  SN: 926755,
  BL_ST2_DT: "2024-05-10 15:08:05",
  BL_ST: "CAGE12",
  BL_ST2: "",
  Sub2List: [],
  Sub1List: [
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510150805",
      dclrNo: "040025492400015397",
      rlbrDttm: "2024-05-10 15:07:16",
      wght: "91",
      rlbrBssNo: "4277624050238M",
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "반출신고",
      pckUt: "CT",
      rlbrCn: "수입신고 수리후 반출",
      shedSgn: "04002549",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510143302",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입신고수리",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04002549",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510142954",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입(사용소비) 결재통보",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04002549/입항전수입",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510132656",
      dclrNo: "040025492490015089",
      rlbrDttm: "2024-05-10 13:25:55",
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn:
        "[부가사항] 인천공항세관검사장(지정장치장)의 장치기간은 최대 2 개월 입니다.",
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "반입신고",
      pckUt: "CT",
      rlbrCn: "입항 반입",
      shedSgn: "04002549/32",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510112323",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입신고 수리전 정정 완료",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04002549",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510111800",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입신고 수리전 정정 접수",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04002549",
    },
    {
      shedNm: "인천공항세관검사장(지정장치장)",
      prcsDttm: "20240510102716",
      dclrNo: "24040040912",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "하기신고 수리",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04002549",
    },
    {
      shedNm: {},
      prcsDttm: "20240510095043",
      dclrNo: "24KE05Y59II00000001",
      rlbrDttm: {},
      wght: {},
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "0",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 운항정보 정정",
      pckUt: {},
      rlbrCn: {},
      shedSgn: {},
    },
    {
      shedNm: {},
      prcsDttm: "20240510095043",
      dclrNo: "24KE05Y59II",
      rlbrDttm: {},
      wght: {},
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "0",
      cargTrcnRelaBsopTpcd: "입항보고 수리",
      pckUt: {},
      rlbrCn: {},
      shedSgn: {},
    },
    {
      shedNm: "(주)대한항공",
      prcsDttm: "20240510092323",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입(사용소비) 심사진행",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04077003",
    },
    {
      shedNm: "(주)대한항공",
      prcsDttm: "20240510091208",
      dclrNo: "4277624050238M",
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: "베스트관세법인",
      bfhnGdncCn:
        "[안내사항] (주)대한항공 은 수입신고 수리일로부터 15일이내에 물품을 반출하여야하며, 미반출시 과태료가 부과됩니다.",
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "수입신고",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04077003/입항전수입",
    },
    {
      shedNm: "(주)대한항공",
      prcsDttm: "20240510053532",
      dclrNo: {},
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: "주식회사 제이티에스쉬핑",
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 심사완료",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04077003",
    },
    {
      shedNm: "(주)대한항공",
      prcsDttm: "20240510041757",
      dclrNo: {},
      rlbrDttm: {},
      wght: "91",
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "1",
      cargTrcnRelaBsopTpcd: "입항적재화물목록 제출",
      pckUt: "CT",
      rlbrCn: {},
      shedSgn: "04077003",
    },
    {
      shedNm: {},
      prcsDttm: "20240510000057",
      dclrNo: "24KE05Y59II",
      rlbrDttm: {},
      wght: {},
      rlbrBssNo: {},
      bfhnGdncCn: {},
      wghtUt: "KG",
      pckGcnt: "0",
      cargTrcnRelaBsopTpcd: "입항보고 제출",
      pckUt: {},
      rlbrCn: {},
      shedSgn: {},
    },
  ],
};
