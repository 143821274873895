import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

let totalCount = 0;
let totalPageCount = 0;
let page = 0;
let rowsPerPage = 30;
export default function SearchPersonalUser(props) {
  const { addFavUser, openUserInfoModal } = props;
  const [filters, setFilters] = useState({});
  const [serverData, setServerData] = useState([]);
  const USER_NM = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    requestPersonalUserList();
    return () => {
      totalCount = 0;
      totalPageCount = 0;
      page = 0;
      rowsPerPage = 30;
    };
  }, [filters]);

  //업체 사용자 목록 불러오기
  const requestPersonalUserList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page,
      pageSize: 5,
      sort: [
        {
          field: "USER_ID",
          sort: "asc",
        },
      ],
      COMP_CD: "Y0001",
      ...filters,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          if (newData.page == 0) {
            setServerData(newData["returnData"]);
          } else {
            setServerData(serverData.concat(newData["returnData"]));
          }
        }
      }
    });
  };
  const actionSearch = () => {
    setFilters({
      USER_NM: USER_NM.current.value,
    });
  };

  const UserItemRender = useCallback((props) => {
    return <UserItem {...props} />;
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-2 border-bottom">
        <div className="p-2 rounded bg-light d-flex flex-row align-items-center">
          <input
            className="flex-grow-1 border-0 bg-transparent"
            type="text"
            placeholder={t("enterSearchNameMSG")}
            ref={USER_NM}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                actionSearch();
              }
            }}
          />
          <button
            className="border-0 bg-transparent p-2"
            onClick={() => actionSearch()}
          >
            <i className="fa-solid fa-magnifying-glass" />
          </button>
        </div>
        <div className="px-2 pt-2">
          <p className="h6 mb-0 font-weight-bold">
            {t("total")}{" "}
            <span className="font-weight-bold text-gbts">{totalCount}</span>
            {t("searchResult")}
          </p>
        </div>
      </div>
      <div className="px-2 pt-2 flex-grow-1">
        <div className="h-100 overflow_scroll-Y">
          {serverData.map((user) => (
            <UserItemRender
              key={`${user.COMP_CD}_${user.USER_ID}`}
              t={t}
              user={user}
              openUserInfoModal={openUserInfoModal}
              addFavUser={addFavUser}
            />
          ))}

          {serverData.length === totalCount && (
            <div className="pb-2">
              <p className="text-center bg-light rounded p-2">
                {t("successSearchMSG")}
              </p>
            </div>
          )}
          {serverData.length < totalCount && (
            <div className="pb-2">
              <button
                className="w-100 rounded font-weight-bold border-0 text-center bg-gbts text-white rounded p-2"
                onClick={() => {
                  if (page < totalPageCount) {
                    page++;
                    requestPersonalUserList();
                  }
                }}
              >
                {t("showMore")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const UserItem = React.memo((props) => {
  const { t, user, openUserInfoModal, addFavUser } = props;

  let userTeam = `${user.USER_TEAM_NM ? "[" + user.USER_TEAM_NM + "] " : ""}${
    user.USER_PART_NM
  }`;

  return (
    <div className="p-2 mb-2 bg-light rounded d-flex flex-row justify-content-between align-items-center">
      <div
        className="d-flex flex-row justify-content-start align-items-center mr-2"
        data-toggle="modal"
        data-target="#UserInfoModal"
        onClick={() => {
          openUserInfoModal(user);
        }}
      >
        <div
          className="border rounded-circle mr-3 position-relative"
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            background: `url(${
              user.USER_PIC ||
              "https://www.gbts.co.kr/images/user_pic-50x50.png"
            }) 50% 50% / cover no-repeat`,
          }}
        >
          <div
            className={`position-absolute border ${
              user["login_state"] ? "bg-info" : "bg-secondary"
            } rounded-circle`}
            style={{
              top: 0,
              left: 0,
              width: 15,
              height: 15,
            }}
          />
        </div>
        <div className="text-secondary">
          {(user.USER_TEAM_NM || user.USER_PART_NM) && (
            <p className="w-100 ellipsis_1">{userTeam}</p>
          )}
          <p className="h6 mb-0 font-weight-bold">
            {user.USER_NICK || user.USER_NM}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center text-gbts">
        <span
          className="h5 mb-0 mr-2 cursor_pointer"
          onClick={() => addFavUser(user)}
        >
          <i className="fa-solid fa-user-plus" />
        </span>
      </div>
    </div>
  );
});
