import React from "react";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();

const Row = ({ title, data, clickEvent, isLast = false, isLink = false }) => {
  let dataParams = {};
  if (clickEvent) {
    dataParams = {
      ...dataParams,
      className: "cursor_pointer text-info",
      onClick: () => clickEvent(),
    };
  }

  return (
    <div className={`row m-0 p-0 ${isLast ? "" : " border-bottom"}`}>
      <div className="col-4 m-0 p-2 border-right bg-light">{title}</div>
      <div className="col-8 m-0 p-2">
        <p {...dataParams}>
          {isLink ? (
            <a href={data} target="_blank">
              {data}
            </a>
          ) : (
            data
          )}
        </p>
      </div>
    </div>
  );
};

const ExportCompanyMobileItem = (props) => {
  const { row, handleSelectComp } = props;
  let customRowData = { ...row };
  let certificate = `${util.getDateToFormat(
    customRowData.EXC_CERTIFICATE_STARTDATE,
    "YYYY-MM-DD"
  )}~${util.getDateToFormat(
    customRowData.EXC_CERTIFICATE_ENDDATE,
    "YYYY-MM-DD"
  )}`;

  return (
    <div className="p-2">
      <div className="p-2 shadow-sm rounded">
        <div className="border">
          <Row title="상호명" data={customRowData.EXC_COMP_NM} />
          <Row title="대표자명" data={customRowData.EXC_COMP_CEO_NM} />
          <Row title="사업자등록번호" data={customRowData.EXC_COMP_NUM} />
          <Row title="지역" data={customRowData.EXC_LOCATION} />
          <Row title="인증연도" data={customRowData.EXC_YEAR} />
          <Row title="인증번호" data={customRowData.EXC_CERTIFICATE_NUM} />
          <Row title="인증기간" data={certificate} />
          <div className="row m-0 p-0 pb-2 pt-3">
            <div className="col-12 m-0 p-0 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  handleSelectComp(row);
                }}
              >
                <i className="fas fa-search" />
              </button>
              <p className="text-primary">기업정보</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ExportCompanyMobileItem);
