import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import $ from "jquery";

import CommonNav from "../../../CommonNav";
import BannerMarketing from "../../BannerMarketing";
import ScheduleAIRDirectTable from "./ScheduleAIRDirectTable";
// import ScheduleAIRPassThoughTable from "./ScheduleAIRPassThoughTable";
import ScheduleAirFreightCostModal from "./ScheduleAirFreightCostModal";
import Footer from "../../../Footer";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import { LocationAirSelectOption } from "../../../../lib/CommonUI";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleAIR";
const shUUID = util.makeUUIDv4();

let loginInfo = null;

let rowsPerPage = 10;
let page = 0;
let pageSize = 0;
let totalCount = 0;
let totalPageCount = 0;
let filters = {
  sm_FCL_LCL: "AIR",
  sm_POL_CODE: "",
  sm_POD_CODE: "",
  sm_PROMO_YN: "",
  ORIGIN: "",
  DEST: "",
  SEARCH_DATE_START: "",
  SEARCH_DATE_END: "",
  sm_FWD_NO: "", //항공사
  sm_PROVIDER_CODE: "", //공급사
};

let modal_data = null;
let isSearch = false;
let isShareAccess = false;
let airline_list = [];
let supplier_list = [];
let isPromoState = null;

let schedule_air_list = [];
let today = new Date();
let currentMonth = today.getMonth() + 1;
if (currentMonth < 10) {
  currentMonth = `0${currentMonth}`;
}
currentMonth = currentMonth + "";

export default function ScheduleAIR(props) {
  const [isDirect, setIsDirect] = useState("Y");
  const [isCostModalOpen, setIsCostModalOpen] = useState(false);
  const [isScheduleLoad, setIsScheduleLoad] = useState(true);
  const [selectWeekDateList, setSelectWeekDateList] = useState({});

  const [searchCodeList, setSearchCodeList] = useState([]);
  const location = useLocation();

  const $pol_select = useRef();
  const $pod_select = useRef();
  const $promo_check = useRef();

  const requestAirLineData = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/airline/JS_airline_select.php",
      msgID: msgID,
      data: {},
      sm_FCL_LCL: "AIR",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = newData["returnData"];
          let airline = [];
          let supplier = [];
          for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];
            if (data.AL_COMP_TYPE === "A") airline.push(data);
            if (data.AL_COMP_TYPE === "P") supplier.push(data);
          }
          airline_list = [...airline];
          supplier_list = [...supplier];
        }
      }
    });
  };

  (() => {
    requestAirLineData();
  })();

  useEffect(() => {
    if (location) {
      if (location.state && location.state.hasOwnProperty("type")) {
        if (location.state.type === "promo") {
          filters = {
            ...filters,
            sm_PROMO_YN: "Y",
          };
          isPromoState = { type: "promo" };
          $promo_check.current.checked = true;
        }
        if (location.state.hasOwnProperty("sm_POL_CODE")) {
          filters = {
            ...filters,
            sm_POL_CODE: location.state.sm_POL_CODE,
            sm_POD_CODE: location.state.sm_POD_CODE,
            ORIGIN: location.state.ORIGIN,
            DEST: location.state.DEST,
          };
          isSearch = true;
        }
      }
    }

    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData.ret) {
        loginInfo = userData["returnData"][0];
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret) {
            loginInfo = n["returnData"][0];
          }
        }
      }
    );

    if (codeList.codeAirCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
        _mClassName,
        (oldV, newV) => {
          setSearchCodeList(newV);
        }
      );
    } else {
      setSearchCodeList(codeList.codeAirCountryFullName);
    }

    makeWeekSelectOptions({ target: { value: currentMonth } });
    return () => {
      rowsPerPage = 10;
      page = 0;
      pageSize = 0;
      totalCount = 0;
      totalPageCount = 0;
      filters = {
        sm_FCL_LCL: "AIR",
        sm_POL_CODE: "",
        sm_POD_CODE: "",
        sm_PROMO_YN: "",
        ORIGIN: "",
        DEST: "",
        SEARCH_DATE_START: "",
        SEARCH_DATE_END: "",
        sm_FWD_NO: "", //항공사
        sm_PROVIDER_CODE: "", //공급사
      };

      modal_data = null;
      isSearch = false;
      isShareAccess = false;
      isPromoState = null;
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName
      );
    };
  }, []);

  // 항공 스케쥴 데이터 select
  const requestUserSelect = () => {
    setIsScheduleLoad(true);
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule_air/JS_schedule_air_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sm_DEPARTURE_DATE",
          dir: "asc",
        },
      ],
      sp_DISPLAY: 0,
      ...filters,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          schedule_air_list = newData["returnData"];
          pageSize = newData.pageSize;
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            schedule_air_list.length > 0
              ? parseInt(
                  parseInt(newData.pageSize, rowsPerPage) / rowsPerPage,
                  rowsPerPage
                ) +
                (parseInt(newData.pageSize, rowsPerPage) % rowsPerPage > 0
                  ? 1
                  : 0)
              : 0;
          setIsScheduleLoad(false);
        }
      }
    });
  };
  const changedFilters = (data) => {
    // if (isScheduleLoad) {
    //   codeList.Modal.current.alert("스케줄 조회중입니다.");
    //   return;
    // }

    filters = {
      ...filters,
      ...data,
    };

    page = 0;
    isSearch = true;
    if (data.hasOwnProperty("sm_PROMO_YN")) {
      requestUserSelect();
    }
  };

  //스케줄 검색
  const handleSearchSchedule = () => {
    page = 0;
    isSearch = true;
    requestUserSelect();
  };

  //검색필터 초기화
  const handleResetFilters = () => {
    $pol_select.current.clearAll();
    $pod_select.current.clearAll();
    $promo_check.current.checked = false;
    page = 0;
    filters = {
      sm_FCL_LCL: "AIR",
      sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
      sm_POL_CODE: "",
      sm_POD_CODE: "",
      sm_PROMO_YN: "",
      ORIGIN: "",
      DEST: "",
      SEARCH_DATE_START: "",
      SEARCH_DATE_END: "",
      sm_FWD_NO: "", //항공사
      sm_PROVIDER_CODE: "", //공급사
    };
    isSearch = false;
    requestUserSelect();
  };

  const handleCostModalOpen = (mainData, subData) => {
    if (!loginInfo) {
      codeList.Modal.current.alert(
        "포워더용 서비스입니다. \n화주는 로지웍에서 스케줄 및 운임조회가 가능합니다."
      );
      return false;
    }

    if (!util.checkCompGB(loginInfo)) {
      codeList.Modal.current.alert(
        "포워더용 서비스입니다. \n화주는 로지웍에서 스케줄 및 운임조회가 가능합니다."
      );
      return;
    }

    if (mainData && subData) {
      modal_data = { mainData, subData };
      setIsCostModalOpen(true);
      return;
    }
    modal_data = null;
    setIsCostModalOpen(false);
  };

  // 사용자 이용로그
  const handleUseHistoryData = (data) => {
    let log_data = {
      shUUID: shUUID,
      shIDX: "", //스케줄 No
      shDate: filters.sm_DOC_CLOSE, // 검색일자(출발일)
      shType: filters.sm_FCL_LCL, // FCL,LCL,항공
      shORIGIN: filters.sm_POL_CODE, //출발지 POL
      shDEST: filters.sm_POD_CODE, //도착지 POD
      shClick: 0, //클릭여부
      shClickFor: "", //클릭 분류
      shMethod: window.location.href, //발생 URL
      ...data,
    };
    if (loginInfo && loginInfo.hasOwnProperty("user_id")) {
      log_data["shCOMPCD"] = loginInfo.comp_cd;
      log_data["shUSERID"] = loginInfo.user_id;
    }

    INSERT_SCHEDULE_USE_HISTORY(log_data);
  };

  //사용자 접근 경로 데이터
  const INSERT_SCHEDULE_USE_HISTORY = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_log_insert.php",
      msgID: msgID,
      data: {},
      ...data,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  //페이지 이동
  const handleChangePage = (val) => {
    page = val;
    let top = document.querySelector("#banner_mark")?.clientHeight;
    window.scrollTo(0, top);
    requestUserSelect();
  };

  // 주단위 선택 핸들러
  const handleSearchDate = (value) => {
    let splitValue = value.split(" : ")[1].split("|");
    let startDate = new Date(splitValue[0]);
    let endDate = new Date(splitValue[1]);

    let sYear = startDate.getFullYear();
    let sMonth = startDate.getMonth() + 1;
    let sDate = startDate.getDate();

    let sLastDate = new Date(sYear, sMonth, 0);

    let eMonth = endDate.getMonth() + 1;

    let days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    let dateList = {};
    let cnt = 0;
    for (let i = 0; i < 7; i++) {
      let dateTxt = "";

      let month = 0;
      let date = 0;
      if (sDate + i > sLastDate.getDate()) {
        ++cnt;
        month = eMonth;
        date = cnt;
      } else {
        month = sMonth;
        date = sDate + i;
      }

      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      const realDate = new Date(new Date().getFullYear(), month - 1, date);

      dateTxt = `[${month}/${date}]`;
      dateList[days[i]] = { dateTxt: dateTxt, realDate: realDate };
      //dateList[days[i]] = dateTxt;
    }
    filters = {
      ...filters,
      SEARCH_DATE_START: splitValue[0],
      SEARCH_DATE_END: splitValue[1],
    };
    setSelectWeekDateList(dateList);
    requestUserSelect();
  };

  const makeWeekSelectOptions = (e) => {
    let year = today.getFullYear();
    let month = e.target.value;

    let sDate = new Date(year, month - 1, 1);
    let lastDay = new Date(
      sDate.getFullYear(),
      sDate.getMonth() + 1,
      0
    ).getDate();
    let endDate = new Date(sDate.getFullYear(), sDate.getMonth(), lastDay);

    let week = sDate.getDay();
    sDate.setDate(sDate.getDate() - week);
    let eDate = new Date(
      sDate.getFullYear(),
      sDate.getMonth(),
      sDate.getDate()
    );

    let obj = document.getElementById("sh_week");
    obj.options.length = 0;
    let seled = "";
    let weekCount = 1;

    while (endDate.getTime() >= eDate.getTime()) {
      let sYear = sDate.getFullYear();
      let sMonth = sDate.getMonth() + 1;
      let sDay = sDate.getDate();

      sMonth = sMonth < 10 ? "0" + sMonth : sMonth;
      sDay = sDay < 10 ? "0" + sDay : sDay;

      let stxt = weekCount + "주차 : " + sYear + "-" + sMonth + "-" + sDay;

      eDate.setDate(sDate.getDate() + 6);

      let eYear = eDate.getFullYear();
      let eMonth = eDate.getMonth() + 1;
      let eDay = eDate.getDate();

      eMonth = eMonth < 10 ? "0" + eMonth : eMonth;
      eDay = eDay < 10 ? "0" + eDay : eDay;

      let etxt = eYear + "-" + eMonth + "-" + eDay;

      if (
        today.getTime() > sDate.getTime() &&
        today.getTime() < eDate.getTime()
      ) {
        seled = stxt + "|" + etxt;
      }

      obj.options[obj.options.length] = new Option(
        stxt + "~" + etxt,
        stxt + "|" + etxt
      );

      sDate = new Date(
        eDate.getFullYear(),
        eDate.getMonth(),
        eDate.getDate() + 1
      );
      eDate = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate());
      weekCount += 1;
    }

    if (seled) {
      obj.value = seled;
      handleSearchDate(seled);
    }
  };

  return (
    <>
      <CommonNav />
      {modal_data && isCostModalOpen && (
        <ScheduleAirFreightCostModal
          loginInfo={loginInfo}
          modal_data={modal_data}
          handleCostModalOpen={handleCostModalOpen}
        />
      )}
      <main className={`py-5 mh-90vh bg-schedule`}>
        <BannerMarketing />
        <div className="container margin_30 mt-0">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="rounded shadow-sm">
                <div className="row m-0 p-0 rounded-top overflow-hidden text-center m-0">
                  <Link
                    to={"/ScheduleLCL"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark text-decoration-none font-weight-bold"
                    state={isPromoState}
                  >
                    해상 LCL
                  </Link>
                  <Link
                    to={"/ScheduleFCL"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark border-left border-right text-decoration-none font-weight-bold"
                    state={isPromoState}
                  >
                    해상 FCL
                  </Link>
                  <Link
                    to={"/ScheduleAIR"}
                    className="col-4 m-0 p-0 py-2 bg-freiscop text-white  text-decoration-none"
                    state={isPromoState}
                  >
                    <span className="font-size-09">
                      <i className="fa-solid fa-cart-flatbed" />
                    </span>{" "}
                    항공
                  </Link>
                </div>

                <div className="border-top bg-white rounded-bottom">
                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">주차</p>

                      <div className="d-flex flex-row border-bottom">
                        <select
                          className="border-0 py-1 bg-white"
                          defaultValue={currentMonth}
                          onChange={(e) => makeWeekSelectOptions(e)}
                        >
                          <option value="">-월-</option>
                          <option value="01">1월</option>
                          <option value="02">2월</option>
                          <option value="03">3월</option>
                          <option value="04">4월</option>
                          <option value="05">5월</option>
                          <option value="06">6월</option>
                          <option value="07">7월</option>
                          <option value="08">8월</option>
                          <option value="09">9월</option>
                          <option value="10">10월</option>
                          <option value="11">11월</option>
                          <option value="12">12월</option>
                        </select>
                        <select
                          id="sh_week"
                          className="border-0 flex-fill py-1 bg-white"
                          style={{ minWidth: 225 }}
                          onChange={(e) => {
                            handleSearchDate(e.target.value);
                          }}
                        >
                          <option value="">-월을 선택해주세요-</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">출발지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pol_select}
                        options={searchCodeList}
                        placeholder="출발지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            ORIGIN: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POL_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilters(data);
                        }}
                        {...LocationAirSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">도착지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pod_select}
                        options={searchCodeList}
                        placeholder="도착지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            DEST: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POD_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilters(data);
                        }}
                        {...LocationAirSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">항공사</p>
                      <Select
                        placeholder="업체를 선택해주세요"
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        multi
                        clearable
                        values={[]}
                        options={airline_list}
                        name={"AL_COMP_NM"}
                        sortBy={"AL_COMP_NM"}
                        searchBy={"AL_COMP_NM"}
                        valueField={"AL_COMP_CD"}
                        labelField={"AL_COMP_NM"}
                        autoFocus={false}
                        onChange={(value) => {
                          if (value.length < 1) {
                            changedFilters({ sm_FWD_NO: "" });
                            return;
                          }

                          let airlineKeys = [];
                          for (let i = 0; i < value.length; i++) {
                            let comp = value[i];
                            airlineKeys.push(comp.AL_COMP_CD);
                          }

                          airlineKeys = airlineKeys.join("|");
                          changedFilters({ sm_FWD_NO: airlineKeys });
                        }}
                        noDataRenderer={() => {
                          return (
                            <div className="px-2 py-1 font-weight-bold">
                              - 데이터 없음 -
                            </div>
                          );
                        }}
                        itemRenderer={({ item, itemIndex, methods }) => {
                          return (
                            <div key={itemIndex}>
                              <div
                                role="option"
                                aria-selected="false"
                                tabIndex="-1"
                                className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
                                onClick={() => {
                                  methods.addItem(item);
                                }}
                              >
                                {item.AL_COMP_LOGO && (
                                  <div
                                    className="d-inline-block mr-2"
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      background: `url(${item.AL_COMP_LOGO}) 50% 50% / contain no-repeat`,
                                    }}
                                  />
                                )}
                                <p className="d-inline-block">
                                  <b>{item.AL_COMP_NM}</b>{" "}
                                  <span className="font-size-08">
                                    [{item.AL_COMP_NM_EN}]
                                  </span>
                                </p>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">공급사</p>
                      <Select
                        placeholder="업체를 선택해주세요"
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        multi
                        clearable
                        values={[]}
                        options={supplier_list}
                        name={"AL_COMP_NM"}
                        sortBy={"AL_COMP_NM"}
                        searchBy={"AL_COMP_NM"}
                        valueField={"AL_COMP_CD"}
                        labelField={"AL_COMP_NM"}
                        autoFocus={false}
                        onChange={(value) => {
                          if (value.length < 1) {
                            changedFilters({ sm_PROVIDER_CODE: "" });
                            return;
                          }

                          let airlineKeys = [];
                          for (let i = 0; i < value.length; i++) {
                            let comp = value[i];
                            airlineKeys.push(comp.AL_COMP_CD);
                          }

                          airlineKeys = airlineKeys.join("|");
                          changedFilters({ sm_PROVIDER_CODE: airlineKeys });
                        }}
                        noDataRenderer={() => {
                          return (
                            <div className="px-2 py-1 font-weight-bold">
                              - 데이터 없음 -
                            </div>
                          );
                        }}
                        itemRenderer={({ item, itemIndex, methods }) => {
                          return (
                            <div key={itemIndex}>
                              <div
                                role="option"
                                aria-selected="false"
                                tabIndex="-1"
                                className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
                                onClick={() => {
                                  methods.addItem(item);
                                }}
                              >
                                {item.AL_COMP_LOGO && (
                                  <div
                                    className="d-inline-block mr-2"
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      background: `url(${item.AL_COMP_LOGO}) 50% 50% / contain no-repeat`,
                                    }}
                                  />
                                )}
                                <p className="d-inline-block">
                                  <b>{item.AL_COMP_NM}</b>{" "}
                                  <span className="font-size-08">
                                    [{item.AL_COMP_NM_EN}]
                                  </span>
                                </p>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p> </p>
                      <div className="d-flex flex-row">
                        <select
                          className="d-inline-block border-top-0 border-left-0 border-right-0 border-bottom bg-white py-1 mr-3"
                          onChange={(e) => {
                            setIsDirect(e.target.value);
                          }}
                        >
                          <option value={"Y"}>직항</option>
                          <option value={"N"}>경유</option>
                        </select>
                        <label className="container_check m-0 mb-2 mb-lg-0 mr-lg-3 p-0">
                          <input
                            type="checkbox"
                            className="position-static"
                            defaultChecked={filters.sm_PROMO_YN === "Y"}
                            ref={$promo_check}
                            onChange={(e) => {
                              isPromoState = e.target.checked
                                ? { type: "promo" }
                                : null;
                              changedFilters({
                                sm_PROMO_YN: e.target.checked ? "Y" : "",
                              });
                            }}
                          />
                          <span className="checkmark" />
                          <span className="ml-3">프로모션 필터</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 m-0 p-2 px-lg-3  d-flex flex-column flex-md-row justify-content-between align-items-md-end">
                      <p className="h6 font-weight-bold mt-3">
                        총{" "}
                        <span className="text-info font-weight-bold">
                          {isDirect === "Y" ? util.addCommas(pageSize) : 0}건
                        </span>
                        의 스케줄이 검색되었습니다
                      </p>
                      <div className="d-flex flex-row justify-content-end">
                        <button
                          className="btn_1 px-4 mr-2 rounded-sm"
                          onClick={handleSearchSchedule}
                        >
                          검색
                        </button>
                        <button
                          className="btn_1 bg-secondary border-secondary rounded-sm"
                          onClick={handleResetFilters}
                        >
                          검색조건 초기화
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              {isScheduleLoad ? (
                <div className="bg-light rounded p-5 h6 text-center">
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </div>
              ) : schedule_air_list.length > 0 ? (
                isDirect === "Y" ? (
                  <ScheduleAIRDirectTable
                    loginInfo={loginInfo}
                    page={page}
                    totalPageCount={totalPageCount}
                    schedule_air_list={schedule_air_list}
                    filters={filters}
                    selectWeekDateList={selectWeekDateList}
                    airline_list={airline_list}
                    supplier_list={supplier_list}
                    handleCostModalOpen={handleCostModalOpen}
                    handleChangePage={handleChangePage}
                    handleUseHistoryData={handleUseHistoryData}
                  />
                ) : (
                  <>
                    <div className="bg-white rounded p-5 h6 text-center">
                      조건에 맞는 스케줄이 존재하지 않습니다
                    </div>
                    {/*             <ScheduleAIRPassThoughTable
                      loginInfo={loginInfo}
                      page={page}
                      totalPageCount={totalPageCount}
                      schedule_air_list={schedule_air_list}
                      filters={filters}
                      selectWeekDateList={selectWeekDateList}
                      handleCostModalOpen={handleCostModalOpen}
                      handleChangePage={handleChangePage}
                      handleUseHistoryData={handleUseHistoryData}
                    />*/}
                  </>
                )
              ) : (
                <div className="bg-white rounded p-5 h6 text-center font-weight-bold text-secondary">
                  조건에 맞는 스케줄이 존재하지 않습니다
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
