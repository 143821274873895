import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import AdvertiseVideoManageTable from "./AdvertiseVideoManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const _mClassName = "AdvertiseVideoManage";
const vmCOMP_KIND_LIST = [
  { key: "Z01", title: "프레이스콥" },
  { key: "Z02", title: "로지톡" },
  { key: "Z03", title: "로지웍" },
  { key: "Z04", title: "로지킵" },
  { key: "B01", title: "포워더" },
  { key: "B02", title: "콘솔사" },
  { key: "B03", title: "선사" },
  { key: "E", title: "관세사" },
  { key: "F", title: "매체" },
  //  --------------------------------------
];
export default function AdvertiseVideoManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $vmCOMP_KIND = useRef();
  const $searchText = useRef();
  const $searchLevel = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);
  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_AD_VIDEO_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_AD_VIDEO_MANAGE_FILTER_CHANGED,
      _mClassName,
      {
        vmCOMP_KIND: $vmCOMP_KIND.current.value,
        SEARCH_WORD: $searchText.current.value,
        SEARCH_LEVEL: $searchLevel.current.value,
      },
      () => {}
    );
  };
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn  btn-outline-primary "
                      data-toggle="modal"
                      data-target="#AdvertiseVideoManageEdit"
                      onClick={addNewData}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="flex-fill d-flex flex-row justify-content-end align-items-center mb-2 bm-sm-0 order-1 order-md-1 order-lg-1">
                    <select
                      className="custom-select form-control ml-2"
                      ref={$vmCOMP_KIND}
                      onChange={() => {
                        searchFilterChanged();
                      }}
                      style={{ width: 150 }}
                    >
                      <option value="">업체구분</option>
                      {vmCOMP_KIND_LIST.map((item) => {
                        return (
                          <option value={item.key} key={item.key}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                    <div className="input-group ml-2">
                      <select
                        className="custom-select form-control"
                        ref={$searchLevel}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">검색필터</option>
                        <option value="vmCOMP_CD">회사코드</option>
                        <option value="COMP_NM">상호명</option>
                        <option value="vmTitle">영상제목</option>
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={(ref) => ($searchText.current = ref)}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <AdvertiseVideoManageTable
              isServerPaging={true}
              isServerSort={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
