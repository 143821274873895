/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CommonNav from "./CommonNav";
import PopupMain from "./popup/PopupMain";
import OpenLogiWork from "./banner/OpenLogiWork";
import CorpInfoReference from "./corpInfoReference/CorpInfoReference";
import Logi_talktalk from "./Logi_talktalk";
import MainService from "./MainService";
import LogiStory from "./logiStory/LogiStory";
import Ad from "./AD/Ad";
import LogiTalkLandingAdvertiseVideoCenter from "../logiTalkLanding/compnent/LogiTalkLandingAdvertiseVideoCenter";
import Footer from "./Footer";

const todayDateTime = +new Date();

export default function Main({ history }) {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const _popupMain = localStorage.getItem("PopupMain");

    if (_popupMain === null) {
      setIsShow(true);
    }

    if (_popupMain !== null && todayDateTime >= +new Date(_popupMain)) {
      setIsShow(true);
    }
  }, []);
  return (
    <>
      <CommonNav />
      {isShow && <PopupMain />}
      <main style={{ marginTop: 55 }}>
        <div id="mainTop" />
        <Logi_talktalk />
        <OpenLogiWork />
        <MainService />
        <CorpInfoReference />
        <LogiStory />
        <Ad />
        <LogiTalkLandingAdvertiseVideoCenter />
      </main>
      <Footer />
    </>
  );
}
