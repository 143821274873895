import React from "react";
import { useTranslation } from "react-i18next";

export default function NewsCompModal(props) {
  const { data, subscribeList, clickSubscribeBtn } = props;
  const isSubscribe = subscribeList.hasOwnProperty(data.cnmMainKey);
  const { t } = useTranslation();

  return (
    <div
      className="modal fade"
      id="NewsCompModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="NewsCompModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mt-0 d-flex flex-row align-items-center">
        <div className="modal-content border-0">
          <div className="modal-body">
            <div className="p-3 text-center">
              <div className="d-flex flex-row justify-content-center">
                <div
                  className="rounded-circle border mb-3"
                  style={{
                    width: 300,
                    height: 300,
                    background: `url(${data.cnmPicMain}) 50% 50% / cover no-repeat`,
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-center mb-2 font-size-08">
                <span className="px-2 py-1 rounded-pill border bg-light mr-1">
                  {data.cnmCompNM}
                </span>
                {!data.isEntire && (
                  <>
                    <span
                      className={`${
                        isSubscribe
                          ? "bg-light"
                          : "bg-freiscop text-white border-freiscop"
                      } px-2 py-1 rounded-pill border ml-1 cursor_pointer`}
                      onClick={() => clickSubscribeBtn(data)}
                    >
                      <span className="font-weight-bold">
                        {data.cnmSubscribe}
                        {t("person")}
                      </span>{" "}
                      {t("subscribe")}
                      {isSubscribe ? t("cancel") : ""}
                    </span>
                  </>
                )}
              </div>
              <p className="h5 font-weight-bold text-center">{data.cnmName}</p>
              <p className="h6 font-weight-bold">{data.cnmDesc}</p>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
              id="NewsCompModalClose"
            >
              <i className="fa fa-times mr-2" />
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
