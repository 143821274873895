import React, { useEffect, useState } from "react";
import CodeList from "../../../lib/CodeList";

const codeList = new CodeList();

const solutions = [
  {
    key: "logiwork",
    title: "기업 서비스 - 로지웍(LOGIWORK)",
    sectionItem: (params) => <LogiWork {...params} />,
  },
  {
    key: "freiscop",
    title: "포워더 서비스 - 프레이스콥(FREISCOP)",
    sectionItem: (params) => <Freiscop {...params} />,
  },
  {
    key: "other",
    title: "다양한 디지털 물류 서비스",
    sectionItem: (params) => <Other {...params} />,
  },
];

export default function TabSection(props) {
  const { isMobile, loginInfo } = props;

  const scrollMove = (id) => {
    const targetOffsetTop = document.querySelector(`#${id}`).offsetTop - 20;
    window.scrollTo({ top: targetOffsetTop, behavior: "smooth" });
  };

  return (
    <div>
      <div>
        {solutions.map((item) => {
          return (
            <div key={item.key} id={item.key}>
              {item.sectionItem({
                isMobile: isMobile,
                loginInfo: loginInfo,
                scrollMove: scrollMove,
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LogiWork({ isMobile, scrollMove }) {
  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-start justify-content-xl-between mb-2">
        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1 border-freiscop text-freiscop`}
            onClick={() => {
              scrollMove("logiwork");
            }}
          >
            기업 서비스 - 로지웍(LOGIWORK)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("freiscop");
            }}
          >
            포워더 서비스 - 프레이스콥(FREISCOP)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("other");
            }}
          >
            다양한 디지털 물류 서비스
          </p>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5">
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              견적문의
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              물류비 견적을 매번 문의하지 말고
              <span className="text-freiscop">
                {" "}
                <br className="d-none d-xl-block" />
                직접 조회하고 부킹까지{" "}
              </span>
              한 번에 실행
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              시세등락을 반영한 물류 공급자의 원가를 직접 조회하고{" "}
              <br className="d-none d-xl-block" />
              신뢰할 수 있는 물류비용을 보장 받으세요.
            </p>
          </div>
        </div>
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="d-flex flex-row justify-content-center">
            <img height="auto" width="80%" src="/img/info/estimate.png" />
          </div>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5 bg-light">
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              업무협업
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              <span className="text-freiscop">수출입 문서작성</span>과{" "}
              <span className="text-freiscop">선적・통관・보험오더</span>를{" "}
              <br className="d-none d-xl-block" />
              로지웍에서 한 번에 해결
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              인보이스, 패킹리스트 등 수출입 문서를 시스템에서
              <br className="d-none d-xl-block" />
              작성하고 필요한 물류사(포워더, 관세사, 운송사, 보험사) 서비스를
              바로 주문하세요.
            </p>
          </div>
        </div>
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="d-flex flex-row justify-content-center">
            <img
              height="auto"
              width="80%"
              src="/img/info/cooperation_work.png"
            />
          </div>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5">
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              물류 시각화
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              물류현황이 한 눈에 들어오는{" "}
              <span className="text-freiscop">
                <br className="d-none d-xl-block" />
                시각화 자료
              </span>
              로 효율적인 화물관리
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              선박/항공기 실시간 위치조회 서비스와 관세청 화물진행 정보를 결합한
              소비자 맞춤형 조회화면(UI)을 제공하는 종합 SCM 관리 시스템입니다.
            </p>
          </div>
        </div>
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="d-flex flex-row justify-content-center">
            <img
              height="auto"
              width="80%"
              src="/img/info/visualization_freight.png"
            />
          </div>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5 bg-light">
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              문서관리
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              <span className="text-freiscop">문서관리 대행 서비스</span>로{" "}
              <br className="d-none d-xl-block" />
              관리비용과 업무량을 동시에 절감
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              무역서류를 클라우드 서버에 등록(영구보관)해주는 서비스로 업무
              담당자 변경으로 인한 문서분실을 예방하고 언제 어디서나 수출입
              문서를 다운로드 받으세요.
            </p>
          </div>
        </div>
        <div className="col-12 col-xl-6 m-0 p-0">
          <div className="d-flex flex-row justify-content-center">
            <img height="auto" width="80%" src="/img/info/doc_manage.png" />
          </div>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5">
        <div className="col-12 col-xl-5 m-0 p-0">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 border border-freiscop text-freiscop`}
            >
              이용료
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              물류비 절감과 업무방식을 개선하는
              <span className="text-freiscop">
                {" "}
                <br className="d-none d-xl-block" />
                합리적인 디지털 서비스 이용료
              </span>
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              부담 없이 가입신청 문의하시면 담당자가 즉시 연락드립니다. GBTS
              무료회원 가입하시고 다양한 디지털 서비스를 이용하세요.
            </p>
            <div className="w-100 text-center text-xl-left">
              <button
                className="btn px-4 bg-freiscop text-white"
                onClick={() => {
                  window.open(
                    "http://home.gbts.co.kr/qa-join/",
                    "_blank",
                    "width=1200, height=800"
                  );
                }}
              >
                <i className="fa-regular fa-paper-plane mr-2" />
                문의하기
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-7 m-0 p-0 pl-xl-3">
          <div className="row m-0 py-3">
            <div className="col-12 col-sm-4 py-2 p-sm-2 p-xl-0 pr-xl-2">
              <div className="h-100 px-3 py-4 rounded-lg shadow d-flex flex-row flex-sm-column align-items-center">
                <div className="d-flex flex-column align-items-center text-center mr-2 mr-xl-0">
                  <p
                    className={`${isMobile ? "h6" : "h5"} mb-0 font-weight-900`}
                  >
                    STANDARD
                  </p>
                  <p
                    className={`${
                      isMobile ? "" : "h6 mb-0"
                    } text-secondary font-weight-bold`}
                  >
                    [소규모 기업 추천]
                  </p>
                  <div className="w-50 border-bottom border-width-4 border-freiscop my-3" />
                  <p
                    className={`${
                      isMobile ? "h6" : "h5"
                    } font-weight-bold mb-2`}
                  >
                    &#8361;9만원/월
                    <span className="font-size-05"> (VAT별도)</span>
                  </p>
                </div>
                <ul>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>USER 3개 이하</p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      계약건수 월 20건 <br />
                      (초과시 3,000원/건 과금)
                    </p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      LCL 수입 1주일 창고료 무료
                    </p>
                  </li>
                  <li className="d-flex flex-row">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      (공통) 선사&콘솔사별 운임, 부대비용 비교
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-4 py-2 p-sm-2 p-xl-0 pr-xl-2">
              <div className="h-100 px-3 py-4 rounded-lg shadow d-flex flex-row flex-sm-column align-items-center">
                <div className="d-flex flex-column align-items-center text-center mr-2 mr-xl-0">
                  <p
                    className={`${isMobile ? "h6" : "h5"} mb-0 font-weight-900`}
                  >
                    BUSINESS
                  </p>
                  <p
                    className={`${
                      isMobile ? "" : "h6 mb-0"
                    } text-secondary font-weight-bold`}
                  >
                    [중소기업 추천]
                  </p>
                  <div className="w-50 border-bottom border-width-4 border-freiscop my-3" />
                  <p
                    className={`${
                      isMobile ? "h6" : "h5"
                    } font-weight-bold mb-2`}
                  >
                    &#8361;19만원/월
                    <span className="font-size-05"> (VAT별도)</span>
                  </p>
                </div>
                <ul>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>USER 10개 이하</p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      계약건수 월 100건 <br />
                      (초과시 1,500원/건 과금)
                    </p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      LCL 수입 1주일 창고료 무료
                    </p>
                  </li>
                  <li className="d-flex flex-row">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      (공통) 선사&콘솔사별 운임, 부대비용 비교
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-4 py-2 p-sm-2 p-xl-0 pr-xl-2">
              <div className="h-100 px-3 py-4 rounded-lg shadow d-flex flex-row flex-sm-column align-items-center">
                <div className="d-flex flex-column align-items-center text-center mr-2 mr-xl-0">
                  <p
                    className={`${isMobile ? "h6" : "h5"} mb-0 font-weight-900`}
                  >
                    PREMIUM
                  </p>
                  <p
                    className={`${
                      isMobile ? "" : "h6 mb-0"
                    } text-secondary font-weight-bold`}
                  >
                    [중견기업 추천]
                  </p>
                  <div className="w-50 border-bottom border-width-4 border-freiscop my-3" />
                  <p
                    className={`${
                      isMobile ? "h6" : "h5"
                    } font-weight-bold mb-2`}
                  >
                    &#8361;29만원/월
                    <span className="font-size-05"> (VAT별도)</span>
                  </p>
                </div>
                <ul>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>USER 20개 이하</p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      계약건수 월 250건 <br />
                      (초과시 500원/건 과금)
                    </p>
                  </li>
                  <li className="d-flex flex-row align-items-baseline mb-1 mb-xl-2">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      LCL 수입 1주일 창고료 무료
                    </p>
                  </li>
                  <li className="d-flex flex-row">
                    <span
                      className="d-inline-flex flex-row align-items-center justify-content-center bg-freiscop text-white rounded-circle mr-1 font-size-06"
                      style={{ minWidth: 13, height: 13 }}
                    >
                      <i className="fa-solid fa-check" />
                    </span>
                    <p style={{ fontSize: "1.1em" }}>
                      (공통) 선사&콘솔사별 운임, 부대비용 비교
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Freiscop({ isMobile, scrollMove }) {
  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-start justify-content-xl-between mb-2">
        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("logiwork");
            }}
          >
            기업 서비스 - 로지웍(LOGIWORK)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1 border-freiscop text-freiscop`}
            onClick={() => {
              scrollMove("freiscop");
            }}
          >
            포워더 서비스 - 프레이스콥(FREISCOP)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("other");
            }}
          >
            다양한 디지털 물류 서비스
          </p>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5 bg-light">
        <div className="col-12 col-xl-5 m-0 p-0">
          <div className="h-100 d-flex flex-row justify-content-center align-items-center">
            <img
              height="auto"
              width="80%"
              src="/img/info/freiscop_.channel.png"
            />
          </div>
        </div>
        <div className="col-12 col-xl-7 m-0 p-0 order-first order-xl-last">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start align-items-xl-end text-left text-xl-right">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              단일채널
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              선사, 콘솔사 및 항공 스케쥴과 운임을 검색하고
              <br className="d-none d-xl-block" /> 예약하는
              <span className="text-freiscop"> 통합 E-BOOKING 채널</span>
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              } m-0 font-weight-bold text-secondary`}
            >
              전화, 이메일 없이 클릭으로 운임조회하고 예약관리하세요
            </p>
          </div>
        </div>
      </div>
      <div className="row m-0 p-3 p-xl-5">
        <div className="col-12 col-xl-5 m-0 p-0">
          <div className="d-flex flex-row justify-content-center">
            <img
              height={isMobile ? "400" : "250"}
              src="/img/info/e-book_note.png"
            />
          </div>
        </div>
        <div className="col-12 col-xl-7 m-0 p-0 text-right order-first order-xl-last">
          <div className="h-100 d-flex flex-column justify-content-between align-items-start align-items-xl-end text-left text-xl-right">
            <p
              className={`${
                isMobile ? "" : "h6"
              } font-weight-bold m-0 d-inline-block px-2 py-xl-2 px-xl-4 bg-freiscop text-white`}
            >
              예약 업데이트
            </p>
            <p
              className={` ${
                isMobile ? "h5" : "h2"
              } mb-0 mt-2 mb-1 my-xl-3 font-weight-900 text-dark`}
            >
              예약과정에서 발생하는 변동내역을 자동반영하는
              <br className="d-none d-xl-block" />
              <span className="text-freiscop"> E-BOOKING NOTE </span>
              <span className="font-size-06 text-dark">(URL 형식)</span>
            </p>
            <p
              className={`${
                isMobile ? "h6 mb-3" : "h5"
              }  m-0 font-weight-bold text-secondary`}
            >
              예약 단계(접수
              <i className="fa-solid fa-arrow-right mx-1" />
              진행중
              <i className="fa-solid fa-arrow-right mx-1" />
              완료)와 예약정보(회사, 스케쥴, 선박추적 등)를 포함한
              <br className="d-none d-xl-block" /> E-BOOKING NOTE를 URL형식으로
              거래처에 전달해 보세요
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Other({ isMobile, loginInfo, scrollMove }) {
  return (
    <>
      <div className="d-flex flex-row flex-wrap justify-content-start justify-content-xl-between mb-2">
        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("logiwork");
            }}
          >
            기업 서비스 - 로지웍(LOGIWORK)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1`}
            onClick={() => {
              scrollMove("freiscop");
            }}
          >
            포워더 서비스 - 프레이스콥(FREISCOP)
          </p>
        </div>

        <div className={`px-3 flex-grow-1`}>
          <p
            className={`${
              isMobile ? "h6" : "h5"
            } d-block border-bottom border-width-3 text-center p-3 font-weight-900 text-secondary cursor_pointer transition-1 border-freiscop text-freiscop`}
            onClick={() => {
              scrollMove("other");
            }}
          >
            다양한 디지털 물류 서비스
          </p>
        </div>
      </div>
      <div>
        <div className="row m-0 p-3 pb-xl-5 bg-light">
          <div className="col-12 col-md-4 m-0 p-0">
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <h5 className="font-weight-bold">물류기업 전문 메신저</h5>
              <h3 className="font-weight-900 text-freiscop">
                로지톡(LOGITALK)
              </h3>
              <img
                width="140px"
                className="my-3"
                src="img/info/logitalk_logo.png"
                alt="logiTalk"
              />
              <button
                className="btn px-4 bg-freiscop text-white"
                onClick={() => {
                  if (loginInfo === null) {
                    codeList.Modal.current.alert(
                      "로그인이 필요한 서비스입니다."
                    );
                    return;
                  } else {
                    window.open("/LogiTalk", "_blank", "width=1200 height=800");
                  }
                }}
              >
                <i className="fa-solid fa-arrow-right mr-2" />
                바로가기
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 m-0 py-3 p-xl-4">
            <div className="d-flex flex-row justify-content-center h-100">
              <div className="w-100 p-3 rounded-lg border shadow d-flex flex-column align-items-center bg-white">
                <img
                  src="/img/info/icon_phone.png"
                  alt="icon_phone"
                  className="mb-3"
                />
                <h5 className="font-weight-bold mb-2">스마트폰 동기화</h5>
                <div className="w-50 border-bottom mb-3" />
                <ul>
                  <li className="mb-2">
                    <div className="d-flex flex-row h6 m-0">
                      <span className="mr-2">·</span>PC, 태블릿, 스마트폰
                      화면에서 GBTS 서비스 전체 이용
                    </div>
                  </li>
                  <li className="mb-2">
                    <div className="d-flex flex-row h6 m-0">
                      <span className="mr-2">·</span>개인정보 보호 사생활 분리
                      설정
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 m-0 py-3 p-xl-4">
            <div className="d-flex flex-row justify-content-center h-100">
              <div className="w-100 p-3 rounded-lg border shadow d-flex flex-column align-items-center bg-white">
                <img
                  src="/img/info/icon_search.png"
                  alt="icon_search"
                  className="mb-3"
                />
                <h5 className="font-weight-bold mb-2">물류기업 및 화주 검색</h5>
                <div className="w-50 border-bottom mb-3" />
                <ul>
                  <li className="mb-2">
                    <div className="d-flex flex-row h6 m-0">
                      <span className="mr-2">·</span>GBTS 가입업체(360여개) 검색
                      후 대화기능
                    </div>
                  </li>
                  <li className="mb-2">
                    <div className="d-flex flex-row h6 m-0">
                      <span className="mr-2">·</span>검색화면에서 신용조회 동시
                      제공
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-3 pb-md-4 px-xl-0 py-xl-5">
          <div className="col-12 col-md-4 m-0 p-0">
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <h5 className="font-weight-bold">지도기반 전국 창고검색</h5>
              <h3 className="font-weight-900 text-freiscop">
                로지킵(LOGIKEEP)
              </h3>
              <img
                width="160px"
                className="my-3"
                src="/img/info/logikeep_icon.png"
                alt="logikeep"
              />
              <button
                className="btn px-4 bg-freiscop text-white"
                onClick={() => {
                  window.open("/storeMall", "_blank", "width=1200 height=800");
                }}
              >
                <i className="fa-solid fa-arrow-right mr-2" />
                바로가기
              </button>
            </div>
          </div>
          <div className="d-none d-md-block col-12 col-md-4 m-0 p-0">
            <div className="h-100 d-flex flex-row align-items-center">
              <img
                height="auto"
                width="100%"
                src="/img/info/laptop_logikeep.png"
                alt="logikeep"
              />
            </div>
          </div>
          <div className="col-12 col-md-4 m-0 p-0 mt-5 mt-xl-0">
            <div className="h-100 px-3">
              <div className="h-50 d-flex flex-row align-items-end align-items-xl-center pr-4">
                <div className="bg-white h-80 px-3 py-4 position-relative border border-light shadow-sm mb-xl-4">
                  <div
                    className="py-2 px-4 bg-freiscop text-white position-absolute font-weight-bold"
                    style={{ top: "0", transform: "translateY(-50%)" }}
                  >
                    SERVICE
                  </div>
                  <p className="mt-2 h6 mb-0">
                    보세화물의 보관,분류,포장 등을 위하여 보세창고를 필요로 하는
                    당사자가 쉽게 검색할 수 있도록 전국의 보세창고 정보를
                    지도기반으로 제공하는 서비스입니다.
                  </p>
                </div>
              </div>

              <div className="h-50 d-flex flex-row align-items-end align-items-xl-center pr-4">
                <div className="w-100 bg-white h-80 px-3 py-4 mt-5 mt-xl-0 position-relative border border-light shadow-sm">
                  <div
                    className="py-2 px-4 bg-freiscop text-white position-absolute font-weight-bold"
                    style={{ top: "0", transform: "translateY(-50%)" }}
                  >
                    PROCESS
                  </div>
                  <p className="mt-2 h6 mb-0">
                    회원가입한 보세창고가 제공하는 시설,장비,서비스 등을
                    이용자가 미리 확인하고 견적과 보관기간등을 문의할 수 있도록
                    보세창고 견적과 예약을 중개합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5">
        <div className="row m-0 mb-xl-3 p-0">
          <div className="col-12 col-md-5 m-0 mb-3 mb-xl-0 p-0 pr-xl-3">
            <div className="h-100 d-flex flex-row justify-content-center justify-content-xl-end align-items-center">
              <div className="d-flex flex-column align-items-center">
                <img
                  className="mb-4"
                  src="/img/info/logitalk_logo.png"
                  width={isMobile ? "80" : "120"}
                  alt="logitalk_logo"
                />
                <div className={`${isMobile ? "h6" : "h3"} font-weight-bold`}>
                  <p>1:1 고객소통 전문 채널톡 서비스</p>
                </div>
                <p
                  className={`${
                    isMobile ? "h4" : "h2"
                  } font-weight-900 text-freiscop mb-3`}
                >
                  로지톡톡(LOGI T-TALK)
                </p>
                <ul className="mb-3 px-2 px-xl-0">
                  <li>
                    <div className={`d-flex flex-row ${isMobile ? "" : "h6"}`}>
                      <i className="fa-solid fa-check mr-2 mt-1" />
                      <p>스마트 톡연결 : 상담직원 톡연결 서비스</p>
                    </div>
                  </li>
                  <li>
                    <div className={`d-flex flex-row ${isMobile ? "" : "h6"}`}>
                      <i className="fa-solid fa-check mr-2 mt-1" />
                      <p>
                        이지챗봇 : 이미지, 바로가기 링크, 텍스트 기반 챗봇 안내
                        서비스{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className={`d-flex flex-row ${isMobile ? "" : "h6"}`}>
                      <i className="fa-solid fa-check mr-2 mt-1" />
                      <p>챗GPT : 물류무역 전문 ‘베스트 Chat-GPT’ 탑재</p>
                    </div>
                  </li>
                </ul>
                <button
                  className="btn btn_1"
                  onClick={() => {
                    window.open(
                      "https://talktalk.gbts.co.kr/?site=GBTS",
                      "_blank",
                      "width=1200, height=800"
                    );
                  }}
                >
                  <p className="h6 m-0">
                    <i className="fa-solid fa-arrow-right mr-2" />
                    바로가기
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 m-0 p-0">
            <div className="d-flex flex-row justify-content-center align-items-end mx-3">
              {isMobile ? (
                <img
                  className="rounded-lg shadow mr-3"
                  width="60%"
                  src="/img/info/logiTalkTalk.png"
                  alt="logitalktalk"
                />
              ) : (
                <img
                  className="rounded-lg shadow mr-xl-5"
                  width="400px"
                  src="/img/info/logiTalkTalk.png"
                  alt="logitalktalk"
                />
              )}
              <img
                src="/img/info/employee_chat.png"
                alt="employee_chat"
                width="20%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
