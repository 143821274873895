import React, { useEffect, useState, useRef, useCallback } from "react";
import Select from "react-dropdown-select";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import CommonNav from "../../main/CommonNav";
import Footer from "../../main/Footer";
import OpenLogiWork from "../../main/banner/OpenLogiWork";
import CorpInfoReference from "../../main/corpInfoReference/CorpInfoReference";
import LogiStory from "../../main/logiStory/LogiStory";
import Ad from "../../main/AD/Ad";
import LogiTalkLandingAdvertiseVideoCenter from "../../logiTalkLanding/compnent/LogiTalkLandingAdvertiseVideoCenter";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { LocationSeaSelectOption } from "../../lib/CommonUI";
import { Link } from "react-router-dom";
import { CountUp } from "../../lib/CountUp";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "MainTest";

export default function MainTest() {
  return (
    <>
      <CommonNav />
      <main style={{ marginTop: 55 }} className="S-CORE text-dark">
        <LineBanner />
        <MainCarousel />
        <Promotion />
        <CorpInfoReference />
        <LogiStory />
        <Ad />
        <LogiTalkLandingAdvertiseVideoCenter />
      </main>
      <Footer />
    </>
  );
}

const LineBanner = () => {
  return (
    <div
      className="m-0 p-3 px-lg-0 py-lg-2"
      style={{
        background: `url(/img/main/main_banner_background.jpg) 0% 0%/cover no-repeat`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-9 m-0 p-0 ">
            <p className="S-CORE h4 mb-1 text-white">
              디지털 수출입 물류 ALL IN ONE 서비스{" "}
              <span className="font-weight-bold m-0 text-yellow text">
                로지웍(LogiWork)
              </span>
            </p>
            <p className="text-white">
              스케쥴 & 운임 조회 │ 온라인 물류부킹 │ 수출입 문서 생성 │ 클라우드
              문서관리 │ 화물위치 확인 │ 물류 AI 챗봇 │ 기업신용 정보조회
            </p>
          </div>
          <div className="col-12 col-sm-3 m-0 p-0 d-flex flex-row justify-content-end align-items-center">
            <button
              className="S-CORE btn btn-sm btn-light border-0 font-weight-bold text-info mt-2 mt-lg-0"
              onClick={() => window.open("https://system.gbts.co.kr/")}
            >
              로지웍 바로가기 <i className="fa-solid fa-right-to-bracket" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

let amount = {
  FCL: 0,
  LCL: 0,
  AIR: 0,
  TOTAL: 0,
};
const MainCarousel = () => {
  const [selected, setSelected] = useState("FCL");
  const [seaPortList, setSeaPortList] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const counterRef = useRef();
  const backgroundList = {
    FCL: "/img/carousel/main_carousel_fcl.jpg",
    LCL: "/img/carousel/main_carousel_lcl.jpg",
    AIR: "/img/carousel/main_carousel_air.jpg",
  };

  useEffect(() => {
    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setSeaPortList(newV);
        }
      );
    } else {
      setSeaPortList(codeList.codeCountryFullName);
    }
    requestScheduleAmount();
  }, []);

  const requestScheduleAmount = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_count_select.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          const data = newData["returnData"][0];
          amount = {
            FCL: Number(data.FCL) + Number(data.FCL2),
            LCL: Number(data.FCL),
            AIR: Number(data.AIR),
          };
          setIsLoad(false);
        }
      }
    });
  };

  useEffect(() => {
    if (!isLoad && counterRef.current) {
      initCountUp();
    }
  }, [isLoad, selected]);

  async function initCountUp() {
    let counterAnim = new CountUp(
      counterRef.current,
      parseInt(amount[selected])
    );
    if (!counterAnim.error) {
      counterAnim.start();
    } else {
      console.error(counterAnim.error);
    }
  }

  const FadeIamge = useCallback(() => {
    return (
      <div
        className="w-100 h-100 mx-auto fade-in-box"
        style={{
          minHeight: 560,
          background: `url(${backgroundList[selected]}) 50% 50% /cover no-repeat`,
        }}
      />
    );
  }, [selected]);

  return (
    <section className="position-relative">
      <FadeIamge />
      <div
        className="position-absolute main-carousel-mask w-100 h-100 d-flex flex-column justify-content-center"
        style={{ zIndex: 2, top: 0, left: 0 }}
      >
        <div className="container mb-5 lineUp">
          <div className="row mb-3 text-white">
            <div className="col-12 S-CORE pl-4">
              <p className="h4 mb-1">간편한 스케줄 & 운임조회</p>
              <p className="h4 mb-0">BOOKING(선복예약)을 한번에</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="px-3 pt-3 pb-2 bg-white rounded-lg shadow">
                <div className="d-flex flex-row border-bottom">
                  <p
                    className={`h6 mb-0 py-2 px-4 cursor_pointer ${
                      selected === "FCL"
                        ? "text-freiscop font-weight-bold border-bottom border-freiscop border-width-2"
                        : ""
                    }`}
                    onClick={() => setSelected("FCL")}
                  >
                    해상 FCL
                  </p>
                  <p
                    className={`h6 mb-0 py-2 px-4 cursor_pointer ${
                      selected === "LCL"
                        ? "text-freiscop font-weight-bold border-bottom border-freiscop border-width-2"
                        : ""
                    }`}
                    onClick={() => setSelected("LCL")}
                  >
                    해상 LCL
                  </p>
                  <p
                    className={`h6 mb-0 py-2 px-4 cursor_pointer ${
                      selected === "AIR"
                        ? "text-freiscop font-weight-bold border-bottom border-freiscop border-width-2"
                        : ""
                    }`}
                    onClick={() => setSelected("AIR")}
                  >
                    항공
                  </p>
                </div>
                <p className="h6 mt-2 mb-0 pl-2">
                  총{" "}
                  <span ref={counterRef} className="text-info font-weight-bold">
                    0
                  </span>
                  <span className="text-info font-weight-bold">건</span>의{" "}
                  {selected === "AIR" ? "항공" : "해상"}
                  {selected} 스케줄을 조회하였습니다.
                </p>
                <div className="d-flex flex-column flex-lg-row mt-2">
                  <input
                    type="date"
                    className="flex-grow-1 border-top-0 border-left-0 border-bottom-0 border-width-3 border-white bg-light rounded-top-left rounded-bottom-left p-2 mb-2"
                  />
                  <div className="main-react-select-wrap flex-grow-1 mb-2">
                    <Select
                      className="d-inline-flex w-100 bg-light p-2 border-top-0 border-width-3 border-white border-bottom-0 border-left-0 rounded-0"
                      options={seaPortList}
                      onChange={(e) => {
                        console.log(e);
                      }}
                      placeholder="출발지를 선택해주세요"
                      {...LocationSeaSelectOption}
                    />
                  </div>
                  <div className="main-react-select-wrap flex-grow-1 mb-2">
                    <Select
                      className="d-inline-flex w-100 bg-light p-2 border-top-0 border-bottom-0 border-left-0 border-white border-width-3 rounded-0 rounded-bottom-right rounded-top-right"
                      options={seaPortList}
                      placeholder="도착지를 선택해주세요"
                      onChange={(e) => {
                        console.log(e);
                      }}
                      {...LocationSeaSelectOption}
                    />
                  </div>
                  <button className="btn btn-primary bg-freiscop border-freiscop rounded-0 rounded-top-right rounded-bottom-right mb-2">
                    검색
                    <i className="fa-solid fa-magnifying-glass ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Promotion = () => {
  const [promoList, setPromoList] = useState([]);

  useEffect(() => {
    requestSchedulePromotion();
  }, []);

  const requestSchedulePromotion = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_promotion_by_main_select.php",
      msgID: msgID,
      data: {},
      skip: 0,
      page: 1,
      take: 6,
      sp_DISPLAY: 1,
      sm_USE_YN: "Y",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          /*setPromoList([
            {
              sm_IDX: "12",
              sm_ORIGIN: "웨이하이 (WEIHAI)",
              sm_DEST: "인천 (INCHEON)",
              sm_POL_CODE: "CNWEI",
              sm_POD_CODE: "KRINC",
              sm_POL_NAME: "웨이하이",
              sm_POD_NAME: "인천",
              sm_PROVIDER_CODE: "GLSN",
              sm_PROMO_MEMO: "[로지톡 견적문의]\n커피쿠폰 무료증정",
              COMP_NM: "(주)지엘에스콘솔",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/gbts/companyInfo/20230518125328_GLS.PNG?GLS.PNG",
              sp_LCL_FCL: "LCL",
            },
            {
              sm_IDX: "8",
              sm_ORIGIN: "인천 (INCHEON)",
              sm_DEST: "호치민 (HOCHIMINH)",
              sm_POL_CODE: "KRINC",
              sm_POD_CODE: "VNSGN",
              sm_POL_NAME: "인천",
              sm_POD_NAME: "호치민",
              sm_PROVIDER_CODE: "NSS",
              sm_PROMO_MEMO: "베트남 전포트\nDOC FEE 면제",
              COMP_NM: "남성해운",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/bLogo/20230330101453_남성.png?남성.png",
              sp_LCL_FCL: "FCL",
            },
            {
              sm_IDX: "6",
              sm_ORIGIN: "부산 (PUSAN)",
              sm_DEST: "첸나이 (CHENNAI)",
              sm_POL_CODE: "KRPUS",
              sm_POD_CODE: "INMAA",
              sm_POL_NAME: "부산",
              sm_POD_NAME: "첸나이",
              sm_PROVIDER_CODE: "MARE",
              sm_PROMO_MEMO: "스타벅스 아메리카노 쿠폰",
              COMP_NM: "마레콘솔",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/bLogo/20230823180130_화면_캡처_20230823_180119.png?화면_캡처_20230823_180119.png",
              sp_LCL_FCL: "LCL",
            },
            {
              sm_IDX: "5",
              sm_ORIGIN: "부산 (PUSAN)",
              sm_DEST: "주나쉐바 (NHAVA SHEVA)",
              sm_POL_CODE: "KRPUS",
              sm_POD_CODE: "INNSA",
              sm_POL_NAME: "부산",
              sm_POD_NAME: "주나쉐바",
              sm_PROVIDER_CODE: "MARE",
              sm_PROMO_MEMO: "스타벅스 아메리카노 쿠폰",
              COMP_NM: "마레콘솔",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/bLogo/20230823180130_화면_캡처_20230823_180119.png?화면_캡처_20230823_180119.png",
              sp_LCL_FCL: "LCL",
            },
            {
              sm_IDX: "4",
              sm_ORIGIN: "부산 (PUSAN)",
              sm_DEST: "카라치 (KARACHI)",
              sm_POL_CODE: "KRPUS",
              sm_POD_CODE: "PKKHI",
              sm_POL_NAME: "부산",
              sm_POD_NAME: "카라치",
              sm_PROVIDER_CODE: "MARE",
              sm_PROMO_MEMO: "스타벅스 아메리카노 쿠폰",
              COMP_NM: "마레콘솔",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/bLogo/20230823180130_화면_캡처_20230823_180119.png?화면_캡처_20230823_180119.png",
              sp_LCL_FCL: "LCL",
            },
            {
              sm_IDX: "1",
              sm_ORIGIN: "부산 (PUSAN)",
              sm_DEST: "서코우 (SHEKOU)",
              sm_POL_CODE: "KRPUS",
              sm_POD_CODE: "CNSHK",
              sm_POL_NAME: "부산",
              sm_POD_NAME: "서코우",
              sm_PROVIDER_CODE: "NSS",
              sm_PROMO_MEMO: "스타벅스 쿠폰 증정",
              COMP_NM: "남성해운",
              SHIP_IMG_URL10: "",
              COMP_LOGO:
                "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/bLogo/20230330101453_남성.png?남성.png",
              sp_LCL_FCL: "FCL",
            },
          ]);*/
          setPromoList(newData["returnData"]);
        }
      }
    });
  };

  const PromoItem = ({ item }) => {
    return (
      <div className="col-6 col-md-4 m-0 p-0 p-1">
        <Link
          to={
            item.sp_LCL_FCL === "LCL"
              ? "/ScheduleLCL"
              : item.sp_LCL_FCL === "FCL"
              ? "/ScheduleFCL"
              : "/ScheduleAIR"
          }
          state={{
            type: "promo",
            sm_POL_CODE: item.sm_POL_CODE,
            sm_POD_CODE: item.sm_POD_CODE,
            ORIGIN: item.sm_ORIGIN,
            DEST: item.sm_DEST,
          }}
          className="d-inline-block rounded p-2 w-100 h-100 border border-light shadow-sm text-dark cursor_pointer_shadow text-decoration-none"
        >
          <div className="d-flex flex-row align-items-center pb-2 border-bottom">
            <div
              className="mr-1"
              style={{
                width: 30,
                height: 30,
                background: `url(${item.COMP_LOGO}) 50% 50%/contain no-repeat`,
              }}
            />
            <div>
              <p className="font-weight-bold ellipsis_1 font-size-08">
                {item.COMP_NM.trim()}
              </p>
              <p className="text-info font-weight-bold ellipsis_1 font-size-09">
                {item.sm_POL_NAME} → {item.sm_POD_NAME}
              </p>
            </div>
          </div>
          <p className="pt-2 font-weight-900 text-secondary ellipsis_2 font-size-09">
            {item.sm_PROMO_MEMO}
          </p>
        </Link>
      </div>
    );
  };

  return (
    <div className="container my-5">
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
            <h3 className="font-weight-bold mb-1">
              이벤트(추천 선사&항공사 프로모션)
            </h3>
            <div className="text-right">
              <Link to="/" className="btn btn-sm btn-danger">
                바로가기
                <i className="fa-solid fa-arrow-right-long ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {promoList.length > 0 && (
        <div className="row px-3">
          {promoList.map((item, index) => {
            let data = { ...item };
            if (item.COMP_LOGO.indexOf("?") > -1) {
              data.COMP_LOGO = item.COMP_LOGO.split("?").find(
                (item) => item.indexOf("http") > -1
              );
            }
            return <PromoItem key={index} item={data} />;
          })}
        </div>
      )}
      {promoList.length === 0 && (
        <div className="row">
          <div className="col-12">
            <div className="p-5 bg-light rounded text-center">
              <p className="h6 mb-0 font-weight-bold text-secondary">
                현재 진행중인 이벤트가 없습니다.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
