import React, { useCallback, useEffect, useRef, useState } from "react";
import CommonNav from "../main/CommonNav";
import Footer from "../main/Footer";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const vmCOMP_KIND_LIST = [
  { key: "Z01", title: "프레이스콥" },
  { key: "Z02", title: "로지톡" },
  { key: "Z03", title: "로지웍" },
  { key: "Z04", title: "로지킵" },
  { key: "other", title: "파트너" },
];

let video_list = [];

export default function AdvertiseVideoCenter() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const SEARCH_WORD = useRef();

  useEffect(() => {
    requestVideoList();
  }, []);

  //검색 활성
  const handleSearch = (vmCOMP_KIND) => {
    let filter = {
      SEARCH_WORD: SEARCH_WORD.current.value,
    };
    if (vmCOMP_KIND !== undefined) {
      filter.vmCOMP_KIND = vmCOMP_KIND;
    }
    requestVideoList(filter);
  };

  //영상목록
  const requestVideoList = (filter) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/advertise_video/JS_advertise_video_customer_select.php",
      msgID: msgID,
      data: {},
      ...filter,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          video_list = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const VideoItemRender = useCallback(({ item }) => {
    return <VideoItem {...item} />;
  }, []);

  const VideoItem = React.memo((props) => {
    const { vmLink, vmTitle, COMP_NM, COMP_LOGO, vmTopYN } = props;
    return (
      <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3 d-flex flex-row justify-content-center">
        <div className="w-100 position-relative">
          <div
            className="position-absolute w-100 h-100 cursor_pointer"
            style={{ zIndex: 1 }}
            onClick={() => {
              window.open(vmLink, "_blank", "width=1200,height=800");
            }}
          />

          {vmTopYN === "Y" && (
            <div
              className="position-absolute border rounded px-3 bg-white font-weight-bold"
              style={{ top: 5, right: 5, fontSize: "0.9em" }}
            >
              고정
            </div>
          )}
          <iframe
            className="rounded border"
            width="100%"
            height="200"
            src={vmLink}
            title={vmTitle}
          />
          <div className="d-flex flex-row justify-content-start align-items-center mt-2">
            <div
              className="d-flex flex-row justify-content-center align-items-center rounded mr-2"
              style={{
                background: `url(${COMP_LOGO}) 50% 50% /contain no-repeat`,
                width: 50,
                height: 50,
              }}
            />
            <div className="text-secondary">
              <h6 className="mb-0 font-weight-bold">{vmTitle}</h6>
              <p>{COMP_NM}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="white_bg" id="write_QnA" style={{ minHeight: "100vh" }}>
      <CommonNav />
      <main className="white_bg">
        <div className="container-xl bg-white mt-5">
          <div className="BM-DH border-bottom  p-5">
            <p className="h2 mb-1 text-freiscop d-flex flex-row align-items-center justify-content-center">
              <span className="h1 mb-0 d-none d-sm-inline">
                <i className="fa-regular fa-circle-play mr-3" />
              </span>
              <span>프레이스콥 홍보영상 센터</span>
            </p>
            <p className="h5 text-secondary mb-0 text-left text-md-center">
              프레이스콥의 다양한 서비스 영상과 회원사 소개영상을 만나보세요.
            </p>
          </div>
          <div className="position-relative px-5 pb-4">
            <div className="px-2 px-md-5 py-3 mt-3 d-flex flex-column align-items-center">
              <div
                className="input-group border overflow-hidden rounded-pill mb-3"
                style={{ maxWidth: 600 }}
              >
                <input
                  id="inputGroupSelect01"
                  type="text"
                  className="form-control"
                  placeholder="검색"
                  ref={SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      handleSearch();
                    }
                  }}
                />
                <div className="input-group-append overflow-hidden">
                  <span
                    className="btn btn-secondary input-group-append px-4 btn-hover-freiscop child-center"
                    onClick={() => handleSearch()}
                  >
                    <i className="fa-solid fa-magnifying-glass" />
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <div className="p-1">
                  <button
                    className="btn btn-sm btn-dark btn-hover-freiscop"
                    onClick={() => {
                      SEARCH_WORD.current.value = "";
                      handleSearch("");
                    }}
                  >
                    전체
                  </button>
                </div>
                {vmCOMP_KIND_LIST.map((item) => {
                  return (
                    <div className="p-1" key={item.key}>
                      <button
                        className="btn btn-sm btn-secondary px-3 btn-hover-freiscop"
                        onClick={() => handleSearch(item.key)}
                      >
                        {item.title}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row m-0 p-0 ">
              {video_list.length ? (
                video_list.map((item, index) => {
                  return <VideoItemRender key={item.vmIDX} item={item} />;
                })
              ) : (
                <div className="px-5 col">
                  <div className="mx-5 py-5 bg-light rounded h5 font-weight-bold text-secondary text-center mb-0">
                    검색조건에 맞는 영상이 존재하지 않습니다.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
