import React, { useCallback } from "react";
import Utilities from "../../lib/Utilities";
import { Link } from "react-router-dom";
import CorpInfoReferencePrint from "./CorpInfoReferencePrint";

const util = new Utilities();

export default function CorpInfoReferenceDetailNav(props) {
  const {
    historyKey,
    handleChangePage,
    pageList,
    pagesOpen,
    compInfo,
    isExistData,
  } = props;
  let DOC_DATE = "-";

  if (isExistData && compInfo !== null) {
    for (let item of compInfo["1000003"]) {
      switch (item.item_nm) {
        case "보고서 기준일자":
          DOC_DATE = item.item_val;
          break;
      }
    }
  }
  const NavItem = useCallback(
    (props) => {
      return <NavItemRender {...props} />;
    },
    [pagesOpen]
  );

  const NavItemRender = React.memo(({ page }) => {
    let navClass =
      "p-2 mr-2 border border-freiscop border-bottom-0 font-weight-bold bg-white text-freiscop ";

    if (pagesOpen[page.key]) {
      navClass = navClass + "bg-freiscop text-white";
    }

    return (
      <button
        className={navClass}
        onClick={() => {
          handleChangePage(page.key);
        }}
      >
        {page.name}
      </button>
    );
  });

  return (
    <>
      <p className="mb-2 d-block d-lg-none">
        미확인(null)정보는 신용보증기금에서 제공하지 않은 정보입니다.
      </p>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-end">
        <div className="d-flex flex-row justify-content-start align-items-center">
          {pageList.map((page) => {
            return <NavItem key={page.key} page={page} />;
          })}
          <p className="ml-2 d-none d-lg-block">
            미확인(null)정보는 신용보증기금에서 제공하지 않은 정보입니다.
          </p>
        </div>
        {compInfo && (
          <div className="order-first order-sm-last mb-1 d-flex flex-row align-items-center">
            <h6 className="text-secondary">기준일자 : {DOC_DATE}</h6>
            {historyKey !== "" && (
              <>
                <button
                  className="btn btn-sm btn-secondary py-1 px-2 ml-2"
                  onClick={() =>
                    util.openPopUp(
                      `/freiscop/CorpInfoReferencePrint/${historyKey}`,
                      "CorpInfoReferencePrint",
                      window.innerWidth
                    )
                  }
                >
                  인쇄 미리보기 <i className="fa-solid fa-print" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
