import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import CommonNav from "../main/CommonNav";
import Footer from "../main/Footer";
import Loading from "../lib/Loading";

import BuddibleSocket from "../lib/BuddibleSocket";
import CodeList from "../lib/CodeList";
import Utilities from "../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function DeleteAccount() {
  const [isLoading, setIsLoading] = useState(false);

  const controller = {
    COMP_CD: useRef(),
    USER_ID: useRef(),
    RET_EMAIL: useRef(),
    REASON: useRef(),
    USER_NM: useRef(),
  };

  const checkValidation = () => {
    if (controller.COMP_CD.current.value.trim() === "") {
      codeList.Modal.current.alert("업체코드를 입력해주세요.", () => {
        controller.COMP_CD.current.focus();
      });
      return true;
    }
    if (controller.USER_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("사용자명을 입력해주세요", () => {
        controller.USER_NM.current.focus();
      });
      return true;
    }
    if (controller.USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert("계정을 입력해주세요", () => {
        controller.USER_ID.current.focus();
      });
      return true;
    }
    if (controller.RET_EMAIL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "삭제결과 회신 이메일을 입력해주세요",
        () => {
          controller.RET_EMAIL.current.focus();
        }
      );
      return true;
    }
    if (controller.REASON.current.value.trim() === "") {
      codeList.Modal.current.alert("탈퇴사유를 입력해주세요", () => {
        controller.REASON.current.focus();
      });
      return true;
    }

    return false;
  };

  const requestDeleteAccount = () => {
    if (checkValidation()) return;
    setIsLoading(true);
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/contact_me.php",
      msgID: msgID,
      COMP_CD: controller.COMP_CD.current.value,
      USER_ID: controller.USER_ID.current.value,
      RET_EMAIL: controller.RET_EMAIL.current.value,
      REASON: controller.REASON.current.value,
      USER_NM: controller.USER_NM.current.value,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        setIsLoading(false);
        if (newData["ret"]) {
          codeList.Modal.current.alert("탈퇴요청이 정상처리되었습니다.", () => {
            window.location.replace("/");
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  return (
    <>
      <CommonNav />
      {isLoading && <Loading />}
      <main style={{ marginTop: 55 }}>
        <div className="container margin_60">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div>
                <h4 className="font-weight-bold mb-2">계정삭제 요청</h4>
                <p>
                  <span className="font-weight-bold text-freiscop">
                    (주)지비티에스
                  </span>
                  에 등록된 회원정보를 삭제하는데 이용하는 페이지입니다.
                </p>
              </div>
              <div className="my-3">
                <h6 className="mb-1">※ 계정 삭제 절차</h6>
                <p>
                  1. 하단 양식에 맞추서 삭제하고자 하는 계정정보를 입력해주세요.
                </p>
                <p>
                  2. 제출한 정보와 탈퇴사유는 관리자에게 전달되어 검토 후
                  계정삭제가 진행됩니다.
                </p>
                <p>
                  3. 계정 및 수집된 사용자 정보(
                  <Link to="/Terms/privacyPolicy">자세히 보기</Link>) 삭제가
                  완료된 후 회신 이메일로 결과를 전달합니다.
                </p>
              </div>

              <div>
                <div className="mb-2">
                  <p>업체코드</p>
                  <input className="form-control" ref={controller.COMP_CD} />
                </div>
                <div className="mb-2">
                  <p>사용자명</p>
                  <input className="form-control" ref={controller.USER_NM} />
                </div>
                <div className="mb-2">
                  <p>계정</p>
                  <input className="form-control" ref={controller.USER_ID} />
                </div>
                <div className="mb-2">
                  <p>삭제결과 회신 이메일</p>
                  <input className="form-control" ref={controller.RET_EMAIL} />
                </div>
                <div className="mb-2">
                  <p>탈퇴사유</p>
                  <textarea
                    className="form-control"
                    ref={controller.REASON}
                    rows={5}
                  />
                </div>
                <p className="text-danger font-weight-bold mb-2">
                  ※ 회원 탈퇴 요청시 2-3일정도 소요가 됩니다. 탈퇴 처리 관련
                  결과 및 안내는 회신 메일로 전송됩니다.
                </p>
                <div className="text-center">
                  <button
                    className="btn_1"
                    onClick={() => requestDeleteAccount()}
                  >
                    회원 탈퇴요청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
