/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const _mClassName = "BLogoManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function BLogoManageEdit(props, ref) {
  const { requestUserSelect } = props;
  const [modalData, setModalData] = useState({});
  const [uploadFile, setUploadFile] = useState("");

  const $controller = {
    blIDX: useRef(),
    blFWDNO: useRef(),
    blCompNM: useRef(),
    blCompNM_KO: useRef(),
    blLOGO: useRef(),
    blUseYN: useRef(),
    blCompType: useRef(),
    blSchDisplay: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {}
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setModalData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openEditModal(e, params) {
      handleModalData(e, params);
    },
    deleteData(e, params) {
      deleteModalData(e, params);
    },
  }));

  //모달 데이터 세팅
  const handleModalData = (e, params) => {
    setModalData(params);
  };

  useEffect(() => {
    if (modalData.hasOwnProperty("currentData")) {
      $controller.blIDX.current.value = modalData.currentData.blIDX;
      $controller.blFWDNO.current.value = modalData.currentData.blFWDNO;
      $controller.blCompNM.current.value = modalData.currentData.blCompNM;
      $controller.blCompNM_KO.current.value = modalData.currentData.blCompNM_KO;
      $controller.blLOGO.current.value = modalData.currentData.blLOGO;
      $controller.blUseYN.current.value = modalData.currentData.blUseYN;
      $controller.blCompType.current.value = modalData.currentData.blCompType;
      $controller.blSchDisplay.current.value =
        modalData.currentData.blSchDisplay;
      setUploadFile(modalData.currentData.blLOGO);
    } else {
      $controller.blIDX.current.value = "";
      $controller.blFWDNO.current.value = "";
      $controller.blCompNM.current.value = "";
      $controller.blCompNM_KO.current.value = "";
      $controller.blLOGO.current.value = "";
      $controller.blUseYN.current.value = "";
      $controller.blCompType.current.value = "";
      $controller.blSchDisplay.current.value = "";
      setUploadFile("");
    }
  }, [modalData]);

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileLink}?${fileName}`;
      $controller.blLOGO.current.value = fileFull;
      setUploadFile(fileFull);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //유효성검사
  const checkValidation = () => {
    if (
      $controller.blCompNM.current &&
      $controller.blCompNM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상호명(영문)을 입력해주세요");
      return true;
    }
    if (
      $controller.blCompType.current &&
      $controller.blCompType.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("업체구분을 선택해주세요");
      return true;
    }
    if (
      $controller.blFWDNO.current &&
      $controller.blFWDNO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("포워더 No를 입력해주세요.");
      return true;
    }
    if (
      $controller.blCompNM_KO.current &&
      $controller.blCompNM_KO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상호명(국문)을 입력해주세요");
      return true;
    }
    if (
      $controller.blLOGO.current &&
      $controller.blLOGO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("로고이미지를 업로드해주세요");
      return true;
    }
    if (
      $controller.blUseYN.current &&
      $controller.blUseYN.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("사용여부를 선택해주세요");
      return true;
    }
    if (
      $controller.blSchDisplay.current &&
      $controller.blSchDisplay.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("표기여부를 선택해주세요.");
      return true;
    }
    return false;
  };

  //홍보영상 저장
  const actionSave = () => {
    if (checkValidation()) return;

    const updateData = {
      blIDX: $controller.blIDX.current.value
        ? Number($controller.blIDX.current.value)
        : "",
      blFWDNO: $controller.blFWDNO.current.value,
      blCompType: $controller.blCompType.current.value,
      blCompNM: $controller.blCompNM.current.value,
      blCompNM_KO: $controller.blCompNM_KO.current.value,
      blLOGO: $controller.blLOGO.current.value,
      blUseYN: $controller.blUseYN.current.value,
      blSchDisplay: $controller.blSchDisplay.current.value,
      blDelYN: "N",
    };
    requestUserUpdate(updateData);
  };

  // 데이터 삭제
  const deleteModalData = (e, params) => {
    let updateData = {
      ...params.currentData,
    };
    codeList.Modal.current.confirm(
      `${updateData.blCompNM_KO}의 로고정보를 삭제 하시겠습니까?`,
      (ret) => {
        if (ret) {
          updateData.blDelYN = "Y";
          requestUserUpdate(updateData);
        }
      }
    );
  };

  //등록 및 수정 서버 요청
  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: updateData.blIDX
        ? "/b_logo/JS_b_logo_update.php"
        : "/b_logo/JS_b_logo_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          socket.fireLocalEventListener(
            MsgIDList.EVENT_BLOGO_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );

          requestUserSelect();
          codeList.Modal.current.alert(newData["MSG"], () => {
            closeEditModal();
          });
        } else {
          codeList.Modal.current.alert(
            "요청 처리중에 문제가 발생하였습니다. 잠시후 다시 시도해주세요"
          );
        }
      }
    });
  };

  //값 초기화 및 모달 닫기
  const closeEditModal = () => {
    for (let key in $controller) {
      if ($controller[key].current) {
        $controller[key].current.value = "";
      }
    }
    setModalData({});
    $("#bLogoManageEditClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="bLogoManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="bLogoManageEdit"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>선사 · 항공사 로고관리</strong>
              </h3>

              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="bLogoManageEditClose"
                onClick={() => {
                  closeEditModal();
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    readOnly={true}
                    ref={$controller.blIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체구분
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.blCompType}>
                    <option value="">미선택</option>
                    <option value="A">항공사</option>
                    <option value="B">콘솔사</option>
                    <option value="F">선사</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  포워더 넘버
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="포워더 넘버"
                    ref={$controller.blFWDNO}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  상호명(국문)
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="상호명(국문)을 입력해주세요"
                    ref={$controller.blCompNM_KO}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  상호명(영문)
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="상호명(영문)을 입력해주세요"
                    ref={$controller.blCompNM}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">LOGO</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control d-none"
                    placeholder="상호명(영문)을 입력해주세요"
                    ref={$controller.blLOGO}
                  />
                  <UploadImageToS3WithPhp
                    region={"mall/bLogo"}
                    callback={UploadFiles}
                  />
                  <div className="pb-2">
                    <p
                      style={{
                        fontSize: "small",
                        color: "#8f8f8f",
                      }}
                    >
                      ※ 이미지는 100X100 사이즈를 권장합니다.
                    </p>
                  </div>
                  <div
                    className="rounded bg-light d-flex flex-row justify-content-center align-items-center"
                    style={{
                      minHeight: 100,
                      background: `url(${
                        uploadFile ? uploadFile.split("?")[0] : ""
                      }) 50% 50% /contain no-repeat`,
                    }}
                  >
                    {!uploadFile && (
                      <p className="h6 m-0 font-weight-bold text-secondary">
                        LOGO
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용여부
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.blUseYN}>
                    <option value="">-미선택-</option>
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  표기여부
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    ref={$controller.blSchDisplay}
                  >
                    <option value="">-미선택-</option>
                    <option value="Y">표기</option>
                    <option value="N">미표기</option>
                  </select>
                  <p className="text-danger font-size-08 mt-1">
                    ※ 스케줄조회시 업체구분에 사용되는지에 대한 선택입니다
                  </p>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary mr-2"
                onClick={() => closeEditModal()}
              >
                취소
              </button>
              <button className="btn btn-primary" onClick={() => actionSave()}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(BLogoManageEdit);
