import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class MMSI {
  constructor(data) {
    if (MMSI.exists) {
      return MMSI.instance;
    }
    MMSI.instance = this;
    MMSI.exists = true;
    return this;
  }
}
//
let mmsiList = {
  200000000 : 'NEGO AIUB',
  205178000 : 'CMA CGM MASAI MARA',
  205795000 : 'A LA MARINE',
  209056000 : 'BEATRIZ B',
  209087000 : 'CAPE PIONEER',
  209135000 : 'CONTSHIP WIN',
  209138000 : 'WARNOW CHIEF',
  209140000 : 'WARNOW MATE',
  209177000 : 'WEC VERMEER',
  209247000 : 'BG SAPPHIRE',
  209248000 : 'BG EMERALD',
  209251000 : 'PUSAN C',
  209258000 : 'DUBAI FORTUNE',
  209276000 : 'ELBWAVE',
  209335000 : 'CONTSHIP PRO',
  209345000 : 'CONTSHIP NEW',
  209361000 : 'SAMSKIP INNOVATOR',
  209370000 : 'ST. MARY',
  209380000 : 'SAMSKIP ENDEAVOUR',
  209423000 : 'MSC DARDANELLES',
  209425000 : 'WARNOW BOATSWAIN',
  209437000 : 'BLACK RHINO',
  209444000 : 'CONTSHIP FOX',
  209459000 : 'CONTSHIP ERA',
  209467000 : 'X-PRESS AGILITY',
  209470000 : 'MSC BOSPHORUS',
  209504000 : 'CONTSHIP ICE',
  209539000 : 'BF CARP',
  209540000 : 'BF TROUT',
  209541000 : 'BF PERCH',
  209543000 : 'THETIS D',
  209550000 : 'AMERICA',
  209559000 : 'MSC APOLLO',
  209570000 : 'ASIAN MOON',
  209575000 : 'CONTSHIP PAX',
  209576000 : 'NILEDUTCH BREDA',
  209591000 : 'CONTSHIP TEN',
  209592000 : 'CONTSHIP VIE',
  209593000 : 'CONTSHIP ZOE',
  209594000 : 'CONTSHIP WAY',
  209613000 : 'BF LETICIA',
  209615000 : 'ATHENS GLORY',
  209616000 : 'NILEDUTCH ANTWERPEN',
  209659000 : 'CONTAINERSHIPSAURORA',
  209692000 : 'CONTSHIP YEN',
  209695000 : 'NORDMAAS',
  209696000 : 'RANGER',
  209697000 : 'CONTSHIP LEO',
  209699000 : 'CONTSHIP LUV',
  209700000 : 'DUBAI ALLIANCE',
  209705000 : 'CONTSHIP AIR',
  209715000 : 'ELBSKIPPER',
  209716000 : 'MSC AQUARIUS',
  209717000 : 'CONTAINERSHIPSARCTIC',
  209719000 : 'BG IRELAND',
  209762000 : 'NCL SALTEN',
  209795000 : 'SYNERGY KEELUNG',
  209798000 : 'SYNERGY OAKLAND',
  209836000 : 'CT ROTTERDAM',
  209849000 : 'GFS PRIME',
  209850000 : 'ANDROMEDA J',
  209862000 : 'WARNOW WHALE',
  209888000 : 'CAPE ALTIUS',
  209912000 : 'CMACGM FORT ST LOUIS',
  209920000 : 'CMA CGM NABUCCO',
  209979000 : 'LE HAVRE',
  210001000 : 'ALASA',
  210008000 : 'JORK RULER',
  210017000 : 'ELBWIND',
  210028000 : 'CONTSHIPS BOREALIS',
  210057000 : 'FALMOUTH',
  210065000 : 'QUEEN B III',
  210070000 : 'CAPE CITIUS',
  210072000 : 'WEC VAN EYCK',
  210101000 : 'EINSTEIN',
  210142000 : 'GFS PEARL',
  210159000 : 'WARNOW DOLPHIN',
  210167000 : 'ELBSPRINTER',
  210195000 : 'CONTSHIPS STELLAR',
  210210000 : 'CAPE CORFU',
  210281000 : 'WEC VAN RIJN',
  210283000 : 'WEC DE HOOGH',
  210296000 : 'QUEEN B II',
  210308000 : 'CAPE HELLAS',
  210312000 : 'WEC MAJORELLE',
  210332000 : 'GERDA',
  210359000 : 'ELBWATER',
  210361000 : 'CONTSHIP EVE',
  210367000 : 'CONTSHIP PEP',
  210403000 : 'CAPE ARAXOS',
  210440000 : 'CONTSHIP SKY',
  210497000 : 'CAPE SYROS',
  210514000 : 'CONTSHIP ART',
  210565000 : 'ASIAN TRADER',
  210568000 : 'ELBRUNNER',
  210663000 : 'SAN GIORGIO',
  210696000 : 'DUBAI ENTERPRISE',
  210731000 : 'MSC ELKE F',
  210740000 : 'LENA',
  210749000 : 'PACIFIC TRADER',
  210804000 : 'NORDLION',
  210905000 : 'ARSOS',
  210950000 : 'VARAMO',
  210989000 : 'IONIA',
  211003531 : 'QUERIN TEST 2',
  211108000 : 'BRUSSELS EXPRESS',
  211111000 : 'RIODEJANEIRO EXPRESS',
  211112000 : 'MONTEVIDEO EXPRESS',
  211119000 : 'BERLIN EXPRESS',
  211120000 : 'HANOI EXPRESS',
  211141000 : 'BIANCA RAMBOW',
  211235880 : 'WEGA',
  211256150 : 'CHIQUITA FARMER',
  211281220 : 'INDEPENDENT SPIRIT',
  211286440 : 'HANNI',
  211315100 : 'CONTAINERSHIPS 6',
  211549000 : 'BASLE EXPRESS',
  211553000 : 'BENEDIKT',
  211578000 : 'ULSAN EXPRESS',
  211779000 : 'NORTHERN MAJESTIC',
  211839000 : 'CHICAGO EXPRESS',
  211845000 : 'CONTAINERSHIPS VIII',
  211870240 : 'NOVA',
  211882920 : 'BARZAN',
  211882930 : 'UMM QARN',
  211886490 : 'AL JMELIYAH',
  211886500 : 'AL ZUBARA',
  211886810 : 'AL NASRIYAH',
  211887090 : 'LINAH',
  211887410 : 'BUENOS AIRES EXPRESS',
  211888820 : 'AFIF',
  211895580 : 'AL DAHNA EXPRESS',
  211897760 : 'AL MURAYKH',
  212006000 : 'JUDITH',
  212008000 : 'ELBWINTER',
  212018000 : 'X-PRESS ELBE',
  212175000 : 'EUROPE',
  212267000 : 'CONTSHIP IVY',
  212276000 : 'SEATTLE C',
  212283000 : 'NORDTIGER',
  212289000 : 'NORDPUMA',
  212347000 : 'HYUNDAI TOKYO',
  212348000 : 'HYUNDAI HONGKONG',
  212350000 : 'HYUNDAI BUSAN',
  212351000 : 'HYUNDAI SINGAPORE',
  212352000 : 'MSC LILOU III',
  212353000 : 'HYUNDAI SHANGHAI',
  212357000 : 'MSC YOSHEEN',
  212417000 : 'VIOLETA B',
  212433000 : 'NORDLEOPARD',
  212463000 : 'CAPE MONTEREY',
  212531000 : 'NORDPANTHER',
  212540000 : 'CAPE FORTIUS',
  212557000 : 'PROTOSTAR N',
  212563000 : 'MARINA ONE',
  212587000 : 'CAPE QUEST',
  212597000 : 'THEA II',
  212613000 : 'RUTH',
  212636000 : 'HANNA',
  212637000 : 'BG DIAMOND',
  212641000 : 'WEC VAN GOGH',
  212659000 : 'NORDAMELIA',
  212714000 : 'VANCOUVER',
  212723000 : 'BF FORTALEZA',
  212724000 : 'SAN LORENZO',
  212748000 : 'BALTIC TERN',
  212752000 : 'BG JADE',
  212777000 : 'WILHELM',
  212814000 : 'NORDPACIFIC',
  212851000 : 'DUBAI VENTURE',
  212906000 : 'BALTIC SHEARWATER',
  212917000 : 'FOUMA',
  212931000 : 'ELBFEEDER',
  212935000 : 'ELBCARRIER',
  212960000 : 'QUEEN B',
  212962000 : 'CAPE ORIENT',
  212963000 : 'WES GESA',
  215020000 : 'MICHIGAN',
  215125000 : 'CMA CGM A. LINCOLN',
  215126000 : 'CMA CGM T.JEFFERSON',
  215127000 : 'CMA CGM CENDRILLON',
  215131000 : 'CMA CGM LYRA',
  215134000 : 'CMA CGM GEMINI',
  215137000 : 'CMA CGM J. MADISON',
  215141000 : 'CMA CGM CORAL',
  215146000 : 'CMA CGM ARISTOTE',
  215148000 : 'CMA CGM AMBER',
  215154000 : 'CMA CGM CORTE REAL',
  215157000 : 'CMA CGM PLATON',
  215158000 : 'CMA CGM MAGELLAN',
  215159000 : 'CMA CGM SWORDFISH',
  215164000 : 'CMA CGM ANDROMEDA',
  215165000 : 'CMA CGM VIRGINIA',
  215167000 : 'ANL WANGARATTA',
  215173000 : 'CMACGM VASCO DE GAMA',
  215175000 : 'CMA CGM BLUE WHALE',
  215176000 : 'GABRIEL A',
  215177000 : 'MARIO A',
  215179000 : 'TEOMAN A',
  215181000 : 'CMA CGM J. ADAMS',
  215183000 : 'CMA CGM NEW JERSEY',
  215185000 : 'CMA CGM LAMARTINE',
  215187000 : 'CMA CGM TARPON',
  215189000 : 'CMA CGM FLORIDA',
  215192000 : 'CMA CGM CALLISTO',
  215195000 : 'CMA CGM B FRANKLIN',
  215196000 : 'CMA CGM CASSIOPEIA',
  215197000 : 'CMA CGM G.WASHINGTON',
  215199000 : 'CMA CGM ZHENG HE',
  215206000 : 'ANL WYONG',
  215207000 : 'CMA CGM KERGUELEN',
  215215000 : 'CMA CGM MAUPASSANT',
  215217000 : 'CMA CGM AQUILA',
  215219000 : 'CMA CGM VON HUMBOLDT',
  215221000 : 'CMACGM GEORG FORSTER',
  215225000 : 'CMA CGM CENTAURUS',
  215230000 : 'CMA CGM WHITE SHARK',
  215240000 : 'CMA CGM GEORGIA',
  215249000 : 'CMA CGM COLUMBA',
  215251000 : 'CMA CGM T.ROOSEVELT',
  215293000 : 'CMA CGM LA SCALA',
  215331000 : 'CMA CGM ARGENTINA',
  215334000 : 'CC FORT ST GEORGES',
  215342000 : 'CMACGMFORT ST PIERR',
  215346000 : 'CMA CGM CHILE',
  215347000 : 'CMA CGM PANAMA',
  215352000 : 'CMA CGM BRAZIL',
  215354000 : 'CMA CGM MEXICO',
  215381000 : 'EXPRESS BLACK SEA',
  215396000 : 'CMACGM FORT ST MARIE',
  215413000 : 'AURETTE A',
  215416000 : 'MARGUERITE A',
  215417000 : 'MICHEL A',
  215418000 : 'VENTO DI TRAMONTANA',
  215419000 : 'CRISTINA A',
  215421000 : 'CINZIA A',
  215424000 : 'YIGITCAN A',
  215426000 : 'VENTO DI SCIROCCO',
  215485000 : 'CMA CGM ATTILA',
  215499000 : 'AKADIMOS',
  215558000 : 'APL QINGDAO',
  215560000 : 'APL SENTOSA',
  215561000 : 'VIRGO',
  215577000 : 'CMA CGM ALASKA',
  215640000 : 'GSL CHATEAU DIF',
  215648000 : 'CMA CGM SAMBHAR',
  215650000 : 'CMA CGM JAMAICA',
  215651000 : 'CMA CGM AMERICA',
  215657000 : 'CMA CGM THALASSA',
  215748000 : 'CNC JUPITER',
  215761000 : 'PORT GDYNIA',
  215765000 : 'CMA CGM TENERE',
  215839000 : 'CMA CGM SCANDOLA',
  215842000 : 'CNC SATURN',
  215857000 : 'CMACGM BOUGAINVILLE',
  215878000 : 'CMA CGM MOZART',
  215879000 : 'CMA CGM NORMA',
  215880000 : 'CMA CGM ROSSINI',
  215891000 : 'CMA CGM BEIRA',
  215922000 : 'CMA CGM KRIBI',
  215930000 : 'CMA CGM LAPEROUSE',
  215965000 : 'CMA CGM C COLOMB',
  215966000 : 'CMA CGM IGUACU',
  215992000 : 'CMA CGM AMERIGO VESP',
  218000250 : 'AL NEFUD',
  218006290 : 'MANILA EXPRESS',
  218008040 : 'TIHAMA',
  218044000 : 'HENNEKE RAMBOW',
  218098000 : 'IDA RAMBOW',
  218284000 : 'KUALA LUMPUR EXPRESS',
  218361000 : 'PRAGUE EXPRESS',
  218364000 : 'FRANKFURT EXPRESS',
  218366000 : 'SOFIA EXPRESS',
  218400000 : 'MSC LA SPEZIA',
  218426000 : 'HONG KONG EXPRESS',
  218427000 : 'SHANGHAI EXPRESS',
  218441000 : 'MSC LIVORNO',
  218474000 : 'ESSEN EXPRESS',
  218565000 : 'LEVERKUSEN EXPRESS',
  218566000 : 'LUDWIGSHAFEN EXPRESS',
  218627000 : 'VERA RAMBOW',
  218643000 : 'ZIM HONG KONG',
  218650000 : 'ZIM NEWARK',
  218774000 : 'DORTMUND EXPRESS',
  218776000 : 'NEW YORK EXPRESS',
  218791000 : 'ANTWERPEN EXPRESS',
  218816000 : 'BEATE',
  218819000 : 'MSC GENOVA',
  218833000 : 'VALPARAISO EXPRESS',
  218834000 : 'POSEN',
  218839000 : 'CALLAO EXPRESS',
  218844000 : 'CMA CGM IVANHOE',
  218845000 : 'CMA CGM ORFEO',
  218847000 : 'MSC CADIZ',
  218850000 : 'CARTAGENA EXPRESS',
  218851000 : 'GUAYAQUIL EXPRESS',
  218853000 : 'MSC VIGO',
  218854000 : 'SANTOS EXPRESS',
  219018271 : 'MAERSKMCKINNEYMOLLER',
  219018501 : 'MAJESTIC MAERSK',
  219018692 : 'MARY MAERSK',
  219018765 : 'MARIE MAERSK',
  219018864 : 'MADISON MAERSK',
  219018986 : 'MAGLEBY MAERSK',
  219019094 : 'MARIBO MAERSK',
  219019139 : 'MARSTAL MAERSK',
  219019365 : 'MATZ MAERSK',
  219021000 : 'MUNKEBO MAERSK',
  219027000 : 'MAERSK EINDHOVEN',
  219027748 : 'NAKSKOV MAERSK',
  219027758 : 'NIMTOFTE MAERSK',
  219027759 : 'NORDAGER MAERSK',
  219027760 : 'NORDBORG MAERSK',
  219027761 : 'NUUK MAERSK',
  219027854 : 'SIUANA ARCTICA',
  219027908 : 'MALERAQ ARCTICA',
  219031000 : 'MAERSK ESSEX',
  219031418 : 'CAROLINE MAERSK',
  219031419 : 'A.P. MOLLER',
  219031426 : 'CARSTEN MAERSK',
  219031427 : 'CORNELIUS MAERSK',
  219032189 : 'CAPE SCOTT',
  219032229 : 'CAPE SPENCER',
  219032707 : 'CAPE SKAGEN',
  219033000 : 'MAERSK EDINBURGH',
  219034000 : 'MANCHESTER MAERSK',
  219036000 : 'MURCIA MAERSK',
  219038000 : 'MANILA MAERSK',
  219039000 : 'MUMBAI MAERSK',
  219045000 : 'MAASTRICHT MAERSK',
  219053000 : 'VOLGA MAERSK',
  219056000 : 'MAERSK EMDEN',
  219068000 : 'SANTA BARBARA',
  219069000 : 'SANTA INES',
  219071000 : 'SANTA URSULA',
  219072000 : 'SANTA CATARINA',
  219075000 : 'SANTA CLARA',
  219077000 : 'SANTA ISABEL',
  219080000 : 'SANTA CRUZ',
  219085000 : 'SANTA RITA',
  219086000 : 'SANTA ROSA',
  219088000 : 'SANTA TERESA',
  219095000 : 'SAN ANTONIO MAERSK',
  219096000 : 'SAN LORENZO MAERSK',
  219100000 : 'SAN AUGUSTIN MAERSK',
  219101000 : 'SAN MARCO MAERSK',
  219102000 : 'SAN NICOLAS MAERSK',
  219107000 : 'SAN RAPHAEL MAERSK',
  219110000 : 'VAYENGA MAERSK',
  219115000 : 'VENTA MAERSK',
  219117000 : 'MAERSK BROWNSVILLE',
  219126000 : 'RHINE MAERSK',
  219130000 : 'RHONE MAERSK',
  219133000 : 'VUOKSI MAERSK',
  219136000 : 'VILNIA MAERSK',
  219155000 : 'MAERSK EVORA',
  219170000 : 'VAGA MAERSK',
  219196000 : 'MAERSK BALTIMORE',
  219198000 : 'MAERSK ELBA',
  219199000 : 'MAERSK EDMONTON',
  219204000 : 'MAERSK BENTONVILLE',
  219210000 : 'MAERSK ESSEN',
  219215000 : 'MAERSK BROOKLYN',
  219216000 : 'MAERSK BOSTON',
  219223000 : 'MAERSK NEWPORT',
  219225000 : 'MAERSK NORFOLK',
  219304000 : 'TUKUMA ARCTICA',
  219324000 : 'SALLY MAERSK',
  219470000 : 'ALEXANDER MAERSK',
  219505000 : 'MAERSK LA PAZ',
  219506000 : 'MAERSK LINS',
  219508000 : 'MAERSK LOTA',
  219509000 : 'MAERSK LEBU',
  219510000 : 'MAERSK LUZ',
  219515000 : 'MAERSK LAGUNA',
  219516000 : 'MAERSK LEON',
  219526000 : 'MAERSK LIMA',
  219527000 : 'MAERSK LAMANAI',
  219530000 : 'MAERSK LANCO',
  219532000 : 'MAERSK LIRQUEN',
  219533000 : 'MAERSK LETICIA',
  219534000 : 'MAERSK LAVRAS',
  219538000 : 'MAERSK LABREA',
  219540000 : 'MAERSK LONDRINA',
  219542000 : 'MAERSK LABERINTO',
  219543000 : 'LAURA MAERSK',
  219578000 : 'MAYVIEW MAERSK',
  219581000 : 'MERETE MAERSK',
  219609000 : 'MORTEN MAERSK',
  219612000 : 'IRENA ARCTICA',
  219620000 : 'MAREN MAERSK',
  219629000 : 'MARGRETHE MAERSK',
  219630000 : 'MARCHEN MAERSK',
  219631000 : 'METTE MAERSK',
  219632000 : 'MARIT MAERSK',
  219646000 : 'MATHILDE MAERSK',
  219668000 : 'MALIK ARCTICA',
  219678000 : 'VISTULA MAERSK',
  219821000 : 'SKAGEN MAERSK',
  219835000 : 'MUNICH MAERSK',
  219836000 : 'MADRID MAERSK',
  219840000 : 'CLIFFORD MAERSK',
  219854000 : 'MARSEILLE MAERSK',
  219861000 : 'MILAN MAERSK',
  219863000 : 'MONACO MAERSK',
  219864000 : 'MOSCOW MAERSK',
  219953000 : 'JEPPESEN MAERSK',
  219974000 : 'JENS MAERSK',
  219982000 : 'JOHANNES MAERSK',
  220008000 : 'JOSEPHINE MAERSK',
  220010000 : 'MOGENS MAERSK',
  220059000 : 'CHARLOTTE MAERSK',
  220061000 : 'CORNELIA MAERSK',
  220129000 : 'COLUMBINE MAERSK',
  220164000 : 'CLEMENTINE MAERSK',
  220198000 : 'OLGA MAERSK',
  220207000 : 'OLUF MAERSK',
  220216000 : 'OLIVIA MAERSK',
  220291000 : 'LARS MAERSK',
  220379000 : 'GUDRUN MAERSK',
  220397000 : 'GRETE MAERSK',
  220413000 : 'GUNVOR MAERSK',
  220414000 : 'GJERTRUD MAERSK',
  220415000 : 'GERD MAERSK',
  220416000 : 'GEORG MAERSK',
  220417000 : 'EMMA MAERSK',
  220477000 : 'ELEONORA MAERSK',
  220478000 : 'ESTELLE MAERSK',
  220496000 : 'EVELYN MAERSK',
  220497000 : 'EBBA MAERSK',
  220499000 : 'ELLY MAERSK',
  220501000 : 'EDITH MAERSK',
  220503000 : 'EUGEN MAERSK',
  220596000 : 'GUSTAV MAERSK',
  220598000 : 'GERDA MAERSK',
  226324000 : 'MARFRET GUYANE',
  228038360 : 'CMA CGM SORBONNE',
  228335900 : 'CMA CGM TOSCA',
  228337900 : 'CMA CGM DALILA',
  228339600 : 'CMA CGM ALMAVIVA',
  228340900 : 'CMA CGM LA TRAVIATA',
  228342900 : 'CMA CGM MEDEA',
  228353600 : 'CMA CGM OTELLO',
  228354600 : 'CMA CGM RIGOLETTO',
  228362900 : 'CMA CGM FORTDEFRANCE',
  228367600 : 'CMA CGM FORT ROYAL',
  228368600 : 'CCFORT SAINT CHARLES',
  228368900 : 'CMA CGM TITUS',
  228369800 : 'CMA CGM ST. LAURENT',
  228373600 : 'CC FORT FLEUR D EPEE',
  228386700 : 'CMACGM JACQUES SAADE',
  228386800 : 'CC CHAMPS ELYSEES',
  228394600 : 'CMA CGM PALAIS ROYAL',
  228394900 : 'CMA CGM LOUVRE',
  228395600 : 'DOUCE FRANCE',
  228397600 : 'CMA CGM RIVOLI',
  228397700 : 'CMA CGM MONTMARTRE',
  228401800 : 'CMA CGM CONCORDE',
  228402900 : 'CMA CGM TROCADERO',
  228403700 : 'CMA CGM SINNAMARY',
  228403800 : 'CMA CGM KOUROU',
  228409800 : 'CMA CGM PATAGONIA',
  228409900 : 'CMA CGM KIMBERLEY',
  228410600 : 'CMA CGM EVERGLADE',
  228410700 : 'CMA CGM GALAPAGOS',
  228410800 : 'CMA CGM GREENLAND',
  228451700 : 'MARIUS',
  229054000 : 'CMA CGM DUTCH HARBOR',
  229075000 : 'CMA CGM CAIMEP',
  229111000 : 'WANDA A',
  229115000 : 'CMA CGM NOUADHIBOU',
  229142000 : 'DIANE A',
  229190000 : 'CMA CGM JULES VERNE',
  229191000 : 'X-PRESS GODAVARI',
  229247000 : 'LION',
  229263000 : 'ETOILE',
  229270000 : 'CMA CGM ST EXUPERY',
  229282000 : 'CMA CGM SAVANNAH',
  229290000 : 'CMA CGM MONTOIR',
  229321000 : 'CMA CGM ZEPHYR',
  229326000 : 'CMA CGM HOPE',
  229331000 : 'CMA CGM DON PASCUALE',
  229334000 : 'CNC BANGKOK',
  229338000 : 'TZINI',
  229340000 : 'CMA CGM MALTA',
  229358000 : 'APL TURKEY',
  229375000 : 'FORT DESAIX',
  229384000 : 'MSC LAUSANNE',
  229389000 : 'CMA CGM UNITY',
  229391000 : 'CMA CGM ISKENDERUN',
  229393000 : 'CMA CGM BALI',
  229394000 : 'CMA CGM LEKKI',
  229452000 : 'MERKUR FJORD',
  229454000 : 'CMA CGM HERMES',
  229457000 : 'CMA CGM VELA',
  229458000 : 'VALOR',
  229463000 : 'CMA CGM DIGNITY',
  229464000 : 'CMA CGM INTEGRITY',
  229465000 : 'CMA CGM LIBERTY',
  229467000 : 'VALUE',
  229474000 : 'SEASMILE',
  229480000 : 'CMA CGM SAIGON',
  229488000 : 'CMA CGM CHOPIN',
  229489000 : 'CMA CGM PUCCINI',
  229528000 : 'CMA CGM BALBOA',
  229541000 : 'VALIANT',
  229548000 : 'VALENCE',
  229550000 : 'SEADREAM',
  229556000 : 'MEGALOPOLIS',
  229557000 : 'MARATHOPOLIS',
  229572000 : 'MERKUR OCEAN',
  229573000 : 'CMA CGM ALIAGA',
  229584000 : 'SEAMASTER',
  229592000 : 'CMA CGM OSIRIS',
  229598000 : 'VANTAGE',
  229606000 : 'ANL WARRNAMBOOL',
  229607000 : 'APL SAIPAN',
  229620000 : 'MSC AZOV',
  229621000 : 'SOFRANA TOURVILLE',
  229622000 : 'MSC TRIESTE',
  229625000 : 'MSC AJACCIO',
  229626000 : 'MSC AMALFI',
  229630000 : 'X-PRESS MULHACEN',
  229633000 : 'CMA CGM CALLAO',
  229635000 : 'CMA CGM PARANAGUA',
  229639000 : 'CMA CGM NEVADA',
  229646000 : 'ARTOTINA',
  229648000 : 'LEONIDIO',
  229649000 : 'KYPARISSIA',
  229657000 : 'SAN PEDRO',
  229660000 : 'SOFRANA SURVILLE',
  229665000 : 'DIMITRIS C',
  229680000 : 'CMA CGM ARICA',
  229684000 : 'CMA CGM VALPARAISO',
  229685000 : 'CMA CGM APOLLON',
  229699000 : 'CMA CGM SYDNEY',
  229705000 : 'CMA CGM MARLIN',
  229726000 : 'APL DANUBE',
  229734000 : 'CMA CGM SAN ANTONIO',
  229736000 : 'CMA CGM FIGARO',
  229742000 : 'CNC LION',
  229744000 : 'CNC TIGER',
  229747000 : 'CMA CGM CAYENNE',
  229749000 : 'CMA CGM MARSEILLE',
  229750000 : 'CMA CGM MOMBASA',
  229752000 : 'CMA CGM ADONIS',
  229753000 : 'CMA CGM PERTH',
  229756000 : 'CMA CGM SEMARANG',
  229778000 : 'CMA CGM ARCTIC',
  229779000 : 'CMA CGM SYMI',
  229786000 : 'CMA CGM MAPUTO',
  229808000 : 'CMA CGM FIDELIO',
  229819000 : 'CMA CGM FUZHOU',
  229820000 : 'CMA CGM LEBU',
  229821000 : 'GALANI',
  229824000 : 'CMA CGM ALEXANDRIA',
  229831000 : 'CMA CGM CEBU',
  229842000 : 'CMA CGM BARRACUDA',
  229846000 : 'CMA CGM MONTREAL',
  229865000 : 'CMA CGM DAVAO',
  229877000 : 'CMA CGM VOLTAIRE',
  229914000 : 'CMA CGM QUELIMANE',
  229916000 : 'CMA CGM NANTONG',
  229917000 : 'CMA CGM ZANZIBAR',
  229921000 : 'CMA CGM DOLPHIN',
  229934000 : 'CMA CGM ELBE',
  229937000 : 'NORDOCELOT',
  229945000 : 'CONTI CORTESIA',
  229947000 : 'CMA CGM LOIRE',
  229949000 : 'ST.JOHN',
  229955000 : 'CMA CGM SEATTLE',
  229956000 : 'CMA CGM PEMBA',
  229966000 : 'JOSITA B',
  229978000 : 'SATIE',
  229988000 : 'CMA CGM TAGE',
  229989000 : 'CMA CGM RHONE',
  229993000 : 'SANTA LOUKIA',
  229997000 : 'CMA CGM PRIDE',
  230984000 : 'LINDA',
  230985000 : 'AILA',
  231123000 : 'SELFOSS',
  231355000 : 'ARNARFELL',
  231356000 : 'HELGAFELL',
  231789000 : 'LAGARFOSS',
  231836000 : 'BRUARFOSS',
  231860000 : 'DETTIFOSS',
  232008054 : 'SCARLET ARROW',
  232013516 : 'SPIRIT OF AUCKLAND',
  232013520 : 'SPIRIT OF HAMBURG',
  232013521 : 'SPIRIT OF MELBOURNE',
  232013524 : 'SPIRIT OF SINGAPORE',
  232013525 : 'SPIRIT OF SYDNEY',
  232018900 : 'HYUNDAI NEPTUNE',
  232018901 : 'HYUNDAI PLUTO',
  232018919 : 'HYUNDAI JUPITER',
  232024771 : 'HYUNDAI SATURN',
  232024772 : 'HYUNDAI EARTH',
  232024773 : 'HYUNDAI MARS',
  232029038 : 'ZENITH LUMOS',
  232030743 : 'ZEPHYR LUMOS',
  232031318 : 'ZEUS LUMOS',
  232031320 : 'ZEAL LUMOS',
  232043367 : 'MAERSK CAMPTON',
  232043368 : 'MAERSK CANDOR',
  232043372 : 'MAERSK CANYON',
  232046824 : 'CONTAINERSHIPS NORD',
  232046826 : 'CONTAINERSHIPS POLAR',
  232049845 : 'CELANDINE',
  235009240 : 'EVER SHINE',
  235009850 : 'EVER SIGMA',
  235010450 : 'EVER SMART',
  235010710 : 'EVER SMILE',
  235050802 : 'TOKYO TOWER',
  235061354 : 'BRIGHTON',
  235067931 : 'COSCO ROTTERDAM',
  235067987 : 'COSCO SHANGHAI',
  235068025 : 'COSCO ANTWERP',
  235068029 : 'COSCO HONGKONG',
  235068031 : 'COSCO FELIXSTOWE',
  235068032 : 'COSCO SINGAPORE',
  235068051 : 'COSCO HAMBURG',
  235082896 : 'EDISON',
  235084298 : 'ERVING',
  235093569 : 'EVER LAMBENT',
  235093618 : 'EVER LASTING',
  235093619 : 'EVER LEADING',
  235098383 : 'EVER LINKING',
  235098885 : 'EVER LEARNED',
  235102677 : 'EVER LENIENT',
  235102678 : 'EVER LIBERAL',
  235102679 : 'EVER LISSOME',
  235102681 : 'EVER LOADING',
  235110737 : 'EVER LIFTING',
  235335000 : 'EVER EAGLE',
  235414000 : 'EVER ENVOY',
  235479000 : 'EVER EXCEL',
  235554000 : 'EVER ELITE',
  235603000 : 'EVER ETHIC',
  235612000 : 'EVER PRIDE',
  235700000 : 'EVER PRIMA',
  236465000 : 'WYBELSUM',
  241311000 : 'EXPRESS BERLIN',
  241312000 : 'HYUNDAI SMART',
  241313000 : 'HYUNDAI SPEED',
  242198100 : 'CIELO DI RABAT',
  242377400 : 'CMA CGM DAKHLA',
  242410400 : 'CMA CGM NADOR',
  242640300 : 'CASABLANCA A',
  242830300 : 'X-PRESS SOUSSE',
  244150340 : 'MARY MAERSK',
  244150813 : 'A2B SPIRIT',
  244170750 : 'A2B AMBITION',
  244180000 : 'FREYA',
  244184354 : 'EA NODDY',
  244201000 : 'NORDICA',
  244238000 : 'ESSENCE',
  244255000 : 'ESCAPE',
  244265000 : 'ESPERANCE',
  244279000 : 'ESPOIR',
  244357000 : 'ENDURANCE',
  244597000 : 'ENFORCER',
  244650437 : 'A2B ENERGY',
  244820000 : 'BERMUDA ISLANDER',
  244850968 : 'SPIRIT',
  244850970 : 'SVEN D',
  244860282 : 'SAMSKIP HOFFELL',
  244870287 : 'A2B FUTURE',
  244874000 : 'EMOTION',
  244985000 : 'VANTAGE',
  244990000 : 'MERSIN EXPRESS',
  245078000 : 'ENCOUNTER',
  245088000 : 'ENERGIZER',
  245222000 : 'ENDEAVOR',
  245258000 : 'EMPIRE',
  245502000 : 'A2B PROUD',
  245570000 : 'ANJA',
  245872000 : 'NJORD',
  246051000 : 'ROTRA MARE',
  246141000 : 'VANQUISH',
  246191000 : 'FENJA',
  246255000 : 'ENSEMBLE',
  246497000 : 'ELISABETH',
  246530000 : 'RIJNBORG',
  246650000 : 'OOCL RAUMA',
  246891000 : 'A2B COMFORT',
  246899000 : 'A2B LEADER',
  247009600 : 'ITAL USODIMARE',
  247012800 : 'ITAL UNICA',
  247012900 : 'ITAL UNIVERSO',
  247425500 : 'BURGUNDY',
  247437600 : 'ITAL BONNY',
  247437900 : 'ITAL BONUS',
  247439600 : 'EVER COZY',
  247483900 : 'JOLLY ROSA',
  248013000 : 'CMA CGM PEGASUS',
  248030000 : 'CMA CGM CARL ANTOINE',
  248052000 : 'CMA CGM TITAN',
  248089000 : 'CAPE SOUNIO',
  248140000 : 'CAPE ARTEMISIO',
  248207000 : 'MAERSK JAIPUR',
  248228000 : 'ALIAGA EXPRESS',
  248247000 : 'CMA CGM MUSSET',
  248269000 : 'CMA CGM SUEZ',
  248270000 : 'IRENES RYTHM',
  248302000 : 'EXPRESS ARGENTINA',
  248402000 : 'CMA CGM BELLINI',
  248422000 : 'ELBELLA',
  248426000 : 'CMA CGM LISA MARIE',
  248430000 : 'CMA CGM RABELAIS',
  248431000 : 'RACINE',
  248432000 : 'CMA CGM NERVAL',
  248455000 : 'CMA CGM EIFFEL',
  248473000 : 'C C JACQUES JOSEPH',
  248556000 : 'POLAR BRASIL',
  248572000 : 'EXPRESS FRANCE',
  248574000 : 'EXPRESS BRAZIL',
  248633000 : 'APL ANTWERP',
  248649000 : 'CMA CGM ESTELLE',
  248655000 : 'CMA CGM LOUGA',
  248659000 : 'CMA CGM PREGOLIA',
  248660000 : 'CMA CGM NEVA',
  248699000 : 'APL FLORIDA',
  248703000 : 'CMA CGM JEAN GABRIEL',
  248711000 : 'APL OREGON',
  248712000 : 'APL CALIFORNIA',
  248729000 : 'CMA CGM ABU DHABI',
  248756000 : 'ANL KOKODA',
  248758000 : 'CMA CGM JEAN MERMOZ',
  248794000 : 'CMACGM LOUIS BLERIOT',
  248819000 : 'HANSA DUBURG',
  248846000 : 'EXPRESS SPAIN',
  248966000 : 'CNC VENUS',
  249037000 : 'ATLANTIC GREEN',
  249096000 : 'MERITO',
  249167000 : 'CMA CGM OHIO',
  249168000 : 'CMA CGM NIAGARA',
  249169000 : 'CMA CGM RIO GRANDE',
  249202000 : 'CAPE AKRITAS',
  249253000 : 'RIO GRANDE',
  249322000 : 'CMACGM JACQUESJUNIOR',
  249324000 : 'CMA CGM TANYA',
  249414000 : 'MERVE A',
  249474000 : 'KINGSTON',
  249509000 : 'ZIM MONACO',
  249515000 : 'TRITON',
  249558000 : 'TITAN',
  249570000 : 'X-PRESS SHANNON',
  249675000 : 'DALIAN',
  249769000 : 'TALOS',
  249773000 : 'TAURUS',
  249783000 : 'CAPE KORTIA',
  249808000 : 'THESEUS',
  249819000 : 'CMA CGM LIBRA',
  249830000 : 'ZIM LUANDA',
  249837000 : 'MARGARETE SCHULTE',
  249877000 : 'CMA CGM RODOLPHE',
  249888000 : 'CMA CGM LEO',
  249917000 : 'CMA CGM MOLIERE',
  249940000 : 'MERSIN EXPRESS',
  249982000 : 'CAPE TAINARO',
  255618000 : 'MSC NORA III',
  255701240 : 'NIEVES B',
  255705000 : 'AVA D',
  255709000 : 'AS SELINA',
  255710000 : 'JULIANA',
  255715000 : 'ESL ZANZIBAR',
  255719000 : 'JOST',
  255771000 : 'BG RUBY',
  255772000 : 'BG ONYX',
  255775000 : 'JSP ANNA',
  255784000 : 'CANDELARIA B',
  255790000 : 'MSC TAMPICO',
  255792000 : 'MSC MANZANILLO',
  255793000 : 'MSC ACAPULCO',
  255796000 : 'MSC TUXPAN',
  255797000 : 'MSC VERACRUZ',
  255799000 : 'MSC CAPE III',
  255801360 : 'ATALANTA',
  255801780 : 'DINA TRADER',
  255801940 : 'CONESTE',
  255801970 : 'RUMBA',
  255802340 : 'ESL ASANTE',
  255802360 : 'MSC ANSHIKA VI',
  255802470 : 'MSC ZONDA III',
  255802560 : 'HANSA SALZBURG',
  255802680 : 'WEC BOUMEESTER',
  255802760 : 'FAITH',
  255802790 : 'NORDIC HAMBURG',
  255803201 : 'MSC PARIS',
  255803260 : 'MSC AGAMEMNON',
  255803450 : 'MSC RIKKU',
  255803480 : 'MSC RIDA',
  255803590 : 'MSC LEANDRA V',
  255803640 : 'ARIES J',
  255803670 : 'MSC NEW HAVEN',
  255803680 : 'MSC BRIDGEPORT',
  255803760 : 'MSC GREENWICH',
  255803770 : 'MSC FAIRFIELD',
  255803780 : 'MSC DARIEN',
  255804350 : 'MSC BREMERHAVEN V',
  255804590 : 'BG GREEN',
  255805260 : 'MSC AZRA',
  255805270 : 'MSC ADYA S/T',
  255805551 : 'VICTORIA',
  255805555 : 'AKACIA',
  255805557 : 'ALANA',
  255805558 : 'MSC VALENCIA',
  255805563 : 'WIELAND',
  255805571 : 'MSC BILBAO',
  255805572 : 'JADRANA',
  255805573 : 'JOGELA',
  255805574 : 'NORTHERN VALENCE',
  255805575 : 'MSC VIGOUR III',
  255805576 : 'NORTHERN VIVACITY',
  255805577 : 'ELBSTROM',
  255805580 : 'JPO AQUARIUS',
  255805587 : 'NCL AVEROY',
  255805588 : 'ESL NHAVA SHEVA',
  255805589 : 'HELLA',
  255805590 : 'HERTA',
  255805594 : 'SVENDBORG',
  255805600 : 'BARBARA P',
  255805601 : 'JONA SOPHIE',
  255805603 : 'FERDINANDA S',
  255805611 : 'MACAO',
  255805617 : 'MSC CAROUGE',
  255805622 : 'AS FLORIANA',
  255805626 : 'INDEPENDENT PRIMERO',
  255805627 : 'SANTUCA B',
  255805652 : 'ISABELLA B',
  255805657 : 'VENETIA',
  255805661 : 'MSC ODESSA V',
  255805664 : 'MSC MARITINA V',
  255805666 : 'JOLLY ARGENTO',
  255805676 : 'MV CHICAGO',
  255805677 : 'BUXCOAST',
  255805678 : 'BUXCLIFF',
  255805686 : 'KORNETT',
  255805703 : 'FLORA DELMAS',
  255805750 : 'SEALAND BALBOA',
  255805755 : 'VERONICA B',
  255805764 : 'OSAKA',
  255805775 : 'WEC MONDRIAAN',
  255805776 : 'ESL BUSAN',
  255805777 : 'MSC JENNY II',
  255805778 : 'TABEA',
  255805780 : 'PANDA 001',
  255805781 : 'AMALTHEA',
  255805788 : 'JAN',
  255805802 : 'WEC CORNEILLE',
  255805803 : 'INDEPENDENT FUTURE',
  255805810 : 'UBENA',
  255805837 : 'SOLONG',
  255805838 : 'DREAM',
  255805839 : 'VISITOR',
  255805840 : 'SAMSKIP EXPRESS',
  255805841 : 'MEANDI',
  255805842 : 'MIRROR',
  255805843 : 'PANDA 002',
  255805844 : 'TROUPER',
  255805845 : 'ANDANTE',
  255805846 : 'MOVEON',
  255805847 : 'ESL WINNER',
  255805849 : 'ULANGA',
  255805852 : 'MSC MELTEMI III',
  255805854 : 'MSC JEONGMIN',
  255805855 : 'MSC CHLOE',
  255805856 : 'MSC SIYA B',
  255805859 : 'MSC ANTONELLA',
  255805864 : 'MSC CHANNE',
  255805865 : 'MSC PALAK',
  255805866 : 'MSC ELMA',
  255805867 : 'MSC ROMANE',
  255805868 : 'MSC DESIREE',
  255805869 : 'MSC GIULIA',
  255805878 : 'CARLOTA B',
  255805883 : 'PIRITA',
  255805884 : 'NAVI BALTIC',
  255805890 : 'LAURA S',
  255805891 : 'MSC BRANKA',
  255805897 : 'MSC SASHA',
  255805899 : 'ANINA',
  255805903 : 'CT PACHUCA',
  255805906 : 'EDITH',
  255805922 : 'MSC ELBE III',
  255805923 : 'MSC WESER',
  255805929 : 'MSC MICHELA',
  255805930 : 'MSC CLEA',
  255805937 : 'MISTRAL',
  255805947 : 'MSC MARYLENA',
  255805954 : 'ATLANTIS A',
  255805956 : 'MSC JERSEY',
  255805965 : 'MSC TALIA F',
  255805966 : 'MSC AMANDA F',
  255805967 : 'MSC LENA F',
  255805968 : 'MSC LUNA F',
  255805969 : 'MSC NINA F',
  255805984 : 'JSP CARLA',
  255805987 : 'EF OLIVIA',
  255805992 : 'BF CARODA',
  255805995 : 'K-RIVER',
  255805996 : 'K-OCEAN',
  255805997 : 'K-STREAM',
  255806004 : 'BRUSSELS',
  255806005 : 'MSC INDIA',
  255806008 : 'MACARENA B',
  255806010 : 'MONTE BRASIL',
  255806011 : 'MONTE DA GUIA',
  255806016 : 'HELMUT',
  255806027 : 'MSC SHUBA B',
  255806028 : 'MSC SHREYA B',
  255806029 : 'MSC NITYA B',
  255806031 : 'MSC MADHU B',
  255806032 : 'MSC YASHI B',
  255806034 : 'MSC JASPER VIII',
  255806037 : 'NORTHERN JUVENILE',
  255806038 : 'NORTHERN JUBILEE',
  255806039 : 'NORTHERN JAGUAR',
  255806054 : 'RITA',
  255806055 : 'GEESKE',
  255806057 : 'BRIGHT',
  255806063 : 'MSC MANON',
  255806064 : 'AS PETRONIA',
  255806065 : 'STADT DRESDEN',
  255806066 : 'AS ANGELINA',
  255806067 : 'AS FATIMA',
  255806068 : 'AS FIORELLA',
  255806069 : 'AS FABRIZIA',
  255806070 : 'AS FLORETTA',
  255806072 : 'AS COLUMBIA',
  255806076 : 'AS PAULINA',
  255806079 : 'AS FATIMA',
  255806081 : 'MSC AMY',
  255806085 : 'AS CLARITA',
  255806086 : 'SONDERBORG',
  255806091 : 'ORNELLA',
  255806092 : 'AS CARELIA',
  255806093 : 'AS CYPRIA',
  255806095 : 'AS PATRIA',
  255806096 : 'MSC RICCARDA II',
  255806097 : 'MSC ROMINA II',
  255806098 : 'AS ROSALIA',
  255806099 : 'AS RAGNA',
  255806100 : 'AS SICILIA',
  255806101 : 'SEVILLIA',
  255806102 : 'AS FABIANA',
  255806103 : 'AS FELICIA',
  255806105 : 'MSC JUSTICE VIII',
  255806107 : 'AVERA',
  255806109 : 'MSC MASHA 3',
  255806110 : 'AS FILIPPA',
  255806113 : 'CMA CGM GOYA',
  255806114 : 'CMA CGM PUGET',
  255806115 : 'OLIVIA',
  255806116 : 'AS CALIFORNIA',
  255806121 : 'AS CAMELLIA',
  255806122 : 'AS SAVANNA',
  255806123 : 'EF AVA',
  255806125 : 'AS CLEMENTINA',
  255806127 : 'AS SOPHIA',
  255806132 : 'WILLIAM',
  255806136 : 'RAQUEL S',
  255806137 : 'AS CAROLINA',
  255806143 : 'AVILA',
  255806148 : 'MARIELYST',
  255806152 : 'AS SARA',
  255806165 : 'WEC FRANS HALS',
  255806169 : 'NORTHERN JAVELIN',
  255806170 : 'NORTHERN JAMBOREE',
  255806171 : 'LUCIA B',
  255806179 : 'CMA CGM VENTANIA',
  255806190 : 'MSC ANNE F',
  255806191 : 'HEINRICH EHLER',
  255806209 : 'EF EMMA',
  255806219 : 'SPIRIT OF CHENNAI',
  255806220 : 'SPIRIT OF KOLKATA',
  255806233 : 'MSC PRECISION V',
  255806235 : 'CT DANIEL',
  255806249 : 'ADELINA D',
  255806253 : 'JETTE',
  255806255 : 'TESSA',
  255806259 : 'SUNAID X',
  255806261 : 'LOLA B',
  255806267 : 'ELBTEAM',
  255806271 : 'WILHELMINE',
  255806274 : 'SPIRIT OF DUBAI',
  255806280 : 'WEC JAN STEEN',
  255806301 : 'REBECCA S',
  255806304 : 'NORTHERN MONUMENT',
  255806305 : 'ILHA DA MADEIRA',
  255806308 : 'CHARLOTTA',
  255806310 : 'CHRISTINA',
  255806313 : 'ARA AMSTERDAM',
  255806327 : 'WEC VAN RUYSDAEL',
  255806330 : 'ADILIA I',
  255806335 : 'MSC ARIES',
  255806338 : 'MSC AURIGA',
  255806339 : 'MSC ORION',
  255806340 : 'MSC VIRGO',
  255806346 : 'PANTONIO',
  255806347 : 'PENGALIA',
  255806352 : 'JSP ROVER',
  255806362 : 'MSC BUSAN',
  255806363 : 'PERSEUS',
  255806367 : 'MSC CHARLESTON',
  255806368 : 'MSC GUERNSEY',
  255806370 : 'ELBSUN',
  255806376 : 'MSC BEIJING',
  255806377 : 'MSC TORONTO',
  255806430 : 'MSC BRITTANY',
  255806434 : 'MSC ABIDJAN',
  255806436 : 'KRISTIN SCHEPERS',
  255806437 : 'HANSA OSTERBURG',
  255806448 : 'MSC BASEL V',
  255806449 : 'MSC BERN V',
  255806451 : 'NORDIC ITALIA',
  255806452 : 'NORDIC ISTRIA',
  255806458 : 'AS NADIA',
  255806459 : 'MSC ANUSHA III',
  255806464 : 'AMINA',
  255806476 : 'SKALAR',
  255806481 : 'INDEPENDENT QUEST',
  255806484 : 'HEINRICH',
  255806485 : 'MSC SAGITTA III',
  255806489 : 'MSC LILY',
  255806490 : 'MSC LETIZIA',
  255806491 : 'MSC BRUNELLA',
  255806492 : 'MSC CATERINA',
  255806493 : 'MSC VITA',
  255806494 : 'MSC ELODIE',
  255806495 : 'MSC SOFIA CELESTE',
  255806496 : 'MSC ANZU',
  255806497 : 'MSC MELINE',
  255806498 : 'MSC JULIE',
  255806499 : 'MSC SARA ELENA',
  255806500 : 'MSC NAOMI',
  255806502 : 'MSC SIYA B',
  255806505 : 'HELENA SCHEPERS',
  255806506 : 'MSC SILVIA',
  255806507 : 'MSC DOMITILLE',
  255806508 : 'MITO',
  255806513 : 'CALISTO',
  255806514 : 'ANNABA',
  255806515 : 'GRETA',
  255806520 : 'MSC GISELLE',
  255806521 : 'MSC MAXINE',
  255806525 : 'KATHARINA SCHEPERS',
  255807025 : 'MSC MAXINE',
  255826000 : 'DAGMAR',
  255903590 : 'ZIM DANUBE',
  255915586 : 'POLAR ARGENTINA',
  255915587 : 'STEEN',
  255915596 : 'RS LISA',
  255915610 : 'JOLLY ORO',
  255915612 : 'EF ELENA',
  255915616 : 'MSC LAGOS X',
  255915617 : 'MSC DAKAR X',
  255915618 : 'ROME EXPRESS',
  255915619 : 'SOUTHAMPTON EXPRESS',
  255915621 : 'EF EMIRA',
  255915622 : 'INDEPENDENT HORIZON',
  255915624 : 'APOLLON D',
  255915636 : 'ESL DACHAN BAY',
  255915641 : 'ESL KABIR',
  255915647 : 'TAMINA',
  255915654 : 'EF ELDRA',
  255915657 : 'FRIDA RUSS',
  255915659 : 'MSC LOME V',
  255915660 : 'EVI NICOLA',
  255915674 : 'ALS HERCULES',
  255915695 : 'CMA CGM ZINGARO',
  255975000 : 'MSC DUBAI VII',
  255980000 : 'MSC YAMUNA VI',
  255983000 : 'MSC CAPETOWN III',
  255993000 : 'ANDREA',
  255994000 : 'ZIM GANGES',
  256011000 : 'CONTI CONTESSA',
  256019000 : 'CMA CGM AMBARLI',
  256022000 : 'CMA CGM ABIDJAN',
  256033000 : 'CMA CGM LITANI',
  256042000 : 'EMMA A',
  256058000 : 'DIMITRA C',
  256062000 : 'CMA CGM TIGRIS',
  256071000 : 'CMA CGM MANTA RAY',
  256075000 : 'ZIM SAVANNAH',
  256095000 : 'CMA CGM VERDI',
  256104000 : 'CMA CGM GANGES',
  256108000 : 'CMA CGM MEKONG',
  256127000 : 'CMA CGM NACALA',
  256142000 : 'CNC URANUS',
  256143000 : 'CNC PLUTO',
  256144000 : 'CMA CGM NAVEGANTES',
  256148000 : 'CMA CGM AQABA',
  256150000 : 'ANL DHAMBI',
  256151000 : 'MSC MADRID',
  256163000 : 'CMA CGM URAL',
  256169000 : 'CMA CGM VOLGA',
  256170000 : 'CMA CGM SURABAYA',
  256171000 : 'CMA CGM VITORIA',
  256172000 : 'MSC CORUNA',
  256208000 : 'CMA CGM TARRAGONA',
  256213000 : 'CMA CGM THAMES',
  256214000 : 'CMA CGM CONSTANZA',
  256229000 : 'CMA CGM OSAKA',
  256244000 : 'CMA CGM LOME',
  256267000 : 'CNC JAGUAR',
  256272000 : 'CMA CGM MANAUS',
  256292000 : 'CMACGM VILA DO CONDE',
  256300000 : 'CMA CGM TANCREDI',
  256311000 : 'CMA CGM GEORGE SAND',
  256318000 : 'SINE A',
  256336000 : 'ECO MAESTRO',
  256381000 : 'CNC CHEETAH',
  256430000 : 'A. IDEFIX',
  256432000 : 'A. OBELIX',
  256441000 : 'CNC PUMA',
  256443000 : 'CMA CGM NILE',
  256449000 : 'CMA CGM YUKON',
  256475000 : 'GISELE A',
  256482000 : 'CMA CGM EXCELLENCE',
  256485000 : 'CMA CGM EXEMPLARITY',
  256486000 : 'IZMIT EXPRESS',
  256490000 : 'CMA CGM RIMBAUD',
  256510000 : 'CMA CGM GULF EXPRESS',
  256547000 : 'STANLEY A',
  256548000 : 'CMA CGM PELLEAS',
  256558000 : 'OREA',
  256559000 : 'CMA CGM LISBON',
  256567000 : 'MSC ALICANTE',
  256570000 : 'SANTA VIOLA',
  256572000 : 'CMA CGM F.SAGAN',
  256573000 : 'MSC BARCELONA',
  256579000 : 'VERA A',
  256587000 : 'CMA CGM BETTER WAYS',
  256589000 : 'ALEXANDRA A',
  256590000 : 'CMA CGM VALENCIA',
  256592000 : 'CMA CGM INNOVATION',
  256598000 : 'CMA CGM BAHIA',
  256604000 : 'CMA CGM BIANCA',
  256612000 : 'CMA CGM CAPE COD',
  256615000 : 'CMA CGM CEDRUS',
  256619000 : 'CMA CGM KHAO SOK',
  256644000 : 'CMA CGM ARKANSAS',
  256649000 : 'ALLEGRI',
  256651000 : 'BEIHAI551',
  256658000 : 'CMA CGM BUZIOS',
  256676000 : 'CMA CGM LEGACY',
  256687000 : 'CMA CGM SAMSON',
  256703000 : 'GEMLIK EXPRESS',
  256708000 : 'CMA CGM MERMAID S/T',
  256728000 : 'HARIS',
  256763000 : 'ISTANBUL EXPRESS',
  256766000 : 'SYDNEY EXPRESS',
  256767000 : 'BREMERHAVEN EXPRESS',
  256771000 : 'CZECH',
  256888000 : 'CMA CGM MELISANDE',
  256930000 : 'YANTIAN',
  256932000 : 'COSCO HELLAS',
  256937000 : 'BEIJING',
  256938000 : 'ZIM YANTIAN',
  256940000 : 'ZIM SHANGHAI',
  256968000 : 'CMA CGM COLUMBIA',
  256976000 : 'NEW JERSEY TRADER',
  257221000 : 'SILVER MARY',
  271000261 : 'MEGAN',
  271000609 : 'BMI EXPRESS',
  271000647 : 'DANIEL A',
  271000677 : 'LUCIEN GA',
  271000744 : 'KARLA A',
  271001057 : 'ROZA A',
  271001058 : 'TOMRIZ A',
  271001092 : 'CLAIRE A',
  271040008 : 'BERNARD A',
  271040635 : 'VIVIEN A',
  271041000 : 'MARTINE A',
  271042433 : 'MUSTAFA DAYI',
  271042493 : 'GULBENIZ A',
  271042494 : 'INGA A',
  271042759 : 'NEVZAT KALKAVAN',
  271043028 : 'KAAN KALKAVAN',
  271043163 : 'MATILDE A',
  271043491 : 'TIMUCIN A',
  271044070 : 'MARTHA A',
  271044163 : 'TURKON EGYPT',
  271044177 : 'SASKIA A',
  271044212 : 'MED TEKIRDAG',
  271044301 : 'KAPTAN AYTAC A',
  271044302 : 'MEHMET KAHVECI A',
  271044398 : 'MED DENIZLI',
  271044570 : 'MERSIN EXPRESS',
  271044633 : 'MED CORLU',
  271044978 : 'LEYLA KALKAVAN',
  271045478 : 'MED SAMSUN',
  271047044 : 'PARPALI',
  271047045 : 'KOSOVAK',
  271048694 : 'MED AYDIN',
  271049029 : 'TURKON ISTANBUL',
  271049958 : 'MED TRABZON',
  271050526 : 'DIONYSSIS A',
  271050679 : 'KAPPA',
  271050950 : 'CORELLI',
  271050951 : 'VASSILIS A',
  271051022 : 'NARA',
  271051067 : 'VENTO',
  271051138 : 'MED IZMIR',
  271051490 : 'LIONFISH',
  271051980 : 'DENIS A',
  271051981 : 'DENIS A',
  271051982 : 'OYKU A',
  271051983 : 'ONUR G.A',
  271052043 : 'MEDKON MIRA',
  272771000 : 'DNIPRO LINE',
  273210520 : 'FESCO MONERON',
  273212630 : 'PERSEY',
  273257110 : 'KAPITAN BYANKIN',
  273298570 : 'FESCO YANINA',
  273310730 : 'NORILSKIY NICKEL',
  273329660 : 'FESCO NOVIK',
  273340920 : 'MONCHEGORSK',
  273343170 : 'FESCO NAVARIN',
  273347430 : 'ZEYA',
  273347820 : 'NADEZHDA',
  273348820 : 'TALNAKH',
  273373920 : 'SASCO AVACHA',
  273374720 : 'NAYADA',
  273383520 : 'SASCO ANIVA',
  273390170 : 'SASCO ANGARA',
  273390174 : 'SASCO ANGARA',
  273399770 : 'IVAN KAPRALOV',
  273430810 : 'IRIDA',
  273452980 : 'FESCO MAGADAN',
  273455590 : 'VENIAMIN KHLOPIN',
  273611080 : 'CRYSTAL STPETERSBURG',
  273611350 : 'TRANSIT LUGOVAYA',
  273611980 : 'MIKHAIL ROBKANOV',
  273612270 : 'TRANSIT TAVAYZA',
  273612530 : 'TRANSIT SHAMORAK E H',
  273613560 : 'FESCO ANASTASIA',
  273615650 : 'FESCO TATARSTAN',
  273616390 : 'TRANSIT MILLIONKA',
  275508000 : 'ARTEMIS',
  275524000 : 'PERSEUS',
  275535000 : 'SHIPLILLY REINVENTIO',
  276870000 : 'NANTO',
  277549000 : 'KANTATA',
  277550000 : 'VICTORIA',
  277562000 : 'SONATA',
  300000000 : 'WAN HAI 370',
  303189000 : 'SSG CARTER',
  303340000 : 'PRESIDENT CLEVELAND',
  303352000 : 'PRESIDENT EISENHOWER',
  303584000 : 'MAUNALEI',
  303657000 : 'MAERSK KENSINGTON',
  304010000 : 'JULIUS S',
  304010376 : 'BORDER',
  304010618 : 'CARIBE MARINER',
  304019000 : 'ANTON SCHEPERS',
  304044000 : 'VEGA SCORPIO',
  304071000 : 'ELBTRAVELLER',
  304080000 : 'AURORA',
  304109000 : 'CARIBE VOYAGER',
  304122000 : 'PEGASUS J',
  304157000 : 'HELENE',
  304198000 : 'MARIA DA PAZ',
  304304000 : 'REGULA',
  304373000 : 'JANINA',
  304374000 : 'FREDERIK',
  304416000 : 'STAR COMET',
  304427000 : 'TONY STARK',
  304460000 : 'NADJA',
  304461000 : 'MARINA',
  304474000 : 'PEYTON LYNN C',
  304490000 : 'MANDO',
  304496000 : 'TITAN',
  304504000 : 'LODUR',
  304509000 : 'SAN PEDRO CALUNGSOD',
  304535000 : 'ATLANTIS',
  304619000 : 'NCL HAUGESUND',
  304623000 : 'KATHERINE BORCHARD',
  304642000 : 'ALLEGRO',
  304667000 : 'ELBSAILOR',
  304670000 : 'CHARLOTTE',
  304688000 : 'BF PHILIPP',
  304747000 : 'MAUREN',
  304809000 : 'ZORAN DJINDJIC',
  304811000 : 'HORIZON',
  304812000 : 'BEOGRAD',
  304834000 : 'IRIS MIKO',
  304846000 : 'EMILIA',
  304903000 : 'ASTRID L',
  304906000 : 'DIANA J',
  304907000 : 'BF ESPERANZA',
  304922000 : 'JSP RIDER',
  304941000 : 'DENEB',
  304959000 : 'MARINA L',
  304972000 : 'ALDEBARAN J',
  304978000 : 'JSP SLEIPNER',
  304979000 : 'NCL IKORNNES',
  305002000 : 'OKEE AURELIA',
  305032000 : 'LUCY BORCHARD',
  305055000 : 'PAVO J',
  305060000 : 'PACANDA',
  305079000 : 'JSP BORA',
  305115000 : 'SPICA J',
  305126000 : 'LANTAU BAY',
  305127000 : 'WES SINA',
  305134000 : 'HOHEBANK',
  305140000 : 'TUCANA',
  305213000 : 'AS FRANZISKA',
  305230000 : 'MINDORO',
  305235000 : 'JAN',
  305236000 : 'HOHEPLATE',
  305258000 : 'WARNOW BELUGA',
  305268000 : 'HENRIKE SCHEPERS',
  305287000 : 'NEUENFELDE',
  305291000 : 'BREEZE',
  305293000 : 'PETKUM',
  305299000 : 'SUSAN BORCHARD',
  305313000 : 'ANNALISA P',
  305346000 : 'AS CHRISTIANA',
  305347000 : 'AS CARLOTTA',
  305350000 : 'STORM',
  305409000 : 'AS PIA',
  305411000 : 'SKOGAFOSS',
  305412000 : 'VENTO DI PONENTE',
  305473000 : 'SIARGAO',
  305482000 : 'AS PAMELA',
  305484000 : 'ORION',
  305500000 : 'SAMAL',
  305506000 : 'THEODOR',
  305510000 : 'WIEBKE SCHEPERS',
  305517000 : 'IMKE SCHEPERS',
  305523000 : 'HELGOLAND',
  305524000 : 'HOOGE',
  305538000 : 'CALANDRA',
  305540000 : 'LANGENESS',
  305545000 : 'PANAY',
  305558000 : 'BALTRUM',
  305563000 : 'BORKUM',
  305567000 : 'PALAWAN',
  305568000 : 'CEBU',
  305575000 : 'ELBSPRING',
  305576000 : 'ELBSKY',
  305588000 : 'NORDERNEY',
  305600000 : 'NAAMA BORCHARD',
  305643000 : 'JSP MISTRAL',
  305648000 : 'PHOENIX J',
  305654000 : 'ALEXANDER B',
  305678000 : 'HS BUSAN',
  305680000 : 'HS SINGAPORE',
  305685000 : 'HS HONG KONG',
  305707000 : 'MARUS',
  305723000 : 'PARADERO',
  305741000 : 'BERNHAC',
  305765000 : 'LOUISE BORCHARD',
  305773000 : 'VOHBURG',
  305784000 : 'BF TIGER',
  305832000 : 'DALYA H',
  305886000 : 'AMELIE BORCHARD',
  305904000 : 'NOAH',
  305969000 : 'OCEANA',
  305971000 : 'VIVIENNE SHERI D',
  305977000 : 'MCC SHENZHEN',
  305978000 : 'ELBSTAR',
  305979000 : 'BAKKAFOSS',
  308015000 : 'MOL CHARISMA',
  308025000 : 'RESOLUTION',
  308234000 : 'MYRIAD',
  309046000 : 'MOL CREATION',
  309358000 : 'RELIANCE',
  309403000 : 'DOLE COLOMBIA',
  309901000 : 'MOL CELEBRATION',
  309942000 : 'DOLE CHILE',
  309947000 : 'HYPERION',
  309972000 : 'HARRIER',
  310132000 : 'LISBON EXPRESS',
  310133000 : 'MV VALENCIA EXPRESS',
  310749000 : 'TORONTO EXPRESS',
  310750000 : 'MONTREAL EXPRESS',
  310760000 : 'QUEBEC EXPRESS',
  310761000 : 'ONTARIO EXPRESS',
  310764000 : 'KOBE EXPRESS',
  310765000 : 'LONDON EXPRESS',
  310768000 : 'DUESSELDORF EXPRESS',
  310823000 : 'LIVERPOOL EXPRESS',
  310825000 : 'DUBLIN EXPRESS',
  310826000 : 'GLASGOW EXPRESS',
  311000169 : 'ULTIMA',
  311000414 : 'DOLE PACIFIC',
  311000415 : 'DOLE ATLANTIC',
  311000416 : 'DOLE CARIBBEAN',
  311000629 : 'KUO LIN',
  311000725 : 'EPONYMA',
  311000780 : 'ESTIMA',
  311000921 : 'CMA CGM HYDRA',
  311000922 : 'CMA CGM MUSCA',
  311000923 : 'CMA CGM MARCO POLO',
  311001005 : 'DOLE MAYA',
  311001006 : 'DOLE AZTEC',
  311001120 : 'LIMA EXPRESS',
  311001150 : 'CMA CGM CHIWAN',
  311001218 : 'CMA CGM P.ANTIOQUIA',
  311001244 : 'SUNNY PHOENIX',
  311001345 : 'FELIXSTOWE',
  311001416 : 'CMA CGM BUTTERFLY',
  311043200 : 'CMA CGM AFRICA ONE',
  311043300 : 'CMA CGM AFRICA TWO',
  311044100 : 'CMA CGM AFRICA FOUR',
  311044200 : 'CMA CGM AFRICA THREE',
  311049600 : 'OPTIMA',
  311058000 : 'HALCYON',
  311060500 : 'KUMASI',
  311272000 : 'REVERENCE',
  311326000 : 'NICOLAS DELMAS',
  311365000 : 'RESURGENCE',
  311655000 : 'MARVEL',
  311691000 : 'REFLECTION',
  311703000 : 'MAGNA',
  311934000 : 'MUSE',
  311935000 : 'RENOWN',
  312016000 : 'RISUN 9',
  312101000 : 'LADY NURAY',
  312338000 : 'JING YE',
  312479000 : 'GRETA',
  312637000 : 'NIU FUHAI',
  312991000 : 'SKY LIGHT',
  314292000 : 'SAMSKIP COMMANDER',
  314732000 : 'SPICA STAR',
  314747000 : 'RMS MEL',
  314749000 : 'HANA H',
  314753000 : 'GLEVIN',
  314754000 : 'RMS TEAM',
  314792000 : 'PALERMO',
  316006074 : 'OCEANEX AVALON',
  316030879 : 'ASTERIX',
  319200700 : 'NYK DAEDALUS',
  319211600 : 'ONE ARCADIA',
  319219200 : 'ONE TRITON',
  325974000 : 'CARINA',
  334953000 : 'AMO 2',
  334955000 : 'AMO',
  338071000 : 'MAERSK PITTSBURGH',
  338078000 : 'MAERSK ATLANTA',
  338121000 : 'LURLINE',
  338241000 : 'MAERSK KINLOSS',
  338403000 : 'MAERSK HARTFORD',
  338408000 : 'MAERSK CHICAGO',
  338418000 : 'MAERSK DENVER',
  338459000 : 'APL ISLANDER',
  338474000 : 'MAERSK DETROIT',
  338525000 : 'MAERSK COLUMBUS',
  338566000 : 'KAMOKUIKI',
  338679000 : 'PRESIDENT TRUMAN',
  338760000 : 'ISLA BELLA',
  338775000 : 'MAERSK CHESAPEAKE',
  338789000 : 'PERLA DEL CARIBE',
  338796000 : 'MATSONIA',
  338864000 : 'MV PAGE',
  341063001 : 'BOSON',
  341104000 : 'MM MADRID',
  341233001 : 'SWIMMER.M',
  341257000 : 'URANUS',
  341258000 : 'SHIRA-E',
  341281001 : 'AK FERAS',
  341339000 : 'GLUON',
  341540000 : 'MARSA VICTORY',
  341644000 : 'TANYA',
  341652000 : 'DHANU',
  341765000 : 'NADIA',
  341817000 : 'MARSA NEPTUNE',
  341884000 : 'LUCIA',
  341988000 : 'CERUS',
  351018000 : 'SOL PROGRESS',
  351038000 : 'MCC TAIPEI',
  351109000 : 'OOCL DURBAN',
  351218000 : 'OEL SHASTA',
  351248000 : 'MSC LUDOVICA',
  351249000 : 'NYK OCEANUS',
  351268000 : 'MSC SOPHIE',
  351272000 : 'BANGKOK',
  351285000 : 'MSC ASTRID III',
  351325000 : 'POLARIS 3',
  351356000 : 'MSC SARISKA V',
  351361000 : 'MSC KALINA',
  351400000 : 'MSC HANNAH',
  351404000 : 'HMM ST PETERSBURG',
  351453000 : 'SUNNY DAISY',
  351465000 : 'EVER BLISS',
  351473000 : 'MSC ALYSSA',
  351496000 : 'MOL EARNEST',
  351513000 : 'PACIFIC GRACE',
  351563000 : 'AEGEAN EXPRESS',
  351566000 : 'ISEACO FORTUNE',
  351567000 : 'STRIDE',
  351571000 : 'EVER FOREVER',
  351577000 : 'FUTURE',
  351607000 : 'MSC MONICA III',
  351634000 : 'MSC MARIA LAURA II',
  351639000 : 'PENANG BRIDGE',
  351645000 : 'ADVANCE',
  351663000 : 'A GALAXY',
  351664000 : 'ITX EHIME',
  351674000 : 'SUNNY LAUREL',
  351675000 : 'MSC KATYAYNI',
  351676000 : 'MOHSEN ILYAS',
  351678000 : 'MAERSK DHAKA',
  351702000 : 'BAL STAR',
  351738000 : 'MSC MARTINA',
  351753000 : 'EVER CORE',
  351819000 : 'MSC ALGHERO',
  351841000 : 'SPRINTER',
  351848000 : 'MSC KATIE',
  351862000 : 'MSC MINA',
  351917000 : 'MSC HELENA III',
  351934000 : 'EVER UTILE',
  351965000 : 'KUO LUNG',
  351977000 : 'MAERSK ARAS',
  352001038 : 'EVER OPUS',
  352001068 : 'EVER OATH',
  352001116 : 'TRANSMAR LEGACY',
  352001168 : 'MSC SANTA MARIA',
  352001178 : 'EVER CONFORM',
  352001244 : 'MSC FLOSTA III',
  352001259 : 'EVER ALOT',
  352001261 : 'EVER OBEY',
  352001313 : 'EVER TOP',
  352001323 : 'MSC AUBE F',
  352001363 : 'EVER CONSIST',
  352001367 : 'MSC BARBADOS',
  352001525 : 'EVER OPTIMA',
  352001564 : 'MEDKON SAMSUN',
  352001639 : 'PROVIDENT',
  352001683 : 'ANL TASMAN TRADER',
  352001685 : 'CMA CGM BLOSSOM',
  352001710 : 'MEDKON MERSIN',
  352001731 : 'GH RIVER',
  352001747 : 'EVER ARIA',
  352001841 : 'XIN HE LU 1',
  352001848 : 'ZHONG XING HONG YUN',
  352001881 : 'HUA XIANG 936',
  352001951 : 'EVER OUTWIT',
  352001987 : 'MAO GANG QUAN ZHOU',
  352002065 : 'MAERSK ACADIA',
  352002086 : 'LIDER PERIHAN',
  352002088 : 'SOL RESILIENCE',
  352002094 : 'KANWAY LUCKY',
  352002104 : 'SKY IRIS',
  352002182 : 'FESCO TRADER',
  352002186 : 'YASAR',
  352002215 : 'YONG YUE 11',
  352002245 : 'MAERSK BISCAYNE',
  352002265 : 'HONOR OCEAN',
  352002269 : 'HONOR VOYAGER',
  352002312 : 'MERATUS JAYAWIJAYA',
  352002350 : 'D QUEENS',
  352002356 : 'SHUN LONG',
  352002364 : 'MEDKON CANAKKALE',
  352002368 : 'A GORYU',
  352002391 : 'MAERSK EVERGLADES',
  352002399 : 'POS QINGDAO',
  352002408 : 'POS GUANGZHOU',
  352002410 : 'SKY TIARA',
  352002451 : 'A KOBE',
  352002466 : 'EUPHORIA',
  352002487 : 'SFT TURKEY',
  352002499 : 'OVP ARIES',
  352002534 : 'XIN HE LU 2',
  352002539 : 'MAO GANG GUANG ZHOU',
  352002559 : 'ESCADA',
  352002561 : 'MERATUS JAYAKARTA',
  352002579 : 'A WASHIBA',
  352002592 : 'ZEYNEP',
  352002597 : 'OPUS ONE',
  352002621 : 'STAR SOURCE',
  352002625 : 'MAERSK GLACIER',
  352002643 : 'XIANG YING',
  352002645 : 'MERATUS JIMBARAN',
  352002648 : 'MERATUS TOMINI',
  352002649 : 'MERATUS JAYAGIRI',
  352002650 : 'MERATUS MEDAN 5',
  352002731 : 'SUNNY LILAC',
  352002748 : 'HONWELL',
  352002790 : 'MEDKON LIA',
  352002798 : 'ALEJANDRINA',
  352002865 : 'HONRISE',
  352002880 : 'STAR BLESSING',
  352002943 : 'MEDKON PERLA',
  352002971 : 'YEOSU VOYAGER',
  352002972 : 'INCHEON VOYAGER',
  352002986 : 'ABRAO COCHIN',
  352002992 : 'MARSA PRIDE',
  352002996 : 'STAR BRIGHT',
  352002997 : 'MAERSK MAMMOTH',
  352003005 : 'NZ SHANGHAI',
  352003007 : 'SIDRA OCEAN',
  352003029 : 'MED STAR',
  352003036 : 'A ONTAKE',
  352003050 : 'RC OCEAN',
  352003066 : 'HONG CHANG SHENG',
  352003081 : 'SUNNY VIOLET',
  352003099 : 'TC MESSENGER',
  352003103 : 'CTM ISTMO',
  352003107 : 'ULSAN VOYAGER',
  352003112 : 'BRIGHT SAKURA',
  352003114 : 'BRIGHT COSMOS',
  352003133 : 'FLYING FISH 1',
  352003243 : 'ITX HIGO',
  352003254 : 'AN HAI',
  352003258 : 'GRAND MARINER',
  352003293 : 'YUAN XIANG FEN JIN',
  352003391 : 'HUA XIN 678',
  352029885 : 'EVER CLEVER',
  352058000 : 'SUNNY CANNA',
  352070000 : 'SPIL CAYA',
  352111000 : 'EVER FINE',
  352135000 : 'EVER LOTUS',
  352138000 : 'MSC JEMIMA',
  352140000 : 'MSC LORETTA',
  352149000 : 'TOLEDO TRIUMPH',
  352153000 : 'SONGYUNHE',
  352157000 : 'PROGRESS C',
  352254000 : 'MAERSK NORESUND',
  352260000 : 'HMM LE HAVRE',
  352269000 : 'MSC AMBRA',
  352297000 : 'FESCO EKATERINA',
  352335000 : 'MSC MARIANNA',
  352358000 : 'CAP SAN ARTEMISSIO',
  352361000 : 'MSC BETTINA',
  352366000 : 'TEXAS TRIUMPH',
  352374000 : 'KMTC TOKYO',
  352382000 : 'TI2 APPLE',
  352385000 : 'NAVIOS NERINE',
  352408000 : 'MERIOS',
  352456000 : 'MIYUNHE',
  352471000 : 'MSC ALIZEE III',
  352544000 : 'MARINA VOYAGER',
  352578000 : 'MSC QINGDAO F',
  352619000 : 'MSC ADELAIDE',
  352631000 : 'COSCO AMERICA',
  352687000 : 'SOL MALAYSIA',
  352688000 : 'MSC FELIXSTOWE',
  352745000 : 'SOL FORTUNE',
  352757000 : 'MSC ZOE',
  352822000 : 'NYK ORION',
  352830000 : 'ORIENTAL BRIGHT',
  352853000 : 'MSC LONDON',
  352866000 : 'EVER CHEER',
  352870000 : 'MSC FLORENTINA',
  352871000 : 'MSC CANDICE',
  352898704 : 'CHENNAI VOYAGER',
  352898711 : 'MSC LEVANTE F',
  352898717 : 'EVER ALP',
  352898766 : 'EVER CONNECT',
  352898818 : 'MSC SANDY III',
  352921000 : 'MEDKON NLS',
  352948000 : 'MSC NERISSA',
  352952000 : 'MSC NEW YORK',
  352965000 : 'YAKOOT',
  352978125 : 'HE YUAN 1',
  352978135 : 'HUI DA 9',
  352978152 : 'HUI FA',
  352978178 : 'LOA GLORY',
  352978183 : 'EVER COMPOSE',
  352978194 : 'YM TOPMOST',
  352978199 : 'EVER ACT',
  352978207 : 'STORM I',
  352978233 : 'SPIL NIRMALA',
  352980000 : 'M.V ATA-1 !',
  352980780 : 'SPIL NINGSIH',
  352980788 : 'EVER AIM',
  352980799 : 'SPIL NITA',
  352980812 : 'EVER CONCISE',
  352980861 : 'EVER OWN',
  352980873 : 'EVER CONCERT',
  352983000 : 'MSC STELLA',
  352986146 : 'EVER ACE',
  352986159 : 'YM TRAVEL',
  352986168 : 'SPIL NISAKA',
  352986169 : 'SPIL NIKEN',
  353000000 : 'NAVIOS ALTAIR',
  353025000 : 'NYK DEMETER',
  353051000 : 'MSC GAIA',
  353059000 : 'HEUNG-A SARAH',
  353066000 : 'QI YUN HE',
  353089000 : 'SITC DANANG',
  353111000 : 'MSC SHANELLE V',
  353117000 : 'MAERSK PUELO',
  353136000 : 'EVER GIVEN',
  353155000 : 'MSC EMANUELA',
  353160000 : 'MSC SONIA',
  353162000 : 'MSC BEATRICE',
  353201000 : 'POS HOCHIMINH',
  353241000 : 'JINYUNHE',
  353258000 : 'EASLINE LIANYUNGANG',
  353289000 : 'MSC ORIANE',
  353290000 : 'MILLENNIUM BRIGHT',
  353293000 : 'STRAITS CITY',
  353346000 : 'SITC KOBE',
  353365000 : 'CAP SAN MALEAS',
  353368000 : 'BANGKOK BRIDGE',
  353428000 : 'MSC LAUREN',
  353467000 : 'DOLPHIN II',
  353499000 : 'MSC DAMLA',
  353590000 : 'MSC ISABELLA',
  353592000 : 'ONE HANOI',
  353612000 : 'MSC BRIANNA',
  353640000 : 'SKY ORION',
  353666000 : 'MSC MICHAELA',
  353719000 : 'MSC MATILDE V',
  353728000 : 'MSC MADELEINE',
  353775000 : 'MSC BARBARA',
  353780000 : 'ALEXANDRIA BRIDGE',
  353800000 : 'EVER BREED',
  353824000 : 'ISEACO WISDOM',
  353848000 : 'HIGHWAY',
  353850000 : 'MSC AUGUSTA',
  353851000 : 'BRIDGE',
  353852000 : 'MSC ANNAMARIA',
  353873000 : 'MSC ANS',
  353903000 : 'SEATTLE BRIDGE',
  353934000 : 'MSC ELA',
  353968000 : 'MSC LISA',
  353997000 : 'ONE MONACO',
  354021000 : 'LOA PEACE',
  354022000 : 'EVER FORE',
  354060000 : 'MAERSK KARUN',
  354092000 : 'MSC LAURA',
  354106000 : 'CALLAO BRIDGE',
  354118000 : 'LOS ANDES BRIDGE',
  354120000 : 'BAY BRIDGE',
  354194000 : 'BAL PEACE',
  354195000 : 'UGL GUANGZHOU',
  354202000 : 'MSC ORNELLA',
  354212000 : 'NYK METEOR',
  354275000 : 'KOTA NILAM',
  354299000 : 'GREEN CELESTE',
  354315000 : 'MSC TOMOKO',
  354340000 : 'MSC MELISSA',
  354358000 : 'COSCO KIKU',
  354380000 : 'SIDRA HALIMA',
  354401000 : 'KMTC POHANG',
  354415000 : 'MSC FABIENNE',
  354462000 : 'MSC ROSA M',
  354464000 : 'DONG YU',
  354467000 : 'KMTC SURABAYA',
  354499000 : 'MSC ESTHI',
  354505000 : 'MSC INGRID',
  354530000 : 'MSC CARMEN',
  354537000 : 'MSC MANDY III',
  354540000 : 'MSC SHANNON III',
  354625000 : 'MSC CAMILLE',
  354646000 : 'EASLINE KWANGYANG',
  354654000 : 'EVER GOLDEN',
  354690000 : 'EVER CAST',
  354711000 : 'MSC MARIA PIA',
  354712000 : 'MSC RACHELE',
  354725000 : 'MSC KRITTIKA',
  354727000 : 'OSLO TRADER',
  354744000 : 'MSC ASYA',
  354745000 : 'MSC TAMARA',
  354751000 : 'ORCA I',
  354776000 : 'MSC RAFAELA',
  354785000 : 'KMARIN ATLANTICA',
  354839000 : 'ONE HAMMERSMITH',
  354884000 : 'SPIL KARTIKA',
  354886000 : 'COSCO EUROPE',
  354891000 : 'NYK NEBULA',
  354928000 : 'ONE SWAN',
  354932000 : 'TAICHUNG',
  354942000 : 'BEAR MOUNTAIN BRIDGE',
  354972000 : 'MSC MARINA',
  354977000 : 'EVER GLOBE',
  355006000 : 'MATSON KAUAI',
  355028000 : 'MSC AMERICA III',
  355033000 : 'HF SPIRIT',
  355106000 : 'EVER BURLY',
  355113000 : 'KMARIN AZUR',
  355129000 : 'MSC TASMANIA',
  355194000 : 'MSC JORDAN III',
  355216000 : 'MSC MAUREEN',
  355224000 : 'GREEN CELEBRITY',
  355232000 : 'ST SUCCESS',
  355233000 : 'MSC IRENE',
  355249000 : 'MSC ATLANTIC III',
  355254000 : 'MSC NEDERLAND III',
  355271000 : 'ONE HONG KONG',
  355283000 : 'MSC LUCIANA',
  355288000 : 'MAERSK ENSHI',
  355289000 : 'MSC SUEZ',
  355305000 : 'MSC SANDRA',
  355308000 : 'MSC LUISA',
  355341000 : 'EVER CHARM',
  355363000 : 'MSC SANTHYA',
  355400000 : 'SAN FRANCISCO BRIDGE',
  355433000 : 'NYK CONSTELLATION',
  355443000 : 'OOCL BRAZIL',
  355449000 : 'SITC TOKYO',
  355474000 : 'CAIYUNHE',
  355502000 : 'PADIAN 4',
  355537000 : 'EVER CALM',
  355623000 : 'EVER FAITH',
  355632000 : 'MAERSK NUSSFJORD',
  355674000 : 'RUN SHENG',
  355714000 : 'MSC IMMA III',
  355722000 : 'EVER CHASTE',
  355738000 : 'MSC REBECCA III',
  355746000 : 'MSC LARA II',
  355771000 : 'HF LUCKY',
  355776000 : 'MSC MAKOTO II',
  355798000 : 'MSC MIA',
  355808000 : 'COSCO NEW YORK',
  355898000 : 'SITC TIANJIN',
  355902000 : 'UNI-PREMIER',
  355906000 : 'MSC OSCAR',
  355919000 : 'MSC MARIA ELENA',
  356012000 : 'SEA OF LUCK',
  356015000 : 'MAERSK VLADIVOSTOK',
  356037000 : 'MSC JOY',
  356093000 : 'MSC JUDITH',
  356101000 : 'MSC SABRINA III',
  356112000 : 'MSC INES',
  356162000 : 'TSS SHAMS',
  356167000 : 'MSC ELEONORA',
  356189000 : 'YM MOVEMENT',
  356205000 : 'MSC KIM',
  356207000 : 'OEL COLOMBO',
  356217000 : 'PHOENIX D',
  356234000 : 'MSC NELA',
  356249000 : 'MSC SAMANTHA VI',
  356289000 : 'MSC OLIVER',
  356298000 : 'EMORA',
  356330000 : 'MSC KRYSTAL',
  356352000 : 'BAI CHAY BRIDGE',
  356367000 : 'PEARL RIVER BRIDGE',
  356369000 : 'MCC DANANG',
  356396000 : 'EASLINE YANTAI',
  356407000 : 'EVER LUCKY',
  356426000 : 'LADY OF LUCK',
  356432000 : 'MSC LENI',
  356481000 : 'SUNNY ACACIA',
  356505000 : 'MSC ESHA F',
  356517000 : 'EVER CLEAR',
  356554000 : 'ATHENS BRIDGE',
  356565000 : 'MOL PREMIUM',
  356575000 : 'HAKATA SEOUL',
  356578000 : 'VICTORY VOYAGER',
  356579000 : 'MSC DON GIOVANNI',
  356581000 : 'COSCO ASIA',
  356582000 : 'EVER GOVERN',
  356629000 : 'NYK PAULA',
  356712000 : 'HMM COPENHAGEN',
  356758000 : 'MAERSK NESNA',
  356792000 : 'LIDER HALIL',
  356815000 : 'ISEACO GENESIS',
  356853000 : 'MAERSK JAKARTA',
  356864000 : 'MSC ROSSELLA',
  356886000 : 'NIIGATA TRADER',
  356893000 : 'EASLINE OSAKA',
  356904000 : 'MSC ALEXA',
  356907000 : 'EVER CHANT',
  356931000 : 'QUEZON BRIDGE',
  356946000 : 'MSC VANESSA',
  356984000 : 'ONE TRUTH',
  356985000 : 'SITC YOKOHAMA',
  357025000 : 'FENGYUNHE',
  357048000 : 'MEDKON LTF',
  357051000 : 'MSC DANIT',
  357067000 : 'MSC ELENI',
  357077000 : 'EVER UNITY',
  357096000 : 'MSC AGRIGENTO',
  357104000 : 'MAERSK ESMERALDAS',
  357106000 : 'MSC DIEGO',
  357131000 : 'UNI PACIFIC',
  357142000 : 'SOL STRIDE',
  357157000 : 'UNI-ASSENT',
  357175000 : 'NYK REMUS',
  357178000 : 'GREEN CLARITY',
  357191000 : 'MSC NILGUN',
  357210000 : 'ONE TREASURE',
  357214000 : 'CMA CGM ALCAZAR',
  357231000 : 'ATHENA',
  357240000 : 'MSC MELATILDE',
  357286000 : 'IBN AL ABBAR',
  357332000 : 'MSC REGINA',
  357345000 : 'MARIA DISCO??2Y',
  357368000 : 'SITC NAGOYA',
  357380000 : 'MAIRA',
  357388000 : 'NIKOLAS',
  357398000 : 'UNI-ASSURE',
  357405000 : 'MSC POH LIN',
  357411000 : 'NEWYORKER',
  357413000 : 'EVER BRACE',
  357420000 : 'EVER CANDID',
  357444000 : 'MSC MEDITERRANEAN',
  357455000 : 'MSC CANBERRA',
  357463000 : 'EVER GRADE',
  357493000 : 'EVER URANUS',
  357506000 : 'MSC ANIELLO',
  357542000 : 'MSC GINA',
  357547000 : 'SAN DIEGO BRIDGE',
  357562000 : 'MSC MALIN',
  357577000 : 'KOTA NEKAD',
  357622000 : 'BOYA',
  357632000 : 'MSC EYRA',
  357702000 : 'A395',
  357737000 : 'EVER URSULA',
  357773000 : 'MSC IRIS',
  357779000 : 'JORDANIA',
  357787000 : 'UNI PATRIOT',
  357842000 : 'A SUKAI',
  357848000 : 'ARICA BRIDGE',
  357874000 : 'EVER USEFUL',
  357891000 : 'MSC TERESA',
  357897000 : 'ASIA EXPRESS',
  357926000 : 'MSC BERYL',
  357939000 : 'EVER URBAN',
  357962000 : 'UNI PHOENIX',
  357979000 : 'UNI PERFECT',
  357980000 : 'UNI-PRUDENT',
  357981000 : 'UNI POPULAR',
  357988000 : 'SEROJA LIMA',
  366418000 : 'POTOMAC EXPRESS',
  366557000 : 'MATSON ANCHORAGE',
  366562000 : 'MANOA',
  366563000 : 'MAHIMAHI',
  366629000 : 'HORIZON SPIRIT',
  366791000 : 'GEORGE II',
  366793000 : 'MATSON KODIAK',
  366794000 : 'MATSON TACOMA',
  367196000 : 'MOKIHANA',
  367422000 : 'DELAWARE EXPRESS',
  367438000 : 'MAUNAWILI',
  367578740 : 'PRESIDENT WILSON',
  367606000 : 'MAERSK IOWA',
  367619000 : 'APL OCEANIA',
  367759000 : 'MAERSK MONTANA',
  367775000 : 'MAERSK OHIO',
  367781000 : 'MISSOURI EXPRESS',
  367834000 : 'GARY I GORDON',
  368053000 : 'MAERSK KANSAS',
  368160000 : 'SAGAMORE',
  368305000 : 'MANULANI',
  368359000 : 'HUDSON EXPRESS',
  369096000 : 'MAERSK SARATOGA',
  369207000 : 'DANIEL K. INOUYE',
  369209000 : 'KAIMANA HILA',
  369215000 : 'COLORADO EXPRESS',
  369246000 : 'MAERSK SENTOSA',
  369248000 : 'MAERSK YORKTOWN',
  369309000 : 'MAERSK SELETAR',
  369339000 : 'PRESIDENT KENNEDY',
  369340000 : 'MAERSK DURBAN',
  369355000 : 'GEORGE III',
  369357000 : 'JANET MARIE',
  369390000 : 'MAERSK TENNESSEE',
  369558000 : 'APL EAGLE',
  369751000 : 'PRESIDENTFDROOSEVELT',
  370012000 : 'SOL VALOUR',
  370014000 : 'BLPL FAITH',
  370031000 : 'DSL MARINER',
  370109000 : 'PADIAN 3',
  370121000 : 'EVER GENIUS',
  370188000 : 'COSCO AFRICA',
  370206000 : 'RUN LONG',
  370254000 : 'MSC ANGELA',
  370271000 : 'MSC SORAYA',
  370273000 : 'MSC NURIA',
  370288000 : 'SARA STAR',
  370320000 : 'MSC NAGOYA V',
  370341000 : 'MSC VALERIA',
  370354000 : 'HF FORTUNE',
  370357000 : 'M.V.KOTA KAYA',
  370376000 : 'EVER CROWN',
  370437000 : 'MSC CORINNA',
  370439000 : 'ACX CRYSTAL',
  370442000 : 'SPIL KARTINI',
  370511000 : 'SEDRA',
  370587000 : 'ONE HENRY HUDSON',
  370616000 : 'EVER BUILD',
  370651000 : 'ONE CRANE',
  370700000 : 'EVER BOOMY',
  370711000 : 'MSC SIXIN',
  370892000 : 'MSC DANIELA',
  370894000 : 'MSC SOLA',
  370928000 : 'MSC DAVAO III',
  370930000 : 'MSC IVANA',
  370932000 : 'OEL INDIA',
  370966000 : 'JI PENG',
  370993000 : 'MSC FRANCESCA',
  371002000 : 'EASLINE DALIAN',
  371047000 : 'MSC SAMAR',
  371057000 : 'MSC ELOISE',
  371059000 : 'MSC LUCY',
  371076000 : 'ONE MILANO',
  371141000 : 'EVER CENTER',
  371215000 : 'ONE MILLAU',
  371218000 : 'MSC EVA',
  371228000 : 'MSC PAMELA',
  371233000 : 'MSC PALOMA',
  371243000 : 'MSC KOREA',
  371245000 : 'MSC RITA',
  371249000 : 'KMTC GWANGYANG',
  371274000 : 'MAERSK ENSENADA',
  371308000 : 'EVER GOODS',
  371319000 : 'HMM OSLO',
  371369000 : 'POS BANGKOK',
  371443000 : 'MSC JAPAN',
  371444000 : 'MCC YANGON',
  371449000 : 'NYK RIGEL',
  371466000 : 'MSC SUSANNA',
  371474000 : 'MSC MARTA',
  371475000 : 'MSC CAROLINA',
  371519000 : 'MARCOS V',
  371543000 : 'MSC CHULAI III',
  371582000 : 'MSC LAURENCE',
  371602000 : 'MSC ALABAMA III',
  371633000 : 'YM MODERATION',
  371711000 : 'MSC GIANNA III',
  371714000 : 'MARGARETRIVERBRIDGE',
  371743000 : 'MSC BELLE',
  371752000 : 'MSC RANIA',
  371753000 : 'MSC ALEXANDRA',
  371791000 : 'HORAI BRIDGE',
  371793000 : 'A HOUOU',
  371799000 : 'GEORGE WASHINGTON BR',
  371818000 : 'PANDA VEGA',
  371829000 : 'EVER GREET',
  371836000 : 'MOL ENDOWMENT',
  371851000 : 'MSC MAEVA',
  371860000 : 'MSC LORENA',
  371866000 : 'CMA CGM KAILAS',
  371908000 : 'MSC SILVANA',
  371929000 : 'MSC ELAINE',
  372003000 : 'MSC GULSUN',
  372008000 : 'MSC HEIDI',
  372023000 : 'BAL BOAN',
  372038000 : 'ONE HUMBER',
  372050000 : 'MSC JOANNA',
  372051000 : 'MSC VITTORIA',
  372104000 : 'ONE HANNOVER',
  372123000 : 'MSC LEIGH',
  372162000 : 'ABANOZ',
  372174000 : 'LDR BEDEL',
  372218000 : 'NYK VEGA',
  372242000 : 'GREEN HORIZON',
  372280000 : 'ONE HAWK',
  372319000 : 'NYK DIANA',
  372354000 : 'EVER BRAVE',
  372367000 : 'ONE HARBOUR',
  372400000 : 'MSC MARIA SAVERIA',
  372440000 : 'XINQUNDAO',
  372490000 : 'SKY WIND',
  372491000 : 'MSC DONATA',
  372512000 : 'NYK VENUS',
  372531000 : 'NYK VESTA',
  372546000 : 'JAWAN',
  372570000 : 'SUNNY LAVENDER',
  372588000 : 'MCC TOKYO',
  372596000 : 'MAERSK NORDDAL',
  372604000 : 'KMTC OSAKA',
  372665000 : 'HUMEN BRIDGE',
  372707000 : 'M WILMINGTON',
  372723000 : 'CAP SAN SOUNIO',
  372724000 : 'ONE HAMBURG',
  372729000 : 'MSC FEBE',
  372733000 : 'EVER FIT',
  372736000 : 'MSC SINDY',
  372737000 : 'MSC ROSARIA',
  372753000 : 'NAVIOS JASMINE',
  372762000 : 'D ANGELS',
  372765000 : 'SITC HONGKONG',
  372797000 : 'OYSTER',
  372800000 : 'MOL SPARKLE',
  372818000 : 'GAUJA',
  372827000 : 'MOL EXPLORER',
  372843000 : 'MSC FIAMMETTA',
  372862000 : 'MSC GABRIELLA',
  372925000 : 'SITC RIZHAO',
  372926000 : 'GANG TONG 19',
  372932000 : 'MCC CEBU',
  372934000 : 'COSCO BOSTON',
  372946000 : 'SWIMMER',
  372955000 : 'MOL EXPERIENCE',
  372972000 : 'MSC BANU III',
  372973000 : 'MSC PINA',
  373004000 : 'MSC FLAVIA',
  373005000 : 'MSC VANDYA',
  373021000 : 'MSC SHAY',
  373031000 : 'MSC CAPELLA',
  373044000 : 'NUMBER 9',
  373068000 : 'MSC REGULUS',
  373069000 : 'MSC AURORA',
  373080000 : 'HE SHENG',
  373119000 : 'ONE HELSINKI',
  373126000 : 'SKY FLOWER',
  373128000 : 'SKY HOPE',
  373136000 : 'ONYX 1',
  373184000 : 'ORITA',
  373220000 : 'KMTC INCHEON',
  373233000 : 'MSC CLORINDA',
  373260000 : 'MAERSK NARVIK',
  373271000 : 'MAERSK IYO',
  373285000 : 'EVER FRONT',
  373314000 : 'MSC SUPRANA F',
  373316000 : 'XIN HE DA',
  373319000 : 'EVER COPE',
  373355000 : 'MSC ARIANE',
  373404000 : 'MSC KATRINA',
  373457000 : 'MOL MAESTRO',
  373459000 : 'SPIL CITRA',
  373485000 : 'ONE HOUSTON',
  373517000 : 'MSC DYMPHNA',
  373579000 : 'MSC GIANNINA II',
  373582000 : 'MSC CELINE',
  373595000 : 'KMTC PENANG',
  373598000 : 'MSC AMSTERDAM',
  373649000 : 'ONE HONOLULU',
  373712000 : 'EVER FAME',
  373721000 : 'EVER LADEN',
  373839000 : 'SING',
  373911000 : 'ONE MUNCHEN',
  373932000 : 'ONE HANGZHOU BAY',
  374091000 : 'HONG PROSPERITY',
  374158000 : 'EVER FOCUS',
  374239000 : 'NZ NINGBO',
  374286000 : 'BLPL TRUST',
  374302000 : 'SUNNY ROSE',
  374391000 : 'MSC TIGER F',
  374431000 : 'SUNNY CLOVER',
  374508000 : 'FILOTIMO',
  374571000 : 'SOL PROMISE',
  374588000 : 'GREEN HOPE',
  374607000 : 'MSC ARINA',
  374629000 : 'JVK STAR',
  374645000 : 'MSC SVEVA',
  374658000 : 'CAP SAN TAINARO',
  374766000 : 'MSC CLARA',
  374776000 : 'NAVIOS MAGNOLIA',
  374782000 : 'KMTC NAGOYA',
  374815000 : 'ONE IBIS',
  374855000 : 'HAMBURG TRADER',
  374858000 : 'ONE AQUILA',
  374859000 : 'MSC MAYA',
  374886000 : 'MSC HAMBURG',
  374898000 : 'EVER BIRTH',
  374935000 : 'TAMPA TRIUMPH',
  374946000 : 'MSC ULSAN III',
  375172000 : 'LEO',
  375751000 : 'GFS JUNO',
  376609000 : 'ALLEGRO',
  376846000 : 'ACE',
  376908000 : 'INTER SYDNEY',
  377074000 : 'ATHENA',
  377311000 : 'GFS JADE',
  377523000 : 'ARIES',
  377751000 : 'GFS RANNA',
  377782000 : 'LIBRA',
  377901104 : 'TROPIC FREEDOM',
  377901107 : 'TROPIC HOPE',
  377901110 : 'TROPIC GEM',
  377901111 : 'TROPIC ISLAND',
  377901119 : 'TROPIC JEWEL',
  377901123 : 'TROPIC LISSETTE',
  405000190 : 'M.V. HARBOUR-1',
  405000223 : 'INVICTA 1',
  405000291 : 'HR SARERA',
  405000292 : 'HR SAHARE',
  405000308 : 'HR RHEA',
  405000309 : 'HR HER',
  405000337 : 'HR FARHA',
  405000338 : 'HR AARAI',
  405000384 : 'HR BALU',
  405000386 : 'HR TURAG',
  412123456 : 'XIN HE SHI BA',
  412200640 : 'DA FU',
  412633000 : 'TIAN JIN HE',
  412658000 : 'TIAN SHENG HE',
  412702640 : 'HUA HANG 1',
  412705880 : 'XIN LONG YUN 86',
  412713000 : 'TIAN KANG HE',
  412714000 : 'TIAN AN HE',
  412746000 : 'TIAN FU HE',
  412750000 : 'TIAN QING HE',
  412767360 : 'XINMINGZHOU78',
  412767750 : 'XIN MING ZHOU 82',
  413050000 : 'XIN DA LIAN',
  413053000 : 'XIN TIAN JIN',
  413054000 : 'XIN QING DAO',
  413055000 : 'XIN CHONG QING',
  413057000 : 'XIN NING BO',
  413058000 : 'XIN LIAN YUN GANG',
  413059000 : 'XIN YANG ZHOU',
  413060000 : 'XIN PU DONG',
  413063000 : 'XIN NAN TONG',
  413064000 : 'XIN YAN TIAN',
  413065000 : 'XIN XIA MEN',
  413068000 : 'XIN SU ZHOU',
  413072000 : 'XIN CHI WAN',
  413073000 : 'XIN QIN HUANG DAO',
  413076000 : 'XIN FU ZHOU',
  413123456 : 'HY4',
  413125000 : 'XIN YAN TAI',
  413131000 : 'XIN YANG SHAN',
  413132000 : 'XIN QUAN ZHOU',
  413133000 : 'XIN CHANG SHU',
  413138000 : 'XIN FANG CHENG',
  413141000 : 'XIN SHAN TOU',
  413142000 : 'XIN HUANG PU',
  413144000 : 'XIN HAI KOU',
  413145000 : 'XIN BEI LUN',
  413146000 : 'XIN CHANG SHA',
  413147000 : 'XIN NAN SHA',
  413148000 : 'XIN RI ZHAO',
  413149000 : 'XIN WEI HAI',
  413150000 : 'XIN ZHAN JIANG',
  413151000 : 'XIN YING KOU',
  413153000 : 'XIN DAN DONG',
  413159000 : 'XIN OU ZHOU',
  413161000 : 'XIN YA ZHOU',
  413165000 : 'XIN MEI ZHOU',
  413166000 : 'XIN TAI CANG',
  413167000 : 'XIN YANG PU',
  413169000 : 'XIN WU HAN',
  413170000 : 'XIN ZHANG ZHOU',
  413171000 : 'XIN FEI ZHOU',
  413173000 : 'XIN DA YANG ZHOU',
  413205380 : 'JI FA NAN HAI',
  413205520 : 'JI FA BEI HAI',
  413209990 : 'REN JIAN FU ZHOU',
  413211430 : 'HAN HAI 8 HAO',
  413213320 : 'ZHONG GU NAN HAI',
  413215160 : 'ZHONG GU DONG HAI',
  413218940 : 'ZHONGWAIYUN SHENZHEN',
  413219020 : 'ZHONG WAI YUN NINGBO',
  413220120 : 'ZHONG GU HUANG HAI',
  413222810 : 'ZHONG GU BO HAI',
  413223320 : 'ZHONG GU BEI HAI',
  413228080 : 'ZHONG GU DIZHONG HAI',
  413230310 : 'JOSCO XINGFU',
  413238570 : 'ZHONG HANG SHENG',
  413247480 : 'ZHONG GU LIN YI',
  413256960 : 'HAN HAI 5 HAO',
  413262830 : 'HUA SHENG 67',
  413263160 : 'ZHONG GU DONG GUAN',
  413274380 : 'HAI FENG HAI KOU',
  413275260 : 'HUA XIN 968',
  413280820 : 'TIAN HAI PING ZE',
  413281350 : 'JIANG YUAN YUAN DA',
  413284260 : 'JOSCO BELLE',
  413293840 : 'XIN MING ZHOU 98',
  413295960 : 'QING YUN HE',
  413297720 : 'XIN MING ZHOU 102',
  413298050 : 'XIN MING ZHOU 88',
  413301510 : 'TIAN FU(TIANJIN)',
  413307840 : 'XIN MING ZHOU 90',
  413310040 : 'XIN MING ZHOU 106',
  413314440 : 'XIN MING ZHOU 92',
  413329850 : 'HAI FENG LIAN XING',
  413333930 : 'FEI YUN HE',
  413334630 : 'HUA HANG HAN YA 1',
  413336090 : 'XIN MING ZHOU 108',
  413337240 : 'XIN MING ZHOU 96',
  413338660 : 'ZHONG GU XIA MEN',
  413342860 : 'GUANGZHOU',
  413343250 : 'HONG TAI 639',
  413346480 : 'REN JIAN DA LIAN',
  413374510 : 'XIUHONG',
  413376690 : 'HAILIANSHENG',
  413377680 : 'ZHONGWAIYUNXINGANG',
  413377920 : 'HONG YUAN 01',
  413378770 : 'ZHONG GU SHANG HAI',
  413380220 : 'HAI MEN',
  413380750 : 'ZHONG GU ZHU HAI',
  413380760 : 'ZHONG GU PENG LAI',
  413381170 : 'ZHONG GU DA LIAN',
  413381180 : 'ZHONG GU TIAN JIN',
  413381250 : 'ZHONG GU YING KOU',
  413381280 : 'ZHONG GU XIONG AN',
  413381290 : 'ZHONGGU BEIJING',
  413381440 : 'ZHONG GU RI ZHAO',
  413381750 : 'QIU JIN',
  413381830 : 'TENG YUN HE',
  413382030 : 'CHUN JIN',
  413383270 : 'LINGYUNHE',
  413384190 : 'HONG TAI637',
  413393620 : 'DONG FANG FU',
  413405430 : 'KAI HE ZHI CHENG',
  413439580 : 'MING HAO',
  413453290 : 'HE YANG',
  413453390 : 'HONG DA XIN 58',
  413453640 : 'XIN MING ZHOU 18',
  413453650 : 'XIN MING ZHOU 20',
  413454360 : 'HEBIN',
  413455660 : 'HE JIN',
  413455950 : 'XIN MING ZHOU 22',
  413456130 : 'XIN MING ZHOU 26',
  413458850 : 'XIN MING ZHOU76',
  413459620 : 'XIN MING ZHOU80',
  413495140 : 'ZHONGGUGUANGZHOU',
  413497490 : 'HUA HANG HAN YA 6',
  413497770 : 'HUA HANG HAN YA 5',
  413519440 : 'MAO GANG XIA MEN',
  413526530 : 'REN JIAN 15',
  413528620 : 'ZHONG LIAN SHAN TOU',
  413536120 : 'XIN XIN SHAN',
  413548380 : 'HONG DA XIN 778',
  413556780 : 'ZHUCHENGXINZHOU',
  413558710 : 'FENG XIN DA 29',
  413560380 : 'CA TOKYO',
  413564860 : 'YANGTZE TRADER',
  413690160 : 'XIAO JIANG',
  413692490 : 'HUA HANG 3',
  413695730 : 'RENJIAN5',
  413695760 : 'DONG FANG QIANG',
  413695770 : 'RENJIAN6',
  413698170 : 'REN JIAN GUANG ZHOU',
  413699570 : 'YONG XIN 101',
  413700420 : 'SHI SHANG 18',
  413701060 : 'JINSHUNHE',
  413701260 : 'ZE HONG',
  413701950 : 'CI TONG 1',
  413702160 : 'WAN XING DA',
  413702170 : 'WANFUDA',
  413703630 : 'HAISU6',
  413703960 : 'HAI SU 7',
  413704320 : 'REN JIAN TANG SHAN',
  413705230 : 'REN JIAN RI ZHAO',
  413760000 : 'TIAN BAO HE',
  413761000 : 'TIAN LONG HE',
  413762000 : 'TIAN XING HE',
  413763000 : 'TIAN YUN HE',
  413790872 : 'HUA FEI LUN 009',
  413798794 : 'GUIGANGXINGTAI15',
  413965000 : 'TIAN LI HE',
  413966000 : 'TIAN XIU HE',
  413996000 : 'REN JIAN 10',
  414195000 : 'RENJIAN8',
  414201000 : 'RI ZHAO SHENG SHI',
  414209000 : 'RI ZHAO HONG YUN',
  414239000 : 'ZHONG GU JI LIN',
  414240000 : 'XIN BIN ZHOU',
  414246000 : 'ZHONG GU SHAN DONG',
  414257000 : 'ZHONG AN XIN HUAYUAN',
  414293000 : 'REN JIAN 17',
  414303000 : 'TIAN XIANG HE',
  414304000 : 'TIAN SHUN HE',
  414310000 : 'TIAN CHANG HE',
  414328000 : 'FAN YA NING DE',
  414333000 : 'REN JIAN 19',
  414334000 : 'REN JIAN 20',
  414342000 : 'FAN YA GUANG ZHOU',
  414345000 : 'FAN YA TIAN JIN',
  414346000 : 'REN JIAN 23',
  414349000 : 'ZHONG GU FU JIAN',
  414350000 : 'FAN YA SHANG HAI',
  414362000 : 'REN JIAN 25',
  414365000 : 'REN JIAN 26',
  414366000 : 'REN JIAN 27',
  414400430 : 'XIN MING ZHOU 86',
  414626000 : 'HENG HUI 5',
  414653000 : 'HENG HUI 6',
  414654000 : 'ZHONG GU JI NAN',
  414661000 : 'ZHONG GU NAN NING',
  414664000 : 'HUA DA 610',
  414672000 : 'ZHONG GU FU ZHOU',
  414676000 : 'ZHONG GU HANG ZHOU',
  414682000 : 'LIANG XIANG 82',
  414685000 : 'ZHONG GU NAN JING',
  414688000 : 'ZHONG GU YIN CHUAN',
  414693000 : 'REN JIAN 16',
  414700000 : 'XIN QIN ZHOU',
  414713000 : 'XIN HANG ZHOU',
  414714000 : 'XIN ZHENG ZHOU',
  414721000 : 'XIN LAN ZHOU',
  414736000 : 'XIN WEN ZHOU',
  414752000 : 'XIN XU ZHOU',
  414758000 : 'XIN CANG ZHOU',
  414760000 : 'XIN HUI ZHOU',
  414793000 : 'ZHONG GU SHEN YANG',
  414798000 : 'CHANG SHUN JIN XIU',
  414806000 : 'ZHONG GU NAN CHANG',
  414807000 : 'ZHONG GU CHANG CHUN',
  414808000 : 'ZHONG GU LAN ZHOU',
  414816000 : 'CHANGSHUN QIANCHENG',
  414821000 : 'ZHONG GU XI AN',
  414830000 : 'ZHONG GU WU HAN',
  414841000 : 'HONG TAI 869',
  414842000 : 'ZHONG GU CHANG SHA',
  414849000 : 'ZHONG GU CHONG QING',
  414861000 : 'XIN YANG ZI 461',
  414877000 : 'ZHONG GU CHENG DU',
  414885000 : 'CHANG AN RUN',
  414888000 : 'ALS LUNA',
  414909000 : 'XIN YANG ZI465',
  414987512 : 'TEST',
  416003900 : 'EVER BLESS',
  416004889 : 'EVER CAST',
  416016000 : 'WAN HAI 103',
  416020000 : 'KUO CHANG',
  416024000 : 'FORMOSACONTAINER NO4',
  416031000 : 'EVER BALMY',
  416032000 : 'EVER BASIS',
  416033000 : 'EVER BEADY',
  416034000 : 'EVER BEAMY',
  416035000 : 'EVER BLOOM',
  416036000 : 'EVER BEFIT',
  416037000 : 'EVER BEING',
  416038000 : 'EVER BOARD',
  416039000 : 'EVER BLESS',
  416040000 : 'EVER BLINK',
  416047000 : 'WAN HAI 232',
  416061000 : 'EVER CREATE',
  416063000 : 'EVER CONVEY',
  416064000 : 'EVER CAREER',
  416070000 : 'EVER ARM',
  416071000 : 'EVER ART',
  416075000 : 'WAN HAI 101',
  416077000 : 'TEH VICTORY',
  416078000 : 'WAN HAI 275',
  416335000 : 'WAN HAI 233',
  416337000 : 'WAN HAI 235',
  416340000 : 'UNI ACTIVE',
  416341000 : 'UNI ADROIT',
  416426000 : 'YM HEIGHTS',
  416427000 : 'YM HARMONY',
  416428000 : 'YM HORIZON',
  416429000 : 'YM HAWK',
  416438000 : 'KANWAY GLOBAL',
  416453000 : 'UNI-PROMOTE',
  416456000 : 'UNI PROBITY',
  416459000 : 'UNI-PROSPER',
  416464000 : 'YM UNICORN',
  416465000 : 'YM UPSURGENCE',
  416466000 : 'YM UNANIMITY',
  416467000 : 'YM UBIQUITY',
  416468000 : 'YM UNIFORMITY',
  416475000 : 'EVER LIBRA',
  416481000 : 'EVER LIVEN',
  416482000 : 'EVER LOGIC',
  416486000 : 'YM INTELLIGENT',
  416487000 : 'YM INAUGURATION',
  416488000 : 'YM IDEALS',
  416490000 : 'YM EVOLUTION',
  416491000 : 'YM ESSENCE',
  416492000 : 'YM IMMENSE',
  416495000 : 'EVER LOYAL',
  416497000 : 'EVER LUNAR',
  416498000 : 'EVER LYRIC',
  416509000 : 'EVER LUCID',
  419000623 : 'SSL KOCHI',
  419000914 : 'SSL MUMBAI',
  419000970 : 'SSL GUJARAT',
  419001019 : 'SSL BHARAT',
  419001093 : 'SAN PRIDE',
  419001126 : 'SSL VISAKHAPATNAM',
  419001250 : 'SSL DELHI',
  419001324 : 'SSL BRAHMAPUTRA',
  419001352 : 'TCI EXPRESS',
  419001369 : 'SSL KRISHNA',
  419001380 : 'MOGRAL',
  419001386 : 'VARADA',
  419001483 : 'TCI ANAND',
  419001574 : 'SM KAVERI',
  419001615 : 'SM MAHI',
  419001618 : 'SM NEYYAR',
  419001631 : 'SM MANALI',
  419001809 : 'SSL GODAVARI',
  419001812 : 'SSL KAVERI',
  419001814 : 'SSL THAMIRABARANI',
  419738000 : 'SCI MUMBAI',
  419739000 : 'SCI CHENNAI',
  422021500 : 'YARAN',
  422026500 : 'SHAMIM',
  422026900 : 'GOLBON',
  422027500 : 'SHIBA',
  422031200 : 'SHABDIS',
  422031300 : 'BEHTA',
  422031500 : 'FLORA',
  422031600 : 'ABYAN',
  422031700 : 'ZARDIS',
  422032200 : 'BEHNAVAZ',
  422032300 : 'SHABGOUN',
  422032600 : 'TOUSKA',
  422032700 : 'BASHT',
  422038800 : 'ARTAM',
  422038900 : 'ARZIN',
  422039100 : 'ARTABAZ',
  422039200 : 'ARTENOS',
  422039300 : 'AZARGOUN',
  422058200 : 'SEPEHR PAYAM',
  422068100 : 'KASHAN',
  422153400 : 'RAYEN',
  422153500 : 'RADIN',
  422154100 : 'BARZIN',
  422154200 : 'HAMOUNA',
  422517000 : 'IRAN SHAHED',
  422812000 : 'ABTIN 1',
  422813000 : 'SHAYAN 1',
  422814000 : 'TABAN1',
  422905000 : 'DAISY',
  428002000 : 'ZIM VIRGINIA',
  431000527 : 'MAIKO',
  431003000 : 'ONE APUS',
  431004022 : 'NAHA2',
  431004911 : 'SHINSETO',
  431004956 : 'SAGAMI',
  431007132 : 'NATORI',
  431008189 : 'SAKURA',
  431008693 : 'MISAKI',
  431009828 : 'RYUNAN',
  431010468 : 'SHIGENOBU',
  431010652 : 'UMIKAJI',
  431011592 : 'NAGARA',
  431019062 : 'NOGAMI',
  431021728 : 'KISO',
  431022362 : 'KAIFU',
  431027000 : 'ONE THESEUS',
  431073000 : 'ONE CYGNUS',
  431080000 : 'ONE BLUE JAY',
  431120000 : 'ONE MANEUVER',
  431187000 : 'BROOKLYN BRIDGE',
  431241000 : 'ONE MACKINAC',
  431260000 : 'ONE MANHATTAN',
  431296000 : 'MOL COURAGE',
  431301812 : 'KOYO',
  431315000 : 'ONE COMPETENCE',
  431332000 : 'ONE COMMITMENT',
  431402037 : 'HYOGO',
  431402072 : 'TENMA',
  431501816 : 'KAMIWAKA',
  431501857 : 'IKUTA',
  431552000 : 'ONE EAGLE',
  431654000 : 'ONE GRUS',
  431655000 : 'ONE COLUMBA',
  431710000 : 'ONE ALTAIR',
  431772000 : 'ONE OWL',
  431787000 : 'ONE TRIBUTE',
  431792000 : 'ONE FALCON',
  431808000 : 'ONE TRADITION',
  431888000 : 'ONE WREN',
  431929000 : 'ONE MINATO',
  431981000 : 'ONE MADRID',
  431986000 : 'ONE STORK',
  431989000 : 'ONE MEISHAN',
  440006000 : 'HOCHIMINH VOYAGER',
  440009000 : 'HYUNDAI DRIVE',
  440017000 : 'SAWASDEE ATLANTIC',
  440022000 : 'SM QINGDAO',
  440059000 : 'SM TOKYO',
  440061000 : 'HMM CHITTAGONG',
  440062000 : 'HYUNDAI OAKLAND',
  440065000 : 'DOOWOO FAMILY',
  440075000 : 'SAWASDEE THAILAND',
  440086000 : 'SM KWANGYANG',
  440088000 : 'SKY RAINBOW',
  440111000 : 'SM TIANJIN',
  440112000 : 'HMM PROMISE',
  440117000 : 'HMM BLESSING',
  440119000 : 'SUNNY PALM',
  440120000 : 'HEUNG-A ULSAN',
  440141000 : 'SM BUSAN',
  440147000 : 'KMTC HOCHIMINH',
  440151000 : 'SM NINGBO',
  440162000 : 'PORT KLANG VOYAGER',
  440166000 : 'HONGKONG VOYAGER',
  440176000 : 'HYUNDAI HOPE',
  440185000 : 'HMM DHAKA',
  440189000 : 'SM YANTIAN',
  440190000 : 'KHARIS HERITAGE',
  440197000 : 'KM HAPPY STAR 1',
  440206000 : 'SM JAKARTA',
  440220000 : 'SM SHANGHAI',
  440221000 : 'PEGASUS PETA',
  440223000 : 'HMM HAMBURG',
  440251000 : 'SAWASDEE SUNRISE',
  440273000 : 'OSAKA VOYAGER',
  440278000 : 'SKY CHALLENGE',
  440279000 : 'GRACE BRIDGE',
  440280000 : 'SKY VICTORIA',
  440284000 : 'SENDAI TRADER',
  440287000 : 'BEIJING BRIDGE',
  440288000 : 'HMM HELSINKI',
  440301000 : 'YOKOHAMA TRADER',
  440303000 : 'KOBE TRADER',
  440309000 : 'QINGDAO TRADER',
  440313000 : 'HMM MONGLA',
  440316000 : 'SAWASDEE BALTIC',
  440321000 : 'SAWASDEE PACIFIC',
  440326000 : 'HMM ALGECIRAS',
  440328000 : 'HMM ROTTERDAM',
  440330000 : 'SHANGHAI VOYAGER',
  440335000 : 'HEUNG-A TOKYO',
  440337000 : 'NINGBO TRADER',
  440342000 : 'JAKARTA VOYAGER',
  440346000 : 'DONGJIN ENTERPRISE',
  440348000 : 'HMM DUBLIN',
  440349000 : 'POS YOKOHAMA',
  440350000 : 'HMM GDANSK',
  440361000 : 'HMM STOCKHOLM',
  440363000 : 'HMM SOUTHAMPTON',
  440366000 : 'STAR VOYAGER',
  440375000 : 'PANCON VICTORY',
  440401000 : 'STAR CHALLENGER',
  440473000 : 'POS TOKYO',
  440486000 : 'HMM ST PETERSBURG',
  440489000 : 'HMM OSLO',
  440491000 : 'KMTC JAKARTA',
  440493000 : 'KMTC SINGAPORE',
  440505000 : 'GLOBAL NUBIRA',
  440516000 : 'NAGOYA TRADER',
  440550000 : 'PANCON SUNSHINE',
  440559000 : 'HYUNDAI GOODWILL',
  440562000 : 'PEGASUS YOTTA',
  440567000 : 'SUNNY OAK',
  440572000 : 'POS SHANGHAI',
  440585000 : 'KMTC KEELUNG',
  440655000 : 'PANCON CHAMPION',
  440716000 : 'DONGJIN VOYAGER',
  440728000 : 'KMTC MANILA',
  440761000 : 'KMTC NHAVA SHEVA',
  440764000 : 'KMTC JEBEL ALI',
  440838000 : 'KMTC CHENNAI',
  440965000 : 'HEUNG-A YOUNG',
  441030000 : 'PEGASUS UNIX',
  441107000 : 'SUNNY SPRUCE',
  441111000 : 'HYUNDAI VANCOUVER',
  441127000 : 'TY INCHEON',
  441158000 : 'SUNNY MAPLE',
  441174000 : 'KMTC PUSAN',
  441225000 : 'KMTC MUMBAI',
  441236000 : 'DONGJIN FORTUNE',
  441248000 : 'KMTC DUBAI',
  441252000 : 'KMTC NINGBO',
  441261000 : 'DONGJIN FIDES',
  441266000 : 'KMTC MUNDRA',
  441271000 : 'PANCON HARMONY',
  441283000 : 'DONGJIN CONTINENTAL',
  441293000 : 'KMTC COLOMBO',
  441300000 : 'SUNNY LINDEN',
  441343000 : 'BANGKOK',
  441387000 : 'HECAN',
  441388000 : 'SHECAN',
  441398000 : 'HYUNDAI JAKARTA',
  441399000 : 'HYUNDAI COLOMBO',
  441401000 : 'KMTC DELHI',
  441403000 : 'SM PORTLAND',
  441420000 : 'PANCON BRIDGE',
  441421000 : 'KMTC SEOUL',
  441424000 : 'DONGJIN CONFIDENT',
  441432000 : 'STAR EXPRESS',
  441433000 : 'STAR PIONEER',
  441442000 : 'WECAN',
  441485000 : 'SUNNY DAHLIA',
  441486000 : 'HYUNDAI PRIDE',
  441502000 : 'KMTC SHENZHEN',
  441548000 : 'ANTWERP BRIDGE',
  441567000 : 'PEGASUS PACER',
  441568000 : 'PEGASUS PRIME',
  441701000 : 'SM LONG BEACH',
  441702000 : 'KMTC QINGDAO',
  441736000 : 'HYUNDAI INTEGRAL',
  441754000 : 'HYUNDAI VICTORY',
  441772000 : 'SINOKOR NIIGATA',
  441777000 : 'SINOKOR HONGKONG',
  441788000 : 'KMTC ULSAN',
  441805000 : 'DONGJIN VENUS',
  441872000 : 'PANCON GLORY',
  441875000 : 'PANCON SUCCESS',
  441885000 : 'SAWASDEE SINGAPORE',
  441902000 : 'KMTC SHANGHAI',
  441933000 : 'KMTC TIANJIN',
  441934000 : 'VOSTOCHNY VOYAGER',
  441952000 : 'SINOKOR QINGDAO',
  441953000 : 'HANSUNG INCHEON',
  441973000 : 'HAKATA VOYAGER',
  441981000 : 'HYUNDAI DREAM',
  445091000 : 'BOUN1',
  445879000 : 'THO SONG',
  450617000 : 'PRINCESS M',
  457130000 : 'PROSPER',
  457583000 : 'DONG HO',
  466055000 : 'AL BIDDA',
  466057000 : 'AL RUMEILA',
  466061000 : 'OSHAIRIJ',
  466073000 : 'MILAHA 1',
  466543000 : 'MAJD',
  470143000 : 'AL SADR-I',
  470305000 : 'SAFEEN PRINCIPAL',
  470552000 : 'AL BAZM II',
  470752000 : 'AL REEM-I',
  477000000 : 'ZIM MOUNT OLYMPUS',
  477001100 : 'MSC AGADIR',
  477001700 : 'COSCO PIRAEUS',
  477002200 : 'YM WIND',
  477002300 : 'KMTC TAIPEIS',
  477003200 : 'SINOTRANS BEIJING',
  477004600 : 'COSCO WELLINGTON',
  477004700 : 'COSCO VENICE',
  477006500 : 'YM WISH',
  477006600 : 'MILD TEMPO',
  477007100 : 'OOCL BUSAN',
  477010600 : 'SINOTRANS KAOHSIUNG',
  477014800 : 'SEASPAN HAMBURG',
  477016900 : 'SITC HAKATA',
  477020100 : 'SEASPAN FELIXSTOWE',
  477020200 : 'CSCL SYDNEY',
  477020300 : 'SEASPAN OCEANIA',
  477020400 : 'CSCL AFRICA',
  477021100 : 'KOTA JOHAN',
  477024800 : 'SITC LIANYUNGANG',
  477024900 : 'SITC SHENZHEN',
  477027100 : 'SITC HANSHIN',
  477030700 : 'COSCO FRANCE',
  477035800 : 'OOCL GERMANY',
  477036600 : 'OOCL TEXAS',
  477043200 : 'COSCO SHIPPING VOLGA',
  477045600 : 'TANG SHAN GANG JI 1',
  477047100 : 'GLORY OCEAN',
  477056400 : 'COSCO OCEANIA',
  477056600 : 'OOCL PANAMA',
  477057100 : 'COSCO SHIPPING SEINE',
  477057600 : 'TANG SHAN GANG JI2',
  477057900 : 'HUA HANG 6',
  477062000 : 'KOKOPO CHIEF',
  477065200 : 'COSCO FORTUNE',
  477066300 : 'CORAL CHIEF',
  477066600 : 'SUNNY CAMELLIA',
  477066700 : 'SEASPAN BEACON',
  477067800 : 'OOCL TAIPEI',
  477067900 : 'OOCL UTAH',
  477070800 : 'CSCL BRISBANE',
  477071700 : 'MAERSK NILE',
  477076300 : 'SEASPAN NEW DELHI',
  477077700 : 'COSCO HAIFA',
  477077800 : 'COSCO GENOA',
  477078600 : 'SITC SHANGHAI',
  477079500 : 'OSG BOSSTEC',
  477083700 : 'MAERSK CHILKA',
  477083800 : 'MAERSK CHAMBAL',
  477089200 : 'OSG BEAUTEC',
  477096300 : 'YM WREATH',
  477096400 : 'SINOTRANS KEELUNG',
  477097400 : 'SEASPAN DUBAI',
  477100300 : 'COSCO HIMALAYAS',
  477100400 : 'OOCL JAPAN',
  477103800 : 'OOCL UNITED KINGDOM',
  477105300 : 'SEASPAN JAKARTA',
  477105600 : 'OOCL ASIA',
  477106800 : 'SEASPAN SAIGON',
  477107200 : 'COSCO PACIFIC',
  477107900 : 'SINOTRANS OSAKA',
  477108100 : 'COSCO FAITH',
  477109800 : 'SINOTRANS HONG KONG',
  477111700 : 'MAERSK NANSHA',
  477111800 : 'CSCL MONTEVIDEO',
  477112300 : 'SITC QINZHOU',
  477112600 : 'SINOTRANS MANILA',
  477117100 : 'MILD CHORUS',
  477117400 : 'CSCL WINTER',
  477117900 : 'CSCL AUTUMN',
  477118100 : 'SWAN LAKE',
  477118600 : 'COSCO SHIPPING RHINE',
  477121100 : 'KOTA MAKMUR',
  477121200 : 'OOCL SCANDINAVIA',
  477127900 : 'SEASPAN BREEZE',
  477129300 : 'YM WIDTH',
  477133700 : 'INTERASIAINSPIRATION',
  477135600 : 'COSCO EXCELLENCE',
  477136800 : 'COSCO KAOHSIUNG',
  477137700 : 'SITC KANTO',
  477139100 : 'COSCO TAURUS',
  477139800 : 'SINOTRANS BANGKOK',
  477139900 : 'COSCO KILIMANJARO',
  477141200 : 'CSCL LIMA',
  477143100 : 'MAERSK ROUBAIX',
  477143400 : 'MAERSK REGENSBURG',
  477144700 : 'MAERSK NADI',
  477144800 : 'MAERSK NEWARK',
  477147600 : 'OOCL INDONESIA',
  477148700 : 'COSCO SHIPPING ALPS',
  477148800 : 'COSCO SHIPPING ARIES',
  477150700 : 'XIN XIN TIAN 2',
  477151200 : 'JOSCO LUCKY',
  477154200 : 'SEASPAN FALCON',
  477154300 : 'SALVADOR EXPRESS',
  477154400 : 'ACACIA HAWK',
  477154500 : 'JOSCO REAL',
  477154600 : 'JOSCO SHINE',
  477154700 : 'COSCO GEMINI',
  477157400 : 'COSCO UNIVERSE',
  477157700 : 'CMA CGM MUNDRA',
  477157800 : 'COSCO SHIPPING LEO',
  477158700 : 'XIN LOS ANGELES',
  477159100 : 'CMA CGM MUMBAI',
  477159600 : 'SEASPAN HARRIER',
  477159700 : 'SEASPAN RAPTOR',
  477159900 : 'JRS CARINA',
  477166300 : 'CMA CGM CHENNAI',
  477166500 : 'COSCO SHIPPING VIRGO',
  477166600 : 'COSCOSHIPPING DENALI',
  477166700 : 'CMA CGM COCHIN',
  477167800 : 'SITC HAIPHONG',
  477168400 : 'COSCO SHIPPING PEONY',
  477168900 : 'COSCO CAPRICORN',
  477172700 : 'COSCO SHIPPING LIBRA',
  477174600 : 'MAERSK CAIRO',
  477174700 : 'MAERSK CUBANGO',
  477174900 : 'MAERSK CHACHAI',
  477178200 : 'MAERSK TAIKUNG',
  477178400 : 'MAERSK TANJONG',
  477178900 : 'MAERSK BINTAN',
  477181200 : 'SEASPAN BELLWETHER',
  477181900 : 'GLORY SHANGHAI',
  477182200 : 'OOCL POLAND',
  477182300 : 'OOCL BRUSSELS',
  477182400 : 'OOCL MIAMI',
  477182500 : 'SITC FANGCHENG',
  477183800 : 'COSCO SCORPIO',
  477183900 : 'COSCO JASMINE',
  477189300 : 'COSCO TAICANG',
  477189700 : 'OOCL NORFOLK',
  477190600 : 'SITC QINGDAO',
  477193400 : 'SUNNY CALLA',
  477194100 : 'COSCO SAGITTARIUS',
  477194400 : 'COSCO NEBULA',
  477194600 : 'COSCO SHIPPING ANDES',
  477194800 : 'COSCO SHIPPING ROSE',
  477195100 : 'MAERSK VIRGINIA',
  477201600 : 'JJ STAR',
  477203100 : 'OOCL BERLIN',
  477203300 : 'SITC HOCHIMINH',
  477203700 : 'SITC YANTAI',
  477207300 : 'MAERSK NEW DELHI',
  477207400 : 'SEASPAN LONCOMILLA',
  477207600 : 'SEASPAN LUMACO',
  477207700 : 'SEASPAN EMERALD',
  477209100 : 'SITC TOYOHASHI',
  477211100 : 'SAFMARINE BAYETE',
  477213400 : 'CSCL JUPITER',
  477213800 : 'SEASPAN CHIBA',
  477214400 : 'GERHARD SCHULTE',
  477214700 : 'OOCL EUROPE',
  477217400 : 'WAN HAI 171',
  477218900 : 'SITC NINGBO',
  477219400 : 'SINOTRANS TIANJIN',
  477219900 : 'COSCO NETHERLANDS',
  477220100 : 'OOCL MALAYSIA',
  477220200 : 'OOCL BANGKOK',
  477222600 : 'OOCL MEMPHIS',
  477222700 : 'OOCL FRANCE',
  477224100 : 'NEW MINGZHOU 16',
  477225300 : 'WAN HAI 172',
  477229400 : 'CSCL MERCURY',
  477232200 : 'COSCO SAKURA',
  477232800 : 'COSCO PISCES',
  477233100 : 'TRADER',
  477233200 : 'KOTA PURI',
  477233300 : 'COSCO AZALEA',
  477233700 : 'SITC TOKUYAMA',
  477233800 : 'SITC SENDAI',
  477234800 : 'DELPHIS RIGA',
  477241800 : 'SEASPAN LAHORE',
  477242600 : 'SINOTRANS SHANGHAI',
  477250600 : 'SITC BUSAN',
  477250700 : 'NEW MINGZHOU 12',
  477257100 : 'CSCL SPRING',
  477266800 : 'CSCL VENUS',
  477266900 : 'COSCO VIETNAM',
  477269300 : 'COSCO GALAXY',
  477269400 : 'COSCO SHIPPING LOTUS',
  477269800 : 'SITC SUBIC',
  477271300 : 'CSCL YELLOW SEA',
  477271400 : 'COSCO PORTUGAL',
  477271900 : 'SEASPAN BRAVO',
  477274400 : 'CSCL SATURN',
  477274700 : 'OOCL NEW YORK',
  477274900 : 'SITC KWANGYANG',
  477276600 : 'OOCL CANADA',
  477281000 : 'DONG FANG SHUN',
  477282300 : 'OOCL ZHOUSHAN',
  477282900 : 'XIN SHANGHAI',
  477286200 : 'COSCOSHIPPING ORCHID',
  477286300 : 'COSCO CAMELLIA',
  477286500 : 'KOTA PUSAKA',
  477293100 : 'OOCL KOREA',
  477293200 : 'OOCL SINGAPORE',
  477293900 : 'CSCL SUMMER',
  477301900 : 'SITC HEBEI',
  477302200 : 'SEASPAN HANNOVER',
  477302300 : 'SITC TAICANG',
  477302500 : 'KOTA LUKIS',
  477302600 : 'KOTA LUMBA',
  477302800 : 'KOTA LUMAYAN',
  477302900 : 'COSCO SHIPPING SOLAR',
  477305900 : 'MSC ANTIGUA',
  477307300 : 'MSC RENEE',
  477307400 : 'MSC MARGRIT',
  477307900 : 'SEASPAN BENEFACTOR',
  477308100 : 'COSCO AQUARIUS',
  477308400 : 'SEASPAN LOGA',
  477311500 : 'COSCO HOUSTON',
  477311600 : 'COSCO COLOMBO',
  477312800 : 'SITC SHIDAO',
  477314100 : 'OOCL HOCHIMINH CITY',
  477314300 : 'XINDE KEELUNG',
  477314700 : 'YM WINNER',
  477318200 : 'COSCO SHIPPING STAR',
  477319300 : 'COSCO SANTOS',
  477321000 : 'OOCL MONTREAL',
  477326200 : 'SUNNY FREESIA',
  477333400 : 'GLORY GUANDONG',
  477333500 : 'OOCL HONG KONG',
  477333900 : 'COSCOSHIPPING THAMES',
  477335600 : 'SITC SHIMIZU',
  477345900 : 'COSCO PLANET',
  477346500 : 'HENRIKA SCHULTE',
  477347200 : 'GLORY TIANJIN',
  477347400 : 'YM WORTH',
  477347900 : 'SITC KEELUNG',
  477348300 : 'SEASPAN BEYOND',
  477348700 : 'NEW MING ZHOU 60',
  477351100 : 'OOCL BEIJING',
  477351400 : 'COSCO PRINCE RUPERT',
  477360800 : 'CSCL ASIA',
  477387600 : 'IAL001',
  477390300 : 'SEASPAN ZAMBEZI',
  477390400 : 'SEASPAN AMAZON',
  477390900 : 'MILD SONATA',
  477397800 : 'COSCO HARMONY',
  477400800 : 'JI YUAN',
  477400900 : 'JI RUN',
  477401100 : 'HAO AN',
  477401900 : 'SITC BATANGAS',
  477406000 : 'UNI ACCORD',
  477407600 : 'FENG ZE YUAN',
  477407900 : 'SEASPAN THAMES',
  477423100 : 'MAERSK CASABLANCA',
  477423800 : 'CSCL URANUS',
  477424100 : 'DONG FANG XING',
  477424600 : 'CSCL MARS',
  477427500 : 'COSCO BELGIUM',
  477430100 : 'YM WELCOME',
  477430500 : 'GLORY ZHENDONG',
  477430600 : 'MAERSK GENOA',
  477434200 : 'SEASPAN TOKYO',
  477439900 : 'NEW MINGZHOU 66',
  477441400 : 'SEASPAN YANGTZE',
  477441800 : 'CSCL BOHAI SEA',
  477444600 : 'SITC DALIAN',
  477454100 : 'YM WELLHEAD',
  477454400 : 'HIGHLAND CHIEF',
  477454700 : 'YM WONDROUS',
  477454900 : 'OOCL GENOA',
  477455200 : 'CHANGSHA',
  477455700 : 'MORESBY CHIEF',
  477462400 : 'HERMANN SCHULTE',
  477463400 : 'COSCO IZMIR',
  477463500 : 'COSCO ASHDOD',
  477464400 : 'COSCO ISTANBUL',
  477464500 : 'COSCO JEDDAH',
  477466300 : 'SITC SHANDONG',
  477468200 : 'SITC CAGAYAN',
  477476800 : 'CSCL INDIAN OCEAN',
  477477400 : 'NEW GUINEA CHIEF',
  477477700 : 'PAPUAN CHIEF',
  477478700 : 'SEASPAN BEAUTY',
  477478800 : 'YM WORLD',
  477486100 : 'LIVORNO EXPRESS',
  477493600 : 'SITC INCHON',
  477504900 : 'OOCL AUSTRALIA',
  477518600 : 'SEASPAN BRIGHTNESS',
  477521300 : 'SITC MAKASSAR',
  477521800 : 'SITC KAOHSIUNG',
  477524100 : 'YM WITNESS',
  477524300 : 'MILD TUNE',
  477524700 : 'YM WHOLESOME',
  477528400 : 'SEASPAN ADONIS',
  477537100 : 'SITC WEIHAI',
  477537200 : 'DELPHIS BOTHNIA',
  477537400 : 'XIN XIN TIAN 1',
  477538900 : 'LAUTOKA CHIEF',
  477539600 : 'SEASPAN OSPREY',
  477541100 : 'OOCL BRISBANE',
  477541900 : 'ACACIA LIBRA',
  477547100 : 'ALTAMIRA EXPRESS',
  477547400 : 'JJ SUN',
  477548100 : 'SITC LIAONING',
  477548300 : 'SEASPAN BELIEF',
  477548900 : 'SITC HAINAN',
  477552100 : 'MAERSK NUBA',
  477552200 : 'SAFMARINE NOKWANDA',
  477552300 : 'MAERSK NYASSA',
  477552400 : 'SAFMARINE BENGUELA',
  477552700 : 'MAERSK NARMADA',
  477552800 : 'SAFMARINE NOMAZWE',
  477552900 : 'MAERSK NAKURU',
  477581100 : 'GOTTFRIED SCHULTE',
  477581400 : 'SEASPAN SANTOS',
  477585300 : 'MILD JAZZ',
  477585400 : 'SITC ZHEJIANG',
  477588800 : 'SITC GUANGXI',
  477593600 : 'XIN HONG KONG',
  477594900 : 'SITC NANSHA',
  477598400 : 'CSCL NEPTUNE',
  477598800 : 'COSCO HOPE',
  477607700 : 'OOCL TOKYO',
  477612200 : 'OOCL NEW ZEALAND',
  477615200 : 'HF WEALTH',
  477615600 : 'CARPENTERS VEGA',
  477616000 : 'OOCL BELGIUM',
  477617600 : 'OOCL WASHINGTON',
  477617700 : 'OOCL SEOUL',
  477624100 : 'MADRID EXPRESS',
  477624200 : 'PROS HOPE',
  477625300 : 'NADI CHIEF',
  477625400 : 'PARIS EXPRESS',
  477625600 : 'SITC PORT KLANG',
  477627300 : 'SEASPAN EMISSARY',
  477627800 : 'OOCL DALIAN',
  477627900 : 'OOCL NAGOYA',
  477629400 : 'MAERSK NINGBO',
  477629500 : 'SITC SHEKOU',
  477629900 : 'RABAUL CHIEF',
  477630400 : 'A KEIGA',
  477632900 : 'SITC PENANG',
  477634900 : 'PUERTO LIMON EXPRESS',
  477636200 : 'MAERSK GIBRALTAR',
  477637600 : 'CSCL SOUTH CHINA SEA',
  477639300 : 'BERNHARD SCHULTE',
  477642100 : 'EVER ORIENT',
  477642200 : 'SUVA CHIEF',
  477642400 : 'NEXOE MAERSK',
  477642500 : 'NICOLINE MAERSK',
  477642600 : 'NORA MAERSK',
  477642700 : 'NYSTED MAERSK',
  477642900 : 'NICOLAI MAERSK',
  477648100 : 'NELE MAERSK',
  477648300 : 'SITC SINGAPORE',
  477648700 : 'M/V LUNA MAERSK',
  477648800 : 'LEDA MAERSK',
  477652300 : 'COSCO ENGLAND',
  477654500 : 'MOUNT CAMERON',
  477655200 : 'COSCO JAPAN',
  477655700 : 'OOCL LE HAVRE',
  477655800 : 'OOCL CHARLESTON',
  477655900 : 'SEASPAN EMPIRE',
  477656100 : 'FAR EAST GRACE',
  477664500 : 'EVER OCEAN',
  477664600 : 'LOUIS MAERSK',
  477665200 : 'LEXA MAERSK',
  477680400 : 'JJ TOKYO',
  477681800 : 'SUNNY IVY',
  477681900 : 'LAUST MAERSK',
  477685300 : 'COSCO KOREA',
  477690600 : 'SEASPAN RIO DE JANEI',
  477690700 : 'CSCL ZEEBRUGGE',
  477690800 : 'COSCO FUZHOU',
  477692700 : 'NEW MINGZHOU 68',
  477694200 : 'GENOA EXPRESS',
  477694300 : 'BARCELONA EXPRESS',
  477696800 : 'RIO GRANDE EXPRESS',
  477698400 : 'MILD WALTZ',
  477698600 : 'COSCO DENMARK',
  477698700 : 'SEASPAN BRILLIANCE',
  477699600 : 'SEASPAN HUDSON',
  477702000 : 'OOCL HAMBURG',
  477704000 : 'OOCL CALIFORNIA',
  477706200 : 'HONIARA CHIEF',
  477711300 : 'SANTA MARTA EXPRESS',
  477712400 : 'CSCL GLOBE',
  477712800 : 'CSCL PACIFIC OCEAN',
  477714100 : 'ZIM CHARLESTON',
  477714300 : 'LICA MAERSK',
  477714600 : 'ZIM SHENZHEN',
  477716100 : 'EVER OASIS',
  477717400 : 'EVER ORDER',
  477719000 : 'OOCL AMERICA',
  477726500 : 'SEASPAN GUAYAQUIL',
  477728400 : 'MAERSK KARACHI',
  477732700 : 'SITC LICHENG',
  477737600 : 'YM WELLNESS',
  477737700 : 'MAERSK GUATEMALA',
  477737800 : 'MAERSK GUAYAQUIL',
  477738200 : 'COSCO PHILIPPINES',
  477738500 : 'OOCL LONDON',
  477738600 : 'OOCL LUXEMBOURG',
  477738700 : 'OOCL GUANGZHOU',
  477738800 : 'OOCL JAKARTA',
  477738900 : 'OOCL SAVANNAH',
  477744200 : 'SEASPAN LINGUE',
  477745500 : 'SITC DECHENG',
  477745800 : 'SITC YUNCHENG',
  477748500 : 'SEASPAN CALICANTO',
  477752300 : 'COSCO SURABAYA',
  477752400 : 'COSCO AQABA',
  477754600 : 'MAERSK VENEZIA',
  477754800 : 'MAERSK INCHEON',
  477759700 : 'HEDWIG SCHULTE',
  477764600 : 'COSCO PRIDE',
  477765800 : 'COSCO AUCKLAND',
  477765900 : 'COSCO ADEN',
  477767500 : 'SINOTRANS QINGDAO',
  477767600 : 'SINOTRANS DALIAN',
  477768100 : 'XIN BEIJING',
  477769600 : 'YM WINDOW',
  477770200 : 'MAERSK STOCKHOLM',
  477770300 : 'MAERSK SALINA',
  477770700 : 'COSCO MALAYSIA',
  477776100 : 'SEASPAN GANGES',
  477776200 : 'COSCO SPAIN',
  477776300 : 'DETROIT EXPRESS',
  477790500 : 'CMA CGM TUTICORIN',
  477792900 : 'MAERSK CABO VERDE',
  477793200 : 'SITC JIADE',
  477793400 : 'OOCL OAKLAND',
  477793900 : 'OOCL KUALA LUMPUR',
  477795300 : 'COSCO GLORY',
  477798200 : 'SITC MINGCHENG',
  477798300 : 'SITC XINCHENG',
  477798400 : 'SITC RENDE',
  477798900 : 'KANWAY FORTUNE',
  477800200 : 'JJ NAGOYA',
  477802700 : 'HANS SCHULTE',
  477806000 : 'OOCL SAN FRANCISCO',
  477809100 : 'XIN AN',
  477809500 : 'ONE MAGDALENA',
  477809800 : 'CUL NANSHA',
  477810500 : 'GLORY GUANGZHOU',
  477811100 : 'OOCL ITALY',
  477811500 : 'JOSCO ALMA',
  477811700 : 'SITC LIDE',
  477813700 : 'SITC MOJI',
  477815200 : 'SEASPAN FRASER',
  477817400 : 'OOCL SHANGHAI',
  477825000 : 'OOCL CHICAGO',
  477829700 : 'OOCL SOUTHAMPTON',
  477831200 : 'COSCO INDONESIA',
  477832200 : 'COSCO SAO PAULO',
  477832300 : 'COSCO VALENCIA',
  477832400 : 'OOCL CHONGQING',
  477832500 : 'OOCL EGYPT',
  477833500 : 'JOSCO JANE',
  477833700 : 'CUL YANGPU',
  477833800 : 'SITC SHANGDE',
  477833900 : 'SITC RUNDE',
  477836400 : 'HONG AN',
  477836500 : 'SITC MINGDE',
  477836900 : 'ONE ORINOCO',
  477842200 : 'ONE AMAZON',
  477842400 : 'ZIM USA',
  477842500 : 'ZIM CANADA',
  477842600 : 'JOSCO HELEN',
  477842700 : 'SITC CHUNMING',
  477842900 : 'SITC XINGDE',
  477845600 : 'COSCO ITALY',
  477846200 : 'SITC WENDE',
  477846300 : 'CUL BANGKOK',
  477846400 : 'CUL LAEMCHABANG',
  477846600 : 'ONE FREEDOM',
  477846900 : 'SITC QIUMING',
  477848700 : 'CSCL EAST CHINA SEA',
  477849400 : 'MAERSK MAKUTU',
  477849500 : 'SITC MACAO',
  477850500 : 'DELPHIS GDANSK',
  477855200 : 'SITC PYEONGTAEK',
  477855900 : 'SITC GUANGDONG',
  477856100 : 'ASL HONG KONG',
  477856400 : 'SITC ZHAOMING',
  477856600 : 'SITC SHENGDE',
  477856700 : 'ASL BAUHINIA',
  477856800 : 'ONE FRIENDSHIP',
  477858700 : 'DELPHIS FINLAND',
  477858800 : 'COSCOSHIPPING DANUBE',
  477859400 : 'SITC CHENMING',
  477859600 : 'SITC XIANDE',
  477859800 : 'SHENG AN',
  477866200 : 'SEASPAN KYOTO',
  477866300 : 'SEASPAN KOBE',
  477867100 : 'SITC PINGHE',
  477867200 : 'SITC YUANMING',
  477867500 : 'JIANGNANZAOCHUAN2761',
  477867600 : 'MAERSK CLEVELAND',
  477867800 : 'ONE FRUITION',
  477867900 : 'ONE FRONTIER',
  477871200 : 'ONE FANTASTIC',
  477871300 : 'MAERSK CHARLESTON',
  477871400 : 'ZIM MOUNT EVEREST',
  477871500 : 'ZIM SAMMY OFER',
  477871600 : 'OOCL SPAIN',
  477871700 : 'SITC ANHE',
  477871800 : 'SITC QIMING',
  477871900 : 'ASL PEONY',
  477883100 : 'COSCO YINGKOU',
  477883300 : 'OOCL KOBE',
  477883500 : 'BELAWAN',
  477883900 : 'CSCL LONG BEACH',
  477884200 : 'KUO LONG',
  477884600 : 'GLORY SHENGDONG',
  477885100 : 'SITC SHUNDE',
  477885900 : 'SITC CHANGMING',
  477886300 : 'OOCL PIRAEUS',
  477887200 : 'SITC SHENGMING',
  477887600 : 'SITC FENGHE',
  477887700 : 'NEWNEW STAR',
  477887800 : 'OOCL TURKIYE',
  477887900 : 'SITC HENGDE',
  477890100 : 'ZIM MOUNT BLANC',
  477890300 : 'ASL TAIPEI',
  477890500 : 'MAERSK SALALAH',
  477890700 : 'OOCL FELIXSTOWE',
  477890900 : 'SITC HUIMING',
  477891200 : 'GERNER MAERSK',
  477891500 : 'ZIM MOUNT DENALI',
  477891600 : 'SITC YUHE',
  477891700 : 'MAERSK STEPNICA',
  477891800 : 'SITC RUIMING',
  477891900 : 'SHENG LI JI',
  477893100 : 'CAUCEDO EXPRESS',
  477893200 : 'MAERSK SAVANNAH',
  477893300 : 'SNL NANJING',
  477893400 : 'ONE COSMOS',
  477893700 : 'GUNDE MAERSK',
  477893800 : 'NEWNEW POLAR BEAR',
  477893900 : 'SNL NANTONG',
  477895200 : 'ONE CONTINUITY',
  477895400 : 'OOCL GDYNIA',
  477895500 : 'OOCL ZEEBRUGGE',
  477895900 : 'SNL HAIKOU',
  477898700 : 'OOCL YOKOHAMA',
  477906100 : 'SITC RENHE',
  477906300 : 'SNL ZHANGJIAGANG',
  477907100 : 'ZIM MOUNT RAINIER',
  477907200 : 'ONE REASSURANCE',
  477907300 : 'ONE READINESS',
  477907600 : 'ZIM AMBER',
  477909200 : 'ZIM CORAL',
  477909300 : 'JY BONITO',
  477909400 : 'NEWNEW STAR 2',
  477909500 : 'MILD ORCHID',
  477909700 : 'GUNHILDE MAERSK',
  477909800 : 'NEWNEW PANDA 1',
  477915200 : 'NEW MINGZHOU 28',
  477920300 : 'OOCL ATLANTA',
  477921600 : 'ZIMMOUNT KILIMANJARO',
  477921700 : 'COSCOSHIPPING BRAZIL',
  477921800 : 'ZIM EMERALD',
  477922100 : 'GUTHORM MAERSK',
  477922300 : 'ONE RECOGNITION',
  477922400 : 'SITC YIHE',
  477926700 : 'NAGALEADER',
  477928600 : 'NE396',
  477928900 : 'BOHAI STAR',
  477929100 : 'ZIM MOUNT FUJI',
  477929700 : 'XIN YANG ZI488',
  477931900 : 'MILD PEONY',
  477942700 : 'SITC MANILA',
  477943000 : 'OSG ADMIRAL',
  477946000 : 'OOCL ROTTERDAM',
  477947100 : 'HUBERT SCHULTE',
  477947600 : 'COSCO DURBAN',
  477947700 : 'COSCO FOS',
  477948200 : 'SITC JIANGSU',
  477948800 : 'YM WARMTH',
  477950300 : 'COSCO DEVELOPMENT',
  477958300 : 'OOCL HOUSTON',
  477958500 : 'MAERSK ANTWERP',
  477958900 : 'MAERSK AVON',
  477959200 : 'COSCO THAILAND',
  477961600 : 'SEASPAN OSAKA',
  477963800 : 'CSCL STAR',
  477967300 : 'SITC FUJIAN',
  477967600 : 'LAKONIA',
  477967700 : 'CSCL ARCTIC OCEAN',
  477967800 : 'CSCL ATLANTIC OCEAN',
  477995162 : 'CHAI WAN',
  477995437 : 'CLEAN HARBOUR 1',
  477995441 : 'CLEAN HARBOUR 2',
  477997028 : 'NGON SHUEN',
  477997030 : 'NIM WAN',
  477997036 : 'LAI WAN',
  477997039 : 'MO SING LENG',
  477997040 : 'TSING CHAU',
  511100005 : 'S PYLOS',
  511100373 : 'HACI MUSA UCAK',
  511100380 : 'ADMIRAL DANIAL',
  511100639 : 'ELLA Y',
  511100739 : 'BAWEAN',
  511100746 : 'HAJ YEHIA',
  511100833 : 'CETUS',
  511100889 : 'NK PEARL',
  511101140 : 'INTERSEA TRAVELER',
  511101214 : 'MANASSA M',
  511101259 : 'XIN FENG',
  511101278 : 'GUANGZHOU',
  511873000 : 'GEMMA',
  512011151 : 'TAKUTAI CHIEF',
  512143000 : 'MOANA CHIEF',
  525003018 : 'ALEXINDO I',
  525003263 : 'ICON CORINTUS',
  525003620 : 'MV.ORIENTAL DIAMOND',
  525003624 : 'ORIENTAL GALAXY',
  525003667 : 'HIJAU SAMUDRA',
  525005174 : 'HIJAU SEGAR',
  525005318 : 'HIJAU JELITA',
  525005319 : 'MV.HIJAU SEJUK',
  525005339 : 'VERIZON',
  525005397 : 'MV. ORIENTAL GOLD',
  525006403 : 'WGM256T',
  525007115 : 'MV.LINTAS BENGKULU',
  525007163 : 'KM. KANAL MAS',
  525007400 : 'MV TELUK MAS',
  525007401 : 'MV KISIK MAS',
  525009349 : 'MV SINAR BANDA',
  525013013 : 'TANTO SETIA',
  525013014 : 'TANTO BERSAMA',
  525013016 : 'TANTO BERSATU',
  525013018 : 'TANTO RAYA',
  525013021 : 'TANTO TERANG',
  525013022 : 'TANTO TANGGUH',
  525013024 : 'MV TANTO SEMANGAT',
  525013026 : 'TANTO PRATAMA',
  525013027 : 'TANTO ALAM',
  525013028 : 'MV.TANTO AMAN',
  525013029 : 'MV TANTO KARUNIA II',
  525013031 : 'MV TANTO SUBUR 1',
  525013033 : 'MV TANTO SUBUR II',
  525013036 : 'MV.TANTO TENANG',
  525013040 : 'TANTO LANCAR',
  525013042 : 'TANTO LUAS',
  525013043 : 'TANTO LANGGENG',
  525015026 : 'MV MULIANIM',
  525015248 : 'MV ARMADA SEJATI',
  525015323 : 'CTP DELTA',
  525015347 : 'KM PULAU LAYANG',
  525015393 : 'KM.TELUK FLAMINGGO',
  525015399 : 'KM.TELUK BINTUNI',
  525015479 : 'MV SELAT MAS',
  525015536 : 'MV.ARMADA PERMATA',
  525015583 : 'MV.ORIENTAL JADE',
  525015658 : 'MV.CTP FORTUNE',
  525015706 : 'CTP GOLDEN',
  525015924 : 'MV.ORIENTAL RUBY',
  525015943 : 'MV.LUZON',
  525015962 : 'MV.ORIENTAL EMERALD',
  525015965 : 'MV.ARMADA SEGARA',
  525015973 : 'ORIENTAL SILVER',
  525016130 : 'KM TANTO SURYA',
  525016244 : 'KM.TANTO ABADI',
  525016466 : 'LUMOSO SELAMAT',
  525016539 : 'LUMOSO BAHAGIA',
  525016549 : 'TANTO EXPRESS',
  525016550 : 'KM TANTO PERMAI',
  525016584 : 'MV. TANTO JAYA',
  525016609 : 'LUMOSO GEMBIRA',
  525018171 : 'TMS GLORY',
  525019019 : 'MV HILIR MAS',
  525019050 : 'MV TELAGA MAS',
  525019100 : 'MV WARIH MAS',
  525019101 : 'MV.UMBUL MAS',
  525019274 : 'MV BELIK MAS',
  525019381 : 'MV TASIK MAS',
  525019398 : 'MV.STRAIT MAS',
  525019417 : 'JALES_MAS',
  525019465 : 'MV.ESTUARI MAS',
  525019510 : 'MARE MAS',
  525019527 : 'MV.KALI MAS',
  525022407 : 'MV.GULF MAS',
  525022408 : 'MV.PALUNG MAS',
  525023419 : 'CURUG MAS',
  525023420 : 'MV BAHAR MAS',
  525024397 : 'MV SUNGAI MAS',
  525025049 : 'MERATUS MANADO',
  525025054 : 'MERATUS MEDAN 1',
  525025067 : 'MERATUS MEDAN 2',
  525025076 : 'MERATUS MAMIRI',
  525025077 : 'MERATUS DILI',
  525025082 : 'MERATUS GORONTALO',
  525025085 : 'MERATUS KUPANG',
  525025088 : 'MERATUS KELIMUTU',
  525025089 : 'MERATUS MALINO',
  525025090 : 'MERATUS KALABAHI',
  525025101 : 'MERATUS KAHAYAN',
  525025102 : 'MERATUS KATINGAN',
  525100294 : 'SPIL HANA',
  525100392 : 'SPIL HAPSRI',
  525100468 : 'MCC ANDALAS',
  525100478 : 'SPIL HASYA',
  525100552 : 'SELATAN DAMAI',
  525100704 : 'SPIL HAYU',
  525100889 : 'MV ICON DANIEL',
  525101050 : 'KENDHAGA NUSANTARA 1',
  525101066 : 'KENDHAGA NUSANTARA 9',
  525101067 : 'KENDHAGA NUSANTARA 7',
  525101097 : 'KENDAGA NUSANTARA15',
  525101118 : 'KENDHAGA NUSANTARA 3',
  525101128 : 'KENDHAGA NUSANTARA10',
  525101129 : 'KENDHAGA NUSANTARA 8',
  525101130 : 'KENDHAGA NUSANTARA 5',
  525101131 : 'KENDHAGA NUSANTARA12',
  525101470 : 'MV SPIL RENATA',
  525113002 : 'TANTO MITRA',
  525113004 : 'MV TANTO MANDIRI',
  525113005 : 'TANTO BERSINAR',
  525113006 : 'MV TANTO SELALU',
  525113008 : 'TANTO SIAP',
  525113009 : 'MV.TANTO SUKSES',
  525113012 : 'MV TANTO TERIMA',
  525113013 : 'TANTO KASIH',
  525113016 : 'TANTO SALAM',
  525113017 : 'TANTO SEJAHTERA',
  525113019 : 'TANTO KAWAN',
  525113026 : 'TANTO KARYA',
  525113028 : 'TANTO KHARISMA',
  525119004 : 'MUARA MAS',
  525119005 : 'AYER MAS',
  525119011 : 'KEDUNG MAS',
  525119012 : 'KAWA MAS',
  525119161 : 'STRAIT MAS',
  525119170 : 'CMA CGM HOMERE',
  525119189 : 'IZUMI MAS',
  525119190 : 'IFAMA MAS',
  525120018 : 'MV.CTP MAKASSAR',
  525125001 : 'MERATUS KARIANGAU',
  525125002 : 'MERATUS KARIMATA',
  525125008 : 'MERATUS LABUAN BAJO',
  525125010 : 'MERATUS LARANTUKA',
  525125011 : 'MERATUS LEMBAR',
  525125012 : 'MERATUS LEMBATA',
  525125014 : 'MERATUS WAINGAPU',
  525125018 : 'MV MERATUS WAKATOBI',
  525125029 : 'MERATUS SAMARINDA',
  525125030 : 'MERATUS SAMPIT',
  525200098 : 'KM. HASIL BAHARI 8',
  525290672 : 'SURYA SAMUDRA V',
  525300044 : 'MULYA SENTOSA II',
  525301156 : 'MV SPIL RAHAYU :)',
  525301388 : 'SPIL RETNO',
  525400142 : 'SPIL RATNA',
  525400885 : 'MV.MITRA KENDARI',
  525600354 : 'FATIMA II',
  533000522 : 'DANUM 159',
  533052300 : 'HARBOUR EXPRESS',
  533130646 : 'MTT TANJUNG MANIS',
  533130813 : 'MTT KUCHING DUA',
  533131021 : 'MTT SAISUNEE',
  533131052 : 'MTT PENGERANG',
  533131059 : 'MTT SENARI',
  533132056 : 'MTT SIBU',
  533132065 : 'MTT SAMALAJU',
  533132066 : 'MTT SARIKEI',
  533132075 : 'MTT SEMPORNA',
  533132085 : 'SALAM MAJU',
  533132087 : 'MTT SAPANGAR',
  533132142 : 'MTT SINGAPORE',
  533132161 : 'MTT SANDAKAN',
  533132436 : 'MTT RAJANG',
  533132646 : 'MTT HAIPHONG',
  533167000 : 'RMN AUX 5',
  533170079 : 'DANUM 160',
  533170319 : 'HARBOUR ZENITH',
  533170323 : 'HARBOUR_RUBY',
  533170482 : 'DANUM 168',
  533170531 : 'DANUM 171',
  533170536 : 'DANUM 172',
  533170554 : 'HARBOUR STAR',
  533170578 : 'HARBOUR NEPTUNE',
  533170614 : 'HARBOUR GALAXY',
  533170635 : 'DANUM 173',
  533170679 : 'DANUM 175',
  533170729 : 'HARBOUR HORIZON',
  533180127 : 'MTT PULAU PINANG',
  533180146 : 'MTT MUARA',
  533180218 : 'MTT BINTULU',
  538001652 : 'HARRISON',
  538001941 : 'PRESIDIO',
  538002485 : 'CAPE FLORES',
  538002591 : 'CAPE FARO',
  538002624 : 'CAPE FLINT',
  538002666 : 'CAPE FORBY',
  538002734 : 'NAVIOS VERMILION',
  538002747 : 'CAPE FRANKLIN',
  538002823 : 'CAPE FULMAR',
  538002955 : 'SC MARA',
  538002961 : 'MOL PROFICIENCY',
  538003035 : 'NAVIOS INDIGO',
  538003104 : 'NAVIOS SPRING',
  538003120 : 'CAPE FAWLEY',
  538003181 : 'CAPE FELTON',
  538003221 : 'CAPE FERROL',
  538003252 : 'WADI BANI KHALID',
  538003274 : 'MATSON OAHU',
  538003278 : 'NAVIOS DESTINY',
  538003407 : 'NAVIOS DEVOTION',
  538003580 : 'CAPE MALE',
  538003648 : 'ONE MAGNIFICENCE',
  538003651 : 'NAVIOS LAPIS',
  538003717 : 'TANJA',
  538003729 : 'ONE MAJESTY',
  538003810 : 'ONE MATRIX',
  538003886 : 'ONE MAXIM',
  538003993 : 'ONE MARVEL',
  538004117 : 'ONE MOTIVATOR',
  538004165 : 'CAPE MOSS',
  538004202 : 'ONE MISSION',
  538004203 : 'ONE MODERN',
  538004612 : 'CUSSLER',
  538004893 : 'STARSHIP LEO',
  538004894 : 'STARSHIP URSA',
  538004895 : 'STARSHIP PEGASUS',
  538004911 : 'MARY',
  538004912 : 'ALEXANDRA',
  538004913 : 'KRISTINA',
  538004914 : 'KATHERINE',
  538005024 : 'RUTH BORCHARD',
  538005039 : 'CHARLOTTE BORCHARD',
  538005042 : 'MUKADDES KALKAVAN',
  538005057 : 'SAFEEN PRIZE',
  538005064 : 'RACHEL BORCHARD',
  538005112 : 'SAFEEN PROSPER',
  538005224 : 'SAFEEN PRISM',
  538005242 : 'SAFEEN PRESTIGE',
  538005308 : 'SAFEEN PRIME',
  538005313 : 'PEGASUS TERA',
  538005519 : 'SUNNY LILY',
  538005520 : 'SUNNY COSMOS',
  538005592 : 'JEJU ISLAND',
  538005624 : 'WIDE ALPHA',
  538005625 : 'STEPHANIE C',
  538005686 : 'HONGKONG BRIDGE',
  538005687 : 'ROTTERDAM BRIDGE',
  538005732 : 'SAN FELIPE',
  538005733 : 'SAN FELIX',
  538005734 : 'SAN FERNANDO',
  538005735 : 'SAN FRANCISCA',
  538005747 : 'MAERSK EUPHRATES',
  538005750 : 'WIDE HOTEL',
  538005751 : 'WIDE INDIA',
  538005752 : 'WIDE JULIET',
  538005895 : 'HEUNG-A JANICE',
  538005896 : 'HEUNG-A HAIPHONG',
  538005897 : 'HEUNG-A XIAMEN',
  538005898 : 'HEUNG-A AKITA',
  538005929 : 'AS NORA',
  538005938 : 'TIANJIN BRIDGE',
  538005956 : 'SEAMAX WESTPORT',
  538005958 : 'ALEXIS',
  538005959 : 'OLIVIA I',
  538006111 : 'TASMAN',
  538006141 : 'SEAMAX STAMFORD',
  538006207 : 'HOPE ISLAND',
  538006257 : 'DELOS WAVE',
  538006605 : 'MH HAMBURG',
  538006767 : 'SEAMAX MYSTIC',
  538006980 : 'HEUNG-A BANGKOK',
  538006981 : 'HEUNGA HOCHIMINH',
  538007063 : 'SEAMAX NORWALK',
  538007097 : 'ZIM WILMINGTON',
  538007157 : 'INSPIRE',
  538007209 : 'EM CORFU',
  538007259 : 'HYUNDAI TACOMA',
  538007311 : 'STARSHIP TAURUS',
  538007312 : 'STARSHIP AQUILA',
  538007360 : 'ONE TRIUMPH',
  538007378 : 'ONE TRUST',
  538007386 : 'INTEGRA',
  538007457 : 'INFINITY',
  538007477 : 'HYUNDAI BRAVE',
  538007479 : 'HYUNDAI COURAGE',
  538007480 : 'HYUNDAI FAITH',
  538007481 : 'HYUNDAI FORCE',
  538007482 : 'HYUNDAI FORWARD',
  538007483 : 'HYUNDAI UNITY',
  538007484 : 'HYUNDAI GRACE',
  538007485 : 'HYUNDAI DYNASTY',
  538007486 : 'HYUNDAI VOYAGER',
  538007487 : 'HYUNDAI SUPREME',
  538007503 : 'KMTC HAIPHONG',
  538007518 : 'TS BANGKOK',
  538007547 : 'STAR CLIPPER',
  538007548 : 'STAR SKIPPER',
  538007610 : 'NAVIOS AZURE',
  538007617 : 'NAVIOS VERANO',
  538007622 : 'NAVIOS VERDE',
  538007628 : 'MATSON LANAI',
  538007641 : 'BFAD ATLANTIC',
  538007662 : 'TS TOKYO',
  538007677 : 'ATLANTIC DISCOVERER',
  538007680 : 'MELINA',
  538007681 : 'SPYROS V',
  538007683 : 'CONSTANTINOS P II',
  538007684 : 'ELENI T',
  538007698 : 'SEAMAX STRATFORD',
  538007711 : 'INGENUITY',
  538007728 : 'TS KAOHSIUNG',
  538007806 : 'INSIGHT',
  538007820 : 'TS OSAKA',
  538007905 : 'HMM PROMISE',
  538007938 : 'CAPTAIN THANASIS I',
  538008132 : 'CAP SAN JUAN',
  538008133 : 'CAP SAN VINCENT',
  538008134 : 'CAP SAN LAZARO',
  538008152 : 'NAVIOS SUMMER',
  538008542 : 'BALTIC NORTH',
  538008563 : 'BALTIC WEST',
  538008564 : 'BALTIC SOUTH',
  538008593 : 'SC MONTREUX',
  538008635 : 'INVICTA',
  538008691 : 'INCEDA',
  538008755 : 'SYNERGY BUSAN',
  538008756 : 'SYNERGY ANTWERP',
  538008811 : 'INCRES',
  538008834 : 'SAWASDEE PACIFIC',
  538008845 : 'TS SINGAPORE',
  538008859 : 'STAR EXPLORER',
  538008860 : 'STAR FRONTIER',
  538008946 : 'INFERRO',
  538008980 : 'MAERSK ZAMBEZI',
  538009116 : 'INESSA',
  538009125 : 'W KAMPALA',
  538009126 : 'W KYRENIA',
  538009143 : 'W KLAIPEDA',
  538009144 : 'W KITHIRA',
  538009162 : 'TS NINGBO',
  538009167 : 'INDURO',
  538009208 : 'CONSISTENCE',
  538009216 : 'SEAMAX DARIEN',
  538009224 : 'GH TRAMONTANE',
  538009244 : 'TS LAEMCHABANG',
  538009272 : 'CONSCIENCE',
  538009273 : 'CONFIDENCE',
  538009274 : 'CONTESSA',
  538009275 : 'CONCERTO',
  538009276 : 'CONSERO',
  538009277 : 'GH MAESTRO',
  538009287 : 'BALTIMORE STAR',
  538009289 : 'BFAD SOUTHERN',
  538009290 : 'TS KELANG',
  538009319 : 'GH BORA',
  538009350 : 'CELSIUS LONDON',
  538009373 : 'TS SYDNEY',
  538009388 : 'ARIANA A',
  538009390 : 'GH FOEHN',
  538009400 : 'GFS GALAXY',
  538009416 : 'SC HOUSTON',
  538009437 : 'CELSIUS NICOSIA',
  538009457 : 'SCO SHANGHAI',
  538009458 : 'MAYFIELD',
  538009489 : 'MORAGA',
  538009514 : 'SHANGHAI VOYAGER',
  538009520 : 'TS KWANGYANG',
  538009523 : 'GABRIELA A',
  538009559 : 'DEL MONTE VALIANT',
  538009560 : 'DEL MONTE GOLD',
  538009561 : 'DEL MONTE SPIRIT',
  538009562 : 'DEL MONTE ROSE',
  538009563 : 'DEL MONTE PRIDE',
  538009564 : 'DEL MONTE HARVESTER',
  538009596 : 'MANILA VOYAGER',
  538009601 : 'CELSIUS NAIROBI',
  538009602 : 'CELSIUS NAPLES',
  538009604 : 'CELSIUS BOSTON',
  538009605 : 'CELSIUS BRICKELL',
  538009624 : 'LADYJANE',
  538009630 : 'CONSIGNIA',
  538009631 : 'CONSILIA',
  538009632 : 'CONTRIVIA',
  538009693 : 'STAR CHASER',
  538009694 : 'STAR RANGER',
  538009707 : 'NYK LAURA',
  538009719 : 'SAFEEN PRIDE',
  538009749 : 'TS DALIAN',
  538009760 : 'MANZANILLO EXPRESS',
  538009762 : 'ITAJAI EXPRESS',
  538009764 : 'BUENAVENTURA EXPRESS',
  538009830 : 'PRESTIGE',
  538009831 : 'GOOD PROSPECT',
  538009877 : 'CELSIUS NELSON',
  538009878 : 'CELSIUS NEW ORLEANS',
  538009884 : 'FAYSTON FARMS',
  538009897 : 'ATLANTIC IBIS',
  538009915 : 'TS NAGOYA',
  538009929 : 'SAWASDEE CHITTAGONG',
  538009940 : 'SAFEEN PIONEER',
  538009951 : 'BIG LILLY',
  538010005 : 'STARSHIP DRACO',
  538010006 : 'PEGASUS PROTO',
  538010008 : 'SAWASDEE SHANGHAI',
  538010028 : 'RENA P',
  538010029 : 'EMMANUEL P',
  538010031 : 'EAGLE II',
  538010037 : 'CF ATHENA',
  538010046 : 'SAWASDEE XIAMEN',
  538010048 : 'SAWASDEE INCHEON',
  538010060 : 'TS XIAMEN',
  538010061 : 'SAWASDEE SIRIUS',
  538010092 : 'NYK SILVIA',
  538010110 : 'TS TIANJIN',
  538010120 : 'GREGOS',
  538010122 : 'TERATAKI',
  538010124 : 'MARLA TIGER',
  538010153 : 'SAFEEN POWER',
  538010170 : 'TS JAKARTA',
  538010174 : 'TS HOCHIMINH',
  538010175 : 'TS KOBE',
  538010176 : 'TS NANSHA',
  538010219 : 'SAWASDEE VEGA',
  538010265 : 'MARLA BULL',
  538010267 : 'TS GUANGZHOU',
  538010322 : 'VEGA COLIGNY',
  538010323 : 'VEGA DAYTONA',
  538010343 : 'REMAH',
  538010344 : 'TS SHEKOU',
  538010372 : 'IRENES RULE',
  538010373 : 'IRENES WISDOM',
  538010399 : 'SAWASDEE CAPELLA',
  538010433 : 'TS MAWEI',
  538010485 : 'VENTO DI BORA',
  538010486 : 'CAPE BRUNO',
  538010501 : 'SAWASDEE RIGEL',
  538010505 : 'SAWASDEE ALTAIR',
  538010544 : 'CAPE BONAVISTA',
  538010545 : 'CAPE BYRON',
  538010558 : 'TS CHIBA',
  538010559 : 'TS HAKATA',
  538010572 : 'SAWASDEE SPICA',
  538010641 : 'TEH TAICHUNG',
  538010654 : 'TS INCHEON',
  538010670 : 'CARLA-LIV',
  538010690 : 'TS VANCOUVER',
  538010693 : 'TS TACOMA',
  538010740 : 'TS MUNDRA',
  538010741 : 'TS MELBOURNE',
  538010745 : 'SAWASDEE DENEB',
  538010758 : 'TS LIANYUNGANG',
  538010770 : 'GULF BARAKAH',
  538010776 : 'WADI DUKA',
  538010783 : 'TS PUSAN',
  538010784 : 'TS QINGDAO',
  538010785 : 'TS SHENZHEN',
  538010792 : 'TS JOHOR',
  538010793 : 'TS CHENNAI',
  538010794 : 'VANCOUVER STAR',
  538010796 : 'SHEKOU STAR',
  538010806 : 'OOCL ST.LAWRENCE',
  538010808 : 'YOKOHAMA STAR',
  538010813 : 'TS PENANG',
  538010837 : 'QINGDAO STAR',
  538010845 : 'SAWASDEE MIMOSA',
  538010876 : 'XIA CHUAN 151',
  538010946 : 'TS SURABAYA',
  538010956 : 'SAFEEN PEARL',
  538090562 : 'CALIDRIS',
  538090563 : 'BARRIER',
  538090571 : 'HANSA COLOMBO',
  538090582 : 'HANSA ASIA',
  538090583 : 'HANSA AUSTRALIA',
  538090584 : 'SPIRIT OF HONG KONG',
  538090596 : 'VEGA ALPHA',
  538090601 : 'VEGA VELA',
  538090605 : 'OLYMPIA',
  538090606 : 'OPHELIA',
  538090625 : 'LOA HARMONY',
  538090639 : 'XING LUO 7',
  538090641 : 'AS NINA',
  548184500 : 'MV OCEAN ZENITH',
  548256500 : 'SPAN ASIA 32',
  548258500 : 'MV SPAN ASIA 31',
  548263500 : 'IRIS PAOAY',
  548321500 : 'MC HUNTER',
  548350500 : 'MERIDIAN OCHO',
  548390100 : 'MV LORCON DUMAGUETE',
  548471100 : 'LORCON GENERALSANTOS',
  548581300 : 'SAN LORENZO RUIZ UNO',
  548612300 : 'DON ALFONSO SR.2',
  548614200 : 'OCEAN OPPORTUNITY',
  548636500 : 'M/V TRANS-ASIA 15',
  548699200 : 'MCC BATANES',
  548740200 : 'SPAN ASIA 25',
  548770200 : 'MV SPAN ASIA 27',
  548819200 : 'MV SPAN ASIA 3',
  553111742 : 'GAZELLE COAST',
  553111747 : 'BOUGAINVILLE COAST',
  553111867 : 'NIUGINI COAST',
  553111941 : 'MV ELSIE',
  563000400 : 'MAERSK SANA',
  563000500 : 'MAERSK SYDNEY',
  563000600 : 'MAERSK SINGAPORE',
  563000700 : 'MAERSK SEVILLE',
  563000800 : 'MAERSK SARNIA',
  563000900 : 'MAERSK SANTANA',
  563001100 : 'MAERSK SHEERNESS',
  563001200 : 'MAERSK SOFIA',
  563001700 : 'MAERSK STADELHORN',
  563002100 : 'MAERSK SIRAC',
  563004200 : 'DALI',
  563004400 : 'CHATTANOOGA',
  563004500 : 'TALLAHASSEE',
  563004600 : 'KALAMAZOO',
  563004900 : 'EVER STRONG',
  563005400 : 'INTERASIA ADVANCE',
  563006800 : 'MAERSK SHAMS',
  563007700 : 'CAPITAINE DAMPIER',
  563011000 : 'KOTA NANHAI',
  563017800 : 'MAERSK HONG KONG',
  563020400 : 'TOKYO TRIUMPH',
  563024600 : 'MIMMI SCHULTE',
  563024700 : 'MOLLY SCHULTE',
  563024800 : 'MINSTREL',
  563024900 : 'MANDALAY',
  563025600 : 'NYK VIRGO',
  563026700 : 'MAERSK HORSBURGH',
  563029900 : 'TAIPEI TRIUMPH',
  563030500 : 'MAERSK HALIFAX',
  563032600 : 'WAN HAI 173',
  563032700 : 'MAERSK SAIGON',
  563032800 : 'MAERSK STRALSUND',
  563032900 : 'MAERSK SEOUL',
  563033000 : 'EVER LOVELY',
  563033800 : 'KOTA PAHLAWAN',
  563034800 : 'PARANAGUA EXPRESS',
  563034900 : 'MAERSK HIDALGO',
  563035100 : 'MAERSK DOUALA',
  563035200 : 'MAERSK DAKAR',
  563035400 : 'ALS FLORA',
  563039700 : 'MAERSK HANOI',
  563041500 : 'WAN HAI 175',
  563042200 : 'MAERSK HANGZHOU',
  563042400 : 'KOTA PELANGI',
  563044300 : 'MAERSK NAIROBI',
  563048400 : 'ONE OLYMPUS',
  563049100 : 'INTERASIA FORWARD',
  563049900 : 'SAN CLEMENTE',
  563050100 : 'MAERSK CAP CARMEL',
  563050200 : 'RIO BLANCO',
  563050300 : 'RIO BRAVO',
  563050400 : 'MAERSK MONTE ALEGRE',
  563050500 : 'POLAR ECUADOR',
  563051100 : 'SAN CHRISTOBAL',
  563051200 : 'MONTE CERVANTES',
  563051300 : 'MONTE PASCOAL',
  563051400 : 'MONTE ROSA',
  563051500 : 'POLAR COSTA RICA',
  563051600 : 'RIO MADEIRA',
  563051700 : 'MAERSK MONTE AZUL',
  563051800 : 'MONTE VERDE',
  563051900 : 'ALIANCA MANAUS',
  563052100 : 'MONTE TAMARO',
  563052200 : 'MAERSK RIO DELTA',
  563052300 : 'MAERSK RIO NEGRO',
  563052400 : 'MONTE ACONCAGUA',
  563052500 : 'MONTE OLIVIA',
  563052600 : 'CAP JACKSON',
  563052700 : 'CAP JERVIS',
  563052800 : 'SAN VICENTE',
  563052900 : 'MAERSK RIO INDIA',
  563053300 : 'MAERSK HAMBURG',
  563053700 : 'INTERASIA PROGRESS',
  563054900 : 'X PRESS GANGES',
  563058900 : 'X-PRESS MACHU PICCHU',
  563059200 : 'MAERSK HERRERA',
  563060800 : 'CAPITAINE TASMAN',
  563061300 : 'ONE ORPHEUS',
  563062200 : 'MAERSK SALTORO',
  563062300 : 'CEZANNE',
  563062600 : 'ALS VESTA',
  563062700 : 'ALS VENUS',
  563066900 : 'POLAR MEXICO',
  563068900 : 'EVER GIFTED',
  563069900 : 'MAERSK HAVANA',
  563071300 : 'X-PRESS KABRU',
  563073500 : 'YM WISDOM',
  563073700 : 'MAERSK MEMPHIS',
  563073800 : 'MAERSK KENTUCKY',
  563075510 : 'PSA AGILITY',
  563075940 : 'PSA CONNECTIVITY',
  563076200 : 'MAERSK GATESHEAD',
  563076300 : 'MAERSK GIRONDE',
  563077400 : 'MAERSK GARONNE',
  563077800 : 'EVER SUPERB',
  563078200 : 'MAERSK HUACHO',
  563078300 : 'MAERSK HOUSTON',
  563078400 : 'MAERSK GAIRLOCH',
  563079000 : 'NYK ROMULUS',
  563082900 : 'KOTA NEBULA',
  563083400 : 'MAERSK BINTULU',
  563084400 : 'CHASTINE MAERSK',
  563084500 : 'CORNELIUS MAERSK',
  563084800 : 'EVER STEADY',
  563087600 : 'MAERSK SIHANOUKVILLE',
  563088700 : 'CNC NEPTUNE',
  563090200 : 'MAERSK YUKON',
  563090300 : 'MAERSK EUREKA',
  563090400 : 'MAERSK EMERALD',
  563090500 : 'MAERSK EDIRNE',
  563091100 : 'MAERSK SONGKHLA',
  563095400 : 'MAERSK MONGLA',
  563095500 : 'SHIMIN',
  563095600 : 'GREEN STAR',
  563095700 : 'GREEN OCEAN',
  563095800 : 'GREEN FOREST',
  563096400 : 'MAERSK BERMUDA',
  563096500 : 'MAERSK BAHAMAS',
  563096700 : 'EVER SAFETY',
  563098400 : 'CNC MARS',
  563101600 : 'MAERSK RUBICON',
  563101900 : 'GREEN EARTH',
  563102000 : 'MAERSK BALI',
  563102100 : 'GREEN SEA',
  563102400 : 'EVER SALUTE',
  563102800 : 'SHINA',
  563103200 : 'POLAR COLOMBIA',
  563103400 : 'MAERSK AMAZON',
  563103800 : 'GREEN POLE',
  563103900 : 'PAC SEGINUS',
  563105800 : 'NAVEGANTES EXPRESS',
  563107100 : 'MAERSK QINZHOU',
  563109200 : 'MAERSK DAVAO',
  563110300 : 'GREEN WAVE',
  563110400 : 'GREEN DAWN',
  563110800 : 'INTERASIA MOMENTUM',
  563111100 : 'WAN HAI 321',
  563111200 : 'SINAR SUNDA',
  563111300 : 'MAERSK XIAMEN',
  563113200 : 'WAN HAI 322',
  563114100 : 'INTERASIA PURSUIT',
  563115100 : 'INTERASIA VISION',
  563115300 : 'GFS RUBY',
  563115400 : 'GFS PRIDE',
  563115900 : 'GFS PRESTIGE',
  563116500 : 'WAN HAI 283',
  563116700 : 'SINAR SORONG',
  563118100 : 'X-PRESS MEKONG',
  563118300 : 'WAN HAI 323',
  563118500 : 'ASIATIC SUN',
  563118600 : 'ASIATIC QUEST',
  563119000 : 'NYK JOANNA',
  563119400 : 'WAN HAI 325',
  563120900 : 'ASIATIC REUNION',
  563121100 : 'ASIATIC PRIDE',
  563121900 : 'WAN HAI 277',
  563122100 : 'MAERSK VALENCIA',
  563122300 : 'X-PRESS SAGARMALA',
  563123500 : 'WAN HAI 521',
  563124200 : 'WAN HAI 326',
  563124300 : 'WAN HAI 327',
  563124400 : 'MH GREEN',
  563125200 : 'BOTANY',
  563125500 : 'JT GLORY',
  563126500 : 'WAN HAI 522',
  563126700 : 'WAN HAI 622',
  563126900 : 'WAN HAI 285',
  563127400 : 'GFS PERFECT',
  563127500 : 'X-PRESS ODYSSEY',
  563127900 : 'MAERSK ATHABASCA',
  563128200 : 'WAN HAI 328',
  563128900 : 'WAN HAI 329',
  563129600 : 'ANDERSON DRAGON',
  563129900 : 'WAN HAI 286',
  563130100 : 'MEDITERRANEAN EXPRES',
  563130400 : 'WAN HAI 721',
  563131500 : 'ONE MANCHESTER',
  563131600 : 'A DAISEN',
  563131700 : 'MAHAWELI',
  563131800 : 'WAN HAI 523',
  563132400 : 'WAN HAI 722',
  563132500 : 'WAN HAI 287',
  563133100 : 'WHUTTHI BHUM',
  563133400 : 'KOTA LAGU',
  563133500 : 'KOTA LARIS',
  563133800 : 'X-PRESS NILWALA',
  563134400 : 'WAN HAI 288',
  563135300 : 'WAN HAI 625',
  563135700 : 'MAERSK NACALA',
  563135800 : 'AKA BHUM',
  563136800 : 'BHUDTHI BHUM',
  563137500 : 'KOTA HENING',
  563137600 : 'KOTA JAYA',
  563137700 : 'KOTA HANDAL',
  563137800 : 'KOTA NASRAT',
  563137900 : 'KOTA LAYANG',
  563138100 : 'KOTA RAKAN',
  563138600 : 'WAN HAI 289',
  563139600 : 'WAN HAI 290',
  563139700 : 'GANTA BHUM',
  563139800 : 'X PRESS NETRAVATI',
  563141200 : 'YM TRUST',
  563141600 : 'X-PRESS KAVERI',
  563142100 : 'EVER SUMMIT',
  563142500 : 'WAN HAI 626',
  563142800 : 'WAN HAI 723',
  563143000 : 'EVER LIVING',
  563143100 : 'WAN HAI 291',
  563143500 : 'ATLANTIC MONACO',
  563143600 : 'ATLANTIC SILVER',
  563144200 : 'INTERASIA RESILIENCE',
  563144500 : 'WHITE DRAGON',
  563144600 : 'SUNRISE DRAGON',
  563145100 : 'CAPITAINE BARET',
  563145300 : 'WAN HAI 176',
  563146500 : 'MAERSK IDAHO',
  563146600 : 'BREMEN BELLE',
  563147400 : 'PACIFIC SINGAPORE',
  563147900 : 'WAN HAI 292',
  563148100 : 'MAERSK WALLIS',
  563148200 : 'MAERSK WILLEMSTADT',
  563148300 : 'MAERSK WELLINGTON',
  563149500 : 'KOTA CABAR',
  563149800 : 'KOTA CAHAYA',
  563149900 : 'KOTA CANTIK',
  563150100 : 'KOTA CARUM',
  563150700 : 'WAN HAI 276',
  563152200 : 'KOTA GABUNG',
  563152300 : 'KOTA LIHAT',
  563152400 : 'KOTA KARIM',
  563152500 : 'KOTA GAYA',
  563152600 : 'KOTA LAWA',
  563152800 : 'X-PRESS IRRAWADDY',
  563153200 : 'WAN HAI 293',
  563154500 : 'MAERSK VALLETTA',
  563155100 : 'X-PRESS MOY',
  563155200 : 'YM TROPHY',
  563155500 : 'EVER APEX',
  563155800 : 'ATLANTIC GENEVA',
  563156200 : 'X-PRESS DHAULAGIRI',
  563156600 : 'MAERSK NUSANTARA',
  563156700 : 'MAERSK CHATTOGRAM',
  563156900 : 'PACIFIC BEIJING',
  563157300 : 'PACIFIC GENEVA',
  563157400 : 'PACIFIC BUSAN',
  563157600 : 'ATLANTIC NORTH',
  563157700 : 'ATLANTIC WEST',
  563158200 : 'ATLANTIC SOUTH',
  563158300 : 'PACIFIC SHENZHEN',
  563158400 : 'PACIFIC CARRIER',
  563158600 : 'PACIFIC TIANJIN',
  563158700 : 'ATLANTIC EAST',
  563158800 : 'WAN HAI 309',
  563158900 : 'MAERSK VERACRUZ',
  563159100 : 'PACIFIC NINGBO',
  563159300 : 'ATLANTIC EXPRESS',
  563159400 : 'PACIFIC DALIAN',
  563159700 : 'WAN HAI 295',
  563160100 : 'EVER FORTUNE',
  563160200 : 'EVER FUTURE',
  563160400 : 'MAERSK VALPARAISO',
  563160800 : 'WAN HAI 308',
  563161200 : 'EVER FORWARD',
  563161400 : 'HUNSA BHUM',
  563161700 : 'WAN HAI 278',
  563161800 : 'MAERSK JIANGYIN',
  563161900 : 'MAERSK VICTORIA',
  563163300 : 'WAN HAI 627',
  563163400 : 'EVER FRANK',
  563163500 : 'WAN HAI 296',
  563164800 : 'GFS PRECIOUS',
  563165200 : 'MAERSK SHEKOU',
  563165500 : 'WAN HAI 351',
  563166100 : 'EVER ATOP',
  563166600 : 'ARAYA BHUM',
  563166700 : 'CRYSTAL A',
  563167500 : 'API BHUM',
  563168100 : 'X-PRESS ANTARES',
  563168200 : 'X-PRESS ALTAIR',
  563168300 : 'X-PRESS SALWEEN',
  563168600 : 'CAPITAINE TUPAIA',
  563169200 : 'INTERASIA ENGAGE',
  563169400 : 'WINDSWEPT',
  563169900 : 'INTERASIA ENHANCE',
  563170300 : 'WAN HAI A07',
  563170500 : 'A GORYU',
  563170600 : 'WAN HAI 352',
  563171800 : 'WAN HAI 355',
  563172200 : 'KOTA LEGIT',
  563172300 : 'KOTA LEKAS',
  563172700 : 'WAN HAI A08',
  563173400 : 'INTERASIA ELEVATE',
  563173600 : 'WAN HAI 353',
  563174100 : 'WAN HAI 357',
  563175600 : 'BRIGHT LAEM CHABANG',
  563176600 : 'EVER ACME',
  563176800 : 'WAN HAI 356',
  563177100 : 'CAT LAI EXPRESS',
  563178400 : 'CNC SULAWESI',
  563178500 : 'KOTA NAZAR',
  563178900 : 'CNC SERVAL',
  563179100 : 'EA GANNET',
  563180800 : 'WAN HAI 358',
  563181000 : 'RACHA BHUM',
  563181400 : 'WAN HAI A01',
  563181600 : 'WAN HAI 360',
  563182200 : 'WAN HAI A09',
  563182400 : 'WAN HAI 359',
  563182800 : 'EA NODDY',
  563183700 : 'BALTIC PETREL',
  563183800 : 'LOBIVIA',
  563183900 : 'X-PRESS ANTLIA',
  563185300 : 'CFS WIND',
  563185700 : 'WAN HAI 331',
  563186500 : 'ALS CERES',
  563186600 : 'PL GERMANY',
  563187400 : 'WAN HAI A10',
  563187500 : 'WAN HAI 361',
  563187900 : 'WAN HAI A02',
  563188500 : 'MAERSK WAKAYAMA',
  563190100 : 'WAN HAI 365',
  563190300 : 'WAN HAI 363',
  563190500 : 'EVER MAX',
  563190600 : 'VIKING ORCA',
  563191100 : 'CFS HORIZON',
  563191200 : 'ALS FIDES',
  563191300 : 'ONE ATLAS',
  563192300 : 'WAN HAI 362',
  563192500 : 'SOL RELIANCE',
  563192600 : 'TIGER CHENNAI',
  563192700 : 'X-PRESS CAPELLA',
  563192800 : 'PIYA BHUM',
  563192900 : 'NATTHA BHUM',
  563193300 : 'CMA CGM GUARANI',
  563193500 : 'MAERSK WALVIS BAY',
  563193600 : 'CNC PANTHER',
  563193900 : 'SSF DREAM',
  563194100 : 'SSF DYNAMIC',
  563194900 : 'WAN HAI A03',
  563195600 : 'WAN HAI 332',
  563195900 : 'CNC LEOPARD',
  563196200 : 'SINAR BAJO',
  563196300 : 'WAN HAI A11',
  563197800 : 'WAN HAI 367',
  563197900 : 'MH PERSEUS',
  563198300 : 'ALS LUNA',
  563198500 : 'MH PEGASUS',
  563199500 : 'X-PRESS CARINA',
  563199600 : 'EVER MACH',
  563199800 : 'SINAR BUKITTINGGI',
  563201400 : 'X-PRESS CASSIOPEIA',
  563201600 : 'WAN HAI A05',
  563201800 : 'CNC JAWA',
  563202300 : 'EA CHARA',
  563202400 : 'X-PRESS AQUARIUS',
  563202500 : 'EA CENTAURUS',
  563202800 : 'X-PRESS PHOENIX',
  563204200 : 'WAN HAI 333',
  563204900 : 'WAN HAI A12',
  563205000 : 'WAN HAI A13',
  563205200 : 'MAERSK YOKOHAMA',
  563205400 : 'CUL QINGDAO',
  563205700 : 'WAN HAI 368',
  563207600 : 'MAERSK OLYMPIC',
  563209300 : 'X-PRESS PISCES',
  563209500 : 'CUL SHEKOU',
  563210600 : 'WAN HAI A06',
  563211100 : 'WAN HAI 335',
  563211300 : 'CUL HOCHIMINH',
  563213800 : 'EVER CHAMP',
  563214600 : 'NPDL CALIFORNIA',
  563214700 : 'NPDL TAHITI',
  563217100 : 'WAN HAI 369',
  563217600 : 'WAN HAI A13',
  563231000 : 'APL MERLION',
  563234000 : 'APL FULLERTON',
  563247000 : 'APL ESPLANADE',
  563253000 : 'KOTA SEMPENA',
  563265000 : 'KOTA LESTARI',
  563266000 : 'APL CAIRO',
  563267000 : 'KOTA LOCENG',
  563313000 : 'WAN HAI 611',
  563361000 : 'KOTA CEPAT',
  563362000 : 'KOTA CEMPAKA',
  563377000 : 'NORFOLK EXPRESS',
  563379000 : 'X-PRESS MEGHNA',
  563382000 : 'WAN HAI 501',
  563400000 : 'EVER UNITED',
  563403000 : 'MERIDIAN',
  563417000 : 'MARINER',
  563433000 : 'MAERSK NORTHWOOD',
  563455000 : 'MAERSK BULAN',
  563458000 : 'MAERSK NESTON',
  563460000 : 'MAERSK NEWCASTLE',
  563471000 : 'CLEMENS SCHULTE',
  563479000 : 'CHRISTA SCHULTE',
  563485000 : 'WAN HAI 311',
  563487000 : 'CARL SCHULTE',
  563491000 : 'CHARLOTTE SCHULTE',
  563498000 : 'APL JEDDAH',
  563508000 : 'MAERSK BRATAN',
  563514000 : 'MAERSK BOGOR',
  563538000 : 'MAERSK JALAN',
  563545000 : 'MIEKE SCHULTE',
  563549000 : 'MAJESTIC',
  563587000 : 'YERUPAJA',
  563589000 : 'NYK FUJI',
  563625000 : 'KOTA RAJIN',
  563675000 : 'INTERASIA CATALYST',
  563677000 : 'KOTA NALURI',
  563726000 : 'APL SCOTLAND',
  563750000 : 'PSA SUSTAINABILITY',
  563754000 : 'APL HOLLAND',
  563757000 : 'MAERSK UTAH',
  563758000 : 'NYK RUMINA',
  563808000 : 'WAN HAI 502',
  563812000 : 'WAN HAI 503',
  563839000 : 'X-PRESS KAILASH',
  563841000 : 'ISARA BHUM',
  563842000 : 'X-PRESS KARAKORAM',
  563886000 : 'KOTA AZAM',
  563887000 : 'KOTA ANGGUN',
  563916000 : 'KOTA RANCAK',
  563955000 : 'KOTA TAMPAN',
  563961000 : 'INTERASIA HORIZON',
  563982000 : 'EVER LIVELY',
  563984000 : 'SINAR BINTAN',
  564029000 : 'CHANA BHUM',
  564030000 : 'DANU BHUM',
  564050000 : 'ORINOCO',
  564058000 : 'KOTA HIDAYAH',
  564169000 : 'M.V KITI BHUM',
  564226000 : 'ORA BHUM',
  564241000 : 'LILA BHUM',
  564246000 : 'SATTHA BHUM',
  564262000 : 'X-PRESS HOOGLY',
  564264000 : 'KOTA SETIA',
  564305000 : 'MAERSK CONAKRY',
  564306000 : 'MAERSK COTONOU',
  564316000 : 'PIRA BHUM',
  564341000 : 'NITHI BHUM',
  564345000 : 'EVER LUCENT',
  564354000 : 'METHI BHUM',
  564360000 : 'MAERSK CALABAR',
  564391000 : 'ALS SUMIRE',
  564398000 : 'MAERSK BATUR',
  564399000 : 'MAERSK BRANI',
  564440000 : 'INTERASIA MOTIVATION',
  564442000 : 'EVER ULYSSES',
  564519000 : 'KOTA RAJA',
  564524000 : 'WAN HAI 303',
  564559000 : 'KOTA RATU',
  564575000 : 'THALASSA AVRA',
  564588000 : 'KOTA RATNA',
  564599000 : 'KOTA RUKUN',
  564619000 : 'APL PUSAN',
  564622000 : 'KOTA HARUM',
  564639000 : 'KOTA SABAS',
  564640000 : 'WAN HAI 305',
  564726000 : 'MAERSK DANUBE',
  564757000 : 'KOTA SINGA',
  564768000 : 'WAN HAI 306',
  564796000 : 'KOTA SURIA',
  564800000 : 'KOTA TENAGA',
  564820000 : 'SINAR BANGKA',
  564822000 : 'MAERSK VARNA',
  564837000 : 'MAERSK VALLVIK',
  564868000 : 'WAN HAI 307',
  564880000 : 'KOTA HALUS',
  564908000 : 'KOTA HAPAS',
  564909000 : 'KOTA HAKIM',
  564938000 : 'NYK ISABEL',
  564979000 : 'MAERSK VISBY',
  564995000 : 'EVER UNICORN',
  565004000 : 'WAN HAI 505',
  565005000 : 'WAN HAI 506',
  565021000 : 'KOTA SAHABAT',
  565032000 : 'WAN HAI 312',
  565050000 : 'KOTA RIA',
  565110000 : 'KOTA SALAM',
  565127000 : 'KOTA RAKYAT',
  565128000 : 'WAN HAI 165',
  565132000 : 'WAN HAI 313',
  565133000 : 'KOTA KAMIL',
  565156000 : 'SWAN RIVER BRIDGE',
  565196000 : 'MAERSK GANGES',
  565213000 : 'LUCIE SCHULTE',
  565220000 : 'WAN HAI 315',
  565222000 : 'HANNAH SCHULTE',
  565253000 : 'GRASMERE MAERSK',
  565283000 : 'CAPT.KATTELMANN',
  565334000 : 'KOTA SATRIA',
  565357000 : 'KOTA SEGAR',
  565376000 : 'MATHILDE SCHULTE',
  565380000 : 'MAX SCHULTE',
  565382000 : 'MELCHIOR SCHULTE',
  565385000 : 'MIA SCHULTE',
  565402000 : 'MATSON MAUI',
  565413000 : 'SOL SINGAPORE',
  565417000 : 'KOTA DUNIA',
  565425000 : 'WAN HAI 316',
  565448000 : 'MAERSK SEMBAWANG',
  565449000 : 'MAERSK SERANGOON',
  565451000 : 'MAERSK SEBAROK',
  565473000 : 'MAERSK NORTHAMPTON',
  565475000 : 'MAERSK NEWHAVEN',
  565482000 : 'MAERSK SENANG',
  565485000 : 'MAERSK SEMAKAU',
  565533000 : 'MAERSK VILNIUS',
  565536000 : 'WAN HAI 507',
  565547000 : 'GFS SAPPHIRE',
  565564000 : 'BLPL BLESSING',
  565565000 : 'WAN HAI 623',
  565567000 : 'WAN HAI 508',
  565570000 : 'MAERSK ALTAIR',
  565615000 : 'NAWATA BHUM',
  565616000 : 'MITRA BHUM',
  565626000 : 'ASIATIC NEPTUNE',
  565653000 : 'WAN HAI 509',
  565661000 : 'MAERSK ANTARES',
  565688000 : 'KOTA NAZIM',
  565696000 : 'OTANA BHUM',
  565697000 : 'PANJA BHUM',
  565706000 : 'KOTA SEJARAH',
  565730000 : 'KOTA RAHMAT',
  565741000 : 'WAN HAI 510',
  565747000 : 'MAERSK ALFIRK',
  565760000 : 'CAPITAINE WALLIS',
  565762000 : 'MAERSK TAURUS',
  565771000 : 'KOTA SEJATI',
  565772000 : 'KOTA DAHLIA',
  565777000 : 'WAN HAI 317',
  565780000 : 'KOTA DUTA',
  565795000 : 'KOTA NABIL',
  565807000 : 'NYK CLARA',
  565810000 : 'MAERSK BATAM',
  565819000 : 'MAERSK ALGOL',
  565825000 : 'MAERSK TUKANG',
  565849000 : 'ASIATIC KING',
  565887000 : 'ACX DIAMOND',
  565893000 : 'KOTA NAGA',
  565904000 : 'KOTA RESTU',
  565909000 : 'KOTA LAMBAI',
  565936000 : 'MAERSK JABAL',
  565944000 : 'KOTA LAMBANG',
  565953000 : 'SINAR SOLO',
  565963000 : 'NYK DANIELLA',
  565967000 : 'MAERSK VIGO',
  565970000 : 'LUDWIG SCHULTE',
  565971000 : 'MAERSK BUTON',
  566002000 : 'NYK FUTAGO',
  566055000 : 'MAERSK CAPE COAST',
  566056000 : 'MAERSK CAMEROUN',
  566093000 : 'MAERSK CHENNAI',
  566118000 : 'KOTA NIPAH',
  566127000 : 'SINAR BANDUNG',
  566145000 : 'MAERSK CONGO',
  566158000 : 'MAERSK CUNENE',
  566187000 : 'MAERSK CAPE TOWN',
  566235000 : 'WAN HAI 271',
  566259000 : 'NYK FURANO',
  566278000 : 'MAERSK NEWBURY',
  566282000 : 'KOTA SELAMAT',
  566318000 : 'APL CHONGQING',
  566319000 : 'APL GWANGYANG',
  566335000 : 'NYK FUSHIMI',
  566340000 : 'WAN HAI 272',
  566406000 : 'WAN HAI 273',
  566407000 : 'APL LE HAVRE',
  566409000 : 'APL SOUTHAMPTON',
  566410000 : 'APL SALALAH',
  566415000 : 'SAFMARINE CAMEROUN',
  566431000 : 'SOL PIONEER',
  566446000 : 'GFS GENESIS',
  566456000 : 'GFS GISELLE',
  566460000 : 'EVER PEARL',
  566482000 : 'APL YANG SHAN',
  566483000 : 'APL BARCELONA',
  566490000 : 'MAERSK CUANZA',
  566491000 : 'MAERSK CABINDA',
  566492000 : 'MAERSK COLOMBO',
  566493000 : 'MAERSK COPENHAGEN',
  566510000 : 'WAN HAI 511',
  566642000 : 'WAN HAI 512',
  566653000 : 'WAN HAI 102',
  566673000 : 'EVER LAUREL',
  566704000 : 'APL PARIS',
  566705000 : 'APL DUBLIN',
  566730000 : 'WAN HAI 513',
  566732000 : 'EVER LAWFUL',
  566755000 : 'MAERSK CADIZ',
  566756000 : 'MAERSK CARDIFF',
  566760000 : 'WAN HAI 105',
  566773000 : 'KOTA MANIS',
  566779000 : 'MAERSK INNOSHIMA',
  566780000 : 'MAERSK IZMIR',
  566781000 : 'MAERSK INVERNESS',
  566794000 : 'EVER LEADER',
  566796000 : 'APL TEMASEK',
  566797000 : 'APL VANCOUVER',
  566828000 : 'WAN HAI 515',
  566853000 : 'EVER LEGACY',
  566858000 : 'KOTA LEMBAH',
  566864000 : 'KOTA MEGAH',
  566879000 : 'APL NEW YORK',
  566880000 : 'APL LION CITY',
  566881000 : 'APL RAFFLES',
  566882000 : 'APL CHANGI',
  566886000 : 'WAN HAI 516',
  566894000 : 'KOTA MACHAN',
  566941000 : 'KOTA GADANG',
  566942000 : 'WAN HAI 517',
  566949000 : 'EVER LEGEND',
  566950000 : 'APL BOSTON',
  566951000 : 'APL PHOENIX',
  566952000 : 'APL SAVANNAH',
  566953000 : 'APL COLUMBUS',
  566954000 : 'APL VANDA',
  566955000 : 'APL SINGAPURA',
  566956000 : 'APL HOUSTON',
  566957000 : 'APL MIAMI',
  566958000 : 'APL CHARLESTON',
  566959000 : 'APL SANTIAGO',
  566960000 : 'APL DETROIT',
  566961000 : 'APL MEXICO CITY',
  566970000 : 'EVER LEGION',
  566981000 : 'KOTA GANDING',
  566999000 : 'WAN HAI 621',
  567002370 : 'NP CHANTHABURI',
  567002380 : 'NP TRAT',
  567002520 : 'NP CHUMPHON',
  567072300 : 'HARI BHUM',
  567303000 : 'URU BHUM',
  567304000 : 'VIRA BHUM',
  567317000 : 'WANA BHUM',
  567318000 : 'XUTRA BHUM',
  567477000 : 'SIRI BHUM',
  567483000 : 'INTRA BHUM',
  567571000 : 'JARU BHUM',
  567574000 : 'KHUNA BHUM',
  567584000 : 'LALIT BHUM',
  567589000 : 'MAKHA BHUM',
  567609000 : 'ZIM THAILAND',
  567614000 : 'ZIM BANGKOK',
  572158220 : 'X PRESS KOHIMA',
  574000120 : 'GOLDEN OCEAN 26',
  574001250 : 'NEW VISION',
  574001370 : 'GOLDEN OCEAN 25',
  574001430 : 'NASICO SKY',
  574001630 : 'HAIAN MIND',
  574001980 : 'VINASHIN EXPRESS 01',
  574002120 : 'TAN CANG PIONEER',
  574002190 : 'VINAFCO 26',
  574002240 : 'HAIAN PARK',
  574002270 : 'TRUONG HAI STAR 3',
  574002540 : 'PHUC KHANH',
  574002850 : 'HAIAN TIME',
  574002930 : 'TAN CANG GLORY',
  574003500 : 'HAIAN BELL',
  574003640 : 'GREEN PACIFIC',
  574003760 : 'PRIDE PACIFIC',
  574003870 : 'TC SYMPHONY',
  574004000 : 'VINAFCO 28',
  574004050 : 'HAIAN LINK',
  574004260 : 'PHUC THAI',
  574004280 : 'PREMIER',
  574004660 : 'PROGRESS',
  574004780 : 'HAIAN VIEW',
  574005100 : 'HAIAN EAST',
  574005160 : 'HAIAN WEST',
  574005410 : 'PRIME',
  574005610 : 'ANBIEN BAY',
  574005640 : 'HAIAN CITY',
  574005800 : 'HAIAN ROSE',
  574005870 : 'TRANSIMEX SUN',
  574005940 : 'HAIAN ALFA',
  574117000 : 'HOA MAI 16',
  574134000 : 'MORNING VINAFCO',
  574260000 : 'BIENDONG MARINER',
  574269000 : 'ATLANTIC OCEAN',
  574280000 : 'BIEN DONG NAVIGATOR',
  574387000 : 'BIENDONG STAR',
  574409000 : 'FORTUNE NAVIGATOR',
  574410000 : 'FORTUNE FREIGHTER',
  574453000 : 'VIMC PIONEER',
  574480000 : 'PACIFIC EXPRESS',
  574491000 : 'HAI VIET STAR',
  577111001 : 'LADY-DEFNE',
  577127000 : 'ATA 2',
  577165000 : 'DUYGU',
  577508000 : 'MICRONESIAN PRIDE',
  600000000 : 'SUGANYU09458',
  605086020 : 'TAMANRASSET',
  605086060 : 'CIRTA',
  605086090 : 'DJANET',
  605136720 : 'IMEDGHASSEN',
  613003732 : 'YEKTA 2',
  613139804 : 'PSL EAGLE',
  613139808 : 'PSL TIGER',
  613426201 : 'AURELIA',
  613519203 : 'DELPHINUS',
  613699603 : 'HALCYON',
  613717300 : 'BLUE STAR 3',
  620040000 : 'PROFESSOR B',
  620620000 : 'MV OCEAN TRADER',
  620724000 : 'TARLAN',
  620743000 : 'ANNABELLA',
  620999147 : 'SOFIA 3',
  620999272 : 'RACE I',
  620999274 : 'SILK',
  621819050 : 'AFRICA SUN',
  622110051 : 'PAN GG',
  622113175 : 'EGY FUTURE',
  622113176 : 'EGY GLORY',
  622113177 : 'EGY SKY',
  622113180 : 'EGY CROWN',
  622121427 : 'WADI ALRAYAN',
  636000000 : 'TAI LE',
  636009008 : 'OOCL BREMERHAVEN',
  636012252 : 'YM WEALTH',
  636012253 : 'YM FOUNTAIN',
  636012254 : 'YM SUCCESS',
  636012794 : 'YM INCREMENT',
  636012795 : 'YM INCEPTION',
  636012796 : 'YM IMAGE',
  636012797 : 'YM INITIATIVE',
  636012798 : 'YM INVENTIVE',
  636012807 : 'MSC UNITED VIII',
  636012808 : 'MSC UTMOST VIII',
  636012905 : 'LORRAINE',
  636013001 : 'KILIMANJARO',
  636013118 : 'YM INSTRUCTION',
  636013119 : 'YM INTERACTION',
  636013121 : 'YM IMPROVEMENT',
  636013143 : 'MSC CAGLIARI IV',
  636013289 : 'COLOMBO',
  636013437 : 'DERBY D',
  636013521 : 'APL MINNESOTA',
  636013522 : 'APL NEW JERSEY',
  636013530 : 'SINGAPORE',
  636013644 : 'TONGALA',
  636013689 : 'MSC UBERTY VIII',
  636013690 : 'NILEDUTCH LION',
  636013691 : 'YM UPWARD',
  636013692 : 'YM UTILITY',
  636013693 : 'YM UNIFORM',
  636013698 : 'YM EFFICIENCY',
  636013699 : 'YM ETERNITY',
  636013898 : 'EVRIDIKI G',
  636014018 : '\M -FJICIENCY',
  636014059 : 'KASSIAKOS',
  636014168 : 'AITOLIKOS',
  636014169 : 'CMA CGM TOPAZ',
  636014199 : 'CMA CGM LAPIS',
  636014200 : 'IONIKOS',
  636014219 : 'ZIM NINGBO',
  636014220 : 'ZIM ANTWERP',
  636014221 : 'ZIM SAN DIEGO',
  636014222 : 'ZIM ROTTERDAM',
  636014224 : 'TIANJIN',
  636014557 : 'YM MANDATE',
  636014558 : 'YM MATURITY',
  636014573 : 'IKARIA',
  636014590 : 'FOLEGANDROS',
  636014603 : 'GSL AFRICA',
  636014644 : 'MSC PETRA',
  636014699 : 'DIAMANTIS P',
  636014740 : 'IRENES REMEDY',
  636014890 : 'ATOUT',
  636014955 : 'EM SPETSES',
  636014956 : 'EM HYDRA',
  636014965 : 'HISTORY ELIZABETH',
  636014968 : 'MARIANETTA',
  636014971 : 'MSC SINES R',
  636014996 : 'YM MUTUALITY',
  636014997 : 'YM MOBILITY',
  636015002 : 'ERATO',
  636015019 : 'EXPRESS ROME',
  636015020 : 'EXPRESS ATHENS',
  636015031 : 'MSC STAR R',
  636015131 : 'MALIAKOS',
  636015181 : 'MSC DURBAN IV',
  636015182 : 'YM MILESTONE',
  636015183 : 'YM MASCULINITY',
  636015233 : 'MSC MANYA',
  636015321 : 'EM ASTORIA',
  636015404 : 'METHONI',
  636015465 : 'NOBILITY',
  636015467 : 'ROSA',
  636015480 : 'KESTREL',
  636015481 : 'PELICAN',
  636015496 : 'MSC MEXICO V',
  636015503 : 'LANA',
  636015506 : 'MSC VEGA',
  636015514 : 'HYUNDAI HONOUR',
  636015516 : 'HYUNDAI RESPECT',
  636015524 : 'IRENES RESOLVE',
  636015604 : 'MSC ALTAIR',
  636015607 : 'HYUNDAI AMBITION',
  636015646 : 'EM KEA',
  636015659 : 'MATE',
  636015664 : 'MSC ALTAMIRA',
  636015665 : 'MSC ALBANY',
  636015666 : 'MSC ANCHORAGE',
  636015667 : 'CALI',
  636015685 : 'GSL CHRISTEL ELISABE',
  636015686 : 'GSL VINIA',
  636015743 : 'MSC ARCHIMIDIS',
  636015744 : 'AGAMEMNON',
  636015754 : 'ARGOLIKOS',
  636015775 : 'LUEBECK',
  636015779 : 'BIG GEORGE',
  636015806 : 'HYUNDAI PRESTIGE',
  636015872 : 'ZIM ALABAMA',
  636015873 : 'NAGOYA TOWER',
  636015876 : 'HYUNDAI PREMIUM',
  636015897 : 'ALEXANDER BAY',
  636015899 : 'MSC HIMANSHI',
  636015912 : 'ANL GIPPSLAND',
  636015930 : 'QINGDAO TOWER',
  636015950 : 'HYUNDAI PARAMOUNT',
  636015952 : 'ADAMS',
  636015975 : 'AGIOS DIMITRIOS',
  636015980 : 'TOCONAO',
  636016003 : 'HYUNDAI PRIVILEGE',
  636016014 : 'MSC ALIX 3',
  636016038 : 'HYUNDAI PLATINUM',
  636016066 : 'MSC POLARIS',
  636016074 : 'TOKYO BAY',
  636016106 : 'ENSENADA',
  636016118 : 'JOANNA',
  636016125 : 'TRAIGUEN',
  636016126 : 'CAP ANDREAS',
  636016177 : 'TRANCURA',
  636016179 : 'BOX ENDEAVOUR',
  636016185 : 'CARDIFF',
  636016186 : 'HAPPY BEE',
  636016204 : 'MSC PRATITI',
  636016231 : 'SWANSEA',
  636016242 : 'YM MODESTY',
  636016247 : 'LOUISE',
  636016305 : 'MSC ALINA',
  636016306 : 'MSC SOFIA PAZ',
  636016321 : 'TYNDALL',
  636016415 : 'MSC CAPUCINE R',
  636016417 : 'MSC GLORY R',
  636016423 : 'MSC BHAVYA V',
  636016424 : 'MSC ANYA',
  636016429 : 'MSC MARGARITA',
  636016430 : 'MSC JULIA R.',
  636016431 : 'MSC VAISHNAVI R.',
  636016432 : 'MSC ARUSHI R.',
  636016433 : 'MSC VIDHI',
  636016434 : 'MSC ANISHA R.',
  636016435 : 'MSC KATYA R.',
  636016436 : 'MSC ZLATA R',
  636016437 : 'MSC VIDISHA R.',
  636016457 : 'MSC ISHYKA',
  636016458 : 'MSC CAPRI',
  636016476 : 'MSC ROCHELLE',
  636016483 : 'AREOPOLIS',
  636016492 : 'MSC SAO PAULO V',
  636016493 : 'MSC MARGARITA',
  636016502 : 'BOX ENDURANCE',
  636016574 : 'MSC SKY II',
  636016575 : 'THORSWIND',
  636016619 : 'X-PRESS NUPTSE',
  636016647 : 'GALEN',
  636016649 : 'GARWOOD',
  636016672 : 'MSC PHOENIX',
  636016673 : 'MSC PEGASUS',
  636016677 : 'X-PRESS LHOTSE',
  636016702 : 'CONTSHIP OAK',
  636016703 : 'YM ENLIGHTENMENT',
  636016704 : 'YM EXCELLENCE',
  636016705 : 'YM EXPRESS',
  636016770 : 'ODYSSEUS',
  636016775 : 'MSC CHERYL 3',
  636016805 : 'MSC NADRIELY',
  636016814 : 'MSC ELSA3',
  636016850 : 'ESL SANA',
  636016851 : 'MSC JULIANA III',
  636016852 : 'MONA LISA',
  636016853 : 'CSL MANHATTAN',
  636016854 : 'PANTHER',
  636016855 : 'ESL WAFA',
  636016856 : 'ESL DANA',
  636016858 : 'MSC CRISTINA',
  636016895 : 'ALE',
  636016940 : 'MSC MANU',
  636016943 : 'MSC RADIANT III',
  636016973 : 'MSC OLIA',
  636016975 : 'SEABOARD PATRIOT',
  636016978 : 'MSC TAVVISHI',
  636016979 : 'ZIM EUROPE',
  636016980 : 'IAN H',
  636016983 : 'ZIM NORFOLK',
  636016985 : 'ZIM XIAMEN',
  636016986 : 'ANTHEA Y',
  636016999 : 'MSC MIRELLA R',
  636017000 : 'MSC RONIT R',
  636017006 : 'MSC ROBERTA V',
  636017007 : 'MSC CARLA III',
  636017016 : 'ANGELIKI',
  636017022 : 'MSC SHIRLEY',
  636017023 : 'MSC RHIANNON',
  636017038 : 'SPECTRUM N',
  636017045 : 'HSL NIKE',
  636017055 : 'MSC MILA 3',
  636017056 : 'MSC HINA',
  636017102 : 'MAERSK SARAT',
  636017103 : 'MAERSK SKARSTIND',
  636017104 : 'MAERSK SHIVLING',
  636017112 : 'CHIQUITA MERCHANT',
  636017116 : 'MSC JANIS 3',
  636017153 : 'SEALAND MANZANILLO',
  636017154 : 'SEALAND LOS ANGELES',
  636017155 : 'SEALAND PHILADELPHIA',
  636017164 : 'ARKADIA',
  636017173 : 'MSC CATHERINE VI',
  636017175 : 'ALS KRONOS',
  636017191 : 'MSC GRENADA III',
  636017218 : 'CHIQUITA EXPRESS',
  636017219 : 'CHIQUITA TRADER',
  636017239 : 'MSC SPRING III',
  636017244 : 'MSC KATALIN II',
  636017245 : 'MSC POLO II',
  636017306 : 'MSC LEO VI',
  636017309 : 'GROTON',
  636017346 : 'MSC SHAULA',
  636017352 : 'ROBIN 5',
  636017360 : 'ROBIN 4',
  636017374 : 'CONTSHIP MAX',
  636017404 : 'TYGRA',
  636017405 : 'MYDJIMEI',
  636017408 : 'MSC VENICE',
  636017425 : 'CMA CGM CORNEILLE',
  636017433 : 'MSC DIANA',
  636017434 : 'MSC INGY',
  636017435 : 'MSC ELOANE',
  636017440 : 'CALA PAGURO',
  636017505 : 'LIDIA',
  636017506 : 'MSC JADE',
  636017514 : 'MSC CORNELIA',
  636017515 : 'SOFIA I',
  636017516 : 'MSC MIRJA',
  636017520 : 'GARDINER',
  636017534 : 'MSC JUANITA F',
  636017537 : 'MSC ISTANBUL',
  636017548 : 'MSC AGATA II',
  636017569 : 'MSC DITTE',
  636017577 : 'MSC REEF',
  636017581 : 'MSC ANNICK',
  636017582 : 'MSC SENA',
  636017605 : 'MSC CLEMENTINA F',
  636017608 : 'BOMAR RENAISSANCE',
  636017610 : 'CITRUS VITA BRASIL',
  636017643 : 'KURE',
  636017683 : 'PERITO MORENO',
  636017684 : 'MSC MIRJAM',
  636017685 : 'MSC RIFAYA',
  636017686 : 'MSC LEANNE',
  636017724 : 'KMTC BANGKOK',
  636017726 : 'MSC ERICA',
  636017727 : 'MSC ANNA',
  636017730 : 'CHIQUITA VENTURE',
  636017731 : 'CHIQUITA PROGRESS',
  636017749 : 'MSC RIFAYA',
  636017768 : 'KMTC LAEM CHABANG',
  636017802 : 'GSL ELEFTHERIA',
  636017803 : 'GSL MELINA',
  636017852 : 'CONTSHIP RAY',
  636017867 : 'MSC TINA',
  636017878 : 'MSC IZMIR F',
  636017882 : 'MSC VIVIANA',
  636017894 : 'CONTSHIP SUN',
  636017907 : 'GSL ALICE',
  636017916 : 'MSC TOPAZ',
  636017921 : 'MSC PERLE',
  636017929 : 'MSC SHEFFIELD III',
  636017944 : 'QUEENSLAND',
  636017957 : 'NEWNEW MOON',
  636017958 : 'TB FENGZE',
  636017971 : 'HALLEY',
  636017993 : 'BOMAR PRAIA',
  636017994 : 'TRF PARTICI',
  636017995 : 'PESCARA',
  636018000 : 'CONTSHIP TOP',
  636018001 : 'CONTSHIP UNO',
  636018018 : 'MAERSK KOWLOON',
  636018026 : 'MSC ALICE II',
  636018028 : 'ATHENIAN',
  636018035 : 'AS CASPRIA',
  636018036 : 'MSC SHANVI III',
  636018037 : 'ATHOS',
  636018038 : 'NAVIOS CONSTELLATION',
  636018056 : 'FS IPANEMA',
  636018057 : 'LOTUS A',
  636018058 : 'CYPRESS',
  636018059 : 'KOI',
  636018068 : 'MSC AVNI',
  636018069 : 'NAVIOS UNISON',
  636018070 : 'ARISTOMENIS',
  636018071 : 'MSC ADITI',
  636018075 : 'MSC ROMA',
  636018079 : 'MSC LISBON',
  636018081 : 'BELITAKI',
  636018097 : 'SM MUMBAI',
  636018102 : 'BELITA',
  636018105 : 'MSC BEIRA IV',
  636018106 : 'MSC CARLOTTA',
  636018167 : 'CONTSHIP ECO',
  636018185 : 'CONTSHIP VOW',
  636018189 : 'MSC ROSHNEY V',
  636018191 : 'MSC PORTO III',
  636018192 : 'PORTSMOUTH',
  636018193 : 'SC MONTREAL',
  636018194 : 'SC MONTANA',
  636018204 : 'MSC GIADA III',
  636018222 : 'DELPHINUS C',
  636018223 : 'MARIA C',
  636018224 : 'ACX PEARL',
  636018237 : 'KOTA MANZANILLO',
  636018240 : 'MSC DENMARK VI',
  636018242 : 'MSC SWEDEN VI',
  636018257 : 'CALLIOPE',
  636018262 : 'YM PLUM',
  636018263 : 'YM ORCHID',
  636018264 : 'YM COSMOS',
  636018268 : 'MSC JEANNE',
  636018269 : 'MSC JEANNE',
  636018276 : 'MSC GAYANE',
  636018311 : 'NAVIOS TEMPO',
  636018318 : 'MSC PAOLA',
  636018319 : 'MSC POSITANO',
  636018320 : 'MSC SARAH V',
  636018321 : 'MSC ADRIANA II',
  636018322 : 'MSC ASLI',
  636018323 : 'MSC CAITLIN',
  636018324 : 'MSC EDITH II',
  636018325 : 'MSC LEA',
  636018326 : 'MSC MIA SUMMER',
  636018335 : 'MSC PAOLA',
  636018346 : 'POLAR CHILE',
  636018364 : 'NAVIOS CHRYSALIS',
  636018378 : 'GSL MERCER',
  636018380 : 'BEETHOVEN',
  636018382 : 'BIG DOG',
  636018385 : 'MSC NATASHA XIII',
  636018389 : 'MSC NITA',
  636018471 : 'ZIM BALTIMORE',
  636018490 : 'MSC VANQUISH II',
  636018491 : 'MSC KAYLA',
  636018502 : 'AS ALVA',
  636018523 : 'POLAR PERU',
  636018529 : 'SEOUL GLOW',
  636018541 : 'CMA CGM MISSISSIPPI',
  636018542 : 'CMA CGM MISSOURI',
  636018553 : 'GSL ROSSI',
  636018560 : 'CHARM C',
  636018561 : 'MSC HUSUM III',
  636018562 : 'MSC PALATIUM III',
  636018563 : 'NAVIOS DORADO',
  636018564 : 'CMA CGM CONGO',
  636018571 : 'MSC EMMA',
  636018572 : 'MSC ELISA XIII',
  636018573 : 'MSC CLAUDIA',
  636018593 : 'MSC HERMES',
  636018594 : 'MSC ARIA III',
  636018597 : 'HAPPY LUCKY',
  636018603 : 'MSC TANIA',
  636018659 : 'MSC NOA',
  636018660 : 'MSC ANTONIA',
  636018680 : 'PUTNAM',
  636018693 : 'GSL VALERIE',
  636018700 : 'THALASSA MANA',
  636018701 : 'THALASSA AXIA',
  636018702 : 'THALASSA TYHI',
  636018703 : 'THALASSA DOXA',
  636018704 : 'CONTSHIP ZEN',
  636018712 : 'CONTSHIP CUB',
  636018715 : 'BUXMELODY',
  636018729 : 'MSC EXPRESS III',
  636018732 : 'MSC EMDEN III',
  636018739 : 'BACH',
  636018744 : 'AS ALEXANDRIA',
  636018745 : 'AS ANITA',
  636018768 : 'HANSA HOMBURG',
  636018769 : 'FITZ ROY',
  636018800 : 'KARLSKRONA',
  636018807 : 'DEBUSSY',
  636018846 : 'ELA',
  636018863 : 'PACIFIC QINGDAO',
  636018904 : 'NAVIOS MIAMI',
  636018912 : 'KLEVEN',
  636018931 : 'SEATRADE GREEN',
  636018933 : 'KOTKA',
  636018964 : 'MSC YORK VII',
  636019021 : 'MSC BIANCA',
  636019053 : 'CONTSHIP FUN',
  636019057 : 'SKY SUNSHINE',
  636019058 : 'NAVIOS BAHAMAS',
  636019061 : 'CARMEL I',
  636019088 : 'SM YANTIAN',
  636019163 : 'CONTSHIP GIN',
  636019174 : 'EVER GENTLE',
  636019197 : 'ETE N',
  636019206 : 'MSC JOSSELINE',
  636019207 : 'MSC JEWEL',
  636019213 : 'MSC FAITH',
  636019214 : 'MSC KANOKO',
  636019215 : 'MSC ALIYA',
  636019221 : 'CONTSHIP RUN',
  636019225 : 'CONTSHIP SEA',
  636019234 : 'EVER GLORY',
  636019245 : 'CONTSHIP DON',
  636019256 : 'LASALLE',
  636019289 : 'MSC HARMONY III',
  636019292 : 'MSC ABIGAIL F',
  636019331 : 'MSC QINGDAO',
  636019332 : 'MSC TIANJIN',
  636019336 : 'FSL SINGAPORE',
  636019337 : 'FSL KELANG',
  636019341 : 'ROB',
  636019342 : 'GSL ELENI',
  636019348 : 'MSC AINO',
  636019355 : 'ZIM TAMPA',
  636019364 : 'MSC TAMISHKA F',
  636019366 : 'GSL SUSAN',
  636019395 : 'MSC ENGLAND',
  636019407 : 'GSL NINGBO',
  636019425 : 'GALLOWAY',
  636019434 : 'MSC ROWAN',
  636019443 : 'MSC RESILIENT III',
  636019446 : 'ALOPO',
  636019493 : 'GSL GRANIA',
  636019507 : 'X-PRESS ANGLESEY',
  636019515 : 'BOSTON TRADER',
  636019523 : 'LISA',
  636019532 : 'CONTSHIP JET',
  636019536 : 'GSL KALLIOPI',
  636019562 : 'CONTSHIP ACE',
  636019574 : 'CALYPSO',
  636019582 : 'MSC ARICA',
  636019587 : 'X PRD3S BARDSEY',
  636019606 : 'VIVALDI',
  636019617 : 'X PRESS BARDSEY',
  636019647 : 'ADMIRAL GALAXY',
  636019671 : 'FAR EAST CHEER',
  636019673 : 'GSL NICOLETTA',
  636019697 : 'GREEN ACE',
  636019698 : 'ASIAN ACE',
  636019700 : 'MAERSK HAI PHONG',
  636019716 : 'VELA',
  636019717 : 'VOLANS',
  636019718 : 'VULPECULA',
  636019746 : 'CONTSHIP MED',
  636019759 : 'MYNY',
  636019760 : 'GSL CHRISTEN',
  636019762 : 'MSC DIYA F',
  636019770 : 'MANET',
  636019794 : 'LORI',
  636019821 : 'MAERSK KWANGYANG',
  636019825 : 'LYON II',
  636019841 : 'MSC NILA II',
  636019861 : 'MSC IDA II',
  636019862 : 'KETA',
  636019863 : 'JULIE',
  636019864 : 'AKITETA',
  636019868 : 'CMA CGM BERLIOZ',
  636019875 : 'KOTA SANTOS',
  636019887 : 'MSC ANDREA F',
  636019892 : 'YM CELEBRITY',
  636019893 : 'YM CONTINENT',
  636019894 : 'YM CREDENTIAL',
  636019895 : 'YM CENTENNIAL',
  636019896 : 'YM CAPACITY',
  636019897 : 'YM CERTAINTY',
  636019898 : 'YM CREDIBILITY',
  636019899 : 'YM CONSTANCY',
  636019900 : 'YM COOPERATION',
  636019901 : 'YM CONTINUITY',
  636019931 : 'FLEUR N',
  636019982 : 'YM TRIUMPH',
  636019983 : 'YM TRUTH',
  636019984 : 'YM TOTALITY',
  636019985 : 'YM TARGET',
  636019986 : 'YM TIPTOP',
  636020153 : 'JONATHAN P',
  636020157 : 'ZEBRA',
  636020168 : 'SAN AMERIGO',
  636020191 : 'SAN ALFONSO',
  636020192 : 'SAN ALBERTO',
  636020198 : 'MSC CANCUN',
  636020202 : 'SCORPIUS',
  636020213 : 'KMTC YOKOHAMA',
  636020215 : 'MSC JESSENIA R',
  636020220 : 'KMTC XIAMEN',
  636020221 : 'KMTC SHIMIZU',
  636020226 : 'SEABOARD EXPLORER',
  636020240 : 'BLACK PEARL',
  636020252 : 'LONG BEACH EXPRESS',
  636020254 : 'KMTC DALIAN',
  636020257 : 'SEATTLE EXPRESS',
  636020258 : 'FOS EXPRESS',
  636020265 : 'TB BRIGHT CITY',
  636020266 : 'ZOI',
  636020280 : 'ADMIRAL MARS',
  636020307 : 'CONTSHIP KEY',
  636020326 : 'BREMEN',
  636020327 : 'C HAMBURG',
  636020332 : 'HMM NURI',
  636020334 : 'MSC SHRISTI',
  636020343 : 'MSC PANAYA',
  636020345 : 'NEOKASTRO',
  636020348 : 'TONSBERG',
  636020349 : 'MARIANNA I',
  636020350 : 'EPAMINONDAS',
  636020351 : 'ZIM HAIFA',
  636020352 : 'SONGA PUMA',
  636020353 : 'ROBIN 2',
  636020354 : 'MSC PASSION III',
  636020360 : 'MSC MALENA',
  636020361 : 'MENDELSSOHN',
  636020371 : 'EVER FAR',
  636020383 : 'HMM GAON',
  636020384 : 'HMM GARAM',
  636020401 : 'OKEE CUNO',
  636020436 : 'NATAL',
  636020437 : 'IRENES RAY',
  636020438 : 'CHOPIN',
  636020440 : 'MSC KANU F',
  636020454 : 'ELIZABETH',
  636020455 : 'CINDY',
  636020456 : 'EVER FAST',
  636020468 : 'MSC DAISY',
  636020475 : 'ARIES',
  636020476 : 'ARGUS',
  636020483 : 'STAMATIS B',
  636020494 : 'MSC TEXAS',
  636020495 : 'MSC TIANPING',
  636020497 : 'MSC TOKYO',
  636020498 : 'MSC YOKOHAMA',
  636020499 : 'MSC VANCOUVER',
  636020500 : 'MSC LIDIA',
  636020501 : 'MSC TIANSHAN',
  636020516 : 'PAFILIA',
  636020524 : 'BIGLI',
  636020532 : 'MUNKSUND',
  636020533 : 'TUNADAL',
  636020536 : 'EVER FAIR',
  636020539 : 'TORO',
  636020542 : 'MSC APOLLINE',
  636020543 : 'MSC AMELIA',
  636020574 : 'MSC CHARLOTTE',
  636020581 : 'ADMIRAL SUN',
  636020582 : 'ADMIRAL MOON',
  636020583 : 'GLEN CANYON',
  636020585 : 'CONTSHIP LEX',
  636020589 : 'GIALOVA',
  636020595 : 'GSL MAREN',
  636020598 : 'MSC DILETTA',
  636020599 : 'MSC MICHELLE',
  636020600 : 'MSC ALLEGRA',
  636020609 : 'YOGI',
  636020610 : 'ANDROUSA',
  636020615 : 'HMM MIR',
  636020646 : 'VASI STAR',
  636020648 : 'ZIM IBERIA',
  636020659 : 'PINOCCHIO',
  636020661 : 'SC PHILLY',
  636020662 : 'SC POTOMAC',
  636020663 : 'SC PHOENIX',
  636020666 : 'SC MARIGOT',
  636020667 : 'SC MEDFORD',
  636020668 : 'SC MEMPHIS',
  636020684 : 'EVER FEAT',
  636020695 : 'MSC EMILIE',
  636020700 : 'MSC SAMU',
  636020701 : 'MSC KYMEA II',
  636020702 : 'MSC TIPHAINE II',
  636020707 : 'HMM HANBADA',
  636020712 : 'MSC DAR ES SALAAM 3',
  636020728 : 'MSC VILDA X',
  636020729 : 'MSC ELLEN',
  636020730 : 'MSC ABY',
  636020743 : 'HMM DAON',
  636020744 : 'HMM HANUL',
  636020746 : 'HMM RAON',
  636020758 : 'BERTIE',
  636020769 : 'GSL DOROTHEA',
  636020770 : 'GSL TEGEA',
  636020772 : 'GSL ARCADIA',
  636020773 : 'GSL MYNY',
  636020774 : 'GSL MELITA',
  636020775 : 'GSL MARIA',
  636020776 : 'GSL VIOLETTA',
  636020781 : 'MSC LANGSAR',
  636020782 : 'MSC SHAHAR',
  636020783 : 'MSC MATTINA',
  636020787 : 'STAR',
  636020811 : 'MSC DHANTIA F',
  636020813 : 'ZAGOR',
  636020814 : 'CONTSHIP ANA',
  636020824 : 'NORFOLK',
  636020846 : 'POLYNESIA',
  636020868 : 'PORTO KAGIO',
  636020869 : 'PORTO CHELI',
  636020870 : 'PORTO GERMENO',
  636020871 : 'MSC RIONA',
  636020878 : 'AEOLUS',
  636020886 : 'HOLSATIA',
  636020898 : 'MSC DORINE',
  636020899 : 'MSC MARIA CLARA',
  636020902 : 'MSC NIKOLETA II',
  636020910 : 'KONRAD',
  636020918 : 'MSC ANAHITA',
  636020919 : 'MSC TIANA F',
  636020928 : 'MAERSK PANGANI',
  636020930 : 'MSC PATNAREE III',
  636020936 : 'MSC BREMEN',
  636020937 : 'MSC YURIDA III',
  636020938 : 'VELIKA',
  636020939 : 'SOUNION TRADER',
  636020941 : 'MIRADOR EXPRESS',
  636020949 : 'MSC MANASA F',
  636020952 : 'MSC SAMIRA III',
  636020953 : 'MSC ELIZABETH III',
  636020959 : 'SKYVIEW',
  636020962 : 'MSC ANA CAMILA III',
  636020978 : 'MSC TRADER II',
  636020979 : 'MSC KUMSAL',
  636020983 : 'GSL KITHIRA',
  636020984 : 'GSL TINOS',
  636020985 : 'GSL SYROS',
  636020986 : 'GSL TRIPOLI',
  636020990 : 'ADDISON',
  636020991 : 'HALSTED',
  636020992 : 'ARCHER',
  636020994 : 'EVER FASHION',
  636021002 : 'HAKUNA MATATA',
  636021004 : 'GSL LALO',
  636021005 : 'MATSON MOLOKAI',
  636021006 : 'GSL ELIZABETH',
  636021011 : 'MSC RINI III',
  636021012 : 'MSC CAROLE',
  636021013 : 'SFL MAUI',
  636021017 : 'MSC OLGA F',
  636021031 : 'MSC VAIGA III',
  636021035 : 'SAVANNAH EXPRESS',
  636021036 : 'THALASSA ELPIDA',
  636021040 : 'SFL HAWAII',
  636021055 : 'ANTIBES EXPRESS',
  636021056 : 'SPARTEL TRADER',
  636021061 : 'MSC NAISHA III',
  636021078 : 'MSC AZURIT F',
  636021079 : 'MSC SIGMA F',
  636021093 : 'SUSTAINABLE EARTH',
  636021094 : 'HARMONIZED EARTH',
  636021095 : 'INTEGRATIVE EARTH',
  636021096 : 'PROACTIVE EARTH',
  636021097 : 'REGAINING EARTH',
  636021101 : 'RANTANPLAN',
  636021104 : 'UGL HONGKONG',
  636021108 : 'SEABREEZE',
  636021122 : 'MSC RAYSHMI',
  636021123 : 'MSC ALANYA',
  636021128 : 'MSC NASSAU',
  636021129 : 'MSC YANG R',
  636021136 : 'EVER CERTAIN',
  636021139 : 'MSC TARA III',
  636021140 : 'X-PRESS MONTE ROSA',
  636021142 : 'MIZAR',
  636021150 : 'MSC CORDELIA III',
  636021151 : 'MSC MUNDRA VIII',
  636021153 : 'MSC REET II',
  636021154 : 'MSC SOTIRIA III',
  636021155 : 'MSC MUMBAI VIII',
  636021160 : 'MSC SHANGHAI V',
  636021161 : 'MSC HONG KONG V',
  636021166 : 'ALIOTH',
  636021184 : 'JAGUAR',
  636021190 : 'EVER FOND',
  636021200 : 'EVER COMMAND',
  636021202 : 'STONEWELL GLORY',
  636021229 : 'SONGA LIONESS',
  636021230 : 'MELANESIAN CHIEF',
  636021231 : 'LILA CANADA',
  636021234 : 'SONGA LEOPARD',
  636021244 : 'YM TOGETHER',
  636021246 : 'MSC PAMIRA III',
  636021247 : 'MSC MOON F',
  636021249 : 'MSC JENNIFER II',
  636021250 : 'MSC ANDRIANA III',
  636021251 : 'MSC ADONIS',
  636021252 : 'MSC BALTIC III',
  636021256 : 'SURABAYA VOYAGER',
  636021266 : 'SIMBA',
  636021289 : 'MARSA ZENITH',
  636021296 : 'NINA A',
  636021302 : 'MSC GEORGIA II',
  636021306 : 'TIMON',
  636021307 : 'ANITA A',
  636021316 : 'ELI A',
  636021320 : 'SHIJING',
  636021321 : 'UTE',
  636021322 : 'ELBE',
  636021323 : 'MOANA',
  636021329 : 'PUMBA',
  636021337 : 'MAERSK CAMBRIDGE',
  636021338 : 'MAERSK CAMDEN',
  636021339 : 'MAERSK CAMPBELL',
  636021356 : 'MSC GRETA III',
  636021360 : 'TINA I',
  636021362 : 'ZIM PACIFIC',
  636021363 : 'MSC EUGENIA',
  636021364 : 'MSC CASSANDRE',
  636021365 : 'MSC WASHINGTON',
  636021366 : 'MSC VIRGINIA',
  636021387 : 'EVER FAVOR',
  636021392 : 'DYROS',
  636021393 : 'MSC EVEREST VIII',
  636021396 : 'ZIM AUSTRALIA',
  636021400 : 'MSC ALDI III',
  636021408 : 'MSC FIE X',
  636021412 : 'MSC EMILY II',
  636021427 : 'SEABOARD PIONEER',
  636021438 : 'MSC AMIHAN F',
  636021440 : 'SEABOARD PRIDE',
  636021448 : 'MSC LONG BEACH VI',
  636021449 : 'MSC LOS ANGELES',
  636021462 : 'EVER FULL',
  636021470 : 'MSC MARA',
  636021471 : 'MSC VICTORIA',
  636021472 : 'ZIM ATLANTIC',
  636021476 : 'H CYGNUS',
  636021480 : 'STONEFISH',
  636021484 : 'MSC SAGITTARIUS F',
  636021492 : 'MSC SARYA III',
  636021506 : 'ZIM NEW ZEALAND',
  636021517 : 'MSC ANTWERP III',
  636021529 : 'MSC ALDEBARAN III',
  636021547 : 'TEX',
  636021578 : 'YM TRANQUILITY',
  636021595 : 'EVER ORIGIN',
  636021597 : 'MSC COLETTE III',
  636021614 : 'MSC HAILEY ANN III',
  636021617 : 'H MERCURY',
  636021649 : 'ONE CONTRIBUTION',
  636021654 : 'BFAD PACIFIC',
  636021663 : 'MSC BIANCA SILVIA',
  636021664 : 'ZIM ASIA',
  636021665 : 'MSC BERANGERE',
  636021666 : 'MSC DARLENE',
  636021667 : 'MSC C. MONTAINE',
  636021669 : 'EVER OUTDO',
  636021690 : 'YM TUTORIAL',
  636021693 : 'YM THRONE',
  636021736 : 'BIG BREEZY',
  636021739 : 'ZIM CHINA',
  636021760 : 'MSC TAMPA',
  636021761 : 'MSC SINGAPORE IV',
  636021768 : 'MSC FATMA',
  636021770 : 'MSC AAYA',
  636021771 : 'MSC SOFIA',
  636021776 : 'ALGECIRAS EXPRESS',
  636021790 : 'MSC FORTUNE F',
  636021813 : 'WAN HAI 177',
  636021814 : 'WAN HAI 178',
  636021830 : 'CUL JAKARTA',
  636021831 : 'CUL MANILA',
  636021855 : 'KYOTO TOWER',
  636021857 : 'XIAMEN',
  636021859 : 'MSC TOKATA F',
  636021870 : 'MSC ALBA F',
  636021888 : 'MSC FREEPORT',
  636021913 : 'MSC NISHA V',
  636021914 : 'MSC NORDEROOG F',
  636021955 : 'EVER ONWARD',
  636021978 : 'F LANA',
  636021991 : 'MSC NIMISHA III',
  636021992 : 'MSC SYDNEY VI',
  636021993 : 'MSC DARWIN VI',
  636021994 : 'MSC MICHIGAN VII',
  636021995 : 'MSC ILLINOIS VII',
  636022006 : 'KOTA LIMA',
  636022007 : 'SUEZ CANAL',
  636022022 : 'MSC YUVIKA V',
  636022027 : 'YM TRILLION',
  636022044 : 'DEPE',
  636022046 : 'MSC SOMYA III',
  636022047 : 'MSC SUEDEROOG F',
  636022077 : 'MSC TRACY V',
  636022078 : 'MSC AMEERA III',
  636022102 : 'MSC TESSA',
  636022103 : 'MSC RAYA',
  636022111 : 'MSC KALAMATA VII',
  636022112 : 'ZIM AMERICA',
  636022113 : 'ZIM VIETNAM',
  636022114 : 'NAVARINO',
  636022116 : 'MSC ALMA VII',
  636022118 : 'ULSAN',
  636022173 : 'ASTERIOS',
  636022174 : 'ADAMASTOS',
  636022175 : 'AIAS',
  636022176 : 'AMOUREUX',
  636022177 : 'ARIONAS',
  636022178 : 'ADRASTOS',
  636022181 : 'ANDROKLIS',
  636022182 : 'AVIOS',
  636022196 : 'BLOOMING EARTH',
  636022214 : 'MAERSK PHUKET',
  636022215 : 'MAERSK PELEPAS',
  636022261 : 'JPO AQUILA',
  636022277 : 'MSC ORTOLAN II',
  636022293 : 'MSC BENEDETTA XIII',
  636022307 : 'MSC MAKALU III',
  636022328 : 'HISTORY MARIA',
  636022342 : 'MATOYA BAY',
  636022344 : 'MAYA BAY',
  636022345 : 'PALUBAY',
  636022412 : 'SINAR SANUR',
  636022441 : 'MSC DARIA',
  636022442 : 'MSC KAYLEY',
  636022443 : 'MSC CALYPSO',
  636022447 : 'MSC TAYLOR',
  636022448 : 'MSC MONICA CRISTINA',
  636022449 : 'MSC FREYA',
  636022450 : 'MSC AUDREY',
  636022451 : 'MSC MARTINA MARIA',
  636022489 : 'ASL QINGDAO',
  636022495 : 'KAPITAN MASLOV',
  636022497 : 'KAPITAN AFANASYEV',
  636022498 : 'FESCO ASKOLD',
  636022499 : 'FESCO DIOMID',
  636022500 : 'FESCO DALNEGORSK',
  636022501 : 'FESCO SOFIA',
  636022516 : 'MSC CELESTINOMARESCA',
  636022523 : 'EVER OMNI',
  636022572 : 'STARSHIP JUPITER',
  636022573 : 'STARSHIP NEPTUNE',
  636022600 : 'MSC LORETO',
  636022601 : 'MSC IRINA',
  636022603 : 'MSC GEMMA',
  636022604 : 'MSC MICHELCAPPELLINI',
  636022605 : 'MSC NICOLA MASTRO',
  636022606 : 'MSC NOA ARIELA',
  636022611 : 'MSC CARPATHIA III',
  636022628 : 'SINAR SIGLI',
  636022632 : 'MSC GENERAL IV',
  636022635 : 'HUTTON',
  636022636 : 'HAMMONIA BEROLINA',
  636022640 : 'SONGA PANTHER',
  636022657 : 'MSC DOMNA X',
  636022658 : 'MSC YUKTA X',
  636022663 : 'KAPITAN SHCHETININA',
  636022664 : 'MOSKVA',
  636022665 : 'SANKT-PETERBURG',
  636022687 : 'G.CROWN',
  636022688 : 'G.DRAGON',
  636022708 : 'MSC PRELUDE V',
  636022765 : 'SEABOARD BLUE',
  636022776 : 'CHIQUITA VOYAGER',
  636022787 : 'CA TOKYO',
  636022795 : 'MSC NIOVI VIII',
  636022799 : 'MOL PRESENCE',
  636022809 : 'AKHISAR',
  636022812 : 'MSC LIPSIA III',
  636022814 : 'LECANGS DOLPHIN',
  636022815 : 'SINAR SIANTAR',
  636022865 : 'OVP TAURUS',
  636022871 : 'MSC ADU V',
  636022873 : 'MSC ATHENS',
  636022875 : 'MSC ATHOS',
  636022879 : 'MSC CHIYO',
  636022889 : 'ONE INNOVATION',
  636022893 : 'CHIQUITA EXPLORER',
  636022899 : 'CAPE BRUNO',
  636022903 : 'PELION',
  636022920 : 'MSC MARIELLA',
  636022921 : 'MSC CLAUDE GIRARDET',
  636022941 : 'MSC SURABAYA VIII',
  636022942 : 'MSC GIOVANNA VII',
  636022963 : 'ONE INTEGRITY',
  636022973 : 'SEABOARD GLOBE',
  636022977 : 'LILA HAREN',
  636022978 : 'SILK',
  636022980 : 'MSC UMA',
  636022984 : 'MSC UNIFIC VI',
  636022985 : 'MSC FLORIANA 6',
  636023002 : 'ONE INFINITY',
  636023015 : 'SINAR SABA',
  636023016 : 'MSC TIA II',
  636023036 : 'BMI EAGLE',
  636023055 : 'TJ ORHAN',
  636023058 : 'ARTHUR MAERSK',
  636023059 : 'GSL EFFIE',
  636023060 : 'GSL ALEXANDRA',
  636023061 : 'GSL SOFIA',
  636023062 : 'ALBERT MAERSK',
  636023063 : 'GSL LYDIA',
  636023065 : 'MSC PILAR VI',
  636023074 : 'ZIM SPARROW',
  636023083 : 'MSC VALENTINA',
  636023084 : 'MSC METTE',
  636023087 : 'MSC CHINA',
  636023098 : 'MSC MICOL',
  636023099 : 'MSC TURKIYE',
  636023100 : 'MSC JIANI',
  636023101 : 'MSC VIVIENNE',
  636023126 : 'XH DOLPHIN',
  636023127 : 'ELONA II',
  636023142 : 'VICTORIA FORTUNE',
  636023143 : 'ELIZABETH FORTUNE',
  636023145 : 'MSC MAGNUM VII',
  636023157 : 'MSC ANCONA III',
  636023159 : 'MSC TURIN II',
  636023175 : 'ZIM EAGLE',
  636023185 : 'MSC PAXI II',
  636023213 : 'MSC LYSE V',
  636023243 : 'LITTLE EMMA',
  636023250 : 'YM WELLSPRING',
  636023251 : 'YM WELLBEING',
  636023252 : 'YM WARRANTY',
  636023265 : 'MSC ANITA',
  636023266 : 'MSC VICTORINE',
  636023267 : 'MSC CANDIDA',
  636023268 : 'MSC GIUSY',
  636023273 : 'MSC REN V',
  636023280 : 'NEW EVERPROSPER',
  636023290 : 'ONE INGENUITY',
  636023293 : 'CMA CGM MERCANTOUR',
  636023303 : 'CMA CGM KRUGER',
  636023306 : 'MSC IRA II',
  636023310 : 'ONE INTELLIGENCE',
  636023312 : 'MSC NAHARA',
  636023342 : 'OOCL BREMERHAVEN',
  636023346 : 'NAVIOS DOMINO',
  636023348 : 'ONE INSPIRATION',
  636023371 : 'SONGA JACKAL',
  636023398 : 'MSC ORSOLA',
  636023415 : 'KAPITAN ABONOSIMOV',
  636023420 : 'MSC MAGNITUDE VII',
  636023421 : 'MSC NAIROBI X',
  636023433 : 'LOG-IN EVOLUTION',
  636023449 : 'CONTSHIP BOX',
  636023450 : 'DA BAO',
  636023461 : 'FIONA',
  636023491 : 'MSC POLONIA III',
  636023534 : 'WAN HAI 612',
  636023535 : 'WAN HAI 613',
  636023562 : 'MSC THAIS',
  636023564 : 'MSC ROSE',
  636023581 : 'YM WONDERLAND',
  636023600 : 'TB QUANZHOU',
  636090636 : 'HANSA RENDSBURG',
  636090654 : 'NORTHERN DECENCY',
  636090756 : 'HANSA BREITENBURG',
  636090799 : 'ANNIE B',
  636090856 : 'LUTETIA',
  636090862 : 'JPO LIBRA',
  636090863 : 'JPO PISCES',
  636090902 : 'CIMBRIA',
  636090967 : 'MONACO',
  636090971 : 'MONTPELLIER',
  636091031 : 'DAPHNE',
  636091088 : 'LETO',
  636091116 : 'SANTA VANESSA',
  636091125 : 'AS PETRA',
  636091130 : 'AS PALINA',
  636091232 : 'MARTINIQUE',
  636091273 : 'NAJADE',
  636091308 : 'BSG BARBADOS',
  636091384 : 'NORTHERN GUARD',
  636091385 : 'NORTHERN GUILD',
  636091400 : 'HANSA HORNEBURG',
  636091401 : 'HANSA WOLFSBURG',
  636091410 : 'VICTORIA L',
  636091448 : 'NORTHERN DEPENDANT',
  636091473 : 'MATSON WAIKIKI',
  636091487 : 'PONTRESINA',
  636091526 : 'NORTHERN DISCOVERY',
  636091527 : 'NORTHERN DIAMOND',
  636091575 : 'HANSA AUGSBURG',
  636091619 : 'ELBSPIRIT',
  636091654 : 'SEABOARD RANGER',
  636091717 : 'NORTHERN PRACTISE',
  636091731 : 'SEABOARD OCEAN',
  636091744 : 'ESL WASL',
  636091765 : 'JACK LONDON',
  636091766 : 'JONATHAN SWIFT',
  636091783 : 'NORTHERN DIPLOMAT',
  636091784 : 'NORTHERN DEMOCRAT',
  636091785 : 'BUXLINK',
  636091823 : 'HANSA RATZEBURG',
  636091835 : 'NORTHERN POWER',
  636091848 : 'BUXFAVOURITE',
  636091883 : 'MERKUR ARCHIPELAGO',
  636091916 : 'PORTO',
  636091959 : 'MSC SAVONA',
  636091995 : 'JPO GEMINI',
  636092040 : 'OPS HAMBURG',
  636092101 : 'JAN RITSCHER',
  636092140 : 'TIGER',
  636092181 : 'HAMMONIA BALTICA',
  636092188 : 'MSC BARI',
  636092240 : 'MASTERY D',
  636092247 : 'MSC TARANTO',
  636092268 : 'MSC RAVENNA',
  636092270 : 'MSC MADRID',
  636092271 : 'MSC RAPALLO',
  636092391 : 'MSC MONTEREY',
  636092424 : 'JAKARTA EXPRESS',
  636092477 : 'HANSA FRESENBURG',
  636092500 : 'MOMBASA EXPRESS',
  636092570 : 'HANSA STEINBURG',
  636092572 : 'HANSA ROTENBURG',
  636092574 : 'HANSA SIEGBURG',
  636092635 : 'MAIKE D',
  636092678 : 'TOLTEN',
  636092679 : 'CHACABUCO',
  636092680 : 'TIRUA',
  636092681 : 'PALENA',
  636092682 : 'MEHUIN',
  636092683 : 'ALGOL',
  636092696 : 'DACHAN BAY EXPRESS',
  636092697 : 'ARICA EXPRESS',
  636092708 : 'SEATRADE ORANGE',
  636092711 : 'SEATRADE RED',
  636092712 : 'SEATRADE WHITE',
  636092713 : 'SEATRADE BLUE',
  636092764 : 'MSC RUBY',
  636092765 : 'ALS APOLLO',
  636092766 : 'MSC PATNAREE III',
  636092775 : 'COPIAPO',
  636092778 : 'BELLAVIA',
  636092780 : 'TEMPANOS',
  636092781 : 'TENO',
  636092782 : 'TORRENTE',
  636092783 : 'TUBUL',
  636092784 : 'TUCAPEL',
  636092786 : 'CAUQUENES',
  636092792 : 'CAUTIN',
  636092793 : 'COCHRANE',
  636092798 : 'CORCOVADO',
  636092799 : 'CISNES',
  636092810 : 'HANSA FREYBURG',
  636092814 : 'COYHAIQUE',
  636092824 : 'AS SVENJA',
  636092825 : 'AS SAMANTA',
  636092826 : 'AS SABRINA',
  636092827 : 'AS SUSANNA',
  636092837 : 'AS FREYA',
  636092840 : 'AS FENJA',
  636092848 : 'MAIPO',
  636092854 : 'TORRES STRAIT',
  636092855 : 'TASMAN STRAIT',
  636092860 : 'AS PAOLA',
  636092863 : 'AS PENELOPE',
  636092864 : 'AS PAULINE',
  636092866 : 'ALS CLIVIA',
  636092869 : 'HANSA EUROPE',
  636092878 : 'RDO FORTUNE',
  636092879 : 'RDO FAVOUR',
  636092880 : 'RAINER D',
  636092883 : 'CONTI ANNAPURNA',
  636092884 : 'CONTI DARWIN',
  636092887 : 'PARIS II',
  636092895 : 'ATACAMA',
  636092896 : 'CCNI ANGOL',
  636092897 : 'CCNI ARAUCO',
  636092898 : 'CCNI ANDES',
  636092906 : 'ANL KOKODA',
  636092910 : 'CMACGM SAN FRANCISCO',
  636092911 : 'RDO ENDEAVOUR',
  636092914 : 'OKEE ALICIA',
  636092919 : 'RDO CONCORD',
  636092927 : 'CONTI MAKALU',
  636092930 : 'CHENNAI EXPRESS',
  636092932 : 'MSC ILONA',
  636092949 : 'MV HANSA FLENSBURG',
  636092953 : 'ZIM PUSAN',
  636092974 : 'HANSA LANKA',
  636092976 : 'MSC ALESSIA',
  636092984 : 'PONA',
  636092986 : 'LUANDA EXPRESS',
  636092995 : 'CHIQUITA CENTURY',
  636092997 : 'HANSA HARBURG',
  636092998 : 'MERKUR HORIZOND',
  636093006 : 'MAERSK NORBERG',
  636093009 : 'MAERSK NACKA',
  636093010 : 'MAERSK NASSJO',
  636093018 : 'THALASSA NIKI',
  636093026 : 'MERKUR HORIZON',
  636093027 : 'BSG BONAIRE',
  636093030 : 'BSG BIMINI',
  636093031 : 'BSG BAHAMAS',
  636093041 : 'CONTI CONQUEST',
  636093042 : 'CONTI COURAGE',
  636093051 : 'BUXWAVE',
  636093054 : 'AS CONSTANTINA',
  636093055 : 'AS SERENA',
  636093069 : 'BF GIANT',
  636093081 : 'MATADI EXPRESS',
  636093082 : 'LE HAVRE EXPRESS',
  636093083 : 'TEMA EXPRESS',
  636093085 : 'OKEE GUSTAV',
  636093086 : 'OKEE HENRI',
  636093093 : 'CONTI CHIVALRY',
  636093094 : 'CONTI CRYSTAL',
  636093095 : 'WARNOW MASTER',
  636093100 : 'SUAPE EXPRESS',
  636093108 : 'DOUALA EXPRESS',
  636093114 : 'LYME BAY',
  636093117 : 'KALAHARI EXPRESS',
  636093121 : 'DALLAS EXPRESS',
  636093131 : 'SEOUL EXPRESS',
  636093132 : 'AMSTERDAM EXPRESS',
  636093137 : 'AL JASRAH',
  636093139 : 'JAZAN',
  636093140 : 'AL MANAMAH',
  636093143 : 'DUBAI EXPRESS',
  636093145 : 'TOKYO EXPRESS',
  636093146 : 'KYOTO EXPRESS',
  636093148 : 'TANGIER EXPRESS',
  636093149 : 'AL QIBLA EXPRESS',
  636093151 : 'HANOVER EXPRESS',
  636093154 : 'NINGBO EXPRESS',
  636093155 : 'DALIAN EXPRESS',
  636093156 : 'BREMEN EXPRESS',
  636093157 : 'ALEXANDER L',
  636093158 : 'RDO ACE',
  636093159 : 'AS CLAUDIA',
  636093160 : 'AL SAFAT',
  636093161 : 'YANTIAN EXPRESS',
  636093164 : 'AL RIFFA',
  636093166 : 'AL DHAIL',
  636093167 : 'COLOMBO EXPRESS',
  636093169 : 'SALAHUDDIN',
  636093170 : 'KIEL EXPRESS',
  636093171 : 'AL MURABBA',
  636093172 : 'ALULA EXPRESS',
  636093174 : 'JEBEL ALI',
  636093175 : 'TAYMA EXPRESS',
  636093176 : 'UNAYZAH EXPRESS',
  636093177 : 'TSINGTAO EXPRESS',
  636093178 : 'OSAKA EXPRESS',
  636093181 : 'AL MASHRAB',
  636093182 : 'AIN SNAN EXPRESS',
  636093183 : 'FRIDA RUSS',
  636093186 : 'UMM SALAL',
  636093193 : 'BUDAPEST EXPRESS',
  636093194 : 'VIENNA EXPRESS',
  636093197 : 'UASC ZAMZAM',
  636093198 : 'SAN VICENTE EXPRESS',
  636093210 : 'AS SILJE',
  636093211 : 'AS ANNE',
  636093212 : 'AS SABINE',
  636093213 : 'AS STINE',
  636093214 : 'AS SIMONE',
  636093217 : 'SCION MAFALDA',
  636093219 : 'NAGOYA EXPRESS',
  636093220 : 'BF HAMBURG',
  636093224 : 'UAFL LIBERTY',
  636093250 : 'NORTHERN DEXTERITY',
  667001467 : 'VINCENT 2',
  667001869 : 'VLADIVOSTOK',
  667002053 : 'AMANY QUEEN',
  667002216 : 'AB MOLY',
  671274100 : 'KORMORAN',
  671302100 : 'SERRANO',
  671315100 : 'MARGI',
  671319100 : 'JOE1',
  671390100 : 'REYFA',
  671659000 : 'PACIFIC M',
  677020010 : 'S.J. RIMA',
  677020300 : 'WAN LIMA',
  677023500 : 'PSL TIGER',
  677026000 : 'MIREMBE JUDITH',
  677026400 : 'J.PIONEER',
  677026900 : 'S J BOSS',
  677053700 : 'FARAHI 2',
  677060800 : 'AL FILK',
  701104000 : 'ARGENTINOII',
  710000200 : 'MERCOSUL SUAPE',
  710000596 : 'SEBASTIAO CABOTO',
  710000646 : 'PEDRO ALVARES CABRAL',
  710000675 : 'FERNAO DE MAGALHAES',
  710000872 : 'BARTOLOMEU DIAS',
  710001270 : 'MERCOSUL SANTOS',
  710003483 : 'ALIANCA LEBLON',
  710003559 : 'MONTE SARMIENTO',
  710003840 : 'LOG IN PANTANAL',
  710004394 : 'LOG-IN POLARIS',
  710004636 : 'LOG-IN ENDURANCE',
  710006293 : 'LOG-IN DISCOVERY',
  710006297 : 'MSC BELMONTE III',
  710006643 : 'MAERSK JALAN',
  710006930 : 'LOG-IN JACARANDA',
  710009040 : 'LOG-IN JATOBA',
  710027240 : 'MERCOSUL ITAJAI',
  710033540 : 'CMA CGM SANTOS',
  710033550 : 'CMA CGM VERACRUZ',
  710074009 : 'VICENTE PINZON',
  710074011 : 'AMERICO VESPUCIO',
  710098000 : 'LOG-IN RESILIENTE',
  725001534 : 'SAN ANTONIO EXPRESS',
  725001547 : 'ANTOFAGASTA EXPRESS',
  725003474 : 'ISLA TAUTIL',
  725017200 : 'MAPOCHO',
  725017700 : 'CONDOR',
  725019910 : 'COPIHUE',
  776025700 : 'VENUS .B',
  312134000:'BEI JIANG',
  355928000:'NEW GOLDEN BRIDGE V',
  414472000:'XIN XIANG XUE LAN',
  355319000:'BIRYONG',
  412204480:'YA LU JIANG',
  352950000:'XIN YU JIN XIANG',
  354344000:'ORIENTAL PEARL VI',
  356644000:'ORIENTAL PEARL VIII',
  374869000:'HUADONG PEARL 8',
  356828000:'NEW GRAND PEACE',
  441178000:'PANSTAR DREAM',
  440642000:'PANSTAR GENIE',
  441246000:'PANSTAR GENIE NO.2',
  441743000:'SANSTAR DREAM',
  355925000:'RIZHAO ORIENT',
  538005877:'ARAFURA LILY',
  441163000:'SEONG HEE',
  431446000:'HAMAYUU',
  431602232:'NEW CAMELLIA',
  372009000:'EASTERN DREAM',
  352808000:'HAE SHIN',
  355297000:'SANG SHIN',
  356005000:'YOUNG SHIN',
  373817000:'GMT ASTRO',
  357170000:'AH SHIN',
  352001129:'SOO SHIN',
  352002302:'WON SHIN',
  352002303:'CHANG SHIN',

};

let shipNameList = {
  'NEGO AIUB' : '200000000',
  'CMA CGM MASAI MARA' : '205178000',
  'A LA MARINE' : '205795000',
  'BEATRIZ B' : '209056000',
  'CAPE PIONEER' : '209087000',
  'CONTSHIP WIN' : '209135000',
  'WARNOW CHIEF' : '209138000',
  'WARNOW MATE' : '209140000',
  'WEC VERMEER' : '209177000',
  'BG SAPPHIRE' : '209247000',
  'BG EMERALD' : '209248000',
  'PUSAN C' : '209251000',
  'DUBAI FORTUNE' : '209258000',
  'ELBWAVE' : '209276000',
  'CONTSHIP PRO' : '209335000',
  'CONTSHIP NEW' : '209345000',
  'SAMSKIP INNOVATOR' : '209361000',
  'ST. MARY' : '209370000',
  'SAMSKIP ENDEAVOUR' : '209380000',
  'MSC DARDANELLES' : '209423000',
  'WARNOW BOATSWAIN' : '209425000',
  'BLACK RHINO' : '209437000',
  'CONTSHIP FOX' : '209444000',
  'CONTSHIP ERA' : '209459000',
  'X-PRESS AGILITY' : '209467000',
  'MSC BOSPHORUS' : '209470000',
  'CONTSHIP ICE' : '209504000',
  'BF CARP' : '209539000',
  'BF TROUT' : '209540000',
  'BF PERCH' : '209541000',
  'THETIS D' : '209543000',
  'AMERICA' : '209550000',
  'MSC APOLLO' : '209559000',
  'ASIAN MOON' : '209570000',
  'CONTSHIP PAX' : '209575000',
  'NILEDUTCH BREDA' : '209576000',
  'CONTSHIP TEN' : '209591000',
  'CONTSHIP VIE' : '209592000',
  'CONTSHIP ZOE' : '209593000',
  'CONTSHIP WAY' : '209594000',
  'BF LETICIA' : '209613000',
  'ATHENS GLORY' : '209615000',
  'NILEDUTCH ANTWERPEN' : '209616000',
  'CONTAINERSHIPSAURORA' : '209659000',
  'CONTSHIP YEN' : '209692000',
  'NORDMAAS' : '209695000',
  'RANGER' : '209696000',
  'CONTSHIP LEO' : '209697000',
  'CONTSHIP LUV' : '209699000',
  'DUBAI ALLIANCE' : '209700000',
  'CONTSHIP AIR' : '209705000',
  'ELBSKIPPER' : '209715000',
  'MSC AQUARIUS' : '209716000',
  'CONTAINERSHIPSARCTIC' : '209717000',
  'BG IRELAND' : '209719000',
  'NCL SALTEN' : '209762000',
  'SYNERGY KEELUNG' : '209795000',
  'SYNERGY OAKLAND' : '209798000',
  'CT ROTTERDAM' : '209836000',
  'GFS PRIME' : '209849000',
  'ANDROMEDA J' : '209850000',
  'WARNOW WHALE' : '209862000',
  'CAPE ALTIUS' : '209888000',
  'CMACGM FORT ST LOUIS' : '209912000',
  'CMA CGM NABUCCO' : '209920000',
  'LE HAVRE' : '209979000',
  'ALASA' : '210001000',
  'JORK RULER' : '210008000',
  'ELBWIND' : '210017000',
  'CONTSHIPS BOREALIS' : '210028000',
  'FALMOUTH' : '210057000',
  'QUEEN B III' : '210065000',
  'CAPE CITIUS' : '210070000',
  'WEC VAN EYCK' : '210072000',
  'EINSTEIN' : '210101000',
  'GFS PEARL' : '210142000',
  'WARNOW DOLPHIN' : '210159000',
  'ELBSPRINTER' : '210167000',
  'CONTSHIPS STELLAR' : '210195000',
  'CAPE CORFU' : '210210000',
  'WEC VAN RIJN' : '210281000',
  'WEC DE HOOGH' : '210283000',
  'QUEEN B II' : '210296000',
  'CAPE HELLAS' : '210308000',
  'WEC MAJORELLE' : '210312000',
  'GERDA' : '210332000',
  'ELBWATER' : '210359000',
  'CONTSHIP EVE' : '210361000',
  'CONTSHIP PEP' : '210367000',
  'CAPE ARAXOS' : '210403000',
  'CONTSHIP SKY' : '210440000',
  'CAPE SYROS' : '210497000',
  'CONTSHIP ART' : '210514000',
  'ASIAN TRADER' : '210565000',
  'ELBRUNNER' : '210568000',
  'SAN GIORGIO' : '210663000',
  'DUBAI ENTERPRISE' : '210696000',
  'MSC ELKE F' : '210731000',
  'LENA' : '210740000',
  'PACIFIC TRADER' : '210749000',
  'NORDLION' : '210804000',
  'ARSOS' : '210905000',
  'VARAMO' : '210950000',
  'IONIA' : '210989000',
  'QUERIN TEST 2' : '211003531',
  'BRUSSELS EXPRESS' : '211108000',
  'RIODEJANEIRO EXPRESS' : '211111000',
  'MONTEVIDEO EXPRESS' : '211112000',
  'BERLIN EXPRESS' : '211119000',
  'HANOI EXPRESS' : '211120000',
  'BIANCA RAMBOW' : '211141000',
  'WEGA' : '211235880',
  'CHIQUITA FARMER' : '211256150',
  'INDEPENDENT SPIRIT' : '211281220',
  'HANNI' : '211286440',
  'CONTAINERSHIPS 6' : '211315100',
  'BASLE EXPRESS' : '211549000',
  'BENEDIKT' : '211553000',
  'ULSAN EXPRESS' : '211578000',
  'NORTHERN MAJESTIC' : '211779000',
  'CHICAGO EXPRESS' : '211839000',
  'CONTAINERSHIPS VIII' : '211845000',
  'NOVA' : '211870240',
  'BARZAN' : '211882920',
  'UMM QARN' : '211882930',
  'AL JMELIYAH' : '211886490',
  'AL ZUBARA' : '211886500',
  'AL NASRIYAH' : '211886810',
  'LINAH' : '211887090',
  'BUENOS AIRES EXPRESS' : '211887410',
  'AFIF' : '211888820',
  'AL DAHNA EXPRESS' : '211895580',
  'AL MURAYKH' : '211897760',
  'JUDITH' : '212006000',
  'ELBWINTER' : '212008000',
  'X-PRESS ELBE' : '212018000',
  'EUROPE' : '212175000',
  'CONTSHIP IVY' : '212267000',
  'SEATTLE C' : '212276000',
  'NORDTIGER' : '212283000',
  'NORDPUMA' : '212289000',
  'HYUNDAI TOKYO' : '212347000',
  'HYUNDAI HONGKONG' : '212348000',
  'HYUNDAI BUSAN' : '212350000',
  'HYUNDAI SINGAPORE' : '212351000',
  'MSC LILOU III' : '212352000',
  'HYUNDAI SHANGHAI' : '212353000',
  'MSC YOSHEEN' : '212357000',
  'VIOLETA B' : '212417000',
  'NORDLEOPARD' : '212433000',
  'CAPE MONTEREY' : '212463000',
  'NORDPANTHER' : '212531000',
  'CAPE FORTIUS' : '212540000',
  'PROTOSTAR N' : '212557000',
  'MARINA ONE' : '212563000',
  'CAPE QUEST' : '212587000',
  'THEA II' : '212597000',
  'RUTH' : '212613000',
  'HANNA' : '212636000',
  'BG DIAMOND' : '212637000',
  'WEC VAN GOGH' : '212641000',
  'NORDAMELIA' : '212659000',
  'VANCOUVER' : '212714000',
  'BF FORTALEZA' : '212723000',
  'SAN LORENZO' : '212724000',
  'BALTIC TERN' : '212748000',
  'BG JADE' : '212752000',
  'WILHELM' : '212777000',
  'NORDPACIFIC' : '212814000',
  'DUBAI VENTURE' : '212851000',
  'BALTIC SHEARWATER' : '212906000',
  'FOUMA' : '212917000',
  'ELBFEEDER' : '212931000',
  'ELBCARRIER' : '212935000',
  'QUEEN B' : '212960000',
  'CAPE ORIENT' : '212962000',
  'WES GESA' : '212963000',
  'MICHIGAN' : '215020000',
  'CMA CGM A. LINCOLN' : '215125000',
  'CMA CGM T.JEFFERSON' : '215126000',
  'CMA CGM CENDRILLON' : '215127000',
  'CMA CGM LYRA' : '215131000',
  'CMA CGM GEMINI' : '215134000',
  'CMA CGM J. MADISON' : '215137000',
  'CMA CGM CORAL' : '215141000',
  'CMA CGM ARISTOTE' : '215146000',
  'CMA CGM AMBER' : '215148000',
  'CMA CGM CORTE REAL' : '215154000',
  'CMA CGM PLATON' : '215157000',
  'CMA CGM MAGELLAN' : '215158000',
  'CMA CGM SWORDFISH' : '215159000',
  'CMA CGM ANDROMEDA' : '215164000',
  'CMA CGM VIRGINIA' : '215165000',
  'ANL WANGARATTA' : '215167000',
  'CMACGM VASCO DE GAMA' : '215173000',
  'CMA CGM BLUE WHALE' : '215175000',
  'GABRIEL A' : '215176000',
  'MARIO A' : '215177000',
  'TEOMAN A' : '215179000',
  'CMA CGM J. ADAMS' : '215181000',
  'CMA CGM NEW JERSEY' : '215183000',
  'CMA CGM LAMARTINE' : '215185000',
  'CMA CGM TARPON' : '215187000',
  'CMA CGM FLORIDA' : '215189000',
  'CMA CGM CALLISTO' : '215192000',
  'CMA CGM B FRANKLIN' : '215195000',
  'CMA CGM CASSIOPEIA' : '215196000',
  'CMA CGM G.WASHINGTON' : '215197000',
  'CMA CGM ZHENG HE' : '215199000',
  'ANL WYONG' : '215206000',
  'CMA CGM KERGUELEN' : '215207000',
  'CMA CGM MAUPASSANT' : '215215000',
  'CMA CGM AQUILA' : '215217000',
  'CMA CGM VON HUMBOLDT' : '215219000',
  'CMACGM GEORG FORSTER' : '215221000',
  'CMA CGM CENTAURUS' : '215225000',
  'CMA CGM WHITE SHARK' : '215230000',
  'CMA CGM GEORGIA' : '215240000',
  'CMA CGM COLUMBA' : '215249000',
  'CMA CGM T.ROOSEVELT' : '215251000',
  'CMA CGM LA SCALA' : '215293000',
  'CMA CGM ARGENTINA' : '215331000',
  'CC FORT ST GEORGES' : '215334000',
  'CMACGMFORT ST PIERR' : '215342000',
  'CMA CGM CHILE' : '215346000',
  'CMA CGM PANAMA' : '215347000',
  'CMA CGM BRAZIL' : '215352000',
  'CMA CGM MEXICO' : '215354000',
  'EXPRESS BLACK SEA' : '215381000',
  'CMACGM FORT ST MARIE' : '215396000',
  'AURETTE A' : '215413000',
  'MARGUERITE A' : '215416000',
  'MICHEL A' : '215417000',
  'VENTO DI TRAMONTANA' : '215418000',
  'CRISTINA A' : '215419000',
  'CINZIA A' : '215421000',
  'YIGITCAN A' : '215424000',
  'VENTO DI SCIROCCO' : '215426000',
  'CMA CGM ATTILA' : '215485000',
  'AKADIMOS' : '215499000',
  'APL QINGDAO' : '215558000',
  'APL SENTOSA' : '215560000',
  'VIRGO' : '215561000',
  'CMA CGM ALASKA' : '215577000',
  'GSL CHATEAU DIF' : '215640000',
  'CMA CGM SAMBHAR' : '215648000',
  'CMA CGM JAMAICA' : '215650000',
  'CMA CGM AMERICA' : '215651000',
  'CMA CGM THALASSA' : '215657000',
  'CNC JUPITER' : '215748000',
  'PORT GDYNIA' : '215761000',
  'CMA CGM TENERE' : '215765000',
  'CMA CGM SCANDOLA' : '215839000',
  'CNC SATURN' : '215842000',
  'CMACGM BOUGAINVILLE' : '215857000',
  'CMA CGM MOZART' : '215878000',
  'CMA CGM NORMA' : '215879000',
  'CMA CGM ROSSINI' : '215880000',
  'CMA CGM BEIRA' : '215891000',
  'CMA CGM KRIBI' : '215922000',
  'CMA CGM LAPEROUSE' : '215930000',
  'CMA CGM C COLOMB' : '215965000',
  'CMA CGM IGUACU' : '215966000',
  'CMA CGM AMERIGO VESP' : '215992000',
  'AL NEFUD' : '218000250',
  'MANILA EXPRESS' : '218006290',
  'TIHAMA' : '218008040',
  'HENNEKE RAMBOW' : '218044000',
  'IDA RAMBOW' : '218098000',
  'KUALA LUMPUR EXPRESS' : '218284000',
  'PRAGUE EXPRESS' : '218361000',
  'FRANKFURT EXPRESS' : '218364000',
  'SOFIA EXPRESS' : '218366000',
  'MSC LA SPEZIA' : '218400000',
  'HONG KONG EXPRESS' : '218426000',
  'SHANGHAI EXPRESS' : '218427000',
  'MSC LIVORNO' : '218441000',
  'ESSEN EXPRESS' : '218474000',
  'LEVERKUSEN EXPRESS' : '218565000',
  'LUDWIGSHAFEN EXPRESS' : '218566000',
  'VERA RAMBOW' : '218627000',
  'ZIM HONG KONG' : '218643000',
  'ZIM NEWARK' : '218650000',
  'DORTMUND EXPRESS' : '218774000',
  'NEW YORK EXPRESS' : '218776000',
  'ANTWERPEN EXPRESS' : '218791000',
  'BEATE' : '218816000',
  'MSC GENOVA' : '218819000',
  'VALPARAISO EXPRESS' : '218833000',
  'POSEN' : '218834000',
  'CALLAO EXPRESS' : '218839000',
  'CMA CGM IVANHOE' : '218844000',
  'CMA CGM ORFEO' : '218845000',
  'MSC CADIZ' : '218847000',
  'CARTAGENA EXPRESS' : '218850000',
  'GUAYAQUIL EXPRESS' : '218851000',
  'MSC VIGO' : '218853000',
  'SANTOS EXPRESS' : '218854000',
  'MAERSKMCKINNEYMOLLER' : '219018271',
  'MAJESTIC MAERSK' : '219018501',
  'MARY MAERSK' : '219018692',
  'MARIE MAERSK' : '219018765',
  'MADISON MAERSK' : '219018864',
  'MAGLEBY MAERSK' : '219018986',
  'MARIBO MAERSK' : '219019094',
  'MARSTAL MAERSK' : '219019139',
  'MATZ MAERSK' : '219019365',
  'MUNKEBO MAERSK' : '219021000',
  'MAERSK EINDHOVEN' : '219027000',
  'NAKSKOV MAERSK' : '219027748',
  'NIMTOFTE MAERSK' : '219027758',
  'NORDAGER MAERSK' : '219027759',
  'NORDBORG MAERSK' : '219027760',
  'NUUK MAERSK' : '219027761',
  'SIUANA ARCTICA' : '219027854',
  'MALERAQ ARCTICA' : '219027908',
  'MAERSK ESSEX' : '219031000',
  'CAROLINE MAERSK' : '219031418',
  'A.P. MOLLER' : '219031419',
  'CARSTEN MAERSK' : '219031426',
  'CORNELIUS MAERSK' : '219031427',
  'CAPE SCOTT' : '219032189',
  'CAPE SPENCER' : '219032229',
  'CAPE SKAGEN' : '219032707',
  'MAERSK EDINBURGH' : '219033000',
  'MANCHESTER MAERSK' : '219034000',
  'MURCIA MAERSK' : '219036000',
  'MANILA MAERSK' : '219038000',
  'MUMBAI MAERSK' : '219039000',
  'MAASTRICHT MAERSK' : '219045000',
  'VOLGA MAERSK' : '219053000',
  'MAERSK EMDEN' : '219056000',
  'SANTA BARBARA' : '219068000',
  'SANTA INES' : '219069000',
  'SANTA URSULA' : '219071000',
  'SANTA CATARINA' : '219072000',
  'SANTA CLARA' : '219075000',
  'SANTA ISABEL' : '219077000',
  'SANTA CRUZ' : '219080000',
  'SANTA RITA' : '219085000',
  'SANTA ROSA' : '219086000',
  'SANTA TERESA' : '219088000',
  'SAN ANTONIO MAERSK' : '219095000',
  'SAN LORENZO MAERSK' : '219096000',
  'SAN AUGUSTIN MAERSK' : '219100000',
  'SAN MARCO MAERSK' : '219101000',
  'SAN NICOLAS MAERSK' : '219102000',
  'SAN RAPHAEL MAERSK' : '219107000',
  'VAYENGA MAERSK' : '219110000',
  'VENTA MAERSK' : '219115000',
  'MAERSK BROWNSVILLE' : '219117000',
  'RHINE MAERSK' : '219126000',
  'RHONE MAERSK' : '219130000',
  'VUOKSI MAERSK' : '219133000',
  'VILNIA MAERSK' : '219136000',
  'MAERSK EVORA' : '219155000',
  'VAGA MAERSK' : '219170000',
  'MAERSK BALTIMORE' : '219196000',
  'MAERSK ELBA' : '219198000',
  'MAERSK EDMONTON' : '219199000',
  'MAERSK BENTONVILLE' : '219204000',
  'MAERSK ESSEN' : '219210000',
  'MAERSK BROOKLYN' : '219215000',
  'MAERSK BOSTON' : '219216000',
  'MAERSK NEWPORT' : '219223000',
  'MAERSK NORFOLK' : '219225000',
  'TUKUMA ARCTICA' : '219304000',
  'SALLY MAERSK' : '219324000',
  'ALEXANDER MAERSK' : '219470000',
  'MAERSK LA PAZ' : '219505000',
  'MAERSK LINS' : '219506000',
  'MAERSK LOTA' : '219508000',
  'MAERSK LEBU' : '219509000',
  'MAERSK LUZ' : '219510000',
  'MAERSK LAGUNA' : '219515000',
  'MAERSK LEON' : '219516000',
  'MAERSK LIMA' : '219526000',
  'MAERSK LAMANAI' : '219527000',
  'MAERSK LANCO' : '219530000',
  'MAERSK LIRQUEN' : '219532000',
  'MAERSK LETICIA' : '219533000',
  'MAERSK LAVRAS' : '219534000',
  'MAERSK LABREA' : '219538000',
  'MAERSK LONDRINA' : '219540000',
  'MAERSK LABERINTO' : '219542000',
  'LAURA MAERSK' : '219543000',
  'MAYVIEW MAERSK' : '219578000',
  'MERETE MAERSK' : '219581000',
  'MORTEN MAERSK' : '219609000',
  'IRENA ARCTICA' : '219612000',
  'MAREN MAERSK' : '219620000',
  'MARGRETHE MAERSK' : '219629000',
  'MARCHEN MAERSK' : '219630000',
  'METTE MAERSK' : '219631000',
  'MARIT MAERSK' : '219632000',
  'MATHILDE MAERSK' : '219646000',
  'MALIK ARCTICA' : '219668000',
  'VISTULA MAERSK' : '219678000',
  'SKAGEN MAERSK' : '219821000',
  'MUNICH MAERSK' : '219835000',
  'MADRID MAERSK' : '219836000',
  'CLIFFORD MAERSK' : '219840000',
  'MARSEILLE MAERSK' : '219854000',
  'MILAN MAERSK' : '219861000',
  'MONACO MAERSK' : '219863000',
  'MOSCOW MAERSK' : '219864000',
  'JEPPESEN MAERSK' : '219953000',
  'JENS MAERSK' : '219974000',
  'JOHANNES MAERSK' : '219982000',
  'JOSEPHINE MAERSK' : '220008000',
  'MOGENS MAERSK' : '220010000',
  'CHARLOTTE MAERSK' : '220059000',
  'CORNELIA MAERSK' : '220061000',
  'COLUMBINE MAERSK' : '220129000',
  'CLEMENTINE MAERSK' : '220164000',
  'OLGA MAERSK' : '220198000',
  'OLUF MAERSK' : '220207000',
  'OLIVIA MAERSK' : '220216000',
  'LARS MAERSK' : '220291000',
  'GUDRUN MAERSK' : '220379000',
  'GRETE MAERSK' : '220397000',
  'GUNVOR MAERSK' : '220413000',
  'GJERTRUD MAERSK' : '220414000',
  'GERD MAERSK' : '220415000',
  'GEORG MAERSK' : '220416000',
  'EMMA MAERSK' : '220417000',
  'ELEONORA MAERSK' : '220477000',
  'ESTELLE MAERSK' : '220478000',
  'EVELYN MAERSK' : '220496000',
  'EBBA MAERSK' : '220497000',
  'ELLY MAERSK' : '220499000',
  'EDITH MAERSK' : '220501000',
  'EUGEN MAERSK' : '220503000',
  'GUSTAV MAERSK' : '220596000',
  'GERDA MAERSK' : '220598000',
  'MARFRET GUYANE' : '226324000',
  'CMA CGM SORBONNE' : '228038360',
  'CMA CGM TOSCA' : '228335900',
  'CMA CGM DALILA' : '228337900',
  'CMA CGM ALMAVIVA' : '228339600',
  'CMA CGM LA TRAVIATA' : '228340900',
  'CMA CGM MEDEA' : '228342900',
  'CMA CGM OTELLO' : '228353600',
  'CMA CGM RIGOLETTO' : '228354600',
  'CMA CGM FORTDEFRANCE' : '228362900',
  'CMA CGM FORT ROYAL' : '228367600',
  'CCFORT SAINT CHARLES' : '228368600',
  'CMA CGM TITUS' : '228368900',
  'CMA CGM ST. LAURENT' : '228369800',
  'CC FORT FLEUR D EPEE' : '228373600',
  'CMACGM JACQUES SAADE' : '228386700',
  'CC CHAMPS ELYSEES' : '228386800',
  'CMA CGM PALAIS ROYAL' : '228394600',
  'CMA CGM LOUVRE' : '228394900',
  'DOUCE FRANCE' : '228395600',
  'CMA CGM RIVOLI' : '228397600',
  'CMA CGM MONTMARTRE' : '228397700',
  'CMA CGM CONCORDE' : '228401800',
  'CMA CGM TROCADERO' : '228402900',
  'CMA CGM SINNAMARY' : '228403700',
  'CMA CGM KOUROU' : '228403800',
  'CMA CGM PATAGONIA' : '228409800',
  'CMA CGM KIMBERLEY' : '228409900',
  'CMA CGM EVERGLADE' : '228410600',
  'CMA CGM GALAPAGOS' : '228410700',
  'CMA CGM GREENLAND' : '228410800',
  'MARIUS' : '228451700',
  'CMA CGM DUTCH HARBOR' : '229054000',
  'CMA CGM CAIMEP' : '229075000',
  'WANDA A' : '229111000',
  'CMA CGM NOUADHIBOU' : '229115000',
  'DIANE A' : '229142000',
  'CMA CGM JULES VERNE' : '229190000',
  'X-PRESS GODAVARI' : '229191000',
  'LION' : '229247000',
  'ETOILE' : '229263000',
  'CMA CGM ST EXUPERY' : '229270000',
  'CMA CGM SAVANNAH' : '229282000',
  'CMA CGM MONTOIR' : '229290000',
  'CMA CGM ZEPHYR' : '229321000',
  'CMA CGM HOPE' : '229326000',
  'CMA CGM DON PASCUALE' : '229331000',
  'CNC BANGKOK' : '229334000',
  'TZINI' : '229338000',
  'CMA CGM MALTA' : '229340000',
  'APL TURKEY' : '229358000',
  'FORT DESAIX' : '229375000',
  'MSC LAUSANNE' : '229384000',
  'CMA CGM UNITY' : '229389000',
  'CMA CGM ISKENDERUN' : '229391000',
  'CMA CGM BALI' : '229393000',
  'CMA CGM LEKKI' : '229394000',
  'MERKUR FJORD' : '229452000',
  'CMA CGM HERMES' : '229454000',
  'CMA CGM VELA' : '229457000',
  'VALOR' : '229458000',
  'CMA CGM DIGNITY' : '229463000',
  'CMA CGM INTEGRITY' : '229464000',
  'CMA CGM LIBERTY' : '229465000',
  'VALUE' : '229467000',
  'SEASMILE' : '229474000',
  'CMA CGM SAIGON' : '229480000',
  'CMA CGM CHOPIN' : '229488000',
  'CMA CGM PUCCINI' : '229489000',
  'CMA CGM BALBOA' : '229528000',
  'VALIANT' : '229541000',
  'VALENCE' : '229548000',
  'SEADREAM' : '229550000',
  'MEGALOPOLIS' : '229556000',
  'MARATHOPOLIS' : '229557000',
  'MERKUR OCEAN' : '229572000',
  'CMA CGM ALIAGA' : '229573000',
  'SEAMASTER' : '229584000',
  'CMA CGM OSIRIS' : '229592000',
  'VANTAGE' : '229598000',
  'ANL WARRNAMBOOL' : '229606000',
  'APL SAIPAN' : '229607000',
  'MSC AZOV' : '229620000',
  'SOFRANA TOURVILLE' : '229621000',
  'MSC TRIESTE' : '229622000',
  'MSC AJACCIO' : '229625000',
  'MSC AMALFI' : '229626000',
  'X-PRESS MULHACEN' : '229630000',
  'CMA CGM CALLAO' : '229633000',
  'CMA CGM PARANAGUA' : '229635000',
  'CMA CGM NEVADA' : '229639000',
  'ARTOTINA' : '229646000',
  'LEONIDIO' : '229648000',
  'KYPARISSIA' : '229649000',
  'SAN PEDRO' : '229657000',
  'SOFRANA SURVILLE' : '229660000',
  'DIMITRIS C' : '229665000',
  'CMA CGM ARICA' : '229680000',
  'CMA CGM VALPARAISO' : '229684000',
  'CMA CGM APOLLON' : '229685000',
  'CMA CGM SYDNEY' : '229699000',
  'CMA CGM MARLIN' : '229705000',
  'APL DANUBE' : '229726000',
  'CMA CGM SAN ANTONIO' : '229734000',
  'CMA CGM FIGARO' : '229736000',
  'CNC LION' : '229742000',
  'CNC TIGER' : '229744000',
  'CMA CGM CAYENNE' : '229747000',
  'CMA CGM MARSEILLE' : '229749000',
  'CMA CGM MOMBASA' : '229750000',
  'CMA CGM ADONIS' : '229752000',
  'CMA CGM PERTH' : '229753000',
  'CMA CGM SEMARANG' : '229756000',
  'CMA CGM ARCTIC' : '229778000',
  'CMA CGM SYMI' : '229779000',
  'CMA CGM MAPUTO' : '229786000',
  'CMA CGM FIDELIO' : '229808000',
  'CMA CGM FUZHOU' : '229819000',
  'CMA CGM LEBU' : '229820000',
  'GALANI' : '229821000',
  'CMA CGM ALEXANDRIA' : '229824000',
  'CMA CGM CEBU' : '229831000',
  'CMA CGM BARRACUDA' : '229842000',
  'CMA CGM MONTREAL' : '229846000',
  'CMA CGM DAVAO' : '229865000',
  'CMA CGM VOLTAIRE' : '229877000',
  'CMA CGM QUELIMANE' : '229914000',
  'CMA CGM NANTONG' : '229916000',
  'CMA CGM ZANZIBAR' : '229917000',
  'CMA CGM DOLPHIN' : '229921000',
  'CMA CGM ELBE' : '229934000',
  'NORDOCELOT' : '229937000',
  'CONTI CORTESIA' : '229945000',
  'CMA CGM LOIRE' : '229947000',
  'ST.JOHN' : '229949000',
  'CMA CGM SEATTLE' : '229955000',
  'CMA CGM PEMBA' : '229956000',
  'JOSITA B' : '229966000',
  'SATIE' : '229978000',
  'CMA CGM TAGE' : '229988000',
  'CMA CGM RHONE' : '229989000',
  'SANTA LOUKIA' : '229993000',
  'CMA CGM PRIDE' : '229997000',
  'LINDA' : '230984000',
  'AILA' : '230985000',
  'SELFOSS' : '231123000',
  'ARNARFELL' : '231355000',
  'HELGAFELL' : '231356000',
  'LAGARFOSS' : '231789000',
  'BRUARFOSS' : '231836000',
  'DETTIFOSS' : '231860000',
  'SCARLET ARROW' : '232008054',
  'SPIRIT OF AUCKLAND' : '232013516',
  'SPIRIT OF HAMBURG' : '232013520',
  'SPIRIT OF MELBOURNE' : '232013521',
  'SPIRIT OF SINGAPORE' : '232013524',
  'SPIRIT OF SYDNEY' : '232013525',
  'HYUNDAI NEPTUNE' : '232018900',
  'HYUNDAI PLUTO' : '232018901',
  'HYUNDAI JUPITER' : '232018919',
  'HYUNDAI SATURN' : '232024771',
  'HYUNDAI EARTH' : '232024772',
  'HYUNDAI MARS' : '232024773',
  'ZENITH LUMOS' : '232029038',
  'ZEPHYR LUMOS' : '232030743',
  'ZEUS LUMOS' : '232031318',
  'ZEAL LUMOS' : '232031320',
  'MAERSK CAMPTON' : '232043367',
  'MAERSK CANDOR' : '232043368',
  'MAERSK CANYON' : '232043372',
  'CONTAINERSHIPS NORD' : '232046824',
  'CONTAINERSHIPS POLAR' : '232046826',
  'CELANDINE' : '232049845',
  'EVER SHINE' : '235009240',
  'EVER SIGMA' : '235009850',
  'EVER SMART' : '235010450',
  'EVER SMILE' : '235010710',
  'TOKYO TOWER' : '235050802',
  'BRIGHTON' : '235061354',
  'COSCO ROTTERDAM' : '235067931',
  'COSCO SHANGHAI' : '235067987',
  'COSCO ANTWERP' : '235068025',
  'COSCO HONGKONG' : '235068029',
  'COSCO FELIXSTOWE' : '235068031',
  'COSCO SINGAPORE' : '235068032',
  'COSCO HAMBURG' : '235068051',
  'EDISON' : '235082896',
  'ERVING' : '235084298',
  'EVER LAMBENT' : '235093569',
  'EVER LASTING' : '235093618',
  'EVER LEADING' : '235093619',
  'EVER LINKING' : '235098383',
  'EVER LEARNED' : '235098885',
  'EVER LENIENT' : '235102677',
  'EVER LIBERAL' : '235102678',
  'EVER LISSOME' : '235102679',
  'EVER LOADING' : '235102681',
  'EVER LIFTING' : '235110737',
  'EVER EAGLE' : '235335000',
  'EVER ENVOY' : '235414000',
  'EVER EXCEL' : '235479000',
  'EVER ELITE' : '235554000',
  'EVER ETHIC' : '235603000',
  'EVER PRIDE' : '235612000',
  'EVER PRIMA' : '235700000',
  'WYBELSUM' : '236465000',
  'EXPRESS BERLIN' : '241311000',
  'HYUNDAI SMART' : '241312000',
  'HYUNDAI SPEED' : '241313000',
  'CIELO DI RABAT' : '242198100',
  'CMA CGM DAKHLA' : '242377400',
  'CMA CGM NADOR' : '242410400',
  'CASABLANCA A' : '242640300',
  'X-PRESS SOUSSE' : '242830300',
  'A2B SPIRIT' : '244150813',
  'A2B AMBITION' : '244170750',
  'FREYA' : '244180000',
  'EA NODDY' : '244184354',
  'NORDICA' : '244201000',
  'ESSENCE' : '244238000',
  'ESCAPE' : '244255000',
  'ESPERANCE' : '244265000',
  'ESPOIR' : '244279000',
  'ENDURANCE' : '244357000',
  'ENFORCER' : '244597000',
  'A2B ENERGY' : '244650437',
  'BERMUDA ISLANDER' : '244820000',
  'SPIRIT' : '244850968',
  'SVEN D' : '244850970',
  'SAMSKIP HOFFELL' : '244860282',
  'A2B FUTURE' : '244870287',
  'EMOTION' : '244874000',
  'MERSIN EXPRESS' : '244990000',
  'ENCOUNTER' : '245078000',
  'ENERGIZER' : '245088000',
  'ENDEAVOR' : '245222000',
  'EMPIRE' : '245258000',
  'A2B PROUD' : '245502000',
  'ANJA' : '245570000',
  'NJORD' : '245872000',
  'ROTRA MARE' : '246051000',
  'VANQUISH' : '246141000',
  'FENJA' : '246191000',
  'ENSEMBLE' : '246255000',
  'ELISABETH' : '246497000',
  'RIJNBORG' : '246530000',
  'OOCL RAUMA' : '246650000',
  'A2B COMFORT' : '246891000',
  'A2B LEADER' : '246899000',
  'ITAL USODIMARE' : '247009600',
  'ITAL UNICA' : '247012800',
  'ITAL UNIVERSO' : '247012900',
  'BURGUNDY' : '247425500',
  'ITAL BONNY' : '247437600',
  'ITAL BONUS' : '247437900',
  'EVER COZY' : '247439600',
  'JOLLY ROSA' : '247483900',
  'CMA CGM PEGASUS' : '248013000',
  'CMA CGM CARL ANTOINE' : '248030000',
  'CMA CGM TITAN' : '248052000',
  'CAPE SOUNIO' : '248089000',
  'CAPE ARTEMISIO' : '248140000',
  'MAERSK JAIPUR' : '248207000',
  'ALIAGA EXPRESS' : '248228000',
  'CMA CGM MUSSET' : '248247000',
  'CMA CGM SUEZ' : '248269000',
  'IRENES RYTHM' : '248270000',
  'EXPRESS ARGENTINA' : '248302000',
  'CMA CGM BELLINI' : '248402000',
  'ELBELLA' : '248422000',
  'CMA CGM LISA MARIE' : '248426000',
  'CMA CGM RABELAIS' : '248430000',
  'RACINE' : '248431000',
  'CMA CGM NERVAL' : '248432000',
  'CMA CGM EIFFEL' : '248455000',
  'C C JACQUES JOSEPH' : '248473000',
  'POLAR BRASIL' : '248556000',
  'EXPRESS FRANCE' : '248572000',
  'EXPRESS BRAZIL' : '248574000',
  'APL ANTWERP' : '248633000',
  'CMA CGM ESTELLE' : '248649000',
  'CMA CGM LOUGA' : '248655000',
  'CMA CGM PREGOLIA' : '248659000',
  'CMA CGM NEVA' : '248660000',
  'APL FLORIDA' : '248699000',
  'CMA CGM JEAN GABRIEL' : '248703000',
  'APL OREGON' : '248711000',
  'APL CALIFORNIA' : '248712000',
  'CMA CGM ABU DHABI' : '248729000',
  'ANL KOKODA' : '248756000',
  'CMA CGM JEAN MERMOZ' : '248758000',
  'CMACGM LOUIS BLERIOT' : '248794000',
  'HANSA DUBURG' : '248819000',
  'EXPRESS SPAIN' : '248846000',
  'CNC VENUS' : '248966000',
  'ATLANTIC GREEN' : '249037000',
  'MERITO' : '249096000',
  'CMA CGM OHIO' : '249167000',
  'CMA CGM NIAGARA' : '249168000',
  'CMA CGM RIO GRANDE' : '249169000',
  'CAPE AKRITAS' : '249202000',
  'RIO GRANDE' : '249253000',
  'CMACGM JACQUESJUNIOR' : '249322000',
  'CMA CGM TANYA' : '249324000',
  'MERVE A' : '249414000',
  'KINGSTON' : '249474000',
  'ZIM MONACO' : '249509000',
  'TRITON' : '249515000',
  'TITAN' : '249558000',
  'X-PRESS SHANNON' : '249570000',
  'DALIAN' : '249675000',
  'TALOS' : '249769000',
  'TAURUS' : '249773000',
  'CAPE KORTIA' : '249783000',
  'THESEUS' : '249808000',
  'CMA CGM LIBRA' : '249819000',
  'ZIM LUANDA' : '249830000',
  'MARGARETE SCHULTE' : '249837000',
  'CMA CGM RODOLPHE' : '249877000',
  'CMA CGM LEO' : '249888000',
  'CMA CGM MOLIERE' : '249917000',
  'CAPE TAINARO' : '249982000',
  'MSC NORA III' : '255618000',
  'NIEVES B' : '255701240',
  'AVA D' : '255705000',
  'AS SELINA' : '255709000',
  'JULIANA' : '255710000',
  'ESL ZANZIBAR' : '255715000',
  'JOST' : '255719000',
  'BG RUBY' : '255771000',
  'BG ONYX' : '255772000',
  'JSP ANNA' : '255775000',
  'CANDELARIA B' : '255784000',
  'MSC TAMPICO' : '255790000',
  'MSC MANZANILLO' : '255792000',
  'MSC ACAPULCO' : '255793000',
  'MSC TUXPAN' : '255796000',
  'MSC VERACRUZ' : '255797000',
  'MSC CAPE III' : '255799000',
  'ATALANTA' : '255801360',
  'DINA TRADER' : '255801780',
  'CONESTE' : '255801940',
  'RUMBA' : '255801970',
  'ESL ASANTE' : '255802340',
  'MSC ANSHIKA VI' : '255802360',
  'MSC ZONDA III' : '255802470',
  'HANSA SALZBURG' : '255802560',
  'WEC BOUMEESTER' : '255802680',
  'FAITH' : '255802760',
  'NORDIC HAMBURG' : '255802790',
  'MSC PARIS' : '255803201',
  'MSC AGAMEMNON' : '255803260',
  'MSC RIKKU' : '255803450',
  'MSC RIDA' : '255803480',
  'MSC LEANDRA V' : '255803590',
  'ARIES J' : '255803640',
  'MSC NEW HAVEN' : '255803670',
  'MSC BRIDGEPORT' : '255803680',
  'MSC GREENWICH' : '255803760',
  'MSC FAIRFIELD' : '255803770',
  'MSC DARIEN' : '255803780',
  'MSC BREMERHAVEN V' : '255804350',
  'BG GREEN' : '255804590',
  'MSC AZRA' : '255805260',
  'MSC ADYA S/T' : '255805270',
  'VICTORIA' : '255805551',
  'AKACIA' : '255805555',
  'ALANA' : '255805557',
  'MSC VALENCIA' : '255805558',
  'WIELAND' : '255805563',
  'MSC BILBAO' : '255805571',
  'JADRANA' : '255805572',
  'JOGELA' : '255805573',
  'NORTHERN VALENCE' : '255805574',
  'MSC VIGOUR III' : '255805575',
  'NORTHERN VIVACITY' : '255805576',
  'ELBSTROM' : '255805577',
  'JPO AQUARIUS' : '255805580',
  'NCL AVEROY' : '255805587',
  'ESL NHAVA SHEVA' : '255805588',
  'HELLA' : '255805589',
  'HERTA' : '255805590',
  'SVENDBORG' : '255805594',
  'BARBARA P' : '255805600',
  'JONA SOPHIE' : '255805601',
  'FERDINANDA S' : '255805603',
  'MACAO' : '255805611',
  'MSC CAROUGE' : '255805617',
  'AS FLORIANA' : '255805622',
  'INDEPENDENT PRIMERO' : '255805626',
  'SANTUCA B' : '255805627',
  'ISABELLA B' : '255805652',
  'VENETIA' : '255805657',
  'MSC ODESSA V' : '255805661',
  'MSC MARITINA V' : '255805664',
  'JOLLY ARGENTO' : '255805666',
  'MV CHICAGO' : '255805676',
  'BUXCOAST' : '255805677',
  'BUXCLIFF' : '255805678',
  'KORNETT' : '255805686',
  'FLORA DELMAS' : '255805703',
  'SEALAND BALBOA' : '255805750',
  'VERONICA B' : '255805755',
  'OSAKA' : '255805764',
  'WEC MONDRIAAN' : '255805775',
  'ESL BUSAN' : '255805776',
  'MSC JENNY II' : '255805777',
  'TABEA' : '255805778',
  'PANDA 001' : '255805780',
  'AMALTHEA' : '255805781',
  'JAN' : '255805788',
  'WEC CORNEILLE' : '255805802',
  'INDEPENDENT FUTURE' : '255805803',
  'UBENA' : '255805810',
  'SOLONG' : '255805837',
  'DREAM' : '255805838',
  'VISITOR' : '255805839',
  'SAMSKIP EXPRESS' : '255805840',
  'MEANDI' : '255805841',
  'MIRROR' : '255805842',
  'PANDA 002' : '255805843',
  'TROUPER' : '255805844',
  'ANDANTE' : '255805845',
  'MOVEON' : '255805846',
  'ESL WINNER' : '255805847',
  'ULANGA' : '255805849',
  'MSC MELTEMI III' : '255805852',
  'MSC JEONGMIN' : '255805854',
  'MSC CHLOE' : '255805855',
  'MSC SIYA B' : '255805856',
  'MSC ANTONELLA' : '255805859',
  'MSC CHANNE' : '255805864',
  'MSC PALAK' : '255805865',
  'MSC ELMA' : '255805866',
  'MSC ROMANE' : '255805867',
  'MSC DESIREE' : '255805868',
  'MSC GIULIA' : '255805869',
  'CARLOTA B' : '255805878',
  'PIRITA' : '255805883',
  'NAVI BALTIC' : '255805884',
  'LAURA S' : '255805890',
  'MSC BRANKA' : '255805891',
  'MSC SASHA' : '255805897',
  'ANINA' : '255805899',
  'CT PACHUCA' : '255805903',
  'EDITH' : '255805906',
  'MSC ELBE III' : '255805922',
  'MSC WESER' : '255805923',
  'MSC MICHELA' : '255805929',
  'MSC CLEA' : '255805930',
  'MISTRAL' : '255805937',
  'MSC MARYLENA' : '255805947',
  'ATLANTIS A' : '255805954',
  'MSC JERSEY' : '255805956',
  'MSC TALIA F' : '255805965',
  'MSC AMANDA F' : '255805966',
  'MSC LENA F' : '255805967',
  'MSC LUNA F' : '255805968',
  'MSC NINA F' : '255805969',
  'JSP CARLA' : '255805984',
  'EF OLIVIA' : '255805987',
  'BF CARODA' : '255805992',
  'K-RIVER' : '255805995',
  'K-OCEAN' : '255805996',
  'K-STREAM' : '255805997',
  'BRUSSELS' : '255806004',
  'MSC INDIA' : '255806005',
  'MACARENA B' : '255806008',
  'MONTE BRASIL' : '255806010',
  'MONTE DA GUIA' : '255806011',
  'HELMUT' : '255806016',
  'MSC SHUBA B' : '255806027',
  'MSC SHREYA B' : '255806028',
  'MSC NITYA B' : '255806029',
  'MSC MADHU B' : '255806031',
  'MSC YASHI B' : '255806032',
  'MSC JASPER VIII' : '255806034',
  'NORTHERN JUVENILE' : '255806037',
  'NORTHERN JUBILEE' : '255806038',
  'NORTHERN JAGUAR' : '255806039',
  'RITA' : '255806054',
  'GEESKE' : '255806055',
  'BRIGHT' : '255806057',
  'MSC MANON' : '255806063',
  'AS PETRONIA' : '255806064',
  'STADT DRESDEN' : '255806065',
  'AS ANGELINA' : '255806066',
  'AS FATIMA' : '255806067',
  'AS FIORELLA' : '255806068',
  'AS FABRIZIA' : '255806069',
  'AS FLORETTA' : '255806070',
  'AS COLUMBIA' : '255806072',
  'AS PAULINA' : '255806076',
  'MSC AMY' : '255806081',
  'AS CLARITA' : '255806085',
  'SONDERBORG' : '255806086',
  'ORNELLA' : '255806091',
  'AS CARELIA' : '255806092',
  'AS CYPRIA' : '255806093',
  'AS PATRIA' : '255806095',
  'MSC RICCARDA II' : '255806096',
  'MSC ROMINA II' : '255806097',
  'AS ROSALIA' : '255806098',
  'AS RAGNA' : '255806099',
  'AS SICILIA' : '255806100',
  'SEVILLIA' : '255806101',
  'AS FABIANA' : '255806102',
  'AS FELICIA' : '255806103',
  'MSC JUSTICE VIII' : '255806105',
  'AVERA' : '255806107',
  'MSC MASHA 3' : '255806109',
  'AS FILIPPA' : '255806110',
  'CMA CGM GOYA' : '255806113',
  'CMA CGM PUGET' : '255806114',
  'OLIVIA' : '255806115',
  'AS CALIFORNIA' : '255806116',
  'AS CAMELLIA' : '255806121',
  'AS SAVANNA' : '255806122',
  'EF AVA' : '255806123',
  'AS CLEMENTINA' : '255806125',
  'AS SOPHIA' : '255806127',
  'WILLIAM' : '255806132',
  'RAQUEL S' : '255806136',
  'AS CAROLINA' : '255806137',
  'AVILA' : '255806143',
  'MARIELYST' : '255806148',
  'AS SARA' : '255806152',
  'WEC FRANS HALS' : '255806165',
  'NORTHERN JAVELIN' : '255806169',
  'NORTHERN JAMBOREE' : '255806170',
  'LUCIA B' : '255806171',
  'CMA CGM VENTANIA' : '255806179',
  'MSC ANNE F' : '255806190',
  'HEINRICH EHLER' : '255806191',
  'EF EMMA' : '255806209',
  'SPIRIT OF CHENNAI' : '255806219',
  'SPIRIT OF KOLKATA' : '255806220',
  'MSC PRECISION V' : '255806233',
  'CT DANIEL' : '255806235',
  'ADELINA D' : '255806249',
  'JETTE' : '255806253',
  'TESSA' : '255806255',
  'SUNAID X' : '255806259',
  'LOLA B' : '255806261',
  'ELBTEAM' : '255806267',
  'WILHELMINE' : '255806271',
  'SPIRIT OF DUBAI' : '255806274',
  'WEC JAN STEEN' : '255806280',
  'REBECCA S' : '255806301',
  'NORTHERN MONUMENT' : '255806304',
  'ILHA DA MADEIRA' : '255806305',
  'CHARLOTTA' : '255806308',
  'CHRISTINA' : '255806310',
  'ARA AMSTERDAM' : '255806313',
  'WEC VAN RUYSDAEL' : '255806327',
  'ADILIA I' : '255806330',
  'MSC ARIES' : '255806335',
  'MSC AURIGA' : '255806338',
  'MSC ORION' : '255806339',
  'MSC VIRGO' : '255806340',
  'PANTONIO' : '255806346',
  'PENGALIA' : '255806347',
  'JSP ROVER' : '255806352',
  'MSC BUSAN' : '255806362',
  'PERSEUS' : '255806363',
  'MSC CHARLESTON' : '255806367',
  'MSC GUERNSEY' : '255806368',
  'ELBSUN' : '255806370',
  'MSC BEIJING' : '255806376',
  'MSC TORONTO' : '255806377',
  'MSC BRITTANY' : '255806430',
  'MSC ABIDJAN' : '255806434',
  'KRISTIN SCHEPERS' : '255806436',
  'HANSA OSTERBURG' : '255806437',
  'MSC BASEL V' : '255806448',
  'MSC BERN V' : '255806449',
  'NORDIC ITALIA' : '255806451',
  'NORDIC ISTRIA' : '255806452',
  'AS NADIA' : '255806458',
  'MSC ANUSHA III' : '255806459',
  'AMINA' : '255806464',
  'SKALAR' : '255806476',
  'INDEPENDENT QUEST' : '255806481',
  'HEINRICH' : '255806484',
  'MSC SAGITTA III' : '255806485',
  'MSC LILY' : '255806489',
  'MSC LETIZIA' : '255806490',
  'MSC BRUNELLA' : '255806491',
  'MSC CATERINA' : '255806492',
  'MSC VITA' : '255806493',
  'MSC ELODIE' : '255806494',
  'MSC SOFIA CELESTE' : '255806495',
  'MSC ANZU' : '255806496',
  'MSC MELINE' : '255806497',
  'MSC JULIE' : '255806498',
  'MSC SARA ELENA' : '255806499',
  'MSC NAOMI' : '255806500',
  'HELENA SCHEPERS' : '255806505',
  'MSC SILVIA' : '255806506',
  'MSC DOMITILLE' : '255806507',
  'MITO' : '255806508',
  'CALISTO' : '255806513',
  'ANNABA' : '255806514',
  'GRETA' : '255806515',
  'MSC GISELLE' : '255806520',
  'MSC MAXINE' : '255806521',
  'KATHARINA SCHEPERS' : '255806525',
  'DAGMAR' : '255826000',
  'ZIM DANUBE' : '255903590',
  'POLAR ARGENTINA' : '255915586',
  'STEEN' : '255915587',
  'RS LISA' : '255915596',
  'JOLLY ORO' : '255915610',
  'EF ELENA' : '255915612',
  'MSC LAGOS X' : '255915616',
  'MSC DAKAR X' : '255915617',
  'ROME EXPRESS' : '255915618',
  'SOUTHAMPTON EXPRESS' : '255915619',
  'EF EMIRA' : '255915621',
  'INDEPENDENT HORIZON' : '255915622',
  'APOLLON D' : '255915624',
  'ESL DACHAN BAY' : '255915636',
  'ESL KABIR' : '255915641',
  'TAMINA' : '255915647',
  'EF ELDRA' : '255915654',
  'FRIDA RUSS' : '255915657',
  'MSC LOME V' : '255915659',
  'EVI NICOLA' : '255915660',
  'ALS HERCULES' : '255915674',
  'CMA CGM ZINGARO' : '255915695',
  'MSC DUBAI VII' : '255975000',
  'MSC YAMUNA VI' : '255980000',
  'MSC CAPETOWN III' : '255983000',
  'ANDREA' : '255993000',
  'ZIM GANGES' : '255994000',
  'CONTI CONTESSA' : '256011000',
  'CMA CGM AMBARLI' : '256019000',
  'CMA CGM ABIDJAN' : '256022000',
  'CMA CGM LITANI' : '256033000',
  'EMMA A' : '256042000',
  'DIMITRA C' : '256058000',
  'CMA CGM TIGRIS' : '256062000',
  'CMA CGM MANTA RAY' : '256071000',
  'ZIM SAVANNAH' : '256075000',
  'CMA CGM VERDI' : '256095000',
  'CMA CGM GANGES' : '256104000',
  'CMA CGM MEKONG' : '256108000',
  'CMA CGM NACALA' : '256127000',
  'CNC URANUS' : '256142000',
  'CNC PLUTO' : '256143000',
  'CMA CGM NAVEGANTES' : '256144000',
  'CMA CGM AQABA' : '256148000',
  'ANL DHAMBI' : '256150000',
  'MSC MADRID' : '256151000',
  'CMA CGM URAL' : '256163000',
  'CMA CGM VOLGA' : '256169000',
  'CMA CGM SURABAYA' : '256170000',
  'CMA CGM VITORIA' : '256171000',
  'MSC CORUNA' : '256172000',
  'CMA CGM TARRAGONA' : '256208000',
  'CMA CGM THAMES' : '256213000',
  'CMA CGM CONSTANZA' : '256214000',
  'CMA CGM OSAKA' : '256229000',
  'CMA CGM LOME' : '256244000',
  'CNC JAGUAR' : '256267000',
  'CMA CGM MANAUS' : '256272000',
  'CMACGM VILA DO CONDE' : '256292000',
  'CMA CGM TANCREDI' : '256300000',
  'CMA CGM GEORGE SAND' : '256311000',
  'SINE A' : '256318000',
  'ECO MAESTRO' : '256336000',
  'CNC CHEETAH' : '256381000',
  'A. IDEFIX' : '256430000',
  'A. OBELIX' : '256432000',
  'CNC PUMA' : '256441000',
  'CMA CGM NILE' : '256443000',
  'CMA CGM YUKON' : '256449000',
  'GISELE A' : '256475000',
  'CMA CGM EXCELLENCE' : '256482000',
  'CMA CGM EXEMPLARITY' : '256485000',
  'IZMIT EXPRESS' : '256486000',
  'CMA CGM RIMBAUD' : '256490000',
  'CMA CGM GULF EXPRESS' : '256510000',
  'STANLEY A' : '256547000',
  'CMA CGM PELLEAS' : '256548000',
  'OREA' : '256558000',
  'CMA CGM LISBON' : '256559000',
  'MSC ALICANTE' : '256567000',
  'SANTA VIOLA' : '256570000',
  'CMA CGM F.SAGAN' : '256572000',
  'MSC BARCELONA' : '256573000',
  'VERA A' : '256579000',
  'CMA CGM BETTER WAYS' : '256587000',
  'ALEXANDRA A' : '256589000',
  'CMA CGM VALENCIA' : '256590000',
  'CMA CGM INNOVATION' : '256592000',
  'CMA CGM BAHIA' : '256598000',
  'CMA CGM BIANCA' : '256604000',
  'CMA CGM CAPE COD' : '256612000',
  'CMA CGM CEDRUS' : '256615000',
  'CMA CGM KHAO SOK' : '256619000',
  'CMA CGM ARKANSAS' : '256644000',
  'ALLEGRI' : '256649000',
  'BEIHAI551' : '256651000',
  'CMA CGM BUZIOS' : '256658000',
  'CMA CGM LEGACY' : '256676000',
  'CMA CGM SAMSON' : '256687000',
  'GEMLIK EXPRESS' : '256703000',
  'CMA CGM MERMAID S/T' : '256708000',
  'HARIS' : '256728000',
  'ISTANBUL EXPRESS' : '256763000',
  'SYDNEY EXPRESS' : '256766000',
  'BREMERHAVEN EXPRESS' : '256767000',
  'CZECH' : '256771000',
  'CMA CGM MELISANDE' : '256888000',
  'YANTIAN' : '256930000',
  'COSCO HELLAS' : '256932000',
  'BEIJING' : '256937000',
  'ZIM YANTIAN' : '256938000',
  'ZIM SHANGHAI' : '256940000',
  'CMA CGM COLUMBIA' : '256968000',
  'NEW JERSEY TRADER' : '256976000',
  'SILVER MARY' : '257221000',
  'MEGAN' : '271000261',
  'BMI EXPRESS' : '271000609',
  'DANIEL A' : '271000647',
  'LUCIEN GA' : '271000677',
  'KARLA A' : '271000744',
  'ROZA A' : '271001057',
  'TOMRIZ A' : '271001058',
  'CLAIRE A' : '271001092',
  'BERNARD A' : '271040008',
  'VIVIEN A' : '271040635',
  'MARTINE A' : '271041000',
  'MUSTAFA DAYI' : '271042433',
  'GULBENIZ A' : '271042493',
  'INGA A' : '271042494',
  'NEVZAT KALKAVAN' : '271042759',
  'KAAN KALKAVAN' : '271043028',
  'MATILDE A' : '271043163',
  'TIMUCIN A' : '271043491',
  'MARTHA A' : '271044070',
  'TURKON EGYPT' : '271044163',
  'SASKIA A' : '271044177',
  'MED TEKIRDAG' : '271044212',
  'KAPTAN AYTAC A' : '271044301',
  'MEHMET KAHVECI A' : '271044302',
  'MED DENIZLI' : '271044398',
  'MED CORLU' : '271044633',
  'LEYLA KALKAVAN' : '271044978',
  'MED SAMSUN' : '271045478',
  'PARPALI' : '271047044',
  'KOSOVAK' : '271047045',
  'MED AYDIN' : '271048694',
  'TURKON ISTANBUL' : '271049029',
  'MED TRABZON' : '271049958',
  'DIONYSSIS A' : '271050526',
  'KAPPA' : '271050679',
  'CORELLI' : '271050950',
  'VASSILIS A' : '271050951',
  'NARA' : '271051022',
  'VENTO' : '271051067',
  'MED IZMIR' : '271051138',
  'LIONFISH' : '271051490',
  'DENIS A' : '271051980',
  'OYKU A' : '271051982',
  'ONUR G.A' : '271051983',
  'MEDKON MIRA' : '271052043',
  'DNIPRO LINE' : '272771000',
  'FESCO MONERON' : '273210520',
  'PERSEY' : '273212630',
  'KAPITAN BYANKIN' : '273257110',
  'FESCO YANINA' : '273298570',
  'NORILSKIY NICKEL' : '273310730',
  'FESCO NOVIK' : '273329660',
  'MONCHEGORSK' : '273340920',
  'FESCO NAVARIN' : '273343170',
  'ZEYA' : '273347430',
  'NADEZHDA' : '273347820',
  'TALNAKH' : '273348820',
  'SASCO AVACHA' : '273373920',
  'NAYADA' : '273374720',
  'SASCO ANIVA' : '273383520',
  'SASCO ANGARA' : '273390170',
  'IVAN KAPRALOV' : '273399770',
  'IRIDA' : '273430810',
  'FESCO MAGADAN' : '273452980',
  'VENIAMIN KHLOPIN' : '273455590',
  'CRYSTAL STPETERSBURG' : '273611080',
  'TRANSIT LUGOVAYA' : '273611350',
  'MIKHAIL ROBKANOV' : '273611980',
  'TRANSIT TAVAYZA' : '273612270',
  'TRANSIT SHAMORAK E H' : '273612530',
  'FESCO ANASTASIA' : '273613560',
  'FESCO TATARSTAN' : '273615650',
  'TRANSIT MILLIONKA' : '273616390',
  'ARTEMIS' : '275508000',
  'SHIPLILLY REINVENTIO' : '275535000',
  'NANTO' : '276870000',
  'KANTATA' : '277549000',
  'SONATA' : '277562000',
  'WAN HAI 370' : '300000000',
  'SSG CARTER' : '303189000',
  'PRESIDENT CLEVELAND' : '303340000',
  'PRESIDENT EISENHOWER' : '303352000',
  'MAUNALEI' : '303584000',
  'MAERSK KENSINGTON' : '303657000',
  'JULIUS S' : '304010000',
  'BORDER' : '304010376',
  'CARIBE MARINER' : '304010618',
  'ANTON SCHEPERS' : '304019000',
  'VEGA SCORPIO' : '304044000',
  'ELBTRAVELLER' : '304071000',
  'AURORA' : '304080000',
  'CARIBE VOYAGER' : '304109000',
  'PEGASUS J' : '304122000',
  'HELENE' : '304157000',
  'MARIA DA PAZ' : '304198000',
  'REGULA' : '304304000',
  'JANINA' : '304373000',
  'FREDERIK' : '304374000',
  'STAR COMET' : '304416000',
  'TONY STARK' : '304427000',
  'NADJA' : '304460000',
  'MARINA' : '304461000',
  'PEYTON LYNN C' : '304474000',
  'MANDO' : '304490000',
  'LODUR' : '304504000',
  'SAN PEDRO CALUNGSOD' : '304509000',
  'ATLANTIS' : '304535000',
  'NCL HAUGESUND' : '304619000',
  'KATHERINE BORCHARD' : '304623000',
  'ALLEGRO' : '304642000',
  'ELBSAILOR' : '304667000',
  'CHARLOTTE' : '304670000',
  'BF PHILIPP' : '304688000',
  'MAUREN' : '304747000',
  'ZORAN DJINDJIC' : '304809000',
  'HORIZON' : '304811000',
  'BEOGRAD' : '304812000',
  'IRIS MIKO' : '304834000',
  'EMILIA' : '304846000',
  'ASTRID L' : '304903000',
  'DIANA J' : '304906000',
  'BF ESPERANZA' : '304907000',
  'JSP RIDER' : '304922000',
  'DENEB' : '304941000',
  'MARINA L' : '304959000',
  'ALDEBARAN J' : '304972000',
  'JSP SLEIPNER' : '304978000',
  'NCL IKORNNES' : '304979000',
  'OKEE AURELIA' : '305002000',
  'LUCY BORCHARD' : '305032000',
  'PAVO J' : '305055000',
  'PACANDA' : '305060000',
  'JSP BORA' : '305079000',
  'SPICA J' : '305115000',
  'LANTAU BAY' : '305126000',
  'WES SINA' : '305127000',
  'HOHEBANK' : '305134000',
  'TUCANA' : '305140000',
  'AS FRANZISKA' : '305213000',
  'MINDORO' : '305230000',
  'HOHEPLATE' : '305236000',
  'WARNOW BELUGA' : '305258000',
  'HENRIKE SCHEPERS' : '305268000',
  'NEUENFELDE' : '305287000',
  'BREEZE' : '305291000',
  'PETKUM' : '305293000',
  'SUSAN BORCHARD' : '305299000',
  'ANNALISA P' : '305313000',
  'AS CHRISTIANA' : '305346000',
  'AS CARLOTTA' : '305347000',
  'STORM' : '305350000',
  'AS PIA' : '305409000',
  'SKOGAFOSS' : '305411000',
  'VENTO DI PONENTE' : '305412000',
  'SIARGAO' : '305473000',
  'AS PAMELA' : '305482000',
  'ORION' : '305484000',
  'SAMAL' : '305500000',
  'THEODOR' : '305506000',
  'WIEBKE SCHEPERS' : '305510000',
  'IMKE SCHEPERS' : '305517000',
  'HELGOLAND' : '305523000',
  'HOOGE' : '305524000',
  'CALANDRA' : '305538000',
  'LANGENESS' : '305540000',
  'PANAY' : '305545000',
  'BALTRUM' : '305558000',
  'BORKUM' : '305563000',
  'PALAWAN' : '305567000',
  'CEBU' : '305568000',
  'ELBSPRING' : '305575000',
  'ELBSKY' : '305576000',
  'NORDERNEY' : '305588000',
  'NAAMA BORCHARD' : '305600000',
  'JSP MISTRAL' : '305643000',
  'PHOENIX J' : '305648000',
  'ALEXANDER B' : '305654000',
  'HS BUSAN' : '305678000',
  'HS SINGAPORE' : '305680000',
  'HS HONG KONG' : '305685000',
  'MARUS' : '305707000',
  'PARADERO' : '305723000',
  'BERNHAC' : '305741000',
  'LOUISE BORCHARD' : '305765000',
  'VOHBURG' : '305773000',
  'BF TIGER' : '305784000',
  'DALYA H' : '305832000',
  'AMELIE BORCHARD' : '305886000',
  'NOAH' : '305904000',
  'OCEANA' : '305969000',
  'VIVIENNE SHERI D' : '305971000',
  'MCC SHENZHEN' : '305977000',
  'ELBSTAR' : '305978000',
  'BAKKAFOSS' : '305979000',
  'MOL CHARISMA' : '308015000',
  'RESOLUTION' : '308025000',
  'MYRIAD' : '308234000',
  'MOL CREATION' : '309046000',
  'RELIANCE' : '309358000',
  'DOLE COLOMBIA' : '309403000',
  'MOL CELEBRATION' : '309901000',
  'DOLE CHILE' : '309942000',
  'HYPERION' : '309947000',
  'HARRIER' : '309972000',
  'LISBON EXPRESS' : '310132000',
  'MV VALENCIA EXPRESS' : '310133000',
  'TORONTO EXPRESS' : '310749000',
  'MONTREAL EXPRESS' : '310750000',
  'QUEBEC EXPRESS' : '310760000',
  'ONTARIO EXPRESS' : '310761000',
  'KOBE EXPRESS' : '310764000',
  'LONDON EXPRESS' : '310765000',
  'DUESSELDORF EXPRESS' : '310768000',
  'LIVERPOOL EXPRESS' : '310823000',
  'DUBLIN EXPRESS' : '310825000',
  'GLASGOW EXPRESS' : '310826000',
  'ULTIMA' : '311000169',
  'DOLE PACIFIC' : '311000414',
  'DOLE ATLANTIC' : '311000415',
  'DOLE CARIBBEAN' : '311000416',
  'KUO LIN' : '311000629',
  'EPONYMA' : '311000725',
  'ESTIMA' : '311000780',
  'CMA CGM HYDRA' : '311000921',
  'CMA CGM MUSCA' : '311000922',
  'CMA CGM MARCO POLO' : '311000923',
  'DOLE MAYA' : '311001005',
  'DOLE AZTEC' : '311001006',
  'LIMA EXPRESS' : '311001120',
  'CMA CGM CHIWAN' : '311001150',
  'CMA CGM P.ANTIOQUIA' : '311001218',
  'SUNNY PHOENIX' : '311001244',
  'FELIXSTOWE' : '311001345',
  'CMA CGM BUTTERFLY' : '311001416',
  'CMA CGM AFRICA ONE' : '311043200',
  'CMA CGM AFRICA TWO' : '311043300',
  'CMA CGM AFRICA FOUR' : '311044100',
  'CMA CGM AFRICA THREE' : '311044200',
  'OPTIMA' : '311049600',
  'HALCYON' : '311058000',
  'KUMASI' : '311060500',
  'REVERENCE' : '311272000',
  'NICOLAS DELMAS' : '311326000',
  'RESURGENCE' : '311365000',
  'MARVEL' : '311655000',
  'REFLECTION' : '311691000',
  'MAGNA' : '311703000',
  'MUSE' : '311934000',
  'RENOWN' : '311935000',
  'RISUN 9' : '312016000',
  'LADY NURAY' : '312101000',
  'JING YE' : '312338000',
  'NIU FUHAI' : '312637000',
  'SKY LIGHT' : '312991000',
  'SAMSKIP COMMANDER' : '314292000',
  'SPICA STAR' : '314732000',
  'RMS MEL' : '314747000',
  'HANA H' : '314749000',
  'GLEVIN' : '314753000',
  'RMS TEAM' : '314754000',
  'PALERMO' : '314792000',
  'OCEANEX AVALON' : '316006074',
  'ASTERIX' : '316030879',
  'NYK DAEDALUS' : '319200700',
  'ONE ARCADIA' : '319211600',
  'ONE TRITON' : '319219200',
  'CARINA' : '325974000',
  'AMO 2' : '334953000',
  'AMO' : '334955000',
  'MAERSK PITTSBURGH' : '338071000',
  'MAERSK ATLANTA' : '338078000',
  'LURLINE' : '338121000',
  'MAERSK KINLOSS' : '338241000',
  'MAERSK HARTFORD' : '338403000',
  'MAERSK CHICAGO' : '338408000',
  'MAERSK DENVER' : '338418000',
  'APL ISLANDER' : '338459000',
  'MAERSK DETROIT' : '338474000',
  'MAERSK COLUMBUS' : '338525000',
  'KAMOKUIKI' : '338566000',
  'PRESIDENT TRUMAN' : '338679000',
  'ISLA BELLA' : '338760000',
  'MAERSK CHESAPEAKE' : '338775000',
  'PERLA DEL CARIBE' : '338789000',
  'MATSONIA' : '338796000',
  'MV PAGE' : '338864000',
  'BOSON' : '341063001',
  'MM MADRID' : '341104000',
  'SWIMMER.M' : '341233001',
  'URANUS' : '341257000',
  'SHIRA-E' : '341258000',
  'AK FERAS' : '341281001',
  'GLUON' : '341339000',
  'MARSA VICTORY' : '341540000',
  'TANYA' : '341644000',
  'DHANU' : '341652000',
  'NADIA' : '341765000',
  'MARSA NEPTUNE' : '341817000',
  'LUCIA' : '341884000',
  'CERUS' : '341988000',
  'SOL PROGRESS' : '351018000',
  'MCC TAIPEI' : '351038000',
  'OOCL DURBAN' : '351109000',
  'OEL SHASTA' : '351218000',
  'MSC LUDOVICA' : '351248000',
  'NYK OCEANUS' : '351249000',
  'MSC SOPHIE' : '351268000',
  'BANGKOK' : '351272000',
  'MSC ASTRID III' : '351285000',
  'POLARIS 3' : '351325000',
  'MSC SARISKA V' : '351356000',
  'MSC KALINA' : '351361000',
  'MSC HANNAH' : '351400000',
  'HMM ST PETERSBURG' : '351404000',
  'SUNNY DAISY' : '351453000',
  'EVER BLISS' : '351465000',
  'MSC ALYSSA' : '351473000',
  'MOL EARNEST' : '351496000',
  'PACIFIC GRACE' : '351513000',
  'AEGEAN EXPRESS' : '351563000',
  'ISEACO FORTUNE' : '351566000',
  'STRIDE' : '351567000',
  'EVER FOREVER' : '351571000',
  'FUTURE' : '351577000',
  'MSC MONICA III' : '351607000',
  'MSC MARIA LAURA II' : '351634000',
  'PENANG BRIDGE' : '351639000',
  'ADVANCE' : '351645000',
  'A GALAXY' : '351663000',
  'ITX EHIME' : '351664000',
  'SUNNY LAUREL' : '351674000',
  'MSC KATYAYNI' : '351675000',
  'MOHSEN ILYAS' : '351676000',
  'MAERSK DHAKA' : '351678000',
  'BAL STAR' : '351702000',
  'MSC MARTINA' : '351738000',
  'EVER CORE' : '351753000',
  'MSC ALGHERO' : '351819000',
  'SPRINTER' : '351841000',
  'MSC KATIE' : '351848000',
  'MSC MINA' : '351862000',
  'MSC HELENA III' : '351917000',
  'EVER UTILE' : '351934000',
  'KUO LUNG' : '351965000',
  'MAERSK ARAS' : '351977000',
  'EVER OPUS' : '352001038',
  'EVER OATH' : '352001068',
  'TRANSMAR LEGACY' : '352001116',
  'MSC SANTA MARIA' : '352001168',
  'EVER CONFORM' : '352001178',
  'MSC FLOSTA III' : '352001244',
  'EVER ALOT' : '352001259',
  'EVER OBEY' : '352001261',
  'EVER TOP' : '352001313',
  'MSC AUBE F' : '352001323',
  'EVER CONSIST' : '352001363',
  'MSC BARBADOS' : '352001367',
  'EVER OPTIMA' : '352001525',
  'MEDKON SAMSUN' : '352001564',
  'PROVIDENT' : '352001639',
  'ANL TASMAN TRADER' : '352001683',
  'CMA CGM BLOSSOM' : '352001685',
  'MEDKON MERSIN' : '352001710',
  'GH RIVER' : '352001731',
  'EVER ARIA' : '352001747',
  'XIN HE LU 1' : '352001841',
  'ZHONG XING HONG YUN' : '352001848',
  'HUA XIANG 936' : '352001881',
  'EVER OUTWIT' : '352001951',
  'MAO GANG QUAN ZHOU' : '352001987',
  'MAERSK ACADIA' : '352002065',
  'LIDER PERIHAN' : '352002086',
  'SOL RESILIENCE' : '352002088',
  'KANWAY LUCKY' : '352002094',
  'SKY IRIS' : '352002104',
  'FESCO TRADER' : '352002182',
  'YASAR' : '352002186',
  'YONG YUE 11' : '352002215',
  'MAERSK BISCAYNE' : '352002245',
  'HONOR OCEAN' : '352002265',
  'HONOR VOYAGER' : '352002269',
  'MERATUS JAYAWIJAYA' : '352002312',
  'D QUEENS' : '352002350',
  'SHUN LONG' : '352002356',
  'MEDKON CANAKKALE' : '352002364',
  'A GORYU' : '352002368',
  'MAERSK EVERGLADES' : '352002391',
  'POS QINGDAO' : '352002399',
  'POS GUANGZHOU' : '352002408',
  'SKY TIARA' : '352002410',
  'A KOBE' : '352002451',
  'EUPHORIA' : '352002466',
  'SFT TURKEY' : '352002487',
  'OVP ARIES' : '352002499',
  'XIN HE LU 2' : '352002534',
  'MAO GANG GUANG ZHOU' : '352002539',
  'ESCADA' : '352002559',
  'MERATUS JAYAKARTA' : '352002561',
  'A WASHIBA' : '352002579',
  'ZEYNEP' : '352002592',
  'OPUS ONE' : '352002597',
  'STAR SOURCE' : '352002621',
  'MAERSK GLACIER' : '352002625',
  'XIANG YING' : '352002643',
  'MERATUS JIMBARAN' : '352002645',
  'MERATUS TOMINI' : '352002648',
  'MERATUS JAYAGIRI' : '352002649',
  'MERATUS MEDAN 5' : '352002650',
  'SUNNY LILAC' : '352002731',
  'HONWELL' : '352002748',
  'MEDKON LIA' : '352002790',
  'ALEJANDRINA' : '352002798',
  'HONRISE' : '352002865',
  'STAR BLESSING' : '352002880',
  'MEDKON PERLA' : '352002943',
  'YEOSU VOYAGER' : '352002971',
  'INCHEON VOYAGER' : '352002972',
  'ABRAO COCHIN' : '352002986',
  'MARSA PRIDE' : '352002992',
  'STAR BRIGHT' : '352002996',
  'MAERSK MAMMOTH' : '352002997',
  'NZ SHANGHAI' : '352003005',
  'SIDRA OCEAN' : '352003007',
  'MED STAR' : '352003029',
  'A ONTAKE' : '352003036',
  'RC OCEAN' : '352003050',
  'HONG CHANG SHENG' : '352003066',
  'SUNNY VIOLET' : '352003081',
  'TC MESSENGER' : '352003099',
  'CTM ISTMO' : '352003103',
  'ULSAN VOYAGER' : '352003107',
  'BRIGHT SAKURA' : '352003112',
  'BRIGHT COSMOS' : '352003114',
  'FLYING FISH 1' : '352003133',
  'ITX HIGO' : '352003243',
  'AN HAI' : '352003254',
  'GRAND MARINER' : '352003258',
  'YUAN XIANG FEN JIN' : '352003293',
  'HUA XIN 678' : '352003391',
  'EVER CLEVER' : '352029885',
  'SUNNY CANNA' : '352058000',
  'SPIL CAYA' : '352070000',
  'EVER FINE' : '352111000',
  'EVER LOTUS' : '352135000',
  'MSC JEMIMA' : '352138000',
  'MSC LORETTA' : '352140000',
  'TOLEDO TRIUMPH' : '352149000',
  'SONGYUNHE' : '352153000',
  'PROGRESS C' : '352157000',
  'MAERSK NORESUND' : '352254000',
  'HMM LE HAVRE' : '352260000',
  'MSC AMBRA' : '352269000',
  'FESCO EKATERINA' : '352297000',
  'MSC MARIANNA' : '352335000',
  'CAP SAN ARTEMISSIO' : '352358000',
  'MSC BETTINA' : '352361000',
  'TEXAS TRIUMPH' : '352366000',
  'KMTC TOKYO' : '352374000',
  'TI2 APPLE' : '352382000',
  'NAVIOS NERINE' : '352385000',
  'MERIOS' : '352408000',
  'MIYUNHE' : '352456000',
  'MSC ALIZEE III' : '352471000',
  'MARINA VOYAGER' : '352544000',
  'MSC QINGDAO F' : '352578000',
  'MSC ADELAIDE' : '352619000',
  'COSCO AMERICA' : '352631000',
  'SOL MALAYSIA' : '352687000',
  'MSC FELIXSTOWE' : '352688000',
  'SOL FORTUNE' : '352745000',
  'MSC ZOE' : '352757000',
  'NYK ORION' : '352822000',
  'ORIENTAL BRIGHT' : '352830000',
  'MSC LONDON' : '352853000',
  'EVER CHEER' : '352866000',
  'MSC FLORENTINA' : '352870000',
  'MSC CANDICE' : '352871000',
  'CHENNAI VOYAGER' : '352898704',
  'MSC LEVANTE F' : '352898711',
  'EVER ALP' : '352898717',
  'EVER CONNECT' : '352898766',
  'MSC SANDY III' : '352898818',
  'MEDKON NLS' : '352921000',
  'MSC NERISSA' : '352948000',
  'MSC NEW YORK' : '352952000',
  'YAKOOT' : '352965000',
  'HE YUAN 1' : '352978125',
  'HUI DA 9' : '352978135',
  'HUI FA' : '352978152',
  'LOA GLORY' : '352978178',
  'EVER COMPOSE' : '352978183',
  'YM TOPMOST' : '352978194',
  'EVER ACT' : '352978199',
  'STORM I' : '352978207',
  'SPIL NIRMALA' : '352978233',
  'M.V ATA-1 !' : '352980000',
  'SPIL NINGSIH' : '352980780',
  'EVER AIM' : '352980788',
  'SPIL NITA' : '352980799',
  'EVER CONCISE' : '352980812',
  'EVER OWN' : '352980861',
  'EVER CONCERT' : '352980873',
  'MSC STELLA' : '352983000',
  'EVER ACE' : '352986146',
  'YM TRAVEL' : '352986159',
  'SPIL NISAKA' : '352986168',
  'SPIL NIKEN' : '352986169',
  'NAVIOS ALTAIR' : '353000000',
  'NYK DEMETER' : '353025000',
  'MSC GAIA' : '353051000',
  'HEUNG-A SARAH' : '353059000',
  'QI YUN HE' : '353066000',
  'SITC DANANG' : '353089000',
  'MSC SHANELLE V' : '353111000',
  'MAERSK PUELO' : '353117000',
  'EVER GIVEN' : '353136000',
  'MSC EMANUELA' : '353155000',
  'MSC SONIA' : '353160000',
  'MSC BEATRICE' : '353162000',
  'POS HOCHIMINH' : '353201000',
  'JINYUNHE' : '353241000',
  'EASLINE LIANYUNGANG' : '353258000',
  'MSC ORIANE' : '353289000',
  'MILLENNIUM BRIGHT' : '353290000',
  'STRAITS CITY' : '353293000',
  'SITC KOBE' : '353346000',
  'CAP SAN MALEAS' : '353365000',
  'BANGKOK BRIDGE' : '353368000',
  'MSC LAUREN' : '353428000',
  'DOLPHIN II' : '353467000',
  'MSC DAMLA' : '353499000',
  'MSC ISABELLA' : '353590000',
  'ONE HANOI' : '353592000',
  'MSC BRIANNA' : '353612000',
  'SKY ORION' : '353640000',
  'MSC MICHAELA' : '353666000',
  'MSC MATILDE V' : '353719000',
  'MSC MADELEINE' : '353728000',
  'MSC BARBARA' : '353775000',
  'ALEXANDRIA BRIDGE' : '353780000',
  'EVER BREED' : '353800000',
  'ISEACO WISDOM' : '353824000',
  'HIGHWAY' : '353848000',
  'MSC AUGUSTA' : '353850000',
  'BRIDGE' : '353851000',
  'MSC ANNAMARIA' : '353852000',
  'MSC ANS' : '353873000',
  'SEATTLE BRIDGE' : '353903000',
  'MSC ELA' : '353934000',
  'MSC LISA' : '353968000',
  'ONE MONACO' : '353997000',
  'LOA PEACE' : '354021000',
  'EVER FORE' : '354022000',
  'MAERSK KARUN' : '354060000',
  'MSC LAURA' : '354092000',
  'CALLAO BRIDGE' : '354106000',
  'LOS ANDES BRIDGE' : '354118000',
  'BAY BRIDGE' : '354120000',
  'BAL PEACE' : '354194000',
  'UGL GUANGZHOU' : '354195000',
  'MSC ORNELLA' : '354202000',
  'NYK METEOR' : '354212000',
  'KOTA NILAM' : '354275000',
  'GREEN CELESTE' : '354299000',
  'MSC TOMOKO' : '354315000',
  'MSC MELISSA' : '354340000',
  'COSCO KIKU' : '354358000',
  'SIDRA HALIMA' : '354380000',
  'KMTC POHANG' : '354401000',
  'MSC FABIENNE' : '354415000',
  'MSC ROSA M' : '354462000',
  'DONG YU' : '354464000',
  'KMTC SURABAYA' : '354467000',
  'MSC ESTHI' : '354499000',
  'MSC INGRID' : '354505000',
  'MSC CARMEN' : '354530000',
  'MSC MANDY III' : '354537000',
  'MSC SHANNON III' : '354540000',
  'MSC CAMILLE' : '354625000',
  'EASLINE KWANGYANG' : '354646000',
  'EVER GOLDEN' : '354654000',
  'EVER CAST' : '354690000',
  'MSC MARIA PIA' : '354711000',
  'MSC RACHELE' : '354712000',
  'MSC KRITTIKA' : '354725000',
  'OSLO TRADER' : '354727000',
  'MSC ASYA' : '354744000',
  'MSC TAMARA' : '354745000',
  'ORCA I' : '354751000',
  'MSC RAFAELA' : '354776000',
  'KMARIN ATLANTICA' : '354785000',
  'ONE HAMMERSMITH' : '354839000',
  'SPIL KARTIKA' : '354884000',
  'COSCO EUROPE' : '354886000',
  'NYK NEBULA' : '354891000',
  'ONE SWAN' : '354928000',
  'TAICHUNG' : '354932000',
  'BEAR MOUNTAIN BRIDGE' : '354942000',
  'MSC MARINA' : '354972000',
  'EVER GLOBE' : '354977000',
  'MATSON KAUAI' : '355006000',
  'MSC AMERICA III' : '355028000',
  'HF SPIRIT' : '355033000',
  'EVER BURLY' : '355106000',
  'KMARIN AZUR' : '355113000',
  'MSC TASMANIA' : '355129000',
  'MSC JORDAN III' : '355194000',
  'MSC MAUREEN' : '355216000',
  'GREEN CELEBRITY' : '355224000',
  'ST SUCCESS' : '355232000',
  'MSC IRENE' : '355233000',
  'MSC ATLANTIC III' : '355249000',
  'MSC NEDERLAND III' : '355254000',
  'ONE HONG KONG' : '355271000',
  'MSC LUCIANA' : '355283000',
  'MAERSK ENSHI' : '355288000',
  'MSC SUEZ' : '355289000',
  'MSC SANDRA' : '355305000',
  'MSC LUISA' : '355308000',
  'EVER CHARM' : '355341000',
  'MSC SANTHYA' : '355363000',
  'SAN FRANCISCO BRIDGE' : '355400000',
  'NYK CONSTELLATION' : '355433000',
  'OOCL BRAZIL' : '355443000',
  'SITC TOKYO' : '355449000',
  'CAIYUNHE' : '355474000',
  'PADIAN 4' : '355502000',
  'EVER CALM' : '355537000',
  'EVER FAITH' : '355623000',
  'MAERSK NUSSFJORD' : '355632000',
  'RUN SHENG' : '355674000',
  'MSC IMMA III' : '355714000',
  'EVER CHASTE' : '355722000',
  'MSC REBECCA III' : '355738000',
  'MSC LARA II' : '355746000',
  'HF LUCKY' : '355771000',
  'MSC MAKOTO II' : '355776000',
  'MSC MIA' : '355798000',
  'COSCO NEW YORK' : '355808000',
  'SITC TIANJIN' : '355898000',
  'UNI-PREMIER' : '355902000',
  'MSC OSCAR' : '355906000',
  'MSC MARIA ELENA' : '355919000',
  'SEA OF LUCK' : '356012000',
  'MAERSK VLADIVOSTOK' : '356015000',
  'MSC JOY' : '356037000',
  'MSC JUDITH' : '356093000',
  'MSC SABRINA III' : '356101000',
  'MSC INES' : '356112000',
  'TSS SHAMS' : '356162000',
  'MSC ELEONORA' : '356167000',
  'YM MOVEMENT' : '356189000',
  'MSC KIM' : '356205000',
  'OEL COLOMBO' : '356207000',
  'PHOENIX D' : '356217000',
  'MSC NELA' : '356234000',
  'MSC SAMANTHA VI' : '356249000',
  'MSC OLIVER' : '356289000',
  'EMORA' : '356298000',
  'MSC KRYSTAL' : '356330000',
  'BAI CHAY BRIDGE' : '356352000',
  'PEARL RIVER BRIDGE' : '356367000',
  'MCC DANANG' : '356369000',
  'EASLINE YANTAI' : '356396000',
  'EVER LUCKY' : '356407000',
  'LADY OF LUCK' : '356426000',
  'MSC LENI' : '356432000',
  'SUNNY ACACIA' : '356481000',
  'MSC ESHA F' : '356505000',
  'EVER CLEAR' : '356517000',
  'ATHENS BRIDGE' : '356554000',
  'MOL PREMIUM' : '356565000',
  'HAKATA SEOUL' : '356575000',
  'VICTORY VOYAGER' : '356578000',
  'MSC DON GIOVANNI' : '356579000',
  'COSCO ASIA' : '356581000',
  'EVER GOVERN' : '356582000',
  'NYK PAULA' : '356629000',
  'HMM COPENHAGEN' : '356712000',
  'MAERSK NESNA' : '356758000',
  'LIDER HALIL' : '356792000',
  'ISEACO GENESIS' : '356815000',
  'MAERSK JAKARTA' : '356853000',
  'MSC ROSSELLA' : '356864000',
  'NIIGATA TRADER' : '356886000',
  'EASLINE OSAKA' : '356893000',
  'MSC ALEXA' : '356904000',
  'EVER CHANT' : '356907000',
  'QUEZON BRIDGE' : '356931000',
  'MSC VANESSA' : '356946000',
  'ONE TRUTH' : '356984000',
  'SITC YOKOHAMA' : '356985000',
  'FENGYUNHE' : '357025000',
  'MEDKON LTF' : '357048000',
  'MSC DANIT' : '357051000',
  'MSC ELENI' : '357067000',
  'EVER UNITY' : '357077000',
  'MSC AGRIGENTO' : '357096000',
  'MAERSK ESMERALDAS' : '357104000',
  'MSC DIEGO' : '357106000',
  'UNI PACIFIC' : '357131000',
  'SOL STRIDE' : '357142000',
  'UNI-ASSENT' : '357157000',
  'NYK REMUS' : '357175000',
  'GREEN CLARITY' : '357178000',
  'MSC NILGUN' : '357191000',
  'ONE TREASURE' : '357210000',
  'CMA CGM ALCAZAR' : '357214000',
  'ATHENA' : '357231000',
  'MSC MELATILDE' : '357240000',
  'IBN AL ABBAR' : '357286000',
  'MSC REGINA' : '357332000',
  'MARIA DISCO??2Y' : '357345000',
  'SITC NAGOYA' : '357368000',
  'MAIRA' : '357380000',
  'NIKOLAS' : '357388000',
  'UNI-ASSURE' : '357398000',
  'MSC POH LIN' : '357405000',
  'NEWYORKER' : '357411000',
  'EVER BRACE' : '357413000',
  'EVER CANDID' : '357420000',
  'MSC MEDITERRANEAN' : '357444000',
  'MSC CANBERRA' : '357455000',
  'EVER GRADE' : '357463000',
  'EVER URANUS' : '357493000',
  'MSC ANIELLO' : '357506000',
  'MSC GINA' : '357542000',
  'SAN DIEGO BRIDGE' : '357547000',
  'MSC MALIN' : '357562000',
  'KOTA NEKAD' : '357577000',
  'BOYA' : '357622000',
  'MSC EYRA' : '357632000',
  'A395' : '357702000',
  'EVER URSULA' : '357737000',
  'MSC IRIS' : '357773000',
  'JORDANIA' : '357779000',
  'UNI PATRIOT' : '357787000',
  'A SUKAI' : '357842000',
  'ARICA BRIDGE' : '357848000',
  'EVER USEFUL' : '357874000',
  'MSC TERESA' : '357891000',
  'ASIA EXPRESS' : '357897000',
  'MSC BERYL' : '357926000',
  'EVER URBAN' : '357939000',
  'UNI PHOENIX' : '357962000',
  'UNI PERFECT' : '357979000',
  'UNI-PRUDENT' : '357980000',
  'UNI POPULAR' : '357981000',
  'SEROJA LIMA' : '357988000',
  'POTOMAC EXPRESS' : '366418000',
  'MATSON ANCHORAGE' : '366557000',
  'MANOA' : '366562000',
  'MAHIMAHI' : '366563000',
  'HORIZON SPIRIT' : '366629000',
  'GEORGE II' : '366791000',
  'MATSON KODIAK' : '366793000',
  'MATSON TACOMA' : '366794000',
  'MOKIHANA' : '367196000',
  'DELAWARE EXPRESS' : '367422000',
  'MAUNAWILI' : '367438000',
  'PRESIDENT WILSON' : '367578740',
  'MAERSK IOWA' : '367606000',
  'APL OCEANIA' : '367619000',
  'MAERSK MONTANA' : '367759000',
  'MAERSK OHIO' : '367775000',
  'MISSOURI EXPRESS' : '367781000',
  'GARY I GORDON' : '367834000',
  'MAERSK KANSAS' : '368053000',
  'SAGAMORE' : '368160000',
  'MANULANI' : '368305000',
  'HUDSON EXPRESS' : '368359000',
  'MAERSK SARATOGA' : '369096000',
  'DANIEL K. INOUYE' : '369207000',
  'KAIMANA HILA' : '369209000',
  'COLORADO EXPRESS' : '369215000',
  'MAERSK SENTOSA' : '369246000',
  'MAERSK YORKTOWN' : '369248000',
  'MAERSK SELETAR' : '369309000',
  'PRESIDENT KENNEDY' : '369339000',
  'MAERSK DURBAN' : '369340000',
  'GEORGE III' : '369355000',
  'JANET MARIE' : '369357000',
  'MAERSK TENNESSEE' : '369390000',
  'APL EAGLE' : '369558000',
  'PRESIDENTFDROOSEVELT' : '369751000',
  'SOL VALOUR' : '370012000',
  'BLPL FAITH' : '370014000',
  'DSL MARINER' : '370031000',
  'PADIAN 3' : '370109000',
  'EVER GENIUS' : '370121000',
  'COSCO AFRICA' : '370188000',
  'RUN LONG' : '370206000',
  'MSC ANGELA' : '370254000',
  'MSC SORAYA' : '370271000',
  'MSC NURIA' : '370273000',
  'SARA STAR' : '370288000',
  'MSC NAGOYA V' : '370320000',
  'MSC VALERIA' : '370341000',
  'HF FORTUNE' : '370354000',
  'M.V.KOTA KAYA' : '370357000',
  'EVER CROWN' : '370376000',
  'MSC CORINNA' : '370437000',
  'ACX CRYSTAL' : '370439000',
  'SPIL KARTINI' : '370442000',
  'SEDRA' : '370511000',
  'ONE HENRY HUDSON' : '370587000',
  'EVER BUILD' : '370616000',
  'ONE CRANE' : '370651000',
  'EVER BOOMY' : '370700000',
  'MSC SIXIN' : '370711000',
  'MSC DANIELA' : '370892000',
  'MSC SOLA' : '370894000',
  'MSC DAVAO III' : '370928000',
  'MSC IVANA' : '370930000',
  'OEL INDIA' : '370932000',
  'JI PENG' : '370966000',
  'MSC FRANCESCA' : '370993000',
  'EASLINE DALIAN' : '371002000',
  'MSC SAMAR' : '371047000',
  'MSC ELOISE' : '371057000',
  'MSC LUCY' : '371059000',
  'ONE MILANO' : '371076000',
  'EVER CENTER' : '371141000',
  'ONE MILLAU' : '371215000',
  'MSC EVA' : '371218000',
  'MSC PAMELA' : '371228000',
  'MSC PALOMA' : '371233000',
  'MSC KOREA' : '371243000',
  'MSC RITA' : '371245000',
  'KMTC GWANGYANG' : '371249000',
  'MAERSK ENSENADA' : '371274000',
  'EVER GOODS' : '371308000',
  'HMM OSLO' : '371319000',
  'POS BANGKOK' : '371369000',
  'MSC JAPAN' : '371443000',
  'MCC YANGON' : '371444000',
  'NYK RIGEL' : '371449000',
  'MSC SUSANNA' : '371466000',
  'MSC MARTA' : '371474000',
  'MSC CAROLINA' : '371475000',
  'MARCOS V' : '371519000',
  'MSC CHULAI III' : '371543000',
  'MSC LAURENCE' : '371582000',
  'MSC ALABAMA III' : '371602000',
  'YM MODERATION' : '371633000',
  'MSC GIANNA III' : '371711000',
  'MARGARETRIVERBRIDGE' : '371714000',
  'MSC BELLE' : '371743000',
  'MSC RANIA' : '371752000',
  'MSC ALEXANDRA' : '371753000',
  'HORAI BRIDGE' : '371791000',
  'A HOUOU' : '371793000',
  'GEORGE WASHINGTON BR' : '371799000',
  'PANDA VEGA' : '371818000',
  'EVER GREET' : '371829000',
  'MOL ENDOWMENT' : '371836000',
  'MSC MAEVA' : '371851000',
  'MSC LORENA' : '371860000',
  'CMA CGM KAILAS' : '371866000',
  'MSC SILVANA' : '371908000',
  'MSC ELAINE' : '371929000',
  'MSC GULSUN' : '372003000',
  'MSC HEIDI' : '372008000',
  'BAL BOAN' : '372023000',
  'ONE HUMBER' : '372038000',
  'MSC JOANNA' : '372050000',
  'MSC VITTORIA' : '372051000',
  'ONE HANNOVER' : '372104000',
  'MSC LEIGH' : '372123000',
  'ABANOZ' : '372162000',
  'LDR BEDEL' : '372174000',
  'NYK VEGA' : '372218000',
  'GREEN HORIZON' : '372242000',
  'ONE HAWK' : '372280000',
  'NYK DIANA' : '372319000',
  'EVER BRAVE' : '372354000',
  'ONE HARBOUR' : '372367000',
  'MSC MARIA SAVERIA' : '372400000',
  'XINQUNDAO' : '372440000',
  'SKY WIND' : '372490000',
  'MSC DONATA' : '372491000',
  'NYK VENUS' : '372512000',
  'NYK VESTA' : '372531000',
  'JAWAN' : '372546000',
  'SUNNY LAVENDER' : '372570000',
  'MCC TOKYO' : '372588000',
  'MAERSK NORDDAL' : '372596000',
  'KMTC OSAKA' : '372604000',
  'HUMEN BRIDGE' : '372665000',
  'M WILMINGTON' : '372707000',
  'CAP SAN SOUNIO' : '372723000',
  'ONE HAMBURG' : '372724000',
  'MSC FEBE' : '372729000',
  'EVER FIT' : '372733000',
  'MSC SINDY' : '372736000',
  'MSC ROSARIA' : '372737000',
  'NAVIOS JASMINE' : '372753000',
  'D ANGELS' : '372762000',
  'SITC HONGKONG' : '372765000',
  'OYSTER' : '372797000',
  'MOL SPARKLE' : '372800000',
  'GAUJA' : '372818000',
  'MOL EXPLORER' : '372827000',
  'MSC FIAMMETTA' : '372843000',
  'MSC GABRIELLA' : '372862000',
  'SITC RIZHAO' : '372925000',
  'GANG TONG 19' : '372926000',
  'MCC CEBU' : '372932000',
  'COSCO BOSTON' : '372934000',
  'SWIMMER' : '372946000',
  'MOL EXPERIENCE' : '372955000',
  'MSC BANU III' : '372972000',
  'MSC PINA' : '372973000',
  'MSC FLAVIA' : '373004000',
  'MSC VANDYA' : '373005000',
  'MSC SHAY' : '373021000',
  'MSC CAPELLA' : '373031000',
  'NUMBER 9' : '373044000',
  'MSC REGULUS' : '373068000',
  'MSC AURORA' : '373069000',
  'HE SHENG' : '373080000',
  'ONE HELSINKI' : '373119000',
  'SKY FLOWER' : '373126000',
  'SKY HOPE' : '373128000',
  'ONYX 1' : '373136000',
  'ORITA' : '373184000',
  'KMTC INCHEON' : '373220000',
  'MSC CLORINDA' : '373233000',
  'MAERSK NARVIK' : '373260000',
  'MAERSK IYO' : '373271000',
  'EVER FRONT' : '373285000',
  'MSC SUPRANA F' : '373314000',
  'XIN HE DA' : '373316000',
  'EVER COPE' : '373319000',
  'MSC ARIANE' : '373355000',
  'MSC KATRINA' : '373404000',
  'MOL MAESTRO' : '373457000',
  'SPIL CITRA' : '373459000',
  'ONE HOUSTON' : '373485000',
  'MSC DYMPHNA' : '373517000',
  'MSC GIANNINA II' : '373579000',
  'MSC CELINE' : '373582000',
  'KMTC PENANG' : '373595000',
  'MSC AMSTERDAM' : '373598000',
  'ONE HONOLULU' : '373649000',
  'EVER FAME' : '373712000',
  'EVER LADEN' : '373721000',
  'SING' : '373839000',
  'ONE MUNCHEN' : '373911000',
  'ONE HANGZHOU BAY' : '373932000',
  'HONG PROSPERITY' : '374091000',
  'EVER FOCUS' : '374158000',
  'NZ NINGBO' : '374239000',
  'BLPL TRUST' : '374286000',
  'SUNNY ROSE' : '374302000',
  'MSC TIGER F' : '374391000',
  'SUNNY CLOVER' : '374431000',
  'FILOTIMO' : '374508000',
  'SOL PROMISE' : '374571000',
  'GREEN HOPE' : '374588000',
  'MSC ARINA' : '374607000',
  'JVK STAR' : '374629000',
  'MSC SVEVA' : '374645000',
  'CAP SAN TAINARO' : '374658000',
  'MSC CLARA' : '374766000',
  'NAVIOS MAGNOLIA' : '374776000',
  'KMTC NAGOYA' : '374782000',
  'ONE IBIS' : '374815000',
  'HAMBURG TRADER' : '374855000',
  'ONE AQUILA' : '374858000',
  'MSC MAYA' : '374859000',
  'MSC HAMBURG' : '374886000',
  'EVER BIRTH' : '374898000',
  'TAMPA TRIUMPH' : '374935000',
  'MSC ULSAN III' : '374946000',
  'LEO' : '375172000',
  'GFS JUNO' : '375751000',
  'ACE' : '376846000',
  'INTER SYDNEY' : '376908000',
  'GFS JADE' : '377311000',
  'ARIES' : '377523000',
  'GFS RANNA' : '377751000',
  'LIBRA' : '377782000',
  'TROPIC FREEDOM' : '377901104',
  'TROPIC HOPE' : '377901107',
  'TROPIC GEM' : '377901110',
  'TROPIC ISLAND' : '377901111',
  'TROPIC JEWEL' : '377901119',
  'TROPIC LISSETTE' : '377901123',
  'M.V. HARBOUR-1' : '405000190',
  'INVICTA 1' : '405000223',
  'HR SARERA' : '405000291',
  'HR SAHARE' : '405000292',
  'HR RHEA' : '405000308',
  'HR HER' : '405000309',
  'HR FARHA' : '405000337',
  'HR AARAI' : '405000338',
  'HR BALU' : '405000384',
  'HR TURAG' : '405000386',
  'XIN HE SHI BA' : '412123456',
  'DA FU' : '412200640',
  'TIAN JIN HE' : '412633000',
  'TIAN SHENG HE' : '412658000',
  'HUA HANG 1' : '412702640',
  'XIN LONG YUN 86' : '412705880',
  'TIAN KANG HE' : '412713000',
  'TIAN AN HE' : '412714000',
  'TIAN FU HE' : '412746000',
  'TIAN QING HE' : '412750000',
  'XINMINGZHOU78' : '412767360',
  'XIN MING ZHOU 82' : '412767750',
  'XIN DA LIAN' : '413050000',
  'XIN TIAN JIN' : '413053000',
  'XIN QING DAO' : '413054000',
  'XIN CHONG QING' : '413055000',
  'XIN NING BO' : '413057000',
  'XIN LIAN YUN GANG' : '413058000',
  'XIN YANG ZHOU' : '413059000',
  'XIN PU DONG' : '413060000',
  'XIN NAN TONG' : '413063000',
  'XIN YAN TIAN' : '413064000',
  'XIN XIA MEN' : '413065000',
  'XIN SU ZHOU' : '413068000',
  'XIN CHI WAN' : '413072000',
  'XIN QIN HUANG DAO' : '413073000',
  'XIN FU ZHOU' : '413076000',
  'HY4' : '413123456',
  'XIN YAN TAI' : '413125000',
  'XIN YANG SHAN' : '413131000',
  'XIN QUAN ZHOU' : '413132000',
  'XIN CHANG SHU' : '413133000',
  'XIN FANG CHENG' : '413138000',
  'XIN SHAN TOU' : '413141000',
  'XIN HUANG PU' : '413142000',
  'XIN HAI KOU' : '413144000',
  'XIN BEI LUN' : '413145000',
  'XIN CHANG SHA' : '413146000',
  'XIN NAN SHA' : '413147000',
  'XIN RI ZHAO' : '413148000',
  'XIN WEI HAI' : '413149000',
  'XIN ZHAN JIANG' : '413150000',
  'XIN YING KOU' : '413151000',
  'XIN DAN DONG' : '413153000',
  'XIN OU ZHOU' : '413159000',
  'XIN YA ZHOU' : '413161000',
  'XIN MEI ZHOU' : '413165000',
  'XIN TAI CANG' : '413166000',
  'XIN YANG PU' : '413167000',
  'XIN WU HAN' : '413169000',
  'XIN ZHANG ZHOU' : '413170000',
  'XIN FEI ZHOU' : '413171000',
  'XIN DA YANG ZHOU' : '413173000',
  'JI FA NAN HAI' : '413205380',
  'JI FA BEI HAI' : '413205520',
  'REN JIAN FU ZHOU' : '413209990',
  'HAN HAI 8 HAO' : '413211430',
  'ZHONG GU NAN HAI' : '413213320',
  'ZHONG GU DONG HAI' : '413215160',
  'ZHONGWAIYUN SHENZHEN' : '413218940',
  'ZHONG WAI YUN NINGBO' : '413219020',
  'ZHONG GU HUANG HAI' : '413220120',
  'ZHONG GU BO HAI' : '413222810',
  'ZHONG GU BEI HAI' : '413223320',
  'ZHONG GU DIZHONG HAI' : '413228080',
  'JOSCO XINGFU' : '413230310',
  'ZHONG HANG SHENG' : '413238570',
  'ZHONG GU LIN YI' : '413247480',
  'HAN HAI 5 HAO' : '413256960',
  'HUA SHENG 67' : '413262830',
  'ZHONG GU DONG GUAN' : '413263160',
  'HAI FENG HAI KOU' : '413274380',
  'HUA XIN 968' : '413275260',
  'TIAN HAI PING ZE' : '413280820',
  'JIANG YUAN YUAN DA' : '413281350',
  'JOSCO BELLE' : '413284260',
  'XIN MING ZHOU 98' : '413293840',
  'QING YUN HE' : '413295960',
  'XIN MING ZHOU 102' : '413297720',
  'XIN MING ZHOU 88' : '413298050',
  'TIAN FU(TIANJIN)' : '413301510',
  'XIN MING ZHOU 90' : '413307840',
  'XIN MING ZHOU 106' : '413310040',
  'XIN MING ZHOU 92' : '413314440',
  'HAI FENG LIAN XING' : '413329850',
  'FEI YUN HE' : '413333930',
  'HUA HANG HAN YA 1' : '413334630',
  'XIN MING ZHOU 108' : '413336090',
  'XIN MING ZHOU 96' : '413337240',
  'ZHONG GU XIA MEN' : '413338660',
  'GUANGZHOU' : '413342860',
  'HONG TAI 639' : '413343250',
  'REN JIAN DA LIAN' : '413346480',
  'XIUHONG' : '413374510',
  'HAILIANSHENG' : '413376690',
  'ZHONGWAIYUNXINGANG' : '413377680',
  'HONG YUAN 01' : '413377920',
  'ZHONG GU SHANG HAI' : '413378770',
  'HAI MEN' : '413380220',
  'ZHONG GU ZHU HAI' : '413380750',
  'ZHONG GU PENG LAI' : '413380760',
  'ZHONG GU DA LIAN' : '413381170',
  'ZHONG GU TIAN JIN' : '413381180',
  'ZHONG GU YING KOU' : '413381250',
  'ZHONG GU XIONG AN' : '413381280',
  'ZHONGGU BEIJING' : '413381290',
  'ZHONG GU RI ZHAO' : '413381440',
  'QIU JIN' : '413381750',
  'TENG YUN HE' : '413381830',
  'CHUN JIN' : '413382030',
  'LINGYUNHE' : '413383270',
  'HONG TAI637' : '413384190',
  'DONG FANG FU' : '413393620',
  'KAI HE ZHI CHENG' : '413405430',
  'MING HAO' : '413439580',
  'HE YANG' : '413453290',
  'HONG DA XIN 58' : '413453390',
  'XIN MING ZHOU 18' : '413453640',
  'XIN MING ZHOU 20' : '413453650',
  'HEBIN' : '413454360',
  'HE JIN' : '413455660',
  'XIN MING ZHOU 22' : '413455950',
  'XIN MING ZHOU 26' : '413456130',
  'XIN MING ZHOU76' : '413458850',
  'XIN MING ZHOU80' : '413459620',
  'ZHONGGUGUANGZHOU' : '413495140',
  'HUA HANG HAN YA 6' : '413497490',
  'HUA HANG HAN YA 5' : '413497770',
  'MAO GANG XIA MEN' : '413519440',
  'REN JIAN 15' : '413526530',
  'ZHONG LIAN SHAN TOU' : '413528620',
  'XIN XIN SHAN' : '413536120',
  'HONG DA XIN 778' : '413548380',
  'ZHUCHENGXINZHOU' : '413556780',
  'FENG XIN DA 29' : '413558710',
  'CA TOKYO' : '413560380',
  'YANGTZE TRADER' : '413564860',
  'XIAO JIANG' : '413690160',
  'HUA HANG 3' : '413692490',
  'RENJIAN5' : '413695730',
  'DONG FANG QIANG' : '413695760',
  'RENJIAN6' : '413695770',
  'REN JIAN GUANG ZHOU' : '413698170',
  'YONG XIN 101' : '413699570',
  'SHI SHANG 18' : '413700420',
  'JINSHUNHE' : '413701060',
  'ZE HONG' : '413701260',
  'CI TONG 1' : '413701950',
  'WAN XING DA' : '413702160',
  'WANFUDA' : '413702170',
  'HAISU6' : '413703630',
  'HAI SU 7' : '413703960',
  'REN JIAN TANG SHAN' : '413704320',
  'REN JIAN RI ZHAO' : '413705230',
  'TIAN BAO HE' : '413760000',
  'TIAN LONG HE' : '413761000',
  'TIAN XING HE' : '413762000',
  'TIAN YUN HE' : '413763000',
  'HUA FEI LUN 009' : '413790872',
  'GUIGANGXINGTAI15' : '413798794',
  'TIAN LI HE' : '413965000',
  'TIAN XIU HE' : '413966000',
  'REN JIAN 10' : '413996000',
  'RENJIAN8' : '414195000',
  'RI ZHAO SHENG SHI' : '414201000',
  'RI ZHAO HONG YUN' : '414209000',
  'ZHONG GU JI LIN' : '414239000',
  'XIN BIN ZHOU' : '414240000',
  'ZHONG GU SHAN DONG' : '414246000',
  'ZHONG AN XIN HUAYUAN' : '414257000',
  'REN JIAN 17' : '414293000',
  'TIAN XIANG HE' : '414303000',
  'TIAN SHUN HE' : '414304000',
  'TIAN CHANG HE' : '414310000',
  'FAN YA NING DE' : '414328000',
  'REN JIAN 19' : '414333000',
  'REN JIAN 20' : '414334000',
  'FAN YA GUANG ZHOU' : '414342000',
  'FAN YA TIAN JIN' : '414345000',
  'REN JIAN 23' : '414346000',
  'ZHONG GU FU JIAN' : '414349000',
  'FAN YA SHANG HAI' : '414350000',
  'REN JIAN 25' : '414362000',
  'REN JIAN 26' : '414365000',
  'REN JIAN 27' : '414366000',
  'XIN MING ZHOU 86' : '414400430',
  'HENG HUI 5' : '414626000',
  'HENG HUI 6' : '414653000',
  'ZHONG GU JI NAN' : '414654000',
  'ZHONG GU NAN NING' : '414661000',
  'HUA DA 610' : '414664000',
  'ZHONG GU FU ZHOU' : '414672000',
  'ZHONG GU HANG ZHOU' : '414676000',
  'LIANG XIANG 82' : '414682000',
  'ZHONG GU NAN JING' : '414685000',
  'ZHONG GU YIN CHUAN' : '414688000',
  'REN JIAN 16' : '414693000',
  'XIN QIN ZHOU' : '414700000',
  'XIN HANG ZHOU' : '414713000',
  'XIN ZHENG ZHOU' : '414714000',
  'XIN LAN ZHOU' : '414721000',
  'XIN WEN ZHOU' : '414736000',
  'XIN XU ZHOU' : '414752000',
  'XIN CANG ZHOU' : '414758000',
  'XIN HUI ZHOU' : '414760000',
  'ZHONG GU SHEN YANG' : '414793000',
  'CHANG SHUN JIN XIU' : '414798000',
  'ZHONG GU NAN CHANG' : '414806000',
  'ZHONG GU CHANG CHUN' : '414807000',
  'ZHONG GU LAN ZHOU' : '414808000',
  'CHANGSHUN QIANCHENG' : '414816000',
  'ZHONG GU XI AN' : '414821000',
  'ZHONG GU WU HAN' : '414830000',
  'HONG TAI 869' : '414841000',
  'ZHONG GU CHANG SHA' : '414842000',
  'ZHONG GU CHONG QING' : '414849000',
  'XIN YANG ZI 461' : '414861000',
  'ZHONG GU CHENG DU' : '414877000',
  'CHANG AN RUN' : '414885000',
  'ALS LUNA' : '414888000',
  'XIN YANG ZI465' : '414909000',
  'TEST' : '414987512',
  'EVER BLESS' : '416003900',
  'WAN HAI 103' : '416016000',
  'KUO CHANG' : '416020000',
  'FORMOSACONTAINER NO4' : '416024000',
  'EVER BALMY' : '416031000',
  'EVER BASIS' : '416032000',
  'EVER BEADY' : '416033000',
  'EVER BEAMY' : '416034000',
  'EVER BLOOM' : '416035000',
  'EVER BEFIT' : '416036000',
  'EVER BEING' : '416037000',
  'EVER BOARD' : '416038000',
  'EVER BLINK' : '416040000',
  'WAN HAI 232' : '416047000',
  'EVER CREATE' : '416061000',
  'EVER CONVEY' : '416063000',
  'EVER CAREER' : '416064000',
  'EVER ARM' : '416070000',
  'EVER ART' : '416071000',
  'WAN HAI 101' : '416075000',
  'TEH VICTORY' : '416077000',
  'WAN HAI 275' : '416078000',
  'WAN HAI 233' : '416335000',
  'WAN HAI 235' : '416337000',
  'UNI ACTIVE' : '416340000',
  'UNI ADROIT' : '416341000',
  'YM HEIGHTS' : '416426000',
  'YM HARMONY' : '416427000',
  'YM HORIZON' : '416428000',
  'YM HAWK' : '416429000',
  'KANWAY GLOBAL' : '416438000',
  'UNI-PROMOTE' : '416453000',
  'UNI PROBITY' : '416456000',
  'UNI-PROSPER' : '416459000',
  'YM UNICORN' : '416464000',
  'YM UPSURGENCE' : '416465000',
  'YM UNANIMITY' : '416466000',
  'YM UBIQUITY' : '416467000',
  'YM UNIFORMITY' : '416468000',
  'EVER LIBRA' : '416475000',
  'EVER LIVEN' : '416481000',
  'EVER LOGIC' : '416482000',
  'YM INTELLIGENT' : '416486000',
  'YM INAUGURATION' : '416487000',
  'YM IDEALS' : '416488000',
  'YM EVOLUTION' : '416490000',
  'YM ESSENCE' : '416491000',
  'YM IMMENSE' : '416492000',
  'EVER LOYAL' : '416495000',
  'EVER LUNAR' : '416497000',
  'EVER LYRIC' : '416498000',
  'EVER LUCID' : '416509000',
  'SSL KOCHI' : '419000623',
  'SSL MUMBAI' : '419000914',
  'SSL GUJARAT' : '419000970',
  'SSL BHARAT' : '419001019',
  'SAN PRIDE' : '419001093',
  'SSL VISAKHAPATNAM' : '419001126',
  'SSL DELHI' : '419001250',
  'SSL BRAHMAPUTRA' : '419001324',
  'TCI EXPRESS' : '419001352',
  'SSL KRISHNA' : '419001369',
  'MOGRAL' : '419001380',
  'VARADA' : '419001386',
  'TCI ANAND' : '419001483',
  'SM KAVERI' : '419001574',
  'SM MAHI' : '419001615',
  'SM NEYYAR' : '419001618',
  'SM MANALI' : '419001631',
  'SSL GODAVARI' : '419001809',
  'SSL KAVERI' : '419001812',
  'SSL THAMIRABARANI' : '419001814',
  'SCI MUMBAI' : '419738000',
  'SCI CHENNAI' : '419739000',
  'YARAN' : '422021500',
  'SHAMIM' : '422026500',
  'GOLBON' : '422026900',
  'SHIBA' : '422027500',
  'SHABDIS' : '422031200',
  'BEHTA' : '422031300',
  'FLORA' : '422031500',
  'ABYAN' : '422031600',
  'ZARDIS' : '422031700',
  'BEHNAVAZ' : '422032200',
  'SHABGOUN' : '422032300',
  'TOUSKA' : '422032600',
  'BASHT' : '422032700',
  'ARTAM' : '422038800',
  'ARZIN' : '422038900',
  'ARTABAZ' : '422039100',
  'ARTENOS' : '422039200',
  'AZARGOUN' : '422039300',
  'SEPEHR PAYAM' : '422058200',
  'KASHAN' : '422068100',
  'RAYEN' : '422153400',
  'RADIN' : '422153500',
  'BARZIN' : '422154100',
  'HAMOUNA' : '422154200',
  'IRAN SHAHED' : '422517000',
  'ABTIN 1' : '422812000',
  'SHAYAN 1' : '422813000',
  'TABAN1' : '422814000',
  'DAISY' : '422905000',
  'ZIM VIRGINIA' : '428002000',
  'MAIKO' : '431000527',
  'ONE APUS' : '431003000',
  'NAHA2' : '431004022',
  'SHINSETO' : '431004911',
  'SAGAMI' : '431004956',
  'NATORI' : '431007132',
  'SAKURA' : '431008189',
  'MISAKI' : '431008693',
  'RYUNAN' : '431009828',
  'SHIGENOBU' : '431010468',
  'UMIKAJI' : '431010652',
  'NAGARA' : '431011592',
  'NOGAMI' : '431019062',
  'KISO' : '431021728',
  'KAIFU' : '431022362',
  'ONE THESEUS' : '431027000',
  'ONE CYGNUS' : '431073000',
  'ONE BLUE JAY' : '431080000',
  'ONE MANEUVER' : '431120000',
  'BROOKLYN BRIDGE' : '431187000',
  'ONE MACKINAC' : '431241000',
  'ONE MANHATTAN' : '431260000',
  'MOL COURAGE' : '431296000',
  'KOYO' : '431301812',
  'ONE COMPETENCE' : '431315000',
  'ONE COMMITMENT' : '431332000',
  'HYOGO' : '431402037',
  'TENMA' : '431402072',
  'KAMIWAKA' : '431501816',
  'IKUTA' : '431501857',
  'ONE EAGLE' : '431552000',
  'ONE GRUS' : '431654000',
  'ONE COLUMBA' : '431655000',
  'ONE ALTAIR' : '431710000',
  'ONE OWL' : '431772000',
  'ONE TRIBUTE' : '431787000',
  'ONE FALCON' : '431792000',
  'ONE TRADITION' : '431808000',
  'ONE WREN' : '431888000',
  'ONE MINATO' : '431929000',
  'ONE MADRID' : '431981000',
  'ONE STORK' : '431986000',
  'ONE MEISHAN' : '431989000',
  'HOCHIMINH VOYAGER' : '440006000',
  'HYUNDAI DRIVE' : '440009000',
  'SAWASDEE ATLANTIC' : '440017000',
  'SM QINGDAO' : '440022000',
  'SM TOKYO' : '440059000',
  'HMM CHITTAGONG' : '440061000',
  'HYUNDAI OAKLAND' : '440062000',
  'DOOWOO FAMILY' : '440065000',
  'SAWASDEE THAILAND' : '440075000',
  'SM KWANGYANG' : '440086000',
  'SKY RAINBOW' : '440088000',
  'SM TIANJIN' : '440111000',
  'HMM PROMISE' : '440112000',
  'HMM BLESSING' : '440117000',
  'SUNNY PALM' : '440119000',
  'HEUNG-A ULSAN' : '440120000',
  'SM BUSAN' : '440141000',
  'KMTC HOCHIMINH' : '440147000',
  'SM NINGBO' : '440151000',
  'PORT KLANG VOYAGER' : '440162000',
  'HONGKONG VOYAGER' : '440166000',
  'HYUNDAI HOPE' : '440176000',
  'HMM DHAKA' : '440185000',
  'SM YANTIAN' : '440189000',
  'KHARIS HERITAGE' : '440190000',
  'KM HAPPY STAR 1' : '440197000',
  'SM JAKARTA' : '440206000',
  'SM SHANGHAI' : '440220000',
  'PEGASUS PETA' : '440221000',
  'HMM HAMBURG' : '440223000',
  'SAWASDEE SUNRISE' : '440251000',
  'OSAKA VOYAGER' : '440273000',
  'SKY CHALLENGE' : '440278000',
  'GRACE BRIDGE' : '440279000',
  'SKY VICTORIA' : '440280000',
  'SENDAI TRADER' : '440284000',
  'BEIJING BRIDGE' : '440287000',
  'HMM HELSINKI' : '440288000',
  'YOKOHAMA TRADER' : '440301000',
  'KOBE TRADER' : '440303000',
  'QINGDAO TRADER' : '440309000',
  'HMM MONGLA' : '440313000',
  'SAWASDEE BALTIC' : '440316000',
  'SAWASDEE PACIFIC' : '440321000',
  'HMM ALGECIRAS' : '440326000',
  'HMM ROTTERDAM' : '440328000',
  'SHANGHAI VOYAGER' : '440330000',
  'HEUNG-A TOKYO' : '440335000',
  'NINGBO TRADER' : '440337000',
  'JAKARTA VOYAGER' : '440342000',
  'DONGJIN ENTERPRISE' : '440346000',
  'HMM DUBLIN' : '440348000',
  'POS YOKOHAMA' : '440349000',
  'HMM GDANSK' : '440350000',
  'HMM STOCKHOLM' : '440361000',
  'HMM SOUTHAMPTON' : '440363000',
  'STAR VOYAGER' : '440366000',
  'PANCON VICTORY' : '440375000',
  'STAR CHALLENGER' : '440401000',
  'POS TOKYO' : '440473000',
  'KMTC JAKARTA' : '440491000',
  'KMTC SINGAPORE' : '440493000',
  'GLOBAL NUBIRA' : '440505000',
  'NAGOYA TRADER' : '440516000',
  'PANCON SUNSHINE' : '440550000',
  'HYUNDAI GOODWILL' : '440559000',
  'PEGASUS YOTTA' : '440562000',
  'SUNNY OAK' : '440567000',
  'POS SHANGHAI' : '440572000',
  'KMTC KEELUNG' : '440585000',
  'PANCON CHAMPION' : '440655000',
  'DONGJIN VOYAGER' : '440716000',
  'KMTC MANILA' : '440728000',
  'KMTC NHAVA SHEVA' : '440761000',
  'KMTC JEBEL ALI' : '440764000',
  'KMTC CHENNAI' : '440838000',
  'HEUNG-A YOUNG' : '440965000',
  'PEGASUS UNIX' : '441030000',
  'SUNNY SPRUCE' : '441107000',
  'HYUNDAI VANCOUVER' : '441111000',
  'TY INCHEON' : '441127000',
  'SUNNY MAPLE' : '441158000',
  'KMTC PUSAN' : '441174000',
  'KMTC MUMBAI' : '441225000',
  'DONGJIN FORTUNE' : '441236000',
  'KMTC DUBAI' : '441248000',
  'KMTC NINGBO' : '441252000',
  'DONGJIN FIDES' : '441261000',
  'KMTC MUNDRA' : '441266000',
  'PANCON HARMONY' : '441271000',
  'DONGJIN CONTINENTAL' : '441283000',
  'KMTC COLOMBO' : '441293000',
  'SUNNY LINDEN' : '441300000',
  'HECAN' : '441387000',
  'SHECAN' : '441388000',
  'HYUNDAI JAKARTA' : '441398000',
  'HYUNDAI COLOMBO' : '441399000',
  'KMTC DELHI' : '441401000',
  'SM PORTLAND' : '441403000',
  'PANCON BRIDGE' : '441420000',
  'KMTC SEOUL' : '441421000',
  'DONGJIN CONFIDENT' : '441424000',
  'STAR EXPRESS' : '441432000',
  'STAR PIONEER' : '441433000',
  'WECAN' : '441442000',
  'SUNNY DAHLIA' : '441485000',
  'HYUNDAI PRIDE' : '441486000',
  'KMTC SHENZHEN' : '441502000',
  'ANTWERP BRIDGE' : '441548000',
  'PEGASUS PACER' : '441567000',
  'PEGASUS PRIME' : '441568000',
  'SM LONG BEACH' : '441701000',
  'KMTC QINGDAO' : '441702000',
  'HYUNDAI INTEGRAL' : '441736000',
  'HYUNDAI VICTORY' : '441754000',
  'SINOKOR NIIGATA' : '441772000',
  'SINOKOR HONGKONG' : '441777000',
  'KMTC ULSAN' : '441788000',
  'DONGJIN VENUS' : '441805000',
  'PANCON GLORY' : '441872000',
  'PANCON SUCCESS' : '441875000',
  'SAWASDEE SINGAPORE' : '441885000',
  'KMTC SHANGHAI' : '441902000',
  'KMTC TIANJIN' : '441933000',
  'VOSTOCHNY VOYAGER' : '441934000',
  'SINOKOR QINGDAO' : '441952000',
  'HANSUNG INCHEON' : '441953000',
  'HAKATA VOYAGER' : '441973000',
  'HYUNDAI DREAM' : '441981000',
  'BOUN1' : '445091000',
  'THO SONG' : '445879000',
  'PRINCESS M' : '450617000',
  'PROSPER' : '457130000',
  'DONG HO' : '457583000',
  'AL BIDDA' : '466055000',
  'AL RUMEILA' : '466057000',
  'OSHAIRIJ' : '466061000',
  'MILAHA 1' : '466073000',
  'MAJD' : '466543000',
  'AL SADR-I' : '470143000',
  'SAFEEN PRINCIPAL' : '470305000',
  'AL BAZM II' : '470552000',
  'AL REEM-I' : '470752000',
  'ZIM MOUNT OLYMPUS' : '477000000',
  'MSC AGADIR' : '477001100',
  'COSCO PIRAEUS' : '477001700',
  'YM WIND' : '477002200',
  'KMTC TAIPEIS' : '477002300',
  'SINOTRANS BEIJING' : '477003200',
  'COSCO WELLINGTON' : '477004600',
  'COSCO VENICE' : '477004700',
  'YM WISH' : '477006500',
  'MILD TEMPO' : '477006600',
  'OOCL BUSAN' : '477007100',
  'SINOTRANS KAOHSIUNG' : '477010600',
  'SEASPAN HAMBURG' : '477014800',
  'SITC HAKATA' : '477016900',
  'SEASPAN FELIXSTOWE' : '477020100',
  'CSCL SYDNEY' : '477020200',
  'SEASPAN OCEANIA' : '477020300',
  'CSCL AFRICA' : '477020400',
  'KOTA JOHAN' : '477021100',
  'SITC LIANYUNGANG' : '477024800',
  'SITC SHENZHEN' : '477024900',
  'SITC HANSHIN' : '477027100',
  'COSCO FRANCE' : '477030700',
  'OOCL GERMANY' : '477035800',
  'OOCL TEXAS' : '477036600',
  'COSCO SHIPPING VOLGA' : '477043200',
  'TANG SHAN GANG JI 1' : '477045600',
  'GLORY OCEAN' : '477047100',
  'COSCO OCEANIA' : '477056400',
  'OOCL PANAMA' : '477056600',
  'COSCO SHIPPING SEINE' : '477057100',
  'TANG SHAN GANG JI2' : '477057600',
  'HUA HANG 6' : '477057900',
  'KOKOPO CHIEF' : '477062000',
  'COSCO FORTUNE' : '477065200',
  'CORAL CHIEF' : '477066300',
  'SUNNY CAMELLIA' : '477066600',
  'SEASPAN BEACON' : '477066700',
  'OOCL TAIPEI' : '477067800',
  'OOCL UTAH' : '477067900',
  'CSCL BRISBANE' : '477070800',
  'MAERSK NILE' : '477071700',
  'SEASPAN NEW DELHI' : '477076300',
  'COSCO HAIFA' : '477077700',
  'COSCO GENOA' : '477077800',
  'SITC SHANGHAI' : '477078600',
  'OSG BOSSTEC' : '477079500',
  'MAERSK CHILKA' : '477083700',
  'MAERSK CHAMBAL' : '477083800',
  'OSG BEAUTEC' : '477089200',
  'YM WREATH' : '477096300',
  'SINOTRANS KEELUNG' : '477096400',
  'SEASPAN DUBAI' : '477097400',
  'COSCO HIMALAYAS' : '477100300',
  'OOCL JAPAN' : '477100400',
  'OOCL UNITED KINGDOM' : '477103800',
  'SEASPAN JAKARTA' : '477105300',
  'OOCL ASIA' : '477105600',
  'SEASPAN SAIGON' : '477106800',
  'COSCO PACIFIC' : '477107200',
  'SINOTRANS OSAKA' : '477107900',
  'COSCO FAITH' : '477108100',
  'SINOTRANS HONG KONG' : '477109800',
  'MAERSK NANSHA' : '477111700',
  'CSCL MONTEVIDEO' : '477111800',
  'SITC QINZHOU' : '477112300',
  'SINOTRANS MANILA' : '477112600',
  'MILD CHORUS' : '477117100',
  'CSCL WINTER' : '477117400',
  'CSCL AUTUMN' : '477117900',
  'SWAN LAKE' : '477118100',
  'COSCO SHIPPING RHINE' : '477118600',
  'KOTA MAKMUR' : '477121100',
  'OOCL SCANDINAVIA' : '477121200',
  'SEASPAN BREEZE' : '477127900',
  'YM WIDTH' : '477129300',
  'INTERASIAINSPIRATION' : '477133700',
  'COSCO EXCELLENCE' : '477135600',
  'COSCO KAOHSIUNG' : '477136800',
  'SITC KANTO' : '477137700',
  'COSCO TAURUS' : '477139100',
  'SINOTRANS BANGKOK' : '477139800',
  'COSCO KILIMANJARO' : '477139900',
  'CSCL LIMA' : '477141200',
  'MAERSK ROUBAIX' : '477143100',
  'MAERSK REGENSBURG' : '477143400',
  'MAERSK NADI' : '477144700',
  'MAERSK NEWARK' : '477144800',
  'OOCL INDONESIA' : '477147600',
  'COSCO SHIPPING ALPS' : '477148700',
  'COSCO SHIPPING ARIES' : '477148800',
  'XIN XIN TIAN 2' : '477150700',
  'JOSCO LUCKY' : '477151200',
  'SEASPAN FALCON' : '477154200',
  'SALVADOR EXPRESS' : '477154300',
  'ACACIA HAWK' : '477154400',
  'JOSCO REAL' : '477154500',
  'JOSCO SHINE' : '477154600',
  'COSCO GEMINI' : '477154700',
  'COSCO UNIVERSE' : '477157400',
  'CMA CGM MUNDRA' : '477157700',
  'COSCO SHIPPING LEO' : '477157800',
  'XIN LOS ANGELES' : '477158700',
  'CMA CGM MUMBAI' : '477159100',
  'SEASPAN HARRIER' : '477159600',
  'SEASPAN RAPTOR' : '477159700',
  'JRS CARINA' : '477159900',
  'CMA CGM CHENNAI' : '477166300',
  'COSCO SHIPPING VIRGO' : '477166500',
  'COSCOSHIPPING DENALI' : '477166600',
  'CMA CGM COCHIN' : '477166700',
  'SITC HAIPHONG' : '477167800',
  'COSCO SHIPPING PEONY' : '477168400',
  'COSCO CAPRICORN' : '477168900',
  'COSCO SHIPPING LIBRA' : '477172700',
  'MAERSK CAIRO' : '477174600',
  'MAERSK CUBANGO' : '477174700',
  'MAERSK CHACHAI' : '477174900',
  'MAERSK TAIKUNG' : '477178200',
  'MAERSK TANJONG' : '477178400',
  'MAERSK BINTAN' : '477178900',
  'SEASPAN BELLWETHER' : '477181200',
  'GLORY SHANGHAI' : '477181900',
  'OOCL POLAND' : '477182200',
  'OOCL BRUSSELS' : '477182300',
  'OOCL MIAMI' : '477182400',
  'SITC FANGCHENG' : '477182500',
  'COSCO SCORPIO' : '477183800',
  'COSCO JASMINE' : '477183900',
  'COSCO TAICANG' : '477189300',
  'OOCL NORFOLK' : '477189700',
  'SITC QINGDAO' : '477190600',
  'SUNNY CALLA' : '477193400',
  'COSCO SAGITTARIUS' : '477194100',
  'COSCO NEBULA' : '477194400',
  'COSCO SHIPPING ANDES' : '477194600',
  'COSCO SHIPPING ROSE' : '477194800',
  'MAERSK VIRGINIA' : '477195100',
  'JJ STAR' : '477201600',
  'OOCL BERLIN' : '477203100',
  'SITC HOCHIMINH' : '477203300',
  'SITC YANTAI' : '477203700',
  'MAERSK NEW DELHI' : '477207300',
  'SEASPAN LONCOMILLA' : '477207400',
  'SEASPAN LUMACO' : '477207600',
  'SEASPAN EMERALD' : '477207700',
  'SITC TOYOHASHI' : '477209100',
  'SAFMARINE BAYETE' : '477211100',
  'CSCL JUPITER' : '477213400',
  'SEASPAN CHIBA' : '477213800',
  'GERHARD SCHULTE' : '477214400',
  'OOCL EUROPE' : '477214700',
  'WAN HAI 171' : '477217400',
  'SITC NINGBO' : '477218900',
  'SINOTRANS TIANJIN' : '477219400',
  'COSCO NETHERLANDS' : '477219900',
  'OOCL MALAYSIA' : '477220100',
  'OOCL BANGKOK' : '477220200',
  'OOCL MEMPHIS' : '477222600',
  'OOCL FRANCE' : '477222700',
  'NEW MINGZHOU 16' : '477224100',
  'WAN HAI 172' : '477225300',
  'CSCL MERCURY' : '477229400',
  'COSCO SAKURA' : '477232200',
  'COSCO PISCES' : '477232800',
  'TRADER' : '477233100',
  'KOTA PURI' : '477233200',
  'COSCO AZALEA' : '477233300',
  'SITC TOKUYAMA' : '477233700',
  'SITC SENDAI' : '477233800',
  'DELPHIS RIGA' : '477234800',
  'SEASPAN LAHORE' : '477241800',
  'SINOTRANS SHANGHAI' : '477242600',
  'SITC BUSAN' : '477250600',
  'NEW MINGZHOU 12' : '477250700',
  'CSCL SPRING' : '477257100',
  'CSCL VENUS' : '477266800',
  'COSCO VIETNAM' : '477266900',
  'COSCO GALAXY' : '477269300',
  'COSCO SHIPPING LOTUS' : '477269400',
  'SITC SUBIC' : '477269800',
  'CSCL YELLOW SEA' : '477271300',
  'COSCO PORTUGAL' : '477271400',
  'SEASPAN BRAVO' : '477271900',
  'CSCL SATURN' : '477274400',
  'OOCL NEW YORK' : '477274700',
  'SITC KWANGYANG' : '477274900',
  'OOCL CANADA' : '477276600',
  'DONG FANG SHUN' : '477281000',
  'OOCL ZHOUSHAN' : '477282300',
  'XIN SHANGHAI' : '477282900',
  'COSCOSHIPPING ORCHID' : '477286200',
  'COSCO CAMELLIA' : '477286300',
  'KOTA PUSAKA' : '477286500',
  'OOCL KOREA' : '477293100',
  'OOCL SINGAPORE' : '477293200',
  'CSCL SUMMER' : '477293900',
  'SITC HEBEI' : '477301900',
  'SEASPAN HANNOVER' : '477302200',
  'SITC TAICANG' : '477302300',
  'KOTA LUKIS' : '477302500',
  'KOTA LUMBA' : '477302600',
  'KOTA LUMAYAN' : '477302800',
  'COSCO SHIPPING SOLAR' : '477302900',
  'MSC ANTIGUA' : '477305900',
  'MSC RENEE' : '477307300',
  'MSC MARGRIT' : '477307400',
  'SEASPAN BENEFACTOR' : '477307900',
  'COSCO AQUARIUS' : '477308100',
  'SEASPAN LOGA' : '477308400',
  'COSCO HOUSTON' : '477311500',
  'COSCO COLOMBO' : '477311600',
  'SITC SHIDAO' : '477312800',
  'OOCL HOCHIMINH CITY' : '477314100',
  'XINDE KEELUNG' : '477314300',
  'YM WINNER' : '477314700',
  'COSCO SHIPPING STAR' : '477318200',
  'COSCO SANTOS' : '477319300',
  'OOCL MONTREAL' : '477321000',
  'SUNNY FREESIA' : '477326200',
  'GLORY GUANDONG' : '477333400',
  'OOCL HONG KONG' : '477333500',
  'COSCOSHIPPING THAMES' : '477333900',
  'SITC SHIMIZU' : '477335600',
  'COSCO PLANET' : '477345900',
  'HENRIKA SCHULTE' : '477346500',
  'GLORY TIANJIN' : '477347200',
  'YM WORTH' : '477347400',
  'SITC KEELUNG' : '477347900',
  'SEASPAN BEYOND' : '477348300',
  'NEW MING ZHOU 60' : '477348700',
  'OOCL BEIJING' : '477351100',
  'COSCO PRINCE RUPERT' : '477351400',
  'CSCL ASIA' : '477360800',
  'IAL001' : '477387600',
  'SEASPAN ZAMBEZI' : '477390300',
  'SEASPAN AMAZON' : '477390400',
  'MILD SONATA' : '477390900',
  'COSCO HARMONY' : '477397800',
  'JI YUAN' : '477400800',
  'JI RUN' : '477400900',
  'HAO AN' : '477401100',
  'SITC BATANGAS' : '477401900',
  'UNI ACCORD' : '477406000',
  'FENG ZE YUAN' : '477407600',
  'SEASPAN THAMES' : '477407900',
  'MAERSK CASABLANCA' : '477423100',
  'CSCL URANUS' : '477423800',
  'DONG FANG XING' : '477424100',
  'CSCL MARS' : '477424600',
  'COSCO BELGIUM' : '477427500',
  'YM WELCOME' : '477430100',
  'GLORY ZHENDONG' : '477430500',
  'MAERSK GENOA' : '477430600',
  'SEASPAN TOKYO' : '477434200',
  'NEW MINGZHOU 66' : '477439900',
  'SEASPAN YANGTZE' : '477441400',
  'CSCL BOHAI SEA' : '477441800',
  'SITC DALIAN' : '477444600',
  'YM WELLHEAD' : '477454100',
  'HIGHLAND CHIEF' : '477454400',
  'YM WONDROUS' : '477454700',
  'OOCL GENOA' : '477454900',
  'CHANGSHA' : '477455200',
  'MORESBY CHIEF' : '477455700',
  'HERMANN SCHULTE' : '477462400',
  'COSCO IZMIR' : '477463400',
  'COSCO ASHDOD' : '477463500',
  'COSCO ISTANBUL' : '477464400',
  'COSCO JEDDAH' : '477464500',
  'SITC SHANDONG' : '477466300',
  'SITC CAGAYAN' : '477468200',
  'CSCL INDIAN OCEAN' : '477476800',
  'NEW GUINEA CHIEF' : '477477400',
  'PAPUAN CHIEF' : '477477700',
  'SEASPAN BEAUTY' : '477478700',
  'YM WORLD' : '477478800',
  'LIVORNO EXPRESS' : '477486100',
  'SITC INCHON' : '477493600',
  'OOCL AUSTRALIA' : '477504900',
  'SEASPAN BRIGHTNESS' : '477518600',
  'SITC MAKASSAR' : '477521300',
  'SITC KAOHSIUNG' : '477521800',
  'YM WITNESS' : '477524100',
  'MILD TUNE' : '477524300',
  'YM WHOLESOME' : '477524700',
  'SEASPAN ADONIS' : '477528400',
  'SITC WEIHAI' : '477537100',
  'DELPHIS BOTHNIA' : '477537200',
  'XIN XIN TIAN 1' : '477537400',
  'LAUTOKA CHIEF' : '477538900',
  'SEASPAN OSPREY' : '477539600',
  'OOCL BRISBANE' : '477541100',
  'ACACIA LIBRA' : '477541900',
  'ALTAMIRA EXPRESS' : '477547100',
  'JJ SUN' : '477547400',
  'SITC LIAONING' : '477548100',
  'SEASPAN BELIEF' : '477548300',
  'SITC HAINAN' : '477548900',
  'MAERSK NUBA' : '477552100',
  'SAFMARINE NOKWANDA' : '477552200',
  'MAERSK NYASSA' : '477552300',
  'SAFMARINE BENGUELA' : '477552400',
  'MAERSK NARMADA' : '477552700',
  'SAFMARINE NOMAZWE' : '477552800',
  'MAERSK NAKURU' : '477552900',
  'GOTTFRIED SCHULTE' : '477581100',
  'SEASPAN SANTOS' : '477581400',
  'MILD JAZZ' : '477585300',
  'SITC ZHEJIANG' : '477585400',
  'SITC GUANGXI' : '477588800',
  'XIN HONG KONG' : '477593600',
  'SITC NANSHA' : '477594900',
  'CSCL NEPTUNE' : '477598400',
  'COSCO HOPE' : '477598800',
  'OOCL TOKYO' : '477607700',
  'OOCL NEW ZEALAND' : '477612200',
  'HF WEALTH' : '477615200',
  'CARPENTERS VEGA' : '477615600',
  'OOCL BELGIUM' : '477616000',
  'OOCL WASHINGTON' : '477617600',
  'OOCL SEOUL' : '477617700',
  'MADRID EXPRESS' : '477624100',
  'PROS HOPE' : '477624200',
  'NADI CHIEF' : '477625300',
  'PARIS EXPRESS' : '477625400',
  'SITC PORT KLANG' : '477625600',
  'SEASPAN EMISSARY' : '477627300',
  'OOCL DALIAN' : '477627800',
  'OOCL NAGOYA' : '477627900',
  'MAERSK NINGBO' : '477629400',
  'SITC SHEKOU' : '477629500',
  'RABAUL CHIEF' : '477629900',
  'A KEIGA' : '477630400',
  'SITC PENANG' : '477632900',
  'PUERTO LIMON EXPRESS' : '477634900',
  'MAERSK GIBRALTAR' : '477636200',
  'CSCL SOUTH CHINA SEA' : '477637600',
  'BERNHARD SCHULTE' : '477639300',
  'EVER ORIENT' : '477642100',
  'SUVA CHIEF' : '477642200',
  'NEXOE MAERSK' : '477642400',
  'NICOLINE MAERSK' : '477642500',
  'NORA MAERSK' : '477642600',
  'NYSTED MAERSK' : '477642700',
  'NICOLAI MAERSK' : '477642900',
  'NELE MAERSK' : '477648100',
  'SITC SINGAPORE' : '477648300',
  'M/V LUNA MAERSK' : '477648700',
  'LEDA MAERSK' : '477648800',
  'COSCO ENGLAND' : '477652300',
  'MOUNT CAMERON' : '477654500',
  'COSCO JAPAN' : '477655200',
  'OOCL LE HAVRE' : '477655700',
  'OOCL CHARLESTON' : '477655800',
  'SEASPAN EMPIRE' : '477655900',
  'FAR EAST GRACE' : '477656100',
  'EVER OCEAN' : '477664500',
  'LOUIS MAERSK' : '477664600',
  'LEXA MAERSK' : '477665200',
  'JJ TOKYO' : '477680400',
  'SUNNY IVY' : '477681800',
  'LAUST MAERSK' : '477681900',
  'COSCO KOREA' : '477685300',
  'SEASPAN RIO DE JANEI' : '477690600',
  'CSCL ZEEBRUGGE' : '477690700',
  'COSCO FUZHOU' : '477690800',
  'NEW MINGZHOU 68' : '477692700',
  'GENOA EXPRESS' : '477694200',
  'BARCELONA EXPRESS' : '477694300',
  'RIO GRANDE EXPRESS' : '477696800',
  'MILD WALTZ' : '477698400',
  'COSCO DENMARK' : '477698600',
  'SEASPAN BRILLIANCE' : '477698700',
  'SEASPAN HUDSON' : '477699600',
  'OOCL HAMBURG' : '477702000',
  'OOCL CALIFORNIA' : '477704000',
  'HONIARA CHIEF' : '477706200',
  'SANTA MARTA EXPRESS' : '477711300',
  'CSCL GLOBE' : '477712400',
  'CSCL PACIFIC OCEAN' : '477712800',
  'ZIM CHARLESTON' : '477714100',
  'LICA MAERSK' : '477714300',
  'ZIM SHENZHEN' : '477714600',
  'EVER OASIS' : '477716100',
  'EVER ORDER' : '477717400',
  'OOCL AMERICA' : '477719000',
  'SEASPAN GUAYAQUIL' : '477726500',
  'MAERSK KARACHI' : '477728400',
  'SITC LICHENG' : '477732700',
  'YM WELLNESS' : '477737600',
  'MAERSK GUATEMALA' : '477737700',
  'MAERSK GUAYAQUIL' : '477737800',
  'COSCO PHILIPPINES' : '477738200',
  'OOCL LONDON' : '477738500',
  'OOCL LUXEMBOURG' : '477738600',
  'OOCL GUANGZHOU' : '477738700',
  'OOCL JAKARTA' : '477738800',
  'OOCL SAVANNAH' : '477738900',
  'SEASPAN LINGUE' : '477744200',
  'SITC DECHENG' : '477745500',
  'SITC YUNCHENG' : '477745800',
  'SEASPAN CALICANTO' : '477748500',
  'COSCO SURABAYA' : '477752300',
  'COSCO AQABA' : '477752400',
  'MAERSK VENEZIA' : '477754600',
  'MAERSK INCHEON' : '477754800',
  'HEDWIG SCHULTE' : '477759700',
  'COSCO PRIDE' : '477764600',
  'COSCO AUCKLAND' : '477765800',
  'COSCO ADEN' : '477765900',
  'SINOTRANS QINGDAO' : '477767500',
  'SINOTRANS DALIAN' : '477767600',
  'XIN BEIJING' : '477768100',
  'YM WINDOW' : '477769600',
  'MAERSK STOCKHOLM' : '477770200',
  'MAERSK SALINA' : '477770300',
  'COSCO MALAYSIA' : '477770700',
  'SEASPAN GANGES' : '477776100',
  'COSCO SPAIN' : '477776200',
  'DETROIT EXPRESS' : '477776300',
  'CMA CGM TUTICORIN' : '477790500',
  'MAERSK CABO VERDE' : '477792900',
  'SITC JIADE' : '477793200',
  'OOCL OAKLAND' : '477793400',
  'OOCL KUALA LUMPUR' : '477793900',
  'COSCO GLORY' : '477795300',
  'SITC MINGCHENG' : '477798200',
  'SITC XINCHENG' : '477798300',
  'SITC RENDE' : '477798400',
  'KANWAY FORTUNE' : '477798900',
  'JJ NAGOYA' : '477800200',
  'HANS SCHULTE' : '477802700',
  'OOCL SAN FRANCISCO' : '477806000',
  'XIN AN' : '477809100',
  'ONE MAGDALENA' : '477809500',
  'CUL NANSHA' : '477809800',
  'GLORY GUANGZHOU' : '477810500',
  'OOCL ITALY' : '477811100',
  'JOSCO ALMA' : '477811500',
  'SITC LIDE' : '477811700',
  'SITC MOJI' : '477813700',
  'SEASPAN FRASER' : '477815200',
  'OOCL SHANGHAI' : '477817400',
  'OOCL CHICAGO' : '477825000',
  'OOCL SOUTHAMPTON' : '477829700',
  'COSCO INDONESIA' : '477831200',
  'COSCO SAO PAULO' : '477832200',
  'COSCO VALENCIA' : '477832300',
  'OOCL CHONGQING' : '477832400',
  'OOCL EGYPT' : '477832500',
  'JOSCO JANE' : '477833500',
  'CUL YANGPU' : '477833700',
  'SITC SHANGDE' : '477833800',
  'SITC RUNDE' : '477833900',
  'HONG AN' : '477836400',
  'SITC MINGDE' : '477836500',
  'ONE ORINOCO' : '477836900',
  'ONE AMAZON' : '477842200',
  'ZIM USA' : '477842400',
  'ZIM CANADA' : '477842500',
  'JOSCO HELEN' : '477842600',
  'SITC CHUNMING' : '477842700',
  'SITC XINGDE' : '477842900',
  'COSCO ITALY' : '477845600',
  'SITC WENDE' : '477846200',
  'CUL BANGKOK' : '477846300',
  'CUL LAEMCHABANG' : '477846400',
  'ONE FREEDOM' : '477846600',
  'SITC QIUMING' : '477846900',
  'CSCL EAST CHINA SEA' : '477848700',
  'MAERSK MAKUTU' : '477849400',
  'SITC MACAO' : '477849500',
  'DELPHIS GDANSK' : '477850500',
  'SITC PYEONGTAEK' : '477855200',
  'SITC GUANGDONG' : '477855900',
  'ASL HONG KONG' : '477856100',
  'SITC ZHAOMING' : '477856400',
  'SITC SHENGDE' : '477856600',
  'ASL BAUHINIA' : '477856700',
  'ONE FRIENDSHIP' : '477856800',
  'DELPHIS FINLAND' : '477858700',
  'COSCOSHIPPING DANUBE' : '477858800',
  'SITC CHENMING' : '477859400',
  'SITC XIANDE' : '477859600',
  'SHENG AN' : '477859800',
  'SEASPAN KYOTO' : '477866200',
  'SEASPAN KOBE' : '477866300',
  'SITC PINGHE' : '477867100',
  'SITC YUANMING' : '477867200',
  'JIANGNANZAOCHUAN2761' : '477867500',
  'MAERSK CLEVELAND' : '477867600',
  'ONE FRUITION' : '477867800',
  'ONE FRONTIER' : '477867900',
  'ONE FANTASTIC' : '477871200',
  'MAERSK CHARLESTON' : '477871300',
  'ZIM MOUNT EVEREST' : '477871400',
  'ZIM SAMMY OFER' : '477871500',
  'OOCL SPAIN' : '477871600',
  'SITC ANHE' : '477871700',
  'SITC QIMING' : '477871800',
  'ASL PEONY' : '477871900',
  'COSCO YINGKOU' : '477883100',
  'OOCL KOBE' : '477883300',
  'BELAWAN' : '477883500',
  'CSCL LONG BEACH' : '477883900',
  'KUO LONG' : '477884200',
  'GLORY SHENGDONG' : '477884600',
  'SITC SHUNDE' : '477885100',
  'SITC CHANGMING' : '477885900',
  'OOCL PIRAEUS' : '477886300',
  'SITC SHENGMING' : '477887200',
  'SITC FENGHE' : '477887600',
  'NEWNEW STAR' : '477887700',
  'OOCL TURKIYE' : '477887800',
  'SITC HENGDE' : '477887900',
  'ZIM MOUNT BLANC' : '477890100',
  'ASL TAIPEI' : '477890300',
  'MAERSK SALALAH' : '477890500',
  'OOCL FELIXSTOWE' : '477890700',
  'SITC HUIMING' : '477890900',
  'GERNER MAERSK' : '477891200',
  'ZIM MOUNT DENALI' : '477891500',
  'SITC YUHE' : '477891600',
  'MAERSK STEPNICA' : '477891700',
  'SITC RUIMING' : '477891800',
  'SHENG LI JI' : '477891900',
  'CAUCEDO EXPRESS' : '477893100',
  'MAERSK SAVANNAH' : '477893200',
  'SNL NANJING' : '477893300',
  'ONE COSMOS' : '477893400',
  'GUNDE MAERSK' : '477893700',
  'NEWNEW POLAR BEAR' : '477893800',
  'SNL NANTONG' : '477893900',
  'ONE CONTINUITY' : '477895200',
  'OOCL GDYNIA' : '477895400',
  'OOCL ZEEBRUGGE' : '477895500',
  'SNL HAIKOU' : '477895900',
  'OOCL YOKOHAMA' : '477898700',
  'SITC RENHE' : '477906100',
  'SNL ZHANGJIAGANG' : '477906300',
  'ZIM MOUNT RAINIER' : '477907100',
  'ONE REASSURANCE' : '477907200',
  'ONE READINESS' : '477907300',
  'ZIM AMBER' : '477907600',
  'ZIM CORAL' : '477909200',
  'JY BONITO' : '477909300',
  'NEWNEW STAR 2' : '477909400',
  'MILD ORCHID' : '477909500',
  'GUNHILDE MAERSK' : '477909700',
  'NEWNEW PANDA 1' : '477909800',
  'NEW MINGZHOU 28' : '477915200',
  'OOCL ATLANTA' : '477920300',
  'ZIMMOUNT KILIMANJARO' : '477921600',
  'COSCOSHIPPING BRAZIL' : '477921700',
  'ZIM EMERALD' : '477921800',
  'GUTHORM MAERSK' : '477922100',
  'ONE RECOGNITION' : '477922300',
  'SITC YIHE' : '477922400',
  'NAGALEADER' : '477926700',
  'NE396' : '477928600',
  'BOHAI STAR' : '477928900',
  'ZIM MOUNT FUJI' : '477929100',
  'XIN YANG ZI488' : '477929700',
  'MILD PEONY' : '477931900',
  'SITC MANILA' : '477942700',
  'OSG ADMIRAL' : '477943000',
  'OOCL ROTTERDAM' : '477946000',
  'HUBERT SCHULTE' : '477947100',
  'COSCO DURBAN' : '477947600',
  'COSCO FOS' : '477947700',
  'SITC JIANGSU' : '477948200',
  'YM WARMTH' : '477948800',
  'COSCO DEVELOPMENT' : '477950300',
  'OOCL HOUSTON' : '477958300',
  'MAERSK ANTWERP' : '477958500',
  'MAERSK AVON' : '477958900',
  'COSCO THAILAND' : '477959200',
  'SEASPAN OSAKA' : '477961600',
  'CSCL STAR' : '477963800',
  'SITC FUJIAN' : '477967300',
  'LAKONIA' : '477967600',
  'CSCL ARCTIC OCEAN' : '477967700',
  'CSCL ATLANTIC OCEAN' : '477967800',
  'CHAI WAN' : '477995162',
  'CLEAN HARBOUR 1' : '477995437',
  'CLEAN HARBOUR 2' : '477995441',
  'NGON SHUEN' : '477997028',
  'NIM WAN' : '477997030',
  'LAI WAN' : '477997036',
  'MO SING LENG' : '477997039',
  'TSING CHAU' : '477997040',
  'S PYLOS' : '511100005',
  'HACI MUSA UCAK' : '511100373',
  'ADMIRAL DANIAL' : '511100380',
  'ELLA Y' : '511100639',
  'BAWEAN' : '511100739',
  'HAJ YEHIA' : '511100746',
  'CETUS' : '511100833',
  'NK PEARL' : '511100889',
  'INTERSEA TRAVELER' : '511101140',
  'MANASSA M' : '511101214',
  'XIN FENG' : '511101259',
  'GEMMA' : '511873000',
  'TAKUTAI CHIEF' : '512011151',
  'MOANA CHIEF' : '512143000',
  'ALEXINDO I' : '525003018',
  'ICON CORINTUS' : '525003263',
  'MV.ORIENTAL DIAMOND' : '525003620',
  'ORIENTAL GALAXY' : '525003624',
  'HIJAU SAMUDRA' : '525003667',
  'HIJAU SEGAR' : '525005174',
  'HIJAU JELITA' : '525005318',
  'MV.HIJAU SEJUK' : '525005319',
  'VERIZON' : '525005339',
  'MV. ORIENTAL GOLD' : '525005397',
  'WGM256T' : '525006403',
  'MV.LINTAS BENGKULU' : '525007115',
  'KM. KANAL MAS' : '525007163',
  'MV TELUK MAS' : '525007400',
  'MV KISIK MAS' : '525007401',
  'MV SINAR BANDA' : '525009349',
  'TANTO SETIA' : '525013013',
  'TANTO BERSAMA' : '525013014',
  'TANTO BERSATU' : '525013016',
  'TANTO RAYA' : '525013018',
  'TANTO TERANG' : '525013021',
  'TANTO TANGGUH' : '525013022',
  'MV TANTO SEMANGAT' : '525013024',
  'TANTO PRATAMA' : '525013026',
  'TANTO ALAM' : '525013027',
  'MV.TANTO AMAN' : '525013028',
  'MV TANTO KARUNIA II' : '525013029',
  'MV TANTO SUBUR 1' : '525013031',
  'MV TANTO SUBUR II' : '525013033',
  'MV.TANTO TENANG' : '525013036',
  'TANTO LANCAR' : '525013040',
  'TANTO LUAS' : '525013042',
  'TANTO LANGGENG' : '525013043',
  'MV MULIANIM' : '525015026',
  'MV ARMADA SEJATI' : '525015248',
  'CTP DELTA' : '525015323',
  'KM PULAU LAYANG' : '525015347',
  'KM.TELUK FLAMINGGO' : '525015393',
  'KM.TELUK BINTUNI' : '525015399',
  'MV SELAT MAS' : '525015479',
  'MV.ARMADA PERMATA' : '525015536',
  'MV.ORIENTAL JADE' : '525015583',
  'MV.CTP FORTUNE' : '525015658',
  'CTP GOLDEN' : '525015706',
  'MV.ORIENTAL RUBY' : '525015924',
  'MV.LUZON' : '525015943',
  'MV.ORIENTAL EMERALD' : '525015962',
  'MV.ARMADA SEGARA' : '525015965',
  'ORIENTAL SILVER' : '525015973',
  'KM TANTO SURYA' : '525016130',
  'KM.TANTO ABADI' : '525016244',
  'LUMOSO SELAMAT' : '525016466',
  'LUMOSO BAHAGIA' : '525016539',
  'TANTO EXPRESS' : '525016549',
  'KM TANTO PERMAI' : '525016550',
  'MV. TANTO JAYA' : '525016584',
  'LUMOSO GEMBIRA' : '525016609',
  'TMS GLORY' : '525018171',
  'MV HILIR MAS' : '525019019',
  'MV TELAGA MAS' : '525019050',
  'MV WARIH MAS' : '525019100',
  'MV.UMBUL MAS' : '525019101',
  'MV BELIK MAS' : '525019274',
  'MV TASIK MAS' : '525019381',
  'MV.STRAIT MAS' : '525019398',
  'JALES_MAS' : '525019417',
  'MV.ESTUARI MAS' : '525019465',
  'MARE MAS' : '525019510',
  'MV.KALI MAS' : '525019527',
  'MV.GULF MAS' : '525022407',
  'MV.PALUNG MAS' : '525022408',
  'CURUG MAS' : '525023419',
  'MV BAHAR MAS' : '525023420',
  'MV SUNGAI MAS' : '525024397',
  'MERATUS MANADO' : '525025049',
  'MERATUS MEDAN 1' : '525025054',
  'MERATUS MEDAN 2' : '525025067',
  'MERATUS MAMIRI' : '525025076',
  'MERATUS DILI' : '525025077',
  'MERATUS GORONTALO' : '525025082',
  'MERATUS KUPANG' : '525025085',
  'MERATUS KELIMUTU' : '525025088',
  'MERATUS MALINO' : '525025089',
  'MERATUS KALABAHI' : '525025090',
  'MERATUS KAHAYAN' : '525025101',
  'MERATUS KATINGAN' : '525025102',
  'SPIL HANA' : '525100294',
  'SPIL HAPSRI' : '525100392',
  'MCC ANDALAS' : '525100468',
  'SPIL HASYA' : '525100478',
  'SELATAN DAMAI' : '525100552',
  'SPIL HAYU' : '525100704',
  'MV ICON DANIEL' : '525100889',
  'KENDHAGA NUSANTARA 1' : '525101050',
  'KENDHAGA NUSANTARA 9' : '525101066',
  'KENDHAGA NUSANTARA 7' : '525101067',
  'KENDAGA NUSANTARA15' : '525101097',
  'KENDHAGA NUSANTARA 3' : '525101118',
  'KENDHAGA NUSANTARA10' : '525101128',
  'KENDHAGA NUSANTARA 8' : '525101129',
  'KENDHAGA NUSANTARA 5' : '525101130',
  'KENDHAGA NUSANTARA12' : '525101131',
  'MV SPIL RENATA' : '525101470',
  'TANTO MITRA' : '525113002',
  'MV TANTO MANDIRI' : '525113004',
  'TANTO BERSINAR' : '525113005',
  'MV TANTO SELALU' : '525113006',
  'TANTO SIAP' : '525113008',
  'MV.TANTO SUKSES' : '525113009',
  'MV TANTO TERIMA' : '525113012',
  'TANTO KASIH' : '525113013',
  'TANTO SALAM' : '525113016',
  'TANTO SEJAHTERA' : '525113017',
  'TANTO KAWAN' : '525113019',
  'TANTO KARYA' : '525113026',
  'TANTO KHARISMA' : '525113028',
  'MUARA MAS' : '525119004',
  'AYER MAS' : '525119005',
  'KEDUNG MAS' : '525119011',
  'KAWA MAS' : '525119012',
  'STRAIT MAS' : '525119161',
  'CMA CGM HOMERE' : '525119170',
  'IZUMI MAS' : '525119189',
  'IFAMA MAS' : '525119190',
  'MV.CTP MAKASSAR' : '525120018',
  'MERATUS KARIANGAU' : '525125001',
  'MERATUS KARIMATA' : '525125002',
  'MERATUS LABUAN BAJO' : '525125008',
  'MERATUS LARANTUKA' : '525125010',
  'MERATUS LEMBAR' : '525125011',
  'MERATUS LEMBATA' : '525125012',
  'MERATUS WAINGAPU' : '525125014',
  'MV MERATUS WAKATOBI' : '525125018',
  'MERATUS SAMARINDA' : '525125029',
  'MERATUS SAMPIT' : '525125030',
  'KM. HASIL BAHARI 8' : '525200098',
  'SURYA SAMUDRA V' : '525290672',
  'MULYA SENTOSA II' : '525300044',
  'MV SPIL RAHAYU :)' : '525301156',
  'SPIL RETNO' : '525301388',
  'SPIL RATNA' : '525400142',
  'MV.MITRA KENDARI' : '525400885',
  'FATIMA II' : '525600354',
  'DANUM 159' : '533000522',
  'HARBOUR EXPRESS' : '533052300',
  'MTT TANJUNG MANIS' : '533130646',
  'MTT KUCHING DUA' : '533130813',
  'MTT SAISUNEE' : '533131021',
  'MTT PENGERANG' : '533131052',
  'MTT SENARI' : '533131059',
  'MTT SIBU' : '533132056',
  'MTT SAMALAJU' : '533132065',
  'MTT SARIKEI' : '533132066',
  'MTT SEMPORNA' : '533132075',
  'SALAM MAJU' : '533132085',
  'MTT SAPANGAR' : '533132087',
  'MTT SINGAPORE' : '533132142',
  'MTT SANDAKAN' : '533132161',
  'MTT RAJANG' : '533132436',
  'MTT HAIPHONG' : '533132646',
  'RMN AUX 5' : '533167000',
  'DANUM 160' : '533170079',
  'HARBOUR ZENITH' : '533170319',
  'HARBOUR_RUBY' : '533170323',
  'DANUM 168' : '533170482',
  'DANUM 171' : '533170531',
  'DANUM 172' : '533170536',
  'HARBOUR STAR' : '533170554',
  'HARBOUR NEPTUNE' : '533170578',
  'HARBOUR GALAXY' : '533170614',
  'DANUM 173' : '533170635',
  'DANUM 175' : '533170679',
  'HARBOUR HORIZON' : '533170729',
  'MTT PULAU PINANG' : '533180127',
  'MTT MUARA' : '533180146',
  'MTT BINTULU' : '533180218',
  'HARRISON' : '538001652',
  'PRESIDIO' : '538001941',
  'CAPE FLORES' : '538002485',
  'CAPE FARO' : '538002591',
  'CAPE FLINT' : '538002624',
  'CAPE FORBY' : '538002666',
  'NAVIOS VERMILION' : '538002734',
  'CAPE FRANKLIN' : '538002747',
  'CAPE FULMAR' : '538002823',
  'SC MARA' : '538002955',
  'MOL PROFICIENCY' : '538002961',
  'NAVIOS INDIGO' : '538003035',
  'NAVIOS SPRING' : '538003104',
  'CAPE FAWLEY' : '538003120',
  'CAPE FELTON' : '538003181',
  'CAPE FERROL' : '538003221',
  'WADI BANI KHALID' : '538003252',
  'MATSON OAHU' : '538003274',
  'NAVIOS DESTINY' : '538003278',
  'NAVIOS DEVOTION' : '538003407',
  'CAPE MALE' : '538003580',
  'ONE MAGNIFICENCE' : '538003648',
  'NAVIOS LAPIS' : '538003651',
  'TANJA' : '538003717',
  'ONE MAJESTY' : '538003729',
  'ONE MATRIX' : '538003810',
  'ONE MAXIM' : '538003886',
  'ONE MARVEL' : '538003993',
  'ONE MOTIVATOR' : '538004117',
  'CAPE MOSS' : '538004165',
  'ONE MISSION' : '538004202',
  'ONE MODERN' : '538004203',
  'CUSSLER' : '538004612',
  'STARSHIP LEO' : '538004893',
  'STARSHIP URSA' : '538004894',
  'STARSHIP PEGASUS' : '538004895',
  'MARY' : '538004911',
  'ALEXANDRA' : '538004912',
  'KRISTINA' : '538004913',
  'KATHERINE' : '538004914',
  'RUTH BORCHARD' : '538005024',
  'CHARLOTTE BORCHARD' : '538005039',
  'MUKADDES KALKAVAN' : '538005042',
  'SAFEEN PRIZE' : '538005057',
  'RACHEL BORCHARD' : '538005064',
  'SAFEEN PROSPER' : '538005112',
  'SAFEEN PRISM' : '538005224',
  'SAFEEN PRESTIGE' : '538005242',
  'SAFEEN PRIME' : '538005308',
  'PEGASUS TERA' : '538005313',
  'SUNNY LILY' : '538005519',
  'SUNNY COSMOS' : '538005520',
  'JEJU ISLAND' : '538005592',
  'WIDE ALPHA' : '538005624',
  'STEPHANIE C' : '538005625',
  'HONGKONG BRIDGE' : '538005686',
  'ROTTERDAM BRIDGE' : '538005687',
  'SAN FELIPE' : '538005732',
  'SAN FELIX' : '538005733',
  'SAN FERNANDO' : '538005734',
  'SAN FRANCISCA' : '538005735',
  'MAERSK EUPHRATES' : '538005747',
  'WIDE HOTEL' : '538005750',
  'WIDE INDIA' : '538005751',
  'WIDE JULIET' : '538005752',
  'HEUNG-A JANICE' : '538005895',
  'HEUNG-A HAIPHONG' : '538005896',
  'HEUNG-A XIAMEN' : '538005897',
  'HEUNG-A AKITA' : '538005898',
  'AS NORA' : '538005929',
  'TIANJIN BRIDGE' : '538005938',
  'SEAMAX WESTPORT' : '538005956',
  'ALEXIS' : '538005958',
  'OLIVIA I' : '538005959',
  'TASMAN' : '538006111',
  'SEAMAX STAMFORD' : '538006141',
  'HOPE ISLAND' : '538006207',
  'DELOS WAVE' : '538006257',
  'MH HAMBURG' : '538006605',
  'SEAMAX MYSTIC' : '538006767',
  'HEUNG-A BANGKOK' : '538006980',
  'HEUNGA HOCHIMINH' : '538006981',
  'SEAMAX NORWALK' : '538007063',
  'ZIM WILMINGTON' : '538007097',
  'INSPIRE' : '538007157',
  'EM CORFU' : '538007209',
  'HYUNDAI TACOMA' : '538007259',
  'STARSHIP TAURUS' : '538007311',
  'STARSHIP AQUILA' : '538007312',
  'ONE TRIUMPH' : '538007360',
  'ONE TRUST' : '538007378',
  'INTEGRA' : '538007386',
  'INFINITY' : '538007457',
  'HYUNDAI BRAVE' : '538007477',
  'HYUNDAI COURAGE' : '538007479',
  'HYUNDAI FAITH' : '538007480',
  'HYUNDAI FORCE' : '538007481',
  'HYUNDAI FORWARD' : '538007482',
  'HYUNDAI UNITY' : '538007483',
  'HYUNDAI GRACE' : '538007484',
  'HYUNDAI DYNASTY' : '538007485',
  'HYUNDAI VOYAGER' : '538007486',
  'HYUNDAI SUPREME' : '538007487',
  'KMTC HAIPHONG' : '538007503',
  'TS BANGKOK' : '538007518',
  'STAR CLIPPER' : '538007547',
  'STAR SKIPPER' : '538007548',
  'NAVIOS AZURE' : '538007610',
  'NAVIOS VERANO' : '538007617',
  'NAVIOS VERDE' : '538007622',
  'MATSON LANAI' : '538007628',
  'BFAD ATLANTIC' : '538007641',
  'TS TOKYO' : '538007662',
  'ATLANTIC DISCOVERER' : '538007677',
  'MELINA' : '538007680',
  'SPYROS V' : '538007681',
  'CONSTANTINOS P II' : '538007683',
  'ELENI T' : '538007684',
  'SEAMAX STRATFORD' : '538007698',
  'INGENUITY' : '538007711',
  'TS KAOHSIUNG' : '538007728',
  'INSIGHT' : '538007806',
  'TS OSAKA' : '538007820',
  'CAPTAIN THANASIS I' : '538007938',
  'CAP SAN JUAN' : '538008132',
  'CAP SAN VINCENT' : '538008133',
  'CAP SAN LAZARO' : '538008134',
  'NAVIOS SUMMER' : '538008152',
  'BALTIC NORTH' : '538008542',
  'BALTIC WEST' : '538008563',
  'BALTIC SOUTH' : '538008564',
  'SC MONTREUX' : '538008593',
  'INVICTA' : '538008635',
  'INCEDA' : '538008691',
  'SYNERGY BUSAN' : '538008755',
  'SYNERGY ANTWERP' : '538008756',
  'INCRES' : '538008811',
  'TS SINGAPORE' : '538008845',
  'STAR EXPLORER' : '538008859',
  'STAR FRONTIER' : '538008860',
  'INFERRO' : '538008946',
  'MAERSK ZAMBEZI' : '538008980',
  'INESSA' : '538009116',
  'W KAMPALA' : '538009125',
  'W KYRENIA' : '538009126',
  'W KLAIPEDA' : '538009143',
  'W KITHIRA' : '538009144',
  'TS NINGBO' : '538009162',
  'INDURO' : '538009167',
  'CONSISTENCE' : '538009208',
  'SEAMAX DARIEN' : '538009216',
  'GH TRAMONTANE' : '538009224',
  'TS LAEMCHABANG' : '538009244',
  'CONSCIENCE' : '538009272',
  'CONFIDENCE' : '538009273',
  'CONTESSA' : '538009274',
  'CONCERTO' : '538009275',
  'CONSERO' : '538009276',
  'GH MAESTRO' : '538009277',
  'BALTIMORE STAR' : '538009287',
  'BFAD SOUTHERN' : '538009289',
  'TS KELANG' : '538009290',
  'GH BORA' : '538009319',
  'CELSIUS LONDON' : '538009350',
  'TS SYDNEY' : '538009373',
  'ARIANA A' : '538009388',
  'GH FOEHN' : '538009390',
  'GFS GALAXY' : '538009400',
  'SC HOUSTON' : '538009416',
  'CELSIUS NICOSIA' : '538009437',
  'SCO SHANGHAI' : '538009457',
  'MAYFIELD' : '538009458',
  'MORAGA' : '538009489',
  'TS KWANGYANG' : '538009520',
  'GABRIELA A' : '538009523',
  'DEL MONTE VALIANT' : '538009559',
  'DEL MONTE GOLD' : '538009560',
  'DEL MONTE SPIRIT' : '538009561',
  'DEL MONTE ROSE' : '538009562',
  'DEL MONTE PRIDE' : '538009563',
  'DEL MONTE HARVESTER' : '538009564',
  'MANILA VOYAGER' : '538009596',
  'CELSIUS NAIROBI' : '538009601',
  'CELSIUS NAPLES' : '538009602',
  'CELSIUS BOSTON' : '538009604',
  'CELSIUS BRICKELL' : '538009605',
  'LADYJANE' : '538009624',
  'CONSIGNIA' : '538009630',
  'CONSILIA' : '538009631',
  'CONTRIVIA' : '538009632',
  'STAR CHASER' : '538009693',
  'STAR RANGER' : '538009694',
  'NYK LAURA' : '538009707',
  'SAFEEN PRIDE' : '538009719',
  'TS DALIAN' : '538009749',
  'MANZANILLO EXPRESS' : '538009760',
  'ITAJAI EXPRESS' : '538009762',
  'BUENAVENTURA EXPRESS' : '538009764',
  'PRESTIGE' : '538009830',
  'GOOD PROSPECT' : '538009831',
  'CELSIUS NELSON' : '538009877',
  'CELSIUS NEW ORLEANS' : '538009878',
  'FAYSTON FARMS' : '538009884',
  'ATLANTIC IBIS' : '538009897',
  'TS NAGOYA' : '538009915',
  'SAWASDEE CHITTAGONG' : '538009929',
  'SAFEEN PIONEER' : '538009940',
  'BIG LILLY' : '538009951',
  'STARSHIP DRACO' : '538010005',
  'PEGASUS PROTO' : '538010006',
  'SAWASDEE SHANGHAI' : '538010008',
  'RENA P' : '538010028',
  'EMMANUEL P' : '538010029',
  'EAGLE II' : '538010031',
  'CF ATHENA' : '538010037',
  'SAWASDEE XIAMEN' : '538010046',
  'SAWASDEE INCHEON' : '538010048',
  'TS XIAMEN' : '538010060',
  'SAWASDEE SIRIUS' : '538010061',
  'NYK SILVIA' : '538010092',
  'TS TIANJIN' : '538010110',
  'GREGOS' : '538010120',
  'TERATAKI' : '538010122',
  'MARLA TIGER' : '538010124',
  'SAFEEN POWER' : '538010153',
  'TS JAKARTA' : '538010170',
  'TS HOCHIMINH' : '538010174',
  'TS KOBE' : '538010175',
  'TS NANSHA' : '538010176',
  'SAWASDEE VEGA' : '538010219',
  'MARLA BULL' : '538010265',
  'TS GUANGZHOU' : '538010267',
  'VEGA COLIGNY' : '538010322',
  'VEGA DAYTONA' : '538010323',
  'REMAH' : '538010343',
  'TS SHEKOU' : '538010344',
  'IRENES RULE' : '538010372',
  'IRENES WISDOM' : '538010373',
  'SAWASDEE CAPELLA' : '538010399',
  'TS MAWEI' : '538010433',
  'VENTO DI BORA' : '538010485',
  'CAPE BRUNO' : '538010486',
  'SAWASDEE RIGEL' : '538010501',
  'SAWASDEE ALTAIR' : '538010505',
  'CAPE BONAVISTA' : '538010544',
  'CAPE BYRON' : '538010545',
  'TS CHIBA' : '538010558',
  'TS HAKATA' : '538010559',
  'SAWASDEE SPICA' : '538010572',
  'TEH TAICHUNG' : '538010641',
  'TS INCHEON' : '538010654',
  'CARLA-LIV' : '538010670',
  'TS VANCOUVER' : '538010690',
  'TS TACOMA' : '538010693',
  'TS MUNDRA' : '538010740',
  'TS MELBOURNE' : '538010741',
  'SAWASDEE DENEB' : '538010745',
  'TS LIANYUNGANG' : '538010758',
  'GULF BARAKAH' : '538010770',
  'WADI DUKA' : '538010776',
  'TS PUSAN' : '538010783',
  'TS QINGDAO' : '538010784',
  'TS SHENZHEN' : '538010785',
  'TS JOHOR' : '538010792',
  'TS CHENNAI' : '538010793',
  'VANCOUVER STAR' : '538010794',
  'SHEKOU STAR' : '538010796',
  'OOCL ST.LAWRENCE' : '538010806',
  'YOKOHAMA STAR' : '538010808',
  'TS PENANG' : '538010813',
  'QINGDAO STAR' : '538010837',
  'SAWASDEE MIMOSA' : '538010845',
  'XIA CHUAN 151' : '538010876',
  'TS SURABAYA' : '538010946',
  'SAFEEN PEARL' : '538010956',
  'CALIDRIS' : '538090562',
  'BARRIER' : '538090563',
  'HANSA COLOMBO' : '538090571',
  'HANSA ASIA' : '538090582',
  'HANSA AUSTRALIA' : '538090583',
  'SPIRIT OF HONG KONG' : '538090584',
  'VEGA ALPHA' : '538090596',
  'VEGA VELA' : '538090601',
  'OLYMPIA' : '538090605',
  'OPHELIA' : '538090606',
  'LOA HARMONY' : '538090625',
  'XING LUO 7' : '538090639',
  'AS NINA' : '538090641',
  'MV OCEAN ZENITH' : '548184500',
  'SPAN ASIA 32' : '548256500',
  'MV SPAN ASIA 31' : '548258500',
  'IRIS PAOAY' : '548263500',
  'MC HUNTER' : '548321500',
  'MERIDIAN OCHO' : '548350500',
  'MV LORCON DUMAGUETE' : '548390100',
  'LORCON GENERALSANTOS' : '548471100',
  'SAN LORENZO RUIZ UNO' : '548581300',
  'DON ALFONSO SR.2' : '548612300',
  'OCEAN OPPORTUNITY' : '548614200',
  'M/V TRANS-ASIA 15' : '548636500',
  'MCC BATANES' : '548699200',
  'SPAN ASIA 25' : '548740200',
  'MV SPAN ASIA 27' : '548770200',
  'MV SPAN ASIA 3' : '548819200',
  'GAZELLE COAST' : '553111742',
  'BOUGAINVILLE COAST' : '553111747',
  'NIUGINI COAST' : '553111867',
  'MV ELSIE' : '553111941',
  'MAERSK SANA' : '563000400',
  'MAERSK SYDNEY' : '563000500',
  'MAERSK SINGAPORE' : '563000600',
  'MAERSK SEVILLE' : '563000700',
  'MAERSK SARNIA' : '563000800',
  'MAERSK SANTANA' : '563000900',
  'MAERSK SHEERNESS' : '563001100',
  'MAERSK SOFIA' : '563001200',
  'MAERSK STADELHORN' : '563001700',
  'MAERSK SIRAC' : '563002100',
  'DALI' : '563004200',
  'CHATTANOOGA' : '563004400',
  'TALLAHASSEE' : '563004500',
  'KALAMAZOO' : '563004600',
  'EVER STRONG' : '563004900',
  'INTERASIA ADVANCE' : '563005400',
  'MAERSK SHAMS' : '563006800',
  'CAPITAINE DAMPIER' : '563007700',
  'KOTA NANHAI' : '563011000',
  'MAERSK HONG KONG' : '563017800',
  'TOKYO TRIUMPH' : '563020400',
  'MIMMI SCHULTE' : '563024600',
  'MOLLY SCHULTE' : '563024700',
  'MINSTREL' : '563024800',
  'MANDALAY' : '563024900',
  'NYK VIRGO' : '563025600',
  'MAERSK HORSBURGH' : '563026700',
  'TAIPEI TRIUMPH' : '563029900',
  'MAERSK HALIFAX' : '563030500',
  'WAN HAI 173' : '563032600',
  'MAERSK SAIGON' : '563032700',
  'MAERSK STRALSUND' : '563032800',
  'MAERSK SEOUL' : '563032900',
  'EVER LOVELY' : '563033000',
  'KOTA PAHLAWAN' : '563033800',
  'PARANAGUA EXPRESS' : '563034800',
  'MAERSK HIDALGO' : '563034900',
  'MAERSK DOUALA' : '563035100',
  'MAERSK DAKAR' : '563035200',
  'ALS FLORA' : '563035400',
  'MAERSK HANOI' : '563039700',
  'WAN HAI 175' : '563041500',
  'MAERSK HANGZHOU' : '563042200',
  'KOTA PELANGI' : '563042400',
  'MAERSK NAIROBI' : '563044300',
  'ONE OLYMPUS' : '563048400',
  'INTERASIA FORWARD' : '563049100',
  'SAN CLEMENTE' : '563049900',
  'MAERSK CAP CARMEL' : '563050100',
  'RIO BLANCO' : '563050200',
  'RIO BRAVO' : '563050300',
  'MAERSK MONTE ALEGRE' : '563050400',
  'POLAR ECUADOR' : '563050500',
  'SAN CHRISTOBAL' : '563051100',
  'MONTE CERVANTES' : '563051200',
  'MONTE PASCOAL' : '563051300',
  'MONTE ROSA' : '563051400',
  'POLAR COSTA RICA' : '563051500',
  'RIO MADEIRA' : '563051600',
  'MAERSK MONTE AZUL' : '563051700',
  'MONTE VERDE' : '563051800',
  'ALIANCA MANAUS' : '563051900',
  'MONTE TAMARO' : '563052100',
  'MAERSK RIO DELTA' : '563052200',
  'MAERSK RIO NEGRO' : '563052300',
  'MONTE ACONCAGUA' : '563052400',
  'MONTE OLIVIA' : '563052500',
  'CAP JACKSON' : '563052600',
  'CAP JERVIS' : '563052700',
  'SAN VICENTE' : '563052800',
  'MAERSK RIO INDIA' : '563052900',
  'MAERSK HAMBURG' : '563053300',
  'INTERASIA PROGRESS' : '563053700',
  'X PRESS GANGES' : '563054900',
  'X-PRESS MACHU PICCHU' : '563058900',
  'MAERSK HERRERA' : '563059200',
  'CAPITAINE TASMAN' : '563060800',
  'ONE ORPHEUS' : '563061300',
  'MAERSK SALTORO' : '563062200',
  'CEZANNE' : '563062300',
  'ALS VESTA' : '563062600',
  'ALS VENUS' : '563062700',
  'POLAR MEXICO' : '563066900',
  'EVER GIFTED' : '563068900',
  'MAERSK HAVANA' : '563069900',
  'X-PRESS KABRU' : '563071300',
  'YM WISDOM' : '563073500',
  'MAERSK MEMPHIS' : '563073700',
  'MAERSK KENTUCKY' : '563073800',
  'PSA AGILITY' : '563075510',
  'PSA CONNECTIVITY' : '563075940',
  'MAERSK GATESHEAD' : '563076200',
  'MAERSK GIRONDE' : '563076300',
  'MAERSK GARONNE' : '563077400',
  'EVER SUPERB' : '563077800',
  'MAERSK HUACHO' : '563078200',
  'MAERSK HOUSTON' : '563078300',
  'MAERSK GAIRLOCH' : '563078400',
  'NYK ROMULUS' : '563079000',
  'KOTA NEBULA' : '563082900',
  'MAERSK BINTULU' : '563083400',
  'CHASTINE MAERSK' : '563084400',
  'EVER STEADY' : '563084800',
  'MAERSK SIHANOUKVILLE' : '563087600',
  'CNC NEPTUNE' : '563088700',
  'MAERSK YUKON' : '563090200',
  'MAERSK EUREKA' : '563090300',
  'MAERSK EMERALD' : '563090400',
  'MAERSK EDIRNE' : '563090500',
  'MAERSK SONGKHLA' : '563091100',
  'MAERSK MONGLA' : '563095400',
  'SHIMIN' : '563095500',
  'GREEN STAR' : '563095600',
  'GREEN OCEAN' : '563095700',
  'GREEN FOREST' : '563095800',
  'MAERSK BERMUDA' : '563096400',
  'MAERSK BAHAMAS' : '563096500',
  'EVER SAFETY' : '563096700',
  'CNC MARS' : '563098400',
  'MAERSK RUBICON' : '563101600',
  'GREEN EARTH' : '563101900',
  'MAERSK BALI' : '563102000',
  'GREEN SEA' : '563102100',
  'EVER SALUTE' : '563102400',
  'SHINA' : '563102800',
  'POLAR COLOMBIA' : '563103200',
  'MAERSK AMAZON' : '563103400',
  'GREEN POLE' : '563103800',
  'PAC SEGINUS' : '563103900',
  'NAVEGANTES EXPRESS' : '563105800',
  'MAERSK QINZHOU' : '563107100',
  'MAERSK DAVAO' : '563109200',
  'GREEN WAVE' : '563110300',
  'GREEN DAWN' : '563110400',
  'INTERASIA MOMENTUM' : '563110800',
  'WAN HAI 321' : '563111100',
  'SINAR SUNDA' : '563111200',
  'MAERSK XIAMEN' : '563111300',
  'WAN HAI 322' : '563113200',
  'INTERASIA PURSUIT' : '563114100',
  'INTERASIA VISION' : '563115100',
  'GFS RUBY' : '563115300',
  'GFS PRIDE' : '563115400',
  'GFS PRESTIGE' : '563115900',
  'WAN HAI 283' : '563116500',
  'SINAR SORONG' : '563116700',
  'X-PRESS MEKONG' : '563118100',
  'WAN HAI 323' : '563118300',
  'ASIATIC SUN' : '563118500',
  'ASIATIC QUEST' : '563118600',
  'NYK JOANNA' : '563119000',
  'WAN HAI 325' : '563119400',
  'ASIATIC REUNION' : '563120900',
  'ASIATIC PRIDE' : '563121100',
  'WAN HAI 277' : '563121900',
  'MAERSK VALENCIA' : '563122100',
  'X-PRESS SAGARMALA' : '563122300',
  'WAN HAI 521' : '563123500',
  'WAN HAI 326' : '563124200',
  'WAN HAI 327' : '563124300',
  'MH GREEN' : '563124400',
  'BOTANY' : '563125200',
  'JT GLORY' : '563125500',
  'WAN HAI 522' : '563126500',
  'WAN HAI 622' : '563126700',
  'WAN HAI 285' : '563126900',
  'GFS PERFECT' : '563127400',
  'X-PRESS ODYSSEY' : '563127500',
  'MAERSK ATHABASCA' : '563127900',
  'WAN HAI 328' : '563128200',
  'WAN HAI 329' : '563128900',
  'ANDERSON DRAGON' : '563129600',
  'WAN HAI 286' : '563129900',
  'MEDITERRANEAN EXPRES' : '563130100',
  'WAN HAI 721' : '563130400',
  'ONE MANCHESTER' : '563131500',
  'A DAISEN' : '563131600',
  'MAHAWELI' : '563131700',
  'WAN HAI 523' : '563131800',
  'WAN HAI 722' : '563132400',
  'WAN HAI 287' : '563132500',
  'WHUTTHI BHUM' : '563133100',
  'KOTA LAGU' : '563133400',
  'KOTA LARIS' : '563133500',
  'X-PRESS NILWALA' : '563133800',
  'WAN HAI 288' : '563134400',
  'WAN HAI 625' : '563135300',
  'MAERSK NACALA' : '563135700',
  'AKA BHUM' : '563135800',
  'BHUDTHI BHUM' : '563136800',
  'KOTA HENING' : '563137500',
  'KOTA JAYA' : '563137600',
  'KOTA HANDAL' : '563137700',
  'KOTA NASRAT' : '563137800',
  'KOTA LAYANG' : '563137900',
  'KOTA RAKAN' : '563138100',
  'WAN HAI 289' : '563138600',
  'WAN HAI 290' : '563139600',
  'GANTA BHUM' : '563139700',
  'X PRESS NETRAVATI' : '563139800',
  'YM TRUST' : '563141200',
  'X-PRESS KAVERI' : '563141600',
  'EVER SUMMIT' : '563142100',
  'WAN HAI 626' : '563142500',
  'WAN HAI 723' : '563142800',
  'EVER LIVING' : '563143000',
  'WAN HAI 291' : '563143100',
  'ATLANTIC MONACO' : '563143500',
  'ATLANTIC SILVER' : '563143600',
  'INTERASIA RESILIENCE' : '563144200',
  'WHITE DRAGON' : '563144500',
  'SUNRISE DRAGON' : '563144600',
  'CAPITAINE BARET' : '563145100',
  'WAN HAI 176' : '563145300',
  'MAERSK IDAHO' : '563146500',
  'BREMEN BELLE' : '563146600',
  'PACIFIC SINGAPORE' : '563147400',
  'WAN HAI 292' : '563147900',
  'MAERSK WALLIS' : '563148100',
  'MAERSK WILLEMSTADT' : '563148200',
  'MAERSK WELLINGTON' : '563148300',
  'KOTA CABAR' : '563149500',
  'KOTA CAHAYA' : '563149800',
  'KOTA CANTIK' : '563149900',
  'KOTA CARUM' : '563150100',
  'WAN HAI 276' : '563150700',
  'KOTA GABUNG' : '563152200',
  'KOTA LIHAT' : '563152300',
  'KOTA KARIM' : '563152400',
  'KOTA GAYA' : '563152500',
  'KOTA LAWA' : '563152600',
  'X-PRESS IRRAWADDY' : '563152800',
  'WAN HAI 293' : '563153200',
  'MAERSK VALLETTA' : '563154500',
  'X-PRESS MOY' : '563155100',
  'YM TROPHY' : '563155200',
  'EVER APEX' : '563155500',
  'ATLANTIC GENEVA' : '563155800',
  'X-PRESS DHAULAGIRI' : '563156200',
  'MAERSK NUSANTARA' : '563156600',
  'MAERSK CHATTOGRAM' : '563156700',
  'PACIFIC BEIJING' : '563156900',
  'PACIFIC GENEVA' : '563157300',
  'PACIFIC BUSAN' : '563157400',
  'ATLANTIC NORTH' : '563157600',
  'ATLANTIC WEST' : '563157700',
  'ATLANTIC SOUTH' : '563158200',
  'PACIFIC SHENZHEN' : '563158300',
  'PACIFIC CARRIER' : '563158400',
  'PACIFIC TIANJIN' : '563158600',
  'ATLANTIC EAST' : '563158700',
  'WAN HAI 309' : '563158800',
  'MAERSK VERACRUZ' : '563158900',
  'PACIFIC NINGBO' : '563159100',
  'ATLANTIC EXPRESS' : '563159300',
  'PACIFIC DALIAN' : '563159400',
  'WAN HAI 295' : '563159700',
  'EVER FORTUNE' : '563160100',
  'EVER FUTURE' : '563160200',
  'MAERSK VALPARAISO' : '563160400',
  'WAN HAI 308' : '563160800',
  'EVER FORWARD' : '563161200',
  'HUNSA BHUM' : '563161400',
  'WAN HAI 278' : '563161700',
  'MAERSK JIANGYIN' : '563161800',
  'MAERSK VICTORIA' : '563161900',
  'WAN HAI 627' : '563163300',
  'EVER FRANK' : '563163400',
  'WAN HAI 296' : '563163500',
  'GFS PRECIOUS' : '563164800',
  'MAERSK SHEKOU' : '563165200',
  'WAN HAI 351' : '563165500',
  'EVER ATOP' : '563166100',
  'ARAYA BHUM' : '563166600',
  'CRYSTAL A' : '563166700',
  'API BHUM' : '563167500',
  'X-PRESS ANTARES' : '563168100',
  'X-PRESS ALTAIR' : '563168200',
  'X-PRESS SALWEEN' : '563168300',
  'CAPITAINE TUPAIA' : '563168600',
  'INTERASIA ENGAGE' : '563169200',
  'WINDSWEPT' : '563169400',
  'INTERASIA ENHANCE' : '563169900',
  'WAN HAI A07' : '563170300',
  'WAN HAI 352' : '563170600',
  'WAN HAI 355' : '563171800',
  'KOTA LEGIT' : '563172200',
  'KOTA LEKAS' : '563172300',
  'WAN HAI A08' : '563172700',
  'INTERASIA ELEVATE' : '563173400',
  'WAN HAI 353' : '563173600',
  'WAN HAI 357' : '563174100',
  'BRIGHT LAEM CHABANG' : '563175600',
  'EVER ACME' : '563176600',
  'WAN HAI 356' : '563176800',
  'CAT LAI EXPRESS' : '563177100',
  'CNC SULAWESI' : '563178400',
  'KOTA NAZAR' : '563178500',
  'CNC SERVAL' : '563178900',
  'EA GANNET' : '563179100',
  'WAN HAI 358' : '563180800',
  'RACHA BHUM' : '563181000',
  'WAN HAI A01' : '563181400',
  'WAN HAI 360' : '563181600',
  'WAN HAI A09' : '563182200',
  'WAN HAI 359' : '563182400',
  'BALTIC PETREL' : '563183700',
  'LOBIVIA' : '563183800',
  'X-PRESS ANTLIA' : '563183900',
  'CFS WIND' : '563185300',
  'WAN HAI 331' : '563185700',
  'ALS CERES' : '563186500',
  'PL GERMANY' : '563186600',
  'WAN HAI A10' : '563187400',
  'WAN HAI 361' : '563187500',
  'WAN HAI A02' : '563187900',
  'MAERSK WAKAYAMA' : '563188500',
  'WAN HAI 365' : '563190100',
  'WAN HAI 363' : '563190300',
  'EVER MAX' : '563190500',
  'VIKING ORCA' : '563190600',
  'CFS HORIZON' : '563191100',
  'ALS FIDES' : '563191200',
  'ONE ATLAS' : '563191300',
  'WAN HAI 362' : '563192300',
  'SOL RELIANCE' : '563192500',
  'TIGER CHENNAI' : '563192600',
  'X-PRESS CAPELLA' : '563192700',
  'PIYA BHUM' : '563192800',
  'NATTHA BHUM' : '563192900',
  'CMA CGM GUARANI' : '563193300',
  'MAERSK WALVIS BAY' : '563193500',
  'CNC PANTHER' : '563193600',
  'SSF DREAM' : '563193900',
  'SSF DYNAMIC' : '563194100',
  'WAN HAI A03' : '563194900',
  'WAN HAI 332' : '563195600',
  'CNC LEOPARD' : '563195900',
  'SINAR BAJO' : '563196200',
  'WAN HAI A11' : '563196300',
  'WAN HAI 367' : '563197800',
  'MH PERSEUS' : '563197900',
  'MH PEGASUS' : '563198500',
  'X-PRESS CARINA' : '563199500',
  'EVER MACH' : '563199600',
  'SINAR BUKITTINGGI' : '563199800',
  'X-PRESS CASSIOPEIA' : '563201400',
  'WAN HAI A05' : '563201600',
  'CNC JAWA' : '563201800',
  'EA CHARA' : '563202300',
  'X-PRESS AQUARIUS' : '563202400',
  'EA CENTAURUS' : '563202500',
  'X-PRESS PHOENIX' : '563202800',
  'WAN HAI 333' : '563204200',
  'WAN HAI A12' : '563204900',
  'WAN HAI A13' : '563205000',
  'MAERSK YOKOHAMA' : '563205200',
  'CUL QINGDAO' : '563205400',
  'WAN HAI 368' : '563205700',
  'MAERSK OLYMPIC' : '563207600',
  'X-PRESS PISCES' : '563209300',
  'CUL SHEKOU' : '563209500',
  'WAN HAI A06' : '563210600',
  'WAN HAI 335' : '563211100',
  'CUL HOCHIMINH' : '563211300',
  'EVER CHAMP' : '563213800',
  'NPDL CALIFORNIA' : '563214600',
  'NPDL TAHITI' : '563214700',
  'WAN HAI 369' : '563217100',
  'APL MERLION' : '563231000',
  'APL FULLERTON' : '563234000',
  'APL ESPLANADE' : '563247000',
  'KOTA SEMPENA' : '563253000',
  'KOTA LESTARI' : '563265000',
  'APL CAIRO' : '563266000',
  'KOTA LOCENG' : '563267000',
  'WAN HAI 611' : '563313000',
  'KOTA CEPAT' : '563361000',
  'KOTA CEMPAKA' : '563362000',
  'NORFOLK EXPRESS' : '563377000',
  'X-PRESS MEGHNA' : '563379000',
  'WAN HAI 501' : '563382000',
  'EVER UNITED' : '563400000',
  'MERIDIAN' : '563403000',
  'MARINER' : '563417000',
  'MAERSK NORTHWOOD' : '563433000',
  'MAERSK BULAN' : '563455000',
  'MAERSK NESTON' : '563458000',
  'MAERSK NEWCASTLE' : '563460000',
  'CLEMENS SCHULTE' : '563471000',
  'CHRISTA SCHULTE' : '563479000',
  'WAN HAI 311' : '563485000',
  'CARL SCHULTE' : '563487000',
  'CHARLOTTE SCHULTE' : '563491000',
  'APL JEDDAH' : '563498000',
  'MAERSK BRATAN' : '563508000',
  'MAERSK BOGOR' : '563514000',
  'MAERSK JALAN' : '563538000',
  'MIEKE SCHULTE' : '563545000',
  'MAJESTIC' : '563549000',
  'YERUPAJA' : '563587000',
  'NYK FUJI' : '563589000',
  'KOTA RAJIN' : '563625000',
  'INTERASIA CATALYST' : '563675000',
  'KOTA NALURI' : '563677000',
  'APL SCOTLAND' : '563726000',
  'PSA SUSTAINABILITY' : '563750000',
  'APL HOLLAND' : '563754000',
  'MAERSK UTAH' : '563757000',
  'NYK RUMINA' : '563758000',
  'WAN HAI 502' : '563808000',
  'WAN HAI 503' : '563812000',
  'X-PRESS KAILASH' : '563839000',
  'ISARA BHUM' : '563841000',
  'X-PRESS KARAKORAM' : '563842000',
  'KOTA AZAM' : '563886000',
  'KOTA ANGGUN' : '563887000',
  'KOTA RANCAK' : '563916000',
  'KOTA TAMPAN' : '563955000',
  'INTERASIA HORIZON' : '563961000',
  'EVER LIVELY' : '563982000',
  'SINAR BINTAN' : '563984000',
  'CHANA BHUM' : '564029000',
  'DANU BHUM' : '564030000',
  'ORINOCO' : '564050000',
  'KOTA HIDAYAH' : '564058000',
  'M.V KITI BHUM' : '564169000',
  'ORA BHUM' : '564226000',
  'LILA BHUM' : '564241000',
  'SATTHA BHUM' : '564246000',
  'X-PRESS HOOGLY' : '564262000',
  'KOTA SETIA' : '564264000',
  'MAERSK CONAKRY' : '564305000',
  'MAERSK COTONOU' : '564306000',
  'PIRA BHUM' : '564316000',
  'NITHI BHUM' : '564341000',
  'EVER LUCENT' : '564345000',
  'METHI BHUM' : '564354000',
  'MAERSK CALABAR' : '564360000',
  'ALS SUMIRE' : '564391000',
  'MAERSK BATUR' : '564398000',
  'MAERSK BRANI' : '564399000',
  'INTERASIA MOTIVATION' : '564440000',
  'EVER ULYSSES' : '564442000',
  'KOTA RAJA' : '564519000',
  'WAN HAI 303' : '564524000',
  'KOTA RATU' : '564559000',
  'THALASSA AVRA' : '564575000',
  'KOTA RATNA' : '564588000',
  'KOTA RUKUN' : '564599000',
  'APL PUSAN' : '564619000',
  'KOTA HARUM' : '564622000',
  'KOTA SABAS' : '564639000',
  'WAN HAI 305' : '564640000',
  'MAERSK DANUBE' : '564726000',
  'KOTA SINGA' : '564757000',
  'WAN HAI 306' : '564768000',
  'KOTA SURIA' : '564796000',
  'KOTA TENAGA' : '564800000',
  'SINAR BANGKA' : '564820000',
  'MAERSK VARNA' : '564822000',
  'MAERSK VALLVIK' : '564837000',
  'WAN HAI 307' : '564868000',
  'KOTA HALUS' : '564880000',
  'KOTA HAPAS' : '564908000',
  'KOTA HAKIM' : '564909000',
  'NYK ISABEL' : '564938000',
  'MAERSK VISBY' : '564979000',
  'EVER UNICORN' : '564995000',
  'WAN HAI 505' : '565004000',
  'WAN HAI 506' : '565005000',
  'KOTA SAHABAT' : '565021000',
  'WAN HAI 312' : '565032000',
  'KOTA RIA' : '565050000',
  'KOTA SALAM' : '565110000',
  'KOTA RAKYAT' : '565127000',
  'WAN HAI 165' : '565128000',
  'WAN HAI 313' : '565132000',
  'KOTA KAMIL' : '565133000',
  'SWAN RIVER BRIDGE' : '565156000',
  'MAERSK GANGES' : '565196000',
  'LUCIE SCHULTE' : '565213000',
  'WAN HAI 315' : '565220000',
  'HANNAH SCHULTE' : '565222000',
  'GRASMERE MAERSK' : '565253000',
  'CAPT.KATTELMANN' : '565283000',
  'KOTA SATRIA' : '565334000',
  'KOTA SEGAR' : '565357000',
  'MATHILDE SCHULTE' : '565376000',
  'MAX SCHULTE' : '565380000',
  'MELCHIOR SCHULTE' : '565382000',
  'MIA SCHULTE' : '565385000',
  'MATSON MAUI' : '565402000',
  'SOL SINGAPORE' : '565413000',
  'KOTA DUNIA' : '565417000',
  'WAN HAI 316' : '565425000',
  'MAERSK SEMBAWANG' : '565448000',
  'MAERSK SERANGOON' : '565449000',
  'MAERSK SEBAROK' : '565451000',
  'MAERSK NORTHAMPTON' : '565473000',
  'MAERSK NEWHAVEN' : '565475000',
  'MAERSK SENANG' : '565482000',
  'MAERSK SEMAKAU' : '565485000',
  'MAERSK VILNIUS' : '565533000',
  'WAN HAI 507' : '565536000',
  'GFS SAPPHIRE' : '565547000',
  'BLPL BLESSING' : '565564000',
  'WAN HAI 623' : '565565000',
  'WAN HAI 508' : '565567000',
  'MAERSK ALTAIR' : '565570000',
  'NAWATA BHUM' : '565615000',
  'MITRA BHUM' : '565616000',
  'ASIATIC NEPTUNE' : '565626000',
  'WAN HAI 509' : '565653000',
  'MAERSK ANTARES' : '565661000',
  'KOTA NAZIM' : '565688000',
  'OTANA BHUM' : '565696000',
  'PANJA BHUM' : '565697000',
  'KOTA SEJARAH' : '565706000',
  'KOTA RAHMAT' : '565730000',
  'WAN HAI 510' : '565741000',
  'MAERSK ALFIRK' : '565747000',
  'CAPITAINE WALLIS' : '565760000',
  'MAERSK TAURUS' : '565762000',
  'KOTA SEJATI' : '565771000',
  'KOTA DAHLIA' : '565772000',
  'WAN HAI 317' : '565777000',
  'KOTA DUTA' : '565780000',
  'KOTA NABIL' : '565795000',
  'NYK CLARA' : '565807000',
  'MAERSK BATAM' : '565810000',
  'MAERSK ALGOL' : '565819000',
  'MAERSK TUKANG' : '565825000',
  'ASIATIC KING' : '565849000',
  'ACX DIAMOND' : '565887000',
  'KOTA NAGA' : '565893000',
  'KOTA RESTU' : '565904000',
  'KOTA LAMBAI' : '565909000',
  'MAERSK JABAL' : '565936000',
  'KOTA LAMBANG' : '565944000',
  'SINAR SOLO' : '565953000',
  'NYK DANIELLA' : '565963000',
  'MAERSK VIGO' : '565967000',
  'LUDWIG SCHULTE' : '565970000',
  'MAERSK BUTON' : '565971000',
  'NYK FUTAGO' : '566002000',
  'MAERSK CAPE COAST' : '566055000',
  'MAERSK CAMEROUN' : '566056000',
  'MAERSK CHENNAI' : '566093000',
  'KOTA NIPAH' : '566118000',
  'SINAR BANDUNG' : '566127000',
  'MAERSK CONGO' : '566145000',
  'MAERSK CUNENE' : '566158000',
  'MAERSK CAPE TOWN' : '566187000',
  'WAN HAI 271' : '566235000',
  'NYK FURANO' : '566259000',
  'MAERSK NEWBURY' : '566278000',
  'KOTA SELAMAT' : '566282000',
  'APL CHONGQING' : '566318000',
  'APL GWANGYANG' : '566319000',
  'NYK FUSHIMI' : '566335000',
  'WAN HAI 272' : '566340000',
  'WAN HAI 273' : '566406000',
  'APL LE HAVRE' : '566407000',
  'APL SOUTHAMPTON' : '566409000',
  'APL SALALAH' : '566410000',
  'SAFMARINE CAMEROUN' : '566415000',
  'SOL PIONEER' : '566431000',
  'GFS GENESIS' : '566446000',
  'GFS GISELLE' : '566456000',
  'EVER PEARL' : '566460000',
  'APL YANG SHAN' : '566482000',
  'APL BARCELONA' : '566483000',
  'MAERSK CUANZA' : '566490000',
  'MAERSK CABINDA' : '566491000',
  'MAERSK COLOMBO' : '566492000',
  'MAERSK COPENHAGEN' : '566493000',
  'WAN HAI 511' : '566510000',
  'WAN HAI 512' : '566642000',
  'WAN HAI 102' : '566653000',
  'EVER LAUREL' : '566673000',
  'APL PARIS' : '566704000',
  'APL DUBLIN' : '566705000',
  'WAN HAI 513' : '566730000',
  'EVER LAWFUL' : '566732000',
  'MAERSK CADIZ' : '566755000',
  'MAERSK CARDIFF' : '566756000',
  'WAN HAI 105' : '566760000',
  'KOTA MANIS' : '566773000',
  'MAERSK INNOSHIMA' : '566779000',
  'MAERSK IZMIR' : '566780000',
  'MAERSK INVERNESS' : '566781000',
  'EVER LEADER' : '566794000',
  'APL TEMASEK' : '566796000',
  'APL VANCOUVER' : '566797000',
  'WAN HAI 515' : '566828000',
  'EVER LEGACY' : '566853000',
  'KOTA LEMBAH' : '566858000',
  'KOTA MEGAH' : '566864000',
  'APL NEW YORK' : '566879000',
  'APL LION CITY' : '566880000',
  'APL RAFFLES' : '566881000',
  'APL CHANGI' : '566882000',
  'WAN HAI 516' : '566886000',
  'KOTA MACHAN' : '566894000',
  'KOTA GADANG' : '566941000',
  'WAN HAI 517' : '566942000',
  'EVER LEGEND' : '566949000',
  'APL BOSTON' : '566950000',
  'APL PHOENIX' : '566951000',
  'APL SAVANNAH' : '566952000',
  'APL COLUMBUS' : '566953000',
  'APL VANDA' : '566954000',
  'APL SINGAPURA' : '566955000',
  'APL HOUSTON' : '566956000',
  'APL MIAMI' : '566957000',
  'APL CHARLESTON' : '566958000',
  'APL SANTIAGO' : '566959000',
  'APL DETROIT' : '566960000',
  'APL MEXICO CITY' : '566961000',
  'EVER LEGION' : '566970000',
  'KOTA GANDING' : '566981000',
  'WAN HAI 621' : '566999000',
  'NP CHANTHABURI' : '567002370',
  'NP TRAT' : '567002380',
  'NP CHUMPHON' : '567002520',
  'HARI BHUM' : '567072300',
  'URU BHUM' : '567303000',
  'VIRA BHUM' : '567304000',
  'WANA BHUM' : '567317000',
  'XUTRA BHUM' : '567318000',
  'SIRI BHUM' : '567477000',
  'INTRA BHUM' : '567483000',
  'JARU BHUM' : '567571000',
  'KHUNA BHUM' : '567574000',
  'LALIT BHUM' : '567584000',
  'MAKHA BHUM' : '567589000',
  'ZIM THAILAND' : '567609000',
  'ZIM BANGKOK' : '567614000',
  'X PRESS KOHIMA' : '572158220',
  'GOLDEN OCEAN 26' : '574000120',
  'NEW VISION' : '574001250',
  'GOLDEN OCEAN 25' : '574001370',
  'NASICO SKY' : '574001430',
  'HAIAN MIND' : '574001630',
  'VINASHIN EXPRESS 01' : '574001980',
  'TAN CANG PIONEER' : '574002120',
  'VINAFCO 26' : '574002190',
  'HAIAN PARK' : '574002240',
  'TRUONG HAI STAR 3' : '574002270',
  'PHUC KHANH' : '574002540',
  'HAIAN TIME' : '574002850',
  'TAN CANG GLORY' : '574002930',
  'HAIAN BELL' : '574003500',
  'GREEN PACIFIC' : '574003640',
  'PRIDE PACIFIC' : '574003760',
  'TC SYMPHONY' : '574003870',
  'VINAFCO 28' : '574004000',
  'HAIAN LINK' : '574004050',
  'PHUC THAI' : '574004260',
  'PREMIER' : '574004280',
  'PROGRESS' : '574004660',
  'HAIAN VIEW' : '574004780',
  'HAIAN EAST' : '574005100',
  'HAIAN WEST' : '574005160',
  'PRIME' : '574005410',
  'ANBIEN BAY' : '574005610',
  'HAIAN CITY' : '574005640',
  'HAIAN ROSE' : '574005800',
  'TRANSIMEX SUN' : '574005870',
  'HAIAN ALFA' : '574005940',
  'HOA MAI 16' : '574117000',
  'MORNING VINAFCO' : '574134000',
  'BIENDONG MARINER' : '574260000',
  'ATLANTIC OCEAN' : '574269000',
  'BIEN DONG NAVIGATOR' : '574280000',
  'BIENDONG STAR' : '574387000',
  'FORTUNE NAVIGATOR' : '574409000',
  'FORTUNE FREIGHTER' : '574410000',
  'VIMC PIONEER' : '574453000',
  'PACIFIC EXPRESS' : '574480000',
  'HAI VIET STAR' : '574491000',
  'LADY-DEFNE' : '577111001',
  'ATA 2' : '577127000',
  'DUYGU' : '577165000',
  'MICRONESIAN PRIDE' : '577508000',
  'SUGANYU09458' : '600000000',
  'TAMANRASSET' : '605086020',
  'CIRTA' : '605086060',
  'DJANET' : '605086090',
  'IMEDGHASSEN' : '605136720',
  'YEKTA 2' : '613003732',
  'PSL EAGLE' : '613139804',
  'PSL TIGER' : '613139808',
  'AURELIA' : '613426201',
  'DELPHINUS' : '613519203',
  'BLUE STAR 3' : '613717300',
  'PROFESSOR B' : '620040000',
  'MV OCEAN TRADER' : '620620000',
  'TARLAN' : '620724000',
  'ANNABELLA' : '620743000',
  'SOFIA 3' : '620999147',
  'RACE I' : '620999272',
  'SILK' : '620999274',
  'AFRICA SUN' : '621819050',
  'PAN GG' : '622110051',
  'EGY FUTURE' : '622113175',
  'EGY GLORY' : '622113176',
  'EGY SKY' : '622113177',
  'EGY CROWN' : '622113180',
  'WADI ALRAYAN' : '622121427',
  'TAI LE' : '636000000',
  'OOCL BREMERHAVEN' : '636009008',
  'YM WEALTH' : '636012252',
  'YM FOUNTAIN' : '636012253',
  'YM SUCCESS' : '636012254',
  'YM INCREMENT' : '636012794',
  'YM INCEPTION' : '636012795',
  'YM IMAGE' : '636012796',
  'YM INITIATIVE' : '636012797',
  'YM INVENTIVE' : '636012798',
  'MSC UNITED VIII' : '636012807',
  'MSC UTMOST VIII' : '636012808',
  'LORRAINE' : '636012905',
  'KILIMANJARO' : '636013001',
  'YM INSTRUCTION' : '636013118',
  'YM INTERACTION' : '636013119',
  'YM IMPROVEMENT' : '636013121',
  'MSC CAGLIARI IV' : '636013143',
  'COLOMBO' : '636013289',
  'DERBY D' : '636013437',
  'APL MINNESOTA' : '636013521',
  'APL NEW JERSEY' : '636013522',
  'SINGAPORE' : '636013530',
  'TONGALA' : '636013644',
  'MSC UBERTY VIII' : '636013689',
  'NILEDUTCH LION' : '636013690',
  'YM UPWARD' : '636013691',
  'YM UTILITY' : '636013692',
  'YM UNIFORM' : '636013693',
  'YM EFFICIENCY' : '636013698',
  'YM ETERNITY' : '636013699',
  'EVRIDIKI G' : '636013898',
  'M -FJICIENCY' : '636014018',
  'KASSIAKOS' : '636014059',
  'AITOLIKOS' : '636014168',
  'CMA CGM TOPAZ' : '636014169',
  'CMA CGM LAPIS' : '636014199',
  'IONIKOS' : '636014200',
  'ZIM NINGBO' : '636014219',
  'ZIM ANTWERP' : '636014220',
  'ZIM SAN DIEGO' : '636014221',
  'ZIM ROTTERDAM' : '636014222',
  'TIANJIN' : '636014224',
  'YM MANDATE' : '636014557',
  'YM MATURITY' : '636014558',
  'IKARIA' : '636014573',
  'FOLEGANDROS' : '636014590',
  'GSL AFRICA' : '636014603',
  'MSC PETRA' : '636014644',
  'DIAMANTIS P' : '636014699',
  'IRENES REMEDY' : '636014740',
  'ATOUT' : '636014890',
  'EM SPETSES' : '636014955',
  'EM HYDRA' : '636014956',
  'HISTORY ELIZABETH' : '636014965',
  'MARIANETTA' : '636014968',
  'MSC SINES R' : '636014971',
  'YM MUTUALITY' : '636014996',
  'YM MOBILITY' : '636014997',
  'ERATO' : '636015002',
  'EXPRESS ROME' : '636015019',
  'EXPRESS ATHENS' : '636015020',
  'MSC STAR R' : '636015031',
  'MALIAKOS' : '636015131',
  'MSC DURBAN IV' : '636015181',
  'YM MILESTONE' : '636015182',
  'YM MASCULINITY' : '636015183',
  'MSC MANYA' : '636015233',
  'EM ASTORIA' : '636015321',
  'METHONI' : '636015404',
  'NOBILITY' : '636015465',
  'ROSA' : '636015467',
  'KESTREL' : '636015480',
  'PELICAN' : '636015481',
  'MSC MEXICO V' : '636015496',
  'LANA' : '636015503',
  'MSC VEGA' : '636015506',
  'HYUNDAI HONOUR' : '636015514',
  'HYUNDAI RESPECT' : '636015516',
  'IRENES RESOLVE' : '636015524',
  'MSC ALTAIR' : '636015604',
  'HYUNDAI AMBITION' : '636015607',
  'EM KEA' : '636015646',
  'MATE' : '636015659',
  'MSC ALTAMIRA' : '636015664',
  'MSC ALBANY' : '636015665',
  'MSC ANCHORAGE' : '636015666',
  'CALI' : '636015667',
  'GSL CHRISTEL ELISABE' : '636015685',
  'GSL VINIA' : '636015686',
  'MSC ARCHIMIDIS' : '636015743',
  'AGAMEMNON' : '636015744',
  'ARGOLIKOS' : '636015754',
  'LUEBECK' : '636015775',
  'BIG GEORGE' : '636015779',
  'HYUNDAI PRESTIGE' : '636015806',
  'ZIM ALABAMA' : '636015872',
  'NAGOYA TOWER' : '636015873',
  'HYUNDAI PREMIUM' : '636015876',
  'ALEXANDER BAY' : '636015897',
  'MSC HIMANSHI' : '636015899',
  'ANL GIPPSLAND' : '636015912',
  'QINGDAO TOWER' : '636015930',
  'HYUNDAI PARAMOUNT' : '636015950',
  'ADAMS' : '636015952',
  'AGIOS DIMITRIOS' : '636015975',
  'TOCONAO' : '636015980',
  'HYUNDAI PRIVILEGE' : '636016003',
  'MSC ALIX 3' : '636016014',
  'HYUNDAI PLATINUM' : '636016038',
  'MSC POLARIS' : '636016066',
  'TOKYO BAY' : '636016074',
  'ENSENADA' : '636016106',
  'JOANNA' : '636016118',
  'TRAIGUEN' : '636016125',
  'CAP ANDREAS' : '636016126',
  'TRANCURA' : '636016177',
  'BOX ENDEAVOUR' : '636016179',
  'CARDIFF' : '636016185',
  'HAPPY BEE' : '636016186',
  'MSC PRATITI' : '636016204',
  'SWANSEA' : '636016231',
  'YM MODESTY' : '636016242',
  'LOUISE' : '636016247',
  'MSC ALINA' : '636016305',
  'MSC SOFIA PAZ' : '636016306',
  'TYNDALL' : '636016321',
  'MSC CAPUCINE R' : '636016415',
  'MSC GLORY R' : '636016417',
  'MSC BHAVYA V' : '636016423',
  'MSC ANYA' : '636016424',
  'MSC MARGARITA' : '636016429',
  'MSC JULIA R.' : '636016430',
  'MSC VAISHNAVI R.' : '636016431',
  'MSC ARUSHI R.' : '636016432',
  'MSC VIDHI' : '636016433',
  'MSC ANISHA R.' : '636016434',
  'MSC KATYA R.' : '636016435',
  'MSC ZLATA R' : '636016436',
  'MSC VIDISHA R.' : '636016437',
  'MSC ISHYKA' : '636016457',
  'MSC CAPRI' : '636016458',
  'MSC ROCHELLE' : '636016476',
  'AREOPOLIS' : '636016483',
  'MSC SAO PAULO V' : '636016492',
  'BOX ENDURANCE' : '636016502',
  'MSC SKY II' : '636016574',
  'THORSWIND' : '636016575',
  'X-PRESS NUPTSE' : '636016619',
  'GALEN' : '636016647',
  'GARWOOD' : '636016649',
  'MSC PHOENIX' : '636016672',
  'MSC PEGASUS' : '636016673',
  'X-PRESS LHOTSE' : '636016677',
  'CONTSHIP OAK' : '636016702',
  'YM ENLIGHTENMENT' : '636016703',
  'YM EXCELLENCE' : '636016704',
  'YM EXPRESS' : '636016705',
  'ODYSSEUS' : '636016770',
  'MSC CHERYL 3' : '636016775',
  'MSC NADRIELY' : '636016805',
  'MSC ELSA3' : '636016814',
  'ESL SANA' : '636016850',
  'MSC JULIANA III' : '636016851',
  'MONA LISA' : '636016852',
  'CSL MANHATTAN' : '636016853',
  'PANTHER' : '636016854',
  'ESL WAFA' : '636016855',
  'ESL DANA' : '636016856',
  'MSC CRISTINA' : '636016858',
  'ALE' : '636016895',
  'MSC MANU' : '636016940',
  'MSC RADIANT III' : '636016943',
  'MSC OLIA' : '636016973',
  'SEABOARD PATRIOT' : '636016975',
  'MSC TAVVISHI' : '636016978',
  'ZIM EUROPE' : '636016979',
  'IAN H' : '636016980',
  'ZIM NORFOLK' : '636016983',
  'ZIM XIAMEN' : '636016985',
  'ANTHEA Y' : '636016986',
  'MSC MIRELLA R' : '636016999',
  'MSC RONIT R' : '636017000',
  'MSC ROBERTA V' : '636017006',
  'MSC CARLA III' : '636017007',
  'ANGELIKI' : '636017016',
  'MSC SHIRLEY' : '636017022',
  'MSC RHIANNON' : '636017023',
  'SPECTRUM N' : '636017038',
  'HSL NIKE' : '636017045',
  'MSC MILA 3' : '636017055',
  'MSC HINA' : '636017056',
  'MAERSK SARAT' : '636017102',
  'MAERSK SKARSTIND' : '636017103',
  'MAERSK SHIVLING' : '636017104',
  'CHIQUITA MERCHANT' : '636017112',
  'MSC JANIS 3' : '636017116',
  'SEALAND MANZANILLO' : '636017153',
  'SEALAND LOS ANGELES' : '636017154',
  'SEALAND PHILADELPHIA' : '636017155',
  'ARKADIA' : '636017164',
  'MSC CATHERINE VI' : '636017173',
  'ALS KRONOS' : '636017175',
  'MSC GRENADA III' : '636017191',
  'CHIQUITA EXPRESS' : '636017218',
  'CHIQUITA TRADER' : '636017219',
  'MSC SPRING III' : '636017239',
  'MSC KATALIN II' : '636017244',
  'MSC POLO II' : '636017245',
  'MSC LEO VI' : '636017306',
  'GROTON' : '636017309',
  'MSC SHAULA' : '636017346',
  'ROBIN 5' : '636017352',
  'ROBIN 4' : '636017360',
  'CONTSHIP MAX' : '636017374',
  'TYGRA' : '636017404',
  'MYDJIMEI' : '636017405',
  'MSC VENICE' : '636017408',
  'CMA CGM CORNEILLE' : '636017425',
  'MSC DIANA' : '636017433',
  'MSC INGY' : '636017434',
  'MSC ELOANE' : '636017435',
  'CALA PAGURO' : '636017440',
  'LIDIA' : '636017505',
  'MSC JADE' : '636017506',
  'MSC CORNELIA' : '636017514',
  'SOFIA I' : '636017515',
  'MSC MIRJA' : '636017516',
  'GARDINER' : '636017520',
  'MSC JUANITA F' : '636017534',
  'MSC ISTANBUL' : '636017537',
  'MSC AGATA II' : '636017548',
  'MSC DITTE' : '636017569',
  'MSC REEF' : '636017577',
  'MSC ANNICK' : '636017581',
  'MSC SENA' : '636017582',
  'MSC CLEMENTINA F' : '636017605',
  'BOMAR RENAISSANCE' : '636017608',
  'CITRUS VITA BRASIL' : '636017610',
  'KURE' : '636017643',
  'PERITO MORENO' : '636017683',
  'MSC MIRJAM' : '636017684',
  'MSC LEANNE' : '636017686',
  'KMTC BANGKOK' : '636017724',
  'MSC ERICA' : '636017726',
  'MSC ANNA' : '636017727',
  'CHIQUITA VENTURE' : '636017730',
  'CHIQUITA PROGRESS' : '636017731',
  'KMTC LAEM CHABANG' : '636017768',
  'GSL ELEFTHERIA' : '636017802',
  'GSL MELINA' : '636017803',
  'CONTSHIP RAY' : '636017852',
  'MSC TINA' : '636017867',
  'MSC IZMIR F' : '636017878',
  'MSC VIVIANA' : '636017882',
  'CONTSHIP SUN' : '636017894',
  'GSL ALICE' : '636017907',
  'MSC TOPAZ' : '636017916',
  'MSC PERLE' : '636017921',
  'MSC SHEFFIELD III' : '636017929',
  'QUEENSLAND' : '636017944',
  'NEWNEW MOON' : '636017957',
  'TB FENGZE' : '636017958',
  'HALLEY' : '636017971',
  'BOMAR PRAIA' : '636017993',
  'TRF PARTICI' : '636017994',
  'PESCARA' : '636017995',
  'CONTSHIP TOP' : '636018000',
  'CONTSHIP UNO' : '636018001',
  'MAERSK KOWLOON' : '636018018',
  'MSC ALICE II' : '636018026',
  'ATHENIAN' : '636018028',
  'AS CASPRIA' : '636018035',
  'MSC SHANVI III' : '636018036',
  'ATHOS' : '636018037',
  'NAVIOS CONSTELLATION' : '636018038',
  'FS IPANEMA' : '636018056',
  'LOTUS A' : '636018057',
  'CYPRESS' : '636018058',
  'KOI' : '636018059',
  'MSC AVNI' : '636018068',
  'NAVIOS UNISON' : '636018069',
  'ARISTOMENIS' : '636018070',
  'MSC ADITI' : '636018071',
  'MSC ROMA' : '636018075',
  'MSC LISBON' : '636018079',
  'BELITAKI' : '636018081',
  'SM MUMBAI' : '636018097',
  'BELITA' : '636018102',
  'MSC BEIRA IV' : '636018105',
  'MSC CARLOTTA' : '636018106',
  'CONTSHIP ECO' : '636018167',
  'CONTSHIP VOW' : '636018185',
  'MSC ROSHNEY V' : '636018189',
  'MSC PORTO III' : '636018191',
  'PORTSMOUTH' : '636018192',
  'SC MONTREAL' : '636018193',
  'SC MONTANA' : '636018194',
  'MSC GIADA III' : '636018204',
  'DELPHINUS C' : '636018222',
  'MARIA C' : '636018223',
  'ACX PEARL' : '636018224',
  'KOTA MANZANILLO' : '636018237',
  'MSC DENMARK VI' : '636018240',
  'MSC SWEDEN VI' : '636018242',
  'CALLIOPE' : '636018257',
  'YM PLUM' : '636018262',
  'YM ORCHID' : '636018263',
  'YM COSMOS' : '636018264',
  'MSC JEANNE' : '636018268',
  'MSC GAYANE' : '636018276',
  'NAVIOS TEMPO' : '636018311',
  'MSC PAOLA' : '636018318',
  'MSC POSITANO' : '636018319',
  'MSC SARAH V' : '636018320',
  'MSC ADRIANA II' : '636018321',
  'MSC ASLI' : '636018322',
  'MSC CAITLIN' : '636018323',
  'MSC EDITH II' : '636018324',
  'MSC LEA' : '636018325',
  'MSC MIA SUMMER' : '636018326',
  'POLAR CHILE' : '636018346',
  'NAVIOS CHRYSALIS' : '636018364',
  'GSL MERCER' : '636018378',
  'BEETHOVEN' : '636018380',
  'BIG DOG' : '636018382',
  'MSC NATASHA XIII' : '636018385',
  'MSC NITA' : '636018389',
  'ZIM BALTIMORE' : '636018471',
  'MSC VANQUISH II' : '636018490',
  'MSC KAYLA' : '636018491',
  'AS ALVA' : '636018502',
  'POLAR PERU' : '636018523',
  'SEOUL GLOW' : '636018529',
  'CMA CGM MISSISSIPPI' : '636018541',
  'CMA CGM MISSOURI' : '636018542',
  'GSL ROSSI' : '636018553',
  'CHARM C' : '636018560',
  'MSC HUSUM III' : '636018561',
  'MSC PALATIUM III' : '636018562',
  'NAVIOS DORADO' : '636018563',
  'CMA CGM CONGO' : '636018564',
  'MSC EMMA' : '636018571',
  'MSC ELISA XIII' : '636018572',
  'MSC CLAUDIA' : '636018573',
  'MSC HERMES' : '636018593',
  'MSC ARIA III' : '636018594',
  'HAPPY LUCKY' : '636018597',
  'MSC TANIA' : '636018603',
  'MSC NOA' : '636018659',
  'MSC ANTONIA' : '636018660',
  'PUTNAM' : '636018680',
  'GSL VALERIE' : '636018693',
  'THALASSA MANA' : '636018700',
  'THALASSA AXIA' : '636018701',
  'THALASSA TYHI' : '636018702',
  'THALASSA DOXA' : '636018703',
  'CONTSHIP ZEN' : '636018704',
  'CONTSHIP CUB' : '636018712',
  'BUXMELODY' : '636018715',
  'MSC EXPRESS III' : '636018729',
  'MSC EMDEN III' : '636018732',
  'BACH' : '636018739',
  'AS ALEXANDRIA' : '636018744',
  'AS ANITA' : '636018745',
  'HANSA HOMBURG' : '636018768',
  'FITZ ROY' : '636018769',
  'KARLSKRONA' : '636018800',
  'DEBUSSY' : '636018807',
  'ELA' : '636018846',
  'PACIFIC QINGDAO' : '636018863',
  'NAVIOS MIAMI' : '636018904',
  'KLEVEN' : '636018912',
  'SEATRADE GREEN' : '636018931',
  'KOTKA' : '636018933',
  'MSC YORK VII' : '636018964',
  'MSC BIANCA' : '636019021',
  'CONTSHIP FUN' : '636019053',
  'SKY SUNSHINE' : '636019057',
  'NAVIOS BAHAMAS' : '636019058',
  'CARMEL I' : '636019061',
  'CONTSHIP GIN' : '636019163',
  'EVER GENTLE' : '636019174',
  'ETE N' : '636019197',
  'MSC JOSSELINE' : '636019206',
  'MSC JEWEL' : '636019207',
  'MSC FAITH' : '636019213',
  'MSC KANOKO' : '636019214',
  'MSC ALIYA' : '636019215',
  'CONTSHIP RUN' : '636019221',
  'CONTSHIP SEA' : '636019225',
  'EVER GLORY' : '636019234',
  'CONTSHIP DON' : '636019245',
  'LASALLE' : '636019256',
  'MSC HARMONY III' : '636019289',
  'MSC ABIGAIL F' : '636019292',
  'MSC QINGDAO' : '636019331',
  'MSC TIANJIN' : '636019332',
  'FSL SINGAPORE' : '636019336',
  'FSL KELANG' : '636019337',
  'ROB' : '636019341',
  'GSL ELENI' : '636019342',
  'MSC AINO' : '636019348',
  'ZIM TAMPA' : '636019355',
  'MSC TAMISHKA F' : '636019364',
  'GSL SUSAN' : '636019366',
  'MSC ENGLAND' : '636019395',
  'GSL NINGBO' : '636019407',
  'GALLOWAY' : '636019425',
  'MSC ROWAN' : '636019434',
  'MSC RESILIENT III' : '636019443',
  'ALOPO' : '636019446',
  'GSL GRANIA' : '636019493',
  'X-PRESS ANGLESEY' : '636019507',
  'BOSTON TRADER' : '636019515',
  'LISA' : '636019523',
  'CONTSHIP JET' : '636019532',
  'GSL KALLIOPI' : '636019536',
  'CONTSHIP ACE' : '636019562',
  'CALYPSO' : '636019574',
  'MSC ARICA' : '636019582',
  'X PRD3S BARDSEY' : '636019587',
  'VIVALDI' : '636019606',
  'X PRESS BARDSEY' : '636019617',
  'ADMIRAL GALAXY' : '636019647',
  'FAR EAST CHEER' : '636019671',
  'GSL NICOLETTA' : '636019673',
  'GREEN ACE' : '636019697',
  'ASIAN ACE' : '636019698',
  'MAERSK HAI PHONG' : '636019700',
  'VELA' : '636019716',
  'VOLANS' : '636019717',
  'VULPECULA' : '636019718',
  'CONTSHIP MED' : '636019746',
  'MYNY' : '636019759',
  'GSL CHRISTEN' : '636019760',
  'MSC DIYA F' : '636019762',
  'MANET' : '636019770',
  'LORI' : '636019794',
  'MAERSK KWANGYANG' : '636019821',
  'LYON II' : '636019825',
  'MSC NILA II' : '636019841',
  'MSC IDA II' : '636019861',
  'KETA' : '636019862',
  'JULIE' : '636019863',
  'AKITETA' : '636019864',
  'CMA CGM BERLIOZ' : '636019868',
  'KOTA SANTOS' : '636019875',
  'MSC ANDREA F' : '636019887',
  'YM CELEBRITY' : '636019892',
  'YM CONTINENT' : '636019893',
  'YM CREDENTIAL' : '636019894',
  'YM CENTENNIAL' : '636019895',
  'YM CAPACITY' : '636019896',
  'YM CERTAINTY' : '636019897',
  'YM CREDIBILITY' : '636019898',
  'YM CONSTANCY' : '636019899',
  'YM COOPERATION' : '636019900',
  'YM CONTINUITY' : '636019901',
  'FLEUR N' : '636019931',
  'YM TRIUMPH' : '636019982',
  'YM TRUTH' : '636019983',
  'YM TOTALITY' : '636019984',
  'YM TARGET' : '636019985',
  'YM TIPTOP' : '636019986',
  'JONATHAN P' : '636020153',
  'ZEBRA' : '636020157',
  'SAN AMERIGO' : '636020168',
  'SAN ALFONSO' : '636020191',
  'SAN ALBERTO' : '636020192',
  'MSC CANCUN' : '636020198',
  'SCORPIUS' : '636020202',
  'KMTC YOKOHAMA' : '636020213',
  'MSC JESSENIA R' : '636020215',
  'KMTC XIAMEN' : '636020220',
  'KMTC SHIMIZU' : '636020221',
  'SEABOARD EXPLORER' : '636020226',
  'BLACK PEARL' : '636020240',
  'LONG BEACH EXPRESS' : '636020252',
  'KMTC DALIAN' : '636020254',
  'SEATTLE EXPRESS' : '636020257',
  'FOS EXPRESS' : '636020258',
  'TB BRIGHT CITY' : '636020265',
  'ZOI' : '636020266',
  'ADMIRAL MARS' : '636020280',
  'CONTSHIP KEY' : '636020307',
  'BREMEN' : '636020326',
  'C HAMBURG' : '636020327',
  'HMM NURI' : '636020332',
  'MSC SHRISTI' : '636020334',
  'MSC PANAYA' : '636020343',
  'NEOKASTRO' : '636020345',
  'TONSBERG' : '636020348',
  'MARIANNA I' : '636020349',
  'EPAMINONDAS' : '636020350',
  'ZIM HAIFA' : '636020351',
  'SONGA PUMA' : '636020352',
  'ROBIN 2' : '636020353',
  'MSC PASSION III' : '636020354',
  'MSC MALENA' : '636020360',
  'MENDELSSOHN' : '636020361',
  'EVER FAR' : '636020371',
  'HMM GAON' : '636020383',
  'HMM GARAM' : '636020384',
  'OKEE CUNO' : '636020401',
  'NATAL' : '636020436',
  'IRENES RAY' : '636020437',
  'CHOPIN' : '636020438',
  'MSC KANU F' : '636020440',
  'ELIZABETH' : '636020454',
  'CINDY' : '636020455',
  'EVER FAST' : '636020456',
  'MSC DAISY' : '636020468',
  'ARGUS' : '636020476',
  'STAMATIS B' : '636020483',
  'MSC TEXAS' : '636020494',
  'MSC TIANPING' : '636020495',
  'MSC TOKYO' : '636020497',
  'MSC YOKOHAMA' : '636020498',
  'MSC VANCOUVER' : '636020499',
  'MSC LIDIA' : '636020500',
  'MSC TIANSHAN' : '636020501',
  'PAFILIA' : '636020516',
  'BIGLI' : '636020524',
  'MUNKSUND' : '636020532',
  'TUNADAL' : '636020533',
  'EVER FAIR' : '636020536',
  'TORO' : '636020539',
  'MSC APOLLINE' : '636020542',
  'MSC AMELIA' : '636020543',
  'MSC CHARLOTTE' : '636020574',
  'ADMIRAL SUN' : '636020581',
  'ADMIRAL MOON' : '636020582',
  'GLEN CANYON' : '636020583',
  'CONTSHIP LEX' : '636020585',
  'GIALOVA' : '636020589',
  'GSL MAREN' : '636020595',
  'MSC DILETTA' : '636020598',
  'MSC MICHELLE' : '636020599',
  'MSC ALLEGRA' : '636020600',
  'YOGI' : '636020609',
  'ANDROUSA' : '636020610',
  'HMM MIR' : '636020615',
  'VASI STAR' : '636020646',
  'ZIM IBERIA' : '636020648',
  'PINOCCHIO' : '636020659',
  'SC PHILLY' : '636020661',
  'SC POTOMAC' : '636020662',
  'SC PHOENIX' : '636020663',
  'SC MARIGOT' : '636020666',
  'SC MEDFORD' : '636020667',
  'SC MEMPHIS' : '636020668',
  'EVER FEAT' : '636020684',
  'MSC EMILIE' : '636020695',
  'MSC SAMU' : '636020700',
  'MSC KYMEA II' : '636020701',
  'MSC TIPHAINE II' : '636020702',
  'HMM HANBADA' : '636020707',
  'MSC DAR ES SALAAM 3' : '636020712',
  'MSC VILDA X' : '636020728',
  'MSC ELLEN' : '636020729',
  'MSC ABY' : '636020730',
  'HMM DAON' : '636020743',
  'HMM HANUL' : '636020744',
  'HMM RAON' : '636020746',
  'BERTIE' : '636020758',
  'GSL DOROTHEA' : '636020769',
  'GSL TEGEA' : '636020770',
  'GSL ARCADIA' : '636020772',
  'GSL MYNY' : '636020773',
  'GSL MELITA' : '636020774',
  'GSL MARIA' : '636020775',
  'GSL VIOLETTA' : '636020776',
  'MSC LANGSAR' : '636020781',
  'MSC SHAHAR' : '636020782',
  'MSC MATTINA' : '636020783',
  'STAR' : '636020787',
  'MSC DHANTIA F' : '636020811',
  'ZAGOR' : '636020813',
  'CONTSHIP ANA' : '636020814',
  'NORFOLK' : '636020824',
  'POLYNESIA' : '636020846',
  'PORTO KAGIO' : '636020868',
  'PORTO CHELI' : '636020869',
  'PORTO GERMENO' : '636020870',
  'MSC RIONA' : '636020871',
  'AEOLUS' : '636020878',
  'HOLSATIA' : '636020886',
  'MSC DORINE' : '636020898',
  'MSC MARIA CLARA' : '636020899',
  'MSC NIKOLETA II' : '636020902',
  'KONRAD' : '636020910',
  'MSC ANAHITA' : '636020918',
  'MSC TIANA F' : '636020919',
  'MAERSK PANGANI' : '636020928',
  'MSC PATNAREE III' : '636020930',
  'MSC BREMEN' : '636020936',
  'MSC YURIDA III' : '636020937',
  'VELIKA' : '636020938',
  'SOUNION TRADER' : '636020939',
  'MIRADOR EXPRESS' : '636020941',
  'MSC MANASA F' : '636020949',
  'MSC SAMIRA III' : '636020952',
  'MSC ELIZABETH III' : '636020953',
  'SKYVIEW' : '636020959',
  'MSC ANA CAMILA III' : '636020962',
  'MSC TRADER II' : '636020978',
  'MSC KUMSAL' : '636020979',
  'GSL KITHIRA' : '636020983',
  'GSL TINOS' : '636020984',
  'GSL SYROS' : '636020985',
  'GSL TRIPOLI' : '636020986',
  'ADDISON' : '636020990',
  'HALSTED' : '636020991',
  'ARCHER' : '636020992',
  'EVER FASHION' : '636020994',
  'HAKUNA MATATA' : '636021002',
  'GSL LALO' : '636021004',
  'MATSON MOLOKAI' : '636021005',
  'GSL ELIZABETH' : '636021006',
  'MSC RINI III' : '636021011',
  'MSC CAROLE' : '636021012',
  'SFL MAUI' : '636021013',
  'MSC OLGA F' : '636021017',
  'MSC VAIGA III' : '636021031',
  'SAVANNAH EXPRESS' : '636021035',
  'THALASSA ELPIDA' : '636021036',
  'SFL HAWAII' : '636021040',
  'ANTIBES EXPRESS' : '636021055',
  'SPARTEL TRADER' : '636021056',
  'MSC NAISHA III' : '636021061',
  'MSC AZURIT F' : '636021078',
  'MSC SIGMA F' : '636021079',
  'SUSTAINABLE EARTH' : '636021093',
  'HARMONIZED EARTH' : '636021094',
  'INTEGRATIVE EARTH' : '636021095',
  'PROACTIVE EARTH' : '636021096',
  'REGAINING EARTH' : '636021097',
  'RANTANPLAN' : '636021101',
  'UGL HONGKONG' : '636021104',
  'SEABREEZE' : '636021108',
  'MSC RAYSHMI' : '636021122',
  'MSC ALANYA' : '636021123',
  'MSC NASSAU' : '636021128',
  'MSC YANG R' : '636021129',
  'EVER CERTAIN' : '636021136',
  'MSC TARA III' : '636021139',
  'X-PRESS MONTE ROSA' : '636021140',
  'MIZAR' : '636021142',
  'MSC CORDELIA III' : '636021150',
  'MSC MUNDRA VIII' : '636021151',
  'MSC REET II' : '636021153',
  'MSC SOTIRIA III' : '636021154',
  'MSC MUMBAI VIII' : '636021155',
  'MSC SHANGHAI V' : '636021160',
  'MSC HONG KONG V' : '636021161',
  'ALIOTH' : '636021166',
  'JAGUAR' : '636021184',
  'EVER FOND' : '636021190',
  'EVER COMMAND' : '636021200',
  'STONEWELL GLORY' : '636021202',
  'SONGA LIONESS' : '636021229',
  'MELANESIAN CHIEF' : '636021230',
  'LILA CANADA' : '636021231',
  'SONGA LEOPARD' : '636021234',
  'YM TOGETHER' : '636021244',
  'MSC PAMIRA III' : '636021246',
  'MSC MOON F' : '636021247',
  'MSC JENNIFER II' : '636021249',
  'MSC ANDRIANA III' : '636021250',
  'MSC ADONIS' : '636021251',
  'MSC BALTIC III' : '636021252',
  'SURABAYA VOYAGER' : '636021256',
  'SIMBA' : '636021266',
  'MARSA ZENITH' : '636021289',
  'NINA A' : '636021296',
  'MSC GEORGIA II' : '636021302',
  'TIMON' : '636021306',
  'ANITA A' : '636021307',
  'ELI A' : '636021316',
  'SHIJING' : '636021320',
  'UTE' : '636021321',
  'ELBE' : '636021322',
  'MOANA' : '636021323',
  'PUMBA' : '636021329',
  'MAERSK CAMBRIDGE' : '636021337',
  'MAERSK CAMDEN' : '636021338',
  'MAERSK CAMPBELL' : '636021339',
  'MSC GRETA III' : '636021356',
  'TINA I' : '636021360',
  'ZIM PACIFIC' : '636021362',
  'MSC EUGENIA' : '636021363',
  'MSC CASSANDRE' : '636021364',
  'MSC WASHINGTON' : '636021365',
  'MSC VIRGINIA' : '636021366',
  'EVER FAVOR' : '636021387',
  'DYROS' : '636021392',
  'MSC EVEREST VIII' : '636021393',
  'ZIM AUSTRALIA' : '636021396',
  'MSC ALDI III' : '636021400',
  'MSC FIE X' : '636021408',
  'MSC EMILY II' : '636021412',
  'SEABOARD PIONEER' : '636021427',
  'MSC AMIHAN F' : '636021438',
  'SEABOARD PRIDE' : '636021440',
  'MSC LONG BEACH VI' : '636021448',
  'MSC LOS ANGELES' : '636021449',
  'EVER FULL' : '636021462',
  'MSC MARA' : '636021470',
  'MSC VICTORIA' : '636021471',
  'ZIM ATLANTIC' : '636021472',
  'H CYGNUS' : '636021476',
  'STONEFISH' : '636021480',
  'MSC SAGITTARIUS F' : '636021484',
  'MSC SARYA III' : '636021492',
  'ZIM NEW ZEALAND' : '636021506',
  'MSC ANTWERP III' : '636021517',
  'MSC ALDEBARAN III' : '636021529',
  'TEX' : '636021547',
  'YM TRANQUILITY' : '636021578',
  'EVER ORIGIN' : '636021595',
  'MSC COLETTE III' : '636021597',
  'MSC HAILEY ANN III' : '636021614',
  'H MERCURY' : '636021617',
  'ONE CONTRIBUTION' : '636021649',
  'BFAD PACIFIC' : '636021654',
  'MSC BIANCA SILVIA' : '636021663',
  'ZIM ASIA' : '636021664',
  'MSC BERANGERE' : '636021665',
  'MSC DARLENE' : '636021666',
  'MSC C. MONTAINE' : '636021667',
  'EVER OUTDO' : '636021669',
  'YM TUTORIAL' : '636021690',
  'YM THRONE' : '636021693',
  'BIG BREEZY' : '636021736',
  'ZIM CHINA' : '636021739',
  'MSC TAMPA' : '636021760',
  'MSC SINGAPORE IV' : '636021761',
  'MSC FATMA' : '636021768',
  'MSC AAYA' : '636021770',
  'MSC SOFIA' : '636021771',
  'ALGECIRAS EXPRESS' : '636021776',
  'MSC FORTUNE F' : '636021790',
  'WAN HAI 177' : '636021813',
  'WAN HAI 178' : '636021814',
  'CUL JAKARTA' : '636021830',
  'CUL MANILA' : '636021831',
  'KYOTO TOWER' : '636021855',
  'XIAMEN' : '636021857',
  'MSC TOKATA F' : '636021859',
  'MSC ALBA F' : '636021870',
  'MSC FREEPORT' : '636021888',
  'MSC NISHA V' : '636021913',
  'MSC NORDEROOG F' : '636021914',
  'EVER ONWARD' : '636021955',
  'F LANA' : '636021978',
  'MSC NIMISHA III' : '636021991',
  'MSC SYDNEY VI' : '636021992',
  'MSC DARWIN VI' : '636021993',
  'MSC MICHIGAN VII' : '636021994',
  'MSC ILLINOIS VII' : '636021995',
  'KOTA LIMA' : '636022006',
  'SUEZ CANAL' : '636022007',
  'MSC YUVIKA V' : '636022022',
  'YM TRILLION' : '636022027',
  'DEPE' : '636022044',
  'MSC SOMYA III' : '636022046',
  'MSC SUEDEROOG F' : '636022047',
  'MSC TRACY V' : '636022077',
  'MSC AMEERA III' : '636022078',
  'MSC TESSA' : '636022102',
  'MSC RAYA' : '636022103',
  'MSC KALAMATA VII' : '636022111',
  'ZIM AMERICA' : '636022112',
  'ZIM VIETNAM' : '636022113',
  'NAVARINO' : '636022114',
  'MSC ALMA VII' : '636022116',
  'ULSAN' : '636022118',
  'ASTERIOS' : '636022173',
  'ADAMASTOS' : '636022174',
  'AIAS' : '636022175',
  'AMOUREUX' : '636022176',
  'ARIONAS' : '636022177',
  'ADRASTOS' : '636022178',
  'ANDROKLIS' : '636022181',
  'AVIOS' : '636022182',
  'BLOOMING EARTH' : '636022196',
  'MAERSK PHUKET' : '636022214',
  'MAERSK PELEPAS' : '636022215',
  'JPO AQUILA' : '636022261',
  'MSC ORTOLAN II' : '636022277',
  'MSC BENEDETTA XIII' : '636022293',
  'MSC MAKALU III' : '636022307',
  'HISTORY MARIA' : '636022328',
  'MATOYA BAY' : '636022342',
  'MAYA BAY' : '636022344',
  'PALUBAY' : '636022345',
  'SINAR SANUR' : '636022412',
  'MSC DARIA' : '636022441',
  'MSC KAYLEY' : '636022442',
  'MSC CALYPSO' : '636022443',
  'MSC TAYLOR' : '636022447',
  'MSC MONICA CRISTINA' : '636022448',
  'MSC FREYA' : '636022449',
  'MSC AUDREY' : '636022450',
  'MSC MARTINA MARIA' : '636022451',
  'ASL QINGDAO' : '636022489',
  'KAPITAN MASLOV' : '636022495',
  'KAPITAN AFANASYEV' : '636022497',
  'FESCO ASKOLD' : '636022498',
  'FESCO DIOMID' : '636022499',
  'FESCO DALNEGORSK' : '636022500',
  'FESCO SOFIA' : '636022501',
  'MSC CELESTINOMARESCA' : '636022516',
  'EVER OMNI' : '636022523',
  'STARSHIP JUPITER' : '636022572',
  'STARSHIP NEPTUNE' : '636022573',
  'MSC LORETO' : '636022600',
  'MSC IRINA' : '636022601',
  'MSC GEMMA' : '636022603',
  'MSC MICHELCAPPELLINI' : '636022604',
  'MSC NICOLA MASTRO' : '636022605',
  'MSC NOA ARIELA' : '636022606',
  'MSC CARPATHIA III' : '636022611',
  'SINAR SIGLI' : '636022628',
  'MSC GENERAL IV' : '636022632',
  'HUTTON' : '636022635',
  'HAMMONIA BEROLINA' : '636022636',
  'SONGA PANTHER' : '636022640',
  'MSC DOMNA X' : '636022657',
  'MSC YUKTA X' : '636022658',
  'KAPITAN SHCHETININA' : '636022663',
  'MOSKVA' : '636022664',
  'SANKT-PETERBURG' : '636022665',
  'G.CROWN' : '636022687',
  'G.DRAGON' : '636022688',
  'MSC PRELUDE V' : '636022708',
  'SEABOARD BLUE' : '636022765',
  'CHIQUITA VOYAGER' : '636022776',
  'MSC NIOVI VIII' : '636022795',
  'MOL PRESENCE' : '636022799',
  'AKHISAR' : '636022809',
  'MSC LIPSIA III' : '636022812',
  'LECANGS DOLPHIN' : '636022814',
  'SINAR SIANTAR' : '636022815',
  'OVP TAURUS' : '636022865',
  'MSC ADU V' : '636022871',
  'MSC ATHENS' : '636022873',
  'MSC ATHOS' : '636022875',
  'MSC CHIYO' : '636022879',
  'ONE INNOVATION' : '636022889',
  'CHIQUITA EXPLORER' : '636022893',
  'PELION' : '636022903',
  'MSC MARIELLA' : '636022920',
  'MSC CLAUDE GIRARDET' : '636022921',
  'MSC SURABAYA VIII' : '636022941',
  'MSC GIOVANNA VII' : '636022942',
  'ONE INTEGRITY' : '636022963',
  'SEABOARD GLOBE' : '636022973',
  'LILA HAREN' : '636022977',
  'MSC UMA' : '636022980',
  'MSC UNIFIC VI' : '636022984',
  'MSC FLORIANA 6' : '636022985',
  'ONE INFINITY' : '636023002',
  'SINAR SABA' : '636023015',
  'MSC TIA II' : '636023016',
  'BMI EAGLE' : '636023036',
  'TJ ORHAN' : '636023055',
  'ARTHUR MAERSK' : '636023058',
  'GSL EFFIE' : '636023059',
  'GSL ALEXANDRA' : '636023060',
  'GSL SOFIA' : '636023061',
  'ALBERT MAERSK' : '636023062',
  'GSL LYDIA' : '636023063',
  'MSC PILAR VI' : '636023065',
  'ZIM SPARROW' : '636023074',
  'MSC VALENTINA' : '636023083',
  'MSC METTE' : '636023084',
  'MSC CHINA' : '636023087',
  'MSC MICOL' : '636023098',
  'MSC TURKIYE' : '636023099',
  'MSC JIANI' : '636023100',
  'MSC VIVIENNE' : '636023101',
  'XH DOLPHIN' : '636023126',
  'ELONA II' : '636023127',
  'VICTORIA FORTUNE' : '636023142',
  'ELIZABETH FORTUNE' : '636023143',
  'MSC MAGNUM VII' : '636023145',
  'MSC ANCONA III' : '636023157',
  'MSC TURIN II' : '636023159',
  'ZIM EAGLE' : '636023175',
  'MSC PAXI II' : '636023185',
  'MSC LYSE V' : '636023213',
  'LITTLE EMMA' : '636023243',
  'YM WELLSPRING' : '636023250',
  'YM WELLBEING' : '636023251',
  'YM WARRANTY' : '636023252',
  'MSC ANITA' : '636023265',
  'MSC VICTORINE' : '636023266',
  'MSC CANDIDA' : '636023267',
  'MSC GIUSY' : '636023268',
  'MSC REN V' : '636023273',
  'NEW EVERPROSPER' : '636023280',
  'ONE INGENUITY' : '636023290',
  'CMA CGM MERCANTOUR' : '636023293',
  'CMA CGM KRUGER' : '636023303',
  'MSC IRA II' : '636023306',
  'ONE INTELLIGENCE' : '636023310',
  'MSC NAHARA' : '636023312',
  'NAVIOS DOMINO' : '636023346',
  'ONE INSPIRATION' : '636023348',
  'SONGA JACKAL' : '636023371',
  'MSC ORSOLA' : '636023398',
  'KAPITAN ABONOSIMOV' : '636023415',
  'MSC MAGNITUDE VII' : '636023420',
  'MSC NAIROBI X' : '636023421',
  'LOG-IN EVOLUTION' : '636023433',
  'CONTSHIP BOX' : '636023449',
  'DA BAO' : '636023450',
  'FIONA' : '636023461',
  'MSC POLONIA III' : '636023491',
  'WAN HAI 612' : '636023534',
  'WAN HAI 613' : '636023535',
  'MSC THAIS' : '636023562',
  'MSC ROSE' : '636023564',
  'YM WONDERLAND' : '636023581',
  'TB QUANZHOU' : '636023600',
  'HANSA RENDSBURG' : '636090636',
  'NORTHERN DECENCY' : '636090654',
  'HANSA BREITENBURG' : '636090756',
  'ANNIE B' : '636090799',
  'LUTETIA' : '636090856',
  'JPO LIBRA' : '636090862',
  'JPO PISCES' : '636090863',
  'CIMBRIA' : '636090902',
  'MONACO' : '636090967',
  'MONTPELLIER' : '636090971',
  'DAPHNE' : '636091031',
  'LETO' : '636091088',
  'SANTA VANESSA' : '636091116',
  'AS PETRA' : '636091125',
  'AS PALINA' : '636091130',
  'MARTINIQUE' : '636091232',
  'NAJADE' : '636091273',
  'BSG BARBADOS' : '636091308',
  'NORTHERN GUARD' : '636091384',
  'NORTHERN GUILD' : '636091385',
  'HANSA HORNEBURG' : '636091400',
  'HANSA WOLFSBURG' : '636091401',
  'VICTORIA L' : '636091410',
  'NORTHERN DEPENDANT' : '636091448',
  'MATSON WAIKIKI' : '636091473',
  'PONTRESINA' : '636091487',
  'NORTHERN DISCOVERY' : '636091526',
  'NORTHERN DIAMOND' : '636091527',
  'HANSA AUGSBURG' : '636091575',
  'ELBSPIRIT' : '636091619',
  'SEABOARD RANGER' : '636091654',
  'NORTHERN PRACTISE' : '636091717',
  'SEABOARD OCEAN' : '636091731',
  'ESL WASL' : '636091744',
  'JACK LONDON' : '636091765',
  'JONATHAN SWIFT' : '636091766',
  'NORTHERN DIPLOMAT' : '636091783',
  'NORTHERN DEMOCRAT' : '636091784',
  'BUXLINK' : '636091785',
  'HANSA RATZEBURG' : '636091823',
  'NORTHERN POWER' : '636091835',
  'BUXFAVOURITE' : '636091848',
  'MERKUR ARCHIPELAGO' : '636091883',
  'PORTO' : '636091916',
  'MSC SAVONA' : '636091959',
  'JPO GEMINI' : '636091995',
  'OPS HAMBURG' : '636092040',
  'JAN RITSCHER' : '636092101',
  'TIGER' : '636092140',
  'HAMMONIA BALTICA' : '636092181',
  'MSC BARI' : '636092188',
  'MASTERY D' : '636092240',
  'MSC TARANTO' : '636092247',
  'MSC RAVENNA' : '636092268',
  'MSC RAPALLO' : '636092271',
  'MSC MONTEREY' : '636092391',
  'JAKARTA EXPRESS' : '636092424',
  'HANSA FRESENBURG' : '636092477',
  'MOMBASA EXPRESS' : '636092500',
  'HANSA STEINBURG' : '636092570',
  'HANSA ROTENBURG' : '636092572',
  'HANSA SIEGBURG' : '636092574',
  'MAIKE D' : '636092635',
  'TOLTEN' : '636092678',
  'CHACABUCO' : '636092679',
  'TIRUA' : '636092680',
  'PALENA' : '636092681',
  'MEHUIN' : '636092682',
  'ALGOL' : '636092683',
  'DACHAN BAY EXPRESS' : '636092696',
  'ARICA EXPRESS' : '636092697',
  'SEATRADE ORANGE' : '636092708',
  'SEATRADE RED' : '636092711',
  'SEATRADE WHITE' : '636092712',
  'SEATRADE BLUE' : '636092713',
  'MSC RUBY' : '636092764',
  'ALS APOLLO' : '636092765',
  'COPIAPO' : '636092775',
  'BELLAVIA' : '636092778',
  'TEMPANOS' : '636092780',
  'TENO' : '636092781',
  'TORRENTE' : '636092782',
  'TUBUL' : '636092783',
  'TUCAPEL' : '636092784',
  'CAUQUENES' : '636092786',
  'CAUTIN' : '636092792',
  'COCHRANE' : '636092793',
  'CORCOVADO' : '636092798',
  'CISNES' : '636092799',
  'HANSA FREYBURG' : '636092810',
  'COYHAIQUE' : '636092814',
  'AS SVENJA' : '636092824',
  'AS SAMANTA' : '636092825',
  'AS SABRINA' : '636092826',
  'AS SUSANNA' : '636092827',
  'AS FREYA' : '636092837',
  'AS FENJA' : '636092840',
  'MAIPO' : '636092848',
  'TORRES STRAIT' : '636092854',
  'TASMAN STRAIT' : '636092855',
  'AS PAOLA' : '636092860',
  'AS PENELOPE' : '636092863',
  'AS PAULINE' : '636092864',
  'ALS CLIVIA' : '636092866',
  'HANSA EUROPE' : '636092869',
  'RDO FORTUNE' : '636092878',
  'RDO FAVOUR' : '636092879',
  'RAINER D' : '636092880',
  'CONTI ANNAPURNA' : '636092883',
  'CONTI DARWIN' : '636092884',
  'PARIS II' : '636092887',
  'ATACAMA' : '636092895',
  'CCNI ANGOL' : '636092896',
  'CCNI ARAUCO' : '636092897',
  'CCNI ANDES' : '636092898',
  'CMACGM SAN FRANCISCO' : '636092910',
  'RDO ENDEAVOUR' : '636092911',
  'OKEE ALICIA' : '636092914',
  'RDO CONCORD' : '636092919',
  'CONTI MAKALU' : '636092927',
  'CHENNAI EXPRESS' : '636092930',
  'MSC ILONA' : '636092932',
  'MV HANSA FLENSBURG' : '636092949',
  'ZIM PUSAN' : '636092953',
  'HANSA LANKA' : '636092974',
  'MSC ALESSIA' : '636092976',
  'PONA' : '636092984',
  'LUANDA EXPRESS' : '636092986',
  'CHIQUITA CENTURY' : '636092995',
  'HANSA HARBURG' : '636092997',
  'MERKUR HORIZOND' : '636092998',
  'MAERSK NORBERG' : '636093006',
  'MAERSK NACKA' : '636093009',
  'MAERSK NASSJO' : '636093010',
  'THALASSA NIKI' : '636093018',
  'MERKUR HORIZON' : '636093026',
  'BSG BONAIRE' : '636093027',
  'BSG BIMINI' : '636093030',
  'BSG BAHAMAS' : '636093031',
  'CONTI CONQUEST' : '636093041',
  'CONTI COURAGE' : '636093042',
  'BUXWAVE' : '636093051',
  'AS CONSTANTINA' : '636093054',
  'AS SERENA' : '636093055',
  'BF GIANT' : '636093069',
  'MATADI EXPRESS' : '636093081',
  'LE HAVRE EXPRESS' : '636093082',
  'TEMA EXPRESS' : '636093083',
  'OKEE GUSTAV' : '636093085',
  'OKEE HENRI' : '636093086',
  'CONTI CHIVALRY' : '636093093',
  'CONTI CRYSTAL' : '636093094',
  'WARNOW MASTER' : '636093095',
  'SUAPE EXPRESS' : '636093100',
  'DOUALA EXPRESS' : '636093108',
  'LYME BAY' : '636093114',
  'KALAHARI EXPRESS' : '636093117',
  'DALLAS EXPRESS' : '636093121',
  'SEOUL EXPRESS' : '636093131',
  'AMSTERDAM EXPRESS' : '636093132',
  'AL JASRAH' : '636093137',
  'JAZAN' : '636093139',
  'AL MANAMAH' : '636093140',
  'DUBAI EXPRESS' : '636093143',
  'TOKYO EXPRESS' : '636093145',
  'KYOTO EXPRESS' : '636093146',
  'TANGIER EXPRESS' : '636093148',
  'AL QIBLA EXPRESS' : '636093149',
  'HANOVER EXPRESS' : '636093151',
  'NINGBO EXPRESS' : '636093154',
  'DALIAN EXPRESS' : '636093155',
  'BREMEN EXPRESS' : '636093156',
  'ALEXANDER L' : '636093157',
  'RDO ACE' : '636093158',
  'AS CLAUDIA' : '636093159',
  'AL SAFAT' : '636093160',
  'YANTIAN EXPRESS' : '636093161',
  'AL RIFFA' : '636093164',
  'AL DHAIL' : '636093166',
  'COLOMBO EXPRESS' : '636093167',
  'SALAHUDDIN' : '636093169',
  'KIEL EXPRESS' : '636093170',
  'AL MURABBA' : '636093171',
  'ALULA EXPRESS' : '636093172',
  'JEBEL ALI' : '636093174',
  'TAYMA EXPRESS' : '636093175',
  'UNAYZAH EXPRESS' : '636093176',
  'TSINGTAO EXPRESS' : '636093177',
  'OSAKA EXPRESS' : '636093178',
  'AL MASHRAB' : '636093181',
  'AIN SNAN EXPRESS' : '636093182',
  'UMM SALAL' : '636093186',
  'BUDAPEST EXPRESS' : '636093193',
  'VIENNA EXPRESS' : '636093194',
  'UASC ZAMZAM' : '636093197',
  'SAN VICENTE EXPRESS' : '636093198',
  'AS SILJE' : '636093210',
  'AS ANNE' : '636093211',
  'AS SABINE' : '636093212',
  'AS STINE' : '636093213',
  'AS SIMONE' : '636093214',
  'SCION MAFALDA' : '636093217',
  'NAGOYA EXPRESS' : '636093219',
  'BF HAMBURG' : '636093220',
  'UAFL LIBERTY' : '636093224',
  'NORTHERN DEXTERITY' : '636093250',
  'VINCENT 2' : '667001467',
  'VLADIVOSTOK' : '667001869',
  'AMANY QUEEN' : '667002053',
  'AB MOLY' : '667002216',
  'KORMORAN' : '671274100',
  'SERRANO' : '671302100',
  'MARGI' : '671315100',
  'JOE1' : '671319100',
  'REYFA' : '671390100',
  'PACIFIC M' : '671659000',
  'S.J. RIMA' : '677020010',
  'WAN LIMA' : '677020300',
  'MIREMBE JUDITH' : '677026000',
  'J.PIONEER' : '677026400',
  'S J BOSS' : '677026900',
  'FARAHI 2' : '677053700',
  'AL FILK' : '677060800',
  'ARGENTINOII' : '701104000',
  'MERCOSUL SUAPE' : '710000200',
  'SEBASTIAO CABOTO' : '710000596',
  'PEDRO ALVARES CABRAL' : '710000646',
  'FERNAO DE MAGALHAES' : '710000675',
  'BARTOLOMEU DIAS' : '710000872',
  'MERCOSUL SANTOS' : '710001270',
  'ALIANCA LEBLON' : '710003483',
  'MONTE SARMIENTO' : '710003559',
  'LOG IN PANTANAL' : '710003840',
  'LOG-IN POLARIS' : '710004394',
  'LOG-IN ENDURANCE' : '710004636',
  'LOG-IN DISCOVERY' : '710006293',
  'MSC BELMONTE III' : '710006297',

  'LOG-IN JACARANDA' : '710006930',
  'LOG-IN JATOBA' : '710009040',
  'MERCOSUL ITAJAI' : '710027240',
  'CMA CGM SANTOS' : '710033540',
  'CMA CGM VERACRUZ' : '710033550',
  'VICENTE PINZON' : '710074009',
  'AMERICO VESPUCIO' : '710074011',
  'LOG-IN RESILIENTE' : '710098000',
  'SAN ANTONIO EXPRESS' : '725001534',
  'ANTOFAGASTA EXPRESS' : '725001547',
  'ISLA TAUTIL' : '725003474',
  'MAPOCHO' : '725017200',
  'CONDOR' : '725017700',
  'COPIHUE' : '725019910',
  'VENUS .B' : '776025700',

  'BEI JIANG':'312134000',
  'NEW GOLDEN BRIDGE V':'355928000',
  'XIN XIANG XUE LAN':'414472000',
  'BIRYONG':'355319000',
  'YA LU JIANG':'412204480',
  'XIN YU JIN XIANG':'352950000',
  'ORIENTAL PEARL VI':'354344000',
  'ORIENTAL PEARL VIII':'356644000',
  'HUADONG PEARL 8':'374869000',
  'NEW GRAND PEACE':'356828000',
  'PANSTAR DREAM':'441178000',
  'PANSTAR GENIE':'440642000',
  'PANSTAR GENIE NO.2':'441246000',
  'SANSTAR DREAM':'441743000',
  'RIZHAO ORIENT':'355925000',
  'ARAFURA LILY':'538005877',
  'SEONG HEE':'441163000',
  'HAMAYUU':'431446000',
  'NEW CAMELLIA':'431602232',
  'EASTERN DREAM':'372009000',
  'HAE SHIN':'352808000',
  'SANG SHIN':'355297000',
  'YOUNG SHIN':'356005000',
  'GMT ASTRO':'373817000',
  'AH SHIN':'357170000',
  'SOO SHIN':'352001129',
  'WON SHIN':'352002302',
  'CHANG SHIN':'352002303',


};

MMSI.prototype.getShipNm = (_mmsi) => {
  return mmsiList[_mmsi] || "";
};

MMSI.prototype.getMMSI = (_shipNm) => {
  let _mmsi = "";

  $.each(mmsiList, function (key, value) {
    if ($.trim(_shipNm).toUpperCase() == $.trim(value).toUpperCase()) {
      _mmsi = key;
      return false;
    }
  });
  if (_mmsi == "") {
    if (_shipNm) {
      if (shipNameList.hasOwnProperty(_shipNm.trim().toUpperCase())) {
        _mmsi = shipNameList[_shipNm];
      }
    }
  }

  return _mmsi;
};

export default MMSI;
