/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithPhp from "../../../lib/UploadImageToS3WithPhp";

const _mClassName = "EntireTalkMsgEditModal";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let sendFiles = [];
function EntireTalkMsgEditModal(props, ref) {
  const { loginInfo, selectedTalkRoom, requestUserSelect } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [cntPic, setCntPic] = useState("");
  const [sendFile, setSendFile] = useState([]);

  const controller = {
    cntPic: useRef(),
    cntLink: useRef(),
    cntTitle: useRef(),
    cntMSG: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_MANAGE_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_TALK_NEWS_MANAGE_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      setUserData(params);
    },
    removeData(e, params) {
      requestUserDelete(e, params);
    },
  }));

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      controller.cntPic.current.value = userData.currentData.cntPic;
      controller.cntLink.current.value = userData.currentData.cntLink;
      controller.cntTitle.current.value = userData.currentData.cntTitle;
      controller.cntMSG.current.value = userData.currentData.cntMSG;
      sendFiles =
        userData.currentData.cntFile !== ""
          ? userData.currentData.cntFile.split("|")
          : [];
      setCntPic(userData.currentData.cntPic);
    } else {
      controller.cntPic.current.value = "";
      controller.cntLink.current.value = "";
      controller.cntTitle.current.value = "";
      controller.cntMSG.current.value = "";
      sendFiles = [];
      setCntPic("");
    }
  }, [userData]);

  const checkValidation = () => {
    if (controller.cntTitle.current.value.trim() === "") {
      codeList.Modal.current.alert("알림톡 제목을 입력해주세요", () => {
        controller.cntTitle.current.focus();
      });
      return false;
    }
    if (controller.cntMSG.current.value.trim() === "") {
      codeList.Modal.current.alert("알림톡 내용을 입력해주세요", () => {
        controller.cntMSG.current.focus();
      });
      return false;
    }

    return true;
  };

  const sendNewsForSubscribe = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "SendNewsForInvited",
      msgID: msgID,
      data: {
        ...news,
        cnmMainKey: news.cnmMainKey,
      },
      cnmMainKey: news.cnmMainKey,
      cniSites: selectedTalkRoom.cniSites,
    };

    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  const requestChatNewsMainUpdate = (updateData, callback) => {
    if (userData.hasOwnProperty("currentData")) return;

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsMainUpdate.php",
      msgID: msgID,
      data: [],
      ...updateData,
      cnmLastTime: new Date(),
      cnmLastMSG: updateData.cntMSG,
      cnmLastUserSite: loginInfo.comp_cd,
      cnmLastUser: loginInfo.user_id,
      isUser: "N",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback();
        }
      }
    });
  };

  const requestChatNewsTalkUpdate = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkUpdate.php",
      msgID: msgID,
      data: [],
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상처리되었습니다", () => {
            if (!userData.hasOwnProperty("currentData")) {
              requestChatNewsMainUpdate(updateData, () => {
                sendNewsForSubscribe(updateData);
              });
            }

            requestUserSelect();
            closeModal();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  const actionSave = () => {
    if (!checkValidation()) return;
    const updateData = {
      cntMSGKey: util.makeUUIDv4(),
      cntMainKey: selectedTalkRoom.cnmMainKey,
      ...selectedTalkRoom,
      ...userData.currentData,
      cntType: sendFiles.length > 0 ? "2" : "1",
      cntPic: controller.cntPic.current.value,
      cntLink: controller.cntLink.current.value,
      cntTitle: controller.cntTitle.current.value,
      cntMSG: controller.cntMSG.current.value,
      cntFile: sendFiles.length > 0 ? sendFiles.join("|") : "",
      cntCreator: loginInfo.user_id,
      cntCreatorSite: loginInfo.comp_cd,
      cntUnreadCNT: 0,
      cntCreatDate: new Date(),
      cntUpdateDate: new Date(),
      cntDelYN: "N",
    };

    requestChatNewsTalkUpdate(updateData);
  };

  const requestUserDelete = (e, params) => {
    codeList.Modal.current.confirm(
      `[${params.currentData.cntTitle}] 전체메세지를 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          const updateData = {
            ...params.currentData,
            cntDelYN: "Y",
          };
          requestChatNewsTalkUpdate(updateData);
        }
      }
    );
  };

  const uploadMainPic = (response) => {
    if (!response.ret) {
      codeList.Modal.current.alert("오류가 발생했습니다. 다시 시도해주세요");
      return;
    }
    const file = `${response.fileName}?${response.realFileName}`;
    controller.cntPic.current.value = file;
    setCntPic(file);
  };

  const uploadFileCallback = async (response) => {
    if (!response.ret) {
      codeList.Modal.current.alert("오류가 발생했습니다. 다시 시도해주세요");
      return;
    }
    const file = `${response.fileName}?${response.realFileName}`;
    await sendFiles.push(file);
    setIsNeedUpdate((prevState) => !prevState);
  };

  const removeAttachFile = (index) => {
    sendFiles.splice(index, 1);
    setIsNeedUpdate((prevState) => !prevState);
  };

  const closeModal = () => {
    controller.cntPic.current.value = "";
    controller.cntLink.current.value = "";
    controller.cntTitle.current.value = "";
    controller.cntMSG.current.value = "";
    setSendFile([]);
    setCntPic("");
    setUserData({});
    $("#EntireTalkMsgEditModalClose").click();
  };

  return (
    <div
      className="modal fade"
      id="EntireTalkMsgEditModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="EntireTalkMsgEditModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: 700 }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="staticBackdropLabel">
              <strong>알림톡 등록</strong>
            </h3>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="EntireTalkMsgEditModalClose"
              onClick={closeModal}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>

          <div className="modal-body">
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">
                대표이미지
              </label>
              <div className="col-sm-9">
                <input
                  className="d-none"
                  ref={controller.cntPic}
                  readOnly={true}
                />
                <UploadImageToS3WithPhp
                  region={`chatNews/${loginInfo.comp_cd}`}
                  callback={uploadMainPic}
                />
                {cntPic !== "" && (
                  <div
                    className="w-100 min-height-200"
                    style={{
                      background: `url(${
                        cntPic.split("?")[0]
                      }) 50% 50% /contain no-repeat`,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">제목</label>
              <div className="col-sm-9">
                <input
                  className="form-control"
                  ref={controller.cntTitle}
                  placeholder="제목을 입력해주세요"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">내용</label>
              <div className="col-sm-9">
                <textarea
                  className="form-control"
                  ref={controller.cntMSG}
                  rows={5}
                  placeholder="내용을 입력해주세요"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">
                첨부파일
              </label>
              <div className="col-sm-9">
                <div>
                  {sendFiles.length > 0
                    ? sendFiles.map((file, index) => {
                        const splitData = file.split("?");
                        const fileLink = splitData[0];
                        const fileName = splitData[1];
                        return (
                          <div key={fileName + "-" + index} className="mb-2">
                            <a href={fileLink}>{fileName}</a>
                            <button
                              className="btn btn-sm btn-danger font-size-08 px-2 py-1 ml-2"
                              onClick={() => removeAttachFile(index)}
                            >
                              삭제
                            </button>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <UploadImageToS3WithPhp
                  region={`chatNews/${loginInfo.comp_cd}`}
                  multi={true}
                  callback={uploadFileCallback}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">
                연결 URL
              </label>
              <div className="col-sm-9">
                <input
                  ref={controller.cntLink}
                  className="form-control"
                  placeholder="알림톡에서 연결될 URL을 입력해주세요"
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              <i className="fa fa-times mr-2" />
              취소
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => actionSave()}
              disabled={loading}
            >
              <i className="fa fa-check mr-2" />
              저장
              {loading && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(EntireTalkMsgEditModal);
