/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import Utilities from "../../lib/Utilities";
import DashboardMainScheduleTotalSummary from "./component/DashboardMainScheduleTotalSummary";
import DashboardMainScheduleDestSummary from "./component/DashboardMainScheduleDestSummary";

const util = new Utilities();

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};

const dateBtnList = [
  { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
  { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
  { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
  { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
  { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
];

let tempDate = new Date();
tempDate.setDate(new Date().getDate() - new Date().getDay());

export default function DashboardMain(props) {
  const [searchStartDate, setSearchStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  const $controller = {
    searchStartDate: useRef(),
    searchEndDate: useRef(),
    DashboardMainScheduleTotalSummary: useRef(),
    DashboardMainScheduleDestSummary: useRef(),
  };

  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
    $controller.searchStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    $controller.searchEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );
    searchFilterChanged();
  };

  //검색필터 데이터 변경
  const searchFilterChanged = (e) => {
    const filter = {
      searchStartDate: $controller.searchStartDate.current.value,
      searchEndDate: $controller.searchEndDate.current.value,
    };

    $controller.DashboardMainScheduleTotalSummary.current.requestServerData(
      filter
    );
    $controller.DashboardMainScheduleDestSummary.current.requestServerData(
      filter
    );
  };

  useEffect(() => {
    searchFilterChanged();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-muted font-weight-bold"></h5>
                    <div className="row">
                      <h3 className="ml-2">
                        <strong>Dash Board</strong>
                      </h3>

                      <div className="col" />
                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <div className="btn-group" role="group">
                          {dateBtnList.map((btn, index) => {
                            return (
                              <button
                                key={index}
                                className="btn  btn-outline-info"
                                data-range={btn.value}
                                onClick={changeDatePicker}
                              >
                                {btn.desc}
                              </button>
                            );
                          })}
                        </div>

                        <div className="input-group mb-2 mb-md-0 mx-0 mx-md-2">
                          <input
                            className="form-control"
                            type="date"
                            value={searchStartDate}
                            ref={$controller.searchStartDate}
                            onChange={(e) => {
                              setSearchStartDate(e.target.value);
                              if (e.target.value > searchEndDate) {
                                setSearchEndDate(e.target.value);
                              }
                              searchFilterChanged();
                            }}
                          />
                          <span className="input-group-append">
                            <div className="input-group-text">-</div>
                          </span>
                          <input
                            className="form-control border-left-0"
                            type="date"
                            value={searchEndDate}
                            ref={$controller.searchEndDate}
                            onChange={(e) => {
                              setSearchEndDate(e.target.value);
                              if (e.target.value < searchStartDate) {
                                setSearchStartDate(e.target.value);
                              }
                              searchFilterChanged();
                            }}
                          />
                          <button
                            className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                            onClick={() => searchFilterChanged()}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0" id="TableForReport">
                    {/*<ManagerManageTable cellClick={cellClick} />*/}
                    <div className="pb-3">
                      <h5 className="text-secondary text-center px-3 pt-3 pb-0 mt-3 mb-0 font-weight-bold">
                        이용자 사용현황
                      </h5>
                      <DashboardMainScheduleTotalSummary
                        ref={$controller.DashboardMainScheduleTotalSummary}
                      />
                    </div>
                  </div>

                  <div className="card-body p-0" id="TableForReport">
                    {/*<ManagerManageTable cellClick={cellClick} />*/}
                    <div className="pb-3">
                      <h5 className="text-secondary text-center px-3  pb-0 mt-3 mb-0 font-weight-bold">
                        구간별 조회 현황 TOP5
                      </h5>
                      <DashboardMainScheduleDestSummary
                        ref={$controller.DashboardMainScheduleDestSummary}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/*

<div className="row m-0 p-0 border-top pt-3">
                      <div className="col-12">
                        <h5 className="text-secondary text-center px-3 pt-3 pb-0 mb-0 font-weight-bold">
                          FAQ대분류
                        </h5>
                      </div>
                      <div style={{ height: "300px" }} className="col-lg-6">
                        <DashboardMainFAQInboundRouteChart
                          depth={1}
                          ref={$controller.DashboardMainFAQInboundRouteChart}
                        />
                      </div>
                      <div style={{ height: "300px" }} className="col-lg-6">
                        <DashboardMainFAQClickBarChart
                          depth={1}
                          ref={$controller.DashboardMainFAQClickBarChart}
                        />
                      </div>
                    </div>


 */
