import React from "react";

export default function SectionTitle({ title, rightElement = null }) {
  return (
    <div className="d-flex flex-row justify-content-between align-items-end border border-freiscop border-top-0 border-bottom-0 border-right-0 px-2 mb-3">
      <h6 className="m-0 font-weight-bold text-freiscop">{title}</h6>
      {rightElement && rightElement}
    </div>
  );
}
