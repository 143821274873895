import React, { useRef, useState } from "react";
import ShipperInfoManageTable from "./ShipperInfoManageTable";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "ShipperInfoManageTableWrap";

export default function ShipperInfoManageTableWrap() {
  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };
  const searchFilterChanged = () => {
    const filters = {
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <div className="content w-100">
      <div className="card w-100">
        <div className="card-header">
          <div className="btn-toolbar d-flex flex-row justify-content-end">
            <div className="input-group">
              <select
                className="custom-select form-control input-group-append"
                ref={controller.SEARCH_LEVEL}
                onChange={searchFilterChanged}
              >
                <option value="">-검색필터-</option>
                <option value="COMP_CD">업체코드</option>
                <option value="COMP_NM">업체명</option>
                <option value="COMP_CEO_NM">대표자명</option>
                <option value="COMP_NUM">사업자등록번호</option>
              </select>
              <input
                className="form-control"
                placeholder="검색어를 입력해주세요"
                style={{ minWidth: 200 }}
                ref={controller.SEARCH_WORD}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    searchFilterChanged();
                  }
                }}
              />
              <button
                className="input-group-text rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                onClick={searchFilterChanged}
              >
                <i className="fas fa-search mr-2" />
                검색
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <ShipperInfoManageTable />
        </div>
      </div>
    </div>
  );
}
