import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import MMSI from "../schedule/mmsiList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

let oceanlookWebToken =
  "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

export default function ScheduleReserveLinkHotDeal(props) {
  let randLink = util.buddibleDecrypt(useParams()["randLink"]);
  let hb_IDX = randLink.split("|")[0];
  let hb_IDX_MAIN = randLink.split("|")[1];
  const [serverData, setServerData] = useState({});
  const [files, setFiles] = useState([]);

  let vh = 0;
  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    requestHotdealMain();
  }, []);

  const requestHotdealMain = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/hotdeal_booking/JS_hotdeal_booking_select.php",
      msgID: msgID,
      data: {},
      isManager: "1",
      hb_IDX: hb_IDX,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          console.log(newData["returnData"][0]);

          if (newData["returnData"][0].hb_FILE) {
            setFiles(newData["returnData"][0].hb_FILE.split("|"));
          }

          // setServerData(newData["returnData"][0]);

          requestCFSAddr(newData["returnData"][0]);
        }
      }
    });
  };

  const requestCFSAddr = (data, smKey) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/JS_customs_store_main_select.php",
      msgID: msgID,
      data: {},
      smKey: data.hb_CFS,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log(newData["returnData"][0]);

        setServerData({
          ...data,
          smName: data.hb_CFS ? newData["returnData"][0].smName : "",
        });
      }
    });
  };

  //첨부파일 다운로드
  const fileDownload = (url, name) => {
    codeList.Modal.current.confirm(
      "첨부된 파일을 다운로드하시겠습니까?",
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  // CFS 상세정보
  const openCFS = () => {
    let url = `https://www.gbts.co.kr/storeMall/${serverData.hb_CFS}`;
    let options =
      "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
    window.open(url, options, "_blank");
  };

  let mmsiNum = mmsi.getMMSI(serverData.hb_VESSEL);

  return (
    <>
      {/*<div className="modal_background bg-white">*/}
      <div className="h-100">
        <div
          className="modal_body bg-white shadow rounded w-100 h-100 overflow_scroll-Y link-content"
          style={{
            maxWidth: 600,
            maxHeight: 800,
            // width: 500,
            // maxHeight: "100vh",
          }}
        >
          {" "}
          <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
            {/*<p className="h5 mr-3 p-0 m-0">예약하기</p>*/}
            <div className="modal-title">BOOK INFORMATION</div>
          </div>
          <div className="p-2" style={{ minWidth: 320 }}>
            {/*<div className="d-flex flex-column flex-sm-row flex-wrap border rounded overflow-hidden">*/}
            <div className="row m-2">
              <div className="col-sm-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  FORWARDER NAME
                </div>
                <div className="p-2 text-center">{serverData.hd_FWD_NM}</div>
              </div>
              <div className="col-sm-6 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  BOOKING NO, H/BL No
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.hb_BOOK_NO ? serverData.hb_BOOK_NO : "-"}</p>
                  <p>{serverData.hb_USER_NM}</p>
                </div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  SCHEDULE NO
                </div>
                <div className="p-2 text-center">{serverData.hb_IDX_MAIN}</div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  FCL/LCL
                </div>
                <div className="p-2 text-center">{serverData.hb_FCL_LCL}</div>
              </div>
              <div className="col-6 col-sm-3 -3 border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  VESSEL
                </div>
                <div className="p-2 text-center">{serverData.hb_VESSEL}</div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  VOY
                </div>
                <div className="p-2 text-center">{serverData.hb_VOY}</div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  DOC CLOSE
                </div>
                <div className="p-2 text-center">{serverData.hb_DOC_CLOSE}</div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  CARGO CLOSE
                </div>
                <div className="p-2 text-center">
                  {serverData.hb_CARGO_CLOSE}
                </div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  POL
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.hb_POL_NAME}</p>
                  <p>{serverData.hb_DEPARTURE_DATE}</p>
                </div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  POD
                </div>
                <div className="p-2 text-center">
                  <p>{serverData.hb_POD_NAME}</p>
                  <p>{serverData.hb_ARRIVE_DATE}</p>
                </div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  SHIPPER
                </div>
                <div className="p-2 text-center">{serverData.hb_COMP_NM}</div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  PRODUCT
                </div>
                <div className="p-2 text-center">
                  {serverData.hb_PRODUCT_NAME}
                </div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  QTY
                </div>
                <div className="p-2 text-center">{serverData.hb_CNT}</div>
              </div>
              <div className="col-6 col-sm-3  border p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100, fontSize: 8 }}
                >
                  UNIT
                </div>
                <div className="p-2 text-center">
                  {serverData.hb_QUANTITIES}
                </div>
              </div>
            </div>

            <div className="row m-2">
              <div className="col-12 col-sm-3 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center d-flex h-100 justify-content-center align-items-center"
                  style={{ minWidth: 100 }}
                >
                  CFS
                </div>
              </div>
              <div className="col-md-5 border rounded-0 p-0">
                <div
                  className="p-2 font-weight-bold text-center"
                  style={{ minWidth: 100 }}
                >
                  {serverData.hb_CFS &&
                    `${serverData.smName} (${serverData.hb_CFS})`}
                </div>
              </div>
              <div className="col-12 col-sm-1"></div>
              <div className="col-12 col-sm-3 border rounded p-0">
                <div
                  className="p-2 bg-success text-white font-weight-bold text-center cursor_pointer d-flex h-100 justify-content-center align-items-center"
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    if (serverData.hb_CFS) {
                      openCFS();
                    } else {
                      codeList.Modal.current.alert(
                        "CFS 정보가 없습니다.",
                        () => {}
                      );
                    }
                  }}
                >
                  CFS 상세정보
                </div>
              </div>
            </div>

            <div className="p-2 py-3">
              <p className="h6 font-weight-bold mb-2">■ BOOKING 요청 메모</p>
              <pre
                className="p-2 rounded"
                style={{
                  fontSize: "1em",
                  border: "1px solid #ccc",
                  fontFamily: "Roboto Helvetica Arial sans-serif",
                }}
              >
                {serverData.hb_MEMO}
              </pre>
            </div>
            {files && files.length > 0 && (
              <div className="p-2 py-3">
                <p className="h6 font-weight-bold mb-2">■ 첨부파일</p>
                <p className="mb-2">
                  - 파일명을 클릭시 브라우저를 통해 미리 확인이 가능합니다.
                </p>
                <div
                  className="mb-2"
                  style={{
                    maxWidth: "100vw",
                  }}
                >
                  {files.map((item, index) => {
                    let splitFile = item.split("?");
                    let fileName = splitFile[0];
                    let fileLink = splitFile[1];
                    return (
                      <p key={item + "_" + index} className="py-1">
                        <span
                          className="text-primary font-weight-bold cursor_pointer mr-2"
                          style={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          onClick={() => {
                            window.open(fileLink, "_blank");
                          }}
                        >
                          {fileName}
                        </span>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            fileDownload(fileLink, fileName);
                          }}
                        >
                          다운로드
                        </button>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="p-2 py-3">
              <p className="h6 font-weight-bold mb-2">■ 처리상태</p>
              <p style={{ fontSize: 15 }}>
                {
                  {
                    Y: "예약확정",
                    N: "예약요청",
                    B: "B/L 발행",
                  }[serverData.hb_STATUS]
                }
              </p>
            </div>

            {mmsiNum && (
              <div className="p-2 py-3">
                <p className="h6 font-weight-bold mb-2">■ 선박위치정보</p>
                <iframe
                  className="w-100 rounded border"
                  height="400"
                  src={`https://www.oceanlook.net/v3/popup?token=${oceanlookWebToken}&mmsi=${mmsiNum}`}
                ></iframe>
              </div>
            )}
          </div>
          <div className="modal-footer justify-content-center">
            <p className="text-center">
              Copyright GBTS Corp. All Right Reserved
            </p>
          </div>
        </div>
      </div>
      {/*</div>*/}
    </>
  );
}
