/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const _mClassName = "PickPlaceManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function PickPlaceManageEdit(props, ref) {
  const { requestUserSelect, compList } = props;
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState("");

  const $controller = {
    ppIDX: useRef(),
    ppCode: useRef(),
    ppKind: useRef(),
    ppLocation: useRef(),
    ppDescription: useRef(),
    ppRemark: useRef(),
    ppNation: useRef(),
    ppUseYN: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setIsLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PICK_PLACE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setModalData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_PICK_PLACE_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openEditModal(e, params) {
      handleModalData(e, params);
    },
    deleteData(e, params) {
      deleteModalData(e, params);
    },
  }));

  //모달 데이터 세팅
  const handleModalData = (e, params) => {
    setModalData(params);
  };

  useEffect(() => {
    if (modalData.hasOwnProperty("currentData")) {
      $controller.ppIDX.current.value = modalData.currentData.ppIDX;
      $controller.ppCode.current.value = modalData.currentData.ppCode;
      $controller.ppKind.current.value = modalData.currentData.ppKind;
      $controller.ppLocation.current.value = modalData.currentData.ppLocation;
      $controller.ppDescription.current.value =
        modalData.currentData.ppDescription;
      $controller.ppRemark.current.value = modalData.currentData.ppRemark;
      $controller.ppNation.current.value = modalData.currentData.ppNation;
      $controller.ppUseYN.current.value = modalData.currentData.ppUseYN;
    } else {
      $controller.ppIDX.current.value = "";
      $controller.ppCode.current.value = "";
      $controller.ppKind.current.value = "";
      $controller.ppLocation.current.value = "";
      $controller.ppDescription.current.value = "";
      $controller.ppRemark.current.value = "";
      $controller.ppNation.current.value = "";
      $controller.ppUseYN.current.value = "";
    }
  }, [modalData]);
  //유효성검사
  const checkValidation = () => {
    if (
      $controller.ppCode.current &&
      $controller.ppCode.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("코드를 입력해주세요");
      return true;
    }
    if (
      $controller.ppKind.current &&
      $controller.ppKind.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("분류를 선택해주세요");
      return true;
    }
    if (
      $controller.ppLocation.current &&
      $controller.ppLocation.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("장소를 입력해주세요");
      return true;
    }
    if (
      $controller.ppDescription.current &&
      $controller.ppDescription.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상세 내용을 입력해주세요");
      return true;
    }
    if (
      $controller.ppRemark.current &&
      $controller.ppRemark.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("비고사항을 입력해주세요");
      return true;
    }
    if (
      $controller.ppNation.current &&
      $controller.ppNation.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("국가를 선택해주세요");
      return true;
    }
    if (
      $controller.ppUseYN.current &&
      $controller.ppUseYN.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("사용여부를 선택해주세요");
      return true;
    }
    return false;
  };

  //홍보영상 저장
  const actionSave = () => {
    if (checkValidation()) return;

    const updateData = {
      ppIDX: $controller.ppIDX.current.value
        ? Number($controller.ppIDX.current.value)
        : "",
      ppCode: $controller.ppCode.current.value,
      ppKind: $controller.ppKind.current.value,
      ppLocation: $controller.ppLocation.current.value,
      ppDescription: $controller.ppDescription.current.value,
      ppRemark: $controller.ppRemark.current.value,
      ppNation: $controller.ppNation.current.value,
      ppUseYN: $controller.ppUseYN.current.value,
      ppDelYN: "N",
    };
    requestUserUpdate(updateData);
  };

  // 데이터 삭제
  const deleteModalData = (e, params) => {
    let updateData = {
      ...params.currentData,
    };
    codeList.Modal.current.confirm(
      `${updateData.ppCode}의 픽업장소를 삭제 하시겠습니까?`,
      (ret) => {
        if (ret) {
          updateData.ppDelYN = "Y";
          requestUserUpdate(updateData);
        }
      }
    );
  };

  //등록 및 수정 서버 요청
  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: updateData.ppIDX
        ? "/pick_place/JS_pick_place_update.php"
        : "/pick_place/JS_pick_place_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          socket.fireLocalEventListener(
            MsgIDList.EVENT_PICK_PLACE_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );

          requestUserSelect();
          codeList.Modal.current.alert(newData["MSG"], () => {
            closeEditModal();
          });
        } else {
          codeList.Modal.current.alert(
            "요청 처리중에 문제가 발생하였습니다. 잠시후 다시 시도해주세요"
          );
        }
      }
    });
  };

  //값 초기화 및 모달 닫기
  const closeEditModal = () => {
    for (let key in $controller) {
      if ($controller[key].current) {
        $controller[key].current.value = "";
      }
    }
    setModalData({});
    setIsLoading(false);
    $("#modalClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="editModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="editModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>픽업장소 관리</strong>
              </h3>

              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="modalClose"
                onClick={() => {
                  closeEditModal();
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    readOnly={true}
                    ref={$controller.ppIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">코드</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="코드를 입력해주세요"
                    ref={$controller.ppCode}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.ppKind}>
                    <option value="">-미선택-</option>
                    <option value="Terminal">Terminal</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">장소</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="장소를 입력해주세요"
                    ref={$controller.ppLocation}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">설명</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="설명을 입력해주세요"
                    ref={$controller.ppDescription}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">비고</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="비고를 입력해주세요"
                    ref={$controller.ppRemark}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">국가</label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.ppNation}>
                    <option value="">-미선택-</option>
                    <option value="KR">KR-한국</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용여부
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.ppUseYN}>
                    <option value="">-미선택-</option>
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary mr-2"
                onClick={() => closeEditModal()}
              >
                취소
              </button>
              <button className="btn btn-primary" onClick={() => actionSave()}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(PickPlaceManageEdit);
