import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
  useReducer,
} from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import { Link } from "react-router-dom";
import LogoSvg from "../assets/LogoSvg";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function PersonalRegistModal() {
  const [isCertification, setIsCertification] = useState(false);
  const [certification, setCertification] = useState(false);
  const [isEmailChk, setIsEmailChk] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [idChk, setIdChk] = useState(true);
  const [state_AUTH_REQ, setState_AUTH_REQ] = useState(false);
  const [state_AUTH_COMP, setState_AUTH_COMP] = useState(false);
  const [state_EMAIL_COMP, setState_EMAIL_COMP] = useState(false);
  const [check_pwd_length, setCheck_pwd_length] = useState(false);
  const [check_pwd_re, setCheck_pwd_re] = useState(false);

  // USER_ID - 이메일 주소형식
  // USER_TEL - 전화번호
  // USER_NM - 이름
  // USER_NICK - 닉네임
  // USER_TEAM_NM - 부서명(회사명)
  // USER_PART_NM - 담당업무
  // const $controllers = {
  //   USER_ID: useRef(),
  //   USER_PW: useRef(),
  //   USER_PW_CHECK: useRef(),
  //   USER_TEL: useRef(),
  //   USER_NM: useRef(),
  //   USER_NICK: useRef(),
  //   USER_TEAM_NM: useRef(),
  //   USER_PART_NM: useRef(),
  // };

  let $controllers = {
    USER_ID: useRef(),
    USER_PW: useRef(),
    USER_PW_RE: useRef(),
    USER_NM: useRef(),
    USER_TEL: useRef(),
    USER_AUTH_CODE: useRef(),
    USER_NICK: useRef(),
    USER_PART_NM: useRef(),
    USER_TEAM_NM: useRef(),
    check_userID: idChk,
    check_pwd_length: check_pwd_length,
    check_pwd_re: check_pwd_re,
    state_AUTH_REQ: state_AUTH_REQ,
    state_AUTH_COMP: state_AUTH_COMP,
    state_EMAIL_COMP: state_EMAIL_COMP,
  };

  const $termsController = {
    termsOfUse: useRef(),
    mallOperationPolicy: useRef(),
    talkOperationPolicy: useRef(),
    privacyPolicy: useRef(),
    disclaimer: useRef(),
  };

  const CheckEmail = (str) => {
    let reg_email =
      /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    return reg_email.test(str);
  };

  const joinData = (key, data) => {
    let retData = {
      COMP_CD: "Y0001",
      USER_ID: $controllers.USER_ID.current.value,
      USER_PW: $controllers.USER_PW.current.value,
      USER_PW_RE: $controllers.USER_PW_RE.current.value,
      USER_NM: $controllers.USER_NM.current.value,
      USER_TEL: $controllers.USER_TEL.current.value,
      // USER_AUTH_CODE: $controllers.USER_AUTH_CODE.current.value,
      USER_NICK: $controllers.USER_NICK.current.value,
      USER_PART_NM: $controllers.USER_PART_NM.current.value,
      USER_TEAM_NM: $controllers.USER_TEAM_NM.current.value,
      check_userID: $controllers.check_userID,
      check_pwd_length: $controllers.check_pwd_length,
      check_pwd_re: $controllers.check_pwd_re,
      state_AUTH_REQ: $controllers.state_AUTH_REQ,
      state_AUTH_COMP: $controllers.state_AUTH_COMP,
      state_EMAIL_COMP: $controllers.state_EMAIL_COMP,
    };

    if (key === "METHOD") {
      retData.METHOD = data;
    }
    if (key === "USER_AUTH_CODE") {
      retData.USER_AUTH_CODE = data;
    }

    return retData;
  };

  const handleChange = (key, text) => {
    // dispatch({ type: key, value: text });
    $controllers[key] = text;
  };

  const checkEmailFromServer = () => {
    let updateData = joinData("METHOD", "CHECK_EMAIL");
    requestUserUpdate(updateData);
  };

  const checkTelFromServer = () => {
    let updateData = joinData("METHOD", "CHECK_TEL");
    requestUserUpdate(updateData);
  };

  const checkAuthFromServer = () => {
    let updateData = joinData("METHOD", "CHECK_AUTH");

    if (state_AUTH_REQ) {
      let authcode = $controllers.USER_AUTH_CODE.current.value;

      updateData.USER_AUTH_CODE = authcode;

      requestUserUpdate(updateData);
    }
  };
  const actionSave = () => {
    let updateData = joinData("METHOD", "INSERT_CUSTOMER");

    if (state_AUTH_REQ && state_AUTH_COMP) {
      let authcode = $controllers.USER_AUTH_CODE.current.value;

      updateData.USER_AUTH_CODE = authcode;

      // console.log(updateData);
      requestUserUpdate(updateData);
    } else {
      codeList.Modal.current.alert(
        "필수 입력사항을 모두 입력 해주세요.",
        () => {}
      );
      return false;
    }
  };

  const checkValidation = (updateData) => {
    //todo 다국어 작업

    // console.log(updateData);
    let regExp = /[01]([016789])[-](\d{4}|\d{3})[-]\d{4}$/g;
    // console.log(regExp.test(updateData.USER_TEL));

    if (updateData.METHOD === "CHECK_EMAIL") {
      if (util.validationEmail(updateData.USER_ID) === false) {
        codeList.Modal.current.alert("이메일 주소를 입력해 주세요.", () => {
          $controllers.USER_ID.current.focus();
        });
        return false;
      }
    } else if (updateData.METHOD === "CHECK_TEL") {
      // console.log(util.validationMobileNumber(updateData.USER_TEL));
      if (util.validationMobileNumber(updateData.USER_TEL) === false) {
        codeList.Modal.current.alert("핸드폰 번호를 입력해 주세요.", () => {
          $controllers.USER_TEL.current.focus();
        });
        return false;
      }
    } else if (updateData.METHOD === "CHECK_AUTH") {
      if (util.validationMobileNumber(updateData.USER_TEL) === false) {
        codeList.Modal.current.alert("핸드폰 번호를 입력해 주세요.", () => {
          $controllers.USER_TEL.current.focus();
        });
        return false;
      }
      if (updateData.USER_AUTH_CODE.length !== 6) {
        codeList.Modal.current.alert("인증번호 6자리를 입력해 주세요.", () => {
          $controllers.USER_AUTH_CODE.current.focus();
        });
        return false;
      }
    } else if (updateData.METHOD === "INSERT_CUSTOMER") {
      if (updateData.USER_NM.trim() === "") {
        codeList.Modal.current.alert("성명을 입력 해주세요.", () => {
          $controllers.USER_NM.current.focus();
        });
        return false;
      }
      if (updateData.USER_NICK.trim() === "") {
        codeList.Modal.current.alert("닉네임을 입력 해주세요.", () => {
          $controllers.USER_NICK.current.focus();
        });
        return false;
      }

      if (updateData.state_EMAIL_COMP === false) {
        codeList.Modal.current.alert("이메일 중복을 확인 해주세요.", () => {});
        return false;
      }
      if (updateData.check_pwd_length === false) {
        codeList.Modal.current.alert(
          "비밀번호를 6자리 이상 입력해주세요.",
          () => {
            $controllers.USER_PW.current.focus();
          }
        );
        return false;
      }

      if (updateData.check_pwd_re === false) {
        codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {});
        return false;
      }
      if (
        updateData.state_AUTH_REQ === false ||
        updateData.state_AUTH_COMP === false
      ) {
        codeList.Modal.current.alert(
          "핸드폰 문자 인증을 진행 해주세요.",
          () => {}
        );
        return false;
      }

      for (let key in $termsController) {
        if ($termsController[key].current.checked === false) {
          codeList.Modal.current.alert("전체 약관은 필수동의입니다.", () => {});
          return false;
        }
      }
    } else {
      return false;
    }

    /*    joinData.comp_gb = 'Y0001';
    if (joinData.comp_mail === '') {
      alert(t('signUp:signUp'), t('signUp:alertCheck_comp_mail'));
      return false;
    }
    if (joinData.comp_tel === '') {
      alert(t('signUp:signUp'), t('signUp:alertCheck_comp_tel'));
      return false;
    }*/

    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    if (checkValidation(updateData) === false) return;

    let socketMsg = {
      file: "/join/JS_join_gbts_private_user.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    if (socketMsg.USER_PW !== "") {
      socketMsg.USER_PW = util.buddibleEncrypt(socketMsg.USER_PW);
    }
    // console.log(socketMsg);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        switch (updateData.METHOD) {
          case "CHECK_AUTH":
            if (newData["ret"] === true) {
              handleChange("state_AUTH_COMP", true);
              setMin(0);
              $controllers.USER_AUTH_CODE.current.disabled = true;
              setState_AUTH_COMP(true);
              setCertification(true);

              codeList.Modal.current.alert("인증 되었습니다.", () => {});
            } else {
              codeList.Modal.current.alert(
                "유효하지 않은 인증번호 입니다.",
                () => {}
              );
            }

            break;
          case "CHECK_EMAIL":
            if (newData["ret"] === true) {
              handleChange("state_EMAIL_COMP", true);
              $controllers.USER_ID.current.disabled = true;
              setState_EMAIL_COMP(true);
              setIsEmailChk(true);
            } else {
              codeList.Modal.current.alert(
                "이미 가입되어있는 이메일 입니다.",
                () => {}
              );
            }
            break;
          case "CHECK_TEL":
            if (newData["ret"] === true) {
              setIsCertification(true);
              setMin(3);
              handleChange("state_AUTH_REQ", true);
              setState_AUTH_REQ(true);
            } else {
              codeList.Modal.current.alert(
                "이미 가입되어있는 전화번호 입니다. 아이디찾기 또는 비밀번호찾기를 진행해주세요.",
                () => {}
              );
            }
            break;
          case "INSERT_CUSTOMER":
            if (newData["ret"] === true) {
              codeList.Modal.current.alert("가입이 완료 되었습니다.", () => {
                window.location.replace("/");
              });
            } else {
              codeList.Modal.current.alert(
                "죄송합니다. 가입중 오류가 발생 하였습니다.",
                () => {}
              );
            }
            break;
        }

        if (updateData.METHOD === "CHECK_EMAIL") {
        }
        if (newData["ret"] === true) {
        }
      }
    });
  };

  // 타이머
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(sec) > 0) {
        setSec(parseInt(sec) - 1);
      }
      if (parseInt(sec) === 0) {
        if (parseInt(min) === 0) {
          clearInterval(countdown);
        } else {
          setMin(parseInt(min) - 1);
          setSec(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [min, sec]);

  const checkPhone = () => {
    checkTelFromServer();
  };
  return (
    <div
      className="modal fade"
      id="personalRegistModal"
      tabIndex="-1"
      aria-labelledby="personalRegistModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header" style={{ borderBottom: "none" }}>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="personalRegistModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="text-lg-center">
                <div id="logo" style={{ marginTop: "-30px" }}>
                  <LogoSvg height="60px" />
                </div>
                <div className="logo  mt-4">
                  <h6 className="m-0 mb-1 text-secondary font-weight-bold">
                    Freiscop 회원가입하시면 GBTS(로지톡, 로지웍, 로지킵)를
                    무료이용할 수 있습니다.
                  </h6>
                </div>
                {/*아이디 패스워드 입력*/}
                <div className="py-3 d-block">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      이메일 <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9 d-flex flex-row">
                      <input
                        type="email"
                        className="form-control w-auto flex-fill mr-3"
                        placeholder="입력하신 이메일을 아이디로 사용합니다."
                        ref={$controllers.USER_ID}
                        // value={$controllers.USER_ID}
                        onChange={(e) => {
                          // handleChange("USER_ID", e.target.value);

                          setIdChk(CheckEmail(e.target.value));
                        }}
                      />

                      {!isEmailChk ? (
                        <button
                          className="btn_2"
                          onClick={() => {
                            checkEmailFromServer();
                            // console.log(updateData);
                          }}
                          style={{ width: 115 }}
                        >
                          중복확인
                        </button>
                      ) : (
                        <button
                          className="btn_1"
                          onClick={() => {
                            $controllers.USER_ID.current.disabled = false;
                            setIsEmailChk(false);
                          }}
                          style={{ width: 115 }}
                        >
                          이메일변경
                        </button>
                      )}
                    </div>
                  </div>
                  {!idChk ? (
                    <div className="mb-3 row" style={{ marginTop: -10 }}>
                      <label className="col-form-label col-sm-3 text-end"></label>
                      <div className="col-sm-9" style={{ textAlign: "left" }}>
                        <span style={{ color: "#f64a4a" }}>
                          올바른 이메일 주소를 입력해주세요
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      비밀번호{" "}
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        // value={$controllers.USER_PW}
                        ref={$controllers.USER_PW}
                        onChange={(e) => {
                          if (e.target.value.length > 6) {
                            setCheck_pwd_length(true);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      비밀번호 확인{" "}
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        // value={$controllers.USER_PW_RE}
                        ref={$controllers.USER_PW_RE}
                        onChange={(e) => {
                          if (
                            e.target.value ===
                            $controllers.USER_PW.current.value
                          ) {
                            setCheck_pwd_re(true);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      이름 <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // value={$controllers.USER_NM}
                        ref={$controllers.USER_NM}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      닉네임 <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // value={$controllers.USER_NICK}
                        ref={$controllers.USER_NICK}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      핸드폰번호{" "}
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9 d-flex flex-row">
                      <input
                        type="tel"
                        className="form-control w-auto flex-fill mr-3"
                        // value={$controllers.USER_TEL}
                        ref={$controllers.USER_TEL}
                        placeholder={
                          "하이픈(-) 을 제외하고 숫자만 입력해주세요."
                        }
                      />
                      <button
                        className="btn_2"
                        onClick={() => {
                          certification
                            ? codeList.Modal.current.alert(
                                "이미 인증을 하였습니다.",
                                () => {}
                              )
                            : checkPhone();
                        }}
                        style={{ width: 130 }}
                      >
                        인증번호 발송
                      </button>
                    </div>
                  </div>

                  {isCertification ? (
                    <>
                      <div className="mb-1 row">
                        <label className="col-form-label col-sm-3 text-end">
                          인증번호{" "}
                          <i style={{ color: "red", fontWeight: 700 }}>*</i>
                        </label>
                        <div className="col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            ref={$controllers.USER_AUTH_CODE}
                          />
                        </div>
                        <button
                          className="btn_2"
                          onClick={() => {
                            certification
                              ? codeList.Modal.current.alert(
                                  "이미 인증을 하였습니다.",
                                  () => {}
                                )
                              : checkAuthFromServer();
                          }}
                          style={{ width: 115, background: "#777777" }}
                        >
                          확인
                        </button>
                      </div>
                      <div className="mb-1 row">
                        <div
                          className="col-sm-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {min !== 0 ? (
                            <>
                              {min}:{sec < 10 ? `0${sec}` : sec}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      회사명{" "}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // value={$controllers.USER_TEAM_NM}
                        ref={$controllers.USER_TEAM_NM}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      담당업무{" "}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // value={$controllers.USER_PART_NM}
                        ref={$controllers.USER_PART_NM}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      약관동의
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2 font-weight-bold">
                        <label className="container_check m-0 p-0 mr-3">
                          <input
                            type="checkbox"
                            className="position-static"
                            onChange={(e) => {
                              let val = e.target.checked;
                              for (let key in $termsController) {
                                if ($termsController[key].current) {
                                  $termsController[key].current.checked = val;
                                }
                              }
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        전체동의
                      </div>
                      <div className="d-flex flex-row flex-wrap">
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.termsOfUse}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/termsOfUse"
                            target="_blank"
                            className="align-middle"
                          >
                            GBTS 이용약관 동의
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.mallOperationPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/mallOperationPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            프레이스콥 운영정책 확인
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.talkOperationPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/talkOperationPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            로지톡 운영정책 확인
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.privacyPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/privacyPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            개인정보 처리방침 동의
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.disclaimer}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/disclaimer"
                            target="_blank"
                            className="align-middle"
                          >
                            면책공고 확인
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1"
              onClick={() => {
                actionSave();
              }}
            >
              가입신청
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
