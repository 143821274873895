/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo,
} from "react";
import Select from "react-dropdown-select";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { BCompSelectOption } from "../../../lib/CommonUI";

const _mClassName = "SchedulePromotionManageEdit";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const display_type = [
  { key: "0", desc: "프레이스콥" },
  { key: "1", desc: "프레이스콥 플러스" },
];

let isLoading = true;
let airport_list = [];
let port_list = [];
let SHIP_NAME = "";
let sp_POL_NAME = "";
let sp_POD_NAME = "";

export default forwardRef(SchedulePromotionManageEdit);
function SchedulePromotionManageEdit(props, ref) {
  const { data = {}, requestUserSelect, loginInfo, ship_comp } = props;
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [transferType, setTransferType] = useState("LCL");
  const SHIP_SELECT = useRef();
  const POL_SELECT = useRef();
  const POD_SELECT = useRef();

  const controller = {
    sp_IDX: useRef(),
    sp_COMP_CD: useRef(),
    sp_LCL_FCL: useRef(),
    sp_DATE_START: useRef(),
    sp_DATE_END: useRef(),
    sp_POL_CODE: useRef(),
    sp_POD_CODE: useRef(),
    sp_MEMO: useRef(),
    sp_USE_YN: useRef(),
    sp_DISPLAY: useRef(),
  };

  useEffect(() => {
    if (airport_list.length === 0) {
      if (codeList.codeAirCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
          _mClassName,
          (oldV, newV) => {
            airport_list = newV;
          }
        );
      } else {
        airport_list = codeList.codeAirCountryFullName;
      }
    }
    if (port_list.length === 0) {
      if (codeList.codeCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
          _mClassName,
          (oldV, newV) => {
            port_list = newV;
          }
        );
      } else {
        port_list = codeList.codeCountryFullName;
      }
    }

    if (airport_list.length > 0 && port_list.length > 0) {
      isLoading = false;
      setIsNeedUpdate(!isNeedUpdate);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
    return () => {
      sp_POL_NAME = "";
      sp_POD_NAME = "";
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      handleDataMapped(e, params);
    },
    removeData(e, params) {
      requestUserDelete(e, params);
    },
  }));

  const handleDataMapped = (e, data) => {
    if (data.hasOwnProperty("currentData")) {
      controller.sp_IDX.current.value = data.currentData.sp_IDX;
      controller.sp_COMP_CD.current.value = data.currentData.sp_COMP_CD;
      controller.sp_LCL_FCL.current.value = data.currentData.sp_LCL_FCL;
      controller.sp_DATE_START.current.value = data.currentData.sp_DATE_START;
      controller.sp_DATE_END.current.value = data.currentData.sp_DATE_END;
      controller.sp_POL_CODE.current.value = data.currentData.sp_POL_CODE;
      controller.sp_POD_CODE.current.value = data.currentData.sp_POD_CODE;
      controller.sp_MEMO.current.value = data.currentData.sp_MEMO;
      controller.sp_USE_YN.current.value = data.currentData.sp_USE_YN;
      controller.sp_DISPLAY.current.value = data.currentData.sp_DISPLAY;
      SHIP_NAME = data.currentData.sp_COMP_CD;
      sp_POL_NAME = data.currentData.sp_POL_NAME;
      sp_POD_NAME = data.currentData.sp_POD_NAME;
      setTransferType(data.currentData.sp_LCL_FCL);
    } else {
      controller.sp_IDX.current.value = "";
      controller.sp_COMP_CD.current.value = "";
      controller.sp_LCL_FCL.current.value = "LCL";
      controller.sp_DATE_START.current.value = "";
      controller.sp_DATE_END.current.value = "";
      controller.sp_POL_CODE.current.value = "";
      controller.sp_POD_CODE.current.value = "";
      controller.sp_MEMO.current.value = "";
      controller.sp_USE_YN.current.value = "Y";
      controller.sp_DISPLAY.current.value = "0";
      SHIP_NAME = "";
      sp_POL_NAME = "";
      sp_POD_NAME = "";
      setTransferType("");
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  const handleChangedTransferType = (value) => {
    sp_POL_NAME = "";
    sp_POD_NAME = "";
    POL_SELECT.current.clearAll();
    POD_SELECT.current.clearAll();
    controller.sp_POL_CODE.current.value = "";
    controller.sp_POD_CODE.current.value = "";
    setTransferType(value);
  };

  const requestUserDelete = (e, params) => {
    const updateData = {
      sp_IDX: params.currentData.sp_IDX,
      sp_DEL_YN: "Y",
    };
    requestUserUpdate(updateData, EDIT_TYPE.DELETE);
  };

  const checkValidation = () => {
    if (controller.sp_COMP_CD.current.value.trim() === "") {
      codeList.Modal.current.alert("선사코드를 선택해주세요", () => {});
      return false;
    }
    if (controller.sp_LCL_FCL.current.value.trim() === "") {
      codeList.Modal.current.alert("운송분류를 선택해주세요", () => {});
      return false;
    }
    if (
      controller.sp_DATE_START.current.value.trim() === "" ||
      controller.sp_DATE_END.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("프로모션 기간을 입력해주세요", () => {});
      return false;
    }
    if (controller.sp_POL_CODE.current.value.trim() === "") {
      codeList.Modal.current.alert("출발지를 선택해주세요", () => {});
      return false;
    }
    if (controller.sp_POD_CODE.current.value.trim() === "") {
      codeList.Modal.current.alert("도착지를 선택해주세요", () => {});
      return false;
    }
    // if (controller.sp_MEMO.current.value.trim() === "") {
    //   codeList.Modal.current.alert("프로모션 내용을 입력해주세요", () => {});
    //   return false;
    // }
    return true;
  };
  const actionSave = () => {
    if (!checkValidation()) return;
    const updateData = {
      sp_IDX: controller.sp_IDX.current.value,
      sp_COMP_CD: controller.sp_COMP_CD.current.value,
      sp_LCL_FCL: controller.sp_LCL_FCL.current.value,
      sp_DATE_START: controller.sp_DATE_START.current.value,
      sp_DATE_END: controller.sp_DATE_END.current.value,
      sp_POL_CODE: controller.sp_POL_CODE.current.value,
      sp_POD_CODE: controller.sp_POD_CODE.current.value,
      sp_MEMO: controller.sp_MEMO.current.value,
      sp_USE_YN: controller.sp_USE_YN.current.value,
      sp_DISPLAY: controller.sp_DISPLAY.current.value,
      sp_DEL_YN: "N",
      sp_TEST_YN: "N",
    };

    requestUserUpdate(
      updateData,
      controller.sp_IDX.current.value ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
    );
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      file: "",
      data: {},
      ...updateData,
    };
    let msg = "스케쥴 프로모션 정보가 정상 업데이트되었습니다.";

    if (TYPE === EDIT_TYPE.INSERT) {
      socketMsg.file = "/schedule_promotion/JS_schedule_promotion_insert.php";
    }
    if (TYPE === EDIT_TYPE.UPDATE) {
      socketMsg.file = "/schedule_promotion/JS_schedule_promotion_update.php";
    }
    if (TYPE === EDIT_TYPE.DELETE) {
      socketMsg.file = "/schedule_promotion/JS_schedule_promotion_delete.php";
      msg = "스케쥴 프로모션이 정상삭제되었습니다.";
    }
    setLoading(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          setLoading(false);
          codeList.Modal.current.alert(msg, () => {
            closeModal();
          });
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "요청처리중에 오류가 발생하였습니다 다시 시도해주세요",
            () => {}
          );
        }
      }
    });
  };

  const closeModal = () => {
    for (let key in controller) {
      if (controller[key].current) {
        controller[key].current.value = "";
      }
    }
    SHIP_SELECT.current.clearAll();
    controller.sp_LCL_FCL.current.value = "LCL";
    $("#SchedulePromotionManageEditClose").click();
  };

  const CustomSelectItemRenderer = ({ item, methods }) => {
    let kind = "해상";
    if (transferType === "AIR") {
      kind = "항공";
    }

    return (
      <p
        role="option"
        aria-selected="false"
        tabIndex="-1"
        className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
        onClick={() => methods.addItem(item)}
        color="#0074D9"
        style={{
          fontSize: "11px",
          padding: "2px 5px",
          cursor: "pointer",
          borderBottom: "1px solid #fff",
          color: "gray",
          fontStyle: "italic",
          whiteSpace: "nowrap",
        }}
      >
        <b
          style={{
            fontWeight: "normal",
            color: "black",
            fontStyle: "normal",
            fontSize: "14px",
          }}
        >
          ({kind}) {item.CustomCode ? "[" + item.CustomCode + "] " : ""}
        </b>

        <b
          style={{
            fontWeight: 600,
            color: "black",
            fontStyle: "normal",
            fontSize: "14px",
          }}
        >
          {`${item.CityName} (${item.CityName_EN}) `}
        </b>

        {item.CountryName + " " + item.ContinentName}
      </p>
    );
  };

  return (
    <>
      {!isLoading && (
        <div
          className="modal fade"
          id="SchedulePromotionManageEdit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="SchedulePromotionManageEdit"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: 700 }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel">
                  <strong>스케쥴 프로모션 등록</strong>
                </h3>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="SchedulePromotionManageEditClose"
                  onClick={closeModal}
                >
                  <i className="fas fa-plus fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    관리 번호
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="account"
                      disabled={true}
                      className="form-control"
                      placeholder="ID"
                      ref={controller.sp_IDX}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    선사코드
                  </label>
                  <div className="col-sm-9">
                    <input className="d-none" ref={controller.sp_COMP_CD} />

                    <Select
                      ref={SHIP_SELECT}
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      disabled={loginInfo.comp_cd !== "Z0001"}
                      name={"blCompNM_KO"}
                      sortBy={"blCompNM_KO"}
                      searchBy={"blCompNM_KO"}
                      valueField={"blFWDNO"}
                      labelField={"blCompNM_KO"}
                      options={ship_comp}
                      placeholder={
                        SHIP_NAME ? SHIP_NAME : "선사정보를 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      onChange={(data) => {
                        let value = data.length === 0 ? "" : data[0].blFWDNO;
                        controller.sp_COMP_CD.current.value = value;
                      }}
                      itemRenderer={({
                        item,
                        itemIndex,
                        props,
                        state,
                        methods,
                      }) => {
                        let compType = "";
                        if (item.blCompType === "B") compType = "콘솔사";
                        else if (item.blCompType === "F") compType = "선사";
                        else if (item.blCompType === "A") compType = "항공사";

                        return (
                          <div key={itemIndex}>
                            <div
                              role="option"
                              aria-selected="false"
                              tabIndex="-1"
                              className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
                              onClick={() => {
                                methods.addItem(item);
                              }}
                            >
                              {item.blLOGO && (
                                <div
                                  className="d-inline-block mr-2"
                                  style={{
                                    width: "1rem",
                                    height: "1rem",
                                    background: `url(${item.blLOGO}) 50% 50% / contain no-repeat`,
                                  }}
                                />
                              )}
                              <p className="d-inline-block">
                                <b>
                                  [{compType}]{item.blCompNM_KO}
                                </b>{" "}
                                <span className="font-size-08">
                                  [{item.blCompNM}]
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    운송분류
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      ref={controller.sp_LCL_FCL}
                      onChange={(e) => {
                        handleChangedTransferType(e.target.value);
                      }}
                    >
                      <option value="LCL">LCL</option>
                      <option value="FCL">FCL</option>
                      <option value="AIR">AIR</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    프로모션 기간
                  </label>
                  <div className="col-sm-9">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        ref={controller.sp_DATE_START}
                      />
                      <span className="input-group-append border-right-0">
                        <div className="input-group-text border-right-0">-</div>
                      </span>
                      <input
                        type="date"
                        className="form-control"
                        ref={controller.sp_DATE_END}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    출발지(출발항)
                  </label>
                  <div className="col-sm-9">
                    <input className="d-none" ref={controller.sp_POL_CODE} />

                    <Select
                      ref={POL_SELECT}
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={
                        transferType === "AIR" ? airport_list : port_list
                      }
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sp_POL_NAME
                          ? sp_POL_NAME
                          : "출발항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          controller.sp_POL_CODE.current.value = "";
                          return;
                        }
                        controller.sp_POL_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={CustomSelectItemRenderer}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    도착지(도착항)
                  </label>
                  <div className="col-sm-9">
                    <input className="d-none" ref={controller.sp_POD_CODE} />
                    <Select
                      ref={POD_SELECT}
                      required
                      className="form-control"
                      clearable={true}
                      multi={false}
                      options={
                        transferType === "AIR" ? airport_list : port_list
                      }
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"City"}
                      placeholder={
                        sp_POD_NAME
                          ? sp_POD_NAME
                          : "도착항(공항)을 선택해주세요"
                      }
                      style={{ flexGrow: 1 }}
                      labelField={"NewFullName"}
                      onChange={(data) => {
                        if (data.length === 0) {
                          controller.sp_POD_CODE.current.value = "";
                          return;
                        }
                        controller.sp_POD_CODE.current.value =
                          data[0].CustomCode;
                      }}
                      itemRenderer={CustomSelectItemRenderer}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    내용
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      rows={5}
                      className="form-control"
                      ref={controller.sp_MEMO}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    노출위치
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      ref={controller.sp_DISPLAY}
                    >
                      <option value="0">프레이스콥</option>
                      <option value="1">프레이스콥 플러스</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    사용여부
                  </label>
                  <div className="col-sm-9">
                    <select className="form-control" ref={controller.sp_USE_YN}>
                      <option value="Y">사용</option>
                      <option value="N">중지</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  <i className="fa fa-times mr-2" />
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => actionSave()}
                  disabled={loading}
                >
                  <i className="fa fa-check mr-2" />
                  저장
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
