import React from "react";
import { useTranslation } from "react-i18next";
import Utilities from "../../../../lib/Utilities";

const util = new Utilities();

function ImpItem(props) {
  const { langMode, item, openImpModal, copyInfo } = props;
  const { t } = useTranslation();

  const isWide = langMode === "wide";
  const titleClass =
    langMode === "wide"
      ? "col-6 m-0 p-0 pr-2 font-weight-bold"
      : "col-3 m-0 p-0 pr-2 font-weight-bold";

  return (
    <div className="mb-2 bg-white rounded">
      <div className="p-2 border-bottom border-width-1">
        <p>
          <span className="font-weight-bold">No.</span> {item.ROWINDEX}
        </p>
      </div>
      <div className="p-2">
        <div className="row m-0 p-1">
          <p className={titleClass}>B/L No.</p>
          <p className="col m-0 p-0 pl-2">{item.BLNO}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("imp_supFirm")}</p>
          <p className="col m-0 p-0 pl-2">{item.SUP_FIRM}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("imp_impFirm")}</p>
          <p className="col m-0 p-0 pl-2">{item.IMP_FIRM}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("reportNo")}</p>
          <p className="col m-0 p-0 pl-2">{item.RPT_NO}</p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("imp_modalAmount")}</p>
          <p className="col m-0 p-0 pl-2">
            {item.Con_Cur} {item.CON_AMT ? util.addCommas(item.CON_AMT) : "-"}
          </p>
        </div>
        <div className="row m-0 p-1">
          <p className={titleClass}>{t("imp_modalProductNm")}</p>
          <p className="col m-0 p-0 pl-2">{item.Std_GName1}</p>
        </div>
        <div className="row m-0 p-0">
          <div className={isWide ? "col-12 m-0 p-0" : "col-6 m-0 p-0"}>
            <div className="row m-0 p-1">
              <p className="col-6 m-0 p-0 pr-2 font-weight-bold">
                {t("imp_modalPkgCnt")}
              </p>
              <p className="m-0 p-0 pl-2">
                {item.CI_ITEM_QTY ? util.addCommas(item.CI_ITEM_QTY) : "-"}
              </p>
            </div>
            <div className="row m-0 p-1">
              <p className="col-6 m-0 p-0 pr-2 font-weight-bold">
                {t("imp_modalWeight")}(KG)
              </p>
              <p className="m-0 p-0 pl-2">
                {item.CI_ITEM_WT ? util.addCommas(item.CI_ITEM_WT) : "-"}
              </p>
            </div>
          </div>
          <div className={isWide ? "col-12 m-0 p-0" : "col-6 m-0 p-0"}>
            <div className="row m-0 p-1">
              <p className="col-6 m-0 p-0 pr-2 font-weight-bold">
                {t("customsClearanceStatus")}
              </p>
              <p className="col m-0 p-0 pl-2">{item.RECE || "-"}</p>
            </div>
            <div className="row m-0 p-1">
              <p className="col-6 m-0 p-0 pr-2 font-weight-bold">
                {t("testTarget")}
              </p>
              <p className="col m-0 p-0 pl-2">{item.Rece_Result || "-"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-1">
        <div className="col-3 m-0 p-1">
          <button
            className="w-100 border-0 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            data-toggle="modal"
            data-target="#ImpModal"
            onClick={(e) => openImpModal(e, "info", item)}
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-circle-info" />
            </span>
            <span>{t("info")}</span>
          </button>
        </div>
        <div className="col-3 m-0 p-1">
          <button
            className="w-100 border-0 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            data-toggle="modal"
            data-target="#ImpModal"
            onClick={(e) => openImpModal(e, "dispatch", item)}
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-truck" />
            </span>
            <span>{t("dispatch")}</span>
          </button>
        </div>
        <div className="col-3 m-0 p-1">
          <button
            className="w-100 border-0 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            data-toggle="modal"
            data-target="#ImpModal"
            onClick={(e) => openImpModal(e, "file", item)}
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-paperclip" />
            </span>
            <span>{t("file")}</span>
          </button>
        </div>
        <div className="col-3 m-0 p-1">
          <button
            className="w-100 border-0 rounded bg-light text-dark d-flex flex-column justify-content-center align-items-center p-3"
            onClick={() => copyInfo("imp", item)}
          >
            <span className="h6 mb-1">
              <i className="fa-solid fa-share-nodes" />
            </span>
            <span>{t("share")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
export default React.memo(ImpItem);
