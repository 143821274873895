/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "MallBannerManageEdit";
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function MallBannerManageEdit(props, ref) {
  const { data = {}, requestUserSelect } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");

  const $controller = {
    mbIDX: useRef(), // 관리번호
    mbTITLE: useRef(), //스케줄 타이틀
    mbCONTENTS: useRef(), //스케줄 내용
    mbURL: useRef(),
    mbLogo: useRef(), //스케줄 첨부파일
    mbUSEYN: useRef(), // 스케줄 사용여부
    mbOrder: useRef(),
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  //스케줄 입력 폼 기본값 세팅(신규등록, 수정)
  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $controller.mbIDX.current.value = userData.currentData.mbIDX;
      $controller.mbIDX.current.disabled = true;
      $controller.mbTITLE.current.value = userData.currentData.mbTITLE;
      $controller.mbCONTENTS.current.value = userData.currentData.mbCONTENTS;
      $controller.mbLogo.current.value = userData.currentData.mbLogo;
      $controller.mbUSEYN.current.value = userData.currentData.mbUSEYN;
      $controller.mbOrder.current.value = userData.currentData.mbOrder;
      $controller.mbURL.current.value = userData.currentData.mbURL;
      setUploadFiles(userData.currentData.mbLogo);
    } else {
      $controller.mbIDX.current.value = "";
      $controller.mbIDX.current.disabled = true;
      $controller.mbTITLE.current.value = "";
      $controller.mbCONTENTS.current.value = "";
      $controller.mbLogo.current.value = "";
      $controller.mbUSEYN.current.value = "";
      $controller.mbOrder.current.value = 0;
      $controller.mbURL.current.value = "";
      setUploadFiles("");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_MALL_BANNER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_MALL_BANNER_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      //console.log(props);
      dataChange(e, params);
    },
    removeNotice(e, params) {
      DeleteNotice(e, params);
    },
  }));

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];
      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      $controller.mbLogo.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      $controller.mbLogo.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  const DeleteNotice = (e, params) => {
    let updateData = {
      ...params.currentData,
      mbDelYN: "Y",
    };
    codeList.Modal.current.confirm("해당 배너를 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.DELETE);
      }
    });
  };

  //스케줄 저장
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        mbIDX: $controller.mbIDX.current.value,
        mbTITLE: $controller.mbTITLE.current.value,
        mbCONTENTS: $controller.mbCONTENTS.current.value,
        mbURL: $controller.mbURL.current.value,
        mbLogo: $controller.mbLogo.current.value,
        mbUSEYN: $controller.mbUSEYN.current.value,
        mbOrder: $controller.mbOrder.current.value,
        mbDelYN: "N",
      };

      requestUserUpdate(
        updateData,
        $controller.mbIDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controller.mbTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("업체명을 입력해주세요", () => {});
      return false;
    }
    if ($controller.mbCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "배너에 들어갈 설명을 입력해주세요",
        () => {}
      );
      return false;
    }
    if ($controller.mbURL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "배너를 클릭시 이동할 URL 주소를 입력해주세요",
        () => {}
      );
      return false;
    }
    if ($controller.mbLogo.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "업체의 로고 이미지를 첨부해주세요",
        () => {}
      );
      return false;
    }

    if ($controller.mbUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.INSERT
          ? "/mallBanner/JS_mall_banner_insert.php"
          : "/mallBanner/JS_mall_baner_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    if (TYPE === EDIT_TYPE.INSERT) {
      socketMsg.file = "/mallBanner/JS_mall_banner_insert.php";
    }
    if (TYPE === EDIT_TYPE.UPDATE) {
      socketMsg.file = "/mallBanner/JS_mall_banner_update.php";
    }
    if (TYPE === EDIT_TYPE.DELETE) {
      socketMsg.file = "/mallBanner/JS_mall_banner_update.php";
    }

    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            MsgIDList.EVENT_MALL_BANNER_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();

          if (TYPE === EDIT_TYPE.INSERT) {
            codeList.Modal.current.alert("배너를 추가 하였습니다.", () => {});
          }
          if (TYPE === EDIT_TYPE.UPDATE) {
            codeList.Modal.current.alert(
              "배너를 업데이트 하였습니다.",
              () => {}
            );
          }
          if (TYPE === EDIT_TYPE.DELETE) {
            codeList.Modal.current.alert(
              "배너가 정상적으로 삭제되었습니다",
              () => {}
            );
          }

          Cancel();
        } else {
          codeList.Modal.current.alert(
            "배너 수정작업중 오류가 발생하였습니다 관리자에게 문의바랍니다",
            () => {}
          );
          setLoading(false);
        }
      }
    });
  };

  // 취소
  const Cancel = () => {
    $controller.mbIDX.current.value = "";
    $controller.mbIDX.current.disabled = true;
    $controller.mbTITLE.current.value = "";
    $controller.mbCONTENTS.current.value = "";
    $controller.mbURL.current.value = "";
    $controller.mbLogo.current.value = "";
    $controller.mbUSEYN.current.value = "";
    $controller.mbOrder.current.value = 0;
    setUploadFiles("");
    $("#MallBannerManageEditModalClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="MallBannerManageEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="MallBannerManageEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>콘솔사/선사 배너 이미지 등록</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="MallBannerManageEditModalClose"
                onChange={() => Cancel()}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$controller.mbIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체명
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="1"
                    ref={$controller.mbTITLE}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">설명</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="2"
                    maxLength={50}
                    placeholder="최대 공백포함 50자까지 등록가능합니다."
                    ref={$controller.mbCONTENTS}
                  />
                </div>
              </div>{" "}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  연결 URL
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    ref={$controller.mbURL}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  파일첨부
                </label>

                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"mall/banner"}
                    callback={UploadFiles}
                  />
                  <div style={{ marginTop: -10 }} className="pb-2">
                    <p
                      style={{
                        fontSize: "small",
                        color: "#8f8f8f",
                      }}
                    >
                      ※ 이미지는 350X100 사이즈를 권장합니다.
                    </p>
                  </div>
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={$controller.mbLogo}
                  />
                  {uploadFiles !== "" &&
                    uploadFiles.split("|").map((v, i) => {
                      let splitData = v.split("?");
                      if (splitData.length === 1) return null;
                      let fileName = splitData[0];
                      let fileLink = splitData[1];
                      return (
                        <div className="my-2" key={v}>
                          {/* <button
                                onClick={() => {\
                                  downloadAs(fileLink, fileName);
                                }}
                              >
                                {fileName}
                              </button>*/}
                          <button
                            className="btn btn-danger mr-2"
                            onClick={() => {
                              deleteFile(fileLink, fileName);
                            }}
                          >
                            삭제
                          </button>
                          {fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                          {!fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  노출순서
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    defaultValue={0}
                    ref={$controller.mbOrder}
                  />
                </div>
              </div>{" "}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controller.mbUSEYN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  id="btnCloseModal"
                  onClick={(e) => {
                    codeList.Modal.current.confirm(
                      `배너 등록을 취소하시겠습니까?`,
                      (ret) => {
                        if (ret) {
                          Cancel();
                        }
                      }
                    );
                  }}
                >
                  <i className="fa fa-times mr-2" />
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => actionSave(e)}
                  disabled={loading}
                >
                  <i className="fa fa-check mr-2" />
                  저장
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(MallBannerManageEdit);
