import React, { useEffect, useRef, useState } from "react";
import ADManageTable from "./ADManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();

const COMP_KIND = [
  { key: "AD_COMP_NM", desc: "업체명" },
  { key: "AD_COMP_HOMEPAGE", desc: "홈페이지주소" },
];
const _mClassName = "ADManage";

export default function ADManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $searchText = useRef();
  const $searchLevel = useRef();
  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_AD_MANAGE_EDIT_MODAL_CALL,
      ADManage,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_AD_MANAGE_FILTER_CHANGED,
      ADManage,
      filters,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                  <div
                    className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                    role="toolbar"
                  >
                    <div
                      className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                      role="group"
                    >
                      <button
                        className="btn  btn-outline-primary "
                        data-toggle="modal"
                        data-target="#ADEditModal"
                        onClick={addNewData}
                        alt={"신규등록"}
                        aria-label={"신규등록"}
                        type="button"
                      >
                        <i className="fas fa-plus" /> 신규등록
                      </button>
                    </div>

                    <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                      <select
                        className="custom-select form-control"
                        ref={$searchLevel}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">분류</option>
                        {COMP_KIND.map((menu, index) => {
                          return (
                            <option key={index} value={menu.key}>
                              {menu.desc}
                            </option>
                          );
                        })}
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={(ref) => ($searchText.current = ref)}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </h6>
              </div>
              <ADManageTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
