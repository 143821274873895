/* eslint-disable no-unused-vars */
import React from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
let store_default_image =
  "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/cutomsStore/20220314164100_premiumiconwarehouse5537005.png";

export default function StoreList(props) {
  let { wordFilter, loading, storeList, isStoreListView, SELECTED_STORE } =
    props;

  return (
    <div className={`flex-fill overflow_scroll p-2`}>
      {loading && (
        <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
          <i className="icon-spin5 animate-spin h4 text-secondary" />
        </div>
      )}

      {/*store.smKey.indexOf(wordFilter) !== -1 ||
      store.smName.indexOf(wordFilter) !== -1 ||
      wordFilter === ""*/}
      {storeList.length > 0 &&
        !loading &&
        isStoreListView &&
        storeList.map((store, index) => {
          let storeImages = store.sdPhoto;
          let storeImageLink = null;
          if (storeImages) {
            let splitData = storeImages.split("|")[0].split("?");
            storeImageLink = splitData[0];
          }

          storeImageLink = storeImageLink
            ? storeImageLink
            : store_default_image;

          //별점 변수
          let rating = Math.round(store.srRating * 10) / 10;

          return (
            <div
              key={index}
              className="p-2 d-flex flex-row justify-content-between align-items-center cursor_pointer"
              style={{ borderBottom: "1px solid #ddd" }}
              onClick={() => {
                SELECTED_STORE(store);
              }}
            >
              <div className="mr-2 overflow-hidden" style={{ width: 280 }}>
                {store.sdKey !== null ? (
                  <span className="badge badge-info mb-1">입점 </span>
                ) : (
                  ""
                )}

                <h6 className="font-weight-bold m-0 p-0 text-truncate">
                  {store.smName}
                </h6>
                <p className="p-0 m-0 text-truncate">{store.smCustoms}</p>
                <p className="p-0 m-0 text-truncate">
                  {util.telFormatConvert(store.smTel)}
                </p>
                {!rating ? (
                  <p className="text-right">평가정보 없음</p>
                ) : (
                  <div className="text-right">
                    {rating >= 1 ? (
                      <i className="icon-star text-warning" />
                    ) : (
                      <i className="icon-star-empty text-warning" />
                    )}
                    {rating >= 2 ? (
                      <i className="icon-star text-warning" />
                    ) : (
                      <i className="icon-star-empty text-warning" />
                    )}
                    {rating >= 3 ? (
                      <i className="icon-star text-warning" />
                    ) : (
                      <i className="icon-star-empty text-warning" />
                    )}
                    {rating >= 4 ? (
                      <i className="icon-star text-warning" />
                    ) : (
                      <i className="icon-star-empty text-warning" />
                    )}
                    {rating >= 5 ? (
                      <i className="icon-star text-warning" />
                    ) : (
                      <i className="icon-star-empty text-warning" />
                    )}{" "}
                    | {rating * 2} / 10 ({store.srCount} 건)
                  </div>
                )}
              </div>
              <div
                className="bg-light border-bottom sbMapbox rounded overflow-hidden text-whited d-flex flex-row justify-content-center align-items-center"
                style={{
                  height: 80,
                  width: 80,
                  background: `url(${storeImageLink}) 50% 50%/contain no-repeat`,
                }}
              />
            </div>
          );
        })}
      {storeList.length === 0 &&
        !loading &&
        isStoreListView &&
        "현재 위치에 창고가 존재하지 않습니다."}
    </div>
  );
}
