import React, { useEffect, useState } from "react";

function CommonHeader() {
  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light bg-navbar topbar static-top bg-freiscop m-0"
        style={{ opacity: 0.4 }}
      />
    </>
  );
}

export default CommonHeader;
