import React, { useEffect, useState } from "react";

import IMPAlarmTalkCompTable from "./IMPAlarmTalkCompTable";
import IMPAlarmTalkRegisteredCompTable from "./IMPAlarmTalkRegisteredCompTable";

let _selectCompList = [];

export default function IMPAlarmTalkManage(props) {
  const { notiSet, saveUserNotiSet } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [selectedCompList, setSelectedCompList] = useState([]);

  useEffect(() => {
    _selectCompList = notiSet["IMP"];
    setSelectedCompList(notiSet["IMP"]);
  }, []);
  const selectComp = (data) => {
    const index = _selectCompList.findIndex(
      (comp) => comp.COMP_CD === data.COMP_CD
    );
    if (index > -1) {
      _selectCompList.splice(index, 1);
    } else {
      const presetData = notiSet["IMP"].find(
        (val) => val.COMP_CD === data.COMP_CD
      );
      _selectCompList = [
        ..._selectCompList,
        {
          ...data,
          WORK: presetData ? presetData["WORK"] : { IMP: 0, CUSTOMS: 0 },
          TRANS: presetData
            ? presetData["TRANS"]
            : {
                ARRIVAL: 0,
                DECLARATION: 0,
                INSPECT: 0,
                COMPLETED: 0,
                CARGO: 0,
                DELIVERY: 0,
              },
          ORDER: presetData
            ? presetData["ORDER"]
            : { CUSTOMS: 0, TRANS: 0, INSUR: 0 },
        },
      ];
    }
    setIsNeedUpdate(!isNeedUpdate);
  };
  const addComp = () => {
    setSelectedCompList([..._selectCompList]);
  };

  const setCompAlarm = (e, data) => {
    let dataList = [...selectedCompList];
    const index = dataList.findIndex((comp) => comp.COMP_CD === data.COMP_CD);
    const [key1, key2] = e.target.name.split("-");
    dataList[index][key1][key2] = e.target.checked ? 1 : 0;
    _selectCompList = [...dataList];
    setSelectedCompList([...dataList]);
  };

  const removeComp = (data = []) => {
    let _dataList = [];
    for (let i = 0; i < selectedCompList.length; i++) {
      const index = data.findIndex(
        (comp) => comp.COMP_CD === selectedCompList[i].COMP_CD
      );
      if (index < 0) {
        _dataList.push(selectedCompList[i]);
      }
    }
    _selectCompList = [..._dataList];
    setSelectedCompList([..._dataList]);
  };

  const handleNotiData = () => {
    let dataList = [];
    selectedCompList.forEach((data) => {
      dataList.push({
        COMPCD: data["COMP_CD"],
        WORK: data["WORK"],
        TRANS: data["TRANS"],
        ORDER: data["ORDER"],
        COMP_CD: data["COMP_CD"],
        COMP_NM: data["COMP_NM"],
        COMP_NUM: data["COMP_NUM"],
        COMP_C_NUM: data["COMP_C_NUM"],
      });
    });
    saveUserNotiSet("IMP", dataList);
  };
  return (
    <div className="h-100 bg-white d-flex flex-column">
      <div className="border-bottom p-2">
        <div className="d-flex flex-row justify-content-end">
          <div>
            <button
              className="logiwork-btn-color active"
              onClick={() => handleNotiData()}
            >
              저장하기
            </button>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-6 p-2">
          <IMPAlarmTalkCompTable
            notiSet={notiSet}
            _selectCompList={_selectCompList}
            selectComp={selectComp}
            addComp={addComp}
          />
        </div>
        <div className="col-6 p-2">
          <IMPAlarmTalkRegisteredCompTable
            selectedCompList={selectedCompList}
            setCompAlarm={setCompAlarm}
            removeComp={removeComp}
          />
        </div>
      </div>
    </div>
  );
}
