import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

let filters = {};

export default function SearchShipper(props) {
  const { addFavUser, openUserInfoModal } = props;
  const [serverData, setServerData] = useState([]);
  const [statisticData, setStatisticData] = useState({
    COMP_CONT: 0,
    USER_CNT: 0,
    MSG_CNT: 0,
  });
  const controller = {
    COMP_CD: useRef(),
    COMP_NUM: useRef(),
  };
  const { t } = useTranslation();

  useEffect(() => {
    requestStatistic();
    return () => {
      filters = {};
    };
  }, []);

  const requestStatistic = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/app/JS_statistic_gbts_user.php",
      msgID: msgID,
      data: [],
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData.hasOwnProperty("returnData")) {
            if (newData.returnData.length > 0) {
              setStatisticData(newData.returnData[0]);
            }
          }
        }
      }
    });
  };

  const requestShipperData = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_owner_user_list.php",
      msgID: msgID,
      data: [],
      sort: [
        {
          field: "USER_ID",
          dir: "asc",
        },
      ],
      ...filters,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
        }
      }
    });
  };

  const actionSearch = () => {
    filters = {
      COMP_CD: controller.COMP_CD.current.value,
      COMP_NUM: controller.COMP_NUM.current.value,
    };
    requestShipperData();
  };

  const UserItemRender = useCallback((props) => {
    return <UserItem {...props} />;
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-2 border-bottom">
        <div className="d-flex flex-row bg-light rounded overflow-hidden w-100">
          <input
            className="border-bottom-0 border-top-0 border-left-0 border-right border-width-2 border-white bg-transparent p-2 width-100"
            placeholder={t("compCode")}
            ref={controller.COMP_CD}
          />
          <input
            className="w-100 flex-grow-1 border-bottom-0 border-top-0 border-left-0 border-right border-width-2 border-white bg-transparent p-2 "
            placeholder={t("compBizNum")}
            ref={controller.COMP_NUM}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                actionSearch();
              }
            }}
          />
          <button
            className="py-2 px-3 bg-gbts border-0 text-white"
            onClick={() => actionSearch()}
          >
            <i className="fa-solid fa-magnifying-glass mt-1" />
          </button>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="h-100 d-flex flex-column">
          <div className="flex-grow-1 overflow_scroll-Y p-2">
            {serverData.length > 0 && (
              <div className="mb-2 px-2 py-3 bg-light d-flex flex-row justify-content-between align-items-center">
                <h6 className="font-weight-bold mb-0">
                  {serverData[0].COMP_NM} [{serverData.length}]
                </h6>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    filters = {};
                    controller.COMP_CD.current.value = "";
                    controller.COMP_NUM.current.value = "";
                    setServerData([]);
                  }}
                >
                  {t("resetSearch")}
                </button>
              </div>
            )}
            {serverData.map((user) => (
              <UserItemRender
                key={`${user.COMP_CD}_${user.USER_ID}`}
                t={t}
                user={user}
                openUserInfoModal={openUserInfoModal}
                addFavUser={addFavUser}
              />
            ))}
          </div>
          <div className="text-white bg-gbts p-3 text-center">
            <p className="h6 font-weight-bold">
              {t("shipperMembers")} ({t("previousDay")})
            </p>
            <div className="row m-0 p-0">
              <div className="col-4 m-0 p-2">
                <p className="font-weight-bold">{t("numOfCop")}</p>
                <p>{statisticData.COMP_CONT}</p>
              </div>
              <div className="col-4 m-0 p-2">
                <p className="font-weight-bold">{t("numOfUsers")}</p>
                <p>{statisticData.USER_CNT}</p>
              </div>
              <div className="col-4 m-0 p-2">
                <p className="font-weight-bold">{t("dailyMsg")}</p>
                <p>{statisticData.MSG_CNT}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const UserItem = React.memo((props) => {
  const { t, user, openUserInfoModal, addFavUser } = props;

  let userTeam = `${user.USER_TEAM_NM ? "[" + user.USER_TEAM_NM + "] " : ""}${
    user.USER_PART_NM
  }`;

  return (
    <div className="p-2 mb-2 bg-light rounded d-flex flex-row justify-content-between align-items-center">
      <div
        className="d-flex flex-row justify-content-start align-items-center mr-2"
        data-toggle="modal"
        data-target="#UserInfoModal"
        onClick={() => {
          openUserInfoModal(user);
        }}
      >
        <div
          className="border rounded-circle mr-3 position-relative"
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            background: `url(${
              user.USER_PIC ||
              "https://www.gbts.co.kr/images/user_pic-50x50.png"
            }) 50% 50% / cover no-repeat`,
          }}
        >
          <div
            className={`position-absolute border ${
              user["login_state"] ? "bg-info" : "bg-secondary"
            } rounded-circle`}
            style={{
              top: 0,
              left: 0,
              width: 15,
              height: 15,
            }}
          />
        </div>
        <div className="text-secondary">
          {(user.USER_TEAM_NM || user.USER_PART_NM) && (
            <p className="w-100 ellipsis_1">{userTeam}</p>
          )}
          <p className="h6 mb-0 font-weight-bold">
            {user.USER_NICK || user.USER_NM}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center text-gbts">
        <span
          className="h5 mb-0 mr-2 cursor_pointer"
          onClick={() => addFavUser(user)}
        >
          <i className="fa-solid fa-user-plus" />
        </span>
      </div>
    </div>
  );
});
