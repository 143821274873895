import React, { useEffect, useState } from "react";

import CommonNav from "../main/CommonNav";
import LogiTalkLandingCarousel from "./compnent/LogiTalkLandingCarousel";
import LogiTalkLandingProductInfo from "./compnent/LogiTalkLandingProductInfo";
import LogiTalkLandingInquiry from "./compnent/LogiTalkLandingInquiry";
import LogiTalkLandingAdvertiseVideoCenter from "./compnent/LogiTalkLandingAdvertiseVideoCenter";
import Footer from "../main/Footer";

export default function LogiTalkLanding() {
  return (
    <>
      <CommonNav />
      <main id="logitalk_landing" style={{ marginTop: 60 }}>
        <LogiTalkLandingCarousel />
        <LogiTalkLandingProductInfo />
        <LogiTalkLandingInquiry />
        <LogiTalkLandingAdvertiseVideoCenter />
      </main>
      <Footer />
    </>
  );
}
