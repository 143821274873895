/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SearchLogisticsTableParent from "./component/SearchLogisticsTableParent";
import SearchLogisticsDetail from "./component/SearchLogisticsDetail";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "SearchLogistics";

export default function SearchLogistics({ params }) {
  const { isMobile, loginInfo } = params;
  const [selectedComp, setSelectedComp] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setSelectedComp(false);
    }
  }, [isMobile]);

  useEffect(() => {
    let selectComp = socket.getLocalDataSet(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE
    );

    if (selectComp) {
      setSelectedComp(true);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE,
      _mClassName,
      (b, n) => {
        if (n) {
          setSelectedComp(true);
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE,
      _mClassName,
      (b, n) => {
        if (n) {
          if (!n) return;
          setSelectedComp(true);
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE,
        _mClassName
      );
    };
  }, []);

  return (
    <div
      className="white_bg d-flex flex-column border border-bottom-0 w-100 h-100 overflow_scroll"
      id="searchLogistics"
    >
      <div className="border-bottom bg-light p-3 mb-0">
        <h4 className="text-info font-weight-bold pt-2">물류기업 검색</h4>
        <p>
          업체부호 또는 업체명 클릭시 해당 기업의 상세정보를 확인 할 수
          있습니다.
        </p>
      </div>

      <div
        className="flex-fill d-flex flex-row justify-content-between"
        style={{ height: "calc( 100vh - 100px)" }}
      >
        <div className="h-100 overflow_scroll-Y flex-fill p-3">
          <SearchLogisticsTableParent isMobile={isMobile} />
        </div>
        {selectedComp && (
          <div
            className={`h-100 bg-white overflow_scroll-Y border-left py-3 ${
              isMobile ? "position-fixed" : ""
            }`}
            id="comp_detail_wrap"
            style={{ top: 0, left: 0 }}
          >
            <div className="w-100 px-3">
              <SearchLogisticsDetail
                isMobile={isMobile}
                loginInfo={loginInfo}
                setSelectedComp={setSelectedComp}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
