import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import PriceFclCustomGradeManageTable from "./component/PriceFclCustomGradeManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "PriceFclCustomGradeManage";
export default function PriceFclCustomGradeManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const fileRef = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);
  const actionSearch = () => {
    let filters = {};

    socket.fireLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_FCL_PRICE_CUSTOM_GRADE_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const exportExcel = () => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//www.gbts.co.kr/backside/priceFclCustomGrade/JS_price_fcl_custom_grade_export_excel.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {},
          })
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([response.data], {
                  type: response.headers["content-type"],
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `업체 등급별 FCL 운임관리_${util.todayDate("YYYYMMDD")}.xls`
              );
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              codeList.Modal.current.alert(
                "오류가 발생하였습니다. 다시 시도해주세요"
              );
            });
        }
      }
    );
  };
  const importExcel = (e) => {
    let file = e.target.files[0],
      name = file.name,
      size = file.size,
      type = file.type;

    if (validation(name, size, type)) {
      requestExcelUpload(e.target.files, function (ret, msg) {
        let type = "error";
        if (ret === "Y") {
          codeList.Modal.current.alert(msg, () => {
            fileRef.current.value = "";
            actionSearch();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요.",
            () => {
              console.log(msg);
            }
          );
        }
      });
    }
  };
  //엑셀 대용량 업로드 통신
  const requestExcelUpload = (fileData, callback) => {
    const formData = new FormData();
    formData.append("files", fileData[0]);

    axios
      .post(
        "//www.gbts.co.kr/backside/priceFclCustomGrade/JS_price_fcl_custom_grade_import_excel.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.RESULT === "Y") {
          callback(response.data.RESULT, response.data.MSG);
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };
  // 엑셀파일 업로드 유효성 검사
  const validation = (name, size, type) => {
    let reg = /\.(xls|xlsx)$/i;

    if (!reg.test(name)) {
      codeList.Modal.current.alert(
        "지원하지 않는 형식 입니다.\nExcel 파일만 가능합니다.",
        () => {}
      );
      return false;
    }
    if (size > 1000000 * 10) {
      codeList.Modal.current.alert("용량은 10MB가 이하로 해주세요.", () => {});
      return false;
    }
    return true;
  };
  const htmlID = `customFile_${util.makeUUIDv4Short()}`;

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                <button
                  className="ml-2 btn btn-sm btn-secondary"
                  onClick={exportExcel}
                >
                  엑셀 다운로드
                </button>

                <input
                  type="file"
                  className="d-none"
                  id={htmlID}
                  ref={fileRef}
                  onChange={(e) => {
                    importExcel(e);
                  }}
                />
                <label
                  className="m-0 ml-2 btn btn-sm btn-primary"
                  htmlFor={htmlID}
                >
                  엑셀 업로드
                </label>
              </div>
            </div>
            <PriceFclCustomGradeManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
