import React from "react";

export default function ScheduleFCLBookingInfoLink() {
  return (
    <div className="d-flex flex-row justify-content-center">
      <div
        className="border border-dark m-2 bg-white"
        style={{ minWidth: 320, maxWidth: 600 }}
      >
        <div className="px-3 pt-3 pb-1">
          <div className="d-flex flex-row border-bottom border-dark">
            <div>console logo</div>
            <h5 className="font-weight-bold">FCL E-BOOKING NOTE</h5>
          </div>
          <div className="py-2">
            {/*Company Information*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Company Information</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Shipping Company
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Freight Forwarder
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Shipper
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
            </div>

            {/*Freight Forwarder Contact*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Freight Forwarder Contact</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Name
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Tel
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Mobile
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    E-mail
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
            </div>

            {/*E-Booking Schedule*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· E-Booking Schedule</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    POL
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    POD
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    FCL/LCL
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    VESSEL
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    VOY
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div className="p-2 bg-secondary text-white border-bottom font-weight-bold">
                    T/S Port
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div className="p-2 bg-secondary text-white border-bottom font-weight-bold">
                    2<sup>nd</sup> Vessel
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>

              <div className="mt-2 bg-light" style={{ height: 200 }}>
                선박위치
              </div>
            </div>

            {/*Work Schedule*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Work Schedule</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    DOC Close
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    EDI Close
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    CFS Close/ARF Close
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Container Close
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Departure Terminal
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Arrival Terminal
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <p>
                ※ The above schedule may be changed without previous notice.
              </p>
            </div>

            {/*Container Information*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Container Information</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Gross Weight
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Type
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Size
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Q'ty
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    S.O.C
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    D.G.
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Empyt
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Flexi-Bag
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Additional Requests
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <p>※ S.O.C : Shipper's Own Container / D.G Dangerous Goods </p>
            </div>

            {/*Container Attachment*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Container Attachment</h6>
              {/*#4472C4*/}
              <div className="border border-dark p-2">
                <p>
                  <span
                    className="font-weight-bold"
                    style={{ color: "#4472C4" }}
                  >
                    로지몰_서비스.pdf
                  </span>{" "}
                  <button
                    className="btn btn-sm btn-primary py-0 px-2 ml-2 rounded-0"
                    style={{ background: "#4472C4", borderColor: "#4472C4" }}
                  >
                    Download
                  </button>
                </p>
              </div>
            </div>

            {/*Reefer Container Additional Information*/}
            {"true" && (
              <div className="mb-3">
                <h6 className="font-weight-bold">
                  · Reefer Container Additional Information
                </h6>
                <div className="d-flex flex-row">
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Size
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Type
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Q'ty
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Temperature(℃)
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Ventilator Open
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                </div>
              </div>
            )}

            {/*OOG Container Additional Information*/}
            {"true" && (
              <div className="mb-3">
                <h6 className="font-weight-bold">
                  · OOG Container Additional Information
                </h6>
                <div className="d-flex flex-row">
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Size
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Type
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Q'ty
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Length(mm)
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Width(mm)
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Height(mm)
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center border-right flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Void(ted)
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                  <div className="text-center flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      G.Weight
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="text-center flex-grow-1">
                    <div
                      className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                      style={{ height: 55 }}
                    >
                      Additional Requests
                    </div>
                    <div className="p-2 bg-light">Namsung Shipping</div>
                  </div>
                </div>
              </div>
            )}

            {/*Inland Delivery Information*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">
                · Inland Delivery Information
              </h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Method
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Freight
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Term
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Door Date
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Door Place
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    OP Name
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Mobile
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Additional Requests
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
            </div>

            {/*Inland Delivery Information CFS*/}
            <div className="d-flex flex-row mt-3">
              <div className="text-center border-right flex-grow-1">
                <div
                  className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                  style={{ height: 55 }}
                >
                  Receiving Date
                </div>
                <div className="p-2 bg-light">Namsung Shipping</div>
              </div>
              <div className="text-center border-right flex-grow-1">
                <div
                  className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                  style={{ height: 55 }}
                >
                  CFS
                </div>
                <div className="p-2 bg-light">Namsung Shipping</div>
              </div>
              <div className="text-center flex-grow-1">
                <div
                  className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                  style={{ height: 55 }}
                >
                  Additional Requests
                </div>
                <div className="p-2 bg-light">Namsung Shipping</div>
              </div>
            </div>

            {/*Other Information*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Other Information</h6>
              <div className="d-flex flex-row">
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Item Description
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center border-right flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    <p>Return Cargo</p>
                    <p style={{ fontSize: "0.9em" }}>(Bonded Transportation)</p>
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
                <div className="text-center flex-grow-1">
                  <div
                    className="p-2 bg-freiscop text-white d-flex flex-column justify-content-center border-bottom font-weight-bold"
                    style={{ height: 55 }}
                  >
                    Additional Requests
                  </div>
                  <div className="p-2 bg-light">Namsung Shipping</div>
                </div>
              </div>
            </div>

            {/*Other Attachment*/}
            <div className="mb-3">
              <h6 className="font-weight-bold">· Other Attachment</h6>
              <div className="border border-dark p-2">
                <p>
                  <span
                    className="font-weight-bold"
                    style={{ color: "#4472C4" }}
                  >
                    로지몰_서비스.pdf
                  </span>{" "}
                  <button
                    className="btn btn-sm btn-primary py-0 px-2 ml-2 rounded-0"
                    style={{ background: "#4472C4", borderColor: "#4472C4" }}
                  >
                    Download
                  </button>
                </p>
              </div>
            </div>

            {/*Progress monitoring*/}
            <div className="mb-3">
              <div className="d-flex flex-row justify-content-start align-items-center">
                <h6 className="font-weight-bold mb-0 mr-2">
                  · Progress monitoring
                </h6>
                <button className="mr-2 btn btn-sm btn-primary rounded-0 bg-freiscop border-freiscop">
                  Ongoing
                </button>
                <button className="mr-2 btn btn-sm border-danger rounded-0">
                  Cancel
                </button>
              </div>

              <div className="d-flex flex-row justify-content-around mt-2">
                <span>Booking Request</span>
                <span>
                  <i className="fa-solid fa-chevron-right" />
                </span>
                <span>Booking Request</span>
                <span>
                  <i className="fa-solid fa-chevron-right" />
                </span>
                <span>Booking Request</span>
                <span>
                  <i className="fa-solid fa-chevron-right" />
                </span>
                <span>Booking Request</span>
              </div>
            </div>

            <div
              className="p-2 d-flex flex-row justify-content-between align-items-center"
              style={{ background: "#d1ecf1" }}
            >
              <div>Freiscop</div>
              <div>
                <h5 className="m-0">Sea/Air Logistics E-Booking Platform</h5>
                <p>Get the vessel real time location service for free</p>
              </div>
              <button className="btn btn-sm btn-light border-info borders-3">
                Go ▶
              </button>
            </div>
          </div>
        </div>
        <div className="bg-secondary text-white font-weight-bold text-center">
          Copyright GBTS Corp. All Rights Reserved
        </div>
      </div>
    </div>
  );
}
