/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import StoreManageTable from "./StoreManageTable";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const _mClassName = "StoreManage";

export default function StoreManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $searchWord = useRef();
  const $searchLevel = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const searchFilterChanged = (e) => {
    const filters = {
      keyword: $searchWord.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const EVENT_ERP_CUSTOMS_STORE_CREATED = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ERP_CUSTOMS_STORE_CREATED,
      _mClassName,
      {
        smKey: "",
        smName: "",
        smCustoms: "",
        smAddrFull: "",
        smFormattedAddr: "",
        smLocationType: "",
        smPlaceID: "",
        smTel: "",
        smFine: "",
        smPenalty: "",
        smBonded: "",
        sdKey: "",
        sdTypeKind: "",
        sdTypeGoods: "",
        sdExtentOutside: "",
        sdExtentInside: "",
        sdGoodsOutside: "",
        sdGoodsInside: "",
        sdEquipment: "",
        sdWorkingTime: "",
        sdSecurity: "",
        sdInsure: "",
        sdEtcMemo: "",
        sdContact1: "",
        sdContact2: "",
        sdContact3: "",
        sdContact4: "",
        sdPhoto: "",
        isNew: "",
      },
      () => {}
    );
  };

  const searchWordClear = () => {
    $searchWord.current.value = "";
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="card-subtitle pt-3 text-muted font-weight-bold">
                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn  btn-outline-primary "
                      onClick={() => {
                        EVENT_ERP_CUSTOMS_STORE_CREATED();
                      }}
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                    <select
                      className="custom-select form-control"
                      style={{ minWidth: 150 }}
                      ref={$searchLevel}
                      onChange={searchFilterChanged}
                    >
                      <option value="">보세여부선택</option>
                      <option value="A">보세창고</option>
                      <option value="B">일반창고</option>
                    </select>

                    <input
                      ref={$searchWord}
                      type="text"
                      className="form-control"
                      placeholder="검색어"
                      aria-label="검색어"
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          searchFilterChanged();
                        }
                      }}
                      aria-describedby="btnGroupAddon2"
                      onClick={() => {
                        searchWordClear();
                      }}
                    />

                    <button
                      className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                      onClick={() => {
                        searchFilterChanged();
                      }}
                    >
                      <i className="fas fa-search mr-2" />
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <StoreManageTable isServerPaging={true} isServerSort={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
