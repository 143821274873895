import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchLogistics from "./components/SearchLogistics";
import SearchPersonalUser from "./components/SearchPersonalUser";
import SearchShipper from "./components/SearchShipper";
import UserInfoModal from "./components/UserInfoModal";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Loading from "../../../lib/Loading";

import "../StyleSheet.css";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "AppWebViewSearchUser";

let loginInfo = null;
function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}

export default function AppWebViewSearchUser() {
  const [userType, setUserType] = useState("SearchLogistics");
  const [langMode, setLangMode] = useState("strait");
  const [isLoad, setIsLoad] = useState(true);
  const { t, i18n } = useTranslation();

  const screenList = [
    {
      key: "SearchLogistics",
      name: t("logisticsComp"),
      component: (params) => <SearchLogistics {...params} />,
    },
    {
      key: "SearchPersonalUser",
      name: t("personalMember"),
      component: (params) => <SearchPersonalUser {...params} />,
    },
    {
      key: "SearchShipper",
      name: t("shipperComp"),
      component: (params) => <SearchShipper {...params} />,
    },
  ];

  // (() => {
  //   if (window.ReactNativeWebView === undefined) {
  //     alert("잘못된 접근입니다.");
  //     window.location.replace("/");
  //   }
  // })();

  useEffect(() => {
    document.addEventListener("message", receiveMessage);
    window.addEventListener("message", receiveMessage);
    return () => {
      document.removeEventListener("message", receiveMessage);
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const receiveMessage = (event) => {
    let data = util.buddibleDecrypt(event.data);
    if (!data) return;
    data = JSON.parse(data);

    if (data.key === "init") {
      i18n.changeLanguage(data.lang, (err) => console.log(err));
      if (data.lang === "en") {
        setLangMode("wide");
      }
      const loginData = {
        comp_cd: data.comp_cd,
        user_id: data.user_id,
        user_pw: data.user_pw,
      };
      requestLogin(loginData, () => {
        setIsLoad(false);
      });
    }
  };

  const requestLogin = (updateData, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/login/JS_login_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "HeaderAccController",
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);

          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          loginInfo = newData["returnData"][0];
        }
        callback && callback();
      }
    });
  };

  const addFavUser = (user) => {
    const data = JSON.stringify({
      key: "addFav",
      ...user,
    });
    window.ReactNativeWebView?.postMessage(data);
  };

  const requestChatToUser = (user) => {
    const data = JSON.stringify({
      key: "requestChatToUser",
      ...user,
    });
    window.ReactNativeWebView?.postMessage(data);
  };

  const openRootStackBigImageView = (user) => {
    const data = JSON.stringify({
      key: "showNameCard",
      ...user,
    });
    window.ReactNativeWebView?.postMessage(data);
  };

  const openUserInfoModal = (user) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_SEARCH_USER_MODAL_CALL,
      _mClassName,
      user,
      () => {}
    );
  };

  return (
    <>
      <UserInfoModal
        addFavUser={addFavUser}
        requestChatToUser={requestChatToUser}
        openRootStackBigImageView={openRootStackBigImageView}
      />
      {isLoad && <Loading />}
      <div
        className="vh-100 d-flex flex-column bg-white mx-auto"
        style={{ maxWidth: 800 }}
      >
        <div className="px-1 pt-1">
          <div className="row m-0 p-0">
            {screenList.map((item) => {
              return (
                <div
                  key={item.key}
                  className={`col-4 m-0 p-2 text-center border-bottom transition-3 ${
                    userType === item.key
                      ? "border-width-2 border-gbts text-gbts font-weight-bold"
                      : ""
                  }`}
                  onClick={() => {
                    setUserType(item.key);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex-grow-1">
          {screenList.map((item) => {
            return (
              <div
                key={item.key}
                className={`h-100 ${userType !== item.key ? "d-none" : ""}`}
              >
                {item.component({
                  loginInfo,
                  langMode,
                  addFavUser,
                  openUserInfoModal,
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
