import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let userTel = "";

export default function FindPWModal() {
  const [isCertification, setIsCertification] = useState(false);
  const [certification, setCertification] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [idChk, setIdChk] = useState(true);
  const [state_AUTH_REQ, setState_AUTH_REQ] = useState(false);
  const [state_AUTH_COMP, setState_AUTH_COMP] = useState(false);
  const [check_pwd_length, setCheck_pwd_length] = useState(false);
  const [check_pwd_re, setCheck_pwd_re] = useState(false);
  const [state_EMAIL_COMP, setState_EMAIL_COMP] = useState(false);
  const [isEmailChk, setIsEmailChk] = useState(false);

  const $controllers = {
    USER_ID: useRef(),
    USER_PW: useRef(),
    USER_NM: useRef(),
    USER_PW_RE: useRef(),
    USER_TEL: useRef(),
    USER_AUTH_CODE: useRef(),
    check_pwd_length: check_pwd_length,
    check_pwd_re: check_pwd_re,
    state_AUTH_REQ: state_AUTH_REQ,
    state_AUTH_COMP: state_AUTH_COMP,
    state_EMAIL_COMP: state_EMAIL_COMP,
  };

  const CheckEmail = (str) => {
    let reg_email =
      /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    return reg_email.test(str);
  };

  const joinData = (key, data) => {
    let retData = {
      COMP_CD: "Y0001",
      USER_ID: $controllers.USER_ID.current.value,
      // USER_PW: $controllers.USER_PW.current.value,
      // USER_PW_RE: $controllers.USER_PW_RE.current.value,
      USER_NM: $controllers.USER_NM.current.value,
      USER_TEL: userTel,
      // USER_AUTH_CODE: $controllers.USER_AUTH_CODE.current.value,
      check_pwd_length: $controllers.check_pwd_length,
      check_pwd_re: $controllers.check_pwd_re,
      state_AUTH_REQ: $controllers.state_AUTH_REQ,
      state_AUTH_COMP: $controllers.state_AUTH_COMP,
      state_EMAIL_COMP: $controllers.state_EMAIL_COMP,
    };

    if (key === "METHOD") {
      retData.METHOD = data;
    }
    if (key === "USER_AUTH_CODE") {
      retData.USER_AUTH_CODE = data;
    }

    return retData;
  };

  const handleChange = (key, text) => {
    // dispatch({ type: key, value: text });
    $controllers[key] = text;
  };

  //테스트
  const reqTelFromServer = () => {
    let updateData = joinData("METHOD", "REQ_TEL");

    if (updateData.USER_NM.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요.", () => {
        $controllers.USER_NM.current.focus();
      });
      return false;
    }
    requestUserUpdate(updateData);
  };
  const checkTelFromServer = () => {
    let updateData = joinData("METHOD", "CHECK_EMAIL_TEL");
    updateData.USER_TEL = userTel;

    console.log(updateData);
    requestUserUpdate(updateData);
  };

  const checkAuthFromServer = () => {
    let updateData = joinData("METHOD", "CHECK_AUTH");

    if (state_AUTH_REQ) {
      let authcode = $controllers.USER_AUTH_CODE.current.value;

      updateData.USER_AUTH_CODE = authcode;

      requestUserUpdate(updateData);
    }
  };

  const actionSave = () => {
    let updateData = joinData("METHOD", "UPDATE_PW");

    if (state_AUTH_REQ && state_AUTH_COMP) {
      let authcode = $controllers.USER_AUTH_CODE.current.value;
      let pw = $controllers.USER_PW.current.value;

      updateData.USER_AUTH_CODE = authcode;
      updateData.USER_PW = pw;

      requestUserUpdate(updateData);
    } else {
      codeList.Modal.current.alert(
        "필수 입력사항을 모두 입력 해주세요.",
        () => {}
      );
      return false;
    }
  };

  const checkValidation = (updateData) => {
    //todo 다국어 작업

    // console.log(updateData);
    let regExp = /[01]([016789])[-](\d{4}|\d{3})[-]\d{4}$/g;
    // console.log(regExp.test(updateData.USER_TEL));

    if (updateData.METHOD === "CHECK_EMAIL_TEL") {
      // console.log(util.validationMobileNumber(updateData.USER_TEL));
      if (util.validationMobileNumber(updateData.USER_TEL) === false) {
        codeList.Modal.current.alert("이메일 확인을 해주세요.", () => {
          $controllers.USER_ID.current.focus();
        });
        return false;
      }

      if (updateData.USER_NM.trim() === "") {
        codeList.Modal.current.alert("성명을 입력 해주세요.", () => {
          $controllers.USER_NM.current.focus();
        });
        return false;
      }
    } else if (updateData.METHOD === "CHECK_AUTH") {
      if (util.validationMobileNumber(updateData.USER_TEL) === false) {
        codeList.Modal.current.alert("핸드폰 번호를 입력해 주세요.", () => {});
        return false;
      }
      if (updateData.USER_AUTH_CODE.length !== 6) {
        codeList.Modal.current.alert("인증번호 6자리를 입력해 주세요.", () => {
          $controllers.USER_AUTH_CODE.current.focus();
        });
        return false;
      }
    } else if (updateData.METHOD === "UPDATE_PW") {
      if (updateData.USER_NM.trim() === "") {
        codeList.Modal.current.alert("성명을 입력 해주세요.", () => {
          $controllers.USER_NM.current.focus();
        });
        return false;
      }

      if (updateData.check_pwd_length === false) {
        codeList.Modal.current.alert(
          "비밀번호를 6자리 이상 입력해주세요.",
          () => {
            $controllers.USER_PW.current.focus();
          }
        );
        return false;
      }

      if (updateData.check_pwd_re === false) {
        codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {});
        return false;
      }
      if (
        updateData.state_AUTH_REQ === false ||
        updateData.state_AUTH_COMP === false
      ) {
        codeList.Modal.current.alert(
          "핸드폰 문자 인증을 진행 해주세요.",
          () => {}
        );
        return false;
      }
    } else if (updateData.METHOD === "REQ_TEL") {
      if (util.validationEmail(updateData.USER_ID) === false) {
        codeList.Modal.current.alert("이메일 주소를 입력해 주세요.", () => {
          $controllers.USER_ID.current.focus();
        });
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    if (checkValidation(updateData) === false) return;

    let socketMsg = {
      file: "/join/JS_join_gbts_private_find_pw.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    if (socketMsg.USER_PW) {
      socketMsg.USER_PW = util.buddibleEncrypt(socketMsg.USER_PW);
    }
    console.log(socketMsg);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("newData", newData);
      if (newData) {
        switch (updateData.METHOD) {
          case "CHECK_AUTH":
            if (newData["ret"] === true) {
              handleChange("state_AUTH_COMP", true);
              setMin(0);
              $controllers.USER_AUTH_CODE.current.disabled = true;
              setState_AUTH_COMP(true);
              setCertification(true);

              codeList.Modal.current.alert("인증 되었습니다.", () => {});
            } else {
              codeList.Modal.current.alert(
                "유효하지 않은 인증번호 입니다.",
                () => {}
              );
            }

            break;
          case "CHECK_EMAIL_TEL":
            if (newData["ret"] === true) {
              handleChange("state_AUTH_REQ", true);
              setState_AUTH_REQ(true);
              setIsCertification(true);
              setMin(3);
            } else {
              codeList.Modal.current.alert(
                "이메일 또는 핸드폰 번호를 확인해주세요.",
                () => {}
              );
            }
            break;
          case "UPDATE_PW":
            if (newData["ret"] === true) {
              codeList.Modal.current.alert(
                "비밀번호 수정이 완료 되었습니다.",
                () => {
                  window.location.replace("/");
                }
              );
            } else {
              codeList.Modal.current.alert(
                "죄송합니다. 오류가 발생 하였습니다.",
                () => {}
              );
            }
            break;
          case "REQ_TEL":
            if (newData["ret"] === true) {
              if (newData["returnData"].length > 0) {
                $controllers.USER_ID.current.disabled = true;
                setState_EMAIL_COMP(true);
                setIsEmailChk(true);

                userTel = newData["returnData"][0].USER_TEL;

                $controllers.USER_TEL.current.value =
                  // newData["returnData"][0].USER_TEL;
                  newData["returnData"][0].USER_TEL.replace(
                    /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
                    "$1-****-$3"
                  ).replace(/\-{1,2}$/g, "");
              } else {
                codeList.Modal.current.alert(
                  "등록된 이메일 주소가 없습니다.",
                  () => {
                    $controllers.USER_ID.current.focus();
                  }
                );
              }
            } else {
              codeList.Modal.current.alert(
                "등록된 이메일 주소가 없습니다.",
                () => {
                  $controllers.USER_ID.current.focus();
                }
              );
            }
            break;
        }
      }
    });
  };

  // 타이머
  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(sec) > 0) {
        setSec(parseInt(sec) - 1);
      }
      if (parseInt(sec) === 0) {
        if (parseInt(min) === 0) {
          clearInterval(countdown);
        } else {
          setMin(parseInt(min) - 1);
          setSec(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [min, sec]);

  return (
    <div
      className="modal fade"
      id="findPWModal"
      tabIndex="-1"
      aria-labelledby="findPWModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title  m-0 p-0">비밀번호 찾기</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="findPWModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="py-3 d-block">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    이름
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      ref={$controllers.USER_NM}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label className="col-form-label col-sm-3 text-end">
                    이메일
                  </label>
                  <div className="col d-flex flex-row">
                    <input
                      className="mr-3 flex-fill form-control w-auto"
                      type="email"
                      // placeholder="입력하신 이메일을 아이디로 사용합니다."
                      ref={$controllers.USER_ID}
                      onChange={(e) => {
                        setIdChk(CheckEmail(e.target.value));
                      }}
                    />

                    {!isEmailChk ? (
                      <button
                        className="btn_2"
                        onClick={() => {
                          reqTelFromServer();
                          // console.log(updateData);
                        }}
                        style={{ width: 130 }}
                      >
                        이메일 확인
                      </button>
                    ) : (
                      <button
                        className="btn_1"
                        onClick={() => {
                          $controllers.USER_ID.current.disabled = false;
                          setIsEmailChk(false);
                        }}
                        style={{ width: 130 }}
                      >
                        이메일 변경
                      </button>
                    )}
                  </div>
                </div>

                {!idChk ? (
                  <div className="mb-3 row" style={{ marginTop: -10 }}>
                    <label className="col-form-label col-sm-3 text-end"></label>
                    <div className="col-sm-9" style={{ textAlign: "left" }}>
                      <span style={{ color: "#f64a4a" }}>
                        올바른 이메일 주소를 입력해주세요
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="row mb-3">
                  <label className="col-form-label col-sm-3 text-end">
                    핸드폰번호{" "}
                  </label>
                  <div className="col d-flex flex-row">
                    <input
                      type="tel"
                      className="mr-3 flex-fill form-control w-auto"
                      disabled={true}
                      ref={$controllers.USER_TEL}
                    />
                    <button
                      className="btn_2"
                      onClick={() => {
                        certification
                          ? codeList.Modal.current.alert(
                              "이미 인증을 하였습니다.",
                              () => {}
                            )
                          : checkTelFromServer();
                      }}
                      style={{ width: 130 }}
                    >
                      인증번호 발송
                    </button>
                  </div>
                </div>

                {isCertification ? (
                  <>
                    <div className="mb-1 row">
                      <label className="col-form-label col-sm-3 text-end">
                        인증번호{" "}
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.USER_AUTH_CODE}
                        />
                      </div>
                      <button
                        className="btn_2"
                        onClick={() => {
                          certification
                            ? codeList.Modal.current.alert(
                                "이미 인증을 하였습니다.",
                                () => {}
                              )
                            : checkAuthFromServer();
                        }}
                        style={{ width: 115, background: "#777777" }}
                      >
                        확인
                      </button>
                    </div>
                    <div className="mb-2 row">
                      <div
                        className="col-sm-12"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {min !== 0 ? (
                          <>
                            {min}:{sec < 10 ? `0${sec}` : sec}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {certification ? (
                  <>
                    <div className="mt-2 mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        변경할 비밀번호{" "}
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="password"
                          className="form-control"
                          ref={$controllers.USER_PW}
                          onChange={(e) => {
                            if (e.target.value.length > 6) {
                              setCheck_pwd_length(true);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        변경할 비밀번호 확인{" "}
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="password"
                          className="form-control"
                          ref={$controllers.USER_PW_RE}
                          onChange={(e) => {
                            if (
                              e.target.value ===
                              $controllers.USER_PW.current.value
                            ) {
                              setCheck_pwd_re(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="text-danger">
                ※ 아이디, 비밀번호 찾기는 개인회원만 이용가능합니다. 기업회원은
                GBTS관리자에게 문의 바랍니다.
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1"
              onClick={() => {
                actionSave();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
