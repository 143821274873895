/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import CommonNav from "../CommonNav";
import Footer from "../Footer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import axios from "axios";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const serverFile = {
  update: "/logistoryBoard/JS_logistory_update.php",
  insert: "/logistoryBoard/JS_logistory_insert.php",
};

const _mClassName = "WriteLogiStory";
let isCheckAdmin = false;
let comp_list = [];
export default function WriteLogiStory(props) {
  const param = useParams();
  const [loginInfo, setLoginInfo] = useState(null);
  const [isEditorState, setIsEditorState] = useState();
  const [uploadFiles, setUploadFiles] = useState("");
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLogoListModalOpen, setIsLogoListModalOpen] = useState(false);
  const [typeList, setTypeList] = useState([]);

  const [prData, setPrData] = useState({
    prCreateDate: "",
    prLOGO: "",
    prBCOMPNM: "",
    prTITLE: "",
    prCONTENTS: "",
    prUSERName: "",
    prFiles: "",
    prType: "",
    prFWDNO: "",
    // prPWD: "", //비밀번호 회사코드가있을경우 회사코드로 대체
    // prPublicYN: "Y", //공개여부
  });

  const QuillRef = useRef();

  const controller = {
    prCreateDate: useRef(),
    prBCOMPNM: useRef(),
    prTITLE: useRef(),
    prCONTENTS: useRef(),
    prUSERName: useRef(),
    prType: useRef(),
    // prPWD: useRef(), //비밀번호 회사코드가있을경우 회사코드로 대체
    // prPublicYN: useRef(), //공개여부
    prFiles: useRef(),
  };

  useEffect(() => {
    selectTypeList();
    getCompLogoList();
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData["returnData"][0].comp_gb === "Z") {
        setLoginInfo(userData["returnData"][0]);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            setLoginInfo(n["returnData"][0]);
          }
        }
      }
    );

    if (param) {
      if (param.hasOwnProperty("idx")) {
        updateDataSet();
      }
    }
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo !== null) {
      if (loginInfo.comp_cd === "Z0001") {
        isCheckAdmin = true;
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, [loginInfo]);

  const getCompLogoList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_b_logo_select.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          comp_list = [...newData["returnData"]];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const selectTypeList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_notice_type_full_name_code_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          setTypeList(newData["returnData"]);
        }
      }
    });
  };

  //수정시 초기 데이터 세팅
  const updateDataSet = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistorySelect.php",
      msgID: msgID,
      data: {},
      prIDX: param.idx,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = newData["returnData"][0];
          for (let key in controller) {
            if (controller[key].current) {
              controller[key].current.value = data[key];
              if (key === "prCreateDate") {
                let prCreateDate = util.getDateToFormat(
                  data[key],
                  "YYYY-MM-DD"
                );
                data[key] = prCreateDate;
                controller[key].current.value = prCreateDate;
              }
              if (key === "prCONTENTS") {
                setIsEditorState(data[key]);
              }
              if (key === "prFiles") {
                setUploadFiles(data[key]);
              }
            }
          }
          let _prData = { ...prData };
          for (let key in _prData) {
            _prData[key] = data.hasOwnProperty(key) ? data[key] : "";
          }
          setPrData(_prData);
        }
      }
    });
  };

  //게시글 등록요청
  const requestProduct = () => {
    if (checkValidation()) {
      codeList.Modal.current.confirm(
        "선사•항공사 공지사항  게시글 등록을 진행하시겠습니까?",
        (ret) => {
          if (ret) {
            let updateData = {
              prCreateDate: controller.prCreateDate.current.value, //공지게시일
              prUSERName: controller.prUSERName.current.value, //질문자 명
              prTITLE: controller.prTITLE.current.value, //질문 제목
              prCONTENTS: controller.prCONTENTS.current.value, //질문 내용
              prFiles: controller.prFiles.current.value, //질문자 이메일
              prBCOMPNM: controller.prBCOMPNM.current.value, //질문자 이메일
              prType: controller.prType.current.value, //질문자 이메일
              prIDX: "", //index
              prINSERTDATE: new Date(), //질문 업로드일
              prUSERID: loginInfo ? loginInfo.user_id : "", //로그인 경우 유저 아이디
              prCompCD: loginInfo ? loginInfo.comp_cd : "", //회사 코드 z로 시작시 관리자
              prUSEYN: "Y", //사용여부
              prLOGO: prData.prLOGO, //로고이미지
              prFWDNO: prData.prFWDNO, //포워더넘버
            };
            if (param) {
              if (param.hasOwnProperty("idx")) {
                updateData.prIDX = param.idx;
              }
            }
            requestUserUpdate(updateData);
          } else {
            codeList.Modal.current.alert(
              "선사•항공사 공지사항  게시글 등록이 취소되었습니다."
            );
          }
        }
      );
    }
  };

  //필수입력값 검사
  const checkValidation = () => {
    if (controller.prTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if (controller.prType.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if (controller.prBCOMPNM.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "선사 또는 콘솔사명을 입력해주세요",
        () => {}
      );
      return false;
    }
    if (controller.prUSERName.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요", () => {});
      return false;
    }
    if (controller.prCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    // if (prData.prPublicYN === "N") {
    //   if (controller.prPWD.current.value.trim() === "") {
    //     codeList.Modal.current.alert("비밀번호를 입력해 주세요", () => {});
    //     return false;
    //   }
    // }
    return true;
  };

  //게시글 등록 서버통신
  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: updateData.prIDX === "" ? serverFile.insert : serverFile.update,
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert("게시글 등록이 완료되었습니다.", () => {
            window.location.pathname = "/Post/ls";
          });
        }
      }
    });
  };

  // //공개여부 설정
  // const ChangePublicYN = (e) => {
  //   let val = e.target.value;
  //   let setData = {};
  //   if (val === "N") {
  //     if (loginInfo !== null) {
  //       controller.prPWD.current.value = loginInfo.comp_cd;
  //       setData.prPWD = loginInfo.comp_cd;
  //       setPwDisabled(true);
  //     } else {
  //       setPwDisabled(false);
  //     }
  //   } else {
  //     controller.prPWD.current.value = "";
  //   }
  //   setPrData({ ...prData, ...setData, prPublicYN: val });
  // };

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];

      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      controller.prFiles.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      // console.log(item, `${name}:${url}`, item === `${name}:${url}`);
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      // console.log(pmFiles);
      controller.prFiles.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      uploadCallback(file[0]);
    };
  };

  //파일첨부 업데이트
  const uploadCallback = async (file) => {
    const region = "mall/logistory";
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", region);

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const range = QuillRef.current?.getEditor().getSelection()?.index;
      if (range !== null && range !== undefined) {
        let quill = QuillRef.current?.getEditor();

        quill?.setSelection(range, 1);
        quill?.clipboard.dangerouslyPasteHTML(
          range,
          `<img style="max-width:100%;"   src=${response.data["url"]} alt="이미지 태그가 삽입됩니다." />`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //로고이미지 포멧셋
  const handleLogoImage = (img) => {
    let url = img || "";
    if (url.indexOf("?")) {
      url = url.split("?")[0];
    }
    return url;
  };

  //회사 로고이미지 업로드 후 처리
  const handleLogoImageUpload = (value) => {
    if (value.ret) {
      let file = `${value.fileName}?${value.realFileName}`;
      let data = {
        ...prData,
        prLOGO: file,
      };
      setPrData(data);
    } else {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생하였습니다. 잠시후 다시 시도해주세요"
      );
    }
  };

  // 선사콘솔사 선택
  const selectCompLogo = (url, bcomp_nm, fwd_no) => {
    let data = {
      ...prData,
      prLOGO: url,
      prBCOMPNM: bcomp_nm,
      prFWDNO: fwd_no,
    };
    controller.prBCOMPNM.current.value = bcomp_nm;
    setPrData(data);
    setIsLogoListModalOpen(false);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  let formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "width",
  ];

  return (
    <>
      {isLogoListModalOpen && (
        <ModalLogoList
          selectCompLogo={selectCompLogo}
          setIsLogoListModalOpen={setIsLogoListModalOpen}
        />
      )}
      <div className="white_bg" id="write_QnA" style={{ minHeight: "100vh" }}>
        <CommonNav />
        <main className="white_bg">
          {isCheckAdmin && (
            <div className="py-5">
              <div className="my-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="pt-3 text-muted font-weight-bold">
                        <div
                          className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                          role="toolbar"
                        >
                          <h3 className="mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0">
                            <strong>선사•항공사 공지사항 작성하기</strong>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex flex-row align-items-end mb-3">
                      <div
                        className="d-flex flex-row justify-content-center align-items-center rounded border bg-light position-relative mr-3"
                        style={{
                          minWidth: 50,
                          width: 50,
                          height: 50,
                          background: `url(${handleLogoImage(
                            prData.prLOGO
                          )}) 50% 50%/contain no-repeat`,
                        }}
                      >
                        <div
                          className="position-absolute"
                          style={{ top: 35, right: -8 }}
                        >
                          <div className="btn-group">
                            <UploadImageToS3WithPhp
                              region="gbts/companyInfo"
                              callback={handleLogoImageUpload}
                            >
                              <span
                                className="btn btn-sm btn-secondary d-flex flex-row justify-content-center align-items-center"
                                style={{ width: 20, height: 20 }}
                              >
                                <i className="fa-regular fa-images" />
                              </span>
                            </UploadImageToS3WithPhp>
                            <button
                              className="btn btn-sm btn-outline-secondary bg-white d-flex flex-row justify-content-center align-items-center"
                              style={{ width: 20, height: 20 }}
                              onClick={() => {
                                setIsLogoListModalOpen(true);
                              }}
                            >
                              <i className="fa-solid fa-bars" />
                            </button>
                          </div>
                        </div>
                        {!prData.prLOGO && (
                          <p className="font-weight-bold text-secondary text-center font-size-08">
                            LOGO
                          </p>
                        )}
                      </div>

                      <input
                        className="form-control h-100 mr-2"
                        type="text"
                        placeholder="선사/콘솔사명"
                        ref={controller.prBCOMPNM}
                      />
                      <input
                        className="form-control h-100 mr-2"
                        type="date"
                        ref={controller.prCreateDate}
                      />

                      <select
                        className="form-control h-100 mr-2"
                        ref={controller.prType}
                      >
                        <option value={""}>-분류-</option>
                        {typeList.length > 0 &&
                          typeList.map((type, index) => {
                            return (
                              <option
                                key={type.SmallType}
                                value={type.SmallType}
                              >
                                {type.FullName}
                              </option>
                            );
                          })}
                      </select>
                      <input
                        className="form-control h-100 mr-2"
                        type="text"
                        placeholder="작성자(닉네임 가능)"
                        ref={controller.prUSERName}
                        defaultValue={loginInfo ? loginInfo.user_nm : ""}
                      />
                    </div>
                    <div className="col-12 d-flex flex-row mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="제목을 입력해 주세요"
                        ref={controller.prTITLE}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control d-none"
                        rows={20}
                        placeholder="문의사항을 입력해주세요"
                        ref={controller.prCONTENTS}
                        disabled
                        value={isEditorState}
                      />

                      <ReactQuill
                        style={{ height: "500px", marginBottom: 50 }}
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={isEditorState || ""}
                        ref={(element) => {
                          if (element !== null) {
                            QuillRef.current = element;
                          }
                        }}
                        onChange={(content, delta, source, editor) => {
                          setIsEditorState(editor.getHTML());
                        }}
                      />
                    </div>

                    <div className="col-12 d-flex flex-row justify-content-between my-3">
                      {uploadFiles !== "" ? (
                        <div
                          className="flex-fill d-flex flex-column"
                          style={{ maxHeight: 100 }}
                        >
                          <p className="h6 font-weight-bold">※ 첨부파일</p>
                          <div className="flex-fill overflow_scroll-Y">
                            {uploadFiles.split("|").map((item, index) => {
                              let splitData = item.split("?");
                              if (splitData.length === 0) return;
                              let fileName = splitData[0];
                              let fileLink = splitData[1];
                              return (
                                <div
                                  className="font-weight-bold mb-2"
                                  key={fileName + index}
                                >
                                  {fileName}
                                  <span
                                    className="ml-3 btn btn-sm btn-danger"
                                    onClick={() => {
                                      deleteFile(fileLink, fileName);
                                    }}
                                  >
                                    삭제
                                  </span>
                                </div>
                              );
                            })}{" "}
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                      <UploadImageToS3WithPhp
                        region={"mall/logiStory"}
                        callback={UploadFiles}
                      />
                      <input
                        className="form-control d-none"
                        disabled={true}
                        type="text"
                        ref={controller.prFiles}
                      />
                    </div>

                    <div className="col-12 py-3 d-flex flex-row justify-content-center">
                      <div className="btn_1 px-5" onClick={requestProduct}>
                        등록하기
                      </div>

                      <Link to="/Post/ls" className="btn_1 outline ml-2 px-5">
                        목록으로
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isCheckAdmin && (
            <div className="py-5 my-5">
              <div className="container py-5">
                <h3 className="text-center font-weight-bold text-secondary">
                  해당 페이지는 관리자만 접근 가능합니다.
                </h3>
                <div className="text-center mt-2">
                  <a
                    href="/"
                    className="btn btn-primary bg-freiscop border-freiscop"
                  >
                    메인으로
                  </a>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
      <Footer />
    </>
  );
}

// 선사, 콘솔사 로고리스트
const ModalLogoList = ({ selectCompLogo, setIsLogoListModalOpen }) => {
  const CompLogoItemRender = useCallback((props) => {
    return <CompLogoItem {...props} />;
  });
  const CompLogoItem = useCallback(
    React.memo((props) => {
      let item = { ...props.item };

      let borderTop = "border-top";
      if (props.index === 0) {
        borderTop = "";
      }

      let logoImage = item.blLOGO ? item.blLOGO.split("?")[0] : "";

      return (
        <div className={borderTop}>
          <div
            className="d-flex flex-row justify-content-start align-items-center p-2 cursor_pointer_bg_light"
            onClick={() => {
              selectCompLogo(item.blLOGO, item.blCompNM_KO, item.blFWDNO);
            }}
          >
            <div
              className="d-flex flex-row justify-content-center align-items-center rounded border bg-light position-relative mr-3"
              style={{
                width: 50,
                height: 50,
                background: `url(${logoImage}) 50% 50%/contain no-repeat`,
              }}
            >
              {logoImage === "" && (
                <p className="font-weight-bold text-secondary text-center font-size-08">
                  LOGO
                </p>
              )}
            </div>
            <p className="font-weight-bold">
              {item.blCompNM}/{item.blCompNM_KO}
            </p>
          </div>
        </div>
      );
    })
  );

  return (
    <div className="modal_background">
      <div className="modal_body bg-white rounded">
        <div className="p-2 d-flex flex-row justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold mr-3">
            선사 · 항공사 로고 리스트
          </h6>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => setIsLogoListModalOpen(false)}
          >
            &times;
          </button>
        </div>
        <div
          className="p-2 border-top"
          style={{ maxHeight: 400, overflowY: "scroll" }}
        >
          {comp_list.length > 0 ? (
            comp_list.map((item, index) => {
              return (
                <CompLogoItemRender
                  key={item.COMP_CD}
                  item={item}
                  index={index}
                />
              );
            })
          ) : (
            <p>선사, 콘솔사의 로고데이터가 존재하지 않습니다.</p>
          )}
        </div>
      </div>
    </div>
  );
};
