import React from "react";

export default function AboutGbts(props) {
  const { isMobile } = props;
  return (
    <div className="p-xl-5">
      <div className="text-center mb-4">
        <h2 className="font-weight-900 mt-3 mt-xl-0 mb-2 lineUp">
          ABOUT <span className="text-freiscop">GBTS</span>
        </h2>
        <h4 className="font-weight-600">
          <p className="px-2 px-xl-0">
            <span className="text-freiscop font-weight-800">
              ‘수출입 물류 디지털 전환(DT)’
            </span>
            을 통하여 물류정보의 비대칭을 해소하고 물류 가시성을 확보하여,
            <br className="d-none d-xl-block" />
            국제물류기업과 수출입기업간 높은 신뢰도를 구축하고, 업무효율을
            극대화하는 협업툴과 물류서비스를 제공하는
            <br className="d-none d-xl-block" />
            디지털물류 스타트업입니다.
          </p>
        </h4>
      </div>
      <div className="p-3 mb-3 mx-3 mx-xl-0 bg-light">
        <div className="d-flex flex-column flex-xl-row justify-content-start align-items-center mb-3">
          <p
            className="h6 m-0 mb-2 mb-xl-0 py-2 px-4 border border-dark bg-white font-weight-900 text-center"
            style={{ minWidth: 150 }}
          >
            현행 업무방식
          </p>
          <div className="py-2 px-xl-4 h6 m-0">
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">①</span>{" "}
              <p>
                기업이 주문한 물류업무를 포워더가 선사/항공사로{" "}
                <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">②</span>{" "}
              <p>
                물류업무를 실행하고 <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">③</span>{" "}
              <p>
                다양한 공급채널에서 받은 물류현황을 <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">④</span>
              <p>
                수작업(전화, 이메일 등)으로 대응하면서 시스템이 없고 효율성이
                떨어지는 업무방식을 답습했습니다.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center mb-3">
          <img
            height={isMobile ? "auto" : "200px"}
            width={isMobile ? "80%" : "auto"}
            src="/img/info/before_process.png"
            alt="before_process"
          />
        </div>
      </div>
      <div className="p-3 mb-3 mx-3 mx-xl-0 bg-freiscop-10">
        <div className="d-flex flex-column flex-xl-row justify-content-start align-items-center mb-3">
          <p
            className="py-2 px-4 h6 m-0 mb-2 mb-xl-0  border border-dark bg-white font-weight-900 text-center"
            style={{ minWidth: 150 }}
          >
            GBTS 업무방식
          </p>
          <div className="py-2 px-xl-4 h6 m-0 ">
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">①</span>
              <p>
                견적부터 예약까지 로지웍으로 오더하고{" "}
                <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">②</span>
              <p>
                포워더가 프레이스콥에서 예약부터 관리까지{" "}
                <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">③</span>
              <p>
                단일 채널에서 실행하면서 업무 전과정을{" "}
                <br className="d-xl-none" />
              </p>
            </div>
            <div className="d-inline-flex flex-row mb-1 mb-xl-0 mr-xl-2">
              <span className="font-weight-bold mr-1">④</span>
              <p>시각화 자료로 실시간 제공합니다.</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center mb-3">
          <img
            height={isMobile ? "auto" : "200px"}
            width={isMobile ? "80%" : "auto"}
            src="/img/info/gbts_process.png"
            alt="before_process"
          />
        </div>
      </div>
    </div>
  );
}
