import React, { useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import BasaCoPointManageTable from "./BasaCoPointManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "BasaCoPointManage";

export default function BasaCoPointManage(props) {
  const { pagesOpen, compList, compListObj } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [selectedComp, setSelectedComp] = useState([]);
  const bcpPOINT = useRef();
  const SEARCH_FILED = useRef();
  const SEARCH_WORD = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  //기업조회 권한 허용 업체 등록
  const updateBASACoPoint = (data) => {
    if (!data && selectedComp.length === 0) {
      codeList.Modal.current.alert("업체를 선택해주세요");
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/basa/JS_basaCoPointUpdate.php",
      msgID: msgID,
      data: [],
      bcpCOMP_CD: data ? data.bcpCOMP_CD : selectedComp[0].COMP_CD,
      bcpCOMP_NAME: data ? data.bcpCOMP_NAME : selectedComp[0].COMP_NM,
      bcpPOINT: data ? data.bcpPOINT : bcpPOINT.current.value,
    };

    codeList.Modal.current.confirm(
      `[${socketMsg.bcpCOMP_NAME}] 업체의 기업조회 가능 횟수를 ${socketMsg.bcpPOINT}(으)로 변경하시겠습니까?`,
      (ret) => {
        if (ret) {
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                bcpPOINT.current.value = 0;
                setSelectedComp([]);
                socket.fireLocalEventListener(
                  MsgIDList.EVENT_ERP_BASA_POINT_UPDATE,
                  _mClassName,
                  {},
                  () => {
                    codeList.Modal.current.alert(
                      `[${selectedComp[0].COMP_NM}] ${socketMsg.bcpPOINT} 포인트가 등록되었습니다.`
                    );
                  }
                );
              } else {
                codeList.Modal.current.alert("등록중 오류가 발생했습니다.");
              }
            }
          });
        }
      }
    );
  };

  const actionSearch = () => {
    const filter = {
      SEARCH_FILED: SEARCH_FILED.current.value,
      SEARCH_WORD: SEARCH_WORD.current.value,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ERP_BASA_POINT_CHANGED_FILTERS,
      _mClassName,
      filter,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid h-100">
      <div className="bg-white rounded border p-3 shadow-none mb-2">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p className="font-weight-bold">업체 선택</p>
          <Select
            placeholder="업체를 선택해주세요"
            style={{ width: 800 }}
            multi={false}
            clearable={true}
            values={selectedComp}
            options={compList}
            sortBy={"COMP_CD"}
            valueField={"COMP_CD"}
            labelField={"COMP_NM"}
            onChange={(value) => {
              setSelectedComp(value);
            }}
            itemRenderer={({ item, itemIndex, props, state, methods }) => {
              const isSelected =
                selectedComp.findIndex(
                  (data) => data.COMP_CD === item.COMP_CD
                ) > -1;
              return (
                <div key={item.COMP_CD}>
                  <div
                    className={`p-2 border-bottom cursor_pointer_bg_light ${
                      isSelected ? "bg-light" : ""
                    }`}
                    onClick={() => {
                      methods.addItem(item);
                    }}
                  >
                    <span className="font-weight-bold text-secondary">
                      [{item.COMP_CD}]
                    </span>{" "}
                    {item.COMP_NM}
                  </div>
                </div>
              );
            }}
          />
          <div className="px-2">
            <input
              type="number"
              min={0}
              defaultValue={0}
              className="form-control"
              ref={bcpPOINT}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  updateBASACoPoint(null);
                }
              }}
            />
          </div>
          <button className="btn_1" onClick={() => updateBASACoPoint(null)}>
            등록
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex flex-row justify-content-end">
            <div>
              <div className="input-group">
                <select className="form-control" ref={SEARCH_FILED}>
                  <option value="bcpCOMP_NAME">업체명</option>
                  <option value="bcpCOMP_CD">업체코드</option>
                </select>
                <input
                  className="form-control"
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  ref={SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionSearch();
                    }
                  }}
                />
                <button
                  className="btn btn-primary input-group-text border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                  onClick={() => actionSearch()}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        <BasaCoPointManageTable
          compListObj={compListObj}
          updateBASACoPoint={updateBASACoPoint}
        />
      </div>
    </div>
  );
}
