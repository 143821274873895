/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const QNA_TYPE = [
  { key: "FJA0", desc: "해상(항공) 스케줄 및 견적문의" },
  { key: "FJA1", desc: "내륙운송 문의" },
  { key: "FJA2", desc: "보관 및 하역관련 문의" },
  { key: "FJA3", desc: "통관 및 FTA문의" },
  { key: "FJA4", desc: "적하보험 및 기타문의" },
];

const _mClassName = "";

function QnAManageEdit(props, ref) {
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const $qaIDX = useRef();
  const $qaTYPE = useRef();
  const $qaTITLE = useRef();
  const $qaCONTENTS = useRef();
  const $qaUSEYN = useRef();

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $qaIDX.current.value = userData.currentData.qaIDX;
      $qaIDX.current.disasbled = true;
      $qaTYPE.current.value = userData.currentData.qaType;
      $qaTITLE.current.value = userData.currentData.qaTITLE;
      $qaCONTENTS.current.value = userData.currentData.qaCONTENTS;
      $qaUSEYN.current.value = userData.currentData.qaUSEYN;
    } else {
      $qaIDX.current.value = "";
      $qaIDX.current.disabled = true;
      $qaTYPE.current.value = "";
      $qaTITLE.current.value = "";
      $qaCONTENTS.current.value = "";
      $qaUSEYN.current.value = "";
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        qaIDX: $qaIDX.current.value,
        qaTYPE: $qaTYPE.current.value,
        qaTITLE: $qaTITLE.current.value,
        qaCONTENTS: $qaCONTENTS.current.value,
        qaUSEYN: $qaUSEYN.current.value,
      };

      requestUserUpdate(
        updateData,
        $qaIDX.current.value.trim() !== "" ? EDIT_TYPE.UPDATE : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($qaTYPE.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if ($qaTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if ($qaCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if ($qaUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/qa/JS_qa_update.php"
          : "/qa/JS_qa_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log("newData", newData);
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_FAQ_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "Q&A정보를 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "Q&A정보를 추가 하였습니다.",
                () => {}
              );

          $("#closeQnAEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "Q&A정보 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "Q&A정보 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };
  return (
    <>
      <div
        className="modal fade"
        id="staticQnAEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticQnAEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>Q&A 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeQnAEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$qaIDX}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select className="custom-select form-control" ref={$qaTYPE}>
                    <option value="">미선택</option>
                    {QNA_TYPE.map((menu, index) => {
                      return (
                        <option key={index} value={menu.key}>
                          {menu.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <textarea className="form-control" rows="3" ref={$qaTITLE} />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="15"
                    ref={$qaCONTENTS}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select className="custom-select form-control" ref={$qaUSEYN}>
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(QnAManageEdit);
