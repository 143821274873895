/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";
import CodeList from "./CodeList";

let util = new Utilities();
let loginMSG = {};
let codeList = new CodeList();

const AutoLogin = () => {
  let socket = new BuddibleSocket();
  // console.log("AutoLogin Ready!");
  // console.log(sessionStorage, localStorage);
  let loginInfo = sessionStorage.getItem("loginInfo");
  if (loginInfo === null) loginInfo = localStorage.getItem("loginInfo");

  let loginData = {
    method: MsgIDList.EVENT_SOCKET_LOGIN,
    msgID: "AutoLogin2",
    data: {
      comp_cd: "",
      user_id: "",
      user_pw: "",
    },
  };

  try {
    loginInfo = util.buddibleDecrypt(loginInfo);
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
  } catch (e) {
    loginInfo = null;
    sessionStorage.removeItem("loginInfo");
  }

  if (loginInfo === null) {
    loginInfo = localStorage.getItem("loginInfo");
    try {
      loginInfo = util.buddibleDecrypt(loginInfo);
      loginInfo = JSON.parse(loginInfo);
    } catch (e) {
      console.log("catch localStorage", loginInfo);
      loginInfo = null;
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
    }
  }

  let loginInfoGBTS = localStorage.getItem("loginInfoGBTS");
  try {
    loginInfoGBTS = util.GBTSDecrypt(loginInfoGBTS);
    loginInfoGBTS = JSON.parse(loginInfoGBTS);
    loginInfo = {
      comp_cd: util.buddibleEncrypt(loginInfoGBTS.comp_cd),
      user_id: util.buddibleEncrypt(loginInfoGBTS.user_id),
      user_pw: util.buddibleEncrypt(loginInfoGBTS.user_pw),
      file: "/login/JS_login_gbts.php",
      msgID: util.makeUUIDv4(),
      storageLifeTime: new Date().getTime(),
    };
  } catch (e) {
    // console.log("error", e);
  }

  if (loginInfo === null) {
    setTimeout(() => {
      socket.sendLocalMessage(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "AutoLogin",
        {},
        () => {}
      );
    }, 1000);

    /*console.log("loginInfo === null");
    alert("로그인 후 이용해주십시요");
    window.location.replace("/");*/
    console.log("loginInfo is null");
  }
  let now = new Date();

  if (loginInfo) {
    if (loginInfo.storageLifeTime < now) {
      loginInfo = null;
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
    }
  }

  useEffect(() => {
    //console.log("AutoLogin waitingForConnect!");
    if (loginInfo === null) {
      socket.sendLocalMessage(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "AutoLogin",
        {},
        () => {}
      );
      console.log("useEffect loginInfo === null");
      /*alert("로그인 후 이용해주십시요");
      window.location.replace("/");*/
      console.log("loginInfo is null");
      return;
    }
    loginToServer();
    socket.addLocalEventListener(
      MsgIDList.EVENT_AUTO_RECONNECTED,
      "AutoLogin",
      (beforeData, newData) => {
        loginToSocketServer();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "AutoLogin2",
      (beforeData, newData) => {
        //console.log("EVENT_LOGIN_AUTO_SUCCESS", newData);
        let msgID = util.makeUUIDv4();

        loginData = {
          method: MsgIDList.EVENT_SOCKET_LOGIN,
          msgID: "AutoLogin2" + msgID,
          data: {
            comp_cd: newData.comp_cd,
            user_id: newData.user_id,
            user_pw: newData.user_pw,
          },
        };
        socket.sendSocketMessage(loginData, msgID, (beforeData, newData) => {
          //console.log(newData);
        });
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_AUTO_RECONNECTED,
        "AutoLogin"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "AutoLogin2"
      );
    };
  }, []);

  const loginToSocketServer = () => {
    let msgID = util.makeUUIDv4();
    //console.log("loginToSocketServer");
    loginData = {
      ...loginData,

      msgID: "AutoLogin2" + msgID,
    };
    socket.sendSocketMessage(loginData, msgID, (beforeData, newData) => {
      //console.log(newData);
    });
  };

  const loginToServer = () => {
    let msgID = util.makeUUIDv4();
    //console.log("loginToServer");
    //console.log(loginInfo);
    if (
      !loginInfo.hasOwnProperty("user_id") ||
      !loginInfo.hasOwnProperty("user_pw") ||
      !loginInfo.hasOwnProperty("comp_cd")
    ) {
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
      return;
    }
    loginMSG = {
      ...loginInfo,
    };

    socket.sendMessage(loginMSG, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log("newData", newData);
        if (newData["ret"]) {
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 300);
          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );
          localStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "AutoLogin",
            newData,
            () => {}
          );
        } else {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_FAIL,
            "AutoLogin",
            {},
            () => {}
          );
        }
      }
      this.loginErrCheck(newData);
    });
  };
  return <></>;
};

export default AutoLogin;
