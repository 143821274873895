import React, { useState, useRef, useEffect, useCallback } from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function ChangePWModal() {
  const [check_pwd_length, setCheck_pwd_length] = useState(false);
  const [check_pwd_re, setCheck_pwd_re] = useState(false);

  const $controllers = {
    USER_ID: useRef(),
    USER_PW_ORIGIN: useRef(),
    USER_PW: useRef(),
    USER_PW_RE: useRef(),
    check_pwd_length: check_pwd_length,
    check_pwd_re: check_pwd_re,
    USER_TEL: "",
  };

  const joinData = (key, data) => {
    let retData = {
      COMP_CD: "Y0001",
      USER_ID: $controllers.USER_ID.current.value,
      USER_PW_ORIGIN: $controllers.USER_PW_ORIGIN.current.value,
      USER_PW: $controllers.USER_PW.current.value,
      USER_PW_RE: $controllers.USER_PW_RE.current.value,
      check_pwd_length: $controllers.check_pwd_length,
      check_pwd_re: $controllers.check_pwd_re,
      USER_TEL: $controllers.USER_TEL,
    };

    if (key === "METHOD") {
      retData.METHOD = data;
    }

    return retData;
  };

  const handleChange = (key, text) => {
    // dispatch({ type: key, value: text });
    $controllers[key] = text;
  };

  const actionSave = () => {
    let updateData = joinData("METHOD", "CHANGE_PW");

    console.log(updateData);
    requestUserUpdate(updateData);
  };

  const updatePW = () => {
    let updateData = joinData("METHOD", "UPDATE_PW");
    console.log(updateData);
    requestUserUpdate(updateData);
  };

  const checkValidation = (updateData) => {
    //todo 다국어 작업

    // console.log(updateData);
    let regExp = /[01]([016789])[-](\d{4}|\d{3})[-]\d{4}$/g;
    // console.log(regExp.test(updateData.USER_TEL));

    if (updateData.METHOD === "CHANGE_PW") {
      if (updateData.USER_ID.trim() === "") {
        codeList.Modal.current.alert("이메일을 입력 해주세요.", () => {
          $controllers.USER_ID.current.focus();
        });
        return false;
      }
      if (updateData.check_pwd_length === false) {
        codeList.Modal.current.alert(
          "비밀번호를 6자리 이상 입력해주세요.",
          () => {}
        );
        return false;
      }
      if (updateData.check_pwd_re === false) {
        codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {});
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    if (checkValidation(updateData) === false) return;

    let socketMsg = {
      file: "/join/JS_join_gbts_private_find_pw.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    if (socketMsg.USER_PW) {
      socketMsg.USER_PW = util.buddibleEncrypt(socketMsg.USER_PW);
    }
    if (socketMsg.USER_PW_ORIGIN) {
      socketMsg.USER_PW_ORIGIN = util.buddibleEncrypt(socketMsg.USER_PW_ORIGIN);
    }
    console.log(socketMsg);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("newData", newData);
      if (newData) {
        switch (updateData.METHOD) {
          case "CHANGE_PW":
            if (newData["ret"] === true) {
              $controllers.USER_TEL = newData["returnData"][0].USER_TEL;
              updatePW();
            } else {
              if (newData["reason"] === "No data") {
                codeList.Modal.current.alert(
                  "이메일 또는 현재 비밀번호를 정확히 입력 해주세요.",
                  () => {}
                );
              } else {
                codeList.Modal.current.alert(
                  "죄송합니다. 오류가 발생 하였습니다.",
                  () => {}
                );
              }
            }
            break;

          case "UPDATE_PW":
            if (newData["ret"] === true) {
              codeList.Modal.current.alert(
                "비밀번호 수정이 완료 되었습니다.",
                () => {
                  window.location.replace("/");
                }
              );
            } else {
              codeList.Modal.current.alert(
                "죄송합니다. 오류가 발생 하였습니다.",
                () => {}
              );
            }
            break;
        }
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="changePWModal"
      tabIndex="-1"
      aria-labelledby="changePWModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title  m-0 p-0">비밀번호 변경</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="changePWModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="py-3 d-block">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    이메일{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      // placeholder="입력하신 이메일을 아이디로 사용합니다."
                      ref={$controllers.USER_ID}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    현재 비밀번호{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      ref={$controllers.USER_PW_ORIGIN}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    변경할 비밀번호{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      ref={$controllers.USER_PW}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          setCheck_pwd_length(true);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    변경할 비밀번호 확인{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      ref={$controllers.USER_PW_RE}
                      onChange={(e) => {
                        if (
                          e.target.value === $controllers.USER_PW.current.value
                        ) {
                          setCheck_pwd_re(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1"
              onClick={() => {
                actionSave();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
