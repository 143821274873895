/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useRef } from "react";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import CommonLoader from "../CommonLoader";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

const _mClassName = "CompInfoManage";

let comp_info = {
  COMP_CD: "",
  COMP_NUM: "",
  COMP_GB: "",
  COMP_NM: "",
  COMP_CEO_NM: "",
  COMP_ADDR: "",
  COMP_TEL: "",
  COMP_FAX: "",
  COMP_ZIP: "",
  COMP_C_NUM: "",
  ACTIVATION_GB: "",
  AUTH_IMP: "",
  AUTH_EXP: "",
  COMP_UPTE: "",
  COMP_JONG: "",
  COMP_MAIL: "",
  REG_ID: null,
  REG_DT: null,
  MOD_ID: "",
  MOD_DT: "",
  FWD_NO: "",
  LLS_CD: null,
  LLS_NM: null,
  COMP_GB_SUB2: null,
  SHIP_IMG_URL10: "",
  COMP_GB_NM: "",
  COMP_HOMEPAGE: "",
  COMP_DESC: "",
  COMP_LOGO: "",
  COMP_SLOGUN: "",
  COMP_TAG: "",
  COMP_EBOOK_URL: "",
};
let isLoading = true;
let isNewData = true;

export default function CompInfoManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [value, setValue] = useState("");

  const $controller = {
    COMP_HOMEPAGE: useRef(),
    COMP_TEL: useRef(),
    COMP_MAIL: useRef(),
    COMP_FAX: useRef(),
    COMP_ADDR: useRef(),
    COMP_TAG: useRef(),
    COMP_SLOGUN: useRef(),
    COMP_EBOOK_URL: useRef(),
  };

  const $quillRef = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      SELECT_COMP_INFO();
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  //회사 기본정보 받아오기
  const SELECT_COMP_INFO = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_comp_list.php",
      msgID: msgID,
      data: [],
      COMP_CD: loginInfo.comp_cd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          SELECT_CUSTOM_COMP_INFO({
            ...newData["returnData"][0],
            COMP_HOMEPAGE: "",
            COMP_DESC: "",
            COMP_LOGO: "",
            COMP_ADDR: "",
            COMP_TEL: "",
            COMP_FAX: "",
            COMP_SLOGUN: "",
            COMP_TAG: "",
            COMP_EBOOK_URL: "",
          });
        }
      }
    });
  };

  //회사 커스텀 정보 받아오기
  const SELECT_CUSTOM_COMP_INFO = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_list.php",
      msgID: msgID,
      data: [],
      COMP_CD: loginInfo.comp_cd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          SELECT_CUSTOM_COMP_DESC({ ...data, ...newData["returnData"][0] });
        }
      }
    });
  };

  //회사 소개글 가져오기
  const SELECT_CUSTOM_COMP_DESC = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_desc_select.php",
      msgID: msgID,
      data: [],
      COMP_CD: data.COMP_CD,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let info = {
            ...data,
          };
          if (newData["returnData"].length) {
            info = {
              ...info,
              ...newData["returnData"][0],
            };
            setValue(info.COMP_DESC);
          }
          comp_info = { ...info };
          isNewData = newData["returnData"].length === 0;
          isLoading = false;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //회사 로고이미지 업로드 후 처리
  const handleLogoImageUpload = (value) => {
    if (value.ret) {
      let file = `${value.fileName}?${value.realFileName}`;
      comp_info = {
        ...comp_info,
        COMP_LOGO: file,
      };
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생하였습니다. 잠시후 다시 시도해주세요"
      );
    }
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      uploadCallback(file[0]);
    };
  };
  //파일첨부 업데이트
  const uploadCallback = async (file) => {
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", "gbts/companyInfo");

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const range = $quillRef.current?.getEditor().getSelection()?.index;
      if (range !== null && range !== undefined) {
        let quill = $quillRef.current?.getEditor();

        quill?.setSelection(range, 1);
        quill?.clipboard.dangerouslyPasteHTML(
          range,
          `<img style="width:100%;" width='100%'  src=${response.data["url"]} alt="이미지 태그가 삽입됩니다." />`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  //저장하기
  const actionSave = () => {
    if (comp_info === null) {
      codeList.Modal.current.alert(
        "저장중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요"
      );
    }
    let updateData = {};
    for (let key in comp_info) {
      if ($controller.hasOwnProperty(key) && $controller[key].current) {
        updateData[key] = $controller[key].current.value;
      }
    }
    updateData.COMP_CD = comp_info.COMP_CD;
    updateData.COMP_LOGO = comp_info.COMP_LOGO;
    updateData.COMP_DESC = value;
    INSERT_COMPANY_CUSTOM_INFO(updateData);
  };

  //회사 custom 정보 저장
  const INSERT_COMPANY_CUSTOM_INFO = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("회사정보가 정상적으로 저장되었습니다.");
          isNewData = false;
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "데이터 변경중에 오류가 발생하였습니다."
          );
        }
      }
    });
  };

  //로고이미지 포멧셋
  const handleLogoImage = (img) => {
    let url = img || "";
    if (url.indexOf("?")) {
      url = url.split("?")[0];
    }
    return url;
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div id="comp_info_header" className="content">
        <div className="container-florder_uid p-0">
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h3 className="mb-0">
                <strong>회사 정보 관리</strong>
              </h3>
              <p className="mb-2">
                업체검색에 노출되는 회사 정보를 수정합니다.
              </p>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => actionSave()}
              >
                저장하기
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="card" style={{ maxWidth: 500 }}>
                <button
                  className="btn btn-dark rounded-circle position-fixed"
                  style={{ width: 50, height: 50, bottom: 20, left: 750 }}
                  onClick={() => actionSave()}
                >
                  <i className="fa-regular fa-floppy-disk" />
                </button>
                <div className="p-3">
                  <div className=" d-flex flex-row justify-content-between align-items-center border-bottom">
                    <h5 className="font-weight-bold text-info pb-2">
                      {comp_info.COMP_NM} 상세 정보
                    </h5>
                    <h5 className="mb-0 pb-2 cursor_pointer">&times;</h5>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center pt-3 pb-5 border-bottom">
                    <div
                      className="d-flex flex-row justify-content-center align-items-center rounded mb-4 border bg-light position-relative"
                      style={{
                        width: 100,
                        height: 100,
                        background: `url(${handleLogoImage(
                          comp_info.COMP_LOGO || comp_info.SHIP_IMG_URL10
                        )}) 50% 50%/contain no-repeat`,
                      }}
                    >
                      <div
                        className=" position-absolute"
                        style={{ top: 70, left: 70 }}
                      >
                        <UploadImageToS3WithPhp
                          region="gbts/companyInfo"
                          callback={handleLogoImageUpload}
                        >
                          <span
                            className="rounded-circle btn btn-sm btn-secondary d-flex flex-row justify-content-center align-items-center"
                            style={{ width: 40, height: 40 }}
                          >
                            <i className="fa-solid fa-camera" />
                          </span>
                        </UploadImageToS3WithPhp>
                      </div>
                      {!comp_info.COMP_LOGO && !comp_info.SHIP_IMG_URL10 && (
                        <p className="font-weight-bold text-secondary text-center">
                          Logo
                          <br /> 이미지 X
                        </p>
                      )}
                    </div>
                    <h3 className="font-weight-bold mb-3">
                      {comp_info.COMP_NM}
                    </h3>
                  </div>
                  <div className="px-2 py-5">
                    <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
                      <h4 className="mb-0 font-weight-bold">업체 정보</h4>
                      <h4 className="mb-0 cursor_pointer">
                        <i className={"fa-solid fa-chevron-up"} />
                      </h4>
                    </div>

                    <div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          부호
                        </div>
                        <div className="col-8 m-0 p-0">
                          {comp_info.COMP_C_NUM}
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          업체명
                        </div>
                        <div className="col-8 m-0 p-0">
                          {comp_info.COMP_NM}{" "}
                          {comp_info.COMP_NM_ENG &&
                            `(${comp_info.COMP_NM_ENG})`}
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          사업자등록번호
                        </div>
                        <div className="col-8 m-0 p-0">
                          {comp_info.COMP_NUM
                            ? util.getAutoHyphenBizID(comp_info.COMP_NUM)
                            : "-"}
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          홈페이지
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_HOMEPAGE}
                            defaultValue={comp_info.COMP_HOMEPAGE}
                            placeholder="홈페이지 주소를 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          대표자명
                        </div>
                        <div className="col-8 m-0 p-0">
                          {comp_info.COMP_CEO_NM ? comp_info.COMP_CEO_NM : "-"}
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          대표전화
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_TEL}
                            defaultValue={comp_info.COMP_TEL}
                            placeholder="회사 대표연락처를 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          팩스번호
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_FAX}
                            defaultValue={comp_info.COMP_FAX}
                            placeholder="회사 팩스번호를 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          이메일
                        </div>

                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_MAIL}
                            defaultValue={comp_info.COMP_MAIL}
                            placeholder="대표 이메일 주소를 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          주소
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_ADDR}
                            defaultValue={comp_info.COMP_ADDR}
                            placeholder="회사 주소를 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          태그(쉼표 구분)
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_TAG}
                            defaultValue={comp_info.COMP_TAG}
                            placeholder="ex) 유럽, 동남아시아, LCL"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          슬로건
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_SLOGUN}
                            defaultValue={comp_info.COMP_SLOGUN}
                            placeholder="슬로건을 입력해주세요"
                          />
                        </div>
                      </div>
                      <div className="row m-0 py-3 border-bottom">
                        <div className="col-4 m-0 p-0 font-weight-bold">
                          E-Book Service
                        </div>
                        <div className="col-8 m-0 p-0">
                          <input
                            type="text"
                            className="form-control m-0 bg-light"
                            ref={$controller.COMP_EBOOK_URL}
                            defaultValue={comp_info.COMP_EBOOK_URL}
                            placeholder="URL을 입력해주세요"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 pb-5">
                    <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
                      <h4 className="mb-0 font-weight-bold">업체 소개</h4>
                      <h4 className="mb-0 cursor_pointer">
                        <i
                          className={`${"fa-solid fa-chevron-up"} cursor-pointer`}
                        />
                      </h4>
                    </div>

                    <div className="py-2 ">
                      <ReactQuill
                        className="bg-white w-100"
                        modules={modules}
                        formats={formats}
                        ref={$quillRef}
                        value={value}
                        onChange={setValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
