import React from "react";
import "../lib/stylesheet/Loading.css";

export default function CommonLoader() {
  return (
    <div className="w-100 h-100 position-relative text-secondary">
      <div className="loading h5">
        <i className="fas fa-spinner" />
        <p className="mt-1">Load Data</p>
      </div>
    </div>
  );
}
