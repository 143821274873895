import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { ResponsiveBar } from "@nivo/bar";
import SectionTitle from "../../SectionTitle";
import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { ChartDefaultOption } from "../../../../lib/CommonUI";
import TableBody from "@material-ui/core/TableBody";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const REG_NUM = /[0-9]{6}/gi;
export default React.memo(VATReportingStatus);
const dateFormat = (str) => {
  let list = str.split("");
  let date = `${list.slice(2, 4).join("")}년 ${list.slice(4, 6).join("")}월`;
  return date;
};

function VATReportingStatus(props) {
  const { compInfo, isExistData, isPrint } = props;
  let purchases = null;
  let sales = null;
  let tableData = null;
  let mappedData = {
    PURCHASE_COST: 0,
    PURCHASE_COST_DATE: 0,
    PURCHASE_COST_LAST: 0,
    PURCHASE_COST_LAST_DATE: 0,
    SALES_COST: 0,
    SALES_COST_DATE: 0,
    SALES_COST_LAST: 0,
    SALES_COST_LAST_DATE: 0,
  };

  if (isExistData && compInfo !== null) {
    // 매입액
    if (compInfo["2000201"].length > 0) {
      let [purchaseA, purchaseB] = compInfo["2000201"];

      purchaseA.item_date = REG_NUM.test(purchaseA.item_nm)
        ? Number(purchaseA.item_nm.match(REG_NUM)[0])
        : "";

      if (purchaseB) {
        purchaseB.item_date = REG_NUM.test(purchaseB.item_nm)
          ? Number(purchaseB.item_nm.match(REG_NUM)[0])
          : "";
      }

      mappedData = {
        ...mappedData,
        PURCHASE_COST: Number(
          purchaseB && purchaseA.item_date > purchaseB.item_date
            ? purchaseB.item_val
            : purchaseA.item_val
        ),
        PURCHASE_COST_DATE:
          purchaseB && purchaseA.item_date > purchaseB.item_date
            ? purchaseB.item_date
            : purchaseA.item_date,
        PURCHASE_COST_LAST: Number(
          purchaseB && purchaseA.item_date > purchaseB.item_date
            ? purchaseB.item_val
            : purchaseA.item_val
        ),
        PURCHASE_COST_LAST_DATE:
          purchaseB && purchaseA.item_date > purchaseB.item_date
            ? purchaseB.item_date
            : purchaseA.item_date,
      };
    }
    // 매출액
    if (compInfo["2000202"].length > 0) {
      let [salesA, salesB] = compInfo["2000202"];
      salesA.item_date = REG_NUM.test(salesA.item_nm)
        ? Number(salesA.item_nm.match(REG_NUM)[0])
        : "";
      if (salesB) {
        salesB.item_date = REG_NUM.test(salesB.item_nm)
          ? Number(salesB.item_nm.match(REG_NUM)[0])
          : "";
      }
      mappedData = {
        ...mappedData,
        SALES_COST: Number(
          salesB && salesA.item_date > salesB.item_date
            ? salesB.item_val
            : salesA.item_val
        ),
        SALES_COST_DATE:
          salesB && salesA.item_date > salesB.item_date
            ? salesB.item_date
            : salesA.item_date,
        SALES_COST_LAST: Number(
          salesB && salesA.item_date > salesB.item_date
            ? salesB.item_val
            : salesA.item_val
        ),
        SALES_COST_LAST_DATE:
          salesB && salesA.item_date > salesB.item_date
            ? salesB.item_date
            : salesA.item_date,
      };
    }

    mappedData.PURCHASE_COST_DATE = dateFormat(
      mappedData.PURCHASE_COST_DATE + ""
    );
    mappedData.PURCHASE_COST_LAST_DATE = dateFormat(
      mappedData.PURCHASE_COST_LAST_DATE + ""
    );
    mappedData.SALES_COST_DATE = dateFormat(mappedData.SALES_COST_DATE + "");
    mappedData.SALES_COST_LAST_DATE = dateFormat(
      mappedData.SALES_COST_LAST_DATE + ""
    );

    purchases = [
      {
        name: mappedData["PURCHASE_COST_LAST_DATE"],
        CNT: mappedData["PURCHASE_COST_LAST"],
        key: mappedData["PURCHASE_COST_LAST_DATE"],
      },
      {
        name: mappedData["PURCHASE_COST_DATE"],
        CNT: mappedData["PURCHASE_COST"],
        key: mappedData["PURCHASE_COST_DATE"],
      },
    ];
    sales = [
      {
        name: mappedData["SALES_COST_LAST_DATE"],
        CNT: mappedData["SALES_COST_LAST"],
        key: mappedData["SALES_COST_LAST_DATE"],
      },
      {
        name: mappedData["SALES_COST_DATE"],
        CNT: mappedData["SALES_COST"],
        key: mappedData["SALES_COST_DATE"],
      },
    ];
    tableData = [
      {
        key: "purchase",
        headName: "매입액",
        current: mappedData["PURCHASE_COST"],
        last: mappedData["PURCHASE_COST_LAST"],
      },
      {
        key: "sales",
        headName: "매출액",
        current: mappedData["SALES_COST"],
        last: mappedData["SALES_COST_LAST"],
      },
    ];
  }

  const headCells = [
    {
      field: "headName",
      headerName: "항목",
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {params.data}
          </span>
        );
      },
    },
    {
      field: "last",
      headerName: mappedData["SALES_COST_LAST_DATE"],
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}
          </span>
        );
      },
    },
    {
      field: "current",
      headerName: mappedData["SALES_COST_DATE"],
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}
          </span>
        );
      },
    },
  ];

  return (
    <div className="mb-5 ">
      <SectionTitle title="부가세 신고현황" />
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flex-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar
              data={purchases}
              title={"분기매입액"}
              colors={["#7f7f7f", "#ff0000"]}
              isPrint={isPrint}
            />
          </div>
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flex-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar
              data={sales}
              title={"분기매출액"}
              colors={["#7f7f7f", "#125186"]}
              isPrint={isPrint}
            />
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col-12 m-0 mb-1 p-0">
            <p className="text-right">단위 : 백만원</p>
          </div>
          <div className="col-12 m-0 p-0">
            <div className="border-top border-bottom border-freiscop">
              <TableContainer>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.field}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          style={{
                            background: "rgba(0,32,96,0.1)",
                            textAlign: "center",
                          }}
                        >
                          {headCell.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData &&
                      tableData.map((row, index) => {
                        return (
                          <TableRow role="checkbox" key={row.key}>
                            {headCells.map((headCell) => (
                              <TableCell
                                key={headCell.field}
                                padding={
                                  headCell.disablePadding ? "none" : "normal"
                                } //패딩 값 부여 여부
                                style={{ textAlign: "center" }}
                              >
                                {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
                                  ? headCell.renderCell({
                                      data: row[headCell.field],
                                      currentData: row,
                                      currentHeader: headCell.field,
                                      headCells: headCells,
                                    })
                                  : headCell.hasOwnProperty("valueGetter")
                                  ? headCell.valueGetter({
                                      data: row[headCell.field],
                                      currentData: row,
                                      currentHeader: headCell.field,
                                      headCells: headCells,
                                    })
                                  : row[headCell.field]}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MyResponsiveBar = ({ data, title, colors, isPrint }) => {
  if (data === null) {
    return <p>{title}에 대한 데이터가 존재하지 않습니다.</p>;
  }
  const widthProps = isPrint ? { width: 300 } : {};
  return (
    <ResponsiveBar
      {...ChartDefaultOption.ResponsiveBar}
      margin={{ top: 30, right: 40, bottom: 50, left: 40 }}
      data={data}
      keys={["CNT"]}
      indexBy="name"
      colorBy={"indexValue"}
      legends={[]}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: title,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      colors={[...colors]}
      labelTextColor={"#ffffff"}
      valueFormat={(v) => {
        return util.addCommas(v);
      }}
      {...widthProps}
      tooltip={({
        id,
        value,
        formattedValue,
        index,
        indexValue,
        data,
        color,
      }) => (
        <div
          style={{
            padding: 12,
            color: "white",
            background: "#222222",
          }}
        >
          <strong>
            {indexValue} : {util.addCommas(formattedValue)}
          </strong>
        </div>
      )}
    />
  );
};
