import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-dropdown-select";

import Loading from "../../../lib/Loading";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleLogiWorkReserveMemo";

const transfer_type = [
  {
    type: "LCL",
    defaultValue:
      "[BOOKING 정보]\n" +
      "- 품목: \n" +
      "- 포장 수:\n" +
      "- 중량: \n" +
      "- CBM: \n" +
      "- 특이사항:\n\n\n" +
      "※ BOOKING이 완료되면 이메일과 로지톡으로 전송됩니다\n\n\n",
  },
  {
    type: "FCL",
    defaultValue:
      "[BOOKING 정보]\n" +
      "- 품목: \n" +
      "- 수량(단위포함):\n" +
      "- 도어일시:\n" +
      "- 도어장소:\n" +
      "- 담당자:\n" +
      "- 특이사항: \n\n\n" +
      "※ BOOKING이 완료되면 이메일과 로지톡으로 전송됩니다\n\n\n",
  },
  {
    type: "AIR",
    defaultValue:
      "[BOOKING 정보]\n" +
      "- 품목: \n" +
      "- 수량(단위포함):\n" +
      "- 입고예정일자:\n" +
      "- 특이사항: \n\n\n" +
      "※ BOOKING이 완료되면 이메일과 로지톡으로 전송됩니다\n\n\n",
  },
];

const today = util.todayDate("YYYY-MM-DD");
let login_info = null;
let sch_info = null;

export default function ScheduleLogiWorkReserveMemo() {
  const params = useParams();
  const { type, exp_imp } = params;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [portList, setPortList] = useState([]);
  const [airportList, setAirportList] = useState([]);
  const [transferType, setTransferType] = useState(
    type ? type.toUpperCase() : "LCL"
  );
  const [POL_CODE, setPOL_CODE] = useState([]);
  const [POD_CODE, setPOD_CODE] = useState([]);
  const [desc, setDesc] = useState(
    transfer_type.find((item) => item.type === type.toUpperCase())
      .defaultValue || transfer_type[0].defaultValue
  );
  const prevSelectedOptionRef = useRef();
  const controller = {
    // rt_IDX: useRef(),
    rt_COMPCD: useRef(),
    rt_USERID: useRef(),
    rt_POL_CODE: useRef(),
    rt_POD_CODE: useRef(),
    rt_REQUEST_DATE: useRef(),
    rt_DESC: useRef(),
    rt_EXP_IMP: useRef(),
    // rt_ANSWER: useRef(),
    // rt_ANSWER_USERID: useRef(),
    // rt_CREATE_DATE: useRef(),
    // rt_UPDATE_DATE: useRef(),
    // rt_STATUS: useRef(),
  };

  useEffect(() => {
    if (prevSelectedOptionRef.current) {
      //항공과 선박이 바뀌면 POL POD코드를 변경하기위한 펑션
      if (
        (prevSelectedOptionRef.current === "AIR" &&
          (transferType === "LCL" || transferType === "FCL")) ||
        ((prevSelectedOptionRef.current === "LCL" ||
          prevSelectedOptionRef.current === "FCL") &&
          transferType === "AIR")
      ) {
        setPOL_CODE([]);
        setPOD_CODE([]);
      }
    }

    // 이후 렌더링에 대비하여 이전 값을 현재 값으로 업데이트 합니다.
    prevSelectedOptionRef.current = transferType;
  }, [transferType]);

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      login_info = userData["returnData"][0];
      setIsNeedUpdate(!isNeedUpdate);
    }

    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setPortList(newV);
        }
      );
    } else {
      setPortList(codeList.codeCountryFullName);
    }

    if (codeList.codeAirCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
        _mClassName,
        (oldV, newV) => {
          setAirportList(newV);
        }
      );
    } else {
      setAirportList(codeList.codeAirCountryFullName);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          login_info = n["returnData"][0];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    );

    return () => {
      sch_info = null;
      localStorage.removeItem("logiwork_booking_info");
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (portList.length > 0 && airportList.length > 0) {
      handleScheduleFCLData();
      setIsLoading(false);
    }
  }, [portList, airportList]);

  const handleScheduleFCLData = () => {
    let data = localStorage.getItem("logiwork_booking_info");
    if (data) {
      data = JSON.parse(util.buddibleDecrypt(data));
      sch_info = data;
      let sch_note =
        `[요청 스케줄 정보]\n` +
        ` - 업체명 : ${data.COMP_NM}\n` +
        ` - 서류마감일 : ${data.sm_DOC_CLOSE}\n` +
        ` - 출발/도착 : ${data.sm_POL_NAME} - ${data.sm_POD_NAME} \n` +
        ` - 출발/도착 일정 : ${data.sm_DEPARTURE_DATE} - ${data.sm_ARRIVE_DATE} \n` +
        `\n` +
        `[운임정보]\n`;

      sch_note +=
        `- 20\` CONTAINER\n` +
        `  OCF : USD ${data.SP_20_INCH}\n` +
        `  PREPAID : `;

      if (data.hasOwnProperty("prepaid")) {
        data.prepaid.forEach((item, index) => {
          sch_note += ` ${item.key} ${util.addCommas(item.container20)} `;
        });
        sch_note += "\n";
      } else {
        sch_note += "\n";
      }

      sch_note += `  COLLECT : `;
      if (data.hasOwnProperty("collect")) {
        data.collect.forEach((item, index) => {
          sch_note += ` ${item.key} ${util.addCommas(item.container20)} `;
        });
      }
      sch_note += "\n";

      sch_note +=
        `- 40\` CONTAINER\n` +
        `  OCF : USD ${data.SP_40_INCH}\n` +
        `  PREPAID : `;

      if (data.hasOwnProperty("prepaid")) {
        data.prepaid.forEach((item, index) => {
          sch_note += ` ${item.key} ${util.addCommas(item.container40)} `;
        });
        sch_note += "\n";
      } else {
        sch_note += "\n";
      }

      sch_note += `  COLLECT : `;
      if (data.hasOwnProperty("collect")) {
        data.collect.forEach((item, index) => {
          sch_note += ` ${item.key} ${util.addCommas(item.container40)} `;
        });
        sch_note += "\n";
      }

      sch_note += "\n\n";
      setPOL_CODE([
        portList.find((item) => item.CustomCode === data.sm_POL_CODE),
      ]);
      setPOD_CODE([
        portList.find((item) => item.CustomCode === data.sm_POD_CODE),
      ]);
      setDesc(desc + sch_note);
    }
  };

  const submit = () => {
    if (!login_info) {
      let msg = "로그인이 필요한 서비스입니다.";
      closeWindow(msg);
    }

    let check_msg = checkValidation();
    if (check_msg) {
      codeList.Modal.current.alert(check_msg);
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logiwork/request_transit/JS_request_transit_insert.php",
      msgID: msgID,
      rt_COMP_NM: login_info.comp_nm,
      rt_USER_NM: login_info.user_nm,
      rt_POL_NAME: POL_CODE[0].NewFullName,
      rt_POD_NAME: POD_CODE[0].NewFullName,
    };
    for (let key in controller) {
      socketMsg[key] = controller[key].current.value;
    }
    socketMsg["rt_TYPE"] = transferType;
    setIsLoading(true);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      setIsLoading(false);
      if (newData && newData["ret"]) {
        window.opener.postMessage("isListUpdate", "*");
        closeWindow("예약요청이 정상처리되었습니다.");
      } else {
        codeList.Modal.current.alert(
          "오류가 발생하였습니다. 다시 시도해주세요"
        );
      }
    });
  };

  const checkValidation = () => {
    let msg = "";
    if (controller.rt_COMPCD.current.value.trim() === "") {
      msg = "로그인이 필요한 서비스입니다.";
      return msg;
    }
    if (controller.rt_USERID.current.value.trim() === "") {
      msg = "로그인이 필요한 서비스입니다.";
      return msg;
    }
    if (controller.rt_POL_CODE.current.value.trim() === "") {
      msg = "출발예정지를 선택해주세요";
      return msg;
    }
    if (controller.rt_POD_CODE.current.value.trim() === "") {
      msg = "도착예정지를 선택해주세요";
      return msg;
    }
    if (controller.rt_REQUEST_DATE.current.value.trim() === "") {
      msg = "출발희망일자을 선택해주세요";
      return msg;
    }
    if (controller.rt_DESC.current.value.trim() === "") {
      msg = "운송화물에 대한 요청사항을 작성해주세요";
      return msg;
    }

    return false;
  };

  const closeWindow = (msg) => {
    codeList.Modal.current.alert(msg, () => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="p-3 vh-100 bg-white d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center pb-3 border-bottom mb-3">
          <p className="h5 font-weight-bold p-0 m-0">BOOKING 요청</p>
          <div>
            {transfer_type.map((item) => {
              let btnClass = "ml-2 btn_1 outline";
              if (item.type === transferType) {
                btnClass = "ml-2 btn_1";
              }
              if (exp_imp === "IMP" && item.type === "AIR") return <></>;
              return (
                <button
                  key={item.type}
                  className={btnClass}
                  onClick={() => {
                    if (item.type === "FCL") {
                      handleScheduleFCLData();
                    } else {
                      sch_info = null;
                      setDesc(item.defaultValue);
                    }
                    setTransferType(item.type);
                  }}
                >
                  {item.type}
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex-grow-1 mb-3">
          {/*
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>관리번호</p>
            </div>
            <div className="col-8 m-0 p-0">
              <input
                className="form-control"
                type="text"
                placeholder="IDX"
                readOnly={true}
                ref={controller.rt_IDX}
              />
            </div>
          </div>
          */}
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>업체코드</p>
            </div>
            <div className="col-8 m-0 p-0">
              <input
                className="form-control"
                type="text"
                placeholder="업체코드"
                readOnly={true}
                defaultValue={login_info && login_info.comp_cd}
                ref={controller.rt_COMPCD}
              />
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>사용자 아이디</p>
            </div>
            <div className="col-8 m-0 p-0">
              <input
                className="form-control"
                type="text"
                placeholder="사용자 아이디"
                readOnly={true}
                defaultValue={login_info && login_info.user_id}
                ref={controller.rt_USERID}
              />
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>수출입 구분</p>
            </div>
            <div className="col-8 m-0 p-0">
              <select
                className="form-control"
                ref={controller.rt_EXP_IMP}
                defaultValue={exp_imp}
              >
                <option value="">-미선택-</option>
                <option value="EXP">수출</option>
                <option value="IMP">수입</option>
              </select>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>출발예정지</p>
            </div>
            <div className="col-8 m-0 p-0">
              <div className="form-control p-0 m-0">
                <input
                  className="d-none"
                  type="text"
                  readOnly={true}
                  ref={controller.rt_POL_CODE}
                  defaultValue={
                    sch_info && transferType === "FCL"
                      ? sch_info["sm_POL_CODE"]
                      : ""
                  }
                />
                <Select
                  required
                  multi={false}
                  clearable={true}
                  options={transferType === "AIR" ? airportList : portList}
                  className="border-0"
                  name={"SearchName"}
                  sortBy={"NewFullName"}
                  searchBy={"SearchName"}
                  valueField={"City"}
                  values={POL_CODE}
                  placeholder="출발지를 선택해주세요"
                  labelField={"NewFullName"}
                  autoFocus={false}
                  onChange={(val) => {
                    let value = val.length > 0 ? val[0].CustomCode : "";
                    controller.rt_POL_CODE.current.value = value;
                    setPOL_CODE(val);
                  }}
                  itemRenderer={({
                    item,
                    itemIndex,
                    props,
                    state,
                    methods,
                  }) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            state.cursor === itemIndex
                              ? "#edf0f9"
                              : "transparent",
                        }}
                      >
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: "normal",
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontFamily: "Consolas",
                            }}
                          >
                            {item.CustomCode
                              ? "[" + item.CustomCode + "] "
                              : ""}
                          </b>

                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>도착예정지</p>
            </div>
            <div className="col-8 m-0 p-0">
              <div className="form-control p-0 m-0">
                <input
                  className="d-none"
                  type="text"
                  readOnly={true}
                  ref={controller.rt_POD_CODE}
                  defaultValue={
                    sch_info && transferType === "FCL"
                      ? sch_info["sm_POD_CODE"]
                      : ""
                  }
                />
                <Select
                  required
                  multi={false}
                  clearable={true}
                  options={transferType === "AIR" ? airportList : portList}
                  className="border-0"
                  name={"SearchName"}
                  sortBy={"NewFullName"}
                  searchBy={"SearchName"}
                  valueField={"City"}
                  placeholder="도착지를 선택해주세요"
                  labelField={"NewFullName"}
                  autoFocus={false}
                  values={POD_CODE}
                  onChange={(val) => {
                    let value = val.length > 0 ? val[0].CustomCode : "";
                    controller.rt_POD_CODE.current.value = value;
                    setPOD_CODE(val);
                  }}
                  itemRenderer={({
                    item,
                    itemIndex,
                    props,
                    state,
                    methods,
                  }) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            state.cursor === itemIndex
                              ? "#edf0f9"
                              : "transparent",
                        }}
                      >
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: "normal",
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontFamily: "Consolas",
                            }}
                          >
                            {item.CustomCode
                              ? "[" + item.CustomCode + "] "
                              : ""}
                          </b>

                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col-4 m-0 p-0">
              <p>출발희망일자</p>
            </div>
            <div className="col-8 m-0 p-0">
              <input
                type="date"
                className="form-control"
                ref={controller.rt_REQUEST_DATE}
                min={today}
                defaultValue={
                  sch_info && transferType === "FCL"
                    ? sch_info.sm_DEPARTURE_DATE
                    : ""
                }
              />
            </div>
          </div>
          <textarea
            className="form-control"
            rows={15}
            placeholder="운송요청할 품목의 품명, 수량, 단위, 요청사항들을 상세히 적어주세요"
            ref={controller.rt_DESC}
            onChange={(e) => setDesc(e.target.value)}
            value={desc}
          />
        </div>

        <div className="text-right">
          <button className="btn_1" onClick={submit}>
            제출
          </button>
        </div>
      </div>
    </>
  );
}
