import React, { useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "LogiTalkLandingCarousel";

let loginInfo = null;
let isMobile_backup = false;
let isVideo_backup = false;

export default function LogiTalkLandingCarousel(props) {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isVideoMobile, setIsVideoMobile] = useState(false);

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            loginInfo = n["returnData"][0];
            setIsNeedUpdate(!isNeedUpdate);
          }
        }
      }
    );
  })();

  useEffect(() => {
    handleIsMobile();
    window.addEventListener("resize", handleIsMobile);

    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData["returnData"].length) {
        loginInfo = userData["returnData"][0];
        setIsNeedUpdate(!isNeedUpdate);
      }
    }

    return () => {
      window.removeEventListener("resize", handleIsMobile);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  //모바일 화면 핸들러
  const handleIsMobile = () => {
    let mobile;
    let videoMobile;
    if (window.innerWidth > 1200) {
      mobile = false;
    } else {
      mobile = true;
    }
    if (window.innerWidth > 1420) {
      videoMobile = false;
    } else {
      videoMobile = true;
    }
    if (isMobile_backup !== mobile) {
      isMobile_backup = mobile;
      setIsMobile(mobile);
    }
    if (isVideo_backup !== videoMobile) {
      isVideo_backup = videoMobile;
      setIsVideoMobile(videoMobile);
    }
  };

  //로지톡 접속하기
  const openLogiTalk = () => {
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인이 필요한 서비스입니다.");
      return;
    }

    window.open("https://www.gbts.co.kr/LogiTalk", "_blank");
  };

  //로지톡 알아보기
  const openLogiTalkDetail = () => {
    window.open(
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/GBTS+%EB%A1%9C%EC%A7%80%ED%86%A1+%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C+%EB%B0%8F+%EC%82%AC%EC%9A%A9%EC%95%88%EB%82%B4.pdf",
      "_blank"
    );
  };

  //로지톡톡 바로가기
  const openLogiTalkTalk = () => {
    window.open("https://talktalk.gbts.co.kr/?site=GBTS", "_blank");
  };

  //로지톡톡 알아보기
  const openLogiTalkTalkDetail = () => {
    window.open(
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/%EB%A1%9C%EC%A7%80%ED%86%A1%ED%86%A1+%EC%A0%9C%EC%95%88%EC%84%9C.pdf",
      "_blank"
    );
  };

  const downloadManual = () => {
    util.downloadAs(
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/GBTS+%EB%A1%9C%EC%A7%80%ED%86%A1+%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C+%EB%B0%8F+%EC%82%AC%EC%9A%A9%EC%95%88%EB%82%B4.pdf",
      "로지톡_설명서.pdf",
      (value) => {
        if (!value.ret) {
          codeList.Modal.current.alert(
            "설명서 다운로드중 오류가 발생하셨습니다. 새로 고침 후 다시 시도해주세요"
          );
          return;
        }
        util.downloadAs(
          "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/%EB%A1%9C%EC%A7%80%ED%86%A1%ED%86%A1+%EC%A0%9C%EC%95%88%EC%84%9C.pdf",
          "로지톡톡_설명서.pdf",
          (value) => {
            if (!value.ret) {
              codeList.Modal.current.alert(
                "설명서 다운로드중 오류가 발생하셨습니다. 새로 고침 후 다시 시도해주세요"
              );
              return;
            }
          }
        );
      }
    );
  };

  return (
    <div className="position-relative">
      <div
        className="overflow-hidden d-flex flex-column align-items-center justify-content-end bg-dark"
        style={{
          height: isMobile ? "100vh" : 800,
          maxHeight: isMobile ? 700 : "unset",
        }}
      >
        <video
          autoPlay={true}
          loop={true}
          playsInline={true}
          muted={true}
          height={isVideoMobile ? "100%" : ""}
          width={!isVideoMobile ? "100%" : ""}
          style={{
            filter:
              "brightness(100%) contrast(100%) saturate(100%) grayscale(0%) invert(0%) sepia(0%) hue-rotate(0deg) blur(0px)",
          }}
          id="LogiTalkLandingCarouselVideo"
        >
          <source
            src="/video/pexels-jack-sparrow-5977270.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag. I suggest you upgrade
          your browser.
        </video>
      </div>

      <div
        className="m-0 p-0 position-absolute h-100 w-100"
        style={{
          top: 0,
          left: 0,
          background: !isMobile
            ? "linear-gradient(75deg, rgba(0,0,0,0.8) 0%, rgba(100,100,100,0.8) 40%, rgba(200,200,200,0.8) 60%, rgba(255,255,255,0.8) 100%)"
            : "linear-gradient(160deg, rgba(0,0,0,0.8) 0%, rgba(100,100,100,0.8) 30%, rgba(200,200,200,0.8) 50%, rgba(255,255,255,0.8) 100%)",
        }}
      >
        <div className="container-xl ">
          <div className="row">
            <div className="col-12 col-xl-6 pt-3 pl-3 px-sm-4 py-sm-5 text-white">
              <p className="h2 m-0 mb-1 BM-DH">
                디지털 물류기업 특화 메신저의 탄생
              </p>
              <p className="h1 m-0 mb-1 mb-sm-3 text-gold BM-DH">
                로지톡(Logitalk)
              </p>
              <p className="h4 font-weight-bold NNS">
                로지톡을 PC와 APP에서 함께 이용하세요
              </p>
              <div className="NNS">
                <button
                  className="d-sm-none d-inline-block btn btn-sm btn-outline-light font-weight-bold mr-2 mb-2"
                  style={{ borderWidth: 2 }}
                  onClick={() => openLogiTalk()}
                >
                  <span className="text-gold">로지톡</span> 접속하기
                  <i className="fa-solid fa-arrow-right-to-bracket" />
                </button>
                <button
                  className="d-sm-none d-inline-block btn btn-sm btn-outline-light font-weight-bold mr-2 mb-2"
                  style={{ borderWidth: 2 }}
                  onClick={() => {
                    openLogiTalkDetail();
                  }}
                >
                  <span className="text-gold">로지톡</span> 알아보기
                </button>
                <button
                  className="d-none d-sm-inline-block btn btn-outline-light font-weight-bold mr-2 mb-2"
                  style={{ borderWidth: 2 }}
                  onClick={() => openLogiTalk()}
                >
                  <span className="text-gold">로지톡</span> 접속하기
                  <i className="fa-solid fa-arrow-right-to-bracket" />
                </button>
                <button
                  className="d-none d-sm-inline-block btn btn-outline-light font-weight-bold mr-2 mb-2"
                  style={{ borderWidth: 2 }}
                  onClick={() => {
                    openLogiTalkDetail();
                  }}
                >
                  <span className="text-gold">로지톡</span> 알아보기
                </button>
              </div>
            </div>
            <div className="col-12 col-xl-6 pt-3 px-sm-4 pt-sm-4 pb-0 pt-xl-5 pb-xl-5 text-right d-flex flex-column justify-content-between">
              <div>
                <p className="h2 m-0 mb-1 BM-DH">
                  고객맞춤 담당자 1:1 상담채널 서비스
                </p>
                <p className="h1 m-0 mb-1 mb-sm-3 text-gbts BM-DH">
                  로지톡톡(LogiT-talk)
                </p>
                <p className="h4 font-weight-bold NNS">
                  로지톡톡으로 고객에게 한 걸음 더 다가가세요
                </p>
                <div className="NNS">
                  <button
                    className="d-sm-none btn btn-sm btn-outline-dark font-weight-bold mb-2"
                    style={{ borderWidth: 2 }}
                    onClick={() => openLogiTalkTalk()}
                  >
                    <span className="text-gbts">로지톡톡</span> 바로가기
                    <i className="fa-solid fa-arrow-right-to-bracket" />
                  </button>
                  <button
                    className="d-sm-none btn btn-sm btn-outline-dark ml-2 font-weight-bold mb-2"
                    style={{ borderWidth: 2 }}
                    onClick={() => {
                      openLogiTalkTalkDetail();
                    }}
                  >
                    <span className="text-gbts">로지톡톡</span> 알아보기
                  </button>
                  <button
                    className="d-none d-sm-inline-block btn btn-outline-dark font-weight-bold mb-2"
                    style={{ borderWidth: 2 }}
                    onClick={() => openLogiTalkTalk()}
                  >
                    <span className="text-gbts">로지톡톡</span> 바로가기
                    <i className="fa-solid fa-arrow-right-to-bracket" />
                  </button>
                  <button
                    className="d-none d-sm-inline-block btn btn-outline-dark ml-2 font-weight-bold mb-2"
                    style={{ borderWidth: 2 }}
                    onClick={() => {
                      openLogiTalkTalkDetail();
                    }}
                  >
                    <span className="text-gbts">로지톡톡</span> 알아보기
                  </button>
                </div>
              </div>
              <div className="d-block">
                <div className="p-0 p-sm-4 p-xl-0" />
                <div className="mt-2 mt-sm-5 pt-0 pt-sm-3 pt-xl-0 d-flex flex-row flex-xl-column justify-content-end align-items-center align-items-xl-end d-none">
                  <button
                    className=" btn btn-primary bg-gold border-0 rounded-circle d-flex flex-row justify-content-center align-items-center m-3 mr-xl-0"
                    style={{ width: 80, height: 80 }}
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/%EB%A1%9C%EC%A7%80%ED%86%A1-logitalk/id1626764819"
                      );
                    }}
                  >
                    <div>
                      {" "}
                      <span className="h4 mb-0">
                        <i className="fa-brands fa-app-store-ios" />
                      </span>
                      <p style={{ fontSize: "0.8em" }}>IOS 다운</p>
                    </div>
                  </button>
                  <button
                    className="btn btn-secondary border-0 rounded-circle d-flex flex-row justify-content-center align-items-center"
                    style={{ width: 80, height: 80 }}
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=kr.co.gbts.logitalk"
                      );
                    }}
                  >
                    <div>
                      <span className="h4 mb-0">
                        <i className="fa-brands fa-google-play" />
                      </span>
                      <p style={{ fontSize: "0.8em" }}>구글 다운</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
