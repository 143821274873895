/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import LogisticsMobileItem from "./LogisticsMobileItem";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const COMP_TYPE = {
  B: "포워더 (선사)",
  C: "운송사",
  D: "보험사",
  E: "관세사",
  F: "보세창고",
  Z: "GBTS 관리자",
};

const COMP_GB_SUB_TYPE = {
  B01: "포워더",
  B02: "콘솔사",
  B03: "선사",
  F01: "보세창고",
  F02: "CY",
  F03: "일반창고",
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const headCells = [
  {
    field: "COMP_GB",
    headerName: "업체구분",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.currentData.COMP_GB_SUB2
            ? `${COMP_GB_SUB_TYPE[params.currentData.COMP_GB_SUB2]}`
            : COMP_TYPE[params.data]}
        </span>
      );
    },
  },
  {
    field: "COMP_C_NUM",
    headerName: "업체부호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span
        className="text-truncate text-wrap text-break font-weight-bold cursor_pointer"
        onClick={() => {
          params.companyDetailChange(params.currentData);
        }}
      >
        {params.data}
      </span>
    ),
  },
  {
    field: "COMP_NM",
    headerName: "업체명",
    minWidth: 120,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let logo = null;

      if (
        params.currentData.SHIP_IMG_URL10 !== null &&
        params.currentData.SHIP_IMG_URL10 !== ""
      ) {
        logo = params.currentData.SHIP_IMG_URL10;
        logo = logo.replace("gbts-invoice.", "gbts-invoice-resized.");
        logo = logo.replace("gbts-invoice/", "gbts-invoice-resized/");
      }

      if (params.currentData.COMP_LOGO) {
        logo = params.currentData.COMP_LOGO;
      }

      return (
        <div
          style={{ alignItems: "center", display: "flex" }}
          className=" cursor_pointer"
          onClick={() => {
            params.companyDetailChange(params.currentData);
          }}
        >
          {logo !== null && (
            <div
              style={{
                background: `url(${logo}) 50% 50% / contain no-repeat`,
                minWidth: 80,
                maxWidth: 80,
                minHeight: 51,
              }}
            />
          )}
          <div className="text-truncate text-wrap text-break font-weight-bold">
            &nbsp;{params.data}
          </div>
        </div>
      );
    },
  },
  {
    field: "COMP_TAG",
    headerName: "태그",
    minWidth: 80,
    maxWidth: 150,
    numeric: false,
    disablePadding: true,
    renderCell: (params) => {
      let tags = params.data;
      if (!params.data) {
        return <span className="text-truncate text-wrap text-break"></span>;
      }
      tags = tags.split(",");

      return (
        <div className="row">
          {tags.map((e, i) => {
            return (
              <div className="btn btn-sm btn-outline-info mr-2 mb-2" key={i}>
                {e.trim()}
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    field: "COMP_SLOGUN",
    headerName: "슬로건",

    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
];

const _mClassName = "SearchLogisticsTable";

function Row(props) {
  const { row, companyDetailChange, compUserView } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      onClick={(event) => {
        companyDetailChange(row);
      }}
      role="checkbox"
      tabIndex={-1}
    >
      {headCells.map((headCell, index) => {
        let style = {};
        if (headCell.hasOwnProperty("minWidth"))
          style.minWidth = headCell.minWidth;
        if (headCell.hasOwnProperty("maxWidth"))
          style.maxWidth = headCell.maxWidth;
        return (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
            style={style}
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  companyDetailChange: companyDetailChange,
                  compUserView: compUserView,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  companyDetailChange: companyDetailChange,
                  compUserView: compUserView,
                })
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={`${headCell.field}_${index}`}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
                style={{ fontWeight: "bold" }}
              >
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

let filters = {
  COMP_GB_SUB2: "B03",
};
let isMobile = false;

export default function SearchLogisticsTable({
  comp_custom_info_list,
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();

  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("COMP_CD");

  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "COMP_CD",
      dir: "asc",
    },
  ]);

  useEffect(() => {
    isMobileHandler();
    window.addEventListener("resize", isMobileHandler);
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE,
      _mClassName,
      (b, n) => {
        filters = n;

        if (filters.hasOwnProperty("COMP_GB") && filters.COMP_GB.length >= 3) {
          filters.COMP_GB_SUB2 = filters.COMP_GB;
          delete filters.COMP_GB;
        }

        requestUserSelect();
      }
    );
    return () => {
      window.removeEventListener("resize", isMobileHandler);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE,
        _mClassName
      );
    };
  }, []);

  const isMobileHandler = () => {
    let inWidth = window.innerWidth;
    if (inWidth >= 960) {
      isMobile = false;
    } else {
      isMobile = true;
    }
  };

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk/JS_gbts_comp_list.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = [];
          for (let i = 0; i < newData["returnData"].length; i++) {
            dataList[i] = newData["returnData"][i];
            if (
              dataList[i].hasOwnProperty("SHIP_IMG_URL10") &&
              dataList[i].SHIP_IMG_URL10
            ) {
              dataList[i].SHIP_IMG_URL10 = util.changeURIForThumbnail(
                dataList[i].SHIP_IMG_URL10
              );
            }
            if (comp_custom_info_list.hasOwnProperty(dataList[i].COMP_CD)) {
              dataList[i] = {
                ...dataList[i],
                ...comp_custom_info_list[dataList[i].COMP_CD],
              };
            }
          }

          if (!isMobile) {
            companyDetailChange(dataList[0]);
          } else {
            companyDetailChange(null);
          }
          setServerData(dataList);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (property === "COMP_GB") {
      property = "COMP_GB_SUB2";
    }
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const companyDetailChange = (compData) => {
    if (compData) {
      socket.fireLocalEventListener(
        MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE,
        _mClassName,
        compData,
        () => {}
      );
    }
  };

  const compUserView = (compData) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE,
      _mClassName,
      compData,
      () => {}
    );
  };

  const LogisticsMobileItemRender = useCallback((props) => {
    return <LogisticsMobileItem {...props} />;
  }, []);

  const rowProps = {
    companyDetailChange: companyDetailChange,
    compUserView: compUserView,
  };

  return (
    <>
      {window.innerWidth >= 960 ? (
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {!isServerPaging &&
                !isServerSort &&
                util
                  .stableSort(data, util.getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={row.COMP_CD}
                      row={row}
                      index={index}
                      {...rowProps}
                    />
                  ))}
              {!isServerPaging &&
                isServerSort &&
                util
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={row.COMP_CD}
                      row={row}
                      index={index}
                      {...rowProps}
                    />
                  ))}

              {isServerPaging &&
                !isServerSort &&
                util
                  .stableSort(data, util.getComparator(order, orderBy))
                  .map((row, index) => (
                    <Row
                      key={row.COMP_CD}
                      row={row}
                      index={index}
                      {...rowProps}
                    />
                  ))}

              {isServerPaging &&
                isServerSort &&
                data.map((row, index) => {
                  return (
                    <Row
                      key={row.COMP_CD}
                      row={row}
                      index={index}
                      {...rowProps}
                    />
                  );
                })}

              {loading && (
                <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length}>
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length === 0 && !loading && (
                <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length}>
                    <div className="d-flex justify-content-center">
                      <div>해당 데이터가 존재하지 않습니다.</div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length > 0 && emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div className="mb-3 mt-3 pr-3">
            <Pagination
              style={{ justifyContent: "flex-end" }}
              className={classes.pagination}
              color="primary"
              count={totalPageCount}
              page={page + 1}
              allin={"right"}
              onChange={(event, value) => {
                handleChangePage(event, value - 1);
              }}
              showFirstButton
              showLastButton
            />
          </div>
        </TableContainer>
      ) : (
        <div>
          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => {
              return (
                <LogisticsMobileItemRender
                  key={row.COMP_CD}
                  row={row}
                  index={index}
                  {...rowProps}
                />
              );
            })}

          {data.length === 0 && !loading && (
            <div className="d-flex justify-content-center p-5">
              <div>해당 데이터가 존재하지 않습니다.</div>
            </div>
          )}

          <div className="mb-3 mt-3 pr-3">
            <Pagination
              style={{ justifyContent: "flex-end" }}
              className={classes.pagination}
              color="primary"
              count={totalPageCount}
              page={page + 1}
              allin={"right"}
              onChange={(event, value) => {
                handleChangePage(event, value - 1);
              }}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      )}
    </>
  );
}
