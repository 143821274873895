/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState, useCallback } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import LogiStoryManageTable from "../logiStoryManage/LogiStoryManageTable";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const _mClassName = "ScheduleRequestManageTable";
let loginInfo = null;

const headCells = [
  {
    field: "sm_UID",
    headerName: "스케줄 관리번호",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sr_IDX",
    headerName: "요청 NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sr_USER_NAME",
    headerName: "요청자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data
          ? `[${params.currentData.sr_COMP_NAME}] ${params.data}`
          : "-"}
      </span>
    ),
  },
  {
    field: "sr_FCL_LCL",
    headerName: "FCL/LCL",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sr_ORIGIN",
    headerName: "출발항(공항)",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sr_DEST",
    headerName: "도착항(공항)",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sm_DOC_CLOSE",
    headerName: "서류마감",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sm_CARGO_CLOSE",
    headerName: "화물마감",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sr_CREATEDATE",
    headerName: "요청일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "sr_STATUS",
    headerName: "상태",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span
          className={`font-weight-bold ${
            params.data === "SR" ? "text-danger" : "text-info"
          }`}
        >
          {params.data === "SR" ? "요청중" : "처리완료"}
        </span>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

export default function ScheduleRequestManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("prIDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "sr_CREATEDATE",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    loginInfo = loginData.returnData[0];

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_REQUEST_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_REQUEST_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_REQUEST_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_REQUEST_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();

    let isManager = loginInfo.comp_gb === "Z";
    let socketMsg = {
      file: "/schedule/JS_schedule_request_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      isManager: isManager,
      USER_ID: loginInfo.user_id,
      COMP_CD: loginInfo.comp_cd,
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    // console.log(event, newPage);
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  requestUserSelect={requestUserSelect}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  requestUserSelect={requestUserSelect}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEdit={openEdit}
                requestUserSelect={requestUserSelect}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            // console.log(value - 1); //apiRef.current.setPage(value - 1)
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

ScheduleRequestManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const {
    row,
    openEdit,
    removeBoard,
    isMultiSelect = false,
    requestUserSelect,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  removeBoard: removeBoard,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  removeBoard: removeBoard,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <ScheduleRequestDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                    removeBoard={removeBoard}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

const ScheduleRequestDetail = (props) => {
  let { rowData, requestUserSelect, setOpen, removeBoard } = props;
  const statusRef = useRef();

  //예약 상태 업데이트
  const UPDATE_SCHEDULE_REQUEST_STATUS = () => {
    codeList.Modal.current.confirm(
      "예약된 스케줄의 처리상태를 변경하시겠습니까?",
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/schedule/JS_schedule_request_update.php",
            msgID: msgID,
            data: {},
            sr_IDX: rowData.sr_IDX,
            sr_STATUS: statusRef.current.value,
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert("상태변경에 성공하였습니다");
                requestUserSelect();
              } else {
                codeList.Modal.current.alert(
                  "상태변경중 오류가 발생했습니다. 관리자에게 문의해주세요"
                );
              }
            }
          });
        }
      }
    );
  };
  return (
    <tr className="container" id={rowData.sr_IDX}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">상세정보</div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col">
                <p className="h6 font-weight-bold mb-2">
                  ■ 스케줄 관리번호 : {rowData.sm_UID}
                </p>
                <hr />
                <p className="h6 mb-2">■ 요청 스케줄 상세</p>

                {loginInfo && loginInfo.comp_gb === "Z" && (
                  <p className="mb-2">
                    포워더 : {rowData.sm_FWD_NO && `[${rowData.sm_FWD_NO}]`}{" "}
                    {rowData.sm_FWD_NM}
                  </p>
                )}

                <div className="d-flex flex-column flex-xl-row flex-wrap border rounded overflow-hidden mb-2">
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      IDNEX
                    </div>
                    <div className="p-2 text-center">{rowData.sr_IDX_MAIN}</div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      FCL/LCL
                    </div>
                    <div className="p-2 text-center">{rowData.sr_FCL_LCL}</div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      VESSEL/VOY
                    </div>
                    <div className="p-2 text-center">{rowData.sr_VESSEL}</div>
                    <div className="p-2 text-center">{rowData.sm_VOY}</div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      POL
                    </div>
                    <div className="p-2 text-center">
                      <p>{rowData.sr_ORIGIN}</p>
                      <p>{rowData.sm_DEPARTURE_DATE}</p>
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      POD
                    </div>
                    <div className="p-2 text-center">
                      <p>{rowData.sr_DEST}</p>
                      <p>{rowData.sm_ARRIVE_DATE}</p>
                    </div>
                  </div>
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      라인
                    </div>
                    <div className="p-2 text-center">
                      {rowData.sm_LINE ? rowData.sm_LINE : "-"}
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      지역
                    </div>
                    <div className="p-2 text-center">
                      <p>{rowData.sm_REGION ? rowData.sm_REGION : "-"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <p className="col-12 h6 mb-2">■ 주요 안내사항</p>
              <div className="col">
                <div className="col-12 border rounded p-2">
                  {rowData.sm_REMARK ? rowData.sm_REMARK : "-"}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="row">
                  <p className="col-12 h6 mb-2">■ 회신처</p>
                  <div className="col-12 col-sm-4 mb-1 mb-sm-2 font-weight-bold">
                    회사코드 및 상호명 :
                  </div>
                  <div className="col-12 col-sm-8 mb-1 mb-sm-2">
                    [{rowData.sr_COMP_CD ? rowData.sr_COMP_CD : "-"}]{" "}
                    {rowData.sr_COMP_NAME ? rowData.sr_COMP_NAME : "-"}
                  </div>
                  <div className="col-12 col-sm-4 mb-1 mb-sm-2 font-weight-bold">
                    요청자명 :
                  </div>
                  <div className="col-12 col-sm-8 mb-1 mb-sm-2">
                    {rowData.sr_USER_NAME ? rowData.sr_USER_NAME : "-"}
                  </div>
                  <div className="col-12 col-sm-4 mb-1 mb-sm-2 font-weight-bold">
                    이메일 :
                  </div>
                  <div className="col-12 col-sm-8 mb-1 mb-sm-2">
                    {rowData.sr_EMAIL ? rowData.sr_EMAIL : "-"}
                  </div>
                  <div className="col-12 col-sm-4 mb-1 mb-sm-2 font-weight-bold">
                    문자(SMS) :
                  </div>
                  <div className="col-12 col-sm-8 mb-1 mb-sm-2">
                    {rowData.sr_TEL ? rowData.sr_TEL : "-"}
                  </div>
                </div>
              </div>

              {loginInfo && loginInfo.comp_gb === "Z" && (
                <div className="col-12 col-xl-4">
                  <p className="h6 mb-2">■ 처리상태</p>
                  <div className="d-flex flex-column flex-xl-row justify-content-between">
                    <select
                      className="form-control mr-0 mr-xl-3 mb-2"
                      defaultValue={rowData.sr_STATUS}
                      ref={statusRef}
                    >
                      <option value="SR">요청중</option>
                      <option value="SS">처리완료</option>
                    </select>
                    <button
                      className="btn btn-primary mb-2"
                      style={{ minWidth: 60 }}
                      onClick={() => UPDATE_SCHEDULE_REQUEST_STATUS()}
                    >
                      저장
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
