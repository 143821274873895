import React, { useRef, useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function StoreReview(props) {
  let { storeReviewList, selectedStore, addReviewListHandler } = props;

  const [loginInfo, setLoginInfo] = useState(null);
  const [isRating, setIsRating] = useState(5);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isReviewModify, setIsReviewModify] = useState(false);
  const [isReviewModifyData, setIsReviewModifyData] = useState(null);

  let $controller = {
    srUserName: useRef(),
    srReviewText: useRef(), // 리뷰내용
    srReviewPWD: useRef(), // 리뷰 비밀번호
    srPublicYN: useRef(), // 공개여부
  };

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "StoreReview",
      (b, n) => {
        if (n) {
          setLoginInfo(n["returnData"][0]);
        }
      }
    );
  })();

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData["returnData"][0]);
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "StoreReview"
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo) {
      $controller.srUserName.current.value = loginInfo.user_nm;
    }
  }, [loginInfo]);

  const ratingHandler = (e) => {
    let rating = e.target.dataset.rating;
    setIsRating(rating);
  };

  const disabledHandler = (val) => {
    setIsDisabled(val);
  };

  const addReview = () => {
    let updateData = {};
    for (let key in $controller) {
      if ($controller[key].current) {
        updateData[key] = $controller[key].current.value
          ? $controller[key].current.value
          : "";
      }
    }
    if (loginInfo !== null) {
      updateData.srUserCompCD = loginInfo.comp_cd;
      updateData.srUserID = loginInfo.user_id;
    }
    updateData.srKey = selectedStore.smKey;
    updateData.srRating = isRating;
    updateData.srCreateDate = new Date();
    updateData.srEditDate = new Date();

    if (checkValidation()) {
      requestUserUpdate(updateData);
    }
  };

  const checkValidation = () => {
    if ($controller.srUserName.current.value.trim() === "") {
      codeList.Modal.current.alert("작성자명을 입력해주세요", () => {});
      return false;
    }
    if ($controller.srPublicYN.current.value.trim() === "") {
      codeList.Modal.current.alert("공개여부를 선택해 주세요", () => {});
      return false;
    }
    if (!isDisabled) {
      if ($controller.srReviewPWD.current.value.trim() === "") {
        codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
        return false;
      }
    }

    if ($controller.srReviewText.current.value.trim() === "") {
      codeList.Modal.current.alert("리뷰를 입력해주세요", () => {});
      return false;
    }
    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/review/JS_store_review_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert("리뷰가 정상 등록되었습니다.", () => {});
          addReviewListHandler(updateData);
          for (let key in $controller) {
            if (key !== "srUserName") {
              $controller[key].current.value = "";
            }
          }
        }
      }
    });
  };

  const reviewModify = (reviewData) => {
    codeList.Modal.current.confirm("리뷰를 수정하시겠습니까?", (ret) => {
      if (ret) {
        for (let key in $controller) {
          if (reviewData.hasOwnProperty(key)) {
            $controller[key].current.value = reviewData[key];
          }
        }
        setIsReviewModifyData({
          ...reviewData,
        });
        setIsReviewModify(true);
      }
    });
  };

  const requestReviewModify = (srShowYN, review) => {
    if (!srShowYN) srShowYN = "Y";

    let updateData = {
      srIDX: isReviewModifyData ? isReviewModifyData.srIDX : review.srIDX,
      srReviewText: $controller.srReviewText.current.value,
      srRating: isRating,
      srPublicYN: $controller.srPublicYN.current.value,
      srShowYN: srShowYN,
    };

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/customsStore/review/JS_store_review_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    codeList.Modal.current.confirm(
      srShowYN === "Y"
        ? "리뷰를 수정하시겠습니까?"
        : "리뷰를 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData.ret) {
                codeList.Modal.current.alert(
                  srShowYN === "Y"
                    ? "리뷰가 정상 등록되었습니다."
                    : "리뷰가 정상적으로 삭제되었습니다.",
                  () => {}
                );
                for (let key in $controller) {
                  if (key !== "srUserName") {
                    $controller[key].current.value = "";
                  }
                }
                setIsReviewModify(false);
                setIsReviewModifyData(null);
                addReviewListHandler(updateData);
              }
            }
          });
        }
      }
    );
  };

  const clearReviewInput = () => {
    for (let key in $controller) {
      if (key !== "srUserName") {
        $controller[key].current.value = "";
      }
    }
    setIsReviewModify(false);
    setIsReviewModifyData(null);
  };

  return (
    <div
      className="modal fade"
      id="StoreReviewModal"
      tabIndex="-1"
      aria-labelledby="StoreReviewModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="StoreReviewModalLabel">
              평점/리뷰
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-secondary container">
            <div className="d-flex  flex-column flex-lg-row justify-content-start">
              <div className="mr-3" style={{ width: 140 }}>
                <p className="h5 font-weight-bold">평점 및 리뷰</p>
              </div>
              <div
                className="flex-fill py-1 overflow_scroll"
                style={{ minHeight: 400, maxHeight: 600 }}
              >
                <div
                  className={`d-flex flex-row justify-content-start p-2 pt-0 border-bottom text-dark font-weight-bold`}
                >
                  <div
                    className="pr-2 mr-2 border-right"
                    style={{ minWidth: 100, maxWidth: 100 }}
                  >
                    작성자
                  </div>
                  <div className="pr-2 mr-2 border-right flex-fill text-dark font-weight-bold">
                    내용
                  </div>
                  <div
                    className="pr-2 mr-2 border-right"
                    style={{ width: 150 }}
                  >
                    평점
                  </div>
                  <div
                    className="pr-2 mr-2 border-right"
                    style={{ width: 150 }}
                  >
                    최종수정일
                  </div>
                </div>
                {storeReviewList.length > 0 ? (
                  storeReviewList.map((review, index) => {
                    let rating = Number(review.srRating);

                    return (
                      <div
                        className={`d-flex flex-row justify-content-start py-3 border-bottom px-2`}
                        key={review.srIDX}
                      >
                        <div
                          className="pr-2 mr-2 "
                          style={{ minWidth: 100, maxWidth: 100 }}
                        >
                          <p>{review.srUserName}</p>
                          {loginInfo !== null &&
                            review.srUserID === loginInfo.user_id && (
                              <>
                                <span
                                  className="cursor_pointer font-weight-bold mr-2"
                                  onClick={() => {
                                    reviewModify(review);
                                  }}
                                >
                                  수정
                                </span>

                                <span
                                  className="cursor_pointer font-weight-bold text-danger"
                                  onClick={() => {
                                    requestReviewModify("N", review);
                                  }}
                                >
                                  삭제
                                </span>
                              </>
                            )}
                        </div>
                        <p className="pr-2 mr-2 flex-fill text-dark font-weight-bold">
                          {review.isShow
                            ? review.srReviewText
                            : "※ 비밀공개 리뷰 입니다."}
                        </p>
                        <div className="pr-2 mr-2" style={{ width: 150 }}>
                          {rating >= 1 ? (
                            <i className="icon-star text-warning" />
                          ) : (
                            <i className="icon-star-empty text-warning" />
                          )}
                          {rating >= 2 ? (
                            <i className="icon-star text-warning" />
                          ) : (
                            <i className="icon-star-empty text-warning" />
                          )}
                          {rating >= 3 ? (
                            <i className="icon-star text-warning" />
                          ) : (
                            <i className="icon-star-empty text-warning" />
                          )}
                          {rating >= 4 ? (
                            <i className="icon-star text-warning" />
                          ) : (
                            <i className="icon-star-empty text-warning" />
                          )}
                          {rating >= 5 ? (
                            <i className="icon-star text-warning" />
                          ) : (
                            <i className="icon-star-empty text-warning" />
                          )}{" "}
                          | {review.srRating * 2}점
                        </div>
                        <div className="pr-2" style={{ width: 150 }}>
                          {review.srEditDate}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="h6 pt-3">등록된 리뷰가 없습니다.</p>
                )}
              </div>
            </div>
            <div className="border-top mt-3 py-3 px-2">
              <div className="d-flex flex-column flex-lg-row justify-content-start">
                <div className="mr-3" style={{ width: 140 }}>
                  <p className="h5 font-weight-bold">리뷰작성</p>
                </div>
                <div className="flex-fill py-1">
                  <div className="row pb-2">
                    <div className="m-0 px-1 col-12">
                      <span className="h5 p-0 m-0 mr-1">평점</span>
                      <span className="h5 p-0 m-0">
                        {isRating >= 1 ? (
                          <i
                            className="icon-star text-warning cursor_pointer"
                            data-rating={1}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        ) : (
                          <i
                            className="icon-star-empty text-warning cursor_pointer"
                            data-rating={1}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        )}
                        {isRating >= 2 ? (
                          <i
                            className="icon-star text-warning cursor_pointer"
                            data-rating={2}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        ) : (
                          <i
                            className="icon-star-empty text-warning cursor_pointer"
                            data-rating={2}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        )}
                        {isRating >= 3 ? (
                          <i
                            className="icon-star text-warning cursor_pointer"
                            data-rating={3}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        ) : (
                          <i
                            className="icon-star-empty text-warning cursor_pointer"
                            data-rating={3}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        )}
                        {isRating >= 4 ? (
                          <i
                            className="icon-star text-warning cursor_pointer"
                            data-rating={4}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        ) : (
                          <i
                            className="icon-star-empty text-warning cursor_pointer"
                            data-rating={4}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        )}
                        {isRating >= 5 ? (
                          <i
                            className="icon-star text-warning cursor_pointer"
                            data-rating={5}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        ) : (
                          <i
                            className="icon-star-empty text-warning cursor_pointer"
                            data-rating={5}
                            onClick={(e) => {
                              ratingHandler(e);
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="m-0 px-1 col-5">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="작성자명"
                        ref={$controller.srUserName}
                      />
                    </div>
                    <div className="m-0 px-1 col-5">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="비밀번호"
                        ref={$controller.srReviewPWD}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className="m-0 px-1 col-2">
                      <select
                        className="form-control"
                        ref={$controller.srPublicYN}
                        onChange={(e) => {
                          disabledHandler(e.target.value !== "N");
                        }}
                      >
                        <option value="">공개여부</option>
                        <option value="Y">공개</option>
                        <option value="N">비공개</option>
                      </select>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="m-0 px-1 col-12">
                      <textarea
                        ref={$controller.srReviewText}
                        className="form-control"
                        rows="5"
                        placeholder="리뷰를 작성해 주세요"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1 outline"
              onClick={() => {
                clearReviewInput();
              }}
            >
              초기화
            </button>
            <button
              type="button"
              className="btn_1"
              onClick={() => {
                if (isReviewModify) {
                  requestReviewModify("Y");
                } else {
                  addReview();
                }
              }}
            >
              {isReviewModify ? "리뷰수정" : "리뷰등록"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
