import React, { useEffect, useState } from "react";
import ScheduleLogiWorkBookList from "../../../main/mall/schedule_logiwork/bookList/ScheduleLogiWorkBookList";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import CommonLoader from "../../CommonLoader";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "RequestTransitMemoManage";

export default function RequestTransitMemoManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [portList, setPortList] = useState([]);
  const [airportList, setAirportList] = useState([]);

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      if (codeList.codeCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
          _mClassName,
          (oldV, newV) => {
            setPortList(newV);
          }
        );
      } else {
        setPortList(codeList.codeCountryFullName);
      }

      if (codeList.codeAirCountryFullName.length === 0) {
        socket.addLocalEventListener(
          MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
          _mClassName,
          (oldV, newV) => {
            setAirportList(newV);
          }
        );
      } else {
        setAirportList(codeList.codeAirCountryFullName);
      }
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  useEffect(() => {
    if (portList.length > 0 && airportList.length > 0) {
      setIsLoading(false);
    }
  }, [portList, airportList]);

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      {!isLoading && (
        <ScheduleLogiWorkBookList
          login_info={loginInfo}
          portList={portList}
          airportList={airportList}
        />
      )}
    </div>
  );
}
