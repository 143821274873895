import React from "react";

export default function OtherServiece(props) {
  const { isMobile } = props;
  return (
    <div className="py-5 p-xl-5 bg-light">
      <div className="text-center mb-4">
        <h2 className="font-weight-900 mt-3 mt-xl-0 mb-3 lineUp">
          다양한 디지털 서비스
        </h2>
        <h3 className="font-weight-600">
          <p className="px-2 px-xl-0">
            업무에 바로 적용할 수 있는 GBTS 디지털 솔루션을 지금 이용해 보세요.
          </p>
        </h3>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center">
        <div
          className="d-flex flex-column justify-content-between p-4 shadow rounded-lg bg-white mb-3 mx-3 mx-xl-0 mb-xl-0"
          style={{ maxWidth: isMobile ? "unset" : 320 }}
        >
          {isMobile && (
            <h4 className="font-weight-900 text-freiscop text-center mb-2 mb-md-0">
              <p>
                물류전용 Chat-GPT
                <br />
                (맞춤형 튜닝제공)
              </p>
            </h4>
          )}
          <div className="d-flex flex-row flex-md-column align-items-center justify-content-between">
            <div className="d-flex flex-column align-items-center">
              {!isMobile && (
                <h4 className="font-weight-900 text-freiscop text-center">
                  <p>
                    물류전용 Chat-GPT
                    <br />
                    (맞춤형 튜닝제공)
                  </p>
                </h4>
              )}
              <div className="mr-3 mr-md-0 my-md-4">
                <img
                  width={isMobile ? "60px" : "unset"}
                  src="/img/info/chat_gpt.png"
                  alt="chat_gpt"
                />
              </div>
            </div>
            <p className="h6 m-0 text-xl-center">
              무역물류에 딥러닝한{" "}
              <span className="font-weight-bold">‘베스트 Chat-GPT’</span>를
              사용해 보시고 회사 페이지에 도입하세요.
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-between p-4 shadow rounded-lg bg-white mb-3 mx-3 mb-xl-0"
          style={{ maxWidth: isMobile ? "unset" : 320 }}
        >
          {isMobile && (
            <h4 className="font-weight-900 text-freiscop text-center mb-2 mb-md-0">
              <p>
                전세계 컨테이너선
                <br />
                실시간 위치추적
              </p>
            </h4>
          )}
          <div className="d-flex flex-row flex-md-column align-items-center justify-content-between">
            <div className="d-flex flex-column align-items-center">
              {!isMobile && (
                <h4 className="font-weight-900 text-freiscop text-center">
                  <p>
                    전세계 컨테이너선
                    <br />
                    실시간 위치추적
                  </p>
                </h4>
              )}
              <div className="mr-3 mr-md-0 my-md-4">
                <img
                  width={isMobile ? "60px" : "unset"}
                  src="/img/info/cursor.png"
                  alt="cursor"
                />
              </div>
            </div>
            <p className="h6 m-0 text-xl-center">
              실시간 선박위치 조회 서비스로 ETA(예상도착일) 관리하고{" "}
              <span className="font-weight-bold">SCM Risk를 해결</span>하세요.
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-between p-4 shadow rounded-lg bg-white mb-3 mx-3 mx-xl-0 mb-xl-0"
          style={{ maxWidth: isMobile ? "unset" : 320 }}
        >
          {isMobile && (
            <h4 className="font-weight-900 text-freiscop text-center mb-2 mb-md-0">
              <p>
                기업 신용정보 조회
                <br />
                (신용보증기금 제공)
              </p>
            </h4>
          )}
          <div className="d-flex flex-row flex-md-column align-items-center justify-content-between">
            <div className="d-flex flex-column align-items-center">
              {!isMobile && (
                <h4 className="font-weight-900 text-freiscop text-center">
                  <p>
                    기업 신용정보 조회
                    <br />
                    (신용보증기금 제공)
                  </p>
                </h4>
              )}
              <div className="mr-3 mr-md-0 my-md-4">
                <img
                  width={isMobile ? "60px" : "unset"}
                  src="/img/info/check_doc.png"
                  alt="check_doc"
                />
              </div>
            </div>
            <p className="h6 m-0 text-xl-center">
              국내최대 기업정보를 보유한 신용보증기금의 품질높은{" "}
              <span className="font-weight-bold">
                신용정보 서비스를 이용해 미수금 관리
              </span>
              에 활용하세요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
