import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const product_type = {
  A: "수출(해상)",
  B: "수입(해상)",
  C: "수출(항공)",
  D: "수입(항공)",
  E: "벌크/특수화물",
};
export default function FCLTitle() {
  const [productData, setProductData] = useState({
    pmArrivalCity: "CDBD01",
    pmCreator: "admin",
    pmDepartureCity: "CDAA01",
    pmDepartureDate: "2021-12-12|2021-12-15|2021-12-21|2021-12-30|2022-01-04",
    pmDepartureDayWeek: "",
    pmDepartureType: "D",
    pmForwarderDesc: "정직한 동서로지스틱스!",
    pmForwarderHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmForwarderImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/forwarderImage/20211212172437_ds.png",
    pmForwarderName: "동서로지스틱스",
    pmIDX: "7",
    pmKeyMain: "eaa51515-3a92-41a8-a6c0-a1503751c415",
    pmOptionInclude: "- 운임, 부대비용을 포함한 상기 고지된 물류비용",
    pmOptionInfo:
      "- 수출통관수수료\n- 국내컨테이너운송료\n\n등 비용은 업무 진행시 제휴사(관세사)청구금액 및 운송구간에 따른 운임에 따릅니다. 해당 비용에 대하여 궁금하시면 문의 남겨 주세요.",
    pmOptionNotInclude:
      "- 통관수수료, 적하보험료, 내륙운송료, DOOR작업건의 경우 상차비용 등은 불포함 되어 있습니다",
    pmProductExpireEnd: "2022-01-08",
    pmProductExpireStart: "2021-12-12",
    pmProductExpireType: "S",
    pmProductName: "인천-하이퐁 SM상선 FCL 직항",
    pmProductType: "A",
    pmShipCompanyDesc: "빠른 SM상선!",
    pmShipCompanyHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmShipCompanyImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/carrierImage/20211212172323_ship-gfa701dc0d_640.jpg",
    pmShipCompanyName: "SM상선",
    pmShipInfo: "PANCON SUCCESS",
    pmTransferDay: "30",
    pmTransferDirectYN: "Y",
    pmTransferType: "FCL",
    pmUSEYN: "Y",
    pmWarningInfo: "1. GBTS로지스몰에서 예약을 하시면 담당…..",
    subData: [
      {
        psIDX: "0",
        psMoneyCD: "USD",
        psServiceCol1: "500",
        psServiceCol2: "950",
        psServiceName: "OCEAN FREIGHT",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "1",
        psMoneyCD: "USD",
        psServiceCol1: "150",
        psServiceCol2: "300",
        psServiceName: "EBS",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "2",
        psMoneyCD: "USD",
        psServiceCol1: "50",
        psServiceCol2: "100",
        psServiceName: "CIS",
        psServiceType: "A",
        psCostType: "P",
      },
      {
        psIDX: "3",
        psMoneyCD: "USD",
        psServiceCol1: "40",
        psServiceCol2: "80",
        psServiceName: "CRS",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "0",
        psMoneyCD: "KRW",
        psServiceCol1: "130000",
        psServiceCol2: "180000",
        psServiceName: "THC",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "1",
        psMoneyCD: "KRW",
        psServiceCol1: "4200",
        psServiceCol2: "8400",
        psServiceName: "WFG",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "2",
        psMoneyCD: "KRW",
        psServiceCol1: "86",
        psServiceCol2: "172",
        psServiceName: "SECURITY CHARGE",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "3",
        psMoneyCD: "KRW",
        psServiceCol1: "50000",
        psServiceCol2: "50000",
        psServiceName: "DOCUMENT FEE",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "4",
        psMoneyCD: "USD",
        psServiceCol1: "80",
        psServiceCol2: "80",
        psServiceName: "D/O FEE",
        psServiceType: "E",
        psCostType: "O",
      },
    ],
  });

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "FCLTitle",
      (beforeData, newData) => {
        if (newData) {
          setProductData({ ...newData });
        }
      }
    );
  }, []);

  return (
    <div className="container" id="fclTitle">
      <div className="row" style={{ paddingTop: 100 }}>
        <h4 className="col-12 p-sm-0 pt-sm-1 font-weight-bold text-dark mb-0">
          [{product_type[productData.pmProductType]}]{" "}
          {productData.pmProductName}
        </h4>
      </div>
    </div>
  );
}
