import React, { useCallback, useEffect, useRef, useState } from "react";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import MMSI from "../../schedule/mmsiList";
import axios from "axios";
import LogoSvg from "../../../../assets/LogoSvg";
import $ from "jquery";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

const useRowStyles = makeStyles({
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
});

const _mClassName = "ScheduleLogiWorkLCLTable";

function isNumber(s) {
  s += ""; // 문자열로 변환
  s = s.replace(/^\s*|\s*$/g, ""); // 좌우 공백 제거
  if (s == "" || isNaN(s)) return false;
  return true;
}

export default function ScheduleLogiWorkLCLTable({
  login_info,
  schedule_list,
  totalPageCount,
  page,
  bComp_list,
  changedPage,
  sendUseHistory,
  openCostPopup,
}) {
  const classes = useRowStyles();
  const [openedInfo, setOpenedInfo] = useState(-1);

  //스케줄 URL 공유
  const shareScheduleUrl = async (sm_UID) => {
    //todo logiwork에서 navigator.clipboard 차단됨
    try {
      await navigator.clipboard.writeText(
        `https://www.gbts.co.kr/ScheduleLCL/${sm_UID}`
      );
      codeList.Modal.current.alert("공유 링크가 정상적으로 복사되었습니다.");
    } catch (e) {
      codeList.Modal.current.alert(
        "공유 링크 복사에 실패하였습니다. 관리자에게 문의해주새요"
      );
    }
  };

  const requestEnquiry = (sm_UID) => {
    codeList.Modal.current.confirm(
      `스케줄No [${sm_UID}]에 대해 담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          if (!login_info) {
            codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
            return;
          }
          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "LogiTalk", "_blank");
        }
      }
    );
  };
  //선박추적 팝업 열기
  const handleOpenOceanLookV3 = (sm_IDX, sm_VESSEL) => {
    if (!login_info) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return;
    }
    let mmsiNum = mmsi.getMMSI(sm_VESSEL);

    if (mmsiNum !== "") {
      let oceanlookWebToken =
        "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";
      let url =
        "https://www.oceanlook.net/v3/popup?token=" +
        oceanlookWebToken +
        "&mmsi=" +
        mmsiNum;

      socket.fireLocalEventListener(
        MsgIDList.EVENT_CHANGED_LOGIWORK_OCEAN_LOOK_URL,
        _mClassName,
        url,
        () => {}
      );
    } else {
      codeList.Modal.current.alert("추적 가능한 선박이 아닙니다.");
      return false;
    }
  };

  //스케줄 아이템 랜더
  const ScheduleItemRender = useCallback(
    ({
      item,
      index,
      openCostPopup,
      login_info,
      openedInfo,
      bComp_list,
      handleOpenOceanLookV3,
      sendUseHistory,
      requestEnquiry,
    }) => {
      return (
        <ScheduleItem
          item={item}
          index={index}
          openCostPopup={openCostPopup}
          login_info={login_info}
          setOpenedInfo={setOpenedInfo}
          openedInfo={openedInfo}
          bComp_list={bComp_list}
          handleOpenOceanLookV3={handleOpenOceanLookV3}
          sendUseHistory={sendUseHistory}
          shareScheduleUrl={shareScheduleUrl}
          requestEnquiry={requestEnquiry}
        />
      );
    },
    []
  );

  return (
    <div>
      <div className="col-12 m-0 p-0 d-none d-xl-block rounded-top-left rounded-top-right overflow-hidden">
        <div className="row m-0 p-0 bg-secondary text-white">
          <div className="col-1 px-1 py-1 m-0 align-items-end">
            &nbsp;스케줄 No
          </div>
          <div className="col-2 px-1 py-1 m-0">콘솔사</div>
          <div className="col-2 px-1 py-1 m-0">서류마감/화물마감</div>
          <div className="col-4 px-1 py-1 m-0">출발/도착</div>
          <div className="col-1 px-1 py-1 m-0">선박</div>
          <div className="col-2 px-0 m-0 bg-dark text-center d-flex flex-row justify-content-around">
            <div className="px-2 py-1">안내</div>
            <div className="px-2 py-1">운임</div>
            <div className="px-2 py-1">문의</div>
            <div className="px-2 py-1">위치</div>
          </div>
        </div>
      </div>
      <div className={`row m-0 p-0 `}>
        {schedule_list.length > 0 &&
          schedule_list.map((item, index) => {
            return (
              <ScheduleItemRender
                key={`schedule_${item.sm_IDX}`}
                index={index}
                item={item}
                login_info={login_info}
                bComp_list={bComp_list}
                openCostPopup={openCostPopup}
                openedInfo={openedInfo}
                handleOpenOceanLookV3={handleOpenOceanLookV3}
                requestEnquiry={requestEnquiry}
                sendUseHistory={sendUseHistory}
              />
            );
          })}
      </div>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            changedPage(value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
}

const ScheduleItem = React.memo((params) => {
  const {
    item,
    index,
    shareScheduleUrl,
    sendUseHistory,
    login_info,
    bComp_list,
    openCostPopup,
    requestEnquiry,
    handleOpenOceanLookV3,
  } = params;

  const isRadius = index === 9;

  let sm_DOC_CLOSE_day = util.handleGetDate(item.sm_DOC_CLOSE);
  let sm_CARGO_CLOSE_day = util.handleGetDate(item.sm_CARGO_CLOSE);
  let sm_DEPARTURE_DATE_day = util.handleGetDate(item.sm_DEPARTURE_DATE);
  let sm_ARRIVE_DATE_day = util.handleGetDate(item.sm_ARRIVE_DATE);

  useEffect(() => {
    $(".open_sch_info_" + item.sm_IDX).click((e) => {
      $("#collapse_btn_" + item.sm_IDX).click();
    });
  }, []);

  let mmsiNum = mmsi.getMMSI(item.sm_VESSEL);

  let consolInfo = bComp_list.find((data) => data.blFWDNO === item.sm_FWD_NO);
  let comp_logo = item.COMP_LOGO;
  if (consolInfo && !comp_logo) {
    comp_logo = consolInfo.blLOGO;
  }
  return (
    <div className="col-12 m-0 p-0 text-secondary">
      <div
        className={`row m-0 p-0 p-xl-1 align-items-center font-size-09 cursor_pointer cursor_pointer_bg_light bg-white ${
          isRadius
            ? "rounded-bottom-left rounded-bottom-right"
            : "border-bottom"
        } `}
      >
        <div className="col-12 col-xl-1 order-0 order-xl-0 m-0 p-0 px-2">
          <div
            className="d-flex flex-row align-items-center py-2 py-xl-0"
            onClick={(e) => {
              shareScheduleUrl();
              sendUseHistory("share", 1, item);
            }}
          >
            <span className="h6 mr-1 mb-0">
              <i className="fa-solid fa-share-nodes mr-1 text-primary" />
            </span>
            <span className="font-size-09">{item.sm_UID}</span>
          </div>
        </div>
        <div className="col-12 d-xl-none border-bottom" />
        <div
          className={`col-6 col-xl-2 m-0 p-2 p-xl-0 order-1 order-xl-1 open_sch_info_${item.sm_IDX}`}
        >
          <div className="d-flex flex-row align-items-center">
            {comp_logo ? (
              <div
                style={{
                  backgroundImage: `url('${comp_logo.split("?")[0]}')`,
                  backgroundPosition: "50% 50%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",

                  width: "40px",
                  height: "40px",
                }}
              />
            ) : (
              <LogoSvg width={"20px"} />
            )}

            <div className="ml-2">
              {item.sm_PROMO_YN === "Y" && (
                <span className="badge badge-danger text-white mr-1 hover_point">
                  PROMO
                  <div
                    className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                    style={{
                      minWidth: 180,
                      bottom: "calc(100% + 20px)",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 9,
                    }}
                  >
                    <p className="text-danger" style={{ fontSize: "1em" }}>
                      *{item.sm_PROMO_MEMO}
                    </p>
                    <div
                      className="bg-white border-right border-bottom"
                      style={{
                        transform: "rotate(45deg)",
                        marginLeft: -10,
                        width: 20,
                        height: 20,
                        position: "absolute",
                        left: "50%",
                      }}
                    />
                  </div>
                </span>
              )}
              {isNumber(item.sm_IDX) && (
                <span className="badge badge-info">추천</span>
              )}
              <p className="font-weight-bold mt-1">
                {item.COMP_NM || item.sm_FWD_NM}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-xl-2 m-0 px-1 p-xl-0 order-3 order-xl-2 open_sch_info_${item.sm_IDX}`}
        >
          <div className="row m-0 p-2 p-xl-0">
            <div className="col-6 m-0 p-0 d-flex flex-row">
              <span className="mr-1 mb-0">
                <i className="fa-regular fa-file-lines" />
              </span>
              <div>
                <p>{item.sm_DOC_CLOSE}</p>
                <p>
                  ({sm_DOC_CLOSE_day}) {item.sm_DOC_CLOSE_TIME}
                </p>
              </div>
            </div>
            <div className="col-6 m-0 p-0 d-flex flex-row">
              <span className="font-weight-bold mr-1">
                <i className="fa-solid fa-dolly" />
              </span>
              <div>
                <p>{item.sm_CARGO_CLOSE}</p>
                <p>
                  ({sm_CARGO_CLOSE_day}) {item.sm_CARGO_CLOSE_TIME}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-xl-4 m-0 p-1 p-xl-0 order-4 order-xl-3 open_sch_info_${item.sm_IDX}`}
        >
          <div className="row m-0 p-2 font-weight-bold">
            <div className="col-6 m-0 p-0">
              <p className="text-dark">{item.sm_ORIGIN}</p>
              <p className="text-danger">
                {item.sm_DEPARTURE_DATE} ({sm_DEPARTURE_DATE_day})
              </p>
            </div>
            <div className="col-6 m-0 p-0">
              <p className="text-dark">{item.sm_DEST}</p>
              <p className="text-danger">
                {item.sm_ARRIVE_DATE} ({sm_ARRIVE_DATE_day}){" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`col-6 col-xl-1 m-0 px-1 py-2 p-xl-0 order-2 order-xl-4 open_sch_info_${item.sm_IDX}`}
        >
          <div className="h-100 d-flex flex-column justify-content-center">
            <p className="font-weight-bold">{item.sm_VESSEL}</p>
            {item.sm_VOY && <p>{item.sm_VOY}</p>}
          </div>
        </div>
        <div className="col-12 m-0 p-0 order-5 order-xl-5 d-xl-none border-top" />
        <div className="col-12 col-xl-2 order-7 order-xl-6 m-0 p-0">
          <div className="py-1 d-flex flex-row  justify-content-around align-items-center position-relative flex-grow-1">
            <div className="position-relative mr-1 hover_point text-center">
              <span className="text-center p-0 m-0">
                <button
                  className="h5 m-0 p-0 text-decoration-none text-secondary border-0 bg-transparent"
                  data-toggle="collapse"
                  href={`#collapse_${item.sm_IDX}`}
                  role="button"
                  aria-expanded="false"
                  aria-controls={`#collapse_${item.sm_IDX}`}
                  id={`collapse_btn_${item.sm_IDX}`}
                >
                  <i className="fa-regular fa-circle-question" />
                </button>
              </span>

              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 9,
                }}
              >
                {item.sm_PROMO_YN === "Y" && (
                  <p className="text-danger">*{item.sm_PROMO_MEMO}</p>
                )}
                클릭시 안내사항을 확인 할 수 있습니다.
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div
              className="position-relative mr-1 hover_point text-center cursor-pointer"
              onClick={() => {
                if (!login_info) {
                  alert("로그인이 필요한 서비스입니다.");
                  return;
                }
                sendUseHistory("book", 1, item);
                openCostPopup("lcl", item.sm_IDX);
              }}
            >
              <span className="h5">
                <i
                  className={`fa-solid fa-magnifying-glass mx-1 cursor_pointer `}
                />
              </span>

              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 9,
                  wordBreak: "keep-all",
                }}
              >
                이 스케줄에 예약 요청 하시겠어요?
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div className="position-relative mr-1 hover_point text-center cursor-pointer">
              <span className="text-center p-0 m-0">
                <span
                  className="h5 m-0 "
                  onClick={() => {
                    requestEnquiry(item.sm_UID);
                  }}
                >
                  <i
                    className="fa-solid fa-message"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </span>
              </span>
              <div
                className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                style={{
                  minWidth: 180,
                  bottom: "calc(100% + 20px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                로지톡으로 담당자와 대화해보세요
                <div
                  className="bg-white border-right border-bottom"
                  style={{
                    transform: "rotate(45deg)",
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                    position: "absolute",
                    left: "50%",
                  }}
                />
              </div>
            </div>
            <div
              className="position-relative mr-1 hover_point text-center cursor-pointer"
              onClick={() => {
                sendUseHistory("ship", 1, item);
                if (!mmsiNum) return;
                handleOpenOceanLookV3(item.sm_UID, item.sm_VESSEL);
              }}
              style={mmsiNum ? { cursor: "pointer" } : {}}
            >
              <span className="text-center p-0 m-0">
                <span className="h5">
                  {mmsiNum ? (
                    <i className="fa-solid fa-location-dot" />
                  ) : (
                    <i
                      className="fa-solid fa-location-dot"
                      style={{ color: "transparent" }}
                    />
                  )}
                </span>
              </span>

              {mmsiNum && (
                <div
                  className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                  style={{
                    minWidth: 180,
                    bottom: "calc(100% + 20px)",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 9,
                  }}
                >
                  선박 위치를 <br />
                  확인해보세요.
                  <div
                    className="bg-white border-right border-bottom"
                    style={{
                      transform: "rotate(45deg)",
                      marginLeft: -10,
                      width: 20,
                      height: 20,
                      position: "absolute",
                      left: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="collapse col-12 m-0 p-0 order-6 order-xl-7"
          id={`collapse_${item.sm_IDX}`}
        >
          <div className="py-2 px-3" style={{ fontSize: "1em" }}>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                선명 · 항차
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_VESSEL} / {item.sm_VOY}
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                소요일
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {(new Date(item.sm_ARRIVE_DATE).getTime() -
                  new Date(item.sm_DEPARTURE_DATE).getTime()) /
                  (1000 * 3600 * 24)}
                일
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                서류 마감
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                <span>
                  {item.sm_DOC_CLOSE} ({sm_DOC_CLOSE_day})
                </span>
                <span>{" " + item.sm_DOC_CLOSE_TIME}</span>
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                화물 마감
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                <span>
                  {item.sm_CARGO_CLOSE} ({sm_CARGO_CLOSE_day})
                </span>
                <span>{" " + item.sm_CARGO_CLOSE_TIME}</span>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                예상 출항
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_DEPARTURE_DATE} ({sm_DEPARTURE_DATE_day})
              </div>
              <div className="col-5 col-sm-4 col-xl-2 py-1 px-0 font-weight-bold border-bottom">
                예상 도착
              </div>
              <div className="col-7 col-sm-8 col-xl-4 py-1 px-0 border-bottom">
                {item.sm_ARRIVE_DATE} ({sm_ARRIVE_DATE_day})
              </div>
            </div>
          </div>
          <div
            className="rounded col-12  py-2 px-3 flex-column flex-xl-row align-items-xl-center bg-white"
            style={{ color: "#3B44AC" }}
          >
            <div className="font-weight-bolder">주요 안내사항</div>
            <p dangerouslySetInnerHTML={{ __html: item.sm_REMARK }} />
          </div>
        </div>
      </div>
    </div>
  );
});
