import React, { useCallback, useState } from "react";
import CorpInfoReferenceDetailNav from "./CorpInfoReferenceDetailNav";
import CorpInfoReferenceDetailSummary from "./corpInfoReferenceDetailSummary/CorpInfoReferenceDetailSummary";
import CorpInfoReferenceDetailFinance from "./corpInfoReferenceDetailFinance/CorpInfoReferenceDetailFinance";
import CorpInfoReferenceDetailSales from "./corpInfoReferenceDetailSales/CorpInfoReferenceDetailSales";
// import CorpInfoReferenceDetailPatent from "./corpInfoReferenceDetailPatent/CorpInfoReferenceDetailPatent";

const pageList = [
  {
    key: "CorpInfoReferenceDetailSummary",
    name: "기업개요",
    page: (params) => <CorpInfoReferenceDetailSummary {...params} />,
  },
  {
    key: "CorpInfoReferenceDetailFinance",
    name: "재무정보",
    page: (params) => <CorpInfoReferenceDetailFinance {...params} />,
  },
  {
    key: "CorpInfoReferenceDetailSales",
    name: "영업현황",
    page: (params) => <CorpInfoReferenceDetailSales {...params} />,
  },
];

export default function CorpInfoReferenceDetail(props) {
  const { historyKey, compInfo, isExistData } = props;

  const [pagesOpen, setPagesOpen] = useState({
    CorpInfoReferenceDetailSummary: true,
    CorpInfoReferenceDetailFinance: false,
    CorpInfoReferenceDetailSales: false,
    CorpInfoReferenceDetailPatent: false,
  });

  const handleChangePage = (name, callback) => {
    let openPages = { ...pagesOpen };
    for (let key in openPages) {
      openPages[key] = key === name;
    }
    if (callback) callback();
    setPagesOpen(openPages);
  };

  const MappedComponent = useCallback(
    ({ p_key, index, pagesOpen, compInfo, isExistData }) => {
      if (!pageList[index].page) return <></>;
      return (
        <MappedComponentMemo
          p_key={p_key}
          index={index}
          pagesOpen={pagesOpen}
          compInfo={compInfo}
          isExistData={isExistData}
        />
      );
    },
    []
  );

  const MappedComponentMemo = React.memo(
    ({ p_key, index, pagesOpen, compInfo, isExistData }) => {
      return (
        <div
          key={`corpInfoReference_${index}`}
          style={!pagesOpen[p_key] ? { display: "none" } : { display: "" }}
          className={`main h-100 comp_print_${index}`}
        >
          {pageList[index].page({ compInfo, isExistData })}
        </div>
      );
    }
  );

  return (
    <div className="h-100 d-flex flex-column">
      <CorpInfoReferenceDetailNav
        handleChangePage={handleChangePage}
        pageList={pageList}
        pagesOpen={pagesOpen}
        compInfo={compInfo}
        isExistData={isExistData}
        historyKey={historyKey}
      />
      <div className="flex-grow-1 border border-freiscop p-3">
        {pageList.map((page, index) => {
          return (
            <MappedComponent
              key={index}
              p_key={page.key}
              index={index}
              pagesOpen={pagesOpen}
              compInfo={compInfo}
              isExistData={isExistData}
            />
          );
        })}
      </div>
    </div>
  );
}
