/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter } from "@mui/material";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const mFOOTER_DATA_TYPE = {
  SUM: "SUM",
  AVG: "AVG",
  MAX: "MAX",
  MIN: "MIN",
  NONE: "NONE",
};
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      /*padding: 11,*/
    },
  },
});

let mFilter = { f_depth: 1 };

let headCells = [];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
  dataCell: {
    padding: "3px 0",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}));

// let serverData = [];

const _mClassName = "DashboardFCLReserveTableComponent";
let loginInfo = null;
let userList = [];
let faqChatList = [];

let totalCount = 100;
let totalPageCount = 1;
let page = 0;
let rowsPerPage = 10;
let order = "asc";
let orderBy = "f_name";
let sortModel = [
  {
    field: "f_name",
    dir: "asc",
  },
];

export default forwardRef(function DashboardFCLReserveTableComponent(
  {
    isServerPaging = true,
    isServerSort = true,
    cellClick,
    site_data,
    CtmHeadCells,
    orderData,
    rowsPerPage = 10,
  },
  ref
) {
  const classes = useStyles();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const editModal = useRef();

  // const [filters, setFilters] = useState({});

  if (CtmHeadCells) {
    headCells = CtmHeadCells;
  }
  if (orderData) {
    order = orderData[0].dir;
    orderBy = orderData[0].field;
    sortModel = orderData;
  }

  useEffect(() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      if (loginData["returnData"].length) {
        loginInfo = { ...loginData["returnData"][0] };
        setIsNeedUpdate(!isNeedUpdate);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n.ret) {
          loginInfo = { ...n["returnData"][0] };
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    requestServerData(params) {
      mFilter = { ...mFilter, ...params };

      requestTableData();
    },
  }));

  const requestTableData = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/dashboard/JS_dashboard_fcl_reserve_per_day.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      f_depth: 1,
      ...mFilter,
    };
    //SITE_CD: site_data.s_ID,

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let returnData = newData["returnData"];
          /*
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
*/

          for (let i = 0; i < headCells.length; i++) {
            if (headCells[i].footerDataLogic) {
              for (let j = 0; j < returnData.length; j++) {
                switch (headCells[i].footerDataLogic) {
                  case mFOOTER_DATA_TYPE.SUM:
                    if (j === 0) {
                      headCells[i].footerData = 0;
                    }
                    headCells[i].footerData += parseInt(
                      returnData[j][headCells[i].field],
                      10
                    );
                    break;
                }
              }
            }
          }
          for (let j = 0; j < returnData.length; j++) {
            returnData[j]["CNT_PER"] = 0;
            if (headCells[1].footerData > 0) {
              if (parseInt(returnData[j]["f_count"], 10) > 0) {
                returnData[j]["CNT_PER"] =
                  parseInt(
                    (parseInt(returnData[j]["f_count"], 10) /
                      headCells[1].footerData) *
                      10000,
                    10
                  ) / 100;
              }
            }
          }
          setServerData(returnData);
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    // console.log(event, newPage);
    page = newPage;
    requestTableData();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    orderBy = property;
    sortModel = [
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ];
    requestTableData();
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeEmployee(e, params) {
    editModal.current.removeEmployee(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table" size={dense ? "small" : "medium"}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          // onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeEmployee={removeEmployee}
                  cellClick={cellClick}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeEmployee={removeEmployee}
                  cellClick={cellClick}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeEmployee={removeEmployee}
                  cellClick={cellClick}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEdit={openEdit}
                removeEmployee={removeEmployee}
                cellClick={cellClick}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <EnhancedTableFooter />
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            // console.log(value - 1); //apiRef.current.setPage(value - 1)
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
});

function EnhancedTableFooter(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  return (
    <TableFooter>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderFooterCell") ? ( // headCell에 "renderCell" 속성 포함여부 확인
              headCell.renderFooterCell(headCell.footerData)
            ) : typeof headCell.footerData === "number" ? (
              <span className="text-truncate text-wrap text-break font-weight-bold">
                {util.addCommas(headCell.footerData)}
              </span>
            ) : (
              <span className="text-truncate text-wrap text-break font-weight-bold">
                {headCell.footerData}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableFooter>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  //정렬 변경
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={
                headCell.field === "USER_NM"
                  ? () => {} // db에 USER_NM이 없어서 서버 sort불가
                  : createSortHandler(headCell.field)
              }
              className="font-weight-bold"
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const {
    row,
    openEdit,
    removeEmployee,
    isMultiSelect = false,
    cellClick,
    index,
  } = props;
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    // console.log(event, name);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      onClick={(event) => handleClick(event, row)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={index}
      // selected={isItemSelected}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.field + index}
          align={headCell.numeric ? "right" : "left"} //text 정렬
          padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          width={headCell.minWidth}
          className={classes.dataCell}
          onClick={() => {
            cellClick(row);
          }}
        >
          {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
                removeEmployee: removeEmployee,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
                removeEmployee: removeEmployee,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};
