import React from "react";
import StatementOfFinancialPosition from "./components/StatementOfFinancialPosition";
import IncomeStatement from "./components/IncomeStatement";
import InfoStability from "./components/InfoStability";

export default function CorpInfoReferenceDetailFinance(props) {
  const { compInfo, isExistData, isPrint } = props;

  const sectionProps = {
    compInfo,
    isExistData,
    isPrint,
  };

  return (
    <div>
      <StatementOfFinancialPosition {...sectionProps} />
      <IncomeStatement {...sectionProps} />
      <InfoStability {...sectionProps} />
    </div>
  );
}
