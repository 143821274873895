import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import ScheduleManageTable from "./ScheduleManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleManage";

let deleteCount = 0;
let deleteSearchStartDate = "";
let deleteSearchEndDate = "";
let deleteSearchCloseLevel = "";
let tempDate = new Date();
tempDate.setDate(new Date().getDate() - new Date().getDay());

export default function ScheduleManage(props) {
  const { searchCodeList, loginInfo, pagesOpen } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isSearchCreate, setIsSearchCreate] = useState(false);
  const [searchStartDate, setSearchStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );

  const [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  const $controller = {
    searchText: useRef(),
    searchLevel: useRef(),
    files: useRef(),
    searchStartDate: useRef(),
    searchEndDate: useRef(),
    searchCloseLevel: useRef(),
    sm_FCL_LCL: useRef(),
    EMPLOYEE_TYPE: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const htmlID = `customFile_${util.makeUUIDv4Short()}`;

  //신규등록
  const handleNewData = () => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const handleDeleteCount = (value) => {
    deleteCount = value;
  };

  //스케줄 다중 삭제
  const SEARCH_SCHEDULE_ALL_DELETE = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      file: "/schedule/JS_schedule_delete.php",
      data: {},
      searchStartDate: deleteSearchStartDate,
      searchEndDate: deleteSearchEndDate,
      searchCloseLevel: deleteSearchCloseLevel,
    };
    codeList.Modal.current.confirm(
      `현재 검색된 ${deleteCount}건의 스케줄을 전부 삭제하시겠습니까?`,
      (ret) => {
        if (!ret) return;
        socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
          if (newData) {
            if (newData["ret"] === true) {
              socket.sendLocalMessage(
                MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED,
                _mClassName,
                newData,
                () => {}
              );
            }
            codeList.Modal.current.alert(newData["MSG"], () => {});
            deleteSearchStartDate = "";
            deleteSearchEndDate = "";
            deleteSearchCloseLevel = "";
            setIsSearchCreate(false);
          }
        });
      }
    );
  };

  //검색필터 데이터 변경
  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $controller.searchText.current.value,
      SEARCH_LEVEL: $controller.searchLevel.current.value,
      searchStartDate: $controller.searchStartDate.current.value,
      searchEndDate: $controller.searchEndDate.current.value,
      searchCloseLevel: $controller.searchCloseLevel.current.value,
      sm_FCL_LCL: $controller.sm_FCL_LCL.current.value,
    };

    if (isSearchCreate) {
      filters.searchStartDate = `${filters.searchStartDate.replace(
        "T",
        " "
      )}:00`;
      filters.searchEndDate = `${filters.searchEndDate.replace("T", " ")}:00`;
    }

    deleteSearchStartDate = filters.searchStartDate;
    deleteSearchEndDate = filters.searchEndDate;
    deleteSearchCloseLevel = filters.searchCloseLevel;
    socket.sendLocalMessage(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };
  //엑셀 대용량 업로드
  const uploadExcel = (e) => {
    let file = e.target.files[0],
      name = file.name,
      size = file.size,
      type = file.type;

    if (validation(name, size, type)) {
      requestExcelUpload(e.target.files, function (ret, msg) {
        let type = "error";
        if (ret === "Y") {
          codeList.Modal.current.alert(msg, () => {
            $controller.files.current.value = "";
            searchFilterChanged();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요.",
            () => {
              console.log(msg);
            }
          );
        }
      });
    }
  };
  //엑셀 대용량 업로드 통신
  const requestExcelUpload = (fileData, callback) => {
    const formData = new FormData();
    formData.append("files", fileData[0]);

    axios
      .post(
        "//www.gbts.co.kr/backside/schedule/JS_schedule_excel_import.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.RESULT === "Y") {
          callback(response.data.RESULT, response.data.MSG);
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };
  // 엑셀파일 업로드 유효성 검사
  const validation = (name, size, type) => {
    let reg = /\.(xls|xlsx)$/i;

    if (!reg.test(name)) {
      codeList.Modal.current.alert(
        "지원하지 않는 형식 입니다.\nExcel 파일만 가능합니다.",
        () => {}
      );
      return false;
    }
    if (size > 1000000 * 10) {
      codeList.Modal.current.alert("용량은 10MB가 이하로 해주세요.", () => {});
      return false;
    }
    return true;
  };

  const searchEmployee = (value) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED,
      _mClassName,
      {
        EMPLOYEE_TYPE: value,
        USER_ID: loginInfo.user_id,
      },
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-end"
                  role="toolbar"
                >
                  <div>
                    <button
                      className={`btn btn-danger mb-2 mr-2 mb-md-0 ${
                        isSearchCreate ? "d-inline-block" : "d-none"
                      }`}
                      onClick={() => SEARCH_SCHEDULE_ALL_DELETE()}
                    >
                      전체삭제
                    </button>
                    <button
                      className="btn btn-outline-primary outline mr-0 mr-md-2 mb-2 mb-md-0"
                      onClick={() => handleNewData()}
                      data-toggle="modal"
                      data-target="#ScheduleManageEdit"
                      aria-label={"신규등록"}
                    >
                      단건신규등록
                    </button>
                  </div>
                  <input
                    type="file"
                    className="d-none"
                    id={htmlID}
                    ref={$controller.files}
                    onChange={(e) => {
                      uploadExcel(e);
                    }}
                  />
                  <label
                    className="btn btn-secondary mb-2 mb-md-0"
                    htmlFor={htmlID}
                  >
                    엑셀 업로드
                  </label>
                </div>

                <div
                  className="btn-toolbar mb-3 justify-content-end"
                  role="toolbar"
                >
                  {(loginInfo.isConsole || loginInfo.isAdmin) && (
                    <div className=" mb-2">
                      <select
                        className="custom-select form-control"
                        ref={$controller.EMPLOYEE_TYPE}
                        onChange={(e) => searchEmployee(e.target.value)}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-담당 정보-</option>
                        <option value="sm_PROVIDER_ID">
                          내 업무 담당 스케쥴
                        </option>
                        <option value="sm_PROVIDER_SALES_ID">
                          내 영업 담당 스케쥴
                        </option>
                      </select>
                    </div>
                  )}
                  <div className=" mb-2 ml-2">
                    <select
                      className="custom-select form-control"
                      ref={$controller.sm_FCL_LCL}
                      onChange={(e) => searchFilterChanged(e.target.value)}
                      style={{ minWidth: 150 }}
                    >
                      <option value="">-운송 분류-</option>
                      <option value="FCL">FCL</option>
                      <option value="LCL">LCL</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <div className="input-group mb-2 mb-md-0 mx-0 mx-md-2">
                      <select
                        className="custom-select form-control"
                        ref={$controller.searchCloseLevel}
                        onChange={(e) => {
                          if (e.target.value === "sm_CREATE_DATE") {
                            setIsSearchCreate(true);
                          } else {
                            setIsSearchCreate(false);
                          }
                          searchFilterChanged();
                        }}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">- 마감일자 -</option>
                        <option value="sm_DOC_CLOSE">서류마감</option>
                        <option value="sm_CARGO_CLOSE">화물마감</option>
                        <option value="sm_DEPARTURE_DATE">출발일자</option>
                        <option value="sm_ARRIVE_DATE">도착일자</option>
                        <option value="sm_CREATE_DATE">등록일자</option>
                      </select>
                      <input
                        className="form-control"
                        type={isSearchCreate ? "datetime-local" : "date"}
                        value={searchStartDate}
                        ref={$controller.searchStartDate}
                        style={{ minWidth: 220 }}
                        onChange={(e) => {
                          setSearchStartDate(e.target.value);
                          if (e.target.value > searchEndDate) {
                            setSearchEndDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                      <span className="input-group-append mb-2">
                        <div className="input-group-text">-</div>
                      </span>
                      <input
                        className="form-control border-left-0"
                        type={isSearchCreate ? "datetime-local" : "date"}
                        value={searchEndDate}
                        ref={$controller.searchEndDate}
                        style={{ minWidth: 220 }}
                        onChange={(e) => {
                          setSearchEndDate(e.target.value);
                          if (e.target.value < searchStartDate) {
                            setSearchStartDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                    </div>

                    <div className="input-group mb-2">
                      <select
                        className="custom-select form-control"
                        ref={$controller.searchLevel}
                        onChange={() => searchFilterChanged()}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-미선택-</option>
                        <option value="sm_UID">관리번호</option>
                        <option value="sm_ORIGIN">출발항</option>
                        <option value="sm_DEST">도착항</option>
                        <option value="sm_FWD_NM">포워더명</option>
                        <option value="sm_VESSEL">선박</option>
                        <option value="sm_VOY">항차</option>
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={$controller.searchText}
                      />

                      <button
                        className="input-group-text btn btn-primary rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                        onClick={() => searchFilterChanged()}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <ScheduleManageTable
              {...props}
              handleDeleteCount={handleDeleteCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
