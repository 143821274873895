import React from "react";
import SectionTitle from "../../SectionTitle";

export default React.memo(CorpCreditInfo);
function CorpCreditInfo({ compInfo, isExistData }) {
  let mappedData = {
    PAYMENT_PERIOD: "-", //결제기간 일수
    SHORT_TERM_OVERDUE_YN: "-", //단기연체중여부
    SHORT_TERM_OVERDUE_HISTORY: "-", //단기연체 내역 여부
    STOP_TRADING_YN: "-", //당좌거래 정지 중 여부
    FINANCIAL_COST: "-", //세전이익/금융비용
  };
  let data = [];

  if (isExistData && compInfo !== null) {
    data = [
      ...compInfo["2000401"],
      ...compInfo["2000402"],
      ...compInfo["2000406"],
      ...compInfo["3000407"],
      ...compInfo["4000020"],
    ];

    for (let item of data) {
      switch (item.item_nm) {
        case "단기연체중 여부":
          mappedData["SHORT_TERM_OVERDUE_YN"] = item.item_val;
          break;
        case "최근 3개월 내 30일 이상 단기연체가 발생했거나 10일 이상의 단기연체가 4건 이상 발생 여부":
          mappedData["SHORT_TERM_OVERDUE_HISTORY"] = item.item_val;
          break;
        case "당좌거래 정지 중 여부":
          mappedData["STOP_TRADING_YN"] = item.item_val;
          break;
        case "결제기간 일수":
          mappedData["PAYMENT_PERIOD"] = item.item_val;
          break;
        case "세전이익/금융비용":
          mappedData["FINANCIAL_COST"] =
            Math.round(item.item_val * 10000) / 100 + "%";
          break;
      }
    }
  }

  const titleClass =
    " m-0 p-2 bg-freiscop-10 text-center border-bottom border-freiscop font-weight-bold";
  const descClass = "m-0 p-2 border-bottom border-freiscop";
  return (
    <div className="mb-5">
      <SectionTitle title="신용관리정보" />
      <div className="container-fluid border-top border-bottom border-freiscop m-0 p-0">
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>단기연체중 여부</div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["SHORT_TERM_OVERDUE_YN"]}
          </div>
          <div className={`col-4 col-md-2 ${titleClass}`}>
            당좌거래 정지 중 여부
          </div>
          <div className={`col-8 col-md-4  ${descClass}`}>
            {mappedData["STOP_TRADING_YN"]}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass}`}>
            3개월이내 단기연체 발생 여부
          </div>
          <div className={`col-8 col-md-10 ${descClass}`}>
            {mappedData["SHORT_TERM_OVERDUE_HISTORY"]}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className={`col-4 col-md-2 ${titleClass} border-bottom-0`}>
            결제기간 일수
          </div>
          <div className={`col-8 col-md-4  ${descClass} border-bottom-0`}>
            {mappedData["PAYMENT_PERIOD"]}
          </div>
          <div className={`col-4 col-md-2 ${titleClass} border-bottom-0`}>
            세전이익/금융비용
          </div>
          <div className={`col-8 col-md-4  ${descClass} border-bottom-0`}>
            {mappedData["FINANCIAL_COST"]}
          </div>
        </div>
      </div>
      <p className="mt-2">
        <span className="font-weight-bold">※ 3개월이내 단기연체 발생 여부</span>
        : 최근 3개월 내 30일 이상 단기연체가 발생했거나 10일 이상의 단기연체가
        4건 이상 발생 여부
      </p>
    </div>
  );
}
