import React, { useEffect, useState, useRef } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "PopupMain";
const today = new Date();
let isMobileBack = false;
let loginInfo = null;

export default function PopupMain() {
  const [isShow, setIsShow] = useState(true);
  const [isMobile, setIsMobile] = useState(false); //760 이하를 모바일로봄
  const closeToday = useRef();
  const closeWeek = useRef();

  useEffect(() => {
    isMobileCheck();
    window.addEventListener("resize", isMobileCheck);

    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData["returnData"][0];
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          loginInfo = n["returnData"][0];
        }
      }
    );

    return () => {
      window.removeEventListener("resize", isMobileCheck);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  const isMobileCheck = () => {
    let windowWidth = window.innerWidth;
    let check = windowWidth <= 760;
    if (isMobileBack !== check) {
      isMobileBack = check;
      setIsMobile(check);
    }
  };

  const FncDisableDateSet = (addDate = 0) => {
    let setDate = today.getDate() + addDate;
    let disableDate = new Date();
    disableDate.setDate(setDate);
    disableDate = disableDate.toLocaleDateString();
    return disableDate;
  };

  const popupClose = () => {
    if (closeToday.current.checked) {
      let disableDate = FncDisableDateSet(1);
      localStorage.setItem("PopupMain", disableDate);
    }

    if (closeWeek.current.checked) {
      let disableDate = FncDisableDateSet(7);
      localStorage.setItem("PopupMain", disableDate);
    }

    setIsShow(false);
  };

  return (
    <>
      {isShow && (
        <div
          className="position-absolute shadow border rounded text-white NNS"
          style={{
            maxWidth: 500,
            ...(isMobile
              ? {
                  top: 80,
                  left: 0,
                }
              : { top: 80, left: 200 }),
            zIndex: 99,
          }}
        >
          <div className="position-relative bg-white" style={{ zIndex: 999 }}>
            <div className="px-3 pt-3 position-absolute" style={{ right: 0 }}>
              <div className="text-right">
                <span
                  className="h4 font-weight-bold mb-0 cursor_pointer text-dark"
                  onClick={() => popupClose()}
                >
                  &times;
                </span>
              </div>
            </div>
            <button
              className="border-0 bg-freiscop px-3 py-1 px-lg-4 py-lg-2 mt-sm-2 rounded-pill text-white font-weight-bold position-absolute"
              style={{
                top: "69%",
                left: "50%",
                transform: "translateX(-50%)",
                translate: ".3",
              }}
              onClick={() => {
                if (!loginInfo) {
                  codeList.Modal.current.alert("로그인이 필요한 서비스입니다.");
                  return;
                }

                let oceanlookWebToken =
                  "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

                let popup = "oceanlookForm";
                //let url = "https://map.oceanlook.net/#/login?token=" + oceanlookWebToken;
                let url =
                  "https://www.oceanlook.net/v3/login?token=" +
                  oceanlookWebToken;
                let options =
                  "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
                window.open(url, popup, options);
              }}
            >
              바로가기
              <i className="fa-solid fa-angles-right ml-2" />
            </button>
            <img
              src="/img/main/popup/korea_fowerder_the_times.png"
              width={"100%"}
            />
            <div className="checkboxes bg-white text-dark p-2 d-flex flex-row justify-content-end border-top position-relative">
              <label
                className="container_check mb-0 d-flex flex-row align-items-center mr-2"
                htmlFor="fregiscopLaunchingInput1"
              >
                일주일간 열지 않기
                <input
                  id="fregiscopLaunchingInput1"
                  className="form-check-input"
                  type="checkbox"
                  ref={closeWeek}
                />{" "}
                <span className="checkmark" />
              </label>
              <label
                className="container_check mb-0 d-flex flex-row align-items-center"
                htmlFor="fregiscopLaunchingInput2"
              >
                오늘하루 열지 않기
                <input
                  id="fregiscopLaunchingInput2"
                  className="form-check-input"
                  type="checkbox"
                  ref={closeToday}
                />{" "}
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
