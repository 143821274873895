import React, { useRef } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";

const socket = new BuddibleSocket();
const _mClassName = "AirlineManageHeader";

export default function AirlineManageHeader() {
  const $controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    COMP_TYPE: useRef(),
  };

  const addNewData = () => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_AIRLINE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = () => {
    const filters = {
      SEARCH_LEVEL: $controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: $controller.SEARCH_WORD.current.value,
      COMP_TYPE: $controller.COMP_TYPE.current.value,
    };

    socket.fireLocalEventListener(
      MsgIDList.EVENT_AIRLINE_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <div className="card-header">
      <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
        <div
          className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
          role="toolbar"
        >
          <div
            className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
            role="group"
          >
            <button
              className="btn  btn-outline-primary "
              data-toggle="modal"
              data-target="#airlineManageEdit"
              onClick={addNewData}
              aria-label={"신규등록"}
              type="button"
            >
              <i className="fas fa-plus" /> 신규등록
            </button>
          </div>

          <div className="flex-fill d-flex flex-row justify-content-end align-items-center mb-2 bm-sm-0 order-1 order-md-1 order-lg-1">
            <select
              className="custom-select form-control ml-2"
              ref={$controller.COMP_TYPE}
              onChange={searchFilterChanged}
              style={{ width: 150 }}
            >
              <option value="">-업체구분-</option>
              <option value="A">항공사</option>
              <option value="P">공급사</option>
            </select>

            <div className="input-group ml-2">
              <select
                className="custom-select form-control"
                ref={$controller.SEARCH_LEVEL}
                onChange={searchFilterChanged}
                style={{ minWidth: 150 }}
              >
                <option value="AL_COMP_CD">업체코드</option>
                <option value="AL_COMP_NM">상호명(국문)</option>
                <option value="AL_COMP_NM_EN">상호명(영문)</option>
              </select>

              <input
                type="text"
                className="form-control"
                placeholder="검색어"
                aria-label="검색어"
                aria-describedby="btnGroupAddon2"
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    searchFilterChanged(e);
                  }
                }}
                ref={$controller.SEARCH_WORD}
              />

              <button
                className="input-group-text btn btn-primary"
                onClick={searchFilterChanged}
              >
                <i className="fas fa-search mr-2" />
                검색
              </button>
            </div>
          </div>
        </div>
      </h6>
    </div>
  );
}
