/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import SearchLogisticsTable from "./SearchLogisticsTable";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();
const socket = new BuddibleSocket();

const _mClassName = "SearchLogisticsTableParent";

const BUSINESS_TYPE_B = [
  { key: "B03", name: "선사" },
  { key: "B02", name: "콘솔사" },
  { key: "B01", name: "포워더" },
];
const BUSINESS_TYPE = [
  { key: "C", name: "운송사" },
  { key: "D", name: "보험사" },
  { key: "E", name: "관세사" },
  { key: "F", name: "보세창고" },
  { key: "Z", name: "GBTS 관리자" },
];

let filters = { COMP_GB: "B", COMP_GB_SUB2: "B03" };
let bigCd = "";
let comp_custom_info_list = {};
let isLoading = true;

export default SearchLogisticsTableParent;
function SearchLogisticsTableParent(props) {
  const { isMobile } = props;
  const [selectBusiness, setSelectBusiness] = useState("B");
  const [serverSubData, setServerSubData] = useState([]);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  let $searchText = useRef();
  let $searchLevel = useRef();

  useEffect(() => {
    SELECT_CUSTOM_COMP_INFO();
  }, []);

  const SELECT_CUSTOM_COMP_INFO = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_list.php",
      msgID: msgID,
      data: [],
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            let data = newData["returnData"][i];
            comp_custom_info_list[data.COMP_CD] = { ...data };
          }
          isLoading = false;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const changeSelectedBusiness = (key) => {
    if (key.length < 3) {
      filters = {
        COMP_GB: key,
      };
    } else {
      filters = {
        COMP_GB_SUB2: key,
      };
    }
    if (key === "B") {
      bigCd = "CTB";
    } else if (key === "F") {
      bigCd = "CTF";
    } else {
      bigCd = "";
    }

    if (bigCd !== "") {
      requestCompList();
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
    setSelectBusiness(key);
  };

  const requestCompList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/app/JS_comp_sub_list.php",
      msgID: msgID,
      data: [],
      bigCd: bigCd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerSubData(newData["returnData"]);
        }
      }
    });
  };

  const selectCompSubType = (e) => {
    let val = e.target.value;
    filters = {
      ...filters,
      COMP_GB_SUB2: val,
    };

    if (selectBusiness !== "") {
      filters.COMP_GB = selectBusiness;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    filters = {
      ...filters,
      searchType: $searchLevel.current.value,
      searchWord: $searchText.current.value,
      // [$searchLevel.current.value]: $searchText.current.value,
    };

    if (selectBusiness !== "") {
      filters.COMP_GB = selectBusiness;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE,
      _mClassName,
      filters,
      () => {}
    );
    // $searchLevel.current.value = "";
    // $searchText.current.value = "";
  };

  return (
    <>
      {!isLoading && (
        <div className="container-fluid">
          <div className="content">
            <div className="row">
              <div className="card w-100">
                <div style={{ padding: ".75rem 1.25rem" }}>
                  <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                    <div
                      className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                      role="toolbar"
                    >
                      <div className="d-flex flex-wrap flex-row flex-wrap align-items-center">
                        {BUSINESS_TYPE_B.map((bType, index) => {
                          return (
                            <a
                              key={bType.key}
                              className={`btn font-weight-bold mb-2 ${
                                BUSINESS_TYPE.length - 1 !== index
                                  ? "mr-2 "
                                  : ""
                              }${
                                filters.COMP_GB_SUB2 === bType.key
                                  ? "btn-primary"
                                  : "btn-outline-primary"
                              }`}
                              onClick={() => {
                                changeSelectedBusiness(bType.key);
                              }}
                            >
                              {bType.name}
                            </a>
                          );
                        })}{" "}
                        {BUSINESS_TYPE.map((bType, index) => {
                          return (
                            <a
                              key={bType.key}
                              className={`btn  font-weight-bold mb-2 ${
                                BUSINESS_TYPE.length - 1 !== index
                                  ? "mr-2 "
                                  : ""
                              }${
                                filters.COMP_GB === bType.key
                                  ? "btn-primary"
                                  : "btn-outline-primary"
                              }`}
                              onClick={() => {
                                changeSelectedBusiness(bType.key);
                              }}
                            >
                              {bType.name}
                            </a>
                          );
                        })}
                      </div>

                      <div className=" input-group order-1 order-md-1 order-lg-1 mb-2 bm-sm-0">
                        {bigCd !== "" ? (
                          <select
                            className="input-group-append form-control border mb-1"
                            style={{ minWidth: 150 }}
                            onChange={(e) => selectCompSubType(e)}
                          >
                            <option value="">업종상세</option>
                            {serverSubData &&
                              serverSubData.map((item, index) => {
                                return (
                                  <option
                                    key={item.cIDX}
                                    label={item.cNAME}
                                    value={item.cSMALLCD}
                                  >
                                    {item.cNAME}
                                  </option>
                                );
                              })}
                          </select>
                        ) : (
                          ""
                        )}
                        <select
                          className="input-group-append form-control border mb-1"
                          ref={$searchLevel}
                          style={{ minWidth: 150 }}
                        >
                          <option value="">검색조건</option>
                          <option value="COMP_CD">업체코드</option>
                          <option value="COMP_NUM">사업자등록번호</option>
                          <option value="COMP_NM">업체명</option>
                        </select>
                        <input
                          type="text"
                          className="form-control border h-100 mb-1"
                          style={{ minWidth: 200, maxHeight: 40 }}
                          placeholder="검색어를 입력해 주세요"
                          aria-label="검색어"
                          aria-describedby="btnGroupAddon2"
                          onKeyPress={(e) => {
                            if (e.charCode === 13) {
                              searchFilterChanged(e);
                            }
                          }}
                          ref={$searchText}
                        />

                        <div
                          className="input-group-prepend btn btn-primary d-flex align-items-center h-100 mb-1"
                          style={{
                            maxHeight: 40,
                            borderRadius: "0px 3px 3px 0",
                          }}
                          onClick={searchFilterChanged}
                        >
                          <i className="fas fa-search mr-2" />
                          검색
                        </div>
                      </div>
                    </div>
                  </h6>
                </div>
                <div className="card-body p-0">
                  <SearchLogisticsTable
                    isMobile={isMobile}
                    comp_custom_info_list={comp_custom_info_list}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
