import React, { useCallback, useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import ScheduleLogiWorkAIRWeekTable from "./ScheduleLogiWorkAIRWeekTable";
import $ from "jquery";

const socket = new BuddibleSocket();
const util = new Utilities();
const useRowStyles = makeStyles({
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
});

const _mClassName = "ScheduleLogiWorkAIRTable";
export default function ScheduleLogiWorkAIRTable({
  login_info,
  page,
  totalPageCount,
  schedule_list,
  selectWeekDateList,
  openCostPopup,
  sendUseHistory,
  changedPage,
}) {
  const classes = useRowStyles();

  //스케줄 아이템 랜더
  const ScheduleItemRender = useCallback((props) => {
    return <ScheduleItem {...props} />;
  }, []);

  return (
    <>
      <div className="col-12 m-0 p-0 d-none d-xl-block rounded-top-left rounded-top-right overflow-hidden">
        <div className="row m-0 p-0 bg-secondary text-white text-xl-center">
          <div className="col-1 px-2 py-1">Detail.</div>
          <div className="col-1 px-2 py-1">운항 월/형식</div>
          <div className="col-1 px-2 py-1">항공사</div>
          <div className="col-1 px-2 py-1">공급사</div>
          <div className="col-3 px-2 py-1 text-xl-left">출발지</div>
          <div className="col-2 px-2 py-1 text-xl-left">운송수단</div>
          <div className="col-3 px-2 py-1 text-xl-left">도착지</div>
        </div>
      </div>

      <div className="col-12 m-0 p-0 rounded-bottom-left rounded-bottom-right">
        {schedule_list.length > 0 &&
          schedule_list.map((item, index) => {
            const openedKey = `schedule_${item.sm_POL_CODE}_${item.sm_POD_CODE}_${index}`;

            return (
              <ScheduleItemRender
                key={`schedule_${item.sm_POL_CODE}_${item.sm_POD_CODE}_${index}`}
                openedKey={openedKey}
                item={item}
                index={index}
                login_info={login_info}
                openCostPopup={openCostPopup}
                sendUseHistory={sendUseHistory}
                selectWeekDateList={selectWeekDateList}
              />
            );
          })}
      </div>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            changedPage(value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </>
  );
}

const ScheduleItem = React.memo(
  ({
    item,
    index,
    openedKey,
    login_info,
    openCostPopup,
    sendUseHistory,
    selectWeekDateList,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    let isRadius = index === 9;

    useEffect(() => {
      $(".open_sch_info_" + openedKey).click((e) => {
        $("#collapse_btn_" + openedKey).click();
      });

      $("#collapse_btn_" + openedKey).click((e) => {
        let value = "true" !== e.target.ariaExpanded;
        setIsOpen(value);
      });
    }, []);

    return (
      <>
        <div
          className="col-12 p-0 m-0"
          onClick={() => sendUseHistory("info", 1, item)}
        >
          <div
            className={`w-100 row bg-white m-0 p-0 align-items-center text-center ${
              isRadius
                ? "rounded-bottom-left rounded-bottom-right"
                : "border-bottom"
            }`}
          >
            <div className={`col-2 col-xl-1 m-0 p-2`}>
              {item.sm_PROMO_YN === "Y" && (
                <div
                  className="d-none d-xl-block ribbon_promo up"
                  style={{ "--color": "#f33220", left: 0 }}
                >
                  <div className="content">
                    <span>P</span>
                  </div>
                </div>
              )}
              <div
                className="position-relative mr-1 hover_point text-xl-center"
                data-toggle="collapse"
                href={`#collapse_${openedKey}`}
                role="button"
                aria-expanded="false"
                aria-controls={`#collapse_${openedKey}`}
                id={`collapse_btn_${openedKey}`}
                style={item.sm_REMARK ? { cursor: "pointer" } : {}}
              >
                <span className="text-center p-0 m-0">
                  <span className="h5 text-freiscop cursor_pointer">
                    {isOpen ? (
                      <i className="fa-solid fa-square-caret-up" />
                    ) : (
                      <i className="fa-solid fa-square-caret-down" />
                    )}
                  </span>
                </span>

                <div
                  className="position-absolute bg-white border shadow-sm p-2 rounded text-center font-weight-bold"
                  style={{
                    minWidth: 180,
                    bottom: "calc(100% + 20px)",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 9,
                  }}
                >
                  클릭시 행당 경로의 주간 스케줄을 확인 할 수 있습니다
                  <div
                    className="bg-white border-right border-bottom"
                    style={{
                      transform: "rotate(45deg)",
                      marginLeft: -10,
                      width: 20,
                      height: 20,
                      position: "absolute",
                      left: "50%",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`open_sch_info_${openedKey} col-2 col-xl-1 m-0 p-2`}
            >
              <p className="d-block d-xl-none font-weight-bold font-size-08 mb-2">
                운항 월/형식
              </p>
              <div className="font-weight-bold cursor_pointer d-flex flex-row justify-content-center">
                {item.sm_DEPARTURE_DATE.substring(0, 7)} <br />
                {item.sm_CARGO_YN === "Y" ? "화물전용" : "화물/승객"}
              </div>
            </div>
            <div
              className={`open_sch_info_${openedKey} col-4 col-xl-1 m-0 p-2 d-flex flex-column align-items-center`}
            >
              <p className="d-block d-xl-none font-weight-bold font-size-08 mb-2">
                항공사
              </p>
              <div
                style={{
                  minHeight: 30,
                  width: 80,
                  background: `url(https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/chatRoom/Z0001/buddible@buddible.com/20230420111829_IMG_1129.jpg) 50% 50% / contain no-repeat`,
                }}
              />
            </div>
            <div
              className={`open_sch_info_${openedKey} col-4 col-xl-1 m-0 p-2 d-flex flex-column align-items-center`}
            >
              <p className="d-block d-xl-none font-weight-bold font-size-08 mb-2">
                공급사
              </p>
              <div
                style={{
                  minHeight: 30,
                  width: 80,
                  background: `url(https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/chatRoom/Z0001/buddible@buddible.com/20230420111829_IMG_1128.jpg) 50% 50% / contain no-repeat`,
                }}
              />
            </div>
            <div
              className={`open_sch_info_${openedKey} col-4 col-xl-3 m-0 p-2 text-xl-left`}
            >
              <div className="d-block d-xl-none font-weight-bold font-size-08 mb-2">
                출발지
              </div>
              <div className="font-weight-bold">{item.sm_ORIGIN}</div>
            </div>
            <div
              className={`open_sch_info_${openedKey} col-4 col-xl-2 m-0 py-2 text-freiscop  text-xl-left`}
            >
              <h5 className="m-0">
                <i className="fa-solid fa-ellipsis" />{" "}
                <i className="fa-solid fa-plane" />{" "}
                <i className="fa-solid fa-ellipsis" />
              </h5>
            </div>
            <div
              className={`open_sch_info_${openedKey} col-4 col-xl-3 m-0 p-2 text-xl-left`}
            >
              <div
                className="d-block d-xl-none font-weight-bold"
                style={{ fontSize: "0.8em" }}
              >
                도착지
              </div>
              <div className="font-weight-bold">{item.sm_DEST}</div>
            </div>

            <div
              className="collapse col-12 m-0 p-0 order-6 order-xl-7"
              id={`collapse_${openedKey}`}
            >
              <ScheduleAirDetailInfo
                item={item}
                isOpen={isOpen}
                openedKey={openedKey}
                login_info={login_info}
                openCostPopup={openCostPopup}
                selectWeekDateList={selectWeekDateList}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);

const handleScheduleData = (dataList) => {
  let tempList = [];

  for (let i = 0; i < dataList.length; i++) {
    let data = dataList[i];
    for (let key in data) {
      if (key.startsWith("sm_INFO")) {
        if (data[key]) {
          let splitData = data[key].split("|");
          let sm_INFO = {
            FLIGHT_NAME: "",
            AIR_FREIGHT_NO: "",
            AIR_FREIGHT_MEMO: "",
            DEPARTURE_TIME: "",
            AIRRIVE_TIME: "",
          };

          sm_INFO["FLIGHT_NAME"] = splitData[0];

          if (data.sm_VESSEL) {
            sm_INFO["AIR_FREIGHT_NO"] = data.sm_VESSEL;
          }

          for (let i = 0; i < splitData.length; i++) {
            let data = splitData[i];
            if (i === 0) {
              sm_INFO["FLIGHT_NAME"] = splitData[0];
              continue;
            }

            if (data.length === 4) {
              sm_INFO["AIR_FREIGHT_NO"] = data;
              continue;
            }
            if (data.indexOf("/") > -1) {
              let flightTime = data;
              let flightTimeSplit = undefined;
              if (flightTime.indexOf("+")) {
                flightTime = flightTime.split("+")[0];
              }
              flightTimeSplit = flightTime.split("/");
              let depTime = flightTimeSplit[0].split("");
              let airTime = flightTimeSplit[1].split("");
              sm_INFO[
                "DEPARTURE_TIME"
              ] = `${depTime[0]}${depTime[1]}:${depTime[2]}${depTime[3]}`;
              sm_INFO[
                "AIRRIVE_TIME"
              ] = `${airTime[0]}${airTime[1]}:${airTime[2]}${airTime[3]}`;
            } else {
              if (sm_INFO["AIR_FREIGHT_MEMO"] === "") {
                sm_INFO["AIR_FREIGHT_MEMO"] = data;
              } else {
                sm_INFO[
                  "AIR_FREIGHT_MEMO"
                ] = `${sm_INFO["AIR_FREIGHT_MEMO"]} \n* ${data}`;
              }
            }
          }

          data[key] = sm_INFO;
        } else {
          data[key] = null;
        }
      }
    }

    let temp = { ...data };
    tempList.push(temp);
  }

  return tempList;
};

const ScheduleAirDetailInfo = ({
  item,
  isOpen,
  openedKey,
  openCostPopup,
  selectWeekDateList,
  login_info,
}) => {
  const [weekData, setWeekData] = useState([]);
  const [isSearch, setIsSearch] = useState(true);

  useEffect(() => {
    if (isOpen && weekData.length < 1) {
      requestUserSelect();
    }
  }, [isOpen]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_sub_select.php",
      msgID: msgID,
      data: {},
      sm_DEPARTURE_DATE: item.sm_DEPARTURE_DATE,
      sm_FWD_NO: item.sm_FWD_NO,
      sm_PROVIDER_CODE: item.sm_PROVIDER_CODE,
      sm_POL_CODE: item.sm_POL_CODE,
      sm_POD_CODE: item.sm_POD_CODE,
      sm_CARGO_YN: item.sm_CARGO_YN,
      // SEARCH_DATE_END: filters.SEARCH_DATE_END,
      // SEARCH_DATE_START: filters.SEARCH_DATE_START,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = handleScheduleData(newData["returnData"]);
          setWeekData(dataList);
          setIsSearch(false);
        }
      }
    });
  };

  return (
    <div className="col-xl-12 rounded bg-white">
      <div className="container-fluid">
        <div className="row m-0 p-0 border-top pt-2 mt-2">
          <div className="col-12 m-0 p-2">
            <p className="m-0 font-weight-bold text-left">
              <span className="text-freiscop mr-2">
                <i className="fa-solid fa-angles-right text-freiscop" />
              </span>
              {item.sm_ORIGIN}
              <span className="text-freiscop mx-2">
                <i className="fa-solid fa-ellipsis" />{" "}
                <i className="fa-solid fa-plane" />{" "}
                <i className="fa-solid fa-ellipsis" />
              </span>{" "}
              {item.sm_DEST}
            </p>
            {item.sm_PROMO_YN === "Y" && (
              <p className="text-left font-weight-bold text-danger">
                *{item.sm_PROMO_MEMO}
              </p>
            )}
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col-12 m-0 p-0 pb-3">
            {!isSearch && weekData ? (
              weekData.map((data) => {
                return (
                  <ScheduleLogiWorkAIRWeekTable
                    key={data.sm_IDX}
                    item={data}
                    login_info={login_info}
                    selectWeekDateList={selectWeekDateList}
                    openCostPopup={openCostPopup}
                    sm_DEPARTURE_DATE={item.sm_DEPARTURE_DATE}
                  />
                );
              })
            ) : (
              <p className="font-weight-bold text-danger">
                ※ 해당 조건에 일치하는 스케쥴 정보가 존재하지 않습니다.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
