import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CorpInfoReference";

export default function CorpInfoReference() {
  const [loginInfo, setLoginInfo] = useState(null);
  const [text, setText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let userInfo = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userInfo && userInfo["returnData"].length) {
      setLoginInfo(userInfo["returnData"][0]);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        setLoginInfo(n["returnData"][0]);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  const actionSearch = () => {
    if (text.trim() === "") {
      codeList.Modal.current.alert("사업자등록번호를 입력해주세요.");
      return;
    }

    if (loginInfo === null) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return;
    }

    socket.fireLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT,
      _mClassName,
      text,
      () => {}
    );

    navigate("/LogiTalk", {
      state: {
        bizNum: text,
      },
    });
  };

  return (
    <>
      <div className="p-3 bg-freiscop-05" id="corpInfoReference">
        <div className="container margin_30">
          <div className="row m-0 p-0 rounded-lg shadow bg-white p-4 lineUp">
            <div className="col-12 col-lg-6 m-0 mb-3 mb-lg-0 p-0">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div>
                  <div className="d-flex flex-row align-items-center">
                    <p className="h4 font-weight-bold m-0 mr-2">
                      기업신용 정보조회
                    </p>
                    <img
                      src="/img/main/kodit_logo.png"
                      height="30"
                      alt="kodit_logo"
                    />
                  </div>
                  <p className="h6 mt-2 mb-4">
                    신용보증기금(KODIT)이 제공하는 기업정보를 조회해보세요.
                  </p>
                </div>
                <div className="input-group mb-3">
                  <input
                    className="form-control border border-width-2 border-freiscop rounded-0"
                    style={{ borderWidth: "3px !important" }}
                    placeholder="사업자등록번호를 입력해주세요"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionSearch();
                      }
                    }}
                  />

                  <button
                    className="btn btn-sm rounded-0 text-white bg-freiscop border-freiscop"
                    onClick={actionSearch}
                  >
                    <i className="fa-solid fa-magnifying-glass" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 m-0 p-0">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <div className="d-flex flex-column align-items-center mb-2">
                    <div className="position-relative">
                      <p className="b6 mb-0 text-danger font-weight-bold position-absolute authorship">
                        출처
                      </p>
                      <p className="h4 mb-0 text-freiscop font-weight-900 BM-DH">
                        BASA
                      </p>
                    </div>
                    <p>Business Analytics System on AI</p>
                  </div>
                  <div className="row m-0 px-md-5">
                    <div className="col-6 m-0 py-1 px-1">
                      <p className="bg-freiscop text-white font-weight-bold rounded-pill py-2 px-4 text-center">
                        AI 경영진단
                      </p>
                    </div>
                    <div className="col-6 m-0 py-1 px-1">
                      <p className="bg-freiscop text-white font-weight-bold rounded-pill py-2 px-4 text-center">
                        재무정보
                      </p>
                    </div>
                    <div className="col-6 m-0 py-1 px-1">
                      <p className="bg-freiscop text-white font-weight-bold rounded-pill py-2 px-4 text-center">
                        거래처 정보
                      </p>
                    </div>
                    <div className="col-6 m-0 py-1 px-1">
                      <p className="bg-freiscop text-white font-weight-bold rounded-pill py-2 px-4 text-center">
                        신용등급
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  className="d-none" // d-md-block
                  src="/img/main/kodit_people.png"
                  alt="kodit_people"
                  height="160"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
