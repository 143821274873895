import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function FindIDModal() {
  const $controllers = {
    USER_NM: useRef(),
    USER_TEL: useRef(),
  };

  const joinData = (key, data) => {
    let retData = {
      COMP_CD: "Y0001",
      USER_NM: $controllers.USER_NM.current.value,
      USER_TEL: $controllers.USER_TEL.current.value,
    };

    if (key === "METHOD") {
      retData.METHOD = data;
    }

    return retData;
  };

  const actionSave = () => {
    let updateData = joinData("METHOD", "FIND_EMAIL");
    requestUserUpdate(updateData);
  };

  const checkValidation = (updateData) => {
    //todo 다국어 작업

    // console.log(updateData);
    let regExp = /[01]([016789])[-](\d{4}|\d{3})[-]\d{4}$/g;
    // console.log(regExp.test(updateData.USER_TEL));

    if (updateData.METHOD === "FIND_EMAIL") {
      if (updateData.USER_NM.trim() === "") {
        codeList.Modal.current.alert("성명을 입력 해주세요.", () => {
          $controllers.USER_NM.current.focus();
        });
        return false;
      }

      if (util.validationMobileNumber(updateData.USER_TEL) === false) {
        codeList.Modal.current.alert("핸드폰 번호를 입력해 주세요.", () => {});
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    if (checkValidation(updateData) === false) return;

    let socketMsg = {
      file: "/join/JS_join_gbts_private_find_id.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    if (socketMsg.USER_PW) {
      socketMsg.USER_PW = util.buddibleEncrypt(socketMsg.USER_PW);
    }
    console.log(socketMsg);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("newData", newData);
      if (newData) {
        switch (updateData.METHOD) {
          case "FIND_EMAIL":
            if (newData["ret"] === true) {
              if (newData["returnData"].length !== 0) {
                codeList.Modal.current.alert(
                  `회원님의 아이디는 ${newData["returnData"][0].USER_ID} 입니다.`,
                  () => {}
                );
              } else {
                codeList.Modal.current.alert(
                  "등록된 이메일이 없습니다. 이름과 핸드폰번호를 확인해주세요.",
                  () => {}
                );
              }
              // codeList.Modal.current.alert("인증 되었습니다.", () => {});
            } else {
              codeList.Modal.current.alert("오류가 발생했습니다.", () => {});
            }

            break;
        }
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="findIDModal"
      tabIndex="-1"
      aria-labelledby="findIDModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title  m-0 p-0">아이디 찾기</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="findIDModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="py-3 d-block">
                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    이름{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      // placeholder="입력하신 이메일을 아이디로 사용합니다."
                      ref={$controllers.USER_NM}
                      // onChange={(e) => {
                      //   setIdChk(CheckEmail(e.target.value));
                      // }}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label className="col-form-label col-sm-3 text-end">
                    핸드폰번호{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="tel"
                      className="form-control"
                      // disabled={true}
                      ref={$controllers.USER_TEL}
                    />
                  </div>
                </div>
              </div>

              <div className="text-danger">
                ※ 아이디, 비밀번호 찾기는 개인회원만 이용가능합니다. 기업회원은
                GBTS관리자에게 문의 바랍니다.
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1"
              onClick={() => {
                actionSave();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
