/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const uiData = {
  mainTitle: "서식자료실 관리",
  type: "서식자료",
};

const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const BBS_TYPE = [
  { key: "BBA01", desc: "물류서식" },
  { key: "BBA02", desc: "통관서식" },
  { key: "BBA03", desc: "무역서식" },
  { key: "BBA04", desc: "기타서식" },
];

const _mClassName = "BBSManageTable";
function BBSManageTable(props, ref) {
  const { data = {}, requestUserSelect } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");

  const $controllers = {
    bbIDX: useRef(),
    bbStype: useRef(),
    bbTITLE: useRef(),
    bbCONTENTS: useRef(),
    bbFiles: useRef(),
    bbUSEYN: useRef(),
  };

  const serverFile = {
    update: "/bbs/JS_bbs_update.php",
    insert: "/bbs/JS_bbs_insert.php",
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $controllers.bbIDX.current.value = userData.currentData.bbIDX;
      $controllers.bbIDX.current.disabled = true;
      $controllers.bbStype.current.value = userData.currentData.bbStype;
      $controllers.bbTITLE.current.value = userData.currentData.bbTITLE;
      $controllers.bbCONTENTS.current.value = userData.currentData.bbCONTENTS;
      $controllers.bbFiles.current.value = userData.currentData.bbFiles;
      $controllers.bbUSEYN.current.value = userData.currentData.bbUSEYN;
      setUploadFiles(userData.currentData.bbFiles);
    } else {
      $controllers.bbIDX.current.value = "";
      $controllers.bbIDX.current.disabled = true;
      $controllers.bbStype.current.value = "";
      $controllers.bbTITLE.current.value = "";
      $controllers.bbCONTENTS.current.value = "";
      $controllers.bbFiles.current.value = "";
      $controllers.bbUSEYN.current.value = "";
      setUploadFiles("");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_BBS_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        // console.log("EVENT_BBS_MANAGE_EDIT_MODAL_CALL");
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_BBS_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
    removeData(e, params) {
      DeleteData(e, params);
    },
  }));

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];
      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      $controllers.bbFiles.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      $controllers.bbFiles.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  const DeleteData = (e, params) => {
    let delData = {
      bbIDX: params.currentData.bbIDX,
      bbDelYN: "Y",
    };
    codeList.Modal.current.confirm(
      `해당 ${uiData.type}를 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          requestUserUpdate(delData, EDIT_TYPE.UPDATE);
        }
      }
    );
  };

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        bbIDX: $controllers.bbIDX.current.value,
        bbStype: $controllers.bbStype.current.value,
        bbTITLE: $controllers.bbTITLE.current.value,
        bbCONTENTS: $controllers.bbCONTENTS.current.value,
        bbFiles: $controllers.bbFiles.current.value,
        bbUSEYN: $controllers.bbUSEYN.current.value,
        bbDelYN: "N",
      };

      requestUserUpdate(
        updateData,
        $controllers.bbIDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controllers.bbStype.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if ($controllers.bbTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if ($controllers.bbCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }

    if ($controllers.bbUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: TYPE === EDIT_TYPE.UPDATE ? serverFile.update : serverFile.insert,
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_BBS_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_BBS_MANAGE_DATA_UPDATED,
            "BBSManageTable",
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `${uiData.type}를 업데이트 하였습니다.`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `${uiData.type}를 추가 하였습니다.`,
                () => {}
              );
          requestUserSelect();
          $("#closeFaqEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                `${uiData.type} 업데이트 중 오류가 발생하였습니다.`,
                () => {}
              )
            : codeList.Modal.current.alert(
                `${uiData.type} 추가 중 오류가 발생하였습니다.`,
                () => {}
              );
        }
      }
    });
  };

  const Cancel = () => {
    $controllers.bbIDX.current.value = "";
    $controllers.bbIDX.current.disabled = true;
    $controllers.bbStype.current.value = "";
    $controllers.bbTITLE.current.value = "";
    $controllers.bbCONTENTS.current.value = "";
    $controllers.bbFiles.current.value = "";
    $controllers.bbUSEYN.current.value = "";
    setUploadFiles("");
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBBSEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBBSEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>{uiData.mainTitle}</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeFaqEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$controllers.bbIDX}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controllers.bbStype}
                  >
                    <option value="">미선택</option>
                    {BBS_TYPE.map((menu, index) => {
                      return (
                        <option key={index} value={menu.key}>
                          {menu.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="3"
                    ref={$controllers.bbTITLE}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="15"
                    ref={$controllers.bbCONTENTS}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  파일첨부
                </label>

                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"mall/bbs"}
                    callback={UploadFiles}
                  />
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={$controllers.bbFiles}
                  />
                  {uploadFiles !== "" &&
                    uploadFiles.split("|").map((v, i) => {
                      let splitData = v.split("?");
                      if (splitData.length === 1) return null;
                      let fileName = splitData[0];
                      let fileLink = splitData[1];
                      return (
                        <div className="my-2" key={v}>
                          {/* <button
                                onClick={() => {\
                                  downloadAs(fileLink, fileName);
                                }}
                              >
                                {fileName}
                              </button>*/}
                          <button
                            className="btn btn-danger mr-2"
                            onClick={() => {
                              deleteFile(splitData[0], fileName);
                            }}
                          >
                            삭제
                          </button>
                          {fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                          {!fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controllers.bbUSEYN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `${uiData.type}등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(BBSManageTable);
