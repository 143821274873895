import React from "react";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();

const LogiTalkShipperMobileItem = (props) => {
  const { row, handleSelectComp, handleCompInfo } = props;
  let customRowData = { ...row };

  const Row = ({ title, data, clickEvent, isLast = false, isLink = false }) => {
    const str = data || "-";
    let dataParams = {};
    if (clickEvent) {
      dataParams = {
        ...dataParams,
        className: "cursor_pointer text-info",
        onClick: () => clickEvent(),
      };
    }

    return (
      <div className={`row m-0 p-0 ${isLast ? "" : " border-bottom"}`}>
        <div className="col-4 m-0 p-2 border-right bg-light">{title}</div>
        <div className="col-8 m-0 p-2">
          <p {...dataParams}>
            {isLink ? (
              <a href={data} target="_blank">
                {str}
              </a>
            ) : (
              str
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="p-2">
      <div className="p-2 shadow-sm rounded">
        <div className="border">
          <Row
            title="상호명"
            data={customRowData.COMP_NM}
            // clickEvent={() => console.log("hi")}
          />
          <Row title="대표자명" data={customRowData.COMP_CEO_NM} />
          <Row title="사업자등록번호" data={customRowData.COMP_NUM} />
          <Row title="주소" data={customRowData.COMP_ADDR} />
          <Row title="주요품목" data={customRowData.PRODUCT_MAIN} />
          <Row title="교역국가" data={customRowData.TRADE_COUNTRY} />
          <Row title="홈페이지" data={customRowData.HOME_PAGE} isLink={true} />
          <div className="row m-0 p-0 pb-2 pt-3">
            <div className="col-6 m-0 p-0 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  handleCompInfo(row);
                }}
              >
                <i className="fas fa-search" />
              </button>
              <p className="text-primary">기업정보</p>
            </div>
            <div className="col-6 m-0 p-0 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  handleSelectComp({ ...row });
                }}
              >
                <i className="fa-solid fa-circle-info" />
              </button>
              <p className="text-primary">상세보기</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LogiTalkShipperMobileItem);
