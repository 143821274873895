/* eslint-disable no-unused-vars */
import React from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function StoreImageSlide(props) {
  let { slideImagClose, imageSlideIndex, selectedStore, slideImagChange } =
    props;
  return (
    <div
      className="position-absolute w-100 h-100 d-flex flex-column justify-content-start p-5"
      style={{
        background: "rgba(0,0,0,0.8)",
        top: 0,
        left: 0,
        zIndex: 999999999999,
      }}
    >
      <div className="d-flex flex-row justify-content-end">
        <span
          className="h2 text-white cursor_pointer"
          onClick={() => {
            slideImagClose(false);
          }}
        >
          &times;
        </span>
      </div>
      <div className="flex-fill d-flex flex-row flex-nowrap justify-content-center align-items-center h-100">
        <div className="h1 pr-3">
          <i
            className="fa-solid fa-angle-left text-light cursor_pointer"
            onClick={() => {
              let index =
                imageSlideIndex === 0
                  ? selectedStore.sdPhoto.split("|").length - 1
                  : imageSlideIndex - 1;
              slideImagChange(index);
            }}
          />
        </div>
        {selectedStore.sdPhoto &&
          selectedStore.sdPhoto.split("|").map((photo, index) => {
            if (imageSlideIndex === index) {
              let splitData = photo.split("?");
              let imgLink = splitData[0];
              return (
                <div
                  key={index}
                  className="p-1 h-100 flex-fill"
                  style={{
                    background: `url(${imgLink}) 50% 50%/contain no-repeat`,
                  }}
                />
              );
            }
          })}{" "}
        <div className="h1 pl-3">
          <i
            className="fa-solid fa-angle-right text-light cursor_pointer"
            onClick={() => {
              let index =
                imageSlideIndex === selectedStore.sdPhoto.split("|").length - 1
                  ? 0
                  : imageSlideIndex + 1;
              slideImagChange(index);
            }}
          />
        </div>
      </div>
    </div>
  );
}
