/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Collapse from "@material-ui/core/Collapse";
import Pagination from "@mui/material/Pagination/Pagination";
import Select from "react-dropdown-select";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "ScheduleLogiWorkBookList";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const headCells = [
  {
    field: "rt_IDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
    // renderCell: (params) => (
    //     <span className="text-truncate text-wrap text-break">{params.data}</span>
    // ),
  },
  {
    field: "rt_COMPCD",
    headerName: "요청자 정보",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      const rt_user = params.currentData.request_user;
      return (
        <>
          {rt_user && (
            <p className="text-truncate text-wrap text-break text-secondary">
              [{rt_user.COMP_NM}] {rt_user.USER_NM}
            </p>
          )}
        </>
      );
    },
  },
  {
    field: "rt_EXP_IMP",
    headerName: "수출입",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data === "EXP" ? "수출" : "수입"}
      </span>
    ),
  },
  {
    field: "rt_TYPE",
    headerName: "운송분류",
    numeric: false,
    disablePadding: false,
    // renderCell: (params) => (
    //     <span className="text-truncate text-wrap text-break">{params.data}</span>
    // ),
  },
  {
    field: "rt_POL_NAME",
    headerName: "출발지",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "rt_POD_NAME",
    headerName: "도착지",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "rt_REQUEST_DATE",
    headerName: "출발희망일자",
    numeric: false,
    disablePadding: false,

    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {util.getDateToFormat(params.data, "YYYY-MM-DD")}
      </span>
    ),
  },
  {
    field: "rt_CREATE_DATE",
    headerName: "요청일",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {util.getDateToFormat(params.data, "YYYY-MM-DD")}
      </span>
    ),
  },
  // {
  //   field: "rt_UPDATE_DATE",
  //   headerName: "최종수정일",
  //   numeric: false,
  //   disablePadding: false,
  //   renderCell: (params) => (
  //     <span className="text-truncate text-wrap text-break">
  //       {util.getDateToFormat(params.data, "YYYY-MM-DD")}
  //     </span>
  //   ),
  // },

  {
    field: "rt_STATUS",
    headerName: "처리상태",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let status = "예약요청";
      let font_color = "text-secondary";
      if (params.data === "N") {
        status = "예약요청";
        font_color = "text-secondary";
      }

      if (params.data === "B") {
        status = "부킹완료";
        font_color = "text-success";
      }

      if (params.data === "I") {
        status = "인보이스발행";
        font_color = "text-info";
      }

      if (params.data === "Y") {
        status = "운송완료";
        font_color = "text-primary";
      }

      if (params.data === "C") {
        status = "예약취소";
        font_color = "text-danger";
      }

      return (
        <span
          className={`text-truncate text-wrap text-break font-weight-bold ${font_color}`}
        >
          {status}
        </span>
      );
    },
  },
];

const data_msg = {
  rt_TYPE: "선적구분을 입력해주세요",
  rt_POL_CODE: "출발지를 선택해주세요",
  rt_POD_CODE: "도착지를 선택해주세요",
  rt_REQUEST_DATE: "출발희망일자를 선택해주세요",
};
function RowDetail({
  row,
  login_info,
  requestUserUpdate,
  portList,
  airportList,
}) {
  const [transferType, setTransferType] = useState(row["rt_TYPE"]);
  const controller = {
    rt_TYPE: useRef(),
    rt_POL_CODE: useRef(),
    rt_POD_CODE: useRef(),
    rt_REQUEST_DATE: useRef(),
    rt_STATUS: useRef(),
    rt_DESC: useRef(),
    rt_ANSWER: useRef(),
  };
  const isAdmin = login_info.comp_gb === "Z";

  const rowPol = (row["rt_TYPE"] === "AIR" ? airportList : portList).find(
    (item) => item.CustomCode === row["rt_POL_CODE"]
  );
  const rowPod = (row["rt_TYPE"] === "AIR" ? airportList : portList).find(
    (item) => item.CustomCode === row["rt_POD_CODE"]
  );
  const defaultPOLValue = rowPol ? [rowPol] : [];
  const defaultPODValue = rowPod ? [rowPod] : [];

  const actionSave = () => {
    codeList.Modal.current.confirm("변경내용을 저장하시겠습니까?", (ret) => {
      if (ret) {
        let updateData = { ...row };
        for (let key in controller) {
          if (controller[key].current) {
            if (
              controller[key].current.value.trim() === "" &&
              data_msg.hasOwnProperty(key)
            ) {
              codeList.Modal.current.alert(data_msg[key], () => {});
              return;
              break;
            }
            updateData[key] = controller[key].current.value;
          }
        }
        if (isAdmin) {
          updateData["rt_ANSWER_USERID"] = login_info.user_id;
        }

        requestUserUpdate(updateData);
      }
    });
  };

  return (
    <div className="p-1 ">
      <div className="card mt-3">
        <div className="card-header bg-light">
          <p className="h font-weight-bold">상세정보</p>
        </div>
        <div className="card-body p-3">
          <div className="mb-3">
            <p className="h6 font-weight-bold">운송요청 정보</p>
            <div className="d-flex flex-row">
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  수출입구분
                </div>
                <div className="p-2 bg-light">
                  <p className="form-control border-0 bg-light font-weight-bold">
                    {row["rt_EXP_IMP"] === "EXP" ? "수출" : "수입"}
                  </p>
                </div>
              </div>
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  선적구분
                </div>
                <div className="p-2">
                  <select
                    className="form-control border-0"
                    defaultValue={row["rt_TYPE"]}
                    ref={controller.rt_TYPE}
                    disabled={isAdmin}
                    onChange={(e) => {
                      setTransferType(e.target.value);
                    }}
                  >
                    <option value="LCL">해상 LCL</option>
                    <option value="FCL">해상 FCL</option>
                    <option value="AIR">항공</option>
                  </select>
                </div>
              </div>
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  출발지
                </div>
                <div className="p-2">
                  <input
                    className={"d-none"}
                    type="text"
                    readOnly={true}
                    ref={controller.rt_POL_CODE}
                    defaultValue={row.rt_POL_CODE}
                  />
                  <input
                    className={`form-control ${isAdmin ? "" : "d-none"}`}
                    type="text"
                    readOnly={true}
                    defaultValue={row["rt_POL_NAME"]}
                  />
                  {!isAdmin && (
                    <Select
                      required
                      multi={false}
                      clearable={true}
                      disabled={isAdmin}
                      options={transferType === "AIR" ? airportList : portList}
                      values={defaultPOLValue}
                      className={`border-0`}
                      name={"SearchName"}
                      sortBy={"SearchName"}
                      searchBy={"SearchName"}
                      valueField={"CustomCode"}
                      placeholder="출발지"
                      labelField={"NewFullName"}
                      autoFocus={false}
                      onChange={(val) => {
                        let value = val.length > 0 ? val[0].CustomCode : "";
                        controller.rt_POL_CODE.current.value = value;
                      }}
                      itemRenderer={({
                        item,
                        itemIndex,
                        props,
                        state,
                        methods,
                      }) => {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                state.cursor === itemIndex
                                  ? "#edf0f9"
                                  : "transparent",
                            }}
                          >
                            <p
                              role="option"
                              aria-selected="false"
                              tabIndex="-1"
                              className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                              onClick={() => methods.addItem(item)}
                              color="#0074D9"
                              style={{
                                fontSize: "11px",
                                padding: "2px 5px",
                                cursor: "pointer",
                                borderBottom: "1px solid #fff",
                                color: "gray",
                                fontStyle: "italic",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b
                                style={{
                                  fontWeight: "normal",
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                  fontFamily: "Consolas",
                                }}
                              >
                                {item.CustomCode
                                  ? "[" + item.CustomCode + "] "
                                  : ""}
                              </b>

                              <b
                                style={{
                                  fontWeight: 600,
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                {`${item.CityName} (${item.CityName_EN}) `}
                              </b>

                              {item.CountryName + " " + item.ContinentName}
                            </p>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  도착지
                </div>
                <div className="p-2">
                  {" "}
                  <input
                    className="d-none"
                    type="text"
                    readOnly={true}
                    ref={controller.rt_POD_CODE}
                    defaultValue={row.rt_POD_CODE}
                  />
                  <input
                    className={`form-control ${isAdmin ? "" : "d-none"}`}
                    type="text"
                    readOnly={true}
                    defaultValue={row["rt_POD_NAME"]}
                  />
                  {!isAdmin && (
                    <Select
                      required
                      multi={false}
                      clearable={true}
                      disabled={isAdmin}
                      options={transferType === "AIR" ? airportList : portList}
                      values={defaultPODValue}
                      className="border-0"
                      name={"SearchName"}
                      sortBy={"City"}
                      searchBy={"SearchName"}
                      valueField={"CustomCode"}
                      placeholder="도착지"
                      labelField={"NewFullName"}
                      autoFocus={false}
                      onChange={(val) => {
                        let value = val.length > 0 ? val[0].CustomCode : "";
                        controller.rt_POD_CODE.current.value = value;
                      }}
                      itemRenderer={({
                        item,
                        itemIndex,
                        props,
                        state,
                        methods,
                      }) => {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                state.cursor === itemIndex
                                  ? "#edf0f9"
                                  : "transparent",
                            }}
                          >
                            <p
                              role="option"
                              aria-selected="false"
                              tabIndex="-1"
                              className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                              onClick={() => methods.addItem(item)}
                              color="#0074D9"
                              style={{
                                fontSize: "11px",
                                padding: "2px 5px",
                                cursor: "pointer",
                                borderBottom: "1px solid #fff",
                                color: "gray",
                                fontStyle: "italic",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "normal",
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                  fontFamily: "Consolas",
                                }}
                              >
                                {item.CustomCode
                                  ? "[" + item.CustomCode + "] "
                                  : ""}
                              </span>

                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "black",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                }}
                              >
                                {`${item.CityName} (${item.CityName_EN}) `}
                              </span>

                              {item.CountryName + " " + item.ContinentName}
                            </p>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  출발희망일자
                </div>
                <div className="p-2">
                  <input
                    className="form-control border-0"
                    disabled={isAdmin}
                    type="date"
                    defaultValue={util.getDateToFormat(
                      row["rt_REQUEST_DATE"],
                      "YYYY-MM-DD"
                    )}
                    ref={controller.rt_REQUEST_DATE}
                  />
                </div>
              </div>
              <div className="flex-grow-1 border text-center">
                <div className="p-2 bg-freiscop text-white font-weight-bold">
                  처리상태
                </div>
                <div className="p-2">
                  <select
                    className="form-control border-0"
                    defaultValue={row["rt_STATUS"]}
                    ref={controller.rt_STATUS}
                    disabled={!isAdmin && row["rt_STATUS"] !== "N"}
                  >
                    <option value="N">예약요청</option>
                    {isAdmin && (
                      <>
                        <option value="B">부킹완료</option>
                        <option value="I">인보이스발행</option>
                        <option value="Y">운송완료</option>
                      </>
                    )}
                    <option value="C">예약취소</option>
                  </select>
                </div>
              </div>
            </div>
            {!isAdmin && (
              <p className="font-weight-bold text-danger mt-2">
                ※ 예약요청 상태에서만 예약취소가 가능합니다.
              </p>
            )}
          </div>
          <div className="mb-3">
            <p className="h6 font-weight-bold">운송요청 메모</p>
            <textarea
              className="form-control"
              placeholder="운송요청 내용"
              defaultValue={row["rt_DESC"]}
              disabled={isAdmin}
              rows={10}
              style={{ height: "auto" }}
            />
          </div>
          <div className="mb-3">
            <p className="h6 font-weight-bold">운송 진행사항 메모</p>
            {isAdmin && (
              <textarea
                className="form-control"
                placeholder="해당 운송요청에 대한 답변을 작성해주세요"
                defaultValue={row["rt_ANSWER"]}
                rows={10}
                ref={controller.rt_ANSWER}
                style={{ height: "auto" }}
              />
            )}
            {!isAdmin && (
              <div className="form-control bg-light">
                {row["rt_ANSWER"] ||
                  "해당 운송요청에 대한 답변이 작성되지 않았습니다."}
              </div>
            )}
          </div>
          <div className="mb-3 text-right">
            <button className="btn_1" onClick={actionSave}>
              저장하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Row(props) {
  const [open, setOpen] = useState(false);
  const { row, login_info, requestUserUpdate, portList, airportList } = props;
  const classes = useRowStyles();
  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowDetail
              row={row}
              login_info={login_info}
              portList={portList}
              airportList={airportList}
              requestUserUpdate={requestUserUpdate}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ScheduleLogiWorkBookList({
  isServerPaging = true,
  isServerSort = true,
  filter,
  expImp,
  login_info,
  portList,
  airportList,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("rt_IDX");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [compList, setCompList] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "rt_IDX",
      dir: "desc",
    },
  ]);

  useEffect(() => {
    requestCompList();
    window.addEventListener("message", popupMessageHandler);
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      window.removeEventListener("message", popupMessageHandler);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  const popupMessageHandler = (e) => {
    const originUrl = e.origin + "";
    if (
      e &&
      originUrl.startsWith("https://www.gbts.co.kr") &&
      e.data === "isListUpdate"
    ) {
      requestUserSelect();
      updateLogiWork();
    }
  };

  useEffect(() => {
    if (compList.length) {
      requestUserSelect();
    }
  }, [page, sortModel, filter, compList]);

  const requestCompList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setCompList(newData["returnData"]);
        }
      }
    });
  };
  const requestUserSelect = () => {
    if (!login_info) return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logiwork/request_transit/JS_request_transit_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      COMP_CD: login_info.comp_cd,
      USER_ID: login_info.user_id,
      ...filter,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let tempList = [];

          for (let i = 0; i < newData["returnData"].length; i++) {
            let data = newData["returnData"][i];
            let request_user = compList.find(
              (item) =>
                item.COMP_CD === data.rt_COMPCD &&
                item.USER_ID === data.rt_USERID
            );
            data["request_user"] = request_user;
            tempList.push(data);
          }

          setServerData(tempList);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logiwork/request_transit/JS_request_transit_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        let msg = newData["ret"]
          ? "운송 요청 메모정보가 정상적으로 저장되었습니다."
          : "요청처리중 오류가 발생하였습니다. 다시 시도해주세요";
        codeList.Modal.current.alert(msg);
        if (newData["ret"]) {
          requestUserSelect();
          updateLogiWork();
        }
      }
    });
  };

  const updateLogiWork = () => {
    window.parent.postMessage({ refreshSched: true }, "*");
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowPros = {
    login_info: login_info,
    portList: portList,
    airportList: airportList,
    requestUserUpdate: requestUserUpdate,
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!loading &&
            !isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowPros} />
              ))}
          {!loading &&
            !isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowPros} />
              ))}

          {!loading &&
            isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowPros} />
              ))}

          {!loading &&
            isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={index} row={row} index={index} {...rowPros} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length + 1} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            // console.log(value - 1); //apiRef.current.setPage(value - 1)
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}
