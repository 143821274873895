import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();
const _mClassName = "UserInfoModal";

function appConsole(data) {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ ...data, isConsole: "" })
  );
}
export default function UserInfoModal(props) {
  const { addFavUser, requestChatToUser, openRootStackBigImageView } = props;
  const [userData, setUserData] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SEARCH_USER_MODAL_CALL,
      _mClassName,
      (b, n) => {
        if (!n) return;
        setUserData(n);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SEARCH_USER_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  return (
    <div
      className="modal fade open"
      id="UserInfoModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="UserInfoModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mt-0 d-flex flex-row align-items-center">
        <div className="modal-content border-0">
          {userData !== undefined && (
            <div className="modal-body py-4">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div
                  className="rounded-circle border mb-3"
                  style={{
                    width: 150,
                    height: 150,
                    background: `url(${
                      userData.USER_PIC ||
                      "https://www.gbts.co.kr/images/user_pic-50x50.png"
                    }) 50% 50% / cover no-repeat`,
                  }}
                />
                <p className="h6">
                  [{userData.COMP_NM}]
                  {userData.USER_TEAM_NM ? ` ${userData.USER_TEAM_NM}` : ""}
                </p>
                <p className="h5 mb-0 font-weight-bold">
                  {userData.USER_NICK || userData.USER_NM}
                </p>
                <div className="w-100 text-center mt-2">
                  <button
                    className="btn btn-secondary px-4 rounded-pill mr-1"
                    onClick={() => {
                      requestChatToUser(userData);
                    }}
                  >
                    {t("reqChat")}
                  </button>
                  <button
                    className="btn btn-primary px-4 rounded-pill bg-gbts ml-1"
                    onClick={() => {
                      addFavUser(userData);
                    }}
                  >
                    {t("addFavorite")}
                  </button>
                </div>

                <div className="w-100 row m-0 p-0 mt-3 px-2 h6 mb-0">
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("teamName")}
                  </div>
                  <div className="col-7 m-0 p-2">
                    {userData.USER_TEAM_NM || "-"}
                  </div>
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("partName")}
                  </div>
                  <div className="col-7 m-0 p-2">
                    {userData.USER_PART_NM || "-"}
                  </div>
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("eMail")}
                  </div>
                  <div className="col-7 m-0 p-2">{userData.USER_ID || "-"}</div>
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("tel")}
                  </div>
                  <div className="col-7 m-0 p-2">
                    {userData.USER_TEL ? (
                      <a href={`tel:${userData.USER_TEL}`}>
                        {userData.USER_TEL}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("fax")}
                  </div>
                  <div className="col-7 m-0 p-2">
                    {userData.USER_FAX || "-"}
                  </div>
                  <div className="col-5 m-0 p-2 font-weight-bold">
                    {t("nameCard")}
                  </div>
                  <div className="col-7 m-0 p-2">
                    {userData.USER_NAME_CARD ? (
                      <button
                        className="btn btn-sm rounded-pill btn-secondary px-3"
                        onClick={() => {
                          openRootStackBigImageView(userData);
                        }}
                      >
                        {t("showNameCard")}
                      </button>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
              id="UserInfoModalClose"
              onClick={() => setUserData(undefined)}
            >
              <i className="fa fa-times mr-2" />
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
