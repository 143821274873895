import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import $ from "jquery";

import CommonNav from "../../../CommonNav";
import BannerMarketing from "../../BannerMarketing";
import ScheduleLCLTable from "./ScheduleLCLTable";
import Footer from "../../../Footer";

import Loading from "../../../../lib/Loading";
import LogoSvg from "../../../../assets/LogoSvg";
import UploadImageToS3WithPhp from "../../../../lib/UploadImageToS3WithPhp";
import {
  LocationSeaSelectOption,
  BCompSelectOption,
} from "../../../../lib/CommonUI";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import MMSI from ".././mmsiList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

const _mClassName = "ScheduleLCL";

let loginInfo = null;
let schedule_list = [];

let rowsPerPage = 10;
let page = 0;
let pageSize = 0;
let totalCount = 0;
let totalPageCount = 0;
let filters = {
  sm_FCL_LCL: "LCL",
  sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
  sm_POL_CODE: "",
  sm_POD_CODE: "",
  sm_PROMO_YN: "",
  ORIGIN: "",
  DEST: "",
  SEARCH_COMP: "",
};

let modal_data = null;
let isSearch = false;
let isShareAccess = false;
let isCostModalOpen = false;
let isBookingModalOpen = false;
let comp_list = [];
let isPromoState = null;
const shUUID = util.makeUUIDv4();

export default function ScheduleLCL() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduleLoad, setIsScheduleLoad] = useState(true);
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false);
  const [searchCodeList, setSearchCodeList] = useState([]);
  const [selectedComp, setSelectedComp] = useState([]);
  const params = useParams();
  const location = useLocation();

  const $pol_select = useRef();
  const $pod_select = useRef();
  const $comp_select = useRef();
  const $promo_check = useRef();

  useEffect(() => {
    if (location) {
      if (location.state && location.state.hasOwnProperty("type")) {
        if (location.state.type === "promo") {
          filters = {
            ...filters,
            sm_PROMO_YN: "Y",
          };
          isPromoState = { type: "promo" };
          $promo_check.current.checked = true;
        }
        if (location.state.hasOwnProperty("sm_POL_CODE")) {
          filters = {
            ...filters,
            sm_POL_CODE: location.state.sm_POL_CODE,
            sm_POD_CODE: location.state.sm_POD_CODE,
            ORIGIN: location.state.ORIGIN,
            DEST: location.state.DEST,
          };
          isSearch = true;
        }
      }
    }

    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData.ret) {
        loginInfo = userData["returnData"][0];
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret) {
            loginInfo = n["returnData"][0];
          }
        }
      }
    );

    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setSearchCodeList(newV);
        }
      );
    } else {
      setSearchCodeList(codeList.codeCountryFullName);
    }
    let id = "";
    if (params && params.hasOwnProperty("id")) {
      id = params.id;
      isSearch = true;
      isShareAccess = true;
    }
    requestShipCompList();
    requestUserSelect(id);
    return () => {
      rowsPerPage = 10;
      page = 0;
      pageSize = 0;
      totalCount = 0;
      totalPageCount = 0;
      filters = {
        sm_FCL_LCL: "LCL",
        sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
        sm_POL_CODE: "",
        sm_POD_CODE: "",
        sm_PROMO_YN: "",
        ORIGIN: "",
        DEST: "",
        SEARCH_COMP: "",
      };

      modal_data = null;
      isSearch = false;
      isShareAccess = false;
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName
      );
    };
  }, []);

  const requestShipCompList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/b_logo/JS_b_logo_select.php",
      msgID: msgID,
      data: [],
      USE_YN: "Y",
      blSchDisplay: "Y",
      blCompType: "B",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          comp_list = [...newData["returnData"]];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //로지스몰 스케줄 정보 받아오기
  const requestUserSelect = (id) => {
    setIsScheduleLoad(true);
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sm_DOC_CLOSE",
          dir: "asc",
        },
      ],
      sp_DISPLAY: 0,
      ...filters,
    };
    let log_data = {};
    if (id) {
      log_data = {
        shClick: 0,
        shClickFor: "share",
      };
      log_data.shClick = 0;
      log_data.shClickFor = "share";
      socketMsg.sm_UID = id;
      sendUserUsedData(log_data);
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          schedule_list = newData["returnData"];
          pageSize = newData.pageSize;
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            schedule_list.length > 0
              ? parseInt(
                  parseInt(newData.pageSize, rowsPerPage) / rowsPerPage,
                  rowsPerPage
                ) +
                (parseInt(newData.pageSize, rowsPerPage) % rowsPerPage > 0
                  ? 1
                  : 0)
              : 0;
          setIsScheduleLoad(false);
        }
      }
    });
  };

  const handleBookingModal = (value) => {
    isBookingModalOpen = value;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const handleCostModal = (value) => {
    isCostModalOpen = value;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const changedFilers = (data) => {
    // if (isScheduleLoad) {
    //   codeList.Modal.current.alert("스케줄 조회중입니다.");
    //   return;
    // }

    filters = {
      ...filters,
      ...data,
    };

    page = 0;
    isSearch = true;
    // requestUserSelect();

    if (data.hasOwnProperty("sm_PROMO_YN")) {
      requestUserSelect();
    }
  };

  //스케줄 검색
  const handleSearchSchedule = () => {
    page = 0;
    isSearch = true;
    requestUserSelect();
  };

  //검색필터 초기화
  const handleResetFilters = () => {
    $pol_select.current.clearAll();
    $pod_select.current.clearAll();
    $promo_check.current.checked = false;
    filters = {
      sm_FCL_LCL: "LCL",
      sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
      sm_POL_CODE: "",
      sm_POD_CODE: "",
      sm_PROMO_YN: "",
      ORIGIN: "",
      DEST: "",
      SEARCH_COMP: "",
    };
    page = 0;
    isSearch = false;
    isPromoState = null;
    setSelectedComp([]);
    if (params && params.hasOwnProperty("id")) {
      const replaceUrl = `${window.location.origin}/ScheduleLCL`;
      window.location.replace(replaceUrl);
    }
    requestUserSelect();
  };

  // LCL운임 정보 요청
  const requestLCLCostList = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_price_lcl_select.php",
      msgID: msgID,
      data: {},
      sm_POL_CODE: data.sm_POL_CODE,
      sm_POD_CODE: data.sm_POD_CODE,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  //운임안내 모달 오픈
  const openCostModal = (data) => {
    if (!loginInfo) {
      codeList.Modal.current.alert(
        "포워더용 서비스입니다. \n화주는 로지웍에서 스케줄 및 운임조회가 가능합니다."
      );
      return false;
    }

    if (!util.checkCompGB(loginInfo)) {
      codeList.Modal.current.alert(
        "포워더용 서비스입니다. \n화주는 로지웍에서 스케줄 및 운임조회가 가능합니다."
      );
      return;
    }

    if (!(data instanceof Object) || !data.sm_IDX) {
      codeList.Modal.current.alert(
        "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
      );
      return;
    }

    requestLCLCostList(data, (newData) => {
      let price_list =
        newData["price_list"].length > 0
          ? newData["price_list"]
          : [
              {
                SP_KND: "OCEAN FREIGHT",
                SP_DIV: "R.TON",
                SP_CUR: "",
                SP_PRICE: "-",
              },
              {
                SP_KND: "SURCHARGE",
                SP_DIV: "R.TON",
                SP_CUR: "",
                SP_PRICE: "-",
              },
            ];
      modal_data = {
        ...data,
        price_list: [...price_list, ...newData["price_default_list"]],
      };
      isCostModalOpen = true;
      setIsNeedUpdate(!isNeedUpdate);
    });
  };

  // 예약 모달 오픈
  const openBookingModal = () => {
    isCostModalOpen = false;
    isBookingModalOpen = true;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //선박추적 팝업 열기
  const handleOpenOceanLookV3 = (sm_IDX, sm_VESSEL) => {
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return;
    }

    let mmsiNum = mmsi.getMMSI(sm_VESSEL);

    if (mmsiNum !== "") {
      let oceanlookWebToken =
        "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

      //"https://map.oceanlook.net/#/map_popup?token="
      let url =
        "https://www.oceanlook.net/v3/popup?token=" +
        oceanlookWebToken +
        "&mmsi=" +
        mmsiNum;
      let popup = "oceanlookForm";
      let options =
        "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
      window.open(url, popup, options);
    } else {
      codeList.Modal.current.alert("추적 가능한 선박이 아닙니다.");
      return false;
    }
  };

  //부킹데이터 제출
  const INSERT_REQUEST_BOOKING_DATA = (data) => {
    isBookingModalOpen = false;
    setIsLoading(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_booking_insert.php",
      msgID: msgID,
      data: {},
      ...data,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setIsLoading(false);
          setIsReserveModalOpen(true);
        } else {
          codeList.Modal.current.alert(
            "예약 중 오류가 발생했습니다. 다시 시도해주세요."
          );
          setIsLoading(false);
        }
      }
    });
  };

  const scheduleUseLog = (type, isClick, data) => {
    sendUserUsedData({
      shIDX: data.sm_IDX,
      shType: data.sm_FCL_LCL,
      shDate: "",
      shORIGIN: data.sm_POL_CODE,
      shDEST: data.sm_POD_CODE,
      shClick: isClick,
      shClickFor: type,
    });
    isCostModalOpen = false;
    isBookingModalOpen = true;
  };

  const sendUserUsedData = (data) => {
    let log_data = {
      shUUID: shUUID,
      shIDX: "", //스케줄 No
      shDate: filters.sm_DOC_CLOSE, // 검색일자(출발일)
      shType: filters.sm_FCL_LCL, // FCL,LCL,항공
      shORIGIN: filters.sm_POL_CODE, //출발지 POL
      shDEST: filters.sm_POD_CODE, //도착지 POD
      shClick: 0, //클릭여부
      shClickFor: "", //클릭 분류
      shMethod: window.location.href, //발생 URL
      ...data,
    };
    if (loginInfo && loginInfo.hasOwnProperty("user_id")) {
      log_data["shCOMPCD"] = loginInfo.comp_cd;
      log_data["shUSERID"] = loginInfo.user_id;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_log_insert.php",
      msgID: msgID,
      data: {},
      ...log_data,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  //페이지 이동
  const handleChangePage = (val) => {
    page = val;
    let top = document.querySelector("#banner_mark")?.clientHeight;
    window.scrollTo(0, top);
    requestUserSelect();
  };

  const requestEnquiry = (item) => {
    codeList.Modal.current.confirm(
      `[${item.sm_ORIGIN}-${item.sm_DEST}] 스케줄에 대해 담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          if (!loginInfo) {
            codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
            return;
          }
          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "LogiTalk", "_blank");
        }
      }
    );
  };

  return (
    <>
      {isLoading && <Loading />}

      {isReserveModalOpen && (
        //예약완료 안내 모달(예약관리 바로가기 기능)
        <ReserveSuccessModal setIsReserveModalOpen={setIsReserveModalOpen} />
      )}
      {isBookingModalOpen && modal_data !== null && (
        //예약요청 모달
        <BookingModal
          loginInfo={loginInfo}
          handleBookingModal={handleBookingModal}
          INSERT_REQUEST_BOOKING_DATA={INSERT_REQUEST_BOOKING_DATA}
        />
      )}
      {isCostModalOpen && modal_data !== null && (
        //운임안내 모달
        <LCLCostModal
          loginInfo={loginInfo}
          handleCostModal={handleCostModal}
          requestEnquiry={requestEnquiry}
          scheduleUseLog={scheduleUseLog}
          openBookingModal={openBookingModal}
        />
      )}

      <CommonNav />
      <main className={`py-5 mh-90vh bg-schedule`}>
        <BannerMarketing />

        <div className="container margin_30 mt-0">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="rounded shadow-sm">
                <div className="row m-0 p-0 text-center rounded-top overflow-hidden m-0">
                  <Link
                    to={"/ScheduleLCL"}
                    className="col-4 m-0 p-0 py-2 bg-freiscop text-white  text-decoration-none"
                    state={isPromoState}
                  >
                    <span className="font-size-09">
                      <i className="fa-solid fa-cart-flatbed" />
                    </span>{" "}
                    해상 LCL
                  </Link>
                  <Link
                    to={"/ScheduleFCL"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark border-left border-right text-decoration-none"
                    state={isPromoState}
                  >
                    해상 FCL
                  </Link>
                  <Link
                    to={"/ScheduleAIR"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark text-decoration-none"
                    state={isPromoState}
                  >
                    항공
                  </Link>
                </div>

                <div className="border-top bg-white rounded-bottom">
                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">
                        서류마감일
                      </p>
                      <input
                        type="date"
                        className="w-100 border-top-0 border-left-0 border-right-0 border-bottom py-1 bg-white"
                        min={util.todayDate("YYYY-MM-DD")}
                        defaultValue={filters.sm_DOC_CLOSE}
                        onChange={(e) => {
                          changedFilers({ sm_DOC_CLOSE: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">출발지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pol_select}
                        options={searchCodeList}
                        placeholder="출발지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            ORIGIN: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POL_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilers(data);
                        }}
                        {...LocationSeaSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">도착지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pod_select}
                        options={searchCodeList}
                        placeholder="도착지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            DEST: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POD_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilers(data);
                        }}
                        {...LocationSeaSelectOption}
                      />
                    </div>
                  </div>

                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">
                        콘솔사 선택
                      </p>
                      <Select
                        ref={$comp_select}
                        placeholder="-콘솔사 선택-"
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        values={selectedComp}
                        options={comp_list}
                        onChange={(value) => {
                          if (value.length < 1) {
                            changedFilers({ SEARCH_COMP: "" });
                            return;
                          }

                          setSelectedComp(value);

                          let compKeys = [];
                          for (let i = 0; i < value.length; i++) {
                            let comp = value[i];
                            compKeys.push(comp.blFWDNO);
                          }

                          compKeys = compKeys.join("|");
                          changedFilers({ SEARCH_COMP: compKeys });
                        }}
                        contentRenderer={({ props, state, methods }) => {
                          let str = "";
                          if (state.values.length > 0) {
                            str = `+ ${state.values.length} 업체`;
                          } else {
                            str = props.placeholder;
                          }
                          return <div>{str}</div>;
                        }}
                        onClearAll={() => {
                          setSelectedComp([]);
                        }}
                        {...BCompSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <label className="container_check m-0 mb-2 mb-lg-0 mr-lg-3 p-0">
                        <input
                          type="checkbox"
                          className="position-static"
                          defaultChecked={filters.sm_PROMO_YN === "Y"}
                          ref={$promo_check}
                          onChange={(e) => {
                            isPromoState = e.target.checked
                              ? { type: "promo" }
                              : null;

                            changedFilers({
                              sm_PROMO_YN: e.target.checked ? "Y" : "",
                            });
                          }}
                        />
                        <span className="checkmark" />
                        <span className="ml-3">프로모션 필터</span>
                      </label>
                    </div>
                    {selectedComp.length > 0 && (
                      <div className="col-lg-12 p-0 m-0">
                        <div className="d-flex flex-row flex-wrap mx-2 mb-3 mb-lg-2 bg-light rounded">
                          {selectedComp.map((item) => {
                            return (
                              <div key={item.blIDX}>
                                <div
                                  role="option"
                                  aria-selected="false"
                                  tabIndex="-1"
                                  className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light pr-2 px-lg-2 py-1 d-flex flex-row align-items-center"
                                  onClick={() => {
                                    let _selectedComp = [...selectedComp];
                                    let delIDX = _selectedComp.findIndex(
                                      (data) => data.blIDX === item.blIDX
                                    );
                                    if (delIDX > -1) {
                                      _selectedComp.splice(delIDX, 1);
                                    }
                                    setSelectedComp(_selectedComp);
                                  }}
                                >
                                  <span className="h6 m-0 mr-2 text-danger">
                                    &times;
                                  </span>
                                  {item.blLOGO && (
                                    <div
                                      className="d-inline-block mr-2"
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                        background: `url(${item.blLOGO}) 50% 50% / contain no-repeat`,
                                      }}
                                    />
                                  )}
                                  <p className="d-inline-block">
                                    <b>{item.blCompNM_KO}</b>{" "}
                                    <span className="font-size-08">
                                      [{item.blCompNM}]
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="col-12 m-0 p-2 px-lg-3  d-flex flex-column flex-lg-row justify-content-between align-items-lg-end">
                      <p className="h6 font-weight-bold mt-3">
                        총{" "}
                        <span className="text-info font-weight-bold">
                          {util.addCommas(pageSize)}건
                        </span>
                        의 스케줄이 검색되었습니다
                        <br className="d-sm-none" />
                        <span style={{ fontSize: "0.8em" }}>
                          ({" "}
                          <i className="fa-solid fa-share-nodes mr-1 text-primary" />
                          스케줄No를 클릭해서 공유해보세요 )
                        </span>
                      </p>
                      <div className="d-flex flex-row justify-content-end">
                        <button
                          className="btn_1 px-4 mr-2 rounded-sm"
                          onClick={handleSearchSchedule}
                        >
                          검색
                        </button>
                        <button
                          className="btn_1 bg-secondary border-secondary rounded-sm"
                          onClick={handleResetFilters}
                        >
                          검색조건 초기화
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              {isScheduleLoad ? (
                <div className="bg-light rounded p-5 h6 text-center">
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </div>
              ) : schedule_list.length > 0 ? (
                <ScheduleLCLTable
                  page={page}
                  totalPageCount={totalPageCount}
                  comp_list={comp_list}
                  schedule_list={schedule_list}
                  loginInfo={loginInfo}
                  openCostModal={openCostModal}
                  handleChangePage={handleChangePage}
                  handleOpenOceanLookV3={handleOpenOceanLookV3}
                  sendUserUsedData={sendUserUsedData}
                />
              ) : (
                <div className="bg-white rounded p-5 h6 text-center font-weight-bold text-secondary">
                  조건에 맞는 스케줄이 존재하지 않습니다
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

//예약요청 완료 모달
const ReserveSuccessModal = ({ setIsReserveModalOpen }) => {
  return (
    <div className="modal_background">
      <div className="modal_body bg-white rounded" style={{ minWidth: 320 }}>
        <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
          <p className="h6 mr-3 p-0 m-0">안내</p>
          <div
            className="btn btn-sm btn-danger"
            onClick={() => setIsReserveModalOpen(false)}
          >
            <i className="fa-solid fa-xmark" />
          </div>
        </div>
        <div className="p-3">예약요청이 정상적으로 완료되었습니다.</div>
        <div className="d-flex flex-row justify-content-end border-top p-3">
          <Link
            to="/ERP/ScheduleBookingManage"
            className="btn btn-sm btn-outline-secondary mr-2"
          >
            예약관리 바로가기
          </Link>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setIsReserveModalOpen(false)}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

const LCLCostModal = (props) => {
  const { handleCostModal, requestEnquiry, scheduleUseLog, openBookingModal } =
    props;

  return (
    <>
      <div className="modal_background">
        <div className="modal_body bg-white rounded" style={{ minWidth: 320 }}>
          <div className="d-flex flex-row justify-content-center border-bottom">
            <div
              className="p-3 d-flex flex-row justify-content-between align-items-center w-100"
              style={{ maxWidth: 1200 }}
            >
              <p className="h5 font-weight-bold mr-3 p-0 m-0">운임 안내</p>
              <div
                className="btn btn-sm btn-danger"
                onClick={() => handleCostModal(false)}
              >
                <i className="fa-solid fa-xmark" />
              </div>
            </div>
          </div>
          <div
            className="overflow-auto p-3 px-3"
            style={{ maxHeight: "calc(100vh - 200px)" }}
          >
            <div className="mb-3">
              <div className="d-flex flex-column flex-xl-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden">
                <div className="border-right flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    콘솔사
                  </div>
                  <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{ height: 55 }}
                  >
                    {modal_data.COMP_LOGO ? (
                      <div
                        style={{
                          backgroundImage: `url('${
                            modal_data.COMP_LOGO.split("?")[0]
                          }')`,
                          backgroundPosition: "50% 50%",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",

                          width: "50px",
                          height: "50px",
                        }}
                      />
                    ) : (
                      <LogoSvg width={"60px"} />
                    )}
                  </div>
                </div>
                <div className="border-right flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    스케쥴 No
                  </div>
                  <div className="p-2 text-center">{modal_data.sm_UID}</div>
                </div>
                <div className="border-right flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    FCL/LCL
                  </div>
                  <div className="p-2 text-center">{modal_data.sm_FCL_LCL}</div>
                </div>
                <div className="border-right flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    VESSEL/VOY
                  </div>

                  <div className="p-2 text-center">
                    <p>{modal_data.sm_VESSEL}</p>
                    <p>{modal_data.sm_VOY}</p>
                  </div>
                </div>
                <div className="flex-grow-1 d-flex flex-column flex-xl-row flex-wrap">
                  <div className="border-right flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      POL
                    </div>
                    <div className="p-2 text-center">
                      <p className="font-weight-bold">
                        {modal_data.sm_POL_NAME}
                      </p>
                      <p className="text-danger">
                        {modal_data.sm_DEPARTURE_DATE}
                      </p>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div
                      className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                      style={{ minWidth: 100 }}
                    >
                      POD
                    </div>
                    <div className="p-2 text-center">
                      <p className="font-weight-bold">
                        {modal_data.sm_POD_NAME}
                      </p>
                      <p className="text-danger">{modal_data.sm_ARRIVE_DATE}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <p className="font-weight-bold mb-1">
                ※ GENERAL RATE FOR FOWARDER
              </p>
              <div className="container-fluid m-0 p-0 text-center">
                <div className="row m-0 p-0 border bg-light font-weight-bold">
                  <div className="col-5 m-0 p-0 border-right">
                    <p className="p-2">CATEGORY</p>
                  </div>
                  <div className="col-2 m-0 p-0 border-right">
                    <p className="p-2">PER</p>
                  </div>
                  {/*
                  <div className="col-3 m-0 p-0 border-right">
                    <p className="p-2">CURRENCY</p>
                  </div>*/}
                  <div className="col-5 m-0 p-0">
                    <p className="p-2">AMOUNT</p>
                  </div>
                </div>
                {modal_data.price_list.map((price, index) => {
                  const key_start = price.SP_KND ? "SP_" : "SPD_";

                  return (
                    <div
                      key={index}
                      className={`row m-0 p-0 border-left border-right border-bottom ${
                        key_start === "SP_" ? "font-weight-bold" : ""
                      }`}
                    >
                      <div className="col-5 m-0 p-0 border-right bg-light">
                        <p className="p-2">{price[key_start + "KND"]}</p>
                      </div>
                      <div className="col-2 m-0 p-0 border-right">
                        <p className="p-2">{price[key_start + "DIV"]}</p>
                      </div>
                      {/*<div className="col-3 m-0 p-0 border-right">
                        <p className="p-2">{price["SPD_CUR"]}</p>
                      </div>*/}
                      <div className="col-5 m-0 p-0">
                        <p className="p-2 text-right">
                          {price[key_start + "CUR"]}{" "}
                          {util.addCommas(price[key_start + "PRICE"])}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p className="mt-1 font-weight-bold">
                *공지시점과 견적시점 간 차이로 인해 일부 차이가 발생할 수
                있습니다
              </p>
              <p className="text-danger mt-1">
                ※운임이 공란인 경우 문의주시면 즉시 안내 드리겠습니다.
              </p>
            </div>

            {/*{modal_data.bookInfo !== null && (*/}
            <div className="row m-0 mb-3 p-0">
              <div className="col-8 m-0 p-0">
                <div>
                  <p className="font-weight-bold">· Booking 및 마감시간 문의</p>
                  <p className="ml-2">
                    김호승 본부장 (032-751-9870, hskim@bstc.kr)
                    {/*{modal_data.bookInfo.OUTPBU} ({modal_data.bookInfo.OUTPBT}
                      ,{modal_data.bookInfo.OUTPBE})*/}
                  </p>
                </div>
                <div className="mt-2">
                  <p className="font-weight-bold">· B/L 및 EDI신고 문의</p>
                  <p className="ml-2">
                    김호승 본부장 (032-751-9870, hskim@bstc.kr)
                    {/*{modal_data.bookInfo.OUTPDU} ({modal_data.bookInfo.OUTPDT}
                      ,{modal_data.bookInfo.OUTPDE})*/}
                  </p>
                </div>

                <div className="mt-2">
                  <p className="font-weight-bold">· CFS 정보</p>
                  {modal_data.smName ? (
                    <>
                      <p className="ml-2">{modal_data.smName}</p>
                      <p className="ml-2">{modal_data.smAddrFull}</p>
                      <p className="ml-2">{modal_data.smTel}</p>
                      <Link
                        to={`/storeMall/${modal_data.sm_CFS}`}
                        target="_blank"
                        className="ml-2 btn btn-sm btn-secondary font-size-06"
                      >
                        상세보기
                      </Link>
                    </>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
              <div className="col-4 m-0 p-0">
                <p className="font-weight-bold text-right">
                  Booking 가능 여부 :{" "}
                  <span className={"text-primary"}>가능</span>
                </p>
              </div>
            </div>
            {/*)}*/}

            <div className="d-flex flex-row justify-content-between">
              <div />
              <div className="d-flex flex-row">
                <button
                  className="mr-2 btn btn-sm btn-primary"
                  onClick={() => requestEnquiry(modal_data)}
                >
                  영업담당자와 대화하기
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    scheduleUseLog("book", 1, modal_data);
                    openBookingModal(modal_data.sm_IDX);
                  }}
                >
                  예약하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

let bookingEmail = [];
// 예약 모달
const BookingModal = ({
  loginInfo,
  handleBookingModal,
  INSERT_REQUEST_BOOKING_DATA,
}) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [emailCount, setEmailCount] = useState(1);
  let payment = "A";

  const $controller = {
    p_name: useRef(),
    p_comp_nm: useRef(),
    p_user: useRef(),
    p_cnt: useRef(),
    p_weight: useRef(),
    p_quantities: useRef(),
    p_memo: useRef(),
  };

  bookingEmail[0] = loginInfo.user_id;

  //예약 첨부파일 업로드
  const handleUploadFile = (value) => {
    if (!value.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...uploadFile];
    //파일명?파일주소
    dataList.push(`${value.realFileName}?${value.fileName}`);
    setUploadFile(dataList);
  };

  //첨부된 파일 삭제
  const handleDeleteUploadFile = (index) => {
    let dataList = [];
    for (let i = 0; i < uploadFile.length; i++) {
      if (i !== index) {
        dataList.push(uploadFile[i]);
      }
    }
    setUploadFile(dataList);
  };

  //회신 이메일 개수 증감
  const emailCountUp = () => {
    setEmailCount((count) => ++count);
  };
  const emailCountDown = (index) => {
    if (emailCount > 1) {
      let dataList = [];
      for (let i = 0; i < bookingEmail.length; i++) {
        if (i !== index) {
          dataList.push(bookingEmail[i]);
        }
      }

      bookingEmail = dataList;
      setEmailCount((count) => --count);
    }
  };

  //회신 이메일 랜더
  const RenderEmailInput = () => {
    let renderInput = [];
    for (let i = 0; i < emailCount; i++) {
      renderInput.push(
        <div className="d-flex flex-row mb-1" key={i}>
          <input
            type="email"
            className="form-control mr-2"
            disabled={i === 0}
            value={bookingEmail[i]}
            placeholder="이메일주소를 입력해주세요"
            onChange={(e) => {
              bookingEmail[i] = e.target.value;
            }}
          />
          <div>
            {i > 0 ? (
              <button
                className={"btn_1 bg-danger h-100"}
                style={{ width: 50 }}
                onClick={() => {
                  emailCountDown(i);
                }}
              >
                -
              </button>
            ) : (
              <button
                className={"btn_1 bg-primary h-100"}
                style={{ width: 50 }}
                onClick={() => emailCountUp()}
              >
                +
              </button>
            )}
          </div>
        </div>
      );
    }
    return renderInput;
  };

  //제출
  const submit = () => {
    for (let key in $controller) {
      if (key === "p_comp_nm" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("실화주 상호를 입력하세요");
        return;
      }
      if (key === "p_user" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("귀사의 B/L 번호를 입력해주세요");
        return;
      }
      if (key === "p_name" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("대표 품목을 입력하세요");
        return;
      }
      if (key === "p_cnt" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("물량을 입력해주세요");
        return;
      }
      if (key === "p_weight" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("중량을 입력해주세요");
        return;
      }
      if (key === "p_quantities" && $controller[key].current.value === "") {
        codeList.Modal.current.alert("수량단위을 입력해주세요");
        return;
      }
    }

    bookingEmail[0] = loginInfo.user_id;
    for (let i = 0; i < bookingEmail.length; i++) {
      if (!util.validationEmail(bookingEmail[i])) {
        codeList.Modal.current.alert(
          "유효하지 않은 이메일이 포함되어 있습니다."
        );
        return;
      }
    }

    let emails = bookingEmail.join("|");
    let files = "";
    if (uploadFile.length > 0) {
      files = uploadFile.join("|");
    }

    let data = {
      sm_UID: modal_data.sm_UID,

      sb_IDX_MAIN: modal_data.sm_IDX,
      sb_PRODUCT_NAME: $controller.p_name.current.value,
      sb_COMP_NM: $controller.p_comp_nm.current.value,
      sb_USER_NM: $controller.p_user.current.value,
      sb_CNT: $controller.p_cnt.current.value,
      sb_WEIGHT: $controller.p_weight.current.value,
      sb_QUANTITIES: $controller.p_quantities.current.value,
      sb_EMAIL: emails,
      sb_MEMO: $controller.p_memo.current.value,
      sb_FILE: files,
      sb_COMP_CD: loginInfo.comp_cd,
      sb_USER_ID: loginInfo.user_id,
      sb_PAYMENT: payment,
      sb_COMP_NAME: loginInfo.comp_nm,
      sb_USER_NAME: loginInfo.user_nm,

      sb_CFS: modal_data.sm_CFS,
      sb_FWD_NO: modal_data.sm_FWD_NO,
      sb_FWD_NM: modal_data.sm_FWD_NM,
      sb_PROVIDER_CODE: modal_data.sm_PROVIDER_CODE,
      sb_PROVIDER_ID: modal_data.sm_PROVIDER_ID,
      sb_FCL_LCL: modal_data.sm_FCL_LCL,
      sb_VESSEL: modal_data.sm_VESSEL,
      sb_VOY: modal_data.sm_VOY,
      sb_DOC_CLOSE: modal_data.sm_DOC_CLOSE,
      sb_DOC_CLOSE_TIME: modal_data.sm_DOC_CLOSE_TIME,
      sb_CARGO_CLOSE: modal_data.sm_CARGO_CLOSE,
      sb_CARGO_CLOSE_TIME: modal_data.sm_CARGO_CLOSE_TIME,
      sb_ORIGIN: modal_data.sm_POL_CODE, //sm_ORIGIN 의 경우 select 쿼리 실행시 "지역명(지역영문명)" 으로 대체되어 들어오기 때문에 POL_CODE 사용
      sb_DEST: modal_data.sm_POD_CODE, //sm_DEST 의 경우 select 쿼리 실행시 "지역명(지역영문명)" 으로 대체되어 들어오기 때문에 POD_CODE 사용 ※ schedule/JS_schedule_select.php 참고
      sb_DEPARTURE_DATE: modal_data.sm_DEPARTURE_DATE,
      sb_ARRIVE_DATE: modal_data.sm_ARRIVE_DATE,
      sb_POL_CODE: modal_data.sm_POL_CODE,
      sb_POD_CODE: modal_data.sm_POD_CODE,
      sb_LINE: modal_data.sm_LINE,
      sb_REGION: modal_data.sm_REGION,
      sb_REMARK: modal_data.sm_REMARK,

      sb_POL_NAME: modal_data.sm_ORIGIN,
      sb_POD_NAME: modal_data.sm_DEST,
    };

    bookingEmail = [];
    INSERT_REQUEST_BOOKING_DATA(data);
  };

  return (
    <div className="modal_background">
      <input style={{ display: "none" }} aria-hidden="true" />
      <input type="password" style={{ display: "none" }} aria-hidden="true" />
      <div className="modal_body bg-white rounded">
        <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
          <p className="h5 mr-3 p-0 m-0">예약하기</p>
          <div
            className="btn btn-sm btn-danger"
            onClick={() => handleBookingModal(false)}
          >
            <i className="fa-solid fa-xmark" />
          </div>
        </div>

        <div
          className="overflow_scroll-Y p-3 px-4"
          style={{
            maxHeight: "calc(100vh - 200px)",
            minWidth: 320,
            maxWidth: 1000,
          }}
        >
          <div className="mb-3">
            <p className="h6 mb-3">
              ■ 요청자 : [{loginInfo.comp_nm}] {loginInfo.user_nm} 님{" "}
              {loginInfo.user_tel ? `(${loginInfo.user_tel})` : ""}
            </p>
            <p className="font-weight-bold">요청스케줄 정보</p>
            <div className="d-flex flex-column flex-xl-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden">
              <div className="border-right flex-grow-1">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  {modal_data.sm_FCL_LCL === "LCL" ? "콘솔사" : "선사"}
                </div>
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ height: 54 }}
                >
                  {modal_data.COMP_LOGO ? (
                    <div
                      style={{
                        backgroundImage: `url('${
                          modal_data.COMP_LOGO.split("?")[0]
                        }')`,
                        backgroundPosition: "50% 50%",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",

                        width: "50px",
                        height: "50px",
                      }}
                    />
                  ) : (
                    <LogoSvg width={"60px"} />
                  )}
                </div>
              </div>
              <div className="border-right flex-grow-1">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  스케쥴 No
                </div>
                <div className="p-2 text-center">{modal_data.sm_UID}</div>
              </div>
              <div className="border-right flex-grow-1">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  FCL/LCL
                </div>
                <div className="p-2 text-center">{modal_data.sm_FCL_LCL}</div>
              </div>
              <div className="border-right flex-grow-1">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  {modal_data.sm_FCL_LCL === "AIR" ? "FLIGHT" : "VESSEL/VOY"}
                </div>
                <div className="p-2 text-center">
                  <p>{modal_data.sm_VESSEL}</p>
                  <p>{modal_data.sm_VOY}</p>
                </div>
              </div>
              <div className="flex-grow-1 d-flex flex-column flex-xl-row flex-wrap">
                <div className="border-right flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    POL
                  </div>
                  <div className="p-2 text-center">
                    <p className="font-weight-bold">{modal_data.sm_POL_NAME}</p>
                    <p className="text-danger">
                      {modal_data.sm_DEPARTURE_DATE}
                    </p>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div
                    className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                    style={{ minWidth: 100 }}
                  >
                    POD
                  </div>
                  <div className="p-2 text-center">
                    <p className="font-weight-bold">{modal_data.sm_POD_NAME}</p>
                    <p className="text-danger">{modal_data.sm_ARRIVE_DATE}</p>
                  </div>
                </div>
              </div>
            </div>

            <p className="font-weight-bold mt-3">품목 정보</p>
            <div style={{ fontSize: "0.9em" }}>
              - 아래의 표에 품목정보를 입력해주세요.
              <div className="d-inline-flex flex-row align-items-center">
                <span
                  style={{ width: 50, height: 18 }}
                  className="d-inline-block bg-info-light mx-2"
                />
                입력항목
              </div>
            </div>
            <div className="row border rounded overflow-hidden my-2 p-0 mx-0">
              <div className="border-right col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  화주 상호
                </div>{" "}
                <div className="p-2">
                  <input
                    className="border-0 bg-info-light w-100"
                    type="text"
                    ref={$controller.p_comp_nm}
                    placeholder="실화주 상호를 입력하세요"
                  />
                </div>
              </div>
              <div className="border-right col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  H B/L NO
                </div>{" "}
                <div className="p-2">
                  <input
                    className="border-0 bg-info-light w-100"
                    type="text"
                    ref={$controller.p_user}
                    placeholder="귀사의 B/L번호를 입력하세요"
                  />
                </div>
              </div>
              <div className="border-right col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  선적예정 품목명
                </div>
                <div className="p-2">
                  <input
                    className="border-0 bg-info-light w-100"
                    type="text"
                    ref={$controller.p_name}
                    placeholder="대표 품목을 입력하세요"
                  />
                </div>
              </div>
              <div className="border-right col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  중량(KG)
                </div>{" "}
                <div className="p-2">
                  <input
                    className="border-0 bg-info-light w-100"
                    type="text"
                    ref={$controller.p_weight}
                    placeholder="중량을 입력하세요"
                  />
                </div>
              </div>
              <div className="border-right col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  부킹물량
                </div>{" "}
                <div className="p-2">
                  <input
                    className="border-0 bg-info-light w-100"
                    type="text"
                    ref={$controller.p_cnt}
                    placeholder="물량을 입력하세요"
                  />
                </div>
              </div>
              <div className="col-12 col-xl-2 m-0 p-0">
                <div
                  className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                  style={{ minWidth: 100 }}
                >
                  단위
                </div>{" "}
                <div className="p-2">
                  <select
                    className="border-0 w-100 bg-info-light"
                    ref={$controller.p_quantities}
                  >
                    {modal_data.sm_FCL_LCL === "AIR" ? (
                      <option value="KG">KG</option>
                    ) : (
                      <>
                        <option value="">미선택</option>
                        <option value="CBM">CBM</option>
                        {/*(LCL)*/}
                        <option value="TEU">TEU</option>
                        {/*(20FT)*/}
                        <option value="FEU">FEU</option>
                        {/*(40FT)*/}
                      </>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <UploadImageToS3WithPhp
              region={"mall/booking"}
              callback={(val) => {
                handleUploadFile(val);
              }}
            />
            <div>
              {uploadFile.length > 0 &&
                uploadFile.map((item, index) => {
                  let fileName = item.split("?")[0];
                  let fileLink = item.split("?")[1];
                  return (
                    <p
                      className="d-flex flex-row justify-content-between mb-1 align-items-center"
                      key={index}
                    >
                      <a
                        className="font-weight-bold text-primary"
                        href={fileLink}
                        target="_blank"
                      >
                        {fileName}
                      </a>
                      <button
                        className="btn_1 bg-danger"
                        onClick={() => {
                          handleDeleteUploadFile(index);
                        }}
                      >
                        삭제
                      </button>
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="row mb-3">
            <p className="col-12 h6 mb-2">■ BOOKING 요청 메모</p>
            <div className="col-12">
              <textarea
                className=" form-control"
                ref={$controller.p_memo}
                rows={5}
                placeholder={
                  modal_data.sm_FCL_LCL === "AIR"
                    ? "화물 입고 예정시간등 추가정보와 특이사항을 작성해주세요"
                    : "예약요청시 특이사항을 작성해주세요"
                }
              />
            </div>
          </div>
          <div>
            <p className="h6 mb-2">■ BOOKING 정보 회신처</p>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">
                이메일{" "}
              </label>
              <div className="col-sm-9 ">{RenderEmailInput()}</div>
            </div>
          </div>
        </div>

        <div className="p-3 d-flex flex-row justify-content-end align-items-center border-top">
          <div
            className="btn_1 bg-danger mr-1"
            onClick={() => handleBookingModal(false)}
          >
            취소
          </div>
          <div className="btn_1 ml-1" onClick={() => submit()}>
            제출
          </div>
        </div>
      </div>
    </div>
  );
};
