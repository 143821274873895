/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import PostTable from "./PostTable";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";

function Post(props, ref) {
  const [activePost, setActivePost] = useState({
    title: "공지사항",
    id: "nt",
    filterChange: MsgIDList.EVENT_POST_NOTICE_FILTERS_CHANGED,
    categorize: [
      { key: "NTA01", desc: "새소식" },
      { key: "NTA02", desc: "오픈예정" },
      { key: "NTA03", desc: "시스템공지" },
      { key: "NTA04", desc: "추천선사 및 프로모션" },
      { key: "NTA05", desc: "입점업체현황" },
    ],
  });

  let tableSet = useRef();

  useImperativeHandle(ref, () => ({
    changePostType(e, params) {
      setActivePost({ ...params });
      tableSet.current.changeTableData(e, params);
    },
  }));
  return (
    <>
      <div className="col-lg-9">
        <div className="box_style_1">
          {activePost && (
            <PostTable ref={tableSet} activePostData={activePost} />
          )}
        </div>{" "}
      </div>
    </>
  );
}
export default forwardRef(Post);
