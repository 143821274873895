/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import CommonNav from "../CommonNav";
import Footer from "../Footer";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";
import { findAllByRole } from "@testing-library/react";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const serverFile = {
  update: "/product_request/JS_pr_update.php",
  insert: "/product_request/JS_pr_insert.php",
};

export default function WriteRequestProduct() {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState(null);
  const [isEditorState, setIsEditorState] = useState();
  const [pwDisabled, setPwDisabled] = useState(true);
  const [prData, setPrData] = useState({
    prTITLE: "",
    prCONTENTS: "",
    prUSERName: "",
    prUSEREmail: "",
    prPWD: "", //비밀번호 회사코드가있을경우 회사코드로 대체
    prPublicYN: "Y", //공개여부
  });

  const controller = {
    prTITLE: useRef(),
    prCONTENTS: useRef(),
    prUSERName: useRef(),
    prUSEREmail: useRef(),
    prPWD: useRef(), //비밀번호 회사코드가있을경우 회사코드로 대체
    prPublicYN: useRef(), //공개여부
  };

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "WriteRequestProduct",
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            setLoginInfo(n["returnData"][0]);
          }
        }
      }
    );
  })();

  useEffect(() => {
    const html =
      "<h6>■ 원하시는 수출입운송 상품에 대하여 아래 사항을 입력해 주세요 .</h6>\n" +
      "\n" +
      "<p><strong>∙ 견적서 제출기한 : </strong>예) 견적서는 2022년   월    일     시 까지 보내주세요.</p> \n" +
      "\n" +
      "<p><strong>∙ 운송구분 : </strong>예)  해상 , 항공</p>\n" +
      "\n" +
      "<p><strong>∙ 운송구간 : </strong> 예) 인천 -> 자카르타</p>\n" +
      "\n" +
      "<p><strong>∙ FCL/LCL(해상) :</strong> 예) FCL , LCL</p>\n" +
      "\n" +
      "<p><strong>∙ 추가서비스 신청여부 :</strong> 예) 통관, 적하보험</p>\n" +
      "\n" +
      "<p><strong>∙ 내륙운송 신청 및 구간 :</strong>  신청, 불필요.  예) 하남에서 인천수출 CFS까지 배차요청</p>\n" +
      "\n" +
      "<p><strong>∙ 기타요청사항 : </strong> 자유롭게 작성해 주세요.  예) 본 건 견적서외에 광고메일 사절합니다.</p>\n";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setIsEditorState(editorState);
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "WriteRequestProduct"
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo !== null) {
      controller.prUSERName.current.value = loginInfo.user_nm;
    }
  }, [loginInfo]);

  const requestProduct = () => {
    if (checkValidation()) {
      codeList.Modal.current.confirm(
        "상품요청 게시글 등록을 진행하시겠습니까?",
        (ret) => {
          if (ret) {
            let updateData = {
              prUSERName: controller.prUSERName.current.value, //질문자 명
              prTITLE: controller.prTITLE.current.value, //질문 제목
              prCONTENTS: controller.prCONTENTS.current.value, //질문 내용
              prUSEREmail: controller.prUSEREmail.current.value, //질문자 이메일
              prIDX: "", //index
              prINSERTDATE: new Date(), //질문 업로드일
              prUSERID: loginInfo ? loginInfo.user_id : "", //로그인 경우 유저 아이디
              prCompCD: loginInfo ? loginInfo.comp_cd : "", //회사 코드 z로 시작시 관리자
              prUSEYN: "Y", //사용여부

              prPWD: controller.prPWD.current.value, //비밀번호 회사코드가있을경우 회사코드로 대체
              prPublicYN: prData.prPublicYN, //공개여부
            };
            requestUserUpdate(updateData);
          } else {
            codeList.Modal.current.alert(
              "상품요청 게시글 등록이 취소되었습니다."
            );
          }
        }
      );
    }
  };

  const checkValidation = () => {
    if (controller.prTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if (controller.prUSERName.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요", () => {});
      return false;
    }
    if (controller.prCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if (prData.prPublicYN === "N") {
      if (controller.prPWD.current.value.trim() === "") {
        codeList.Modal.current.alert("비밀번호를 입력해 주세요", () => {});
        return false;
      }
    }
    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/product_request/JS_pr_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert(
            "문의사항 등록이 완료되었습니다.",
            () => {
              window.location.pathname = "/Post/prq";
            }
          );
        }
      }
    });
  };

  const ChangePublicYN = (e) => {
    let val = e.target.value;
    let setData = {};
    if (val === "N") {
      if (loginInfo !== null) {
        controller.prPWD.current.value = loginInfo.comp_cd;
        setData.prPWD = loginInfo.comp_cd;
        setPwDisabled(true);
      } else {
        setPwDisabled(false);
      }
    } else {
      controller.prPWD.current.value = "";
    }
    setPrData({ ...prData, ...setData, prPublicYN: val });
    // console.log(prData);
  };

  return (
    <>
      {" "}
      <div className="white_bg" id="write_QnA" style={{ minHeight: "100vh" }}>
        <CommonNav />
        <main className="white_bg">
          <div className="py-5">
            <div className="my-5">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="pt-3 text-muted font-weight-bold">
                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <h3 className="mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0">
                          <strong>상품요청 게시글 작성하기</strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-row mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="제목을 입력해 주세요"
                      ref={controller.prTITLE}
                    />
                  </div>
                  <div className="col-12 d-flex flex-row mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="작성자(닉네임 가능)"
                      ref={controller.prUSERName}
                    />
                  </div>
                  <div className="col-12 d-flex flex-row mb-3">
                    <input
                      className="form-control mr-3"
                      type="text"
                      placeholder="회신 이메일 (포워더 회원에게만 노출됩니다 /미 기재시 GBTS 아이디 사용)"
                      ref={controller.prUSEREmail}
                    />

                    <input
                      className="form-control mr-3"
                      type="password"
                      placeholder="비밀번호"
                      ref={controller.prPWD}
                      disabled={pwDisabled}
                    />
                    <div className="btn-group btn-group-toggle">
                      <label
                        className={`btn btn-light ${
                          prData.prPublicYN === "N" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="public"
                          value="N"
                          onClick={(e) => {
                            ChangePublicYN(e);
                          }}
                        />
                        <i className="icon-lock" />
                      </label>{" "}
                      <label
                        className={`btn btn-light ${
                          prData.prPublicYN === "Y" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="public"
                          value="Y"
                          onClick={(e) => {
                            ChangePublicYN(e);
                          }}
                        />
                        <i className="icon-lock-open-alt" />
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control d-none"
                      rows={20}
                      placeholder="문의사항을 입력해주세요"
                      ref={controller.prCONTENTS}
                      disabled
                      value={
                        isEditorState &&
                        draftToHtml(
                          convertToRaw(isEditorState.getCurrentContent())
                        )
                      }
                    />
                    <Editor
                      editorState={isEditorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="border write_editor"
                      onEditorStateChange={setIsEditorState}
                    />
                  </div>
                  <div className="col-12 py-3 d-flex flex-row justify-content-center">
                    <div
                      className="btn_1 px-5"
                      onClick={() => {
                        requestProduct();
                      }}
                    >
                      등록하기
                    </div>

                    <Link to="/Post/pr" className="btn_1 outline ml-2 px-5">
                      목록으로
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
