/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { ResponsiveBar } from "@nivo/bar";
import { ChartDefaultOption } from "../../../lib/CommonUI";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "DashboardFCLReserveBarChart";
let mFilter = {};

export default forwardRef(function DashboardFCLReserveBarChart({}, ref) {
  const [serverData, setServerData] = useState([
    {
      sandwich: 141,
      sandwichColor: "hsl(337, 70%, 50%)",
    },
  ]);
  const [dataKeys, setDataKeys] = useState([]);

  useEffect(() => {
    //requestUserList();
  }, []);

  useImperativeHandle(ref, () => ({
    requestServerData(params) {
      mFilter = { ...params };
      requestServerData();
    },
  }));

  const requestServerData = (filter) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/dashboard/JS_dashboard_fcl_reserve_per_forwarder.php",
      msgID: msgID,
      data: {},
    };

    if (mFilter) {
      socketMsg = { ...socketMsg, ...mFilter };
    } else {
      return;
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          // setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const MyResponsiveBar = React.memo(
    ({ data /* see data tab */, dataKeys }) => {
      return (
        <ResponsiveBar
          {...ChartDefaultOption.ResponsiveBar}
          data={data}
          keys={["f_count"]}
          indexBy="sbf_COMP_NM"
          tooltip={({
            id,
            value,
            formattedValue,
            index,
            indexValue,
            data,
            color,
          }) => (
            <div
              style={{
                padding: 12,
                color: "white",
                background: "#222222",
              }}
            >
              <strong>
                {indexValue} : {formattedValue} 건
              </strong>
            </div>
          )}
        />
      );
    }
  );

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  return (
    <>
      {/*<MyResponsivePie data={sampleData}/>*/}
      <div style={{ height: "300px" }}>
        <MyResponsiveBar data={data} dataKeys={dataKeys} />
      </div>
    </>
  );
});
