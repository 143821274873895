import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CorpInfoReferenceDetailSummary from "./corpInfoReferenceDetailSummary/CorpInfoReferenceDetailSummary";
import CorpInfoReferenceDetailFinance from "./corpInfoReferenceDetailFinance/CorpInfoReferenceDetailFinance";
import CorpInfoReferenceDetailSales from "./corpInfoReferenceDetailSales/CorpInfoReferenceDetailSales";

import LogoSvg from "../../assets/LogoSvg";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import GBTSLogoSvg from "../../assets/GBTSLogoSvg";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CorpInfoReferencePrint";

let isDevSer = window.origin === "https://www.gbts.co.kr:4100";
export default function CorpInfoReferencePrint(props) {
  const [compInfo, setCompInfo] = useState(null);
  const [loginInfo, setLoginInfo] = useState(null);
  const [isExistData, setIsExistData] = useState(false);

  const param = useParams();

  // 기업정보 요청
  const requestCompInfo = (key, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/basa/JS_basa_result_history_select.php",
      msgID: msgID,
      data: [],
      brhIDX: key,
      brhCOMP_CD: loginInfo.comp_cd,
      brhUSER_ID: loginInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 기업조회 데이터 가공
  const handleCompData = (dataList, callback) => {
    if (isDevSer) {
      let testIndex = dataList.findIndex((item) => item.req_cd === "3000407");
      if (testIndex > -1 && dataList[testIndex].Lst.length === 0) {
        dataList[testIndex].Lst = [
          { item_val: "45.00", item_nm: "결제기간 일수" },
        ];
      }
    }

    let _compInfo = {};
    for (let item of dataList) {
      _compInfo[item.req_cd] = item["Lst"] ? item["Lst"] : [];
    }

    setIsExistData(true);
    setCompInfo(_compInfo);
    callback && callback();
  };

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData["returnData"][0]);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          setLoginInfo(n["returnData"][0]);
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo !== null) {
      if (param && param.hasOwnProperty("key")) {
        requestCompInfo(param.key, (newData) => {
          if (newData["returnData"].length > 0) {
            let data = {
              brhIDX: newData["returnData"][0].brhIDX,
              brhBizno: newData["returnData"][0].brhBizno,
              brhCOMP_CD: newData["returnData"][0].brhCOMP_CD,
              brhUSER_ID: newData["returnData"][0].brhUSER_ID,
              brhCreatedDate: newData["returnData"][0].brhCreatedDate,
              ...JSON.parse(newData["returnData"][0].brhResult),
            };

            handleCompData(data.rpnsRsltLst);
          } else {
            window.close();
          }
        });
      } else {
        window.close();
      }
    }
  }, [loginInfo]);

  let DOC_DATE = "-";

  if (isExistData && compInfo !== null) {
    for (let item of compInfo["1000003"]) {
      switch (item.item_nm) {
        case "보고서 기준일자":
          DOC_DATE = item.item_val;
          break;
      }
    }
  }

  const params = {
    compInfo: compInfo,
    isExistData: isExistData,
    isPrint: true,
    DOC_DATE,
  };

  if (!isExistData) return <></>;

  return (
    <div className="p-4 bg-white page">
      <div className="d-flex flex-row justify-content-between mb-3">
        <div className="flex-grow-1 text-center">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <GBTSLogoSvg height={"24px"} />
            <p className="h6 font-weight-bold ml-3 mb-0">
              로지톡-기업정보 조회
            </p>
          </div>
          <p>https://www.gbts.co.kr</p>
        </div>
        <div>
          <button
            className="btn btn-sm btn-secondary comp_info_print_btn"
            onClick={() => window.print()}
            disabled={!isExistData}
          >
            <i className="fa-solid fa-print" /> 인쇄하기
          </button>
        </div>
      </div>
      <div className="comp_info_print">
        <CorpInfoReferenceDetailSummary {...params} />
        <div className="my-5">
          <CorpInfoReferenceDetailFinance {...params} />
        </div>
        <CorpInfoReferenceDetailSales {...params} />
      </div>
    </div>
  );
}
