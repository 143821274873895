/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithPhp from "../../../lib/UploadImageToS3WithPhp";

const _mClassName = "EntireTalkRoomEditModal";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
function EntireTalkRoomEditModal(props, ref) {
  const { loginInfo } = props;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const controller = {
    cnmPicMain: useRef(),
    cnmName: useRef(),
    cnmDesc: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_ENTIRE_TALK_ROOM_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData(n);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ENTIRE_TALK_ROOM_EDIT_MODAL_CALL,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      setUserData(params);
    },
    removeData(e, params) {
      requestUserDelete(e, params);
    },
  }));

  useEffect(() => {
    if (userData !== null) {
      controller.cnmPicMain.current.value = userData.cnmPicMain;
      controller.cnmName.current.value = userData.cnmName;
      controller.cnmDesc.current.value = userData.cnmDesc;
    } else {
      controller.cnmPicMain.current.value = "";
      controller.cnmName.current.value = "";
      controller.cnmDesc.current.value = "";
    }
  }, [userData]);

  const checkValidation = () => {
    if (controller.cnmName.current.value.trim() === "") {
      codeList.Modal.current.alert("채널 명칭을 입력해주세요", () => {
        controller.cnmName.current.focus();
      });
      return false;
    }
    if (controller.cnmDesc.current.value.trim() === "") {
      codeList.Modal.current.alert("채널 설명을 입력해주세요", () => {
        controller.cnmDesc.current.focus();
      });
      return false;
    }

    return true;
  };

  const updateEntireTalkList = (data) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ENTIRE_TALK_ROOM_LIST_UPDATE,
      _mClassName,
      data,
      () => {}
    );
  };

  const updateEntireTalkRoom = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_updateEntireTalkRoom.php",
      msgID: msgID,
      data: [],
      ...updateData,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상처리되었습니다", () => {
            updateEntireTalkList(updateData);
            closeModal();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  const actionSave = () => {
    if (!checkValidation()) return;
    const updateData = {
      cnmMainKey: userData !== null ? userData.cnmMainKey : util.makeUUIDv4(),
      cnmType: "5",
      cnmSite: loginInfo.comp_cd,
      cnmCompNM: loginInfo.comp_nm,
      cnmTreeKey: "",
      cnmAllow: "",
      cnmKind: "",
      cnmPWD: "",
      cnmPicMain: controller.cnmPicMain.current.value,
      cnmName: controller.cnmName.current.value,
      cnmDesc: controller.cnmDesc.current.value,
      cnmCreator: loginInfo.user_id,
      cnmCreatorSite: loginInfo.comp_cd,
      cnmCreatDate: new Date(),
      cnmUpdateDate: new Date(),
      cnmDelYN: "N",
    };

    updateEntireTalkRoom(updateData);
  };

  const handleUploadImage = (data) => {
    if (!data.ret) {
      codeList.Modal.current.alert("오류가 발생했습니다. 다시 시도해주세요");
      return;
    }
    controller.cnmPicMain.current.value = `${data.fileName}?${data.realFileName}`;
    setUserData((prevState) => {
      return {
        ...prevState,
        cnmPicMain: `${data.fileName}?${data.realFileName}`,
      };
    });
  };

  const requestUserDelete = (e, params) => {
    codeList.Modal.current.confirm(
      `[${params.cnmName}]을 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          const updateData = {
            ...params,
            cnmDelYN: "Y",
          };
          updateEntireTalkRoom(updateData);
        }
      }
    );
  };

  const closeModal = () => {
    controller.cnmPicMain.current.value = "";
    controller.cnmName.current.value = "";
    controller.cnmDesc.current.value = "";
    setUserData(null);
    $("#EntireTalkRoomEditModalClose").click();
  };

  return (
    <div
      className="modal fade"
      id="EntireTalkRoomEditModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="EntireTalkRoomEditModal"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: 700 }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="staticBackdropLabel">
              <strong>전체메세지 채널 등록</strong>
            </h3>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="EntireTalkRoomEditModalClose"
              onClick={closeModal}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>

          <div className="modal-body">
            <div className="mb-4 row justify-content-center">
              <div
                className="rounded-circle border position-relative"
                style={{
                  width: 200,
                  height: 200,
                  background: `url(${
                    userData ? userData.cnmPicMain : ""
                  }) 50% 50%/ contain no-repeat`,
                }}
              >
                <UploadImageToS3WithPhp
                  region={"newsTalk/entire"}
                  callback={handleUploadImage}
                >
                  <span
                    className="position-absolute btn btn-secondary"
                    style={{ bottom: 10, right: 10 }}
                  >
                    <i className="fa-regular fa-image" />
                  </span>
                </UploadImageToS3WithPhp>
              </div>
              <input
                className="d-none"
                ref={controller.cnmPicMain}
                defaultValue={userData ? userData.cnmPicMain : ""}
              />
            </div>

            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">채널명</label>
              <div className="col-sm-9">
                <input
                  className="form-control"
                  ref={controller.cnmName}
                  placeholder="채널명을 입력해주세요."
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">설명</label>
              <div className="col-sm-9">
                <textarea
                  className="form-control"
                  ref={controller.cnmDesc}
                  rows={5}
                  placeholder="채널에 대한 설명을 입력해주세요."
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              <i className="fa fa-times mr-2" />
              취소
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => actionSave()}
              disabled={loading}
            >
              <i className="fa fa-check mr-2" />
              저장
              {loading && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(EntireTalkRoomEditModal);
