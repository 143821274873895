/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import ScheduleAIRManageEdit from "./ScheduleAIRManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
    "& button": {
      borderRadius: "100%",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const _mClassName = "ScheduleAIRManageTable";

const headCells = [
  {
    field: "sm_IDX",
    headerName: "관리번호",
    numeric: false,
    disablePadding: false,
  },

  {
    field: "sm_DEPARTURE_DATE",
    headerName: "운항월",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let month = new Date(params.data).getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      return (
        <span className="text-truncate text-wrap text-break">{month}월</span>
      );
    },
  },
  {
    field: "sm_PROVIDER_NM",
    headerName: "공급사",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sm_FWD_NM",
    headerName: "항공사",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },

  {
    field: "sm_VESSEL",
    headerName: "항공기",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sm_VOY}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_ORIGIN",
    headerName: "출발지",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
        </div>
      );
    },
  },
  {
    field: "sm_DEST",
    headerName: "도착지",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
        </div>
      );
    },
  },
  {
    field: "sm_USE_YN",
    headerName: "노출여부",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">
            {params.data === "Y" ? "사용" : "중지"}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_Function",
    headerName: "기능",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#ScheduleAIRManageEdit"
            onClick={(e) => params.openEdit(e, params)}
            title={"수정"}
            aria-label={"수정"}
          >
            <i className="fa-solid fa-pen" />
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-toggle="modal"
            onClick={(e) => params.removeData(e, params)}
            title={"삭제"}
            aria-label={"삭제"}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        </span>
      );
    },
  },
];

let filters = {};

export default function ScheduleAIRManageTable({
  isServerPaging = true,
  isServerSort = true,
  searchCodeList,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const [airline, setAirline] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sm_IDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "sm_IDX",
      dir: "desc",
    },
  ]);

  useEffect(() => {
    requestAirlineList();

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = { ...n };
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestAirlineList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/airline/JS_airline_select.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log(newData);
      if (newData) {
        if (newData["ret"]) {
          const _airline = newData["returnData"].filters(
            (data) => data.COMP_TYPE === "A"
          );
          const _supplier = newData["returnData"].filters(
            (data) => data.COMP_TYPE === "A"
          );
          setAirline(_airline);
          setSupplier(_supplier);
        }
      }
    });
  };

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_air_sub_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeData(e, params) {
    editModal.current.removeData(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeData={removeData}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEdit={openEdit}
                removeData={removeData}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <ScheduleAIRManageEdit
        ref={editModal}
        requestUserSelect={requestUserSelect}
        searchCodeList={searchCodeList}
        airline={airline}
        supplier={supplier}
      />
    </TableContainer>
  );
}

ScheduleAIRManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const {
    row,
    isMultiSelect = false,
    requestUserSelect,
    requestUserUpdate,
    openEdit,
    removeData,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserUpdate: requestUserUpdate,
                  openEdit: openEdit,
                  removeData: removeData,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <ScheduleDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                    requestUserUpdate={requestUserUpdate}
                    openEdit={openEdit}
                    removeData={removeData}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

const handleScheduleData = (rowData) => {
  let data = { ...rowData };

  for (let key in rowData) {
    if (key.startsWith("sm_INFO")) {
      if (data[key]) {
        let splitData = data[key].split("|");
        let sm_INFO = {
          FLIGHT_NAME: "",
          AIR_FREIGHT_NO: "",
          AIR_FREIGHT_MEMO: "",
          DEPARTURE_TIME: "",
          AIRRIVE_TIME: "",
        };

        sm_INFO["FLIGHT_NAME"] = splitData[0];

        if (data.sm_VESSEL) {
          sm_INFO["AIR_FREIGHT_NO"] = data.sm_VESSEL;
        }

        for (let i = 0; i < splitData.length; i++) {
          let data = splitData[i];
          if (i === 0) {
            sm_INFO["FLIGHT_NAME"] = splitData[0];
            continue;
          }

          if (data.length === 4) {
            sm_INFO["AIR_FREIGHT_NO"] = data;
            continue;
          }
          if (data.indexOf("/") > -1) {
            let flightTime = data;
            let flightTimeSplit = undefined;
            if (flightTime.indexOf("+")) {
              flightTime = flightTime.split("+")[0];
            }
            flightTimeSplit = flightTime.split("/");
            let depTime = flightTimeSplit[0].split("");
            let airTime = flightTimeSplit[1].split("");
            sm_INFO[
              "DEPARTURE_TIME"
            ] = `${depTime[0]}${depTime[1]}:${depTime[2]}${depTime[3]}`;
            sm_INFO[
              "AIRRIVE_TIME"
            ] = `${airTime[0]}${airTime[1]}:${airTime[2]}${airTime[3]}`;
          } else {
            if (sm_INFO["AIR_FREIGHT_MEMO"] === "") {
              sm_INFO["AIR_FREIGHT_MEMO"] = data;
            } else {
              sm_INFO[
                "AIR_FREIGHT_MEMO"
              ] = `${sm_INFO["AIR_FREIGHT_MEMO"]} \n* ${data}`;
            }
          }
        }

        data[key] = sm_INFO;
      } else {
        data[key] = null;
      }
    }
  }
  return data;
};
const ScheduleDetail = (props) => {
  let { rowData, requestUserSelect, setOpen } = props;
  const [weekData, setWeekData] = useState({});

  useEffect(() => {
    let data = handleScheduleData(rowData);
    setWeekData(data);
  }, []);

  let sm_FWD_LOGO = "";
  if (weekData.sm_FWD_LOGO) {
    sm_FWD_LOGO = weekData.sm_FWD_LOGO.split("?")[0];
  }
  let sm_PROVIDER_LOGO = "";
  if (weekData.sm_PROVIDER_LOGO) {
    sm_PROVIDER_LOGO = weekData.sm_PROVIDER_LOGO.split("?")[0];
  }

  return (
    <tr className="container" id={rowData.sr_IDX}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <p className="m-0 font-weight-bold text-left">
              <span className="text-freiscop mr-2">
                <i className="fa-solid fa-angles-right text-freiscop" />
              </span>
              {weekData.sm_ORIGIN}
              <span className="text-freiscop mx-2">
                <i className="fa-solid fa-ellipsis" />{" "}
                <i className="fa-solid fa-plane" />{" "}
                <i className="fa-solid fa-ellipsis" />
              </span>{" "}
              {weekData.sm_DEST}
            </p>
          </div>

          <div className="card-body">
            <div className="row m-0 mb-3 p-0">
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 관리번호</p>
                <p className="ml-2">{weekData.sm_IDX}</p>
              </div>
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 운항월</p>
                <p className="ml-2">{weekData.sm_DEPARTURE_DATE}</p>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 항공사</p>
                <p className="ml-2">
                  {sm_FWD_LOGO && (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        background: `url(${sm_FWD_LOGO})50% 50% / contain no-repeat`,
                      }}
                    />
                  )}
                  {weekData.sm_FWD_NO}
                </p>
              </div>
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 공급사</p>
                <p className="ml-2">
                  {sm_PROVIDER_LOGO && (
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        background: `url(${sm_PROVIDER_LOGO})50% 50% / contain no-repeat`,
                      }}
                    />
                  )}
                  {weekData.sm_PROVIDER_NM}
                </p>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 항공기 No</p>
                <p className="ml-2">{weekData.sm_VESSEL}</p>
              </div>
              <div className="col-6 m-0 p-0">
                <p className="font-weight-bold mb-1">· 화물전용 여부</p>
                <p className="ml-2">
                  {weekData.sm_CARGO_YN === "Y" ? "화물전용" : "화물/승객"}
                </p>
              </div>
            </div>
            <p className="font-weight-bold mb-1">· 요일별 상세</p>
            <div className="mb-3 bg-light rounded row m-0 p-0">
              <div className="col-12 m-0 p-0">
                <div className="row h-100 m-0 p-0 text-center">
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold text-danger">
                      일{/*{selectWeekDateList.SUN}*/}
                    </span>
                    {weekData.sm_INFO_SUN && (
                      <AirFreightItem data={weekData.sm_INFO_SUN} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold">
                      월{/*{selectWeekDateList.MON}*/}
                    </span>
                    {weekData.sm_INFO_MON && (
                      <AirFreightItem data={weekData.sm_INFO_MON} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold">
                      화{/*{selectWeekDateList.TUE}*/}
                    </span>
                    {weekData.sm_INFO_TUE && (
                      <AirFreightItem data={weekData.sm_INFO_TUE} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold">
                      수{/*{selectWeekDateList.WED}*/}
                    </span>
                    {weekData.sm_INFO_WED && (
                      <AirFreightItem data={weekData.sm_INFO_WED} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold">
                      목{/*{selectWeekDateList.THU}*/}
                    </span>
                    {weekData.sm_INFO_THU && (
                      <AirFreightItem data={weekData.sm_INFO_THU} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold">
                      금 {/*{selectWeekDateList.FRI}*/}
                    </span>
                    {weekData.sm_INFO_FRI && (
                      <AirFreightItem data={weekData.sm_INFO_FRI} />
                    )}
                  </div>
                  <div
                    className="col-auto flex-grow-1 m-0 p-2 d-flex flex-column justify-content-satrt align-items-center"
                    style={{ width: 100 }}
                  >
                    <span className="mb-2 font-weight-bold text-primary">
                      토 {/*{selectWeekDateList.SAT}*/}
                    </span>
                    {weekData.sm_INFO_SAT && (
                      <AirFreightItem data={weekData.sm_INFO_SAT} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2">
              <p className="font-weight-bold">※ 주요 안내사항</p>
              <p>{weekData.sm_REMARK || "-"}</p>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

const PlaneIcon = ({ data }) => {
  return (
    <div
      className={`border-0 bg-freiscop text-white rounded-sm d-flex flex-row align-items-center justify-content-center`}
      style={{ width: 30, height: 30 }}
    >
      <i className="fa-solid fa-plane-departure" />
    </div>
  );
};

const AirFreightItem = ({ data }) => {
  return (
    <>
      <PlaneIcon data={data} />
      <div className="mt-1 font-size-08">
        <p>{data.FLIGHT_NAME}</p>
        <p>{data.AIR_FREIGHT_NO}</p>
        <p className="font-weight-bold">
          {data.DEPARTURE_TIME} - {data.AIRRIVE_TIME}
        </p>
        <p className="font-weight-bold text-danger">
          {data.AIR_FREIGHT_MEMO ? "*" + data.AIR_FREIGHT_MEMO : " "}
        </p>
      </div>
    </>
  );
};
