/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Utilities from "../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import QnAManageTable from "./QnAManageTable";
import axios from "axios";
import CodeList from "../../lib/CodeList";
import CommonLoader from "../CommonLoader";
const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const QNA_TYPE = [
  { key: "FJA0", desc: "해상(항공) 스케줄 및 견적문의" },
  { key: "FJA1", desc: "내륙운송 문의" },
  { key: "FJA2", desc: "보관 및 하역관련 문의" },
  { key: "FJA3", desc: "통관 및 FTA문의" },
  { key: "FJA4", desc: "적하보험 및 기타문의" },
];

const _mClassName = "QnAManage";

function QnAManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $searchText = useRef();
  const $searchLevel = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_FAQ_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const downloadExcel = (e) => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//www.gbts.co.kr/backside/faq/JS_faq_excel_export.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              SEARCH_WORD: $searchText.current.value,
              SEARCH_LEVEL: $searchLevel.current.value,
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Q&A리스트_${util.todayDate("YYYYMMDD")}.xls`
            );
            document.body.appendChild(link);
            link.click();
          });
        }
      }
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col">
            <div className="card">
              <div className="card-header">
                {/*필터 영역*/}
                <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                  {/*간편기간 영역*/}

                  <div
                    className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                    role="toolbar"
                  >
                    <div
                      className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                      role="group"
                    >
                      <button
                        className="btn  btn-outline-primary "
                        data-toggle="modal"
                        data-target="#staticQnAEditModal"
                        onClick={addNewData}
                        alt={"신규등록"}
                        aria-label={"신규등록"}
                        type="button"
                      >
                        <i className="fas fa-plus" /> 신규등록
                      </button>

                      <button
                        className="btn  btn-outline-primary"
                        onClick={downloadExcel}
                      >
                        <i className="fas fa-download mr-2" />
                        엑셀파일로 받기
                      </button>
                    </div>

                    <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                      <select
                        className="custom-select form-control"
                        ref={$searchLevel}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">분류</option>
                        {QNA_TYPE.map((menu, index) => {
                          return (
                            <option key={index} value={menu.key}>
                              {menu.desc}
                            </option>
                          );
                        })}
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={(ref) => ($searchText.current = ref)}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </h6>
              </div>
              <QnAManageTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QnAManage;
