/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "EntireTalkRoomCompList";
const COMP_TYPE = {
  A: "화주",
  B: "포워더 (선사)",
  C: "운송사",
  D: "보험사",
  E: "관세사",
  F: "보세창고",
  Y: "개인회원",
  Z: "GBTS 관리자",
};
const COMP_GB_SUB_TYPE = {
  B01: "포워더",
  B02: "콘솔사",
  B03: "선사",
  F01: "보세창고",
  F02: "CY",
  F03: "일반창고",
};

const BUSINESS_TYPE_B = [
  { key: "ALL", value: "All", name: "-업체분류-" },
  { key: "B03", value: "B03", name: "선사" },
  { key: "B01", value: "B01", name: "포워더" },
];
const BUSINESS_TYPE = [
  { key: "A", value: "A", name: "화주" },
  { key: "C", value: "C", name: "운송사" },
  { key: "D", value: "D", name: "보험사" },
  { key: "E", value: "E", name: "관세사" },
  { key: "F", value: "F", name: "보세창고" },
  { key: "Y", value: "Y", name: "개인회원" },
  { key: "Z", value: "Z", name: "GBTS 관리자" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
    "& button": {
      borderRadius: "100%",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 4,
    },
    tb: {
      lineHeight: "1em",
    },
  },
});

const headCells = [
  {
    field: "COMP_GB",
    headerName: "구분",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.currentData.COMP_GB_SUB2
            ? `${COMP_GB_SUB_TYPE[params.currentData.COMP_GB_SUB2]}`
            : COMP_TYPE[params.data]}
        </span>
      );
    },
  },
  {
    field: "COMP_CD",
    headerName: "코드",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "COMP_C_NUM",
    headerName: "부호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "COMP_NM",
    headerName: "업체명",
    minWidth: 120,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let logo = null;

      if (
        params.currentData.SHIP_IMG_URL10 !== null &&
        params.currentData.SHIP_IMG_URL10 !== ""
      ) {
        logo = params.currentData.SHIP_IMG_URL10;
        logo = logo.replace("gbts-invoice.", "gbts-invoice-resized.");
        logo = logo.replace("gbts-invoice/", "gbts-invoice-resized/");
      }

      if (params.currentData.COMP_LOGO) {
        logo = params.currentData.COMP_LOGO;
      }

      return (
        <div className="d-flex flex-row align-items-center">
          <div
            style={{
              background: `url(${logo}) 50% 50% / contain no-repeat`,
              minWidth: logo ? 30 : 0,
              maxWidth: 30,
              minHeight: 30,
            }}
          />
          <div className="text-truncate text-wrap text-break">
            &nbsp;{params.data}
          </div>
        </div>
      );
    },
  },
];

export default function EntireTalkRoomCompList(props) {
  const {
    isServerPaging = true,
    isServerSort = true,
    selectedTalkRoom,
    selectedTalkRoomCompList,
    updateTalkRoomCompList,
  } = props;

  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("COMP_CD");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "COMP_CD",
      dir: "asc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const controller = {
    comp_gb: useRef(),
    searchType: useRef(),
    searchWord: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    setPage(0);
    setFilters({});
  }, [selectedTalkRoom, selectedTalkRoomCompList]);

  useEffect(() => {
    selectedTalkRoom.cnmMainKey && requestUserSelect();
  }, [page, sortModel, filters]);

  const handleCompList = (dataList) => {
    let list = [];
    for (let i = 0; i < dataList.length; i++) {
      list.push(dataList[i].COMP_CD);
    }
    return list.join("|");
  };

  const requestUserSelect = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_getAllCompList.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      sites: handleCompList(selectedTalkRoomCompList),
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = [];
          for (let i = 0; i < newData["returnData"].length; i++) {
            dataList[i] = newData["returnData"][i];
            if (
              dataList[i].hasOwnProperty("SHIP_IMG_URL10") &&
              dataList[i].SHIP_IMG_URL10
            ) {
              dataList[i].SHIP_IMG_URL10 = util.changeURIForThumbnail(
                dataList[i].SHIP_IMG_URL10
              );
            }
          }
          setServerData(dataList);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const requestUserSelectAll = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_entire/JS_getAllCompList.php",
      msgID: msgID,
      data: [],
      sort: [...sortModel],
      sites: handleCompList(selectedTalkRoomCompList),
      ...filters,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const changeFilters = (e) => {
    setPage(0);

    let filter = { ...filters };
    if (e.target.value === "All") {
      filter.searchType = "";
      filter.searchWord = "";
      controller.comp_gb.current.value = "All";
      controller.searchType.current.value = "";
      controller.searchWord.current.value = "";
      setFilters({});
      return;
    }

    if (e.target.name === "COMP_GB") {
      filter.COMP_GB_SUB2 = "";
    }
    if (e.target.name === "COMP_GB_SUB2") {
      filter.COMP_GB = "";
    }
    if (e.target.name !== "searchBtn" && e.target.name !== "searchWord") {
      filter[e.target.name] = e.target.value;
    } else {
      filter = {};
      filter.searchType = controller.searchType.current.value;
      filter.searchWord = controller.searchWord.current.value;
    }

    setFilters(filter);
  };

  const selectCompFilteredAll = () => {
    requestUserSelectAll((newData) => {
      let dataList = [...newData["returnData"]];
      for (let i = 0; i < newData["returnData"].length; i++) {
        const data = newData["returnData"][i];
        const index = dataList.findIndex(
          (item) => item.COMP_CD === data.COMP_CD
        );

        if (index > -1) continue;

        dataList.push(data);
      }

      setSelected(dataList);
    });
  };

  const selectComp = (isCheck, data) => {
    let dataList = [...selected];

    if (isCheck) {
      dataList = [...dataList, data];
    }

    if (!isCheck) {
      const index = dataList.findIndex((item) => item.COMP_CD === data.COMP_CD);
      dataList.splice(index, 1);
    }

    setSelected(dataList);
  };

  const handleUpdateTalkRoomCompList = () => {
    const _dataList = [...selectedTalkRoomCompList, ...selected];
    const uniqueArray = _dataList.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (o) => o.COMP_CD === obj.COMP_CD && o.COMP_CD === obj.COMP_CD
        )
    );

    updateTalkRoomCompList(uniqueArray, () => {
      controller.comp_gb.current.value = "All";
      controller.searchType.current.value = "";
      controller.searchWord.current.value = "";
      setSelected([]);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (property === "COMP_GB") {
      property = "COMP_GB_SUB2";
    }
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const isDisabled = !selectedTalkRoom.cnmMainKey;
  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = {
    selectedTalkRoomCompList,
    selected,
    selectComp,
  };

  return (
    <div className="card shadow-sm">
      <div className="card-header">
        <div className="d-flex flex-column flex-xl-row justify-content-between align-items-end align-items-xl-center">
          <p className="h6 mb-xl-0 font-weight-bold text-dark">등록가능 업체</p>
          <div className="text-right">
            <button
              className="btn btn-sm btn-primary mb-2"
              disabled={isDisabled}
              onClick={() => {
                selectCompFilteredAll();
              }}
            >
              전체 선택
            </button>
            <button
              className="btn btn-sm btn-danger ml-2 mb-2"
              disabled={isDisabled}
              onClick={() => {
                setSelected([]);
              }}
            >
              전체 취소
            </button>
            <button
              className="btn btn-sm btn-secondary ml-2 mb-2"
              value="All"
              onClick={(e) => {
                changeFilters(e);
              }}
            >
              검색초기화
            </button>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <select
            disabled={isDisabled}
            className="form-control border"
            style={{ minWidth: 80, maxWidth: 150 }}
            name="COMP_GB"
            ref={controller.comp_gb}
            onChange={(e) => {
              if (e.target.value.length > 1) {
                e.target.name = "COMP_GB_SUB2";
              } else {
                e.target.name = "COMP_GB";
              }
              changeFilters(e);
            }}
          >
            {BUSINESS_TYPE_B.map((bType, index) => {
              return (
                <option key={bType.key} value={bType.value}>
                  {bType.name}
                </option>
              );
            })}
            {BUSINESS_TYPE.map((bType, index) => {
              return (
                <option key={bType.key} value={bType.value}>
                  {bType.name}
                </option>
              );
            })}
          </select>
          <div className="ml-2">
            <div className="input-group justify-content-end">
              <select
                className="input-group-append form-control border"
                style={{ minWidth: 100, maxWidth: 150 }}
                name="searchType"
                ref={controller.searchType}
                disabled={isDisabled}
                onChange={(e) => changeFilters(e)}
              >
                <option value="">-검색조건-</option>
                <option value="COMP_CD">업체코드</option>
                <option value="COMP_NUM">사업자등록번호</option>
                <option value="COMP_NM">업체명</option>
              </select>
              <input
                type="text"
                className="form-control border"
                style={{ minWidth: 200, maxWidth: 200 }}
                placeholder="검색어를 입력해 주세요"
                aria-label="검색어"
                aria-describedby="btnGroupAddon2"
                name="searchWord"
                ref={controller.searchWord}
                disabled={isDisabled}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    changeFilters(e);
                  }
                }}
              />

              <button
                className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                style={{
                  borderRadius: "0px 3px 3px 0",
                }}
                name="searchBtn"
                onClick={(e) => changeFilters(e)}
                disabled={isDisabled}
              >
                <i className="fas fa-search mr-2" />
                검색
              </button>
            </div>
          </div>
        </div>
      </div>
      {!selectedTalkRoom.cnmMainKey && (
        <div className="p-5 text-secondary font-weight-bold text-center">
          채널을 선택해주세요
        </div>
      )}

      {selectedTalkRoom.cnmMainKey && (
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />

            <TableBody>
              {!isServerPaging &&
                !isServerSort &&
                util
                  .stableSort(data, util.getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row key={index} row={row} index={index} {...rowProps} />
                  ))}
              {!isServerPaging &&
                isServerSort &&
                util
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row key={index} row={row} index={index} {...rowProps} />
                  ))}

              {isServerPaging &&
                !isServerSort &&
                util
                  .stableSort(data, util.getComparator(order, orderBy))
                  .map((row, index) => (
                    <Row key={index} row={row} index={index} {...rowProps} />
                  ))}

              {isServerPaging &&
                isServerSort &&
                data.map((row, index) => (
                  <Row key={index} row={row} index={index} {...rowProps} />
                ))}

              {loading && (
                <TableRow style={{ height: (dense ? 33 : 51) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length + 1}>
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length === 0 && !loading && (
                <TableRow style={{ height: (dense ? 33 : 51) * rowsPerPage }}>
                  <TableCell colSpan={headCells.length + 1}>
                    <div className="d-flex justify-content-center">
                      <div>{`[${selectedTalkRoom.cnmName}] 채널에 등록가능한 업체가 존재하지 않습니다.`}</div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {data.length > 0 && emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 51) * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div className="mb-3 mt-3 px-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <button
                className="btn btn-primary font-weight-bold"
                disabled={isDisabled}
                onClick={handleUpdateTalkRoomCompList}
              >
                {selected.length}개 업체 저장
              </button>

              <Pagination
                style={{ justifyContent: "flex-end" }}
                className={classes.pagination}
                color="primary"
                count={totalPageCount}
                page={page + 1}
                allin={"right"}
                onChange={(event, value) => {
                  handleChangePage(event, value - 1);
                }}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </TableContainer>
      )}
    </div>
  );
}

EntireTalkRoomCompList.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeData: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function Row(props) {
  const { row, index, selected, selectComp } = props;
  const classes = useRowStyles();

  const isChecked =
    selected.findIndex(
      (item) => typeof item === "object" && item.COMP_CD === row.COMP_CD
    ) > -1;

  return (
    <TableRow className={[classes.root, "text-truncate"].join(" ")} key={index}>
      <TableCell>
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={(e) => {
            selectComp(e.target.checked, row);
          }}
        />
      </TableCell>
      {headCells.map((headCell, index) => {
        let style = {};
        if (headCell.hasOwnProperty("minWidth"))
          style.minWidth = headCell.minWidth;
        if (headCell.hasOwnProperty("maxWidth"))
          style.maxWidth = headCell.maxWidth;
        return (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
            style={style}
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                })
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={`${headCell.field}_${index}`}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
                style={{ fontWeight: "bold" }}
              >
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};
