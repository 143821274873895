import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ChatNewsRoom";
const sortModel = [{ field: "cntCreatDate", dir: "desc" }];
const now = new Date(); // 현재 날짜 및 시간
let standardDate = new Date(now.setDate(now.getDate() + 1)); // 내일
let page = 0;
let rowsPerPage = 10;
let totalCount = 100;
let totalPageCount = 1;
let beforeScroll = 0;

export default function ChatNewsRoom(props) {
  const {
    loginInfo,
    isMobile,
    isWindowActive,
    alertDisabledList,
    activeChatNewsRoom,
    closeNewsChatRoom,
    clearNewsUnreadCnt,
    updateAlertDisable,
  } = props;
  const [serverData, setServerData] = useState([]);

  useEffect(() => {
    setServerData([]);
    beforeScroll = 0;
    page = 0;
    rowsPerPage = 10;
    totalCount = 100;
    totalPageCount = 1;

    requestUserSelect({}, () => {
      setTimeout(() => {
        moveToBottom();
      }, 100);
    });
  }, [activeChatNewsRoom]);

  useEffect(() => {
    updateTalkNewsRead(serverData[serverData.length - 1]);
  }, [serverData, isWindowActive]);

  useEffect(() => {
    socket.addLocalEventListener("SendNews", _mClassName, receiveMSGCallback);
    socket.addLocalEventListener(
      "SendNewsForInvited",
      _mClassName,
      receiveMSGCallback
    );

    return () => {
      socket.removeLocalEventListener("SendNews", _mClassName);
      socket.removeLocalEventListener("SendNewsForInvited", _mClassName);
    };
  }, [serverData]);

  const receiveMSGCallback = (b, n) => {
    if (activeChatNewsRoom.cnmMainKey !== n.data.cntMainKey) return;
    if (n.data.hasOwnProperty("isSubscribe")) return;

    let dataList = [...serverData];

    if (n.data.cntDelYN === "N") {
      dataList.push({ ...activeChatNewsRoom, ...n.data });
    }
    if (n.data.cntDelYN === "Y") {
      const index = dataList.findIndex(
        (item) => item.cntMSGKey === n.data.cntMSGKey
      );
      if (index > -1) {
        dataList.splice(index, 1);
      }
    }
    setServerData(dataList);
  };

  const moveToBottom = () => {
    const _target = document.querySelector("#msgList");
    _target.scrollTo({
      top: _target.scrollHeight - beforeScroll,
      behavior: beforeScroll > 0 ? "instant" : "smooth",
    });
    beforeScroll = _target.scrollHeight;
    // _target.scrollTop = _target.scrollHeight - beforeScroll;
    // setBeforeScroll(_target.scrollHeight);
  };

  const requestUserSelect = (filters, callback) => {
    const msgID = util.makeUUIDv4();
    const data = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      sort: [...sortModel],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      cntMainKey: activeChatNewsRoom.cnmMainKey,
      cntDelYN: "N",
      ...filters,
    };

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          setServerData((prevState) => {
            return [...newData["returnData"].reverse(), ...prevState];
          });
          callback && callback();
        }
      }
    });
  };

  const updateTalkNewsRead = (data) => {
    if (!data || !isWindowActive) return;

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_updateTalkNewsRead.php",
      msgID: msgID,
      rnrMainKey: data.cnmMainKey,
      rnrMSGKey: data.cntMSGKey,
      rnrMSGIDX: data.cntIDX,
      rnrCreator: loginInfo.user_id,
      rnrCreatorSite: loginInfo.comp_cd,
      rnrUpdateDate: new Date(),
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          clearNewsUnreadCnt(data.cnmMainKey);
        }
      }
    });
  };

  //creative time separator
  const dateSeparator = (data) => {
    let date = new Date(data);
    let separator = "";

    let standardDateYear = standardDate.getFullYear();
    let standardDateMonth = standardDate.getMonth();
    let standardDateDay = standardDate.getDate();

    let crtYear = date.getFullYear();
    let crtMonth = date.getMonth();
    let crtDate = date.getDate();

    if (
      standardDateYear !== crtYear ||
      standardDateMonth !== crtMonth ||
      standardDateDay !== crtDate
    ) {
      standardDate = date;
      separator = (
        <div className="d-flex flex-row align-items-center py-2">
          <div className="border border-bottom-0 flex-grow-1" />
          <div className="text-secondary  px-2">
            {date.toLocaleDateString()}
          </div>
          <div className="border border-bottom-0 flex-grow-1" />
        </div>
      );
    }

    return separator;
  };

  const openNewsCorpModal = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_INFO_MODAL_CALL,
      _mClassName,
      { ...activeChatNewsRoom }
    );
  };

  const changeAlertDisabledStatus = () => {
    const type = alertDisabledList.hasOwnProperty(activeChatNewsRoom.cnmMainKey)
      ? "DELETE"
      : "INSERT";
    updateAlertDisable(type, {
      ...activeChatNewsRoom,
      crmMainKey: activeChatNewsRoom.cnmMainKey,
    });
  };

  const NewsItemRender = useCallback((props) => {
    return (
      <>
        {dateSeparator(props.item.cntCreatDate)}
        <NewsItem {...props} />
      </>
    );
  }, []);

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  return (
    <>
      <div className="bg-white h-100 mh-100 p-3 d-flex flex-column border-left">
        <div className="pb-3 d-flex flex-row justify-content-between">
          <div className="px-2 text-secondary">
            <p className="h6 font-weight-bold m-0 ellipsis_1">
              {activeChatNewsRoom.cnmName}
            </p>
            <p className="ellipsis_1">{activeChatNewsRoom.cnmDesc}</p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <button
              className="h6 font-weight-bold border-0 bg-transparent text-secondary"
              data-toggle="modal"
              data-target="#ChatNewsCompModal"
              onClick={() => openNewsCorpModal()}
            >
              <i className="fa-solid fa-circle-info" />
            </button>
            <button className="h6 font-weight-bold border-0 bg-transparent nav-item dropdown text-secondary pr-2 position-relative">
              <i
                className="fa-solid fa-bars nav-link dropdown-toggle text-secondary"
                id="chat-dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              />

              <div className="dropdown-menu text-secondary custom_dropdown border-0 shadow">
                <div
                  className="dropdown-item p-1 px-3 cursor_pointer d-flex flex-row justify-content-start align-items-center text-secondary"
                  onClick={() => {
                    changeAlertDisabledStatus();
                  }}
                >
                  {alertDisabledList.hasOwnProperty(
                    activeChatNewsRoom.cnmMainKey
                  ) ? (
                    <i
                      className="far fa-bell-slash mr-2"
                      style={{ minWidth: 20 }}
                    />
                  ) : (
                    <i className="fas fa-bell mr-2" style={{ minWidth: 20 }} />
                  )}
                  <p>
                    {alertDisabledList.hasOwnProperty(
                      activeChatNewsRoom.cnmMainKey
                    )
                      ? "켜기"
                      : "끄기"}
                  </p>
                </div>
              </div>
            </button>
            {isMobile && (
              <button
                className="h6 font-weight-bold border-0 bg-transparent ml-2"
                onClick={() => {
                  closeNewsChatRoom(activeChatNewsRoom);
                }}
              >
                <i className="fa-solid fa-xmark" />
              </button>
            )}
          </div>
        </div>
        <div className="flex-fill position-relative" style={{ height: 100 }}>
          <div
            id="msgList"
            className="h-100 bg-blue-light overflow_scroll-Y scroll_hidden rounded-xl p-2"
          >
            {data.length === 0 && (
              <div className="w-100 h-100 child-center">
                <p className="font-weight-bold text-secondary">
                  등록된 알림톡이 없습니다.
                </p>
              </div>
            )}

            {data.length > 0 && page < totalPageCount - 1 && (
              <div className="w-100 text-center my-2">
                <button
                  className="btn_1 rounded-pill"
                  onClick={() => {
                    page = ++page;
                    requestUserSelect({}, moveToBottom);
                  }}
                >
                  이전메세지
                </button>
              </div>
            )}
            {data.map((item, index) => {
              return (
                <NewsItemRender
                  key={item.cntMSGKey}
                  index={index}
                  item={item}
                  data={data}
                  activeChatNewsRoom={activeChatNewsRoom}
                />
              );
            })}
          </div>
          <button
            className="position-absolute rounded-circle border-0 child-center"
            onClick={() => {
              beforeScroll = 0;
              moveToBottom();
            }}
            style={{
              width: 40,
              height: 40,
              right: 15,
              bottom: 15,
              background: "rgba(0,0,0,.6)",
            }}
          >
            <span className="h6 mb-0 text-white">
              <i className="fa-solid fa-arrow-down" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

const NewsItem = React.memo((props) => {
  const { item, activeChatNewsRoom } = props;
  const { cntPic, cntMSG, cntTitle, cntFile, cntLink, cntCreatDate } = item;
  const profile =
    activeChatNewsRoom.COMP_LOGO !== ""
      ? activeChatNewsRoom.COMP_LOGO.split("?")[0]
      : "";

  const [picLink, picName] = cntPic.split("?");
  const files = cntFile !== "" ? cntFile.split("|") : [];
  const date = new Date(cntCreatDate);
  const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  const min =
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;

  return (
    <div className="d-flex flex-row justify-content-start align-items-start py-2">
      <div className="d-flex flex-row justify-content-start">
        <div
          className="rounded-circle mr-2"
          style={{
            minWidth: 45,
            minHeight: 45,
            width: 45,
            height: 45,
            background: `url(${profile}) #fff 50% 50% /contain no-repeat`,
          }}
        />
        <div className="pt-2">
          <p className="h6 font-weight-bold mb-2">
            {activeChatNewsRoom.cnmCompNM}
          </p>
          <div className="d-flex flex-row align-items-end">
            <div
              className="bg-white overflow-hidden shadow-sm"
              style={{ borderRadius: "0 10px 10px 10px", maxWidth: 320 }}
            >
              <div>
                <div className="px-3 py-2 bg-freiscop-50 text-white">
                  <p className="mb-0 font-weight-bolder">{cntTitle}</p>
                </div>
                <div className="px-3">
                  {picLink !== "" && (
                    <div
                      className="position-relative mt-3 cursor_pointer"
                      onClick={() => {
                        window.open(
                          picLink,
                          "chatImage",
                          "width=800, height=1000"
                        );
                      }}
                    >
                      <img
                        src={picLink}
                        alt={picName}
                        width="100%"
                        className="rounded"
                        style={{
                          minWidth: 288,
                          minHeight: 288,
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className="position-absolute d-inline-block bg-freiscop-50 text-white font-weight-bold rounded px-2 py-1 text-center"
                        style={{ bottom: 5, left: 5 }}
                      >
                        크게 보기
                      </div>
                    </div>
                  )}
                  <p className="my-2">{cntMSG}</p>
                </div>
              </div>

              {cntLink !== "" && (
                <div className="px-3 pb-2">
                  <p className="font-weight-bold">※ 참조링크</p>
                  <p>
                    <a href={cntLink} target="_blank" className="underline">
                      {cntLink}
                    </a>{" "}
                  </p>
                </div>
              )}

              {files.length > 0 && (
                <div className="px-3 pb-2">
                  <p className="mb-2 font-weight-bold">※ 첨부파일</p>
                  {files.map((file, index) => {
                    const [fileLink, fileName] = file.split("?");
                    return (
                      <p key={index} className="mb-1">
                        <span
                          className="cursor_pointer mr-2"
                          onClick={() => {
                            codeList.Modal.current.confirm(
                              `[${fileName}]을 다운로드하시겠습니까?`,
                              (ret) => {
                                if (ret) {
                                  util.downloadAs(fileLink, fileName);
                                }
                              }
                            );
                          }}
                        >
                          💾
                        </span>
                        <span
                          className="underline text-primary cursor_pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              fileLink,
                              "chatFile",
                              "width=800, height=1000"
                            );
                          }}
                        >
                          {fileName}{" "}
                        </span>
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
            <span className="pl-2">
              {hour}:{min}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
