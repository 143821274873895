import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";

const carousel_text = {
  nt: {
    title: "공지사항",
    desc: "프레이스콥 중요알림을 확인하세요",
  },
  ls: {
    title: "선사•항공사 공지사항",
    desc: "주요 선사와 콘솔사의 안내를 편리하게 확인하세요",
  },
  qa: {
    title: "Q&A 게시판",
    desc: "궁금한 점은 무엇이든 물어보세요",
  },
  pr: {
    title: "추천선사 및 프로모션",
    desc: "파트너•회원사들의 프로모션 정보를 확인하세요",
  },
};

export default function PostCarousel() {
  const param = useParams();
  return (
    <>
      <section
        className="parallax-window subheader_plain background-image"
        data-parallax="scroll"
        data-image-src="/images/gbts_carousel_1.jpg"
        data-natural-width="1400"
        data-natural-height="250"
        style={{
          background:
            "url(/images/gbts_carousel_2.jpg) 50% 50%/cover no-repeat",
          height: "250px",
          minHeight: "250px",
        }}
      >
        <div
          className="parallax-content-1 opacity-mask"
          data-opacity-mask="rgba(0, 0, 0, 0.6)"
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            height: "250px",
            minHeight: "250px",
          }}
        >
          <div className="animated fadeInDown">
            <h1>{carousel_text[param.post_type].title}</h1>
            <p>{carousel_text[param.post_type].desc}</p>
          </div>
        </div>
      </section>
    </>
  );
}
