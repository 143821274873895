import React, { useEffect, useState } from "react";
import CodeList from "../../lib/CodeList";

const codeList = new CodeList();

const solutions = [
  {
    key: "smartCooperation",
    nav_title: "스마트 협업",
    title: "화주 및 물류 파트너와 스마트한 협업의 시작",
    sub_title: "Smart Cooperation",
    desc: "기업화주의 물류를 로지웍에서 통합관리하면 업무채널을 일원화할 수 있습니다. 물류 공급자(선사, 포워더)와 수요자(기업화주)가 같은 화면(One-Screen)에서 동일한 물류업무를 진행하기 때문에 귀사는 운송 서비스 뿐만 아니라 고객 맞춤형 추가서비스를 화주에게 선제적으로 제공하여 화주 만족도를 극대화 할 수 있습니다.\n",
    img: "smartCooperation.png",
    img_alt: "스마트협업",
    img_order: 1,
  },
  {
    key: "workEfficiency",
    nav_title: "업무효율",
    title: "실시간 업무공유로 불필요한 문의 제거",
    sub_title: "Upgrading Work Efficiency",
    desc: "로지웍에서 보관하는 모든 문서는 클라우드에 저장되어 실시간 공유됩니다. 필요할 때마다 클라우드에서 문서를 다운로드 받고 업로드하여 동일한 업무를 이메일 등으로 수차례 요청하는 기존 업무방식에서 벗어날 수 있습니다. 또한, 문서가 클라우드에 평생 저장되기 때문에 문서가 분실되어도 걱정이 없고 담당자의 업무공백에도 효율적으로 대처할 수 있습니다. ",
    img: "workEfficiency.png",
    img_alt: "업무효율",
    img_order: 0,
  },
  {
    key: "mobileSupport",
    nav_title: "모바일 지원",
    title: "스마트폰에서도 동일한 로지웍 서비스 제공",
    sub_title: "Smartphone Syncronization",
    desc: "로지웍이 제공하는 서비스는 지비티에스 기업 전용 메신저 ‘로지톡’을 이용해 스마트폰에서도 동일하게 제공 받을 수 있습니다. 안드로이드와 앱스토어에서 ‘로지톡’을 검색해 보세요.\n",
    img: "mobileSupport.png",
    img_alt: "모바일 지원",
    img_order: 1,
  },
];

export default function LogiWorkLandingSolution() {
  const [currentHash, setCurrentHash] = useState("smartCooperation");

  return (
    <div className="p-3 p-md-5 margin_30">
      <div className="container-xl">
        <p className="BM-DH h2 mb-4 font-weight-bold">
          로지웍은
          <br />
          디지털 물류를 완성하는 스마트 솔루션입니다.
        </p>
        <div className="carousel-indicators p-0 m-0 position-static justify-content-center justify-content-md-start h5 mb-5 font-weight-900 ">
          {solutions.map((item, index) => {
            return (
              <p
                key={item.key}
                data-target="#solutionCarousel"
                data-slide-to={index}
                className={`${
                  currentHash === item.key
                    ? "text-freiscop border-bottom border-2 border-freiscop"
                    : ""
                }  cursor_pointer mr-3 text-center`}
                onClick={() => setCurrentHash(item.key)}
              >
                {item.nav_title}
              </p>
            );
          })}
        </div>

        <div
          id="solutionCarousel"
          className="carousel slide flex-fill"
          data-touch="false"
          data-interval="false"
        >
          <div className="carousel-inner">
            {solutions.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`carousel-item ${
                    currentHash === item.key ? "active" : ""
                  }`}
                >
                  <div className="row m-0 p-0 pb-4 pt-md-4">
                    <div
                      className={`col-xl-4 m-0 p-0 d-flex flex-row justify-content-center align-items-center order-xl-${item.img_order}`}
                    >
                      <img
                        className="d-none d-xl-block"
                        style={{ height: 400 }}
                        src={`/img/use_img/${item.img}`}
                        alt={item.img_alt}
                      />
                      <img
                        className="d-none d-md-block mb-3 d-xl-none"
                        style={{ height: 250 }}
                        src={`/img/use_img/${item.img}`}
                        alt={item.img_alt}
                      />
                      <img
                        className="d-block d-md-none mb-3"
                        style={{ height: 120 }}
                        src={`/img/use_img/${item.img}`}
                        alt={item.img_alt}
                      />
                    </div>
                    <div
                      className={`col-xl-8 m-0 p-0 ${
                        item.img_order === 1 ? "pr-xl-3" : "pl-xl-3"
                      } d-flex flex-column justify-content-between`}
                    >
                      <div>
                        <p className="h3 font-weight-900 text-freiscop">
                          {item.title}
                        </p>
                        <p className="h4 text-secondary">{item.sub_title}</p>
                      </div>
                      <p
                        className="h5 pr-xl-5"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "keep-all",
                          lineHeight: "1.8em",
                        }}
                      >
                        {item.desc}
                      </p>
                      <div className="text-center text-lg-left mt-2">
                        <button
                          className="btn btn-info bg-freiscop border-freiscop"
                          onClick={() => {
                            window.open(
                              "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/notice/20230808110326_230803_%EB%A1%9C%EC%A7%80%EC%9B%8D_%EC%A0%9C%EC%95%88%EC%84%9C.pdf",
                              "_blank",
                              "width=1200, height=800"
                            );
                          }}
                        >
                          로지웍 소개자료
                          <i className="fa-solid fa-download ml-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <button
            className="carousel-control-prev text-secondary bg-transparent d-inline-block border-0 ml-2 ml-md-0"
            type="button"
            style={{
              width: "unset",
              height: "unset",
              left: "-40px",
            }}
            onClick={() => {
              let findInd = solutions.findIndex(
                (item) => item.key === currentHash
              );

              if (findInd > 0) {
                setCurrentHash(solutions[findInd - 1].key);
              } else {
                setCurrentHash(solutions[solutions.length - 1].key);
              }
            }}
          >
            <span className="h1 mb-0">
              <i className="fa-solid fa-chevron-left" />
            </span>
          </button>
          <button
            className="carousel-control-next text-secondary bg-transparent border-0 mr-2 mr-md-0"
            type="button"
            style={{
              width: "unset",
              height: "unset",
              right: "-40px",
            }}
            onClick={() => {
              let findInd = solutions.findIndex(
                (item) => item.key === currentHash
              );
              if (findInd < solutions.length - 1) {
                setCurrentHash(solutions[findInd + 1].key);
              } else {
                setCurrentHash(solutions[0].key);
              }
            }}
          >
            <span className="h1 mb-0">
              <i className="fa-solid fa-chevron-right" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
