/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import ReviewEdit from "./ReviewEdit";

const socket = new BuddibleSocket();
const util = new Utilities();

export default function Review() {
  const [productData, setProductData] = useState({});
  const [reviewAVG, setReviewAVG] = useState(0);
  const [reviews, setReviews] = useState([]);
  const editModal = useRef();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "Review",
      (beforeData, newData) => {
        if (newData) {
          setProductData({ ...newData });
        }
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
        "Review"
      );
    };
  }, []);

  useEffect(() => {
    requestReviews();
  }, [productData]);

  //상품 리뷰 불러오기
  const requestReviews = () => {
    if (productData.pmCreatorSite === "") return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/review/JS_review_select.php",
      msgID: msgID,
      data: {},
      rvCompCD: productData.pmCreatorSite, //상품등록자 업체코드
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setReviews([...newData["returnData"]]);
          if (newData["returnData"].length > 0) {
            setReviewAVG(newData["returnData"][0]["rating_avg"]);
          }
        }
      }
    });
  };

  //리뷰 등록하기
  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_PRODUCT_REVIEW_MODAL_CALL,
      "Review",
      {},
      () => {}
    );
  };

  return (
    <>
      <div className="container py-4 mb-5">
        <div className="row">
          <div className="col-12">
            <h3>후기</h3>
          </div>

          <div className="col-12">
            <div className="d-flex  flex-ros justify-content-between">
              <div id="general_rating" className="m-0">
                <div className="rating">
                  <span className="text-warning">
                    {reviewAVG > 0 ? (
                      <i className="icon-star" />
                    ) : (
                      <i className="icon-star-empty" />
                    )}
                    {reviewAVG > 2 ? (
                      <i className="icon-star" />
                    ) : (
                      <i className="icon-star-empty" />
                    )}
                    {reviewAVG > 4 ? (
                      <i className="icon-star" />
                    ) : (
                      <i className="icon-star-empty" />
                    )}
                    {reviewAVG > 6 ? (
                      <i className="icon-star" />
                    ) : (
                      <i className="icon-star-empty" />
                    )}
                    {reviewAVG > 8 ? (
                      <i className="icon-star" />
                    ) : (
                      <i className="icon-star-empty" />
                    )}
                  </span>
                </div>
                <span>
                  {reviews.length > 0 &&
                    `${parseFloat(reviewAVG).toFixed(1)}/10 (${
                      reviews.length
                    }건)`}
                </span>
              </div>
              <button
                className="btn_1"
                data-toggle="modal"
                data-target="#staticReviewModal"
                onClick={addNewData}
                aria-label={"후기등록하기"}
                type="button"
              >
                후기등록하기
              </button>
            </div>

            <hr />
            {reviews.map((rev, index) => {
              let ratingCount = Math.floor(Number(rev.rvRATING) / 2);
              let returnRating = [];
              for (let i = 0; i < 5; i++) {
                if (i < ratingCount) {
                  returnRating.push(
                    <i key={`rating_${i}`} className="icon-star text-warning" />
                  );
                } else {
                  returnRating.push(
                    <i
                      key={`rating_empty_${i}`}
                      className="icon-star-empty text-warning"
                    />
                  );
                }
              }
              return (
                <div className="review_strip_single" key={`review_${index}`}>
                  <small> - {rev.rvCreateDate} -</small>
                  <h4 className="ml-0">{rev.rvUSERName}</h4>
                  <p>{rev.rvCONTENTS}</p>
                  <div className="rating">
                    {returnRating}

                    <div
                      className="d-inline-block text-center"
                      style={{ minWidth: 40 }}
                    >
                      {rev.rvRATING}점
                    </div>
                  </div>
                </div>
              );
            })}
            {reviews.length === 0 && (
              <p>등록된 후기가 없습니다. 첫 이용자가 되어 후기를 남겨보세요.</p>
            )}
          </div>
        </div>
      </div>
      <ReviewEdit ref={editModal} requestReviews={requestReviews} />
    </>
  );
}
