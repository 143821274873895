/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const NOTICE_TYPE = [
  { key: "NTA01", desc: "새소식" },
  { key: "NTA02", desc: "오픈예정" },
  { key: "NTA03", desc: "시스템공지" },
  { key: "NTA04", desc: "프로모션" },
  { key: "NTA05", desc: "입점업체현황" },
];
const _mClassName = "NoticeManageEdit";

function NoticeManageEdit(props, ref) {
  const { data = {}, requestUserSelect } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");

  const $noticeRef = {
    ntIDX: useRef(), // 관리번호
    ntTITLE: useRef(), //공지사항 타이틀
    ntCONTENTS: useRef(), //공지사항 내용
    ntFiles: useRef(), //공지사항 첨부파일
    ntUSEYN: useRef(), // 공지사항 사용여부
    ntStype: useRef(), //공지사항 분류
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  //공지사항 입력 폼 기본값 세팅(신규등록, 수정)
  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $noticeRef.ntIDX.current.value = userData.currentData.ntIDX;
      $noticeRef.ntIDX.current.disabled = true;
      $noticeRef.ntStype.current.value = userData.currentData.ntStype;
      $noticeRef.ntTITLE.current.value = userData.currentData.ntTITLE;
      $noticeRef.ntCONTENTS.current.value = userData.currentData.ntCONTENTS;
      $noticeRef.ntFiles.current.value = userData.currentData.ntFiles;
      $noticeRef.ntUSEYN.current.value = userData.currentData.ntUSEYN;
      setUploadFiles(userData.currentData.ntFiles);
    } else {
      $noticeRef.ntIDX.current.value = "";
      $noticeRef.ntIDX.current.disabled = true;
      $noticeRef.ntTITLE.current.value = "";
      $noticeRef.ntStype.current.value = "";
      $noticeRef.ntCONTENTS.current.value = "";
      $noticeRef.ntFiles.current.value = "";
      $noticeRef.ntUSEYN.current.value = "";
      setUploadFiles("");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_NOTICE_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_NOTICE_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
    removeNotice(e, params) {
      DeleteNotice(e, params);
    },
  }));

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];
      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      $noticeRef.ntFiles.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      $noticeRef.ntFiles.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  const DeleteNotice = (e, params) => {
    let updateData = {
      ntIDX: params.currentData.ntIDX,
      ntDelYN: "Y",
    };
    codeList.Modal.current.confirm(
      "해당 공지사항을 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          requestUserUpdate(updateData, EDIT_TYPE.UPDATE);
        }
      }
    );
  };

  //공지사항 저장
  const actionSave = (e) => {
    // console.log(codeList.Modal.current);
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        ntIDX: $noticeRef.ntIDX.current.value,
        ntStype: $noticeRef.ntStype.current.value,
        ntTITLE: $noticeRef.ntTITLE.current.value,
        ntCONTENTS: $noticeRef.ntCONTENTS.current.value,
        ntFiles: $noticeRef.ntFiles.current.value,
        ntUSEYN: $noticeRef.ntUSEYN.current.value,
        ntDelYN: "N",
      };

      requestUserUpdate(
        updateData,
        $noticeRef.ntIDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($noticeRef.ntStype.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if ($noticeRef.ntTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if ($noticeRef.ntCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if ($noticeRef.ntUSEYN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/notice/JS_notice_update.php"
          : "/notice/JS_notice_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_NOTICE_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_NOTICE_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "공지사항을 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "공지사항을 추가 하였습니다.",
                () => {}
              );

          $("#NoticeEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "공지사항 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "공지사항 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };
  const Cancel = () => {
    $noticeRef.ntIDX.current.value = "";
    $noticeRef.ntIDX.current.disabled = true;
    $noticeRef.ntTITLE.current.value = "";
    $noticeRef.ntStype.current.value = "";
    $noticeRef.ntCONTENTS.current.value = "";
    $noticeRef.ntFiles.current.value = "";
    $noticeRef.ntUSEYN.current.value = "";
    setUploadFiles("");
    $("#NoticeEditModal").click();
  };
  return (
    <>
      <div
        className="modal fade"
        id="NoticeEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="NoticeEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>공지사항 등록</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="NoticeEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={$noticeRef.ntIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$noticeRef.ntStype}
                  >
                    <option value="">미선택</option>
                    {NOTICE_TYPE.map((menu, index) => {
                      return (
                        <option key={index} value={menu.key}>
                          {menu.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="3"
                    ref={$noticeRef.ntTITLE}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="15"
                    ref={$noticeRef.ntCONTENTS}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  파일첨부
                </label>

                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"mall/notice"}
                    callback={UploadFiles}
                  />
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={$noticeRef.ntFiles}
                  />
                  {uploadFiles !== "" &&
                    uploadFiles.split("|").map((v, i) => {
                      let splitData = v.split("?");
                      if (splitData.length === 1) return null;
                      let fileName = splitData[0];
                      let fileLink = splitData[1];
                      return (
                        <div className="my-2" key={v}>
                          {/* <button
                                onClick={() => {\
                                  downloadAs(fileLink, fileName);
                                }}
                              >
                                {fileName}
                              </button>*/}
                          <button
                            className="btn btn-danger mr-2"
                            onClick={() => {
                              deleteFile(splitData[0], fileName);
                            }}
                          >
                            삭제
                          </button>
                          {fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                          {!fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$noticeRef.ntUSEYN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `공지사항 등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        Cancel();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(NoticeManageEdit);
