/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import MMSI from "../../main/mall/schedule/mmsiList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

const useStyles = makeStyles((theme) => ({
  ...codeList.publicVar.tableDefaultStyle(theme),
}));
const useRowStyles = makeStyles({
  ...codeList.publicVar.tableRowDefaultStyle(),
});
const headCells = [
  {
    field: "sm_UID",
    headerName: "스케줄 No.",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sbf_IDX",
    headerName: "예약 No.",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sm_PROVIDER_NAME",
    headerName: "선사",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "sbf_SHIPPER",
    headerName: "SHIPPER",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "sbf_COMP_NM",
    headerName: "실화주",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "sbf_USER_NAME",
    headerName: "부킹담당자",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sbf_BOOK_TEL}
          </p>
        </div>
      );
    },
  },
  {
    field: "sm_DEPARTURE_DATE",
    headerName: "출발항(공항) 및 일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      const polDay = util.handleGetDate(params.data);
      return (
        <div>
          <p className="text-truncate text-wrap text-break font-weight-bold">
            {params.currentData.sm_POL_NAME}
          </p>
          <span className="text-danger font-weight-bold">
            {util.getDateToFormat(params.data, "YYYY-MM-DD")} ({polDay})
          </span>
        </div>
      );
    },
  },
  {
    field: "sm_ARRIVE_DATE",
    headerName: "도착항(공항) 및 일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      const podDay = util.handleGetDate(params.data);
      return (
        <div>
          <p className="text-truncate text-wrap text-break font-weight-bold">
            {params.currentData.sm_POD_NAME}
          </p>
          <span className="text-danger font-weight-bold">
            {util.getDateToFormat(params.data, "YYYY-MM-DD")} ({podDay})
          </span>
        </div>
      );
    },
  },
  {
    field: "sm_DOC_CLOSE",
    headerName: "서류마감",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          {util.getDateToFormat(params.data, "YYYY-MM-DD")}
          <br />
          {params.currentData.sb_CARGO_CLOSE_TIME
            ? params.currentData.sb_CARGO_CLOSE_TIME
            : ""}
        </span>
      );
    },
  },
  {
    field: "sm_CARGO_CLOSE",
    headerName: "화물마감",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <p className="text-truncate text-wrap text-break">{params.data}</p>
          <p className="text-truncate text-wrap text-break">
            {params.currentData.sb_DEPARTURE_DATE}
          </p>
        </div>
      );
    },
  },

  {
    field: "sbf_STATUS",
    headerName: "처리상태",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let text_color = "text-secondary";
      let desc = "예약요청";
      if (params.data === "N") {
        text_color = "text-secondary";
        desc = "예약요청";
      }
      if (params.data === "Y") {
        text_color = "text-info";
        desc = "예약확정";
      }
      if (params.data === "SR") {
        text_color = "text-warning";
        desc = "SR접수완료";
      }
      if (params.data === "B") {
        text_color = "text-primary";
        desc = "B/L발행";
      }
      if (params.data === "C") {
        text_color = "text-danger";
        desc = "예약취소";
      }
      if (params.data === "SR") {
        text_color = "text-freiscop";
        desc = "SR수령";
      }

      return (
        <span
          className={`text-truncate text-wrap text-break font-weight-bold ${text_color}`}
        >
          {desc}
        </span>
      );
    },
  },
];
const _mClassName = "ScheduleFCLBookingManageTable";
const times = [
  "00:00",
  "00:10",
  "00:20",
  "00:30",
  "00:40",
  "00:50",
  "01:00",
  "01:10",
  "01:20",
  "01:30",
  "01:40",
  "01:50",
  "02:00",
  "02:10",
  "02:20",
  "02:30",
  "02:40",
  "02:50",
  "03:00",
  "03:10",
  "03:20",
  "03:30",
  "03:40",
  "03:50",
  "04:00",
  "04:10",
  "04:20",
  "04:30",
  "04:40",
  "04:50",
  "05:00",
  "05:10",
  "05:20",
  "05:30",
  "05:40",
  "05:50",
  "06:00",
  "06:10",
  "06:20",
  "06:30",
  "06:40",
  "06:50",
  "07:00",
  "07:10",
  "07:20",
  "07:30",
  "07:40",
  "07:50",
  "08:00",
  "08:10",
  "08:20",
  "08:30",
  "08:40",
  "08:50",
  "09:00",
  "09:10",
  "09:20",
  "09:30",
  "09:40",
  "09:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
  "22:00",
  "22:10",
  "22:20",
  "22:30",
  "22:40",
  "22:50",
  "23:00",
  "23:10",
  "23:20",
  "23:30",
  "23:40",
  "23:50",
];
const sfc_TYPE = {
  "": "",
  DC: "Dry",
  RF: "Reefer",
  TC: "Tank",
  OT: "Open Top",
  FR: "Flat Rack",
};
const sfc_SIZE = {
  "": "",
  20: "20`",
  40: "40`",
  "4H": "40`HQ",
  45: "45`",
};

export default function ScheduleFCLBookingManageTable(props) {
  const {
    isServerPaging = true,
    isServerSort = true,
    loginInfo,
    pickPlace,
    compListObj,
  } = props;

  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("prIDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "sbf_CREATE_DATE",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestReserveFCLList();
  }, [page, sortModel, filters]);

  //FCL 예약정보 받아오기
  const requestReserveFCLList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_reserve_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sbf_CREATE_DATE",
          dir: "desc",
        },
      ],
      isManager: loginInfo.isAdmin,
      USER_ID: loginInfo.user_id,
      COMP_CD: loginInfo.comp_cd,
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            const comp_cd = newData["returnData"][i].sm_PROVIDER_CODE;
            newData["returnData"][i].sm_PROVIDER_NAME =
              compListObj.hasOwnProperty(comp_cd)
                ? compListObj[comp_cd].COMP_NM
                : "-";
          }

          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  //스케쥴 예약정보 업데이트
  function updateReserveFCLData(e, params, msg) {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_reserve_update.php",
      msgID: msgID,
      data: {},
      ...params,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          let renderMSG = msg ? msg : "스케줄의 정보가 변경되었습니다.";
          codeList.Modal.current.alert(renderMSG);
          requestReserveFCLList();
        } else {
          codeList.Modal.current.alert("요청 처리중 오류가 발생하였습니다.");
        }
        setLoading(false);
      }
    });
  }

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  //테이블 페이지 변경
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //테이블 정렬 방식 변경
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowParams = (row, index) => {
    return {
      row: row,
      index: index,
      loginInfo: loginInfo,
      pickPlace: pickPlace,
      compListObj: compListObj,
      updateReserveFCLData: updateReserveFCLData,
      requestReserveFCLList: requestReserveFCLList,
    };
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row key={index} {...rowParams(row, index)} />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row key={index} {...rowParams(row, index)} />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

ScheduleFCLBookingManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const {
    row,
    requestReserveFCLList,
    updateReserveFCLData,
    loginInfo,
    compListObj,
    pickPlace,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate cursor_pointer"].join(" ")}
        hover
        onClick={() => setOpen(!open)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  compListObj: compListObj,
                  updateReserveFCLData: updateReserveFCLData,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  compListObj: compListObj,
                  updateReserveFCLData: updateReserveFCLData,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={headCells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <ScheduleFCLBookingDetail
                    rowData={row}
                    loginInfo={loginInfo}
                    pickPlace={pickPlace}
                    compListObj={compListObj}
                    requestReserveFCLList={requestReserveFCLList}
                    updateReserveFCLData={updateReserveFCLData}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({}).isRequired,
};

const ScheduleFCLBookingDetail = (props) => {
  const {
    rowData,
    requestReserveFCLList,
    updateReserveFCLData,
    loginInfo,
    pickPlace,
    compListObj,
  } = props;

  const $controller = {
    sbf_SHIPPER: useRef(), //SHIPPER
    sbf_COMP_NM: useRef(), //실화주

    sbf_USER_NAME: useRef(), //부킹담당자 이름
    sbf_BOOK_TEL: useRef(), //부킹담당자 전화번호
    sbf_BOOK_MOBILE: useRef(), //부킹담당자 휴대전화
    sbf_USER_ID: useRef(), //부킹담당자 이메일
    sbf_BOOK_FAX: useRef(), //부킹담당자 팩스

    sbf_DOC_USER_NAME: useRef(), //서류담당자 이름
    sbf_DOC_USER_TEL: useRef(), //서류담당자 전화번호
    sbf_DOC_USER_MOBILE: useRef(), //서류담당자 휴대전화
    sbf_DOC_USER_ID: useRef(), //서류담당자 이메일
    sbf_DOC_USER_FAX: useRef(), //서류담당자 팩스

    sbf_WEIGHT_CNT: useRef(), //화물 무게
    sbf_WEIGHT_TYPE: useRef(), //화물 무게 구분

    sbf_TRAN_TYPE: useRef(), // 운송조건 구분
    sbf_TRAN_FREIGHT: useRef(), //운송조건 Freight
    sbf_TRAN_FREIGHT_SCNO: useRef(), //운송조건 SC No.
    sbf_TRAN_TERM: useRef(), //운송조건 SC No.

    sbf_PICK_DATE: useRef(), //픽업날자
    sbf_PICK_TIME: useRef(), //픽업 시간
    sbf_PICK_PLACE: useRef(), //픽업위치
    sbf_PICK_USER_NM: useRef(), //픽업 담당자 이름
    sbf_PICK_USER_TEL: useRef(), //픽업 담당자 연락처
    sbf_PICK_MEMO: useRef(), // 픽업 요청 메모

    sbf_FACT_DATE: useRef(), //공장 작업일자
    sbf_FACT_TIME: useRef(), //공장 작업 시간
    sbf_FACT_PLACE: useRef(), //공장 작업장소
    sbf_FACT_USER_NM: useRef(), //공장 담당자 이름
    sbf_FACT_USER_TEL: useRef(), //공장 담당자 연락처
    sbf_FACT_MEMO: useRef(), //공장 요청 메모

    sbf_CFS_DATE: useRef(), //입고 날짜
    sbf_CFS_TIME: useRef(), //입고 시간
    sbf_CFS_PLACE: useRef(), // 입고 CFS
    sbf_CFS_MEMO: useRef(), //입고 요청사항

    sbf_ETC_PRODUCT: useRef(), //영문명
    sbf_ETC_RTN_YN: useRef(), //반송화물 보세운송조건
    sbf_ETC_MEMO: useRef(), //요청사항
    sbf_STATUS: useRef(), //예약상태
    sbf_SHORT_LINK: useRef(), //예약정보 링크
    sbf_BLNO: useRef(), //BL등록
  };
  const [containerList, setContainerList] = useState([]);
  const [containerOGList, setContainerOGList] = useState([]);
  const [reeferCount, setReeferCount] = useState(0);
  const [ougCount, setOugCount] = useState(0);
  const [containerFiles, setContainerFiles] = useState([]);
  const [etcFiles, setEtcFiles] = useState([]);
  const [transitType, setTransitType] = useState("LINE");

  useEffect(() => {
    if (rowData.sbf_FILES !== "") {
      let dataList = rowData.sbf_FILES.split("|");
      setContainerFiles(dataList);
    }

    if (rowData.sbf_ETC_FILES !== "") {
      let dataList = rowData.sbf_ETC_FILES.split("|");
      setEtcFiles(dataList);
    }
    if (rowData.sbf_TRAN_TYPE !== "") {
      setTransitType(rowData.sbf_TRAN_TYPE);
    }

    SELECT_CONTAINER_DETAIL();
  }, []);

  //등록 컨테이너 정보 받아오기
  const SELECT_CONTAINER_DETAIL = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_reserve_detail_select.php",
      msgID: msgID,
      data: {},
      sbf_BOOK_UID: rowData.sbf_BOOK_UID,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let mainContainers = [];
          let ogContainers = [];
          let reefer_count = 0;
          let oug_count = 0;
          if (newData["returnData"].length) {
            mainContainers = newData["returnData"].filter(
              (item) => item.sfc_MAIN_TYPE !== "O"
            );
            ogContainers = newData["returnData"].filter(
              (item) => item.sfc_MAIN_TYPE === "O"
            );

            reefer_count = newData["returnData"].filter(
              (item) => item.sfc_TYPE === "RF"
            ).length;
            oug_count = newData["returnData"].filter(
              (item) => item.sfc_TYPE === "OT" || item.sfc_TYPE === "FR"
            ).length;
          }
          setReeferCount(reefer_count);
          setOugCount(oug_count);
          setContainerList(mainContainers);
          setContainerOGList(ogContainers);
        }
      }
    });
  };

  //컨테이너 추가
  const AddContainerInfo = (type = "M") => {
    let data = [
      ...containerList,
      {
        id: util.makeUUIDv4(),
        sfc_BOOK_UID: rowData.sbf_BOOK_UID,
        sfc_MAIN_TYPE: type, //컨테이너 타입
        sfc_SIZE: "", //컨테이너 사이즈
        sfc_TYPE: "", //컨테이너 추가정보 타입
        sfc_CNT: "", // 수량
        sfc_MAKER_YN: "", // 화주컨테이너 YN
        sfc_DANGER_YN: "", //위험화물 YN
        sfc_EMPTY_YN: "", //empty YN
        sfc_FLEXI_YN: "", // Flexi-Bag YN
        sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
        sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
        sfc_LENGTH: "", //OUG 사이즈
        sfc_WIDTH: "", //OUG 사이즈
        sfc_HEIGHT: "", //OUG 사이즈
        sfc_VOID: "", //OUG 사이즈
        sfc_WEIGHT: "", //OUG 사이즈
        sfc_MEMO: "", // 컨테이너 메모
        sfc_DEL_YN: "N", // 컨테이너 삭제여부
      },
    ];
    setContainerList(data);
  };

  //컨테이너 데이터 변경
  const ContainerDetailDataChange = (index, key, value) => {
    // ※DRY/ REEFER/ TANK/ OPT, FRK 동시에 선택 불가

    // DRY, REEFER, TANK, OPT, FRK 는 동일 사이즈 추가 불가 QTY로만 조정
    // REEFER는 20, 40HQ 사이즈만 존재함

    // OPT, FRK는 컨테이너 수량별로 detail정보가 필요
    // OPT, FRK는 size 변경시 상세 정보 자동변경 필요

    // Dry, Reefer, Tank, OpenTop, FlatRack

    //container_detail_list[index][key] = value;
    /*
    DC - Dry
    RF - Reefer
    TC - Tank
    OT - Open Top
    FR - Flat Rack
    */

    let _containerList = [...containerList];

    _containerList[index][key] = value;
    if (_containerList.length > 1) {
      // 행이 1개이상일 경우 비교
      // ※DRY/ REEFER/ TANK/ OPT, FRK 동시에 선택 불가 검사
      if (key === "sfc_TYPE") {
        //타입 변경시
        let beforeType = "";

        for (let i = 0; i < _containerList.length; i++) {
          if (i !== index) {
            //이전 선택 컨테이너타입 변수 저장
            if (beforeType === "") {
              beforeType = _containerList[i]["sfc_TYPE"];
              break;
            }
          }
        }

        if (beforeType !== "") {
          //이전에 선택된 컨테이너 타입이 있을경우
          let currentType = _containerList[index]["sfc_TYPE"];
          if (beforeType !== currentType) {
            //이전 컨테이너와 타입이 다른 경우
            if (
              !(
                (beforeType === "OT" || beforeType === "FR") &&
                (currentType === "OT" || currentType === "FR")
              )
            ) {
              // 이전 컨테이너와 타입이 다르면서 OT와 FR혼용이 아닌경우 선택 취소
              codeList.Modal.current.alert(
                `${sfc_TYPE[currentType]} 컨테이너와 다른 컨테이너는 동시 선택이 불가능합니다.`
              );
              _containerList[index][key] = "";
              setContainerList([..._containerList]);
              return;
            }
          }
        }

        //컨테이너 타입 유효성 모두 검수 끝난경우
        //_container_detail_list[index][key] = value;
      }
    }

    //사이즈 유효성 검사
    // REEFER는 20, 40HQ 사이즈만 존재함
    // FR, OP는 20, 40 사이즈만 존재함
    let currentType = _containerList[index]["sfc_TYPE"];
    let currentSize = _containerList[index]["sfc_SIZE"];
    if (currentSize !== "") {
      if (currentType === "RF" && currentSize === "40") {
        codeList.Modal.current.alert(
          `${sfc_TYPE[currentType]} 컨테이너는 40사이즈가 없습니다. 40HQ로 자동 변경합니다.`
        );
        _containerList[index]["sfc_SIZE"] = "4H";
        //setContainer_detail_list([..._container_detail_list]);
      }
      if (
        (currentType === "OT" || currentType === "FR") &&
        currentSize === "4H"
      ) {
        codeList.Modal.current.alert(
          `${sfc_TYPE[currentType]} 컨테이너는 40HQ사이즈가 없습니다. 40으로 자동 변경합니다.`
        );
        _containerList[index]["sfc_SIZE"] = "40";
        //setContainer_detail_list([..._container_detail_list]);
      }
    }

    //사이즈 변경시
    // DRY, REEFER, TANK, OPT, FRK 는 동일 사이즈 추가 불가 QTY로만 조정
    if (_containerList.length > 1) {
      let sizeList = {};
      for (let i = 0; i < _containerList.length; i++) {
        if (i !== index) {
          //이전 선택 컨테이너사이즈 변수 저장
          // OT와 FR은 중복 사이즈가 가능하기때문에 TYPE도 키값에 추가
          sizeList[
            _containerList[i]["sfc_TYPE"] + _containerList[i]["sfc_SIZE"]
          ] = true;
        }
      }
      if (
        sizeList.hasOwnProperty(
          _containerList[index]["sfc_TYPE"] + _containerList[index]["sfc_SIZE"]
        )
      ) {
        //중복 타입의 중복 사이즈가 있는경우 선택 취소
        codeList.Modal.current.alert(
          `컨테이너 사이즈가 중복 되었습니다. 컨테이너 정보를 확인 바랍니다.`
        );
        _containerList[index]["sfc_SIZE"] = "";
        setContainerList([..._containerList]);
        return;
      }
    }
    remakeOGData();
    setContainerList(_containerList);

    /*
    for (let i = 0; i < _container_detail_list.length; i++) {
      if (i !== index) {
        //이전 선택 컨테이너사이즈 변수 저장
        sizeList[_container_detail_list[i]["sfc_SIZE"]] = true;
      }
    }*/

    let reefer_count =
      _containerList.filter(
        (item) => item.sfc_TYPE === "RF" && item.sfc_DEL_YN === "N"
      ).length > 0;
    // if (reefer_count) {
    //   AddContainerInfo("R");
    // }
    setReeferCount(reefer_count);
    let oug_count =
      _containerList.filter(
        (item) =>
          (item.sfc_TYPE === "OT" || item.sfc_TYPE === "FR") &&
          item.sfc_DEL_YN === "N"
      ).length > 0;
    setOugCount(oug_count);
    // if (oug_count) {
    //   AddContainerInfo("O");
    // }
    //setIsNeedUpdate(!isNeedUpdate);
  };

  //OG 컨테이너 행 개수 컨트롤
  const remakeOGData = () => {
    //container_detail_list_OG
    let _containerList = [...containerList];
    let _containerOGList = [...containerOGList];
    let totalOG = 0;
    for (let i = 0; i < _containerList.length; i++) {
      if (
        _containerList[i]["sfc_TYPE"] === "OT" ||
        _containerList[i]["sfc_TYPE"] === "FR"
      ) {
        totalOG += parseInt(_containerList[i]["sfc_CNT"], 0);
      }
    }

    if (_containerOGList.length > totalOG) {
      _containerOGList.splice(totalOG - 1);
    }
    if (_containerOGList.length < totalOG) {
      let addCount = totalOG - _containerOGList.length;
      for (let i = 0; i < addCount; i++) {
        _containerOGList.push({
          id: util.makeUUIDv4(),
          sfc_BOOK_UID: rowData.sbf_BOOK_UID,
          sfc_MAIN_TYPE: "O", //컨테이너 타입
          sfc_SIZE: "", //컨테이너 사이즈
          sfc_TYPE: "", //컨테이너 추가정보 타입
          sfc_CNT: "", // 수량
          sfc_MAKER_YN: "", // 화주컨테이너 YN
          sfc_DANGER_YN: "", //위험화물 YN
          sfc_EMPTY_YN: "", //empty YN
          sfc_FLEXI_YN: "", // Flexi-Bag YN
          sfc_TEMPERATURE: "", //냉동 컨테이너 추가정보 온도
          sfc_FAN_OPEN_PER: "", //냉동 컨테이너 추가정보 환풍기 OPEN
          sfc_LENGTH: "", //OUG 사이즈
          sfc_WIDTH: "", //OUG 사이즈
          sfc_HEIGHT: "", //OUG 사이즈
          sfc_VOID: "", //OUG 사이즈
          sfc_WEIGHT: "", //OUG 사이즈
          sfc_MEMO: "", // 컨테이너 메모
        });
      }
    }

    let increaseCnt = 0;
    for (let i = 0; i < _containerList.length; i++) {
      if (
        _containerList[i]["sfc_TYPE"] === "OT" ||
        _containerList[i]["sfc_TYPE"] === "FR"
      ) {
        let addCount = 0;

        addCount = parseInt(_containerList[i]["sfc_CNT"], 0);

        if (addCount > 0) {
          for (let j = increaseCnt; j < increaseCnt + addCount; j++) {
            _containerOGList[j]["sfc_TYPE"] = _containerList[i]["sfc_TYPE"];
            _containerOGList[j]["sfc_SIZE"] = _containerList[i]["sfc_SIZE"];
          }
          increaseCnt += addCount;
        }
      }
    }

    setContainerOGList(_containerOGList);
  };

  //OG 컨테이너 데이터 변경
  const ContainerOGDetailDataChange = (index, key, value, data) => {
    let _containerOGList = [...containerOGList];

    if (key === "sfc_DEL_YN") {
      codeList.Modal.current.confirm(
        "해당 컨테이너를 삭제하시겠습니까? 삭제된 내용은 저장 후 반영됩니다.",
        (ret) => {
          if (ret) {
            _containerOGList[index][key] = value;
            let _containerList = [...containerList];
            let mainIndex = _containerList.findIndex(
              (item) =>
                item.sfc_MAIN_TYPE === "M" &&
                item.sfc_SIZE === data.sfc_SIZE &&
                item.sfc_BOOK_UID === data.sfc_BOOK_UID
            );
            _containerList[mainIndex].sfc_CNT =
              _containerList[mainIndex].sfc_CNT - 1;
            setContainerList(_containerList);
          } else {
            return;
          }
        }
      );
    } else {
      _containerOGList[index][key] = value;
    }

    setContainerOGList(_containerOGList);
  };

  //컨테이너 삭제
  const deleteContainerRow = (index, key, value, data) => {
    codeList.Modal.current.confirm(
      "해당 컨테이너 정보를 삭제하시겠습니까?, 삭제여부 적용은 저장후 적용됩니다.",
      (ret) => {
        if (ret) {
          let _containerList = [...containerList];
          let _containerOGList = [...containerOGList];
          if (
            data.sfc_MAIN_TYPE === "M" &&
            (data.sfc_TYPE === "OT" || data.sfc_TYPE === "FR")
          ) {
            //  삭제되는 메인컨테이너 타입이 OUG 컨테이너인 경우
            let newOGContainerList = [];
            for (let i = 0; i < _containerOGList.length; i++) {
              let _container = _containerOGList[i];
              if (
                _container.sfc_TYPE === data.sfc_TYPE &&
                _container.sfc_SIZE === data.sfc_SIZE
              ) {
                if (
                  _container.hasOwnProperty("sfc_IDX") &&
                  _container.sfc_IDX
                ) {
                  _container.sfc_DEL_YN = "Y";
                  newOGContainerList = [...newOGContainerList, _container];
                }
              } else {
                newOGContainerList = [...newOGContainerList, _container];
              }
            }
            _containerOGList = newOGContainerList;
          }
          if (
            _containerList[index].hasOwnProperty("sfc_IDX") &&
            _containerList[index].sfc_IDX
          ) {
            //  기존에 등록되어있던 컨테이너
            _containerList[index].sfc_DEL_YN = "Y";
          } else {
            _containerList.splice(index, 1);
          }

          let reefer_count =
            _containerList.filter(
              (item) => item.sfc_TYPE === "RF" && item.sfc_DEL_YN === "N"
            ).length > 0;
          let oug_count =
            _containerList.filter(
              (item) =>
                (item.sfc_TYPE === "OT" || item.sfc_TYPE === "FR") &&
                item.sfc_DEL_YN === "N"
            ).length > 0;
          setReeferCount(reefer_count);
          setOugCount(oug_count);
          setContainerOGList(_containerOGList);
          setContainerList(_containerList);
        }
      }
    );
  };

  //컨테이너 정보 첨부파일 업로드
  const uploadContainerFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...containerFiles, `${val.realFileName}?${val.fileName}`];
    //파일명?파일주소
    setContainerFiles(dataList);
  };

  //컨테이너 정보 첨부파일 삭제
  const deleteContainerFile = (index) => {
    let dataList = [...containerFiles];
    dataList.splice(index, 1);
    setContainerFiles(dataList);
  };

  //컨테이너 정보 첨부파일 업로드
  const uploadAddMemoFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert("오류로인해 파일을 업로드하지 못했습니다.");
      return;
    }
    let dataList = [...etcFiles, `${val.realFileName}?${val.fileName}`];
    //파일명?파일주소
    setEtcFiles(dataList);
  };

  //컨테이너 정보 첨부파일 삭제
  const deleteEtcFile = (index) => {
    let dataList = [...etcFiles];
    dataList.splice(index, 1);
    setEtcFiles(dataList);
  };

  //컨테이너 제외 입력값 검사
  const checkValidation = () => {
    //회사정보
    if ($controller.sbf_COMP_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("회사정보의 실화주 정보를 입력해주세요.");
      return false;
    }

    //부킹담당자
    if ($controller.sbf_USER_NAME.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 이름을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_BOOK_TEL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 전화번호을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_BOOK_MOBILE.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 휴대전화 번호를 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 부킹담당자 이메일을 입력해주세요."
      );
      return false;
    }
    //서류담당자
    if ($controller.sbf_DOC_USER_NAME.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 이름을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_TEL.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 전화번호을 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_MOBILE.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 휴대전화 번호를 입력해주세요."
      );
      return false;
    }
    if ($controller.sbf_DOC_USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "담당자정보의 서류담당자 이메일을 입력해주세요."
      );
      return false;
    }
    //화물상세정보
    if ($controller.sbf_WEIGHT_CNT.current.value.trim() === "") {
      codeList.Modal.current.alert("화물의 무게를 입력해주세요.");
      return false;
    }

    //운송조건
    if (
      $controller.sbf_TRAN_FREIGHT.current.value === "Collect" &&
      $controller.sbf_TRAN_FREIGHT_SCNO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송조건의 SC No.를 입력해주세요.");
      return false;
    }
    if ($controller.sbf_TRAN_TERM.current.value.trim() === "") {
      codeList.Modal.current.alert("운송조건 Term를 선택해주세요");
      return false;
    }
    //운송요청 - 픽업정보
    if (
      $controller.sbf_PICK_DATE.current &&
      $controller.sbf_PICK_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 픽업일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_PICK_PLACE.current &&
      $controller.sbf_PICK_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 픽업장소를 선택해주세요"
      );
      return false;
    }

    if (
      $controller.sbf_PICK_USER_NM.current &&
      $controller.sbf_PICK_USER_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 픽업정보 담당자를 입력해주세요");
      return false;
    }

    if (
      $controller.sbf_PICK_USER_TEL.current &&
      $controller.sbf_PICK_USER_TEL.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 픽업정보 담당자 연락처를 입력해주세요"
      );
      return false;
    }

    //운송요청 - 도어정보
    if (
      $controller.sbf_FACT_DATE.current &&
      $controller.sbf_FACT_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 작업일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_FACT_PLACE.current &&
      $controller.sbf_FACT_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 작업장소를 선택해주세요"
      );
      return false;
    }

    if (
      $controller.sbf_FACT_USER_NM.current &&
      $controller.sbf_FACT_USER_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 도어정보 담당자를 입력해주세요");
      return false;
    }

    if (
      $controller.sbf_FACT_USER_TEL.current &&
      $controller.sbf_FACT_USER_TEL.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 도어정보 담당자 연락처를 입력해주세요"
      );
      return false;
    }

    //운송요청 - 입고정보
    if (
      $controller.sbf_CFS_DATE.current &&
      $controller.sbf_CFS_DATE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(
        "운송요청의 입고정보 반입일자를 선택해주세요"
      );
      return false;
    }
    if (
      $controller.sbf_CFS_PLACE.current &&
      $controller.sbf_CFS_PLACE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("운송요청의 입고정보 CFS를 선택해주세요");
      return false;
    }

    return true;
  };

  //컨테이너 데이터 입력 검사
  const checkValidationForContainer = () => {
    //기본컨테이너 및 Reefer컨테이너 리스트 검사
    let _containerList = [...containerList];
    for (let i = 0; i < _containerList.length; i++) {
      let main_container = _containerList[i];
      //  일반 컨테이너 입력검사
      if (main_container["sfc_TYPE"].trim() === "") {
        codeList.Modal.current.alert("컨테이너 정보의 타입을 입력해주세요.");
        return false;
      }
      if (main_container["sfc_SIZE"].trim() === "") {
        codeList.Modal.current.alert("컨테이너 정보의 사이즈를 입력해주세요.");
        return false;
      }
      if (
        main_container["sfc_CNT"].trim() === "" ||
        main_container["sfc_CNT"] === 0
      ) {
        codeList.Modal.current.alert("컨테이너 정보의 수량을 입력해주세요.");
        return false;
      }

      if (main_container["sfc_MAKER_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 화주 컨테이너 여부를 선택해주세요."
        );
        return false;
      }
      if (main_container["sfc_DANGER_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 위험화물 여부를 선택해주세요."
        );
        return false;
      }
      if (main_container["sfc_EMPTY_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 Empyt 여부를 선택해주세요."
        );
        return false;
      }

      if (main_container["sfc_FLEXI_YN"].trim() === "") {
        codeList.Modal.current.alert(
          "컨테이너 정보의 Flexi-Bag 여부를 선택해주세요."
        );
        return false;
      }

      if (main_container["sfc_TYPE"] === "RF") {
        //  냉동컨테이너 입력 검사
        if (main_container["sfc_TEMPERATURE"].trim() === "") {
          codeList.Modal.current.alert(
            "냉동 컨테이너 추가정보의 온도를 입력해주세요."
          );
          return false;
        }
        if (main_container["sfc_FAN_OPEN_PER"].trim() === "") {
          codeList.Modal.current.alert(
            "냉동 컨테이너 추가정보의 환풍기 오픈정도를 선택해주세요."
          );
          return false;
        }
      }
    }

    //OG 컨테이너 리스트 검사
    let _containerOGList = [...containerOGList];
    for (let i = 0; i < _containerOGList.length; i++) {
      let oog_container = _containerOGList[i];
      if (oog_container["sfc_LENGTH"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Length를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_WIDTH"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Width를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_HEIGHT"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Height를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_VOID"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Void를 입력해주세요."
        );
        return false;
      }
      if (oog_container["sfc_WEIGHT"].trim() === "") {
        codeList.Modal.current.alert(
          "OUG 컨테이너 추가정보의 Weight를 입력해주세요."
        );
        return false;
      }
    }

    return true;
  };

  //저장 싱행
  const actionSave = (e) => {
    codeList.Modal.current.confirm(
      "해당 스케줄의 예약정보를 변경하시겠습니까?",
      (ret) => {
        if (ret) {
          if (checkValidation() && checkValidationForContainer()) {
            let data = {
              sbf_IDX: rowData.sbf_IDX,
              sbf_IDX_MAIN: rowData.sm_IDX,
              sbf_UID_MAIN: rowData.sm_UID,

              sbf_BOOK_UID: rowData.sbf_BOOK_UID, //예약 UID
              sbf_COMP_CD: loginInfo.comp_cd, //업체코드
              sbf_COMP_NAME: loginInfo.comp_nm, //업체명

              sbf_SHIPPER: null, //SHIPPER
              sbf_COMP_NM: null, //실화주

              sbf_USER_NAME: null, //부킹담당자 이름
              sbf_BOOK_TEL: null, //부킹담당자 전화번호
              sbf_BOOK_MOBILE: null, //부킹담당자 휴대전화
              sbf_USER_ID: null, //부킹담당자 이메일
              sbf_BOOK_FAX: null, //부킹담당자 팩스

              sbf_DOC_USER_NAME: null, //서류담당자 이름
              sbf_DOC_USER_TEL: null, //서류담당자 전화번호
              sbf_DOC_USER_MOBILE: null, //서류담당자 휴대전화
              sbf_DOC_USER_ID: null, //서류담당자 이메일
              sbf_DOC_USER_FAX: null, //서류담당자 팩스

              sbf_WEIGHT_CNT: null, //화물 무게
              sbf_WEIGHT_TYPE: null, //화물 무게 구분

              sbf_TRAN_TYPE: null, // 운송조건 구분
              sbf_TRAN_FREIGHT: null, //운송조건 Freight
              sbf_TRAN_FREIGHT_SCNO: null, //운송조건 SC No.
              sbf_TRAN_TERM: null, //운송조건 SC No.

              sbf_PICK_DATE: null, //픽업날자
              sbf_PICK_TIME: null, //픽업 시간
              sbf_PICK_PLACE: null, //픽업위치
              sbf_PICK_USER_NM: null, //픽업 담당자 이름
              sbf_PICK_USER_TEL: null, //픽업 담당자 연락처
              sbf_PICK_MEMO: null, // 픽업 요청 메모

              sbf_FACT_DATE: null, //공장 작업일자
              sbf_FACT_TIME: null, //공장 작업 시간
              sbf_FACT_PLACE: null, //공장 작업장소
              sbf_FACT_USER_NM: null, //공장 담당자 이름
              sbf_FACT_USER_TEL: null, //공장 담당자 연락처
              sbf_FACT_MEMO: null, //공장 요청 메모

              sbf_CFS_DATE: null, //입고 날짜
              sbf_CFS_TIME: null, //입고 시간
              sbf_CFS_PLACE: null, // 입고 CFS
              sbf_CFS_MEMO: null, //입고 요청사항

              sbf_ETC_PRODUCT: null, //영문명
              sbf_ETC_RTN_YN: null, //반송화물 보세운송조건
              sbf_ETC_MEMO: null, //요청사항
              sbf_SHORT_LINK: null, //예약정보 링크
              sbf_BLNO: null, //BL No

              sbf_FILES: containerFiles.join("|"),
              sbf_ETC_FILES: etcFiles.join("|"),

              sbf_BEFORE_STATUS: rowData.sbf_STATUS, //변경 전 상태
              sbf_STATUS: "N", // 변경후 상태
              sbf_CREATE_COMPCD: loginInfo.comp_cd,
              sbf_CREATE_USERID: loginInfo.user_id,
              sbf_UPDATE_COMPCD: loginInfo.comp_cd,
              sbf_UPDATE_USERID: loginInfo.user_id,
              containers: [...containerList, ...containerOGList],

              //  스케줄 정보(상태변경시 이메일에 적용)
              sm_PROVIDER_CODE: rowData.sm_PROVIDER_CODE, //선사
              sm_PROVIDER_NAME: compListObj.hasOwnProperty(
                rowData.sm_PROVIDER_CODE
              )
                ? compListObj[rowData.sm_PROVIDER_CODE].COMP_NM
                : "선사 정보 확인", //선사
              sm_FWD_NM: rowData.sm_FWD_NM,
              sm_POL_NAME: rowData.sm_POL_NAME,
              sm_POD_NAME: rowData.sm_POD_NAME,
              sm_DEPARTURE_DATE: rowData.sm_DEPARTURE_DATE,
              sm_ARRIVE_DATE: rowData.sm_ARRIVE_DATE,
              sm_VESSEL: rowData.sm_VESSEL,
              sm_VOY: rowData.sm_VOY,
            };
            for (let key in $controller) {
              if ($controller[key].current)
                data[key] = $controller[key].current.value || "";
            }
            updateReserveFCLData(e, data, "FCL 예약정보가 변경되었습니다.");
          }
        }
      }
    );
  };

  //BL등록처리
  const UPDATE_RESERVE_FCL_BLNO = () => {
    if ($controller.sbf_BLNO.current.value.trim() === "") {
      codeList.Modal.current.alert("B/L No.를 입력해주세요");
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_BLNO_update.php",
      msgID: msgID,
      data: {},
      sbf_IDX: rowData.sbf_IDX,
      sbf_STATUS: "B",
      sbf_BEFORE_STATUS: rowData.sbf_STATUS,
      sbf_BLNO: $controller.sbf_BLNO.current.value,
      //  스케줄 정보(상태변경시 이메일에 적용)
      sm_PROVIDER_CODE: rowData.sm_PROVIDER_CODE, //선사
      sm_PROVIDER_NAME: compListObj.hasOwnProperty(rowData.sm_PROVIDER_CODE)
        ? compListObj[rowData.sm_PROVIDER_CODE].COMP_NM
        : "선사 정보 확인", //선사
      sm_FWD_NM: rowData.sm_FWD_NM,
      sm_POL_NAME: rowData.sm_POL_NAME,
      sm_POD_NAME: rowData.sm_POD_NAME,
      sm_DEPARTURE_DATE: rowData.sm_DEPARTURE_DATE,
      sm_ARRIVE_DATE: rowData.sm_ARRIVE_DATE,
      sm_VESSEL: rowData.sm_VESSEL,
      sm_VOY: rowData.sm_VOY,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("B/L No.가 정상적으로 등록되었습니다.");
          requestReserveFCLList();
        } else {
          codeList.Modal.current.alert(
            "B/L No. 등록중 오류가 발생하였습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };
  //B/L No. 삭제
  const DELETE_RESERVE_FCL_BLNO = () => {
    codeList.Modal.current.confirm(
      "등록된 B/L No.를 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/schedule_fcl_reserve/JS_schedule_fcl_BLNO_update.php",
            msgID: msgID,
            data: {},
            sbf_IDX: rowData.sbf_IDX,
            sbf_STATUS: "Y",
            sbf_BEFORE_STATUS: rowData.sbf_STATUS,
            sbf_BLNO: "",
            //  스케줄 정보(상태변경시 이메일에 적용)
            sm_PROVIDER_CODE: rowData.sm_PROVIDER_CODE, //선사
            sm_PROVIDER_NAME: compListObj.hasOwnProperty(
              rowData.sm_PROVIDER_CODE
            )
              ? compListObj[rowData.sm_PROVIDER_CODE].COMP_NM
              : "선사 정보 확인", //선사
            sm_FWD_NM: rowData.sm_FWD_NM,
            sm_POL_NAME: rowData.sm_POL_NAME,
            sm_POD_NAME: rowData.sm_POD_NAME,
            sm_DEPARTURE_DATE: rowData.sm_DEPARTURE_DATE,
            sm_ARRIVE_DATE: rowData.sm_ARRIVE_DATE,
            sm_VESSEL: rowData.sm_VESSEL,
            sm_VOY: rowData.sm_VOY,
          };

          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  "B/L No.가 정상적으로 삭제되었습니다."
                );
                $controller.sbf_BLNO.current.value = "";
                requestReserveFCLList();
              } else {
                codeList.Modal.current.alert(
                  "오류가 발생하였습니다. 다시 시도해주세요."
                );
              }
            }
          });
        }
      }
    );
  };

  //컨테이너 삭제
  const DELETE_CONTAINER_DATA = (data, index) => {
    if (containerList.length === 1) {
      codeList.Modal.current.alert(
        "최소 한개의 컨테이너 정보 등록이 필요합니다."
      );
      return;
    }

    codeList.Modal.current.confirm(
      "해당 컨테이너 정보를 삭제하시겠습니까?",
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/schedule_fcl_reserve/JS_schedule_fcl_reserve_container_delete.php",
            msgID: msgID,
            data: {},
            sfc_IDX: data.sfc_IDX,
            sfc_BOOK_UID: data.sfc_BOOK_UID,
          };

          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  "컨테이너 정보가 정상적으로 삭제되었습니다."
                );

                if (data.sfc_MAIN_TYPE === "O") {
                  let _containerOGList = [...containerOGList];
                  _containerOGList.splice(index, 1);

                  let _containerList = [...containerList];
                  let thisMainContainerIndex = _containerList.findIndex(
                    (item) =>
                      item.sfc_MAIN_TYPE === "M" &&
                      item.sfc_BOOK_UID === data.sfc_BOOK_UID &&
                      item.sfc_SIZE === data.sfc_SIZE
                  );

                  _containerList[thisMainContainerIndex].sfc_CNT =
                    --_containerList[thisMainContainerIndex].sfc_CNT;

                  setContainerOGList(_containerOGList);
                  setContainerList(_containerList);
                } else {
                  let _containerList = [...containerList];
                  _containerList.splice(index, 1);

                  if (reeferCount) {
                    let count =
                      _containerList.filter(
                        (item) => item.sfc_MAIN_TYPE === "R"
                      ).length > 0;
                    setReeferCount(count);
                  }
                  if (ougCount) {
                    let count =
                      _containerList.filter(
                        (item) => item.sfc_MAIN_TYPE === "O"
                      ).length > 0;
                    setOugCount(count);
                  }
                  setContainerList(_containerList);
                }
              } else {
                codeList.Modal.current.alert(
                  "컨테이너 정보 삭제에 실패하였습니다. 다시 시도해주세요"
                );
              }
            }
          });
        }
      }
    );
  };

  // 선박위치 조회
  let mmsiNum = mmsi.getMMSI(rowData.sm_VESSEL);
  const showOceanLookV3 = (e, arr) => {
    e.preventDefault();
    let oceanlookWebToken =
      "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

    let popup = "oceanlookForm";
    let url =
      "https://www.oceanlook.net/v3/popup?token=" +
      oceanlookWebToken +
      "&mmsi=" +
      mmsiNum;
    let options =
      "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
    window.open(url, popup, options);
  };

  //담당자와 바로대화
  const requestEnquiryBottom = (managerCompCD, managerID, managerType) => {
    codeList.Modal.current.confirm(
      `로지톡으로 ${managerType} 담당자에게 연결하시겠습니까?`,
      (ret) => {
        if (ret) {
          // localStorage.setItem(
          //   "enquiryManager",
          //   `${managerCompCD}|${managerID}`
          // );

          localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
          window.open("/LogiTalk", "_blank", "width=1200, height=800");
        } else {
          codeList.Modal.current.alert("담당자 연결이 취소되었습니다.");
        }
      }
    );
  };

  const createLink = (e) => {
    let url =
      "https://www.gbts.co.kr/backside/schedule_booking_fcl_information_link.php?num=";
    let randLink = util.buddibleEncrypt(rowData.sbf_IDX);

    url = url + randLink + "#schedule_info_scroll";
    url = encodeURI(url);
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      {
        long_url: url,
      },
      msgID,
      (beforeData, newData) => {
        $controller.sbf_SHORT_LINK.current.value = newData["link"];

        let updateData = {
          sbf_IDX: rowData.sbf_IDX,
          sbf_SHORT_LINK: newData["link"],
        };

        updateReserveFCLShortLink(e, updateData);
      }
    );
  };

  const updateReserveFCLShortLink = (e, updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_fcl_reserve/JS_schedule_fcl_short_link_create.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert("예약정보 링크가 생성되었습니다");
          requestReserveFCLList();
        } else {
          codeList.Modal.current.alert("요청 처리중 오류가 발생하였습니다.");
        }
      }
    });
  };

  // 링크 복사
  const copyLink = () => {
    const el = $controller.sbf_SHORT_LINK.current;
    el.select();
    document.execCommand("copy");
    if (document.execCommand("copy")) {
      codeList.Modal.current.alert("복사되었습니다.", () => {});
    }
  };

  // 링크 미리보기
  const openLink = () => {
    const el = $controller.sbf_SHORT_LINK.current.value;
    if (!el) {
      codeList.Modal.current.alert("링크를 먼저 생성 해주세요.", () => {});
    } else {
      window.open(el, "", "_blank");
    }
  };

  return (
    <>
      <tr
        className="container"
        id={rowData.sb_IDX}
        style={{ background: "#F7F7F7" }}
      >
        <td colSpan="12">
          <div className="card border-0 shadow-none m-3 text-dark">
            <div
              className="card-body m-0 p-0"
              style={{ background: "#F7F7F7" }}
            >
              <p className="rounded-square bg-white h6 font-weight-bold mb-3">
                ■ 스케줄 관리번호 : {rowData.sm_UID}
              </p>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2 mr-3">
                  ■ 요청 회사 및 담당자 정보
                </p>
                <div className="row m-0 p-0">
                  <div className="col-6 m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            SHIPPER
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            readOnly={true}
                            ref={$controller.sbf_SHIPPER}
                            defaultValue={rowData.sbf_SHIPPER}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            실화주
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            ref={$controller.sbf_COMP_NM}
                            defaultValue={rowData.sbf_COMP_NM}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-xl-6 m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <span className="mb-0 font-weight-bold">
                          부킹담당자
                        </span>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            이름
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_USER_NAME}
                            defaultValue={rowData.sbf_USER_NAME}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0" />
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            전화번호
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            ref={$controller.sbf_BOOK_TEL}
                            defaultValue={rowData.sbf_BOOK_TEL}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            휴대전화
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            ref={$controller.sbf_BOOK_MOBILE}
                            defaultValue={rowData.sbf_BOOK_MOBILE}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            이메일
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            ref={$controller.sbf_USER_ID}
                            defaultValue={rowData.sbf_USER_ID}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            FAX
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_BOOK_FAX}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            defaultValue={rowData.sbf_BOOK_FAX}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="col-xl-6 m-0 mb-2 p-0 d-flex flex-row justify-content-between align-items-center">
                        <span className="mb-0 font-weight-bold">
                          서류담당자
                        </span>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0" />
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            이름
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_DOC_USER_NAME}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            defaultValue={rowData.sbf_DOC_USER_NAME}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0" />
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            전화번호
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            type="text"
                            ref={$controller.sbf_DOC_USER_TEL}
                            defaultValue={rowData.sbf_DOC_USER_TEL}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            휴대전화
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_DOC_USER_MOBILE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            defaultValue={rowData.sbf_DOC_USER_MOBILE}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            이메일
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_DOC_USER_ID}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            defaultValue={rowData.sbf_DOC_USER_ID}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 100 }}
                          >
                            FAX
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_DOC_USER_FAX}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            defaultValue={rowData.sbf_DOC_USER_FAX}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 요청 스케쥴 정보</p>
                <div className="row m-0 p-0">
                  <div className="col-xl-12 m-0 p-0 mb-2">
                    <div className="row m-0 p-0">
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          스케쥴 No
                        </div>
                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          {rowData.sm_UID}
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          선사
                        </div>
                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <input
                            type="text"
                            className="bg-transparent border-0 text-center w-100"
                            placeholder="선사 입력"
                            defaultValue={rowData.sm_FWD_NM}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          FCL/LCL
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          {rowData.sm_FCL_LCL}
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          VESSEL/VOY
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <p>
                            <input
                              type="text"
                              className="bg-transparent border-0 text-center w-100"
                              placeholder="VESSEL 입력"
                              defaultValue={rowData.sm_VESSEL}
                              disabled={
                                !(loginInfo.isAdmin || loginInfo.isConsole)
                              }

                              // onChange={(e) =>
                              //   ContainerDetailDataChange(index, "sfc_CNT", e.target.value)
                              // }
                            />
                          </p>{" "}
                          <p>
                            <input
                              type="text"
                              className="bg-transparent border-0 text-center w-100"
                              placeholder="VOY 입력"
                              defaultValue={rowData.sm_VOY}
                              disabled={
                                !(loginInfo.isAdmin || loginInfo.isConsole)
                              }

                              // onChange={(e) =>
                              //   ContainerDetailDataChange(index, "sfc_CNT", e.target.value)
                              // }
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          POL
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <p className="font-weight-bold">
                            {rowData.sm_POL_NAME}
                          </p>
                          <p className="font-weight-bold text-danger">
                            {rowData.sm_DEPARTURE_DATE} (
                            {util.handleGetDate(rowData.sm_DEPARTURE_DATE)})
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          POD
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <p className="font-weight-bold">
                            {rowData.sm_POD_NAME}
                          </p>
                          <p className="font-weight-bold text-danger">
                            {rowData.sm_ARRIVE_DATE} (
                            {util.handleGetDate(rowData.sm_ARRIVE_DATE)})
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-secondary text-white font-weight-bold p-2">
                          T/S Port
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          {rowData.sm_TS_PORT || "-"}
                        </div>
                      </div>
                      <div className="col-6 col-xl-auto flex-grow-1 text-center m-0 p-0 d-flex flex-column">
                        <div className="bg-secondary text-white font-weight-bold p-2">
                          2<sup>nd</sup> Vessel
                        </div>
                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          {rowData.sm_2ND_VESSEL || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 m-0 p-0">
                    <p className="text-secondary">
                      ※ 운항선사의 사정상 출항/도착시간은 예고없이 변동될 수
                      있습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 업무 스케쥴 정보</p>
                <div className="row m-0 p-0">
                  <div className="col-xl-12 m-0 p-0 mb-2">
                    <div className="row m-0 p-0">
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          서류마감
                        </div>
                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <input
                            type="date"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_DOC_CLOSE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />{" "}
                          <input
                            type="text"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_DOC_CLOSE_TIME}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          EDI 신고마감
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <input
                            type="date"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_EDI_DATE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />{" "}
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          CFS 반입마감/AFR마감
                        </div>
                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <input
                            type="date"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_CFS_DATE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />{" "}
                          <input
                            type="date"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_AFR_DATE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 col-xl-auto flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          컨테이너 반입마감
                        </div>

                        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                          <input
                            type="date"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_CARGO_CLOSE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />{" "}
                          <input
                            type="text"
                            className="bg-transparent border-0 text-center w-100"
                            defaultValue={rowData.sm_CARGO_CLOSE_TIME}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isConsole)
                            }
                          />
                        </div>
                      </div>
                      <div className="row m-0 p-0 flex-grow-1 text-center">
                        <div className="col-12 col-xl-6 m-0 p-0 border-right d-flex flex-column">
                          <div className="bg-info text-white font-weight-bold p-2">
                            출발터미널
                          </div>

                          <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                            <p className="font-weight-bold">
                              {rowData.sm_DEPARTURE_TERMINAL || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 m-0 p-0 d-flex flex-column">
                          <div className="bg-info text-white font-weight-bold p-2">
                            도착 터미널
                          </div>

                          <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
                            <p className="font-weight-bold">
                              {rowData.sm_ARRIVE_TERMINAL || "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 m-0 p-0">
                    <span className="text-secondary">
                      ※ 서류/컨테이너 반입마감 시간은 변동될 수 있으므로,
                      업무담당자 톡연결로 확인해 주시기 바랍니다.
                    </span>
                  </div>
                </div>
              </div>

              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 화물 상세 정보</p>
                <div className="row m-0 p-0">
                  <div className="col-6 m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 150 }}
                          >
                            Gross Weight
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="number"
                            ref={$controller.sbf_WEIGHT_CNT}
                            defaultValue={rowData.sbf_WEIGHT_CNT}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                            style={{ width: 150 }}
                          />
                        </div>
                      </div>
                      <div className="col-6 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start align-items-center overflow-hidden h-100 mr-2">
                          <input
                            className={"d-none"}
                            ref={$controller.sbf_WEIGHT_TYPE}
                            defaultValue={rowData.sbf_WEIGHT_TYPE}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              defaultChecked={true}
                              disabled={
                                !(loginInfo.isAdmin || loginInfo.isForwarder)
                              }
                              name="sbf_WEIGHT_CNT"
                              onClick={(e) => {
                                $controller.sbf_WEIGHT_TYPE.current.value =
                                  "KG";
                              }}
                            />{" "}
                            KG
                          </label>
                          <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              className="mr-1"
                              disabled={
                                !(loginInfo.isAdmin || loginInfo.isForwarder)
                              }
                              name="sbf_WEIGHT_CNT"
                              onClick={(e) => {
                                $controller.sbf_WEIGHT_TYPE.current.value =
                                  "TON";
                              }}
                            />{" "}
                            TON
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-square bg-white mb-3">
                <div
                  className="col-xl-12 m-0 mb-2 p-0 d-flex flex-row justify-content-between"
                  style={{ width: 1110 }}
                >
                  <div>
                    <p className="h6 font-weight-bold mb-2">■ 컨테이너 정보</p>
                  </div>
                  <button
                    className="btn btn-sm btn-light py-1 px-2 font-size-09"
                    onClick={() => AddContainerInfo("M")}
                  >
                    컨테이너 추가 +
                  </button>
                </div>

                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0 mb-2 overflow_scroll">
                    <div
                      className="row flex-nowrap m-0 p-0"
                      style={{ width: 1110 }}
                    >
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          Type
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          Size
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          Q'ty
                        </div>
                      </div>
                      <div className="col-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          화주 컨테이너
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          위험화물
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          Empyt
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          Flexi-Bag
                        </div>
                      </div>
                      <div className="col-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                        <div className="bg-info text-white font-weight-bold p-2">
                          추가 요청사항
                        </div>
                      </div>
                      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
                        <div className="bg-secondary text-white font-weight-bold p-2">
                          삭제
                        </div>
                      </div>
                    </div>
                    {containerList.map((item, index) => {
                      if (item.sfc_DEL_YN === "N") {
                        return (
                          <ContainerInfoRow
                            item={item}
                            index={index}
                            key={item.sfc_IDX}
                            loginInfo={loginInfo}
                            ContainerDetailDataChange={
                              ContainerDetailDataChange
                            }
                            DELETE_CONTAINER_DATA={DELETE_CONTAINER_DATA}
                            deleteContainerRow={deleteContainerRow}
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="col-xl-12 m-0 p-0 mb-2">
                    <div className=" d-flex flex-row align-items-center">
                      <span
                        className="d-inline-block bg-info text-white rounded px-4 py-1 mr-2 height-30"
                        style={{ lineHeight: "22px" }}
                      >
                        파일첨부
                      </span>
                      <UploadImageToS3WithPhp
                        region={`mall/reserveFcl/${rowData.sm_UID}`}
                        callback={(val) => {
                          uploadContainerFile(val);
                        }}
                      >
                        <span
                          className="btn btn-sm btn-secondary height-30 width-30 text-center"
                          style={{ lineHeight: "26px" }}
                        >
                          +
                        </span>
                      </UploadImageToS3WithPhp>
                    </div>
                  </div>
                  <div>
                    {containerFiles.length > 0 &&
                      containerFiles.map((item, index) => {
                        let split_data = item.split("?");
                        let file_name = split_data[0];
                        let file_link = split_data[1];
                        return (
                          <div
                            key={item + "_" + index}
                            className="d-flex flex-row align-items-center mb-1"
                          >
                            <span
                              className="cursor_pointer text-underline text-primary mr-2"
                              onClick={() => {
                                window.open(
                                  file_link,
                                  "_blank",
                                  "width=1200, height=800"
                                );
                              }}
                            >
                              {file_name}
                            </span>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1  font-size-06"
                              onClick={() => deleteContainerFile(index)}
                            >
                              삭제
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 컨테이너 추가 정보</p>

                <div className="row m-0 p-0">
                  {reeferCount > 0 && (
                    <div className="col-xl-12 m-0 p-0 mb-2">
                      <div className="col-xl-12 m-0 mb-2 p-0 d-flex flex-row justify-content-between">
                        <span className="mb-0 font-weight-bold">
                          냉동 컨테이너(Reefer) 추가정보
                        </span>
                      </div>
                      <div className="col-12 m-0 p-0 overflow_scroll">
                        <div
                          className="row flex-nowrap m-0 p-0"
                          style={{ width: 1110 }}
                        >
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Type
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Size
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              온도(℃)
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              환풍기 OPEN
                            </div>
                          </div>
                        </div>
                        {containerList.map((item, index) => {
                          if (
                            item.sfc_TYPE === "RF" &&
                            item.sfc_DEL_YN === "N"
                          ) {
                            return (
                              <ReeferContainerInfoRow
                                key={item.sfc_IDX}
                                item={item}
                                index={index}
                                loginInfo={loginInfo}
                                deleteContainerRow={deleteContainerRow}
                                ContainerDetailDataChange={
                                  ContainerDetailDataChange
                                }
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                  {ougCount > 0 && (
                    <div className="col-xl-12 m-0 p-0 mb-2">
                      <div className="col-xl-12 m-0 mb-2 p-0 d-flex flex-row justify-content-between">
                        <div>
                          <span className="mb-0 font-weight-bold mr-2">
                            OUG(Out of Gauge) 추가정보
                          </span>
                          <button className="btn btn-sm btn-outline-secondary px-2 py-1 font-size-08">
                            컨테이너 사이즈정보
                          </button>
                        </div>
                      </div>

                      <div className="col-12 m-0 p-0 mb-2 overflow_scroll">
                        <div
                          className="row flex-nowrap m-0 p-0 font-size-09"
                          style={{ width: 1110 }}
                        >
                          <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Type
                            </div>
                          </div>
                          <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-freiscop text-white font-weight-bold p-2">
                              Size
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Length(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Width(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Height(mm)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Void(teu)
                            </div>
                          </div>
                          <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              Weight(kg)
                            </div>
                          </div>
                          <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-info text-white font-weight-bold p-2">
                              요청사항
                            </div>
                          </div>
                          {/*       <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                            <div className="bg-secondary text-white font-weight-bold p-2">
                              삭제
                            </div>
                          </div>*/}
                        </div>
                        {containerOGList.map((item, index) => {
                          if (item.sfc_DEL_YN === "N") {
                            return (
                              <OUGContainerInfoRow
                                key={item.sfc_IDX}
                                item={item}
                                index={index}
                                loginInfo={loginInfo}
                                ContainerOGDetailDataChange={
                                  ContainerOGDetailDataChange
                                }
                                DELETE_CONTAINER_DATA={DELETE_CONTAINER_DATA}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                  {reeferCount < 1 && ougCount < 1 && (
                    <p className="ml-2">
                      - 해당 예약건은 컨테이너 추가 정보가 불필요합니다.
                    </p>
                  )}
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 운송조건 정보</p>
                <div className="row m-0 p-0">
                  <div className="col-xl-12 m-0 p-0 d-flex flex-row flex-wrap justify-content-start align-items-center">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <div className="bg-info text-white py-1 px-2 font-weight-bold rounded px-3">
                        구분
                      </div>
                      <div className="d-flex flex-row justify-content-start align-items-center overflow-hidden h-100 mx-2">
                        <input
                          type="text"
                          className="d-none"
                          ref={$controller.sbf_TRAN_TYPE}
                          disabled={
                            !(loginInfo.isAdmin || loginInfo.isForwarder)
                          }
                          defaultValue={rowData.sbf_TRAN_TYPE}
                        />
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            defaultChecked={rowData.sbf_TRAN_TYPE === "LINE"}
                            name="sbf_TRAN_TYPE"
                            onClick={() => {
                              $controller.sbf_TRAN_TYPE.current.value = "LINE";
                              setTransitType("LINE");
                            }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />{" "}
                          라인운송
                        </label>
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            name="sbf_TRAN_TYPE"
                            defaultChecked={rowData.sbf_TRAN_TYPE === "SELF"}
                            onClick={() => {
                              $controller.sbf_TRAN_TYPE.current.value = "SELF";
                              setTransitType("SELF");
                            }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />{" "}
                          자가운송
                        </label>
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            name="sbf_TRAN_TYPE"
                            defaultChecked={rowData.sbf_TRAN_TYPE === "CFS"}
                            onClick={() => {
                              $controller.sbf_TRAN_TYPE.current.value = "CFS";
                              setTransitType("CFS");
                            }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />{" "}
                          CFS
                        </label>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <div className="bg-info text-white py-1 px-2 font-weight-bold rounded px-3">
                        Freight
                      </div>
                      <div className="d-flex flex-row justify-content-start align-items-center overflow-hidden h-100 mx-2">
                        <input
                          type="text"
                          className="d-none"
                          ref={$controller.sbf_TRAN_FREIGHT}
                          defaultValue={rowData.sbf_TRAN_FREIGHT}
                          disabled={
                            !(loginInfo.isAdmin || loginInfo.isForwarder)
                          }
                        />
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            name="sbf_TRAN_FREIGHT"
                            defaultChecked={
                              rowData.sbf_TRAN_FREIGHT === "Prepaid"
                            }
                            onClick={() => {
                              $controller.sbf_TRAN_FREIGHT.current.value =
                                "Prepaid";
                              $controller.sbf_TRAN_FREIGHT_SCNO.current.value =
                                "";
                              $controller.sbf_TRAN_FREIGHT_SCNO.current.disabled = true;
                            }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />{" "}
                          Prepaid
                        </label>
                        <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                          <input
                            type="radio"
                            className="mr-1"
                            name="sbf_TRAN_FREIGHT"
                            defaultChecked={
                              rowData.sbf_TRAN_FREIGHT === "Collect"
                            }
                            onClick={() => {
                              $controller.sbf_TRAN_FREIGHT.current.value =
                                "Collect";
                              $controller.sbf_TRAN_FREIGHT_SCNO.current.disabled = false;
                            }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />{" "}
                          Collect
                        </label>
                        <input
                          type="text"
                          className="border rounded"
                          ref={$controller.sbf_TRAN_FREIGHT_SCNO}
                          defaultValue={rowData.sbf_TRAN_FREIGHT_SCNO}
                          placeholder="SC No. 입력해주세요"
                          disabled={
                            !(loginInfo.isAdmin || loginInfo.isForwarder)
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                      <div
                        className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                        style={{ width: 100 }}
                      >
                        Term
                      </div>

                      <select
                        className="form-control p-0 px-2 m-0 h-unset flex-fill"
                        style={{ width: 200 }}
                        disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                        ref={$controller.sbf_TRAN_TERM}
                        defaultValue={rowData.sbf_TRAN_TERM}
                      >
                        {transitType === "LINE" && (
                          <option value="CY-CY">CY-CY</option>
                        )}
                        {transitType === "SELF" && (
                          <>
                            <option value="">미선택</option>
                            <option value="CY-CY">CY-CY</option>
                            <option value="CFS-CY">CFS-CY</option>
                          </>
                        )}
                        {transitType === "CFS" && (
                          <option value="CFS-CY">CFS-CY</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 운송요청 정보</p>
                <div className="row m-0 p-0">
                  <div className="col-xl-12 m-0 p-0">
                    {transitType === "LINE" && (
                      <div className="row m-0 p-0">
                        <div className="col-xl-6 m-0 p-0">
                          <div className="row m-0 p-0">
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <span className="h6 mb-0 font-weight-bold">
                                픽업정보
                              </span>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  픽업일자
                                </div>
                                <input
                                  className="col-4 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                  type="date"
                                  ref={$controller.sbf_PICK_DATE}
                                  defaultValue={rowData.sbf_PICK_DATE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                                <select
                                  className="col-4 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_PICK_TIME}
                                  defaultValue={rowData.sbf_PICK_TIME}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  {times.map((time) => {
                                    return (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  픽업장소
                                </div>
                                <select
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right  p-0 px-2 m-0 h-unset font-size-08"
                                  style={{ width: 200 }}
                                  ref={$controller.sbf_PICK_PLACE}
                                  defaultValue={rowData.sbf_PICK_PLACE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  <option value="">미선택</option>
                                  {pickPlace.map((item) => {
                                    return (
                                      <option key={item.ppCode}>
                                        {item.ppRemark}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  담당자
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_USER_NM}
                                  defaultValue={rowData.sbf_PICK_USER_NM}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  연락처
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_USER_TEL}
                                  defaultValue={rowData.sbf_PICK_USER_TEL}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  요청사항
                                </div>
                                <input
                                  className="col-10 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_MEMO}
                                  defaultValue={rowData.sbf_PICK_MEMO}
                                  placeholder="픽업 요청사항을 입력해주세요"
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 m-0 p-0">
                          <div className="row m-0 p-0">
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <span className="h6 mb-0 font-weight-bold">
                                도어정보
                              </span>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  작업일자
                                </div>
                                <input
                                  className="col-5 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                  type="date"
                                  ref={$controller.sbf_FACT_DATE}
                                  defaultValue={rowData.sbf_FACT_DATE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                                <select
                                  className="col-3 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_FACT_TIME}
                                  defaultValue={rowData.sbf_FACT_TIME}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  {times.map((time) => {
                                    return (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  작업장소
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_FACT_PLACE}
                                  defaultValue={rowData.sbf_FACT_PLACE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  담당자
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_FACT_USER_NM}
                                  defaultValue={rowData.sbf_FACT_USER_NM}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  연락처
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_FACT_USER_TEL}
                                  defaultValue={rowData.sbf_FACT_USER_TEL}
                                />
                              </div>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  요청사항
                                </div>
                                <input
                                  className="col-10 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_FACT_MEMO}
                                  defaultValue={rowData.sbf_FACT_MEMO}
                                  placeholder="작업 요청사항을 입력해주세요"
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {transitType === "SELF" && (
                      <div className="row m-0 p-0">
                        <div className="col-xl-6 m-0 p-0">
                          <div className="row m-0 p-0">
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <span className="h6 mb-0 font-weight-bold">
                                픽업정보
                              </span>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  픽업일자
                                </div>
                                <input
                                  className="col-5 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                  type="date"
                                  ref={$controller.sbf_PICK_DATE}
                                  defaultValue={rowData.sbf_PICK_DATE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                                <select
                                  className="col-3 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_PICK_TIME}
                                  defaultValue={rowData.sbf_PICK_TIME}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  {times.map((time) => {
                                    return (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  픽업장소
                                </div>
                                <select
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_PICK_PLACE}
                                  defaultValue={rowData.sbf_PICK_PLACE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  <option value="">미선택</option>
                                  {pickPlace.map((item) => {
                                    return (
                                      <option key={item.ppCode}>
                                        {item.ppRemark}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  담당자
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_USER_NM}
                                  defaultValue={rowData.sbf_PICK_USER_NM}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-4 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  연락처
                                </div>
                                <input
                                  className="col-8 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_USER_TEL}
                                  defaultValue={rowData.sbf_PICK_USER_TEL}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  요청사항
                                </div>
                                <input
                                  className="col-10 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_PICK_MEMO}
                                  defaultValue={rowData.sbf_PICK_MEMO}
                                  placeholder="픽업 요청사항을 입력해주세요"
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {transitType === "CFS" && (
                      <div className="row m-0 p-0">
                        <div className="col-xl-6 m-0 p-0">
                          <div className="row m-0 p-0">
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <span className="h6 mb-0 font-weight-bold">
                                입고정보
                              </span>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  반입일자
                                </div>
                                <input
                                  className="col-6 form-control rounded-0 p-0 px-2 m-0 h-unset font-size-08"
                                  type="date"
                                  ref={$controller.sbf_CFS_DATE}
                                  defaultValue={rowData.sbf_CFS_DATE}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                                <select
                                  className="col-4 form-control border-left-0 rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset font-size-08"
                                  ref={$controller.sbf_CFS_TIME}
                                  defaultValue={rowData.sbf_CFS_TIME}
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                >
                                  {times.map((time) => {
                                    return (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  CFS
                                </div>
                                <input
                                  className="col-10 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset"
                                  ref={$controller.sbf_CFS_PLACE}
                                  defaultValue={rowData.sbf_CFS_PLACE}
                                  placeholder="CFS정보를 입력해주세요"
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-12 m-0 mb-2 p-0">
                              <div className="row m-0 p-0 mr-2">
                                <div className="col-2 rounded-top-left rounded-bottom-left bg-info text-white py-1 px-2 text-center font-weight-bold">
                                  요청사항
                                </div>
                                <input
                                  className="col-10 form-control rounded-0 rounded-top-right rounded-bottom-right p-0 px-2 m-0 h-unset flex-fill"
                                  type="text"
                                  ref={$controller.sbf_CFS_MEMO}
                                  defaultValue={rowData.sbf_CFS_MEMO}
                                  placeholder="픽업 요청사항을 입력해주세요"
                                  disabled={
                                    !(
                                      loginInfo.isAdmin || loginInfo.isForwarder
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <p className="h6 font-weight-bold mb-2">■ 추가정보(선택입력)</p>
                <div className="row m-0 p-0">
                  <div
                    className="col-xl-6 m-0 p-0
                  "
                  >
                    <div className="row m-0 p-0">
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 200 }}
                          >
                            품명(영문)
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            ref={$controller.sbf_ETC_PRODUCT}
                            defaultValue={rowData.sbf_ETC_PRODUCT}
                            style={{ width: 200 }}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start  overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold rounded"
                            style={{ width: 200 }}
                          >
                            반송화물(보세운송조건)
                          </div>
                          <div className="d-flex flex-row justify-content-start px-2">
                            <input
                              type="text"
                              ref={$controller.sbf_ETC_RTN_YN}
                              defaultValue={rowData.sbf_ETC_RTN_YN}
                              className="d-none"
                              disabled={
                                !(loginInfo.isAdmin || loginInfo.isForwarder)
                              }
                            />

                            <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                              <input
                                type="radio"
                                className="mr-1"
                                defaultChecked={rowData.sbf_ETC_RTN_YN === "N"}
                                name="sbf_ETC_RTN_YN"
                                onClick={() => {
                                  $controller.sbf_ETC_RTN_YN.current.value =
                                    "N";
                                }}
                                disabled={
                                  !(
                                    !loginInfo.isAdmin || !loginInfo.isForwarder
                                  )
                                }
                              />{" "}
                              No
                            </label>
                            <label className="m-0 p-0 font-weight-bold mr-2 d-flex flex-row align-items-center">
                              <input
                                type="radio"
                                className="mr-1"
                                name="sbf_ETC_RTN_YN"
                                defaultChecked={rowData.sbf_ETC_RTN_YN === "Y"}
                                onClick={() => {
                                  $controller.sbf_ETC_RTN_YN.current.value =
                                    "Y";
                                }}
                                disabled={
                                  !(
                                    !loginInfo.isAdmin || !loginInfo.isForwarder
                                  )
                                }
                              />{" "}
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 m-0 mb-2 p-0">
                        <div className="d-flex flex-row justify-content-start rounded overflow-hidden mr-2">
                          <div
                            className="bg-info text-white py-1 px-2 text-center font-weight-bold"
                            style={{ width: 200 }}
                          >
                            요청사항
                          </div>
                          <input
                            className="form-control p-0 px-2 m-0 h-unset flex-fill"
                            type="text"
                            style={{ width: 200 }}
                            ref={$controller.sbf_ETC_MEMO}
                            defaultValue={rowData.sbf_ETC_MEMO}
                            disabled={
                              !(loginInfo.isAdmin || loginInfo.isForwarder)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 m-0 p-0">
                        <div className=" d-flex flex-row align-items-center">
                          <span
                            className="bg-info text-white text-center rounded px-5 py-1 mr-2"
                            style={{ width: 200 }}
                          >
                            파일첨부
                          </span>
                          <UploadImageToS3WithPhp
                            region={`mall/reserveFcl/${rowData.sm_UID}`}
                            callback={(val) => {
                              uploadAddMemoFile(val);
                            }}
                          >
                            <span
                              className="btn btn-sm btn-secondary py-0"
                              style={{ height: 28 }}
                            >
                              +
                            </span>
                          </UploadImageToS3WithPhp>
                        </div>
                        <div>
                          {etcFiles.length > 0 &&
                            etcFiles.map((item, index) => {
                              const [file_name, file_link] = item.split("?");
                              return (
                                <div
                                  key={item + "_" + index}
                                  className="d-flex flex-row align-items-center mt-2"
                                >
                                  <span
                                    className="cursor_pointer text-underline text-primary mr-2"
                                    onClick={() => {
                                      window.open(
                                        file_link,
                                        "_blank",
                                        "width=1200, height=800"
                                      );
                                    }}
                                  >
                                    {file_name}
                                  </span>
                                  <button
                                    className="btn btn-sm btn-danger px-2 py-1  font-size-06"
                                    onClick={() => deleteEtcFile(index)}
                                  >
                                    삭제
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white mb-3">
                <div className="row m-0 p-0">
                  <div className="col-7 m-0 p-0">
                    <div className="mb-3">
                      <div className="mb-2 d-flex flex-row justify-content-start align-items-center">
                        <div>■ 처리상태</div>
                        <div className="ml-3">
                          <select
                            className="p-2 bg-light border-0"
                            style={{ width: 120 }}
                            defaultValue={rowData.sbf_STATUS}
                            ref={$controller.sbf_STATUS}
                          >
                            <option value="N">예약요청</option>
                            <option value="Y">예약확정</option>
                            <option value="SR">SR접수완료</option>
                            <option value="B">B/L발행</option>
                            <option value="C">요청취소</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center mt-2 px-2">
                        <span
                          className={
                            rowData.sbf_STATUS === "N"
                              ? "font-weight-bold text-primary"
                              : ""
                          }
                        >
                          예약요청
                        </span>
                        <i className="fa-solid fa-chevron-right mx-3" />
                        <span
                          className={
                            rowData.sbf_STATUS === "Y"
                              ? "font-weight-bold text-primary"
                              : ""
                          }
                        >
                          예약확정
                        </span>
                        <i className="fa-solid fa-chevron-right mx-3" />
                        <span
                          className={
                            rowData.sbf_STATUS === "SR"
                              ? "font-weight-bold text-primary"
                              : ""
                          }
                        >
                          S/R접수
                        </span>
                        <i className="fa-solid fa-chevron-right mx-3" />
                        <span
                          className={
                            rowData.sbf_STATUS === "B"
                              ? "font-weight-bold text-primary"
                              : ""
                          }
                        >
                          B/L발행
                        </span>
                        <div className="flex-fill pl-2">
                          <div className="d-flex flex-row">
                            <input
                              className="bg-info-light border-0 px-2"
                              type={"text"}
                              placeholder="B/L No.를 입력해주세요"
                              ref={$controller.sbf_BLNO}
                              defaultValue={rowData.sbf_BLNO}
                            />
                            <button
                              className="btn btn-sm btn-dark rounded-0 border-0"
                              onClick={() => UPDATE_RESERVE_FCL_BLNO()}
                            >
                              B/L등록
                            </button>
                            {rowData.sbf_BLNO !== "" && (
                              <button
                                className="btn btn-sm btn-danger rounded-0 border-0"
                                onClick={() => DELETE_RESERVE_FCL_BLNO()}
                              >
                                B/L삭제
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 d-flex flex-row justify-content-start align-items-center">
                      <div>■ 선박위치</div>
                      <div className="ml-3">
                        <button
                          className="btn btn-sm py-0 btn-primary"
                          disabled={!mmsiNum}
                          onClick={(e) => showOceanLookV3(e)}
                        >
                          조회하기
                        </button>
                      </div>
                    </div>

                    <div className="d-flex flex-row mt-2">
                      <button
                        className="btn btn-sm btn-primary mr-2"
                        onClick={() => {
                          createLink();
                        }}
                      >
                        예약정보 URL 생성
                      </button>
                      <input
                        className="form-control"
                        style={{ width: "40%", borderRadius: "5px 0 0 5px" }}
                        readOnly={true}
                        defaultValue={rowData.sbf_SHORT_LINK}
                        ref={$controller.sbf_SHORT_LINK}
                      />
                      <button
                        className="btn btn-md btn-primary"
                        style={{ borderRadius: "0 5px 5px 0" }}
                        onClick={() => {
                          copyLink();
                        }}
                      >
                        복사
                      </button>

                      <button
                        className="btn btn-md btn-primary ml-2"
                        onClick={() => {
                          openLink();
                        }}
                      >
                        미리보기
                      </button>
                    </div>
                  </div>
                  <div className="col-5 m-0 p-0">
                    {(loginInfo.isAdmin || loginInfo.isForwarder) && (
                      <>
                        <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                          <div className="row m-0 p-0">
                            <div className="col-auto m-0 p-0">■ 선사 : </div>
                            <div className="col-auto m-0 p-0 ml-1">
                              {" "}
                              {compListObj.hasOwnProperty(
                                rowData.sm_PROVIDER_CODE
                              ) &&
                                compListObj[rowData.sm_PROVIDER_CODE].COMP_NM}
                            </div>
                          </div>
                          <div className="ml-3 d-flex flex-row">
                            <button
                              className="btn btn-sm btn-dark mr-2"
                              style={{ width: 180 }}
                              disabled={rowData.COMP_EBOOK_URL === ""}
                              onClick={() =>
                                window.open(
                                  rowData.COMP_EBOOK_URL,
                                  "_blank",
                                  "height=800, width=1200"
                                )
                              }
                            >
                              E-BOOK Service 이동
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              style={{ width: 140 }}
                              disabled={!rowData.sm_PROVIDER_ID}
                              onClick={() => {
                                requestEnquiryBottom(
                                  rowData.sm_PROVIDER_CODE,
                                  rowData.sm_PROVIDER_ID,
                                  "업무"
                                );
                              }}
                            >
                              업무 담당자 연결
                            </button>
                          </div>
                        </div>
                        <div className="mb-2 d-flex flex-row justify-content-between align-items-top d-none">
                          <div />
                          <div className="ml-3">
                            <button
                              className="btn btn-sm btn-primary"
                              style={{ width: 140 }}
                              disabled={!rowData.sm_PROVIDER_SALES_ID}
                              onClick={() =>
                                requestEnquiryBottom(
                                  rowData.sm_PROVIDER_CODE,
                                  rowData.sm_PROVIDER_SALES_ID,
                                  "영업"
                                )
                              }
                            >
                              영업 담당자 연결
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {(loginInfo.isAdmin || loginInfo.isConsole) && (
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>■ 포워더 : {rowData.sbf_SHIPPER}</div>
                        <div className="ml-3">
                          <button
                            className="btn btn-sm btn-primary"
                            style={{ width: 140 }}
                            onClick={() =>
                              requestEnquiryBottom(
                                rowData.sbf_USER_ID,
                                rowData.sbf_COMP_CD
                              )
                            }
                          >
                            담당자 연결
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-square bg-white">
                <div className="d-flex flex-row justify-content-end">
                  <button
                    className="btn btn-primary px-4 font-weight-bold"
                    onClick={(e) => {
                      actionSave(e);
                    }}
                  >
                    <i className="fa-regular fa-floppy-disk mr-2" />
                    예약정보 저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

//컨테이너 기본 정보
const ContainerInfoRow = React.memo((props) => {
  const {
    item,
    loginInfo,
    index,
    setIsModalOpen,
    ContainerDetailDataChange,
    deleteContainerRow,
  } = props;

  return (
    <div className="row flex-nowrap m-0 p-0" style={{ width: 1110 }}>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_TYPE}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_TYPE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="DC">Dry</option>
            <option value="RF">Reefer</option>
            <option value="TC">Tank</option>
            <option value="OT">Open Top</option>
            <option value="FR">Flat Rack</option>
            {/*<option value="Tank">Tank</option>*/}
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_SIZE}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_SIZE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="20">20`</option>
            <option value="40">40`</option>
            <option value="4H">40`HQ</option>
            <option value="45`">45`</option>
          </select>
        </div>
      </div>

      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="수량 입력"
            value={item.sfc_CNT}
            min={1}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_CNT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_MAKER_YN}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_MAKER_YN", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_DANGER_YN}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) => {
              // if (e.target.value === "Y" && dgNoteActive === 0) {
              //   note_modal_data = {
              //     title: "DG 관련서류는 아래 첨부파일에 등록해주세요",
              //     desc: "※ 관련서류: 위험물컨테이너(자동차) 수납검사증(한국해사위험 물검사원장 발행)",
              //   };
              //   dgNoteActive = 1;
              //   setIsModalOpen(true);
              // }
              ContainerDetailDataChange(index, "sfc_DANGER_YN", e.target.value);
            }}
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_EMPTY_YN}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_EMPTY_YN", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_FLEXI_YN}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) => {
              // if (e.target.value === "Y" && flexiBagActive === 0) {
              //   note_modal_data = {
              //     title: "Flexi-Bag 관련서류는 아래 첨부파일에 등록해 주세요.",
              //     desc: "※ 관련서류: MSDS, LOI, COA Membership, COA Rail Impact TestReport, Insurance Policy Certificate\n",
              //   };
              //   flexiBagActive = 1;
              //   setIsModalOpen(true);
              // }
              ContainerDetailDataChange(index, "sfc_FLEXI_YN", e.target.value);
            }}
          >
            <option value="">미선택</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>
      <div className="col-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="컨테이너 요청사항을 입력해주세요"
            defaultValue={item.sfc_MEMO}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerDetailDataChange(index, "sfc_MEMO", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onClick={() => deleteContainerRow(index, "sfc_DEL_YN", "Y", item)}
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>
    </div>
  );
});

//냉동 컨테이너 정보
const ReeferContainerInfoRow = React.memo((props) => {
  const {
    item,
    loginInfo,
    index,
    ContainerDetailDataChange,
    deleteContainerRow,
  } = props;
  return (
    <div className="row flex-nowrap m-0 p-0" style={{ width: 1110 }}>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <span className="text-center ">Reefer</span>
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <span className="text-center border-0 ">
            {sfc_SIZE[item.sfc_SIZE]}
          </span>
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="온도입력"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            value={item.sfc_TEMPERATURE}
            onChange={(e) =>
              ContainerDetailDataChange(
                index,
                "sfc_TEMPERATURE",
                e.target.value
              )
            }
          />
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            value={item.sfc_FAN_OPEN_PER}
            onChange={(e) =>
              ContainerDetailDataChange(
                index,
                "sfc_FAN_OPEN_PER",
                e.target.value
              )
            }
          >
            <option value="">미선택</option>
            <option value="0">CLOSE</option>
            <option value="100">FULL OPEN</option>
            <option value="10">10% OPEN</option>
            <option value="20">20% OPEN</option>
            <option value="30">30% OPEN</option>
            <option value="40">40% OPEN</option>
            <option value="50">50% OPEN</option>
            <option value="60">60% OPEN</option>
            <option value="70">70% OPEN</option>
            <option value="80">80% OPEN</option>
            <option value="90">90% OPEN</option>
          </select>
        </div>
      </div>
      {/*
      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onClick={() => deleteContainerRow(index, "sfc_DEL_YN", "Y", item)}
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>
      */}
    </div>
  );
});

//OUG 컨테이너 정보
const OUGContainerInfoRow = React.memo((props) => {
  const { item, loginInfo, index, ContainerOGDetailDataChange } = props;
  return (
    <div
      className="row flex-nowrap m-0 p-0 border-bottom"
      style={{ width: 1110 }}
    >
      <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_TYPE}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_TYPE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="OT">Open Top</option>
            <option value="FR">Flat Rack</option>
          </select>
        </div>
      </div>
      <div className="col-xl-2 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <select
            className="text-center form-select bg-transparent border-0"
            defaultValue={item.sfc_SIZE}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_SIZE", e.target.value)
            }
          >
            <option value="">미선택</option>
            <option value="20">20`</option>
            <option value="40">40`</option>
            <option value="4H">40`HQ</option>
            <option value="45`">45`</option>
          </select>
        </div>
      </div>

      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            defaultValue={item.sfc_LENGTH}
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_LENGTH", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            defaultValue={item.sfc_WIDTH}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_WIDTH", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            defaultValue={item.sfc_HEIGHT}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_HEIGHT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            defaultValue={item.sfc_VOID}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_VOID", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-1 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="number"
            className="bg-transparent border-0 text-center w-100"
            placeholder="입력"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            defaultValue={item.sfc_WEIGHT}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_WEIGHT", e.target.value)
            }
          />
        </div>
      </div>
      <div className="col-xl-3 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <input
            type="text"
            className="bg-transparent border-0 text-center w-100"
            placeholder="요청사항을 입력해주세요"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            defaultValue={item.sfc_MEMO}
            onChange={(e) =>
              ContainerOGDetailDataChange(index, "sfc_MEMO", e.target.value)
            }
          />
        </div>
      </div>
      {/*      <div className="col-1 flex-grow-1 text-center m-0 p-0 d-flex flex-column">
        <div className="bg-light p-2 flex-fill d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-sm text-danger"
            disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
            onClick={() =>
              ContainerOGDetailDataChange(index, "sfc_DEL_YN", "Y", item)
            }
          >
            <i className="fa-solid fa-minus" />
          </button>
        </div>
      </div>*/}
    </div>
  );
});
