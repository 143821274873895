/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import CommonNav from "../main/CommonNav";
import PostCarousel from "./PostCarousel";
import PostContent from "./PostContent";
import Footer from "../main/Footer";
import { useLocation } from "react-router-dom";

export default function PostMain(props) {
  return (
    <>
      <CommonNav />
      <main>
        <div
          id="post_main"
          className="super_container d-flex flex-column"
          style={{ minHeight: "100vh" }}
        >
          <PostCarousel />
          <PostContent />
        </div>
      </main>
      <Footer />
    </>
  );
}
