import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "ShipperInfoManageEdit";
const infos = [
  {
    filed: "COMP_C_NUM",
    infoName: "부호",
    placeholder: "부호를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "COMP_NM",
    infoName: "상호명",
    placeholder: "상호명을 입력해주세요",
    isEdit: true,
    isRequired: true,
  },
  {
    filed: "COMP_NUM",
    infoName: "사업자등록번호",
    placeholder: "",
    isEdit: false,
    isRequired: true,
  },
  {
    filed: "HOME_PAGE",
    infoName: "홈페이지",
    placeholder: "홈페이지 주소를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "PRODUCT_MAIN",
    infoName: "주요품목",
    placeholder: "주요품목을 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "TRADE_COUNTRY",
    infoName: "교역국가",
    placeholder: "교역국가를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "COMP_CEO_NM",
    infoName: "대표자명",
    placeholder: "대표자명을 입력해주세요",
    isEdit: true,
    isRequired: true,
  },
  {
    filed: "COMP_TEL",
    infoName: "대표전화",
    placeholder: "대표전화를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "COMP_FAX",
    infoName: "팩스번호",
    placeholder: "팩스번호를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
  {
    filed: "COMP_ADDR",
    infoName: "주소",
    placeholder: "주소를 입력해주세요",
    isEdit: true,
    isRequired: false,
  },
];

export default function ShipperInfoManageEdit() {
  const [compInfo, setCompInfo] = useState({});

  const controller = {
    COMP_C_NUM: useRef(),
    COMP_NM: useRef(),
    HOME_PAGE: useRef(),
    PRODUCT_MAIN: useRef(),
    TRADE_COUNTRY: useRef(),
    COMP_CEO_NM: useRef(),
    COMP_TEL: useRef(),
    COMP_FAX: useRef(),
    COMP_ADDR: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_SELECT_COMP,
      _mClassName,
      (b, n) => {
        setCompInfo(n);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SHIPPER_MANAGE_SELECT_COMP,
        _mClassName
      );
    };
  }, []);

  const checkValidate = () => {
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i];
      if (info.isEdit && info.isRequired) {
        if (
          controller.hasOwnProperty(info.filed) &&
          controller[info.filed].current.value.trim() === ""
        ) {
          codeList.Modal.current.alert(info.placeholder, () => {});
          return false;
        }
      }
    }
    return true;
  };

  const actionSave = () => {
    if (compInfo === null) {
      codeList.Modal.current.alert("화주 미선택 상태입니다.");
      return;
    }
    if (!checkValidate()) return;
    let updateData = {
      ...compInfo,
    };
    for (let key in controller) {
      updateData[key] = controller[key].current.value;
    }

    codeList.Modal.current.confirm(
      `업체코드 [${compInfo.COMP_CD}]의 정보를 수정저장하시겠습니까?\n 확인을 누를 시 현재 입력된 데이터가 저장됩니다.`,
      (ret) => {
        if (ret) {
          requestUserUpdate(updateData);
        }
      }
    );
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_search_shipper/JS_gbts_comp_shipper_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "화주기업정보가 정상적으로 수정되었습니다."
          );
          socket.fireLocalEventListener(
            MsgIDList.EVENT_SHIPPER_MANAGE_DATA_UPDATE,
            _mClassName,
            newData
          );
        } else {
          codeList.Modal.current.alert(
            "요청처리중 오류가 발생하였습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  const InfoEditRow = (data) => {
    const { filed, infoName, placeholder, isEdit } = data;

    let inputProperty = {
      type: "text",
      className: "form-control m-0 bg-light",
      ref: controller[filed],
      placeholder: placeholder,
    };
    if (compInfo.hasOwnProperty("COMP_CD")) {
      inputProperty.defaultValue = compInfo[filed];
    }

    let str = compInfo ? compInfo[filed] : "-";
    if (compInfo.hasOwnProperty("COMP_CD") && filed === "COMP_NUM") {
      str = util.getAutoHyphenBizID(compInfo[filed]);
    }

    return (
      <div className="row m-0 py-3 border-bottom">
        <div className="col-4 m-0 p-0 font-weight-bold">{infoName}</div>
        <div className="col-8 m-0 p-0">
          {isEdit ? <input {...inputProperty} /> : <p>{str}</p>}
        </div>
      </div>
    );
  };

  return (
    <div className="card p-3">
      <div className="mb-3">
        <div className=" d-flex flex-row justify-content-between align-items-center border-bottom">
          <h6 className="font-weight-bold text-info pb-2">
            {compInfo.hasOwnProperty("COMP_CD") ? compInfo.COMP_NM : "업체명"}{" "}
            프로필
          </h6>
          <h6 className="mb-0 pb-2 cursor_pointer">&times;</h6>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center py-5 border-bottom">
          <h3 className="font-weight-bold mb-3">
            {compInfo.hasOwnProperty("COMP_CD") ? compInfo.COMP_NM : "업체명"}
          </h3>
          <button className="btn btn-sm btn-primary">
            사용자와 대화하기
            <i className="fa-solid fa-arrow-down ml-2" />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
          <h5 className="mb-0 font-weight-bold">업체 정보</h5>
          <h5 className="mb-0 cursor_pointer">
            <i className={"fa-solid fa-chevron-up"} />
          </h5>
        </div>
        <div>
          {infos.map((info, index) => {
            return <InfoEditRow key={info.filed} {...info} index={index} />;
          })}
        </div>
      </div>
      <div className="mb-3">
        <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
          <h5 className="mb-0 font-weight-bold">사용자보기</h5>
          <h5 className="mb-0 cursor_pointer">
            <i className={"fa-solid fa-chevron-down"} />
          </h5>
        </div>
        <div className="p-1 mt-2 bg-light">
          -실제 사용자정보는 로지톡 기업화주 검색시 조회됩니다.
        </div>
      </div>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => actionSave()}>
          저장하기
        </button>
      </div>
    </div>
  );
}
