import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import $ from "jquery";
import axios from "axios";

import CommonNav from "../../../CommonNav";
import BannerMarketing from "../../BannerMarketing";
import ScheduleFCLTable from "./ScheduleFCLTable";
import Footer from "../../../Footer";
import FreightCostModal from "./FreightCostModal";

import Loading from "../../../../lib/Loading";
import MMSI from ".././mmsiList";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import {
  BCompSelectOption,
  LocationSeaSelectOption,
} from "../../../../lib/CommonUI";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const mmsi = new MMSI();

const _mClassName = "ScheduleFCL";
const shUUID = util.makeUUIDv4();

let loginInfo = null;
let comp_list = [];
let comp_list_obj = {};
let currency_rate = null;

let schedule_list = [];
let rowsPerPage = 10;
let page = 0;
let pageSize = 0;
let totalCount = 0;
let totalPageCount = 0;
let isPromoState = null;

let filters = {
  sm_FCL_LCL: "FCL",
  sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
  sm_POL_CODE: "",
  sm_POD_CODE: "",
  sm_PROMO_YN: "",
  ORIGIN: "",
  DEST: "",
  SEARCH_COMP: "",
};
let modal_data = null;

export default function ScheduleFCL() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduleLoad, setIsScheduleLoad] = useState(true);
  const [isFreightCostModalOpen, setIsFreightCostModalOpen] = useState(false);
  const [searchCodeList, setSearchCodeList] = useState([]);
  const [selectedComp, setSelectedComp] = useState([]);
  const params = useParams();
  const location = useLocation();
  const $comp_select = useRef();
  const $pol_select = useRef();
  const $pod_select = useRef();
  const $promo_check = useRef();

  useEffect(() => {
    if (location) {
      if (location.state && location.state.hasOwnProperty("type")) {
        if (location.state.type === "promo") {
          filters = {
            ...filters,
            ...location.state,
            sm_PROMO_YN: "Y",
          };
          isPromoState = { type: "promo" };
          $promo_check.current.checked = true;
        }
        if (location.state.hasOwnProperty("sm_POL_CODE")) {
          filters = {
            ...filters,
            sm_POL_CODE: location.state.sm_POL_CODE,
            sm_POD_CODE: location.state.sm_POD_CODE,
            ORIGIN: location.state.ORIGIN,
            DEST: location.state.DEST,
          };
        }
      }
    }
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (userData.ret) {
        loginInfo = userData["returnData"][0];
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          if (n.ret) {
            loginInfo = n["returnData"][0];
          }
        }
      }
    );

    if (codeList.codeCountryFullName.length === 0) {
      socket.addLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName,
        (oldV, newV) => {
          setSearchCodeList(newV);
        }
      );
    } else {
      setSearchCodeList(codeList.codeCountryFullName);
    }
    let id = "";
    if (params && params.hasOwnProperty("id")) {
      id = params.id;
    }

    requestCurrencyRate((newData) => {
      currency_rate = JSON.parse(newData["returnData"][0].cr_price_data);
      requestShipCompList((newData) => {
        comp_list = [...newData["returnData"]];
        newData["returnData"].forEach((item) => {
          comp_list_obj[item.blFWDNO] = item;
        });
        setIsNeedUpdate((prev) => !prev);
        requestUserSelect(id);
      });
    });
    return () => {
      rowsPerPage = 10;
      page = 0;
      pageSize = 0;
      totalCount = 0;
      totalPageCount = 0;
      filters = {
        sm_FCL_LCL: "FCL",
        sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
        sm_POL_CODE: "",
        sm_POD_CODE: "",
        sm_PROMO_YN: "",
        ORIGIN: "",
        DEST: "",
        SEARCH_COMP: "",
      };

      modal_data = null;
      isPromoState = null;
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
        _mClassName
      );
    };
  }, []);

  // get 국가별 통화 단위
  const requestCurrencyRate = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/currency_rate/JS_currency_rate_select.php",
      msgID: msgID,
      data: [],
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // get 선사 목록
  const requestShipCompList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/b_logo/JS_b_logo_select.php",
      msgID: msgID,
      data: [],
      USE_YN: "Y",
      blSchDisplay: "Y",
      blCompType: "F",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // get 스케줄 목록
  const requestUserSelect = (id) => {
    setIsScheduleLoad(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_full.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [
        {
          field: "sm_DOC_CLOSE",
          dir: "asc",
        },
      ],
      ...filters,
      sp_DISPLAY: 0,
    };
    let log_data = {};
    if (id) {
      log_data = {
        shClick: 0,
        shClickFor: "share",
      };
      log_data.shClick = 0;
      log_data.shClickFor = "share";
      socketMsg.sm_UID = id;
    }
    handleUseHistoryData(log_data);

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          schedule_list = newData["returnData"];
          pageSize = newData.pageSize;
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            schedule_list.length > 0
              ? parseInt(
                  parseInt(newData.pageSize, rowsPerPage) / rowsPerPage,
                  rowsPerPage
                ) +
                (parseInt(newData.pageSize, rowsPerPage) % rowsPerPage > 0
                  ? 1
                  : 0)
              : 0;

          setIsScheduleLoad(false);
        }
      }
    });
  };

  // 스케줄 검색필터 변경
  const changedFilers = (data) => {
    // if (isScheduleLoad) {
    //   codeList.Modal.current.alert("스케줄 조회중입니다.");
    //   return;
    // }

    filters = {
      ...filters,
      ...data,
    };

    page = 0;
    if (data.hasOwnProperty("sm_PROMO_YN")) {
      requestUserSelect();
    }
  };

  //스케줄 검색 요청
  const handleSearchSchedule = () => {
    page = 0;
    requestUserSelect();
  };

  //검색필터 초기화
  const handleResetFilters = () => {
    $pol_select.current.clearAll();
    $pod_select.current.clearAll();
    $promo_check.current.checked = false;
    filters = {
      sm_FCL_LCL: "FCL",
      sm_DOC_CLOSE: util.todayDate("YYYY-MM-DD"),
      sm_POL_CODE: "",
      sm_POD_CODE: "",
      sm_PROMO_YN: "",
      ORIGIN: "",
      DEST: "",
      SEARCH_COMP: "",
    };
    page = 0;
    isPromoState = null;
    setSelectedComp([]);

    if (params && params.hasOwnProperty("id")) {
      const replaceUrl = `${window.location.origin}/ScheduleFCL`;
      window.location.replace(replaceUrl);
    }

    requestUserSelect();
  };

  // 항구코드변환
  function switchPortsCode(val) {
    const codes = {
      CNDAL: "CNDLC",
      CNNBO: "CNNGB",
      CNNJI: "CNNKG",
      CNSGH: "CNSHA",
      CNQIN: "CNTAO",
      CNXGA: "CNTXG",

      CNSTG: "CNSWA",
      CNYAT: "CNYNT",
      CNZJI: "CNZJG",
      JPAKI: "JPAXT",
      JPNGY: "JPNGO",
      JPOIP: "JPOIT",
      JPSDJ: "JPSGM",
      VNHAP: "VNHPH",
    };
    if (codes.hasOwnProperty(val)) {
      val = codes[val];
    }
    return val;
  }

  // 남성해운 운임 및 스케줄 디테일
  const getCostNSS = (callback) => {
    let data = {
      bukrs: "1000",
      inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
      inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inptype: "D",
    };

    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceNSS", data)
      .then((response) => {
        console.log("response", response);

        let data = null;
        if (
          response.data.hasOwnProperty("dlt_preCol1") &&
          response.data["dlt_preCol1"].length > 0
        ) {
          data = [
            ...response.data["dlt_preCol1"],
            ...response.data["dlt_preCol2"],
          ];
        }

        callback(data);
      })
      .catch((error) => {
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );
        let replaceUrl = `${window.location.origin}/ScheduleFCL`;
        if (modal_data) {
          replaceUrl = replaceUrl + `/${modal_data.sm_UID}`;
        }

        window.location.replace(replaceUrl);

        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };
  const getScheduleDetailNSS = (callback) => {
    let selectScheDetailData = {
      inpdiv: "F",
      inpdiv2: "S",
      inplpt: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inpdpt: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inpmon: modal_data.sm_DEPARTURE_DATE
        .replace(/[^0-9]/gi, "")
        .substring(0, 6), //해당항차 출발 년월

      inptsd: "D",
      inpvvd: modal_data.sm_VOY, //항차
    };

    axios
      .post(
        "https://talktalk.gbts.co.kr:6100/selectScheDetail",
        selectScheDetailData
      )
      .then((response) => {
        let data = null;

        if (
          response.data.hasOwnProperty("dlt_scheduleDetailList") &&
          response.data["dlt_scheduleDetailList"].length > 0
        ) {
          data = { ...response.data["dlt_scheduleDetailList"][0] };
          data.VSL_CLOSE_F_desc = data.VSL_CLOSE_F === "F" ? "종료" : "가능";
        }
        callback && callback(data);
      })
      .catch((error) => {
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );
        setIsLoading(false);
        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };

  // 동영해운 운임 GET
  const getCostPCS = (cb) => {
    let data = {
      bukrs: "1000",
      inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
      inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
      inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
      inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
      inptype: "D",
    };

    axios
      .post("https://talktalk.gbts.co.kr:6500/getPricePCS", data)
      .then((response) => {
        let data = null;
        if (
          response.data.hasOwnProperty("dlt_preCol1") &&
          response.data["dlt_preCol1"].length > 0
        ) {
          data = [
            ...response.data["dlt_preCol1"],
            ...response.data["dlt_preCol2"],
          ];
        }

        if (cb) cb(data);
      })
      .catch((error) => {
        console.log("error", error);
        window.alert(
          "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
        );
        let replaceUrl = `${window.location.origin}/ScheduleFCL`;

        window.location.replace(replaceUrl);

        // console.error(error);
      })
      .finally((data) => {
        // console.log("finally", data);
      });
  };

  // 장금상선 운임 get
  const getCostSKR = (cb) => {
    //장금상선
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceSinokor", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // 흥아라인 운임 get
  const getCostHAS = (cb) => {
    //장금상선
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceHeungaline", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 동진상선 운임 요청
  const getPriceDJShip = (cb) => {
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceDJShip", {
        inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
      })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //운임안내 모달 open
  const openCostInfoModal = (item) => {
    if (!loginInfo || !util.checkCompGB(loginInfo)) {
      codeList.Modal.current.alert(
        "포워더용 서비스입니다. \n화주는 로지웍에서 스케줄 및 운임조회가 가능합니다."
      );
      return;
    }

    setIsLoading(true);
    modal_data = {
      COMP_CD: item.COMP_CD,
      COMP_LOGO: item.COMP_LOGO,
      sm_IDX: item.sm_IDX,
      sm_FWD_NO: item.sm_FWD_NO,
      sm_FWD_NM: item.sm_FWD_NM,
      sm_FCL_LCL: item.sm_FCL_LCL,
      sm_VESSEL: item.sm_VESSEL,
      sm_VOY: item.sm_VOY,
      sm_DOC_CLOSE: item.sm_DOC_CLOSE,
      sm_ORIGIN: item.sm_ORIGIN,
      sm_DEST: item.sm_DEST,
      sm_POL_CODE: item.sm_POL_CODE,
      sm_POD_CODE: item.sm_POD_CODE,
      sm_POL_NAME: item.sm_POL_NAME,
      sm_POD_NAME: item.sm_POD_NAME,
      sm_PROMO_YN: item.sm_PROMO_YN,
      sm_PROMO_MEMO: item.sm_PROMO_MEMO,
      sm_DEPARTURE_DATE: item.sm_DEPARTURE_DATE,
      sm_ARRIVE_DATE: item.sm_ARRIVE_DATE,
    };

    if (modal_data) {
      switch (modal_data.sm_FWD_NO) {
        case "NSS":
          getCostNSS((data) => {
            modal_data["SurchargeInfo"] = data;
            getScheduleDetailNSS((data) => {
              modal_data["bookInfo"] = data;
              requestCostOCF();
            });
          });
          break;
        case "NSSL":
          getCostNSS((data) => {
            modal_data["SurchargeInfo"] = data;
            getScheduleDetailNSS((data) => {
              modal_data["bookInfo"] = data;
              requestCostOCF();
            });
          });
          break;
        case "PCS":
          getCostPCS((data) => {
            modal_data["SurchargeInfo"] = data;
            requestCostOCF();
          });
          break;
        case "SKR":
          getCostSKR((data) => {
            modal_data.snkCost = data;
            requestCostOCF();
          });
          break;
        case "HAS":
          getCostHAS((data) => {
            modal_data.snkCost = data;
            requestCostOCF();
          });
          break;
        case "DJS":
          getPriceDJShip((data) => {
            modal_data.CoastList = data.CoastList;
            requestCostOCF();
          });
          break;
        default:
          requestCostOCF();
          break;
      }
    } else {
      codeList.Modal.current.alert(
        "오류로인해 데이터를 받아오지 못했습니다. 다시 시도해주세요"
      );
      setIsLoading(false);
    }
  };

  // OCF 운임정보 get
  const requestCostOCF = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_fcl_ocf_select.php",
      msgID: msgID,
      // isGrade: 1,
      // COMP_LVL: "A",
      SP_POL: modal_data.sm_POL_CODE,
      SP_POD: modal_data.sm_POD_CODE,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = {};
          newData["comp_cost"].forEach((item) => {
            data[item.SP_SHIP_CODE] = {
              SP_20_INCH: Number(item.SP_20_INCH),
              SP_40_INCH: Number(item.SP_40_INCH),
            };
          });

          modal_data["comp_cost"] = data;
          modal_data["default_cost"] = newData["default_cost"][0] || {
            SP_20_INCH: 0,
            SP_40_INCH: 0,
          };

          let ocf = data[modal_data.sm_FWD_NO] || modal_data["default_cost"];

          modal_data["SP_20_INCH"] = ocf["SP_20_INCH"];
          modal_data["SP_40_INCH"] = ocf["SP_40_INCH"];

          setIsFreightCostModalOpen(true);
          setIsLoading(false);
        }
      }
    });
  };

  //동일구간 스케줄 요청
  const requestCompareSchedule = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_fcl_compare_info.php",
      msgID: msgID,
      sm_DOC_CLOSE: modal_data.sm_DOC_CLOSE,
      sm_POL_CODE: modal_data.sm_POL_CODE,
      sm_POD_CODE: modal_data.sm_POD_CODE,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData["returnData"]);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생하였습니다. 다시 시도해주세요."
          );
          setIsLoading(false);
        }
      }
    });
  };

  // Compare Prepaid, Collect Cost (NSS , HAS ,SKR, PCS)
  const requestCompareScheduleCost = (callback) => {
    axios
      .post("https://talktalk.gbts.co.kr:6500/getPriceForAll", {
        pol: modal_data.sm_POL_CODE,
        pod: modal_data.sm_POD_CODE,
        //--------------------------------
        bukrs: "1000",
        inpetas: modal_data.sm_DEPARTURE_DATE.replace(/[^0-9]/gi, ""),
        inppod: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inppodds: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inppol: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppolds: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppor: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppords: switchPortsCode(modal_data.sm_POL_CODE), //출발
        inppvy: switchPortsCode(modal_data.sm_POD_CODE) , //도착
        inppvyds: switchPortsCode(modal_data.sm_POD_CODE), //도착
        inptype: "D",
      })
      .then((response) => {
        callback && callback(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  // KRW 합계 및 최소값 계산
  const calcCost = (compareList, price) => {
    let minCost = {};

    for (let i = 0; i < price.length; i++) {
      let item = price[i];
      let key = item.Company;

      const _prepaid = item.aggregate.Prepaid;
      const _collect = item.aggregate.Collect;
      let prepaid = [];
      let collect = [];

      let pre20Total = 0;
      let pre40Total = 0;
      let col20Total = 0;
      let col40Total = 0;

      for (let key in _prepaid) {
        let con20 = _prepaid[key]["Standard Container 20GP"];
        //+ _prepaid[key]["wfglc"];
        let con40 = _prepaid[key]["Standard Container 40GP"];
        //+_prepaid[key]["wfglc"];

        prepaid.push({
          key: key,
          container20: con20,
          container40: con40,
        });

        if (con20 > 0) {
          if (!minCost.hasOwnProperty([`prepaid_20_${key}`])) {
            minCost[`prepaid_20_${key}`] = con20;
          } else {
            if (minCost[`prepaid_20_${key}`] > con20) {
              minCost[`prepaid_20_${key}`] = con20;
            }
          }
        }
        if (con40 > 0) {
          if (!minCost.hasOwnProperty([`prepaid_40_${key}`])) {
            minCost[`prepaid_40_${key}`] = con40;
          } else {
            if (minCost[`prepaid_40_${key}`] > con40) {
              minCost[`prepaid_40_${key}`] = con40;
            }
          }
        }

        if (currency_rate.hasOwnProperty(key) && currency_rate[key] !== null) {
          pre20Total += con20 * currency_rate[key];
          pre40Total += con40 * currency_rate[key];
        }

        if (key === "KRW") {
          pre20Total += con20;
          pre40Total += con40;
        }
      }
      for (let key in _collect) {
        let con20 = _collect[key]["Standard Container 20GP"];
        //+_collect[key]["wfglc"];
        let con40 = _collect[key]["Standard Container 40GP"];
        //+_collect[key]["wfglc"];

        collect.push({
          key: key,
          container20: con20,
          container40: con40,
        });

        if (con20 > 0) {
          if (!minCost.hasOwnProperty([`collect_20_${key}`])) {
            minCost[`collect_20_${key}`] = con20;
          } else {
            if (minCost[`collect_20_${key}`] > con20) {
              minCost[`collect_20_${key}`] = con20;
            }
          }
        }
        if (con40 > 0) {
          if (!minCost.hasOwnProperty([`collect_40_${key}`])) {
            minCost[`collect_40_${key}`] = con40;
          } else {
            if (minCost[`collect_40_${key}`] > con40) {
              minCost[`collect_40_${key}`] = con40;
            }
          }
        }

        if (currency_rate.hasOwnProperty(key) && currency_rate[key] !== null) {
          col20Total += con20 * currency_rate[key];
          col40Total += con40 * currency_rate[key];
        }

        if (key === "KRW") {
          col20Total += con20;
          col40Total += con40;
        }
      }

      let ocf20 = compareList[key]["SP_20_INCH"];
      let ocf40 = compareList[key]["SP_40_INCH"];

      pre20Total += ocf20 * currency_rate["USD"];
      pre40Total += ocf40 * currency_rate["USD"];

      if (!minCost.hasOwnProperty("SP_20_INCH")) {
        minCost.SP_20_INCH = ocf20;
      } else {
        if (minCost.SP_20_INCH > ocf20) {
          minCost.SP_20_INCH = ocf20;
        }
      }
      if (!minCost.hasOwnProperty("SP_40_INCH")) {
        minCost.SP_40_INCH = ocf40;
      } else {
        if (minCost.SP_40_INCH > ocf40) {
          minCost.SP_40_INCH = ocf40;
        }
      }

      pre20Total = Math.round(pre20Total);
      pre40Total = Math.round(pre40Total);

      col20Total = Math.round(col20Total);
      col40Total = Math.round(col40Total);

      let allTotal20 = pre20Total + col20Total;
      let allTotal40 = pre40Total + col40Total;

      if (pre20Total > 0) {
        if (!minCost.hasOwnProperty("pre20Total")) {
          minCost.pre20Total = pre20Total;
        } else {
          if (minCost.pre20Total > pre20Total) {
            minCost.pre20Total = pre20Total;
          }
        }
      }

      if (pre40Total > 0) {
        if (!minCost.hasOwnProperty("pre40Total")) {
          minCost.pre40Total = pre40Total;
        } else {
          if (minCost.pre40Total > pre40Total) {
            minCost.pre40Total = pre40Total;
          }
        }
      }

      if (col20Total > 0) {
        if (!minCost.hasOwnProperty("col20Total")) {
          minCost.col20Total = col20Total;
        } else {
          if (minCost.col20Total > col20Total) {
            minCost.col20Total = col20Total;
          }
        }
      }

      if (col40Total > 0) {
        if (!minCost.hasOwnProperty("col40Total")) {
          minCost.col40Total = col40Total;
        } else {
          if (minCost.col40Total > col40Total) {
            minCost.col40Total = col40Total;
          }
        }
      }
      if (allTotal20 > 0) {
        if (!minCost.hasOwnProperty("allTotal20")) {
          minCost.allTotal20 = allTotal20;
        } else {
          if (minCost.allTotal20 > allTotal20) {
            minCost.allTotal20 = allTotal20;
          }
        }
      }

      if (allTotal40 > 0) {
        if (!minCost.hasOwnProperty("allTotal40")) {
          minCost.allTotal40 = allTotal40;
        } else {
          if (minCost.allTotal40 > allTotal40) {
            minCost.allTotal40 = allTotal40;
          }
        }
      }

      compareList[key].pre20Total = pre20Total || "-";
      compareList[key].pre40Total = pre40Total || "-";

      compareList[key].col20Total = col20Total || "-";
      compareList[key].col40Total = col40Total || "-";

      compareList[key].allTotal20 = allTotal20 || "-";
      compareList[key].allTotal40 = allTotal40 || "-";

      compareList[key].prepaid = prepaid;
      compareList[key].collect = collect;
    }
    return { compareList, minCost };
  };

  // 동일구간 운임비교 요청
  const handleCompareCostData = (callback) => {
    let dataList = {};
    let price = {};
    requestCompareScheduleCost((data) => {
      modal_data.uuid = data.uuid;
      price = data.PriceData;

      for (let i = 0; i < price.length; i++) {
        let item = price[i];
        let key = item.Company;
        dataList[key] = {
          isExistSche: false,
          sm_FWD_NO: key,
          blLOGO: comp_list_obj[key].blLOGO,
          blCompNM: comp_list_obj[key].blCompNM,
          blCompNM_KO: comp_list_obj[key].blCompNM_KO,
        };

        if (modal_data.comp_cost.hasOwnProperty(key)) {
          dataList[key] = {
            ...dataList[key],
            ...modal_data.comp_cost[key],
          };
        } else {
          dataList[key] = {
            ...dataList[key],
            ...modal_data.default_cost,
          };
        }
      }

      requestCompareSchedule((data) => {
        if (Array.isArray(data) && data.length > 0) {
          data.forEach((item) => {
            let key = item.sm_FWD_NO;
            dataList[key] = {
              ...dataList[key],
              isExistSche: true,
              sm_FWD_NO: item.sm_FWD_NO,
              sm_FCL_LCL: item.sm_FCL_LCL,
              sm_VESSEL: item.sm_VESSEL,
              sm_VOY: item.sm_VOY,
              sm_DOC_CLOSE: item.sm_DOC_CLOSE,
              sm_CARGO_CLOSE: item.sm_CARGO_CLOSE,
              sm_DEPARTURE_DATE: item.sm_DEPARTURE_DATE,
              sm_ARRIVE_DATE: item.sm_ARRIVE_DATE,
              blLOGO: comp_list_obj[key].blLOGO,
              blCompNM: comp_list_obj[key].blCompNM,
              blCompNM_KO: comp_list_obj[key].blCompNM_KO,
            };
          });
        }

        let { compareList, minCost } = calcCost(dataList, price);
        compareList = Object.values(compareList);
        modal_data["compareList"] = compareList;
        modal_data["minCost"] = minCost;
        callback && callback(compareList);
      });
    });
  };

  //선박추적 팝업 열기
  const handleOpenOceanLookV3 = (sm_IDX, sm_VESSEL) => {
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return;
    }

    let mmsiNum = mmsi.getMMSI(sm_VESSEL);

    if (mmsiNum !== "") {
      let oceanlookWebToken =
        "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

      //"https://map.oceanlook.net/#/map_popup?token="
      let url =
        "https://www.oceanlook.net/v3/popup?token=" +
        oceanlookWebToken +
        "&mmsi=" +
        mmsiNum;
      let popup = "oceanlookForm";
      let options =
        "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
      window.open(url, popup, options);
    } else {
      codeList.Modal.current.alert("추적 가능한 선박이 아닙니다.");
      return false;
    }
    //}
  };

  const scheduleUseLog = (type, isClick, data) => {
    handleUseHistoryData({
      shIDX: data.sm_IDX,
      shType: data.sm_FCL_LCL,
      shDate: "",
      shORIGIN: data.sm_POL_CODE,
      shDEST: data.sm_POD_CODE,
      shClick: isClick,
      shClickFor: type,
    });
  };

  const handleUseHistoryData = (data) => {
    let log_data = {
      shUUID: shUUID,
      shIDX: "", //스케줄 No
      shDate: filters.sm_DOC_CLOSE, // 검색일자(출발일)
      shType: filters.sm_FCL_LCL, // FCL,LCL,항공
      shORIGIN: filters.sm_POL_CODE, //출발지 POL챈ㅅ
      shDEST: filters.sm_POD_CODE, //도착지 POD
      shClick: 0, //클릭여부
      shClickFor: "", //클릭 분류
      shMethod: window.location.href, //발생 URL
      ...data,
    };
    if (loginInfo && loginInfo.hasOwnProperty("user_id")) {
      log_data["shCOMPCD"] = loginInfo.comp_cd;
      log_data["shUSERID"] = loginInfo.user_id;
    }

    INSERT_SCHEDULE_USE_HISTORY(log_data);
  };

  //사용자 접근 경로 데이터
  const INSERT_SCHEDULE_USE_HISTORY = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule/JS_schedule_select_log_insert.php",
      msgID: msgID,
      data: {},
      ...data,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  //페이지 이동
  const handleChangePage = (val) => {
    page = val;
    let top = document.querySelector("#banner_mark")?.clientHeight;
    window.scrollTo(0, top);
    requestUserSelect();
  };

  // 담당자 연결
  const requestEnquiry = (item, callback) => {
    codeList.Modal.current.confirm(
      `FCL 스케줄 [${item.sm_POL_NAME} - ${item.sm_POD_NAME}]에 대해 담당자에게 문의하시겠습니까?`,
      (ret) => {
        if (ret) {
          if (!loginInfo) {
            codeList.Modal.current.alert("로그인이 필요한 서비스 입니다.");
            return;
          }

          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/schedule/JS_send_inquiry_mail.php",
            msgID: msgID,
            data: {},
            ...modal_data,
            ...loginInfo,
            request_data: util.todayDate("YYYY-MM-DD"),
          };

          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                // todo 담당자 연결 일시정지 추후 재확인 필요
                localStorage.setItem(
                  "enquiryManager",
                  "Z0001|hskim@gbts.co.kr"
                );
                window.open("/LogiTalk", "LogiTalk", "_blank");
                callback && callback();
              } else {
                codeList.Modal.current.alert(
                  "오류가 발생하였습니다. 다시 시도해주세요"
                );
              }
            }
          });
        } else {
          callback && callback();
        }
      }
    );
  };

  return (
    <>
      {isLoading && <Loading />}

      {isFreightCostModalOpen && modal_data !== null && (
        <FreightCostModal
          loginInfo={loginInfo}
          modal_data={modal_data}
          setIsFreightCostModalOpen={setIsFreightCostModalOpen}
          requestEnquiry={requestEnquiry}
          scheduleUseLog={scheduleUseLog}
          handleCompareCostData={handleCompareCostData}
        />
      )}

      <CommonNav />
      <main className={`py-5 mh-90vh bg-schedule`}>
        <BannerMarketing />
        <div className="container margin_30 mt-0">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="rounded shadow-sm">
                <div className="row m-0 p-0 rounded-top overflow-hidden text-center m-0">
                  <Link
                    to={"/ScheduleLCL"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark  text-decoration-none"
                    state={isPromoState}
                  >
                    해상 LCL
                  </Link>
                  <Link
                    to={"/ScheduleFCL"}
                    className="col-4 m-0 p-0 py-2 bg-freiscop text-white border-left border-right text-decoration-none"
                    state={isPromoState}
                  >
                    <span className="font-size-09">
                      <i className="fa-solid fa-cart-flatbed" />
                    </span>{" "}
                    해상 FCL
                  </Link>
                  <Link
                    to={"/ScheduleAIR"}
                    className="col-4 m-0 p-0 py-2 bg-light text-dark text-decoration-none"
                    state={isPromoState}
                  >
                    항공
                  </Link>
                </div>
                <div className="border-top rounded-bottom bg-white">
                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">
                        서류마감일
                      </p>
                      <input
                        type="date"
                        className="w-100 border-top-0 border-left-0 border-right-0 border-bottom py-1 bg-white"
                        min={util.todayDate("YYYY-MM-DD")}
                        defaultValue={filters.sm_DOC_CLOSE}
                        onChange={(e) => {
                          changedFilers({ sm_DOC_CLOSE: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">출발지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pol_select}
                        options={searchCodeList}
                        placeholder="출발지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            ORIGIN: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POL_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilers(data);
                        }}
                        {...LocationSeaSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">도착지</p>
                      <Select
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        ref={$pod_select}
                        options={searchCodeList}
                        placeholder="도착지를 선택해주세요"
                        onChange={(val) => {
                          let data = {
                            DEST: val.length > 0 ? val[0]["CityName"] : "",
                            sm_POD_CODE:
                              val.length > 0 ? val[0]["CustomCode"] : "",
                          };
                          changedFilers(data);
                        }}
                        {...LocationSeaSelectOption}
                      />
                    </div>
                  </div>

                  <div className="row m-0 p-0">
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <p className="font-size-09 font-weight-bold">선사 선택</p>
                      <Select
                        ref={$comp_select}
                        placeholder="-선사 선택-"
                        className="w-100 p-0 m-0 border-top-0 border-left-0 border-right-0 border-bottom"
                        values={selectedComp}
                        options={comp_list}
                        onChange={(value) => {
                          if (value.length < 1) {
                            changedFilers({ SEARCH_COMP: "" });
                            return;
                          }

                          setSelectedComp(value);

                          let compKeys = [];
                          for (let i = 0; i < value.length; i++) {
                            let comp = value[i];
                            compKeys.push(comp.blFWDNO);
                          }

                          compKeys = compKeys.join("|");
                          changedFilers({ SEARCH_COMP: compKeys });
                        }}
                        contentRenderer={({ props, state, methods }) => {
                          let str = "";
                          if (state.values.length > 0) {
                            str = `+ ${state.values.length} 업체`;
                          } else {
                            str = props.placeholder;
                          }
                          return <div key="count">{str}</div>;
                        }}
                        onClearAll={() => {
                          setSelectedComp([]);
                        }}
                        {...BCompSelectOption}
                      />
                    </div>
                    <div className="col-12 col-lg-4 m-0 p-2 px-lg-3">
                      <label className="container_check m-0 mb-2 mb-lg-0 mr-lg-3 p-0">
                        <input
                          type="checkbox"
                          className="position-static"
                          defaultChecked={filters.sm_PROMO_YN === "Y"}
                          ref={$promo_check}
                          onChange={(e) => {
                            isPromoState = e.target.checked
                              ? { type: "promo" }
                              : null;

                            changedFilers({
                              sm_PROMO_YN: e.target.checked ? "Y" : "",
                            });
                          }}
                        />
                        <span className="checkmark" />
                        <span className="ml-3">프로모션 필터</span>
                      </label>
                    </div>
                    {selectedComp.length > 0 && (
                      <div className="col-lg-12 p-0 m-0">
                        <div className="d-flex flex-row flex-wrap mx-2 mb-3 mb-lg-2 bg-light rounded">
                          ???
                          {selectedComp.map((item) => {
                            return (
                              <div key={item.blIDX}>
                                <div
                                  role="option"
                                  aria-selected="false"
                                  tabIndex="-1"
                                  className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light pr-2 px-lg-2 py-1 d-flex flex-row align-items-center"
                                  onClick={() => {
                                    let _selectedComp = [...selectedComp];
                                    let delIDX = _selectedComp.findIndex(
                                      (data) => data.blIDX === item.blIDX
                                    );
                                    if (delIDX > -1) {
                                      _selectedComp.splice(delIDX, 1);
                                    }
                                    setSelectedComp(_selectedComp);

                                    // $comp_select.current.removeItem(item);
                                    // methods.addItem(item);
                                  }}
                                >
                                  <span className="h6 m-0 mr-2 text-danger">
                                    &times;
                                  </span>
                                  {item.blLOGO && (
                                    <div
                                      className="d-inline-block mr-2"
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                        background: `url(${item.blLOGO}) 50% 50% / contain no-repeat`,
                                      }}
                                    />
                                  )}
                                  <p className="d-inline-block">
                                    <b>{item.blCompNM_KO}</b>{" "}
                                    <span className="font-size-08">
                                      [{item.blCompNM}]
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row m-0 p-0">
                    <div
                      className={`col-12 ${
                        selectedComp.length > 0 ? "pb-2 pb-lg-3" : "p-3 py-lg-2"
                      }  m-0 px-2  d-flex flex-column flex-md-row justify-content-md-between`}
                    >
                      <p className="h6 font-weight-bold mt-3">
                        총{" "}
                        <span className="text-info">
                          {util.addCommas(pageSize)}건
                        </span>
                        의 스케줄이 검색되었습니다
                      </p>
                      <div className="align-self-end">
                        <button
                          className="btn_1 px-4 mr-2 rounded-sm "
                          onClick={handleSearchSchedule}
                        >
                          검색
                        </button>{" "}
                        <button
                          className="btn_1 bg-secondary border-secondary rounded-sm"
                          onClick={handleResetFilters}
                        >
                          검색조건 초기화
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              {isScheduleLoad ? (
                <div className="bg-light rounded p-5 h6 text-center">
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </div>
              ) : schedule_list.length > 0 ? (
                <ScheduleFCLTable
                  loginInfo={loginInfo}
                  page={page}
                  totalPageCount={totalPageCount}
                  schedule_list={schedule_list}
                  comp_list={comp_list}
                  filters={filters}
                  handleChangePage={handleChangePage}
                  openCostInfoModal={openCostInfoModal}
                  handleOpenOceanLookV3={handleOpenOceanLookV3}
                  handleUseHistoryData={handleUseHistoryData}
                />
              ) : (
                <div className="bg-white rounded p-5 h6 text-center font-weight-bold text-secondary">
                  조건에 맞는 스케줄이 존재하지 않습니다
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
