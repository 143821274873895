import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function ImpModal(props, ref) {
  const { downloadFiles, shareFiles } = props;
  const [subject, setSubject] = useState("");
  const [data, setData] = useState(undefined);
  const [selectFile, setSelectFile] = useState([]);
  const { t } = useTranslation();

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openModal(e, key, params) {
      if (key === "file") {
        SELECT_IMP_SUB_FILE_LIST(params.RPT_NO, (newData) => {
          setSubject(key);
          setData({ ...params, files: newData });
        });
        return;
      }
      setSubject(key);
      setData(params);
    },
  }));

  //수입정보 첨부파일 받아오기
  const SELECT_IMP_SUB_FILE_LIST = (RPT_NO, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/app/JS_imp_sub_file_list.php",
      msgID: msgID,
      data: {},
      RPT_NO: RPT_NO,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (callback) callback(newData["returnData"]);
        }
      }
    });
  };

  const handleSelectFiles = (e, item) => {
    setSelectFile((prevState) => {
      if (e.target.checked) {
        return [...prevState, item];
      }
      return prevState.filter((data) => data.SN !== item.SN);
    });
  };

  const closeModal = () => {
    setSubject("");
    setData(undefined);
    setSelectFile([]);
  };

  return (
    <div
      className="modal fade"
      id="ImpModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="ImpModal"
    >
      <div className="modal-dialog modal-dialog-centered mt-0 d-flex flex-row align-items-center">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              <strong>
                {subject === "info"
                  ? t("imp_modalMemo")
                  : subject === "dispatch"
                  ? t("dispatch")
                  : t("file")}
              </strong>
            </h5>
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              aria-label="Close"
              id="ImpModalClose"
              onClick={() => closeModal()}
            >
              <i className="fas fa-plus fa-times" />
            </button>
          </div>
          <div className="modal-body p-1">
            {data !== undefined && (
              <>
                {subject === "info" && (
                  <p className="font-weight-normal p-2">
                    {data.memoData || t("notMemoMSG")}
                  </p>
                )}
                {subject === "dispatch" && (
                  <div className="p-2">
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 p-0 font-weight-bold">
                        {t("transitStatus")}
                      </div>
                      <div className="col-6 m-0 p-0 text-right">
                        {data.LLS_ORDER_ST || "-"}
                      </div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 p-0 font-weight-bold">
                        {t("carNumber")}
                      </div>
                      <div className="col-6 m-0 p-0 text-right">
                        {data.CAR_NUM || "-"}
                      </div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-6 m-0 p-0 font-weight-bold">
                        {t("tel")}
                      </div>
                      <div className="col-6 m-0 p-0 text-right">
                        <a href={`tel:${data.CAR_TEL}`}>
                          <i className="fa-solid fa-phone mr-2 text-gbts" />
                          {data.CAR_TEL || "-"}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {subject === "file" && (
                  <div>
                    <div className="row m-0 p-0 font-weight-bold border-bottom">
                      <div className="col-2 m-0 p-1 text-center">
                        {t("select")}
                      </div>
                      <div className="col-3 m-0 p-1">{t("fileType")}</div>
                      <div className="col-7 m-0 p-1">{t("fileName")}</div>
                    </div>

                    {data.files?.length > 0 &&
                      data.files.map((item, index) => {
                        const isLast = index === data.files.length - 1;
                        return (
                          <div
                            key={index}
                            className={`row m-0 p-0 ${
                              isLast ? "" : "border-bottom"
                            }`}
                          >
                            <div className="col-2 m-0 py-2 px-1 d-flex flex-row align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  handleSelectFiles(e, item);
                                }}
                              />
                            </div>
                            <div className="col-3 m-0 py-2 px-1">
                              <p>{item.FILE_GB_NM}</p>
                            </div>
                            <div className="col-7 m-0 py-2 px-1">
                              <p>{item.FILE_NM}</p>
                            </div>
                          </div>
                        );
                      })}

                    {data.files?.length > 0 && (
                      <div className="row m-0 mt-2 p-0">
                        <div className="col-6 m-0 p-1">
                          <button
                            className="btn btn-sm btn-secondary w-100"
                            onClick={() => downloadFiles(selectFile)}
                          >
                            {t("fileDownload")}
                          </button>
                        </div>
                        <div className="col-6 m-0 p-1">
                          <button
                            className="btn btn-sm btn-secondary w-100"
                            onClick={() => shareFiles(selectFile)}
                          >
                            {t("share")}
                          </button>
                        </div>
                      </div>
                    )}
                    {data.files?.length === 0 && (
                      <p className="p-2">{t("notFileMSG")}</p>
                    )}
                  </div>
                )}
              </>
            )}

            {subject === "" && (
              <div className="col-12 text-center py-2">
                <div className="h4 mb-0">
                  <i className="fas fa-spinner spin-infinite" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ImpModal);
