import React, { useEffect, useState } from "react";

import ChatNewsCompModal from "./ChatNewsCompModal";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import { useParams } from "react-router-dom";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "ChatNewsDetail";

let subscribeList = {};
let likePostList = {};

let news_main = undefined;
let loginInfo = null;

export default function ChatNewsDetail(props) {
  const { setSelectedItem } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [newsChatList, setNewsChatList] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [data, setData] = useState(null);
  const params = useParams();

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData && userData["returnData"][0]) {
      loginInfo = userData["returnData"][0];

      requestChatNewsList((newsList) => {
        requestEntireChannelList((entireList) => {
          requestUserLikeList((newData) => {
            for (let i = 0; i < newData["returnData"].length; i++) {
              likePostList[newData["returnData"][i].cntlMSGKey] = "";
            }
          });
          handleTalkNewsChannelList([
            ...newsList["returnData"],
            ...entireList["returnData"],
          ]);
          requestNewsList();
        });
      });
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n && n["returnData"][0]) {
          loginInfo = n["returnData"][0];
          requestChatNewsList((newsList) => {
            requestEntireChannelList((entireList) => {
              requestUserLikeList((newData) => {
                for (let i = 0; i < newData["returnData"].length; i++) {
                  likePostList[newData["returnData"][i].cntlMSGKey] = "";
                }
              });

              handleTalkNewsChannelList([
                ...newsList["returnData"],
                ...entireList["returnData"],
              ]);
              requestNewsList();
            });
          });
        }
      }
    );

    socket.addLocalEventListener("ChangedNews", _mClassName, (b, n) => {
      if (!n.data.hasOwnProperty("cnuAgreeYN")) return;
      news_main = {
        ...news_main,
        cnmSubscribe:
          Number(news_main.cnmSubscribe) + (n.data.cnuAgreeYN === "Y" ? 1 : -1),
      };
      setIsNeedUpdate((prevState) => !prevState);
    });

    return () => {
      news_main = undefined;
      socket.removeLocalEventListener("ChangedNews", _mClassName);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (data) {
      setIsLiked(likePostList.hasOwnProperty(data.cntMSGKey));
      requestChatNewsMain();
    }
  }, [data]);

  // 뉴스채널 목록 데이터 정리
  const handleTalkNewsChannelList = (cnmList) => {
    let dataList = [];
    subscribeList = {};
    for (let i = 0; i < cnmList.length; i++) {
      let data = cnmList[i];
      subscribeList[data.cnmMainKey] = "";
      if (!data.cnmPicMain) {
        data.cnmPicMain = data.COMP_LOGO;
      }
      dataList.push({
        ...data,
        LastTime: data.cnmLastTime,
      });
    }

    setNewsChatList(dataList);
  };

  // 구독 뉴스채널 데이터 요청
  const requestChatNewsList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_getSubscribeChatNewsList.php",
      msgID: msgID,
      data: {},
      cnuUserSite: loginInfo.comp_cd,
      cnuUserID: loginInfo.user_id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            newData["returnData"][i].isEntire = false;
          }
          callback && callback(newData);
        }
      }
    });
  };

  const requestEntireChannelList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_getEntireChannelListWithUser.php",
      msgID: msgID,
      data: {},
      cniUserSite: loginInfo.comp_cd,
      cniUser: loginInfo.user_id,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].cnmLastDelYN === "Y") {
              newData["returnData"][i].cnmLastMSG = "삭제된 메세지입니다.";
            }
            newData["returnData"][i].isEntire = true;
          }
          callback && callback(newData);
        }
      }
    });
  };

  const requestUserLikeList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_getLikeListWithUser.php",
      msgID: msgID,
      data: {},
      cntlCreator: loginInfo.user_id,
      cntlCreatorSite: loginInfo.comp_cd,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const requestNewsList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      cntDelYN: "N",
      isLogiTalk: "Y",
      cntIDX: params.id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setData(newData["returnData"][0]);
        }
      }
    });
  };

  const requestChatNewsMain = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_chatNewsMainSelect.php",
      msgID: msgID,
      data: {},
      cnmMainKey: data.cntMainKey,
      skip: 0,
      take: 1,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length === 0) {
            codeList.Modal.current.alert(
              "오류가 발생했습니다. 다시 시도해주세요",
              () => {
                setData(null);
              }
            );
            return;
          }

          if (!newData["returnData"][0].cnmPicMain) {
            newData["returnData"][0].cnmPicMain =
              newData["returnData"][0].COMP_LOGO;
          }

          news_main = newData["returnData"][0];
          setIsNeedUpdate((prevState) => !prevState);
        }
      }
    });
  };

  const updateLikeStatusNewsWithUser = (type, updateData) => {
    const filePath = {
      INSERT: "/talk_news/JS_insertLikeChatNews.php",
      DELETE: "/talk_news/JS_deleteLikeChatNews.php",
    };
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: filePath[type],
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (type === "INSERT") {
            likePostList[updateData.cntlMSGKey] = "";
          }
          if (type === "DELETE") {
            delete likePostList[updateData.cntlMSGKey];
          }

          if (updateData.cntlMSGKey === data.cntMSGKey) {
            setData((prevState) => {
              let nextState = { ...prevState };
              let cntLikeTotal = Number(nextState.cntLikeTotal);
              if (type === "INSERT") {
                ++cntLikeTotal;
              } else {
                --cntLikeTotal;
              }

              nextState.cntLikeTotal = cntLikeTotal;
              return nextState;
            });
          }
          requestNewsList();
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  const downloadFile = (url, name) => {
    codeList.Modal.current.confirm(
      `[${name}]을 다운로드 하시겠습니까?`,
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  const ChangedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  const actionSubscribe = () => {
    const isSubscribe = subscribeList.hasOwnProperty(news_main.cnmMainKey);
    const confirmMSG = isSubscribe
      ? "채널을 구독취소하시겠습니까?"
      : "채널을 구독하시겠습니까?";

    codeList.Modal.current.confirm(
      `${news_main.cnmCompNM}의 [${news_main.cnmName}] ${confirmMSG}`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/talk_news/JS_chatNewsChannelSubscribe.php",
            msgID: msgID,
            data: {},
            cnuMainKey: news_main.cnmMainKey,
            cnuUserSite: loginInfo.comp_cd,
            cnuUserID: loginInfo.user_id,
            cnuRoomAuth: "",
            cnuRoomName: news_main.cnmName,
            cnuAgreeYN: isSubscribe ? "N" : "Y",
            cnuJoinDate: new Date(),
          };
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  socketMsg.cnuAgreeYN === "Y"
                    ? `[${news_main.cnmName}]이 구독되었습니다.`
                    : `[${news_main.cnmName}]이 구독 취소되었습니다.`,
                  () => {
                    requestChatNewsList((newsList) => {
                      requestEntireChannelList((entireList) => {
                        handleTalkNewsChannelList([
                          ...newsList["returnData"],
                          ...entireList["returnData"],
                        ]);
                      });
                    });
                    ChangedNews({
                      ...news_main,
                      cnuAgreeYN: socketMsg.cnuAgreeYN,
                      isSubscribe: isSubscribe ? "N" : "Y",
                    });
                  }
                );
              } else {
                codeList.Modal.current.alert(
                  "오류가 발생했습니다. 다시 시도해주세요"
                );
              }
            }
          });
        }
      }
    );
  };

  const openNewsCorpModal = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_INFO_MODAL_CALL,
      _mClassName,
      { ...news_main, ...data }
    );
  };

  const clickBtnLike = () => {
    const TYPE = isLiked ? "DELETE" : "INSERT";
    updateLikeStatusNewsWithUser(TYPE, {
      cntlMSGKey: data.cntMSGKey,
      cntlCreator: loginInfo.user_id,
      cntlCreatorSite: loginInfo.comp_cd,
    });
  };

  if (!data) return <></>;

  const [year, month, date] = util
    .getDateToFormat(data.cntCreatDate, "YYYY-MM-DD")
    .split("-");
  const [imgLink, imgName] = data.cntPic.split("?");
  const files = data.cntFile !== "" ? data.cntFile.split("|") : "";

  if (news_main === undefined) {
    return <></>;
  }

  const isSubscribe = subscribeList.hasOwnProperty(news_main.cnmMainKey);

  return (
    <>
      <ChatNewsCompModal
        newsChatList={newsChatList}
        actionSubscribe={actionSubscribe}
      />
      <div className="p-5 w-100 h-100 overflow_scroll-Y bg-white">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-6">
            <div className="d-flex flex-row justify-content-between mb-5">
              <div>
                <p className="h2 font-weight-bold">{data.cntTitle}</p>
                <p>
                  작성자 :{" "}
                  <span
                    className="underline cursor_pointer font-weight-bold"
                    data-toggle="modal"
                    data-target="#ChatNewsCompModal"
                    onClick={() => openNewsCorpModal()}
                  >
                    {news_main.cnmCompNM}
                  </span>{" "}
                  · {year}년 {month}월 {date}일
                </p>
              </div>
            </div>

            <div>
              {imgLink && (
                <img
                  src={imgLink}
                  alt={imgName}
                  className="w-100 mb-3 rounded-lg"
                />
              )}
              <p className="h6 pb-3 mb-3">{data.cntMSG}</p>

              {data.cntLink !== "" && (
                <div className="h6 border-top py-3">
                  <p className="font-weight-bold mb-2">※ 참조 링크</p>
                  <p>
                    <a
                      href={data.cntLink}
                      target="_blank"
                      className="underline"
                    >
                      {data.cntLink}
                    </a>
                  </p>
                </div>
              )}

              {files !== "" && (
                <div className="h6 pt-3">
                  <p className="font-weight-bold mb-3">※ 첨부파일</p>
                  {files.map((file, index) => {
                    const splitData = file.split("?");
                    const fileLink = splitData[0];
                    const fileName = splitData[1];

                    return (
                      <p key={index} className="mb-2">
                        <span
                          className="d-inline-block mr-2 font-weight-bold cursor_pointer font-size-08"
                          onClick={() => {
                            downloadFile(fileLink, fileName);
                          }}
                        >
                          💾 저장
                        </span>
                        <a href={fileLink}>{fileName}</a>
                      </p>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="mt-5 pt-2">
              {Number(data.cntLikeTotal) > 0 && (
                <span className="font-size-09 font-weight-bolder text-secondary">
                  좋아요 {data.cntLikeTotal}
                </span>
              )}
              <span style={{ color: "transparent" }}>좋아요</span>
            </div>

            <div className="d-flex flex-row justify-content-between font-weight-bold mb-0 mt-2">
              <div>
                <button
                  className={`btn btn-white pl-0 font-weight-bold text-${
                    isLiked ? "freiscop" : "secondary"
                  }`}
                  onClick={clickBtnLike}
                >
                  <i
                    className={
                      isLiked
                        ? "fa-solid fa-thumbs-up"
                        : "fa-regular fa-thumbs-up"
                    }
                  />
                  <span className="d-inline-block ml-2 font-weight-bold">
                    좋아요
                  </span>
                </button>
              </div>
              <div className="d-flex flex-row justify-content-center font-weight-bold">
                <div className="mr-1 bg-light border p-2 rounded">
                  {news_main.cnmSubscribe}명 구독
                </div>
                <button
                  className={`ml-1 ${
                    isSubscribe ? "bg-light border p-2 rounded" : "p-2 btn_1"
                  }`}
                  onClick={() => actionSubscribe(news_main)}
                >
                  {isSubscribe ? "구독 취소" : "구독하기"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
