/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CommonNav from "../main/CommonNav";
import Footer from "../main/Footer";

import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";

import { useParams } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const add_file = {
  nt: "/notice/JS_notice_select.php",
  qa: "/qa/JS_qa_select.php",
  bb: "/bbs/JS_bbs_select.php",
  pr: "/promotion/JS_promotion_select.php",
  prq: "/product_request/JS_pr_select.php",
  ls: "/logistoryBoard/JS_logistorySelect.php",
};

const replay_select = {
  qa: "/qa/JS_qa_select_replay.php",
  prq: "/product_request/JS_pr_select_replay.php",
  ls: "/logistoryBoard/JS_logistory_select_replay.php",
};
const replay_insert = {
  qa: "/qa/JS_qa_insert_replay.php",
  prq: "/product_request/JS_pr_insert_replay.php",
  ls: "/logistoryBoard/JS_logistory_insert_replay.php",
};
const replay_update = {
  qa: "/qa/JS_qa_update_replay.php",
  prq: "/product_request/JS_pr_update_replay.php",
  ls: "/logistoryBoard/JS_logistory_update_replay.php",
};

export default function PostDetail() {
  const param = useParams();
  const [loginInfo, setLoginInfo] = useState(null);
  const [lock, setLock] = useState(false);
  const [qaReplayList, setQaReplayList] = useState([]);
  const [detail, setDetail] = useState({
    TITLE: "Post Detail title",
    sName: " 분류",
    CONTENTS: "viverra cursus ante laoreet eleifend. Donec vel",
    CreateDate: "2021-12-07",
    UpdateDate: "2021-12-07",
    Files: "",
    basicData: null,
  });
  const [isModify, setIsModify] = useState(false);
  const [isModifyData, setIsModifyData] = useState(null);

  const qrContent = useRef();
  const pwInput = useRef();

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "PostDetail",
      (b, n) => {
        if (n) {
          if (n["returnData"].length) {
            setLoginInfo({ ...n["returnData"][0] });
          }
        }
      }
    );
  })();

  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata["returnData"].length) {
        setLoginInfo(userdata["returnData"][0]);
      }
    }
    if (param.post_type === "prq") {
      updateReadCount();
    }
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "PostDetail"
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
    if (
      param.post_type === "qa" ||
      param.post_type === "prq" ||
      param.post_type === "ls"
    ) {
      requestReplaySelect();
    }
  }, [loginInfo]);

  //Post 데이터 가져오기
  const updateReadCount = () => {
    let msgID = util.makeUUIDv4();

    let socketMsg = {
      file: "/product_request/JS_pr_update_read.php",
      msgID: msgID,
      data: {},
      prIDX: param.post_idx,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
  };

  //Post 데이터 가져오기
  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let file = add_file[param.post_type];
    let postType =
      param.post_type === "prq" || param.post_type === "ls"
        ? "pr"
        : param.post_type;

    let idx = `${postType}IDX`;
    let socketMsg = {
      file: file,
      msgID: msgID,
      data: {},
      [idx]: param.post_idx,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let data = newData["returnData"][0];
          let setData = {
            COMPCD: data[`${postType}CompCD`],
            USERID: data[`${postType}USERID`],
            TITLE: data[`${postType}TITLE`],
            CONTENTS: data[`${postType}CONTENTS`],
            CreateDate: util.getDateToFormat(
              data[`${postType}CreateDate`],
              "YYYY-MM-DD"
            ),
            UpdateDate: util.getDateToFormat(
              data[`${postType}UpdateDate`],
              "YYYY-MM-DD"
            ),
            Files: data[`${postType}Files`],
            basicData: { ...data },
          };

          // if (data.hasOwnProperty("qaPublicYN")) {
          //   if (data.qaPublicYN === "N") {
          //     setData.pw = data.qaPWD;
          //     setLock(true);
          //   }
          // }
          // if (data.hasOwnProperty("prPublicYN")) {
          //   if (data.prPublicYN === "N") {
          //     setData.pw = data.prPWD;
          //     setLock(true);
          //   }
          // }
          // if (loginInfo !== null) {
          //   if (data.hasOwnProperty("qaPWD")) {
          //     if (
          //       loginInfo.comp_cd === data.qaPWD ||
          //       loginInfo.comp_cd.indexOf("Z") > -1
          //     ) {
          //       setLock(false);
          //     }
          //   }
          //
          //   if (data.hasOwnProperty("prPWD")) {
          //     if (
          //       loginInfo.comp_cd === data.prPWD ||
          //       loginInfo.comp_cd.indexOf("Z") > -1 ||
          //       loginInfo.comp_cd.indexOf("B") > -1
          //     ) {
          //       setLock(false);
          //     }
          //   }
          // }

          if (param.post_type !== "pr") {
            setData.sName = data.sName;
          }

          if (param.post_type === "prq") {
            setData.prReadCnt = data.prReadCnt;
          }
          if (param.post_type === "ls") {
            setData.IDX = data[`id`];
          }

          setDetail({
            ...setData,
          });
        }
      }
    });
  };

  const activeReplay = () => {
    if (qrContent.current.value.trim() === "") {
      codeList.Modal.current.alert("답변 내용을 입력해주세요");
    } else {
      if (loginInfo !== null) {
        const dataList = {
          IDXMain: param.post_idx,
          Comment: qrContent.current.value,
          INSERTDATE: new Date(),
          CompCD: loginInfo.comp_cd,
          USERID: loginInfo.user_id,
          CompNM: loginInfo.comp_nm,
          USERNM: loginInfo.user_nm,
          USEYN: "Y",
        };
        let replayDataList = {};

        for (let key in dataList) {
          if (param.post_type === "qa") {
            replayDataList[`pr${key}`] = dataList[key];
          }
          if (param.post_type === "prq" || param.post_type === "ls") {
            replayDataList[`prr${key}`] = dataList[key];
          }
        }
        requestReplay(replayDataList);
      } else {
        codeList.Modal.current.alert("로그인이 필요한 서비스입니다.");
      }
    }
  };

  const requestReplay = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: replay_insert[param.post_type],
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "답변글이 정상적으로 등록되었습니다.",
            () => {
              qrContent.current.value = "";
              requestReplaySelect();
            }
          );
        }
      }
    });
  };

  //Q&A 댓글 리스트 받아오기
  const requestReplaySelect = () => {
    let msgID = util.makeUUIDv4();
    let postType = "qa";
    if (param.post_type === "prq" || param.post_type === "ls") {
      postType = "prr";
    }
    let socketMsg = {
      file: replay_select[param.post_type],
      msgID: msgID,
      data: {},
    };
    socketMsg[`${postType}IDX`] = param.post_idx;

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          setQaReplayList([...newData["returnData"]]);
        }
      }
    });
  };

  const checkPassword = () => {
    if (pwInput.current.value === detail.pw) {
      setLock(false);
    } else {
      codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.");
    }
  };

  const modifyReplay = (replayData, useYN, exposure) => {
    let postType = param.post_type;
    if (postType === "prq" || postType === "ls") {
      postType = "prr";
    }

    if (exposure) {
      codeList.Modal.current.confirm(
        "댓글을 노출로 설정하시겠습니까??",
        (ret) => {
          requestReplayUpdate(useYN, replayData);
        }
      );
      return;
    }

    if (useYN === "N") {
      codeList.Modal.current.confirm("댓글을 삭제하시겠습니까?", (ret) => {
        if (ret) {
          setIsModifyData({ ...replayData });
          requestReplayUpdate(useYN, replayData);
        }
      });
    } else {
      codeList.Modal.current.confirm("댓글을 수정하시겠습니까?", (ret) => {
        if (ret) {
          setIsModifyData({ ...replayData });
          setIsModify(true);
          qrContent.current.value = replayData[`${postType}Comment`];
        }
      });
    }
  };

  const requestReplayUpdate = (useYN, replayData, update) => {
    let msgID = util.makeUUIDv4();

    let postType = "qr";
    if (param.post_type === "prq" || param.post_type === "ls") {
      postType = "prr";
    }

    console.log(replayData);

    let socketMsg = {
      file: replay_update[param.post_type],
      msgID: msgID,
      data: {},
      [`${postType}Comment`]: update
        ? qrContent.current.value
        : replayData[`${postType}Comment`],
      [`${postType}IDX`]:
        postType === "qr" ? replayData.qrIDXMain : replayData[`${postType}IDX`],
      [`${postType}USEYN`]: useYN,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            useYN === "Y"
              ? "답변글이 정상적으로 수정되었습니다."
              : "답변글이 정상적으로 삭제 되었습니다.",
            () => {}
          );
          qrContent.current.value = "";
          requestReplaySelect();
          setIsModifyData(null);
          setIsModify(false);
        }
      }
    });
  };

  const clearReplay = () => {
    codeList.Modal.current.confirm("답변작성을 초기화 하시겠습니까?", (ret) => {
      if (ret) {
        qrContent.current.value = "";
        setIsModifyData(null);
        setIsModify(false);
        codeList.Modal.current.alert("초기화되었습니다.");
      } else {
        codeList.Modal.current.alert("취소하였습니다..");
      }
    });
  };

  function autoLink(doc) {
    let regURL = new RegExp(
      "(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)",
      "gi"
    );
    let regEmail = new RegExp(
      "([xA1-xFEa-z0-9_-]+@[xA1-xFEa-z0-9-]+.[a-z0-9-]+)",
      "gi"
    );
    return doc
      .replace(
        regURL,
        "<a style='text-decoration-line: underline;' href='$1://$2' target='_blank'>$1://$2</a>"
      )
      .replace(
        regEmail,
        "<a style='text-decoration-line: underline;' href='mailto:$1'>$1</a>"
      );
  }
  return (
    <>
      {/*
      {lock && (
        <div className="filter_blur">
          <div
            className="card position-absolute"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="card-header font-weight-bold">비밀번호 입력</div>
            <div className="card-body">
              <p className=" mb-3">
                해당글은 비공개 글 입니다.
                <br />
                <strong>(무료)포워더회원으로 로그인시 내용확인이 가능</strong>
                하며,
                <br /> 작성자 본인인 경우 비밀번호를 입력해 주세요.
              </p>
              <input
                type="password"
                className="form-control"
                ref={pwInput}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    checkPassword();
                  }
                }}
              />
            </div>
            <div className="card-footer d-flex justify-content-end">
              <Link
                to={`/Post/${param.post_type}`}
                className="btn btn-secondary"
              >
                목록으로
              </Link>
              <div
                className="btn btn-primary ml-2"
                onClick={() => {
                  checkPassword();
                }}
              >
                확인
              </div>
            </div>
          </div>
        </div>
      )}
      */}
      <CommonNav />
      <main className="py-5">
        <div className="container mt-5 margin_60">
          <div className="row">
            <div className="col-12">
              <div className="box_style_1">
                <div className="post nopadding">
                  <div className="d-flex flex-column flex-xl-row justify-content-xl-start align-items-xl-center mb-2">
                    {param.post_type === "ls" && detail.basicData && (
                      <div className="d-flex flex-row justify-content-start align-items-center mb-1 mb-lg-0">
                        <div
                          className="d-flex flex-row justify-content-center align-items-center position-relative mr-3"
                          style={{
                            width: 30,
                            height: 30,
                            background: `url(${
                              detail.basicData.prLOGO
                                ? detail.basicData.prLOGO.split("?")[0]
                                : ""
                            }) 50% 50%/contain no-repeat`,
                          }}
                        >
                          {!detail.basicData.prLOGO && (
                            <p className="font-weight-bold text-secondary text-center font-size-08">
                              LOGO
                            </p>
                          )}
                        </div>

                        <h2 className="m-0 mr-2">
                          [{detail.basicData.prBCOMPNM}]
                        </h2>
                      </div>
                    )}
                    <h2 className="m-0">{detail.TITLE}</h2>
                  </div>
                  <div className="post_info clearfix">
                    <div className="post-left">
                      <ul>
                        <li>
                          <i className="icon-calendar-empty" />
                          등록일 : <span>{detail.CreateDate}</span>
                        </li>
                        <li>
                          {!param.post_type === "ls" && (
                            <i className="icon-inbox-alt" />
                          )}
                          {param.post_type === "nt" && "공지사항"}
                          {param.post_type === "qa" && "Q&A 게시판"}
                          {param.post_type === "bb" && "서식자료실"}
                          {param.post_type === "pr" && "프로모션"}
                          {param.post_type === "prq" && "상품요청"}
                        </li>
                        {param.post_type !== "prq" &&
                          param.post_type !== "ls" && (
                            <li>
                              <i className="icon-tags" />
                              {detail.sName || "-"}
                            </li>
                          )}{" "}
                        {param.post_type === "ls" && (
                          <li>
                            <i className="icon-inbox-alt" />
                            {detail.sName || "-"}
                          </li>
                        )}{" "}
                        {param.post_type === "prq" && (
                          <li>
                            <i className="icon-users" /> {detail["prReadCnt"]}
                          </li>
                        )}
                      </ul>
                    </div>

                    {(param.post_type === "qa" ||
                      param.post_type === "prq" ||
                      param.post_type === "ls") && (
                      <div className="post-right">
                        <i className="icon-comment" />
                        댓글 :
                        <a href="#">
                          {
                            qaReplayList.filter(
                              (item) =>
                                item[
                                  `${
                                    param.post_type === "qa" ? "qr" : "prr"
                                  }USEYN`
                                ] === "Y"
                            ).length
                          }
                        </a>
                      </div>
                    )}
                  </div>
                  {param.post_type === "prq" && detail.basicData !== null
                    ? loginInfo !== null
                      ? (loginInfo.comp_cd.startsWith("Z") ||
                          loginInfo.comp_cd.startsWith("B") ||
                          loginInfo.user_id === detail.basicData.prUSERID) && (
                          <div className="font-weight-bold bg-light mb-2 border p-3">
                            <p className="m-0 p-0">
                              - 작성자명 : {detail.basicData.prUSERName}
                            </p>
                            <p className="m-0 p-0">
                              - 이메일 : {detail.basicData.prUSEREmail}
                            </p>
                          </div>
                        )
                      : ""
                    : ""}

                  <div
                    className="p-3"
                    style={{ whiteSpace: "break-spaces", minHeight: 100 }}
                  >
                    {param.post_type !== "prq" && param.post_type !== "ls" ? (
                      detail.CONTENTS
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: detail.CONTENTS, //autoLink(detail.CONTENTS)
                        }}
                      />
                    )}
                  </div>

                  {param.post_type === "prq" ? (
                    loginInfo !== null ? (
                      loginInfo.comp_gb === "Z" || loginInfo.comp_gb === "B" ? (
                        <></>
                      ) : (
                        <div>
                          <p className="text-danger">
                            ※ 상품 및 견적서 요청인의 정보는 포워더계정으로
                            로그인하면 확인할 수 있습니다.
                          </p>
                          <p className="text-danger">
                            {"    "}포워더 회원가입은 무료이니 많은 이용
                            바랍니다.
                          </p>
                        </div>
                      )
                    ) : (
                      <div>
                        <p className="text-danger">
                          ※ 상품 및 견적서 요청인의 정보는 포워더계정으로
                          로그인하면 확인할 수 있습니다.
                        </p>
                        <p className="text-danger">
                          {"    "}포워더 회원가입은 무료이니 많은 이용 바랍니다.
                        </p>
                      </div>
                    )
                  ) : (
                    <></>
                  )}

                  {detail.Files ? (
                    <div className="border mt-5 p-3">
                      ※첨부파일
                      <div className="m-0 p-0">
                        {detail.Files.split("|").map((v, i) => {
                          let splitData = v.split("?");
                          if (splitData.length === 1) return null;
                          let fileName = splitData[0];
                          let fileLink = splitData[1];
                          return (
                            <div className="my-2" key={v}>
                              {fileName.endsWith(".pdf") && (
                                <a
                                  className="text-primary font-weight-bold border-0"
                                  href={fileLink}
                                  download={fileName}
                                  target="_blank"
                                >
                                  {fileName}
                                </a>
                              )}
                              {!fileName.endsWith(".pdf") && (
                                <button
                                  className="btn_1"
                                  onClick={() =>
                                    util.downloadAs(fileLink, fileName)
                                  }
                                >
                                  <i className="fas fa-download" /> {fileName}
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {(param.post_type === "qa" ||
                    param.post_type === "prq" ||
                    param.post_type === "ls") && (
                    <>
                      <h4
                        className="m-0 p-0 mt-5 pt-3"
                        style={{ borderTop: "1px #ccc dashed" }}
                      >
                        댓글 :{" "}
                        {
                          qaReplayList.filter(
                            (item) =>
                              item[
                                `${
                                  param.post_type === "qa" ? "qr" : "prr"
                                }USEYN`
                              ] === "Y"
                          ).length
                        }
                      </h4>
                      <div id="comments">
                        <ol>
                          {qaReplayList.length > 0 ? (
                            qaReplayList.map((re) => {
                              let postType = "qr";
                              if (
                                param.post_type === "prq" ||
                                param.post_type === "ls"
                              ) {
                                postType = "prr";
                              }

                              return (
                                <li
                                  className={
                                    re[`${postType}USEYN`] === "Y"
                                      ? ""
                                      : loginInfo &&
                                        loginInfo.comp_cd.startsWith("Z")
                                      ? ""
                                      : "d-none"
                                  }
                                  key={
                                    re[`${postType}CreateDate`] +
                                    "_" +
                                    re[`${postType}USERNM`]
                                  }
                                >
                                  <div className="comment_right clearfix border-bottom w-100 pb-3">
                                    <div className="comment_info">
                                      {" "}
                                      {re[`${postType}CompNM`]}
                                      <span>|</span>
                                      {re[`${postType}USERNM`]}
                                      <span>|</span>{" "}
                                      {re[`${postType}CreateDate`]}{" "}
                                      {loginInfo !== null &&
                                      ((re.prrUSERID === loginInfo.user_id &&
                                        re.prrCompCD === loginInfo.comp_cd) ||
                                        loginInfo.comp_cd.startsWith("Z")) ? (
                                        <>
                                          <span className="pr-0 ">
                                            |
                                            <span
                                              className="pr-0 mr-0 font-weight-bold cursor_pointer "
                                              onClick={() => {
                                                modifyReplay(re, "Y");
                                              }}
                                            >
                                              수정
                                            </span>
                                          </span>
                                          <span>
                                            |
                                            {re[
                                              `${
                                                param.post_type === "qa"
                                                  ? "qr"
                                                  : "prr"
                                              }USEYN`
                                            ] === "N" ? (
                                              <span
                                                className="font-weight-bold cursor_pointer"
                                                onClick={() => {
                                                  modifyReplay(re, "Y", "Y");
                                                }}
                                              >
                                                노출
                                              </span>
                                            ) : (
                                              <span
                                                className="font-weight-bold cursor_pointer"
                                                onClick={() => {
                                                  modifyReplay(re, "N");
                                                }}
                                              >
                                                삭제
                                              </span>
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <p
                                      className={`p-0 m-0 ${
                                        re[`${postType}USEYN`] === "N"
                                          ? "line-through"
                                          : ""
                                      } `}
                                    >
                                      {re[`${postType}Comment`]}
                                    </p>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <li>
                              <div className="comment_right clearfix">
                                <div className="comment_info">
                                  <p className="p-0 m-0">
                                    - 등록된 답변이 없습니다.
                                  </p>
                                </div>
                              </div>
                            </li>
                          )}
                        </ol>
                      </div>

                      <h4>{isModify ? "답변 수정" : "답변 달기"}</h4>

                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows={5}
                          placeholder="답변글을 입력해주세요"
                          ref={qrContent}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="reset"
                          className="btn_1 mr-2"
                          value="초기화"
                          onClick={() => {
                            clearReplay();
                          }}
                        />
                        <input
                          type="submit"
                          className="btn_1"
                          value={isModify ? "답변 수정" : "답변 등록"}
                          onClick={() => {
                            if (isModify) {
                              requestReplayUpdate("Y", isModifyData, "update");
                            } else {
                              activeReplay();
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>{" "}
              <div className="row mt-5 d-flex flex-row justify-content-center">
                {param.post_type === "ls" &&
                  loginInfo !== null &&
                  (detail.USERID === loginInfo.user_id ||
                    loginInfo.comp_cd.startsWith("Z")) && (
                    <Link
                      className="btn btn-primary px-5 mr-1"
                      to={`/WriteLogiStory/${detail.IDX}`}
                    >
                      수정하기
                    </Link>
                  )}
                <Link
                  className="btn btn-secondary px-5 ml-1"
                  to={`/Post/${param.post_type}`}
                >
                  목록으로
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
