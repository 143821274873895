import React from "react";
import VATReportingStatus from "./component/VATReportingStatus";
import WhereToBuyList from "./component/WhereToBuyList";
import SellerList from "./component/SellerList";

export default function CorpInfoReferenceDetailSales(props) {
  const { compInfo, isExistData, isPrint } = props;

  const sectionProps = {
    compInfo,
    isExistData,
    isPrint,
  };
  return (
    <div>
      <VATReportingStatus {...sectionProps} />
      <WhereToBuyList {...sectionProps} />
      <SellerList {...sectionProps} />
    </div>
  );
}
