/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const _mClassName = "ADManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

function ADManageEdit(props, ref) {
  const { data = {}, requestUserSelect } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");

  const controller = {
    AD_IDX: useRef(), // 관리번호
    AD_COMP_NM: useRef(), //업체명
    AD_COMP_HOMEPAGE: useRef(), //링크주소
    AD_LOGO: useRef(), //첨부파일
    AD_EXPOSURE_LOCATION: useRef(), // 사용여부
    AD_USE_YN: useRef(), // 사용여부
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  //스케줄 입력 폼 기본값 세팅(신규등록, 수정)
  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      controller.AD_IDX.current.value = userData.currentData.AD_IDX;
      controller.AD_COMP_NM.current.value = userData.currentData.AD_COMP_NM;
      controller.AD_COMP_HOMEPAGE.current.value =
        userData.currentData.AD_COMP_HOMEPAGE;
      controller.AD_LOGO.current.value = userData.currentData.AD_LOGO;
      controller.AD_EXPOSURE_LOCATION.current.value =
        userData.currentData.AD_EXPOSURE_LOCATION;
      controller.AD_USE_YN.current.value = userData.currentData.AD_USE_YN;
      setUploadFiles(userData.currentData.AD_LOGO);
    } else {
      controller.AD_IDX.current.value = "";
      controller.AD_COMP_NM.current.value = "";
      controller.AD_COMP_HOMEPAGE.current.value = "";
      controller.AD_LOGO.current.value = "";
      controller.AD_EXPOSURE_LOCATION.current.value = "";
      controller.AD_USE_YN.current.value = "";
      setUploadFiles("");
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_AD_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_AD_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
    removeNotice(e, params) {
      DeleteNotice(e, params);
    },
  }));

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];
      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      controller.AD_LOGO.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      controller.AD_LOGO.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  const DeleteNotice = (e, params) => {
    let updateData = {
      AD_IDX: params.currentData.AD_IDX,
      AD_DEL_YN: "Y",
    };
    codeList.Modal.current.confirm("해당 배너를 삭제하시겠습니까?", (ret) => {
      if (ret) {
        requestUserUpdate(updateData, EDIT_TYPE.UPDATE);
      }
    });
  };

  //스케줄 저장
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        AD_IDX: controller.AD_IDX.current.value,
        AD_COMP_NM: controller.AD_COMP_NM.current.value,
        AD_COMP_HOMEPAGE: controller.AD_COMP_HOMEPAGE.current.value,
        AD_LOGO: controller.AD_LOGO.current.value,
        AD_EXPOSURE_LOCATION: controller.AD_EXPOSURE_LOCATION.current.value,
        AD_USE_YN: controller.AD_USE_YN.current.value,
        AD_DEL_YN: "N",
      };

      requestUserUpdate(
        updateData,
        controller.AD_IDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if (controller.AD_COMP_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("업체명을 입력해주세요", () => {});
      return false;
    }

    if (controller.AD_USE_YN.current.value.trim() === "") {
      codeList.Modal.current.alert("사용여부를 선택해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/product_hosts/JS_product_hosts_update.php"
          : "/product_hosts/JS_product_hosts_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_AD_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_AD_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          requestUserSelect();
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "배너를 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert("배너를 추가 하였습니다.", () => {});

          $("#ADEditModal").click();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "배너 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "배너 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="ADEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="ADEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>콘솔사/선사 배너 이미지 등록</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="ADEditModal"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    disabled={true}
                    ref={controller.AD_IDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체명
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="1"
                    ref={controller.AD_COMP_NM}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  링크주소
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="2"
                    ref={controller.AD_COMP_HOMEPAGE}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  파일첨부
                </label>

                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"mall/notice"}
                    callback={UploadFiles}
                  />
                  <div style={{ marginTop: -10 }} className="pb-2">
                    <p
                      style={{
                        fontSize: "small",
                        color: "#8f8f8f",
                      }}
                    >
                      ※ 이미지는 350X100 사이즈를 권장합니다.
                    </p>
                  </div>
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={controller.AD_LOGO}
                  />
                  {uploadFiles !== "" &&
                    uploadFiles.split("|").map((v, i) => {
                      let splitData = v.split("?");
                      if (splitData.length === 1) return null;
                      let fileName = splitData[0];
                      let fileLink = splitData[1];
                      return (
                        <div className="my-2" key={v}>
                          {/* <button
                                onClick={() => {\
                                  downloadAs(fileLink, fileName);
                                }}
                              >
                                {fileName}
                              </button>*/}
                          <button
                            className="btn btn-danger mr-2"
                            onClick={() => {
                              deleteFile(fileLink, fileName);
                            }}
                          >
                            삭제
                          </button>
                          {fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                          {!fileName.endsWith(".pdf") && (
                            <a
                              className="font-weight-bold text-decoration-underline"
                              onClick={(e) => {
                                e.preventDefault();
                                util.downloadAs(fileLink, fileName);
                              }}
                            >
                              {fileName}
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  노출위치
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={controller.AD_EXPOSURE_LOCATION}
                  >
                    <option value="">-미선택-</option>
                    <option value="M">프레이스콥 메인</option>
                    <option value="L">로지톡</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용유무
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={controller.AD_USE_YN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  id="btnCloseModal"
                  onClick={(e) => {
                    // codeList.Modal.current.confirm(
                    //   `배너 등록을 취소하시겠습니까?`,
                    //   (ret) => {
                    //     if (ret) {
                    //       Cancel();
                    //     }
                    //   }
                    // );
                  }}
                >
                  <i className="fa fa-times mr-2" />
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => actionSave(e)}
                  disabled={loading}
                >
                  <i className="fa fa-check mr-2" />
                  저장
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(ADManageEdit);
