/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import Select from "react-dropdown-select";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const _mClassName = "AdvertiseVideoManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const vmCOMP_KIND_LIST = [
  { key: "Z01", title: "프레이스콥" },
  { key: "Z02", title: "로지톡" },
  { key: "Z03", title: "로지웍" },
  { key: "Z04", title: "로지킵" },
  { key: "B01", title: "포워더" },
  { key: "B02", title: "콘솔사" },
  { key: "B03", title: "선사" },
  { key: "E", title: "관세사" },
  { key: "F", title: "매체" },
  //  --------------------------------------
];

function AdvertiseVideoManageEdit(props, ref) {
  const { requestUserSelect, compList } = props;
  const [adVideoData, setAdVideoData] = useState({});
  const [selectValue, setSelectValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const $controller = {
    vmIDX: useRef(),
    vmCOMP_CD: useRef(),
    vmCOMP_KIND: useRef(),
    vmLink: useRef(),
    vmTitle: useRef(),
    vmDesc: useRef(),
    vmTopYN: useRef(),
    vmUseYN: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setIsLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_AD_VIDEO_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setAdVideoData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_AD_VIDEO_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openEditModal(e, params) {
      handleAdVideoData(e, params);
    },
    deleteADVideo(e, params) {
      DELETE_AD_VIDEO(e, params);
    },
  }));

  //모달 데이터 세팅
  const handleAdVideoData = (e, params) => {
    setAdVideoData(params);
  };

  useEffect(() => {
    if (adVideoData.hasOwnProperty("currentData")) {
      $controller.vmIDX.current.value = adVideoData.currentData.vmIDX;
      $controller.vmCOMP_KIND.current.value =
        adVideoData.currentData.vmCOMP_KIND;
      $controller.vmLink.current.value = adVideoData.currentData.vmLink;
      $controller.vmTitle.current.value = adVideoData.currentData.vmTitle;
      $controller.vmDesc.current.value = adVideoData.currentData.vmDesc;
      $controller.vmTopYN.current.value = adVideoData.currentData.vmTopYN;
      $controller.vmUseYN.current.value = adVideoData.currentData.vmUseYN;
      let selected = compList.find(
        (item) => item.COMP_CD === adVideoData.currentData.vmCOMP_CD
      );
      setSelectValue([selected]);
    } else {
      $controller.vmIDX.current.value = "";
      $controller.vmCOMP_KIND.current.value = "";
      $controller.vmCOMP_CD.current.value = "";
      $controller.vmLink.current.value = "";
      $controller.vmTitle.current.value = "";
      $controller.vmDesc.current.value = "";
      $controller.vmTopYN.current.value = "";
      $controller.vmUseYN.current.value = "";
      setSelectValue([]);
    }
  }, [adVideoData]);

  //유효성검사
  const checkValidation = () => {
    if (
      $controller.vmCOMP_KIND.current &&
      $controller.vmCOMP_KIND.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("업체구분을 선택해주세요");
      return true;
    }
    if (
      $controller.vmCOMP_CD.current &&
      $controller.vmCOMP_CD.current.state.values.length === 0
    ) {
      codeList.Modal.current.alert("업로드된 영상의 회사를 선택해주세요");
      return true;
    }
    if (
      $controller.vmLink.current &&
      $controller.vmLink.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("영상의 URL 주소를 입력해주세요");
      return true;
    }
    if (
      $controller.vmTitle.current &&
      $controller.vmTitle.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("영상의 제목을 입력해주세요");
      return true;
    }
    if (
      $controller.vmTopYN.current &&
      $controller.vmTopYN.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("영상의 상단고정 여부를 선택해주세요");
      return true;
    }
    if (
      $controller.vmUseYN.current &&
      $controller.vmUseYN.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("영상의 사용여부를 선택해주세요");
      return true;
    }
    return false;
  };

  //홍보영상 저장
  const SAVE_AD_VIDEO = () => {
    if (checkValidation()) return;

    const updateData = {
      vmIDX: $controller.vmIDX.current.value
        ? Number($controller.vmIDX.current.value)
        : "",
      vmCOMP_KIND: $controller.vmCOMP_KIND.current.value,
      vmCOMP_CD: $controller.vmCOMP_CD.current.state.values[0].COMP_CD,
      vmLink: $controller.vmLink.current.value,
      vmTitle: $controller.vmTitle.current.value,
      vmDesc: $controller.vmDesc.current.value,
      vmTopYN: $controller.vmTopYN.current.value,
      vmUseYN: $controller.vmUseYN.current.value,
      vmDelYN: "N",
    };
    REQUEST_USER_UPDATE(updateData);
  };

  //홍보 영상 삭제
  const DELETE_AD_VIDEO = (e, params) => {
    let updateData = {
      ...params.currentData,
    };
    codeList.Modal.current.confirm(
      `홍보영상 ${updateData.vmTitle}을(를) 삭제 하시겠습니까?`,
      (ret) => {
        if (ret) {
          updateData.vmDelYN = "Y";
          REQUEST_USER_UPDATE(updateData);
        }
      }
    );
  };

  //등록 및 수정 서버 요청
  const REQUEST_USER_UPDATE = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/advertise_video/JS_advetise_video_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          requestUserSelect();
          codeList.Modal.current.alert(newData["MSG"], () => {
            closeEditModal();
          });
        } else {
          codeList.Modal.current.alert(
            "요청 처리중에 문제가 발생하였습니다. 잠시후 다시 시도해주세요"
          );
        }
      }
    });
  };

  //값 초기화 및 모달 닫기
  const closeEditModal = () => {
    for (let key in $controller) {
      if ($controller[key].current) {
        $controller[key].current.value = "";
      }
    }
    setAdVideoData({});
    setSelectValue([]);
    setIsLoading(false);
    $("#AdvertiseVideoManageEditClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="AdvertiseVideoManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="AdvertiseVideoManageEdit"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>홍보영상관리</strong>
              </h3>

              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="AdvertiseVideoManageEditClose"
                onClick={() => {
                  closeEditModal();
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    disabled={true}
                    ref={$controller.vmIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체구분
                </label>
                <div className="col-sm-9 position-relative">
                  <select
                    className="custom-select form-control"
                    ref={$controller.vmCOMP_KIND}
                  >
                    <option value="">업체구분</option>
                    {vmCOMP_KIND_LIST.map((item) => {
                      return (
                        <option value={item.key} key={item.key}>
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  회사코드
                </label>
                <div className="col-sm-9 position-relative">
                  <Select
                    className="w-100 text-dark"
                    style={{ width: "100%", fontSize: "1em" }}
                    ref={$controller.vmCOMP_CD}
                    required
                    clearable={true}
                    multi={false}
                    options={compList}
                    name={"COMP_NM"}
                    sortBy={"COMP_NM"}
                    searchBy={"COMP_NM"}
                    valueField={"COMP_CD"}
                    labelField={"COMP_NM"}
                    autoFocus={false}
                    values={selectValue}
                    placeholder={"등록할 업체를 선택해주세요"}
                    itemRenderer={({
                      item,
                      itemIndex,
                      props,
                      state,
                      methods,
                    }) => {
                      return (
                        <div
                          style={{
                            width: 200,
                            backgroundColor:
                              state.cursor === itemIndex
                                ? "#edf0f9"
                                : "transparent",
                          }}
                        >
                          <p
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                            onClick={() => {
                              methods.addItem(item);
                              setSelectValue([item]);
                            }}
                            color="#0074D9"
                            style={{
                              fontSize: "11px",
                              padding: "2px 5px",
                              cursor: "pointer",
                              borderBottom: "1px solid #fff",
                              color: "gray",
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b
                              style={{
                                fontWeight: "normal",
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                                fontFamily: "Consolas",
                              }}
                            >
                              {item.COMP_CD ? "[" + item.COMP_CD + "] " : ""}
                            </b>

                            <b
                              style={{
                                fontWeight: 600,
                                color: "black",
                                fontStyle: "normal",
                                fontSize: "14px",
                              }}
                            >
                              {`${item.COMP_NM}  `}
                            </b>
                          </p>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  영상 URL
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="영상이 업로드 되어있는 링크를 입력해주세요"
                    ref={$controller.vmLink}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  영상 제목
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="영상의 제목을 입력해주세요"
                    ref={$controller.vmTitle}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  영상 설명
                </label>
                <div className="col-sm-9">
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder="영상의 설명을 입력해주세요"
                    ref={$controller.vmDesc}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  상단 고정
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.vmTopYN}>
                    <option value="">-미선택-</option>
                    <option value="Y">고정</option>
                    <option value="N">미고정</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  사용여부
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.vmUseYN}>
                    <option value="">-미선택-</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary mr-2"
                onClick={() => closeEditModal()}
              >
                취소
              </button>
              <button
                className="btn btn-primary"
                onClick={() => SAVE_AD_VIDEO()}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(AdvertiseVideoManageEdit);
