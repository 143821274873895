/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo,
  useCallback,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UploadImageToS3WithPhp from "../../lib/UploadImageToS3WithPhp";
import axios from "axios";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const _mClassName = "LogiStoryEditModal";

let logoList = [];
let typeList = [];

export default forwardRef(LogiStoryEditModal);

function LogiStoryEditModal(props, ref) {
  const { data = {}, loginInfo } = props;

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEditorState, setIsEditorState] = useState();
  const [uploadFiles, setUploadFiles] = useState("");
  const [isLogoListModalOpen, setIsLogoListModalOpen] = useState(false);
  const [prData, setPrData] = useState({
    prCreateDate: "",
    prLOGO: "",
    prBCOMPNM: "",
    prTITLE: "",
    prCONTENTS: "",
    prUSERName: "",
    prFiles: "",
    prFWDNO: "",
  });

  const $quillRef = useRef();
  const $controller = {
    prIDX: useRef(),
    prCreateDate: useRef(),
    prBCOMPNM: useRef(),
    prTITLE: useRef(),
    prType: useRef(),
    prCONTENTS: useRef(),
    prUSERName: useRef(),
    prFiles: useRef(),
    prUSEYN: useRef(), //노출여부
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      $controller.prIDX.current.value = userData.currentData.prIDX;
      $controller.prCreateDate.current.value = util.getDateToFormat(
        userData.currentData.prCreateDate,
        "YYYY-MM-DD"
      );
      $controller.prBCOMPNM.current.value = userData.currentData.prBCOMPNM;
      $controller.prUSERName.current.value = userData.currentData.prUSERName;
      $controller.prTITLE.current.value = userData.currentData.prTITLE;
      $controller.prFiles.current.value = userData.currentData.prFiles;
      $controller.prUSEYN.current.value = userData.currentData.prUSEYN;
      $controller.prCONTENTS.current.value = userData.currentData.prCONTENTS;
      $controller.prType.current.value = userData.currentData.prType;
      setIsEditorState(userData.currentData.prCONTENTS);
      let data = {
        prCreateDate: userData.currentData.prCreateDate,
        prLOGO: userData.currentData.prLOGO,
        prBCOMPNM: userData.currentData.prBCOMPNM,
        prTITLE: userData.currentData.prTITLE,
        prCONTENTS: userData.currentData.prCONTENTS,
        prUSERName: userData.currentData.prUSERName,
        prFiles: userData.currentData.prFiles,
        prFWDNO: userData.currentData.prFWDNO,
      };
      setPrData(data);
      setUploadFiles(userData.currentData.prFiles);
    } else {
      $controller.prIDX.current.value = "";
      $controller.prCreateDate.current.value = "";
      $controller.prBCOMPNM.current.value = "";
      $controller.prUSERName.current.value = loginInfo.user_nm;
      $controller.prTITLE.current.value = "";
      $controller.prCONTENTS.current.value = "";
      $controller.prFiles.current.value = "";
      $controller.prUSEYN.current.value = "";
      $controller.prType.current.value = "";
      setIsEditorState();
      setPrData({
        prCreateDate: "",
        prLOGO: "",
        prBCOMPNM: "",
        prTITLE: "",
        prCONTENTS: "",
        prUSERName: "",
        prFiles: "",
      });
      setUploadFiles("");
    }
  }, [userData]);

  useEffect(() => {
    selectTypeList();
    selectBLogoList();

    socket.addLocalEventListener(
      MsgIDList.EVENT_BLOGO_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        selectBLogoList();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGISTORY_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_BLOGO_MANAGE_DATA_UPDATED,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGISTORY_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  const selectTypeList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_notice_type_full_name_code_select.php",
      msgID: msgID,
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          typeList = newData["returnData"];
        }
      }
    });
  };

  const selectBLogoList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_b_logo_select.php",
      msgID: msgID,
      data: {},
      sort: [
        {
          field: "blCompNM_KO",
          dir: "asc",
        },
      ],
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length > 0) {
          logoList = newData["returnData"];
        }
      }
    });
  };

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));

  const imageHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      uploadCallback(file[0]);
    };
  };

  //파일첨부 업데이트
  const uploadCallback = async (file) => {
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName = util.todayDateTime() + "_" + name;
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("region", "mall/logistory");

    try {
      const response = await axios.post(
        "https://www.gbts.co.kr/backside/aws/uploadfile.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const range = $quillRef.current?.getEditor().getSelection()?.index;
      if (range !== null && range !== undefined) {
        let quill = $quillRef.current?.getEditor();

        quill?.setSelection(range, 1);
        quill?.clipboard.dangerouslyPasteHTML(
          range,
          `<img style="width:100%;" width='100%'  src=${response.data["url"]} alt="이미지 태그가 삽입됩니다." />`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //로고이미지 포멧셋
  const handleLogoImage = (img) => {
    let url = img || "";
    if (url.indexOf("?")) {
      url = url.split("?")[0];
    }
    return url;
  };

  //회사 로고이미지 업로드 후 처리
  const handleLogoImageUpload = (value) => {
    if (value.ret) {
      let file = `${value.fileName}?${value.realFileName}`;
      let data = {
        ...prData,
        prLOGO: file,
      };
      setPrData(data);
    } else {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생하였습니다. 잠시후 다시 시도해주세요"
      );
    }
  };

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileName}?${fileLink}`;
      let fileList = uploadFiles !== "" ? uploadFiles.split("|") : [];

      fileList.push(fileFull);
      let setFiles = fileList.join("|");
      $controller.prFiles.current.value = setFiles;
      setUploadFiles(setFiles);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      // console.log(item, `${name}:${url}`, item === `${name}:${url}`);
      return item === `${name}?${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      // console.log(pmFiles);
      $controller.prFiles.current.value = pmFiles;
      setUploadFiles(pmFiles);
    }
  };

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        prCreateDate: $controller.prCreateDate.current.value, //작성일자
        prUSERName: $controller.prUSERName.current.value, //질문자 명
        prTITLE: $controller.prTITLE.current.value, //질문 제목
        prCONTENTS: $controller.prCONTENTS.current.value, //질문 내용
        prFiles: $controller.prFiles.current.value, //질문자 이메일
        prBCOMPNM: $controller.prBCOMPNM.current.value, //질문자 이메일
        prIDX: $controller.prIDX.current.value.trim(), //index
        prINSERTDATE: new Date(), //질문 업로드일
        prUSERID: loginInfo.user_id, //로그인 경우 유저 아이디
        prCompCD: loginInfo.comp_cd, //회사 코드 z로 시작시 관리자
        prUSEYN: $controller.prUSEYN.current.value, //사용여부
        prFWDNO: prData.prFWDNO, //회사 코드 z로 시작시 관리자
        prLOGO: prData.prLOGO, //로고이미지
        prType: $controller.prType.current.value,
      };
      if (!updateData.prCreateDate) {
        updateData.prCreateDate = util.getDateToFormat(
          new Date(),
          "YYYY-MM-DD"
        );
      }

      requestUserUpdate(
        updateData,
        $controller.prIDX.current.value.trim() !== ""
          ? EDIT_TYPE.UPDATE
          : EDIT_TYPE.INSERT
      );
    } else {
      setLoading(false);
    }
  };

  // 선사콘솔사 선택
  const selectCompLogo = (url, bcomp_nm, fwd_no) => {
    let data = {
      ...prData,
      prLOGO: url,
      prBCOMPNM: bcomp_nm,
      prFWDNO: fwd_no,
    };
    $controller.prBCOMPNM.current.value = bcomp_nm;
    setPrData(data);
    setIsLogoListModalOpen(false);
  };

  const checkValidation = () => {
    if ($controller.prType.current.value.trim() === "") {
      codeList.Modal.current.alert("분류를 선택해주세요", () => {});
      return false;
    }
    if ($controller.prTITLE.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if ($controller.prBCOMPNM.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "선사 또는 콘솔사명을 입력해주세요",
        () => {}
      );
      return false;
    }
    if ($controller.prUSERName.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요", () => {});
      return false;
    }
    if ($controller.prCONTENTS.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestUserUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file:
        TYPE === EDIT_TYPE.UPDATE
          ? "/logistoryBoard/JS_logistory_update.php"
          : "/logistoryBoard/JS_logistory_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log("newData", newData);
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            TYPE === EDIT_TYPE.UPDATE
              ? MsgIDList.EVENT_LOGISTORY_MANAGE_DATA_UPDATED
              : MsgIDList.EVENT_LOGISTORY_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "선사 · 항공사 공지사항을 업데이트 하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "선사 · 항공사 공지사항을 추가 하였습니다.",
                () => {}
              );

          closeModal();
        } else {
          setLoading(false);
          TYPE === EDIT_TYPE.UPDATE
            ? codeList.Modal.current.alert(
                "선사 · 항공사 공지사항 업데이트 중 오류가 발생하였습니다.",
                () => {}
              )
            : codeList.Modal.current.alert(
                "선사 · 항공사 공지사항 추가 중 오류가 발생하였습니다.",
                () => {}
              );
        }
      }
    });
  };

  // 모달 닫기 및 매핑데이터 초기화
  const closeModal = () => {
    $controller.prIDX.current.value = "";
    $controller.prCreateDate.current.value = "";
    $controller.prBCOMPNM.current.value = "";
    $controller.prTITLE.current.value = "";
    $controller.prCONTENTS.current.value = "";
    $controller.prUSERName.current.value = "";
    $controller.prFiles.current.value = "";
    $controller.prUSEYN.current.value = "";
    setPrData({
      prCreateDate: "",
      prLOGO: "",
      prBCOMPNM: "",
      prTITLE: "",
      prCONTENTS: "",
      prUSERName: "",
      prFiles: "",
      prFWDNO: "",
    });
    setIsEditorState();
    setUploadFiles("");
    $("#closeLogistroyModal").click();
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  let formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "width",
  ];

  return (
    <>
      <div
        className="modal fade"
        id="LogistoryEditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="LogistoryEditModal"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>공지사항 등록 · 수정</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeLogistroyModal"
                onClick={() => closeModal()}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    readOnly={true}
                    placeholder="관리번호"
                    ref={$controller.prIDX}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  작성일자
                </label>
                <div className="col-sm-9">
                  <input
                    type="date"
                    className="form-control"
                    ref={$controller.prCreateDate}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">분류</label>
                <div className="col-sm-9">
                  <select className="form-control" ref={$controller.prType}>
                    <option value={""}>-미선택-</option>
                    {typeList.length > 0 &&
                      typeList.map((type, index) => {
                        return (
                          <option key={type.SmallType} value={type.SmallType}>
                            {type.FullName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  콘솔사,선사 선택
                </label>
                <div className="col-sm-9">
                  <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0 d-flex flex-row align-items-end">
                      <div
                        className="d-flex flex-row justify-content-center align-items-center rounded border bg-light position-relative mr-3"
                        style={{
                          minWidth: 50,
                          width: 50,
                          height: 50,
                          background: `url(${handleLogoImage(
                            prData.prLOGO
                          )}) 50% 50%/contain no-repeat`,
                        }}
                      >
                        <div
                          className="position-absolute"
                          style={{ top: 35, right: -8 }}
                        >
                          <div className="btn-group">
                            <UploadImageToS3WithPhp
                              region="gbts/companyInfo"
                              callback={handleLogoImageUpload}
                            >
                              <span
                                className="btn btn-sm btn-secondary d-flex flex-row justify-content-center align-items-center"
                                style={{ width: 20, height: 20 }}
                              >
                                <i className="fa-regular fa-images" />
                              </span>
                            </UploadImageToS3WithPhp>
                            <button
                              className="btn btn-sm btn-outline-secondary bg-white d-flex flex-row justify-content-center align-items-center"
                              style={{ width: 20, height: 20 }}
                              onClick={() => {
                                setIsLogoListModalOpen(true);
                              }}
                            >
                              <i className="fa-solid fa-bars" />
                            </button>
                          </div>
                        </div>
                        {!prData.prLOGO && (
                          <p className="font-weight-bold text-secondary text-center font-size-08">
                            LOGO
                          </p>
                        )}
                      </div>

                      <input
                        className="form-control h-100 mr-2"
                        type="text"
                        placeholder="선사/콘솔사명"
                        ref={$controller.prBCOMPNM}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  작성자(이름)
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="1"
                    ref={$controller.prUSERName}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="2"
                    ref={$controller.prTITLE}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9 mb-3">
                  {isLogoListModalOpen && (
                    <ModalLogoList
                      selectCompLogo={selectCompLogo}
                      setIsLogoListModalOpen={setIsLogoListModalOpen}
                    />
                  )}
                  <textarea
                    className="form-control d-none"
                    rows={20}
                    placeholder="문의사항을 입력해주세요"
                    ref={$controller.prCONTENTS}
                    disabled
                    value={isEditorState}
                  />
                  <ReactQuill
                    style={{ height: "350px", marginBottom: 50 }}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={isEditorState || ""}
                    ref={(element) => {
                      if (element !== null) {
                        $quillRef.current = element;
                      }
                    }}
                    onChange={(content, delta, source, editor) => {
                      setIsEditorState(editor.getHTML());
                    }}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  첨부파일
                </label>
                <div className="col-sm-9">
                  <UploadImageToS3WithPhp
                    region={"mall/logiStory"}
                    callback={UploadFiles}
                  />
                  <input
                    className="form-control d-none"
                    disabled={true}
                    type="text"
                    ref={$controller.prFiles}
                  />
                </div>
                <label className="col-form-label col-sm-3 text-end"></label>
                <div className="col-sm-9">
                  <div className="row m-0 p-0">
                    {uploadFiles !== "" && (
                      <div
                        className="flex-fill d-flex flex-column"
                        style={{ maxHeight: 100 }}
                      >
                        <div className="flex-fill overflow_scroll-Y">
                          {uploadFiles.split("|").map((item, index) => {
                            let splitData = item.split("?");
                            if (splitData.length === 0) return;
                            let fileName = splitData[0];
                            let fileLink = splitData[1];
                            return (
                              <div
                                className="font-size-08 mb-2"
                                key={fileName + index}
                              >
                                {fileName}
                                <span
                                  className="ml-2 btn btn-sm btn-danger p-1 font-size-08"
                                  onClick={() => {
                                    deleteFile(fileLink, fileName);
                                  }}
                                >
                                  삭제
                                </span>
                              </div>
                            );
                          })}{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  {/*<textarea*/}
                  {/*    className="form-control"*/}
                  {/*    rows="2"*/}
                  {/*    ref={$controller.prTITLE}*/}
                  {/*/>*/}
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  노출여부
                </label>
                <div className="col-sm-9">
                  <select
                    className="custom-select form-control"
                    ref={$controller.prUSEYN}
                  >
                    <option value="">미선택</option>
                    <option value="Y">사용</option>
                    <option value="N">중지</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={(e) => {
                  codeList.Modal.current.confirm(
                    `등록을 취소하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        closeModal();
                      }
                    }
                  );
                }}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// 선사, 콘솔사 로고리스트
const ModalLogoList = ({ selectCompLogo, setIsLogoListModalOpen }) => {
  const [text, setText] = useState("");
  const searchRef = useRef();

  const actionSearch = () => {
    setText(searchRef.current.value);
  };

  const CompLogoItemRender = useCallback((props) => {
    return <CompLogoItem {...props} />;
  }, []);
  const CompLogoItem = useCallback(
    React.memo((props) => {
      let item = { ...props.item };

      let borderTop = "border-top";
      if (props.index === 0) {
        borderTop = "";
      }

      let logoImage = item.blLOGO ? item.blLOGO.split("?")[0] : "";

      return (
        <div className={borderTop}>
          <div
            className="d-flex flex-row justify-content-start align-items-center p-2 cursor_pointer_bg_light"
            onClick={() =>
              selectCompLogo(item.blLOGO, item.blCompNM_KO, item.blFWDNO)
            }
          >
            <div
              className="d-flex flex-row justify-content-center align-items-center rounded border bg-light position-relative mr-3"
              style={{
                width: 50,
                height: 50,
                background: `url(${logoImage}) 50% 50%/contain no-repeat`,
              }}
            >
              {logoImage === "" && (
                <p className="font-weight-bold text-secondary text-center font-size-08">
                  LOGO
                </p>
              )}
            </div>
            <p className="font-weight-bold">
              {item.blCompNM}/{item.blCompNM_KO}
            </p>
          </div>
        </div>
      );
    })
  );

  return (
    <div className="modal_background" style={{ zIndex: 999999 }}>
      <div
        className="modal_body bg-white rounded"
        style={{ zIndex: 999999, minWidth: 600 }}
      >
        <div className="p-2 d-flex flex-row justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold mr-3">
            선사 · 항공사 로고 리스트
          </h6>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => setIsLogoListModalOpen(false)}
          >
            &times;
          </button>
        </div>
        <div className="p-2 border-top">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="업체명을 입력해주세요."
              ref={searchRef}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  actionSearch();
                }
              }}
            />
            <div className="input-group-btn">
              <button className="btn btn-secondary" onClick={actionSearch}>
                검색
              </button>
            </div>
          </div>
          <div className="mt-2" style={{ maxHeight: 400, overflowY: "scroll" }}>
            {logoList.length > 0 ? (
              logoList
                .filter(
                  (d) =>
                    d.blCompNM.toUpperCase().indexOf(text.toUpperCase()) > -1 ||
                    d.blCompNM_KO.indexOf(text) > -1
                )
                .map((item, index) => {
                  return (
                    <CompLogoItemRender key={index} item={item} index={index} />
                  );
                })
            ) : (
              <p>선사, 콘솔사의 로고데이터가 존재하지 않습니다.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
