/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import LogiTalk from "../../logiTalk/LogiTalk";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let sdTypeKind = {
  A: "보세창고",
  B: "일반창고",
};
let sdTypeGoods = {
  A: "드라이",
  B: "냉장",
  C: "냉동",
  D: "보온",
  E: "검역물",
  F: "수산물",
  G: "위험물",
};

export default function StoreDetailContact(props) {
  let {
    loginInfo,
    currentView,
    selectedStore,
    SELECTED_STORE_CLEAR,
    storeURLCopy,
    moveStoreDetailScroll,
    setIsContactView,
    isContactView,
    slideImagOpen,
    storeRequestInsert,
    storeReviewList,
    isLogiWork,
  } = props;

  const [mobile, setMobile] = useState(false);

  const controller = {
    srKey: useRef(), //창고 키
    srType: useRef(), // 문의종류
    srGoods: useRef(), // 화물종류
    srPacking: useRef(), // 포장상태
    srInOutType: useRef(), // 보광장소
    srCustomsYN: useRef(), // 보세여부
    srStartDate: useRef(), // 보관 예정 시작
    srEndDate: useRef(), // 보관 예정 종료
    srUserName: useRef(), // 회신 담당자명
    srEmail: useRef(), // 회신 이메일
    srMobile: useRef(), // 회신 전화번호
    srDesc: useRef(), // 문의내용
  };

  useEffect(() => {
    isMobile();
  }, []);

  useEffect(() => {
    if (loginInfo !== null) {
      if (controller.srKey.current) {
        controller.srUserName.current.value = loginInfo.user_nm;
        controller.srEmail.current.value = loginInfo.user_id;
        controller.srMobile.current.value = loginInfo.user_tel;
      }
    }
  }, [loginInfo]);

  const isMobile = () => {
    let UserAgent = navigator.userAgent;

    if (
      UserAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
      ) != null ||
      UserAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const storeRequestClear = () => {
    for (let key in controller) {
      if (key !== "srKey") {
        controller[key].current.value = "";
      }
    }
  };

  const contactDataHandler = () => {
    let uploadData = {};
    for (let key in controller) {
      if (controller[key].current) {
        uploadData[key] = controller[key].current.value
          ? controller[key].current.value
          : "";
      }
    }
    storeRequestInsert(uploadData);
  };

  const requestEnquiry = (managerCompCD, managerID) => {
    if (loginInfo) {
      codeList.Modal.current.confirm(
        `로지톡으로 담당자에게 연결하시겠습니까?`,
        (ret) => {
          if (ret) {
            // localStorage.setItem(
            //   "enquiryManager",
            //   `${managerCompCD}|${managerID}`
            // );

            localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
            window.open("/LogiTalk", "_blank", "width=1200, height=800");
          } else {
            codeList.Modal.current.alert("담당자 연결이 취소되었습니다.");
          }
        }
      );
    } else {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
    }
  };

  return (
    <div
      className={`d-inline-block bg-white border shadow d-flex flex-column ${
        currentView === 2 ? "position-fixed" : ""
      }`}
      style={{
        height: isLogiWork
          ? "100vh"
          : currentView !== 2
          ? "calc(100vh - 98px)"
          : currentView === 1
          ? "calc(100vh - 98px)"
          : "calc(100vh - 58px)",
        minWidth: currentView !== 2 ? 400 : "100vw",
        maxWidth: currentView !== 2 ? 450 : "100vw",
        left: 0,
        top: isLogiWork ? 0 : currentView === 2 ? 60 : 98,
        zIndex: 999,
      }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center text-secondary font-weight-bold w-100 border-bottom p-3 ">
        <h5 className="overflow-hidden text-dark text-truncate font-weight-bold m-0 p-0 flex-fill">
          {selectedStore.smName}
        </h5>{" "}
        <h2
          className="cursor_pointer m-0 p-0 px-2"
          onClick={() => {
            SELECTED_STORE_CLEAR();
          }}
        >
          &times;
        </h2>
      </div>
      <div className="overflow_scroll" id="storeDetail">
        <div className="border-bottom p-5 text-center">
          <h5 className="overflow-hidden text-dark text-truncate font-weight-bold m-0 p-0">
            {selectedStore.smName}
          </h5>
          <div className="d-flex flex-row justify-content-center">
            <div
              className="btn_1 mt-3 mr-2"
              onClick={() => {
                storeURLCopy();
              }}
            >
              창고정보 공유하기 <i className="fa-solid fa-share-from-square" />
            </div>
            <div
              className="btn_1 outline mt-3"
              onClick={() => {
                moveStoreDetailScroll();
              }}
            >
              문의하기 <i className="fa-solid fa-arrow-down-long" />
            </div>
          </div>
        </div>

        <div className="p-4">
          <div>
            <h5 className="border-bottom border-dark py-2 px-1 m-0  font-weight-bold">
              평점/리뷰
            </h5>
            <div className="m-0 p-0 w-100 mb-3">
              {storeReviewList.length > 0 ? (
                storeReviewList.slice(0, 3).map((review) => {
                  return (
                    <div
                      className="d-flex flex-row justify-content-start border-bottom py-3"
                      key={review.srIDX}
                    >
                      <span style={{ minWidth: 80 }}>{review.srUserName}</span>
                      <span className="flex-fill overflow_hidden text-truncate mx-2">
                        {review.isShow
                          ? review.srReviewText
                          : "비공개 댓글입니다."}
                      </span>
                      <span style={{ minWidth: 50 }}>
                        <i className="icon-star text-warning" />
                        {review.srRating * 2} 점
                      </span>
                      <span style={{ minWidth: 80 }}>{review.srEditDate}</span>
                    </div>
                  );
                })
              ) : (
                <p className="py-3">등록된 리뷰가 없습니다.</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-center">
              <button
                type="button"
                className="btn_1"
                data-toggle="modal"
                data-target="#StoreReviewModal"
              >
                리뷰등록 및 전체보기
              </button>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div>
            <h5 className="border-bottom border-dark py-2 px-1 m-0  font-weight-bold">
              업체정보
            </h5>

            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">업체명</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.smName}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">담당세관</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.smCustoms}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">대표전화</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.smTel}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">영업시간</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdWorkingTime}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">주 소</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                <p>{selectedStore.smAddrFull}</p>
                <div className="d-flex flex-row justify-content-start py-2">
                  <a
                    className="mr-1 btn_1 outline d-flex flex-row align-items-center"
                    href={`https://map.kakao.com/link/to/${selectedStore.smName},${selectedStore.lat},${selectedStore.lng}`}
                    target="_blank"
                  >
                    <div
                      className="mr-2"
                      style={{
                        background: `url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/snb/img_snb.png) no-repeat`,
                        width: 21,
                        height: 27,
                        backgroundPosition: "0 0",
                      }}
                    />
                    카카오 길찾기
                  </a>

                  <a
                    className="ml-1 btn_1 outline d-flex flex-row align-items-center"
                    href={
                      mobile
                        ? `nmap://route/car?dlat=${selectedStore.lat}&dlng=${selectedStore.lng}&dname=${selectedStore.smName}&appname=gbts.co.kr`
                        : `http://map.naver.com/index.nhn?elng=${selectedStore.lng}&elat=${selectedStore.lat}&pathType=0&showMap=true&etext=${selectedStore.smName}&menu=route`
                    }
                    target="_blank"
                  >
                    <div
                      className="mr-2"
                      style={{
                        backgroundImage: `url(
                            "https://ssl.pstatic.net/static/maps/v5/pc/20220121141622/home.png"
                          )`,
                        backgroundSize: "508px 492px",
                        width: 21,
                        height: 21,
                        backgroundPosition: "-468px -392px",
                      }}
                    />
                    길찾기
                  </a>
                </div>
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div
                className="col-4 p-0 m-0"
                onClick={() => {
                  setIsContactView(!isContactView);
                }}
              >
                담당자 정보보기{" "}
                {isContactView ? (
                  <i className="fa-solid fa-angle-up ml-1" />
                ) : (
                  <i className="fa-solid fa-angle-down ml-1" />
                )}
              </div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {isContactView && (
                  <div>
                    {!selectedStore.sdContact1 &&
                      !selectedStore.sdContact2 &&
                      !selectedStore.sdContact3 &&
                      !selectedStore.sdContact4 &&
                      "등록된 담당자 정보가 없습니다"}
                    {selectedStore.sdContact1 && (
                      <div className="p-0">
                        <div className="border-bottom p-2">
                          {selectedStore.sdContact1 && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자명 : {selectedStore.sdContact1}
                            </p>
                          )}
                          {selectedStore.sdContact1Tel && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 연락처 : {selectedStore.sdContact1Tel}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact1Email && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 이메일 : {selectedStore.sdContact1Email}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact1CompCD &&
                            selectedStore.sdContact1UserID && (
                              <div
                                className="btn_1"
                                onClick={() => {
                                  requestEnquiry(
                                    selectedStore.sdContact1CompCD,
                                    selectedStore.sdContact1UserID
                                  );
                                }}
                              >
                                로지톡으로 문의하기
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {selectedStore.sdContact2 && (
                      <div className="p-0">
                        <div className="border-bottom p-2">
                          {selectedStore.sdContact2 && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자명 : {selectedStore.sdContact2}
                            </p>
                          )}
                          {selectedStore.sdContact2Tel && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 연락처 : {selectedStore.sdContact2Tel}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact2Email && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 이메일 : {selectedStore.sdContact2Email}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact2CompCD &&
                            selectedStore.sdContact2UserID &&
                            loginInfo !== null && (
                              <Link
                                to="LogiTalk"
                                className="btn_1"
                                onClick={() => {
                                  requestEnquiry(
                                    selectedStore.sdContact2CompCD,
                                    selectedStore.sdContact2UserID
                                  );
                                }}
                              >
                                로지톡으로 문의하기
                              </Link>
                            )}
                        </div>
                      </div>
                    )}
                    {selectedStore.sdContact3 && (
                      <div className="p-0">
                        <div className="border-bottom p-2">
                          {selectedStore.sdContact3 && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자명 : {selectedStore.sdContact3}
                            </p>
                          )}
                          {selectedStore.sdContact3Tel && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 연락처 : {selectedStore.sdContact3Tel}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact3Email && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 이메일 : {selectedStore.sdContact3Email}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact3CompCD &&
                            selectedStore.sdContact3UserID &&
                            loginInfo !== null && (
                              <Link
                                to="/LogiTalk"
                                className="btn_1"
                                onClick={() => {
                                  requestEnquiry(
                                    selectedStore.sdContact3CompCD,
                                    selectedStore.sdContact3UserID
                                  );
                                }}
                              >
                                로지톡으로 문의하기
                              </Link>
                            )}
                        </div>
                      </div>
                    )}
                    {selectedStore.sdContact4 && (
                      <div className="p-0">
                        <div className="p-2">
                          {selectedStore.sdContact4 && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자명 : {selectedStore.sdContact4}
                            </p>
                          )}
                          {selectedStore.sdContact4Tel && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 연락처 : {selectedStore.sdContact4Tel}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact4Email && (
                            <p className="mb-1 p-0 text-secondary">
                              - 담당자 이메일 : {selectedStore.sdContact4Email}{" "}
                            </p>
                          )}
                          {selectedStore.sdContact4CompCD &&
                            selectedStore.sdContact4UserID &&
                            loginInfo !== null && (
                              <Link
                                to="LogiTalk"
                                className="btn_1"
                                onClick={() => {
                                  requestEnquiry(
                                    selectedStore.sdContact4CompCD,
                                    selectedStore.sdContact4UserID
                                  );
                                }}
                              >
                                로지톡으로 문의하기
                              </Link>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <h5 className="border-bottom border-dark py-2 px-1 m-0  font-weight-bold">
              창고전경사진
            </h5>
            <div className="m-0 p-0 w-100 d-flex flex-row flex-wrap justify-content-start py-3">
              {!selectedStore.sdPhoto && "- 등록된 사진이 없습니다."}
              {selectedStore.sdPhoto &&
                selectedStore.sdPhoto.split("|").map((photo, index) => {
                  let splitData = photo.split("?");
                  let imgLink = splitData[0];
                  return (
                    <div
                      key={index}
                      className="p-1"
                      style={{
                        width: "33.3333%",
                        height: 100,
                      }}
                    >
                      <div
                        className="w-100 h-100 cursor_pointer bg-light rounded border"
                        style={{
                          background: `url(${imgLink}) 50% 50%/contain no-repeat`,
                        }}
                        onClick={() => {
                          slideImagOpen(index);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <h5 className="border-bottom border-dark py-2 px-1 m-0 font-weight-bold">
              창고 상세 정보
            </h5>

            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">보세구분</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdTypeKind
                  ? selectedStore.sdTypeKind.split("|").map((kind) => {
                      return <span>{sdTypeKind[kind]}</span>;
                    })
                  : "등록된 정보가 없습니다."}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">취급화물</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdTypeGoods
                  ? selectedStore.sdTypeGoods.split("|").map((goods, index) => {
                      return `${sdTypeGoods[goods]} ${
                        selectedStore.sdTypeGoods.split("|").length - 1 > index
                          ? ", "
                          : ""
                      }
                      `;
                    })
                  : "등록된 정보가 없습니다."}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">옥내면적</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdExtentInside}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">옥외(야적)면적</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdExtentOutside}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">옥내장치가능화물</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdGoodsInside}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">옥외(야적)장치가능화물</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdGoodsOutside}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">보안장비</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdSecurity}
              </div>
            </div>
            <div className="border-bottom row py-3 m-0">
              <div className="col-4 p-0 m-0">보험가입현황</div>
              <div className="col p-0 m-0 font-weight-bold text-secondary">
                {selectedStore.sdInsure}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div>
            <h5 className="border-bottom border-dark py-2 px-1 m-0 font-weight-bold">
              기타 안내 사항
            </h5>

            <div className="border-bottom row py-3 m-0">
              {selectedStore.sdEtcMemo}
            </div>
          </div>
        </div>
        <div className="p-4">
          <h5 className="border-bottom border-dark py-2 px-1 m-0 font-weight-bold mb-3">
            문의 하기
          </h5>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                창고고유번호
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9  m-0 p-0">
                <input
                  disabled
                  type="text"
                  className="form-control"
                  placeholder="예) 견적, 업무제휴, 적업가능"
                  ref={controller.srKey}
                  value={selectedStore.smKey}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                문의종류
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9  m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="예) 견적, 업무제휴, 적업가능"
                  ref={controller.srType}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                화물종류
              </label>
              <div className="col-sm-9  m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="예) 고사리 "
                  ref={controller.srGoods}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                포장상태
              </label>
              <div className="col-sm-9  m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="예) 톤백"
                  ref={controller.srPacking}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                보관장소
              </label>
              <div className="col-sm-9  m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="예) 옥내 또는 비 맞지 않는 옥외"
                  ref={controller.srInOutType}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                보세여부
              </label>
              <div className="col-sm-9  m-0 p-0">
                <select className="form-control" ref={controller.srCustomsYN}>
                  <option value="">선택</option>
                  <option value="Y">보세</option>
                  <option value="N">일반</option>
                </select>
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2 ">
                보관예정기간
              </label>
              <div className="col-sm-9  m-0 p-0">
                <div className="d-flex flex-row justify-content-star align-items-center">
                  <input
                    className="form-control"
                    type="date"
                    ref={controller.srStartDate}
                    defaultValue={new Date().toISOString().slice(0, 10)}
                  />
                  <span className="px-2">-</span>
                  <input
                    className="form-control"
                    type="date"
                    ref={controller.srEndDate}
                    defaultValue={new Date().toISOString().slice(0, 10)}
                  />
                </div>

                <p className="p-0 m-0 text-danger">
                  ※ 미정인경우 기타 문의사항에 필수 작성 바랍니다.
                </p>
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                연락받을 담당자 정보
                <i style={{ color: "red", fontWeight: 700 }}>*</i>
              </label>
              <div className="col-sm-9  m-0 p-0 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="성함"
                  ref={controller.srUserName}
                />
              </div>
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2" />
              <div className="col-sm-9  m-0 p-0 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="이메일"
                  ref={controller.srEmail}
                />
              </div>
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2" />
              <div className="col-sm-9  m-0 p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="전화번호"
                  ref={controller.srMobile}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="mb-3 row d-flex flex-row align-items-center">
              <label className="col-form-label col-sm-3 text-end m-0 p-0 align-self-start pt-2">
                기타 문의 사항
              </label>
              <div className="col-sm-9  m-0 p-0">
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="기타 문의 사항을 자유롭게 적어주세요."
                  ref={controller.srDesc}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <div
              className="btn_1 outline mr-2"
              onClick={() => {
                storeRequestClear();
              }}
            >
              등록정보 초기화
            </div>
            <div
              className="btn_1"
              onClick={() => {
                contactDataHandler();
              }}
            >
              제출하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
