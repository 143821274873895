/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import axios from "axios";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const converter = require("xml-js");
const _mClassName = "SearchLogisticsDetail";

let sortModel = [
  {
    field: "USER_ID",
    sort: "asc",
  },
];

let isCompInfoOpen = true;
let isCompDetailOpen = true;
let isCompUsersOpen = true;

let multiChatUserList = [];

let totalCount = 100;
let totalPageCount = 1;
let page = 0;
let rowsPerPage = 5;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
export default function SearchLogisticsDetail(props) {
  const { isMobile, loginInfo, setSelectedComp } = props;

  const [compData, setCompData] = useState("");
  const [serverData, setServerData] = useState([]);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useRowStyles();

  useEffect(() => {
    let coData = socket.getLocalDataSet(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE
    );
    if (coData) {
      SELECT_COMP_DESC(coData);
      requestGBTSUserList(coData.COMP_CD);
      getUnipassData();
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE,
      _mClassName,
      (b, n) => {
        if (n) {
          SELECT_COMP_DESC(n);
          requestGBTSUserList(n.COMP_CD);
          getUnipassData();
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_ROOM_CREATE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (n["ret"]) {
          let data = n["data"];
          if (
            data.crmCreator === loginInfo.user_id &&
            data.crmCreatorSite === loginInfo.comp_cd
          ) {
            handleCloseCompDetail();
          }
        }
      }
    );

    return () => {
      handleCloseCompDetail();
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_ROOM_CREATE,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    document
      .querySelector("#comp_detail_wrap")
      .scrollTo({ top: 0, behavior: "smooth" });
  }, [compData]);

  //회사 소개 불러오기
  const SELECT_COMP_DESC = (data) => {
    if (!data.hasOwnProperty("COMP_CD")) return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_desc_select.php",
      msgID: msgID,
      data: {},
      sort: [...sortModel],
      COMP_CD: data.COMP_CD,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      let compInfo = {
        ...data,
        COMP_DESC: "",
      };
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            compInfo.COMP_DESC = newData["returnData"][0].COMP_DESC;
          }
        }
      }
      setCompData(compInfo);
    });
  };

  //업체 사용자정보 불러오기
  const requestGBTSUserList = (comp_cd) => {
    if (!comp_cd) return;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
      sort: [...sortModel],
      COMP_CD: comp_cd,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = [];
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].USE_GB !== "D") {
              if (newData["returnData"][i].USER_PIC) {
                newData["returnData"][i].USER_PIC = util.changeURIForThumbnail(
                  newData["returnData"][i].USER_PIC
                );
              }
              dataList.push(newData["returnData"][i]);
            }
          }
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          getLoginState(newData["returnData"]);
          setServerData(newData["returnData"]);
          setIsLoading(false);
        }
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    page = newPage - 1;
    requestGBTSUserList(compData.COMP_CD);
  };

  //사용자 접속상태 받아오기
  const getLoginState = (userData) => {
    let msgID = util.makeUUIDv4();
    let dataList = [...userData];
    let userList = [];
    for (let i = 0; i < dataList.length; i++) {
      userList.push({
        COMP_CD: dataList[i].COMP_CD,
        USER_ID: dataList[i].USER_ID,
      });
    }
    let data = {
      method: MsgIDList.EVENT_TALK_LOGIN_STATE_GET,
      msgID: msgID,
      data: {
        users: [...userList],
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          for (let i = 0; i < dataList.length; i++) {
            newData["returnData"].forEach((val) => {
              if (val.USER_ID === dataList[i].USER_ID) {
                dataList[i].login_state = val.STATE;
              }
            });
          }
          setServerData([...dataList]);
        }
      }
    });
    setIsLoading(false);
  };

  //즐겨찾기 사용자 추가
  const INSERT_TALK_FAV_USER = (addUser) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_talk_fav_user_insert.php",
      msgID: msgID,
      data: {},
      cfuUserSite: loginInfo.comp_cd, //내 comp_cd
      cfuUserID: loginInfo.user_id, //내 user_id
      cfuFavUserSite: addUser.COMP_CD, //대상 comp_cd
      cfuFavUserID: addUser.USER_ID, //대상 user id
      cfuCustomNick: addUser.USER_NICK, //사용자지정 닉네임
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert("즐겨찾기가 완료되었습니다.");
          socket.fireLocalEventListener(
            MsgIDList.EVENT_TALK_FAV_UESR_INSERT,
            _mClassName,
            newData,
            () => {}
          );
        } else {
          if (newData["returnData"].length) {
            codeList.Modal.current.alert(
              "이미 등록되어 있거나 신청할 수 없습니다."
            );
          } else {
            codeList.Modal.current.alert(
              "오류로 인해 신청이 불가합니다. 관리자에게 문의바랍니다."
            );
          }
        }
      }
    });
  };

  //화물운송주선업자 내역
  const getUnipassData = () => {
    //https://unipass.customs.go.kr:38010/ext/rest/frwrBrkdQry/retrieveFrwrBrkd?crkyCn=n260s292v041z276v020t010o0&frwrSgn=TJTJ
    //B, E, F

    //보세구역
    //https://unipass.customs.go.kr:38010/ext/rest/shedInfoQry/retrieveShedInfo?crkyCn=y200z222q041f206d040e040a0&snarSgn=02012018

    //관세사
    //https://unipass.customs.go.kr:38010/ext/rest/lcaBrkdQry/retrieveLcaBrkd?crkyCn=y230s252q021g206y040w060m0&lcaSgn=12776
    axios
      .get(
        `/unipass/ext/rest/frwrBrkdQry/retrieveFrwrBrkd?crkyCn=n260s292v041z276v020t010o0&frwrSgn=TJTJ`
      )
      .then((response) => {
        //console.log("axios handleData");
        let result = response.data;
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };

  const addMultiChatUser = (user) => {
    multiChatUserList = [...multiChatUserList, user];
  };

  const subtractMultiChatUser = (user) => {
    let dataList = [...multiChatUserList];
    if (dataList.length === 0) return;
    let deleteIDX = dataList.findIndex(
      (item) => item.COMP_CD === user.COMP_CD && item.USER_ID === user.USER_ID
    );
    dataList.splice(deleteIDX, 1);
    multiChatUserList = [...dataList];
  };

  const actionCreateChatRoom = () => {
    if (multiChatUserList.length === 0) {
      codeList.Modal.current.alert("대화 상대를 선택해주세요");
      return;
    }
    EVENT_SOCKET_ROOM_CREATE();
  };

  //채팅방  생성
  const EVENT_SOCKET_ROOM_CREATE = () => {
    let userList = [];
    for (let i = 0; i < multiChatUserList.length; i++) {
      userList.push({
        cruUserSite: multiChatUserList[i].COMP_CD,
        cruUserID: multiChatUserList[i].USER_ID,
      });
    }

    let msgID = util.makeUUIDv4();
    let crmType = multiChatUserList.length > 1 ? 2 : 1;
    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_CREATE,
      msgID: msgID,
      data: {
        crmMainKey: "",
        crmType: crmType, //1:PtoP 2:Private 3:Public 오픈채팅 4:PublicSecret 비공개
        crmAllow: "N",
        crmName: "Private Chat",
        crmPWD: "",
        crmLastMSG: `${
          loginInfo.USER_NICK ? loginInfo.USER_NICK : loginInfo.user_nm
        }님이 대화를 요청하였습니다.`,
        crmCreator: loginInfo.user_id,
        crmCreatorSite: loginInfo.comp_cd,
        chatUserList: [...userList],
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  //상세정보 창 닫기
  const handleCloseCompDetail = () => {
    isCompInfoOpen = true;
    isCompDetailOpen = true;
    isCompUsersOpen = true;
    multiChatUserList = [];
    setSelectedComp(false);
    setServerData([]);
    setCompData("");
  };

  return (
    <>
      {isLoading && (
        <div
          className="w-100 h-100 position-absolute rounded d-flex flex-column justify-content-center align-items-center"
          style={{ top: 0, left: 0, background: "rgba(0,0,0,0.4)" }}
        >
          <h4 className="text-white">...Loading</h4>
        </div>
      )}
      {compData !== "" && (
        <div style={{ width: isMobile ? "100%" : 400 }}>
          <div className="d-flex flex-row justify-content-between align-items-center border-bottom">
            <h5 className="font-weight-bold text-info pb-2">
              {compData.COMP_NM} 프로필
            </h5>
            <h5
              className="mb-0 pb-2 cursor_pointer"
              onClick={() => {
                handleCloseCompDetail();
              }}
            >
              &times;
            </h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center pt-3 pb-5 border-bottom">
            {(compData.COMP_LOGO || compData.SHIP_IMG_URL10) && (
              <div
                style={{
                  width: 100,
                  height: 100,
                  background: `url(${
                    compData.COMP_LOGO || compData.SHIP_IMG_URL10
                  }) 50% 50%/contain no-repeat`,
                }}
              />
            )}
            <h3 className="font-weight-bold mb-3 mt-2">{compData.COMP_NM}</h3>
            <div className="d-flex flex-row">
              <a className="btn btn-sm btn-primary mr-2" href="#compUserList">
                사용자와 대화하기
                <i className="fa-solid fa-arrow-down ml-1" />
              </a>
              {compData.COMP_HOMEPAGE && (
                <button
                  className="btn btn-sm btn-outline-dark"
                  onClick={() => {
                    window.open(
                      compData.COMP_HOMEPAGE,
                      "_blank",
                      "width=1200, height=800"
                    );
                  }}
                >
                  홈페이지 바로가기
                  <i className="fa-solid fa-globe ml-1" />
                </button>
              )}
            </div>
          </div>
          <div className="px-2 py-5">
            <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
              <h4 className="mb-0 font-weight-bold">업체 정보</h4>
              <h4 className="mb-0 cursor_pointer">
                <i
                  className={` ${
                    isCompDetailOpen
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }`}
                  onClick={() => {
                    isCompDetailOpen = !isCompDetailOpen;
                    setIsNeedUpdate(!isNeedUpdate);
                  }}
                />
              </h4>
            </div>

            <div className={`${isCompDetailOpen ? "" : "d-none"}`}>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">부호</div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_C_NUM || "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">업체명</div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_NM}{" "}
                  {compData.COMP_NM_ENG && `(${compData.COMP_NM_ENG})`}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">
                  사업자등록번호
                </div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_NUM
                    ? util.getAutoHyphenBizID(compData.COMP_NUM)
                    : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">홈페이지</div>
                <div className="col-8 m-0 p-0">
                  <p
                    className={`${
                      compData.COMP_HOMEPAGE ? "text-gbts cursor_pointer" : ""
                    }`}
                    style={{
                      textDecoration: compData.COMP_HOMEPAGE
                        ? "underline"
                        : "unset",
                    }}
                    onClick={() => {
                      if (compData.COMP_HOMEPAGE) {
                        window.open(
                          compData.COMP_HOMEPAGE,
                          "_blank",
                          "width=1200, height=800"
                        );
                      }
                    }}
                  >
                    {compData.COMP_HOMEPAGE ? compData.COMP_HOMEPAGE : "-"}
                  </p>
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">대표자명</div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_CEO_NM ? compData.COMP_CEO_NM : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">대표전화</div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_TEL ? compData.COMP_TEL : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">팩스번호</div>
                <div className="col-8 m-0 p-0">
                  {compData.COMP_FAX ? compData.COMP_FAX : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">이메일</div>
                <div className="col-8 m-0 p-0">
                  {" "}
                  {compData.COMP_MAIL ? compData.COMP_MAIL : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">주소</div>
                <div className="col-8 m-0 p-0">
                  <p>{compData.COMP_ADDR}</p>
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">태그</div>
                <div className="col-8 m-0 p-0">
                  {" "}
                  {compData.COMP_TAG ? compData.COMP_TAG : "-"}
                </div>
              </div>
              <div className="row m-0 py-3 border-bottom">
                <div className="col-4 m-0 p-0 font-weight-bold">슬로건</div>
                <div className="col-8 m-0 p-0">
                  {" "}
                  {compData.COMP_SLOGUN ? compData.COMP_SLOGUN : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="px-2 pb-5">
            <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
              <h4 className="mb-0 font-weight-bold">업체 소개</h4>
              <h4 className="mb-0 cursor_pointer">
                <i
                  className={`${
                    isCompInfoOpen
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  } cursor-pointer`}
                  onClick={() => {
                    isCompInfoOpen = !isCompInfoOpen;
                    setIsNeedUpdate(!isNeedUpdate);
                  }}
                />
              </h4>
            </div>

            <div className={`py-2 ${isCompInfoOpen ? "" : "d-none"}`}>
              {compData.COMP_DESC ? (
                <div
                  id="search_comp_detail_desc"
                  dangerouslySetInnerHTML={{ __html: compData.COMP_DESC }}
                  // style={{ maxWidth: isMobile ? "100%" : 400 }}
                />
              ) : (
                <div className="p-2 bg-light rounded">
                  -등록된 소개글이 존재하지 않습니다-
                </div>
              )}
            </div>
          </div>

          <div className="px-2 pb-5" id="compUserList">
            <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
              <div>
                <h4 className="mb-0 font-weight-bold">
                  사용자 보기{" "}
                  <button
                    className="btn btn-sm btn-secondary mb-1"
                    onClick={() => {
                      actionCreateChatRoom();
                    }}
                  >
                    채팅 생성
                  </button>
                </h4>
              </div>
              <h4 className="mb-0 cursor_pointer">
                <i
                  className={`${
                    isCompUsersOpen
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  } cursor-pointer`}
                  onClick={() => {
                    isCompUsersOpen = !isCompUsersOpen;
                    setIsNeedUpdate(!isNeedUpdate);
                  }}
                />
              </h4>
            </div>
            <p className="mt-2">
              ※ 사용자 정보 좌측 체크박스 체크 후 채팅생성 버튼을 클릭하면 해당
              사용자와 대화가 가능합니다.
            </p>
            <div className={`py-2 ${isCompUsersOpen ? "" : "d-none"}`}>
              {serverData.length > 0
                ? serverData.map((user) => {
                    let nameCardUrl = "";
                    if (user.USER_NAME_CARD) {
                      let split_data = user.USER_NAME_CARD.split("?");
                      nameCardUrl = split_data.find(
                        (str) => str.indexOf("https://gbts") > -1
                      );
                    }

                    let userTeam = `${
                      user.USER_TEAM_NM ? "[" + user.USER_TEAM_NM + "] " : ""
                    }${user.USER_PART_NM}`;

                    if (isMobile) {
                      if (userTeam.length > 12) {
                        userTeam = userTeam.slice(0, 11) + "...";
                      }
                    }

                    if (
                      user.COMP_CD !== loginInfo.comp_cd ||
                      user.USER_ID !== loginInfo.user_id
                    ) {
                      return (
                        <div
                          key={`${user.COMP_CD}_${user.USER_ID}`}
                          className="p-2 mb-2 border-bottom d-flex flex-row justify-content-between align-items-center"
                        >
                          <div className="d-flex flex-row justify-content-start align-items-center mr-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  addMultiChatUser(user);
                                } else {
                                  subtractMultiChatUser(user);
                                }
                              }}
                            />
                            <div
                              className="border rounded-circle mr-3 position-relative"
                              style={{
                                width: 60,
                                minWidth: 60,
                                height: 60,
                                background: `url(${
                                  user.USER_PIC ||
                                  "https://www.gbts.co.kr/images/user_pic-50x50.png"
                                }) 50% 50% / cover no-repeat`,
                              }}
                            >
                              <div
                                className={`position-absolute border ${
                                  user["login_state"]
                                    ? "bg-info"
                                    : "bg-secondary"
                                } rounded-circle`}
                                style={{
                                  top: 0,
                                  left: 0,
                                  width: 15,
                                  height: 15,
                                }}
                              />
                            </div>
                            <div className="text-secondary">
                              {(user.USER_TEAM_NM || user.USER_PART_NM) && (
                                <p className="w-100 ellipsis_1">{userTeam}</p>
                              )}
                              <p className="h6 mb-0 font-weight-bold">
                                {user.USER_NICK || user.USER_NM}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center text-gbts">
                            {nameCardUrl && (
                              <span
                                className="h3 mb-0 mr-3 cursor_pointer"
                                onClick={() => {
                                  window.open(
                                    nameCardUrl,
                                    "_blank",
                                    "width=1000, height=600"
                                  );
                                }}
                              >
                                <i className="fa-regular fa-address-card" />
                              </span>
                            )}
                            <span
                              className="h5 mb-0 mr-2 cursor_pointer"
                              onClick={() => INSERT_TALK_FAV_USER(user)}
                            >
                              <i className="fa-solid fa-user-plus" />
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })
                : "- 등록된 사용자 정보가 존재하지 않습니다."}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
