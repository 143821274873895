import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SectionTitle from "../../SectionTitle";
import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import TableBody from "@material-ui/core/TableBody";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const headCells = [
  {
    field: "COMP_NM",
    headerName: "항목",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params._data}
        </span>
      );
    },
  },
  {
    field: "BIZ_COST",
    headerName: "거래금액",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params._data}
        </span>
      );
    },
  },
  {
    field: "BIZ_PER",
    headerName: "거래비중",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params._data}
        </span>
      );
    },
  },
  {
    field: "CREDIT_RATING",
    headerName: "신용도",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params._data}
        </span>
      );
    },
  },
];

export default function WhereToBuyList({ compInfo, isExistData }) {
  let dataList = [];
  if (isExistData && compInfo !== null) {
    for (let item of compInfo["6000035"]) {
      let index = item.item_nm.charAt(item.item_nm.length - 1) - 1;

      if (dataList[index] === undefined) {
        dataList[index] = {};
      }

      let item_nm = item.item_nm.replace(/[0-9]/, "");
      item_nm = item_nm.replace(/ /, "");
      switch (item_nm) {
        case "업체수":
          dataList[index]["COMP_NM"] = item.item_val;
          break;
        case "거래금액":
          dataList[index]["BIZ_COST"] = util.addCommas(item.item_val);
          break;
        case "거래비중":
          dataList[index]["BIZ_PER"] = item.item_val + "%";
          break;
        case "신용도":
          dataList[index]["CREDIT_RATING"] = item.item_val;
          break;
      }
    }
  }

  return (
    <div className="mb-5">
      <SectionTitle
        title="주요 구매처"
        rightElement={<p className="text-right m-0">단위 : 천원</p>}
      />{" "}
      <div className="border-top border-bottom border-freiscop">
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.field}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    style={{
                      background: "rgba(0,32,96,0.1)",
                      textAlign: "center",
                    }}
                  >
                    {headCell.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.map((row, index) => {
                return (
                  <TableRow role="checkbox" key={index}>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.field}
                        padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
                        style={{ textAlign: "center" }}
                      >
                        {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
                          ? headCell.renderCell({
                              _data: row[headCell.field],
                              currentData: row,
                              currentHeader: headCell.field,
                              headCells: headCells,
                            })
                          : headCell.hasOwnProperty("valueGetter")
                          ? headCell.valueGetter({
                              _data: row[headCell.field],
                              currentData: row,
                              currentHeader: headCell.field,
                              headCells: headCells,
                            })
                          : row[headCell.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
