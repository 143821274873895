/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from "react";
import CodeList from "../lib/CodeList";
import $ from "jquery";
import { Link } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import axios from "axios";
import UploadImageToS3WithPhp from "../lib/UploadImageToS3WithPhp";
import LogoSvg from "../assets/LogoSvg";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let isCheck = false;
let isUser = false;
export default function RegistModal() {
  const [activeRegist, setActiveRegist] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState("");
  const [compGB, setCompGB] = useState("");

  const [registView, setRegistView] = useState(0);

  const $controllers = {
    comp_gb: useRef(),
    comp_mail: useRef(),
    comp_num: useRef(),
    comp_nm: useRef(),
    comp_c_num: useRef(),
    fwd_no: useRef(),
    comp_ceo_nm: useRef(),
    comp_addr: useRef(),
    comp_upte: useRef(),
    comp_jong: useRef(),
    comp_tel: useRef(),
    comp_fax: useRef(),
    comp_zip: useRef(),
    img_saup: useRef(),
  };

  const $termsController = {
    termsOfUse: useRef(),
    mallOperationPolicy: useRef(),
    talkOperationPolicy: useRef(),
    privacyPolicy: useRef(),
    disclaimer: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      "RegistModal",
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        "RegistModal"
      );
    };
  }, []);

  const fileUploaded = (val) => {
    /*
    ret: true,
            fileName: `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${region}/${name}`,
     */

    // console.log(val.ret);
    if (val.ret) {
      let changedFileName = val.fileName;
      /*let filename = uploadFiles;
      if (filename !== "") {
        filename += "|";
      }*/
      let filename = `${val.realFileName}*${changedFileName}`;

      console.log("filename", filename);

      setUploadFiles(filename);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //첨부파일 삭제
  const deleteFile = (url, name) => {
    let splitData = uploadFiles.split("|");
    let index = splitData.findIndex((item) => {
      // console.log(item, `${name}:${url}`, item === `${name}:${url}`);
      return item === `${name}*${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      // console.log(pmFiles);
      setUploadFiles(pmFiles);
    }
  };

  const actionSave = (e) => {
    /*console.log("uploadFiles", uploadFiles.split("*")[1]);
    return;*/
    if (!isCheck) {
      codeList.Modal.current.alert(
        "사업자번호 중복확인 후 가입신청이 가능합니다."
      );
      return;
    }
    if (isUser) {
      codeList.Modal.current.alert(
        "이미 등록된 사업자등록번호 입니다. 관리자에게 문의해주세요"
      );
      return;
    }

    setLoading(true);
    if (checkValidation()) {
      let splitData = uploadFiles.split("*");
      if (splitData.length === 1) return null;
      let fileName = splitData[0];
      let fileLink = `${splitData[1]}`;

      let updateData = {
        comp_gb: $controllers.comp_gb.current.value,
        comp_mail: $controllers.comp_mail.current.value,
        comp_num: $controllers.comp_num.current.value,
        comp_nm: $controllers.comp_nm.current.value,
        comp_c_num: $controllers.comp_c_num.current.value,
        fwd_no: $controllers.fwd_no.current.value,
        comp_ceo_nm: $controllers.comp_ceo_nm.current.value,
        comp_addr: $controllers.comp_addr.current.value,
        comp_upte: $controllers.comp_upte.current.value,
        comp_jong: $controllers.comp_jong.current.value,
        comp_tel: $controllers.comp_tel.current.value,
        comp_fax: $controllers.comp_fax.current.value,
        comp_zip: $controllers.comp_zip.current.value,
        img_saup: fileLink,
      };
      requestUserUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const CHECK_COMP_NUM = () => {
    setLoading(true);
    if ($controllers.comp_num.current.value.trim === "") {
      codeList.Modal.current.alert("사업자번호를 입력해주세요");
      return;
    }

    // let compNumCheck = util.validationBizID(
    //   $controllers.comp_num.current.value
    // );
    // if (!compNumCheck) {
    //   codeList.Modal.current.alert("유효하지 않은 사업자 등록번호입니다.");
    //   return;
    // }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/join/JS_comp_join_check.php",
      msgID: msgID,
      data: {},
      COMP_NUM: $controllers.comp_num.current.value,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      console.log("CHECK_COMP_NUM", newData);
      if (newData) {
        if (newData["ret"] === true) {
          if (newData["cnt"] === 1) {
            codeList.Modal.current.alert("이미 가입된 사업자번호입니다.");
            isUser = true;
          } else {
            codeList.Modal.current.alert("가입이 가능한 사업자 번호입니다");
            isUser = false;
            isCheck = true;
          }
        } else {
          codeList.Modal.current.alert(
            "사업자번호 중복확인중에 오류가 발생하였습니다. 관리자에게 문의 바랍니다."
          );
        }
        setLoading(false);
      }
    });
  };

  const checkValidation = () => {
    if ($controllers.comp_gb.current.value.trim() === "") {
      codeList.Modal.current.alert("업체구분을 선택해주세요", () => {});
      return false;
    }
    if ($controllers.comp_mail.current.value.trim() === "") {
      codeList.Modal.current.alert("메일주소를 입력해주세요.", () => {});
      return false;
    }
    if ($controllers.comp_num.current.value.trim() === "") {
      codeList.Modal.current.alert("사업자번호를 입력해주세요", () => {});
      return false;
    }

    if ($controllers.comp_nm.current.value.trim() === "") {
      codeList.Modal.current.alert("업체명을 입력해주세요", () => {});
      return false;
    }

    if ($controllers.comp_ceo_nm.current.value.trim() === "") {
      codeList.Modal.current.alert("대표자명을 입력해주세요", () => {});
      return false;
    }

    if ($controllers.comp_tel.current.value.trim() === "") {
      codeList.Modal.current.alert("연락처를 입력해주세요", () => {});
      return false;
    }
    if (uploadFiles === "") {
      codeList.Modal.current.alert("사업자등록증을 업로드 해주세요", () => {});
      return false;
    }

    for (let key in $termsController) {
      if ($termsController[key].current.checked === false) {
        codeList.Modal.current.alert("전체 약관은 필수동의입니다.", () => {});
        return false;
      }
    }

    return true;
  };

  const requestUserUpdate = (updateData) => {
    // console.log("JS_join_gbts_user");

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/join/JS_join_gbts_user.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          codeList.Modal.current.alert(
            `정상적으로 회원가입이 완료되었습니다.`,
            () => {}
          );

          for (let key in $controllers) {
            if ($controllers[key].current) {
              $controllers[key].current.value = "";
            }
          }
          for (let key in $termsController) {
            if ($termsController[key].current) {
              $termsController[key].current.checked = false;
            }
          }

          isCheck = false;
          isUser = false;
          setUploadFiles("");
          $("#registModalClose").click();
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/join/js_join_email_send.php",
            msgID: msgID,
            data: {},
            comp_cd: newData.comp_cd,
            site_gb: "프레이스콥(freiscop)",
            ...updateData,
          };

          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {});
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            `회원가입신청 중 오류가 발생하였습니다. 잠시 후 다시 시도 부탁드립니다.`,
            () => {}
          );
        }
      }
      setLoading(false);
    });
  };

  const Cancel = () => {
    $controllers.bbIDX.current.value = "";
    $controllers.bbIDX.current.disabled = true;
    $controllers.bbStype.current.value = "";
    $controllers.bbTITLE.current.value = "";
    $controllers.bbCONTENTS.current.value = "";
    $controllers.bbFiles.current.value = "";
    $controllers.bbUSEYN.current.value = "";
    setUploadFiles("");
  };

  const comp_gb_changed = () => {
    setCompGB($controllers.comp_gb.current.value);
  };

  return (
    <div
      className="modal fade"
      id="registModal"
      tabIndex="-1"
      aria-labelledby="registModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header" style={{ borderBottom: "none" }}>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="registModalClose"
              onClick={() => {
                isCheck = false;
                isUser = false;

                setRegistView(0);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="text-lg-center">
                <div id="logo" style={{ marginTop: "-30px" }}>
                  <LogoSvg height="60px" />
                </div>
                <div className="logo  mt-4">
                  <h6 className="m-0 mb-1 text-secondary font-weight-bold">
                    Freiscop 회원가입하시면 GBTS(로지톡, 로지웍, 로지킵)를
                    무료이용할 수 있습니다.
                  </h6>
                </div>
                {/*아이디 패스워드 입력*/}
                <div
                  className={`py-3 ${
                    activeRegist === 0 ? "d-block" : "d-none"
                  }`}
                >
                  <div className={registView === 0 ? "" : "d-none"}>
                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        업체구분{" "}
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="custom-select form-control"
                          ref={$controllers.comp_gb}
                          onChange={comp_gb_changed}
                        >
                          <option value="">선택</option>
                          <option value="A">화주</option>
                          <option value="B">포워더 (선사)</option>
                          <option value="C">운송사</option>
                          <option value="D">보험사</option>
                          <option value="E">관세사</option>
                          <option value="F">보세창고</option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        사업자번호
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9 d-flex flex-row justify-content-between">
                        <div className="flex-fill mr-2">
                          <input
                            maxLength={10}
                            type="text"
                            className="form-control"
                            placeholder="'-'를 제외하고 입력해주세요"
                            ref={$controllers.comp_num}
                            disabled={isCheck}
                          />
                        </div>
                        <button
                          className="btn_1"
                          onClick={() => CHECK_COMP_NUM()}
                          disabled={isCheck}
                        >
                          중복확인
                        </button>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        사업자등록증{" "}
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <UploadImageToS3WithPhp
                          region={"saup"}
                          callback={fileUploaded}
                        />
                        {uploadFiles !== "" &&
                          uploadFiles.split("|").map((v, i) => {
                            let splitData = v.split("*");
                            if (splitData.length === 1) return null;
                            let fileName = splitData[0];
                            let fileLink = `${splitData[1]}`;
                            return (
                              <div
                                className="my-2"
                                key={v}
                                style={{ textAlign: "start" }}
                              >
                                <button
                                  className="btn btn-danger mr-2"
                                  onClick={() => {
                                    deleteFile(fileLink, fileName);
                                  }}
                                >
                                  삭제
                                </button>
                                {fileName.endsWith(".pdf") && (
                                  <a
                                    className="font-weight-bold text-decoration-underline"
                                    href={fileLink}
                                    download={fileName}
                                    target="_blank"
                                  >
                                    {fileName}
                                  </a>
                                )}
                                {!fileName.endsWith(".pdf") && (
                                  <a
                                    className="font-weight-bold text-decoration-underline"
                                    href={fileLink}
                                    download={fileName}
                                    target="_blank"
                                  >
                                    {fileName}
                                  </a>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        업체명{" "}
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_nm}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        {compGB === "" && "업체고유 부호"}
                        {compGB === "A" && "통관고유부호"}
                        {compGB === "B" && "통관고유부호"}
                        {compGB === "C" && "운송사부호"}
                        {compGB === "D" && "보험사부호"}
                        {compGB === "E" && "관세사부호"}
                        {compGB === "F" && "보세구역부호"}
                        {(compGB === "E" || compGB === "F") && (
                          <i style={{ color: "red", fontWeight: 700 }}>*</i>
                        )}
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          ref={$controllers.comp_c_num}
                        />
                      </div>
                    </div>

                    <div
                      className="mb-3 row"
                      style={
                        compGB !== "B"
                          ? { display: "none" }
                          : { display: "flex" }
                      }
                    >
                      <label className="col-form-label col-sm-3 text-end">
                        포워더(선사) 부호
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          ref={$controllers.fwd_no}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        대표자명
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_ceo_nm}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row" style={{ display: "none" }}>
                      <label className="col-form-label col-sm-3 text-end">
                        주소
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_addr}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row" style={{ display: "none" }}>
                      <label className="col-form-label col-sm-3 text-end">
                        업태
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_upte}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row" style={{ display: "none" }}>
                      <label className="col-form-label col-sm-3 text-end">
                        종목
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_jong}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        연락처{" "}
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_tel}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row" style={{ display: "none" }}>
                      <label className="col-form-label col-sm-3 text-end">
                        팩스번호
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_fax}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row" style={{ display: "none" }}>
                      <label className="col-form-label col-sm-3 text-end">
                        우편번호
                      </label>
                      <div className="col-sm-9">
                        <input
                          maxLength={5}
                          type="text"
                          className="form-control"
                          ref={$controllers.comp_zip}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-form-label col-sm-3 text-end">
                        이메일{" "}
                        <i style={{ color: "red", fontWeight: 700 }}>*</i>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="가입신청 결과가 입력한 메일 주소로 발송됩니다."
                          ref={$controllers.comp_mail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3 text-end">
                      약관동의
                      <i style={{ color: "red", fontWeight: 700 }}>*</i>
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2 font-weight-bold">
                        <label className="container_check m-0 p-0 mr-3">
                          <input
                            type="checkbox"
                            className="position-static"
                            onChange={(e) => {
                              let val = e.target.checked;
                              for (let key in $termsController) {
                                if ($termsController[key].current) {
                                  $termsController[key].current.checked = val;
                                }
                              }
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        전체동의
                      </div>

                      <div className="d-flex flex-row flex-wrap">
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.termsOfUse}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/termsOfUse"
                            target="_blank"
                            className="align-middle"
                          >
                            GBTS 이용약관 동의
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.mallOperationPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/mallOperationPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            프레이스콥 운영정책 확인
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.talkOperationPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/talkOperationPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            로지톡 운영정책 확인
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.privacyPolicy}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/privacyPolicy"
                            target="_blank"
                            className="align-middle"
                          >
                            개인정보 처리방침 동의
                          </Link>
                        </div>
                        <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
                          <label className="container_check m-0 p-0 mr-3">
                            <input
                              type="checkbox"
                              className="position-static"
                              ref={$termsController.disclaimer}
                            />
                            <span className="checkmark" />
                          </label>
                          <Link
                            to="/Terms/disclaimer"
                            target="_blank"
                            className="align-middle"
                          >
                            면책공고 확인
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn_1"
              disabled={loading}
              onClick={() => {
                actionSave();
              }}
            >
              가입신청
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
