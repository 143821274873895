import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Select from "react-dropdown-select";

import PriceFclDefaultManageTable from "./component/PriceFclDefaultManageTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "PriceFclDefaultManage";
let port_list = [];
export default function PriceFclDefaultManage(props) {
  const { pagesOpen } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const fileRef = useRef();
  const controller = {
    SP_POL: useRef(),
    SP_POD: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      if (port_list.length === 0) {
        if (codeList.codeCountryFullName.length === 0) {
          socket.addLocalEventListener(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
            _mClassName,
            (oldV, newV) => {
              port_list = newV;
              setIsNeedUpdate(!isNeedUpdate);
            }
          );
        } else {
          port_list = codeList.codeCountryFullName;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const actionSearch = () => {
    let filters = {
      SP_POL: controller.SP_POL.current.value,
      SP_POD: controller.SP_POD.current.value,
    };

    socket.fireLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_FCL_PRICE_DEFAULT_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const exportExcel = () => {
    codeList.Modal.current.confirm(
      "엑셀파일을 다운로드 하시겠습니까?",
      (ret) => {
        if (ret) {
          axios({
            method: "POST",
            url: "//www.gbts.co.kr/backside/priceFclDefault/JS_price_fcl_default_export_excel.php",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              SP_POL: controller.SP_POL.current.value,
              SP_POD: controller.SP_POD.current.value,
            },
          })
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([response.data], {
                  type: response.headers["content-type"],
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `스케줄(FCL) 기본운임_${util.todayDate("YYYYMMDD")}.xls`
              );
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              codeList.Modal.current.alert(
                "오류가 발생하였습니다. 다시 시도해주세요"
              );
            });
        }
      }
    );
  };
  const importExcel = (e) => {
    let file = e.target.files[0],
      name = file.name,
      size = file.size,
      type = file.type;

    if (validation(name, size, type)) {
      requestExcelUpload(e.target.files, function (ret, msg) {
        let type = "error";
        if (ret === "Y") {
          codeList.Modal.current.alert(msg, () => {
            fileRef.current.value = "";
            actionSearch();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요.",
            () => {
              console.log(msg);
            }
          );
        }
      });
    }
  };
  //엑셀 대용량 업로드 통신
  const requestExcelUpload = (fileData, callback) => {
    const formData = new FormData();
    formData.append("files", fileData[0]);

    axios
      .post(
        "//www.gbts.co.kr/backside/priceFclDefault/JS_price_fcl_default_import_excel.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.RESULT === "Y") {
          callback(response.data.RESULT, response.data.MSG);
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };
  // 엑셀파일 업로드 유효성 검사
  const validation = (name, size, type) => {
    let reg = /\.(xls|xlsx)$/i;

    if (!reg.test(name)) {
      codeList.Modal.current.alert(
        "지원하지 않는 형식 입니다.\nExcel 파일만 가능합니다.",
        () => {}
      );
      return false;
    }
    if (size > 1000000 * 10) {
      codeList.Modal.current.alert("용량은 10MB가 이하로 해주세요.", () => {});
      return false;
    }
    return true;
  };
  const htmlID = `customFile_${util.makeUUIDv4Short()}`;
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-row justify-content-end align-items-center mb-2">
                <button
                  className="ml-2 btn btn-sm btn-secondary"
                  onClick={exportExcel}
                >
                  엑셀 다운로드
                </button>

                <input
                  type="file"
                  className="d-none"
                  id={htmlID}
                  ref={fileRef}
                  onChange={(e) => {
                    importExcel(e);
                  }}
                />
                <label
                  className="m-0 ml-2 btn btn-sm btn-primary"
                  htmlFor={htmlID}
                >
                  엑셀 업로드
                </label>
              </div>
              <div className="d-flex flex-row justify-content-end">
                <div className="pl-2 d-flex" style={{ height: 36 }}>
                  <input
                    type="text"
                    className="form-control rounded d-none"
                    disabled={true}
                    ref={controller.SP_POL}
                  />
                  <Select
                    required
                    className="form-control text-dark"
                    clearable={true}
                    multi={false}
                    options={port_list}
                    name={"SearchName"}
                    sortBy={"SearchName"}
                    searchBy={"SearchName"}
                    valueField={"City"}
                    placeholder={"출발지"}
                    style={{
                      flexGrow: 1,
                      minWidth: 180,
                    }}
                    labelField={"NewFullName"}
                    onChange={(data) => {
                      let value = data.length === 0 ? "" : data[0].CustomCode;
                      controller.SP_POL.current.value = value;
                      actionSearch();
                    }}
                    itemRenderer={({ item, methods }) => {
                      return (
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      );
                    }}
                  />
                </div>

                <div className="pl-2 d-flex" style={{ height: 36 }}>
                  <input
                    type="text"
                    className="form-control rounded d-none"
                    disabled={true}
                    ref={controller.SP_POD}
                  />
                  <Select
                    required
                    className="form-control text-dark"
                    clearable={true}
                    multi={false}
                    options={port_list}
                    name={"SearchName"}
                    sortBy={"SearchName"}
                    searchBy={"SearchName"}
                    valueField={"City"}
                    placeholder={"도착지"}
                    style={{
                      flexGrow: 1,
                      minWidth: 180,
                    }}
                    labelField={"NewFullName"}
                    onChange={(data) => {
                      let value = data.length === 0 ? "" : data[0].CustomCode;
                      controller.SP_POD.current.value = value;
                      actionSearch();
                    }}
                    itemRenderer={({ item, methods }) => {
                      return (
                        <p
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
                          onClick={() => methods.addItem(item)}
                          color="#0074D9"
                          style={{
                            fontSize: "11px",
                            padding: "2px 5px",
                            cursor: "pointer",
                            borderBottom: "1px solid #fff",
                            color: "gray",
                            fontStyle: "italic",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b
                            style={{
                              fontWeight: 600,
                              color: "black",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {`${item.CityName} (${item.CityName_EN}) `}
                          </b>

                          {item.CountryName + " " + item.ContinentName}
                        </p>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <PriceFclDefaultManageTable {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
