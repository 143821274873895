import React, { useEffect, useState } from "react";

import SearchShipperTableWrap from "./searchShipperComponent/SearchShipperTableWrap";
import SearchShipperAD from "./searchShipperAD/SearchShipperAD";
import SearchShipperDetail from "./searchShipperComponent/SearchShipperDetail";

export default function SearchShipper(props) {
  return (
    <>
      <div className="border border-bottom-0 p-3 w-100 h-100 overflow_scroll bg-white">
        <div className="mb-2">
          <h4 className="text-info pt-2 font-weight-bold">기업화주 검색</h4>
          <p>
            다양한 물류 니즈를 가진 수출입 회사(로지톡 회원사, 수출유망 중소기업
            등)를 로지톡에서 간편하게 조회하세요.
          </p>
        </div>

        <div
          className="flex-fill d-flex flex-row justify-content-between"
          style={{ height: "calc( 100vh - 100px)" }}
        >
          <div className="h-100 overflow_scroll-Y flex-fill pr-2">
            <SearchShipperTableWrap {...props} />
            <SearchShipperAD />
          </div>

          <SearchShipperDetail {...props} />
        </div>
      </div>
    </>
  );
}
