/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import GBTSLogoSvg from "../../assets/GBTSLogoSvg";

const socket = new BuddibleSocket();
const util = new Utilities();

let slideCountBackUp = 4;
export default function Ad() {
  const [slideCount, setSlideCount] = useState(4);
  const [adList, setAdList] = useState([]);

  useEffect(() => {
    AD_SELECT();
    handleSlideCount();
    window.addEventListener("resize", handleSlideCount);
    return () => {
      window.removeEventListener("resize", handleSlideCount);
    };
  }, []);

  const handleSlideCount = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      slideCountBackUp = 1;
      //  mobile
    } else if (windowWidth >= 576 && windowWidth < 992) {
      slideCountBackUp = 2;
    } else {
      slideCountBackUp = 4;
    }
    setSlideCount(slideCountBackUp);
  };

  const AD_SELECT = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/product_hosts/JS_product_hosts_select.php",
      msgID: msgID,
      data: {},
      AD_USE_YN: "Y",
      AD_EXPOSURE_LOCATION: "M",
      sort: [
        {
          field: "AD_CREATE_DATE",
          dir: "desc",
        },
      ],
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setAdList(newData["returnData"]);
        }
      }
    });
  };

  const AdItemRender = useCallback((params) => {
    return <AdItem {...params} />;
  }, []);

  const AdItem = useCallback(
    React.memo((params) => {
      const item = { ...params };
      const imgSrc = item.AD_LOGO.split("?")[1];
      return (
        <div className="m-0 p-2 item">
          <div
            className="tour_container shadow-none"
            style={{
              display: "flex",
              justifyContent: "center",
              height: 80,
              marginBottom: "6px",
            }}
          >
            <div className="ad-container cursor_pointer">
              <div
                className="w-100 h-100 bg-white rounded-lg border border-light cursor_pointer_shadow"
                style={{
                  background: `url(${imgSrc}) 50% 50%/contain  no-repeat`,
                }}
                onClick={() => {
                  if (item.AD_COMP_HOMEPAGE) {
                    if (!item.AD_COMP_HOMEPAGE.match("http")) {
                      window.open(
                        `https://${item.AD_COMP_HOMEPAGE}`,
                        "",
                        "_blank"
                      );
                    } else {
                      window.open(`${item.AD_COMP_HOMEPAGE}`, "", "_blank");
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    })
  );

  return (
    <div className="py-3 bg-freiscop-05">
      <div className="container margin_30">
        <div className="main_title text-dark NNS">
          <h2 className="h2 font-weight-900 m-3">
            <GBTSLogoSvg /> 제휴 파트너사
          </h2>
          <p className="mb-0 font-weight-bold">
            GBTS와 제휴계약을 체결한{" "}
            <span className="text-info">
              물류 공급사(선사,항공사,콘솔사)와 포워더 및 협력사
            </span>
            입니다.
          </p>
          <p className="mb-2 font-weight-bold">
            GBTS는 국내외 물류기업들과의 제휴를 언제나 환영합니다.
          </p>
        </div>

        <div className="row mx-3 mx-sm-0">
          {adList.length > 0 && (
            <div className="col-12 m-0 p-0">
              <OwlCarousel
                className="owl-theme"
                loop={true}
                nav={true}
                dots={false}
                autoplay={true}
                autoplayTimeout={2500}
                autoplayHoverPause={true}
                items={slideCount}
                navContainerClass="d-flex flex-row justify-content-center"
                navClass={[
                  "custom-owl-nav prev h3 font-weight-900 mb-0",
                  "custom-owl-nav next h3 font-weight-900 mb-0",
                ]}
              >
                {adList.map((item, index) => {
                  return <AdItemRender {...item} key={index} />;
                })}
              </OwlCarousel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
