/* eslint-disable no-unused-vars */
import React, { useState, useImperativeHandle, forwardRef } from "react";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import { ResponsiveBar } from "@nivo/bar";
import { ChartDefaultOption } from "../../../lib/CommonUI";

const socket = new BuddibleSocket();
const util = new Utilities();

export default forwardRef(function DashboardMainScheduleTotalSummary({}, ref) {
  const [serverData, setServerData] = useState([
    {
      sandwich: 141,
      sandwichColor: "hsl(337, 70%, 50%)",
    },
  ]);

  useImperativeHandle(ref, () => ({
    requestServerData(params) {
      requestServerData(params);
    },
  }));

  const requestServerData = (filter) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/dashboard/JS_dashboard_schedule_total_summary.php",
      msgID: msgID,
      data: {},
    };
    if (filter) {
      socketMsg = { ...socketMsg, ...filter };
    } else {
      return;
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData2"]);
        }
      }
    });
  };

  const MyResponsiveBar = ({ data }) => (
    <ResponsiveBar
      {...ChartDefaultOption.ResponsiveBar}
      data={data}
      keys={["CNT"]}
      indexBy="name"
      legends={[]}
      colorBy={"indexValue"}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "이용자 사용현황",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      valueFormat={(v) => {
        return util.addCommas(v);
      }}
      tooltip={({ formattedValue, indexValue }) => (
        <div
          style={{
            padding: 12,
            color: "white",
            background: "#222222",
          }}
        >
          <strong>
            {indexValue} : {util.addCommas(formattedValue)}
          </strong>
        </div>
      )}
    />
  );

  return (
    <>
      <div style={{ height: "300px" }}>
        <MyResponsiveBar data={serverData} />
      </div>
    </>
  );
});
