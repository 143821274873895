import React, { useCallback, useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let totalCount = 100;
let totalPageCount = 1;
let page = 0;
let rowsPerPage = 20;

export default function ChatRoomFiles(props) {
  const classes = useRowStyles();
  const { activeChRoom, isAllow, setIsFileShow } = props;
  const [serverData, setServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    requestUserSelect();
    return () => {
      totalCount = 100;
      totalPageCount = 1;
      page = 0;
      rowsPerPage = 20;
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    page = newPage;
    requestUserSelect();
  };

  const requestUserSelect = () => {
    if (!isAllow) return;
    setIsLoading(true);
    let msgID = util.makeUUIDv4();
    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_MSG_LIST_GET,
      file: "/talk/JS_talk_room_msg_file_list.php",
      msgID: msgID,
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      crtMainKey: activeChRoom.crmMainKey,
      crtType: 2,
      crtDelYN: "N",
    };

    socket.sendMessage(data, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          let dataList = [...newData["returnData"]];
          let temp = [];
          for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];
            if (data.crtFile.indexOf("|") > -1) {
              let multiFile = data.crtFile.split("|");
              for (let j = 0; j < multiFile.length; j++) {
                let splitFile = {
                  ...data,
                  crtIDX: `${data.crtIDX}_${j + 1}`,
                  crtFile: multiFile[j],
                };
                temp.push(splitFile);
              }
            } else {
              temp.push(data);
            }
          }
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          setServerData(temp);
          setIsLoading(false);
          document.querySelector("#fileListScrollWrap").scrollTo(0, 0);
        }
      }
    });
  };

  const handleIcon = (fileName) => {
    let icon = "fa-solid fa-file";
    let nameSplit = fileName.split(".");
    let fileType = nameSplit[nameSplit.length - 1].toLowerCase().trim();
    if (fileType === "xlsx" || fileType === "xls") {
      icon = "fa-regular fa-file-excel";
    } else if (fileType === "docx" || fileType === "doc") {
      icon = "fa-regular fa-file-word";
    } else if (
      fileType === "zip" ||
      fileType === "apk" ||
      fileType === "rar" ||
      fileType === "7z" ||
      fileType === "tar"
    ) {
      icon = "fa-regular fa-file-zipper";
    } else if (fileType === "pptx" || fileType === "ppt") {
      icon = "fa-regular fa-file-powerpoint";
    } else if (fileType === "pdf") {
      icon = "fa-regular fa-file-pdf";
    }
    return icon;
  };
  const closeFiles = () => {
    totalCount = 100;
    totalPageCount = 1;
    page = 0;
    rowsPerPage = 0;
    setIsFileShow(false);
  };

  const FileItem = useCallback(({ item }) => {
    return <FileItemRender {...item} />;
  }, []);

  const FileItemRender = React.memo((props) => {
    let { crtIDX, crtFile, senderUserNICK, senderUserName } = props;
    let splitData = crtFile.split("?");
    let fileLink = splitData[0];
    let fileName = splitData[1];

    let isImg = false;

    if (
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".gif") ||
      fileName.endsWith(".bmp") ||
      fileName.endsWith(".png")
    ) {
      isImg = true;
    }

    return (
      <div className="border mb-2 p-2 pr-3 d-flex flex-row justify-content-start align-items-center">
        <div
          className="rounded mr-2 d-flex flex-row justify-content-center align-items-center"
          style={{
            background: `${
              isImg ? `url('${fileLink}') 50% 50%/cover no-repeat` : "#eeeeee"
            }`,
            height: 70,
            width: 70,
          }}
        >
          {!isImg && (
            <div className="h1 text-gbts m-0">
              <i className={handleIcon(fileName)} />
            </div>
          )}
        </div>
        <div className="flex-fill">
          <p
            className="font-weight-bold overflow-hidden text-truncate m-0 p-0"
            style={{ maxWidth: 200 }}
          >
            {fileName}
          </p>
          <p className="m-0 p-0" style={{ fontSize: "0.8em" }}>
            {senderUserNICK || senderUserName}
          </p>
          <p className="m-0 p-0 text-right font-weight-bold text-info cursor_pointer">
            <span
              onClick={() => {
                if (!isImg) {
                  codeList.Modal.current.confirm(
                    `${fileName}을 다운로드 하시겠습니까?`,
                    (ret) => {
                      if (ret) {
                        util.downloadAs(fileLink, fileName);
                      } else {
                        codeList.Modal.current.alert(
                          "다운로드를 취소하였습니다"
                        );
                      }
                    }
                  );
                } else {
                  window.open(fileLink, "_blank", "width=1000, height=1000");
                }
              }}
            >
              {isImg ? "크게보기" : "다운로드"}
            </span>
          </p>
        </div>
      </div>
    );
  });
  return (
    <div
      className="h-100 w-100 position-absolute d-flex flex-row justify-content-between overflow-hidden"
      style={{
        background: "rgba(0,0,0,0.1)",
        backdropFilter: "blur(2px)",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <div className="p-3">
        <h6 className="font-weight-bold">
          <i
            className="fa-solid fa-arrow-left-long cursor_pointer"
            onClick={() => {
              closeFiles();
            }}
          />
        </h6>
      </div>
      <div
        className="bg-white shadow-lg p-3 d-flex flex-column  animation-move-left animation-duration-1 active "
        style={{ minWidth: 340, maxWidth: 400 }}
      >
        <h6 className="text-info pb-2">
          <i
            className="fa-solid fa-xmark cursor_pointer text-dark mr-2"
            onClick={() => {
              closeFiles();
            }}
          />
          파일모음
        </h6>

        <div id="fileListScrollWrap" className="flex-fill overflow_scroll">
          {serverData.length === 0 && <p>공유된 파일이 존재하지 않습니다.</p>}
          {serverData.length > 0 &&
            serverData.map((item) => {
              return <FileItem key={item.crtIDX} item={item} />;
            })}
        </div>
        <div className="pt-2">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
