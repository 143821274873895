/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import PickPlaceManageEdit from "./PickPlaceManageEdit";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "PickPlaceManageTable";

const useStyles = makeStyles((theme) => ({
  ...codeList.publicVar.tableDefaultStyle(theme),
}));
const useRowStyles = makeStyles({
  ...codeList.publicVar.tableRowDefaultStyle(),
});

const headCells = [
  {
    field: "ppIDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "ppCode",
    headerName: "코드",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "ppLocation",
    headerName: "장소",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "ppKind",
    headerName: "분류",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "ppDescription",
    headerName: "상세",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "ppRemark",
    headerName: "비고",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "ppUseYN",
    headerName: "사용여부",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data === "Y" ? "사용" : "미사용"}
      </span>
    ),
  },
  {
    field: "ppCreateDate",
    headerName: "생성일",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },

  {
    field: "ROWNUM",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#editModal"
            onClick={(e) => params.openEditModal(e, params)}
            aria-label={"수정"}
          >
            <i className="fas fa-pen " />
          </button>
          <button
            type="button"
            key={"delBtn-" + params.currentData.uID}
            className="btn btn-danger ml-2"
            onClick={(e) => params.deleteData(e, params)}
            aria-label={"삭제"}
          >
            <i className="far fa-trash-alt" />
          </button>
        </span>
      );
    },
  },
];

export default function PickPlaceManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("ppIDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();
  const [sortModel, setSortModel] = useState([
    {
      field: "ppIDX",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_PICK_PLACE_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setPage(0);
        setFilters({ ...n });
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PICK_PLACE_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_PICK_PLACE_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PICK_PLACE_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/pick_place/JS_pick_place_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    // console.log(event, newPage);
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log("handleRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEditModal(e, params) {
    editModal.current.openEditModal(e, params);
  }

  function deleteData(e, params) {
    editModal.current.deleteData(e, params);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEditModal={openEditModal}
                  deleteData={deleteData}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEditModal={openEditModal}
                  deleteData={deleteData}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEditModal={openEditModal}
                  deleteData={deleteData}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                openEditModal={openEditModal}
                deleteData={deleteData}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <PickPlaceManageEdit
        ref={editModal}
        userData={{ name: "aa" }}
        requestUserSelect={requestUserSelect}
      />
    </TableContainer>
  );
}

PickPlaceManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openEditModalModal: PropTypes.func,
        })
      ),
    })
  ),
  openEditModalModal: PropTypes.func,
  deleteData: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function Row(props) {
  const { row, openEditModal, deleteData, isMultiSelect = false } = props;
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.field}
          align={headCell.numeric ? "right" : "left"} //text 정렬
          padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
        >
          {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEditModal: openEditModal,
                deleteData: deleteData,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEditModal: openEditModal,
                deleteData: deleteData,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};
