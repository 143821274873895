import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import ScheduleBookingManageTable from "./ScheduleBookingManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const _mClassName = "ScheduleBookingManage";

let filters = {
  sb_STATUS: "",
  sb_STATUS2: "",
  sb_FCL_LCL: "",
  SEARCH_LEVEL_DATE: "",
  SEARCH_START_DATE: "",
  SEARCH_END_DATE: "",
  SEARCH_LEVEL: "",
  SEARCH_WORD: "",
  EMPLOYEE_TYPE: "",
};

export default function ScheduleBookingManage(props) {
  const { pagesOpen, loginInfo, compList, compListObj, searchCodeList } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $controller = {
    sb_STATUS: useRef(),
    sb_STATUS2: useRef(),
    sb_FCL_LCL: useRef(),
    SEARCH_LEVEL_DATE: useRef(),
    SEARCH_START_DATE: useRef(),
    SEARCH_END_DATE: useRef(),
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    EMPLOYEE_TYPE: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  //검색필더 값 변경
  const handleFilterChange = (key, value) => {
    filters = {
      ...filters,
      [key]: value,
    };
  };

  //검색필터 적용
  const searchFilterChanged = (key, value) => {
    if (key) {
      filters = {
        ...filters,
        [key]: value,
      };
    }
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  const searchEmployee = (value) => {
    filters = {
      ...filters,
      EMPLOYEE_TYPE: value,
    };
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  if (isLoad) {
    return <CommonLoader />;
  }

  return (
    <div className="container-fluid">
      <div className="content">
        <div className="container-florder_uid p-0">
          <div className="row ">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                    <div
                      className="btn-toolbar mb-3 justify-content-md-end"
                      role="toolbar"
                    >
                      {(loginInfo.isConsole || loginInfo.isAdmin) && (
                        <div className="mb-2">
                          <select
                            className="custom-select form-control"
                            ref={$controller.EMPLOYEE_TYPE}
                            onChange={(e) => searchEmployee(e.target.value)}
                            style={{ minWidth: 150 }}
                          >
                            <option value="">-담당 정보-</option>
                            <option value="sm_PROVIDER_ID">
                              내 업무 담당 스케쥴
                            </option>
                            <option value="sm_PROVIDER_SALES_ID">
                              내 영업 담당 스케쥴
                            </option>
                          </select>
                        </div>
                      )}

                      {loginInfo.comp_gb === "Z" && (
                        <div className=" ml-2">
                          <select
                            className="custom-select form-control"
                            ref={$controller.sb_STATUS2}
                            onChange={(e) =>
                              searchFilterChanged("sb_STATUS2", e.target.value)
                            }
                            style={{ minWidth: 150 }}
                          >
                            <option value="">-결재상태-</option>
                            <option value="A">대금요청</option>
                            <option value="B">결제완료</option>
                          </select>
                        </div>
                      )}
                      <div className=" mb-2 mb-md-0 mx-0 ml-md-2">
                        <select
                          className="custom-select form-control"
                          ref={$controller.sb_STATUS}
                          onChange={(e) =>
                            searchFilterChanged("sb_STATUS", e.target.value)
                          }
                          style={{ minWidth: 150 }}
                        >
                          <option value="">-요청상태-</option>
                          <option value="N">예약요청</option>
                          <option value="Y">예약확정</option>
                          <option value="B">B/L발행</option>
                          <option value="C">예약취소</option>
                        </select>
                      </div>

                      <div className=" mb-2 mb-md-0 mx-0 ml-md-2">
                        <select
                          className="custom-select form-control"
                          ref={$controller.sb_FCL_LCL}
                          onChange={(e) =>
                            searchFilterChanged("sb_FCL_LCL", e.target.value)
                          }
                          style={{ minWidth: 150 }}
                        >
                          <option value="">-운송분류-</option>
                          <option value="LCL">LCL</option>
                          <option value="FCL">FCL</option>
                          <option value="AIR">AIR</option>
                        </select>
                      </div>

                      <div className="input-group m-0 ml-2">
                        <select
                          className="custom-select form-control"
                          ref={$controller.SEARCH_LEVEL_DATE}
                          onChange={(e) =>
                            handleFilterChange(
                              "SEARCH_LEVEL_DATE",
                              e.target.value
                            )
                          }
                          style={{ minWidth: 150 }}
                        >
                          <option value="">- 검색일자 -</option>
                          <option value="sb_DOC_CLOSE">서류마감</option>
                          <option value="sb_CARGO_CLOSE">화물마감</option>
                          <option value="sb_DEPARTURE_DATE">출발일자</option>
                          <option value="sb_ARRIVE_DATE">도착일자</option>
                          <option value="sb_CREATEDATE">요청일자</option>
                        </select>
                        <input
                          className="form-control border"
                          style={{ height: 40 }}
                          type="date"
                          ref={$controller.SEARCH_START_DATE}
                          // defaultValue={defaultStartDate}
                          onChange={(e) => {
                            handleFilterChange(
                              "SEARCH_START_DATE",
                              e.target.value
                            );
                          }}
                        />
                        <span className="input-group-append mb-2">
                          <div className="input-group-text">-</div>
                        </span>
                        <input
                          className=" border form-control border-left-0"
                          style={{ height: 40 }}
                          type="date"
                          // defaultValue={defaultEndDate}
                          ref={$controller.SEARCH_END_DATE}
                          onChange={(e) => {
                            handleFilterChange(
                              "SEARCH_END_DATE",
                              e.target.value
                            );
                          }}
                        />
                      </div>

                      <div className="input-group order-1 order-md-1 order-lg-1 mb-2 ml-2">
                        <select
                          className="custom-select form-control"
                          ref={$controller.SEARCH_LEVEL}
                          onChange={(e) =>
                            handleFilterChange("SEARCH_LEVEL", e.target.value)
                          }
                          style={{ minWidth: 150 }}
                        >
                          <option value="">-검색어기준-</option>
                          <option value="sm_UID">스케줄 관리번호</option>
                          <option value="sb_IDX">예약 No.</option>
                          <option value="sb_COMP_CD">회사코드</option>
                          <option value="sb_COMP_NM">상호명</option>
                          <option value="sb_USER_NM">요청자명</option>
                          <option value="sb_ORIGIN">출발항</option>
                          <option value="sb_DEST">도착항</option>
                        </select>

                        <input
                          type="text"
                          className="border form-control rounded-0"
                          placeholder="검색어"
                          style={{ height: 40 }}
                          ref={$controller.SEARCH_WORD}
                          onChange={(e) => {
                            filters = {
                              ...filters,
                              SEARCH_WORD: e.target.value,
                            };
                          }}
                          onKeyPress={(e) => {
                            if (e.charCode === 13) {
                              searchFilterChanged();
                            }
                          }}
                        />

                        <button
                          className="input-group-text btn btn-primary rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                          onClick={() => searchFilterChanged()}
                        >
                          <i className="fas fa-search mr-2" />
                          검색
                        </button>
                      </div>
                    </div>
                  </h6>
                </div>
                <ScheduleBookingManageTable {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
