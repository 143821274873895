import React from "react";
import CorpCurrentStatus from "./components/CorpCurrentStatus";
import CorpCreditInfo from "./components/CorpCreditInfo";
import CorpComprehensiveAnalysis from "./components/CorpComprehensiveAnalysis";
import CorpComprehensiveAnalysisByYear from "./components/CorpComprehensiveAnalysisByYear";
import CorpRiskDiagnosis2 from "./components/CorpRiskDiagnosis2";

export default function CorpInfoReferenceDetailSummary(props) {
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div className="flex-grow-1">
        <CorpCurrentStatus {...props} />
        <CorpCreditInfo {...props} />
        <CorpComprehensiveAnalysisByYear {...props} />
        <CorpComprehensiveAnalysis {...props} />
        <CorpRiskDiagnosis2 {...props} />
      </div>
      <p className="text-secondary">
        ※ 신용보증기금이 수집한 정보를 토대로 작성한 것으로, 분석기업과의
        금융거래 등 상거래 관계의 설정 및 유지여부 등의 판단 목적으로만
        사용하여야 합니다. 해당 정보를 활용한 어떠한 의사결정에 대하여
        신용보증기금과 프레이스콥은 일체의 책임을 지지 않습니다.
      </p>
    </div>
  );
}
