import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Utilities from "../../../../lib/Utilities";

const util = new Utilities();

export default function SearchLogisticsUserTable(props) {
  const {
    loginInfo,
    serverData,
    requestLogisticsUserList,
    addFavUser,
    openUserInfoModal,
  } = props;
  const { t } = useTranslation();

  const COMP_TYPE = {
    B: `${t("forwarder")}(${t("forwarder2")})`,
    C: t("carrier"),
    D: t("insuranceCompany"),
    E: t("customsOfficer"),
    F: t("customsOfficer"),
    Z: t("manager"),
  };

  const COMP_GB_SUB_TYPE = {
    B01: t("forwarder"), //포워더
    B02: t("forwarder2"), //콘솔사
    B03: t("forwarder3"), //선사
    F01: t("bondedStore"),
    F02: "CY",
    F03: t("general_warehouse_sub"),
  };

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const CompItemRender = useCallback((props) => {
    return <CompItem {...props} />;
  }, []);

  return (
    <>
      {data.map((item, index) => {
        return (
          <CompItemRender
            key={item.COMP_CD}
            t={t}
            item={item}
            loginInfo={loginInfo}
            index={index}
            addFavUser={addFavUser}
            openUserInfoModal={openUserInfoModal}
            requestLogisticsUserList={requestLogisticsUserList}
            COMP_TYPE={COMP_TYPE}
            COMP_GB_SUB_TYPE={COMP_GB_SUB_TYPE}
          />
        );
      })}
    </>
  );
}

const CompItem = React.memo((props) => {
  const {
    t,
    index,
    item,
    loginInfo,
    requestLogisticsUserList,
    addFavUser,
    openUserInfoModal,
    COMP_TYPE,
    COMP_GB_SUB_TYPE,
  } = props;
  const [isCollapse, setIsCollapse] = useState(false);
  const [userList, setUserList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const displayIndex = index + 1;

  useEffect(() => {
    if (isCollapse && userList === undefined) {
      requestLogisticsUserList(item, (dataList) => {
        setUserList(dataList);
        setIsLoading(false);
      });
    }
  }, [isCollapse, userList]);

  return (
    <>
      <a
        className="p-2 d-block border-top text-dark"
        data-toggle="collapse"
        href={`#${item.COMP_CD}`}
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
        onClick={(e) => {
          setIsCollapse((prevState) => !prevState);
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-between font-weight-bold">
          <div className="d-flex flex-row align-items-center">
            <div className="pl-2 pr-3">{displayIndex}</div>
            <div className="pr-2">
              <div
                style={{
                  width: 36,
                  height: 36,
                  background: `url(${item.SHIP_IMG_URL10}) 50% 50% / contain no-repeat`,
                }}
              />
            </div>
            <div className="pr-2">
              <p>
                [
                {item.COMP_GB_SUB2
                  ? COMP_GB_SUB_TYPE[item.COMP_GB_SUB2]
                  : COMP_TYPE[item.COMP_GB]}
                ]
              </p>
              <p>{item.COMP_NM}</p>
            </div>
          </div>
          <span className="d-inline-block p-2">
            <i
              className={`fa-solid fa-chevron-${isCollapse ? "up" : "down"}`}
            />
          </span>
        </div>
      </a>

      <div className="collapse" id={item.COMP_CD}>
        <div className="px-2 pt-2">
          <div className="p-2 rounded bg-light">
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">
                {t("compName")}
              </div>
              <div className="col-9 m-0 p-1">
                <p>{item.COMP_NM}</p>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">
                {t("compCEOName")}
              </div>
              <div className="col-9 m-0 p-1">
                <p>{item.COMP_CEO_NM}</p>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">
                {t("compKind")}
              </div>
              <div className="col-9 m-0 p-1">
                <p>
                  {item.COMP_GB_SUB2
                    ? COMP_GB_SUB_TYPE[item.COMP_GB_SUB2]
                    : COMP_TYPE[item.COMP_GB]}
                </p>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">{t("addr")}</div>
              <div className="col-9 m-0 p-1">
                <p>{item.COMP_ADDR}</p>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">{t("tel")}</div>
              <div className="col-9 m-0 p-1">
                <a href={`tel:${item.COMP_TEL}`} className="text-dark">
                  <i className="fa-solid fa-phone mr-2 text-gbts" />
                  {item.COMP_TEL}
                </a>
              </div>
            </div>
            <div className="row m-0 p-0">
              <div className="col-3 m-0 p-1 font-weight-bold">{t("fax")}</div>
              <div className="col-9 m-0 p-1">
                <p>{item.COMP_FAX}</p>
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="p-2">
            <div className="p-2 text-center rounded bg-light font-weight-bold text-secondary">
              <p className="h6 mb-0">
                <i className="fa-solid fa-spinner rotate" />
              </p>
              <p className="mt-1">{t("loadUserDataMSG")}</p>
            </div>
          </div>
        )}
        {userList !== undefined && (
          <div className="px-2 pt-2">
            {userList.map((user) => {
              if (
                loginInfo &&
                loginInfo.comp_cd === user.COMP_CD &&
                loginInfo.user_id === user.USER_ID
              ) {
                return <></>;
              }

              const userTeam =
                (user.USER_TEAM_NM ? `[${user.USER_TEAM_NM}] ` : "") +
                user.USER_PART_NM;

              return (
                <div className="p-2 mb-2 bg-light rounded d-flex flex-row justify-content-between align-items-center">
                  <div
                    className="d-flex flex-row justify-content-start align-items-center mr-2"
                    data-toggle="modal"
                    data-target="#UserInfoModal"
                    onClick={() => openUserInfoModal(user)}
                  >
                    <div
                      className="border rounded-circle mr-3 position-relative"
                      style={{
                        width: 48,
                        minWidth: 48,
                        height: 48,
                        background: `url(${
                          user.USER_PIC ||
                          "https://www.gbts.co.kr/images/user_pic-50x50.png"
                        }) 50% 50% / cover no-repeat`,
                      }}
                    >
                      <div
                        className={`position-absolute border ${
                          user["login_state"] ? "bg-info" : "bg-secondary"
                        } rounded-circle`}
                        style={{
                          top: 0,
                          left: 0,
                          width: 15,
                          height: 15,
                        }}
                      />
                    </div>
                    <div className="text-secondary">
                      {(user.USER_TEAM_NM || user.USER_PART_NM) && (
                        <p className="w-100 ellipsis_1">{userTeam}</p>
                      )}
                      <p className="h6 mb-0 font-weight-bold">
                        {user.USER_NICK || user.USER_NM}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-gbts">
                    <span
                      className="h5 mb-0 mr-2 cursor_pointer"
                      onClick={() => addFavUser(user)}
                    >
                      <i className="fa-solid fa-user-plus" />
                    </span>
                  </div>
                </div>
              );
            })}
            <a
              className="d-block p-2 mb-2 w-100 bg-secondary text-white rounded text-center"
              data-toggle="collapse"
              href={`#${item.COMP_CD}`}
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={(e) => {
                setIsCollapse((prevState) => !prevState);
              }}
            >
              {t("close")}
            </a>
          </div>
        )}
      </div>
    </>
  );
});
