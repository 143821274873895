/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import LogiStoryEditModal from "./LogiStoryEditModal";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "LogiStoryManageTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const headCells = [
  {
    field: "prIDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sName",
    headerName: "분류",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data || "-"}
        </span>
      );
    },
  },
  {
    field: "prCompType",
    headerName: "업체구분",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let str = params.data ? comp_type[params.data] : "기타";
      return <span className="text-truncate text-wrap text-break">{str}</span>;
    },
  },
  {
    field: "prBCOMPNM",
    headerName: "업체",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      const img = params.currentData.prLOGO || "";
      return (
        <div className="d-flex flex-row align-items-center">
          {img && (
            <div
              className="mr-2"
              style={{
                width: 25,
                height: 25,
                background: `url(${img}) 50% 50% / contain no-repeat`,
              }}
            />
          )}
          {params.data}
        </div>
      );
    },
  },
  {
    field: "prTITLE",
    headerName: "제목",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let title = params.data;
      if (title && title.length > 30) {
        title = title.slice(0, 17) + "...";
      }
      return (
        <span className="text-truncate text-wrap text-break">{title}</span>
      );
    },
  },
  {
    field: "prUSERName",
    headerName: "작성자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "prCreateDate",
    headerName: "작성일",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "prUSEYN",
    headerName: "노출여부",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data === "Y" ? "노출" : "중지"}
      </span>
    ),
  },
  {
    field: "UPDATE",
    headerName: "수정",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#LogistoryEditModal"
          onClick={(e) => params.openEdit(e, params)}
          aria-label="수정"
        >
          <i className="fas fa-pen "></i>
        </button>
      </span>
    ),
  },
];
const comp_type = {
  A: "항공사",
  B: "콘솔사",
  F: "선사",
};

export default function LogiStoryManageTable({
  isServerPaging = true,
  isServerSort = true,
  loginInfo,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("prIDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "prIDX",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_BLOGO_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGISTORY_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setPage(0);
        setFilters({ ...n });
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGISTORY_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_BLOGO_MANAGE_DATA_UPDATED,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGISTORY_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGISTORY_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistorySelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      sendErp: "Y",
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeBoard(e, params) {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/logistoryBoard/JS_logistory_update.php",
      msgID: msgID,
      data: {},
      ...params,
    };
    codeList.Modal.current.confirm(
      "해당 게시글을 상태업데이트를 하시겠습니까?",
      (ret) => {
        if (ret) {
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData.ret) {
                codeList.Modal.current.alert(
                  "게시글 상태 업데이트가 완료되었습니다."
                );
                requestUserSelect();
              }
            }
          });
        } else {
          codeList.Modal.current.alert("게시글 상태업데이트 취소하였습니다.");
        }
      }
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    index={index}
                    openEdit={openEdit}
                    removeBoard={removeBoard}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    index={index}
                    openEdit={openEdit}
                    removeBoard={removeBoard}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    index={index}
                    openEdit={openEdit}
                    removeBoard={removeBoard}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                  removeBoard={removeBoard}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              // console.log(value - 1); //apiRef.current.setPage(value - 1)
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
      <LogiStoryEditModal
        ref={editModal}
        userData={{ name: "aa" }}
        loginInfo={loginInfo}
      />
    </>
  );
}

LogiStoryManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeBoard: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

function Row(props) {
  const { row, openEdit, removeBoard, requestUserSelect } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  removeBoard: removeBoard,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  removeBoard: removeBoard,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <BoardDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                    removeBoard={removeBoard}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    // id: PropTypes.string.isRequired,
  }).isRequired,
};

const BoardDetail = (props) => {
  let { rowData, requestUserSelect, setOpen, removeBoard } = props;
  let parentData = { ...rowData };
  let files;

  if (parentData.prFiles) {
    files = parentData.prFiles.split("|");
  }

  const fileDownload = (url, name) => {
    codeList.Modal.current.confirm(
      "첨부된 파일을 다운로드하시겠습니까?",
      (ret) => {
        if (ret) {
          util.downloadAs(url, name);
        }
      }
    );
  };

  return (
    <tr className="container" id={parentData.prIDX}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">상세정보</div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">글번호</h6>{" "}
                <p>{rowData.prIDX}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">제목</h6>{" "}
                <p>{rowData.prTITLE}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">작성일자</h6>{" "}
                <p>{rowData.prCreateDate}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">작성자</h6>{" "}
                <p>{rowData.prUSERName}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">수정일자</h6>{" "}
                <p>{rowData.prUpdateDate}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">이메일</h6>{" "}
                <p>{rowData.prUSEREmail}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">첨부파일</h6>{" "}
                <div className="d-flex flex-row">
                  {files &&
                    files.map((item) => {
                      let splitData = item.split("?");
                      if (!splitData.length) return;
                      let fileName = splitData[0];
                      let fileLink = splitData[1];

                      let isImg = false;
                      if (
                        fileName.endsWith(".jpg") ||
                        fileName.endsWith(".jpeg") ||
                        fileName.endsWith(".gif") ||
                        fileName.endsWith(".bmp") ||
                        fileName.endsWith(".png")
                      ) {
                        isImg = true;
                      }

                      if (isImg) {
                        return (
                          <img
                            key={fileLink}
                            className="rounded mr-2 border cursor_pointer"
                            src={fileLink}
                            alt={fileName}
                            style={{ height: 80 }}
                            onClick={() => {
                              fileDownload(fileLink, fileName);
                            }}
                          />
                        );
                      } else {
                        return (
                          <div
                            className="cursor_pointer text-secondary rounded mr-2 border h1 d-flex flex-row justify-content-center align-items-center"
                            key={fileLink}
                            style={{ height: 80, width: 80 }}
                            onClick={() => {
                              fileDownload(fileLink, fileName);
                            }}
                          >
                            <i className="fa-solid fa-file" />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">내용</h6>{" "}
                <div
                  className="quill_data_wrap"
                  dangerouslySetInnerHTML={{ __html: rowData.prCONTENTS }}
                />
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">노출여부</h6>{" "}
                <select
                  className="form-control"
                  onChange={(e) => {
                    parentData = {
                      ...parentData,
                      prUSEYN: e.target.value,
                    };
                  }}
                  style={{ maxWidth: 200 }}
                  defaultValue={rowData.prUSEYN}
                >
                  <option value="Y">노출</option>
                  <option value="N">중지</option>
                </select>
              </div>
              <div className="col-12 d-flex flex-row justify-content-end">
                <button
                  className="btn btn-primary px-4"
                  onClick={(e) => {
                    removeBoard(e, parentData);
                  }}
                >
                  저장
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
