import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../CustomStyle.css";

export default function LogiTalkNav(props) {
  let { changedActiveMainMenu, pages, pagesOpen, isMobile } = props;
  const [fixedBottom, setFixedBottom] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    navPositionHandler();
    window.addEventListener("resize", navPositionHandler);
    return () => {
      window.removeEventListener("resize", navPositionHandler);
    };
  }, []);

  const navPositionHandler = () => {
    let inWidth = window.innerWidth;
    if (inWidth >= 960) {
      setFixedBottom("");
    } else {
      setFixedBottom("fixed-bottom");
    }
  };

  const toHome = () => {
    navigate("/");
  };

  return (
    <div
      className={`d-flex static p-1 py-lg-2 ${
        isMobile ? "flex-row order-2" : "order-0 flex-column"
      } text-center justify-content-between align-items-center ${fixedBottom}`}
      style={{ background: "#081c34" }}
    >
      <span
        className={`d-flex ${
          isMobile ? " flex-row" : "flex-column"
        } justify-content-between align-items-center`}
      >
        {pages.map((menu) => {
          if (menu.id === "SetUserprofile") {
            return null;
          }

          return (
            <div className="position-relative hover_point" key={menu.id}>
              <span
                className={`d-inline-block text-white ${
                  pagesOpen[menu.id] ? " bg-primary" : ""
                } w-100 p-2 cursor_pointer rounded`}
                style={{ minWidth: 56 }}
                onClick={() => {
                  changedActiveMainMenu(menu.id);
                }}
              >
                <span className="h6 m-0 mt-1">
                  <i className={menu.icon} />
                </span>
                <p className="m-0 mt-1 p-0 font-size-08">{menu.title}</p>
              </span>

              {!isMobile && (
                <>
                  <div
                    className="position-absolute bg-white border shadow p-2 rounded text-center font-weight-bold"
                    style={{
                      minWidth: 180,
                      zIndex: 9,
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "calc(100% + 10px)",
                    }}
                  >
                    <p>{menu.guide}</p>
                    <div
                      className="bg-white border-left border-bottom position-absolute "
                      style={{
                        transform: "rotate(45deg) translateY(-50%)",
                        top: "50%",
                        width: 20,
                        height: 20,
                        right: "calc(100% - 2px)",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </span>
      <div
        className={`d-flex ${
          isMobile ? "flex-row " : "flex-column"
        } justify-content-end`}
      >
        <span
          className={`text-center ${
            isMobile ? "" : "w-100"
          }  p-3 cursor_pointer text-white `}
          onClick={() => {
            toHome();
          }}
        >
          <p className="h6 m-0">
            <i className={"fas fa-home"} />
          </p>
          <p className="m-0 mt-1 p-0 font-size-08">홈페이지</p>
        </span>
        <span
          className={`text-center ${
            isMobile ? "" : "w-100"
          }  p-3 cursor_pointer text-white `}
          onClick={() => {
            changedActiveMainMenu("SetUserprofile");
          }}
        >
          <p className="h6 m-0">
            <i className={"fas fa-cog"} />
          </p>
          <p className="m-0 mt-1 p-0 font-size-08">설정</p>
        </span>
      </div>
    </div>
  );
}
