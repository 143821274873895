/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "BasaCoPointManageEdit";

function BasaCoPointManageEdit(props, ref) {
  const { data = {}, requestBasaCoPointList } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const controller = {
    bcpCOMP_CD: useRef(),
    bcpCOMP_NAME: useRef(),
    bcpPOINT: useRef(),
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      controller.bcpCOMP_CD.current.disabled = true;
      controller.bcpCOMP_CD.current.value = userData.currentData.bcpCOMP_CD;
      controller.bcpCOMP_NAME.current.value = userData.currentData.bcpCOMP_NAME;
      controller.bcpPOINT.current.value = userData.currentData.bcpPOINT;
    } else {
      controller.bcpCOMP_CD.current.disabled = true;
      controller.bcpCOMP_CD.current.value = "";
      controller.bcpCOMP_NAME.current.value = "";
      controller.bcpPOINT.current.value = 0;
    }
  }, [userData]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_BASA_POINT_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_BASA_POINT_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
  }));

  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        bcpCOMP_CD: controller.bcpCOMP_CD.current.value,
        bcpCOMP_NAME: controller.bcpCOMP_NAME.current.value,
        bcpPOINT: controller.bcpPOINT.current.value,
      };

      requestUserUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if (controller.bcpCOMP_CD.current.value.trim() === "") {
      codeList.Modal.current.alert("업체를 선택해주세요", () => {});
      return false;
    }
    if (controller.bcpPOINT.current.value.trim() === "") {
      codeList.Modal.current.alert("포인트를 입력해주세요", () => {});
      return false;
    }
    return true;
  };

  const closeModal = () => {
    controller.bcpCOMP_CD.current.disabled = true;
    controller.bcpCOMP_CD.current.value = "";
    controller.bcpCOMP_NAME.current.value = "";
    controller.bcpPOINT.current.value = 0;
    setUserData({});
    $("#closeBasaCoPointManageEdit").click();
  };

  const requestUserUpdate = (updateData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/basa/JS_basaCoPointUpdate.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          codeList.Modal.current.alert(
            "BASA 포인트 정보가 정상 수정되었습니다.",
            () => {
              requestBasaCoPointList();
              closeModal();
            }
          );
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="BasaCoPointManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="BasaCoPointManageEdit"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>BASA 포인트 관리</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="closeBasaCoPointManageEdit"
                onClick={closeModal}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체 코드
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="업체코드"
                    disabled={true}
                    ref={controller.bcpCOMP_CD}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체명
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="업체명"
                    disabled={true}
                    ref={controller.bcpCOMP_NAME}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  기존포인트
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    readOnly={true}
                    ref={controller.bcpPOINT}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  추가포인트
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    onChange={(e) => {
                      controller.bcpPOINT.current.value =
                        Number(userData.currentData.bcpPOINT) +
                        Number(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={closeModal}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(BasaCoPointManageEdit);
