import React, { useEffect, useState } from "react";
import ShipperInfoManageEdit from "./components/ShipperInfoManageEdit";
import ShipperInfoManageTableWrap from "./components/ShipperInfoManageTableWrap";
import CommonLoader from "../CommonLoader";

const _mClassName = "ShipperInfoManage";

export default function ShipperInfoManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-auto" style={{ width: 500 }}>
          <ShipperInfoManageEdit />
        </div>
        <div className="col-auto flex-grow-1">
          <ShipperInfoManageTableWrap />
        </div>
      </div>
    </div>
  );
}
