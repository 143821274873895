import React, { useRef, useState, useEffect } from "react";
import MallBannerManageTable from "./MallBannerManageTable";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();

const _mClassName = "MallBannerManage";
export default function MallBannerManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const $searchText = useRef();
  const $searchLevel = useRef();
  const $searchUSE = useRef();

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = (e) => {
    socket.sendLocalMessage(
      MsgIDList.EVENT_MALL_BANNER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  //검색
  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
      mbUSEYN: $searchUSE.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_MALL_BANNER_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };
  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                <div
                  className="btn-toolbar d-flex flex-column flex-md-row justify-content-md-between mb-3"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-0 mr-mb-2 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn  btn-outline-primary "
                      data-toggle="modal"
                      data-target="#MallBannerManageEditModal"
                      onClick={addNewData}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-end bm-sm-0">
                    <select
                      className="custom-select form-control w-auto mr-0 mr-md-2 mb-2"
                      ref={$searchUSE}
                      onChange={searchFilterChanged}
                    >
                      <option value="">사용여부</option>
                      <option value="Y">사용</option>
                      <option value="N">중지</option>
                    </select>

                    <div className="flex-grow-1 input-group">
                      <select
                        className="custom-select form-control w-auto input-group-prepend mb-2 mb-md-0 mb-2"
                        ref={$searchLevel}
                        onChange={searchFilterChanged}
                      >
                        <option value="mbTITLE">업체명</option>
                        <option value="mbCONTENTS">내용</option>
                      </select>

                      <input
                        type="text"
                        className="form-control w-auto mb-2 mb-md-0 mb-2"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        style={{ minWidth: 150 }}
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={$searchText}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-0 rounded-top-right rounded-bottom-right mb-2"
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <MallBannerManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
