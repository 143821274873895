import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SectionTitle from "../../SectionTitle";
import { ResponsiveBar } from "@nivo/bar";
import Utilities from "../../../../lib/Utilities";
import TableBody from "@material-ui/core/TableBody";

const util = new Utilities();

export default function InfoStability(props) {
  const { compInfo, isExistData, isPrint } = props;
  let borrowingsMonthly = null;
  let borrowingsTotal = null;
  let tableData = null;

  let _data = [];
  let mappedData = {
    DOC_DATE: "",
    DOC_DATE_LAST: "",
    DOC_DATE_BEFORE: "",
    BORROWINGS_3: 0,
    BORROWINGS_3_LAST: 0,
    BORROWINGS_6: 0,
    BORROWINGS_6_LAST: 0,
    BORROWINGS_12: 0,
    BORROWINGS_12_LAST: 0,
    BORROWINGS_TOTAL: 0,
    BORROWINGS_TOTAL_LAST: 0,
  };

  if (isExistData && compInfo !== null) {
    _data = [
      ...compInfo["2000301"],
      ...compInfo["2000304"],
      ...compInfo["2000305"],
      ...compInfo["2000308"],
      ...compInfo["2000309"],
      ...compInfo["2000312"],
      ...compInfo["2000313"],
      ...compInfo["2000316"],
    ];

    // 당년, 전전, 전전년 계산로직
    if (compInfo["1000003"].length > 0) {
      for (let item of compInfo["1000003"]) {
        switch (item.item_nm) {
          case "당기결산종료일자":
            mappedData["DOC_DATE"] = util.getDateToFormat(
              item.item_val,
              "YYYY-MM-DD"
            );

            break;
        }
      }

      if (mappedData["DOC_DATE"] !== "-") {
        let date = new Date(mappedData["DOC_DATE"]);
        mappedData["DOC_DATE"] = util.getDateToFormat(date, "YY");
        mappedData["DOC_DATE_LAST"] = mappedData["DOC_DATE"] - 1;
        mappedData["DOC_DATE_BEFORE"] = mappedData["DOC_DATE"] - 2;
      }
    }

    for (let item of _data) {
      switch (item.item_nm) {
        case "3개월 이내 만기 도래 차입금(당월)":
          mappedData["BORROWINGS_3"] = Number(item.item_val);
          break;
        case "3개월 이내 만기 도래 차입금(전년동월)":
          mappedData["BORROWINGS_3_LAST"] = Number(item.item_val);
          break;
        case "6개월 이내 만기 도래 차입금(당월)":
          mappedData["BORROWINGS_6"] = Number(item.item_val);
          break;
        case "6개월 이내 만기 도래 차입금(전년동월)":
          mappedData["BORROWINGS_6_LAST"] = Number(item.item_val);
          break;
        case "12개월 이내 만기 도래 차입금(당월)":
          mappedData["BORROWINGS_12"] = Number(item.item_val);
          break;
        case "12개월 이내 만기 도래 차입금(전년동월)":
          mappedData["BORROWINGS_12_LAST"] = Number(item.item_val);
          break;
        case "총차입금(당월)":
          mappedData["BORROWINGS_TOTAL"] = Number(item.item_val);
          break;
        case "총차입금(전년동월)":
          mappedData["BORROWINGS_TOTAL_LAST"] = Number(item.item_val);
          break;
      }
    }

    borrowingsMonthly = [
      {
        country: "전년동월",
        monthly3: mappedData["BORROWINGS_3_LAST"], //3개월 단기차입금
        monthly3Color: "#125186",
        monthly6: mappedData["BORROWINGS_6_LAST"], //6개월 단기차입금
        monthly6Color: "#0097B7",
        monthly12: mappedData["BORROWINGS_12_LAST"], //12개월 단기차입금
        monthly6Color2: "#0097B7",
      },
      {
        country: `${mappedData["DOC_DATE"]}년`,
        monthly3: mappedData["BORROWINGS_3"],
        monthly3Color: "#125186",
        monthly6: mappedData["BORROWINGS_6"],
        monthly6Color: "#0097B7",
        monthly12: mappedData["BORROWINGS_12"],
        monthly6Color2: "#0097B7",
      },
    ];
    borrowingsTotal = [
      {
        country: "전년동월",
        totalBorrowings: mappedData["BORROWINGS_TOTAL_LAST"], //3개월 단기차입금
        totalBorrowingsColor: "#125186",
      },
      {
        country: `${mappedData["DOC_DATE"]}년`,
        totalBorrowings: mappedData["BORROWINGS_TOTAL"],
        totalBorrowingsColor: "#125186",
      },
    ];
    tableData = [
      {
        key: "borrowings3",
        headName: "3개월 만기 차입금",
        last: mappedData["BORROWINGS_3_LAST"],
        lastPer: 0,
        current: mappedData["BORROWINGS_3"],
        currentPer: isFinite(
          mappedData["BORROWINGS_3"] / mappedData["BORROWINGS_3_LAST"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["BORROWINGS_3"],
                mappedData["BORROWINGS_3_LAST"]
              )
            )
          : 100,
      },
      {
        key: "borrowings6",
        headName: "6개월 만기 차입금",
        last: mappedData["BORROWINGS_6_LAST"],
        lastPer: 0,
        current: mappedData["BORROWINGS_6"],
        currentPer: isFinite(
          mappedData["BORROWINGS_6"] / mappedData["BORROWINGS_6_LAST"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["BORROWINGS_6"],
                mappedData["BORROWINGS_6_LAST"]
              )
            )
          : 100,
      },
      {
        key: "borrowings12",
        headName: "12개월 만기 차입금",
        last: mappedData["BORROWINGS_12_LAST"],
        lastPer: 0,
        current: mappedData["BORROWINGS_12"],
        currentPer: isFinite(
          mappedData["BORROWINGS_12"] / mappedData["BORROWINGS_12_LAST"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["BORROWINGS_12"],
                mappedData["BORROWINGS_12_LAST"]
              )
            )
          : 100,
      },
      {
        key: "borrowingsTotal",
        headName: "총차입금",
        last: mappedData["BORROWINGS_TOTAL_LAST"],
        lastPer: 0,
        current: mappedData["BORROWINGS_TOTAL"],
        currentPer: isFinite(
          mappedData["BORROWINGS_TOTAL"] / mappedData["BORROWINGS_TOTAL_LAST"]
        )
          ? util.addCommas(
              util.getPercent(
                mappedData["BORROWINGS_TOTAL"],
                mappedData["BORROWINGS_TOTAL_LAST"]
              )
            )
          : 100,
      },
    ];
  }

  const headCells = [
    {
      field: "headName",
      headerName: "항목",
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {params.data}
          </span>
        );
      },
    },
    {
      field: "last",
      headerName: `전년동월`,
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}({params.currentData.lastPer}%)
          </span>
        );
      },
    },
    {
      field: "current",
      headerName: `${mappedData["DOC_DATE"]}년(YoY)`,
      minWidth: 150,
      numeric: false,
      disablePadding: false,
      renderCell: (params) => {
        return (
          <span className="text-truncate text-wrap text-break">
            {util.addCommas(params.data)}({params.currentData.currentPer}%)
          </span>
        );
      },
    },
  ];

  return (
    <div className="mb-5 ">
      <SectionTitle title="재무정보(안정성)" />{" "}
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flex-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <MyResponsiveBar data={borrowingsMonthly} isPrint={isPrint} />
          </div>
          <div
            className={`col-md-6 col-xl-4 ${
              isPrint ? "col-4" : ""
            } border d-flex flex-row justify-content-center align-items-center`}
            style={{ height: "300px" }}
          >
            <CustomResponsiveBar data={borrowingsTotal} isPrint={isPrint} />
          </div>
        </div>
        <p className="text-right mb-1">단위 : 백만원</p>
        <div className="border-top border-bottom border-freiscop">
          <TableContainer>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.field}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      style={{
                        background: "rgba(0,32,96,0.1)",
                        textAlign: "center",
                      }}
                    >
                      {headCell.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.map((row, index) => {
                    return (
                      <TableRow role="checkbox" key={row.key}>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.field}
                            padding={
                              headCell.disablePadding ? "none" : "normal"
                            }
                            style={{ textAlign: "center" }}
                          >
                            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
                              ? headCell.renderCell({
                                  data: row[headCell.field],
                                  currentData: row,
                                  currentHeader: headCell.field,
                                  headCells: headCells,
                                })
                              : headCell.hasOwnProperty("valueGetter")
                              ? headCell.valueGetter({
                                  data: row[headCell.field],
                                  currentData: row,
                                  currentHeader: headCell.field,
                                  headCells: headCells,
                                })
                              : row[headCell.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

const MyResponsiveBar = ({ data, isPrint }) => {
  if (data === null) {
    return <p>차입금 규모에 대한 데이터가 존재하지 않습니다.</p>;
  }
  const widthProps = isPrint ? { width: 300 } : {};
  return (
    <ResponsiveBar
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      data={data}
      keys={["monthly3", "monthly6", "monthly12"]}
      indexBy="country"
      padding={0.3}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={["#aab5cf", "#0097B7", "#125186"]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "차입금 규모",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"#ffffff"}
      role="application"
      ariaLabel="재무정보(안정성)"
      valueFormat={(v) => {
        return util.addCommas(v);
      }}
      {...widthProps}
      tooltip={(e) => {
        let type = "3개월";
        if (e.id === "monthly6") {
          type = "6개월";
        } else if (e.id === "monthly12") {
          type = "12개월";
        }
        let str = type + " : " + util.addCommas(e.value);
        return (
          <div
            style={{
              padding: 12,
              color: "white",
              background: "#222222",
            }}
          >
            <strong>{str}</strong>
          </div>
        );
      }}
    />
  );
};
const CustomResponsiveBar = ({ data, isPrint }) => {
  if (data === null) {
    return <p>단기차입금 총합에 대한 데이터가 존재하지 않습니다.</p>;
  }

  const widthProps = isPrint ? { width: 300 } : {};
  return (
    <ResponsiveBar
      data={data}
      keys={["totalBorrowings"]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={["#125186"]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "총차입금",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      valueFormat={(v) => {
        return util.addCommas(v);
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"#ffffff"}
      role="application"
      ariaLabel="총차입금"
      {...widthProps}
      tooltip={(e) => {
        let str = e.indexValue + " : " + util.addCommas(e.value);
        return (
          <div
            style={{
              padding: 12,
              color: "white",
              background: "#222222",
            }}
          >
            <strong>{str}</strong>
          </div>
        );
      }}
    />
  );
};
