/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";
import CodeList, { CommonConstList } from "./CodeList";

let util = new Utilities();
let codeList = new CodeList();

const _mClassName = "InitCodeList";

const InitCodeList = ({ initFinished }) => {
  let socket = new BuddibleSocket();
  let initMaxCnt = 3;
  let initCurrentCnt = 0;

  const initDone = () => {
    ++initCurrentCnt;
    //console.log("initDone", initCurrentCnt, codeList.codeCountryFullName);
    if (initMaxCnt === initCurrentCnt) initFinished();
  };

  useEffect(() => {
    requestAllCodeList(initDone);
    requestCountryFullName(initDone);
    requestAirCountryFullName(initDone);
    //requestKakaoAPI(initDone);
  }, []);

  const requestKakaoAPI = (callback) => {
    const mapScript = document.createElement("script");

    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=a34e17d539f1ddace4bc73436ba11e5d&libraries=services,clusterer,drawing&autoload=false`;

    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      console.log("onLoadKakaoMap");
      callback && callback();
    };
    mapScript.addEventListener("load", onLoadKakaoMap);
  };

  const requestAllCodeList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/code/JS_country_code_select.php",
      msgID: msgID,
      data: {},
      cBIGCD: "CD",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.codeCountryList = newData["returnData"];
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_COUNTRY,
            _mClassName,
            codeList.codeCountryList,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  const requestCountryFullName = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/code/JS_country_full_name_code_select.php",
      msgID: msgID,
      data: {},
      cBIGCD: "CD",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.codeCountryFullName = newData["returnData"];
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME,
            _mClassName,
            codeList.codeCountryFullName,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  const requestAirCountryFullName = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/code/JS_air_country_full_name_code_select.php",
      msgID: msgID,
      data: {},
      cBIGCD: "CD",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.codeAirCountryFullName = newData["returnData"];
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR,
            _mClassName,
            codeList.codeAirCountryFullName,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  return <></>;
};

export default InitCodeList;
