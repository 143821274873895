import React from "react";
import CodeList from "../../lib/CodeList";

const codeList = new CodeList();

export default function LogiWorkLandingCarousel({
  loginInfo,
  setIsInquiryModalOpen,
}) {
  const showOceanLookV3 = (e, arr) => {
    e.preventDefault();
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
      return false;
    }
    let oceanlookWebToken =
      "fc7132f8909b60db6fb4f762e250e3807bc68f6459a293545e8fdae813441cab";

    let popup = "oceanlookForm";
    //let url = "https://map.oceanlook.net/#/login?token=" + oceanlookWebToken;
    let url = "https://www.oceanlook.net/v3/login?token=" + oceanlookWebToken;

    let options =
      "top=0, left=0, width=1920, height=1080, status=no, menubar=no, toolbar=no, resizable=no";
    window.open(url, popup, options);
  };

  return (
    <div
      className="p-3 p-xl-5 text-white NNS"
      style={{
        background: `url(/img/use_img/shutterstock_1810522879_1920.jpg) 50% 70%/cover no-repeat`,
      }}
    >
      <div className="my-3 container-xl">
        <div className="row m-0 p-0 ">
          <div className="col-xl-9 m-0 p-0 ">
            <p className="BM-DH h4 mb-1 mb-xl-3 ">
              화주와 포워더, 관세사가 협업하는 디지털 수출입물류 관리 솔루션
            </p>
            <p className="BM-DH h1 mb-3 text-yellow">로지웍(LogiWork)</p>
            <div className="row m-0 mb-3 p-0 h6 text-light">
              <div className="col-12 text-center text-lg-left col-lg-2 h5 m-0 mb-2 mb-lg-0 p-0">
                <p className="font-weight-bold">기업 기대효과</p>
              </div>
              <div className="col-12 col-lg-10 text-left m-0 p-0">
                <ul className="ml-2">
                  <li className="mb-2">
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 물류비 절감
                      </p>
                      <p className="pl-2">
                        견적기반이 아닌 공급자(선사,항공사) 스케쥴과 운임을 직접
                        조회해서 부킹하는 업무방식
                      </p>
                    </div>
                  </li>
                  <li className="mb-2">
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 업무 효율성
                      </p>
                      <p className="pl-2">
                        선적부킹과 수출입 문서를 작성해서 포워더, 관세사,
                        보험사로 오더하고 동일한 화면에서 업무처리하는 협업기반
                        업무 시스템
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 편리성 증대
                      </p>
                      <p className="pl-2">
                        업무 진행현황과 각종 수출입 문서를 휴대폰 App에서도
                        조회하고 관리하는 업무개선 시스템
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row m-0 mb-5 p-0 h6 text-light">
              <div className="col-12 text-center text-lg-left col-lg-2 h5 m-0 mb-2 mb-lg-0 p-0">
                <p className="font-weight-bold">포워더 기대효과</p>
              </div>
              <div className="col-12 col-lg-10 text-left m-0 p-0">
                <ul className="ml-2">
                  <li className="mb-2">
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 기업홍보
                      </p>
                      <p className="pl-2">
                        로지웍 서비스를 이용하는 화주가 보는 추천 포워더
                        리스트로 등록하여 신규 화주와 매칭 가능성 증대
                      </p>{" "}
                    </div>
                  </li>
                  <li className="mb-2">
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 영업도구 제공
                      </p>
                      <p className="pl-2">
                        디지털 물류 서비스를 제공하는 디지털 포워더로 품질 높은
                        서비스를 제공하여 고객 이탈방지 및 관리 편의증대
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex flex-row">
                      <p className="font-weight-bold" style={{ minWidth: 110 }}>
                        · 업무 효율화
                      </p>
                      <p className="pl-2">
                        수출입 업무 진행현황을 실시간 제공(화물위치, 통관현황
                        등)함으로써 반복되는 고객응대 전화업무 감소
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="BM-DH h5 text-center text-xl-center mb-3">
              <span className=" border-top border-bottom d-inline-block px-3 py-2">
                도입효과
              </span>
            </div>
            <div className="row m-0 mb-2 mb-xl-5 p-0">
              <div className="col-xl-4 py-2 m-0 p-xl-0 text-center">
                <p className="h1">
                  <i className="fa-regular fa-eye" />
                </p>
                <p className="h6 font-weight-bold mb-2 text-yellow">
                  물류 가시성 확보
                </p>
                <p>
                  수출입 4대업무(선적/통관/운송/보험) 진행현황과 선박위치까지
                  제공하는 실시간 모니터링 서비스
                </p>
              </div>
              <div className="col-xl-4 py-2 m-0 p-xl-0 text-center">
                <p className="h1">
                  <i className="fa-solid fa-lock" />
                </p>
                <p className="h6 font-weight-bold mb-2 text-yellow">
                  락인(LOCK-IN) 효과
                </p>
                <p>
                  로지웍에서 기업화주의 물류정보를 통합 관리함으로써 고객
                  물류정보 선점, 락인효과 발생으로 고객이탈 방지
                </p>
              </div>
              <div className="col-xl-4 py-2 m-0 p-xl-0 text-center">
                <p className="h1">
                  <i className="fa-solid fa-chart-line" />
                </p>
                <p className="h6 font-weight-bold mb-2 text-yellow">
                  관리자용 대시보드
                </p>
                <p>
                  기간별 수출입 실적(금액, 건수 등)과 업무 진행상황을 보여주는
                  대시보드를 통해 경영전략 및 고객분석으로 활용
                </p>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <button
                className="btn btn btn-light border-0 text-info"
                onClick={() => window.open("https://system.gbts.co.kr/")}
              >
                로지웍 바로가기 <i className="fa-solid fa-right-to-bracket" />
              </button>
              <button
                className="d-inline-block  btn btn btn-info bg-freiscop border-0 font-weight-bold ml-2"
                onClick={(e) => showOceanLookV3(e)}
              >
                <i className="fa-solid fa-ship" /> OCEANLOOK
              </button>
            </div>
            <div className="d-xl-none text-right mt-5">
              <div className="mb-3">
                <button
                  className="btn btn-info rounded-circle"
                  style={{ width: 70, height: 70, lineHeight: "1.2em" }}
                  data-toggle="modal"
                  data-target="#popupRegistModal"
                >
                  회원
                  <br />
                  가입
                </button>
              </div>

              <div>
                <button
                  className="btn btn-secondary rounded-circle"
                  style={{ width: 70, height: 70, lineHeight: "1.2em" }}
                  onClick={() => setIsInquiryModalOpen(true)}
                >
                  도입
                  <br />
                  문의
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-3 m-0 p-0 d-none d-xl-block">
            <div className="h-100 d-flex flex-column align-items-end justify-content-end">
              <div>
                <div className="mb-3">
                  <button
                    className="btn btn-info rounded-circle"
                    style={{ width: 70, height: 70, lineHeight: "1.2em" }}
                    data-toggle="modal"
                    data-target="#popupRegistModal"
                  >
                    회원
                    <br />
                    가입
                  </button>
                </div>

                <div>
                  <button
                    className="btn btn-secondary rounded-circle"
                    style={{ width: 70, height: 70, lineHeight: "1.2em" }}
                    onClick={() => setIsInquiryModalOpen(true)}
                  >
                    도입
                    <br />
                    문의
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
