import React, { useState, useRef, useEffect } from "react";
import Select from "react-dropdown-select";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

import TalkNewsCompManageTable from "./TalkNewsCompManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "TalkNewsCompManage";

export default function TalkNewsCompManage(props) {
  const { loginInfo, compList, compListObj, pagesOpen } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [selectedComp, setSelectedComp] = useState([]);
  const table = useRef();
  const controller = {
    cnmType: useRef(),
    cnmDelYN: useRef(),
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);
  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_chatNewsMainUpdate.php",
      msgID: msgID,
      data: [],
      cnmMainKey: msgID,
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상처리되었습니다.", () => {
            table.current.requestData();
          });
        } else {
          codeList.Modal.current.alert(newData["MSG"]);
        }
      }
    });
  };

  const actionSave = () => {
    if (selectedComp.length === 0) {
      codeList.Modal.current.alert("업체를 선택해주세요");
      return;
    }
    const updateData = {
      cnmType: 3,
      cnmSite: selectedComp[0].COMP_CD,
      cnmCompNM: selectedComp[0].COMP_NM,
      cnmName: `[${selectedComp[0].COMP_NM}] 알림톡 서비스`,
      cnmCreator: loginInfo.user_id,
      cnmCreatorSite: loginInfo.comp_cd,
      crmDelYN: "N",
    };
    requestUserUpdate(updateData);
  };

  const actionSearch = (e) => {
    const filters = {
      cnmType: controller.cnmType.current.value,
      cnmDelYN: controller.cnmDelYN.current.value,
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };
    table.current.changeFile(e, filters);
  };

  if (isLoad) return <CommonLoader />;
  return (
    <div className="container-fluid">
      <div className="bg-white p-2 rounded mb-3 border">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p className="font-weight-bold">업체 선택</p>
          <Select
            placeholder="업체를 선택해주세요"
            style={{ width: 800 }}
            multi={false}
            clearable={true}
            values={selectedComp}
            options={compList}
            sortBy={"COMP_NM"}
            valueField={"COMP_NM"}
            labelField={"COMP_NM"}
            onChange={(value) => {
              setSelectedComp(value);
            }}
            itemRenderer={({ item, itemIndex, props, state, methods }) => {
              const isSelected =
                selectedComp.findIndex(
                  (data) => data.COMP_CD === item.COMP_CD
                ) > -1;
              return (
                <div key={item.COMP_CD}>
                  <div
                    className={`p-2 border-bottom cursor_pointer_bg_light ${
                      isSelected ? "bg-light" : ""
                    }`}
                    onClick={() => {
                      methods.addItem(item);
                    }}
                  >
                    <span className="font-weight-bold text-secondary">
                      [{item.COMP_CD}]
                    </span>{" "}
                    {item.COMP_NM}
                  </div>
                </div>
              );
            }}
          />
          <button className="btn_1" onClick={() => actionSave()}>
            등록
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex flex-row justify-content-end">
            <select
              className="form-control w-auto"
              ref={controller.cnmType}
              onChange={(e) => actionSearch(e)}
            >
              <option value="">-채널분류-</option>
              <option value="1">Invite</option>
              <option value="2">Intranet</option>
              <option value="3">Public</option>
              <option value="4">PublicSecret</option>
            </select>
            <select
              className="form-control w-auto ml-2"
              ref={controller.cnmDelYN}
              onChange={(e) => actionSearch(e)}
            >
              <option value="">-사용여부-</option>
              <option value="N">사용</option>
              <option value="Y">미사용</option>
            </select>
            <div className="ml-2">
              <div className="input-group">
                <select className="form-control" ref={controller.SEARCH_LEVEL}>
                  <option value="">-키워드-</option>
                  <option value="cnmCompNM">업체명</option>
                  <option value="cnmName">이름</option>
                  <option value="cnmDesc">설명</option>
                </select>
                <input
                  className="form-control"
                  placeholder="검색어를 입력해주세요"
                  ref={controller.SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionSearch(e);
                    }
                  }}
                />
                <button
                  className="btn btn-primary  input-group-text border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                  onClick={(e) => actionSearch(e)}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        <TalkNewsCompManageTable ref={table} compListObj={compListObj} />
      </div>
    </div>
  );
}
