import React from "react";

export default function Disclaimer() {
  return (
    <div className="text-center">
      <h2>
        <strong>면책공고</strong>
      </h2>
      <p>&nbsp;</p>
      <p>
        이 웹사이트에 실린 내용은 GBTS를 통하여 서비스제공자인 포워더회원사,
        관세사회원사, 운송사회원사, 보세창고회원사 및 보험사회원사의 물류, 통관,
        운송 및 보험에 대한 이해를 돕고자 제공된 것으로 법률적 자문이나 해석을
        위해 제공된 것이 아닙니다.
      </p>
      <p>
        구체적인 사안과 관련하여 서비스제공자인 각 서비스제공자 또는 GBTS에게
        법적 자문을 구하지 않고 이 웹사이트에 실려있는 내용에 근거하여 한 어떠한
        행동에 대해서도 각 회원사 및 GBTS는 책임을지지 않습니다.
      </p>
    </div>
  );
}
