import React, { useState, useEffect, useCallback } from "react";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

export default function SearchShipperAD() {
  const [adList, setAdList] = useState([]);

  useEffect(() => {
    AD_SELECT();
  }, []);

  const AD_SELECT = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/product_hosts/JS_product_hosts_select.php",
      msgID: msgID,
      data: {},
      USE_YN: "Y",
      AD_EXPOSURE_LOCATION: "L",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setAdList(newData["returnData"]);
        }
      }
    });
  };

  const AdItemRender = useCallback((params) => {
    return <AdItem {...params} />;
  }, []);

  const AdItem = useCallback(
    React.memo((params) => {
      const item = { ...params };
      const imgSrc = item.AD_LOGO.split("?")[1];
      return (
        <div className="col-12 col-md-6 col-lg-2 m-0 p-2 item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: 80,
              marginBottom: "6px",
            }}
          >
            <div className="ad-container cursor_pointer">
              <div
                className="w-100 h-100 bg-white"
                style={{
                  background: `url(${imgSrc}) 50% 50%/contain  no-repeat`,
                }}
                onClick={() => {
                  if (item.AD_COMP_HOMEPAGE) {
                    if (!item.AD_COMP_HOMEPAGE.match("http")) {
                      window.open(
                        `https://${item.AD_COMP_HOMEPAGE}`,
                        "",
                        "_blank"
                      );
                    } else {
                      window.open(`${item.AD_COMP_HOMEPAGE}`, "", "_blank");
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }),
    []
  );
  return (
    <div className="content">
      <div className="row m-0 p-0">
        {adList.length > 0 &&
          adList.map((item, index) => {
            return <AdItemRender {...item} key={index} />;
          })}
      </div>
    </div>
  );
}
