import Utilities from "./Utilities";
import axios from "axios";

export const MsgIDList = {
  EVENT_SOCKET_LOGIN: "EVENT_SOCKET_LOGIN",
  EVENT_SOCKET_ROOM_CREATE: "EVENT_SOCKET_ROOM_CREATE",
  EVENT_SOCKET_TALK_ALLOW: "EVENT_SOCKET_TALK_ALLOW",
  EVENT_SOCKET_TALK_MSG_SEND: "EVENT_SOCKET_TALK_MSG_SEND",
  EVENT_SOCKET_TALK_MSG_READ: "EVENT_SOCKET_TALK_MSG_READ",
  EVENT_SOCKET_ROOM_LIST_GET: "EVENT_SOCKET_ROOM_LIST_GET",
  EVENT_SOCKET_ROOM_MSG_LIST_GET: "EVENT_SOCKET_ROOM_MSG_LIST_GET",
  EVENT_SOCKET_TALK_CHAT_READ: "EVENT_SOCKET_TALK_CHAT_READ",
  EVENT_SOCKET_USER_PROFILE_CHANGED: "EVENT_SOCKET_USER_PROFILE_CHANGED",
  EVENT_TALK_ALERT_DISABLE: "EVENT_TALK_ALERT_DISABLE",
  EVENT_TALK_ALERT_DISABLE_CHANGE: "EVENT_TALK_ALERT_DISABLE_CHANGE",
  EVENT_TALK_ACTIVE_CHATROOM_CHANGE: "EVENT_TALK_ACTIVE_CHATROOM_CHANGE",
  EVENT_TALK_FAV_UESR_INSERT: "EVENT_TALK_FAV_UESR_INSERT",
  EVENT_TALK_CHATROOM_CREATED: "EVENT_TALK_CHATROOM_CREATED",
  EVENT_SOCKET_ROOM_INVITE: "EVENT_SOCKET_ROOM_INVITE",
  EVENT_TALK_ADD_CHAT_USER: "EVENT_TALK_ADD_CHAT_USER",
  EVENT_TALK_ROOM_NAME_CHANGE: "EVENT_TALK_ROOM_NAME_CHANGE",
  EVENT_TALK_CHAT_LIST_ROOM_DELETE: "EVENT_TALK_CHAT_LIST_ROOM_DELETE",
  EVENT_TALK_CHAT_ROOM_TITLE_CHANGE: "EVENT_TALK_CHAT_ROOM_TITLE_CHANGE",
  EVENT_TALK_LOGIN_STATE_CHANGED: "EVENT_TALK_LOGIN_STATE_CHANGED",
  EVENT_SOCKET_MSG_DELETE: "EVENT_SOCKET_MSG_DELETE",
  EVENT_SOCKET_TALK_LEAVE_ROOM: "EVENT_SOCKET_TALK_LEAVE_ROOM",
  EVENT_LOGITALK_LOGISTICS_SEARCH_DATA_CHANGE:
    "EVENT_LOGITALK_LOGISTICS_SEARCH_DATA_CHANGE",

  EVENT_TALK_SEARCH_SHIPPER_RESET: "EVENT_TALK_SEARCH_SHIPPER_RESET",

  EVENT_TALK_LOGIN_STATE_GET: "EVENT_TALK_LOGIN_STATE_GET",

  EVENT_SOCKET_DATA_ERROR: "EVENT_SOCKET_DATA_ERROR",

  EVENT_INIT_CODE_COUNTRY: "EVENT_INIT_CODE_COUNTRY",
  EVENT_INIT_CODE_COUNTRY_FULL_NAME: "EVENT_INIT_CODE_COUNTRY_FULL_NAME",
  EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR:
    "EVENT_INIT_CODE_COUNTRY_FULL_NAME_AIR",

  EVENT_PORT_TYPE_FILTER_CHANGED: "EVENT_PORT_TYPE_FILTER_CHANGED",
  EVENT_PORT_DETAIL_FILTER_CHANGED: "EVENT_PORT_DETAIL_FILTER_CHANGED",
  EVENT_PORT_SORT_CHANGED: "EVENT_PORT_SORT_CHANGED",

  EVENT_ERP_PAGE_CHANGED: "EVENT_ERP_PAGE_CHANGED",
  EVENT_ERP_PAGE_CHANGE_REMOTE: "EVENT_ERP_PAGE_CHANGE_REMOTE",
  EVENT_ERP_PRODUCT_DATA_MODIFY: "EVENT_ERP_PRODUCT_DATA_MODIFY",

  EVENT_ERP_PRODUCT_MANAGE_FILTER_CHANGED:
    "EVENT_ERP_PRODUCT_MANAGE_FILTER_CHANGED",

  EVENT_ERP_RESERVE_MANAGE_FILTER_CHANGED:
    "EVENT_ERP_RESERVE_MANAGE_FILTER_CHANGED",
  EVENT_ERP_RESERVE_MANAGE_NEED_REFRESH:
    "EVENT_ERP_RESERVE_MANAGE_NEED_REFRESH",

  EVENT_ERP_BASA_POINT_UPDATE: "EVENT_ERP_BASA_POINT_UPDATE",
  EVENT_ERP_BASA_POINT_CHANGED_FILTERS: "EVENT_ERP_BASA_POINT_CHANGED_FILTERS",
  EVENT_ERP_BASA_POINT_EDIT_MODAL_CALL: "EVENT_ERP_BASA_POINT_EDIT_MODAL_CALL",

  EVENT_ERP_BASA_HISTORY_CHANGED_FILTER:
    "EVENT_ERP_BASA_HISTORY_CHANGED_FILTER",

  EVENT_PRODUCT_DATA_INSERTED: "EVENT_PRODUCT_DATA_INSERTED",

  EVENT_POST_TYPE_CHANGE: "EVENT_POST_TYPE_CHANGE",
  EVENT_POST_NOTICE_FILTERS_CHANGED: "EVENT_POST_NOTICE_FILTERS_CHANGED",
  EVENT_POST_QNA_FILTERS_CHANGED: "EVENT_POST_QNA_FILTERS_CHANGED",
  EVENT_POST_BBS_FILTERS_CHANGED: "EVENT_POST_BBS_FILTERS_CHANGED",

  EVENT_USER_MANAGE_FILTER_CHANGED: "EVENT_USER_MANAGE_FILTER_CHANGED",
  EVENT_USER_MANAGE_USER_UPDATED: "EVENT_USER_MANAGE_USER_UPDATED",
  EVENT_USER_MANAGE_USER_INSERTED: "EVENT_USER_MANAGE_USER_INSERTED",
  EVENT_USER_MANAGE_EDIT_MODAL_CALL: "EVENT_USER_MANAGE_EDIT_MODAL_CALL",
  EVENT_USER_MANAGE_DATA_UPDATED: "EVENT_USER_MANAGE_DATA_UPDATED",
  EVENT_USER_MANAGE_DATA_INSERTED: "EVENT_USER_MANAGE_DATA_INSERTED",

  EVENT_BRANCH_MANAGE_FILTER_CHANGED: "EVENT_BRANCH_MANAGE_FILTER_CHANGED",
  EVENT_BRANCH_MANAGE_DATA_UPDATED: "EVENT_BRANCH_MANAGE_DATA_UPDATED",
  EVENT_BRANCH_MANAGE_DATA_INSERTED: "EVENT_BRANCH_MANAGE_DATA_INSERTED",
  EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL: "EVENT_BRANCH_MANAGE_EDIT_MODAL_CALL",

  EVENT_FAQ_MANAGE_FILTER_CHANGED: "EVENT_FAQ_MANAGE_FILTER_CHANGED",
  EVENT_FAQ_MANAGE_DATA_UPDATED: "EVENT_FAQ_MANAGE_DATA_UPDATED",
  EVENT_FAQ_MANAGE_DATA_INSERTED: "EVENT_FAQ_MANAGE_DATA_INSERTED",
  EVENT_FAQ_MANAGE_EDIT_MODAL_CALL: "EVENT_FAQ_MANAGE_EDIT_MODAL_CALL",

  EVENT_BBS_MANAGE_FILTER_CHANGED: "EVENT_BBS_MANAGE_FILTER_CHANGED",
  EVENT_BBS_MANAGE_DATA_UPDATED: "EVENT_BBS_MANAGE_DATA_UPDATED",
  EVENT_BBS_MANAGE_DATA_INSERTED: "EVENT_BBS_MANAGE_DATA_INSERTED",
  EVENT_BBS_MANAGE_EDIT_MODAL_CALL: "EVENT_BBS_MANAGE_EDIT_MODAL_CALL",

  EVENT_PROMOTION_MANAGE_FILTER_CHANGED:
    "EVENT_PROMOTION_MANAGE_FILTER_CHANGED",
  EVENT_PROMOTION_MANAGE_DATA_UPDATED: "EVENT_PROMOTION_MANAGE_DATA_UPDATED",
  EVENT_PROMOTION_MANAGE_DATA_INSERTED: "EVENT_PROMOTION_MANAGE_DATA_INSERTED",
  EVENT_PROMOTION_MANAGE_EDIT_MODAL_CALL:
    "EVENT_PROMOTION_MANAGE_EDIT_MODAL_CALL",

  EVENT_NOTICE_MANAGE_FILTER_CHANGED: "EVENT_NOTICE_MANAGE_FILTER_CHANGED",
  EVENT_NOTICE_MANAGE_DATA_UPDATED: "EVENT_NOTICE_MANAGE_DATA_UPDATED",
  EVENT_NOTICE_MANAGE_DATA_INSERTED: "EVENT_NOTICE_MANAGE_DATA_INSERTED",
  EVENT_NOTICE_MANAGE_EDIT_MODAL_CALL: "EVENT_NOTICE_MANAGE_EDIT_MODAL_CALL",

  EVENT_PAYMENT_REGISTER_DATA_CHANGED: "EVENT_PAYMENT_REGISTER_DATA_CHANGED",
  EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL:
    "EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL",
  EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED:
    "EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED",

  EVENT_PAYMENT_LIST_FILTER_CHANGED: "EVENT_PAYMENT_LIST_FILTER_CHANGED",
  EVENT_PAYMENT_LIST_DATA_CHANGED: "EVENT_PAYMENT_LIST_DATA_CHANGED",
  EVENT_PAYMENT_LIST_DATA_CLEAR_ALL: "EVENT_PAYMENT_LIST_DATA_CLEAR_ALL",
  EVENT_PAYMENT_LIST_NEED_REFRESH: "EVENT_PAYMENT_LIST_NEED_REFRESH",
  EVENT_PAYMENT_LIST_SUB_NEED_REFRESH: "EVENT_PAYMENT_LIST_SUB_NEED_REFRESH",

  EVENT_CITY_MANAGE_EDIT_MODAL_CALL: "EVENT_CITY_MANAGE_EDIT_MODAL_CALL",
  EVENT_CITY_MANAGE_DATA_UPDATE: "EVENT_CITY_MANAGE_DATA_UPDATE",

  EVENT_HOMEPAGE_DRAW_MODAL_MAP: "EVENT_HOMEPAGE_DRAW_MODAL_MAP",

  EVENT_PRODUCT_DATA_CLEAR: "EVENT_PRODUCT_DATA_CLEAR",
  EVENT_PRODUCT_DATA_CHANGE: "EVENT_PRODUCT_DATA_CHANGE",

  EVENT_PRODUCT_REVIEW_MODAL_CALL: "EVENT_PRODUCT_REVIEW_MODAL_CALL",

  EVENT_REQUEST_RESERVE_VOLUME_CHANGE: "EVENT_REQUEST_RESERVE_VOLUME_CHANGE",

  EVENT_LOGITALK_CHAT_USER_CHANGE: "EVENT_LOGITALK_CHAT_USER_CHANGE",
  EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE:
    "EVENT_LOGITALK_LOGISTICS_SEARCH_FILTER_CHANGE",
  EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE:
    "EVENT_LOGITALK_LOGISTICS_COMP_DETAIL_DATA_CHANGE",
  EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE:
    "EVENT_LOGITALK_LOGISTICS_SELECTED_COMP_USER_CHANGE",

  EVENT_LOGITALK_SHIPPER_SEARCH_DATA_CHANGE:
    "EVENT_LOGITALK_SHIPPER_SEARCH_DATA_CHANGE",
  EVENT_LOGITALK_SHIPPER_SEARCH_FILTER_CHANGE:
    "EVENT_LOGITALK_SHIPPER_SEARCH_FILTER_CHANGE",

  EVENT_CARD_LOGIS_RECEIPT_DATA_CHANGE: "EVENT_CARD_LOGIS_RECEIPT_DATA_CHANGE",
  EVENT_INIT_CODE_ACCOUNT_LEVEL: "EVENT_INIT_CODE_ACCOUNT_LEVEL",
  EVENT_INIT_CODE_BRANCH_TYPE: "EVENT_INIT_CODE_BRANCH_TYPE",
  EVENT_INIT_CODE_FAQ_TYPE: "EVENT_INIT_CODE_FAQ_TYPE",
  EVENT_INIT_CODE_PAYMENT_TAX_TYPE: "EVENT_INIT_CODE_PAYMENT_TAX_TYPE",
  EVENT_INIT_CODE_PAYMENT_STATUE: "EVENT_INIT_CODE_PAYMENT_STATUE",
  EVENT_LOGIN_AUTO_FAIL: "EVENT_LOGIN_AUTO_FAIL",
  EVENT_LOGIN_AUTO_SUCCESS: "EVENT_LOGIN_AUTO_SUCCESS",
  EVENT_AUTO_RECONNECTED: "EVENT_AUTO_RECONNECTED",

  EVENT_CUSTOMS_STORE_KEYWORD_CHANGED: "EVENT_CUSTOMS_STORE_KEYWORD_CHANGED",

  EVENT_ERP_CUSTOMS_STORE_CREATED: "EVENT_ERP_CUSTOMS_STORE_CREATED",
  EVENT_ERP_CUSTOMS_STORE_MANAGE_FILTER_CHANGED:
    "EVENT_ERP_CUSTOMS_STORE_MANAGE_FILTER_CHANGED",

  EVENT_ERP_CUSTOMS_STORE_REQUEST_MANAGE_FILTER_CHANGED:
    "EVENT_ERP_CUSTOMS_STORE_REQUEST_MANAGE_FILTER_CHANGED",

  EVENT_ERP_PRODUCT_REQUEST_MANAGE_FILTER_CHANGED_FILTER_CHANGED:
    "EVENT_ERP_PRODUCT_REQUEST_MANAGE_FILTER_CHANGED_FILTER_CHANGED",

  EVENT_ERP_CUSTOMS_STORE_REVIEW_MANAGE_FILTER_CHANGED_FILTER_CHANGED:
    "EVENT_ERP_CUSTOMS_STORE_REVIEW_MANAGE_FILTER_CHANGED_FILTER_CHANGED",

  EVENT_ERP_PRODUCT_REQUEST_REPLY_MANAGE_FILTER_CHANGED_FILTER_CHANGED:
    "EVENT_ERP_PRODUCT_REQUEST_REPLY_MANAGE_FILTER_CHANGED_FILTER_CHANGED",

  EVENT_AD_MANAGE_EDIT_MODAL_CALL: "EVENT_AD_MANAGE_EDIT_MODAL_CALL",
  EVENT_AD_MANAGE_FILTER_CHANGED: "EVENT_AD_MANAGE_FILTER_CHANGED",
  EVENT_AD_MANAGE_DATA_UPDATED: "EVENT_AD_MANAGE_DATA_UPDATED",

  EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL: "EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL",
  EVENT_BLOGO_MANAGE_FILTER_CHANGED: "EVENT_BLOGO_MANAGE_FILTER_CHANGED",
  EVENT_BLOGO_MANAGE_DATA_UPDATED: "EVENT_BLOGO_MANAGE_DATA_UPDATED",

  EVENT_PICK_PLACE_MANAGE_EDIT_MODAL_CALL:
    "EVENT_PICK_PLACE_MANAGE_EDIT_MODAL_CALL",
  EVENT_PICK_PLACE_MANAGE_FILTER_CHANGED:
    "EVENT_PICK_PLACE_MANAGE_FILTER_CHANGED",
  EVENT_PICK_PLACE_MANAGE_DATA_UPDATED: "EVENT_PICK_PLACE_MANAGE_DATA_UPDATED",

  EVENT_LOGISTORY_MANAGE_EDIT_MODAL_CALL:
    "EVENT_LOGISTORY_MANAGE_EDIT_MODAL_CALL",
  EVENT_LOGISTORY_MANAGE_FILTER_CHANGED:
    "EVENT_LOGISTORY_MANAGE_FILTER_CHANGED",
  EVENT_LOGISTORY_MANAGE_DATA_UPDATED: "EVENT_LOGISTORY_MANAGE_DATA_UPDATED",
  EVENT_MALL_BANNER_MANAGE_EDIT_MODAL_CALL:
    "EVENT_MALL_BANNER_MANAGE_EDIT_MODAL_CALL",
  EVENT_MALL_BANNER_MANAGE_FILTER_CHANGED:
    "EVENT_MALL_BANNER_MANAGE_FILTER_CHANGED",
  EVENT_MALL_BANNER_MANAGE_DATA_UPDATED:
    "EVENT_MALL_BANNER_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_REQUEST_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_REQUEST_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_REQUEST_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_REQUEST_MANAGE_DATA_UPDATED",

  EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED:
    "EVENT_MALL_SCHEDULE_MANAGE_FILTER_CHANGED",
  EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED:
    "EVENT_MALL_SCHEDULE_MANAGE_DATA_UPDATED",
  EVENT_MALL_SCHEDULE_MANAGE_EDIT_MODAL_CALL:
    "EVENT_MALL_SCHEDULE_MANAGE_EDIT_MODAL_CALL",

  EVENT_TALK_NOTICE_DATA_UPDATE: "EVENT_TALK_NOTICE_DATA_UPDATE",
  EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE: "EVENT_TALK_CHAT_ROOM_NOTICE_CHANGE",

  //스케쥴 예약관리
  EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_BOOKING_MANAGE_FILTER_CHANGED",

  //핫딜 예약관리
  EVENT_HOTDEAL_BOOKING_MANAGE_FILTER_CHANGED:
    "EVENT_HOTDEAL_BOOKING_MANAGE_FILTER_CHANGED",

  //핫딜 상품관리
  EVENT_MALL_HOTDEAL_MANAGE_FILTER_CHANGED:
    "EVENT_MALL_HOTDEAL_MANAGE_FILTER_CHANGED",
  EVENT_MALL_HOTDEAL_MANAGE_EDIT_MODAL_CALL:
    "EVENT_MALL_HOTDEAL_MANAGE_EDIT_MODAL_CALL",

  //핫딜 이미지 관리
  EVENT_MALL_HOTDEAL_IMAGE_MANAGE_EDIT_MODAL_CALL:
    "EVENT_MALL_HOTDEAL_IMAGE_MANAGE_EDIT_MODAL_CALL",
  EVENT_MALL_HOTDEAL_IMAGE_MANAGE_FILTER_CHANGED:
    "EVENT_MALL_HOTDEAL_IMAGE_MANAGE_FILTER_CHANGED",
  EVENT_MALL_HOTDEAL_IMAGE_MANAGE_DATA_UPDATED:
    "EVENT_MALL_HOTDEAL_IMAGE_MANAGE_DATA_UPDATED",

  //관리자 등록업체 정보 관리
  EVENT_ADMIN_COMPANY_INFO_MANAGE_FILTER_CHANGE:
    "EVENT_ADMIN_COMPANY_INFO_MANAGE_FILTER_CHANGE",
  EVENT_ADMIN_COMPANY_INFO_MANAGE_COMP_SELECT:
    "EVENT_ADMIN_COMPANY_INFO_MANAGE_COMP_SELECT",

  //홍보 비디오 관리
  EVENT_AD_VIDEO_MANAGE_EDIT_MODAL_CALL:
    "EVENT_AD_VIDEO_MANAGE_EDIT_MODAL_CALL",
  EVENT_AD_VIDEO_MANAGE_FILTER_CHANGED: "EVENT_AD_VIDEO_MANAGE_FILTER_CHANGED",
  EVENT_AD_VIDEO_MANAGE_DATA_UPDATED: "EVENT_AD_VIDEO_MANAGE_DATA_UPDATED",

  //FCL 예약관리
  EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_BOOKING_FCL_MANAGE_FILTER_CHANGED",

  //항공사/공급사 관리
  EVENT_AIRLINE_MANAGE_EDIT_MODAL_CALL: "EVENT_AIRLINE_MANAGE_EDIT_MODAL_CALL",
  EVENT_AIRLINE_MANAGE_FILTER_CHANGED: "EVENT_AIRLINE_MANAGE_FILTER_CHANGED",
  EVENT_AIRLINE_MANAGE_DATA_UPDATE: "EVENT_AIRLINE_MANAGE_DATA_UPDATE",

  //스케쥴 항공 관리
  EVENT_SCHEDULE_AIR_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_AIR_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_AIR_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_AIR_MANAGE_DATA_UPDATED",
  EVENT_SCHEDULE_AIR_MANAGE_EDIT_MODAL_CALL:
    "EVENT_SCHEDULE_AIR_MANAGE_EDIT_MODAL_CALL",

  //스케쥴 항공 예약페이지 이동
  EVENT_MOVE_RESERVE_AIR: "EVENT_MOVE_RESERVE_AIR",

  //ERP 스케쥴 프로모션 관리
  EVENT_SCHEDULE_PROMOTION_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_PROMOTION_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_PROMOTION_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_PROMOTION_MANAGE_DATA_UPDATED",
  EVENT_SCHEDULE_PROMOTION_MANAGE_EDIT_MODAL_CALL:
    "EVENT_SCHEDULE_PROMOTION_MANAGE_EDIT_MODAL_CALL",
  //ERP 스케쥴 항공 예약관리
  EVENT_SCHEDULE_AIR_BOOKING_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_AIR_BOOKING_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_AIR_BOOKING_MANAGE_DATA_UPDATE:
    "EVENT_SCHEDULE_AIR_BOOKING_MANAGE_DATA_UPDATE",

  //로지톡 기업화주 검색
  EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED:
    "EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED",
  EVENT_LOGITALK_SEARCH_SHIPPER_COMP_SELECT:
    "EVENT_LOGITALK_SEARCH_SHIPPER_COMP_SELECT",
  EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT:
    "EVENT_LOGITALK_SEARCH_EXPORT_COMP_SELECT",

  EVENT_SHIPPER_MANAGE_FILTER_CHANGED: "EVENT_SHIPPER_MANAGE_FILTER_CHANGED",
  EVENT_SHIPPER_MANAGE_DATA_UPDATE: "EVENT_SHIPPER_MANAGE_DATA_UPDATE",
  EVENT_SHIPPER_MANAGE_SELECT_COMP: "EVENT_SHIPPER_MANAGE_SELECT_COMP",
  EVENT_SHIPPER_MANAGE_EDIT_MODAL_CALL: "EVENT_SHIPPER_MANAGE_EDIT_MODAL_CALL",

  EVENT_CHANGED_LOGIWORK_OCEAN_LOOK_URL:
    "EVENT_CHANGED_LOGIWORK_OCEAN_LOOK_URL",

  EVENT_LOGIWORK_REQUEST_TRANSIT_FILTER_CHANGED:
    "EVENT_LOGIWORK_REQUEST_TRANSIT_FILTER_CHANGED",
  EVENT_LOGIWORK_REQUEST_TRANSIT_DATA_UPDATE:
    "EVENT_LOGIWORK_REQUEST_TRANSIT_DATA_UPDATE",

  EVENT_SCHEDULE_AIR_PRICE_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_AIR_PRICE_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_AIR_PRICE_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_AIR_PRICE_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_FCL_PRICE_DEFAULT_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_FCL_PRICE_DEFAULT_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_FCL_PRICE_DEFAULT_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_FCL_PRICE_DEFAULT_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_FCL_PRICE_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_FCL_PRICE_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_FCL_PRICE_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_FCL_PRICE_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_FCL_PRICE_FOREIGN_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_FCL_PRICE_FOREIGN_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_FCL_PRICE_FOREIGN_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_FCL_PRICE_FOREIGN_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_FCL_PRICE_CUSTOM_GRADE_MANAGE_FILTER_CHANGED:
    "EVENT_SCHEDULE_FCL_PRICE_CUSTOM_GRADE_MANAGE_FILTER_CHANGED",
  EVENT_SCHEDULE_FCL_PRICE_CUSTOM_GRADE_MANAGE_DATA_UPDATED:
    "EVENT_SCHEDULE_FCL_PRICE_CUSTOM_GRADE_MANAGE_DATA_UPDATED",

  EVENT_SCHEDULE_LCL_FIXED_PRICE_FILTER_CHANGED:
    "EVENT_SCHEDULE_LCL_FIXED_PRICE_FILTER_CHANGE",
  EVENT_SCHEDULE_LCL_ADDED_PRICE_FILTER_CHANGED:
    "EVENT_SCHEDULE_LCL_ADDED_PRICE_FILTER_CHANGE",

  EVENT_TALK_CORP_INFO_HISTORY_FILTER_CHANGED:
    "EVENT_TALK_CORP_INFO_HISTORY_FILTER_CHANGED",

  EVENT_TALK_NEWS_MANAGE_MODAL_CALL: "EVENT_TALK_NEWS_MANAGE_MODAL_CALL",
  EVENT_TALK_NEWS_SUBSCRIBE_CHANGED: "EVENT_TALK_NEWS_SUBSCRIBE_CHANGED",
  EVENT_TALK_NEWS_INFO_MODAL_CALL: "EVENT_TALK_NEWS_INFO_MODAL_CALL",

  EVENT_ENTIRE_TALK_ROOM_EDIT_MODAL_CALL:
    "EVENT_ENTIRE_TALK_ROOM_EDIT_MODAL_CALL",
  EVENT_ENTIRE_TALK_ROOM_LIST_UPDATE: "EVENT_ENTIRE_TALK_ROOM_LIST_UPDATE",
  //   ---WebView
  EVENT_SEARCH_USER_MODAL_CALL: "EVENT_SEARCH_USER_MODAL_CALL",
};

let isDebug = false;

export default class BuddibleSocket {
  constructor() {
    if (BuddibleSocket.exists) {
      return BuddibleSocket.instance;
    }
    this._socketServer = null;
    this._eventListener = {};
    this._localEventListener = {};
    this._instanceEventListener = {};
    this._Dataset = {};
    this._localDataset = new Map();
    this._connectWaitingList = [];
    this._util = new Utilities();
    this._isConnected = false;
    this._isReconnect = false;
    this._UUID = this._util.makeUUIDv4();
    BuddibleSocket.instance = this;
    BuddibleSocket.exists = true;
    this.dataDserverUrl = "https://www.gbts.co.kr/backside";
    return this;
  }

  //change socket ref
  changeServer = (str) => {
    this._socketServer = str;
    // console.log("changeServer", this._socketServer);
  };

  isConnected = () => {
    return this._isConnected;
  };

  getState = () => {
    return this._socketServer;
  };

  addLocalEventListener = (msgID, className, callback) => {
    //isDebug && console.log('addLocalEventListener', className);
    let listeners = [];
    if (this._localEventListener[msgID]) {
      let isExist = false;
      for (let i = 0; i < this._localEventListener[msgID].length; i++) {
        if (this._localEventListener[msgID][i].className === className) {
          this._localEventListener[msgID][i].callback = callback;
          isExist = true;
          return;
        }
      }
      if (isExist === false) {
        listeners = [
          ...this._localEventListener[msgID],
          {
            className: className,
            callback: callback,
          },
        ];
      }
    } else {
      listeners = [
        {
          className: className,
          callback: callback,
        },
      ];
    }
    this._localEventListener = {
      ...this._localEventListener,
      [msgID]: listeners,
    };
    //isDebug && console.log('_localEventListener',this._localEventListener);
  };

  removeLocalEventListener = (msgID, className) => {
    //isDebug && console.log('removeLocalEventListener',this._localEventListener);
    if (Array.isArray(this._localEventListener[msgID]))
      this._localEventListener[msgID] = this._localEventListener[msgID].filter(
        (item) => item.className !== className
      );
  };

  //send message to server
  sendLocalMessage = (msgID, className, data, callback) => {
    this.fireLocalEventListener(msgID, className, data, callback);
    //isDebug && console.log(e);
  };

  fireLocalEventListener = (msgID, className, newData, callback) => {
    let beforeData = this._localDataset.get(msgID);
    this._localDataset.set(msgID, newData);
    //isDebug && console.log(this._localEventListener,msgID,beforeData,newData);
    if (this._localEventListener.hasOwnProperty(msgID)) {
      //isDebug && console.log('noneListener in normal');

      if (this._localEventListener[msgID]) {
        for (let i = 0; i < this._localEventListener[msgID].length; i++) {
          try {
            if (this._localEventListener[msgID][i].className !== className)
              this._localEventListener[msgID][i].callback(beforeData, newData);
          } catch (e) {}
        }
      }
    }
  };

  getLocalDataSet = (msgID) => {
    return this._localDataset.get(msgID);
  };

  //send message to server
  sendMessage = (e, msgID, callback) => {
    //console.log("sendMessage");
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);
    axios.defaults.withCredentials = true;
    axios
      .post(
        this.dataDserverUrl + e.file,
        {
          message: e,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log("axios handleData");
        // isDebug && console.log("axios handleData", response.data);
        // isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(
          result.msgId ? result.msgId : result.socketMsgID,
          this._Dataset[result.msgId],
          result,
          () => {}
        );
      })
      .catch((response) => {
        console.log("Error!", response);
        //alert("데이터 통신중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.");
        this.fireEventListener(
          MsgIDList.EVENT_SOCKET_DATA_ERROR,
          {},
          {},
          () => {}
        );
      });

    this.writeLog();
  };

  //send message to server
  sendMessagePure = (e, msgID, callback) => {
    //console.log("sendMessage");
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);
    axios
      .post(
        e.file,
        {
          message: e,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log("axios handleData");
        // isDebug && console.log("axios handleData", response.data);
        // isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(
          result.msgId ? result.msgId : result.socketMsgID,
          this._Dataset[result.msgId],
          result,
          () => {}
        );
      })
      .catch((response) => {
        console.log("Error!", response);

        //alert("데이터 통신중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.");
        this.fireEventListener(
          MsgIDList.EVENT_SOCKET_DATA_ERROR,
          {},
          {},
          () => {}
        );
      });

    this.writeLog();
  };

  //send message to server
  sendSocketMessage = (e, msgID, callback) => {
    if (!this._isConnected) {
      this._connectWaitingList.push({
        className: "socket",
        callback: () => {
          setTimeout(() => {
            this.sendSocketMessage(e, msgID, callback);
          }, 500);
        },
      });
      return;
    }

    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }

    isDebug && console.log("sendMessage", e);

    let message = JSON.stringify(e);
    // console.log(this._UUID, this._socketServer);
    this._socketServer.sendMessage(message);
    this.writeLog();
  };

  //send message to server
  getShortTag = (e, msgID, callback) => {
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);

    axios
      .post(
        "/v4/shorten",
        {
          ...e,
          group_guid: "Bl97a5Ek61V",
          domain: "bit.ly",
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        // isDebug && console.log("axios handleData", response.data);
        // isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(msgID, this._Dataset[msgID], result, () => {});
      })
      .catch((response) => {
        console.log("Error!");
      });

    this.writeLog();
  };

  handleData = (data) => {
    // console.log("handleData");
    let result = {};
    try {
      result = JSON.parse(data);
    } catch (e) {
      // console.log(data);
      alert("데이터 통신중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.");
      this.fireEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        {},
        {},
        () => {}
      );
      return;
    }

    isDebug && console.log("socket handleData", result);
    this.fireLocalEventListener(result.method, result.msgId, result);
    this.fireEventListener(
      result.msgId ? result.msgId : result.socketMsgID,
      this._Dataset[result.msgId],
      result,
      () => {}
    );
  };

  addEventListener = (msgID, className, callback) => {
    if (!this._eventListener[msgID]) {
      this._eventListener = {
        ...this._eventListener,
        [msgID]: [
          {
            className: className,
            callback: callback,
          },
        ],
      };
    } else {
      this._eventListener[msgID] = [
        ...this._eventListener[msgID],
        {
          className: className,
          callback: callback,
        },
      ];
    }
    isDebug && console.log("_eventListener", this._eventListener);
  };

  addInstanceEventListener = (msgID, callback) => {
    //console.log(`addInstanceEventListener = (${msgID}, callback)`);
    if (!this._instanceEventListener[msgID]) {
      isDebug && console.log("no listener", this._instanceEventListener);
      this._instanceEventListener = {
        ...this._instanceEventListener,
        [msgID]: [
          {
            callback: callback,
          },
        ],
      };
      isDebug && console.log("no listener2", this._instanceEventListener);
    } else {
      isDebug && console.log("yes listener", this._instanceEventListener);
      this._instanceEventListener[msgID] = [
        ...this._instanceEventListener[msgID],
        {
          _instanceEventListener: callback,
        },
      ];
      isDebug && console.log("yes listener2", this._instanceEventListener);
    }
  };

  removeInstanceEventListener = (msgID) => {
    isDebug && console.log("removeInstanceEventListener", msgID);
    delete this._instanceEventListener[msgID];
  };

  removeEventListener = (msgID, className) => {
    this._eventListener[msgID] = this._eventListener[msgID].filter(
      (item) => item.className !== className
    );
  };

  fireEventListener = (msgID, beforeData, newData, callback) => {
    //console.log("fireEventListener msgID", msgID);
    //console.log(this._eventListener);
    // eslint-disable-next-line array-callback-return
    if (!this._eventListener[msgID]) {
      //console.log('noneListener in normal');
      //console.log(this._instanceEventListener);
      if (this._instanceEventListener[msgID]) {
        this._instanceEventListener[msgID].map((e) => {
          //console.log('fired event to instance event listener');
          try {
            e.callback(beforeData, newData);
          } catch (e) {}

          if (newData.socketMsgID) {
            this.removeInstanceEventListener(newData.socketMsgID);
          }
          return true;
        });
      }
    } else {
      //console.log('hihihi');
      this._eventListener[msgID].forEach((e) => {
        try {
          return e.callback(beforeData, newData);
        } catch (e) {}
      });
      this._Dataset[msgID] = newData;
    }
  };

  writeLog = () => {
    /*
        this._socketServer = null;
        this._eventListener = {};
        this._instanceEventListener = {};
        this._Dataset = {};
         */
    if (isDebug) {
      console.log("_socketServer", this._socketServer);
      console.log("_eventListener", this._eventListener);
      console.log("_instanceEventListener", this._instanceEventListener);
      console.log("_Dataset", this._Dataset);
    }
  };

  waitingForConnect(className, callback) {
    if (this._isConnected) callback(true);

    this._connectWaitingList.push({ className: className, callback: callback });
  }

  responseWaitingForConnect() {
    for (let i = 0; i < this._connectWaitingList.length; i++) {
      let listItem = this._connectWaitingList[i];
      if (listItem.hasOwnProperty("callback")) listItem["callback"](true);
    }
    this._connectWaitingList = [];
  }

  handleOpen = () => {
    this._isConnected = true;
    if (!this._isReconnect) {
      this._isReconnect = true;
    } else {
      this.sendLocalMessage(
        MsgIDList.EVENT_AUTO_RECONNECTED,
        "BuddibleSocket",
        {},
        () => {}
      );
    }
    this.responseWaitingForConnect();
    isDebug && console.log("connected:)");
  };
  handleClose = () => {
    this._isConnected = false;
    isDebug && console.log("disconnected:(");
  };
}
