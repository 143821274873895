import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
export default function RouteChangeTracker(props) {
  let location = useLocation();

  useEffect(() => {
    if (window.location.port === "") {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }

    //ga('send', 'pageview', location.pathname)
  }, [location]);

  return <></>;
}
