import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";
import LogoSvg from "../../../../assets/LogoSvg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import remarkRehype from "remark-rehype";

/*
import rehypeDocument from "rehype-document";
import rehypeRaw from "rehype-raw";
import rehypeFormat from "rehype-format";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";

*/

/*
rehypeHighlight,
                      rehypeDocument,
                      rehypeFormat,
                      rehypeRaw,
                      rehypeStringify,
                      remarkParse,
                      remarkRehype,
 */

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
export default function FreightCostModal({
  loginInfo,
  modal_data,
  setIsFreightCostModalOpen,
  scheduleUseLog,
  requestEnquiry,
  // handleCompareCostData,
  handleCompareCostData,
}) {
  const [isShowCompare, setIsShowCompare] = useState(false);
  const [botTalk, setBotTalk] = useState("");
  const [isTalking, setIsTalking] = useState(false);
  const [compareData, setCompareData] = useState([]);
  const [sortModel, setSortModel] = useState({
    field: "allTotal20",
    sort: "asc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const isNSS =
    modal_data.COMP_CD === "NSSL" ||
    modal_data.COMP_CD === "NSS" ||
    modal_data.sm_FWD_NO === "NSSL" ||
    modal_data.sm_FWD_NO === "NSS";
  const isSNK = modal_data.COMP_CD === "SKR" || modal_data.sm_FWD_NO === "SKR";
  const isHAS = modal_data.COMP_CD === "HAS" || modal_data.sm_FWD_NO === "HAS";
  const isPCS = modal_data.COMP_CD === "PCS" || modal_data.sm_FWD_NO === "PCS";
  const isDJS = modal_data.COMP_CD === "DJS" || modal_data.sm_FWD_NO === "DJS";
  const isCostRowDisplay = !isNSS && !isSNK && !isHAS && !isPCS && !isDJS;

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(compareData)) {
      sortCompareData(compareData);
    }
  }, [sortModel]);

  const sortCompareData = (data) => {
    let dataList = [...data];
    dataList.sort((a, b) => {
      return a[sortModel.field] - b[sortModel.field];
    });

    if (sortModel.sort === "desc") {
      dataList.reverse();
    }

    setCompareData(dataList);
  };

  const handleCompareCostView = (value) => {
    setIsShowCompare(value);
    if (compareData.length < 1) {
      setIsLoading(true);
      handleCompareCostData((data) => {
        sortCompareData(data);
        setIsLoading(false);
        getAIAnalysis();
      });
    }
  };

  const handleSort = (key) => {
    let sortData = {
      field: key,
      sort: sortModel.sort === "asc" ? "desc" : "asc",
    };
    setSortModel(sortData);
  };

  const actionRequestInquiry = () => {
    setIsDisabledBtn(true);
    requestEnquiry(modal_data, () => {
      setIsDisabledBtn(false);
    });
  };

  let prepaid = [];
  let collect = [];

  if (isNSS || isPCS) {
    modal_data.SurchargeInfo.forEach((item) => {
      let data = {
        kind: item.surknd,
        base: "",
        amount20: "",
        amount40: "",
      };

      if (item.dc20) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.dc20)}`;
      } else if (item.wfg20) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.wfg20)}`;
      } else if (item.wfglc) {
        data.amount20 = `${item.wfgcur} ${util.addCommas(item.wfglc)}`;
      }

      if (item.dc40) {
        data.amount40 = `${item.wfgcur} ${util.addCommas(item.dc40)}`;
      } else if (item.wfg40) {
        data.amount40 = `${item.wfgcur} ${util.addCommas(item.wfg40)}`;
      }

      if (item.dc20 || item.wfg20 || item.wfglc) {
        if (item.surpcg === "C") {
          collect.push(data);
        } else if (item.surpcg === "P") {
          prepaid.push(data);
        }
      }
    });
  } else if (isSNK || isHAS) {
    //장금상선, 흥아라인 운임 분류 로직
    const snkCost = modal_data.snkCost;
    snkCost.forEach((item) => {
      const data = {
        kind: item.col0,
        base: item.col2,
        amount20: item.col4,
        amount40: item.col5,
      };

      if (item.col1 === "Prepaid") {
        prepaid.push(data);
      } else if (item.col1 === "Collect") {
        collect.push(data);
      }
    });
  } else if (isDJS) {
    for (let currency in modal_data.CoastList.Prepaid) {
      for (let kind in modal_data.CoastList.Prepaid[currency]) {
        prepaid.push({
          kind: kind,
          base: "",
          amount20:
            currency +
            " " +
            util.addCommas(modal_data.CoastList.Prepaid[currency][kind]),
          amount40: false,
        });
      }
    }
    for (let currency in modal_data.CoastList.Collect) {
      for (let kind in modal_data.CoastList.Collect[currency]) {
        collect.push({
          kind: kind,
          base: "",
          amount20:
            currency +
            " " +
            util.addCommas(modal_data.CoastList.Collect[currency][kind]),
          amount40: false,
        });
      }
    }
  }

  async function getAIAnalysis() {
    if (isTalking) return;
    setIsTalking(true);
    try {
      let url = "/ai_service/api/ai_price";
      //let url = "/ai_service/api/schedule";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmd: "get",
          input: "운임비교",
          MSG_UID: util.makeUUIDv4(),
          SESSION: util.makeUUIDv4(),
          COMP_CD: loginInfo ? loginInfo.comp_cd : "",
          USER_ID: loginInfo ? loginInfo.user_id : "",
          PRICE_UID: modal_data.uuid,
          COMP_LVL: "",
          DOC_CLOSE: modal_data.sm_DOC_CLOSE,
          POL_CODE: modal_data.sm_POL_CODE,
          POD_CODE: modal_data.sm_POD_CODE,
        }),
      });

      const reader = response.body.getReader();
      let result = "";

      const decodeStream = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setBotTalk(result);
          setIsTalking(false);
          break;
        }
        result += decodeStream.decode(value);
        setBotTalk(result);
      }

      //const data = await response.json();
      if (response.status !== 200) {
        console.log(`Request failed with status ${response.status}`);
        setBotTalk("");
      }
      //setTextInput("");
    } catch (error) {
      console.error(error);
      setIsTalking(false);
      // alert(error.message);
    }
  }

  return (
    <>
      <div className="modal_background">
        <div
          className="modal_body bg-white rounded"
          style={{ minWidth: "60vw" }}
        >
          <div className="d-flex flex-row justify-content-center border-bottom">
            <div
              className="p-3 d-flex flex-row justify-content-between align-items-center w-100"
              style={{ maxWidth: 1400 }}
            >
              <p className="h5 font-weight-bold mr-3 p-0 m-0">운임 안내</p>
              <button
                disabled={isDisabledBtn}
                className="btn btn-sm btn-danger"
                onClick={() => setIsFreightCostModalOpen(false)}
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
          </div>
          <div
            className="overflow-auto p-3"
            style={{ maxHeight: "calc(100vh - 200px)" }}
          >
            <div className="btn-group mb-3">
              <button
                className={`btn ${
                  !isShowCompare ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => handleCompareCostView(false)}
              >
                운임 상세내역
              </button>
              <button
                className={`btn ${
                  isShowCompare ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => handleCompareCostView(true)}
              >
                동일구간 운임비교
              </button>
            </div>

            {isLoading && (
              <div className="p-5 bg-light rounded-lg">
                <p className="mb-0 text-center text-secondary font-weight-bold">
                  운임정보를 비교하고 있습니다...
                  <i className="fa-solid fa-gear rotate" />
                </p>
              </div>
            )}
            {!isLoading && (
              <>
                {!isShowCompare ? (
                  <>
                    <div className="mb-3">
                      <div className="mb-3">
                        <div className="d-flex flex-column flex-xl-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden">
                          <div className="border-right flex-grow-1">
                            <div
                              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                              style={{ minWidth: 100 }}
                            >
                              선사
                            </div>
                            <div
                              className="d-flex flex-row justify-content-center align-items-center"
                              style={{ height: 55 }}
                            >
                              {modal_data.COMP_LOGO ? (
                                <div
                                  style={{
                                    backgroundImage: `url('${
                                      modal_data.COMP_LOGO.split("?")[0]
                                    }')`,
                                    backgroundPosition: "50% 50%",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",

                                    width: "50px",
                                    height: "50px",
                                  }}
                                />
                              ) : (
                                <LogoSvg width={"60px"} />
                              )}
                            </div>
                          </div>
                          <div className="border-right flex-grow-1">
                            <div
                              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                              style={{ minWidth: 100 }}
                            >
                              FCL/LCL
                            </div>
                            <div className="p-2 text-center">
                              {modal_data.sm_FCL_LCL}
                            </div>
                          </div>
                          <div className="border-right flex-grow-1">
                            <div
                              className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                              style={{ minWidth: 100 }}
                            >
                              VESSEL/VOY
                            </div>

                            <div className="p-2 text-center">
                              <p>{modal_data.sm_VESSEL}</p>
                              <p>{modal_data.sm_VOY}</p>
                            </div>
                          </div>
                          <div className="flex-grow-1 d-flex flex-column flex-xl-row flex-wrap">
                            <div className="border-right flex-grow-1">
                              <div
                                className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                                style={{ minWidth: 100 }}
                              >
                                POL
                              </div>
                              <div className="p-2 text-center">
                                <p className="font-weight-bold">
                                  {modal_data.sm_POL_NAME}
                                </p>
                                <p className="text-danger">
                                  {modal_data.sm_DEPARTURE_DATE}
                                </p>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <div
                                className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                                style={{ minWidth: 100 }}
                              >
                                POD
                              </div>
                              <div className="p-2 text-center">
                                <p className="font-weight-bold">
                                  {modal_data.sm_POD_NAME}
                                </p>
                                <p className="text-danger">
                                  {modal_data.sm_ARRIVE_DATE}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <>
                        <div>
                          <p className="mt-3 font-weight-bold">
                            ※ Dry Container 기준
                          </p>
                          <div className="container-fluid m-0 p-0 text-center border">
                            <div className="row m-0 p-0 border-bottom bg-light">
                              <div className="col-4 m-0 p-0 py-3 border-right">
                                Kind
                              </div>
                              <div className="col-8 m-0 p-0">
                                <div className="row m-0 p-0 py-1 border-bottom">
                                  <div className="col-12">Amount</div>
                                </div>
                                <div className="row m-0 p-0">
                                  <div className="col-6 m-0 py-1 border-right">
                                    20
                                  </div>
                                  <div className="col-6 m-0 py-1">40</div>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0 p-0">
                              <div className="col-4 m-0 p-0 py-1  bg-light border-right">
                                OCF
                              </div>
                              <div className="col-8 m-0 p-0 text-right">
                                <div className="row m-0 p-0 h-100">
                                  <div className="col m-0 py-1 border-right">
                                    ${" "}
                                    {modal_data["SP_20_INCH"] &&
                                      util.addCommas(modal_data["SP_20_INCH"])}
                                  </div>
                                  <div className="col m-0 py-1">
                                    ${" "}
                                    {modal_data["SP_40_INCH"] &&
                                      util.addCommas(modal_data["SP_40_INCH"])}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <p className="mt-1 mt-3">
                              Prepaid : {prepaid.length}
                            </p>

                            <div className="container-fluid m-0 p-0 text-center">
                              <div className="row m-0 p-0 border bg-light">
                                <div
                                  className={`${
                                    isSNK || isHAS ? "col-5" : " col-6"
                                  } col-auto m-0 flex-grow-1 py-3 bg-light border-right`}
                                >
                                  Kind
                                </div>
                                {(isSNK || isHAS) && (
                                  <div className="col-2 m-0 p-0 py-3 border-right">
                                    Base
                                  </div>
                                )}
                                <div
                                  className={`${
                                    isSNK || isHAS ? "col-5" : " col-6"
                                  } m-0 p-0`}
                                >
                                  <div className="row m-0 p-0 py-1 border-bottom">
                                    <div className="col-12">Amount</div>
                                  </div>
                                  <div className="row m-0 p-0">
                                    <div className="col m-0 py-1 border-right">
                                      20
                                    </div>
                                    <div className="col m-0 py-1">40</div>
                                  </div>
                                </div>
                              </div>
                              {prepaid.length > 0 &&
                                prepaid.map((item, index) => {
                                  if (!item.amount20 || !item.amount40)
                                    return null;
                                  return (
                                    <div
                                      className="row border-left border-right border-bottom m-0 p-0"
                                      key={index}
                                    >
                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 flex-grow-1 py-1 bg-light border-right`}
                                      >
                                        {item.kind}
                                      </div>
                                      {(isSNK || isHAS) && (
                                        <div className="col-2 m-0 py-1 border-right">
                                          {item.base}
                                        </div>
                                      )}

                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 p-0`}
                                      >
                                        <div className="row h-100 m-0 p-0">
                                          <div className="col-6 flex-grow-1 m-0 py-1 border-right text-right">
                                            {item.amount20}
                                          </div>
                                          <div className="col-6 m-0 py-1 text-right">
                                            {item.amount40}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                              {prepaid.length > 0 &&
                                prepaid.map((item, index) => {
                                  if (!(item.amount20 && !item.amount40))
                                    return null;
                                  return (
                                    <div
                                      className={`row border m-0 ${
                                        isDJS ? "border-top-0" : "mt-1"
                                      }  p-0`}
                                      key={index}
                                    >
                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 flex-grow-1 py-1 bg-light border-right`}
                                      >
                                        {item.kind}
                                      </div>
                                      {(isSNK || isHAS) && (
                                        <div className="col-2 m-0 py-1 border-right">
                                          {item.base}
                                        </div>
                                      )}

                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 p-0`}
                                      >
                                        <div className="row h-100 m-0 p-0">
                                          <div className="col-12 flex-grow-1 m-0 py-1 text-right">
                                            {item.amount20}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div>
                            <p className="mt-1 mt-3">
                              Collect : {collect.length}
                            </p>

                            <div className="container-fluid m-0 p-0 text-center">
                              <div className="row m-0 p-0 border bg-light">
                                <div
                                  className={`${
                                    isSNK || isHAS ? "col-5" : " col-6"
                                  } col-auto m-0 flex-grow-1 py-3 bg-light border-right`}
                                >
                                  Kind
                                </div>
                                {(isSNK || isHAS) && (
                                  <div className="col-2 m-0 p-0 py-3 border-right">
                                    Base
                                  </div>
                                )}
                                <div
                                  className={`${
                                    isSNK || isHAS ? "col-5" : " col-6"
                                  } m-0 p-0`}
                                >
                                  <div className="row m-0 p-0 py-1 border-bottom">
                                    <div className="col-12">Amount</div>
                                  </div>
                                  <div className="row m-0 p-0">
                                    <div className="col m-0 py-1 border-right">
                                      20
                                    </div>
                                    <div className="col m-0 py-1">40</div>
                                  </div>
                                </div>
                              </div>
                              {collect.length > 0 &&
                                collect.map((item, index) => {
                                  if (!item.amount20 || !item.amount40)
                                    return null;
                                  return (
                                    <div
                                      className="row border-left border-right border-bottom m-0 p-0"
                                      key={index}
                                    >
                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 flex-grow-1 py-1 bg-light border-right`}
                                      >
                                        {item.kind}
                                      </div>
                                      {(isSNK || isHAS) && (
                                        <div className="col-2 m-0 py-1 border-right">
                                          {item.base}
                                        </div>
                                      )}

                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 p-0`}
                                      >
                                        <div className="row h-100 m-0 p-0">
                                          <div className="col-6 flex-grow-1 m-0 py-1 border-right text-right">
                                            {item.amount20}
                                          </div>
                                          <div className="col-6 m-0 py-1 text-right">
                                            {item.amount40}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                              {collect.length > 0 &&
                                collect.map((item, index) => {
                                  if (!(item.amount20 && !item.amount40))
                                    return null;
                                  return (
                                    <div
                                      className={`row border m-0 ${
                                        isDJS ? "border-top-0" : "mt-1"
                                      } p-0`}
                                      key={index}
                                    >
                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 flex-grow-1 py-1 bg-light border-right`}
                                      >
                                        {item.kind}
                                      </div>
                                      {(isSNK || isHAS) && (
                                        <div className="col-2 m-0 py-1 border-right">
                                          {item.base}
                                        </div>
                                      )}

                                      <div
                                        className={`${
                                          isSNK || isHAS ? "col-5" : " col-6"
                                        } m-0 p-0`}
                                      >
                                        <div className="row h-100 m-0 p-0">
                                          <div className="col-12 flex-grow-1 m-0 py-1 text-right">
                                            {item.amount20}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <p className="mt-2 text-danger">
                          ※ 제시 운임은 우대운송계약(SC)을 적용하지 않은 General
                          Rate입니다.
                        </p>
                      </>
                      <div className="row m-0 mt-2 p-0">
                        <div className="col-8 m-0 p-0">
                          <div>
                            <p className="font-weight-bold">
                              · Booking 및 마감시간 문의
                            </p>
                            <p className="ml-2">
                              김호승 본부장 (032-751-9870, hskim@bstc.kr)
                              {/*{modal_data.bookInfo.OUTPBU} ({modal_data.bookInfo.OUTPBT}
                      ,{modal_data.bookInfo.OUTPBE})*/}
                            </p>
                          </div>
                          <div className="mt-2">
                            <p className="font-weight-bold">
                              · B/L 및 EDI신고 문의
                            </p>
                            <p className="ml-2">
                              {isNSS &&
                              (modal_data.sm_FWD_NO === "NSSL" ||
                                modal_data.sm_FWD_NO === "NSS")
                                ? "이혜인 사원 (02-772-8897, elielee@namsung.co.kr)"
                                : "-등록된 담당자정보가 존재하지 않습니다."}

                              {/*{modal_data.bookInfo.OUTPDU} ({modal_data.bookInfo.OUTPDT}
                      ,{modal_data.bookInfo.OUTPDE})*/}
                            </p>
                          </div>

                          <div className="mt-2">
                            <p className="font-weight-bold">· CFS 정보</p>
                            <p className="ml-2">
                              {modal_data.bookInfo &&
                                (modal_data.bookInfo.CFS_TEL_L ||
                                  "-등록된 CFS 정보가 존재하지 않습니다.")}
                              {!modal_data.bookInfo &&
                                "-등록된 CFS 정보가 존재하지 않습니다."}
                            </p>
                          </div>
                        </div>
                        <div className="col-4 m-0 p-0">
                          {isNSS && (
                            <p className="font-weight-bold text-right">
                              Booking 가능 여부 :{" "}
                              <span
                                className={
                                  modal_data.bookInfo &&
                                  (modal_data.bookInfo.VSL_CLOSE_F === "F"
                                    ? "text-danger"
                                    : "text-primary")
                                }
                              >
                                {modal_data.bookInfo &&
                                  modal_data.bookInfo.VSL_CLOSE_F_desc}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between">
                      <div />
                      <div className="d-flex flex-row">
                        <button
                          className="mr-2 btn btn-sm btn-primary"
                          disabled={isDisabledBtn}
                          onClick={actionRequestInquiry}
                        >
                          문의하기{" "}
                          {isDisabledBtn && (
                            <i className="fa-solid fa-spinner rotate" />
                          )}
                        </button>
                        {(modal_data.sm_FWD_NO === "NSSL" ||
                          modal_data.sm_FWD_NO === "NSS") && (
                          <Link
                            to={`/ReserveFCL/${modal_data.sm_IDX}`}
                            state={{
                              CFS_TEL_L:
                                modal_data.bookInfo &&
                                (modal_data.bookInfo.CFS_TEL_L || "-"),
                            }}
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              scheduleUseLog("book", 1, modal_data);
                            }}
                          >
                            예약하기
                          </Link>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <div className="mb-3">
                        <p className="h6 font-weight-bold pl-2">
                          <span className="text-info font-weight-900">
                            <i className="fa-solid fa-robot" /> AI
                          </span>
                          가 분석한 운임비교 안내
                        </p>
                        <div className="bg-light p-3 rounded-lg text-dark mb-0 markup_div">
                          <ReactMarkdown
                            remarkPlugins={[
                              remarkGfm,
                              rehypeHighlight,
                              remarkRehype,
                            ]}
                          >
                            {botTalk ? botTalk : ""}
                          </ReactMarkdown>

                          {isTalking && (
                            <span className="text-secondary">
                              ...
                              <i className="fa-solid fa-gear rotate" />
                            </span>
                          )}
                        </div>
                      </div>
                      <p className="mt-3 h6 font-weight-bold">
                        · 20FT Container
                      </p>
                      <div className="container-fluid m-0 p-0 text-center border">
                        <CompareCostHeader
                          sortModel={sortModel}
                          handleSort={handleSort}
                        />
                        {isCostRowDisplay && (
                          <CompareCostRow
                            COMP_LOGO={modal_data.COMP_LOGO}
                            OCF={modal_data["SP_20_INCH"]}
                            minCost={modal_data.minCost}
                            isLarge={false}
                            isLast={false}
                          />
                        )}
                        {compareData.length > 0 &&
                          compareData.map((item, index) => {
                            const isLast =
                              modal_data.compareList.length - 1 === index;

                            return (
                              <CompareCostRow
                                key={item.sm_FWD_NO}
                                COMP_LOGO={item.blLOGO}
                                OCF={item["SP_20_INCH"]}
                                minCost={modal_data.minCost}
                                item={item}
                                index={index}
                                isLarge={false}
                                isLast={isLast}
                              />
                            );
                          })}
                      </div>
                      <p className="mt-3 h6 font-weight-bold">
                        · 40FT Container
                      </p>
                      <div className="container-fluid m-0 p-0 text-center border mb-3">
                        <CompareCostHeader
                          sortModel={sortModel}
                          handleSort={handleSort}
                        />
                        {isCostRowDisplay && (
                          <CompareCostRow
                            COMP_LOGO={modal_data.COMP_LOGO}
                            OCF={modal_data["SP_40_INCH"]}
                            minCost={modal_data.minCost}
                            isLarge={false}
                            isLast={false}
                          />
                        )}
                        {compareData.length > 0 &&
                          compareData.map((item, index) => {
                            const isLast =
                              modal_data.compareList.length - 1 === index;
                            return (
                              <CompareCostRow
                                key={item.sm_FWD_NO}
                                COMP_LOGO={item.blLOGO}
                                OCF={item["SP_40_INCH"]}
                                minCost={modal_data.minCost}
                                item={item}
                                index={index}
                                isLarge={true}
                                isLast={isLast}
                              />
                            );
                          })}
                      </div>

                      <div className="mb-3">
                        <p className="mt-3 h6 font-weight-bold">
                          · 예약가능 스케줄 정보
                        </p>
                        <div className="row m-0 p-0 border bg-info text-white font-weight-bold text-center">
                          <div className="col-2 m-0 p-0 py-2 border-right">
                            선사
                          </div>
                          <div className="col-2 m-0 p-0 py-2 border-right">
                            DOC CLOSE
                            {/*DOC/CARGO CLOSE*/}
                          </div>
                          <div className="col-2 m-0 p-0 py-2 border-right">
                            VESSEL/VOY
                          </div>
                          <div className="col-3 m-0 p-0 py-2 border-right">
                            POL
                          </div>
                          <div className="col-3 m-0 p-0 py-2">POD</div>
                        </div>
                        {isCostRowDisplay && (
                          <CompareCompRow
                            isRepeat={false}
                            COMP_LOGO={modal_data.COMP_LOGO}
                            sm_FCL_LCL={modal_data.sm_FCL_LCL}
                            sm_DOC_CLOSE={modal_data.sm_DOC_CLOSE}
                            sm_CARGO_CLOSE={modal_data.sm_CARGO_CLOSE}
                            sm_VESSEL={modal_data.sm_VESSEL}
                            sm_VOY={modal_data.sm_VOY}
                            sm_POL_NAME={modal_data.sm_POL_NAME}
                            sm_POD_NAME={modal_data.sm_POD_NAME}
                            sm_DEPARTURE_DATE={modal_data.sm_DEPARTURE_DATE}
                            sm_ARRIVE_DATE={modal_data.sm_ARRIVE_DATE}
                          />
                        )}
                        {compareData.length > 0 &&
                          compareData.map((item) => {
                            if (!item.isExistSche) return null;

                            return (
                              <CompareCompRow
                                key={item.sm_FWD_NO}
                                COMP_LOGO={item.blLOGO}
                                sm_FCL_LCL={item.sm_FCL_LCL}
                                sm_DOC_CLOSE={item.sm_DOC_CLOSE}
                                sm_CARGO_CLOSE={item.sm_CARGO_CLOSE}
                                sm_VESSEL={item.sm_VESSEL}
                                sm_VOY={item.sm_VOY}
                                sm_POL_NAME={modal_data.sm_POL_NAME}
                                sm_POD_NAME={modal_data.sm_POD_NAME}
                                sm_DEPARTURE_DATE={item.sm_DEPARTURE_DATE}
                                sm_ARRIVE_DATE={item.sm_ARRIVE_DATE}
                              />
                            );
                          })}
                      </div>
                    </div>

                    <p className={`h6 text-danger`}>
                      ※ 적용환율은 조회 당시 한국은행 매매 기준율을 적용한
                      값으로 추후 변경될 수 있습니다.
                    </p>

                    <div className="d-flex flex-row justify-content-between">
                      <div />
                      <div className="d-flex flex-row">
                        <button
                          className="mr-2 btn btn-sm btn-primary"
                          disabled={isDisabledBtn}
                          onClick={actionRequestInquiry}
                        >
                          문의하기{" "}
                          {isDisabledBtn && (
                            <i className="fa-solid fa-spinner rotate" />
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const CompareCostHeader = ({ sortModel, handleSort }) => (
  <div className="row m-0 p-0 border-bottom bg-light font-weight-bold">
    <div className="col-2 m-0 p-0 py-3 border-right">선사</div>
    <div className="col-10 m-0 p-0">
      <div className="row m-0 p-0">
        <div className="col-6 m-0 p-0 border-right">
          <div className="row m-0 p-0">
            <div className="col-12 m-0 p-0 py-1 border-bottom">항목별 합계</div>
            <div className="col-4 m-0 p-0 py-1 border-right">
              <p>OCF</p>
              <p> </p>
            </div>
            <div className="col-4 m-0 p-0 py-1 border-right">
              <p>Prepaid</p>
              <p> </p>
            </div>
            <div className="col-4 m-0 p-0 py-1">
              <p>Collect</p>
              <p> </p>
            </div>
          </div>
        </div>
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0 bg-secondary text-white">
            <div className="col-12 m-0 p-0 py-1 border-bottom">
              KRW 합산비교
            </div>
            <div
              className="col-4 m-0 p-0 py-1  border-right cursor_pointer"
              onClick={() => handleSort("allTotal20")}
            >
              TOTAL{" "}
              {sortModel.field === "allTotal20" && (
                <i
                  className={` ${
                    sortModel.sort === "asc"
                      ? "fa-solid fa-caret-up"
                      : "fa-solid fa-caret-down"
                  }`}
                />
              )}
            </div>
            <div
              className="col-4 m-0 p-0 py-1  border-right cursor_pointer"
              onClick={() => handleSort("pre20Total")}
            >
              <p>
                Prepaid{" "}
                {sortModel.field === "pre20Total" && (
                  <i
                    className={` ${
                      sortModel.sort === "asc"
                        ? "fa-solid fa-caret-up"
                        : "fa-solid fa-caret-down"
                    }`}
                  />
                )}
              </p>
              <p>+OCF</p>
            </div>
            <div
              className="col-4 m-0 p-0 py-1 cursor_pointer"
              onClick={() => handleSort("col20Total")}
            >
              <p>
                Collect{" "}
                {sortModel.field === "col20Total" && (
                  <i
                    className={` ${
                      sortModel.sort === "asc"
                        ? "fa-solid fa-caret-up"
                        : "fa-solid fa-caret-down"
                    }`}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const CompareCostRow = ({
  COMP_LOGO,
  OCF,
  minCost,
  item = null,
  isLarge,
  isLast = true,
}) => {
  let ocfClass = "";
  let totalClass = "";
  let totalPreClass = "";
  let totalColClass = "";

  if (item) {
    if (isLarge) {
      ocfClass =
        Number(OCF) === Number(minCost.SP_40_INCH)
          ? "text-primary font-weight-900"
          : "";
      totalClass =
        item.allTotal40 === minCost.allTotal40
          ? "text-primary font-weight-900"
          : "";
      totalPreClass =
        item.pre40Total === minCost.pre40Total
          ? "text-primary font-weight-900"
          : "";
      totalColClass =
        item.col40Total === minCost.col40Total
          ? "text-primary font-weight-900"
          : "";
    } else {
      ocfClass =
        Number(OCF) === Number(minCost.SP_20_INCH)
          ? "text-primary font-weight-900"
          : "";
      totalClass =
        item.allTotal20 === minCost.allTotal20
          ? "text-primary font-weight-900"
          : "";
      totalPreClass =
        item.pre20Total === minCost.pre20Total
          ? "text-primary font-weight-900"
          : "";
      totalColClass =
        item.col20Total === minCost.col20Total
          ? "text-primary font-weight-900"
          : "";
    }
  }

  return (
    <div
      className={`row m-0 p-0  ${isLast ? "" : "border-bottom"} ${
        item ? "" : "border border-secondary border-2"
      }`}
    >
      <div className="col-2 m-0 py-2 d-flex flex-column justify-content-center border-right">
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: 55 }}
        >
          {COMP_LOGO ? (
            <div
              style={{
                backgroundImage: `url('${COMP_LOGO.split("?")[0]}')`,
                backgroundPosition: "50% 50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",

                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <LogoSvg width={"60px"} />
          )}
        </div>
      </div>
      <div className="col-10 m-0 p-0 text-right">
        <div className="row m-0 p-0 h-100">
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            <p className={ocfClass}>USD {OCF ? util.addCommas(OCF) : "-"}</p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            {item && item.prepaid && item.prepaid.length > 0 ? (
              item.prepaid.map((data, index) => {
                let minKey = `prepaid_${isLarge ? "40" : "20"}_${data.key}`;
                let cost = data[isLarge ? "container40" : "container20"];
                let costClass = "";
                if (minCost[minKey] && minCost[minKey] === cost) {
                  costClass = "text-primary font-weight-900 ";
                }

                if (cost && cost > 0) {
                  cost = " " + util.addCommas(cost);
                } else {
                  cost = " -";
                }

                return (
                  <p key={index} className={costClass}>
                    {data.key}
                    {data.key !== "waive" ? " " + cost || " -" : ""}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right">
            {item && item.collect && item.collect.length > 0 ? (
              item.collect.map((data, index) => {
                let minKey = `collect_${isLarge ? "40" : "20"}_${data.key}`;
                let cost = data[isLarge ? "container40" : "container20"];
                let costClass = "";
                if (minCost[minKey] && minCost[minKey] === cost) {
                  costClass = "text-primary font-weight-900 ";
                }

                if (cost && cost > 0) {
                  cost = " " + util.addCommas(cost);
                } else {
                  cost = " -";
                }

                return (
                  <p key={index} className={costClass}>
                    {data.key}
                    {data.key !== "waive" ? cost : ""}
                  </p>
                );
              })
            ) : (
              <p>-</p>
            )}
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right bg-light">
            <p className={totalClass}>
              &#8361;{" "}
              {item
                ? isLarge
                  ? util.addCommas(item.allTotal40)
                  : util.addCommas(item.allTotal20)
                : "-"}
            </p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center border-right bg-light">
            <p className={totalPreClass}>
              &#8361;{" "}
              {item
                ? isLarge
                  ? util.addCommas(item.pre40Total)
                  : util.addCommas(item.pre20Total)
                : "-"}
            </p>
          </div>
          <div className="col-2 m-0 p-0 p-2 d-flex flex-column justify-content-center bg-light">
            <p className={totalColClass}>
              &#8361;
              {item
                ? isLarge
                  ? util.addCommas(item.col40Total)
                  : util.addCommas(item.col20Total)
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompareCompRow = ({
  isRepeat = true,
  COMP_LOGO,
  sm_DOC_CLOSE,
  sm_VESSEL,
  sm_VOY,
  sm_POL_NAME,
  sm_POD_NAME,
  sm_DEPARTURE_DATE,
  sm_ARRIVE_DATE,
}) => {
  return (
    <div
      className={`row m-0 p-0 border-bottom border-right border-left text-center ${
        isRepeat ? "" : "border border-2 border-secondary"
      }`}
    >
      <div className="col-2 m-0 p-0 border-right">
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: 55 }}
        >
          {COMP_LOGO ? (
            <div
              style={{
                backgroundImage: `url('${COMP_LOGO.split("?")[0]}')`,
                backgroundPosition: "50% 50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",

                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <LogoSvg width={"60px"} />
          )}
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        {" "}
        <div className="p-2 text-center">
          {/*{sm_FCL_LCL}*/}
          <p>
            {sm_DOC_CLOSE} ({util.handleGetDate(sm_DOC_CLOSE)})
          </p>
        </div>
      </div>
      <div className="col-2 m-0 p-0 border-right">
        {" "}
        <div className="p-2 text-center">
          <p className="font-weight-bold">{sm_VESSEL}</p>
          <p>{sm_VOY}</p>
        </div>
      </div>
      <div className="col-3 m-0 p-0 border-right">
        <div className="p-2 text-center">
          <p className="font-weight-bold">{sm_POL_NAME}</p>
          <p className="text-danger font-weight-bold">
            {sm_DEPARTURE_DATE} ({util.handleGetDate(sm_DEPARTURE_DATE)})
          </p>
        </div>
      </div>
      <div className="col-3 m-0 p-0">
        <div className="p-2 text-center">
          <p className="font-weight-bold">{sm_POD_NAME}</p>
          <p className="text-danger font-weight-bold">
            {sm_ARRIVE_DATE}({util.handleGetDate(sm_ARRIVE_DATE)})
          </p>
        </div>
      </div>
    </div>
  );
};
