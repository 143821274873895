import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "SearchShipperDetail";

let isCompInfoOpen = true;
let isCompDetailOpen = true;
let isCompUsersOpen = true;

let multiChatUserList = [];

export default function SearchShipperDetail(props) {
  const { loginInfo, isMobile } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [compInfo, setCompInfo] = useState(null);

  const userList = useRef();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      () => {}
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_COMP_SELECT,
      _mClassName,
      (b, n) => {
        if (n === null) {
          setCompInfo(n);
          return;
        }

        handleCompData(n);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_COMP_SELECT,
        _mClassName
      );
    };
  }, []);

  const handleCompData = (data) => {
    let newComp = { ...data };
    SELECT_COMP_CUSTOM_INFO(newComp, (custom_info) => {
      newComp = { ...newComp, ...custom_info };
      SELECT_COMP_USER_LIST(newComp, (userList) => {
        newComp.user_list = userList;
        setCompInfo(newComp);
      });
    });
  };
  useEffect(() => {
    if (compInfo) {
      document
        .querySelector("#ex_comp_detail")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [compInfo]);

  const SELECT_COMP_CUSTOM_INFO = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_company_info/JS_gbts_comp_custom_info_desc_select.php",
      msgID: msgID,
      data: {},
      COMP_CD: data.COMP_CD,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        let custom_info = {};
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            custom_info = { ...newData["returnData"][0] };
          }
        } else {
          custom_info = {};
        }
        callback(custom_info);
      }
    });
  };
  const SELECT_COMP_USER_LIST = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
      COMP_CD: data.COMP_CD,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = [];
          for (let i = 0; i < newData["returnData"].length; i++) {
            if (newData["returnData"][i].USE_GB !== "D") {
              if (newData["returnData"][i].USER_PIC) {
                newData["returnData"][i].USER_PIC = util.changeURIForThumbnail(
                  newData["returnData"][i].USER_PIC
                );
              }
              dataList.push(newData["returnData"][i]);
            }
          }
          callback(dataList);
        }
      }
    });
  };

  const addMultiChatUser = (user) => {
    multiChatUserList = [...multiChatUserList, user];
  };

  const subtractMultiChatUser = (user) => {
    let dataList = [...multiChatUserList];
    if (dataList.length === 0) return;
    let deleteIDX = dataList.findIndex(
      (item) => item.COMP_CD === user.COMP_CD && item.USER_ID === user.USER_ID
    );
    dataList.splice(deleteIDX, 1);
    multiChatUserList = [...dataList];
  };

  const actionCreateChatRoom = () => {
    if (multiChatUserList.length === 0) {
      codeList.Modal.current.alert("대화 상대를 선택해주세요");
      return;
    }
    EVENT_SOCKET_ROOM_CREATE();
  };

  //채팅방  생성
  const EVENT_SOCKET_ROOM_CREATE = () => {
    let userList = [];
    for (let i = 0; i < multiChatUserList.length; i++) {
      userList.push({
        cruUserSite: multiChatUserList[i].COMP_CD,
        cruUserID: multiChatUserList[i].USER_ID,
      });
    }

    let msgID = util.makeUUIDv4();
    let crmType = multiChatUserList.length > 1 ? 2 : 1;
    let data = {
      method: MsgIDList.EVENT_SOCKET_ROOM_CREATE,
      msgID: msgID,
      data: {
        crmMainKey: "",
        crmType: crmType, //1:PtoP 2:Private 3:Public 오픈채팅 4:PublicSecret 비공개
        crmAllow: "N",
        crmName: "Private Chat",
        crmPWD: "",
        crmLastMSG: `${
          loginInfo.USER_NICK ? loginInfo.USER_NICK : loginInfo.user_nm
        }님이 대화를 요청하였습니다.`,
        crmCreator: loginInfo.user_id,
        crmCreatorSite: loginInfo.comp_cd,
        chatUserList: [...userList],
        senderCompCD: loginInfo.comp_cd,
        senderUserID: loginInfo.user_id,
        senderUserName: loginInfo.user_nm,
        senderUserNICK: loginInfo.USER_NICK,
        senderUserPART: loginInfo.USER_PART_NM,
        senderUserTEAM: loginInfo.USER_TEAM_NM,
        senderUserPIC: loginInfo.USER_PIC,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  const INSERT_TALK_FAV_USER = (addUser) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_talk_fav_user_insert.php",
      msgID: msgID,
      data: {},
      cfuUserSite: loginInfo.comp_cd, //내 comp_cd
      cfuUserID: loginInfo.user_id, //내 user_id
      cfuFavUserSite: addUser.COMP_CD, //대상 comp_cd
      cfuFavUserID: addUser.USER_ID, //대상 user id
      cfuCustomNick: addUser.USER_NICK, //사용자지정 닉네임
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData.ret) {
          codeList.Modal.current.alert("즐겨찾기가 완료되었습니다.");
          socket.fireLocalEventListener(
            MsgIDList.EVENT_TALK_FAV_UESR_INSERT,
            _mClassName,
            newData,
            () => {}
          );
        } else {
          if (newData["returnData"].length) {
            codeList.Modal.current.alert(
              "이미 등록되어 있거나 신청할 수 없습니다."
            );
          } else {
            codeList.Modal.current.alert(
              "오류로 인해 신청이 불가합니다. 관리자에게 문의바랍니다."
            );
          }
        }
      }
    });
  };

  const closeCompDetail = () => {
    isCompInfoOpen = true;
    isCompDetailOpen = true;
    isCompUsersOpen = true;
    setCompInfo(null);
  };

  if (compInfo === null) return <></>;

  let detail_class = " border-left";
  let detail_style = { maxWidth: 400 };
  if (isMobile) {
    detail_class = "position-fixed bg-white";
    detail_style = {
      width: "100vw",
      top: 0,
      left: 0,
      paddingBottom: "70px important",
    };
  }

  return (
    <div
      className={`h-100 overflow_scroll-Y p-1 ${detail_class}`}
      id="ex_comp_detail"
      style={detail_style}
    >
      {/*헤더*/}
      <div className="p-2 border-bottom d-flex flex-row justify-content-between align-items-center">
        <p className="h6 m-0 font-weight-bold text-primary">
          {compInfo.COMP_NM} 프로필
        </p>
        <span className="h4 m-0 cursor_pointer" onClick={closeCompDetail}>
          &times;
        </span>
      </div>
      {/*업체로고*/}
      <div className="d-flex flex-column justify-content-center align-items-center py-5 border-bottom">
        {(compInfo.COMP_LOGO || compInfo.SHIP_IMG_URL10) && (
          <div
            style={{
              width: 100,
              height: 100,
              background: `url(${
                compInfo.COMP_LOGO || compInfo.SHIP_IMG_URL10
              }) 50% 50%/contain no-repeat`,
            }}
          />
        )}
        <h3 className="font-weight-bold mb-3">{compInfo.COMP_NM}</h3>
        <div className="d-flex flex-row">
          <a
            id="btn_comp_users"
            className="btn btn-sm btn-primary mr-2"
            href="#user_list"
          >
            사용자와 대화하기
            <i className="fa-solid fa-arrow-down ml-1" />
          </a>
          {compInfo.COMP_HOMEPAGE && (
            <button
              className="btn btn-sm btn-outline-dark"
              onClick={() => {
                window.open(
                  compInfo.COMP_HOMEPAGE,
                  "_blank",
                  "width=1200, height=800"
                );
              }}
            >
              홈페이지 바로가기
              <i className="fa-solid fa-globe ml-1" />
            </button>
          )}
        </div>
      </div>

      <div className="px-2 py-5">
        <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
          <h4 className="mb-0 font-weight-bold">업체 정보</h4>
          <h4 className="mb-0 cursor_pointer">
            <i
              className={` ${
                isCompDetailOpen
                  ? "fa-solid fa-chevron-up"
                  : "fa-solid fa-chevron-down"
              }`}
              onClick={() => {
                isCompDetailOpen = !isCompDetailOpen;
                setIsNeedUpdate(!isNeedUpdate);
              }}
            />
          </h4>
        </div>

        <div className={`${isCompDetailOpen ? "" : "d-none"}`}>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">부호</div>
            <div className="col-8 m-0 p-0">{compInfo.COMP_C_NUM}</div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">업체명</div>
            <div className="col-8 m-0 p-0">
              {compInfo.COMP_NM}{" "}
              {compInfo.COMP_NM_ENG && `(${compInfo.COMP_NM_ENG})`}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">사업자등록번호</div>
            <div className="col-8 m-0 p-0">
              {compInfo.COMP_NUM
                ? util.getAutoHyphenBizID(compInfo.COMP_NUM)
                : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">홈페이지</div>
            <div className="col-8 m-0 p-0">
              <p
                className={`${
                  compInfo.HOME_PAGE ? "text-gbts cursor_pointer" : ""
                }`}
                style={{
                  textDecoration: compInfo.HOME_PAGE ? "underline" : "unset",
                }}
                onClick={() => {
                  if (compInfo.HOME_PAGE) {
                    window.open(
                      compInfo.HOME_PAGE,
                      "_blank",
                      "width=1200, height=800"
                    );
                  }
                }}
              >
                {compInfo.HOME_PAGE ? compInfo.HOME_PAGE : "-"}
              </p>
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">주요품목</div>
            <div className="col-8 m-0 p-0">
              {compInfo.PRODUCT_MAIN ? compInfo.PRODUCT_MAIN : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">교역국가</div>
            <div className="col-8 m-0 p-0">
              {compInfo.TRADE_COUNTRY ? compInfo.TRADE_COUNTRY : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">대표자명</div>
            <div className="col-8 m-0 p-0">
              {compInfo.COMP_CEO_NM ? compInfo.COMP_CEO_NM : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">대표전화</div>
            <div className="col-8 m-0 p-0">
              {compInfo.COMP_TEL
                ? util.getAutoHyphenPhone(compInfo.COMP_TEL)
                : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">팩스번호</div>
            <div className="col-8 m-0 p-0">
              {compInfo.COMP_FAX
                ? util.getAutoHyphenPhone(compInfo.COMP_FAX)
                : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">이메일</div>
            <div className="col-8 m-0 p-0">
              {" "}
              {compInfo.COMP_MAIL ? compInfo.COMP_MAIL : "-"}
            </div>
          </div>
          <div className="row m-0 py-3 border-bottom">
            <div className="col-4 m-0 p-0 font-weight-bold">주소</div>
            <div className="col-8 m-0 p-0">{compInfo.COMP_ADDR}</div>
          </div>
        </div>
      </div>

      <div className="px-2 pb-5" id="user_list" ref={userList}>
        <div className="pb-2 mb-0 border-bottom border-dark d-flex flex-row justify-content-between align-items-center">
          <div>
            <h4 className="mb-0 font-weight-bold">
              사용자 보기{" "}
              <button
                className="btn btn-sm btn-secondary mb-1"
                onClick={() => {
                  actionCreateChatRoom();
                }}
              >
                채팅 생성
              </button>
            </h4>
          </div>
          <h4 className="mb-0 cursor_pointer">
            <i
              className={`${
                isCompUsersOpen
                  ? "fa-solid fa-chevron-up"
                  : "fa-solid fa-chevron-down"
              } cursor-pointer`}
              onClick={() => {
                isCompUsersOpen = !isCompUsersOpen;
                setIsNeedUpdate(!isNeedUpdate);
              }}
            />
          </h4>
        </div>
        <p className="mt-2">
          ※ 사용자 정보 좌측 체크박스 체크 후 채팅생성 버튼을 클릭하면 해당
          사용자와 대화가 가능합니다.
        </p>
        <div className={`py-2 ${isCompUsersOpen ? "" : "d-none"}`}>
          {compInfo.user_list.length > 0
            ? compInfo.user_list.map((user) => {
                let nameCardUrl = "";
                if (user.USER_NAME_CARD) {
                  let split_data = user.USER_NAME_CARD.split("?");
                  nameCardUrl = split_data.find(
                    (str) => str.indexOf("https://gbts") > -1
                  );
                }
                if (
                  user.COMP_CD !== loginInfo.comp_cd ||
                  user.USER_ID !== loginInfo.user_id
                ) {
                  return (
                    <div
                      key={`${user.COMP_CD}_${user.USER_ID}`}
                      className="p-2 mb-2 border-bottom d-flex flex-row justify-content-between align-items-center"
                    >
                      <div className="d-flex flex-row justify-content-start align-items-center mr-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          onChange={(e) => {
                            if (e.target.checked) {
                              addMultiChatUser(user);
                            } else {
                              subtractMultiChatUser(user);
                            }
                          }}
                        />
                        <div
                          className="border rounded-circle mr-3 position-relative"
                          style={{
                            width: 60,
                            minWidth: 60,
                            height: 60,
                            background: `url(${
                              user.USER_PIC ||
                              "https://www.gbts.co.kr/images/user_pic-50x50.png"
                            }) 50% 50% / cover no-repeat`,
                          }}
                        >
                          <div
                            className={`position-absolute border ${
                              user["login_state"] ? "bg-info" : "bg-secondary"
                            } rounded-circle`}
                            style={{
                              top: 0,
                              left: 0,
                              width: 15,
                              height: 15,
                            }}
                          />
                        </div>
                        <div className="flex-fill text-secondary">
                          {(user.USER_TEAM_NM || user.USER_PART_NM) && (
                            <p className="w-100 ellipsis_1 ">
                              {user.USER_TEAM_NM && `[${user.USER_TEAM_NM}]`}{" "}
                              {user.USER_PART_NM}
                            </p>
                          )}
                          <p className="h6 mb-0 font-weight-bold">
                            {user.USER_NICK || user.USER_NM}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center text-gbts">
                        {nameCardUrl && (
                          <span
                            className="h3 mb-0 mr-3 cursor_pointer"
                            onClick={() => {
                              window.open(
                                nameCardUrl,
                                "_blank",
                                "width=1000, height=600"
                              );
                            }}
                          >
                            <i className="fa-regular fa-address-card" />
                          </span>
                        )}
                        <span
                          className="h5 mb-0 mr-2 cursor_pointer"
                          onClick={() => INSERT_TALK_FAV_USER(user)}
                        >
                          <i className="fa-solid fa-user-plus" />
                        </span>
                      </div>
                    </div>
                  );
                }
              })
            : "- 등록된 사용자 정보가 존재하지 않습니다."}
        </div>
      </div>
    </div>
  );
}
