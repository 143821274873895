/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

import SchedulePromotionManageEdit from "./SchedulePromotionManageEdit";

const _mClassName = "SchedulePromotionManageTable";

const socket = new BuddibleSocket();
const util = new Utilities();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const headCells = [
  {
    field: "sp_IDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_COMP_CD",
    headerName: "선사코드",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_LCL_FCL",
    headerName: "운송방법",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_POL_NAME",
    headerName: "출발항",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_POD_NAME",
    headerName: "도착항",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_MEMO",
    headerName: "내용",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_DATE_START",
    headerName: "시작일",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_DATE_END",
    headerName: "종료일",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sp_DISPLAY",
    headerName: "노출위치",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          {Number(params.data) === 0 ? "프레이스콥" : "프레이스콥 플러스"}
        </span>
      );
    },
  },
  {
    field: "sp_USE_YN",
    headerName: "사용여부",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let color = "text-danger";
      if (params.data === "Y") {
        color = "text-primary";
      }

      return (
        <span
          className={`text-truncate text-wrap text-break font-weight-bold ${color}`}
        >
          {params.data === "Y" ? "사용" : "중지"}
        </span>
      );
    },
  },
  {
    field: "sp_CREATE_DATE",
    headerName: "생성일",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let dateSet = util.getDateToFormat(params.data, "YYYY-MM-DD");
      return (
        <span className="text-truncate text-wrap text-break">{dateSet}</span>
      );
    },
  },
  {
    field: "sp_FUNCTION",
    headerName: "기능",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#SchedulePromotionManageEdit"
            onClick={(e) => params.openEdit(e, params)}
            title={"수정"}
            aria-label={"수정"}
          >
            <i className="fa-solid fa-pen" />
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-toggle="modal"
            onClick={(e) => params.removeData(e, params)}
            title={"삭제"}
            aria-label={"삭제"}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        </div>
      );
    },
  },
];

function Row(props) {
  const { row, openEdit, removeData } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      tabIndex={-1}
      key={row.id}
    >
      {headCells.map((headCell) => {
        const cellProps = {
          data: row[headCell.field],
          currentData: row,
          currentHeader: headCell.field,
          headCells: headCells,
          openEdit: openEdit,
          removeData: removeData,
        };
        return (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell(cellProps)
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter(cellProps)
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SchedulePromotionManageTable({
  isServerPaging = true,
  isServerSort = true,
  loginInfo,
  ship_comp,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sp_IDX");

  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "sp_IDX",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const editModal = useRef();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_PROMOTION_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_promotion/JS_schedule_promotion_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      COMP_CD: loginInfo.comp_cd,
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeData(e, params) {
    editModal.current.removeData(e, params);
  }

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProp = { openEdit: openEdit, removeData: removeData };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={index} row={row} index={index} {...rowProp} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <SchedulePromotionManageEdit
        ref={editModal}
        loginInfo={loginInfo}
        ship_comp={ship_comp}
        requestUserSelect={requestUserSelect}
      />
    </TableContainer>
  );
}
