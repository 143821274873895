import React, { useCallback } from "react";
export default function CommonSlideNavbar(props) {
  const {
    pages,
    activeSubMenu,
    pagesOpen,
    handleOpenSubMenu,
    handlePageOpen,
    loginInfo,
  } = props;

  const NavItemRender = useCallback(
    ({ item }) => {
      let active = pagesOpen[item.name] ? "active" : "";
      let display = activeSubMenu[item.key] ? "d-block" : "d-none";
      return (
        <NavItem
          active={active}
          display={display}
          name={item.name}
          title={item.title}
        />
      );
    },
    [pagesOpen, activeSubMenu]
  );

  const NavItem = React.memo(({ active, display, name, title }) => {
    return (
      <li
        className={`nav-item ${active} ${display}`}
        onClick={() => handlePageOpen(name)}
      >
        <span className="nav-link cursor_pointer">
          <span>{title}</span>
        </span>
      </li>
    );
  });

  return (
    <>
      <div
        id="accordionSidebar"
        className="navbar-nav sidebar sidebar-light accordion position-relative min-width-210"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center bg-freiscop py-3"
          href="/"
        >
          <img className="h-100" src="/img/use_img/logo_white.png" alt="logo" />
        </a>
        <ul
          className="overflow_scroll-Y"
          style={{ maxHeight: "calc(100vh - 70px)" }}
        >
          {pages.map((data, index) => {
            if (loginInfo && loginInfo.erp_auth > data.uLevel) {
              if (!data.hasOwnProperty("page")) {
                let iconClass = activeSubMenu[data.key]
                  ? "fa-solid fa-angle-up"
                  : "fa-solid fa-angle-down";
                return (
                  <li
                    key={`slideNav_${index}`}
                    className="cursor_pointer"
                    onClick={() => handleOpenSubMenu(data.key)}
                  >
                    <hr className="sidebar-divider" />
                    <div className="sidebar-heading d-flex flex-row justify-content-between text-secondary align-items-center">
                      {data.title}
                      <span className="h6 m-0">
                        <i className={iconClass} />
                      </span>
                    </div>
                  </li>
                );
              } else {
                return <NavItemRender key={`slideNav_${index}`} item={data} />;
              }
            }
          })}
        </ul>
      </div>
    </>
  );
}
