/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";
import BasaCoPointManageEdit from "./BasaCoPointManageEditModal";
const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "BasaCoPointManageTable";

const useStyles = makeStyles((theme) => ({
  ...codeList.publicVar.tableDefaultStyle(theme),
}));
const useRowStyles = makeStyles({
  ...codeList.publicVar.tableRowDefaultStyle(),
});

const headCells = [
  {
    field: "bcpCOMP_CD",
    headerName: "업체코드",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "bcpCOMP_NAME",
    headerName: "업체명",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },

  {
    field: "bcpPOINT",
    headerName: "허용횟수",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.addCommas(params.data)}</span>;
    },
  },
  {
    field: "bcpUPDATE_DATE",
    headerName: "최종수정일",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "function",
    headerName: "수정",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#BasaCoPointManageEdit"
          onClick={(e) => params.openEdit(e, params)}
        >
          <i className="fa-regular fa-pen-to-square" />
        </button>
      );
    },
  },
];

let filters = {};

export default function BasaCoPointManageTable({
  isServerPaging = true,
  isServerSort = true,
  updateBASACoPoint,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("bcpCOMP_CD");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "bcpCOMP_CD",
      dir: "asc",
    },
  ]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_BASA_POINT_UPDATE,
      _mClassName,
      (b, n) => {
        requestBasaCoPointList();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_BASA_POINT_CHANGED_FILTERS,
      _mClassName,
      (b, n) => {
        filters = { ...n };
        setPage(0);
        requestBasaCoPointList();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_BASA_POINT_UPDATE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_BASA_POINT_CHANGED_FILTERS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestBasaCoPointList();
  }, [page, sortModel]);

  const requestBasaCoPointList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/basa/JS_basaCoPointSelect.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeCompInfoCounterAuth = (item, value) => {
    if (Number(item.bcpPOINT) === Number(value)) return;
    updateBASACoPoint({
      bcpCOMP_CD: item.bcpCOMP_CD,
      bcpCOMP_NAME: item.bcpCOMP_NAME,
      bcpPOINT: value,
    });
  };

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const rowProps = {
    handleChangeCompInfoCounterAuth,
    openEdit,
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  return (
    <>
      <BasaCoPointManageEdit
        data={{}}
        ref={editModal}
        requestBasaCoPointList={requestBasaCoPointList}
      />
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.bcpCOMP_CD}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.bcpCOMP_CD}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={row.bcpCOMP_CD}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={row.bcpCOMP_CD}
                  row={row}
                  index={index}
                  {...rowProps}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

BasaCoPointManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  removeData: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

function Row(props) {
  const { row, handleChangeCompInfoCounterAuth, openEdit } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      key={row.bcpCOMP_CD}
    >
      {headCells.map((headCell, index) => {
        let style = {};
        if (headCell.hasOwnProperty("minWidth"))
          style.minWidth = headCell.minWidth;
        if (headCell.hasOwnProperty("maxWidth"))
          style.maxWidth = headCell.maxWidth;
        return (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
            style={style}
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  handleChangeCompInfoCounterAuth:
                    handleChangeCompInfoCounterAuth,
                  openEdit: openEdit,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  handleChangeCompInfoCounterAuth:
                    handleChangeCompInfoCounterAuth,
                  openEdit: openEdit,
                })
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={`${headCell.field}_${index}`}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
                style={{ fontWeight: "bold" }}
              >
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};
