import React from "react";

const COMP_TYPE = {
  B: "포워더 (선사)",
  C: "운송사",
  D: "보험사",
  E: "관세사",
  F: "보세창고",
  Z: "GBTS 관리자",
};

const COMP_GB_SUB_TYPE = {
  B01: "포워더",
  B02: "콘솔사",
  B03: "선사",
  F01: "보세창고",
  F02: "CY",
  F03: "일반창고",
};

const LogisticsMobileItem = (props) => {
  const { row, companyDetailChange } = props;

  let logo = null;

  if (row.SHIP_IMG_URL10 !== null && row.SHIP_IMG_URL10 !== "") {
    logo = row.SHIP_IMG_URL10;
    logo = logo.replace("gbts-invoice.", "gbts-invoice-resized.");
    logo = logo.replace("gbts-invoice/", "gbts-invoice-resized/");
  }

  if (row.COMP_LOGO) {
    logo = row.COMP_LOGO;
  }

  let COMP_GB_SUB2 = row.COMP_GB_SUB2
    ? `${COMP_GB_SUB_TYPE[row.COMP_GB_SUB2]}`
    : COMP_TYPE[row.COMP_GB];

  let tags = row.COMP_TAG;
  tags = row.COMP_TAG ? tags.split(",") : [];

  return (
    <div
      className="p-3 border-bottom cursor_pointer"
      onClick={() => {
        companyDetailChange(row);
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            width: 120,
            height: 120,
            minHeight: 120,
            background: `url(${logo}) 50% 50% / contain no-repeat`,
          }}
        />
        <p className="mb-1">
          [{COMP_GB_SUB2}] {row.COMP_C_NUM}
        </p>
        <p className="h4 mb-1 font-weight-bold">{row.COMP_NM}</p>
        <p className="mb-1">{row.COMP_SLOGUN}</p>
        <div className="d-inline-block text-center">
          {tags.map((e, i) => {
            return (
              <span className="btn btn-sm btn-outline-info m-1" key={i}>
                {e.trim()}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default React.memo(LogisticsMobileItem);
