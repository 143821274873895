/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const product_type = {
  A: "수출(해상)",
  B: "수입(해상)",
  C: "수출(항공)",
  D: "수입(항공)",
  E: "벌크/특수화물",
};
const tableHead = {
  FCL: ["비용항목", "20FT", "40FT"],
  LCL: ["비용항목", "금액"],
  AIR: ["비용항목", "금액"],
};

export default function FCLShippingDetailData() {
  const param = useParams();
  const [productData, setProductData] = useState({
    pmArrivalCity: "CDBD01",
    pmCreator: "admin",
    pmContactName: "",
    pmContactTel: "",
    pmContactEmail: "",
    pmContactCompCD: "",
    pmContactUserID: "",
    pmDepartureCity: "CDAA01",
    pmDepartureDate: "2021-12-12|2021-12-15|2021-12-21|2021-12-30|2022-01-04",
    pmDepartureDayWeek: "",
    pmDepartureType: "D",
    pmForwarderDesc: "정직한 동서로지스틱스!",
    pmForwarderHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmForwarderImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/forwarderImage/20211212172437_ds.png",
    pmForwarderName: "동서로지스틱스",
    pmIDX: "7",
    pmKeyMain: "eaa51515-3a92-41a8-a6c0-a1503751c415",
    pmOptionInclude: "- 운임, 부대비용을 포함한 상기 고지된 물류비용",
    pmOptionInfo:
      "- 수출통관수수료\n- 국내컨테이너운송료\n\n등 비용은 업무 진행시 제휴사(관세사)청구금액 및 운송구간에 따른 운임에 따릅니다. 해당 비용에 대하여 궁금하시면 문의 남겨 주세요.",
    pmOptionNotInclude:
      "- 통관수수료, 적하보험료, 내륙운송료, DOOR작업건의 경우 상차비용 등은 불포함 되어 있습니다",
    pmProductExpireEnd: "2022-01-08",
    pmProductExpireStart: "2021-12-12",
    pmProductExpireType: "S",
    pmProductName: "인천-하이퐁 SM상선 FCL 직항",
    pmProductType: "A",
    pmShipCompanyDesc: "빠른 SM상선!",
    pmShipCompanyHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmShipCompanyImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/carrierImage/20211212172323_ship-gfa701dc0d_640.jpg",
    pmShipCompanyName: "SM상선",
    pmShipInfo: "PANCON SUCCESS",
    pmTransferDay: "30",
    pmTransferDirectYN: "Y",
    pmTransferType: "FCL",
    pmUSEYN: "Y",
    pmWarningInfo: "1. GBTS로지스몰에서 예약을 하시면 담당…..",
    subData: [
      {
        psIDX: "0",
        psMoneyCD: "USD",
        psServiceCol1: "500",
        psServiceCol2: "950",
        psServiceName: "OCEAN FREIGHT",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "1",
        psMoneyCD: "USD",
        psServiceCol1: "150",
        psServiceCol2: "300",
        psServiceName: "EBS",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "2",
        psMoneyCD: "USD",
        psServiceCol1: "50",
        psServiceCol2: "100",
        psServiceName: "CIS",
        psServiceType: "A",
        psCostType: "P",
      },
      {
        psIDX: "3",
        psMoneyCD: "USD",
        psServiceCol1: "40",
        psServiceCol2: "80",
        psServiceName: "CRS",
        psServiceType: "A",
        psCostType: "O",
      },
      {
        psIDX: "0",
        psMoneyCD: "KRW",
        psServiceCol1: "130000",
        psServiceCol2: "180000",
        psServiceName: "THC",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "1",
        psMoneyCD: "KRW",
        psServiceCol1: "4200",
        psServiceCol2: "8400",
        psServiceName: "WFG",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "2",
        psMoneyCD: "KRW",
        psServiceCol1: "86",
        psServiceCol2: "172",
        psServiceName: "SECURITY CHARGE",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "3",
        psMoneyCD: "KRW",
        psServiceCol1: "50000",
        psServiceCol2: "50000",
        psServiceName: "DOCUMENT FEE",
        psServiceType: "E",
        psCostType: "O",
      },
      {
        psIDX: "4",
        psMoneyCD: "USD",
        psServiceCol1: "80",
        psServiceCol2: "80",
        psServiceName: "D/O FEE",
        psServiceType: "E",
        psCostType: "O",
      },
    ],
  });
  const [isExpired, setIsExpired] = useState(false);
  const [loginInfo, setLoginInfo] = useState(null);

  useEffect(() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      setLoginInfo(loginData);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "FCLShippingDetailData",
      (b, n) => {
        if (n) {
          setLoginInfo(n["returnData"][0]);
        }
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "FCLShippingDetailData",
      (beforeData, newData) => {
        if (newData) {
          setProductData({ ...newData });
          if (
            newData.pmUSEYN === "N" ||
            newData.pmProductExpireEnd <
              util.getDateToFormat(new Date(), "YYYY-MM-DD")
          ) {
            setIsExpired(true);
          } else {
            setIsExpired(false);
          }
        }
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "FCLShippingDetailData"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
        "FCLShippingDetailData"
      );
    };
  }, []);

  return (
    <div className="container p-sm-0">
      <div className="row wrapper">
        <FCLShippingDetail productData={productData} />
        <FCLShippingDetailSideNav
          propsProductData={productData}
          isExpired={isExpired}
          loginInfo={loginInfo}
        />
      </div>
    </div>
  );
}

const selectWeekday = [
  { text: "월요일", value: "mon" },
  { text: "화요일", value: "tue" },
  { text: "수요일", value: "wed" },
  { text: "목요일", value: "thu" },
  { text: "금요일", value: "fri" },
  { text: "토요일", value: "sat" },
  { text: "일요일", value: "sun" },
];

function FCLShippingDetail({ productData }) {
  const makeTableHead = () => {
    let head = { ...tableHead };
    let returnHead = [];
    for (let key in head) {
      if (key === productData.pmTransferType) {
        head[key].forEach((desc, index) => {
          returnHead.push(
            <th key={index} scope="col">
              {desc}
            </th>
          );
        });
      }
    }
    return returnHead;
  };
  let pmDepartureDayWeek = "";
  if (productData.pmDepartureDayWeek !== "") {
    let dayArr = productData.pmDepartureDayWeek.split("|");
    for (let i = 0; i < dayArr.length; i++) {
      dayArr[i] = util.getCodeName(dayArr[i], selectWeekday);
    }
    pmDepartureDayWeek = dayArr.join(", ");
  }

  let citysFull = codeList.codeCountryFullName;
  if (citysFull.length > 0) {
    citysFull.forEach((city) => {
      if (city.City === productData.pmDepartureCity) {
        productData.pmDepartureCity = `${city.CountryName}(${city.CityName})`;
      }
      if (city.City === productData.pmArrivalCity) {
        productData.pmArrivalCity = `${city.CountryName}(${city.CityName})`;
      }
    });
  }
  return (
    <>
      <div className="col-lg-8 sideContent" id="fclShippingDetail">
        <div className="theiaStickySidebar">
          <hr />
          <div className="row">
            <div className="col-lg-3">
              <h3>선사 & 포워더</h3>
            </div>
            <div className="col-lg-9">
              <h4>{productData.pmForwarderName}</h4>
              {productData.pmForwarderHome !== "" && (
                <a href={productData.pmForwarderHome} target="_blank">
                  포워더 홈페이지
                </a>
              )}
              <p
                className="mt-2"
                style={{ whiteSpace: "break-spaces", wordBreak: "keep-all" }}
              >
                {productData.pmForwarderDesc}
              </p>
              <h4>{productData.pmShipCompanyName}</h4>{" "}
              {productData.pmShipCompanyHome !== "" && (
                <a href={productData.pmShipCompanyHome} target="_blank">
                  선사/항공사 홈페이지
                </a>
              )}
              <p
                className="mt-2"
                style={{ whiteSpace: "break-spaces", wordBreak: "keep-all" }}
              >
                {productData.pmShipCompanyDesc}
              </p>
              {productData.pmShipInfo && (
                <div className="row">
                  <div className="col-md-6">
                    <p>선박 정보</p>
                    <ul className="list_ok">
                      <li>{productData.pmShipInfo}</li>
                    </ul>
                  </div>
                </div>
              )}
              {productData.pmContactName &&
                (productData.pmContactTel || productData.pmContactEmail) && (
                  <>
                    <h4 className="mt-3">담당자 정보</h4>

                    <p>담 당 자 : {productData.pmContactName}</p>
                    {productData.pmContactTel && (
                      <p>연 락 처 : {productData.pmContactTel}</p>
                    )}
                    {productData.pmContactEmail && (
                      <p>이 메 일 : {productData.pmContactEmail}</p>
                    )}
                  </>
                )}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-3">
              <h3>상품정보</h3>
            </div>
            <div className="col-lg-9">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th colSpan="2">상품 상세정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>상품분류</td>
                      <td>{product_type[productData.pmProductType]}</td>
                    </tr>
                    <tr>
                      <td>운행분류</td>
                      <td>{productData.pmTransferType}</td>
                    </tr>
                    <tr>
                      <td>운송구간</td>
                      <td>
                        {productData.pmDepartureCity}{" "}
                        <i className="fas fa-long-arrow-alt-right" />{" "}
                        {productData.pmArrivalCity}
                      </td>
                    </tr>

                    <tr>
                      <td>상품유효기간</td>
                      <td>
                        {productData.pmProductExpireStart} ~{" "}
                        {productData.pmProductExpireEnd} (
                        {productData.pmProductExpireType === "S"
                          ? "선적일 기준"
                          : "출항일 기준"}
                        )
                      </td>
                    </tr>
                    <tr>
                      <td>선적일정</td>
                      <td>
                        {productData.pmDepartureType !== "W"
                          ? productData.pmDepartureDate
                              .split("|")
                              .map((date, index) => {
                                return <p key={`date_p_${index}`}>{date}</p>;
                              })
                          : `매주 ${pmDepartureDayWeek}`}
                      </td>
                    </tr>
                    <tr>
                      <td>운송소요일</td>
                      <td>{productData.pmTransferDay}</td>
                    </tr>
                  </tbody>
                </table>

                {(productData.pmContactName ||
                  (productData.pmContactTel && productData.pmContactEmail)) && (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th colSpan="2">담당자 정보</th>
                      </tr>
                    </thead>

                    <tbody>
                      {productData.pmContactName && (
                        <tr>
                          <td>담당자명</td>
                          <td>{productData.pmContactName}</td>
                        </tr>
                      )}
                      {productData.pmContactTel && (
                        <tr>
                          <td>담당자 연락처</td>
                          <td>{productData.pmContactTel}</td>
                        </tr>
                      )}
                      {productData.pmContactEmail && (
                        <tr>
                          <td>담당자 이메일</td>
                          <td>{productData.pmContactEmail}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-3">
              <h3>FREIGHT</h3>
            </div>
            <div className="col-lg-9">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>{makeTableHead()}</tr>
                  </thead>
                  <tbody>
                    {productData.subData.map((fright, index) => {
                      if (fright.psServiceType === "A") {
                        if (
                          fright.psServiceName &&
                          (0 !== Number(fright.psServiceCol1) ||
                            0 !== Number(fright.psServiceCol2))
                        ) {
                          return (
                            <tr key={`FREIGHT_${index}`}>
                              <td
                                style={{ width: 200, textOverflow: "ellipsis" }}
                              >
                                {fright.psServiceName}
                              </td>
                              {fright.psServiceCol2 &&
                              fright.psMoneyCD !== "KRW" ? (
                                <td>
                                  {fright.psMoneyCD}{" "}
                                  {util.addCommas(fright.psServiceCol1)}
                                </td>
                              ) : (
                                <td>
                                  {util.addCommas(fright.psServiceCol1)} 원
                                </td>
                              )}
                              {productData.pmTransferType === "FCL" ? (
                                fright.psServiceCol2 &&
                                fright.psMoneyCD !== "KRW" ? (
                                  <td>
                                    {fright.psMoneyCD}{" "}
                                    {util.addCommas(fright.psServiceCol2)}
                                  </td>
                                ) : (
                                  <td>
                                    {util.addCommas(fright.psServiceCol2)} 원
                                  </td>
                                )
                              ) : (
                                // ""
                                <></>
                              )}
                            </tr>
                          );
                        }
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-3">
              <h3>부대비용</h3>
            </div>
            <div className="col-lg-9">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>{makeTableHead()}</tr>
                  </thead>
                  <tbody>
                    {productData.subData.map((fright, index) => {
                      if (fright.psServiceType === "E") {
                        if (
                          fright.psServiceName &&
                          (0 !== Number(fright.psServiceCol1) ||
                            0 !== Number(fright.psServiceCol2))
                        ) {
                          return (
                            <tr key={`cont_${index}`}>
                              <td
                                style={{ width: 200, textOverflow: "ellipsis" }}
                              >
                                {fright.psServiceName}
                              </td>
                              {fright.psServiceCol2 &&
                              fright.psMoneyCD !== "KRW" ? (
                                <td>
                                  {fright.psMoneyCD}{" "}
                                  {util.addCommas(fright.psServiceCol1)}
                                </td>
                              ) : (
                                <td>
                                  {util.addCommas(fright.psServiceCol1)} 원
                                </td>
                              )}
                              {productData.pmTransferType === "FCL" ? (
                                fright.psServiceCol2 &&
                                fright.psMoneyCD !== "KRW" ? (
                                  <td>
                                    {fright.psMoneyCD}{" "}
                                    {util.addCommas(fright.psServiceCol2)}
                                  </td>
                                ) : (
                                  <td>
                                    {util.addCommas(fright.psServiceCol2)} 원
                                  </td>
                                )
                              ) : (
                                // ""
                                <></>
                              )}
                            </tr>
                          );
                        }
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr />
          {productData.pmOptionInfo && (
            <>
              {" "}
              <div className="row">
                <div className="col-lg-3">
                  <h3>옵션비용</h3>
                </div>
                <div
                  className="col-lg-9"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {productData.pmOptionInfo}
                </div>
              </div>
              <hr />
            </>
          )}

          <div className="row">
            <div className="col">
              <div className="mb-2">
                <div className="row bg-light border p-4 m-0">
                  <div className="col-12 mb-3">
                    <i className="far fa-check-circle" /> 포함사항
                  </div>
                  <div
                    className="col-12 p-4 bg-white"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {productData.pmOptionInclude
                      ? productData.pmOptionInclude
                      : "별도 기재된 포함사항이 없습니다."}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="row bg-light border p-4 m-0">
                  <div className="col-12 mb-3">
                    <i className="far fa-times-circle" /> 불포함사항
                  </div>
                  <div
                    className="col-12 p-4 bg-white"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {productData.pmOptionNotInclude
                      ? productData.pmOptionNotInclude
                      : "별도 기재된 불포함 사항이 없습니다."}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="row bg-light border p-4 m-0">
                  <div className="col-12 mb-3">
                    <i className="fas fa-exclamation-circle" /> 예약시 유의사항
                  </div>
                  <div
                    className="col-12 p-4 bg-white"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {productData.pmWarningInfo
                      ? productData.pmWarningInfo
                      : "별도 기재된 예약시 유의사항이 없습니다."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FCLShippingDetailSideNav({ propsProductData, isExpired, loginInfo }) {
  const navigate = useNavigate();
  const [productData, setProductData] = useState({ ...propsProductData });
  const [shippingDetailSideNavWidth, setSideNavWidth] = useState(356);
  const [shippingDetailSideNav, setSideNav] = useState({
    position: "relative",
    top: 50,
  });
  // console.log("isExpired", isExpired);

  const [costBase, setCostBase] = useState({
    krwOnceCostCol1: 0,
    krwOnceCostCol2: 0,
    foreignOnceCostCol1: 0,
    foreignOnceCostCol2: 0,
    krwCostCol1: 0,
    krwCostCol2: 0,
    foreignCostCol1: 0,
    foreignCostCol2: 0,
  });
  const [col1Data, setCol1Data] = useState({
    foreignCurrencyCost: 0,
    foreignCurrencyOnceCost: 0,
    krwCost: 0,
    krwOnceCost: 0,
    count: 0,
  });
  const [col2Data, setCol2Data] = useState({
    foreignCurrencyCost: 0,
    foreignCurrencyOnceCost: 0,
    krwCost: 0,
    krwOnceCost: 0,
    count: 0,
  });
  const [sendReserveData, setSendReserveData] = useState({
    foreignCurrencyCost: 0,
    krwCost: 0,
    psServiceCol1: 0,
    psServiceCol2: 0,
  });
  //상품정보 가져오기
  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PRODUCT_DATA_CHANGE,
      "FCLShippingDetailSideNav",
      (beforeData, newData) => {
        if (newData) {
          SetCostBase(newData);
        }
      }
    );
  }, []);

  //용량 별 외화,원화 금액
  const SetCostBase = (newData) => {
    if (newData.subData.length) {
      let costDataList = [...newData.subData];
      let costData = { ...costBase };
      costDataList.forEach((cost) => {
        let psServiceCol1 = Number(cost.psServiceCol1);
        let psServiceCol2 = Number(cost.psServiceCol2);
        if (cost["psCostType"] === "P") {
          if (cost["psMoneyCD"] === "KRW") {
            costData.krwCostCol1 = costData.krwCostCol1 + psServiceCol1;
            costData.krwCostCol2 = costData.krwCostCol2 + psServiceCol2;
            //  외화
          } else {
            costData.foreignCostCol1 = costData.foreignCostCol1 + psServiceCol1;
            costData.foreignCostCol2 = costData.foreignCostCol2 + psServiceCol2;
          }
        } else {
          if (cost["psMoneyCD"] === "KRW") {
            costData.krwOnceCostCol1 = costData.krwOnceCostCol1 + psServiceCol1;
            costData.krwOnceCostCol2 = costData.krwOnceCostCol2 + psServiceCol2;
            //  외화
          } else {
            costData.foreignOnceCostCol1 =
              costData.foreignOnceCostCol1 + psServiceCol1;
            costData.foreignOnceCostCol2 =
              costData.foreignOnceCostCol2 + psServiceCol2;
          }
        }
      });
      setCostBase({ ...costData });
      setProductData({ ...newData });
    }
  };

  //col1 수량 및 금액 핸들러
  const Col1DataChange = (e) => {
    let val = e.target.value;
    let costData = {
      ...costBase,
    };
    let setData = {
      foreignCurrencyCost: 0,
      foreignCurrencyOnceCost: 0,
      krwCost: 0,
      krwOnceCost: 0,
      count: 0,
    };
    if (val > 0) {
      setData.count = val;
      for (let key in costData) {
        if (key.indexOf("Col1") > -1) {
          if (key.indexOf("foreign") > -1) {
            if (key.indexOf("Once") > -1) {
              setData.foreignCurrencyOnceCost =
                setData.foreignCurrencyOnceCost + costData[key];
            } else {
              setData.foreignCurrencyCost =
                setData.foreignCurrencyCost + costData[key] * val;
            }
          } else {
            if (key.indexOf("Once") > -1) {
              setData.krwOnceCost = setData.krwOnceCost + costData[key];
            } else {
              setData.krwCost = setData.krwCost + costData[key] * val;
            }
          }
        }
      }
    }
    setCol1Data({ ...setData });
  };
  //col2 수량 및 금액 핸들러
  const Col2DataChange = (e) => {
    let val = e.target.value;
    let costData = {
      ...costBase,
    };
    let setData = {
      foreignCurrencyCost: 0,
      foreignCurrencyOnceCost: 0,
      krwCost: 0,
      krwOnceCost: 0,
      count: 0,
    };
    if (val > 0) {
      setData.count = val;
      for (let key in costData) {
        if (key.indexOf("Col2") > -1) {
          if (key.indexOf("foreign") > -1) {
            if (key.indexOf("Once") > -1) {
              setData.foreignCurrencyOnceCost =
                setData.foreignCurrencyOnceCost + costData[key];
            } else {
              setData.foreignCurrencyCost =
                setData.foreignCurrencyCost + costData[key] * val;
            }
          } else {
            if (key.indexOf("Once") > -1) {
              setData.krwOnceCost = setData.krwOnceCost + costData[key];
            } else {
              setData.krwCost = setData.krwCost + costData[key] * val;
            }
          }
        }
      }
    }
    setCol2Data({ ...setData });
  };

  useEffect(() => {
    let setData = {
      foreignCurrencyCost:
        col1Data.foreignCurrencyCost +
        col1Data.foreignCurrencyOnceCost +
        col2Data.foreignCurrencyCost +
        col2Data.foreignCurrencyOnceCost,
      krwCost:
        col1Data.krwCost +
        col1Data.krwOnceCost +
        col2Data.krwCost +
        col2Data.krwOnceCost,
      psServiceCol1: col1Data.count,
      psServiceCol2: col2Data.count,
    };

    setSendReserveData({ ...setData });
  }, [col1Data, col2Data]);

  //창 사이즈 조절 시 내비 width 값 조정
  const SideNavWidthHandler = () => {
    if (window.innerWidth > 992) {
      let setWidth =
        document.getElementById("shippingDetailSideNav").clientWidth - 24;
      setSideNavWidth(setWidth);
    } else {
      setSideNavWidth("auto");
    }
  };

  //스크롤 고정 내비
  const SideNavScrollHandler = () => {
    if (window.innerWidth > 992) {
      try {
        let start = document.getElementById("fclImages").offsetHeight;
        let end =
          start +
          document.getElementById("fclShippingDetail").offsetHeight -
          document.getElementById("shippingDetailSideNavContent").offsetHeight;

        if (start < window.pageYOffset && end - 50 > window.pageYOffset) {
          setSideNav({
            position: "fixed",
            top: "100px",
          });
        } else if (end - 50 < window.pageYOffset) {
          setSideNav({
            position: "absolute",
            bottom: 0,
          });
        } else {
          setSideNav({
            position: "absolute",
            top: 0,
          });
        }
      } catch (e) {}
    } else {
      setSideNav({
        position: "relative",
        top: "unset",
      });
    }
  };

  //사이드 내비 스크롤, 리사이즈
  useEffect(() => {
    SideNavScrollHandler();
    SideNavWidthHandler();
    try {
      window.addEventListener("resize", SideNavWidthHandler);
      window.addEventListener("scroll", SideNavScrollHandler);
      return () => {
        window.removeEventListener("resize", SideNavWidthHandler);
        window.removeEventListener("scroll", SideNavScrollHandler);
      };
    } catch (e) {}
  }, []);

  const CheckReserveData = (e) => {
    e.preventDefault();
    let check = true;
    let checkData = { ...sendReserveData };
    if (!checkData.psServiceCol1 && !checkData.psServiceCol2) {
      codeList.Modal.current.alert("수량을 입력 해 주세요", () => {});
      check = false;
    }

    if (check)
      navigate(`/Reserve/${productData.pmTransferType}/${productData.pmIDX}`, {
        state: {
          foreignCurrencyCost: sendReserveData.foreignCurrencyCost,
          krwCost: sendReserveData.krwCost,
          psServiceCol1: sendReserveData.psServiceCol1,
          psServiceCol2: sendReserveData.psServiceCol2,
        },
      });
  };

  const foreignView = () => {
    let costList = [...productData.subData];
    let foreign = "";
    for (let i = 0; i < costList.length; i++) {
      if (costList[i].psMoneyCD !== "KRW") {
        foreign = costList[i].psMoneyCD;
        break;
      }
    }
    return foreign;
  };

  const requestEnquiry = (managerCompCD, managerID) => {
    if (loginInfo) {
      codeList.Modal.current.confirm(
        `로지톡으로 담당자에게 연결하시겠습니까?`,
        (ret) => {
          if (ret) {
            // localStorage.setItem(
            //   "enquiryManager",
            //   `${managerCompCD}|${managerID}`
            // );

            localStorage.setItem("enquiryManager", "Z0001|hskim@gbts.co.kr");
            window.open("/LogiTalk", "_blank", "width=1200, height=800");
          } else {
            codeList.Modal.current.alert("담당자 연결이 취소되었습니다.");
          }
        }
      );
    } else {
      codeList.Modal.current.alert("로그인 후 이용 가능한 서비스입니다.");
    }
  };

  let os = "";

  let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf("android") > -1) {
    os = "android";
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    os = "ios";
  } else {
    os = "other";
  }
  const moveApp = () => {
    if (os === "android") {
      //안드로이드
      // window.location.href = `intent://example.com/logiTalk/?mComp=${productData.pmContactCompCD}&mId=${productData.pmContactUserID}#Intent;scheme=https;package=kr.co.gbts.logitalk;end;`;
      window.location.href = `intent://example.com/logiTalk/?mComp=${"Z0001"}&mId=${"buddible@buddible.com"}#Intent;scheme=https;package=kr.co.gbts.logitalk;end;`;
      // window.location.href = `logiTalk://example.com/logiTalk/?mComp=${"Z0001"}&mId=${"buddible@buddible.com"}#Intent;scheme=https;package=kr.co.gbts.logitalk;end;`;
      // window.location.href = `https://example.com/logiTalk/?mComp=${"Z0001"}&mId=${"buddible@buddible.com"};scheme=https;package=kr.co.gbts.logitalk;end;`;
    } else if (os === "ios") {
      //IOS
      // window.location.href = `https://logitalk.page.link?mComp=${"Z0001"}&mId=${"buddible@buddible.com"}#Intent;scheme=https;package=kr.co.gbts.logitalk;end;`;
      window.location.href = `intent://example.com/logiTalk/?mComp=${"Z0001"}&mId=${"buddible@buddible.com"}#Intent;scheme=https;package=kr.co.gbts.logitalk;end;`;
    } else {
      return;
    }
  };
  return (
    <>
      <div
        className="col-lg-4 pl-lg-4 mb-lg-3 p-0 pr-lg-0 position-relative"
        id="shippingDetailSideNav"
      >
        <div
          id="shippingDetailSideNavContent"
          style={{
            width: shippingDetailSideNavWidth,
            ...shippingDetailSideNav,
          }}
        >
          <div className="box_style_1 mb-0" id="booking_box">
            <h3 className="inner">- 예약하기 -</h3>
            <div className="row">
              <div className="col-12">
                {productData.pmTransferType === "FCL" ? (
                  <>
                    <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                      <div>※ 20FT 수량</div>
                      <div>
                        <input
                          className="form-control text-right"
                          type="number"
                          min={0}
                          onChange={(e) => {
                            Col1DataChange(e);
                          }}
                          data-keyword="psServiceCol1"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                      <div>※ 40FT 수량</div>
                      <div>
                        <input
                          className="form-control text-right"
                          type="number"
                          min={0}
                          onChange={(e) => {
                            Col2DataChange(e);
                          }}
                          data-keyword="psServiceCol2"
                        />
                      </div>
                    </div>
                  </>
                ) : productData.pmTransferType === "LCL" ? (
                  <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                    <div className="d-flex flex-row align-items-center">
                      <span className="mr-3">※</span>
                      <input
                        className="form-control d-inline-block w-auto"
                        type="number"
                        min={0}
                        onChange={(e) => {
                          Col1DataChange(e);
                        }}
                        data-keyword="psServiceCol1"
                      />
                    </div>
                    <div className="font-weight-bold">CBM</div>
                  </div>
                ) : (
                  <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                    <div className="d-flex flex-row align-items-center">
                      <span className="mr-3">※</span>
                      <input
                        className="form-control d-inline-block w-auto"
                        type="number"
                        min={0}
                        onChange={(e) => {
                          Col1DataChange(e);
                        }}
                        data-keyword="psServiceCol1"
                      />
                    </div>
                    <div className="font-weight-bold">Kg</div>
                  </div>
                )}
              </div>
            </div>
            <br />
            <table className="table table_summary">
              <tbody>
                <tr>
                  <td>
                    {productData.pmTransferType === "FCL" && "20FT"}
                    {productData.pmTransferType === "LCL" && "CBM"}
                    {productData.pmTransferType === "AIR" && "KG"}
                  </td>
                  <td className="text-right">{col1Data.count}</td>
                </tr>
                {productData.pmTransferType === "FCL" && (
                  <tr>
                    <td>40TF</td>
                    <td className="text-right">{col2Data.count}</td>
                  </tr>
                )}
                <tr className="total">
                  <td>원화 합계</td>
                  <td className="text-right">
                    {" "}
                    {util.addCommas(sendReserveData.krwCost)} 원
                  </td>
                </tr>
                <tr className="total">
                  <td>외화 합계</td>
                  <td className="text-right">
                    {foreignView()}{" "}
                    {util.addCommas(sendReserveData.foreignCurrencyCost)}
                  </td>
                </tr>
              </tbody>
            </table>
            {isExpired === false && (
              <>
                <button
                  className="btn_full"
                  onClick={(e) => {
                    CheckReserveData(e);
                  }}
                  disabled={isExpired}
                >
                  예약 및 문의하기
                </button>
              </>
            )}
            {productData.pmContactCompCD && productData.pmContactUserID && (
              <>
                <div
                  // to="/LogiTalk"
                  // target="_blank"
                  className="btn_1 d-block text-center w-100 py-2"
                  onClick={() => {
                    requestEnquiry(
                      productData.pmContactCompCD,
                      productData.pmContactUserID
                    );
                  }}
                >
                  웹 로지톡으로 문의하기
                </div>
                {/*{loginInfo && loginInfo.hasOwnProperty("user_id") && (*/}
                {/*<>*/}
                {/*  <div*/}
                {/*    className="btn_1 d-block text-center w-100 py-2 mt-2"*/}
                {/*    onClick={() => {*/}
                {/*      moveApp();*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    로지톡앱으로 문의하기*/}
                {/*  </div>*/}
                {/*</>*/}
                {/*)}*/}
              </>
            )}

            {isExpired === true && (
              <span className="text-danger">
                ※ 해당 상품은 판매가 중지 되었거나 유효기간이 만료 된 상품
                입니다.
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
