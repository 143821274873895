/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { CommonConstList } from "./CodeList";

export const ChartDefaultOption = {
  ResponsivePie: {
    margin: { top: 40, right: 80, bottom: 80, left: 80 },
    innerRadius: 0.5,
    padAngle: 0.7,
    cornerRadius: 3,
    activeOuterRadiusOffset: 8,
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: "#333333",
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: "color" },
    arcLabelsSkipAngle: 10,
    arcLabelsTextColor: {
      from: "color",
      modifiers: [["darker", 2]],
    },
    arcLinkLabel: (data) => {
      return `${data.id} (${data.value})`;
    },
    defs: [
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ],
    legends: [
      {
        anchor: "bottom",
        direction: "row",
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: "#999",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ],
  },
  ResponsiveLine: {
    margin: { top: 50, right: 110, bottom: 50, left: 60 },
    curve: "monotoneX",
    xScale: { type: "point" },
    yScale: {
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: true,
      reverse: false,
    },
    axisTop: null,
    axisRight: null,
    axisBottom: {
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "일자",
      legendOffset: 36,
      legendPosition: "middle",
    },
    axisLeft: {
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "건수",
      legendOffset: -40,
      legendPosition: "middle",
    },
    pointSize: 10,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    pointBorderColor: { from: "serieColor" },
    pointLabelYOffset: -12,
    useMesh: true,
    legends: [
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
  },
  ResponsiveBar: {
    margin: { top: 50, right: 130, bottom: 50, left: 60 },
    padding: 0.3,
    valueScale: { type: "linear" },
    indexScale: { type: "band", round: true },
    colors: { scheme: "nivo" },
    defs: [
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ],
    fill: [
      /*{
        match: {
          id: "업무시간",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },*/
    ],
    borderColor: {
      from: "color",
      modifiers: [["darker", 1.6]],
    },
    axisTop: null,
    axisRight: null,

    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: {
      from: "color",
      modifiers: [["darker", 1.6]],
    },
    legendLabel: (datum) => {
      //return "건수";
      //console.log(datum);
      //return `${datum.id} (${datum.value})`;
      return `${datum.id}`;
    },
    legends: [
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 150,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    tooltip: ({
      id,
      value,
      formattedValue,
      index,
      indexValue,
      data,
      color,
    }) => (
      <div
        style={{
          padding: 12,
          color: "white",
          background: "#222222",
        }}
      >
        <strong>
          {indexValue} : {id} ({formattedValue})
        </strong>
      </div>
    ),
    role: "application",
    ariaLabel: "로지톡톡",
    barAriaLabel: function (e) {
      return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
    },
  },
};

export const LocationSeaSelectOption = {
  required: true,
  multi: false,
  clearable: true,
  name: "SearchName",
  sortBy: "NewFullName",
  searchBy: "SearchName",
  valueField: "City",
  labelField: "NewFullName",
  autoFocus: false,
  //
  // contentRenderer: ({ props, state, methods }) => {
  //   let str = "";
  //   if (state.values.length > 0) {
  //     let value = state.values[0];
  //     str = `[${value.CustomCode}] ${value.CityName}`;
  //   } else {
  //     str = props.placeholder;
  //   }
  //
  //   return <div>{str}</div>;
  // },
  itemRenderer: ({ item, itemIndex, props, state, methods }) => {
    return (
      <div
        style={{
          backgroundColor:
            state.cursor === itemIndex ? "#edf0f9" : "transparent",
        }}
      >
        <p
          role="option"
          aria-selected="false"
          tabIndex="-1"
          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
          onClick={() => {
            methods.addItem(item);
          }}
          color="#0074D9"
          style={{
            fontSize: "11px",
            padding: "2px 5px",
            cursor: "pointer",
            borderBottom: "1px solid #fff",
            color: "gray",
            fontStyle: "italic",
            whiteSpace: "nowrap",
          }}
        >
          <b
            style={{
              fontWeight: "normal",
              color: "black",
              fontStyle: "normal",
              fontSize: "14px",
              fontFamily: "Consolas",
            }}
          >
            {item.CustomCode ? "[" + item.CustomCode + "] " : ""}
          </b>

          <b
            style={{
              fontWeight: 600,
              color: "black",
              fontStyle: "normal",
              fontSize: "14px",
            }}
          >
            {`${item.CityName} (${item.CityName_EN}) `}
          </b>

          {item.CountryName + " " + item.ContinentName}
        </p>
      </div>
    );
  },
};

export const LocationAirSelectOption = {
  required: true,
  clearable: true,
  multi: false,
  name: "SearchName",
  sortBy: "NewFullName",
  searchBy: "SearchName",
  valueField: "City",
  labelField: "NewFullName",
  autoFocus: false,
  itemRenderer: ({ item, itemIndex, props, state, methods }) => {
    return (
      <div
        style={{
          backgroundColor:
            state.cursor === itemIndex ? "#edf0f9" : "transparent",
        }}
      >
        <p
          role="option"
          aria-selected="false"
          tabIndex="-1"
          className="react-dropdown-select-item    css-148o527-ItemComponent evc32pp0"
          onClick={() => {
            methods.addItem(item);
          }}
          color="#0074D9"
          style={{
            fontSize: "11px",
            padding: "2px 5px",
            cursor: "pointer",
            borderBottom: "1px solid #fff",
            color: "gray",
            fontStyle: "italic",
            whiteSpace: "nowrap",
          }}
        >
          <b
            style={{
              fontWeight: "normal",
              color: "black",
              fontStyle: "normal",
              fontSize: "14px",
              fontFamily: "Consolas",
            }}
          >
            {item.CustomCode ? "[" + item.CustomCode + "] " : ""}
          </b>

          <b
            style={{
              fontWeight: 600,
              color: "black",
              fontStyle: "normal",
              fontSize: "14px",
            }}
          >
            {`${item.CityName} (${item.CityName_EN}) `}
          </b>

          {item.CountryName + " " + item.ContinentName}
        </p>
      </div>
    );
  },
};

export const BCompSelectOption = {
  multi: true,
  clearable: true,
  name: "blCompNM_KO",
  sortBy: "blCompNM_KO",
  searchBy: "blCompNM_KO",
  valueField: "blFWDNO",
  labelField: "blFWDNO",
  autoFocus: false,
  noDataRenderer: () => {
    return <div className="px-2 py-1 font-weight-bold">- 데이터 없음 -</div>;
  },

  itemRenderer: ({ item, itemIndex, props, state, methods }) => {
    return (
      <div key={item.blIDX}>
        <div
          role="option"
          aria-selected="false"
          tabIndex="-1"
          className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0 cursor_pointer_bg_light px-2 py-1 d-flex flex-row align-items-center"
          onClick={() => {
            methods.addItem(item);
          }}
        >
          {item.blLOGO && (
            <div
              className="d-inline-block mr-2"
              style={{
                width: "1rem",
                height: "1rem",
                background: `url(${item.blLOGO}) 50% 50% / contain no-repeat`,
              }}
            />
          )}
          <p className="d-inline-block">
            <b>{item.blCompNM_KO}</b>{" "}
            <span className="font-size-08">[{item.blCompNM}]</span>
          </p>
        </div>
      </div>
    );
  },
};

class CommonUI extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  openModal = (type, title, message, placeholder = "", callback) => {
    this.modal.current.openModal(
      type,
      title,
      message,
      (placeholder = ""),
      callback
    );
  };

  alert = (message, callback) => {
    this.modal.current.alert(message, callback);
  };

  confirm = (message, callback) => {
    this.modal.current.confirm(message, callback);
  };

  render() {
    return (
      <>
        <Modal ref={this.modal} />
      </>
    );
  }
}

export default CommonUI;
