import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import ScheduleAirBookingManageTable from "./component/ScheduleAirBookingManageTable";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const _mClassName = "ScheduleAirBookingManage";

let airline_list = [];
let supplier_list = [];

export default function ScheduleAirBookingManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const controller = {
    sba_STATUS: useRef(),
    sba_FWD_NO: useRef(),
    sba_PROVIDER_CODE: useRef(),
    SEARCH_DATE_TYPE: useRef(),
    SEARCH_DATE_START: useRef(),
    SEARCH_DATE_END: useRef(),
    SEARCH_TYPE: useRef(),
    SEARCH_WORD: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      SELECT_AIRLINE_COMP_LIST();
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const SELECT_AIRLINE_COMP_LIST = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/airline/JS_airline_select.php",
      msgID: msgID,
      data: {},
      sm_FCL_LCL: "AIR",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = newData["returnData"];
          let airline = [];
          let supplier = [];
          for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];
            if (data.AL_COMP_TYPE === "A") {
              airline.push(data);
            } else {
              supplier.push(data);
            }
            airline_list = [...airline];
            supplier_list = [...supplier];
            setIsNeedUpdate(!isNeedUpdate);
          }
        }
      }
    });
  };
  const handleChangedFilters = () => {
    const filters = {
      sba_STATUS: controller.sba_STATUS.current.value,
      sba_FWD_NO: controller.sba_FWD_NO.current.value,
      sba_PROVIDER_CODE: controller.sba_PROVIDER_CODE.current.value,
      SEARCH_DATE_TYPE: controller.SEARCH_DATE_TYPE.current.value,
      SEARCH_DATE_START: controller.SEARCH_DATE_START.current.value,
      SEARCH_DATE_END: controller.SEARCH_DATE_END.current.value,
      SEARCH_TYPE: controller.SEARCH_TYPE.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };
    socket.sendLocalMessage(
      MsgIDList.EVENT_SCHEDULE_AIR_BOOKING_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <div className="btn-toolbar mb-3 justify-content-end" role="toolbar">
            <select
              className="custom-select form-control w-auto ml-2"
              ref={controller.sba_STATUS}
              onChange={handleChangedFilters}
            >
              <option value="">-처리상태-</option>
              <option value="N">요청</option>
              <option value="Y">확정</option>
              <option value="C">취소</option>
            </select>
            <select
              className="custom-select form-control w-auto ml-2"
              ref={controller.sba_FWD_NO}
              onChange={handleChangedFilters}
            >
              <option value="">-항공사-</option>
              {airline_list.map((data) => {
                return (
                  <option key={data.AL_COMP_CD} value={data.AL_COMP_CD}>
                    {data.AL_COMP_NM}
                  </option>
                );
              })}
            </select>
            <select
              className="custom-select form-control w-auto ml-2"
              ref={controller.sba_PROVIDER_CODE}
              onChange={handleChangedFilters}
            >
              <option value="">-공급사-</option>
              {supplier_list.map((data) => {
                return (
                  <option key={data.AL_COMP_CD} value={data.AL_COMP_CD}>
                    {data.AL_COMP_NM}
                  </option>
                );
              })}
            </select>
            <div className="input-group ml-2">
              <select
                className="custom-select form-control w-auto"
                ref={controller.SEARCH_DATE_TYPE}
                onChange={handleChangedFilters}
              >
                <option value="">-검색일자-</option>
                <option value="sba_FLIGHT_DATE">운송일자</option>
                <option value="sba_CREATEDATE">요청일자</option>
              </select>
              <input
                type="date"
                className="form-control"
                ref={controller.SEARCH_DATE_START}
                onChange={handleChangedFilters}
                style={{ minWidth: 150 }}
              />
              <div className="input-group-append border-right-0">
                <span className="input-group-text border-right-0">-</span>
              </div>
              <input
                type="date"
                className="form-control"
                ref={controller.SEARCH_DATE_END}
                onChange={handleChangedFilters}
                style={{ minWidth: 150 }}
              />
            </div>
            <div className="input-group ml-2">
              <select
                className="custom-select form-control w-auto"
                ref={controller.SEARCH_TYPE}
              >
                <option value="">-검색조건-</option>
                <option value="sba_COMP_NM">예약업체명</option>
                <option value="sba_USER_NM">예약자명</option>
              </select>
              <input
                type="text"
                placeholder="검색어를 입력해주세요"
                className="form-control"
                ref={controller.SEARCH_WORD}
                style={{ minWidth: 180 }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    handleChangedFilters();
                  }
                }}
              />
              <button
                className="input-group-text btn btn-primary rounded-0 rounded-top-right rounded-bottom-right border-left-0"
                onClick={handleChangedFilters}
              >
                검색
              </button>
            </div>
          </div>
        </div>
        <ScheduleAirBookingManageTable loginInfo={loginInfo} />
      </div>
    </div>
  );
}
