import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

let slideCountBackUp = 4;
let bannerList = [];

export default function BannerMarketing() {
  const [slideCount, setSlideCount] = useState(4);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    handleSlideCount();
    window.addEventListener("resize", handleSlideCount);

    if (bannerList.length === 0) {
      requestScheduleBanner();
    }

    return () => {
      window.removeEventListener("resize", handleSlideCount);
    };
  }, []);

  const handleSlideCount = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      slideCountBackUp = 1;
      //  mobile
    } else if (windowWidth >= 576 && windowWidth < 992) {
      slideCountBackUp = 2;
    } else {
      slideCountBackUp = 4;
    }
    setSlideCount(slideCountBackUp);
  };

  const requestScheduleBanner = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/mallBanner/JS_mall_banner_select.php",
      msgID: msgID,
      data: {},
      mbUSEYN: "Y",
      sort: [
        {
          field: "mbOrder",
          sort: "asc",
        },
      ],
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          bannerList = newData["returnData"];
          setIsNeedUpdate((prevState) => !prevState);
        }
      }
    });
  };

  //연결링크 새창 열기
  const moveToUrl = (url) => {
    if (url) {
      window.open(url, "_blank", "top=0, left=0, width=1920, height=1080");
    }
  };
  const BannerItemRender = useCallback(({ item, moveToUrl }) => {
    return <BannerItem {...item} moveToUrl={moveToUrl} />;
  }, []);
  return (
    <div id="banner_mark" className="container margin_30 mt-4 mb-3">
      <div className="row">
        <div className="col-12">
          {bannerList.length > 0 ? (
            <OwlCarousel
              className="owl-theme"
              loop={true}
              nav={true}
              dots={false}
              autoplay={true}
              autoplayTimeout={2500}
              autoplayHoverPause={true}
              items={slideCount}
              navContainerClass="d-none d-md-flex flex-row justify-content-center"
              navClass={[
                "custom-owl-nav-circle prev font-weight-900 mb-0",
                "custom-owl-nav-circle next font-weight-900 mb-0",
              ]}
              stageOuterClass="custom-owl-stage-outer bg-white rounded shadow-sm"
              navText={[
                '<i class="fa-solid fa-chevron-left"></i>',
                '<i class="fa-solid fa-chevron-right"></i>',
              ]}
            >
              {bannerList.map((item) => {
                return (
                  <BannerItemRender
                    key={item.mbIDX}
                    item={item}
                    moveToUrl={moveToUrl}
                  />
                );
              })}
            </OwlCarousel>
          ) : (
            <div className="p-5 shadow-sm rounded text-center text-secondary h6 bg-white font-weight-bold mb-0">
              해당위치에 광고배너를 등록하고 사용자를 모아보세요
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const BannerItem = React.memo(
  ({ mbLogo, mbTITLE, mbCONTENTS, mbURL, moveToUrl }) => {
    let logo = "";
    if (mbLogo) {
      logo = mbLogo.split("?")[1];
    }

    return (
      <div className="item">
        <div className="border-light border-right p-2">
          <div
            className="d-flex flex-column justify-content-center align-items-center bg-white rounded cursor_pointer cursor_pointer_shadow"
            style={{ height: 130 }}
            onClick={() => moveToUrl(mbURL)}
          >
            <div className="p-2 d-flex flex-column justify-content-center align-items-center position-relative">
              <div
                style={{
                  background: `url(${logo}) 50% 50% / contain no-repeat`,
                  width: 80,
                  height: 50,
                }}
              />
              <p className="my-1 pb-1 font-weight-bold border-bottom border-width-2 border-info text-center text-dark">
                {mbTITLE}
              </p>
              <p className="text-center ellipsis_2 font-size-08 text-secondary">
                {mbCONTENTS}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
