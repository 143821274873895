import React from "react";
import PropTypes from "prop-types";

export default function LogoSvg(props) {
  const { width, height, color, point } = props;

  let fontColor = color || "#00437d";
  let pointColor = point || "#0097b7";

  return (
    <svg
      id="b6b3e12a-0f2e-489d-a113-6af325cfb374"
      data-name="레이어 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 118.99"
      width={width}
      height={height}
    >
      <path
        fill={pointColor}
        d="M175.43,22.12c-1.67,0-2.51-.79-2.51-2.39V2.51A2.22,2.22,0,0,1,175.43,0h18.89a2.22,2.22,0,0,1,2.52,2.51V19.73c0,1.6-.84,2.39-2.52,2.39Z"
      />
      <path
        fill={fontColor}
        d="M42.33,0H36.59A41.94,41.94,0,0,0,23.32,1.85a20.55,20.55,0,0,0-9,5.74,24.49,24.49,0,0,0-5.14,9.75A56.46,56.46,0,0,0,7.29,31.09H2.51c-1.68,0-2.51.8-2.51,2.39V49.39A2.22,2.22,0,0,0,2.51,51.9H7.29V94q0,2.28,2.52,2.28h19c1.59,0,2.39-.76,2.39-2.28V51.9H42.33a2.22,2.22,0,0,0,2.51-2.51V33.48c0-1.59-.83-2.39-2.51-2.39H31.21A29.28,29.28,0,0,1,31.57,26a9,9,0,0,1,1-3.05,3.25,3.25,0,0,1,1.61-1.44,7.14,7.14,0,0,1,2.39-.35h5.74a2.72,2.72,0,0,0,1.8-.6,2.35,2.35,0,0,0,.71-1.91V2.39C44.84.8,44,0,42.33,0Z"
      />
      <path
        fill={fontColor}
        d="M95,29.9a58.08,58.08,0,0,0-10.82,1,22.58,22.58,0,0,0-10.59,5.68V33.36c0-1.51-.83-2.27-2.51-2.27H52c-1.6,0-2.39.76-2.39,2.27V93.75c0,1.68.79,2.52,2.39,2.52H71.15c1.6,0,2.39-.84,2.39-2.52V59.2a28.44,28.44,0,0,1,5.09-3.41,27.29,27.29,0,0,1,5-2,29.5,29.5,0,0,1,5.32-1c1.87-.15,3.93-.23,6.16-.23,1.59,0,2.39-.84,2.39-2.51V32.17C97.46,30.65,96.62,29.9,95,29.9Z"
      />
      <path
        fill={fontColor}
        d="M159,35.82A32.08,32.08,0,0,0,148.58,31a50,50,0,0,0-13.33-1.67,54.52,54.52,0,0,0-13.64,1.61A30.66,30.66,0,0,0,110.79,36a23.22,23.22,0,0,0-7.17,9,31.85,31.85,0,0,0-2.57,13.46v10.4a31.14,31.14,0,0,0,2.63,13.45,24,24,0,0,0,7.17,9,29.73,29.73,0,0,0,10.65,5.08,50.5,50.5,0,0,0,13,1.62,54.52,54.52,0,0,0,13.27-1.5A33.4,33.4,0,0,0,158,92.32a20.66,20.66,0,0,0,6.58-6.58,16,16,0,0,0,2.33-8.49V75.58a2.12,2.12,0,0,0-2.39-2.39H147a2.24,2.24,0,0,0-1.8.59,4.27,4.27,0,0,0-.71,1.8,4.57,4.57,0,0,1-2.75,3.83,16.55,16.55,0,0,1-6.58,1.07c-3.83,0-6.62-.91-8.37-2.75a10.07,10.07,0,0,1-2.63-7.29v-1.8h41.49a2.22,2.22,0,0,0,2.52-2.51V53.69a21.43,21.43,0,0,0-2.4-10.16A22.18,22.18,0,0,0,159,35.82ZM124.13,56.09a9.3,9.3,0,0,1,3.16-7.18,11.61,11.61,0,0,1,7.84-2.63q4.91,0,7.65,2.63a9.82,9.82,0,0,1,2.87,7.18Z"
      />
      <path
        fill={fontColor}
        d="M194.32,31.09h-19c-1.6,0-2.39.76-2.39,2.27V93.75c0,1.68.79,2.52,2.39,2.52h19.13q2.4,0,2.4-2.52V33.36C196.84,31.85,196,31.09,194.32,31.09Z"
      />
      <path
        fill={fontColor}
        d="M239.41,57.88q-3.24-.84-6-1.67a44.54,44.54,0,0,1-4.9-1.74,15.64,15.64,0,0,1-3.35-1.85,2.72,2.72,0,0,1-1.26-2.16,3.84,3.84,0,0,1,1.79-3.28A9.41,9.41,0,0,1,231,45.92a9.21,9.21,0,0,1,4.48,1.14,5.27,5.27,0,0,1,2.69,3A3,3,0,0,0,241.32,52h13a2.91,2.91,0,0,0,1.79-.54,1.92,1.92,0,0,0,.6-1.85,21.5,21.5,0,0,0-3.29-10.17,20.19,20.19,0,0,0-6.64-6.22,27.68,27.68,0,0,0-8.67-3.17,50.3,50.3,0,0,0-9.27-.89,33.89,33.89,0,0,0-10.7,1.61,26.2,26.2,0,0,0-8.37,4.49,20.61,20.61,0,0,0-5.5,6.81,18.91,18.91,0,0,0-2,8.61,14.88,14.88,0,0,0,1.49,6.88,15.93,15.93,0,0,0,4.31,5.2,28.78,28.78,0,0,0,6.87,4,85.85,85.85,0,0,0,9.33,3.29c1.91.56,3.71,1,5.38,1.49A41.62,41.62,0,0,1,234.09,73,10.61,10.61,0,0,1,237,74.56a2.8,2.8,0,0,1,1.07,2.21,3.8,3.8,0,0,1-1.91,3.29,10.28,10.28,0,0,1-5.62,1.26q-6.7,0-8.73-5.38A3,3,0,0,0,218.72,74H204a2.11,2.11,0,0,0-2.39,2.39,18.07,18.07,0,0,0,1.55,6.75,20.37,20.37,0,0,0,5.08,7.06,29.46,29.46,0,0,0,9.21,5.56,37.37,37.37,0,0,0,13.82,2.27,39.17,39.17,0,0,0,11.66-1.62,27.87,27.87,0,0,0,8.9-4.48,20.4,20.4,0,0,0,5.74-6.76,17.83,17.83,0,0,0,2-8.43q0-8-5.32-12.19T239.41,57.88Z"
      />
      <path
        fill={fontColor}
        d="M321.15,35.7A33.79,33.79,0,0,0,310.74,31a50.1,50.1,0,0,0-13.33-1.67,54.52,54.52,0,0,0-13.64,1.61A30.66,30.66,0,0,0,273,36a23.22,23.22,0,0,0-7.17,9,31.85,31.85,0,0,0-2.57,13.46v10.4a31.77,31.77,0,0,0,2.57,13.45,23.25,23.25,0,0,0,7.17,9,30.3,30.3,0,0,0,10.82,5.08,54,54,0,0,0,13.64,1.62,55.32,55.32,0,0,0,13.33-1.5A31,31,0,0,0,321.15,92a21,21,0,0,0,6.75-7.65,22.85,22.85,0,0,0,2.39-10.71V72a2.11,2.11,0,0,0-2.39-2.39H309.13c-1.68,0-2.51.8-2.51,2.39a6.13,6.13,0,0,1-2.1,4.84q-2.08,1.86-7.11,1.86-5.27,0-7.6-2.81a10.11,10.11,0,0,1-2.33-6.64V58.12a10,10,0,0,1,2.39-6.64q2.4-2.8,7.66-2.81c3.34,0,5.7.58,7.05,1.74a4.94,4.94,0,0,1,2,3.76c0,1.6.83,2.39,2.51,2.39H327.9a2.11,2.11,0,0,0,2.39-2.39V52.5a19.07,19.07,0,0,0-2.39-9.45A21.83,21.83,0,0,0,321.15,35.7Z"
      />
      <path
        fill={fontColor}
        d="M477.73,48.55a28.38,28.38,0,0,0-6.22-10,25.57,25.57,0,0,0-9.33-6,32.79,32.79,0,0,0-11.72-2,25.29,25.29,0,0,0-7.35,1.14,15.32,15.32,0,0,0-6.28,3.53v-1.8c0-1.51-.84-2.27-2.51-2.27H415.66c-1.59,0-2.39.76-2.39,2.27v83.12c0,1.67.8,2.51,2.39,2.51h18.78c1.59,0,2.39-.84,2.39-2.51V96.27h7.65a52.42,52.42,0,0,0,14.71-1.92,29.47,29.47,0,0,0,11.18-5.86,26.3,26.3,0,0,0,7.12-9.86A35.59,35.59,0,0,0,480,64.69V62.3A38.67,38.67,0,0,0,477.73,48.55ZM456.56,63.14q0,7-3.17,10.22t-8.91,3.17h-7.65V53.34A11.14,11.14,0,0,1,441,50.88a13.68,13.68,0,0,1,4.24-.77c3.83,0,6.68,1,8.55,3.11S456.56,58.6,456.56,63.14Z"
      />
      <path
        fill={fontColor}
        d="M397.34,62.48a28.1,28.1,0,0,0-26.89-26.89V56h-2.28V35.59a28.1,28.1,0,0,0-26.89,26.89h20.45v2.28H341.28a28.1,28.1,0,0,0,26.89,26.89V71.2h2.28V91.65a28.1,28.1,0,0,0,26.89-26.89H376.89V62.48Zm-28,3.42a2.28,2.28,0,1,1,2.28-2.28A2.27,2.27,0,0,1,369.31,65.9Z"
      />
      <path
        fill={pointColor}
        d="M372.2,103.29h-5.78V97.85a34.46,34.46,0,0,1-31.34-31.33h-5.44v-5.8h5.44a34.46,34.46,0,0,1,31.34-31.33V24h5.78v5.44a34.46,34.46,0,0,1,31.34,31.33H409v5.8h-5.44A34.46,34.46,0,0,1,372.2,97.85ZM369.31,33.9A29.72,29.72,0,1,0,399,63.62,29.76,29.76,0,0,0,369.31,33.9Z"
      />
    </svg>
  );
}

LogoSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
