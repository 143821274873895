/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { ResponsiveLine } from "@nivo/line";
import { ChartDefaultOption } from "../../../lib/CommonUI";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "DashboardFCLReserveLineChat";

let mFilter = {};
let headCells = [];

export default forwardRef(function DashboardFCLReserveLineChat({}, ref) {
  const [filter, setFilter] = useState({});
  const [serverData, setServerData] = useState([
    {
      sandwich: 141,
      sandwichColor: "hsl(337, 70%, 50%)",
    },
  ]);

  useEffect(() => {
    //requestUserList();
  }, []);

  useImperativeHandle(ref, () => ({
    requestServerData(params) {
      mFilter = { ...mFilter, ...params };
      setFilter({ ...filter, ...params });
      requestServerData(params);
    },
  }));

  const requestServerData = (filter) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/dashboard/JS_dashboard_fcl_reserve_per_day.php",
      msgID: msgID,
      data: {},
    };

    if (filter) {
      socketMsg = { ...socketMsg, ...filter };
    } else {
      return;
    }

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
      if (newData) {
        if (newData["ret"]) {
          //setServerData(newData["returnData"]);

          setServerData([
            {
              id: "예약건수",
              color: "hsl(239, 70%, 50%)",
              data: [...newData["returnData"]],
            },
          ]);
          // setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const MyResponsiveBar = ({ data /* see data tab */ }) => (
    /*<ResponsiveBar
          {...ChartDefaultOption.ResponsiveBar}
          data={data}
          keys={dataKeys}
          indexBy="f_create_date"
          /!*colorBy={"indexValue"}*
          onClick={(data) => {
            data.data.depth = depth;
            socket.sendLocalMessage(
                MsgIDList.EVENT_ERP_DASHBOARD_INBOUND_ROUTE_FILTER_CHANGED,
                _mClassName + depth,
                data.data
            );
          }}
      />*/
    <ResponsiveLine
      {...ChartDefaultOption.ResponsiveLine}
      data={data}
      keys={["f_count"]}
      indexBy="sbf_CREATE_DATE"
      sliceTooltip={({ slice }) => {
        console.log("slice", slice);
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}
          >
            <div>x: {slice.id}</div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: "3px 0",
                }}
              ></div>
            ))}
          </div>
        );
      }}
    />
  );

  return (
    <>
      {/*<MyResponsivePie data={sampleData}/>*/}

      <div style={{ height: "300px" }}>
        <MyResponsiveBar data={serverData} />
      </div>
    </>
  );
});
