import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translation.en.json";
import ko from "./translation.ko.json";

const languages = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources: languages,
  debug: false,
  lng: "ko",
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});
