/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithPhp from "../../../lib/UploadImageToS3WithPhp";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const _mClassName = "AirlineManageEdit";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

function AirlineManageEdit(props, ref) {
  const { requestUserSelect, compList } = props;
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState("");

  const $controller = {
    AL_COMP_CD: useRef(),
    AL_COMP_NM: useRef(),
    AL_COMP_NM_EN: useRef(),
    AL_COMP_LOGO: useRef(),
    AL_COMP_TYPE: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setIsLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setModalData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_BLOGO_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  //부모 호출 함수
  useImperativeHandle(ref, () => ({
    openEditModal(e, params) {
      handleModalData(e, params);
    },
  }));

  //모달 데이터 세팅
  const handleModalData = (e, params) => {
    setModalData(params);
  };

  useEffect(() => {
    if (modalData.hasOwnProperty("currentData")) {
      $controller.AL_COMP_CD.current.value = modalData.currentData.AL_COMP_CD;
      $controller.AL_COMP_NM.current.value = modalData.currentData.AL_COMP_NM;
      $controller.AL_COMP_NM_EN.current.value =
        modalData.currentData.AL_COMP_NM_EN;
      $controller.AL_COMP_LOGO.current.value =
        modalData.currentData.AL_COMP_LOGO;
      $controller.AL_COMP_TYPE.current.value =
        modalData.currentData.AL_COMP_TYPE;
      setUploadFile(modalData.currentData.AL_COMP_LOGO);
    } else {
      $controller.AL_COMP_CD.current.value = "";
      $controller.AL_COMP_NM.current.value = "";
      $controller.AL_COMP_NM_EN.current.value = "";
      $controller.AL_COMP_LOGO.current.value = "";
      $controller.AL_COMP_TYPE.current.value = "";
      setUploadFile("");
    }
  }, [modalData]);

  //첨부파일 업로드
  const UploadFiles = (val) => {
    if (val.ret) {
      let fileLink = val.fileName;
      let fileName = val.realFileName;
      let fileFull = `${fileLink}?${fileName}`;
      $controller.AL_COMP_LOGO.current.value = fileFull;
      setUploadFile(fileFull);
    } else {
      codeList.Modal.current.alert(
        "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
        () => {}
      );
    }
  };

  //유효성검사
  const checkValidation = () => {
    if (
      $controller.AL_COMP_NM.current &&
      $controller.AL_COMP_NM.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상호명을 입력해주세요");
      return true;
    }
    if (
      $controller.AL_COMP_TYPE.current &&
      $controller.AL_COMP_TYPE.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("업체구분을 선택해주세요");
      return true;
    }
    if (
      $controller.AL_COMP_NM_EN.current &&
      $controller.AL_COMP_NM_EN.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("상호명(영문)을 입력해주세요");
      return true;
    }
    if (
      $controller.AL_COMP_LOGO.current &&
      $controller.AL_COMP_LOGO.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("로고이미지를 업로드해주세요");
      return true;
    }
    return false;
  };

  //홍보영상 저장
  const actionSave = () => {
    if (checkValidation()) return;

    const updateData = {
      AL_COMP_CD: $controller.AL_COMP_CD.current.value,
      AL_COMP_NM: $controller.AL_COMP_NM.current.value,
      AL_COMP_NM_EN: $controller.AL_COMP_NM_EN.current.value,
      AL_COMP_LOGO: $controller.AL_COMP_LOGO.current.value,
      AL_COMP_TYPE: $controller.AL_COMP_TYPE.current.value,
    };
    requestUserUpdate(updateData);
  };

  //등록 및 수정 서버 요청
  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: modalData.hasOwnProperty("currentData")
        ? "/airline/JS_airline_update.php"
        : "/airline/JS_airline_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          socket.fireLocalEventListener(
            MsgIDList.EVENT_AIRLINE_MANAGE_DATA_UPDATE,
            _mClassName,
            newData,
            () => {}
          );

          requestUserSelect();
          codeList.Modal.current.alert(
            "항공사/공급사 정보가 업데이트 되었습니다.",
            () => {
              closeEditModal();
            }
          );
        } else {
          codeList.Modal.current.alert(
            "요청 처리중에 문제가 발생하였습니다. 잠시후 다시 시도해주세요"
          );
        }
      }
    });
  };

  //값 초기화 및 모달 닫기
  const closeEditModal = () => {
    for (let key in $controller) {
      if ($controller[key].current) {
        $controller[key].current.value = "";
      }
    }
    setModalData({});
    setIsLoading(false);
    $("#airlineManageEditClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="airlineManageEdit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="airlineManageEdit"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 700 }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>선사 · 항공사 로고관리</strong>
              </h3>

              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="airlineManageEditClose"
                onClick={() => {
                  closeEditModal();
                }}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체코드
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="업체코드를 입력해주세요"
                    ref={$controller.AL_COMP_CD}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  업체구분
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    ref={$controller.AL_COMP_TYPE}
                  >
                    <option value="">미선택</option>
                    <option value="A">항공사</option>
                    <option value="P">공급사</option>
                    <option value="B">선사</option>
                    <option value="C">콘솔사</option>
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  상호명
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="상호명을 입력해주세요"
                    ref={$controller.AL_COMP_NM}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  상호명(영문)
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="상호명(영문)을 입력해주세요"
                    ref={$controller.AL_COMP_NM_EN}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">LOGO</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control d-none"
                    ref={$controller.AL_COMP_LOGO}
                  />
                  <UploadImageToS3WithPhp
                    region={"mall/airline"}
                    callback={UploadFiles}
                  />
                  <div className="pb-2">
                    <p
                      style={{
                        fontSize: "small",
                        color: "#8f8f8f",
                      }}
                    >
                      ※ 이미지는 100X100 사이즈를 권장합니다.
                    </p>
                  </div>
                  <div
                    className="rounded bg-light d-flex flex-row justify-content-center align-items-center"
                    style={{
                      minHeight: 100,
                      background: `url(${
                        uploadFile ? uploadFile.split("?")[0] : ""
                      }) 50% 50% /contain no-repeat`,
                    }}
                  >
                    {!uploadFile && (
                      <p className="h6 m-0 font-weight-bold text-secondary">
                        LOGO
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary mr-2"
                onClick={() => closeEditModal()}
              >
                취소
              </button>
              <button className="btn btn-primary" onClick={() => actionSave()}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(AirlineManageEdit);
