import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import EntireTalkMsgEditModal from "./EntireTalkMsgEditModal";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import EntireTalkRoomPreviewModal from "./EntireTalkRoomPreviewModal";

const socket = new BuddibleSocket();
const util = new Utilities();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const _mClassName = "TalkNewsCompManageTable";
const headCells = [
  {
    field: "cntTitle",
    headerName: "제목",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "cntLink",
    headerName: "연결 URL",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      if (params.data === "") {
        return <span>-</span>;
      }
      return (
        <a href={params.data} target="_blank">
          {params.data}
        </a>
      );
    },
  },
  {
    field: "cntLikeTotal",
    headerName: "좋아요",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p className="text-right">{params.data} 명</p>;
    },
  },

  {
    field: "cntCreatDate",
    headerName: "생성일",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "cntDelYN",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#EntireTalkMsgEditModal"
            onClick={(e) => params.openEdit(e, params)}
            title={"수정"}
            aria-label={"수정"}
          >
            <i className="fa-solid fa-pen" />
          </button>
          <button
            type="button"
            className="btn btn-danger"
            data-toggle="modal"
            onClick={(e) => params.removeData(e, params)}
            title={"삭제"}
            aria-label={"삭제"}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        </span>
      );
    },
  },
];

function Row(props) {
  const { row, openEdit, removeData } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        size={"small"}
      >
        {headCells.map((headCell) => {
          const rowProp = {
            data: row[headCell.field],
            currentData: row,
            currentHeader: headCell.field,
            headCells: headCells,
            openEdit: openEdit,
            removeData: removeData,
            open: open,
            setOpen: setOpen,
          };
          return (
            <TableCell
              key={headCell.field}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.hasOwnProperty("renderCell")
                ? headCell.renderCell({ ...rowProp })
                : headCell.hasOwnProperty("valueGetter")
                ? headCell.valueGetter({ ...rowProp })
                : row[headCell.field]}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

export default function EntireTalkMsgManage(props) {
  const {
    isServerPaging = false,
    isServerSort = false,
    selectedTalkRoom,
    loginInfo,
  } = props;

  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("cntCreatDate");
  const [order, setOrder] = useState("desc");

  const [sortModel, setSortModel] = useState([
    {
      field: "cntCreatDate",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  const editModal = useRef();
  const searchController = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cntMainKey: selectedTalkRoom.cnmMainKey,
      cntDelYN: "N",
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  useEffect(() => {
    if (selectedTalkRoom.cnmMainKey) {
      requestUserSelect();
    }
  }, [page, sortModel, filters, selectedTalkRoom]);

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  function removeData(e, params) {
    editModal.current.removeData(e, params);
  }

  const actionSearch = () => {
    setFilters({
      SEARCH_LEVEL: searchController.SEARCH_LEVEL.current.value,
      SEARCH_WORD: searchController.SEARCH_WORD.current.value,
    });
  };

  const isDisabled = !selectedTalkRoom.cnmMainKey;

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowsProps = {
    openEdit,
    removeData,
    requestUserSelect,
  };

  return (
    <>
      <EntireTalkMsgEditModal
        ref={editModal}
        loginInfo={loginInfo}
        selectedTalkRoom={selectedTalkRoom}
        requestUserSelect={requestUserSelect}
      />
      {!isDisabled && (
        <EntireTalkRoomPreviewModal selectedTalkRoom={selectedTalkRoom} />
      )}
      <div className="card shadow-sm">
        <div className="card-header">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <button
                className="btn btn-sm btn-primary mr-2"
                data-toggle="modal"
                data-target="#EntireTalkMsgEditModal"
                onClick={(e) => openEdit(e, {})}
                disabled={isDisabled}
              >
                <i className="fa-solid fa-plus mr-2" />
                신규 작성
              </button>
              <button
                className="btn btn-sm btn-outline-primary"
                data-toggle="modal"
                data-target="#EntireTalkRoomPreviewModal"
                disabled={isDisabled}
                // onClick={(e) => {
                //   console.log("selectedTalkRoom", selectedTalkRoom);
                //   console.log("미리보기");
                // }}
                disabled={isDisabled}
              >
                미리보기
              </button>
            </div>
            <div>
              <div className="input-group">
                <select
                  className="form-control"
                  disabled={isDisabled}
                  ref={searchController.SEARCH_LEVEL}
                >
                  <option value="">검색 기준</option>
                  <option value="cntTitle">제목</option>
                  <option value="cntMSG">내용</option>
                </select>
                <input
                  className="form-control"
                  placeholder="검색어를 입력해주세요"
                  disabled={isDisabled}
                  ref={searchController.SEARCH_WORD}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      actionSearch();
                    }
                  }}
                />
                <button
                  className="input-group-text border-left-0 rounded-0 rounded-top-right rounded-bottom-right"
                  disabled={isDisabled}
                  onClick={actionSearch}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        {!selectedTalkRoom.cnmMainKey && (
          <div className="p-5 text-secondary font-weight-bold text-center">
            채널을 선택해주세요
          </div>
        )}
        {selectedTalkRoom.cnmMainKey && (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" id="storeReviewManageList">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />

              <TableBody>
                {!isServerPaging &&
                  !isServerSort &&
                  util
                    .stableSort(data, util.getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row
                        key={row.id}
                        row={row}
                        index={index}
                        {...rowsProps}
                      />
                    ))}
                {!isServerPaging &&
                  isServerSort &&
                  util
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row
                        key={row.id}
                        row={row}
                        index={index}
                        {...rowsProps}
                      />
                    ))}

                {isServerPaging &&
                  !isServerSort &&
                  util
                    .stableSort(data, util.getComparator(order, orderBy))
                    .map((row, index) => (
                      <Row
                        key={row.id}
                        row={row}
                        index={index}
                        {...rowsProps}
                      />
                    ))}

                {isServerPaging &&
                  isServerSort &&
                  data.map((row, index) => (
                    <Row key={row.id} row={row} index={index} {...rowsProps} />
                  ))}

                {loading && (
                  <TableRow style={{ height: 54 * rowsPerPage }}>
                    <TableCell colSpan={headCells.length}>
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-grow text-secondary"
                          role="status"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {data.length === 0 && !loading && (
                  <TableRow style={{ height: 54 * rowsPerPage }}>
                    <TableCell colSpan={headCells.length}>
                      <div className="d-flex justify-content-center">
                        <div>해당 데이터가 존재하지 않습니다.</div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {data.length > 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 54 * emptyRows }}>
                    <TableCell colSpan={headCells.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <div className="mb-3 mt-3 pr-3">
              <Pagination
                style={{ justifyContent: "flex-end" }}
                className={classes.pagination}
                color="primary"
                count={totalPageCount}
                page={page + 1}
                allin={"right"}
                onChange={(event, value) => {
                  handleChangePage(event, value - 1);
                }}
                showFirstButton
                showLastButton
              />
            </div>
          </TableContainer>
        )}
      </div>
    </>
  );
}
