/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ShipperInfoManageTable";

const useStyles = makeStyles((theme) => ({
  ...codeList.publicVar.tableDefaultStyle(theme),
}));
const useRowStyles = makeStyles({
  ...codeList.publicVar.tableRowDefaultStyle(),
});

const headCells = [
  {
    field: "COMP_CD",
    headerName: "업체코드",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data || "-"}
        </span>
      );
    },
  },
  {
    field: "COMP_NM",
    headerName: "업체명",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data || "-"}
        </span>
      );
    },
  },
  {
    field: "COMP_CEO_NM",
    headerName: "대표자명",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data || "-"}
        </span>
      );
    },
  },
  {
    field: "COMP_NUM",
    headerName: "사업자등록번호",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {util.getAutoHyphenBizID(params.data)}
        </span>
      );
    },
  },
];

function Row(props) {
  const { row, index, handleCompSelect } = props;
  const classes = useRowStyles();
  return (
    <TableRow
      className={[classes.root, "text-truncate cursor-pointer"].join(" ")}
      hover
      role="checkbox"
      key={index}
      onClick={() => handleCompSelect(row)}
    >
      {headCells.map((headCell, index) => {
        let style = {};
        if (headCell.hasOwnProperty("minWidth"))
          style.minWidth = headCell.minWidth;
        if (headCell.hasOwnProperty("maxWidth"))
          style.maxWidth = headCell.maxWidth;

        const cellProps = {
          data: row[headCell.field],
          currentData: row,
          currentHeader: headCell.field,
          headCells: headCells,
        };

        return (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
            style={style}
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell(cellProps)
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter(cellProps)
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <TableCell
              key={`${headCell.field}_${index}`}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
                style={{ fontWeight: "bold" }}
              >
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

let filters = {};

export default function ShipperInfoManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("COMP_CD");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "COMP_CD",
      dir: "asc",
    },
  ]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = n;
        requestUserSelect();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_DATA_UPDATE,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SHIPPER_MANAGE_DATA_UPDATE,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_SHIPPER_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_search_shipper/JS_gbts_comp_shipper_list.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (property === "COMP_GB") {
      property = "COMP_GB_SUB2";
    }
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const handleCompSelect = (data) => {
    if (!data || !data.hasOwnProperty("COMP_CD")) return;
    socket.fireLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_SELECT_COMP,
      _mClassName,
      data,
      () => {}
    );
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = {
    handleCompSelect: handleCompSelect,
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProps} />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProps} />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProps} />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={index} row={row} index={index} {...rowProps} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}
