/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

import ScheduleAirBookingDetail from "./ScheduleAirBookingDetail";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const headCells = [
  {
    field: "sba_IDX_MAIN",
    headerName: "스케쥴 NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sba_IDX",
    headerName: "예약 NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "sba_USER_NM",
    headerName: "예약자",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <p>
            [{params.currentData.sba_COMP_NM}]{params.data}
          </p>
        </span>
      );
    },
  },
  {
    field: "sba_FWD_NM",
    headerName: "항공사",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <p>{params.data}</p>
        </span>
      );
    },
  },
  {
    field: "sba_PROVIDER_NM",
    headerName: "공급사",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <p>{params.data}</p>
        </span>
      );
    },
  },
  {
    field: "sba_FLIGHT_DATE",
    headerName: "운송일자",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="font-weight-bold text-danger">
          <p>
            {util.getDateToFormat(params.data, "YYYY-MM-DD")}(
            {util.handleGetDate(params.data)})
          </p>
        </span>
      );
    },
  },
  {
    field: "sba_POL_NAME",
    headerName: "출발지",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="font-weight-bold">
          <p>{params.data}</p>
        </span>
      );
    },
  },
  {
    field: "sba_POD_NAME",
    headerName: "도착지",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="font-weight-bold">
          <p>{params.data}</p>
        </span>
      );
    },
  },
  {
    field: "sba_CREATEDATE",
    headerName: "요청일자",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "sba_STATUS",
    headerName: "처리상태",
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let status = "요청";
      let color = "text-secondary";
      if (params.data === "N") {
        status = "요청";
        color = "text-secondary";
      }
      if (params.data === "Y") {
        status = "확정";
        color = "text-primary";
      }
      if (params.data === "C") {
        status = "취소";
        color = "text-danger";
      }

      return <span className={`font-weight-bold ${color}`}>{status}</span>;
    },
  },
];

const _mClassName = "ScheduleAirBookingManageTable";

export default function ScheduleAirBookingManageTable({
  isServerPaging = true,
  isServerSort = true,
  loginInfo,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("sba_IDX");

  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "sba_IDX",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_BOOKING_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SCHEDULE_AIR_BOOKING_MANAGE_DATA_UPDATE,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_BOOKING_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SCHEDULE_AIR_BOOKING_MANAGE_DATA_UPDATE,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    const msgID = util.makeUUIDv4();

    const socketMsg = {
      file: "/schedule_booking_air/JS_schedule_booking_air_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],

      ...filters,
    };

    if (loginInfo.isConsole) {
      socketMsg.sba_PROVIDER_CODE = loginInfo.comp_cd;
    }
    if (loginInfo.isForwarder) {
      socketMsg.sba_COMP_CD = loginInfo.comp_cd;
    }

    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = handleScheduleData(newData["returnData"]);
          setServerData(dataList);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const handleScheduleData = (dataList) => {
    let tempList = [];
    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i];
      for (let key in data) {
        if (key.startsWith("sba_INFO")) {
          if (data[key]) {
            let splitData = data[key].split("|");
            let sba_INFO = {
              FLIGHT_NAME: "",
              AIR_FREIGHT_NO: "",
              AIR_FREIGHT_MEMO: "",
              DEPARTURE_TIME: "",
              AIRRIVE_TIME: "",
            };

            sba_INFO["FLIGHT_NAME"] = splitData[0];

            if (data.sba_VESSEL) {
              sba_INFO["AIR_FREIGHT_NO"] = data.sba_VESSEL;
            }

            if (splitData.length === 3) {
              if (splitData[1].length === 4) {
                sba_INFO["AIR_FREIGHT_NO"] = splitData[1];
              } else {
                sba_INFO["AIR_FREIGHT_MEMO"] = splitData[1];
              }
            }
            // 항공 데이터 값의 개수가 3개 보다 많은 경우 처리

            let lastIDX = splitData.length - 1;
            let flightTime = splitData[lastIDX];
            let flightTimeSplit = undefined;
            if (flightTime.indexOf("+")) {
              flightTime = flightTime.split("+")[0];
            }

            flightTimeSplit = flightTime.split("/");

            let depTime = flightTimeSplit[0].split("");
            let airTime = flightTimeSplit[1].split("");
            sba_INFO[
              "DEPARTURE_TIME"
            ] = `${depTime[0]}${depTime[1]}:${depTime[2]}${depTime[3]}`;
            sba_INFO[
              "AIRRIVE_TIME"
            ] = `${airTime[0]}${airTime[1]}:${airTime[2]}${airTime[3]}`;

            data[key] = sba_INFO;
          } else {
            data[key] = null;
          }
        }
      }

      let temp = { ...data };
      tempList.push(temp);
    }

    return tempList;
  };

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const requestUserUpdate = (updateData) => {
    codeList.Modal.current.confirm(
      "해당 스케쥴의 정보가 현재 입력값으로 변경 저장됩니다.",
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/schedule_booking_air/JS_schedule_booking_air_update.php",
            msgID: msgID,
            data: {},
            ...updateData,
          };
          setLoading(true);
          socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert(
                  "항공스케쥴 예약정보가 정상 수정되었습니다.",
                  () => {
                    requestUserSelect();
                  }
                );
              } else {
                codeList.Modal.current.alert(
                  "요청처리중 오류가 발생하였습니다 다시 시도해주세요.",
                  () => {}
                );
              }
            }
          });
        }
      }
    );
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProp = {
    requestUserUpdate: requestUserUpdate,
    loginInfo: loginInfo,
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row key={index} row={row} index={index} {...rowProp} />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={index} row={row} index={index} {...rowProp} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, requestUserUpdate, loginInfo } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={() => setOpen(!open)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => {
          const cellProps = {
            data: row[headCell.field],
            currentData: row,
            currentHeader: headCell.field,
            headCells: headCells,
            requestUserUpdate: requestUserUpdate,
            loginInfo: loginInfo,
          };
          return (
            <TableCell
              key={headCell.field}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.hasOwnProperty("renderCell")
                ? headCell.renderCell(cellProps)
                : headCell.hasOwnProperty("valueGetter")
                ? headCell.valueGetter(cellProps)
                : row[headCell.field]}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={headCells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                <ScheduleAirBookingDetail
                  rowData={row}
                  requestUserUpdate={requestUserUpdate}
                  loginInfo={loginInfo}
                />
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
