import React from "react";
import SectionTitle from "../../SectionTitle";

const Reg_purchase_per = /[0-9]{1,2}?\.[0-9]{1,2} %/gi;

export default function CorpRiskDiagnosis2({ compInfo, isExistData }) {
  let mappedData = {
    SALES_ANALYSIS_LEVEL: "-",
    SALES_ANALYSIS_LEVEL_PER: "-",
    SALES_ANALYSIS_LEVEL_KO: "-",
    SALES_ANALYSIS_DESC: "- 상세진단 결과",
    PURCHASE_ANALYSIS_LEVEL: "-",
    PURCHASE_ANALYSIS_LEVEL_PER: "-",
    PURCHASE_ANALYSIS_LEVEL_KO: "-",
    PURCHASE_ANALYSIS_DESC: "- 상세진단 결과",
  };

  if (isExistData && compInfo !== null) {
    if (compInfo["3000201"].length > 0) {
      for (let item of compInfo["3000201"]) {
        switch (item.item_nm) {
          case "판매위험 등급":
            mappedData["SALES_ANALYSIS_LEVEL"] = "INDEX " + item.item_val;
            break;
          case "판매위험 등급수준":
            mappedData["SALES_ANALYSIS_LEVEL_KO"] = item.item_val;
            break;
        }
      }
    }
    if (compInfo["3000204"].length > 0) {
      mappedData["SALES_ANALYSIS_DESC"] = compInfo[
        "3000204"
      ][0].item_val.replaceAll("다. ", "다.\n");
      mappedData["SALES_ANALYSIS_LEVEL_PER"] =
        compInfo["3000204"][0].item_val.match(Reg_purchase_per)[0];
    }
    if (compInfo["3000301"].length > 0) {
      for (let item of compInfo["3000301"]) {
        switch (item.item_nm) {
          case "구매위험 등급":
            mappedData["PURCHASE_ANALYSIS_LEVEL"] = "INDEX " + item.item_val;
            break;
          case "구매위험 등급수준":
            mappedData["PURCHASE_ANALYSIS_LEVEL_KO"] = item.item_val;
            break;
        }
      }
    }
    if (compInfo["3000304"].length > 0) {
      mappedData["PURCHASE_ANALYSIS_DESC"] = compInfo[
        "3000304"
      ][0].item_val.replaceAll("다.  ", "다.\n");
      mappedData["PURCHASE_ANALYSIS_LEVEL_PER"] =
        compInfo["3000304"][0].item_val.match(Reg_purchase_per)[0];
    }
  }

  return (
    <div className="mb-5">
      <SectionTitle title={"개별 위험진단"} />

      <div className="container-fluid m-0 mb-3 p-0">
        <div className="row m-0 mb-2 p-0">
          <div className="col-auto p-2 bg-freiscop-10">
            <p className="h6 m-0 text-center font-weight-bold">
              판매위험지표 <i className="fa-solid fa-circle-exclamation" />
            </p>
          </div>
          <div className="col-auto p-2">
            <span className="h6 m-0 text-freiscop ">
              {mappedData["SALES_ANALYSIS_LEVEL"]}{" "}
            </span>
            <span className="text-secondary ">
              ({mappedData["SALES_ANALYSIS_LEVEL_KO"]} / 동종업계 상위{" "}
              {mappedData["SALES_ANALYSIS_LEVEL_PER"]})
            </span>
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col-12 col-xl-6 m-0 p-0">
            <p className="border border-freiscop text-freiscop p-3 font-weight-bold ">
              {mappedData["SALES_ANALYSIS_DESC"]}
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 mb-3 p-0">
        <div className="row m-0 mb-2 p-0">
          <div className="col-auto p-2 bg-freiscop-10">
            <p className="h6 m-0 text-center font-weight-bold">
              구매위험지표 <i className="fa-solid fa-circle-exclamation" />
            </p>
          </div>
          <div className="col-auto p-2 ">
            <span className="h6 m-0 text-freiscop ">
              {mappedData["PURCHASE_ANALYSIS_LEVEL"]}
            </span>
            <span className="text-secondary">
              ({mappedData["PURCHASE_ANALYSIS_LEVEL_KO"]} / 동종업계 상위{" "}
              {mappedData["PURCHASE_ANALYSIS_LEVEL_PER"]})
            </span>
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="col-12 col-xl-6 m-0 p-0">
            <p className="border border-freiscop text-freiscop p-3 font-weight-bold ">
              {mappedData["PURCHASE_ANALYSIS_DESC"]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
