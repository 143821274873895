import React, { useEffect, useState } from "react";
import SectionTitle from "../../SectionTitle";
import { ResponsiveLine } from "@nivo/line";

let Reg_Exp = /[0-9]{1,2}?\.[0-9]{1,2} %/gi;
export default React.memo(CorpComprehensiveAnalysisByYear);
function CorpComprehensiveAnalysisByYear(props) {
  const { compInfo, isExistData, isPrint } = props;

  let analysis = null;

  let mappedData = {
    TOTAL_ANALYSIS_LEVEL: "-",
    TOTAL_ANALYSIS_LEVEL_PER: "-",
    TOTAL_ANALYSIS_LEVEL_KO: "-",
    TOTAL_ANALYSIS_DESC: "- BASA 등급 최종진단 결과",
    TOTAL_ANALYSIS_BY_YEAR: [],
  };
  if (isExistData && compInfo !== null) {
    if (compInfo["3000003"].length > 0) {
      let item_val = compInfo["3000003"][0].item_val;
      item_val = item_val.replaceAll("다. ", "다.\n");
      mappedData["TOTAL_ANALYSIS_DESC"] = item_val;
    }

    if (compInfo["3000007"].length > 0) {
      mappedData["TOTAL_ANALYSIS_BY_YEAR"] = compInfo["3000007"];
    }

    for (let item of compInfo["3000001"]) {
      switch (item.item_nm) {
        case "BASA 등급":
          mappedData["TOTAL_ANALYSIS_LEVEL"] = "E" + item.item_val;
          break;
        case "BASA 등급수준":
          mappedData["TOTAL_ANALYSIS_LEVEL_KO"] = item.item_val;
          break;
      }
    }
    mappedData["TOTAL_ANALYSIS_LEVEL_PER"] =
      mappedData["TOTAL_ANALYSIS_DESC"].match(Reg_Exp);
    let analysis_data = [];
    for (let i = 0; i < mappedData["TOTAL_ANALYSIS_BY_YEAR"].length; i++) {
      const data = mappedData["TOTAL_ANALYSIS_BY_YEAR"][i];
      let level = Number(data.item_val.split("")[1]);
      let date = "";
      let item = data.item_nm.split("");
      date = `${item.splice(0, 4).join("")}년 ${item.splice(0, 2).join("")}월`;
      analysis_data.push({
        x: date,
        y: level,
      });
    }
    analysis = [
      {
        id: "종합 기업분석",
        color: "#ff0000",
        data: analysis_data,
      },
    ];
  }

  return (
    <div className="mb-5 ">
      <SectionTitle title={"종합 기업분석(연별)"} />
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 mb-3 p-0">
          <div className="col-auto p-2 bg-freiscop-10">
            <p className="h6 m-0 text-center font-weight-bold">종합분석</p>
          </div>
          <div className="col-auto p-2">
            <span className="h6 m-0 text-freiscop ">
              {mappedData.TOTAL_ANALYSIS_LEVEL}{" "}
            </span>
            <span className="text-secondary">
              ({mappedData["TOTAL_ANALYSIS_LEVEL_KO"]} / 동종업계 상위{" "}
              {mappedData["TOTAL_ANALYSIS_LEVEL_PER"]})
            </span>
          </div>
        </div>

        <div className="row m-0 p-0">
          <div className="col-12 m-0 p-0">
            <div
              className="d-flex flex-row justify-content-center align-items-center border-top border-bottom border-freiscop p-3 mb-3"
              style={{ height: 250, maxWidth: "100vw" }}
            >
              {analysis !== null ? (
                <MyResponsiveLine data={analysis} isPrint={isPrint} />
              ) : (
                <p className="h6 text-secondary text-center">
                  종합 기업분석에 대한 월별 추이가 존재하지 않습니다.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MyResponsiveLine = ({ data, title, isPrint }) => {
  const colors = ["#125186"];
  const propsWidth = isPrint ? { width: 1000 } : {};

  return (
    <ResponsiveLine
      data={data}
      colors={colors}
      margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 1,
        max: 10,
        stacked: true,
        reverse: true,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: title,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      enablePointLabel={true}
      pointLabel={(data) => `E${data.y}`}
      {...propsWidth}
      tooltip={({ point }) => {
        let tooltipData = { ...point.data };
        return (
          <div
            style={{
              padding: 12,
              color: "white",
              background: "#222222",
            }}
          >
            <strong>
              {tooltipData.xFormatted} : E{tooltipData.y}
            </strong>
          </div>
        );
      }}
    />
  );
};
