/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import Post from "./Post";
import PostSideNav from "./PostSideNav";
export default function PostContent() {
  const [postMenu, setPostMenu] = useState("nt");
  const postContent = useRef();
  const param = useParams();
  const location = useLocation();
  const postMenuList = [
    {
      title: "공지사항",
      id: "nt",
      categorize: [
        { key: "NTA01", desc: "새소식" },
        { key: "NTA02", desc: "오픈예정" },
        { key: "NTA03", desc: "시스템공지" },
        { key: "NTA04", desc: "추천선사 및 프로모션" },
        { key: "NTA05", desc: "입점업체현황" },
      ],
    },
    {
      title: "선사•항공사 공지사항",
      id: "ls",
    },
    {
      title: "Q&A 게시판",
      id: "qa",
      categorize: [
        { key: "FJA0", desc: "해상(항공)스케쥴 및 견적문의" },
        { key: "FJA1", desc: "포워더 등 물류기업 입점 안내" },
        { key: "FJA2", desc: "서비스 제휴/협업/투자" },
        { key: "FJA3", desc: "통관 및 FTA문의" },
        { key: "FJA4", desc: "적하보험 및 기타문의" },
      ],
    },
    // {
    //   title: "서식자료실",
    //   id: "bb",
    //   categorize: [
    //     { key: "BBA01", desc: "물류서식" },
    //     { key: "BBA02", desc: "통관서식" },
    //     { key: "BBA03", desc: "무역서식" },
    //     { key: "BBA04", desc: "기타서식" },
    //   ],
    // },
    {
      title: "추천선사 및 프로모션",
      id: "pr",
    },
    // {
    //   title: "상품(견적) 요청 게시판",
    //   id: "prq",
    // },
  ];

  useEffect(() => {
    postMenuList.forEach((menu) => {
      if (menu.id === param.post_type) {
        ChangePostType(null, menu);
      }
    });
  }, [location.pathname]);

  function ChangePostType(e, params) {
    setPostMenu(params);
    postContent.current.changePostType(e, params);
  }

  return (
    <>
      <div id="position">
        <div className="container">
          <ul>
            <li>
              <a href="#">자료실</a>
            </li>
            <li>{postMenu.title}</li>
          </ul>
        </div>
      </div>
      <div className="container margin_60">
        <div className="row">
          <Post ref={postContent} />
          <PostSideNav postMenuList={postMenuList} />
        </div>
      </div>
    </>
  );
}
