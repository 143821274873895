import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import Utilities from "../../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ScheduleAirFreightCostModal";

const FSC_COST = {
  S: { type: "C/WT", minAmount: 0, cost: 570 },
  M: { type: "C/WT", minAmount: 0, cost: 600 },
  L: { type: "C/WT", minAmount: 0, cost: 640 },
};

const SCC_COST = {
  ID: { type: "C/WT", minAmount: 6500, cost: 130 },
  DE: { type: "C/WT", minAmount: 6500, cost: 130 },
  US: { type: "C/WT", minAmount: 6500, cost: 130 },
};

let costData = null;
let today = new Date();

export default function ScheduleAirFreightCostModal({
  loginInfo,
  modal_data,
  handleCostModalOpen,
}) {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const isBooking = +today < +modal_data.subData.realDate;
  const navigate = useNavigate();

  useEffect(() => {
    requestUserSelect();
    return () => {
      costData = null;
    };
  }, []);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/schedule_air/JS_schedule_price_air_select.php",
      msgID: msgID,
      data: {},
      sm_POL_CODE: modal_data.mainData.sm_POL_CODE,
      sm_POD_CODE: modal_data.mainData.sm_POD_CODE,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          costData = {
            sm_IDX: modal_data.mainData.sm_IDX,
            sm_FWD_NO: modal_data.mainData.sm_FWD_NO,
            sm_FWD_NM: modal_data.mainData.sm_FWD_NM,
            sm_PROVIDER_CODE: modal_data.mainData.sm_PROVIDER_CODE,
            sm_PROVIDER_ID: modal_data.mainData.sm_PROVIDER_ID,
            sm_PROVIDER_SALES_ID: modal_data.mainData.sm_PROVIDER_SALES_ID,
            sm_DEPARTURE_DATE: modal_data.mainData.sm_DEPARTURE_DATE,
            sm_VESSEL: modal_data.mainData.sm_VESSEL,
            sm_CARGO_YN: modal_data.mainData.sm_CARGO_YN,
            sm_POL_CODE: modal_data.mainData.sm_POL_CODE,
            sm_POD_CODE: modal_data.mainData.sm_POD_CODE,
            sm_ORIGIN: modal_data.mainData.sm_ORIGIN,
            sm_DEST: modal_data.mainData.sm_DEST,
            sm_EXP_IMP: modal_data.mainData.sm_EXP_IMP,
            ...modal_data.subData,
            ...newData["returnData"][0],
          };
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const moveToReserveAIR = () => {
    if (!loginInfo) {
      codeList.Modal.current.alert("로그인 후 이용가능한 서비스입니다.");
      return;
    }
    if (!(loginInfo.COMP_BG !== "B" || loginInfo.COMP_BG !== "Z")) {
      codeList.Modal.current.alert("포워더 회원만 이용 가능한 서비스입니다.");
      return;
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_MOVE_RESERVE_AIR,
      _mClassName,
      costData,
      () => {}
    );
    navigate(`/ReserveAIR/${costData.sm_IDX}`);
  };

  return (
    <div className="modal_background">
      <div className="modal_body bg-white rounded" style={{ minWidth: 320 }}>
        <div className="container-fluid m-0 p-0">
          <div className="row m-0 p-3 border-bottom">
            <div className="col-10 m-0 p-0">
              <h5 className="m-0 p-0 font-weight-bold">운임 안내</h5>
            </div>
            <div className="col-2 m-0 p-0 text-right">
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleCostModalOpen()}
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
          </div>
          <div
            className="overflow-auto p-3 px-3"
            style={{ maxHeight: "calc(100vh - 200px)" }}
          >
            {costData !== null && (
              <>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 mb-1 p-0">
                    <p className="font-weight-bold">※ 운송정보 요약</p>
                  </div>
                  <div className="col-12 m-0 mb-2 p-0">
                    <div className="d-flex flex-column flex-xl-row flex-wrap border rounded-top-left rounded-top-right overflow-hidden">
                      <div className="flex-grow-1 d-flex flex-column flex-md-row">
                        <div className="border-top border-right flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            항공사
                          </div>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <div
                              className="d-flex flex-row justify-content-center align-items-center"
                              style={{
                                minHeight: 30,
                                width: 80,
                                background: `url(${modal_data.mainData.AL_LOGO}) 50% 50% / contain no-repeat`,
                              }}
                            >
                              {!modal_data.mainData.AL_LOGO && (
                                <span>LOGO</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="border-top border-right flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            공급사
                          </div>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <div
                              className="d-flex flex-row justify-content-center align-items-center"
                              style={{
                                minHeight: 30,
                                width: 80,
                                background: `url(${modal_data.mainData.SL_LOGO}) 50% 50% / contain no-repeat`,
                              }}
                            >
                              {!modal_data.mainData.SL_LOGO && (
                                <span>LOGO</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="border-top border-right flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            항명
                          </div>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <p>{costData.FLIGHT_NAME}</p>
                            <p>{costData.AIR_FREIGHT_NO}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column flex-md-row">
                        <div className="border-top border-right flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            출발시간
                          </div>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <p className="font-weight-bold">
                              {costData.sm_ORIGIN}
                            </p>
                            <p className="font-weight-bold text-danger">
                              {costData.sm_POL_CODE} {costData.DEPARTURE_TIME}
                            </p>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div
                            className="p-2 bg-info text-white font-weight-bold text-center border-bottom"
                            style={{ minWidth: 100 }}
                          >
                            도착시간
                          </div>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ height: 55 }}
                          >
                            <p className="font-weight-bold">
                              {costData.sm_DEST}
                            </p>
                            <p className="font-weight-bold text-danger">
                              {costData.sm_POD_CODE} {costData.AIRRIVE_TIME}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 m-0 mb-1 p-0">
                    <p className="font-weight-bold">※ 기본운임정보</p>
                  </div>
                  <div className="col-12 m-0 mb-2 p-0">
                    <div className="d-flex flex-column flex-sm-row flex-wrap border-bottom border-left rounded overflow-hidden">
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          적용타입
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_TYPE || "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          통화
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_MONEY_KIND || "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          최소금액
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_MIN
                            ? util.addCommas(costData.SPA_COST_MIN)
                            : "-"}{" "}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          - 45K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_MINUS_45K
                            ? util.addCommas(costData.SPA_COST_MINUS_45K)
                            : "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          + 45K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_PLUS_45K
                            ? util.addCommas(costData.SPA_COST_PLUS_45K)
                            : "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          + 100K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_100K
                            ? util.addCommas(costData.SPA_COST_100K)
                            : "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          + 300K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_300K
                            ? util.addCommas(costData.SPA_COST_300K)
                            : "-"}{" "}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          + 500K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_500K
                            ? util.addCommas(costData.SPA_COST_500K)
                            : "-"}
                        </div>
                      </div>
                      <div className="border-top border-right flex-grow-1">
                        <div className="p-2 bg-light font-weight-bold text-center border-bottom">
                          + 1000K
                        </div>
                        <div className="p-2 d-flex flex-row justify-content-center align-items-center">
                          {costData.SPA_COST_1000K
                            ? util.addCommas(costData.SPA_COST_1000K)
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-12 m-0 p-0">
                    <p className="font-weight-bold">※ 기타운임정보</p>
                  </div>
                  <div className="col-12 m-0 mb-2 p-0 border">
                    <div className="row m-0 p-0 text-center bg-light font-weight-bold">
                      <div className="col-2 m-0 p-2 border-bottom border-right">
                        운임
                      </div>
                      <div className="col-4 m-0 p-2 border-bottom border-right">
                        운임명
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom border-right">
                        적용타입
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom border-right">
                        Min Amount
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom">금액</div>
                    </div>
                    <div className="row m-0 p-0 text-center">
                      <div className="col-2 m-0 p-2 bg-light border-bottom border-right">
                        CGC
                      </div>
                      <div className="col-4 m-0 p-2 bg-light border-bottom border-right">
                        CGC CHARGE
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom text-right border-right">
                        B/L
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom text-right border-right">
                        0
                      </div>
                      <div className="col-2 m-0 p-2 border-bottom text-right">
                        3,000
                      </div>
                    </div>
                    <div className="row m-0 p-0 text-center">
                      <div className="col-2 m-0 p-2 bg-light border-right">
                        FSC
                      </div>
                      <div className="col-4 m-0 p-2 bg-light border-right">
                        FUEL SURCHARGE
                      </div>

                      <div className="col-2 m-0 p-2 text-right border-right">
                        {costData.SPA_DISTANCE
                          ? FSC_COST[costData.SPA_DISTANCE].type
                          : "-"}
                      </div>
                      <div className="col-2 m-0 p-2 text-right border-right">
                        {costData.SPA_DISTANCE
                          ? util.addCommas(
                              FSC_COST[costData.SPA_DISTANCE].minAmount
                            )
                          : "-"}
                      </div>
                      <div className="col-2 m-0 p-2 text-right">
                        {costData.SPA_DISTANCE
                          ? util.addCommas(FSC_COST[costData.SPA_DISTANCE].cost)
                          : "-"}
                      </div>
                    </div>
                    {SCC_COST.hasOwnProperty(costData.SPA_COUNTRY) && (
                      <div className="row m-0 p-0 border-top text-center">
                        <div className="col-2 m-0 p-2 bg-light border-right">
                          SCC
                        </div>
                        <div className="col-4 m-0 p-2 bg-light border-right">
                          SECURITY CHARGE
                        </div>
                        <div className="col-2 m-0 p-2 text-right border-right">
                          {SCC_COST[costData.SPA_COUNTRY].type}
                        </div>
                        <div className="col-2 m-0 p-2 text-right border-right">
                          {util.addCommas(
                            SCC_COST[costData.SPA_COUNTRY].minAmount
                          )}
                        </div>
                        <div className="col-2 m-0 p-2 text-right">
                          {util.addCommas(SCC_COST[costData.SPA_COUNTRY].cost)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row m-0 p-0 mb-2">
                  <div className="col-auto flex-fill m-0 p-0">
                    <p className="font-weight-bold">· 예약 담당자 정보</p>
                    <p className="text-secondary ml-2 mb-2">
                      김호승 본부장(<a href="tel:032-751-9870">032-751-9870</a>,{" "}
                      <a href="mailto:hskim@bstc.kr">hskim@bstc.kr</a>)
                    </p>
                    <p className="font-weight-bold">· 공급사 담당자 정보</p>

                    {modal_data.mainData.sm_PROVIDER_CODE === "B0036" ? (
                      <p className="text-secondary ml-2">
                        김미경 과장(
                        <a href="mailto:scl@sclogistics.co.kr">
                          scl@sclogistics.co.kr
                        </a>
                        )
                      </p>
                    ) : (
                      <p className="text-secondary ml-2 mb-2">
                        김호승 본부장(
                        <a href="tel:032-751-9870">032-751-9870</a>,{" "}
                        <a href="mailto:hskim@bstc.kr">hskim@bstc.kr</a>)
                      </p>
                    )}
                  </div>
                  <div className="col-auto m-0 p-0">
                    <p className="font-weight-bold">
                      · Booking 가능여부 :{" "}
                      <span
                        className={isBooking ? "text-primary" : "text-danger"}
                      >
                        {isBooking ? "가능" : "불가"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-auto flex-fill m-0 p-0">
                    <p className="font-weight-bold">· 반입지 정보</p>
                    {modal_data.mainData.sm_FWD_NO === "ASIANA" ? (
                      <>
                        <p className="text-secondary ml-2">(주)더블유코리아</p>
                        <p className="text-secondary ml-2">
                          인천시 중구 공항동로 296번길 98-30 범한판토스 내 27-28
                          GATE
                        </p>
                        <p className="text-secondary ml-2">032-744-7404</p>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                <div className="row m-0 p-0">
                  <div className="col-12 m-0 mb-2 p-0 text-right">
                    <button
                      disabled={!isBooking}
                      onClick={() => {
                        moveToReserveAIR();
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      예약하기
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
