import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import ExportCompManageEdit from "./ExportCompManageEdit";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "ExportCompManageTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const headCells = [
  {
    field: "EXC_IDX",
    headerName: "순번",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "EXC_COMP_NM",
    headerName: "상호명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p>{params.data}</p>;
    },
  },
  {
    field: "EXC_COMP_CEO_NM",
    headerName: "대표자명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "EXC_COMP_NUM",
    headerName: "사업자등록번호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "EXC_LOCATION",
    headerName: "지역",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "EXC_YEAR",
    headerName: "인증연도",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },

  {
    field: "EXC_CERTIFICATE_NUM",
    headerName: "인증서번호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
  },
  {
    field: "EXC_CERTIFICATE_STARTDATE",
    headerName: "인증시작",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</p>;
    },
  },
  {
    field: "EXC_CERTIFICATE_ENDDATE",
    headerName: "인증종료",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</p>;
    },
  },
  {
    field: "EXC_FUNC",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <div>
          <button
            type="button"
            key={"btn-" + params.currentData.EXC_IDX}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#ExportCompManageEdit"
            onClick={(e) => params.openEdit(e, params)}
            aria-label={"수정"}
          >
            <i className="fas fa-pen " />
          </button>
        </div>
      );
    },
  },
];
let filters = {};

function Row(props) {
  const { row, openEdit } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      {headCells.map((headCell) => {
        const rowProp = {
          data: row[headCell.field],
          currentData: row,
          currentHeader: headCell.field,
          headCells: headCells,
          openEdit: openEdit,
        };
        return (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell(rowProp)
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter(rowProp)
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ExportCompManageTable(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("EXC_CREATE_DATE");
  const [sortModel, setSortModel] = useState([
    {
      field: "EXC_CREATE_DATE",
      dir: "desc",
    },
  ]);
  const [dense, setDense] = useState(false);
  const [serverData, setServerData] = useState([]);
  const editModal = useRef();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      () => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        filters = n;
        setTotalCount(1);
        setPage(0);
        setTotalCount(100);
        setRowsPerPage(10);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);
  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk_search_shipper/JS_export_company_select.php",
      msgID: msgID,
      data: [],
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
          setLoading(false);
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = { openEdit: openEdit };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {data.map((row, index) => {
            return (
              <Row
                key={row.EXC_COMP_NUM}
                row={row}
                index={index}
                {...rowProps}
              />
            );
          })}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <ExportCompManageEdit
        ref={editModal}
        requestUserSelect={requestUserSelect}
      />
    </TableContainer>
  );
}
