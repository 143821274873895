/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import CommonNav from "../main/CommonNav";
import Footer from "../main/Footer";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import $ from "jquery";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const product_type = {
  A: "수출(해상)",
  B: "수입(해상)",
  C: "수출(항공)",
  D: "수입(항공)",
  E: "벌크/특수화물",
};
function Reserve(props) {
  let param = useParams();
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState({
    pmArrivalCity: "CDBD01",
    pmCreator: "",
    pmCreatorSite: "",
    pmDepartureCity: "CDAA01",
    pmDepartureDate: "2021-12-12|2021-12-15|2021-12-21|2021-12-30|2022-01-04",
    pmDepartureDayWeek: "",
    pmDepartureType: "D",
    pmForwarderDesc: "정직한 동서로지스틱스!",
    pmForwarderHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmForwarderImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/forwarderImage/20211212172437_ds.png",
    pmForwarderName: "동서로지스틱스",
    pmIDX: "",
    pmKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
    pmOptionInclude: "- 운임, 부대비용을 포함한 상기 고지된 물류비용",
    pmOptionInfo:
      "- 수출통관수수료\n- 국내컨테이너운송료\n\n등 비용은 업무 진행시 제휴사(관세사)청구금액 및 운송구간에 따른 운임에 따릅니다. 해당 비용에 대하여 궁금하시면 문의 남겨 주세요.",
    pmOptionNotInclude:
      "- 통관수수료, 적하보험료, 내륙운송료, DOOR작업건의 경우 상차비용 등은 불포함 되어 있습니다",
    pmProductExpireEnd: "2022-01-08",
    pmProductExpireStart: "2021-12-12",
    pmProductExpireType: "S",
    pmProductName: "인천-하이퐁 SM상선 FCL 직항",
    pmProductType: "A",
    pmShipCompanyDesc: "빠른 SM상선!",
    pmShipCompanyHome: "https://www.gbts.co.kr:3500/FCLMain/FCL/123",
    pmShipCompanyImg:
      "https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/mall/carrierImage/20211212172323_ship-gfa701dc0d_640.jpg",
    pmShipCompanyName: "SM상선",
    pmShipInfo: "PANCON SUCCESS",
    pmTransferDay: "30",
    pmTransferDirectYN: "Y",
    pmTransferType: "LCL",
    pmUSEYN: "Y",
    pmWarningInfo: "1. 프레이스콥에서 예약을 하시면 담당…..",
    subData: [
      {
        psIDX: 0,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "OCEAN FREIGHT",
        psServiceType: "A",
      },
      {
        psIDX: 1,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "EBS",
        psServiceType: "A",
      },
      {
        psIDX: 2,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 500,
        psServiceCol2: 950,
        psServiceName: "CIS",
        psServiceType: "A",
      },
      {
        psIDX: 3,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 40,
        psServiceCol2: 80,
        psServiceName: "CRS",
        psServiceType: "A",
      },
      {
        psIDX: 4,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 130000,
        psServiceCol2: 180000,
        psServiceName: "THC",
        psServiceType: "E",
      },
      {
        psIDX: 4,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 4200,
        psServiceCol2: 8400,
        psServiceName: "WFG",
        psServiceType: "E",
      },
      {
        psIDX: 5,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 86,
        psServiceCol2: 86,
        psServiceName: "SECURITY CHARGE",
        psServiceType: "E",
      },
      {
        psIDX: 6,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "KRW",
        psServiceCol1: 50000,
        psServiceCol2: 50000,
        psServiceName: "DOCUMENT FEE",
        psServiceType: "E",
      },
      {
        psIDX: 7,
        psKeyMain: "58a3162a-3b8a-49f8-a5d6-acfb78cd10b0",
        psKeySub: "bc893a3",
        psMoneyCD: "USD",
        psServiceCol1: 80,
        psServiceCol2: 80,
        psServiceName: "D/O FEE",
        psServiceType: "E",
      },
    ],
  });
  const [sendReserveData, setSendReserveData] = useState({
    foreignCurrencyCost: 0,
    krwCost: 0,
    psServiceCol1: 0,
    psServiceCol2: 0,
  });

  let loginInfo = null;

  let popupData = useRef();

  const controller = {
    memo: useRef(),
    coName: useRef(),
    userName: useRef(),
    userTel: useRef(),
    userEmail: useRef(),
    customsRequest: useRef(),
    cargoInsurance: useRef(),
  };

  const selectWeekday = [
    { text: "월요일", value: "mon" },
    { text: "화요일", value: "tue" },
    { text: "수요일", value: "wed" },
    { text: "목요일", value: "thu" },
    { text: "금요일", value: "fri" },
    { text: "토요일", value: "sat" },
    { text: "일요일", value: "sun" },
  ];

  let pmDepartureDayWeek = "";
  if (productData.pmDepartureDayWeek !== "") {
    let dayArr = productData.pmDepartureDayWeek.split("|");
    for (let i = 0; i < dayArr.length; i++) {
      dayArr[i] = util.getCodeName(dayArr[i], selectWeekday);
    }
    pmDepartureDayWeek = dayArr.join(", ");
  }
  //예약 요청 수량 및 금액
  useEffect(() => {
    let productReserveData = location.state;
    if (productReserveData) {
      setSendReserveData({ ...productReserveData });
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      "Reserve",
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        "Reserve"
      );
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const pr_idx = param.pr_idx;
    const reserve_type = param.reserve_type;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/product/JS_product_select_detail.php",
      msgID: msgID,
      data: {},
      pmIDX: pr_idx,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length === 0) return;
          let data = newData["returnData"];
          let temp = { ...productData };
          for (let key in temp) {
            if (data[0].hasOwnProperty(key)) temp[key] = data[0][key];
          }
          temp["subData"] = [];
          for (let i = 0; i < data.length; i++) {
            const tempSub = {
              psIDX: data[i]["psIDX"],
              psKeyMain: data[i]["psKeyMain"],
              psKeySub: data[i]["psKeySub"],
              psMoneyCD: data[i]["psMoneyCD"],
              psServiceCol1: data[i]["psServiceCol1"],
              psServiceCol2: data[i]["psServiceCol2"],
              psServiceName: data[i]["psServiceName"],
              psServiceType: data[i]["psServiceType"],
              psCostType: data[i]["psCostType"],
            };
            temp["subData"].push(tempSub);
          }
          setProductData({ ...temp });
        }
      }
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("loginInfo")) {
      loginInfo = JSON.parse(
        util.buddibleDecrypt(sessionStorage.getItem("loginInfo"))
      );
    }

    if (loginInfo !== null) {
      controller.userName.current.value = loginInfo["returnData"][0]["user_nm"];
      controller.coName.current.value = loginInfo["returnData"][0]["comp_nm"];
      if (loginInfo["returnData"][0]["user_id"].indexOf("@") > 0)
        controller.userEmail.current.value =
          loginInfo["returnData"][0]["user_id"];
      if (loginInfo["returnData"][0]["user_tel"] !== "")
        controller.userTel.current.value =
          loginInfo["returnData"][0]["user_tel"];
    }
  }, []);

  const reserveRequest = () => {
    if (checkValidation()) {
      codeList.Modal.current.confirm(
        "예약문의 요청을 진행하시겠습니까?",
        (ret) => {
          if (ret) {
            setLoading(true);
            let updateData = {
              prKeyMain: productData.pmKeyMain,
              prCheckOption: "",
              prQuestion: "",
              prUserCompany: controller.coName.current.value,
              prUserName: controller.userName.current.value,
              prUserTel: controller.userTel.current.value,
              prUserEmail: controller.userEmail.current.value,
              prOrder1: sendReserveData.psServiceCol1, //20FT건수 or CBM용량 or KG
              prOrder2: sendReserveData.psServiceCol2, //40FT건수
              prOrderType: productData.pmProductType, //A 수출(해상)상품,B 수입(해상)상품,C 수출(항공)상품,D 수입(항공)상품,E 벌크/특수화물
              prWeightType: param.reserve_type, //FCL LCL AIR
              prIDXMain: param.pr_idx, //pmIDX
              prUserID: "",
              prUserSite: "",
            };
            updateData.prCheckOption =
              controller.customsRequest.current.checked &&
              controller.cargoInsurance.current.checked
                ? "RI"
                : controller.customsRequest.current.checked
                ? "R"
                : controller.cargoInsurance.current.checked
                ? "I"
                : "";
            updateData.prQuestion = controller.memo.current.value.trim();

            if (sessionStorage.getItem("loginInfo")) {
              loginInfo = JSON.parse(
                util.buddibleDecrypt(sessionStorage.getItem("loginInfo"))
              );
            }
            if (loginInfo !== null) {
              updateData.prUserSite = loginInfo["returnData"][0]["comp_cd"];
              updateData.prUserID = loginInfo["returnData"][0]["user_id"];
            }
            requestDataSave(updateData);
          }
        }
      );
    }
  };

  //필수 값 입력 검사
  const checkValidation = () => {
    if (controller.coName.current.value.trim() === "") {
      codeList.Modal.current.alert("회사명은 필수 입력사항 입니다.", () => {});
      return false;
    }
    if (controller.userName.current.value.trim() === "") {
      codeList.Modal.current.alert("고객명은 필수 입력사항 입니다.", () => {});
      return false;
    }
    if (controller.userTel.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "전화번호는 필수 입력사항 입니다.",
        () => {}
      );
      return false;
    }
    if (controller.userEmail.current.value.trim() === "") {
      codeList.Modal.current.alert("이메일은 필수 입력사항 입니다.", () => {});
      return false;
    }
    return true;
  };

  const requestDataSave = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_product_reserve_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            MsgIDList.EVENT_PRODUCT_DATA_INSERTED,
            "ProductManageEdit",
            newData,
            () => {}
          );
          codeList.Modal.current.alert("정보 저장이 완료되었습니다.", () => {});

          $("#BranchEditModalClose").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "저장 중 오류가 발생하였습니다.",
            () => {}
          );
        }
        return;
      }
      setLoading(false);
      codeList.Modal.current.alert("저장 중 오류가 발생하였습니다.", () => {});
    });
  };

  const reservePrint = () => {
    let pop_title = "예약 및 문의하기";
    window.open(
      "https://www.gbts.co.kr/backside/reserve/print_page.php",
      "예약 및 문의하기",
      "width=1000px,height=800px,scrollbars=yes",
      pop_title
    );
    let frmData = document.frmData;
    let object = {
      ...productData,
      ...sendReserveData,
      //----------------------

      prKeyMain: productData.pmKeyMain,
      prCheckOption: "",
      prQuestion: "",
      prUserCompany: controller.coName.current.value,
      prUserName: controller.userName.current.value,
      prUserTel: controller.userTel.current.value,
      prUserEmail: controller.userEmail.current.value,
      prWeightType: param.reserve_type, //FCL LCL AIR
      prIDXMain: param.pr_idx, //pmIDX
      prUserID: "",
      prUserSite: "",
      msgTransferType: `${product_type[productData.pmProductType]} > ${
        productData.pmTransferType
      }`,
      msgDepartureCity: productData.pmDepartureCity,
      msgArrivalCity: productData.pmArrivalCity,
    };
    object.prCheckOption =
      controller.customsRequest.current.checked &&
      controller.cargoInsurance.current.checked
        ? "RI"
        : controller.customsRequest.current.checked
        ? "R"
        : controller.cargoInsurance.current.checked
        ? "I"
        : "";
    object.prQuestion = controller.memo.current.value.trim();

    popupData.current.value = JSON.stringify(object);
    frmData.target = pop_title;
    frmData.action = "https://www.gbts.co.kr/backside/reserve/print_page.php";
    frmData.submit();
  };

  let citysFull = codeList.codeCountryFullName;
  if (citysFull.length > 0) {
    citysFull.forEach((city) => {
      if (city.City === productData.pmDepartureCity) {
        productData.pmDepartureCity = `${city.CountryName}(${city.CityName})`;
      }
      if (city.City === productData.pmArrivalCity) {
        productData.pmArrivalCity = `${city.CountryName}(${city.CityName})`;
      }
    });
  }

  const foreignView = () => {
    let costList = [...productData.subData];
    let foreign = "";
    for (let i = 0; i < costList.length; i++) {
      if (costList[i].psMoneyCD !== "KRW") {
        foreign = costList[i].psMoneyCD;
        break;
      }
    }
    return foreign;
  };

  return (
    <>
      <CommonNav />
      <main className="white_bg">
        <div
          className="super_container py-5 d-flex flex-column"
          /*style={{ height: "100vh" }}*/
        >
          <div className="container mb-5 " style={{ marginTop: 80 }}>
            {/*상품정보*/}
            <div className="row">
              <div className="col">
                <h3 className="font-weight-bold pb-2">예약 및 문의하기</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-2 border border-right-0 border-bottom-0 d-flex justify-content-center align-items-center m-0 h6 font-weight-bold p-3 bg-light">
                상품정보
              </div>
              <div className="col-10 border border-bottom-0 p-3">
                <div className="d-flex flex-wrap mb-3">
                  <div className="border bg-info border-right-0 font-weight-bold text-white px-3 py-1">
                    상품유효기간
                  </div>
                  <div className="border border-right-0 px-3 py-1">
                    {productData.pmProductExpireStart} -{" "}
                    {productData.pmProductExpireEnd}
                  </div>
                  <div className="border bg-info border-right-0 font-weight-bold text-white px-3 py-1">
                    출항일
                  </div>
                  <div className="border px-3 py-1">
                    {productData.pmDepartureType === "W"
                      ? productData.pmDepartureDayWeek !== ""
                        ? `매주 ${pmDepartureDayWeek}`
                        : productData.pmDepartureDate.split("|").map((date) => {
                            return (
                              <p className="p-0 m-0" key={date}>
                                {date}
                              </p>
                            );
                          })
                      : productData.pmDepartureDate !== ""
                      ? productData.pmDepartureDate.split("|").map((date) => {
                          return (
                            <p className="m-0 p-0" key={date}>
                              {date}
                            </p>
                          );
                        })
                      : ""}
                  </div>
                  <div className="border bg-info border-right-0 font-weight-bold text-white px-3 py-1">
                    TRANSIT TIME
                  </div>
                  <div className="border px-3 py-1">
                    ({productData.pmTransferDirectYN === "Y" ? "직항" : "일반"}){" "}
                    {productData.pmTransferDay}일
                  </div>
                </div>
                <p className="font-weight-bold">
                  운송 종류 : {product_type[productData.pmProductType]} >{" "}
                  {productData.pmTransferType}
                </p>
                <p className="font-weight-bold">
                  운송 구간 : {productData.pmDepartureCity}{" "}
                  <i className="fas fa-long-arrow-alt-right" />{" "}
                  {productData.pmArrivalCity}
                </p>
                <p className="font-weight-bold">
                  선사(항공사) & 선명(편명) : {productData.pmShipCompanyName}
                </p>
                <p className="font-weight-bold">
                  포워더 : {productData.pmForwarderName}
                </p>
              </div>
            </div>
            {/*선적 예정 화물및 운임*/}
            <div className="row">
              <div className="col-2 bg-light border border-right-0 border-bottom-0 d-flex justify-content-center align-items-center m-0 h6 font-weight-bold p-3">
                선적 예정 화물및 운임
              </div>
              {productData.pmTransferType === "FCL" ? (
                <div className="col-10 border border-bottom-0 p-3">
                  <p className="font-weight-bold text-dark">
                    선적물량 : 20FT {sendReserveData.psServiceCol1} EA
                    {sendReserveData.psServiceCol2
                      ? ` / 40FT ${sendReserveData.psServiceCol2} EA`
                      : ""}
                  </p>
                  <p className="font-weight-bold text-dark">
                    {" "}
                    외화운임 합계 USD{" "}
                    {util.addCommas(sendReserveData.foreignCurrencyCost)}
                  </p>
                  <p className="font-weight-bold text-dark">
                    {" "}
                    원화운임 합계 {util.addCommas(sendReserveData.krwCost)} 원
                  </p>
                  <span className="text-danger">
                    ※ (주의) 외화운임과 원화운임을 더한 금액이 예상되는 운임총액
                    입니다.
                  </span>
                </div>
              ) : productData.pmTransferType === "LCL" ? (
                <div className="col-10 border border-bottom-0 p-3">
                  <p className="font-weight-bold text-dark">
                    선적물량 : {sendReserveData.psServiceCol1} CBM
                  </p>

                  <p className="font-weight-bold text-dark">
                    {" "}
                    외화운임 합계 USD{" "}
                    {util.addCommas(sendReserveData.foreignCurrencyCost)}
                  </p>
                  <p className="font-weight-bold text-dark">
                    {" "}
                    원화운임 합계 {util.addCommas(sendReserveData.krwCost)} 원
                  </p>
                  <span className="text-danger">
                    ※ (주의) 외화운임과 원화운임을 더한 금액이 예상되는 운임총액
                    입니다.
                  </span>
                </div>
              ) : (
                <div className="col-10 border border-bottom-0 p-3">
                  {" "}
                  <p className="font-weight-bold text-dark">
                    선적물량 : {sendReserveData.psServiceCol1} KG
                  </p>
                  <p className="font-weight-bold text-dark">
                    {" "}
                    외화운임 합계
                    {foreignView()}{" "}
                    {util.addCommas(sendReserveData.foreignCurrencyCost)}
                  </p>
                  <p className="font-weight-bold text-dark">
                    {" "}
                    원화운임 합계 {util.addCommas(sendReserveData.krwCost)} 원
                  </p>
                  <span className="text-danger">
                    ※ (주의) 외화운임과 원화운임을 더한 금액이 예상되는 운임총액
                    입니다.
                  </span>
                </div>
              )}
            </div>
            {/*통관 및 보험*/}
            <div className="row">
              <div className="col-2 bg-light border border-right-0 border-bottom-0 d-flex justify-content-center align-items-center m-0 h6 font-weight-bold p-3">
                통관 및 보험
              </div>
              <div className="col-10 border border-bottom-0 p-3">
                <input
                  type="checkbox"
                  id="customsClearance"
                  className="mr-1"
                  ref={controller.customsRequest}
                />
                <label htmlFor="customsClearance" className="m-0 mr-3">
                  통관 요청
                </label>
                <input
                  type="checkbox"
                  id="insurance"
                  className="mr-1"
                  ref={controller.cargoInsurance}
                />
                <label htmlFor="insurance" className="m-0 mr-3">
                  적하보험 요청
                </label>
              </div>
            </div>
            {/*추가문의*/}
            <div className="row">
              <div className="col-2 bg-light border border-right-0 border-bottom-0 d-flex justify-content-center align-items-center m-0 h6 font-weight-bold p-3">
                추가문의
              </div>
              <div className="col-10 border border-bottom-0 p-3">
                <textarea
                  className="w-100 h-100 border-0"
                  placeholder="추가문의 및 요청사항을 자유롭게 입력해 주세요."
                  ref={controller.memo}
                />
              </div>
            </div>
            {/*예약자 정보입력*/}
            <div className="row">
              <div className="col-2 bg-light border border-right-0 d-flex justify-content-center align-items-center m-0 h6 font-weight-bold p-3">
                예약자 정보입력
              </div>
              <div className="col-10 border">
                <div className="contact_form_inputs p-3 d-flex flex-md-row flex-column justify-content-between row align-items-between">
                  <div className="col-6 pl-0">
                    <input
                      type="text"
                      className="form-control contact_form_name input_field w-100 mb-3"
                      placeholder="상호명"
                      required="required"
                      data-error="Name is required."
                      ref={controller.coName}
                    />
                  </div>
                  <div className="col-6 pl-0 mb-3">
                    <input
                      type="text"
                      className="form-control contact_form_name input_field w-100"
                      placeholder="담당자"
                      required="required"
                      data-error="Name is required."
                      ref={controller.userName}
                    />
                  </div>
                  <div className="col-6 pl-0">
                    <input
                      type="tel"
                      className="form-control contact_form_name input_field w-100"
                      placeholder="연락처"
                      required="required"
                      data-error="Name is required."
                      ref={controller.userTel}
                    />
                  </div>
                  <div className="col-6 pl-0">
                    <input
                      type="email"
                      className="form-control contact_form_name input_field w-100"
                      placeholder="이메일"
                      required="required"
                      data-error="Name is required."
                      ref={controller.userEmail}
                    />
                  </div>
                </div>
              </div>
              <form
                name="frmData"
                id="frmData"
                method="post"
                style={{ display: "none" }}
              >
                <input
                  type="text"
                  name="popupData"
                  id="popupData"
                  ref={popupData}
                />
              </form>
            </div>
            {/*buttons*/}
            <div className="row py-5">
              <div className="col" />
              <div className="col-6 d-flex justify-content-center">
                <button
                  className="btn_1 mr-3 px-5"
                  onClick={(e) => {
                    e.preventDefault();
                    reserveRequest();
                  }}
                  disabled={loading}
                >
                  제출하기
                </button>
                <a
                  className="btn_1 outline px-5"
                  onClick={(e) => {
                    reservePrint();
                  }}
                >
                  출력하기
                </a>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Reserve;
