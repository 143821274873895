import React, { useEffect, useRef, useState } from "react";
import ExportCompManageTable from "./ExportCompManageTable";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import axios from "axios";
import CommonLoader from "../CommonLoader";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ExportCompManage";

export default function ExportCompManage(props) {
  const { pagesOpen, loginInfo } = props;
  const [isLoad, setIsLoad] = useState(true);
  const htmlID = `customFile_${util.makeUUIDv4Short()}`;
  const file_upload = useRef();
  const controller = {
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
    SEARCH_START_DATE: useRef(),
    SEARCH_END_DATE: useRef(),
  };

  useEffect(() => {
    if (isLoad && pagesOpen[_mClassName]) {
      setIsLoad(false);
    }
  }, [pagesOpen[_mClassName]]);

  const addNewData = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_SHIPPER_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const uploadExcel = (e) => {
    let file = e.target.files[0],
      name = file.name,
      size = file.size,
      type = file.type;

    if (validation(name, size, type)) {
      requestExcelUpload(e.target.files, function (ret, msg) {
        let type = "error";
        if (ret === "Y") {
          codeList.Modal.current.alert(msg, () => {
            file_upload.current.value = "";
            searchFilterChanged();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요.",
            () => {
              console.log(msg);
            }
          );
        }
      });
    }
  };
  //엑셀 대용량 업로드 통신
  const requestExcelUpload = (fileData, callback) => {
    const formData = new FormData();
    formData.append("files", fileData[0]);

    axios
      .post(
        "//www.gbts.co.kr/backside/talk_search_shipper/JS_export_company_excel_import.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.RESULT === "Y") {
          callback(response.data.RESULT, response.data.MSG);
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  };
  // 엑셀파일 업로드 유효성 검사
  const validation = (name, size, type) => {
    let reg = /\.(xls|xlsx)$/i;

    if (!reg.test(name)) {
      codeList.Modal.current.alert(
        "지원하지 않는 형식 입니다.\nExcel 파일만 가능합니다.",
        () => {}
      );
      return false;
    }
    if (size > 1000000 * 10) {
      codeList.Modal.current.alert("용량은 10MB가 이하로 해주세요.", () => {});
      return false;
    }
    return true;
  };

  const searchFilterChanged = () => {
    const filters = {
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
      SEARCH_START_DATE: controller.SEARCH_START_DATE.current.value,
      SEARCH_END_DATE: controller.SEARCH_END_DATE.current.value,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_LOGITALK_SEARCH_SHIPPER_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="content">
        <div className="card">
          <div className="card-header">
            <div className="btn-toolbar d-flex flex-row justify-content-between">
              <div>
                <button
                  className="btn btn-outline-primary mr-2"
                  onClick={addNewData}
                  data-toggle="modal"
                  data-target="#ExportCompManageEdit"
                >
                  신규등록
                </button>

                <input
                  type="file"
                  className="d-none"
                  id={htmlID}
                  ref={file_upload}
                  onChange={(e) => {
                    uploadExcel(e);
                  }}
                />
                <label
                  className="btn btn-secondary mb-2 mb-md-0"
                  htmlFor={htmlID}
                >
                  엑셀 업로드
                </label>
              </div>
              <div className="d-flex flex-row">
                <div className="input-group mr-2">
                  <input
                    type="date"
                    className="form-control"
                    ref={controller.SEARCH_START_DATE}
                    onChange={searchFilterChanged}
                  />
                  <span className="input-group-text rounded-0 border-left-0 border-right-0">
                    -
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    ref={controller.SEARCH_END_DATE}
                    onChange={searchFilterChanged}
                  />
                </div>

                <div className="input-group">
                  <select
                    className="form-control"
                    ref={controller.SEARCH_LEVEL}
                    // onChange={searchFilterChanged}
                  >
                    <option value="">-검색필터-</option>
                    <option value="EXC_COMP_NM">상호명</option>
                    <option value="EXC_COMP_CEO_NM">대표자명</option>
                    <option value="EXC_COMP_NUM">사업자등록번호</option>
                    <option value="EXC_LOCATION">지역</option>
                    <option value="EXC_CERTIFICATE_NUM">인증서번호</option>
                  </select>
                  <input
                    className="form-control"
                    placeholder="검색어를 입력해주세요"
                    ref={controller.SEARCH_WORD}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        searchFilterChanged();
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary rounded-0 rounded-top-right rounded-bottom-right"
                    onClick={searchFilterChanged}
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <ExportCompManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
