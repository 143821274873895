import React, { useRef, useState } from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { Link } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function LogiTalkLandingInquiry() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-light">
      <div className={`${isModalOpen ? "d-block" : "d-none"}`}>
        <InquiryModal handleIsModalOpen={handleIsModalOpen} />
      </div>
      <div className="container-xl p-5 NNS">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="BM-DH h1">
            <p className="text-secondary">물류기업 전문 상담채널 서비스</p>
            <p>
              <span className="bg-gbts text-white px-1">로지톡톡</span> 도입하고
              디지털 스마트 워크를 실현하세요.
            </p>
          </div>
        </div>
        <div
          className="border bg-white shadow p-3 row"
          style={{ borderRadius: 50 }}
        >
          <div className="col-12 col-md-6 border-right d-none d-md-flex flex-column align-items-center py-3">
            <div className="d-flex flex-column justify-content-center align-items-center text-center w-100">
              <span
                className="d-inline-block h4 font-weight-bold pb-2 mb-3 text-center"
                style={{ borderBottom: "5px solid #3b44ac" }}
              >
                자동챗봇 서버
              </span>
              <img
                className="rounded mb-2 w-100"
                style={{ maxWidth: 260 }}
                src="https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/talk/chatRoom/E0001/hskim@bstc.kr/20221117091042_shutterstock_712414756.jpg"
                alt="자동챗봇 서버"
              />
              <p className="h5 text-left" style={{ maxWidth: 260 }}>
                고객이 자주 묻는 질문에 대한 답변을 챗봇에 미리 저장하면{" "}
                <span className="text-gbts">업무량이 대폭 절감</span>됩니다.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex d-md-none flex-column align-items-center py-3">
            <div className="d-flex flex-column justify-content-center align-items-center text-center w-100">
              <span
                className="d-inline-block h4 font-weight-bold pb-2 mb-3 text-center"
                style={{ borderBottom: "5px solid #3b44ac" }}
              >
                자동챗봇 서버
              </span>
              <img
                className="rounded mb-2 w-100"
                style={{ maxWidth: 260 }}
                src="/img/inquiryAutoChat.jpg"
                alt="자동챗봇 서버"
              />
              <p className="h5 text-left" style={{ maxWidth: 260 }}>
                고객이 자주 묻는 질문에 대한 답변을 챗봇에 미리 저장하면{" "}
                <span className="text-gbts">업무량이 대폭 절감</span>됩니다.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column align-items-center py-3">
            <div className="d-flex flex-column justify-content-center align-items-center text-center w-100">
              <span
                className="d-inline-block h4 font-weight-bold pb-2 mb-3 text-center"
                style={{ borderBottom: "5px solid #3b44ac" }}
              >
                담당자 원클릭 연결
              </span>
              <img
                className="rounded mb-2 w-100"
                style={{ maxWidth: 260 }}
                src="/img/inquiryEmploeeMatching.png"
                alt="자동챗봇 서버"
              />
              <p className="h5 text-left" style={{ maxWidth: 260 }}>
                업무 담당자를 원클릭 연결하면 로지톡으로 알림받은{" "}
                <span className="text-gbts">담당자가 실시간 고객 응대</span>할
                수 있습니다
              </p>
            </div>
          </div>
        </div>
        <div className="font-weight-bold d-flex flex-column align-items-center">
          <div className="my-5 w-100">
            <p className="h4 row m-0 p-0 mx-3 mb-2">
              <span className="col-1 m-0 p-0 text-right ">
                <i className="fa-solid fa-check text-gbts" />
              </span>
              <span className="col-11 m-0 p-0 pl-3">
                CS 품질 향상을 위한{" "}
                <span className="font-weight-800">
                  관리자 대시보드(DASH BOARD)
                </span>{" "}
                지원
              </span>
            </p>{" "}
            <p className="h4 row m-0 p-0 mx-3 mb-2">
              <span className="col-1 m-0 p-0 text-right ">
                <i className="fa-solid fa-check text-gbts" />
              </span>
              <span className="col-11 m-0 p-0 pl-3">
                빠르고 쉽게 따라할 수 있는{" "}
                <span className="font-weight-800">자동답변 메뉴 만들기</span>
              </span>
            </p>
            <p className="h4 row m-0 p-0 mx-3 mb-2">
              <span className="col-1 m-0 p-0 text-right ">
                <i className="fa-solid fa-check text-gbts" />
              </span>
              <span className="col-11 m-0 p-0 pl-3">
                디자인, 구성 변경 등 회사{" "}
                <span className="font-weight-800">맞춤형 서비스 제공</span>
              </span>
            </p>
          </div>
          <button
            className="btn_1 text-gbts px-5"
            onClick={() => handleIsModalOpen()}
          >
            <span className="h4 mb-0">
              도입문의
              <i className="fa-solid fa-arrow-right-long ml-3" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

//도입문의 모달
const InquiryModal = (props) => {
  const { handleIsModalOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const $controller = {
    comp_name: useRef(),
    user_name: useRef(),
    e_mail: useRef(),
    phone_num: useRef(),
    memo: useRef(),
    privacyPolicy: useRef(),
  };

  const checkValidate = () => {
    if (
      $controller.comp_name.current &&
      $controller.comp_name.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("회사명은 필수 입력입니다.");
      return true;
    }
    if (
      $controller.user_name.current &&
      $controller.user_name.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이름을 입력해주세요");
      return true;
    }
    if (
      $controller.e_mail.current &&
      $controller.e_mail.current.value.trim() === "" &&
      $controller.phone_num.current &&
      $controller.phone_num.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이메일 또는 휴대폰 번호를 입력해주세요");
      return true;
    }
    if (
      $controller.e_mail.current.value.trim() === "" &&
      $controller.phone_num.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert("이메일 또는 휴대폰 번호를 입력해주세요");
      return true;
    }

    if (!util.validationEmail($controller.e_mail.current.value)) {
      codeList.Modal.current.alert("유효하지 않은 이메일 주소입니다.");
      return true;
    }
    if (!util.validationMobileNumber($controller.phone_num.current.value)) {
      codeList.Modal.current.alert("유효하지 않은 휴대폰 번호입니다.");
      return true;
    }
    if (!$controller.privacyPolicy.current.checked) {
      codeList.Modal.current.alert("개인정보 처리방침 동의를 체크해주세요");
      return true;
    }

    return false;
  };

  const requestInquiry = () => {
    if (checkValidate()) return;
    setIsLoading(true);
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talktalk_inquiry/JS_talktalk_inquiry_mail_send.php",
      msgID: msgID,
      data: {},
      comp_name: $controller.comp_name.current.value,
      user_name: $controller.user_name.current.value,
      e_mail: $controller.e_mail.current.value,
      phone_num: $controller.phone_num.current.value,
      memo: $controller.memo.current.value,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "로지톡톡 도입문의 요청 메일이 정상적으로 발송되었습니다."
          );
        } else {
          codeList.Modal.current.alert("문의 요청 중 오류가 발생하였습니다.");
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="modal_background">
        {isLoading && (
          <div
            className="position-fixed w-100 h-100 d-flex flex-column justify-content-center align-items-center"
            style={{ background: "rgba(0,0,0,0.6)", zIndex: 999 }}
          >
            <span className="h3 text-white">...Loading</span>
          </div>
        )}
        <div
          className="modal_body rounded bg-white overflow-hidden"
          style={{ maxWidth: "90vw" }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center p-3">
            <p className="h4 mb-0 font-weight-bold">
              <span className="text-gbts">로지톡톡</span> 도입문의
            </p>
            <p
              className="h3 mb-0 text-danger cursor_pointer"
              onClick={() => handleIsModalOpen()}
            >
              &times;
            </p>
          </div>
          <div
            className="border-top border-bottom p-3"
            style={{ width: 600, maxWidth: "100%" }}
          >
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">회사명</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.comp_name}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">이름</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.user_name}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">이메일</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.e_mail}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">
                휴대폰번호
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  ref={$controller.phone_num}
                />
              </div>
            </div>
            <div className="mb-2 row">
              <label className="col-form-label col-sm-3 text-end">
                요청사항
              </label>
              <div className="col-sm-9">
                <textarea
                  rows={5}
                  className="form-control"
                  ref={$controller.memo}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between p-3">
            <div className="form-group d-flex flex-row justify-content-start align-items-center text-left mr-2">
              <label className="container_check m-0 p-0 mr-3">
                <input
                  type="checkbox"
                  className="position-static"
                  ref={$controller.privacyPolicy}
                />
                <span className="checkmark" />
              </label>
              <Link
                to="/Terms/privacyPolicy"
                target="_blank"
                className="align-middle"
              >
                개인정보 처리방침 동의
              </Link>
            </div>

            <div>
              <button
                className="btn btn-secondary mr-2"
                onClick={() => handleIsModalOpen()}
              >
                취소
              </button>
              <button
                className="btn btn-dark bg-gbts"
                onClick={() => requestInquiry()}
              >
                문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
