import React from "react";
import TermsNav from "./TermsNav";
import TermsOfUse from "./TermsOfUse";
import MallOperationPolicy from "./MallOperationPolicy";
import TalkOperationPolicy from "./TalkOperationPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclaimer from "./Disclaimer";
import Footer from "../Footer";
import { useParams } from "react-router-dom";

export default function Terms() {
  let params = useParams();

  console.log(params);
  let pages = {
    termsOfUse: {
      id: "termsOfUse",
      component: <TermsOfUse />,
      title: "GBTS 이용약관",
    },
    mallOperationPolicy: {
      id: "mallOperationPolicy",
      component: <MallOperationPolicy />,
      title: "프레이스콥 운영정책",
    },
    talkOperationPolicy: {
      id: "talkOperationPolicy",
      component: <TalkOperationPolicy />,
      title: "로지톡 운영정책",
    },
    privacyPolicy: {
      id: "privacyPolicy",
      component: <PrivacyPolicy />,
      title: "개인정보 처리방침",
    },
    disclaimer: {
      id: "disclaimer",
      component: <Disclaimer />,
      title: "면책공고",
    },
  };

  const createdTerms = () => {
    return pages[params.terms_type].component;
  };
  return (
    <>
      <TermsNav pages={pages} />
      <main className="pb-5" style={{ marginTop: 150 }}>
        <div className="container p-5 bg-white rounded">{createdTerms()}</div>
      </main>
      <Footer />
    </>
  );
}
