import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithPhp from "../../../lib/UploadImageToS3WithPhp";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const times = [
  "00:00",
  "00:10",
  "00:20",
  "00:30",
  "00:40",
  "00:50",
  "01:00",
  "01:10",
  "01:20",
  "01:30",
  "01:40",
  "01:50",
  "02:00",
  "02:10",
  "02:20",
  "02:30",
  "02:40",
  "02:50",
  "03:00",
  "03:10",
  "03:20",
  "03:30",
  "03:40",
  "03:50",
  "04:00",
  "04:10",
  "04:20",
  "04:30",
  "04:40",
  "04:50",
  "05:00",
  "05:10",
  "05:20",
  "05:30",
  "05:40",
  "05:50",
  "06:00",
  "06:10",
  "06:20",
  "06:30",
  "06:40",
  "06:50",
  "07:00",
  "07:10",
  "07:20",
  "07:30",
  "07:40",
  "07:50",
  "08:00",
  "08:10",
  "08:20",
  "08:30",
  "08:40",
  "08:50",
  "09:00",
  "09:10",
  "09:20",
  "09:30",
  "09:40",
  "09:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
  "22:00",
  "22:10",
  "22:20",
  "22:30",
  "22:40",
  "22:50",
  "23:00",
  "23:10",
  "23:20",
  "23:30",
  "23:40",
  "23:50",
];

let sba_INFO = {};
let fileList = [];
let sba_EMAIL = [];

export default function ScheduleAirBookingDetail(props) {
  const { rowData, requestUserUpdate, loginInfo } = props;
  const [uploadFile, setUploadFile] = useState([]);
  const controller = {
    sba_SHIPPER_NM: useRef(),
    sba_BLNO: useRef(),
    sba_PRODUCT_NM: useRef(),
    sba_CNT: useRef(),
    sba_QUANTITIES: useRef(),
    sba_BRING_TIME: useRef(),
    sba_CFS_CODE: useRef(),
    sba_MEMO: useRef(),
    sba_SHORT_LINK: useRef(),
    sba_STATUS: useRef(),
  };

  useEffect(() => {
    let requestDay = util.handleGetDate(rowData.sba_FLIGHT_DATE);
    requestDay = requestDay.toUpperCase();
    sba_INFO = rowData[`sba_INFO_${requestDay}`];

    sba_EMAIL = rowData.sba_EMAIL.split("|");

    if (rowData.sba_FILES !== "") {
      let splitData = rowData.sba_FILES.split("|");
      let temp = [];
      for (let i = 0; i < splitData.length; i++) {
        let data = splitData[i];
        let dataSplit = data.split("?");
        temp.push({
          fileName: dataSplit[0],
          fileLink: dataSplit[1],
        });
      }
      fileList = temp;
      setUploadFile(temp);
    }
  }, []);

  const handleUploadFile = (val) => {
    if (!val.ret) {
      codeList.Modal.current.alert(
        "파일 업로드에 실패하였습니다 다시 시도해주세요."
      );
      return;
    }
    fileList.push({
      fileName: val.realFileName,
      fileLink: val.fileName,
    });
    setUploadFile([...fileList]);
  };

  const handleDeleteFile = (index) => {
    fileList.splice(index, 1);
    setUploadFile([...fileList]);
  };

  const createLink = () => {
    let url =
      "https://www.gbts.co.kr/backside/schedule_booking_air_information_link.php?num=";
    let randLink = util.buddibleEncrypt(rowData.sba_IDX);

    url = url + randLink + "#schedule_info_scroll";
    url = encodeURI(url);
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      {
        long_url: url,
      },
      msgID,
      (beforeData, newData) => {
        controller.sba_SHORT_LINK.current.value = newData["link"];
        let fileJoinList = [];
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            let file = fileList[i];
            fileJoinList.push(`${file.fileName}?${file.fileLink}`);
          }
        }
        let updateData = {
          sba_IDX: rowData.sba_IDX,
          sba_IDX_MAIN: rowData.sba_IDX_MAIN,
          sba_SHIPPER_NM: controller.sba_SHIPPER_NM.current.value,
          sba_BLNO: controller.sba_BLNO.current.value,
          sba_PRODUCT_NM: controller.sba_PRODUCT_NM.current.value,
          sba_CNT: controller.sba_CNT.current.value,
          sba_QUANTITIES: controller.sba_QUANTITIES.current.value,
          sba_BRING_TIME: controller.sba_BRING_TIME.current.value,
          sba_FILES: fileJoinList.join("|"),
          sba_CFS_CODE: controller.sba_CFS_CODE.current.value,
          sba_MEMO: controller.sba_MEMO.current.value,
          sba_STATUS: controller.sba_STATUS.current.value,
          sba_SHORT_LINK: newData["link"],
        };

        requestUserUpdate(updateData);
      }
    );
  };
  const copyLink = () => {
    const el = controller.sba_SHORT_LINK.current;
    el.select();
    document.execCommand("copy");
    if (document.execCommand("copy")) {
      codeList.Modal.current.alert("복사되었습니다.", () => {});
    }
  };
  const openLink = () => {
    const el = controller.sba_SHORT_LINK.current.value;
    if (!el) {
      codeList.Modal.current.alert("링크를 먼저 생성 해주세요.", () => {});
    } else {
      window.open(el, "", "_blank");
    }
  };

  const checkValidation = () => {
    if (controller.sba_SHIPPER_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("실화주 정보를 입력해주세요.");
      return false;
    }
    if (controller.sba_BLNO.current.value.trim() === "") {
      codeList.Modal.current.alert("H B/L NO를 입력해주세요.");
      return false;
    }
    if (controller.sba_PRODUCT_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("선적예정품목명을 입력해주세요.");
      return false;
    }
    if (controller.sba_CNT.current.value.trim() === "") {
      codeList.Modal.current.alert("부킹물량을 입력해주세요.");
      return false;
    }
    if (controller.sba_QUANTITIES.current.value.trim() === "") {
      codeList.Modal.current.alert("단위를 입력해주세요.");
      return false;
    }
    if (controller.sba_BRING_TIME.current.value.trim() === "") {
      codeList.Modal.current.alert("반입(예정)시간을 선택해주세요.");
      return false;
    }
    return true;
  };
  const actionSave = () => {
    if (!checkValidation()) return;

    let fileJoinList = [];
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        fileJoinList.push(`${file.fileName}?${file.fileLink}`);
      }
    }

    const updateData = {
      sba_IDX: rowData.sba_IDX,
      sba_IDX_MAIN: rowData.sba_IDX_MAIN,
      sba_SHIPPER_NM: controller.sba_SHIPPER_NM.current.value,
      sba_BLNO: controller.sba_BLNO.current.value,
      sba_PRODUCT_NM: controller.sba_PRODUCT_NM.current.value,
      sba_CNT: controller.sba_CNT.current.value,
      sba_QUANTITIES: controller.sba_QUANTITIES.current.value,
      sba_BRING_TIME: controller.sba_BRING_TIME.current.value,
      sba_FILES: fileJoinList.join("|"),
      sba_CFS_CODE: controller.sba_CFS_CODE.current.value,
      sba_MEMO: controller.sba_MEMO.current.value,
      sba_STATUS: controller.sba_STATUS.current.value,
    };

    requestUserUpdate(updateData);
  };

  return (
    <tr
      className="container mx-0 p-0"
      id={rowData.sba_IDX}
      style={{ background: "#F7F7F7" }}
    >
      <td colSpan="12">
        <div className="card border-0 shadow-none m-3 text-dark">
          <div className="card-body m-0 p-0" style={{ background: "#F7F7F7" }}>
            <div className="rounded-square bg-white mb-3">
              <p className="h6 font-weight-bold">■ 요청스케쥴 정보</p>
              <div className="d-flex flex-row border border-right-0 text-center mb-1">
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">항공사</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <p>{rowData.sba_FWD_NM}</p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">공급사</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <p>{rowData.sba_PROVIDER_NM}</p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">편명</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <p>{sba_INFO.FLIGHT_NAME}</p>
                    <p>{sba_INFO.AIR_FREIGHT_NO}</p>
                    {sba_INFO.AIR_FREIGHT_MEMO != "" && (
                      <p className="text-danger font-weight-bold">
                        *{sba_INFO.AIR_FREIGHT_MEMO}
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">
                      출발지 및 시간
                    </p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <p className="font-weight-bold">{rowData.sba_POL_NAME}</p>
                    <p className="font-weight-bold text-danger">
                      {sba_INFO.DEPARTURE_TIME}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">
                      도착지 및 시간
                    </p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <p className="font-weight-bold">{rowData.sba_POD_NAME}</p>
                    <p className="font-weight-bold text-danger">
                      {sba_INFO.AIRRIVE_TIME}
                    </p>
                  </div>
                </div>
              </div>
              <p>스케쥴 메모 : {rowData.sba_REMARK || "-"}</p>
            </div>
            <div className="rounded-square bg-white mb-3">
              <p className="h6 font-weight-bold">■ 품목 정보</p>
              <div className="d-flex flex-row align-items-center mb-1">
                - 아래의 표에 품목정보를 입력해주세요{" "}
                <div
                  className="d-inline-block bg-info-light h-100 mx-1"
                  style={{ width: 80, height: 30 }}
                >
                  {" "}
                </div>{" "}
                입력항목{" "}
              </div>
              <div className="d-flex flex-row border border-right-0 text-center">
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">화주상호</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <input
                      className="bg-info-light border-0 text-center"
                      type="text"
                      ref={controller.sba_SHIPPER_NM}
                      defaultValue={rowData.sba_SHIPPER_NM}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                      placeholder="화주 상호명을 입력해주세요"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">H B/L NO</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <input
                      className="bg-info-light border-0 text-center"
                      type="text"
                      ref={controller.sba_BLNO}
                      defaultValue={rowData.sba_BLNO}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                      placeholder="H B/L NO를 입력해주세요"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">
                      선적예정 품목명
                    </p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <input
                      className="bg-info-light border-0 text-center"
                      type="text"
                      ref={controller.sba_PRODUCT_NM}
                      defaultValue={rowData.sba_PRODUCT_NM}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                      placeholder="품목명을 입력해주세요"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">부킹물량</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <input
                      className="bg-info-light border-0 text-center"
                      type="text"
                      ref={controller.sba_CNT}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                      defaultValue={rowData.sba_CNT}
                      placeholder="수량을 입력해주세요"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">단위</p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <input
                      className="bg-info-light border-0 text-center"
                      type="text"
                      ref={controller.sba_QUANTITIES}
                      defaultValue={rowData.sba_QUANTITIES}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                      placeholder="수량 단위를 입력해주세요"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 border-right">
                  <div className="p-2 bg-info">
                    <p className="text-white font-weight-bold">
                      반입(예정)시간
                    </p>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column justify-content-center p-2">
                    <select
                      className="bg-info-light border-0 text-center"
                      ref={controller.sba_BRING_TIME}
                      defaultValue={rowData.sba_BRING_TIME}
                      disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                    >
                      {times.map((time) => {
                        return (
                          <option key={time} value={time} className="bg-white">
                            {time}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-square bg-white mb-3">
              <p className="h6 font-weight-bold">■ 반입지 정보</p>
              <div className="row m-0 mb-2 p-0 border border-right-0">
                <div className="col-4 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                  <div className="bg-info text-white font-weight-bold p-2">
                    CFS CODE
                  </div>
                  <div className="bg-light p-1 flex-fill d-flex flex-column align-items-center justify-content-center">
                    <input
                      ref={controller.sba_CFS_CODE}
                      disabled={true}
                      className="border-0 bg-light text-center"
                      value={"040077140"}
                    />
                  </div>
                </div>
                <div className="col-8 flex-grow-1 text-center border-right m-0 p-0 d-flex flex-column">
                  <div className="bg-info text-white font-weight-bold p-2">
                    주소/전화번호
                  </div>
                  <div className="bg-light p-1 flex-fill d-flex flex-column align-items-start justify-content-cener">
                    [(주)더블유코리아] 주소 : 인천시 중구 공항동로 296번길 98-30
                    범한 판토스 내 27-28 GATE(TEL : 032-744-7404)
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-square bg-white mb-3">
              <p className="h6 font-weight-bold">■ 첨부파일</p>
              <div>
                <div style={{ width: 200 }}>
                  <UploadImageToS3WithPhp
                    region={`mall/reserveAIR/${rowData.sba_IDX_MAIN}`}
                    callback={(val) => {
                      handleUploadFile(val);
                    }}
                  />
                </div>
                {uploadFile.length > 0 ? (
                  uploadFile.map((file, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex flex-row align-items-center mt-2"
                      >
                        <a href={file.fileLink} target="_blank">
                          {file.fileName}
                        </a>
                        <button
                          className="btn btn-sm btn-danger py-0 px-2 ml-2 font-size-08"
                          onClick={() => handleDeleteFile(index)}
                        >
                          삭제
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p>- 등록된 첨부파일이 존재하지 않습니다.</p>
                )}
              </div>
            </div>
            <div className="rounded-square bg-white mb-3">
              <p className="h6 font-weight-bold">■ BOOKING 요청 메모</p>
              <div>
                <textarea
                  className="form-control"
                  rows={5}
                  placeholder="Booking 요청 메모를 작성해주세요"
                  disabled={!(loginInfo.isAdmin || loginInfo.isForwarder)}
                  ref={controller.sba_MEMO}
                  defaultValue={rowData.sba_MEMO}
                />
              </div>
            </div>
            <div className="rounded-square bg-white mb-3">
              <div className="row m-0 p-0">
                <div className="col-6 m-0 p-0">
                  <p className="h6 font-weight-bold">■ Booking 정보 회신처</p>
                  <div>
                    {sba_EMAIL.map((mail) => {
                      return (
                        <a key={mail} href={`mailto:${mail}`}>
                          {mail}
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="col-6 m-0 p-0">
                  <p className="h6 font-weight-bold">■ 처리상태</p>
                  <select
                    className="form-control mb-2"
                    style={{ width: 200 }}
                    ref={controller.sba_STATUS}
                    defaultValue={rowData.sba_STATUS}
                  >
                    <option value="N">요청</option>
                    {(loginInfo.isConsole || loginInfo.isAdmin) && (
                      <option value="Y">확정</option>
                    )}
                    <option value="C">취소</option>
                  </select>
                </div>
              </div>
              <div>
                <div className="w-50 d-flex flex-row mt-2">
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    onClick={() => {
                      createLink();
                    }}
                  >
                    예약정보 URL 생성
                  </button>
                  <input
                    className="form-control"
                    style={{ width: "40%", borderRadius: "5px 0 0 5px" }}
                    readOnly={true}
                    defaultValue={rowData.sba_SHORT_LINK}
                    ref={controller.sba_SHORT_LINK}
                  />
                  <button
                    className="btn btn-md btn-primary"
                    style={{ borderRadius: "0 5px 5px 0" }}
                    onClick={() => {
                      copyLink();
                    }}
                  >
                    복사
                  </button>

                  <button
                    className="btn btn-md btn-primary ml-2"
                    onClick={() => {
                      openLink();
                    }}
                  >
                    미리보기
                  </button>
                </div>
              </div>
            </div>
            <div className="rounded-square bg-white mb-3">
              <div className="text-right">
                <button
                  className="btn btn-primary px-4 font-weight-bold"
                  onClick={(e) => {
                    actionSave(e);
                  }}
                >
                  <i className="fa-regular fa-floppy-disk mr-2" />
                  예약정보 저장
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
