/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import CityManageCityTable from "./CityManageCityTable";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const headCells = [
  {
    field: "cIDX",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "cNAME",
    headerName: "국가명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },

  {
    field: "cUSEYN",
    headerName: "사용유무",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },

  {
    field: "prUpdateDate",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span>
        <button
          type="button"
          key={"btn-" + params.currentData.uID}
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#staticCityEditModal"
          onClick={(e) => params.openEdit(e, params)}
          aria-label={"수정"}
        >
          <i className="fas fa-pen " />
        </button>
      </span>
    ),
  },
];
const _mClassName = "CityManageTable";

export default function CityManageTable({
  rowData,
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("cIDX");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "cIDX",
      sort: "asc",
    },
  ]);
  let filters = {};

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_CITY_MANAGE_DATA_UPDATE,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
  })();

  useEffect(() => {
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_CITY_MANAGE_DATA_UPDATE,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/code/JS_code_mng_country_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
      cBIGCD: rowData.cBIGCD,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  function openEdit(e, params) {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_CITY_MANAGE_EDIT_MODAL_CALL,
      "CityManageCountryTable",
      params.currentData,
      () => {}
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.cIDX}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={row.cIDX}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={row.cIDX}
                  row={row}
                  index={index}
                  openEdit={openEdit}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row key={row.cIDX} row={row} index={index} openEdit={openEdit} />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
    </TableContainer>
  );
}

CityManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
function Row(props) {
  const { row, index, openEdit, isMultiSelect = false } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();
  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.cIDX);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.cIDX)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.cIDX}
        selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                도시관리
              </Typography>
              <CityManageCityTable
                rowData={row}
                isServerPaging={false}
                isServerSort={false}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({}).isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-light">
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : (
                ""
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};
