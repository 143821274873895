import React, { useEffect, useState } from "react";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

import EntireTalkRoomList from "./component/EntireTalkRoomList";
import EntireTalkRoomCompList from "./component/EntireTalkRoomCompList";
import EntireTalkMsgManage from "./component/EntireTalkMsgManage";
import CommonLoader from "../CommonLoader";
import EntireTalkRoomRegisteredCompList from "./component/EntireTalkRoomRegisteredCompList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "EntireTalkManage";

let talkRoomList = [];

export default function EntireTalkManage(props) {
  const { pagesOpen, loginInfo, compList, compListObj } = props;
  const [isLoad, setIsLoad] = useState(true);
  const [selectedTalkRoom, setSelectedTalkRoom] = useState({});
  const [selectedTalkRoomCompList, setSelectedTalkRoomCompList] = useState([]);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (pagesOpen[_mClassName] && isLoad) {
      requestEntireTalkRoomList((newData) => {
        talkRoomList = newData["returnData"];
        setIsLoad(false);
      });
    }
  }, [pagesOpen[_mClassName]]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_ENTIRE_TALK_ROOM_LIST_UPDATE,
      _mClassName,
      (b, n) => {
        if (!n) return;

        requestEntireTalkRoomList((newData) => {
          let room =
            newData["returnData"].find(
              (item) => item.cnmMainKey === n.cnmMainKey
            ) || talkRoomList.find((item) => item.cnmMainKey === n.cnmMainKey);
          room = {
            ...room,
            ...newData,
          };

          changedNews(room);
          setSelectedTalkRoomCompList([]);
          talkRoomList = newData["returnData"];
          setSelectedTalkRoom({});
          setIsNeedUpdate((prevState) => !prevState);
        });
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ENTIRE_TALK_ROOM_LIST_UPDATE,
        _mClassName
      );
    };
  }, []);

  const requestEntireTalkRoomList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_getEntireTalkRoomList.php",
      msgID: msgID,
      data: [],
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length > 0) {
            for (let i = 0; i < newData["returnData"].length; i++) {
              if (!newData["returnData"][i].cnmPicMain) {
                newData["returnData"][i].cnmPicMain =
                  newData["returnData"][i].COMP_LOGO;
              }
            }
          }
          callback && callback(newData);
        }
      }
    });
  };

  const requestSelectedTalkRoomCompList = (data, callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_getEntireTalkRoomCompList.php",
      msgID: msgID,
      data: [],
      cniMainKey: data.cnmMainKey,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const selectTalkRoom = (data) => {
    requestSelectedTalkRoomCompList(data, (newData) => {
      const comp = newData["returnData"][0];
      const sites = comp && comp.cniSites ? comp.cniSites.split("|") : [];
      let dataList = [];

      for (let i = 0; i < sites.length; i++) {
        const comp_cd = sites[i];
        const comp = compListObj[comp_cd];
        dataList.push(comp);
      }

      setSelectedTalkRoomCompList(dataList);
      setSelectedTalkRoom(data);
    });
  };

  const changedNews = (news) => {
    const msgID = util.makeUUIDv4();
    const data = {
      method: "ChangedNews",
      msgID: msgID,
      data: {
        ...news,
        cnmMainKey: news.cnmMainKey,
      },
    };
    socket.sendSocketMessage(data, msgID, (beforeData, newData) => {});
  };

  const makeChannelRegisteredCompData = (dataList) => {
    let list = [];
    for (let i = 0; i < dataList.length; i++) {
      list.push(dataList[i].COMP_CD);
    }
    return list.join("|");
  };

  const updateTalkRoomCompList = (dataList, callback) => {
    const sites = makeChannelRegisteredCompData(dataList);

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_entire/JS_updateEntireTalkRoomCompList.php",
      msgID: msgID,
      data: [],
      cniMainKey: selectedTalkRoom.cnmMainKey,
      cniSites: sites,
      cniCreator: loginInfo.user_id,
      cniCreatorSite: loginInfo.comp_cd,
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          changedNews({ ...selectedTalkRoom, cniSites: sites });
          codeList.Modal.current.alert("정상처리되었습니다.");
          setSelectedTalkRoomCompList(dataList);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
        callback && callback();
      }
    });
  };

  const openEntireEditModal = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_ENTIRE_TALK_ROOM_EDIT_MODAL_CALL,
      _mClassName,
      null,
      () => {}
    );
  };

  if (isLoad) return <CommonLoader />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-xl-3 pr-0 mb-3">
          <div className="card shadow-sm">
            <div className="card-header text-right">
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#EntireTalkRoomEditModal"
                onClick={openEntireEditModal}
              >
                신규생성
              </button>
            </div>
            <div className="pt-2 px-2">
              <EntireTalkRoomList
                loginInfo={loginInfo}
                talkRoomList={talkRoomList}
                selectedTalkRoom={selectedTalkRoom}
                selectTalkRoom={selectTalkRoom}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-9">
          <div className="row m-0 p-0 mb-3">
            <div className="col-6 m-0 p-0 pr-2">
              <EntireTalkRoomCompList
                compList={compList}
                compListObj={compListObj}
                selectedTalkRoom={selectedTalkRoom}
                selectedTalkRoomCompList={selectedTalkRoomCompList}
                updateTalkRoomCompList={updateTalkRoomCompList}
              />
            </div>
            <div className="col-6 m-0 p-0 pl-2">
              <EntireTalkRoomRegisteredCompList
                compList={compList}
                compListObj={compListObj}
                selectedTalkRoom={selectedTalkRoom}
                selectedTalkRoomCompList={selectedTalkRoomCompList}
                updateTalkRoomCompList={updateTalkRoomCompList}
              />
            </div>
          </div>
          <EntireTalkMsgManage
            loginInfo={loginInfo}
            selectedTalkRoom={selectedTalkRoom}
          />
        </div>
      </div>
    </div>
  );
}
